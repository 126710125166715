import { useLayoutEffect, useRef, useState } from 'react'

export const useWidth = () => {
    const ref = useRef()
    const [width, setWidth] = useState(null)
    const currentWidth = ref.current ? ref.current.offsetWidth : null
    useLayoutEffect(() => {
        setWidth(currentWidth)
    }, [currentWidth])
    return [ref, width]
}

export const useHeight = () => {
    const ref = useRef()
    const [height, setHeight] = useState(null)
    const currentHeight = ref.current ? ref.current.offsetHeight : null
    useLayoutEffect(() => {
        setHeight(currentHeight)
    }, [currentHeight])
    return [ref, height]
}
