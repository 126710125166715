import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('findingService', findingService)

    findingService.$inject = ['$rootScope', '$translate', '$timeout', 'dataQuery', 'wfObject', '$q', '$uibModal', 'requirements', 'dataOperationsService', 'modalService', 'valueChainService', '$ngBootbox', 'wfAuth']
    function findingService($rootScope, $translate, $timeout, dataQuery, wfObject, $q, $uibModal, requirementService, dataOps, modal, valueChainService, $ngBootbox, wfAuth) {
        let
            service
			
        const loadedStepsByNetworkId = {}
			
        const severity = [
            { id: 0, name: $translate.instant('modules.findings.severities.observation'), color: '#4472C4' },
            { id: 1, name: $translate.instant('modules.findings.severities.minor'), color: '#FFF2CC' },
            { id: 2, name: $translate.instant('modules.findings.severities.major'), color: '#FFC000' },
            { id: 3, name: $translate.instant('modules.findings.severities.critical'), color: '#FF0000' },
            { id: 4, name: $translate.instant('modules.findings.severities.zeroTolerance'), color: '#C00000' },
        ]

        service = {
            getFindingSteps,
            severity,
            severityById: _.keyBy(severity, 'id'),
            openAnswerCreator,
            openInfoAttacherPicker,
        }

        return service

        function getFindingSteps(ticket) {
            const culture = wfAuth.getCulture()
            return $q((resolve, reject) => {
                let
                    output = {}
					
                let steps

                if (ticket && ticket.networkId) {

                }
                else {
                    if (!loadedStepsByNetworkId['default']) {
                        steps = [
                            { type: 71, id: 18704, wfid: '71-18704', title: culture === 'sv-SE' ? 'Grundorsaksanalys' : 'Root Cause Analysis',
                                findingStepSettings: { enableAttachments: false, optionalWhenSeverity: [enums.findingSeverity.observation, enums.findingSeverity.minor] },
                            },
                            { type: 71, id: 18705, wfid: '71-18705', title: culture === 'sv-SE' ? 'Plan för korrigerande åtgärder' : 'Corrective Action Plan',
                                findingStepSettings: { enableAttachments: false, optionalWhenSeverity: [enums.findingSeverity.observation] },
                            },
                            { type: 71, id: 18706, wfid: '71-18706', title: culture === 'sv-SE' ? 'Plan för förebyggande åtgärder' : 'Preventive Action Plan',
                                findingStepSettings: { enableAttachments: false, optionalWhenSeverity: [enums.findingSeverity.observation, enums.findingSeverity.minor] },
                            },
                            { type: 71, id: 18707, wfid: '71-18707', title: culture === 'sv-SE' ? 'Implementerade korrigerande åtgärder' : 'Corrective Action Implemented',
                                findingStepSettings: { enableAttachments: true, unlockWhenCompleted: ['71-18704', '71-18705', '71-18706'], optionalWhenSeverity: [enums.findingSeverity.observation] },
                            },
                            { type: 71, id: 18708, wfid: '71-18708', title: culture === 'sv-SE' ? 'Implementerade förebyggande åtgärder' : 'Preventive Action Implemented',
                                findingStepSettings: { enableAttachments: true, unlockWhenCompleted: ['71-18704', '71-18705', '71-18706'], optionalWhenSeverity: [enums.findingSeverity.observation, enums.findingSeverity.minor] },
                            },
                        ]

                        loadedStepsByNetworkId['default'] = {
                            networkId: _.get(ticket, 'networkId'),
                            steps,
                            byId: _.keyBy(steps, 'id'),
                        }
                    }

                    output = loadedStepsByNetworkId['default']
                }

                resolve(output)
            })
        }

        function openAnswerCreator(options) {
            _.assign({
                finding: null,
                step: null,
                ticket: null,
            }, options)

            const finding = options.finding; let promise

            promise = modal.createWithPromise({
                type: enums.objectType.findingStepAnswer,
                findingId: finding.id,
                findingStepId: options.step.id,
                findingCreatorOrganizationId: finding.creatorOrganizationId,
                findingCreatorUserId: finding.creatorUserId,
                networkId: finding.networkId,
                contextParentWfid: finding.contextParentWfid,
            }, {
                networkId: finding.networkId,
                contextParentWfid: finding.contextParentWfid,
            })

            promise.then((answer) => {
                wfObject.inject({
                    type: enums.objectType.virtualDataRelation,
                    wfid: '81-|' + finding.wfid + '|' + answer.wfid,
                    parentType: finding.type,
                    parentData1: null,
                    childId: answer.id,
                    childType: answer.type,
                    wffid: finding.wfid,
                    wfcid: answer.wfid,
                })
            })

            return promise
        }

        function openInfoAttacherPicker(findingStepAnswer, afterSubmitFunction, ticket) {
            return modal.openCreatorAndPicker({
                showTopItemAboveTitle: true,
                hideListHeader: false,
                hideFilters: false,
                // translations: {
                // 	addWord: $translate.instant('Send'),
                // 	toWord: $translate.instant('To'),
                // 	filterButton_all: $translate.instant('AllUsers'),
                // 	filterButton_selected: $translate.instant('Sent')
                // },
                compilerControl: null,
                title: $translate.instant('Attach'),
                create: true,
                pick: false,
                objectTypes: [enums.objectType.orgDocument],
                relationTarget: { item: findingStepAnswer, kind: enums.subItemsKind.relatedContentByUser },
                intersection: ticket,
            }).closed(() => {
                afterSubmitFunction()
            })
        }
    }
})()
