(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfListInterfaceSandbox', {
            templateUrl: 'scripts/wf/list/wfListInterfaceSandbox.component.html',
            controllerAs: 'vm',
            controller: wfListInterfaceSandboxComponentController,
            bindings: {
            },
        })

    wfListInterfaceSandboxComponentController.$inject = ['$scope', '$attrs', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$compile', '$translate', 'apiProxy', 'dataQuery', 'wfPropertyExtractor', 'DataNegotiator', '$http', '$templateCache', 'modalService', 'dataOperationsService', 'wfObject', '$q', 'valueChainService']
    function wfListInterfaceSandboxComponentController($scope, $attrs, $timeout, $element, $rootScope, $state, requirements, $compile, $translate, apiProxy, dataQuery, wfPropertyExtractor, DataNegotiator, $http, $templateCache, modal, dataOps, wfObject, $q, valueChainService) {
        const
            vm = this
			
        let pickerSelectorButtonBusy = false

        _.assign(vm, {
            uiCompiler: {},
            selectedItemCompiler: {},
            uiWidth: 100,
            // fromItem: { type: 71, id: 10015 }, // Activities
            // fromItem: { type: 71, id: 10013 },
            // fromItem: { type: 21, id: 1120 },
            // fromItem: { type: 21, id: 1 }, // Most answered measure on WF
            // fromItem: { type: 52, id: 63 },
            // fromItem: { type: 52, id: 7 },
            // fromItem: { type: 71, id: 990 }, // ISO 9001
            // fromItem: { type: 71, id: 903 }, // ISO 14001
            // fromItem: { type: 71, id: 15077 }, // Requirement Package with Everything
            // fromItem: { type: 71, id: 11723 },
            fromItem: { type: 71, id: 61 },
            negotiator: undefined,
            asPanel: true,
            showListInterface: true,
            loadDepth: 5,

            setBackground,
            setItemFromRoot,
            setItemFromVCPackages,
            setItemFromQuestions,
            setItemFromMeasures,
            setItemFromCommonStructures,
            setLoadDepth,
            setTicketMode,
        })

        vm.widthSliderOptions = {

        }

        $timeout(() => { //timeout set for the slider
            vm.uiWidth = 66.66
        })

        initNegotiator()

        function initNegotiator() {
            // vm.ticket = {
            // 	"networkId": 67,
            // 	"organizationIds": [
            // 		120072, 4520, 7328, 35478, 120220, 120237, 120186, 44290, 31428, 13292, 14011, 109178, 109207, 41897, 120121, 7772,
            // 		33715, 34406, 45475, 53329, 41572, 14475, 16389, 18796, 21711, 21728, 29435, 120122, 120136, 111846, 111847, 111849,
            // 		111850, 111851, 111852, 111853, 111864, 111865, 111871, 111877, 120033, 120120
            // 	]
            // };

            // Worldfavor Dev Account VC
            // vm.ticket = {
            // 	"networkId": 75,
            // 	"organizationIds": [
            // 		121246,
            // 		121247,
            // 		121248,
            // 		121249,
            // 		121250,
            // 		121251,
            // 		121252,
            // 		121253
            // 	]
            // }

            vm.negotiator = new DataNegotiator({ fromItem: vm.fromItem, ticket: vm.ticket, loadDepth: vm.loadDepth })
			
            vm.negotiator.onRequest.then((res) => {
                vm.fromItem = vm.negotiator.item
                vm.selectedItemCompiler.compile()
            })
        }
		
        function setItemFromRoot() {
            return openHierarchicalPicker({
                objectId: 13544,
                objectType: 71,
                childrenLoadDepth: 3,
                getterConditions: {
                    contextVariable1: 'onlyLatestAnswers',
                    applyIntersectionIfPossible: true,
                },
                ticket: {
                },
                culture: 'sv-SE',
            })
        }
		
        function setItemFromVCPackages() {
            return openHierarchicalPicker({
                objectId: 12225,
                objectType: 71,
                childrenLoadDepth: 1,
            })
        }
		
        function setItemFromQuestions() {
            return openHierarchicalPicker({
                objectId: 112, // The Environment category from ISO 26000
                objectType: 71,
                childrenLoadDepth: 1,
            })
        }
		
        function setItemFromMeasures() {
            return openHierarchicalPicker({
                objectId: 15236, // Structure containing the top 50 most answered measures on WF
                objectType: 71,
                childrenLoadDepth: 1,
            })
        }
		
        function setItemFromCommonStructures() {
            return openHierarchicalPicker({
                objectId: 15266, // The most commonly used lists on WF (Info repo, Users, Event log, ISO-standards etc)
                objectType: 71,
                childrenLoadDepth: 1,
            })
        }

        function openHierarchicalPicker(parameters, selectItemCallback) {
            let openedModal
            var promise = dataOps.getObject(parameters).then((res) => {
                promise.openedModal = openedModal = modal.openItem({
                    size: 'width-800',
                    template: '<wf-hierarchical vm-prototype="vmPrototype" override="{ headerOptions: pageHeader }" limit-depth="' + parameters.childrenLoadDepth + '" item="item" use-item-as-is transclusion-outer-vm="outerVm">'
						+ '<transclude-item-side>'
						+ '<button class="btn btn-default ml30" wf-click="outerVm.selectItem(hierItemVm.item)">Set</button>'
						+ '</transclude-item-side>'
						+ '</wf-hierarchical>',
                    scope: {
                        pageHeader: { title: 'Select item to load' },
                        item: res,
                        vmPrototype: {
                            showBackButton: false,
                            showFilterBars: false,
                            showFulfillment: false,
                            showLevelsFilterBar: true,
                            showParentsStatistics: false,
                            showQuestionAnswerStatistics: false,
                            showRequirements: false,
                            showMetadata: false,
                            showSigning: false,
                            showSunburstChart: false,
                            mainPanelFullWidth: true,
                            itemSettings: {
                                showDropdown: false,
                                showMetadata: false,
                            },
                            isWorkMode: false,
                            showTypeSpecificTools: false,
                        },
                        outerVm: {
                            selectItem: selectItemCallback || function (item) {
                                vm.fromItem = item.content
                                initNegotiator()

                                vm.uiCompiler.compile()
                                vm.selectedItemCompiler.compile()
                                openedModal.modal.close()
                            },
                        },
                    },
                })
            })

            return promise
        }

        function setBackground(mode) {
            if (mode !== 'whiteBg') {
                $('body').css('backgroundColor', '')
            }

            if (mode === 'whitePanel') {
                vm.asPanel = true
            }
            else if (mode === 'noPanel') {
                vm.asPanel = false
            }
            else if (mode === 'whiteBg') {
                vm.asPanel = false
                $('body').css('backgroundColor', 'white')
            }
        }

        function setLoadDepth(loadDepth) {
            vm.loadDepth = loadDepth
            initNegotiator()
            vm.uiCompiler.compile()
        }

        function setTicketMode(mode) {
            if (mode === 'valueChain') {
                return $q((resolve, reject) => {
                    var promise = openHierarchicalPicker({
                        objectId: 12226,
                        objectType: 71,
                        childrenLoadDepth: 1,
                    }, (itemComposite) => {
                        const networkId = itemComposite.content.id
                        vm.ticketNetworkTitle = itemComposite.content.title

                        if (pickerSelectorButtonBusy) return

                        pickerSelectorButtonBusy = true
                        resolve()

                        return valueChainService.loadOrganizationsInNetwork(networkId, true).then((res) => {
                            // If there are duplicate relations to any organization then those duplicate will have undefined childContent
                            // (since the result is not injected into JSData) so remove them here.
                            const organizationIds = _.chain(res).map('childId').compact().value()
                            promise.openedModal.modal.close()

                            vm.ticket = { networkId, organizationIds }
                            initNegotiator()
                            vm.uiCompiler.compile()
                            vm.ticketMode = mode

                            setTimeout(() => {
                                pickerSelectorButtonBusy = false
                            }, 2000)
                        })
                    })

                    promise.then(() => {
                        resolve()
                    })
                })
            }
            else {
                vm.ticket = vm.ticketMode = undefined
                vm.ticketNetworkTitle = undefined
                initNegotiator()
                vm.uiCompiler.compile()
                $timeout()
            }

        }
    }
})()
