(function() {
    'use strict'
    angular
        .module('wf.common')
        .component('wfCreatorTime', {
            templateUrl: 'scripts/wf/creatorTime/wfCreatorTime.component.html',
            controller: wfCreatorTimeController,
            controllerAs: 'creatorVm',
            require: {
                wfItemUse: '?^wfItemUse',
            },
            bindings: {
                item: '<',
                configFromAttribute: '<config',
            },
        })

    wfCreatorTimeController.$inject = []
    function wfCreatorTimeController() {
        const vm = this

        _.assign(vm, {
            creator: undefined,
            time: undefined,
            config: {
                formatDate: 'ddd, D MMM YYYY',
                formatTime: 'HH:mm',
                display: {
                    creator: true,
                    creatorImage: true,
                    creatorName: true,
                    dateAndTime: true,
                    date: true,
                    time: true,
                },
            },
        })

        vm.$onInit = function () {
            if (!vm.item && vm.wfItemUse) vm.item = vm.wfItemUse.item
			
            assignAttributesToVm()
            initializeCreator()
            initializeTime()
        }
		
        function initializeCreator() {
            if (vm.item && vm.item.content.isUserDataType() && vm.item.content.creatorUser) {
                vm.creator = {}
                _.assign(vm.creator, {
                    imageUrl: vm.item.content.creatorUser.imageUrl,
                    name: vm.item.content.creatorUser.name,
                })
            }
        }

        function initializeTime() {
            if (vm.item && vm.item.content.createdAt) {
                vm.time = {
                    date: moment(vm.item.createdAt).format(vm.config.formatDate),
                    time: moment(vm.item.createdAt).format(vm.config.formatTime),
                }
            }
        }

        function assignAttributesToVm() {
            if (!_.isEmpty(vm.configFromAttribute)) vm.config = _.defaultsDeep(vm.configFromAttribute, vm.config)

            // _.each(vm, function (value, key) {
            // 	if (key in $attrs) {
            // 		if ($attrs[key] && $attrs[key].length)
            // 			vm[key] = $parse($attrs[key])($scope);
            // 		else if (typeof value === "boolean")
            // 			vm[key] = true;
            // 	}
            // });
        }
    }
})()
