import React from 'react'
import { react2angular } from 'react2angular'
import RequirementPackageExporter from '@worldfavor/portal/components/Exporter/RequirementPackageExporter'
import { WrapProvider } from './providers'

const props = [
    'open',
    'packages',
    'networkId',
    'onClosed',
]

class RequirementPackageExporterWrapper extends React.Component {
    render() {
        const { wfAuth, ...rest } = this.props
        return (
            <WrapProvider wfAuth={wfAuth}>
                <RequirementPackageExporter {...rest} />
            </WrapProvider>
        )
    }
}

export default react2angular(RequirementPackageExporterWrapper, props, ['wfAuth'])
