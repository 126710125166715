// @flow
import type { Dispatch } from 'redux'
import { deleteItem, createSubItemRelation } from './dataThunk'
import { SubItemsKind } from '@worldfavor/constants/enums'

export const addContributor = (networkWfid: string, contributorWfid: string, orgWfid: string) => {
    return async (dispatch: Dispatch<*>) => {
        await dispatch(createSubItemRelation(networkWfid, contributorWfid, SubItemsKind.children, { contextParentWfid: orgWfid }))
    }
}

export const removeContributor = (contributorEdge: Object) => {
    return async (dispatch: Dispatch<*>) => {
        await dispatch(deleteItem(contributorEdge))
    }
}

export const addContributors = (networkWfid: string, contributorWfids: Array<string>, orgWfid: string) => {
    return async (dispatch: Dispatch<*>) => {
        const promises = contributorWfids.map((wfid) => {
            return dispatch(addContributor(networkWfid, wfid, orgWfid))
        })

        return Promise.all(promises)
    }
}

export const removeContributors = (contributorEdges: Array<Object>) => {
    return async (dispatch: Dispatch<*>) => {
        const promises = contributorEdges.map((contributorEdge) => {
            return dispatch(removeContributor(contributorEdge))
        })

        return Promise.all(promises)
    }
}
