import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    // Usage:
    //
    // Creates:
    //

    angular
        .module('wf.common')
        .component('wfHierarchicalEditorItem', {
            templateUrl: 'scripts/wf/hierarchical/wfHierarchicalEditorItem.component.html',
            //templateUrl: 'templateUrl',
            require: '^^wfHierarchicalEditorController',
            controller: wfHierarchicalEditorItemController,
            controllerAs: 'vm',
            bindings: {
                item: '=',
                numberPath: '=',
                packageVm: '=',
                belongsTo: '=',
                depth: '=',
            },
        })

    wfHierarchicalEditorItemController.$inject = ['$scope', '$timeout', 'wfAuth', '$element']
    function wfHierarchicalEditorItemController($scope, $timeout, wfAuth, $element) {
        const
            $ctrl = this

        const authOrgId = wfAuth.getOrganizationId() || -1

        ////////////////

        $ctrl.$onInit = $onInit
        $ctrl.$onChanges = function(changesObj) { }
        $ctrl.$onDestory = function() { }

        function $onInit() {
            $ctrl.enums = enums
            $ctrl.itemContent = $ctrl.item.childContent
            $ctrl.itemRelation = $ctrl.item
            $ctrl.dragControlListeners = {
                accept(sourceItemHandleScope, destSortableScope) {
                    return destSortableScope.modelValue.includes(sourceItemHandleScope.itemScope.item)
                },
                orderChanged() {
                    $ctrl.packageVm.reorder($ctrl.childs)
                },
            }

            const { conditions } = $ctrl.itemContent

            $ctrl.addQuestion = addQuestion
            $ctrl.addCategory = addCategory
            $ctrl.addMeasure = addMeasure
            $ctrl.addRelativeMeasure = addRelativeMeasure
            $ctrl.addTaskStructure = addTaskStructure
            $ctrl.addUtilityStructure = addUtilityStructure
            $ctrl.reorder = reorder
            $ctrl.authOrgId = authOrgId

            if ($ctrl.item.childType === enums.objectType.structure) {
                $ctrl.childs = _.sortBy($ctrl.itemContent.childs, 'order')
            }

            setDropdownActions()

            // $ctrl.itemRelatedContent = _.orderBy($ctrl.itemContent.relatedContent, "childContent.title");

            if (conditions && 'dataRelation' in conditions && !conditions.dataRelation.autoGenerated) {
                $ctrl.hasDataRelationCondition = true
            }
            if (conditions && 'dataRelationByUser' in conditions) {
                $ctrl.hasDataRelationByUserCondition = true
            }
            if (conditions && 'objectTypes' in conditions) {
                $ctrl.hasObjectTypesCondition = true
            }

            handleTransclusion()

            $scope.$on('dropdownActionExecuted', ($event, operation, item) => {
                if (operation === 'delete') {
                    if (_.remove($ctrl.childs, { wfid: item.wfid }).length) {
                        $timeout()

                        $ctrl.packageVm.reorder($ctrl.childs)
                    }
                }
            })
        }

        // TODO: Must use simplified form
        function setDropdownActions() {
            if ($ctrl.itemContent.creatorOrganizationId === 1 && $ctrl.itemRelation.organizationId === authOrgId) // Relation added by categorizing another system framework item so allow deleteTag action
                $ctrl.dropdownActions = ['deleteRelation']
            else if ($ctrl.itemContent.creatorOrganizationId === 1 && !$ctrl.itemRelation.organizationId && authOrgId !== 1) // The content is from a system framework and relation owner is not defined so deny all actions
                $ctrl.dropdownActions = undefined
            else // Otherwise, the content is not from a system framework so both update and deleteTag actions is allowed
                $ctrl.dropdownActions = ['edit', 'deleteRelation']
        }

        function addQuestion() {
            $ctrl.packageVm.addQuestion($ctrl.itemContent).modal.closed.then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function addMeasure() {
            $ctrl.packageVm.addMeasure($ctrl.itemContent).modal.closed.then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function addRelativeMeasure() {
            $ctrl.packageVm.addRelativeMeasure($ctrl.itemContent).modal.closed.then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function addCategory() {
            $ctrl.packageVm.addCategory($ctrl.itemContent).then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function addTaskStructure() {
            $ctrl.packageVm.addTaskStructure($ctrl.itemContent).then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function addUtilityStructure() {
            $ctrl.packageVm.addUtilityStructure($ctrl.itemContent).then(() => {
                $ctrl.childs = $ctrl.itemContent.childs
                $timeout()

                $ctrl.packageVm.reorder($ctrl.childs)
            })
        }

        function reorder(direction) {
            return $ctrl.packageVm.reorder($ctrl.belongsTo, $ctrl.item, direction)
        }

        function handleTransclusion() {
            const
                packageVm = $ctrl.packageVm

            let elementClone

            if (packageVm.transcludeItemSide) {
                elementClone = packageVm.transcludeItemSide.elementClone.cloneNode(true)

                $scope.outerVm = packageVm.transcludeItemSide.outerVm
                $scope.hierItemVm = $ctrl
                $element.find('div.transclude-side').append(elementClone.childNodes)
            }
        }
    }
})()
