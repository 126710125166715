import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('analyzeService', analyzeService)

    analyzeService.$inject = ['$rootScope', '$translate', '$timeout', 'dataQuery', 'wfObject', '$q', '$uibModal', 'requirements', 'dataOperationsService', 'modalService', 'valueChainService', '$ngBootbox', 'wfMeasureService']
    function analyzeService($rootScope, $translate, $timeout, dataQuery, wfObject, $q, $uibModal, requirementService, dataOps, modal, valueChainService, $ngBootbox, wfMeasureService) {
        let
            service
			
        const mathOperationById = _.chain([
            { id: 0, name: $translate.instant('mathOperations.add'), icon: 'fa fa-plus' },
            { id: 1, name: $translate.instant('mathOperations.subtract'), icon: 'fa fa-minus' },
            { id: 2, name: $translate.instant('mathOperations.multiply'), icon: 'fa fa-times' },
            { id: 3, name: $translate.instant('mathOperations.divide'), icon: 'fa fa-minus', css: { transform: 'rotate(-60deg)' } },
            { id: 4, name: $translate.instant('mathOperations.replaceWith'), icon: 'fa fa-exchange' },
        ]).keyBy('id').mapValues('name').value()

        service = {
            requirementsLoader,
            editRequirements,
            openAnalyzeJob,
            openAnalyzeJobCreator,
            deleteAnalyzeJob,
            makeRuleValuePairId,
        }

        return service

        function editRequirements(item, ticket, uiMode) {
            return $q((resolve, reject) => {
                const templateHtml = '<wf-analyze-requirements-editor item="item" ticket="ticket" ui-mode="uiMode"></wf-analyze-requirements-editor>'

                const scope = _.assign($rootScope.$new(), {
                    item,
                    ticket,
                    uiMode,
                    result: {},
                })

                const modal = $uibModal.open({
                    animation: true,
                    size: 'width-1100',
                    backdrop: 'static',
                    template: templateHtml,
                    scope,
                })

                modal.closed.then(() => {
                    // result = { requirementsWereChanged: boolean }

                    resolve(scope.result)
                })
            })
        }

        function requirementsLoader(item, ticket, $scope) {
            const
                initialPromises = []
				
            const requirementUiMode = enums.uiMode.admin
				
            const actualObjectType = item.childType
				
            const itemContent = item.childContent
				
            let availableRequirementOptions
				
            let availableRequirementOptionsById
				
            let specificItemOptionsById = {}
				
            let structurePickerSourceWfid
				
            const self = this

            self.items = []

            _.assign(self, {
                loadFormattedRequirementsOnItem,
            })

            // Mocked data
            // vm.items = _.times(2, function (index) {
            // 	return _.defaultsDeep({ actualObject: (function () { var id = parseInt(_.uniqueId()) + 16; return { type: 109, id: id, wfid: "109-" + id } })(), model: {
            // 		order: index + 1,
            // 		rule: undefined,
            // 		value: undefined,
            // 		points: _.random(1, 5),
            // 		operation: _.random(0, 3),
            // 		calculationGrouping: calculationGrouping.local
            // 	}}, itemPrototype);
            // });

            function loadFormattedRequirementsOnItem(options, additionalOutput) {
                let objectTypesCondition; let measurePeriodSettings

                options = _.assign({
                    fromServer: false,
                    preloadedRequirements: undefined,
                    additionalItemsWithMeasurePeriodSettings: undefined,
                    format: true,
                }, options)

                return $q((resolve, reject) => {
                    if (!availableRequirementOptions) {
                        switch (actualObjectType) {
                            case enums.objectType.question:
                                availableRequirementOptions = requirementService.getQuestionSettings(ticket.organizationId, requirementUiMode, $scope, true).requirementOptions
                                break
                            case enums.objectType.measure:
                                measurePeriodSettings = _.get(item.settings, 'measurePeriodSettings')

                                if (measurePeriodSettings) {
                                    availableRequirementOptions = requirementService.getMeasureSettingsFromPeriodSettings(null, requirementUiMode, $scope, measurePeriodSettings, item).requirementOptions
                                }
                                else {
                                    availableRequirementOptions = requirementService.getMeasureSettings(ticket.organizationId, requirementUiMode, $scope).requirementOptions
                                }

                                if (options && options.additionalItemsWithMeasurePeriodSettings) {
                                    _.each(options.additionalItemsWithMeasurePeriodSettings, (_item) => {
                                        const
                                            _measurePeriodSettings = _.get(_item.settings, 'measurePeriodSettings')
											
                                        let additionalRequirementOptions

                                        if (_measurePeriodSettings) {
                                            additionalRequirementOptions = requirementService.getMeasureSettingsFromPeriodSettings(null, requirementUiMode, $scope, _measurePeriodSettings, _item).requirementOptions
                                            Array.prototype.push.apply(availableRequirementOptions, additionalRequirementOptions)
                                        }
                                    })

                                    availableRequirementOptions = _.uniqBy(availableRequirementOptions, (requirementOption) => {
                                        return requirementOption.name
                                    })
                                }

                                break
                            case enums.objectType.parameter:
                                availableRequirementOptions = requirementService.getParameterSettings(ticket.organizationId, requirementUiMode, $scope).requirementOptions
                                break
                            case enums.objectType.structure:
                                let sourceItemFirstLevelAsFilter = false

                                if (!_.get(itemContent.conditions, 'pickerSettings.sourceItem') && !_.get(itemContent.conditions, 'objectTypes') && _.get(itemContent.conditions, 'dataRelation.autoGenerated')) {
                                    availableRequirementOptions = requirementService.getUtilityStructureSettings(ticket.organizationId, requirementUiMode, $scope, true).requirementOptions
                                    availableRequirementOptions.forEach((requirementOption) => {
                                        if (requirementOption.specificItemOptionsLoader && typeof requirementOption.specificItemOptionsLoader.load === 'function') {
                                            initialPromises.push((() => {
                                                const promise = requirementOption.specificItemOptionsLoader.load(options.networkId)

                                                promise.then((specificItemOptions) => {
                                                    requirementOption.specificItemOptions = specificItemOptions
                                                    specificItemOptionsById = { ..._.chain(specificItemOptions).keyBy('wfid').mapValues('name').value(), ...specificItemOptionsById }
                                                })

                                                return promise
                                            })())
                                        }
                                    })
                                    //structurePickerSourceWfid = "71-13804" // Structure that have all countries as children
                                }
                                else {
                                    structurePickerSourceWfid = _.get(itemContent, 'conditions.pickerSettings.sourceItem')
                                    sourceItemFirstLevelAsFilter = _.get(itemContent, 'conditions.pickerSettings.sourceItemFirstLevelAsFilter')

                                    if (!structurePickerSourceWfid && (objectTypesCondition = _.get(itemContent, 'conditions.objectTypes')) && objectTypesCondition[0] === enums.objectType.country) {
                                        structurePickerSourceWfid = '71-13804' // Structure that have all countries as children
                                    }

                                    availableRequirementOptions = requirementService.getTaskStructureSettings(ticket.organizationId, requirementUiMode, $scope, !!structurePickerSourceWfid).requirementOptions

                                }

                                if (structurePickerSourceWfid) {
                                    initialPromises.push((function () {
                                        const promise = dataOps.getSubItems(structurePickerSourceWfid, enums.subItemsKind.children, sourceItemFirstLevelAsFilter && { childrenLoadDepth: 2 })

                                        promise.then((res) => {
                                            let relations = res

                                            if (sourceItemFirstLevelAsFilter) {
                                                relations = _.flatten(res.map(x => x.childContent.childs))
                                            }

                                            const items = _.map(relations, (dataRelation) => {
                                                return {
                                                    wfid: dataRelation.wfcid,
                                                    name: dataRelation.childContent.getMainTextual(),
                                                }
                                            })
                                            specificItemOptionsById = { ..._.chain(items).keyBy('wfid').mapValues('name').value(), ...specificItemOptionsById }

                                            if (additionalOutput) {
                                                additionalOutput.specificItemOptions = items
                                            }
                                        })

                                        return promise
                                    })())
                                }
                                break
                        }
                    }

                    if (options.fromServer) initialPromises.push(loadRequirements())

                    $q.all(initialPromises).then(() => {
                        if (availableRequirementOptions) {
                            availableRequirementOptions = _.reject(availableRequirementOptions, { rule: 0 }) // Remove the option "None"
                            availableRequirementOptions = _.reject(availableRequirementOptions, { rule: enums.requirementRule.emptyRequirement }) // Remove the option "Empty requirement"
                            availableRequirementOptions = _.reject(availableRequirementOptions, { rule: enums.requirementRule.manual }) // Remove the option "Manual assessment"

                            _.each(availableRequirementOptions, (reqOpt) => {
                                reqOpt.id = makeRuleValuePairId(itemContent, reqOpt)
                            })
                        }

                        if (options.format) {
                            availableRequirementOptionsById = _.chain(availableRequirementOptions).keyBy('id').mapValues('name').value()
                            handleRequirementsResult()
                            resolve(self.formattedRequirements)
                        }
                        else {
                            resolve(availableRequirementOptions)
                        }
                    })
                })

                function handleRequirementsResult() {
                    const analyzeRequirements = options.preloadedRequirements || item.analyzeRequirements
                    let
                        periodName
						
                    let requirementPartText
						
                    let requirementText_anyAnswer
						
                    let requirementText_anyAnswerWithMoreInfo
						
                    let requirementText_anyAnswerWithMoreInfoExceptIfNA

                    self.formattedRequirements = _.chain(analyzeRequirements).sortBy('order').map((analyzeReq) => {
                        const output = {
                            mathOperationText: mathOperationById[analyzeReq.operation.toString()],
                            terminal: analyzeReq.terminal,
                            calcGrouping: analyzeReq.calculationGrouping,
                            points: analyzeReq.points,
                            requirementId: analyzeReq.id,
                            resultTask: analyzeReq.resultTask,
                            resultTaskName: getResultTaskName(analyzeReq),
                        }

                        if (actualObjectType === enums.objectType.structure && analyzeReq.rule === enums.requirementRule.specificValues) {
                            output.requirementText = specificItemOptionsById[analyzeReq.value]
                        }
                        else if (actualObjectType === enums.objectType.structure && analyzeReq.rule === enums.requirementRule.span) {
                            if (!analyzeReq.value)
                            {
                                output.requirementText = $translate.instant('modules.valueChain.requirements.Span') + ' (?)'
                            }
                            else
                            {
                                output.requirementText = $translate.instant('modules.valueChain.requirements.Span') + ' (' + analyzeReq.value.replace('|', ' - ') + ')'
                            }
                        }
                        else if (actualObjectType === enums.objectType.measure && _.get(analyzeReq, 'valueDetails.measurePeriod')) {
                            requirementPartText = ''

                            if (analyzeReq.rule === enums.requirementRule.anyValue) {
                                requirementPartText = requirementText_anyAnswer || (requirementText_anyAnswer = $translate.instant('modules.valueChain.requirements.AnyAnswer'))
                            }
                            else if (analyzeReq.rule === enums.requirementRule.anyValueWithRelatedContent) {
                                requirementPartText = requirementText_anyAnswerWithMoreInfo || (requirementText_anyAnswerWithMoreInfo = $translate.instant('modules.valueChain.requirements.AnyAnswerWithMoreInfo'))
                            }
                            else if (analyzeReq.rule === enums.requirementRule.anyValueWithRelatedContentExceptIfNA) {
                                requirementPartText = requirementText_anyAnswerWithMoreInfoExceptIfNA || (requirementText_anyAnswerWithMoreInfoExceptIfNA = $translate.instant('modules.valueChain.requirements.AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA'))
                            }

                            periodName = wfMeasureService.formatPeriodNameFromPeriodItem(analyzeReq.valueDetails.measurePeriod)
                            output.requirementText = requirementPartText + ' (' + periodName + ')'
                        }
                        else {
                            output.requirementText = availableRequirementOptionsById[makeRuleValuePairId(itemContent, analyzeReq)]
                        }

                        return output
                    }).value()
                }

                function getResultTaskName(analyzeReq) {
                    switch (analyzeReq.resultTask) {
                        case enums.analyzeResultTask.notDefined: return ''
                            // case enums.analyzeResultTask.writeLocalLabel: return $translate.instant("modules.analyze.additionalResultTask.");
                        case enums.analyzeResultTask.writeGlobalLabel: return $translate.instant('modules.analyze.additionalResultTask.writeGlobalLabel')
                            // case enums.analyzeResultTask.writeLocalAndGlobalLabel: return $translate.instant("modules.analyze.additionalResultTask.");
                        case enums.analyzeResultTask.categorizeTargetOrganization: return $translate.instant('modules.analyze.additionalResultTask.categorizeTargetOrganization')
                        case enums.analyzeResultTask.uncategorizeTargetOrganization: return $translate.instant('modules.analyze.additionalResultTask.uncategorizeTargetOrganization')
                        case enums.analyzeResultTask.createObject: return $translate.instant('modules.analyze.additionalResultTask.createMeasureAnswer')
                        case enums.analyzeResultTask.createInfluences: return $translate.instant('modules.analyze.additionalResultTask.createInfluences')
                        case enums.analyzeResultTask.deleteInfluences: return $translate.instant('modules.analyze.additionalResultTask.deleteInfluences')
                    }
                }
            }

            function loadRequirements() {
                const promise = dataOps.getObjects({
                    requestSignature_noResultNeeded: ['analyze_requirements', item.wfid, ticket.organizationId, ticket.networkId, ticket.contextParentWfid].join('_'),
                    objectType: enums.objectType.analyzeRequirement,
                    bypassCache: true,
                    wrapInRelations: false,
                    getterConditions: {
                        objectType: item.type,
                        objectId: item.id,
                        organizationId: ticket.organizationId,
                        networkId: ticket.networkId,
                        contextParentWfid: ticket.contextParentWfid,
                    },
                })

                promise.then((res) => {
                    self.analyzeRequirements = item.analyzeRequirements
                })

                return promise
            }
        }

        function makeRuleValuePairId(itemContent, obj) {
            if (itemContent.type === enums.objectType.structure) {
                return obj.rule + '¤' + (obj.value || '') + '¤' + (obj.ruleInstruction || '')
            }
            else return obj.rule + '¤' + obj.value
        }

        function openAnalyzeJobCreator(options) {
            options = _.assign({
                organization: undefined,
                networkId: undefined,
                compilerControl: undefined,
                existingAnalyzeJobs: undefined,
            }, options)

            return modal.createWithPromise({
                type: enums.objectType.analyzeJob,
                targetOrganizationId: options.organization.id,
                networkId: options.networkId,
            }, {
                customFormSpecification: getFormSpecification(),
                title: $translate.instant('modules.valueChain.analyzeJob.creationModalHeader', { orgname: options.organization.name }),
                action(model) {
                    const deferred = $q.defer()
                    const promises = []
                    const newAnalyzeJobs = []

                    _.each(model.selectedItems, (item) => {
                        promises.push(dataOps.create({
                            type: enums.objectType.analyzeJob,
                            targetOrganizationId: options.organization.id,
                            networkId: options.networkId,
                            objectType: 71,
                            objectId: item.id,
                        }).then(res => newAnalyzeJobs.push(res)))
                    })

                    $q.all(promises).then((res) => {
                        deferred.resolve(newAnalyzeJobs)
                    }, () => {
                        deferred.reject()
                        modal.alert({
                            title: $translate.instant('errorMessages.serverError.title'),
                            message: $translate.instant('errorMessages.serverError.message'),
                            onEscape: false,
                            type: 'info',
                            buttons: {
                                reload: {
                                    label: $translate.instant('errorMessages.reloadButtonCaption'),
                                    className: 'btn-hollow action',
                                    callback() {
                                        location.reload()
                                    },
                                },
                            },
                        })
                    })

                    return deferred.promise
                },
            })

            function getFormSpecification() {
                return {
                    schema: {
                        type: 'object',
                        properties: {
                            sourceObject: {
                                title: ' ', // Non-break space (Alt+0160)
                                type: 'integer',
                            },
                        },
                    },
                    form: [
                        {
                            key: 'sourceObject',
                            type: 'picker_multiple',
                            typeOptions: {
                                addButtonCaption: $translate.instant('modules.valueChain.analyzeJob.choosePackage'),
                                singlePick: false,
                                validateAction(event, model, relationBucket) {
                                    return !!relationBucket.allSelected.length
                                },
                                submitAction(event, model, relationBucket) {
                                    model.selectedItems = relationBucket.allSelected
                                },
                                picker: {
                                    // List of Analyze packages in current network
                                    sourceList() {
                                        return $q((resolve, reject) => {
                                            loadAnalyzePackagesInNetwork(options.networkId).then((dataRelations) => {
                                                let structures = _.map(dataRelations, 'childContent')
                                                if (options.existingAnalyzeJobs) {
                                                    structures = _.differenceWith(structures, options.existingAnalyzeJobs, (structure, analyzeJob) => {
                                                        return structure.wfid === analyzeJob.wfcid
                                                    })
                                                }

                                                resolve(_.map(structures, (structure) => {
                                                    return {
                                                        data: structure,
                                                        toggle: structure,
                                                        wfid: structure.wfid,
                                                    }
                                                }))
                                            })
                                        })
                                    },
                                    title: $translate.instant('modules.valueChain.analyzeJob.choosePackage'),
                                },
                            },
                        },
                    ],
                }
            }
        }

        function deleteAnalyzeJob(analyzeJob, organization) {
            return $q((resolve, reject) => {
                $ngBootbox.customDialog({
                    title: $translate.instant('modules.valueChain.analyzeJob.remove.modalTitle'),
                    message: $translate.instant('modules.valueChain.analyzeJob.remove.modalMessage', { analyzejobtitle: analyzeJob.title, orgname: organization.name }),
                    onEscape: true,
                    className: 'valueChain-modal-removeOrganization',
                    buttons: {
                        cancel: {
                            label: $translate.instant('No'),
                            className: 'btn-default',
                            callback() {
                            },
                        },
                        primary: {
                            label: $translate.instant('Delete'),
                            className: 'btn-danger',
                            callback() {
                                dataOps.destroy(analyzeJob).then(() => {
                                    resolve(analyzeJob, organization)
                                })
                            },
                        },
                    },
                })
            })
        }

        function openAnalyzeJob(analyzeJob, options) {
            const scope = {}

            scope.jobId = analyzeJob.id

            return modal.open({
                template: '<wf-analyze-job job-id="jobId"></wf-analyze-job>',
                scope,
                className: 'modal-width-1100',
                onClosed: _.get(options, 'onClosed'),
            })
        }

        // Data Collector functions
        // ##############################################################################

        function loadAnalyzePackagesInNetwork(networkOrId) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.network,
                    objectId: networkId,
                    childrenLoadDepth: 1,
                    getterConditions: {
                        objectTypes: [enums.objectType.structure],
                    },
                }).then((obj) => {
                    const
                        relationsInNetwork = _.filter(obj.childs, { childType: enums.objectType.structure })
						
                    const relationsInNetworkByChildId = _.keyBy(relationsInNetwork, 'childId')

                    dataOps.getObject({
                        objectId: valueChainService.ids.analyzePackagesStructure, // Contains the current organization's data collector analyze packages
                        objectType: enums.objectType.structure,
                        childrenLoadDepth: 1,
                    }).then((structure) => {
                        // Filter mainStructure childs to only get the children that are also in the network.
                        const items = _.filter(structure.childs, (dataRelation) => {
                            const networkDataRelation = relationsInNetworkByChildId[dataRelation.childId]
                            if (networkDataRelation) {
                                return true
                            }
                        })

                        resolve(items)
                    })
                })
            })
        }
    }
})()
