import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    let boxesDefinition

    angular
        .module('wf.common')
        .controller('ObjectViewerController', ObjectViewerController)

    ObjectViewerController.$inject = ['$scope', 'dataOperationsService', 'modalService', '$translate', 'wfTranslate', '$timeout', '$stateParams', '$sanitize', 'moment', '$state', '$rootScope', 'wfAuth', '$q', 'valueChainService', '$compile', '$injector', 'wfObject', 'dataQuery', 'wfMeasureService']
    function ObjectViewerController($scope, dataOps, modal, $translate, wfTranslate, $timeout, $stateParams, $sanitize, moment, $state, $rootScope, wfAuth, $q, valueChainService, $compile, $injector, wfObject, dataQuery, wfMeasureService) {
        const
            vm = this
			
        let mainItem
			
        let objectId
			
        let objectType

        vm.networkId = !isNaN($stateParams.network) ? parseInt($stateParams.network) : undefined

        _.assign(vm, {
            //Arrays
            byWf: [],
            byUser: [],
            logEvents: [],

            //Objects
            header: {},
            context: {},
            text: {
                readMore: $translate.instant('ReadMore'),
                readLess: $translate.instant('Hide'),
            },

            //Variables
            allLoaded: false,
            answeringUIMode: enums.uiMode.work,
            expand: false,
            isUserInbox: false,
            isViewMode: false,
            isWorkMode: false,
            listOfQuestions: false,
            measure: false,
            onlyShowItemsWithUserData: false,
            simplifiedLayout: false,
            toggleSwitch: false,

            //Functions
            showHideText,
            populateBoxItems,
            toggleItemsWithUserData,
            broadcastMeasureChange,
            repopulateBoxes,
        })

        _.assign($scope, {
            modal,
        })

        activate()

        function activate() {
            const promises = []; let promise

            if ($scope.itemFromDirective) {
                objectType = $scope.itemFromDirective.type
                objectId = $scope.itemFromDirective.id
                vm.intersectionSettings = $scope.intersectionSettings
                vm.simplifiedLayout = true
                vm.uiMode = $scope.uiMode
                vm.onlyShowItemsWithUserData = true

                if (vm.uiMode) {
                    if (vm.uiMode == enums.uiMode.view) vm.isViewMode = true
                    else if (vm.uiMode == enums.uiMode.work) vm.isWorkMode = true
                    vm.answeringUIMode = enums.uiMode.view
                }
            }
            else if ($injector.has('$stateParams')) {
                $injector.invoke(['$stateParams', function ($stateParams) {
                    if ($stateParams.objectWfid) {
                        objectType = $stateParams.objectWfid.split('-')[0]
                        objectId = $stateParams.objectWfid.split('-')[1]
                    }
                    vm.relationWfid = $stateParams.relationWfid
                    vm.originalRelationWfid = $stateParams.originalRelationWfid
                    vm.positionRelationWfid = $stateParams.positionRelationWfid
                }])
            }

            promise = dataOps.getObject({
                objectType,
                objectId,
            })
            promises.push(promise)
            promise.then((result) => {
                if (result.wfid === -1) {
                    return
                }

                vm.item = mainItem = result
                vm.boxes = _.cloneDeep(getBoxes())
                vm.isUserInbox = mainItem.type == enums.objectType.individual
            })

            if (vm.relationWfid && vm.relationWfid.indexOf('73-') === 0) {
                promises.push(promise = dataOps.getObject(vm.relationWfid))
                promise.then((res) => {
                    vm.dataRelation = res
                })
            }

            if (vm.originalRelationWfid) {
                promises.push(promise = dataOps.getObject(vm.originalRelationWfid))
                promise.then((res) => {
                    vm.originalRelation = res
                })
            }

            if (vm.positionRelationWfid) {
                promises.push(promise = dataOps.getObject(vm.positionRelationWfid))
                promise.then((res) => {
                    vm.positionRelation = res
                })
            }

            $q.all(promises).then(() => {
                if (!vm.item) {
                    vm.allLoaded = true
                    vm.header.itemId = ''
                    vm.header.headerText = 'Object not available'
                    return
                }

                if (vm.simplifiedLayout) {
                    initializeBody()
                }
                else {
                    initializeHeader(mainItem)
                    initializeBody()
                }
            })
        }

        //***********************************HEADER**************************************
        //*******************************************************************************

        function initializeHeader(item) {
            const objectType = getObjectTypes()
            const objectName = wfTranslate.instant('MAP_ObjectType', { type: item.type })

            vm.header.itemId = 'ID: ' + item.wfid //ID

            if (item.type == enums.objectType.structure) {
                vm.header.headerText = item.getHeaderText()
            }
            else {
                vm.header.headerText = item.getHeaderText() || objectName //HEADER TEXT
            }

            if (item.imageUrl != null) //HEADER IMAGE
                vm.header.imageUrl = item.getImageUrl()

            vm.header.part1 = item.text || item.formattedAddress || item.description || item.value || item.registrationNumber
            vm.header.part2 = item.url || item.period || item.guidance || item.fileType

            switch (item.type) {
                case objectType.question:
                    vm.header.headerText = $translate.instant('Question')
                    break
                case objectType.questionAnswer:
                    vm.header.headerText = $translate.instant('modules.objectViewer.answerToAQuestion') + ': ' + $sanitize(vm.header.headerText)
                    break
                case objectType.measure:
                    vm.header.headerText = $translate.instant('Measure')
                    vm.measure = true
                    break
                case objectType.relativeMeasure:
                    vm.header.headerText = $translate.instant('RelativeMeasure')
                    vm.measure = true
                    break
                case objectType.measureAnswer:
                    var measureText = $translate.instant('MeasureAnswer')
                    vm.header.headerText = $sanitize(measureText)
                    vm.header.part1 = $sanitize(item.getHeaderText()) + ' (' + $sanitize(item.childContent.name) + ')'
                    vm.header.part2 = $translate.instant('modules.objectViewer.forThePeriod') + ': ' + $sanitize(moment(item.period).format('YYYY'))
                    break
                case objectType.orgActivity:
                    vm.header.part2 = getWhenUntilDate(item)
                    break
                case objectType.orgDocument:
                    vm.header.headerText = $translate.instant('Document') + ': ' + $sanitize(vm.header.headerText)
                    vm.header.part1 = $translate.instant('File') + ' ' + $translate.instant('Type') + ': ' + $sanitize(item.fileType)
                    vm.header.part2 = getDocumentLink(item)
                    break
                case objectType.location:
                    vm.header.part1 = $translate.instant('Address') + ': ' + $sanitize(item.formattedAddress)
                    vm.header.part2 = '(Lat: ' + $sanitize(item.latitude) + ', Long: ' + $sanitize(item.longitude) + ')'
                    vm.header.imageUrl = getStaticGoogleImg(item)
                    break
                case objectType.statement:
                    vm.header.headerText = $translate.instant('Statement')
                    break
                case objectType.link:
                    break
                case objectType.embed:
                    vm.header.faviconUrl = item.faviconUrl
                    break
                default:
                    break
            }

            if (item.type == enums.objectType.individual) {
                vm.header.part1 = $translate.instant('InboxDescription')
            }

            if (item.guidance != null) vm.expand = true

            vm.header.additionalInformation = item.providerName || item.authorName || item.duration //HEADER BODY - ADDITIONAL INFORMATION
            vm.header.reportedCreated = moment(item.createdAt).format('YYYY-MM-DD') || moment(item.reportedBy).format('YYYY-MM-DD') //CREATED/REPORTED
            //vm.header.verifiedBy = item.verifiedBy; //VERIFIED BY

            if (vm.header.headerText && vm.header.headerText.length) {
                $rootScope.setPageTitle(vm.header.headerText)
            }

            // console.log("vm.HEADER :", vm.header);
        }

        //************************************BODY***************************************
        //*******************************************************************************
        function initializeBody(object) {
            if (vm.isUserInbox) {
                vm.boxes = _.filter(vm.boxes, { kind: enums.subItemsKind.childrenByUser })
            }

            if (vm.simplifiedLayout) {
                const objectMetadata = mainItem.metadata && mainItem.metadata.countByRelationKind ? mainItem.metadata.countByRelationKind : {} || {}

                vm.boxes = _.filter(vm.boxes, (box) => {
                    return !box.sideBar && objectMetadata[box.kind] > 0
                })
            }

            vm.boxes = _.filter(vm.boxes, (box) => {
                let output = !box.showForObjectTypes || _.includes(box.showForObjectTypes, mainItem.type)
                output = output && (!box.condition || box.condition())
                return output
            })

            _.remove(vm.boxes, (box) => {
                return box.hideForObjectTypes && _.includes(box.hideForObjectTypes, mainItem.type)
            })

            if (typeof mainItem.ancestorId !== 'undefined') {
                _.remove(vm.boxes, (box) => {
                    return box.id === 'eventLog'
                })
            }

            if (vm.boxes.length == 0) {
                vm.allLoaded = true
                if (typeof $scope.onLoaded === 'function') {
                    $scope.onLoaded()(vm)
                }
                $timeout()
            }

            _.forEach(vm.boxes, function(box, index) {
                const typeSpecific = _.find(box.typeSpecific, { type: mainItem.type })
                box.objViewerVm = vm
                getSubItems(box, typeSpecific).then(() => {
                    populateBoxItems(box)

                    if (typeSpecific) {
                        if (typeSpecific.title) box.title = typeSpecific.title

                        if (typeSpecific.pickerSettings) {
                            typeSpecific.pickerSettings.relationTarget.item = mainItem
                            typeSpecific.pickerSettings.relationTarget.kind = box.kind
                            if (box.byUser) box.pickerSettings = typeSpecific.pickerSettings
                        }
                        if (vm.isUserInbox && box.kind == enums.subItemsKind.childrenByUser) {
                            box.alwaysShow = true
                            box.emptyState = true
                            box.userInbox = true
                        }
                    }

                    doPostProcessingExtraStuff(box, mainItem).then(() => {
                        if (_.every(vm.boxes, { finishedLoading: true })) {
                            // $timeout(function() {
                            vm.boxes = _.chain(vm.boxes)
                                .filter((box) => {
                                    const typeSpecific = _.find(box.typeSpecific, { type: mainItem.type }) || {}
                                    // console.log(typeSpecific.alwaysShow)
                                    return box.items.length > 0 || (box.alwaysShow && (!typeSpecific || typeSpecific.alwaysShow !== false))
                                })
                                .sortBy('order')
                                .value()

                            vm.leftBoxes = _.filter(vm.boxes, { sideBar: false })

                            if (!vm.simplifiedLayout) vm.rightBoxes = _.filter(vm.boxes, { sideBar: true })

                            vm.allLoaded = true
                            if (typeof $scope.onLoaded === 'function') {
                                $scope.onLoaded()(vm)
                            }
                            $timeout()
                        }
                    })
                }, this)
            })

            // console.log("vm.BODY :", vm.boxes);
        }
        //*************************FUNCTIONS & CONFIGURATIONS****************************
        //*******************************************************************************

        function getSubItems(box, typeSpecific) {
            let customLoaderPromise
            box.currentItemTypeSettings = typeSpecific

            if (typeof box.itemsLoader === 'function') {
                customLoaderPromise = box.itemsLoader(mainItem)
                if (customLoaderPromise && customLoaderPromise.then) {
                    return customLoaderPromise
                }
            }

            return dataOps.getSubItems(mainItem, box.kind, {
                useOrganizationMatchModeFromKind: true,
                childrenLoadDepth: typeSpecific ? typeSpecific.loadDepth : undefined,
            }).then((res) => {
                // console.log(box.kind, res)

            })
        }

        function populateBoxItems(box) {
            const
                typeSpecific = _.find(box.typeSpecific, { type: mainItem.type })
				
            let query
				
            let customGetterResult
				
            let output

            if (typeof box.itemsGetter === 'function') {
                customGetterResult = box.itemsGetter(mainItem)

                if (typeof customGetterResult !== 'undefined') output = customGetterResult
            }

            if (!output) {
                output = _.chain(mainItem.getSubListOfKind(box.kind)).filter((item) => {
                    let itemContent; let metadata; let countByRelationKind
                    // if (item.relationType === 1)
                    // 	console.info("SKIPPING ITEM", "|", item.wfid, "|", item.getSubContentOfKind(box.kind).getMainTextual());

                    if (!(item.type === enums.objectType.dataRelation || (item.parentType === enums.objectType.relativeMeasure && item.type === enums.objectType.virtualDataRelation)) || item.relationType === 1) // item.relationType === 1 is Infrastructural
                        return

                    if (box.kind == 2) itemContent = item.getSubContentOfKind(enums.subItemsKind.parentsByUser) || item.getSubContentOfKind(box.kind)
                    else itemContent = item.getSubContentOfKind(box.kind)

                    if (vm.onlyShowItemsWithUserData
						&& !itemContent.isUserDataType()
						&& (metadata = itemContent.metadata)
						&& (countByRelationKind = metadata.countByRelationKind)
						&& countByRelationKind[enums.subItemsKind.childrenByUser] === 0
                    ) {
                        return
                    }

                    return itemContent
                }).orderBy(['createdAt'], ['desc']).value()

                if (vm.intersectionSettings && vm.intersectionSettings.contextParents && vm.intersectionSettings.contextParents.length
					&& _.includes([
					    enums.subItemsKind.childrenByUser,
					    enums.subItemsKind.relatedContentByUser,
					], box.kind))
                {
                    output = dataQuery.getIntersectedSubItems(vm.item, _.assign({
                        kind: box.kind,
                        sourceDataRelations: output,
                    }, vm.intersectionSettings))
                }
            }

            box.items.length = 0
            Array.prototype.push.apply(box.items, output)

            return box.items
        }

        function toggleItemsWithUserData() {
            vm.boxes = _.chain(vm.boxes)
                .each((box) => {
                    populateBoxItems(box)
                })
                .filter((box) => {
                    return box.items.length > 0 || box.alwaysShow
                })
                .sortBy('order')
                .value()

            vm.leftBoxes = _.filter(vm.boxes, { sideBar: false })

            if (!vm.simplifiedLayout) vm.rightBoxes = _.filter(vm.boxes, { sideBar: true })
        }

        function showHideText() {
            vm.expand = !vm.expand
        }

        function getStaticGoogleImg(item) {
            const googleKey = 'AIzaSyChfzQUypvsAJdjIdCtQIzAUP_P0GHIbiM'
            const longitude = item.longitude
            const latitude = item.latitude
            return 'https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=180x180&maptype=roadmap&markers=color:red%7Alabel:C%7C' + longitude + ',' + latitude + '&key=' + googleKey
        }

        function getWhenUntilDate(item) {
            const whenUntilText = $translate.instant('modules.objectViewer.whenUntilDate')
            const whenDate = moment($sanitize(item.whenDate)).format('YYYY-MM-DD')
            const untilDate = moment($sanitize(item.untilDate)).format('YYYY-MM-DD')
            return whenUntilText + ': ' + whenDate + ' / ' + untilDate
        }

        function getDocumentLink(item) {
            const url = item.url
            const icon = ' (<i class=\'fa fa-external-link\'></i> pdf)</span></a>'
            return '<a target=\'_blank\' href=\'' + $sanitize(url) + '\'><span class=\'filetype\'>' + $translate.instant('Open') + ' ' + $translate.instant('Document') + icon
        }

        function getObjectTypes() {
            return {
                structure: enums.objectType.structure,
                question: enums.objectType.question,
                questionAnswer: enums.objectType.questionAnswer,
                measure: enums.objectType.measure,
                relativeMeasure: enums.objectType.relativeMeasure,
                measureAnswer: enums.objectType.measureAnswer,
                orgActivity: enums.objectType.orgActivity,
                orgDocument: enums.objectType.orgDocument,
                location: enums.objectType.location,
                statement: enums.objectType.statement,
                link: enums.objectType.link,
                embed: enums.objectType.embed,
            }
        }

        function broadcastMeasureChange() {
            $scope.$broadcast('measureChangedInObjectViewer')
        }

        function getBoxes() {
            if (boxesDefinition) return boxesDefinition

            boxesDefinition = [
                {
                    byUser: false,
                    kind: enums.subItemsKind.parents,
                    order: 2,
                    title: $translate.instant('modules.objectViewer.parents'),
                    items: [],
                    alwaysShow: false,
                    emptyState: false,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    typeSpecific: [
                        { type: enums.objectType.question },
						 { type: enums.objectType.measure },
						 { type: enums.objectType.relativeMeasure },
						 { type: enums.objectType.measureAnswer },
						 { type: enums.objectType.orgDocument },
						 { type: enums.objectType.embed },
						 { type: enums.objectType.location },
						 { type: enums.objectType.orgActivity },
						 { type: enums.objectType.statement },
						 { type: enums.objectType.link },
						 { type: enums.objectType.structure },
                    ],
                },
                {
                    byUser: true,
                    kind: enums.subItemsKind.childrenByUser,
                    title: $translate.instant('modules.objectViewer.childrenByUser'),
                    order: 3,
                    items: [],
                    alwaysShow: false,
                    emptyState: false,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: false,
                    hideForObjectTypes: [enums.objectType.organization],
                    droppableOptions: undefined,
                    typeSpecific: [
                        {
                            type: enums.objectType.structure,
                            title: $translate.instant('modules.objectViewer.structureChildrenByUser'),
                            // pickerSettings: {
                            // 	hideListHeader: true,
                            // 	title: $translate.instant('AddTo'),
                            // 	relationTarget: {/* Set in activate func */ }
                            // }
                        },
                        {
                            type: enums.objectType.question,
                            title: $translate.instant('modules.objectViewer.childrenByUser'),
                            // pickerSettings: {
                            // 	hideListHeader: true,
                            // 	title: $translate.instant('AddTo'),
                            // 	relationTarget: {/* Set in activate func */ }
                            // objectType: {enums.objectType.answers whatever}//something like that
                            // }
                        },
                        {
                            type: enums.objectType.measure,
                            title: $translate.instant('MeasureAnswers'),
                            showChart: true,
                            // pickerSettings: {
                            // 	hideListHeader: true,
                            // 	title: $translate.instant('AddTo'),
                            // 	relationTarget: {/* Set in activate func */ }
                            // }
                            addButtonAction(box, mainItem) {
                                modal.openMeasureAnswerCreator(box.objViewerVm.originalRelation, mainItem).then((res) => {
                                    if (res) {
                                        populateBoxItems(box)
                                        broadcastMeasureChange()
                                        $timeout()
                                    }
                                })
                            },
                        },
                        {
                            type: enums.objectType.individual,
                            title: $translate.instant('InboxTitle'),
                            userInbox: true,
                            alwaysShow: true,
                            emptyState: true,
                        },
                        {
                            type: enums.objectType.organization,
                            title: $translate.instant('modules.objectViewer.requestsForInformation'),
                            allowExpanding: false,
                            blockItemTools: true,
                            blockItemDropdown: true,
                            alwaysShow: true,
                            emptyState: true,
                            // pickerSettings: {
                            // 	hideListHeader: true,
                            // 	title: $translate.instant('AddTo'),
                            // 	relationTarget: {/* Set in activate func */ }
                            // }
                        },
                    ],
                },
                {
                    byUser: true,
                    kind: enums.subItemsKind.relatedContentByUser,
                    showAttachInfoDropdown: true,
                    order: 2,
                    title: $translate.instant('modules.objectViewer.attachedInformation'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: false,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: true,
                    droppableOptions() {
                        // function is used here because of the box = this;
                        // At the end of getBoxes() the loop goes through each box and runs this function if droppableOptions is a function
                        const box = this
                        return {
                            singleFile: false,
                            disableOsFileDrop: false,
                            disableWfItemDrop: true,
                            elementToStyle: 'div.box-body',
                            objectCreation: {
                                objectType: enums.objectType.orgDocument,
                                dataRelationOptions: {
                                    kind: enums.subItemsKind.relatedContentByUser,
                                    item1: mainItem,
                                },
                                afterDropCallback() {
                                    populateBoxItems(box)
                                    $scope.$broadcast('dropdownActionExecuted', 'attach', mainItem)
                                    $timeout()
                                },
                            },
                        }
                    },
                    typeSpecific: [
                        {
                            type: enums.objectType.structure,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.questionAnswer,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.question,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.measure,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.measureAnswer,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.orgDocument,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.orgActivity,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.location,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                create: false, sourceItem: '73-347315',
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.statement,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                create: false, sourceItem: '73-347315',
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.link,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                create: false, sourceItem: '73-347315',
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.embed,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                create: false, sourceItem: '73-347315',
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                        {
                            type: enums.objectType.organization,
                            pickerSettings: {
                                hideListHeader: true,
                                title: $translate.instant('AddTo'),
                                create: false, sourceItem: '73-347315',
                                relationTarget: {/* Set in activate func */ },
                            },
                        },
                    ],
                },
                {
                    byUser: false,
                    kind: enums.subItemsKind.children,
                    order: 3,
                    title: $translate.instant('modules.objectViewer.children'),
                    items: [],
                    alwaysShow: false,
                    emptyState: false,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: true,
                    hideForObjectTypes: [enums.objectType.organization],
                    droppableOptions: undefined,
                    typeSpecific: [
                        {
                            type: enums.objectType.structure,
                        },
                        {
                            type: enums.objectType.question,
                        },
                        {
                            type: enums.objectType.measure,
                        },
                        {
                            type: enums.objectType.relativeMeasure,
                            showChart: true,
                        },
                    ],
                },
                {
                    byUser: false,
                    kind: enums.subItemsKind.relatedContent,
                    order: 4,
                    title: $translate.instant('modules.objectViewer.relatedContent'),
                    items: [],
                    alwaysShow: false,
                    emptyState: false,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: true,
                    droppableOptions: undefined,
                    typeSpecific: [
                        {
                            type: enums.objectType.structure,
                        },
                    ],
                },
                {
                    byUser: true,
                    kind: enums.subItemsKind.parentsByUser,
                    order: 1,
                    title: $translate.instant('modules.objectViewer.parentsByUser'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: false,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    hideForObjectTypes: [enums.objectType.organization, enums.objectType.productionSite, enums.objectType.productService],
                    addButtonIconClass: 'fa fa-pencil smaller-icon',
                    addButtonAction: vm.item.isUserDataType() ? undefined : function (mainItem) {
                        const box = this
                        modal.addTo(vm.item).closed(() => {
                            populateBoxItems(box)
                        })
                    },
                },
                {
                    // Users on organization
                    byUser: false,
                    kind: enums.subItemsKind.usersOnOrg,
                    order: 2,
                    title: $translate.instant('Users'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: false,
                    finishedLoading: false,
                    answersLoaded: false,
                    hasSearchBar: true,
                    droppableOptions: undefined,
                    showForObjectTypes: [enums.objectType.organization],
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/addUser1.png',
                        description: $translate.instant('modules.valueChain.contactPersons.emptyStateHeader'),
                    },
                    condition() {
                        return !!vm.networkId
                    },
                    // addButtonIconClass: "ionicons ion-edit",
                    itemsLoader(mainItem) {
                        return dataOps.getObjects({
                            requestSignature_noResultNeeded: ['objectViewer', mainItem.wfid, 'users'].join('_'),
                            objectType: enums.objectType.individual,
                            getterConditions: {
                                networkId: vm.networkId,
                                organizationId: mainItem.id,
                            },
                            // usersCondition: 1
                        })
                    },
                    itemsGetter(mainItem) {
                        const userRelations = wfObject.filter({ where: {
                            childType: enums.objectType.individual,
                            parentType: enums.objectType.organization,
                            wffid: mainItem.wfid,
                        } })
                        const usersById = _.keyBy(wfObject.filter({ where: { type: enums.objectType.individual } }), 'id')

                        const userWfidsInNetwork = _.chain(wfObject.filter({ where: {
                            type: enums.objectType.dataRelation,
                            childType: enums.objectType.individual,
                            wffid: { in: ['52-' + vm.networkId, '52-1'] },
                        } })).map('wfcid').uniq().keyBy().value()

                        const usersInNetworkDataRelations = _.chain(userRelations).filter((userDataRelation) => {
                            return userDataRelation.wfcid in userWfidsInNetwork
                        }).value()
                        return usersInNetworkDataRelations
                    },
                    addButtonAction(mainItem) {
                        const box = this
                        valueChainService.openOrganizationUsersManager(mainItem, vm.networkId).modal.closed.then(() => {
                            populateBoxItems(box)
                            $timeout()
                        })
                    },
                },
                {
                    // ParentsByUser for organization and productionSites only, intersects on categories in current network if available
                    id: 'parentsByUser_forItemWithinNetwork',
                    byUser: false,
                    kind: enums.subItemsKind.parentsByUser,
                    order: 2,
                    title: $translate.instant('modules.objectViewer.parentsByUser'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    finishedLoading: false,
                    showMetadata: true,
                    metadataItemSource: 'dataRelation', // Passed into wfItemComponent from objectViewerItem.component.js to set what item the wfMetadata directive will use
                    hideMetadataDetailedViewLink: true,
                    showForObjectTypes: [enums.objectType.productionSite, enums.objectType.organization, enums.objectType.productService],
                    // blockItemDropdown: true,
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/folder.png',
                        description: $translate.instant('modules.valueChain.organizationCategories.emptyStateHeader'),
                    },
                    // attachInformationButton: "dataRelation",
                    itemDropdownActions: [
                        'deleteRelation',
                        {
                            text: 'AttachInformation', icon: 'fa fa-paperclip', subActions: [
                                { text: 'Activity', icon: 'fa fa-calendar-check-o', action: 'dropdownVm.attach(\'activity\', dropdownVm.dataRelation)' },
                                { text: 'Document', icon: 'fa fa-file', action: 'dropdownVm.attach(\'document\', dropdownVm.dataRelation)' },
                                { text: 'Statement', icon: 'fa fa-quote-right', action: 'dropdownVm.attach(\'statement\', dropdownVm.dataRelation)' },
                                { text: 'Link', icon: 'fa fa-link', action: 'dropdownVm.attach(\'link\', dropdownVm.dataRelation)' },
                                { text: 'Embed', icon: 'fa fa-play-circle', action: 'dropdownVm.attach(\'embed\', dropdownVm.dataRelation)' },
                            ],
                        },
                    ],
                    condition() {
                        return !!vm.networkId
                    },
                    itemsLoader(mainItem) {
                        return $q.all([
                            dataOps.getSubItems(mainItem, enums.subItemsKind.parentsByUser, { includeDataRelationsMetadataCount: true, bypassCache: true }),
                            valueChainService.loadCategoriesInNetwork(vm.networkId),
                        ])
                    },
                    itemsGetter(mainItem) {
                        if (vm.networkId && (mainItem.type === enums.objectType.organization || mainItem.type === enums.objectType.productionSite || mainItem.type === enums.objectType.productService)) {
                            return (function () {
                                const
                                    output = []
									
                                let parentsFromNetworkCategories
									
                                let parentsIdsFromNetworkCategories
									
                                let parents
									
                                let otherParents
									
                                let structureParents
									
                                const organizationId = wfAuth.getOrganizationId()

                                parents = mainItem.getSubListOfKind(enums.subItemsKind.parentsByUser)
                                structureParents = _.filter(parents, { parentType: enums.objectType.structure })
                                otherParents = _.difference(parents, structureParents)

                                parentsFromNetworkCategories = valueChainService.getCategoriesInNetwork(vm.networkId)
                                parentsIdsFromNetworkCategories = _.map(parentsFromNetworkCategories, 'wfcid')

                                structureParents = _.filter(structureParents, (item) => {
                                    return _.includes(parentsIdsFromNetworkCategories, item.wffid) && item.organizationId == organizationId
                                })

                                // Array.prototype.push.apply(output, otherParents);
                                Array.prototype.push.apply(output, structureParents)

                                return output
                            })(this)
                        }
                    },
                    addButtonAction: !vm.networkId ? undefined : function (mainItem) {
                        const box = this
                        valueChainService.openOrganizationCategoriesPicker(mainItem, vm.networkId).closed(() => {
                            populateBoxItems(box)
                            $timeout()
                        })
                    },
                },
                {
                    // Historic ParentsByUser for organization and productionSites only, intersects on categories in current network if available
                    id: 'historicParentsByUser_forItemWithinNetwork',
                    byUser: false,
                    kind: enums.subItemsKind.parentsByUser,
                    order: 3,
                    title: $translate.instant('modules.objectViewer.parentsByUser_historic'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    finishedLoading: false,
                    showMetadata: true,
                    metadataItemSource: 'dataRelation', // Passed into wfItemComponent from objectViewerItem.component.js to set what item the wfMetadata directive will use
                    hideMetadataDetailedViewLink: true,
                    metadataOptions: {
                        hidePopoverAddAction: true,
                        popoverItemSettings: {
                            showDropdown: false,
                            metadataConfig: {
                                limitRelationKinds: [],
                                showDetailedViewLink: false,
                            },
                        },
                    },
                    showForObjectTypes: [enums.objectType.productionSite, enums.objectType.organization, enums.objectType.productService],
                    blockItemDropdown: true,
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/folder.png',
                        description: $translate.instant('modules.valueChain.organizationCategories.emptyStateHeader'),
                    },
                    condition() {
                        return !!vm.networkId
                    },
                    itemsLoader(mainItem) {
                        return $q.all([
                            dataOps.getSubItems(mainItem, enums.subItemsKind.parentsByUser, { useDeletedDataRelations: true, includeDataRelationsMetadataCount: true, bypassCache: true }),
                            valueChainService.loadCategoriesInNetwork(vm.networkId),
                        ])
                    },
                    itemsGetter(mainItem) {
                        if (vm.networkId && (mainItem.type === enums.objectType.organization || mainItem.type === enums.objectType.productionSite || mainItem.type === enums.objectType.productService)) {
                            return (function () {
                                const
                                    output = []
									
                                let parentsFromNetworkCategories
									
                                let parentsIdsFromNetworkCategories
									
                                let parents
									
                                let otherParents
									
                                let structureParents
									
                                const organizationId = wfAuth.getOrganizationId()

                                parents = wfObject.filter({ where: { type: enums.objectType.historicDataRelation, wfcid: mainItem.wfid, parentData1: null } })
                                structureParents = _.filter(parents, { parentType: enums.objectType.structure })
                                otherParents = _.difference(parents, structureParents)

                                parentsFromNetworkCategories = valueChainService.getCategoriesInNetwork(vm.networkId)
                                parentsIdsFromNetworkCategories = _.map(parentsFromNetworkCategories, 'wfcid')

                                structureParents = _.filter(structureParents, (item) => {
                                    return _.includes(parentsIdsFromNetworkCategories, item.wffid) && item.organizationId == organizationId
                                })

                                // Array.prototype.push.apply(output, otherParents);
                                Array.prototype.push.apply(output, structureParents)

                                return output
                            })(this)
                        }
                    },
                },
                {
                    // Notifications for organizations only
                    byUser: false,
                    kind: undefined,
                    order: 10,
                    title: $translate.instant('Notifications'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: true,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    finishedLoading: false,
                    showForObjectTypes: [enums.objectType.organization],
                    displayAs: 'notification',
                    blockItemDropdown: true,
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/bell.png',
                        description: $translate.instant('modules.valueChain.notifications.emptyStateHeader'),
                    },
                    itemsLoader(mainItem) {
                        const box = this

                        return dataOps.getObjects({
                            objectType: enums.objectType.notification,
                            organizationIds: [mainItem.id],
                        }).then((res) => {
                            box.requestResult = res
                        })
                    },
                    itemsGetter(mainItem) {
                        const filteredNotifications = _.map(this.requestResult, 'childContent')
                        return filteredNotifications
                    },
                    // itemTemplateHtml: "<wf-notification></wf-notification>"
                },
                {
                    // Influences to organization
                    byUser: true,
                    kind: enums.subItemsKind.childrenByUser,
                    title: $translate.instant('modules.objectViewer.requestsForInformation'),
                    order: 3,
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: false,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: false,
                    hasSearchBar: true,
                    droppableOptions: undefined,
                    finishedLoading: false,
                    showMetadata: false,
                    showForObjectTypes: [enums.objectType.organization],
                    blockItemDropdown: true,
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/folder.png',
                        description: $translate.instant('modules.valueChain.influence.emptyStateHeader'),
                    },
                    condition() {
                        return !!vm.networkId
                    },
                    itemsLoader(mainItem) {
                        return dataOps.getObjects({
                            requestSignature_noResultNeeded: ['objectViewer', mainItem.wfid, 'influences'].join('_'),
                            objectType: enums.objectType.influence,
                            wrapInRelations: true,
                            getterConditions: {
                                organizationIds: [mainItem.id],
                                includeOrganizations: false,
                            },
                        })
                    },
                    itemsGetter(mainItem) {
                        const query = { where: { type: enums.objectType.influence, creatorOrganizationId: wfAuth.getOrganizationId(), organizationId: mainItem.id } }

                        if (vm.networkId) query.where.channelId = vm.networkId

                        return wfObject.filter(query)
                    },
                    addButtonAction: !vm.networkId ? undefined : function (mainItem) {
                        const box = this
                        valueChainService.openInfluenceCreator({
                            organization: mainItem,
                            networkId: vm.networkId,
                        }).modal.closed.then(() => {
                            populateBoxItems(box)
                            $timeout()
                        })
                    },
                    itemClickAction($event, $scope, ovItemVm, item) {
                        valueChainService.openInfluence(item).modal.closed.then(() => {
                            let element

                            if (ovItemVm.itemComponentControl && ovItemVm.itemComponentControl.$scope) {
                                element = ovItemVm.itemComponentControl.$element.find('div.influenceStatus')
                                $compile(element.contents())(ovItemVm.itemComponentControl.$scope)
                                $timeout()
                            }
                        })
                    },
                },
                {
                    // Log events
                    id: 'eventLog',
                    byUser: false,
                    kind: undefined,
                    order: 10,
                    title: $translate.instant('EventLog'),
                    items: [],
                    alwaysShow: true,
                    emptyState: true,
                    sideBar: true,
                    toggleExpanded: false,
                    isLoading: false,
                    allowExpanding: false,
                    hasSearchBar: false,
                    droppableOptions: undefined,
                    finishedLoading: false,
                    // hideForObjectTypes: [ enums.objectType.individual, enums.objectType.organization ],
                    // showForObjectTypes: [ enums.objectType.organization ],
                    displayAs: 'notification',
                    emptyStateConfig: {
                        imageUrl: 'assets/img/icons/bell.png',
                        description: $translate.instant('modules.notifications.eventLog.emptyStateHeader'),
                    },
                    hideForObjectTypes: [enums.objectType.individual],
                    blockItemDropdown: true,
                    itemsLoader(mainItem) {
                        const box = this

                        return dataOps.getObjects({
                            requestSignature_noResultNeeded: ['objectViewer', mainItem.wfid, 'logEvents'].join('_'),
                            objectType: enums.objectType.logEvent,
                            wfids: [mainItem.wfid],
                        }).then((res) => {
                            box.requestResult = res
                        })
                    },
                    itemsGetter(mainItem) {
                        return _.chain(this.requestResult || [])
                            .map('childContent')
                            .filter((item) => {
                                return item.type === enums.objectType.logEvent && typeof item !== 'undefined'
                            }).orderBy(['createdAt'], ['desc']).value()
                    },
                    // itemTemplateHtml: "<wf-notification></wf-notification>"
                },
            ]

            _.each(boxesDefinition, (box) => {
                if (typeof box.droppableOptions === 'function') {
                    box.droppableOptions = box.droppableOptions()
                }
            })

            return boxesDefinition

        }

        function getLogEvents(item) {
        }

        function doPostProcessingExtraStuff(box, item) {
            const promises = []; const innerPromises = []
            const jqDf = $.Deferred()
            const questionsDataRelations = _.filter(box.items, { childType: enums.objectType.question })
            const measuresDataRelations = _.filter(box.items, { childType: enums.objectType.measure })
            const relativeMeasuresDataRelations = _.filter(box.items, { childType: enums.objectType.relativeMeasure })

            if (box.items.length > 0 && box.kind == enums.subItemsKind.children && (_.every(box.items, { childType: enums.objectType.question }) || _.every(box.items, { parentType: enums.objectType.question }))) {
                box.title = $translate.instant('Questions')
                vm.listOfQuestions = true
            }

            if (box.items.length > 0 && box.kind == enums.subItemsKind.children && (_.every(box.items, { childType: enums.objectType.measure }) || _.every(box.items, { parentType: enums.objectType.measure }))) {
                box.title = $translate.instant('Measures')
            }

            if (box.items.length > 0 && box.kind == enums.subItemsKind.children && (_.every(box.items, { childType: enums.objectType.relativeMeasure }) || _.every(box.items, { parentType: enums.objectType.relativeMeasure }))) {
                box.hasSearchBar = false
                box.title = $translate.instant('RelativeMeasures')
            }

            if (item.type === enums.objectType.questionAnswer || item.type === enums.objectType.measureAnswer) {
                if (box.kind == enums.subItemsKind.parentsByUser && (_.every(box.items, { childType: enums.objectType.question }) || _.every(box.items, { parentType: enums.objectType.question }))) {
                    box.title = $translate.instant('Question')
                }
                if (box.kind == enums.subItemsKind.parentsByUser && (_.every(box.items, { childType: enums.objectType.measure }) || _.every(box.items, { parentType: enums.objectType.measure }))) {
                    box.title = $translate.instant('Measure')
                }
            }

            if (questionsDataRelations.length != 0) {
                // dataOps.getSubItemsOfAll(_.map(questionsDataRelations, "childContent"), enums.subItemsKind.childrenByUser).then(function () {
                // 	console.log("answers loaded", questionsDataRelations);
                // 	box.finishedLoading = true;
                // 	jqDf.resolve();
                // });
                _.forEach(_.map(questionsDataRelations, 'childContent'), (element) => {
                    innerPromises.push(dataOps.getSubItems(element, enums.subItemsKind.children))
                })
                promises.push($q.all(innerPromises))
            }
            //Replace with dataOps.getSubItemsOfAll()
            if (measuresDataRelations.length != 0) {
                _.forEach(_.map(measuresDataRelations, 'childContent'), (element) => {
                    innerPromises.push(dataOps.getSubItems(element, enums.subItemsKind.children))
                })
                promises.push($q.all(innerPromises))
            }

            //Replace with dataOps.getSubItemsOfAll()
            if (relativeMeasuresDataRelations.length != 0) {
                _.forEach(_.map(relativeMeasuresDataRelations, 'childContent'), (element) => {
                    innerPromises.push(dataOps.getSubItems(element, enums.subItemsKind.children))
                })
                promises.push($q.all(innerPromises))
            }

            if (promises.length > 0) {
                $q.all(promises).then(() => {
                    box.finishedLoading = true
                    jqDf.resolve()
                })
            }
            else {
                box.finishedLoading = true
                jqDf.resolve()
            }

            return jqDf.promise()
        }

        function repopulateBoxes() {
            _.each(vm.boxes, (box) => {
                populateBoxItems(box)
            })
            vm.broadcastMeasureChange()
            $timeout()
        }
    }
})()
