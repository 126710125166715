(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfEmbedPlayer', wfEmbedPlayer)
    wfEmbedPlayer.$inject = ['$parse']
    function wfEmbedPlayer($parse) {

        const directive = {
            restrict: 'E',
            templateUrl: 'scripts/wf/embed/wfEmbedPlayer.directive.html',
            link,
        }

        return directive

        function link($scope, $element, $attrs) {
            let
                itemContent
				
            let mediaContent

            if ('itemContent' in $attrs) {
                itemContent = $parse($attrs.itemContent)($scope)
            }
            else {
                itemContent = $scope.item.childContent
            }

            $element.addClass('embed-player')
            $scope.embedPlayerVm = {
                playMedia,
                playerPaddingTop: ((1.0 / itemContent.aspectRatio) * 100) + '%',
                playerBackgroundThumbnail: itemContent.thumbnailUrl,
            }
			
            function playMedia() {
                const
                    html = itemContent.html
					
                let src; let iframe
				
                $scope.embedPlayerVm.playMedia = null

                mediaContent = $(html)

                if (mediaContent.length === 1 && mediaContent.first()[0].tagName.toLowerCase() === 'iframe') {
                    iframe = mediaContent.first()
                    // iframe.attr("src", iframe.attr("src").replace('autoplay=0', 'autoplay=1'));
                    // iframe.attr("src", iframe.attr("src").replace('autoplay%3D0', 'autoplay%3D1'));
                    iframe.attr('src', iframe.attr('src') + '&autoplay=1')
					
                    $element.children().addClass('loading').append(mediaContent)
                }
            }
        }
    }
})()
