//@flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SearchBar from '@worldfavor/components/SearchBar'
import DataExplorerSourceMenu from '@worldfavor/components/DataExplorer/DataExplorerSourceMenu'
import { injectIntl } from 'react-intl'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        width: '100%',
    },
    menuButton: {
        minWidth: '20%',
        borderTopLeftRadius: 50,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 0,
    },
    menuButtonText: {
        paddingLeft: 20,
    },
    menuIcon: {},
    searchBar: {
        minWidth: '80%',
    },
    onFocusSearchBarBorder: {},
    roundedSearchBar: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 50,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 50,
    },
})

type Props = {
  value: string,
  selectedMenuOption: string,
  menuOptions: Array<Object>,
  onSearchChange: () => void,
  onMenuChange: () => void,
  onClear: () => void,
  isLoading: boolean,
}

const SearchBarWithMenu = (props: Props) => {
    const { value, onSearchChange, onClear, menuOptions, onMenuChange, selectedMenuOption, intl, isLoading } = props
    const classes = useStyles(props)
    return (
        <div className={classes.root}>
            <DataExplorerSourceMenu
                menuOptions={menuOptions}
                onChange={onMenuChange}
                classes={{
                    menuButton: classes.menuButton,
                    menuButtonText: classes.menuButtonText,
                    menuIcon: classes.menuIcon,
                }}
            />
            <SearchBar
                onClear={onClear}
                isLoading={isLoading}
                rounded
                showLoader
                inputProps={{
                    placeholder: intl.formatMessage({ id: 'general.search' }) + ` ${selectedMenuOption.label.toLowerCase()}`,
                    onChange: onSearchChange,
                    value,
                }}
                classes={{
                    root: classes.searchBar,
                    rounded: classes.roundedSearchBar,
                    onFocusBorder: classes.onFocusSearchBarBorder,
                }}
            />
        </div>
    )
}

export default injectIntl(SearchBarWithMenu)
