// @flow
import * as React from 'react'
import AnswerValue from './AnswerValue'

import '@worldfavor/less/components/question-container.less'

type Props = {
  values: Array<{
    type: string,
    value?: number,
    title?: string,
    subTitle?: string,
    valueColor: string,
  }>,
  forceShowPercentage?: boolean,
}

const ItemStatistics = (props: Props) => {
    const { values, forceShowPercentage, valueColor } = props
    const valueFontSize = values.length > 1 ? (values.length > 2 ? '40px' : '45px') : '50px'
    const style = valueColor ? { color: valueColor, fontSize: valueFontSize  } : {}
    return (
        <div styleName="summary-statistics">
            {
                values.map(({ value, title, subTitle, type }, index) => (
                    <AnswerValue
                        key={`item-stats-${index}`}
                        {...{ value, title, subTitle, type, style }}
                        forceShowPercentage={forceShowPercentage}
                    />
                ))
            }
        </div>
    )
}

ItemStatistics.defaultProps = {
    values: [],
}

export default ItemStatistics
