import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfInlinePicker', {
            templateUrl: 'scripts/wf/picker/wfInlinePicker.component.html',
            controller: wfInlinePickerController,
            controllerAs: 'vm',
            require: {
                wfWorkItem: '?^^wfWorkItem',
            },
            bindings: {
                pickerSettings: '<',
                appendPopoverToBody: '<',
                hideFilters: '<',
                answerModule: '<',
            },
        })

    wfInlinePickerController.$inject = ['$element', '$scope', '$compile', 'wfUtilityService', 'pickerService', '$timeout', 'Picker', '$translate', 'wfTranslate', '$q']
    function wfInlinePickerController($element, $scope, $compile, wfUtilityService, pickerService, $timeout, Picker, $translate, wfTranslate, $q) {
        const vm = this
        const popoverTemplateUrl = 'scripts/wf/picker/wfInlinePickerPopover.template.html'
        let inlinePickerWrapperElement
        let inputField
		
        _.assign(vm, {
            //Variables
            popoverInitiated: false,
            pickerContentLoaded: false,
            picker: undefined,
            inputFieldCompiler: {},
            filteringConfig: undefined,
            itemsPerPage: 50,
            filterOptionsSourceBase: undefined, // Comes from picker factory
            filterOptionsSource: undefined,
            selectedFilterId: 'all',
            pickerLabel: undefined,
            inputFieldPlaceholder: undefined,

            emptyState: {
                header: '',
                description: '',
            },
            emptyStateSearch: {
                header: '',
                description: '',
            },

            //Functions
            onSearch,
            showPopover,
        })

        vm.$onInit = function() {
            setInputFieldPlaceholder()
            wfUtilityService.loadTemplateUrlInCache(popoverTemplateUrl)
        }

        function setInputFieldPlaceholder() {
            const selectedAnswerType = vm.answerModule.type
            let itemText = undefined

            if (selectedAnswerType === enums.objectType.structure) itemText = $translate.instant('modules.picker.alternative')
            else itemText = wfTranslate.instant('MAP_ObjectType', { type: selectedAnswerType, IA: true })

            vm.inputFieldPlaceholder = $translate.instant('modules.picker.inputFieldPlaceholder', { itemText: itemText.toLowerCase() })

            if (selectedAnswerType === enums.objectType.structure) vm.pickerLabel = $translate.instant('modules.picker.chooseAlternatives')
            else vm.pickerLabel = wfTranslate.instant('MAP_ObjectType', { type: selectedAnswerType, plural: true })
        }

        function onSearch(searchString, items, searchActive) {
            if (searchActive) {
                vm.searchActive = true
                if (!vm.popoverVisible) showPopover()
            }

            if (searchString === '') vm.searchActive = false

            if (vm.filterOptionsSourceBase) {
                if (searchActive) {
                    // All code regarding vm.filterOptionsSource and vm.filterOptionsSourceBase have to be improved!!!
                    vm.filterOptionsSource = _.clone(vm.filterOptionsSourceBase).map(x => _.clone(x)).map((x) => {
                        x.items = _.intersection(items, x.items)
                        x.count = x.items.length
                        x.isSelected = vm.selectedFilterId === x.id

                        return x
                    })
                }
                else {
                    vm.filterOptionsSource = _.clone(vm.filterOptionsSourceBase).map(x => _.clone(x))
                    vm.filterOptionsSource.forEach(x => x.isSelected = vm.selectedFilterId === x.id)
                }
            }
        }

        function recompileInputField() {
            if (vm.inputFieldCompiler && typeof vm.inputFieldCompiler.compile === 'function') {
                vm.inputFieldCompiler.compile()
                vm.pickerContentLoaded = true

                $timeout(() => {
                    if (inputField) inputField.focus()
                }, 200)
            }
        }

        function showPopover() {
            vm.pickerContentLoaded = false

            initializePopover()

            if (!vm.popoverVisible && vm.popoverInitiated) {
                inlinePickerWrapperElement.popover('show')
                vm.popoverVisible = true
                inputField = $element.find('input.form-control')
				
                if (!vm.picker) {
                    let pickerSettings

                    if (vm.answerModule && vm.answerModule.type) {
                        pickerSettings = _.cloneDeep(vm.pickerSettings)
                        pickerSettings.objectType = vm.answerModule.type
                    }
                    else pickerSettings = _.cloneDeep(vm.pickerSettings)

                    if (vm.answerModule) {
                        pickerSettings.onToggled = function (relation, isSelected, _allSelectedItems) {
                            if (isSelected) {
                                vm.answerModule.itemComposite.addSubItem(relation, vm.answerModule.itemComposite.subItemsKind)
                            }
                            else {
                                vm.answerModule.itemComposite.removeSubItem(relation)
                            }

                            if (vm.wfWorkItem) {
                                vm.wfWorkItem.itemComposite.syncFulfillment()
                            }
                            allSelectedItems = _allSelectedItems
							
                            if (!vm.picker.sourceItemFirstLevelAsFilter && vm.filteringVm) {
                                vm.filteringVm.updateButtonBars()
                            }
                        }
                    }

                    let allSelectedItems = []
                    pickerSettings.onItemsSynced = function (_allSelectedItems, _filterOptionsSourceBase) {
                        allSelectedItems = _allSelectedItems

                        // All code regarding vm.filterOptionsSource and vm.filterOptionsSourceBase have to be improved!!!
                        if (_filterOptionsSourceBase) {
                            vm.filterOptionsSourceBase = _filterOptionsSourceBase
                            vm.filterOptionsSource = _.clone(vm.filterOptionsSourceBase).map(x => _.clone(x))
                            vm.filterOptionsSource.forEach(x => x.isSelected = vm.selectedFilterId === x.id)
                        }
                    }

                    vm.picker = new Picker(pickerSettings)

                    vm.filteringConfig = {
                        onFiltered(filteredItems, selectedOptions) {
                            if (selectedOptions.length === 0) {
                                vm.selectedFilterId = 'all'
                            }
                            else {
                                vm.selectedFilterId = selectedOptions[0].id
                            }
                        },
                        filters: [
                            {
                                displayAs: 'buttons',
                                single: true,
                                canClearAll: false,
                                canDeselectAll: false,
                                filterOptionsSource: vm.picker.sourceItemFirstLevelAsFilter ? () => {
                                    // All code regarding vm.filterOptionsSource and vm.filterOptionsSourceBase have to be improved!!!
                                    if (vm.filterOptionsSource) {
                                        const output = _.clone(vm.filterOptionsSource).map(x => _.clone(x))
                                        output.forEach(x => x.isSelected = vm.selectedFilterId === x.id)
                                        return output
                                    }
                                    else {
                                        return []
                                    }
                                }
                                    : () => {
                                        const searchedToggledItems = _.intersection(vm.searchResultItems, allSelectedItems)
                                        return [
                                            {
                                                id: 'all',
                                                title: $translate.instant('All'),
                                                count: vm.searchResultItems.length,
                                                items: vm.searchResultItems,
                                                isSelected: vm.selectedFilterId === 'all' || allSelectedItems.length === 0,
                                            }, allSelectedItems.length && {
                                                id: 'toggled',
                                                title: $translate.instant('Selected_Plural'),
                                                count: searchedToggledItems.length,
                                                items: searchedToggledItems,
                                                isSelected: vm.selectedFilterId === 'toggled',
                                            },
                                        ].filter(Boolean) },
                            },
                        ],
                    }
					
                    if (vm.answerModule && vm.answerModule.picker) vm.answerModule.picker.instance = vm.picker

                    vm.picker.loadItems().then((pickerObject) => {
                        initEmptyStateText().then(() => {
                            recompileInputField()
                        })
                    }, (rejectedObj) => {
                        console.error('Picker items could not be loaded', rejectedObj)
                    })
                }
                else {
                    vm.pickerContentLoaded = true
                }
				
                setTimeout(() => {
                    $(document).on('click.pickerPopoverEvent' + $scope.$id, (event) => {
                        if (vm.popoverVisible && $(event.target).closest('wf-inline-picker')[0] !== $element[0]) hidePopover()
                    })
                }, 100)
            }
            else {
                vm.pickerContentLoaded = true
            }

            $timeout()
        }

        function initEmptyStateText() {
            const promises = []

            if (vm.answerModule.type === enums.objectType.structure) {
                vm.emptyState.header = tryGettingDefaultMultilingual('modules.picker.customObjectType.emptyStateHeader')
                vm.emptyStateSearch.header = tryGettingDefaultMultilingual('modules.picker.customObjectType.emptyStateSearch')
            }
            else {
                promises.push(wfTranslate.tryInstant('modules.picker.objectTypes.' + vm.answerModule.type + '.emptyStateHeader', (text) => {
                    vm.emptyState.header = text || tryGettingDefaultMultilingual('modules.picker.customObjectType.emptyStateHeader')
                }))
				
                promises.push(wfTranslate.tryInstant('modules.picker.objectTypes.' + vm.answerModule.type + '.emptyStateDescription', (text) => {
                    vm.emptyState.description = text || tryGettingDefaultMultilingual('modules.picker.customObjectType.emptyStateDescription')
                }))

                promises.push(wfTranslate.tryInstant('modules.picker.objectTypes.' + vm.answerModule.type + '.emptyStateSearch', (text) => {
                    vm.emptyStateSearch.header = text || tryGettingDefaultMultilingual('modules.picker.customObjectType.emptyStateSearch')
                }))
            }
			
            function tryGettingDefaultMultilingual(key) {
                let
                    output
					
                const typeWording = vm.answerModule.type !== enums.objectType.structure && wfTranslate.instant('MAP_ObjectType', { type: vm.answerModule.type, plural: true })

                if (typeWording) {
                    output = $translate.instant(key, { information: typeWording.toLowerCase() })
                }
                else {
                    output = $translate.instant(key, { information: wfTranslate.instant('Alternatives').toLowerCase() })
                }

                return output
            }

            return $q.all(promises)
        }

        function hidePopover() {
            if (vm.popoverVisible) {
                vm.popoverVisible = false
                vm.pickerContentLoaded = false
                inlinePickerWrapperElement.popover('hide')
                $(document).off('click.pickerPopoverEvent' + $scope.$id)
            }
            $timeout()
        }

        function initializePopover() {
            let compiledElement

            inlinePickerWrapperElement = $element.find('div.inline-picker-wrapper')
            inlinePickerWrapperElement.attr('data-toggle', 'popover')
            inlinePickerWrapperElement.popover({
                delay: 0,
                animation: true,
                placement: 'bottom',
                container: vm.appendPopoverToBody ? 'body' : false,
                trigger: 'manual',
                template: '<div class="popover picker-popover"><div class="popover-content"></div></div>',
                html: true,
                selector: false,
                content() {
                    compiledElement = $compile(wfUtilityService.getTemplateFromCache(popoverTemplateUrl))($scope)
                    $timeout()

                    return compiledElement
                },
            })
            vm.popoverInitiated = true
        }
    }
})()
