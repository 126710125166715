import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .factory('DataNegotiator', DataNegotiatorFactory)

    DataNegotiatorFactory.$inject = ['$rootScope', 'apiProxy', 'dataQuery', 'wfPropertyExtractor', '$timeout', 'wfAuth', 'wfObject', 'dataOperationsService', 'tableDataExtractor', '$q', 'wfTranslate']
    function DataNegotiatorFactory($rootScope, apiProxy, dataQuery, wfPropertyExtractor, $timeout, wfAuth, wfObject, dataOps, tableDataExtractor, $q, wfTranslate) {
        const permissionsPrototype = {
            canCreate: true,
            canRead: false,
            canUpdate: true,
            canDelete: true,
        }

        _.assign(DataNegotiator.prototype, {
            fromItem: undefined, // (object) Parameters that decide what item to load. Can be a wfid or { id, type }
            ticket: undefined, // (object) Optional ticket that defines networkId, organizationId(s) and contextParentWfid
            loadDepth: 0, // (integer) How many levels of children should be loaded
            loaded: false, // (boolean)
            loadOnInit: true, // (boolean) If data should be loaded from server instantly when the negotiator has been instantiated
            pagingFunctionEnabled: false,
            useInfiniteScroll: false,
            useServerPagination: false,
            useRecursiveRelationsLoading: false,
            usePlaceholderLoaders: true,
            onlyLoadRelations: false,
            mainHierarchyKind: undefined, // The relation kind used for loading the main hierarchy
            additionalSubItemsKinds: undefined, // Any additional kinds that will also be loaded for each level
            isHierarchicalData: false,
            includeStatistics: true,
            onlyStatistics: false,
            splitUpOrganizationStatistics: undefined,
            splitUpRelativeMeasureSourcesStatistics: undefined,
            aggregateYearly: undefined,
            aggregatePeriodFrequencies: false,
            loadParents: undefined,
            loadMetadata: undefined,
            loadVisibilityTags: undefined,
            loadCreators: true,
            loadRequirements: undefined,
            convertMeasureAnswerUnits: undefined,
            periodSpan: undefined,
            excludeYears: undefined,
            includeMeasureTargets: undefined,
            dataRelationId: undefined,
            includeAvailablePeriods: undefined,
            allContentPreloaded: false, // When onlyLoadRelations is true but childContent is already loaded from before then this can be set to true and the content prop will have a value

            inject: undefined, // (boolean) If defined it will force the data to be injected or not
            injected: false, // (boolean) If the loaded result was injected in JSData
            isConsolidated: false, // (boolean) If consolidated is used, meaning that the ticket contains networkId and organizationIds
            isFiltered: false,

            item: undefined, // (object | wfObject) The raw item loaded from the server (not composite). Undefined until data has been loaded once.
            itemComposite: undefined, // (object) The item composite of the item loaded from the server
            itemConditions: undefined, // (object) Object for shorthand access to certain conditions on the loaded item

            hooks: undefined, // Hooks for customzing behavior of the negotiator of transforming data
            // Available hooks:
            // beforeItemCompositesCreation - Callback that gets passed the loaded relations just before ItemComposites are created.
            //                                If it returns an array that array will be used to generate the ItemComposites.
            //

            // Arrays
            items: undefined, // (array) At first it creates fake (dummy) items to show the Placeholders, once the items are loaded from the server it replaces them with item composites.
			                  //         When used with wfFiltering (frontend mode) this array is the result of the selected filtering
            rawItems: undefined, // (array) When used with wfFiltering (frontend mode) this array is the unfiltered array used for searching/filtering
            viewItems: undefined, // (array) If infinite scroll is active in the frontend this is the final array used in the template
            showItemPlaceholders: false, // (bool) If all items should be displayed as placeholders
            permissions: _.clone(permissionsPrototype),
            listInterfaceConfig: undefined, // Combined settings/conditions on the item

            _callbacks: undefined, // (object) Added callbacks for the xhrRequest per callback name ('always', 'done', 'fail' and 'then')
            lastCallbackResults: undefined, // (object) The last result loaded from the server per callback name
            onRequest: undefined, // (object) Object for adding callback functions to future AJAX request. If a request has already finished, the callback will be immediately invoked using lastCallbackResults.
            xhrRequest: undefined, // (object) The last or ongoing request

            // Paging settings
            pageSize: 20,

            // Getters
            hasAnyItems() {
                const output = !!(this.loaded && this.rawItems && this.rawItems.length !== 0)
                return output
            },
            hasAnyFilteredItems() {
                const output = !!(this.loaded && this.items && this.items.length !== 0)
                return output
            },

            // Functions
            loadItemsFromServer, // (function) Aborts any ongoing request and starts a new request
            addItem, // (function) Takes a relation object, creates an item composite from it and pushes it into the items array
            removeItem, // (function) Removes an item from the items array
            infiniteScrollPagingFunction,
            onFiltered,
            loadContentOnItems,
            abortOngoingXhrRequests() {
                if (this.xhrRequest && !this.xhrRequest.status && this.xhrRequest.abort) {
                    // console.log("abort loading");
                    this.xhrRequest.abort()
                    this.xhrRequest = undefined
                }
                if (this.contextLoadingXhrRequest && !this.contextLoadingXhrRequest.status && this.contextLoadingXhrRequest.abort) {
                    // console.log("abort loading");
                    this.contextLoadingXhrRequest.abort()
                    this.contextLoadingXhrRequest = undefined
                }
            },
            getTableData() {
                let options; let contextParentType

                if (this.tableData) return this.tableData

                options = {
                    includeCreatorOrganization: true,
                    includeContextParent: !!this.ticket.contextParentType || (this.ticket.contextParentWfids && this.ticket.contextParentWfids.length),
                    includeCreatorUser: true,
                    includeAttachedData: true,
                    ticket: this.ticket,
                }

                if (options.includeContextParent) {
                    if (this.ticket.contextParentType) contextParentType = this.ticket.contextParentType
                    else contextParentType = parseInt(this.ticket.contextParentWfids[0].split('-'))

                    options.contextParentHeader = wfTranslate.instant('MAP_ObjectType', { type: contextParentType })
                }

                return $q((resolve, reject) => {
                    tableDataExtractor.getDataFromItemComposites(this.items, options).then((output) => {
                        this.tableData = output
                        resolve(output)
                    })
                })

                // return this.tableData = output;
            },
            getTableData_onlyLatestAnswers() {
                if (this.tableData_onlyLatestAnswers) return this.tableData_onlyLatestAnswers

                const includeContextParent = !!this.ticket.contextParentType || (this.ticket.contextParentWfids && !!this.ticket.contextParentWfids.length)
                let contextParentType
                let isInternalPackage = false

                if (includeContextParent) {
                    if (this.ticket.contextParentType) {
                        contextParentType = this.ticket.contextParentType
                    }
                    else {
                        contextParentType = parseInt(this.ticket.contextParentWfids[0].split('-'))
                    }

                    const ticketOrgId = this.ticket.organizationId || (this.ticket.organizationIds && this.ticket.organizationIds.length === 1 && this.ticket.organizationIds[0])

                    if (contextParentType === enums.objectType.organization && ticketOrgId === wfAuth.getOrganizationId()) {
                        isInternalPackage = true
                    }
                }

                return $q((resolve, reject) => {
                    tableDataExtractor.getDataFromItemComposites(this.items, {
                        includeCreatorOrganization: true,
                        includeContextParent,
                        includeCreatorUser: true,
                        onlyLatestData: true,
                        includeAttachedData: true,
                        ticket: this.ticket,
                        isInternalPackage,
                    }).then((output) => {
                        this.tableData_onlyLatestAnswers = output
                        resolve(output)
                    })
                })
            },
            getColumnDefinitions() {
                let output; let options; let contextParentType

                options = {
                    includeCreatorOrganization: true,
                    includeContextParent: !!this.ticket.contextParentType || (this.ticket.contextParentWfids && !!this.ticket.contextParentWfids.length),
                    includeCreatorUser: true,
                    includeAttachedData: true,
                    ticket: this.ticket,
                }

                if (options.includeContextParent) {
                    if (this.ticket.contextParentType) contextParentType = this.ticket.contextParentType
                    else contextParentType = parseInt(this.ticket.contextParentWfids[0].split('-'))

                    options.contextParentHeader = wfTranslate.instant('MAP_ObjectType', { type: contextParentType })
                }

                output = tableDataExtractor.getColumnDefinitionsFromItemComposites(this.items, options)

                return output
            },
            getTableExportMapping() {
                const columnDefinitions = this.getColumnDefinitions()
                let output

                if (this.tableExportMapping) return this.tableExportMapping

                output = _.map(columnDefinitions, (columnDef) => {
                    const columnOutput = {
                        include: true,
                        header: columnDef.name || columnDef.field,
                        source: columnDef.field,
                    }

                    return columnOutput
                })

                output = _.keyBy(output, 'source')

                return this.tableExportMapping = output
            },
            toggleItemRelations() { // Put on itemComposite prototype
                // TODO: Add/remove itemComposites to/from arrays negotiator.items and negotiator.viewItems array at correct position
            },

        })

        DataNegotiator.instantiate = instantiate

        return DataNegotiator

        function DataNegotiator(options) {
            const
                self = this
				
            const callbacks = {
                done: [],
                fail: [],
                always: [],
                then: [],
            }
				
            const lastCallbackResults = {}
				
            const authOrgId = wfAuth.getOrganizationId()

            _.assign(this, options)

            _.assign(this, {
                _callbacks: callbacks,
                items: self.usePlaceholderLoaders ? _.times(6, () => { return { isPlaceholder: true } }) : [],
                viewItems: self.usePlaceholderLoaders ? _.times(6, () => { return { isPlaceholder: true } }) : [],
                lastCallbackResults: {},
                onRequest: (function () {
                    const output = _.chain(['done', 'fail', 'always', 'then']).keyBy().mapValues((value) => {
                        return function () {
                            callbacks[value].push(arguments)

                            if (value === 'then' && typeof arguments[1] === 'function' && self.lastCallbackResults['then_fail']) {
                                if (typeof arguments[1] === 'function') arguments[1].apply(null, self.lastCallbackResults[value])
                            }
                            else {
                                if (typeof arguments[0] === 'function' && self.lastCallbackResults[value]) arguments[0].apply(null, self.lastCallbackResults[value])
                            }
                        }
                    }).value()

                    return output
                })(),
            })

            if (this.useInfiniteScroll) {
                this.paginationPageSize = 100000
            }
            else {
                this.paginationPageSize = this.pageSize
            }

            if (this.ticket && (this.ticket.organizationIds || (this.ticket.networkId && !this.ticket.organizationId))) {
                this.isConsolidated = true
				 // Force relatedContentByUser if type is structure and consolidated is true and includeStatisticsInAggregated is not true

                if (!this.mainHierarchyKind) {
                    if (this.loadDepth == 0 && this.fromItem.type === enums.objectType.structure && !_.get(this.fromItem, 'conditions.uiSettings.includeStatisticsInAggregated')) this.mainHierarchyKind = enums.subItemsKind.relatedContentByUser
                    else this.mainHierarchyKind = enums.subItemsKind.children
                }
            }

            if (!this.mainHierarchyKind) this.mainHierarchyKind = enums.subItemsKind.children

            if (this.loadOnInit) {
                this.loadItemsFromServer({
                    limit: this.useServerPagination ? this.pageSize : undefined,
                })
            }

            if (options.$scope) {
                options.$scope.$on('$destroy', () => {
                    if (self.itemsWatcher) self.itemsWatcher()

                    if (self.xhrRequest && self.xhrRequest.abort) {
                        self.xhrRequest.abort()
                        self.xhrRequest = undefined
                    }
                })
            }

        }

        function instantiate(wfDataNegotiatorControllerOrInstance, options) {
            if (options && options.fromItem) return new DataNegotiator(options)
            else if (wfDataNegotiatorControllerOrInstance) {
                if (wfDataNegotiatorControllerOrInstance instanceof DataNegotiator) return wfDataNegotiatorControllerOrInstance
                else if (wfDataNegotiatorControllerOrInstance.instance) return wfDataNegotiatorControllerOrInstance.instance
            }
        }

        function addItem(relation, options) {
            const
                self = this
				
            let itemComposite
				
            const appendToChildrenOf = _.get(options, 'appendToChildrenOf')
				
            let lastChild
				
            let prevItemIndex

            itemComposite = dataQuery.makeItemComposites([relation], { itemPrototype: _.get(options, 'itemPrototype') })[0]
            itemComposite.mainTextual = wfPropertyExtractor.getMainTextual(itemComposite.content)

            if (appendToChildrenOf) {
                // TODO: Inserting at index need more work for self.viewItems

                prevItemIndex = _.findLastIndex(self.items, { parentWfid: appendToChildrenOf.wfid })
                if (prevItemIndex === -1) prevItemIndex = _.findLastIndex(self.items, { wfid: appendToChildrenOf.wfid })

                self.items.splice(prevItemIndex + 1, 0, itemComposite)

                prevItemIndex = _.findLastIndex(self.rawItems, { parentWfid: appendToChildrenOf.wfid })
                if (prevItemIndex === -1) prevItemIndex = _.findLastIndex(self.rawItems, { wfid: appendToChildrenOf.wfid })

                self.rawItems.splice(prevItemIndex + 1, 0, itemComposite)

                prevItemIndex = _.findLastIndex(self.viewItems, { parentWfid: appendToChildrenOf.wfid })
                if (prevItemIndex === -1) prevItemIndex = _.findLastIndex(self.viewItems, { wfid: appendToChildrenOf.wfid })

                self.viewItems.splice(prevItemIndex + 1, 0, itemComposite)
            }
            else {
                self.items.unshift(itemComposite)
                self.rawItems.unshift(itemComposite)
                self.viewItems.unshift(itemComposite)
            }
            $timeout()
        }

        function removeItem(item) {
        }

        function loadItemsFromServer(options) {
            const
                self = this
				
            const fromItem = this.fromItem = _.get(options, 'fromItem') || this.fromItem
				
            const ticket = this.ticket = _.get(options, 'ticket') || this.ticket
				
            const additionalRequestParams = _.get(options, 'additionalRequestParams') || this.additionalRequestParams
				
            let objectId
				
            let objectType
				
            let serverMethodName
				
            const relationParentData1Values = [wfObject.getRelationParentDataOfKind(self.mainHierarchyKind)]
				
            let deferred // Used to mock the xhrRequest promise when self.preloadedItems are defined

            options = _.assign({
                skip: undefined,
                limit: undefined,
            }, options)

            if (fromItem.type && fromItem.id) {
                objectType = fromItem.type
                objectId = fromItem.id
            }
            else if (fromItem.wfid) {
                objectType = parseInt(fromItem.wfid.split('-')[0])
                objectId = parseInt(fromItem.wfid.split('-')[1])
            }

            // Only structures are allowed to have loadDepth larger than zero
            if (self.loadDepth > 0 && objectType !== enums.objectType.structure) {
                self.loadDepth = 0
            }

            if (self.useServerPagination && options.skip) {
                // Infinite scroll
                self.pageLoading = true // Toggles the infinite scroll loader below the list
                self.pagingFunctionEnabled = false
                if (self.usePlaceholderLoaders) Array.prototype.push.apply(self.viewItems, _.times(1, () => { return { isPlaceholder: true, depth: _.get(_.last(self.viewItems), 'depth') } }))
                serverMethodName = 'multi.getSubItems'
            }
            else {
                serverMethodName = 'multi.getObject'
                self.loaded = false

                // Infinite scroll
                self.pageLoading = false
                self.pagingFunctionEnabled = false
            }

            if (self.preloadedItem) {
                deferred = $q.defer()
                this.xhrRequest = deferred.promise
                deferred.resolve(self.preloadedItem)
            }
            else {
                this.xhrRequest = apiProxy.raw(serverMethodName, self.latestRequestParameters = _.defaultsDeep(additionalRequestParams, {
                    objectType,
                    objectId,
                    item: {
                        type: objectType,
                        id: objectId,
                    },
                    kind: self.mainHierarchyKind,
                    includeStatistics: self.includeStatistics && !self.onlyStatistics,
                    onlyStatistics: self.onlyStatistics,
                    // loadOrganizations: true,
                    loadParents: self.loadParents,
                    loadMetadata: self.loadMetadata,
                    loadVisibilityTags: self.loadVisibilityTags,
                    loadRequirements: self.loadRequirements,
                    childrenLoadDepth: self.loadDepth,
                    getterConditions: {
                        useRecursiveRelationsLoading: self.useRecursiveRelationsLoading,
                        includeOrganizations: true,
                        applyIntersectionIfPossible: true,
                        dataRelationOrganizationMatchMode: 2, // Is needed to load children relations that are both with null org or with an org value
                        loadCreators: self.loadCreators, // true
                        additionalSubItemsKinds: self.additionalSubItemsKinds,
                        splitUpOrganizationStatistics: self.splitUpOrganizationStatistics,
                        splitUpRelativeMeasureSourcesStatistics: self.splitUpRelativeMeasureSourcesStatistics,
                        aggregateYearly: self.aggregateYearly && !self.aggregatePeriodFrequencies,
                        aggregatePeriodFrequencies: self.aggregatePeriodFrequencies,
                        convertMeasureAnswerUnits: self.convertMeasureAnswerUnits,
                        periodSpan: self.periodSpan,
                        excludeYears: self.excludeYears,
                        includeMeasureTargets: self.includeMeasureTargets,
                        dataRelationId: self.dataRelationId,
                        includeAvailablePeriods: self.includeAvailablePeriods,
                    },
                    ticket: ticket ? {
                        networkId: ticket.networkId,
                        organizationIds: ticket.organizationIds,
                        contextParentWfid: ticket.contextParentWfid,
                        contextParentWfids: ticket.contextParentWfids,
                        contextParentType: ticket.contextParentType,
                        receivingOrganizationsAndLimitedDataAccessFromInfluenceId: ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId,
                    } : undefined,
                    skip: options.skip,
                    limit: options.limit,
                    onlyLoadRelations: self.onlyLoadRelations,
                    loadSubItemsKind: self.mainHierarchyKind,
                    culture: wfAuth.getCulture(),
                }))
            }

            // By default, if self.additionalSubItemsKinds contains relatedContentByUser it is included in the request but ignored when building itemComposite because
            // we don't want to include relatedContentByUser in the main hierarchy result.
            if (self.additionalSubItemsKinds && self.additionalSubItemsKinds.includes(enums.subItemsKind.relatedContentByUser)) {
                _.remove(self.additionalSubItemsKinds, x => x === enums.subItemsKind.relatedContentByUser)
            }

            if (self.additionalSubItemsKinds && self.additionalSubItemsKinds.length) {
                Array.prototype.push.apply(relationParentData1Values, _.map(self.additionalSubItemsKinds, (kind) => {
                    return wfObject.getRelationParentDataOfKind(kind)
                }))
            }

            // This callbacks will be executed first
            self.lastCallbackResults = {}

            this.xhrRequest.then((res) => {
                const
                    injected = false
					
                const subListPropName = self.mainHierarchyKind === enums.subItemsKind.relatedContentByUser ? 'relatedContentByUser' : 'childs'
                // TODO: Make into a utility function to get subListPropName
					
                let relations
					
                let childContentByWfid
					
                let contentByWfid
					
                let creatorOrganizationsByWfid
					
                let creatorUsersByWfid
					
                let contextParentContentsByWfid
					
                let item
					
                let items
					
                let relationWfids
					
                let triggerPagingFunction = false
					
                let mainHierarchyRelations

                // If server pagination is used and options.skip is defined then append the server result items after the already existing items in this negotiator
                if (self.loaded && self.useServerPagination && options.skip && res.additionalItems) {
                    self.pageLoading = false //  // Toggles the infinite scroll loader below the list
                    _.remove(self.viewItems, { isPlaceholder: true })

                    // When server pagination is used, the items (dataRelations) are in the array at res.additionalItems.
                    // The items in that array are always a flat list, even if loadDepth is larger than 1.
                    if (res instanceof Array && res.length) {
                        self.pagingFunctionEnabled = self.useInfiniteScroll // Set to true because there might be more items to load when scrolling to the bottom
                        items = res

                        if (self.injected) {
                            wfObject.inject(items)
                            relationWfids = _.map(items, 'wfid')

                            items = dataQuery.makeItemComposites(wfObject.filter({ where: { wfid: { in: relationWfids } } }), { targetKind: self.mainHierarchyKind })

                            items = _.sortBy(items, (itemComposite) => { // Make sure that the items are in the same order as they were in server response
                                return relationWfids.indexOf(itemComposite.wfid)
                            })
                        }
                        else {
                            items = dataQuery.makeItemComposites(res.additionalItems, { targetKind: self.mainHierarchyKind })
                        }

                        Array.prototype.push.apply(self.items, items)
                        Array.prototype.push.apply(self.rawItems, items)
                        Array.prototype.push.apply(self.viewItems, items)
                    }
                    else { // Else, res.additionalItems is empty
                        self.pagingFunctionEnabled = false // Set to false because there are no more items to load when scrolling to the bottom
                    }

                }
                else { // If the main loader is in use (self.loaded is true) or options.skip is undefined then replace the items in this negotiator with the server result
                    self.isHierarchicalData = self.loadDepth > 0

                    if (!self.isConsolidated) {
                        wfObject.inject(res)
                        self.injected = typeof self.inject === 'boolean' ? self.inject : true
                    }
                    else self.injected = typeof self.inject === 'boolean' ? self.inject : false

                    // Handle the loaded item
                    if (self.injected) {
                        item = wfObject.get(res.wfid)
                        self.item = item
                    }
                    else {
                        self.item = item = res
                    }

                    self.itemMetadata = _.cloneDeep(self.item.metadata)
                    // if (self.item.wfid === "21-3599") {
                    // 	console.log("negotiator", self.itemMetadata.count);
                    // }

                    // Handle the loaded item's sub items (entire hierarchy if loadDepth > 1)

                    // If useRecursiveRelationsLoading is used then everything is in res.additionalItems
                    if (self.useRecursiveRelationsLoading && res.additionalItems) {
                        if (relationParentData1Values.length === 1) {
                            mainHierarchyRelations = _.filter(res.additionalItems, { parentData1: wfObject.getRelationParentDataOfKind(self.mainHierarchyKind) })
                        }
                        else {

                            mainHierarchyRelations = _.filter(res.additionalItems, (relation) => {
                                return !!~relationParentData1Values.indexOf(relation.parentData1) // If array contains item
                            })
                        }

                        if (self.injected) {
                            wfObject.inject(res.additionalItems)
                            relationWfids = _.map(mainHierarchyRelations, 'wfid')
                            relations = hook_beforeItemCompositesCreation(wfObject.filter({ where: { wfid: { in: relationWfids } } }))

                            items = dataQuery.makeItemComposites(relations, { targetKind: self.mainHierarchyKind, additionalKinds: self.additionalSubItemsKinds, sortHierarchically: true, rootContentWfid: self.item.wfid })

                            items = _.sortBy(items, (itemComposite) => { // Make sure that the items are in the same order as they were in server response
                                return itemComposite.responseOrder = relationWfids.indexOf(itemComposite.wfid)
                            })
                        }
                        else {
                            relations = hook_beforeItemCompositesCreation(mainHierarchyRelations)
                            items = dataQuery.makeItemComposites(relations, { targetKind: self.mainHierarchyKind, additionalKinds: self.additionalSubItemsKinds })
                        }
                    }
                    else { // Else, access the sub items through getter property property as ususal
                        if (self.injected) {
                            relations = hook_beforeItemCompositesCreation(item[subListPropName])

                            if (self.loadDepth > 1) items = dataQuery.getHierarchyAsList(self.item, null, { maxDepth: self.loadDepth, filter(item) {
                                return item.type !== enums.objectType.questionAnswer && item.type !== enums.objectType.measureAnswer
                            } })
                            else items = dataQuery.makeItemComposites(relations, { targetKind: self.mainHierarchyKind })
                        }
                        else {
                            relations = hook_beforeItemCompositesCreation(item[subListPropName])

                            if (_.get(self.fromItem, 'conditions.uiSettings.includeStatisticsInAggregated')) {
                                relations = _.chain(relations)
                                    .groupBy('wfcid')
                                    .mapValues((groupRelations, key) => {
                                        const output = _.find(groupRelations, (relation) => {
                                            return relation.childContent
                                        })

                                        output.metadata = {
                                            statistics: {
                                                groupCount: groupRelations.length,
                                            },
                                        }

                                        output.settings = {
                                            uiSettings: { hideMetadata: true },
                                        }

                                        delete output.createdAt
                                        delete output.creatorOrganization
                                        delete output.creatorUser

                                        return output
                                    })
                                    .map()
                                    .orderBy('metadata.statistics.groupCount', 'desc')
                                    .value()
                            }

                            if (self.loadDepth > 1) items = dataQuery.getHierarchyAsList(self.item, null, { maxDepth: self.loadDepth, accessPropertiesDirectly: true, filter(item) {
                                return item.type !== enums.objectType.questionAnswer && item.type !== enums.objectType.measureAnswer
                            } })
                            else items = dataQuery.makeItemComposites(relations, { targetKind: self.mainHierarchyKind })

                            // The backend only includes childContent once per usage so we need to assign it here since the data is not injected in JSData.
                            contentByWfid = _.chain(items).map('content').compact().keyBy('wfid').value()
                            _.each(items, (item) => {
                                if (!item.content && item.wfid in contentByWfid) item.content = contentByWfid[item.wfid]
                            })

                            childContentByWfid = _.chain(items).filter({ type: enums.objectType.questionAnswer }).map('content.childContent').compact().keyBy('wfid').value()

                            creatorOrganizationsByWfid = _.chain(items).map('content.creatorOrganization').compact().keyBy('wfid').value()
                            _.assign(creatorOrganizationsByWfid, _.chain(items).map('dataRelation.creatorOrganization').compact().keyBy('wfid').value())

                            creatorUsersByWfid = _.chain(items).map('content.creatorUser').compact().keyBy('wfid').value()
                            _.assign(creatorUsersByWfid, _.chain(items).map('dataRelation.creatorUser').compact().keyBy('wfid').value())

                            contextParentContentsByWfid = _.chain(items).map('dataRelation.contextParentContent').compact().keyBy('wfid').value()

                        }
                    }

                    //self.items = _.take(self.items, 20);

                    self.itemComposite = dataQuery.makeItemComposites([{ childContent: self.item }])[0]
                    self.listInterfaceConfig = _.defaultsDeep(
                        _.get(self.itemComposite, 'dataRelation.settings_user.listInterfaceConfig'),
                        _.get(self.itemComposite, 'dataRelation.settings_organization.listInterfaceConfig'),
                        _.get(self.itemComposite, 'dataRelation.settings.listInterfaceConfig'),
                        _.get(self.itemComposite, 'content.conditions_user.listInterfaceConfig'),
                        _.get(self.itemComposite, 'content.conditions_organization.listInterfaceConfig'),
                        _.get(self.itemComposite, 'content.conditions.listInterfaceConfig'),
                    )

                    if (item.type === enums.objectType.network) {
                        items = _.filter(items, { type: enums.objectType.organization })
                    }

                    self.itemConditions = {
                        isChildrenVirtual: !!_.get(self.item, 'conditions.dataRelation'),
                        childrenObjectTypes: _.get(self.item, 'conditions.objectTypes') || [],
                    }

                    if (!self.onlyLoadRelations) {
                        _.remove(items, (item) => {
                            return !item.content
                        })
                    }

                    self.items.length = 0
                    Array.prototype.push.apply(self.items, items)

                    self.rawItems = _.clone(items)
                    self.viewItems = []

                    self.permissions = _.defaults(item.permissions, permissionsPrototype)

                    self.loaded = true

                    if (items.length) self.pagingFunctionEnabled = self.useInfiniteScroll

                    if (self.useServerPagination) {
                        Array.prototype.push.apply(self.viewItems, items)
                    }
                    else {
                        triggerPagingFunction = true
                    }
                }

                let contentsByWfid = {}
                if (self.allContentPreloaded) {
                    const contentWfids = items.map(x => x.wfid)
                    contentsByWfid = wfObject.filter({ where: { wfid: { in: contentWfids } } }).reduce((acc, val) => ({
                        ...acc,
                        [val.wfid]: val,
                    }), {})
                }

                _.each(items, (itemComposite) => {
                    itemComposite.mainTextual = wfPropertyExtractor.getMainTextual(itemComposite.content)

                    if (self.onlyLoadRelations) {
                        // It's possible that content can be defined at this point if it existed in JSData when the itemComposite was constructed.
                        // We simply ignore that by setting content to undefined here so that JSData cache won't affect content loading.
                        // Note: Only if itemComposite.dataRelation.itemCompositeInstructions.childContent is undefined.
                        if (!(itemComposite.dataRelation.itemCompositeInstructions && itemComposite.dataRelation.itemCompositeInstructions.childContent)) {
                            if (self.allContentPreloaded && itemComposite.wfid in contentsByWfid) {
                                itemComposite.content = contentsByWfid[itemComposite.wfid]
                                itemComposite.isContentLoaded = true
                                itemComposite.isLoading = false
                            }
                            else {
                                itemComposite.content = undefined
                                itemComposite.isContentLoaded = false
                                itemComposite.isLoading = false
                            }
                        }
                    }

                    if (!self.injected) {
                        if (itemComposite.type === enums.objectType.questionAnswer) {
                            itemComposite.content.childContent = childContentByWfid[_.get(itemComposite.content, 'wfcid')] // QuestionAnswerType
                        }

                        if (itemComposite.content) {
                            if (!itemComposite.content.creatorUser && creatorUsersByWfid) itemComposite.content.creatorUser = creatorUsersByWfid[itemComposite.content.creatorUserWfid]

                            if (!itemComposite.content.creatorOrganization && creatorOrganizationsByWfid) itemComposite.content.creatorOrganization = creatorOrganizationsByWfid[itemComposite.content.creatorOrganizationWfid]
                        }

                        if (itemComposite.dataRelation) {
                            if (creatorUsersByWfid && !itemComposite.dataRelation.creatorUser && itemComposite.dataRelation.creatorUserWfid) itemComposite.dataRelation.creatorUser = creatorUsersByWfid[itemComposite.dataRelation.creatorUserWfid]

                            if (creatorOrganizationsByWfid && !itemComposite.dataRelation.creatorOrganization && itemComposite.dataRelation.creatorOrganizationWfid) itemComposite.dataRelation.creatorOrganization = creatorOrganizationsByWfid[itemComposite.dataRelation.creatorOrganizationWfid]

                            if (contextParentContentsByWfid && !itemComposite.dataRelation.contextParentContent && itemComposite.dataRelation.wfxpid) itemComposite.dataRelation.contextParentContent = contextParentContentsByWfid[itemComposite.dataRelation.wfxpid]
                        }

                        itemComposite.creatorOrganization = (itemComposite.content && itemComposite.content.creatorOrganization) || (itemComposite.dataRelation && itemComposite.dataRelation.creatorOrganization)
                        itemComposite.creatorUser = (itemComposite.content && itemComposite.content.creatorUser) || (itemComposite.dataRelation && itemComposite.dataRelation.creatorUser)
                    }
                })

                if (triggerPagingFunction) self.infiniteScrollPagingFunction() // When frontent pagination is used the paging function is called so that an initial set of items is shown

                // self.itemContents = _.map(this.items, function (item) {
                // 	return dataOps.prepareWfObject(item.content);
                // });

                $timeout()
            }, () => self.failed = true)

            // These callbacks will be executed after the one above
            _.each(['done', 'fail', 'always', 'then'], (callbackName) => {
                if (!(callbackName in self.xhrRequest)) return

                self.xhrRequest[callbackName](function () {
                    const args = arguments

                    self.lastCallbackResults[callbackName] = args

                    _.each(self._callbacks[callbackName], (argumentsArray) => {
                        if (typeof argumentsArray[0] === 'function') argumentsArray[0].apply(null, args)
                    })
                }, callbackName === 'then' ? function () {
                    const args = arguments

                    self.lastCallbackResults[callbackName + '_fail'] = args

                    _.each(self._callbacks[callbackName], (argumentsArray) => {
                        if (typeof argumentsArray[1] === 'function') argumentsArray[1].apply(null, args)
                    })
                } : undefined)
            })

            function hook_beforeItemCompositesCreation(relations) {
                const
                    hook = _.get(self, 'hooks.beforeItemCompositesCreation')
					
                let returnedValue

                if (_.isFunction(hook)) {
                    returnedValue = hook(relations)
                    if (returnedValue instanceof Array) return returnedValue
                }

                return relations
            }
        }

        function infiniteScrollPagingFunction() {
            let newChunk

            if (this.useInfiniteScroll) {
                if (this.loaded && this.pagingFunctionEnabled) {
                    if (this.useServerPagination) {
                        if (!this.pageLoading) {
                            this.loadItemsFromServer({
                                skip: this.rawItems.length,
                                limit: this.pageSize,
                            })
                        }
                    }
                    else {
                        newChunk = _.chain(this.items).slice(this.viewItems.length).take(this.pageSize).value()

                        if (newChunk.length) {
                            Array.prototype.push.apply(this.viewItems, newChunk)
                            this.loadContentOnItems(newChunk)
                        }
                        _.remove(self.viewItems, { isPlaceholder: true })

                        if (this.viewItems.length === this.items.length) {
                            this.pagingFunctionEnabled = false
                        }
                    }

                }
            }
            else {
                _.remove(self.viewItems, { isPlaceholder: true })
                Array.prototype.push.apply(this.viewItems, this.items)
            }
        }

        function onFiltered(filterdeItems, selectedOptions) {
            this.isFiltered = selectedOptions.length > 0

            this.viewItems.length = 0
            this.infiniteScrollPagingFunction()
        }

        function loadContentOnItems(itemComposites) {
            const
                self = this
				
            let itemCompositesWithoutContent
				
            let promise
				
            let wfids
				
            const itemsByWfid = {}
				
            let itemGroup
				
            let xhrRequest

            return $q((resolve, reject) => {
                itemCompositesWithoutContent = _.filter(itemComposites, { isContentLoaded: false, isLoading: false, content: undefined })

                if (itemCompositesWithoutContent.length === 0) {
                    resolve()
                    return
                }

                wfids = _.map(itemCompositesWithoutContent, (itemComposite) => {
                    itemGroup = itemsByWfid[itemComposite.wfid]
                    if (!itemGroup) {
                        itemGroup = itemsByWfid[itemComposite.wfid] = []
                    }

                    itemGroup.push(itemComposite)

                    itemComposite.isLoading = true

                    return itemComposite.wfid
                })

                $timeout()

                self.contextLoadingXhrRequest = xhrRequest = apiProxy.raw('multi.getObjects', {
                    wfids,
                    subItemsGetterInstructions: [
                        { subItemsKind: enums.subItemsKind.relatedContentByUser },
                    ],
                    culture: wfAuth.getCulture(),
                    ticket: self.ticket,
                })

                xhrRequest.then((res) => {
                    _.each(res, (content) => {
                        itemGroup = itemsByWfid[content.wfid]

                        for (let i = 0, len = itemGroup.length; i < len; i++) {
                            itemGroup[i].content = content
                            itemGroup[i].isLoading = false
                            itemGroup[i].isContentLoaded = true
                        }

                        delete itemsByWfid[content.wfid]
                    })

                    _.each(itemsByWfid, (itemGroup) => {
                        for (let i = 0, len = itemGroup.length; i < len; i++) {
                            _.remove(self.rawItems, itemGroup[i])
                            _.remove(self.items, itemGroup[i])
                            _.remove(self.viewItems, itemGroup[i])
                        }
                    })

                    // loadAttachedInformationOnItems(itemCompositesWithoutContent);

                    resolve()
                })
            })
        }

        // function loadAttachedInformationOnItems(itemComposites) {
        // 	var
        // 		itemCompositesToLoadAttachedInfoOn = _.filter(itemComposites, function (itemComposite) {
        // 			return _.get(itemComposite.content.metadata, "countByRelationKind." + enums.subItemsKind.relatedContentByUser) > 0
        // 		}),
        // 		requestParams,
        // 		xhrRequest
        // 	;

        // 	if (itemCompositesToLoadAttachedInfoOn.length === 0)
        // 		return;

        // 	requestParams = { items: _.map(itemCompositesToLoadAttachedInfoOn, function (item) {
        // 		return {
        // 			type: item.type,
        // 			id: item.id,
        // 			kind: enums.subItemsKind.relatedContentByUser
        // 		};
        // 	})};

        // 	xhrRequest = apiProxy("multi.getSubItemsOfAll", requestParams);

        // 	xhrRequest.then(function (res) {
        // 		var attachedItemComposites = dataQuery.makeItemComposites(res);

        // 		_.each(itemCompositesToLoadAttachedInfoOn, function (itemComposite) {
        // 			itemComposite.attachedInformation = _.filter(attachedItemComposites, function (attachedItemComposite) {
        // 				return _.get(attachedItemComposite.relation, "wffid") === itemComposite.wfid;
        // 			});
        // 		});

        // 		console.log(itemCompositesToLoadAttachedInfoOn);
        // 	});
        // }
    }
})()
