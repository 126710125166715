/**
 * @ngdoc directive
 * @name wfPermissions
 * @restrict A
 * 
 * @description
 * Use to set up a container in which CRUD permissions can be used to show/hide ui elements.
 * The permissions can be based on a permissions object from a structure that contains the booleans canCreate, canRead, canUpdate and canDelete.
 * Use the accompanying directives wf-permission-* (for each type of operation) on any html element that should be shown/hidden based on a permission.
 */

(function() {
    'use strict'
	
    const PREFIX_REGEXP = /^((?:x|data)[:\-_])/i
    const SPECIAL_CHARS_REGEXP = /[:\-_]+(.)/g

    angular
        .module('wf.common')
        .directive(definePermissionDirectives())

    //Converts all accepted directives format into proper directive name (from AngularJS source code)
    function directiveNormalize(name) {
        return name
            .replace(PREFIX_REGEXP, '')
            .replace(SPECIAL_CHARS_REGEXP, (all, letter) => {
                return letter.toUpperCase()
            })
    }

    function permissionNameAsPropKey(name) {
        return directiveNormalize('can-' + name)
    }
	
    function definePermissionDirectives() {
        const permissionDirectives = {}
		
        // The directives are defined with a loop to avoid repetitive code
        angular.forEach(
            'create read update delete multiple'.split(' '),
            (permissionName) => {
                const
                    directiveName = 'wf-permission-' + permissionName
					
                const permissionPropertyKey = permissionNameAsPropKey(permissionName)
					
                const normalizedDirectiveName = directiveNormalize(directiveName)

                permissionDirectives[normalizedDirectiveName] = ['$parse', '$rootScope', '$compile', function($parse, $rootScope, $compile) {
                    return {
                        require: '?^^wfPermissions',
                        restrict: 'A',
                        priority: 10001,
                        terminal: true, // stop compiling other directives on the
						                // element, we'll fix it in `post
                        compile(element, attrs) {
                            return {
                                post(scope, element, attrs, ctrl) {
                                    let
                                        parent
										
                                    let i = 10

                                    if (!ctrl && (parent = scope.$parent)) {
                                        while (i) {
                                            i--
                                            ctrl = parent.permissionsVm

                                            if (ctrl) break
                                            else {
                                                parent = parent.$parent
                                                if (!parent) break
                                            }
                                        }
                                    }

                                    // console.log(directiveName, normalizedDirectiveName, ctrl.permissions[permissionPropertyKey], ctrl.permissions);

                                    if (ctrl && !isAllowed(ctrl.permissions, scope, attrs)) {
                                        element.remove()
                                    }
                                    else {
                                        // Prevent recursion by removing the permission directive
                                        element[0].removeAttribute(directiveName)
										
                                        // Compile the element so other directives gets processed
                                        $compile(element)(scope)
                                    }
                                },
                            }
                        },
                    }

                    function isAllowed(permissions, scope, attrs) {
                        let multipleOptions; let output = false

                        if (permissionName === 'multiple') {
                            multipleOptions = $parse(attrs.wfPermissionMultiple)(scope)
                            if (multipleOptions) {
                                if (typeof multipleOptions.any === 'string') {
                                    output = _.some(multipleOptions.any.split(','), (name) => {
                                        return permissions[permissionNameAsPropKey(name)]
                                    })
                                }
                                else if (typeof multipleOptions.all === 'string') {
                                    output = multipleOptions.any = _.every(multipleOptions.any.split(','), (name) => {
                                        return permissions[permissionNameAsPropKey(name)]
                                    })
                                }

                                return output
                            }
                        }
                        else {
                            return permissions[permissionPropertyKey]
                        }
                    }
                }]
            },
        )

        return permissionDirectives
    }

})()
