// @flow
import * as React from 'react'
import ItemSummaryStats from './ItemSummaryStats'
import ItemSummaryList from './ItemSummaryList'
import Map from './Map'
import type { Item } from '@worldfavor/types'
import ItemStatistics from './Questions/ItemStatistics'
import { FormattedMessage } from 'react-intl'

const MAX_ITEM_PER_BOX = 20

type Props = {
  subItems: Array<Item>,
  valueColor?: string,
}

class ItemSummaryContainer extends React.PureComponent<Props> {

  static defaultProps = {
      subItems: [],
  }

  _getStats = (): Array<{ value: number, type: string }> => {
    const { subItems } = this.props
    const groupedStats = subItems.reduce((acc, item) => ({
      ...acc,
      [item.type]: (acc[item.type] || 0) + 1,
    }), {})
    return Object.keys(groupedStats)
      .map(key => ({
        value: groupedStats[key],
        title: <FormattedMessage id={`modules.itemTypes.${key}.${groupedStats[key] > 1 ? 'p' : 's'}`}/>,
      }))
  }

  render() {
      const { subItems, valueColor } = this.props

      // Display attached items as a map only if all items have the same type
      // and latitude / longitude
      if (subItems.every((item, index, array) => item.latitude && item.longitude
      && (index === 0 || item.type === array[index - 1].type))) {
          return <Map items={subItems} clusterize />
      }

      if (subItems.length > MAX_ITEM_PER_BOX) {
          return <div style={{ height: 250, display: 'flex' }}><ItemStatistics
            values={this._getStats()}
            forceShowPercentage={false}
            valueColor={valueColor}
          /></div>
      }
      return (
          <ItemSummaryList
              {...this.props}
          />
      )
  }
}

export default ItemSummaryContainer
