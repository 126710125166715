import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('dropdownUtility', dropdownUtilityService)

    dropdownUtilityService.$inject = ['wfAuth', 'modalService']
    function dropdownUtilityService(wfAuth, modal) {
        let currentElement
        let currentWindow
        const service = {
            setAsOpen,
            close,
            fixDropDownPosition,
            determineActions,
            buildDropdownActions,
        }
        const body = $('body')

        return service

        function setAsOpen(element, window) {
            currentElement = element
            currentWindow = window
        }

        function close() {
            if (currentElement) {
                if (currentElement.hasClass('open')) {
                    currentElement.removeClass('open')
                }
                currentElement = undefined
                body.click()
            }
        }

        function fixDropDownPosition(element, dropdown, submenu, options) {
            const
                position = element.position()
				
            const offset = element.offset()
				
            let offsetLeft = offset.left - position.left
				
            let offsetTop = offset.top - position.top
				
            const scrollLeft = currentWindow.scrollLeft()
				
            const scrollTop = currentWindow.scrollTop()
				
            const windowWidth = currentWindow.outerWidth()
				
            let dropdownTop
				
            let dropdownLeft
				
            const modalOpened = Boolean(body.hasClass('modal-open') || body.children('div.modal').length)
				
            let modalOffset
				
            let spaceForDropdown
				
            const ieInUse = $.browser.msie
				
            const dropdownInPopover = options.appendedPopoverToBody // it's always false or undefined unless specified in the dropdownOptions for each item

            if (modalOpened) {
                if (!ieInUse && !dropdownInPopover) {
                    modalOffset = body.find('div.modal-content').offset()
                    offsetLeft = offsetLeft - modalOffset.left
                    offsetTop = offsetTop + element.outerHeight(true) - (modalOffset.top - scrollTop) - scrollTop
                }
            }

            if (submenu) {
                dropdownLeft = (offsetLeft + element.outerWidth(true)) - scrollLeft
                dropdownTop = offsetTop - scrollTop
            }
            else {
                if (options.responsive) {
                    spaceForDropdown = windowWidth - offsetLeft
                    if (spaceForDropdown && (spaceForDropdown > dropdown.outerWidth())) dropdownLeft = offsetLeft - scrollLeft // for small screen (mobile) support deduction of $(window).scrollLeft() is required
                    else dropdownLeft = offsetLeft - scrollLeft + (element.outerWidth() - dropdown.outerWidth())
                }
                else if (options.align === 'right') dropdownLeft = offsetLeft - scrollLeft + (element.outerWidth() - dropdown.outerWidth())
                else dropdownLeft = offsetLeft - scrollLeft // for small screen (mobile) support deduction of $(window).scrollLeft() is required

                if (modalOpened) {
                    if (!ieInUse && !dropdownInPopover) dropdownTop = offsetTop
                    else dropdownTop = offsetTop + element.outerHeight(true) - scrollTop
                }
                else dropdownTop = offsetTop + element.outerHeight(true) - scrollTop
            }
			
            dropdown.css('top', dropdownTop + 'px')
            dropdown.css('left', dropdownLeft + 'px')
        }

        function determineActions(itemComposite, negotiator) {
            let
                output = []
				
            const canRead = negotiator.permissions.canRead
				
            const canCreate = negotiator.permissions.canCreate
				
            const canUpdate = negotiator.permissions.canUpdate
				
            const canDelete = negotiator.permissions.canDelete

            // TODO: Check permissions from negotiator.item

            if (itemComposite.isUserData) {
                if (itemComposite.content.organizationId === wfAuth.getOrganizationId()) {
                    if (canUpdate) {
                        output.push('edit')
                    }
                    if (canDelete) {
                        if (canUpdate) output.push('-')

                        output.push('deleteContent')
                        if (!negotiator.itemConditions.isChildrenVirtual) {
                            output.push('deleteRelation')
                        }
                    }
                }
                // output = [
                // 	{ text: 'Edit', icon: 'fa fa-pencil', action: 'vm.hierVm.editItem(vm.item)' },
                // 	'visibility',
                // 	'-',
					
                // ];
            }
            // else if (negotiator.) {

            // }
            else {
                output = []
                // output = [
                // 	{ text: 'OpenObjectViewer', icon: 'fa fa-external-link', action: 'vm.hierVm.openObjectViewer(vm.item)' },
                // 	{ text: "Statistics", icon: "ionicons ion-android-expand", action: function () {
                // 		//$scope.expandVm.toggleExpand();
                // 	}, condition: true },
                // 	'-',
                // 	{ text: "AddTo", icon: "fa fa-plus", action: 'vm.hierVm.openModal_addTo(vm.item)' },
                // 	{ text: "SendTo", icon: "fa fa-share", action: 'vm.hierVm.openModal_sendTo(vm.item)' },
                // 	// { text: "AssignTask", icon: "fa fa-share", action: 'vm.hierVm.openModal_assignTask(vm.item)', condition: $ctrl.hierVm.orgHasInternalValueChain },
                // 	{ text: "SetGoal", icon: "fa fa-check", action: 'vm.hierVm.openModal_setRequirement(vm.item)' },
                // 	{ text: "AttachInformation", icon:"fa fa-paperclip", subActions: [
                // 		{ text: 'Activity', icon: 'fa fa-calendar-check-o', action: 'vm.hierVm.openModal_attachInformation(vm.item, 15)' },
                // 		{ text: 'Document', icon: 'fa fa-file', action: 'vm.hierVm.openModal_attachInformation(vm.item, 18)' },
                // 		{ text: 'Statement', icon: 'fa fa-quote-right', action: 'vm.hierVm.openModal_attachInformation(vm.item, 44)' },
                // 		{ text: 'Link', icon: 'fa fa-link', action: 'vm.hierVm.openModal_attachInformation(vm.item, 50)' },
                // 		{ text: 'Embed', icon: 'fa fa-play-circle', action: 'vm.hierVm.openModal_attachInformation(vm.item, 90)' }
                // 	] }
                // ];
            }

            return output
        }

        function buildDropdownActions(options) {
            const
                item = options.item
				
            let dropdownActions

            if (options.actions === 'attachInformation') {
                dropdownActions = [
                    { text: 'Activity', icon: 'fa fa-calendar-check-o', action: createAttachAction(enums.objectType.orgActivity) },
                    { text: 'Document', icon: 'fa fa-file', action: createAttachAction(enums.objectType.orgDocument) },
                    { text: 'Statement', icon: 'fa fa-quote-right', action: createAttachAction(enums.objectType.statement) },
                    { text: 'Link', icon: 'fa fa-link', action: createAttachAction(enums.objectType.link) },
                    { text: 'Embed', icon: 'fa fa-play-circle', action: createAttachAction(enums.objectType.embed) },
                ]
            }

            return dropdownActions
				
            function createAttachAction(objectType) {
                return function () {
                    const pickerOptions = {
                        objectTypes: [objectType],
                        relationTarget: {
                            item: item.content,
                            kind: enums.subItemsKind.relatedContentByUser,
                        },
                        intersection: options.ticket,
                    }
		
                    modal.openCreatorAndPicker(pickerOptions).closed((res) => {
                        if (typeof options.callback === 'function') options.callback(res)
                    })
                }
            }
        }
    }
} ())
