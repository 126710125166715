import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'
import FiltersContainer from '@worldfavor/components/Filters/FiltersContainer'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
    appBar: props => ({
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        height: props.barHeight,

        //Ugly fix, but because of angular NavBar fixed-layout class is applied to all <header> tags
        width: '100% !important',
        maxWidth: 'initial !important',
    }),
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    labelIcon: {
        fontSize: theme.typography.fontSizes.medium,
        color: Colors.black,
        marginRight: 6,
    },
    labelText: {
        fontSize: theme.typography.fontSizes.large,
        color: Colors.black,
    },
    gridContainer: {
        height: '100%',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    filterBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 10,
    },
    filterChipButtons: {
        paddingLeft: 16,
        paddingRight: 16,
        flex: 1,
    },
    clearButtonWrapper: {
    
    },
    clearFiltersIcon: {
        marginRight: 6,
    },
    clearFiltersButton: {
        backgroundColor: Colors.orange,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: Colors.darkOrange + '!important',
        },
    },
}))

const DataExplorerFilterBar = (props) => {
    const { filters, onAfterFilterChanged } = props
    const classes = useStyles(props)

    function _onAfterFilterChanged(selectedFilters) {
        onAfterFilterChanged && onAfterFilterChanged(selectedFilters)
    }

    return (
        <AppBar classes={{ root: classes.appBar }}>
            <Grid container justify={'center'} classes={{ container: classes.gridContainer }}>
                <Grid item xs={12} classes={{ item: classes.gridItem }}>
                    <div className={classes.filterBar}>
                        <div className={classes.labelWrapper}>
                            <i className={classNames(classes.labelIcon, 'fas fa-filter')} />
                            <span className={classes.labelText}><FormattedMessage id={'filtering.filterBy'} /></span>
                        </div>
                        <div className={classes.filterChipButtons}>
                            <FiltersContainer filters={filters} onAfterFilterChanged={_onAfterFilterChanged} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </AppBar>
    )
}

DataExplorerFilterBar.defaultProps = {
    barHeight: 60,
}

export default DataExplorerFilterBar
