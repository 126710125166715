import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ScorecardController', ScorecardController)

    ScorecardController.$inject = ['$scope', '$stateParams', 'dataOperationsService', '$timeout', 'modalService', '$translate', '$q']
    function ScorecardController($scope, $stateParams, dataOps, $timeout, modal, $translate, $q) {
        const vm = this

        _.assign(vm, {
            //Variables
            allLoaded: false,
            measures: [],
			
            //Functions
            openPicker,
            getLatestAnswer,
        })
		
        activate()

        function activate() {
            dataOps.getObject({
                objectId: $stateParams.objectId,
                objectType: $stateParams.objectType,
                childrenLoadDepth: 2,
            }).then((result) => {
                if (result.type === enums.objectType.dataRelation) vm.mainStructure = result.childContent
                else vm.mainStructure = result

                populateMeasures()
                vm.noDataMessage = 'No data to show the chart'
                // console.log(vm.measures);

                $timeout(() => {
                    vm.allLoaded = true
                })
            })
        }

        function populateMeasures() {
            let
                measureDataRelations
				
            let measureWfids
				
            let measureContents

            measureDataRelations = vm.mainStructure.childs
            measureWfids = _.map(measureDataRelations, 'wfcid')
            measureContents = wfObject.filter({ where: {
                type: enums.objectType.measure,
                wfid: { in: measureWfids },
            } })

            vm.measures = _.map(measureDataRelations, (dataRelation) => {
                const
                    measureContent = _.find(measureContents, { wfid: dataRelation.wfcid })
					
                const measureItem = {
                    wfid: measureContent.wfid,
                    content: measureContent,
                    dataRelation,
                }

                measureItem.latestAnswerRelation = getLatestAnswer(measureItem)
                measureItem.latestAnswerContent = measureItem.latestAnswerRelation ? measureItem.latestAnswerRelation.childContent : null

                return measureItem
            })
        }

        function openPicker() {
            modal.openCreatorAndPicker({
                title: $translate.instant('Measures'),
                create: false,
                // sourceItem: '73-40014',
                objectTypes: [enums.objectType.measure],
                hideListHeader: true,
                relationTarget: { item: vm.mainStructure, kind: enums.subItemsKind.childrenByUser },
            }).closed(() => {
                //CODE TO RELOAD THE SCORECARD PAGE
                loadMeasureAnswers().then(() => {
                    populateMeasures()
                    $timeout()
                })
            })
        }

        function getLatestAnswer(measure) {
            const measureAnswers = measure.content.childs
            const groupedSortedAnswers = _
                .chain(measureAnswers)
                .groupBy('childContent.year')
                .map((group) => {
                    return _.orderBy(group, ['createdAt'], ['desc'])[0]
                })
                .orderBy([function (a) {
                    return a.childContent.year
                }], ['desc']).value()

            return groupedSortedAnswers[0]
        }

        function loadMeasureAnswers() {
            const promises = []

            _.forEach(vm.mainStructure.childs, (measure) => {
                promises.push(dataOps.getSubItems(measure.childContent, enums.subItemsKind.childrenByUser))
            })

            return $q.all(promises)
        }
    }

}())
