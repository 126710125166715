import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('OrganizationSettingsController', OrganizationSettingsController)

    OrganizationSettingsController.$inject = ['$scope', '$element', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', 'formSchemaService', '$q', '$rootScope', 'DS', 'wfAuth', 'responsiveUi']
    function OrganizationSettingsController($scope, $element, dataOps, modal, wfObject, dataQuery, $translate, $timeout, formSchemaService, $q, $rootScope, DS, wfAuth, responsiveUi) {
        const
            vm = this
			
        const userProperties = ['name', 'registrationNumber', 'imageUrl']
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const orgObjectType = enums.objectType.organization
			
        let promise
			
        let originalModel

        vm.pageTitle = $translate.instant('menu.OrganizationSettings')
        vm.formControl = {}
        vm.submitButtonClick = submitButtonClick
        vm.objectType = enums.objectType.organization
        vm.formCompiler = {}

        activate()

        function activate() {
            responsiveUi.enableResponsiveness()
            responsiveUi.disableResponsivenessOnScopeDestroy($scope)

            dataOps.getObject({
                bypassCache: true,
                objectType: enums.objectType.organization,
                objectId: authOrgId,
                skipExtras: true,
            }).then((org) => {
                vm.model = originalModel = org

                loadFormSpecification().then(() => {
                    if (org.primaryLocationId) {
                        dataOps.getObject({
                            bypassCache: true,
                            objectType: enums.objectType.location,
                            objectId: org.primaryLocationId,
                        }).then((location) => {
                            vm.loaded = true
                            vm.primaryLocation = location
                        })
                    }
                    else {
                        vm.loaded = true
                    }
                })
            })
        }

        function submit() {
            const
                promises = []
				
            const jqDf = $.Deferred()

            vm.formControl.submit((model, deferred) => {
                model.id = authOrgId
                model.wfid = orgObjectType + '-' + authOrgId
                model.type = orgObjectType

                if (!model.registrationNumber && !model.vatNumber) {
                    vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false)
                    vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false)
                    deferred.reject()
                    return false
                }

                // Checking if location should be removed
                if (originalModel.primaryLocationId && !model.primaryLocationId) {
                    promises.push(dataOps.destroy(vm.primaryLocation))
                }

                $q.all(promises).then(() => {
                    dataOps.update(model).then((updatedOrg) => {
                        wfAuth.assignAuthUserValues({
                            organizationName: updatedOrg.name,
                        })

                        if (model.imageUpload) {
                            delete model.imageUpload
                        }

                        deferred.resolve(model)
                        vm.model = originalModel = wfObject.get(vm.model.wfid)
                        if (originalModel.primaryLocationId) vm.primaryLocation = wfObject.get(enums.objectType.location + '-' + originalModel.primaryLocationId)
                        else vm.primaryLocation = undefined
                    })
                })
            }).then((valid) => {
                if (valid) vm.formCompiler.compile()

                jqDf.resolve()
            })

            return jqDf.promise()
        }

        function submitButtonClick(e) {
            return submit()
        }

        function loadFormSpecification() {
            let isVatOrRegNumberValid = false
            const instantVatOrRegNumberValidation = true
            let regNumberValid = false
            let vatNumberValid = false

            return formSchemaService.getFromType(enums.objectType.organization).then((res) => {
                res.schema.required = ['name', 'country', 'employeeCountSpan']
                vm.customFormSpec = {
                    schema: res.schema,
                    form: [
                        {
                            key: 'name',
                            title: $translate.instant('modules.organization.introModal.form.orgName'),
                        },
                        {
                            type: 'section',
                            htmlClass: 'registration-vat row mr0',
                            items: [
                                {
                                    key: 'registrationNumber',
                                    title: $translate.instant('modules.organization.introModal.form.regNumber'),
                                    htmlClass: 'col-sm-6',
                                    validationMessage: {
                                        vatOrRegNumber: $translate.instant('validationMessages.vatRegOrGlnNumber'),
                                    },
                                    $validators: {
                                        vatOrRegNumber(value) {
                                            if (!instantVatOrRegNumberValidation) return true

                                            const result = !!(vatNumberValid || value)
                                            regNumberValid = !!value

                                            if (isVatOrRegNumberValid !== result) {
                                                isVatOrRegNumberValid = result
                                                vm.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result)
                                            }

                                            return result
                                        },
                                    },
                                },
                                {
                                    key: 'vatNumber',
                                    title: $translate.instant('modules.organization.introModal.form.vatNumber'),
                                    htmlClass: 'col-sm-6',
                                    validationMessage: {
                                        vatOrRegNumber: $translate.instant('validationMessages.vatRegOrGlnNumber'),
                                    },
                                    $validators: {
                                        vatOrRegNumber(value) {
                                            if (!instantVatOrRegNumberValidation) return true

                                            const result = !!(regNumberValid || value)
                                            vatNumberValid = !!value

                                            if (isVatOrRegNumberValid !== result) {
                                                isVatOrRegNumberValid = result
                                                vm.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result)
                                            }

                                            return result
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            key: 'gln',
                            title: $translate.instant('GLNNumber'),
                        },
                        {
                            key: 'country',
                            title: $translate.instant('modules.organization.introModal.form.country'),
                        },
                        {
                            key: 'employeeCountSpan',
                            title: $translate.instant('modules.organization.introModal.form.employeeRange'),
                        },
                        {
                            type: 'section',
                            htmlClass: 'logo-address row mr0',
                            items: [
                                {
                                    type: 'section',
                                    htmlClass: 'col-sm-6 pr0 organization-logo',
                                    items: [{ key: 'imageUpload' }],
                                },
                                {
                                    type: 'section',
                                    htmlClass: 'col-sm-6 pr0 organization-location',
                                    items: [{ key: 'primaryLocationId' }],
                                },
                            ],
                        },
                        {
                            key: 'industry',
                            title: $translate.instant('Industry'),
                            type: 'picker_multiple',
                            typeOptions: {
                                addButtonCaption: $translate.instant('Select') + ' ' + $translate.instant('Industry').toLowerCase(),
                                targetWfid: '71-14409', // Currently selected industries
                                picker: {
                                    sourceItem: '71-13886', // List of available industries
                                    title: $translate.instant('modules.organization.introModal.form.industries.pickerTitle'),
                                    description: $translate.instant('modules.organization.introModal.form.industries.pickerDescription'),
                                },
                            },
                        },
                    ],
                }
            })
        }
    }
})()
