import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 19,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
})

const InfluenceEditor = ({ classes, form, formValidation, showValidation, users, onChangeField }) => {
  
    const handleChange = key => (event) => {
        onChangeField(event, key, event.target.value)
    }

    return (
        <div className={classes.root}>
            <TextField
                id="user"
                value={form.creatorUserId}
                label={<FormattedMessage id={'supplyChain.influences.creatorUser'} />}
                onChange={handleChange('creatorUserId')}
                error={showValidation && formValidation.creatorUserId}
                className={classes.textField}
                select
                required
                margin="normal"
                variant="filled"
            >
                {
                    users.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))
                }
            </TextField>
            <TextField
                id="activatedAt"
                value={form.activatedAt}
                label={<FormattedMessage id={'supplyChain.influences.activatedAt'} />}
                onChange={handleChange('activatedAt')}
                error={showValidation && formValidation.activatedAt}
                type="date"
                className={classes.textField}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="filled"
            />
            <TextField
                id="fulfillmentDueAt"
                value={form.fulfillmentDueAt}
                label={<FormattedMessage id={'supplyChain.influences.fulfillmentDueAt'} />}
                onChange={handleChange('fulfillmentDueAt')}
                error={showValidation && formValidation.fulfillmentDueAt}
                type="date"
                className={classes.textField}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="filled"
            />
            <TextField
                id="comment"
                value={form.comment}
                label={<FormattedMessage id={'supplyChain.influences.comment'} />}
                onChange={handleChange('comment')}
                error={showValidation && formValidation.comment}
                className={classes.textField}
                rows={6}
                margin="normal"
                multiline
                variant="filled"
            />
        </div>
    )
}

InfluenceEditor.defaultProps = {
    users: [],
    onChangeField: () => {},
}

export default withStyles(styles)(InfluenceEditor)
