// @flow
import * as React from 'react'

type Props = {
  url: string,
  size: number,
  style: { [string] : any },
}

class Avatar extends React.PureComponent<Props> {

  static defaultProps = {
      size: 12,
      style: {},
  }

  render() {
      const { url, size, style } = this.props
      return (
          <div
              style={{
                  height: size,
                  width: size,
                  backgroundImage: `url(${url})`,
                  backgroundSize: 'cover',
                  borderRadius: '100%',
                  backgroundPosition: 'center',
                  ...style,
              }}
          />
      )
  }
}

export default Avatar
