// @flow
import * as React from 'react'
import {
    getNodesFromRequestIdAndPageId,
    getPageFromRequestIdAndPageId,
} from '../selectors/paginationSelector'
import { connect } from 'react-redux'

const withPage = (Component: React.ComponentType<any>) => {
    class WithPageComponent extends React.Component<any> {
        render() {
      const { pageId, requestId, ...other } = this.props //eslint-disable-line
            return (
                <Component {...other} />
            )
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const items = getNodesFromRequestIdAndPageId(state, ownProps.requestId, ownProps.pageId)
        const page = getPageFromRequestIdAndPageId(state, ownProps.requestId, ownProps.pageId)
        const { totalElements, pageNumber, pageSize } = page

        return {
            items,
            totalElements,
            pageNumber,
            pageSize,
        }
    }

    return connect(mapStateToProps)(WithPageComponent)
}

export default withPage
