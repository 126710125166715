import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('UsersController', UsersController)

    UsersController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$ngBootbox', 'responsiveUi']
    function UsersController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, wfItemToolsService, $ngBootbox, responsiveUi) {
        const
            vm = this
			
        let usersContents
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const hasInsightsRoot = wfAuth.getRootStructureInfo().name === 'insights'
        // If org has the Insights page as root (wfLandingPage component)
			
        const userRole = wfAuth.getUserRole()
			
        const canManageUsers = !userRole || userRole === 'Superuser'
			
        const rootStructure = wfAuth.getRootStructureInfo()
			
        const reportingOrgRootStructure = 12756
			
        const isReportingUser = rootStructure.id === reportingOrgRootStructure

        _.assign(vm, {
            canManageUsers,
            editUserOrgAccessSettings,
            createUser,
            removeUserFromOrg,
            sendUserInviteMail,
            editUserAccess,
            translateFullText: $translate.instant('modules.users.Full'),
            edit,
            userControls: {},
            restrictedAccessByUserId: {},
        })
		
        activate()

        ////////////////

        function activate() {
            responsiveUi.enableResponsiveness()
            responsiveUi.disableResponsivenessOnScopeDestroy($scope)

            dataOps.getObject({
                objectId: 322825, // Contains the current organization's users
                objectType: enums.objectType.dataRelation,
            }).then((res) => {
                vm.item = res
                $rootScope.setPageTitle(vm.item.childContent.title)

                apiProxy('authentication.getCurrentOrgSettingsPerUser').then((res) => {
                    vm.users = vm.item.childContent.childs
                    vm.orgAccessSettingsPerUser = res

                    _.forEach(vm.users, (userDataRelation) => {
                        vm.userControls[userDataRelation.wfcid] = {}
                        const itemContent = userDataRelation.childContent

                        itemContent.activationText = itemContent.activationPending ? $translate.instant('Not_Activated') : $translate.instant('Activated')

                    })

                    for (const key in vm.orgAccessSettingsPerUser) {
                        if (!vm.orgAccessSettingsPerUser[key].rootObjectTitle) vm.orgAccessSettingsPerUser[key].rootObjectTitle = '?'
                    }

                    usersContents = _.map(vm.users, 'childContent')

                    apiProxy('authentication.getUserIdsWithRestrictedAccess').then((userIds) => {
                        vm.restrictedAccessByUserId = _.mapValues(_.mapKeys(userIds))
                        // console.log(vm.restrictedAccessByUserId);

                        // Get structure with AccessTags that specifies CUD permissions
                        dataOps.getObject('71-14489', {
                            childrenLoadDepth: 0,
                        }).then((res) => {
                            vm.permissionsStructure = res
                            vm.loaded = true
                            defineDropdownActions()

                            $timeout()
                        })
                    })
                })
            })
        }

        function defineDropdownActions() {
            const
                permissions = _.get(vm.permissionsStructure, 'permissions') || {}
				
            const canUpdate = canManageUsers && permissions.canUpdate !== false
				
            const canDelete = canManageUsers && permissions.canDelete !== false
				
            const currentUserId = wfAuth.getWorldfavorUserId()

            vm.dropdownActions = [
                { text: 'Edit', icon: 'fa fa-pencil', action: edit, condition: canUpdate },
                { text: 'EditPermissions', icon: 'fa fa-unlock-alt', action: editUserAccess, condition: canUpdate && !hasInsightsRoot },
                // { text: 'EditPermissions', icon: 'fa fa-unlock-alt', action: '$parent.vm.editUserOrgAccessSettings(itemVm.itemContent)' },
                { text: 'modules.users.SendInviteMail', icon: 'fa fa-envelope',
                    action: sendUserInviteMail, condition(dropdownVm) {
                        return canUpdate && (dropdownVm.itemContent.id !== currentUserId)
                    },
                },
                canUpdate && canDelete && !hasInsightsRoot ? '-' : undefined,
                { text: 'modules.users.RemoveFromOrganization', icon: 'fa fa-times', action: removeUserFromOrg, cssClass: 'removal', condition: canDelete && !hasInsightsRoot },
            ]
        }

        function editUserOrgAccessSettings(itemContent, itemRelation, dropdownControl) {
            wfItemToolsService.editUserOrgAccessSettings(itemContent).then((res) => {
                let
                    orgAccessSettings = vm.orgAccessSettingsPerUser[res.auth0Id]
					
                let rootObject
				
                if (!orgAccessSettings) orgAccessSettings = vm.orgAccessSettingsPerUser[res.auth0Id] = {}

                orgAccessSettings.rootObjectId = res.rootObjectId

                if (res.rootObjectId) {
                    rootObject = wfObject.get('73-' + res.rootObjectId)
                    if (rootObject && rootObject.childContent) orgAccessSettings.rootObjectTitle = rootObject.childContent.headerText
                    else orgAccessSettings.rootObjectTitle = null
                }
                else {
                    orgAccessSettings.rootObjectTitle = null
                }

                orgAccessSettings.viewMode = res.viewMode

                vm.userControls[itemContent.wfid].compile()
                // dropdownControl.notifyItemUpdated();
            })
        }

        function sendUserInviteMail(itemContent) {
            modal.previewMail({
                userIds: [itemContent.id],
                mailPurpose: 4,
                showSendButton: true,
                showForm: true,
                // previewEndpoint: "authentication.previewInviteMailToColleague",
                // sendEndpoint: "authentication.sendInviteMailToColleague_new",
                onSent() {
                    // console.log("Sent!")
                },
            })
            // modal.askToSendUserInviteMail(itemContent);
        }

        function createUser(negotiator) {
            const requiredFields = ['email', 'given_name', 'family_name', 'password']
            if (isReportingUser) requiredFields.push('position')

            modal.createWithRelation({
                requiredFields,
                objectType: hasInsightsRoot ? enums.objectType.individual : 1000, // Normal objecType for user is 100. Type 1000 is used to show the user form including password field.
                dataRelationOptions: { kind: 9, virtual: true, virtualItem1: vm.item.childContent, item1: { wffid: '101-' + wfAuth.getOrganizationId(), type: 101, id: wfAuth.getOrganizationId() } },
                onBeforeSubmitTriggered(event) {
                    const
                        userModel = event.getModel()
						
                    const userAlreadyOnOrganization = _.find(usersContents, (user) => {
                        return user.email.toLowerCase().trim() == userModel.email.toLowerCase().trim()
                    })

                    if (userAlreadyOnOrganization) {
                        $ngBootbox.customDialog({
                            title: $translate.instant('modules.users.alreadyInOrganization.modalTitle'),
                            message: $translate.instant('modules.users.alreadyInOrganization.modalMessage', {
                                name: userAlreadyOnOrganization.name,
                                email: userAlreadyOnOrganization.email,
                            }),
                            onEscape: true,
                            className: 'modal-width-500',
                            buttons: {
                                cancel: {
                                    label: $translate.instant('OK'),
                                    className: 'btn-primary',
                                    callback() {
                                        event.cancelSubmit()
                                    },
                                },
                            },
                        })
                    }
                    else event.continueSubmit()
                },
            }).then((res) => {
                if (res) {
                    // negotiator.addItem(res);
                    vm.users = vm.item.childContent.childs
                    vm.listControl.compile()
                    vm.userControls[res.wfcid] = {}
                    usersContents.push(res.childContent)

                    $timeout()
                }
            })
        }

        function removeUserFromOrg(itemContent, itemRelation, dropdownControl) {
            const
                childContent = itemContent
				
            const wfid = childContent.wfid

            modal.confirmDelete(itemRelation, {
                title: $translate.instant('modules.users.confirmRemoveFromOrganization.header', { username: childContent.getHeaderText() }),
                message: $translate.instant('modules.users.confirmRemoveFromOrganization.message', { username: childContent.getHeaderText() }),
                button: $translate.instant('modules.users.confirmRemoveFromOrganization.button'),
				
            }).then(() => {
                _.remove(usersContents, { wfid })
                vm.users = vm.item.childContent.childs
                vm.listControl.compile()
                // dropdownControl.notifyItemRemoved();
                $timeout()
            })
        }

        function edit(itemContent, itemRelation, dropdownControl) {
            modal.edit(itemContent).then((res) => {
                vm.userControls[itemContent.wfid].compile()
                // dropdownControl.notifyItemUpdated();
            })
        }

        function editUserAccess(itemContent, itemRelation, dropdownControl) {
            modal.openItem({
                size: 'width-900',
                template: '<wf-hierarchical include="{ backButton: false }" override="{ headerOptions: pageHeader }" org-access-admin="{ orgId: orgId, userId: userId, objectWfid: \'71-10010\' }"></wf-hierarchical>',
                scope: {
                    pageHeader: { title: itemContent.name },
                    orgId: authOrgId,
                    userId: itemContent.worldfavorUserId,
                },
            })
        }
    }
})()
