import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('WfOrganizationPanelController', WfOrganizationPanelController)

    WfOrganizationPanelController.$inject = ['dataOperationsService', '$state', '$rootScope', 'wfAuth']
    function WfOrganizationPanelController(dataOps, $state, $rootScope, wfAuth) {
        const
            vm = this
			
        const orgPanelStructureId = 868068
			
        const informationRepositoryId = 10013
			
        const contentId = 22855
			
        const frameworkId = 22856
			
        const rootStructure = wfAuth.getRootStructureInfo()
			
        const reportingOrgRootStructure = 12756
			
        const isReportingUser = rootStructure ? rootStructure.id === reportingOrgRootStructure : false
			
        const groupsToExclude = [
            {
                id: contentId, // CONTENT
                condition: isReportingUser,
            },
        ]
			
        const preparedItems = []

        _.assign(vm, {
            //Variables
            loaded: false,
            flatListSettings: undefined,

            // Functions
            selectItemSettings,
        })

        activate()

        function activate() {
            loadOrganizationPanelStructure().then((dataRelation) => {
                const orgPanelChilds = _.get(dataRelation, 'childContent.childs')
                if (orgPanelChilds) vm.flatListSettings = prepareItemsForSideMenu(orgPanelChilds)
                else console.error('Could not get organization panel settings')

                checkUrlAndSelectItem()

                $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
                    unselectItems()
                    checkUrlAndSelectItem()
                })
                vm.loaded = true
            })
        }

        function loadOrganizationPanelStructure() {
            return dataOps.getObject(enums.objectType.dataRelation + '-' + orgPanelStructureId, {
                objectId: orgPanelStructureId,
                objectType: enums.objectType.dataRelation,
                childrenLoadDepth: 2,
            })
        }

        function prepareItemsForSideMenu(itemsDataRelation, parentContentId, traversingChildItems) {
            _.each(itemsDataRelation, (itemDataRelation) => {
                if (shouldExcludeItem(itemDataRelation)) return

                const itemContent = itemDataRelation.childContent
                preparedItems.push({
                    parentItem: !traversingChildItems,
                    icon: itemContent.conditions && itemContent.conditions.iconCssClass ? itemContent.conditions.iconCssClass : 'fas fa-circle',
                    selected: false,
                    state: parentContentId && parentContentId ? getState(itemDataRelation, itemContent) : '',
                    title: itemContent.title ? itemContent.title : 'title undefined',
                })

                if (!traversingChildItems && itemContent.childs) prepareItemsForSideMenu(itemContent.childs, parseInt(itemContent.id), true)
            })

            return preparedItems

            function getState(dataRelation, itemContent) {
                let state = {
                    urlPath: undefined,
                    idPath: undefined,
                }

                if (itemContent) state.urlPath = _.get(itemContent, 'conditions.urlPath')

                if (!state.urlPath) {
                    switch (parentContentId) {
                        case informationRepositoryId:
                        case contentId:
                        case frameworkId:
                            state = {
                                urlPath: 'orgPanel/account/dataRelation',
                                idPath: dataRelation.id,
                            }
                            break
					
                        default:
                            state = undefined
                            break
                    }
                }
                else {
                    //Fix this
                    if (state.urlPath.includes('/account/')) state.urlPath = 'orgPanel' + state.urlPath
                    else state.urlPath = 'orgPanel/account' + state.urlPath
                }
				
                return state
            }
        }

        function shouldExcludeItem(itemDataRelation) {
            let excludeItem = false
            if (groupsToExclude && groupsToExclude.length) {
                _.each(groupsToExclude, (excludeObject) => {
                    if (excludeObject.condition) {
                        if (itemDataRelation.childId === excludeObject.id || itemDataRelation.parentId === excludeObject.id) excludeItem = true
                    }
                })
            }

            return excludeItem
        }

        function selectItemSettings(item) {
            unselectItems()
			
            item.selected = true
            if (item.state && item.state.urlPath) $state.go(item.state.urlPath, { idPath: item.state.idPath })
            else console.error('settingsItem state or urlPath not defined. Could not load settings page')
        }

        function unselectItems() {
            let selectedItems = undefined
            selectedItems = _.filter(vm.flatListSettings, 'selected')
            if (selectedItems && selectedItems.length) {
                _.each(selectedItems, (selectedItem) => {
                    selectedItem.selected = false
                })
            }
        }

        function checkUrlAndSelectItem() {
            let selectedItem = undefined

            if ($state.params && $state.params.idPath) selectedItem = _.find(vm.flatListSettings, (item) => { return (item.state.urlPath === $state.current.name) && (item.state.idPath === parseInt($state.params.idPath)) })
            else if ($state.params && $state.current && $state.current.name !== 'organizationPanel') selectedItem = _.find(vm.flatListSettings, (item) => { return item.state.urlPath === $state.current.name })
            else {
                selectedItem = _.find(vm.flatListSettings, { parentItem: false })
                selectItemSettings(selectedItem)
            }

            if (selectedItem) selectedItem.selected = true
        }
    }
})()
