import { FontWeights, FontSizes } from '@worldfavor/constants/typography'
import Colors from '@worldfavor/constants/colors'
import { makeStyles } from '@material-ui/core/styles'

const TableElementsStyles = makeStyles({
    cell: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 30,
        width: '100%',
        paddingRight: 15,
    },
    labelCell: {
        fontWeight: FontWeights.medium,
        paddingRight: 15,
    },
    scoreLabel: {
        border: `1px solid ${Colors.defaultColor}`,
        borderRadius: 4,
        padding: '1px 8px 1px 8px',
        fontSize: FontSizes.default,
        fontWeight: FontWeights.medium,
        color: Colors.defaultColor,
    },
    image: {
        minWidth: 30,
        maxWidth: 30,
        minHeight: 30,
        borderRadius: 2,
        marginRight: 20,
    },
})

export default TableElementsStyles
