(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('PackageSolutionPageController', PackageSolutionPageController)

    PackageSolutionPageController.$inject = ['$stateParams', '$state', 'dataOperationsService', '$translate', 'wfAuth', '$rootScope']
    function PackageSolutionPageController($stateParams, $state, dataOps, $translate, wfAuth, $rootScope) {
        const vm = this
        const authUser = wfAuth.getWorldfavorUser()

        _.assign(vm, {
            loaded: true,
            greetingsTitle: $translate.instant('modules.signup.landingPage.pageHeader.title', { userName: authUser.given_name + '!' }),
            objectId: _.get($stateParams, 'objectId') || _.get($state, 'params.objectId'),
            objectType: _.get($stateParams, 'objectType') || _.get($state, 'params.objectType'),
            solution: undefined,
        })
		
        activate()

        function activate() {
            let itemFromJSData = undefined
            let solutionWfid = ''

            if (vm.objectId && vm.objectType) {
                solutionWfid = vm.objectType + '-' + vm.objectId
                itemFromJSData = wfObject.get(solutionWfid)

                if (itemFromJSData && typeof itemFromJSData.wfid !== 'string') applyAndLoadSolution(itemFromJSData)
                else dataOps.getObject(solutionWfid).then((res) => { applyAndLoadSolution(res) })
            }
            else console.error('could not load PackageSolutionPage, objectId or objectType not defined')
        }

        function applyAndLoadSolution(solution) {
            vm.solution = solution
            $rootScope.setPageTitle(solution.title)
            vm.loaded = true
        }
    }
})()
