import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('itemStatisticsStore', itemStatisticsStore)
        .component('wfItemStatistics', {
            // templateUrl: ["$attrs", function ($attrs) {
            // 	if ("orgAccessAdmin" in $attrs)
            // 		return "scripts/wf/hierarchical/wfHierarchicalItemOrgAccess.component.html";
            // 	else if ("publicLayout" in $attrs)
            // 		return "scripts/wf/hierarchical/wfHierarchicalItemPublicLayout.component.html";
            // 	else
            // 		return "scripts/wf/hierarchical/wfHierarchicalItem.component.html";
            // }],
            templateUrl: 'scripts/wf/statistics/wfItemStatistics.component.html',
            require: {
                HierarchicalController: '?^^HierarchicalController',
                wfDataNegotiator: '?^^wfDataNegotiator',
            },
            controller: wfItemStatisticsController,
            controllerAs: 'vm',
            bindings: {
                itemContent: '=',
                itemRelation: '=',
                organizationIds: '=',
                intersection: '=',
                negotiatorFromAttr: '=negotiator',
                preloadedTotals: '=preparedTotals',
                useEqualWidths: '<',
                small: '<',
                createNewNegotiator: '<',
                showLatestAnswersInfoIcon: '<'
            },
        })

    let interpolateFunc

    wfItemStatisticsController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', 'apiProxy', 'itemStatisticsStore', 'DataNegotiator', '$interpolate', '$sanitize', '$filter', 'wfPropertyExtractor', 'wfAuth']
    function wfItemStatisticsController($scope, $timeout, $element, $rootScope, $state, requirements, $transclude, $compile, $translate, apiProxy, itemStatisticsStore, DataNegotiator, $interpolate, $sanitize, $filter, wfPropertyExtractor, wfAuth) {
        const
            vm = this

        let xhrRequest

        vm.$onInit = function() {
            let
                cachedResponse

            const existingNegotiator = vm.createNewNegotiator ? undefined : vm.wfDataNegotiator || vm.negotiatorFromAttr

            let organizationIds

            let orgIdsJoined

            let networkId

            let contextParentWfid

            let contextParentWfids

            let contextParentWfidsJoined

            let contextParentType

            let loadSubItemsKind

            if (vm.itemContent && vm.itemContent.type === enums.objectType.finding) {
                vm.loaded = true
                return
            }

            if (vm.preloadedTotals) {
                vm.loaded = true
                buildTotalsHtml(vm.preloadedTotals)
                return
            }

            if (existingNegotiator) {

                // If both a negotiator and itemContent was provided but the type and id doesn't match then don't use the negotiator for loading
                if (vm.itemContent && (vm.itemContent.type !== existingNegotiator.fromItem.type || vm.itemContent.id !== existingNegotiator.fromItem.id)) {
                    orgIdsJoined = existingNegotiator.ticket.organizationIds.join(',')
                    organizationIds = existingNegotiator.ticket.organizationIds
                    networkId = existingNegotiator.ticket.networkId
                    contextParentWfid = existingNegotiator.ticket.contextParentWfid
                    contextParentWfids = existingNegotiator.ticket.contextParentWfids
                    contextParentType = existingNegotiator.ticket.contextParentType
                    contextParentWfidsJoined = existingNegotiator.ticket.contextParentWfids ? existingNegotiator.ticket.contextParentWfids.join(',') : null
                }
                else {
                    vm.negotiator = DataNegotiator.instantiate(existingNegotiator, { fromItem: vm.fromItem, ticket: vm.ticket, onlyStatistics: true, convertMeasureAnswerUnits: true })

                    vm.isQuestion = vm.negotiator.fromItem.type === enums.objectType.question
                    vm.isMeasure = vm.negotiator.fromItem.type === enums.objectType.measure || vm.negotiator.fromItem.type === enums.objectType.relativeMeasure
                    vm.isStructure = vm.negotiator.fromItem.type === enums.objectType.structure

                    vm.negotiator.onRequest.then((res) => {
                        vm.preparedTotals = handleStatisticsObject(res, vm.itemRelation)
                        // vm.totals = _.reject(_.get(res, "metadata.statistics.totals"), { id: "count" });
                        // vm.totalsById = _.chain(vm.totals).keyBy("id").mapValues("value").value();
                        // vm.totalCount = _.get(res, "metadata.statistics.count");
                        // vm.latestMeasurePeriodDataSet = _.first(_.get(_.last(_.get(res, "metadata.statistics.datasets"), { id: "period" }), "data"));
                        vm.loaded = true
                        buildTotalsHtml(vm.preparedTotals)

                        // if (vm.negotiator.fromItem.type === enums.objectType.measure) {
                        // 	// vm.measureUnit = vm.negotiator.fromItem.childContent.symbol;
                        // }
                    })
                    return
                }
            }
            else {
                if (!vm.intersection.organizationId && !vm.intersection.organizationIds) vm.intersection.organizationId = wfAuth.getOrganizationId()

                if (vm.intersection.organizationId && !vm.intersection.organizationIds) vm.intersection.organizationIds = [vm.intersection.organizationId]

                orgIdsJoined = vm.intersection.organizationIds.join(',')
                organizationIds = vm.intersection.organizationIds
                networkId = vm.intersection.networkId
                contextParentWfid = vm.intersection.contextParentWfid
                contextParentWfids = vm.intersection.contextParentWfids
                contextParentWfidsJoined = vm.intersection.contextParentWfids ? vm.intersection.contextParentWfids.join(',') : null
                contextParentType = vm.intersection.contextParentType

                // if (vm.itemContent.type === enums.objectType.measure) {
                // 	// vm.measureUnit = vm.itemContent.childContent.symbol;
                // }
            }

            vm.isQuestion = vm.itemContent.type === enums.objectType.question
            vm.isMeasure = vm.itemContent.type === enums.objectType.measure || vm.itemContent.type === enums.objectType.relativeMeasure
            vm.isStructure = vm.itemContent.type === enums.objectType.structure

            itemStatisticsStore.bindScrollHandler()

            cachedResponse = itemStatisticsStore.getValidCachedResponse(vm.itemContent.wfid, {
                networkId,
                organizationIds: orgIdsJoined,
                contextParentWfid,
                contextParentWfids,
                contextParentWfidsJoined,
                contextParentType,
            })

            if (cachedResponse) {
                // vm.totals = cachedResponse.totals;
                // vm.totalCount = cachedResponse.totalCount;
                // vm.totalsById = cachedResponse.totalsById;
                // vm.latestMeasurePeriodDataSet = cachedResponse.latestMeasurePeriodDataSet;
                vm.loaded = true
                buildTotalsHtml(cachedResponse.preparedTotals)
            }
            else {
                if (vm.itemContent.type === 71) {

                    // There is a scenario when a Worldfavor admin in Data Collector picks items from GRI Standards as childrenByUser on behalf of each organization in the Data Collector.
                    // These picked items are then used to drive the content of a Requirement Package so that each organization gets an influence with the items there were selected just for them.
                    // In that scenario the sub-items of the structure have to be childrenByUser so that they are part of the main tree in hierarchical but the wfItemStatstics component
                    // by default only checks for relatedContentByUser items on structures. To overcome this, the condition uiSettings.includeStatisticsInAggregated is used to force
                    // the component to include the statistics for structure and not loadSubItemsKind to setting enums.subItemsKind.relatedContentByUser.
                    if (_.get(vm.itemContent, 'conditions.uiSettings.includeStatisticsInAggregated')) {
                        loadSubItemsKind = enums.subItemsKind.childrenByUser
                    }
                    else {
                        loadSubItemsKind = enums.subItemsKind.relatedContentByUser
                    }
                }

                itemStatisticsStore.addQueueItem({
                    id: $scope.$id,
                    element: $element[0],
                    trigger() {
                        xhrRequest = apiProxy.raw('multi.getObject', {
                            objectId: vm.itemContent.id,
                            objectType: vm.itemContent.type,
                            onlyStatistics: true,
                            loadSubItemsKind,
                            getterConditions: {
                                convertMeasureAnswerUnits: true,
                            },
                            ticket: {
                                networkId,
                                organizationIds,
                                contextParentWfid,
                                contextParentWfids,
                                contextParentType,
                            },
                        })

                        xhrRequest.then((res) => {
                            vm.preparedTotals = handleStatisticsObject(res, vm.itemRelation)

                            // vm.totals = _.reject(_.get(res, "metadata.statistics.totals"), { id: "count" });
                            // vm.totalCount = _.get(res, "metadata.statistics.count");
                            // vm.totalsById = _.chain(vm.totals).keyBy("id").mapValues("value").value();
                            // vm.latestMeasurePeriodDataSet = _.first(_.get(_.last(_.get(res, "metadata.statistics.datasets"), { id: "period" }), "data"));

                            itemStatisticsStore.addToStore(vm.itemContent.wfid, {
                                // totals: vm.totals,
                                // totalCount: vm.totalCount,
                                // latestMeasurePeriodDataSet: vm.latestMeasurePeriodDataSet,
                                networkId,
                                organizationIds: orgIdsJoined,
                                contextParentWfid,
                                contextParentWfids,
                                contextParentWfidsJoined,
                                contextParentType,
                                preparedTotals: vm.preparedTotals,
                            })

                            buildTotalsHtml(vm.preparedTotals)
                            vm.loaded = true

                            $timeout()
                        })

                        return xhrRequest
                    },
                })
            }
        }

        vm.$onDestroy = function() {
            if (xhrRequest) {
                xhrRequest.abort()
                xhrRequest = undefined
            }

            itemStatisticsStore.destroyQueueItem($scope.$id)
        }

        function buildTotalsHtml(totalsArray) {
            let
                output = ''

            let itemWidth

            let styleAttr

            const inconsistentClass = 'inconsistent-data'

            const tooltipText = $translate.instant('modules.itemStatistics.inconsistentDataTooltipText')

            if (!interpolateFunc) {
                interpolateFunc = $interpolate([
                    '<div class="total-item"{{styleAttr}} title="{{constructedTooltipText}}">',
                    '<div class="total-value {{inconsistentClass}}">{{shortValue}}{{infoMessageHtml}}</div>',
                    '<div class="total-title">{{title}}</div>',
                    '<div class="total-subTitle">{{subTitle}}</div>',
                    '</div>',
                ].join(''))
            }

            if (vm.useEqualWidths) {
                itemWidth = 100 / totalsArray.length
                styleAttr = ' style="width:' + itemWidth + '%;"'
            }

            output = _.map(totalsArray, (total) => {
                if (!total.shortValue) total.shortValue = total.value

                if (styleAttr) total.styleAttr = styleAttr

                if (total.inconsistentData) {
                    total.inconsistentClass = inconsistentClass
                    total.value = tooltipText
                    total.shortValue = undefined
                }

                if (vm.isQuestion) {
                    total.constructedTooltipText = $translate.instant('modules.itemStatistics.constructedTooltipText', { percentage: total.shortValue, answer: total.title, organizationsAnswered: total.organizationsAnswered, organizationsTotal: total.organizationsTotal})
                }
                else {
                    total.constructedTooltipText = total.title
                }

                return interpolateFunc(total)
            }).join('')

            vm.constructedTotalsHtml = output
        }

        function handleStatisticsObject(item, relation) {
            let
                output = []

            const statistics = _.get(item, 'metadata.statistics')

            let total

            let unit

            let relationHasValidSettings

            const inconsistendDataMessageHtml = '<div class=\'item-statistics-info-message\'> <span class=\'main-text\'> ' + $translate.instant('modules.itemStatistics.inconsistentData') + ' <i class=\'fas fa-info-circle\'></i> </span> </div>'

            if (!statistics || !statistics.totals) return

            if (item.type === enums.objectType.structure && !vm.preloadedTotals) {
                if (relation && (_.get(relation, 'settings.attachObjectTypes') || _.get(relation, 'originalRelation.settings.attachObjectTypes'))) relationHasValidSettings = true

                if (relationHasValidSettings || _.get(item, 'conditions.objectTypes') || _.get(item, 'conditions.uiSettings.includeStatisticsInAggregated') || statistics.totals) {
                    output = [
                        { value: statistics.count, title: $translate.instant('Added') },
                    ]

                    total = _.find(statistics.totals, { id: 'organizationCount' })

                    if (total) {
                        output.push({ value: total.value, title: $translate.instant('Organizations') })
                    }
                }
            }
            else if (item.type === enums.objectType.question) {
                output = _.chain(_.reject(statistics.totals, { id: 'count' })).map((total) => {
                    const questionAnswerTypeId = parseInt(total.id.split('-')[1])
                    const answerText = wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId)
                    vm.statisticsValue = total.value

                    if (answerText) {
                        return {
                            value: numeral(total.percentage).format('0,0.[000]') + '%',
                            title: answerText,
                            subTitle: total.value + ' / ' + statistics.count,
                            organizationsAnswered: total.value,
                            organizationsTotal: statistics.count,
                        }
                    }
                }).compact().value()
            }
            else if (item.type === enums.objectType.measure || item.type === enums.objectType.relativeMeasure) {
                vm.latestMeasurePeriodDataSet = _.last(_.get(_.last(statistics.datasets, { id: 'period' }), 'data'))
                const periodDataset = _.find(statistics.datasets, { id: 'period' })

                if (vm.latestMeasurePeriodDataSet) {
                    unit = _.get(item, 'unit.symbol')

                    if (unit && unit !== '%') unit = ' ' + unit

                    output = [
                        { value: vm.latestMeasurePeriodDataSet.average, title: $translate.instant('Average'), subTitle: vm.latestMeasurePeriodDataSet.year, inconsistentData: periodDataset && periodDataset.inconsistent },
                        unit && unit === '%' ? undefined : { value: vm.latestMeasurePeriodDataSet.sum, title: $translate.instant('Sum'), subTitle: vm.latestMeasurePeriodDataSet.year, inconsistentData: periodDataset && periodDataset.inconsistent },
                        { value: vm.latestMeasurePeriodDataSet.organizationCount, title: $translate.instant('Organizations'), subTitle: vm.latestMeasurePeriodDataSet.year, id: 'orgCount' },
                    ]

                    output = _.compact(output)

                    _.each(output, (total) => {
                        let hasDecimals = false

                        if (total.inconsistentData) {
                            total.value = ''
                            total.shortValue = ''
                            total.infoMessageHtml = inconsistendDataMessageHtml
                            return
                        }

                        if (typeof total.value === 'number') {
                            hasDecimals = total.value % 1 !== 0 // Check if the value has decimals

                            if (hasDecimals) total.value = _.round(total.value, 1)

                            if (Math.abs(total.value) >= 10000) total.shortValue = numeral(total.value).format('0.0a')
                            else total.shortValue = numeral(total.value).format('0,0.[0000]')

                            if (total.id != 'orgCount') {
                                // if (hasDecimals)
                                total.value = numeral(total.value).format('0,0.[0000]')
                                // else

                                if (unit) {
                                    total.value += unit
                                    total.shortValue += unit
                                }
                            }
                        }
                        else total.shortValue = total.value

                    })
                }
            }

            _.each(output, (total) => {
                for (const key in total) {
                    total[key] = $sanitize(total[key])
                }
            })

            return output
        }
    }

    itemStatisticsStore.$inject = []

    function itemStatisticsStore() {
        let
            xhrQueue = []

        let requestInProgress = false

        let timer_triggerNextRequest

        const responseCache = {}

        let scrollEventHandlerBound

        let scrollableContainer

        let currentOngoingRequestSpec

        const maxAgeForCachedResponse_minutes = 5

        let scrollingInModal = false

        const service = {
            xhrQueue,
            triggerNextReqeust,
            sortQueue,
            bindScrollHandler,
            unbindScrollHandler,
            elementInViewport,
            elementInContainer,
            destroyQueueItem,
            addQueueItem,
            getValidCachedResponse,
            addToStore,
        }

        return service

        function triggerNextReqeust() {
            const requestSpec = xhrQueue[0]

            if (requestSpec && !requestSpec.elementOutsideView) {
                currentOngoingRequestSpec = requestSpec
                xhrQueue.shift()
                requestInProgress = true
                requestSpec.xhr = requestSpec.trigger()
                requestSpec.xhr.then(() => { // Success
                    if (requestSpec === currentOngoingRequestSpec) currentOngoingRequestSpec = undefined
                    // $(requestSpec.element).children().css({ "border": "2px solid lime" });
                    triggerNextReqeust()
                }, () => { // Failed
                    if (requestSpec === currentOngoingRequestSpec) currentOngoingRequestSpec = undefined

                    // $(requestSpec.element).children().css({ "border": "2px solid gray" });
                    triggerNextReqeust()
                })
            }
            else requestInProgress = false
        }

        function sortQueue() {
            _.each(xhrQueue, (queueItem) => {
                queueItem.elementOutsideView = !elementInContainer(queueItem.element)
                // if (queueItem.elementOutsideView) {
                // 	$(queueItem.element).children().css({ "border": "2px solid red" });
                // }
                // else {
                // 	$(queueItem.element).children().css({ "border": "2px solid #E6C900" });
                // }
            })
            if (currentOngoingRequestSpec && !elementInContainer(currentOngoingRequestSpec.element)) {
                // console.log("aborted");
                if (currentOngoingRequestSpec.xhr) {
                    currentOngoingRequestSpec.xhr.abort()
                    currentOngoingRequestSpec.xhr = undefined
                    requestInProgress = false
                    xhrQueue.push(currentOngoingRequestSpec)
                }
            }
            xhrQueue = _.sortBy(xhrQueue, 'elementOutsideView', 'id')

            if (!requestInProgress) triggerNextReqeust()
            // console.log(xhrQueue)
        }

        function addToStore(itemKey, data) {
            const now = new Date().getTime() / 1000.0 / 60.0
            _.remove(xhrQueue, { id: data.id })

            data.addedAt = now

            responseCache[itemKey].push(data)
        }

        function getValidCachedResponse(itemKey, ticket) {
            let
                cachedResponse

            let now

            if (!responseCache[itemKey]) responseCache[itemKey] = []

            cachedResponse = _.find(responseCache[itemKey], {
                networkId: ticket.networkId,
                organizationIds: ticket.organizationIds,
                contextParentWfid: ticket.contextParentWfid,
                contextParentWfids: ticket.contextParentWfids,
                contextParentType: ticket.contextParentType,
            })

            if (cachedResponse) {
                now = new Date().getTime() / 1000.0 / 60.0

                if (now - cachedResponse.addedAt > maxAgeForCachedResponse_minutes) { // Check if the cached response is older than 5 minutes
                    _.remove(responseCache[itemKey], cachedResponse)
                    cachedResponse = undefined
                }
            }

            return cachedResponse
        }

        function addQueueItem(spec) {
            setTimeout(() => {
                xhrQueue.push(spec)

                if (!requestInProgress) triggerNextReqeust()
            }, 0)
        }

        function destroyQueueItem(id) {
            _.remove(xhrQueue, { id })

            if (xhrQueue.length === 0) requestInProgress = false
            unbindScrollHandler()

            // if (timer_triggerNextRequest) {
            clearTimeout(timer_triggerNextRequest)
            setTimeout(() => {
                if (xhrQueue.length > 0 && !requestInProgress) {
                    triggerNextReqeust()
                }
            }, 100)
            // }
        }

        function bindScrollHandler() {
            if (!scrollEventHandlerBound) {
                scrollEventHandlerBound = true

                scrollableContainer = $('div.wf-modal.modal')
                if (!scrollableContainer.length) {
                    scrollableContainer = $(window)
                    scrollingInModal = false
                }
                else scrollingInModal = true

                scrollableContainer.on('scroll.wfItemStatistics', _.throttle(() => {
                    sortQueue()
                }, 300))
            }
        }

        function unbindScrollHandler() {
            scrollEventHandlerBound = false
            if (scrollableContainer) scrollableContainer.off('scroll.wfItemStatistics')
        }

        // Determine if any part of the element is visible in the viewport
        function elementInViewport(el) {
            let top = el.offsetTop
            let left = el.offsetLeft
            const width = el.offsetWidth
            const height = el.offsetHeight

            while (el.offsetParent) {
                el = el.offsetParent
                top += el.offsetTop
                left += el.offsetLeft
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
				left < (window.pageXOffset + window.innerWidth) &&
				(top + height) > window.pageYOffset &&
				(left + width) > window.pageXOffset
            )
        }

        // Determine if any part of the element is visible in a scrollable element or in the viewport
        function elementInContainer(elem, partial)
        {
            if (scrollingInModal) {
                partial = true
                const container = scrollableContainer
                const contHeight = container.height()
                const contTop = container.scrollTop()
                const contBottom = contTop + contHeight

                const elemTop = $(elem).offset().top - container.offset().top
                const elemBottom = elemTop + $(elem).outerHeight()

                const isTotal = (elemTop >= 0 && elemBottom <= contHeight)
                const isPart = partial && ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.height()))

                return  isTotal  || isPart
            }
            else return elementInViewport(elem)
        }
    }
})()
