//@flow
import * as React from 'react'
import Truncate from 'react-truncate'
import { withStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'

type Props = {
  children: string | React.Node,
  lines: number,
  readLessText?: string,
  readMoreText?: string,
  width?: number,
  trimWhitespace?: boolean,
  className?: string,
  hideReadMore?: boolean,
}

const styles = {
    readMore: {
        color: Colors.blue,
        marginLeft: 5,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    readLess: {
        color: Colors.blue,
        marginLeft: 5,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}

type State = {
  expanded: boolean,
  truncated: boolean,
}

class MultiLineTextEllipsis extends React.PureComponent<Props, State> {
  static defaultProps = {
      lines: 3,
      hideReadMore: false,
  }

  state = {
      expanded: false,
      truncated: false,
  }

  truncateRef = null

  componentDidMount() {
      window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize)
  }

  toggleText = () => {
      const { truncated, expanded } = this.state
      if (truncated || expanded) {
          this.setState({ expanded: !expanded })
      }
  }

  handleTruncate = (truncated) => {
      if (!this.props.hideReadMore && this.state.truncated !== truncated) {
          this.setState({
              truncated,
          })
      }
  }

  toggleLines = (event) => {
      event.preventDefault()

      this.setState({
          expanded: !this.state.expanded,
      })
  }

  onWindowResize = () => {
      this.truncateRef && this.truncateRef.onResize()
  }

  render() {
      const { expanded, truncated } = this.state
      const {
          children,
          hideReadMore,
          readMoreText,
          readLessText,
          lines,
          classes,
          trimWhitespace,
          width,
          className,
      } = this.props

      return (
          <div className={className}>
              <Truncate
                  ref={ref => this.truncateRef = ref}
                  lines={!expanded && lines}
                  onTruncate={this.handleTruncate}
                  trimWhitespace={trimWhitespace}
                  width={width}
                  ellipsis={(
                      <span>
                          { '...' }
                          {
                              !hideReadMore && (
                                  <span className={classes.readMore} onClick={this.toggleLines}>
                                      { readMoreText || <FormattedMessage id="general.showMore" /> }
                                  </span>
                              )
                          }
                      </span>
                  )}
              >
                  {children}
              </Truncate>
              {
                  !truncated && expanded && !hideReadMore && (
                      <span className={classes.readLess} onClick={this.toggleLines}>
                          { readLessText || <FormattedMessage id="general.showLess" /> }
                      </span>
                  )
              }
          </div>
      )
  }
}

export default withStyles(styles)(MultiLineTextEllipsis)
