// The purpose of the directive is to show one or more buttons for adding relatedContentByUser to a question or measure.
// Existing related content will be shown as a list.
// The directive is dependent on hierarchical controller.

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfInfluenceMoreInformation', wfInfluenceMoreInformation)

    wfInfluenceMoreInformation.$inject = []
    function wfInfluenceMoreInformation() {
        const directive = {
            templateUrl: 'scripts/wf/influence/wfInfluenceMoreInformation.directive.html',
            restrict: 'E',
            link,
        }
        return directive

        function link($scope, $attrs, $element) {
            let i = 0
            if ($scope.vm.item.type === 11 || $scope.vm.item.type === 21) {
                tryAccessingVm()
            }

            function tryAccessingVm() {
                if ($scope.vm.item.type === 11) {
                    $scope.moreInfoAdderVm = $scope.questionAnsweringVm
                }
                else if ($scope.vm.item.type === 21) {
                    $scope.moreInfoAdderVm = $scope.measureAnsweringVm
                }
				
                if (!$scope.moreInfoAdderVm) {
                    i++
                    if (i === 10) return
	
                    // console.log($scope.vm.item.type, "retry", i)
                    setTimeout(() => {
                        tryAccessingVm()
                    }, 100)
                }
                else {
                    // console.log($scope.vm.item.type, $scope.moreInfoAdderVm);
                }
            }
        }
    }
})()
