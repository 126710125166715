import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('InternalValueChainUsersController', InternalValueChainUsersController)

    InternalValueChainUsersController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q',
        'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$state', '$sanitize', '$ngBootbox', '$window', 'valueChainService', '$interpolate']
    function InternalValueChainUsersController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q,
        wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state, $sanitize, $ngBootbox, $window, valueChainService, $interpolate) {
        const
            vm = this
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const itemsToEject = []

        $scope.moment = moment
		
        _.assign(vm, {
            window: $window,
            loaded: false,
            selectedParentId: undefined,
            userCompilers: {},
            mailSettings: {},
            openedWithParent: undefined,
            network: undefined,
            isWorldfavorAdmin: authOrgId === 4536,

            filters: {
                parents: [],
                status: [],
                packages: [],
                selectedFilters: {},
                selectedParents: [],
            },

            // IDs
            filterUserIds: [],
            organizationIds: [],
            userWFIDs: [],
            allUserIdsInValueChain: [],

            // Arrays
            allUsersInValueChain: [],
            allOrgRegistrationNumbers: [],
            allInfluences: [],
            filteredUsers: [],
            organizations: [],
            organizationsWithFailedMail: [],

            // Distinct arrays
            distinctInfluenceStructures: [],
            distinctParents: [],

            // Lookup objects
            failedMailsByOrg: {},
            influencesByUser: {},
            influenceStructuresById: {},
            organizationsById: {},
            organizationsByParent: {},
            organizationsByAnything: {},
            parentsByOrg: {},
            usersByOrg: {},
            removingOrganizations: {},

            // Functions
            filterOnAnything,
            openInfluence,
            openMailHistory,
            changeContactPerson,
            changeCategories,
            createInfluence,
            sendMailToUser,
            addOrganization,
            editOrganization,
            removeOrganization,
            deleteInfluence,
            admin_setRootObject,
        })

        console.time('Load data collector')
        activate()

        ////////////////

        function activate() {
            // $scope.$on("objectUpdated", function ($event, obj) {
            // 	if (_.includes(allWfids, obj.wfid)) {
            // 		resyncEverything();
            // 	}
            // })

            $scope.$on('$destroy', () => {
                // var leftToEject, counter = 0;
                const orgWfids = _.map(vm.allUsersInValueChain, 'wfid')
                if (vm.network) {
                    // dataOps.eject(vm.network.wfid);

                    // leftToEject = vm.allInfluences.length;
                    // while (leftToEject--) {
                    // 	if (wfObject.eject(vm.allInfluences[leftToEject].wfid))
                    // 		counter++;
                    // }
                    // console.log("[Eject from cache] Influences", counter); // Influences

                    // counter = 0;
                    // leftToEject = orgWfids.length;
                    // while (leftToEject--) {
                    // 	if (wfObject.eject(orgWfids[leftToEject]))
                    // 		counter++;
                    // }
                    // console.log("[Eject from cache] Organizations", counter); // Organizations

                    // // console.log("[Eject from cache] Influences", wfObject.ejectAll({ where: { type: 13, wfid: { "in": _.map(vm.allInfluences, "wfid") }}}).length); // Influences
                    // // console.log("[Eject from cache] Organizations", wfObject.ejectAll({ where: { type: 101, wfid: { "in": orgWfids }}}).length); // Organizations

                    // // console.log("[Eject from cache] Network relations", wfObject.ejectAll({ where: { type: 73, wffid: vm.network.wfid }}).length); // Network relations
                    // // console.log("[Eject from cache] Relations where orgs are children", wfObject.ejectAll({ where: { type: 73, wfcid: { "in": orgWfids }}}).length); // Relations where orgs are children
                    // // console.log("[Eject from cache] Network notifications", wfObject.ejectAll({ where: { type: enums.objectType.notification, channelId: vm.network.id }}).length); // Network notifications
                }
            })

            vm.pageHeaderPreTitleItem = wfObject.get('71-' + valueChainService.ids.internalValueChainRootStructure)
            if (vm.pageHeaderPreTitleItem) {
                $rootScope.setPageTitle(vm.pageHeaderPreTitleItem.title)
            }

            valueChainService.loadInternalValueChainUsers().then((result) => {
                const
                    mainStructure = result.structure
				
                vm.pageHeaderItem = mainStructure
                $rootScope.setPageTitle((vm.pageHeaderPreTitleItem ? (vm.pageHeaderPreTitleItem.title + ' - ') : '') + vm.pageHeaderItem.title)
				
                handleInitialResponse(result)
            })
        }

        function handleInitialResponse(obj) {
            vm.userWFIDs = _.chain(obj.users).uniqBy('childId').orderBy(['createdAt'], ['desc']).map('wfcid').value()
            vm.allUsersInValueChain = wfObject.filter({ where: { type: enums.objectType.individual, wfid: { in: vm.userWFIDs } } })
            vm.allUserIdsInValueChain = _.map(vm.allUsersInValueChain, 'id')
			
            //  _.chain(obj.childs).uniqBy("childId").orderBy([ "createdAt" ], [ "desc" ]).map("childContent").value();

            if (vm.openedWithParent) {
                vm.userWFIDs = _.chain(vm.openedWithParent.childs).uniqBy('childId').orderBy(['createdAt'], ['desc']).map('wfcid').value()
                vm.users = wfObject.filter({ where: { type: enums.objectType.individual, wfid: { in: vm.userWFIDs } } })
                // vm.users = _.chain(vm.openedWithParent.childs).uniqBy("childId").orderBy([ "createdAt" ], [ "desc" ]).map("childContent").value();
            }
            else { // Overview mode, all organizations i Data Collector
                vm.users = vm.allUsersInValueChain
            }

            // console.log(vm.userWFIDs);

            vm.userIds = _.map(vm.users, 'id')
            vm.usersById = _.keyBy(vm.users, 'id')

            vm.filteredUsers = vm.users
            // filterOnAnything();

            // var userRelations = wfObject.filter({ where: {
            // 	childType: enums.objectType.individual,
            // 	parentType: enums.objectType.organization,
            // 	wffid: { "in": vm.userWFIDs }
            // } });
            // var usersById = _.keyBy(wfObject.filter({ where: { type: enums.objectType.individual } }), "id");

            // vm.usersByOrg = _.chain(userRelations).groupBy("parentId").mapValues(function (userDataRelations) {
            // 	var
            // 		output = [],
            // 		userIds = _.map(userDataRelations, "childId")
            // 	;
            // 	for (var i = 0, len = userIds.length; i < len; i++) {
            // 		output.push(usersById[userIds[i]])
            // 	}
            // 	return output;
            // }).value();

            loadDataDependenciesAsync(() => {
                vm.loaded = true
				
                syncDistinctInfluenceStructures()
                // syncFilterBars();

                // _.assign(vm.usersByAnything, {
                // 	"failedMails": vm.usersWithFailedMail
                // })

                filterOnAnything()
                $timeout()
                console.timeEnd('Load data collector')
                // console.log(vm.userCompilers)

                // 	loadNotificationsAsync();
            })
        }

        function loadDataDependenciesAsync(resolve) {
            if (vm.userIds.length) {
                dataOps.getObjects({
                    requestSignature_noResultNeeded: ['valueChain', 'internal', 'influences'].join('_'),
                    objectType: enums.objectType.influence,
                    wrapInRelations: false,
                    getterConditions: {
                        userIds: vm.userIds,
                    },
                    // bypassCache: true
                }).then(() => {
                    vm.allInfluences = wfObject.filter({ where: { type: 13, creatorOrganizationId: authOrgId, userId: { in: vm.userIds }, isInternal: true } })
                    vm.influencesByUser = _.groupBy(vm.allInfluences, 'userId')

                    // Get structures used to influence with
                    dataOps.getObjects(_.chain(vm.allInfluences).uniqBy('wfcid').map((influence) => {
                        return influence.objectType + '-' + influence.objectId
                    }).value()).then(() => {
						
                        // Get influences made to the organizations made by the auth org
                        // Get all MailSettings for the network
                        dataOps.getObjects({
                            requestSignature_noResultNeeded: ['valueChain', 'internal', 'mailSettings'].join('_'),
                            objectType: enums.objectType.mailSettings,
                            wrapInRelations: false,
                        }).then(() => {
                            vm.mailSettings = _.keyBy(wfObject.filter({ where: { type: enums.objectType.mailSettings, networkId: null } }), 'mailPurpose')
                            resolve()
                        })

                        return
                        // Get failed emails.
                        // The result is an array with outboundMail items - one per organization that has at least one failed mail.
                        // If an organization have several failed mails then only the first one is included.
                        apiProxy('multi.getObjects', {
                            objectType: enums.objectType.mailOutbound,
                            wrapInRelations: false,
                            organizationIds: vm.userIds,
                        }).then((mailOutboundResult) => {

                            _.forEach(mailOutboundResult, (mailOutbound) => {
                                // console.log(_.chain(vm.usersByOrg[mailOutbound.toOrganizationId]).map("email").value(), mailOutbound.toEmail);
                                if (_.chain(vm.usersByOrg[mailOutbound.toOrganizationId]).map('email').includes(mailOutbound.toEmail).value()) {
                                    vm.failedMailsByOrg[mailOutbound.toOrganizationId] = vm.failedMailsByOrg[mailOutbound.toOrganizationId] || {}
                                    vm.failedMailsByOrg[mailOutbound.toOrganizationId][mailOutbound.toEmail] = mailOutbound
									
                                    vm.usersWithFailedMail.push(_.find(vm.users, { id: mailOutbound.toOrganizationId }))
                                }
                            })

                            // Get all MailSettings for the network
                            dataOps.getObjects({
                                requestSignature_noResultNeeded: ['valueChain', vm.network.id, 'mailSettings'].join('_'),
                                objectType: enums.objectType.mailSettings,
                                wrapInRelations: false,
                                getterConditions: {
                                    networkId: vm.network.id,
                                },
                            }).then(() => {
                                vm.mailSettings = _.keyBy(wfObject.filter({ where: { type: enums.objectType.mailSettings, networkId: vm.network.id } }), 'mailPurpose')

                                resolve()
                            })

                        })
                    })
                })
            }
            else {
                resolve()
            }
        }

        function syncFilterBars() {
            console.time('syncFilterBars')
            const
                allParentRelations = wfObject.filter({ where: {
                    type: enums.objectType.dataRelation,
                    parentType: enums.objectType.structure,
                    childType: enums.objectType.organization,
                    wfcid: { in: vm.userWFIDs },
                } })
				
            const allParentWFIDs = _.map(allParentRelations, 'wffid')
				
            const distinctParentWFIDs = _.uniq(allParentWFIDs)
				
            let distinctParents = _.chain(wfObject.filter({ where: {
                type: enums.objectType.structure,
                wfid: { in: distinctParentWFIDs },
            } })).sortBy('title').map((item) => {
                return {
                    type: 'parent',
                    title: item.title,
                    wfid: item.wfid,
                    id: item.wfid,
                    objectId: item.id,
                }
            }).value()
				
            const distinctParentsByWfid = _.keyBy(distinctParents, 'wfid')

            // distinctParents = _.chain(allParents)
            // 	.uniqBy("parentId")
            // 	.map("parentContent")
            // 	.sortBy("title")
            // 	.value();

            if (vm.openedWithParent) {
                // The opened category should not appear as a filter button. So it gets excluded here.
                distinctParents = _.filter(distinctParents, (item) => {
                    return item.id !== vm.openedWithParent.id
                })
            }

            vm.distinctParents = distinctParents
            vm.distinctParents.unshift({
                title: $translate.instant('All'),
                type: 'parent',
                count: vm.users.length,
                id: 0,
                wfid: 0,
            })

            vm.filters.parents = vm.distinctParents

            vm.usersByAnything = _.chain(allParentRelations)
                .groupBy('wffid')
                .mapValues((items, wfid) => {
                    const output = []; let parent
                    for (let i = 0, len = items.length; i < len; i++) {
                        output.push(vm.usersById[items[i].childId])
                    }

                    parent = _.find(distinctParents, { wfid })
                    if (parent) {
                        parent.count = output.length
                        // console.log(parent.title, parent.count);
                    }

                    return output
                }).value()

            vm.parentsByOrg = _.chain(allParentRelations)
                .groupBy('childId')
                .mapValues((items) => {
                    const output = []
                    for (let i = 0, len = items.length; i < len; i++) {
                        output.push(distinctParentsByWfid[items[i].wffid])
                    }
                    return output
                }).value()

            vm.usersByAnything[0] = _.clone(vm.users)

            vm.filters.packages = []

            _.each(vm.distinctInfluenceStructures, (structure) => {
                const
                    organizations = []
					
                const orgIds = _.chain(vm.allInfluences).filter({ objectId: structure.id }).map('organizationId').uniq().value()
					
                let filterButton

                filterButton = {
                    type: 'package',
                    id: structure.wfid,
                    // type: structure.type,
                    wfid: structure.wfid,
                    title: structure.title,
                    hasImage: structure.hasImage,
                    imageUrl: structure.imageUrl,
                    count: orgIds.length,
                }
                vm.filters.packages.push(filterButton)

                for (let i = 0, len = orgIds.length; i < len; i++) {
                    organizations.push(vm.usersById[orgIds[i]])
                }
				
                vm.usersByAnything[structure.wfid] = organizations
            })

            const statusTitlesByStatus = {
                // "influences_withDueDate": $translate.instant("modules.valueChain.dueDate"),
                influences_fulfilled: $translate.instant('Fulfills'),
                influences_progress: $translate.instant('modules.valueChain.partiallyFulfilled'),
                influences_overdue: $translate.instant('modules.valueChain.requireAction'),
                influences_withException: $translate.instant('Exception'),
            }
            const statusIconsByStatus = {
                // "influences_withDueDate": $translate.instant("modules.valueChain.dueDate"),
                influences_fulfilled: 'fas fa-check fulfilled',
                influences_progress: 'fa fa-minus-circle hasProgress',
                influences_overdue: 'fa fa-warning overdue',
                influences_withException: 'fa fa-exclamation specialRequirements',
            }
            const influencesByStatus = _.chain(statusTitlesByStatus).clone().mapValues(() => {
                return []
            }).value()
            vm.filters.statuses = []

            _.each(vm.allInfluences, (influence) => {
                const
                    withDueDate = !influence.fulfilled && influence.fulfillmentDueAt && !influence.isOverdue
					
                const progress = !influence.fulfilled && influence.fulfillmentProgress < influence.fulfillmentProgressTotal
					
                const overdue = !influence.fulfilled && influence.isOverdue

                if (influence.containsSpecialRequirements) influencesByStatus['influences_withException'].push(influence)

                if (influence.fulfilled) influencesByStatus['influences_fulfilled'].push(influence)
                else {
                    // if (withDueDate)
                    // 	influencesByStatus["influences_withDueDate"].push(influence);
                    if (progress) influencesByStatus['influences_progress'].push(influence)
                    if (overdue) influencesByStatus['influences_overdue'].push(influence)
                }
            })

            _.each(influencesByStatus, (influences, key) => {
                const
                    organizations = []
					
                const orgIds = _.chain(influences).map('organizationId').uniq().value()

                for (let i = 0, len = orgIds.length; i < len; i++) {
                    organizations.push(vm.usersById[orgIds[i]])
                }
				
                vm.usersByAnything[key] = organizations
                vm.filters.statuses.push({
                    type: 'status',
                    id: key,
                    wfid: key,
                    title: statusTitlesByStatus[key],
                    count: organizations.length,
                    iconCssClass: statusIconsByStatus[key],
                })
            })

            // if (vm.usersWithFailedMail.length) {
            // 	vm.filters.statuses.unshift({
            // 		type: "status",
            // 		id: "failedMails",
            // 		wfid: "failedMails",
            // 		title: $translate.instant("modules.valueChain.failedMails"),
            // 		count: vm.usersWithFailedMail.length,
            // 		iconCssClass: "fa fa-times-circle failedMails"
            // 	});
            // }
            // var
            // 	allParents = _.filter(wfObject.filter({ where: {
            // 		type: enums.objectType.dataRelation,
            // 		parentType: enums.objectType.structure,
            // 		childType: enums.objectType.organization,
            // 		childId: { "in": vm.userIds }
            // 	}}), function (item) {
            // 		return item.parentContent;
            // 	}),
            // 	distinctParents
            // ;

            // distinctParents = _.chain(allParents)
            // 	.uniqBy("parentId")
            // 	.map("parentContent")
            // 	.sortBy("title")
            // 	.value();

            // if (vm.openedWithParent) {
            // 	distinctParents = _.filter(distinctParents, function (item) {
            // 		return item.id !== vm.openedWithParent.id;
            // 	});
            // }

            // vm.distinctParents = distinctParents;
            // vm.distinctParents.unshift({
            // 	title: $translate.instant("All"),
            // 	wfid: 0
            // });

            // vm.usersByAnything = _.chain(allParents)
            // 	.groupBy("wffid")
            // 	.mapValues(function (items) {
            // 		return _.map(items, "childContent");
            // 	}).value();

            // vm.parentsByOrg = _.chain(allParents)
            // 	.groupBy("childId")
            // 	.mapValues(function (items) {
            // 		return _.map(items, "parentContent");
            // 	}).value();

            // vm.usersByAnything[0] = _.clone(vm.users);
            console.timeEnd('syncFilterBars')
        }

        function syncDistinctInfluenceStructures() {
            let
                distinctStructures

            distinctStructures = _.chain(vm.allInfluences)
            // .filter({ objectType: enums.objectType.structure })
                .uniqBy('objectId')
                .map('childContent')
                .filter((item) => {
                    return item
                })
                .sortBy('title')
                .value()

            vm.distinctInfluenceStructures = distinctStructures
            vm.influenceStructuresById = _.keyBy(distinctStructures, 'id')
        }

        function filterOnAnything(filter, force) {
            // var
            // 	intersectedWithSearch = [],
            // 	intersectedWithParents = [],
            // 	intersectedWithStatus = [],
            // 	intersectedWithPackages = [],
            // 	arraysToIntersect = [],
            // 	intersectionItems = [],
            // 	id
            // 	// selectedLevelFiltersBeforeIntersection = _.map(levels, "selectedFilter")
            // ;

            // // _.each(vm.aggregatedParents.itemsByParentWfid, function (value, key) {
            // // 	console.info("---", _.find(value, { wfid: "71-116" }));
            // // });

            // if (_.isEmpty(vm.filters.selectedFilters)) {
            // 	filter = _.find(vm.filters.parents, { id: 0 });
            // }

            // // console.log(filter ? filter.type : null, filter)

            // if (filter && typeof filter !== "undefined") {
            // 	id = filter.id;

            // 	if (vm.filters.selectedFilters[id]) {
            // 		// console.info("delete", filter.id, vm.aggregatedParents.selectedFiltersById[filter.id])
            // 		if (filter.type == "parent" && filter.id == 0 && vm.filters.selectedFilters[filter.id]) {
            // 			return;
            // 		}
            // 		delete vm.filters.selectedFilters[id];
            // 		_.remove(vm.filters.selectedParents, filter);

            // 		if (vm.filters.selectedParents.length == 0) {
            // 			var allButton = _.find(vm.filters.parents, { id: 0 });
            // 			vm.filters.selectedFilters[allButton.id] = allButton;
            // 			vm.filters.selectedParents.push(allButton);
            // 		}
            // 		// console.info("deleteed?", filter.id, vm.aggregatedParents.selectedFiltersById[filter.id])
					
            // 	}
            // 	else {
            // 		//  vm.aggregatedParents.selectedFiltersById[filter.id] = true
            // 		// console.log(filter.id, vm.aggregatedParents.selectedFiltersById[filter.id], vm.aggregatedParents.itemsByParentWfid[filter.id])
            // 		if (filter.type == "parent" && filter.id != 0) {
            // 			var allButton = _.find(vm.filters.parents, { id: 0 });
            // 			delete vm.filters.selectedFilters[allButton.id];
            // 			_.remove(vm.filters.selectedParents, allButton);
            // 		}
            // 		if (filter.type == "parent" && filter.id == 0) {
            // 			_.each(vm.filters.parents, function (item) {
            // 				if (item.id != 0) {
            // 					delete vm.filters.selectedFilters[item.id];
            // 					_.remove(vm.filters.selectedParents, item);
            // 				}
            // 			})

            // 		}

            // 		vm.filters.selectedFilters[id] = filter;
            // 		vm.filters.selectedParents.push(filter);
            // 	}
            // }

            // // console.log(vm.filters.selectedFilters)
            // // console.info(vm.usersByAnything);

            // _.each(vm.filters.selectedFilters, function (value, key) {
            // 	var array;
            // 	switch (value.type) {
            // 		case "searchFilter":
            // 			array = intersectedWithSearch;
            // 			break;
            // 		case "parent":
            // 			array = intersectedWithParents;
            // 			break;
            // 		case "status":
            // 			array = intersectedWithStatus;
            // 			break;
            // 		case "package":
            // 			array = intersectedWithPackages;
            // 			break;
            // 	}
            // 	// console.info(_.find(vm.aggregatedParents.itemsByParentWfid[key], { wfid: "71-116" }));
            // 	// console.info(value.type, key);
            // 	Array.prototype.push.apply(array, vm.usersByAnything[key]);
            // });

            // // console.log(vm.aggregatedParents.itemsByParentWfid);

            // if (intersectedWithSearch.length > 0)
            // 	arraysToIntersect.push(intersectedWithSearch);
            // if (intersectedWithParents.length > 0)
            // 	arraysToIntersect.push(intersectedWithParents);
            // if (intersectedWithStatus.length > 0)
            // 	arraysToIntersect.push(intersectedWithStatus);
            // if (intersectedWithPackages.length > 0)
            // 	arraysToIntersect.push(intersectedWithPackages);

            // intersectionItems = _.intersection.apply(null, arraysToIntersect);

            // intersectionItems = _.chain(intersectionItems).uniq().filter(function (item) {
            // 	return !!item;
            // }).value();

            const now = moment()

            // vm.filteredUsers.length = 0;
            // vm.filterUserIds.length = 0;

            // Array.prototype.push.apply(vm.filteredUsers, intersectionItems);
            // Array.prototype.push.apply(vm.filterUserIds, _.map(vm.filteredUsers, "id"));

            vm.requiresActionCount = _.chain(vm.allInfluences).filter((influence) => {
                return !!~vm.userIds.indexOf(influence.userId) && influence.fulfillmentDueAt && now.isAfter(influence.fulfillmentDueAt, 'days') && !influence.fulfilled
            }).map('userId').uniq().value().length

            // if (vm.chartsCompiler)
            // 	vm.chartsCompiler.compile();
			
            $timeout()
        }

        function openInfluence(influence) {
            valueChainService.openInfluence(influence, vm.userCompilers[influence.userId])
        }

        function loadNotificationsAsync() {
            if (vm.userIds.length) {
                dataOps.getObjects({
                    objectType: enums.objectType.notification,
                    userIds: vm.userIds,
                }).then((notificationsResult) => {
                    // var
                    // 	wfids = _.map(eventLogResult.childs, 'childContent.wfcid') // eventLogResult > childs(dataRelations) > childContent(logEvent) > childContent(items to get)
                    // ;

                    // wfids = _.uniq(wfids);
                    // console.info(eventLogResult);
                    // dataOps.getObjects(wfids).then(function () {
                    $timeout(() => {
                        const filteredNotifications = _.map(notificationsResult, 'childContent')

                        vm.notificationsLoaded = true
                        vm.notifications = filteredNotifications
                    })

                    // });
                })
            }
            else {
                vm.notificationsLoaded = true
                vm.notifications = []
            }
			
        }

        function resyncEverything() {
            vm.allInfluences = wfObject.filter({ where: { type: 13, creatorOrganizationId: authOrgId, userId: { in: vm.userIds }, isInternal: true } })
            vm.influencesByUser = _.groupBy(vm.allInfluences, 'userId')

            vm.userWFIDs = _.map(vm.users, 'wfid')
            vm.userIds = _.map(vm.users, 'id')
            vm.usersById = _.keyBy(vm.users, 'id')
			
            // vm.usersByOrg = _.chain(wfObject.filter({ where: {
            // 	childType: enums.objectType.individual,
            // 	parentType: enums.objectType.organization,
            // 	parentId: { "in": vm.userIds }
            // }}))
            // 	.groupBy("parentId")
            // 	.mapValues(function (userDataRelations) {
            // 		return _.map(userDataRelations, "childContent")
            // 	})
            // 	.value();
			
            // var userRelations = wfObject.filter({ where: {
            // 	childType: enums.objectType.individual,
            // 	parentType: enums.objectType.organization,
            // 	wffid: { "in": vm.userWFIDs }
            // } });
            // var usersById = _.keyBy(wfObject.filter({ where: { type: enums.objectType.individual } }), "id");

            // vm.usersByOrg = _.chain(userRelations).groupBy("parentId").mapValues(function (userDataRelations) {
            // 	var
            // 		output = [],
            // 		userIds = _.map(userDataRelations, "childId")
            // 	;
            // 	for (var i = 0, len = userIds.length; i < len; i++) {
            // 		output.push(usersById[userIds[i]])
            // 	}
            // 	return output;
            // }).value();
				
            syncDistinctInfluenceStructures()
            syncFilterBars()

            filterOnAnything(null, true)
        }

        function openMailHistory(org) {
            modal.openMailHistory({
                toOrganizationId: org.id,
                name: org.name,
            })
        }

        function changeContactPerson(org) {
            valueChainService.openOrganizationUsersManager(org, vm.userCompilers[org.id]).modal.closed.then(() => {
                resyncEverything()
                vm.userCompilers[org.id].compile()
            })
        }

        function changeCategories(org) {
            valueChainService.openOrganizationCategoriesPicker(org, vm.network.id).closed(() => {
                resyncEverything()
                vm.userCompilers[org.id].compile()
            })
        }

        function createInfluence(org) {
            valueChainService.openInfluenceCreator({
                organization: org,
                networkId: vm.network.id,
                compilerControl: vm.userCompilers[org.id],
            }).modal.closed.then(() => {
                resyncEverything()
                vm.userCompilers[org.id].compile()
            })
        }

        function sendMailToUser(user) {
            modal.previewMail({
                userIds: [user.id], mailPurpose: 2,
            })

            return
            const
                formSpec = {
                    schema: {},
                    form: [],
                    // onBeforeRender: function (model) {
                    // },
                    // onBeforeSubmit: function (model) {
                    // }
                }
				
            let model

            formSchemaService.getFromType(enums.objectType.mailSettings).then((res) => {
                // model = _.assign({
                // 	organizationId: organization.id,
                // 	networkId: vm.network.id
                // }, vm.mailSettings[enums.mailPurpose.valueChainInvitation]);
                model = {
                    organizationId: authOrgId,
                    userIds: [user.id],
                    subject: vm.mailSettings[enums.mailPurpose.valueChainInvitation] ? vm.mailSettings[enums.mailPurpose.valueChainInvitation].subject : '',
                    bodyText: vm.mailSettings[enums.mailPurpose.valueChainInvitation] ? vm.mailSettings[enums.mailPurpose.valueChainInvitation].bodyText : '',
                }

                formSpec.schema = {
                    type: 'object',
                    properties: (function () {
                        return _.cloneDeep(res.schema.properties)
                    })(),
                    required: ['subject', 'bodyText'],
                }

                formSpec.form = [
                    // {
                    // 	key: "fromName",
                    // 	readonly: true
                    // },
                    // {
                    // 	key: "fromEmail",
                    // 	readonly: true
                    // },
                    {
                        key: 'subject',
                    },
                    // {
                    // 	key: "helloText",
                    // },
                    {
                        key: 'bodyText',
                        type: 'textarea',
                    },
                    // {
                    // 	key: "callToActionText",
                    // 	type: "textarea"
                    // },
                    // {
                    // 	key: "endingText",
                    // 	type: "textarea"
                    // },
                    // {
                    // 	key: "regardsText",
                    // }
                    // {
                    // 	type: "template",
                    // 	template: (function () {
                    // 		var
                    // 			templateParams,
                    // 			influencesInterpolateFunc = $interpolate("<li class='pb5 text-bold'><span class='display-inline-block pl5 pr10'>&bull;</span>{{title}}</li>"),
                    // 			influencesHtml = "",
                    // 			output
                    // 		;

                    // 		_.each(templateParams, function (value, key) {
                    // 			if (typeof value === "string")
                    // 				templateParams[key] = value.replace(/{{OrganizationName}}/g, wfAuth.getOrganization().name);
                    // 		});

                    // 		_.each(_.filter(vm.influencesByUser[organization.id], { fulfilled: false }), function (influence) {
                    // 			var infHtml = "";
                    // 			infHtml = influencesInterpolateFunc({
                    // 				title: (vm.influenceStructuresById[influence.objectId] || {}).title
                    // 			})
                    // 			influencesHtml += infHtml;
                    // 		})

                    // 		if (influencesHtml === "") {
                    // 			influencesHtml = influencesInterpolateFunc({ title: "Alla uppgifter genomförda" });
                    // 		}

                    // 		templateParams = _.assign(_.clone(model), {
                    // 			label: $translate.instant("PreviewOfMail"),
                    // 			bodyText: "{{model.bodyText}}",
                    // 			helloText: model.helloText + " {{Name}}",
                    // 			influencesHtml: influencesHtml
                    // 		});

                    // 		output = $interpolate([
                    // 			'<label class="control-label">{{label}}</label>',
                    // 			"<div class='alert alert-lightgray mail-preview'>",
                    // 			"<div class='pb10 text-bold' style='font-size: 16px;' ng-non-bindable>{{helloText}}</div>",
                    // 			"<div class='pb15'>{{bodyText}}</div>",
                    // 			"<ul class='list-unstyled'>{{influencesHtml}}</ul>",
                    // 			"<div class='pt15 pb15'>{{callToActionText}}</div>",
                    // 			"<button style='border-radius:0' class='btn btn-primary btn-sm'>{{actionButtonText}}</button>",
                    // 			"<div class='pt20 pb10'>{{endingText}}</div>",
                    // 			"<div>{{regardsText}}</div>",
                    // 			"</div>"
                    // 		].join(""))(templateParams)

                    // 		return output;
                    // 	})(),
                    // 	model: model
                    // }
                ]

                _.each(_.filter(formSpec.form, (field) => {
                    return field.readonly
                }), (field) => {
                    field.disableErrorState = true
                    field.disableSuccessState = true
                    field.htmlClass = 'readonly-simplified'
                })

                // subject: vm.mailSettings[enums.mailPurpose.valueChainInvitation] ? vm.mailSettings[enums.mailPurpose.valueChainInvitation].subject : "",
                // bodyText: vm.mailSettings[enums.mailPurpose.valueChainInvitation] ? vm.mailSettings[enums.mailPurpose.valueChainInvitation].bodyText : "",

                modal.edit(model, {
                    title: $translate.instant('modules.valueChain.actions.sendMail'),
                    action: 'suppliers.sendInviteMail',
                    customFormSpecification: formSpec,
                    bypassAdapter: true,
                    submitCaption: $translate.instant('Send'),
                }).then(() => {

                    // $ngBootbox.confirm("Skicka mail till: " + organization.name)
                    // 	.then(function(result) {
                    // 		apiProxy("suppliers.sendInviteMail", {
                    // 			organizationId: organization.id,
                    // 			networkId: vm.networkId,
                    // 			creatorOrganizationId: orgDataRelation.organizationId
                    // 		})
                    // 	}, function() {
                    // 	});
                })
            })
        }

        function editOrganization(org) {
            modal.edit(org).then(() => {
                vm.userCompilers[org.id].compile()
            })
        }

        function removeOrganization(org) {
            $ngBootbox.customDialog({
                title: $translate.instant('modules.valueChain.organizations.remove.modalTitle', { orgname: org.name }),
                message: $translate.instant('modules.valueChain.organizations.remove.modalMessage'),
                onEscape: true,
                className: 'valueChain-modal-removeOrganization',
                buttons: {
                    cancel: {
                        label: $translate.instant('No'),
                        className: 'btn-default',
                        callback() {
                        },
                    },
                    primary: {
                        label: $translate.instant('Remove'),
                        className: 'btn-danger',
                        callback() {
                            let
                                deleteCounter = 0
								
                            let total = 0
								
                            const orgDataRelation = wfObject.filter({ where: {
                                type: enums.objectType.dataRelation,
                                parentType: enums.objectType.network,
                                wffid: vm.network.wfid,
                                wfcid: org.wfid,
                            } })[0]
								
                            const checkIfAllDeleted = function () {
                                deleteCounter++
                                if (total === deleteCounter) {
                                    _.remove(vm.allUsersInValueChain, org)
                                    _.remove(vm.users, org)

                                    delete vm.removingOrganizations[org.id]

                                    resyncEverything()
                                }
                            }

                            vm.removingOrganizations[org.id] = true

                            if (orgDataRelation) {
                                // Remove from network
                                // console.log(52, orgDataRelation);
                                total++
                                dataOps.destroy(orgDataRelation).then(() => {
                                    checkIfAllDeleted()
                                })
                            }

                            // Remove from all categories (if any)
                            _.each(wfObject.filter({ where: {
                                type: enums.objectType.dataRelation,
                                organizationId: vm.network.organizationId,
                                parentType: enums.objectType.structure,
                                wfcid: org.wfid,
                            } }), (item) => {
                                // console.log(71, item);
                                total++
                                dataOps.destroy(item).then(() => {
                                    checkIfAllDeleted()
                                })
                            })

                            // Remove all influences to organization (if any)
                            _.each(wfObject.filter({ where: {
                                type: enums.objectType.influence,
                                creatorOrganizationId: vm.network.organizationId,
                                organizationId: org.id,
                                channelId: vm.network.id,
                            } }), (item) => {
                                // console.log(13, item);
                                total++
                                dataOps.destroy(item).then(() => {
                                    checkIfAllDeleted()
                                })
                            })

                            $timeout()
                        },
                    },
                },
            })
        }

        function addOrganization() {
            const
                swedishOrgNumberRegExp = /^(\d{1})(\d{5})\-(\d{4})$/
				
            const jqDf = $.Deferred()
				
            const mapOrgsByRegNumber = function (organizations) {
                return _.chain(organizations).filter((org) => {
                    return org.registrationNumber && org.registrationNumber.length > 0 // Ignore empty reg numbers
                }).map((org) => {
                    let orgNumber = org.registrationNumber

                    if (org.countryId === 190) { // Sweden
                        if (swedishOrgNumberRegExp.test(orgNumber)) orgNumber = orgNumber // If valid swedish reg number, use that
                        else if (orgNumber.length === 10 && !isNaN(orgNumber)) // If correct length and only numbers (like 1234567890)
                            orgNumber = orgNumber.substr(0, 6) + '-' + orgNumber.substr(6) // Add a dash (like 123456-7890)
                    }

                    return [orgNumber, org]
                }).fromPairs().value()
            }
				
            const valueChainOrganizationsByRegNumber = mapOrgsByRegNumber(vm.allUsersInValueChain)
				
            const categoryOrganizationsByRegNumber = vm.openedWithParent ? mapOrgsByRegNumber(vm.users) : null
				
            let addRelationToCategoryOnly = false

            const formSpec = {
                schema: {},
                form: ['*'],
                // onBeforeSubmit: function (model) {
                // },
				
            }

            formSpec.schema = {
                type: 'object',
                properties: {
                    name: {
                        title: $translate.instant('Name'),
                        type: 'string',
                        'x-schema-form': {
                        },
                    },
                    registrationNumber: {
                        title: $translate.instant('RegistrationNumber'),
                        type: 'string',
                        // "x-schema-form": {
                        // 	validationMessage: {
                        // 		swedishOrgNumber: $translate.instant("validationMessages.swedishOrganizationNumber")
                        // 	},
                        // 	$validators: {
                        // 		swedishOrgNumber: function (value) {
                        // 			return swedishOrgNumberRegExp.test(value) || !value || value.length == 0
                        // 		}
                        // 	}
                        // }
                    },
                },
                required: ['name', 'registrationNumber'],
            }

            modal.createWithPromise({
                type: 101,
                countryId: 190,
            },
            {
                title: $translate.instant('modules.valueChain.organizations.createNew'),
                submitCaption: $translate.instant('Add'),
                customFormSpecification: formSpec,
                bypassAdapter: true,
                onBeforeSubmitTriggered(event) {
                    const
                        orgModel = event.getModel()
							
                    const organizationAlreadyInValueChain = valueChainOrganizationsByRegNumber[orgModel.registrationNumber]
							
                    const organizationAlreadyInCategory = vm.openedWithParent ? categoryOrganizationsByRegNumber[orgModel.registrationNumber] : null

                    // console.log("OPEN WITH PARENT", !!vm.openedWithParent);
                    // console.log("IN CATEGORY", organizationAlreadyInValueChain);
                    // console.log("IN VALUE CHAIN", organizationAlreadyInCategory);

                    if (vm.openedWithParent && organizationAlreadyInValueChain && !organizationAlreadyInCategory) {
                        // If the organization is already in the Data Collector but not in the currently opened category
                        addRelationToCategoryOnly = true
                        // console.log("addRelationToCategoryOnly");
                        event.setResultAndCloseModal(organizationAlreadyInValueChain)
                    }
                    else if ((vm.openedWithParent && organizationAlreadyInCategory) || organizationAlreadyInValueChain) {
                        // If the organization is already in the Data Collector and/or in the opened category
                        $ngBootbox.customDialog({
                            title: $translate.instant('modules.valueChain.organizations.alreadyAdded.modalTitle'),
                            message: $translate.instant('modules.valueChain.organizations.alreadyAdded.modalMessage', {
                                orgname: organizationAlreadyInValueChain.name,
                                orgnumber: organizationAlreadyInValueChain.registrationNumber,
                            }),
                            onEscape: true,
                            className: 'valueChain-modal-orgAlreadyExists',
                            buttons: {
                                cancel: {
                                    label: $translate.instant('OK'),
                                    className: 'btn-primary',
                                    callback() {
                                        event.cancelSubmit()
                                    },
                                },
                            },
                        })
                    }
                    else {
                        // If the organization is not already in the Data Collector then
                        // check with server if it already exists in the database (using registration number)
                        apiProxy('utility.getOrganizationByCondition', {
                            registrationNumber: orgModel.registrationNumber,
                        }).then((org) => {
                            if (!org) event.continueSubmit()
                            else {
                                $ngBootbox.customDialog({
                                    title: $translate.instant('modules.valueChain.organizations.alreadyExists.modalTitle'),
                                    message: $translate.instant('modules.valueChain.organizations.alreadyExists.modalMessage', {
                                        orgname: org.name,
                                        orgnumber: org.registrationNumber,
                                    }),
                                    onEscape: true,
                                    className: 'valueChain-modal-orgAlreadyExists',
                                    buttons: {
                                        cancel: {
                                            label: $translate.instant('No'),
                                            className: 'btn-default',
                                            callback() {
                                                event.cancelSubmit()
                                            },
                                        },
                                        primary: {
                                            label: $translate.instant('Yes'),
                                            className: 'btn-primary',
                                            callback() {
                                                wfObject.inject(org)
                                                org = wfObject.get(org.wfid)
                                                event.setResultAndCloseModal(org)
                                            },
                                        },
                                    },
                                })
                            }
                        })
                        // event.closeModal();
                    }
                },
            },
            ).then((organization) => {
                const
                    addToCategory = function () {
                        return dataOps.createSubItemRelation(vm.openedWithParent, organization, enums.subItemsKind.childrenByUser)
                    }
                    
                const finish = function () {
                    dataOps.getObject({
                        objectType: enums.objectType.organization,
                        objectId: organization.id,
                        getterConditions: {
                            includeOrganizationsUsers: true,
                        },
                        // bypassCache: true
                    }).then(() => {
                        jqDf.resolve()
                        resyncEverything()
                        $timeout()
                    })
                }

                if (addRelationToCategoryOnly) {
                    addToCategory().then(() => {
                        if (!_.find(vm.users, { id: organization.id })) {
                            vm.users.unshift(organization)
                        }
                        finish()
                    })
                }
                else {
                    dataOps.createSubItemRelation(vm.network, organization, enums.subItemsKind.childrenByUser).then((res) => {
                        vm.allUsersInValueChain.unshift(organization)
                        if (!_.find(vm.users, { id: organization.id })) {
                            vm.users.unshift(organization)
                        }

                        if (vm.openedWithParent) {
                            addToCategory().then((res) => {
                                finish()
                            })
                        }
                        else {
                            finish()
                        }
                    })
                }
            })

            jqDf.promise()
        }

        function deleteInfluence(influence, organization) {
            $ngBootbox.customDialog({
                title: $translate.instant('modules.valueChain.influence.remove.modalTitle'),
                message: $translate.instant('modules.valueChain.influence.remove.modalMessage', { influencename: influence.childContent.title, orgname: organization.name }),
                onEscape: true,
                className: 'valueChain-modal-removeOrganization',
                buttons: {
                    cancel: {
                        label: $translate.instant('No'),
                        className: 'btn-default',
                        callback() {
                        },
                    },
                    primary: {
                        label: $translate.instant('Delete'),
                        className: 'btn-danger',
                        callback() {
                            dataOps.destroy(influence).then(() => {
                                _.remove(vm.influencesByUser[organization.id], influence)
                                vm.userCompilers[organization.id].compile()
                                $timeout()
                            })
                        },
                    },
                },
            })
        }

        function admin_setRootObject(org) {
            // dataOps.getObjects({}).then(function () {});
            const availableRootObjects = [
                { value: -1, name: 'Report' },
                { value: 12756, name: 'Report and ISO-standards' },
                { value: 13544, name: 'Everything (Manage, Visualize, VC, Report)' },
            ]
            const formSpec = {
                schema: {
                    type: 'object',
                    properties: {
                        rootObjectId: {
                            title: 'Root',
                            type: 'integer',
                        },
                    },
                },
                form: [
                    {
                        key: 'rootObjectId',
                        type: 'select',
                        titleMap: availableRootObjects,
                    },
                ],
            }

            getOrganizationRootObject(org.id).then((rootObjectSimplified) => {
                console.log(availableRootObjects, rootObjectSimplified.id, _.some(availableRootObjects, { value: rootObjectSimplified.id }))
                if (!_.some(availableRootObjects, { value: rootObjectSimplified.id })) {
                    availableRootObjects.push({ value: rootObjectSimplified.id, name: '(OLD) ' + rootObjectSimplified.title })
                }

                modal.edit({
                    rootObjectId: rootObjectSimplified.id,
                },
                {
                    title: 'Set root for ' + org.name,
                    action(model) {
                        return $q((resolve, reject) => {
                            apiProxy('authentication.setOrganizationRoot', { organizationId: org.id, rootObjectId: model.rootObjectId }).then(() => {
                                resolve()
                            })
                        })
                    },
                    customFormSpecification: formSpec,
                },
                ).then(() => {
                })
            })
        }

        function getOrganizationRootObject(orgId) {
            return apiProxy('authentication.getOrganizationRoot', { organizationId: orgId, culture: wfAuth.getCulture() })
        }
    }
})()
