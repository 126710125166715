// @flow
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@worldfavor/components/Table'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import StatusHeaderCell from './Table/StatusHeaderCell'
import MappingStatusHeaderCell from './Table/MappingStatusHeaderCell'

const useStyles = makeStyles({
    row: {
        cursor: 'pointer',
    },
})

type Props = {
  data: Array<Object>,
  columnComponents?: { [string]: React.ComponentType<any> },
  onFilterChange?: () => void,
}

const ProductTable = (props: Props) => {
    const { data, columnComponents, onFilterChange, ...rest } = props
    const [filter, setFilter] = useState(null)
    const classes = useStyles(props)

    useEffect(() => {
        if (filter) {
            const formattedFilters = Object.keys(filter)
                .map((key) => {
                    if (!filter[key]) {
                        return null
                    }

                    if (key === 'transparencyLevel') {
                        return {
                            filterType: 'PropertyName',
                            propertyName: key,
                            ...filter[key],
                        }
                    }

                    return {
                        filterType: key,
                        ...filter[key],
                    }
                })
                .filter(Boolean)
            onFilterChange(formattedFilters)
        }
    }, [filter])

    function _onFilterChange(key) {
        function onChange(value) {
            setFilter(prevFilter => ({
                ...prevFilter,
                [key]: value,
            }))
        }

        return onChange
    }

    function renderColumnCell({ column }) {
        const columnId = typeof column === 'string' ? column : column.id
        const columns = {
            product: <TableHeaderCell label={<FormattedMessage id="general.product" values={{ count: 1 }} />} />,
            organization: <TableHeaderCell label={<FormattedMessage id="general.organization" values={{ count: 1 }} />} />,
            coMapper: <TableHeaderCell label={<FormattedMessage id="general.coMapper" values={{ count: 1 }} />} />,
            gtin: <TableHeaderCell label={<FormattedMessage id={`general.gtin`} />} />,
            transparencyLevel: (
                <MappingStatusHeaderCell
                    onMappingCheckChange={_onFilterChange('transparencyLevel')}
                    enableFilter={typeof column === 'object' && column.enableFilter}
                />
            ),
            activation: (
                <StatusHeaderCell
                    onStatusCheckChange={_onFilterChange('ActivationStatus')}
                    enableFilter={typeof column === 'object' && column.enableFilter}
                />
            ),
            ...columnComponents,
        }

        if (columnId === 'actions') {
            return null
        }

        return columns[columnId] || <TableHeaderCell label={<FormattedMessage id={`general.${columnId}`} />} />
    }

    return (
        <Table
            data={data}
            classes={{ row: classes.row }}
            renderColumnCell={renderColumnCell}
            {...rest}
        />
    )
}

ProductTable.defaultProps = {
    onFilterChange: () => {},
}

export default ProductTable
