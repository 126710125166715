import * as enums from '@worldfavor/constants/enums'
import html2canvas from 'html2canvas'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfEasyList', {
            templateUrl: 'scripts/wf/easy/wfEasyList.component.html',
            controller: wfEasyListController,
            controllerAs: 'vm',
            bindings: {
                loader: '<',
                ticket: '<',
                config: '<',
                enableSearch: '<',
            },
        })

    wfEasyListController.$inject = ['$q', 'TheNewLoaderFactory', 'wfAuth', '$translate']
    function wfEasyListController($q, TheNewLoaderFactory, wfAuth, $translate) {
        const vm = this

        this.$onInit = function () {
            const modalElement = $('div.modal[uib-modal-window]')

            const rootStructure = wfAuth.getRootStructureInfo()
            const reportingOrgRootStructure = 12756

            _.assign(vm, {
                infiniteScrollContainer: modalElement.length ? modalElement.last() : undefined,
                loader: TheNewLoaderFactory.instantiate(vm.loader || _.assign({}, vm.config, { ticket: vm.ticket })),
                onSearch,
                isReportingUser: rootStructure.id === reportingOrgRootStructure,

                workModeModel: false, // need to use boolean in the ng-modal for the switcher
                // showUiModeSwitcher: vm.loader && vm.loader.influence ? true : false,
                showUiModeSwitcher: true,
                downloadButtonTooltipText: $translate.instant('modules.visualization.chartist.downloadButtonTooltipMessage'),
                switchUiMode,
                onSeriesClick: onChartSeriesClick,
                captureChart,
            })

            if (vm.loader.hierarchyStructureId === 100016) {
              vm.useHorizontalScrollingOnFilter = true
            }

            if (modalElement.length === 0) {
                scrollToTop()
            }

            vm.loader.onLoaded(() => {
                if (vm.loader.uiMode) vm.workModeModel = vm.loader.uiMode === enums.uiMode.work
            })
        }

        function onChartSeriesClick(seriesLabel) {
            console.log('custom chartSeries clicked', seriesLabel)
        }

        function onSearch(searchString, items, searchActive) {
            vm.loader.filteredAndSearchedItems = searchActive ? items : undefined

            if (searchActive) vm.searchActive = true

            if (searchString === '') vm.searchActive = false

            vm.loader.viewItems.length = 0
            vm.loader.pagingFunctionEnabled = true
            vm.loader.infiniteScrollPagingFunction()
        }

        function switchUiMode() {
            if (vm.workModeModel) vm.loader.setUiMode(enums.uiMode.work)
            else vm.loader.setUiMode(enums.uiMode.view)
        }

        function scrollToTop() {
            $('html, body').scrollTop(0)
        }

        function captureChart($event, childWfid) {
            let dashboardPanelBodyElement
			let elementsToHideInCapture
			let dashboardPanelElement

            function capture($event) {
                const deferred = $q.defer();

				dashboardPanelBodyElement = $($event.target).closest("div.panel-body")

				if (!!dashboardPanelBodyElement.length) {
					dashboardPanelElement = dashboardPanelBodyElement.parent()
					dashboardPanelElement.addClass("capturing-visualization")
					dashboardPanelElement.attr("data-capturing-title", $translate.instant("modules.visualization.download.downloadingMessage"))

					setTimeout(() => {
						doCapture()
					}, 100)
				}

				function doCapture() {
					dashboardPanelBodyElement.find("span").removeAttr("xmlns")
					elementsToHideInCapture = dashboardPanelBodyElement.find("div.download-chart-button, div.container-open")
					elementsToHideInCapture.css("visibility", "hidden")

					const captureElement = dashboardPanelBodyElement.get(0)
					html2canvas(captureElement, { foreignObjectRendering: false, scale: 2.5 })
						.then(canvas => {
							canvas.style.display = 'none'
							document.body.appendChild(canvas)
							deferred.resolve(canvas)
						})
				}

                return deferred.promise
            }

            capture($event).then((canvas) => {
                const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                const a = document.createElement('a')
                a.setAttribute('download', `WF_Question_${childWfid}.png`)
                a.setAttribute('href', image)
                a.click()
                a.remove()
                canvas.remove()

                elementsToHideInCapture.css("visibility", "")
				dashboardPanelElement.removeClass("capturing-visualization")
				dashboardPanelElement.removeAttr("data-capturing-title")
            })
        }
    }
})()
