// @flow
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from '@worldfavor/hooks'
import _ from 'lodash'

type Props = {
  data: Array<any>,
  filterKeys: Array<string>,
  keyExtractor: (any, number) => string,
  renderItem: ({ item: any, index: number, match?: { search: string, keys: Array<string> } }) => React.Node,
  ItemSeparatorComponent?: React.ComponentType<any>,
  ListEmptyComponent?: React.ComponentType<any>,
  minSearchLength?: number,
}

const SearchList = ({
    searchText,
    data,
    filterKeys,
    keyExtractor,
    ListEmptyComponent,
    ItemSeparatorComponent,
    renderItem,
    onDataFiltered,
    minSearchLength,
    style,
}: Props) => {
    const [filteredData, setFilteredData] = useState<Array<{ item: any, match?: any }>>(data.map(item => ({ item })))
    const [getFromHistory, addToHistory] = useHistory(10, [data])

    useEffect(() => {
        const getData = () => {
            if (!searchText || (minSearchLength && minSearchLength > 0 && searchText.length < minSearchLength)) {
                return data.map(item => ({ item }))
            }

            let searchResult = (getFromHistory(({ search }) => search === searchText) || {}).result
            if (!searchResult) {
                const searchRegexp = new RegExp(searchText, 'i')
                searchResult = data.map((item) => {
                    const matchingKeys = filterKeys.filter(key => (_.get(item, key) || '').match(searchRegexp))

                    if (matchingKeys.length === 0) {
                        return null
                    }

                    return {
                        item,
                        match: {
                            search: searchText,
                            keys: matchingKeys,
                        },
                    }
                }).filter(Boolean)
                addToHistory({ search: searchText, result: searchResult })
            }
            onDataFiltered && onDataFiltered(searchResult)
            return searchResult
        }

        setFilteredData(getData())
    }, [data, searchText])

    const extractKey = (item: any, index: number) => keyExtractor ? keyExtractor(item, index) : item.key

    return (
        <div style={style}>
            {
                filteredData.length === 0 ?
                    (ListEmptyComponent ? <ListEmptyComponent /> : null)
                    : (
                        filteredData.map(({ item, match }, index) => (
                            <React.Fragment
                                key={extractKey(item, index)}
                            >
                                { index > 0 && ItemSeparatorComponent && <ItemSeparatorComponent />}
                                { renderItem({ item, index, match }) }
                            </React.Fragment>
                        ))
                    )
            }
        </div>
    )
}

export default SearchList
