import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    dot: {
        marginRight: 5,
        backgroundColor: Colors.gray,
        width: 12,
        height: 12,
        borderRadius: '100%',
    },
    activeDot: {
        backgroundColor: Colors.green,
    },
}

const statuses = {
    inactive: {
        labelId: 'general.inactive',
    },
    active: {
        labelId: 'general.active',
    },
}
  
const ActivationStatusIndicator = ({ classes, status }) => {
    const currentStatus = statuses[status ? 'active' : 'inactive']

    return (
        <div className={classes.container}>
            <div className={classNames(classes.dot, status && classes.activeDot)} />
            <span className={classes.label}><FormattedMessage id={currentStatus.labelId} /></span>
            {/* <FormattedMessage id={visibilityStatus.labelId} /> */}
        </div>
    )
}

export default withStyles(styles)(ActivationStatusIndicator)
