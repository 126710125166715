// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'

import ProductTable from './ProductTable'
import ProductTableRowCell from './ProductTableRowCell'
import { productPath } from '@worldfavor/constants/paths'

const useStyles = makeStyles({
    row: {
        cursor: 'pointer',
    },
})

const rowKeyExtractor = ({ item: { node: { wfid } } = {} } = {}) => `row-${wfid}`

const ReportingProductTable = (props) => {
    const { data, influenceWfid, history, ...rest } = props
    const classes = useStyles(props)

    function renderRowCell(params) {
        const { search } = params.row.match
        const { wfid } = params.row.item.node

        return (
            <ProductTableRowCell
                {...params}
                row={params.row.item}
                to={productPath(influenceWfid, wfid)}
                search={search}
            />
        )
    }

    function onRowClick(event, row) {
        history.push(productPath(influenceWfid, row.item.node.wfid))
    }

    return (
        <ProductTable
            data={data}
            classes={{ row: classes.row }}
            className={classes.table}
            columns={['product', 'gtin', 'transparencyLevel']}
            renderRowCell={renderRowCell}
            rowKeyExtractor={rowKeyExtractor}
            onRowClick={onRowClick}
            rowHover
            {...rest}
        />
    )
}

export default withRouter(ReportingProductTable)
