/**
 * @ngdoc directive
 * @name wfObjectViewer
 * 
 * @description 
 * Used for displaying all the information and relations with the particular item
 * 
 * @param {WfObject=} item Used as the main item
 * 
 * @param {} view-mode 
 * @param {funciton} on-loaded
 * @param {} answerable 
 * @param {Object} box
 * @param {Object} object-viewer-vm Passing the scope to the 
 * 
 * ////////NOT DONE YET//////
 */

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfObjectViewer', wfObjectViewer)

    wfObjectViewer.$inject = []
	
    function wfObjectViewer() {
        const directive = {
            restrict: 'E',
            scope: {
                itemFromDirective: '=item',
                intersectionSettings: '<intersection',
                onLoaded: '&',
                uiMode: '<',
            },
            controller: ['$scope', '$attrs', function ($scope, $attrs) {
                $scope.viewMode = 'viewMode' in $attrs
            }],
            templateUrl: 'scripts/wf/objectViewer/objectViewer.controller.html',
        }
        return directive
    }
})()
