// @flow
import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import SearchBar from '../SearchBar'
import { geocode, suggest, reverseGeocode, Categories } from '@worldfavor/utils/ArcGisApi'
import Place from '@material-ui/icons/Place'
import Divider from '../Divider'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash'

const styles = theme => ({
    mapContainer: {
        position: 'relative',
    },
    searchContainer: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 9999,
        width: 400,
    },
    searchResultContainer: {
        marginTop: 6,
        borderRadius: 10,
        maxHeight: 164,
        overflowY: 'auto',
    },
    searchRoot: {
        backgroundColor: 'white !important',
        boxShadow: theme.shadows[1],
    },
    divider: {
        paddingLeft: 14,
        paddingRight: 14,
    },
    selectedAddress: {
        marginLeft: 8,
        marginRight: 16,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    selectedAddressIcon: {
        marginRight: 12,
        color: '#B1B1B1',
    },
    selectedAddressText: {
        fontSize: 18,
        color: theme.palette.text.primary,

        maxWidth: 480,

        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})

const SearchResultRow = withStyles(theme => ({
    root: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 14,
        paddingRight: 14,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        overflow: 'hidden',
    },
    placeIcon: {
        marginRight: 8,
        color: '#B1B1B1',
    },
    address: {
        fontSize: 14,
        color: theme.palette.text.primary,

        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}))(({ text, classes, magicKey, onClick }) => {
    function _onClick() {
        onClick && onClick(text, magicKey)
    }

    return (
        <div className={classes.root} onClick={_onClick}>
            <Place className={classes.placeIcon} size={'small'} />
            <div className={classes.address} title={text}>{text}</div>
        </div>
    )
})

const initialPosition = [51.505, -0.09]
const initialZoom = 3

type SelectedAddress = {
  latitude: number,
  longitude: number,
  address: {
    LongLabel: string,
    CountryCode: string,
  },
}

type Props = {
  initialAddress: SelectedAddress,
  open: boolean,
  onCancel: () => void,
  onSelectAddress: (event: SyntheticEvent<any>, selectedAddress: SelectedAddress) => void,
  disableStreetInt: boolean,
  disableDistanceMarker: boolean,
  disableStreetAddress: boolean,
  disableStreetName: boolean,
  disablePOI: boolean,
  disablePointAddress: boolean,
  disablePostal: boolean,
  disableLocality: boolean,
}

const LocationPickerDialog = ({
    classes,
    open,
    initialAddress,
    onCancel,
    onSelectAddress,
    disableStreetInt,
    disableDistanceMarker,
    disableStreetAddress,
    disableStreetName,
    disablePOI,
    disablePointAddress,
    disablePostal,
    disableLocality,
    intl,
    ...rest
}: Props) => {
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [isSearchFocus, setIsSearchFocus] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [position, setPosition] = useState(initialPosition)
    const [zoom, setZoom] = useState(initialZoom)

    const [fetchAddressSuggestions] = useDebouncedCallback(async () => {
        const location = map.current.leafletElement.getCenter()
        const result = await suggest(search, location, getCategories(), 10)

        if (!result.data.error) {
            setSearchResults(result.data.suggestions)
        }
        else {
            setSearchResults([])
        }
    }, 200)

    const map = useRef()

    function onSearchChange(event) {
        setSearch(event.target.value)
    }

    function onSearchClear() {
        setSearch('')
    }

    async function onSearchResultClick(text, magicKey) {
        const location = map.current.leafletElement.getCenter()
        const result = await geocode(text, location, magicKey, getCategories())

        const resultData = result.data.candidates[0]
        const selectedAddress = resultData ?
            {
                latitude: resultData.location.y,
                longitude: resultData.location.x,
                address: {
                    ...resultData.attributes,
                    CountryCode: resultData.attributes.Country,
                },
            } : null

        setSearch(text)
        setSelectedAddress(selectedAddress)
        setIsSearchFocus(false)

        const zoom = Categories[resultData.attributes.Addr_type].zoom || 12
        map.current.leafletElement.flyTo([selectedAddress.latitude, selectedAddress.longitude], zoom)
    }

    function getCategories() {
        return [
            !disableStreetInt && Categories.StreetInt.name,
            !disableDistanceMarker && Categories.DistanceMarker.name,
            !disableStreetAddress && Categories.StreetAddress.name,
            !disableStreetName && Categories.StreetName.name,
            !disablePOI && Categories.POI.name,
            !disablePointAddress && Categories.PointAddress.name,
            !disablePostal && Categories.Postal.name,
            !disableLocality && Categories.Locality.name,
        ].filter(Boolean)
    }

    function getFeatureTypes() {
        return [
            !disableStreetInt && Categories.StreetInt.featureType,
            !disableDistanceMarker && Categories.DistanceMarker.featureType,
            !disableStreetAddress && Categories.StreetAddress.featureType,
            !disableStreetName && Categories.StreetName.featureType,
            !disablePOI && Categories.POI.featureType,
            !disablePointAddress && Categories.PointAddress.featureType,
            !disablePostal && Categories.Postal.featureType,
            !disableLocality && Categories.Locality.featureType,
        ].filter(Boolean)
    }

    async function handleMapClick(event) {
        const coords = event.latlng
        const result = await reverseGeocode(coords.lat, coords.lng, getFeatureTypes())
        const { data } = result
        const selectedAddress = !data.error ?
            {
                latitude: data.location.y,
                longitude: data.location.x,
                address: data.address,
            } : null

        setSelectedAddress(selectedAddress)
        setIsSearchFocus(false)
    }

    // reset the selectedAddress to the initial address whenever opening the dialog
    useEffect(() => {
        if (open && initialAddress) {
            setSearch(_.get(initialAddress, 'address.LongLabel') || '')
            setSelectedAddress(initialAddress)

            if (initialAddress.latitude && initialAddress.longitude) {
                setPosition([initialAddress.latitude, initialAddress.longitude])
            }

            setZoom((Categories[_.get(initialAddress, 'address.Addr_type', '')] || {}).zoom || 12)
        }
    }, [open, initialAddress])

    useEffect(() => {
        if (search.length > 0) {
            fetchAddressSuggestions()
        }
        else {
            setSearchResults([])
        }
    }, [search])

    function handleSearchFocus() {
        setIsSearchFocus(true)
    }

    function _onSelectAddress(e) {
        onSelectAddress(e, selectedAddress)
    }

    const attributionText = 'Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCan, Esri Japan, METI, Esri China (Hong Kong), Esri Korea, Esri (Thailand), NGCC, (c) OpenStreetMap contributors, and the GIS User Community'
    const attribution = `<span title="${attributionText}" style="max-width:${800 - 60}px;text-overflow: ellipsis;overflow: hidden;display: inline-block;white-space: nowrap;height: 12px;">${attributionText}</span>`

    return (
        <Dialog
            open={open}
            {...rest}
            maxWidth={false}
        >

            <div className={classes.mapContainer}>

                <div className={classes.searchContainer}>
                    <SearchBar
                        onClear={onSearchClear}
                        classes={{ root: classes.searchRoot }}
                        onFocus={handleSearchFocus}
                        rounded
                        compact
                        inputProps={{
                            value: search,
                            placeholder: intl.formatMessage({ id: 'search.locationPlaceholder' }),
                            onChange: onSearchChange,
                        }}
                    />

                    {
                        isSearchFocus && searchResults.length > 0 && (
                            <Paper className={classes.searchResultContainer}>
                                {
                                    searchResults.map((item, index) => (
                                        <React.Fragment key={`result-${item.magicKey}`}>
                                            <SearchResultRow {...item} onClick={onSearchResultClick} />
                                            {
                                                index < searchResults.length - 1 && (
                                                    <div className={classes.divider}>
                                                        <Divider height={1} margin={'0'} width={'100%'} color={'rgba(221, 221, 221, 0.5)'} />
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </Paper>
                        )
                    }
                </div>

                <Map
                    ref={map}
                    center={position}
                    zoom={zoom}
                    onClick={handleMapClick}
                    minZoom={2}
                    maxZoom={19}
                    style={{ width: 800, height: 440 }}
                >
                    <TileLayer
                        attribution={attribution}
                        url={'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'}
                    />

                    {
                        selectedAddress && (
                            <Marker position={[selectedAddress.latitude, selectedAddress.longitude]}>
                                <Popup>{selectedAddress.address.LongLabel}</Popup>
                            </Marker>
                        )
                    }
                </Map>
            </div>

            <DialogActions>
                {
                    selectedAddress && (
                        <div className={classes.selectedAddress}>
                            <Place className={classes.selectedAddressIcon} size={'small'} />
                            <div
                                className={classes.selectedAddressText}
                                title={selectedAddress.address.LongLabel}
                            >
                                { selectedAddress.address.LongLabel }
                            </div>
                        </div>
                    )
                }
                <Button onClick={onCancel}>
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button
                    disabled={!selectedAddress}
                    onClick={_onSelectAddress}
                >
                    <FormattedMessage id={'picker.location.selectAddress'} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LocationPickerDialog.defaultProps = {
    onSelectAddress: () => {},
    disableStreetInt: false,
    disableDistanceMarker: true,
    disableStreetAddress: false,
    disableStreetName: false,
    disablePOI: false,
    disablePointAddress: false,
    disablePostal: true,
    disableLocality: false,
}

export default injectIntl(withStyles(styles)(LocationPickerDialog))
