// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import OrganizationMap from '../Map/OrganizationMap'
import EmptyViewerPlaceholder from './EmptyViewerPlaceholder'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    placeholderContainer: {
        position: 'absolute',
        top: 0,
        zIndex: 999,

        width: '100%',
        height: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const MapViewer = (props) => {
    const { edges, nodes, organizations, onEditButtonClick, editable } = props
    const classes = useStyles(props)
    return (
        <div className={classes.root}>
            <OrganizationMap
                width={'100%'}
                height={'100%'}
                edges={edges}
                nodes={nodes}
                organizations={organizations}
            />
            {
                organizations.length === 0 && (
                    <div className={classes.placeholderContainer}>
                        <Paper>
                            <EmptyViewerPlaceholder
                                onEditButtonClick={onEditButtonClick}
                                editable={editable}
                            />
                        </Paper>
                    </div>
                )
            }
        </div>
    )
}

export default MapViewer
