import { getStore } from '../store/storeRegistry'
import axios from 'axios'
import { getCulture } from '../selectors/appSelector'

let BASE_URL = ''

export const registerBaseUrl = (baseUrl) => {
    BASE_URL = baseUrl
}

export const getBaseUrl = (mockedRequest) => {
    if (mockedRequest) {
        return 'https://wfapi.azure-api.net/mocked-api'
    }
    return BASE_URL || 'https://api25-beta.worldfavor.com/api'
}
const request = (method, url, payload, headers, source, options: { overrideData: boolean, mockedRequest: boolean, responseType: string } = {}) => {
    const state = getStore().getState()
    const culture = getCulture(state)

    return axios(
        {
            method,
            url: `${getBaseUrl(options.mockedRequest)}${url}`,
            /*params: {
        ...(process.env.NODE_ENV !== 'production' ?
          { fields: qs.stringify(payload, { arrayFormat: 'brackets' }) } :
          {})
      },*/
            data: options.overrideData ? payload : {
                ...payload,
                culture,
            },
            headers: {
                Authorization: `Bearer ${state.app.token}`,
                'Content-Type': 'application/json;  charset=UTF-8',
                'Cache-Control': 'no-cache',
                'X-WF-Culture': culture,
                ...headers,
            },
            cancelToken: source ? source.token : null,
            responseType: options.responseType,
        },
    ).catch((err) => {
        if (axios.isCancel(err)) {
            err.cancelled = true
        }
        throw err
    })
}

export const get = (url, headers, source, options) => request('GET', url, null, headers, source, options)
export const deleteRequest = (url, payload, headers, source) => request('DELETE', url, payload, headers, source)
export const head = (url, headers, source) => request('HEAD', url, null, headers, source)
export const options = (url, headers, source) => request('OPTIONS', url, null, headers, source)
export const post = (url, payload, headers, source, options) => request('POST', url, payload, headers, source, options)
export const put = (url, payload, headers, source) => request('PUT', url, payload, headers, source)
export const patch = (url, payload, headers, source) => request('PATCH', url, payload, headers, source)
