(function() {
    'use strict'
    angular
        .module('wf.common')
        .component('wfLandingPage', wfLandingPage())
	
    function wfLandingPage() {
        const component = {
            templateUrl: 'scripts/wf/signup/wfLandingPage.component.html',
            controller: wfLandingPageController,
            controllerAs: 'vm',
            bindings: {
                // Is used to control if more content should be shown around the materiality analysis targeted for free user and new signups.
                // Set stripped to true to hide the extra content.
                stripped: '<',
                disableAddingToScope: '<',
                scopeCategoryId: '<',
            },
        }
				
        return component
    }
	
    wfLandingPageController.$inject = ['$scope', '$rootScope', 'wfAuth', '$timeout', '$element', 'dataOperationsService', '$q', 'modalService', 'DataNegotiator', '$translate', '$state', '$location', '$anchorScroll', 'responsiveUi']
    function wfLandingPageController($scope, $rootScope, wfAuth, $timeout, $element, dataOps, $q, modal, DataNegotiator, $translate, $state, $location, $anchorScroll, responsiveUi) {
        const
            vm = this
				
        const specificUiSettings = getSpecificUiSettings()

        _.assign(vm, {
            //Variables
            descriptionSteps: [
                {
                    imagePath: '/assets/img/svg/file.svg',
                    text: $translate.instant('modules.signup.landingPage.descriptionSteps.firstStep.text'),
                    info: $translate.instant('modules.signup.landingPage.descriptionSteps.firstStep.info', { numberOfCompanies: 328 }),
                    class: 'link',
                    tooltipText: $translate.instant('modules.signup.landingPage.descriptionSteps.firstStep.tooltipText'),
                },
                {
                    imagePath: '/assets/img/svg/analysis.svg',
                    text: $translate.instant('modules.signup.landingPage.descriptionSteps.secondStep.text'),
                    info: $translate.instant('modules.signup.landingPage.descriptionSteps.secondStep.info'),
                    anchorId: 'what-is-this-anchor',
                },
                {
                    imagePath: '/assets/img/svg/sdg_logo_circle.svg',
                    text: $translate.instant('modules.signup.landingPage.descriptionSteps.thirdStep.text'),
                    info: $translate.instant('modules.signup.landingPage.descriptionSteps.thirdStep.info'),
                    anchorId: 'sdg-goals',
                },
                {
                    imagePath: '/assets/img/svg/finger.svg',
                    text: $translate.instant('modules.signup.landingPage.descriptionSteps.fourthStep.text'),
                    info: $translate.instant('modules.signup.landingPage.descriptionSteps.fourthStep.info'),
                },
					
            ],
            pageTitle: $translate.instant('modules.signup.landingPage.pageHeader.title', { userName: wfAuth.getWorldfavorUser().given_name + '!' }),
            filters: [],
            applyIeStyles: $.browser.msie,
            loadingData: true,
            contactFormLoading: false,
            hbspScriptLoaded: false,
				
            expandBox,
            goToState,
            goToAnchor,
            openContactForm,
        })

        responsiveUi.enableResponsiveness()
        responsiveUi.disableResponsivenessOnScopeDestroy($scope)
        responsiveUi.hideNavbarOnScroll($scope)
        activate()

        function activate() {
            wfAuth.forceCulture('en-US', false)

            $scope.$on('$destroy', () => {
                wfAuth.resetForcedCulture()
            })
				
            $rootScope.setPageTitle('')
            loadOrgUsers()
            loadHbspScript()
        }

        function loadOrgUsers() {
            dataOps.getOrganizationUsers().then((users) => {
                vm.orgUsers = users
                vm.orgUsersLoaded = true
            })
        }
			
        function expandBox(structure) {
            if (!structure.expanded) structure.uiSettings.limitItems = structure.item.childs.length
            else structure.uiSettings.limitItems = specificUiSettings[structure.wfid].limitItems
				
            structure.expanded = !structure.expanded

            $timeout()
        }

        function getSpecificUiSettings() {
            return {
                '71-10517': {
                    limitItems: 5,
                    itemComponent: {
                        include: {
                            image: false,
                            header: true,
                            body: true,
                            itemDropdown: false,
                            preHeader: false,
                            subHeader: false,
                            metadata: false,
                        },
                    },
                },
                '71-387': {
                    showNumbering: false,
                    limitItems: 5,
                    itemComponent: {
                        include: {
                            image: true,
                            header: true,
                            body: true,
                            itemDropdown: false,
                            preHeader: false,
                            subHeader: false,
                            metadata: false,
                        },
                    },
                },
            }
        }
			
        function goToState(state) {
            $state.go(state)
        }
			
        function goToAnchor(id) {
            const gap = 50
            if (id) {
                $('html, body').animate({
                    scrollTop: $('#' + id).offset().top - gap,
                }, 1000)
            }
        }

        function loadHbspScript() {
            let scriptElement

            if (!$('#hs-script-loader').length) {
                scriptElement = document.createElement('script')
                scriptElement.id = 'hs-form-loader'
                scriptElement.type = 'text/javascript'
                scriptElement.src = '//js.hsforms.net/forms/v2.js'
                scriptElement.charset = 'utf-8'
                document.head.appendChild(scriptElement)

                scriptElement.onload = scriptElement.onreadystatechange = function() {
                    vm.hbspScriptLoaded = true
                    //Handling memory leak in IE
                    scriptElement.onload = scriptElement.onreadystatechange = null
                }
            }
        }

        function openContactForm() {
            vm.contactFormLoading = true
            if (hbspt) {
                modal.open({
                    template: '<div id=\'hubspot-form-container\'></div>',
                    scope: { },
                    windowClass: 'modal-width-600 wf-landing-page-contact-modal',
                    onLoaded($scope, $element) {
                        // setTimeout(function () {
                        vm.contactFormLoading = false
                        const
                            hubSpotForm = $('#hubspot-form-container')
									
                        const user = wfAuth.getUser()
		
                        // Config docs: https://developers.hubspot.com/docs/methods/forms/advanced_form_options
                        hbspt.forms.create({
                            portalId: '2989273',
                            formId: 'fe3e06f0-80d7-4cb0-9cf8-5f4b94741f72',
                            target: '#hubspot-form-container',
                            css: '',
                            onFormReady() {
                                hubSpotForm.find('select[name="i_want_to"]').val('').change()
                                hubSpotForm.find('input[name="email"]').val(user.email).change()
                                hubSpotForm.find('input[name="firstname"]').val(user.given_name).change()
                                hubSpotForm.find('input[name="lastname"]').val(user.family_name).change()
                                hubSpotForm.find('input[name="phone"]').val(user.phone_number).change()
                                hubSpotForm.find('textarea[name="message"]').val('').change()
                                hubSpotForm.find('input[name="subscribe_to_worldfavor_sustainability_blog"]').prop('checked', false).change()
                                hubSpotForm.find('.hs-submit').click(() => {
                                    hubSpotForm.addClass('showValidationErrors')
                                })
                            },
                        })
                        // }, 0);
                    },
                })
            }
            else {
                setTimeout(() => {
                    openContactForm()
                }, 500)
            }
        }
    }
})()
