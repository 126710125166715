import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ValueChainController', ValueChainController)

    ValueChainController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$state', '$sanitize', '$ngBootbox', '$window', 'valueChainService']
    function ValueChainController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state, $sanitize, $ngBootbox, $window, valueChainService) {
        const
            vm = this
			
        const authOrgId = wfAuth.getOrganizationId()

        $scope.moment = moment
		
        _.assign(vm, {
            window: $window,
            loaded: false,
            networks: [],
        })

        // valueChainOrganizations-overview

        activate()

        ////////////////

        function activate() {
            // if (_.get($state, "$current.parent.self.data.isSolutionRoot")) {
            // 	vm.routeStateName = $state.$current.parent.self.name + ".valueChainOrganizations-overview";
            // }

            dataOps.getObject({
                objectType: enums.objectType.structure,
                objectId: _.get($state, 'params.networksStructureId') || valueChainService.ids.networksStructure, // This will contain networks for the authenticated organization
                childrenLoadDepth: 1,
            }).then((mainStructure) => {
                vm.networks = _.sortBy(mainStructure.childs, (networkDataRelation) => {
                    return networkDataRelation.childContent.title
                })
                vm.loaded = true

                if (vm.networks.length == 0) {
                    $state.go('promotion', { nameRef: 'ValueChain' }, { location: false })
                }
            })
        }
    }
})()
