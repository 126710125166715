import * as React from 'react'
import Images from '@worldfavor/assets/Images'
import { withStyles } from '@material-ui/core/styles'

const styles = {
    root: {
        minHeight: 500,
        backgroundImage: `url(${Images.logoLoader})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        animation: 'opacityFadeIn 1s',
    },
}

const Loading = ({ classes }) => {
    return (
        <div className={classes.root} />
    )
}

export default withStyles(styles)(Loading)
