//@flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FilterDropdown from './FilterDropdown'
import FilterButton  from './FilterButton'
import { FILTER_OPTIONS_TYPE_PROPS } from '@worldfavor/constants'

const useStyles = makeStyles({
    root: {

    },
})

type Props = {
  filters: Array<{ label: string, renderAs: string, filterOptions: FILTER_OPTIONS_TYPE_PROPS }>,
  hasActiveFilters: () => void
}

const FiltersContainer = (props: Props) => {
    const { filters, onAfterFilterChanged } = props
    const classes = useStyles(props)
    const [selectedFilters, setSelectedFilters] = useState([])

    useEffect(() => {
        onAfterFilterChanged && onAfterFilterChanged(selectedFilters)
    }, [selectedFilters])

    // Add necessary properties to filter objects
    filters.map(filter => Object.assign(filter, { active: false }))
  
    function _onAfterFilterChanged(clickedFilterOptions, filter) {
        filter.active = clickedFilterOptions & clickedFilterOptions.length > 0
        filter.selectedOptions = [...new Set(clickedFilterOptions)]

        const allSelectedFilters = filters.map(filter => filter.selectedOptions).flat()
        setSelectedFilters(allSelectedFilters)
    }

    function renderFilter(filter, index) {
        switch (filter.renderAs) {
            case 'dropdown':
                return (
                    <FilterDropdown
                        key={`filter-dropdown-${index}`}
                        label={filter.label}
                        filterOptions={filter.filterOptions}
                        filter={filter}
                        onAfterFilterChanged={_onAfterFilterChanged}
                    />
                )
            case 'toggle':
                return (
                // TO BE DONE
                    <FilterButton
                        key={`button-filter-${index}`}
                        label={filter.label}
                        filterOptions={filter.filterOptions}
                        filter={filter}
                        onAfterFilterChanged={_onAfterFilterChanged}
                    />
                )
            default:
                return null
        }
    }

    return (
        <div className={classes.root}>
            { filters.map((filter, index) => renderFilter(filter, index)) }
        </div>
    )
}

export default FiltersContainer
