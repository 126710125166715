(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfTranslate', wfTranslate)
        .directive('wfIfLanguage', wfIfLanguage)

    wfTranslate.$inject = ['$translate', '$parse', 'wfTranslate']

    function wfTranslate($translate, $parse, wfTranslate) {
        const directive = {
            link,
            restrict: 'A',
        }
        return directive
		
        function link(scope, element, attrs) {
            const
                key = attrs.wfTranslate
				
            let translateParams
				
            if (attrs.wfTranslateValues) {
                translateParams = $parse(attrs.wfTranslateValues)(scope)
            }
            element.text(wfTranslate.instant(key, translateParams))
        }
    }

    wfIfLanguage.$inject = ['$translate', '$parse', 'wfTranslate', '$compile']

    function wfIfLanguage($translate, $parse, wfTranslate, $compile) {
        const directive = {
            restrict: 'A',
            priority: 10000, // process this directive first
            terminal: true, // stop compiling other directives on the
						    // element, we'll fix it in `post`
            compile(element, attrs) {
                return {
                    post(scope, element, attrs, ctrl) {
                        // if (scope.vm && scope.vm.item)
                        // 	console.log(scope.vm.item.wfid, "if - compile", attrs.wfIfFirst)
                        // console.log(attrs.wfIfLanguage , $translate.preferredLanguage())
                        // console.log(attrs.wfIfLanguage === "sv", $translate.preferredLanguage() === "sv-SE")
                        // console.log(attrs.wfIfLanguage === "en", $translate.preferredLanguage() === "en-US")

                        if ((attrs.wfIfLanguage === 'sv' && $translate.proposedLanguage() === 'sv-SE')
							||
							(attrs.wfIfLanguage === 'en' && $translate.proposedLanguage() === 'en-US')
                        ) {
                            // prevent recursion
                            element[0].removeAttribute('wf-if-language')
							
                            // process the directives we added
                            $compile(element)(scope)
                        }
                        else {
                            element.remove()
                        }
                    },
                }
            },
        }
        return directive
    }
})()
