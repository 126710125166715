import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc directive
 * @name wfDropdown
 * @require ?^^wfItemsCollection, ?^^wfActionCompiler
 * 
 * @description 
 * wf-dropdown directive has the purpose to extract the right actions for a particular item and display them in a dropdown to the UI. 
 * Depending on the item type (isUserData or frameworkData) the actions are selected appropriately.
 * Also there is a support for completely custom actions that can be defined for use in any scope.
 * 
 * @param {WfObject} item Used as the main item
 * @param {WfObject=} item-content If item or item-relation is not available, itemContent is passed
 * @param {WfObject=} item-relation If item is not available, itemRelation is passed
 * 
 * @param {jQuery element} element The main element that is used for animating the removal of element in the UI
 * @param {Array|String=} actions This attribute requires either an ARRAY of custom actions (see description bellow) OR a String describing the defaultActionCollection OR a single action specification (i.e. 'edit', 'sendTo')
 * @description 
 * Properties of an action object are: 
 * {text: "multilingualReference", icon: "fa fa-pencil", action: "function()", condition: function(), cssClass: "className"}
 * MANDATORY properties are: text, icon, action
 * OPTIONAL properties: condition, cssClass
 * 
 * @param {} disable-deleting Makes sure that any delete or remove action is not passed into the dropdown.
 * @param {} on-action-click Specify any custom function to be called.
 * @param {} use-plus-icon Changes "three dot" button to a circular + button for triggering the dropdown list.
 * @param {Object} overflow-settings Custom settings that are passed to the dropdownUtility service
 * 
 * @param {Object} actionSpecification defines properties for each action (text, icon, action, condition, cssClass)
 * 
 * @param {Object} defaultActionCollections A set/collection of default actions defined in actions attribute
 * @property userData A collection of actions - ['edit', 'visibility', '-', 'deleteRelation', 'deleteContent',]
 * @property answers A collection of actions - ['objectViewer', 'visibility', 'attachInformation', '-', 'deleteContent']
 * @property frameworkData A collection of actions - ['objectViewer', '-', 'addTo', 'sendTo', 'attachInformation']
 * @property userPermissions A collection of actions - ['edit', 'editPermissions', 'sendInviteMail', 'removeFromOrganization']
 * @property attachedInformation A collection of actions - ['attachActivity', 'attachDocument', 'attachStatement', 'attachLink', 'attachMedia']
 * 
 * @example SIMPLE example - mandatory attributes
 * <wf-dropdown item="itemVm.item" actions="'userData'"></wf-dropdown>
 * <wf-dropdown item-content="itemVm.itemContent" actions="'attachedInformation'"></wf-dropdown>
 * <wf-dropdown item-content="itemVm.itemContent" item-relation="itemVm.itemRelation" actions="'frameworkData'"></wf-dropdown>
 * 
 * @example ADDITIONAL ATTRIBUTES
 * <wf-dropdown item="itemVm.item" disable-deleting actions="'userData'"></wf-dropdown>
 * <wf-dropdown item-content="itemVm.itemContent" use-plus-icon actions="'attachedInformation'"></wf-dropdown> 
 * 
 * @example CUSTOM ACTION - two custom actions
 * <wf-dropdown item="itemVm.item" actions="[{text:'Comment', icon:'fa fa-commenting-o', action:'itemVm.addComment(comment)'}, {text:'Delete', icon:'fa fa-remove', action:'itemVm.deleteMe(item)', cssClass:'removal'}]"></wf-dropdown>
 * 
 * @example CUSTOM ACTION - combination of defaultCollection and two custom actions
 * <wf-dropdown item="itemVm.item" actions="['userData', {text:'Delete', icon:'fa fa-remove', action:'itemVm.deleteMe(item)', cssClass:'removal'}]"></wf-dropdown>
 * 
 * @example ITEMS COLLECTION - using wf-items-collection & wf-action-compiler
 * @description In case we have a list of items in a collection (i.e. list of items in Attached Information) we need to use wf-items-collection in order to have the syncronization when we add/remove an item
 * wf-items-collection (added as an attribute above the wf-item-component (above wf-dropdown) and NOT on the ng-repeat element)
 * wf-action-compiler Is used for passing the jQuery element in order to remove it from the list. It is added on the ng-repeat element
 * 
 * <ul class="list-group" wf-items-collection="{ items: vm.getItems() }">
		<li wf-action-compiler ng-repeat="item in vm.getItems()">
			<wf-item-component item="item" dropdown-actions="'userData'"></wf-item-component>
		</li>
	</ul>
 * 
 * 
 * **NOTE
 * 	The order of appearance inside the dropdown depends on the order of objects/strings inside actions attribute (['userData', {text:"Delete", icon:"fa fa-remove", action:"itemVm.removeMe(item)"}])
 * 	There is a "dummy" String value '-' that can be included inside the array in between actions which adds the separation line between actions.
 *	The "dummy" separator can be expressed as an Object with the condition property: { divider: '-', condition: showDivider}.
 *  
 *  wf-dropdown directive supports SINGLE transclusion which uses buildHtml() funciton to populate a dropdown list with <li> actions
 * 
 *  
 * 
 */

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfDropdown', wfDropdown)

    wfDropdown.$inject = ['$compile', '$parse', '$interpolate', 'modalService', '$translate', '$q', '$state', '$timeout', 'wfTranslate', 'wfMeasureService']
    function wfDropdown($compile, $parse, $interpolate, modal, $translate, $q, $state, $timeout, wfTranslate, wfMeasureService) {
        const directive = {
            require: ['?^^wfItemsCollection', '?^^wfActionCompiler', '?^^wfPermissions', '?^^wfActionCompilerUpdate', '?^^wfDataNegotiator'],
            link,
            restrict: 'E',
            transclude: true,
            templateUrl: 'scripts/wf/dropdown/wfDropdown.directive.html',
            controllerAs: 'dropdownVm',
            controller: ['$scope', '$element', '$attrs', '$transclude', function ($scope, $element, $attrs, $transclude) {
                const vm = this

                if ('appendedPopoverToBody' in $attrs) {
                    vm.appendedPopoverToBody = $parse($attrs.appendedPopoverToBody)($scope)
                }

                if ('customActionSpecifications' in $attrs) {
                    vm.customActionSpecifications = $parse($attrs.customActionSpecifications)($scope)
                }

                if ('getCustomFormSpecificationForEditing' in $attrs) {
                    vm.getCustomFormSpecificationForEditing = $parse($attrs.getCustomFormSpecificationForEditing)($scope)
                }
				
                if ('btnClass' in $attrs) {
                    vm.btnClass = $attrs.btnClass
                }
                else vm.btnClass = 'btn btn-hollow btn-sq'

                if ('btnTranslate' in $attrs) {
                    vm.btnCaption = $translate.instant($parse($attrs.btnTranslate)($scope))
                }
                else if ('btnCaption' in $attrs) {
                    vm.btnCaption = $parse($attrs.btnCaption)($scope)
                }

                if ('btnIcon' in $attrs) {
                    vm.btnIcon = $attrs.btnIcon

                    if ('btnIconPlacement' in $attrs) {
                        vm.btnIconPlacement = $attrs.btnIconPlacement
                    }
                }
				
                if ('btnDropdownIndicator' in $attrs) {
                    vm.btnDropdownIndicator = $attrs.btnDropdownIndicator
                }

                if ('overflowSettings' in $attrs) {
                    vm.overflowSettings = $parse($attrs.overflowSettings)($scope)
                }

                if (!vm.overflowSettings) vm.overflowSettings = {}
					
                _.assign(vm.overflowSettings, {
                    appendedPopoverToBody: vm.appendedPopoverToBody,
                })

                if ('ticket' in $attrs) {
                    vm.ticket = $parse($attrs.ticket)($scope)
                    vm.thirdParty = vm.ticket && vm.ticket.thirdParty
                }

                $transclude((clone, outerScope) => {
                    let transcludedDropdownTriggerElement; let transcludedDropdownTriggerContent
                    let customTriggerWrapper

                    if (clone && (transcludedDropdownTriggerElement = clone.filter('wf-dropdown-trigger')).length) {
                        transcludedDropdownTriggerContent = transcludedDropdownTriggerElement.contents()

                        customTriggerWrapper = $element.find('div.dropdown-custom-trigger')
                        customTriggerWrapper.html(transcludedDropdownTriggerElement.html())
                        vm.useCustomTriggerHtml = true

                    }
                })
            }],
        }
        return directive
		
        function link(scope, element, attrs, controllers, transclude) {
            const
                vm = scope.dropdownVm
				
            let interpolateFunc
				
            let onActionClickCallback
				
            const wfItemsCollection = controllers[0]
				
            const wfActionCompiler = controllers[1]
				
            const wfPermissions = controllers[2]
				
            const wfActionCompilerUpdate = controllers[3]
				
            const wfDataNegotiator = controllers[4]
				
            const itemAttributes = ['actions', 'items']
				
            let intersectionSettings
				
            let allDropdownItems // Collection of all specified dropdown items in this instance of wfDropdown

            scope.usePlusIcon = false
            if ('usePlusIcon' in attrs) {
                scope.usePlusIcon = true
            }

            var fn = function () {
                element[0].removeEventListener('click', fn, false)

                allDropdownItems = []
				
                transclude(scope, (clone, outerScope) => {
                    let html
                    const ul = element.find('ul.dropdown-menu')
                    let actionsCollection = []
                    let actions
                    let transcludedDropdownItemElements

                    _.assign(vm, {
                        //Variables
                        item: undefined,
                        itemContent: undefined,
                        dataRelation: undefined,
                        disableDeleting: false,
                        overflowSettings: undefined,

                        //Functions
                        edit,
                        editVisibility,
                        deleteContent,
                        deleteRelation,
                        addTo,
                        sendTo,
                        openObjectViewer,
                        attach,
                        editPermissions,
                        sendInviteMail,
                        removeFromOrganization,
                        invokeAction,
                    })

                    ul.hide()

                    if (clone.length && (transcludedDropdownItemElements = clone.filter('wf-dropdown-item')).length) {
                        ul.append(transcludedDropdownItemElements)
                        ul.html(ul.html().replace(/wf-dropdown-item/g, 'li'))
                    }
                    else if (clone.length && (transcludedDropdownItemElements = clone.filter('li')).length) {
                        ul.append(transcludedDropdownItemElements)
                        ul.html(ul.html())
                    }
                    else {
                        if ('itemContent' in attrs && 'itemRelation' in attrs) {
                            vm.itemContent = $parse(attrs.itemContent)(scope)
                            vm.dataRelation = $parse(attrs.itemRelation)(scope)
                        }
                        else if ('itemContent' in attrs && !('itemRelation' in attrs)) {
                            vm.itemContent = $parse(attrs.itemContent)(scope)
                        }
                        else if ('item' in attrs) {
                            vm.item = $parse(attrs.item)(scope)
                            if ('DSChanges' in vm.item) { // If of type WfObject
                                vm.itemContent = vm.item.childContent
                                vm.dataRelation = vm.item
                            }
                            else if (vm.item.composite || vm.item.isComposite) {
                                vm.itemComposite = vm.item
                                vm.itemContent = vm.item.content
                                vm.dataRelation = vm.item.dataRelation
                            }
                        }

                        if ('element' in attrs) {
                            vm.element = $parse(attrs.element)(scope)
                        }

                        if ('disableDeleting' in attrs) {
                            const parsedValue = $parse(attrs.disableDeleting)(scope)
                            if (parsedValue != undefined && parsedValue) vm.disableDeleting = parsedValue
                        }

                        if ('intersection' in attrs) {
                            intersectionSettings = $parse(attrs.intersection)(scope)
                        }

                        if ('onActionClick' in attrs) {
                            onActionClickCallback = $parse(attrs.onActionClick)(scope)
                        }

                        if ('actions' in attrs) {
                            actions = $parse(attrs.actions)(scope)
                            if (typeof actions === 'string') {
                                actions = [actions]
                            }

                            if (vm.itemContent && vm.itemContent.isAnswerType() && (!actions || !actions.length)) {
                                actionsCollection = extractDropdownItems(['answers'])
                            }
                            else actionsCollection = extractDropdownItems(actions)
                        }
						
                        if (actionsCollection.length != 0) {
                            _.each(allDropdownItems, (item, index) => {
                                if (typeof item === 'object') item.id = index
                            })

                            html = ''
                            _.each(actionsCollection, (item) => {
                                html += buildHtml(item)
                            })
                            ul.html(html)
                        }
                    }

                    $compile(ul.contents())(scope)
                    setTimeout(() => {
                        ul.css({ display: '' })
                    }, 0)
                    $timeout()
                })
            }
            element[0].addEventListener('click', fn, false)

            element[0].addEventListener('click', (event) => {
                event.dropdown = true
            }, false)

            //-----------------------------------------------------------------------------
            //-----------------------------FUNCTIONS---------------------------------------
            //-----------------------------------------------------------------------------

            function getActionSpecification(key) {
                let specification = getSpecification(key)
                let customSpecification = undefined

                if (vm.customActionSpecifications) {
                    customSpecification = vm.customActionSpecifications[key]

                    if (customSpecification) specification = customSpecification
                }

                return specification

                function getSpecification(key) {
                    switch (key) {
                        case 'edit':
                            return { text: 'Edit', icon: 'fa fa-pencil', action: 'dropdownVm.edit(dropdownVm.itemContent, dropdownVm.dataRelation)', condition(dropdownVm) {
                                return dropdownVm.itemContent.type !== enums.objectType.questionAnswer
                            } }
                        case 'visibility':
                            return { text: 'VisibleIn', icon: 'fa fa-eye', action: 'dropdownVm.editVisibility(dropdownVm.itemContent)', condition() {
                                return false
                            } }
                        case 'deleteContent':
                            return { text: 'Delete', icon: 'fa fa-trash', action: 'dropdownVm.deleteContent(dropdownVm.itemContent, dropdownVm.dataRelation)', cssClass: 'removal',
                                condition() { return !vm.disableDeleting && vm.itemContent.type !== enums.objectType.productionSite && vm.itemContent.type !== enums.objectType.productService } }
                        case 'deleteRelation':
                            return { text: 'DeleteTag', icon: 'fa fa-times', action: 'dropdownVm.deleteRelation(dropdownVm.dataRelation)', cssClass: 'removal',
                                condition(dropdownVm) {
                                    return vm.dataRelation != undefined && vm.dataRelation.type === enums.objectType.dataRelation
										&& dropdownVm.itemContent.type !== enums.objectType.measureAnswer
										&& dropdownVm.itemContent.type !== enums.objectType.questionAnswer
                                },
                            }
                        case 'addTo':
                            return { text: 'AddTo', icon: 'fa fa-plus', action: 'dropdownVm.addTo(dropdownVm.itemContent)' }
                        case 'sendTo':
                            return { text: 'SendTo', icon: 'fas fa-user-friends', action: 'dropdownVm.sendTo(dropdownVm.itemContent)' }
                        case 'objectViewer':
                            return { text: 'OpenObjectViewer', icon: 'fa fa-external-link', href(dropdownVm) {
                                return $state.href('objectViewer_encoded', { encodedData: dropdownVm.itemContent.getEncodedWfid() })
                            } }
                        case 'attachInformation':
                            return { text: 'AttachInformation', icon: 'fa fa-paperclip', subActions: extractDropdownItems(getDefaultActionCollections().attachedInformation) }
                        case 'editPermissions':
                            return { text: 'EditPermissions', icon: 'fa fa-user', action: 'dropdownVm.editPermissions(dropdownVm.itemContent)' }
                        case 'sendInviteMail':
                            return { text: 'modules.users.SendInviteMail', icon: 'fa fa-envelope', action: 'dropdownVm.sendInviteMail(dropdownVm.itemContent)' }
                        case 'removeFromOrganization':
                            return { text: 'modules.users.RemoveFromOrganization', icon: 'fa fa-trash', action: 'dropdownVm.removeFromOrganization(dropdownVm.itemContent)' }
                        case 'attachActivity':
                            return { text: 'Activity', icon: 'fa fa-calendar-check-o', action: 'dropdownVm.attach(\'activity\', dropdownVm.itemContent)' }
                        case 'attachDocument':
                            return { text: 'Document', icon: 'fa fa-file', action: 'dropdownVm.attach(\'document\', dropdownVm.itemContent)' }
                        case 'attachStatement':
                            return { text: 'Statement', icon: 'fa fa-quote-right', action: 'dropdownVm.attach(\'statement\', dropdownVm.itemContent)' }
                        case 'attachLink':
                            return { text: 'Link', icon: 'fa fa-link', action: 'dropdownVm.attach(\'link\', dropdownVm.itemContent)' }
                        case 'attachMedia':
                            return { text: 'Embed', icon: 'fa fa-play-circle', action: 'dropdownVm.attach(\'embed\', dropdownVm.itemContent)' }
						// case "attachLocation":
						// 	return { text: 'AddTo', icon: 'fa fa-plus', action: 'dropdownVm.addTo(dropdownVm.itemContent)' };
                    }
                }
            }

            function getDefaultActionCollections() {
                return {
                    userData: ['edit', 'visibility', '-', 'deleteContent', 'deleteRelation'],
                    answers: ['objectViewer', 'visibility', 'attachInformation', '-', 'deleteContent'],
                    frameworkData: ['objectViewer', '-', 'addTo', 'sendTo', 'attachInformation'],
                    userPermissions: ['edit', 'editPermissions', 'sendInviteMail', 'removeFromOrganization'],
                    attachedInformation: ['attachActivity', 'attachDocument', 'attachStatement', 'attachLink', 'attachMedia'],
                }
            }
			
            function getMultilingual(text) {
                return wfTranslate.instant(text)
            }

            function extractDropdownItems(actions) {
                const collection = []
                const defaultActionCollections = getDefaultActionCollections()
                let actionSpecification
                const shouldPush = function(condition) {
                    if (typeof condition === 'boolean') return condition
                    else if (typeof condition === 'function') return condition(vm)
                    else return true
                }

                _.each(actions, (dropdownItem) => {
                    if (typeof dropdownItem === 'string') {
                        if (defaultActionCollections[dropdownItem] != undefined) {
                            Array.prototype.push.apply(collection, extractDropdownItems(defaultActionCollections[dropdownItem]))
                        }
                        else {
                            if (dropdownItem === '-') collection.push(dropdownItem)
                            else {
                                actionSpecification = getActionSpecification(dropdownItem)
                                if (actionSpecification != undefined) {
                                    if (shouldPush(actionSpecification.condition)) collection.push(actionSpecification)
                                }
                                else console.error('Action \'' + dropdownItem + '\' does not exist - check getActionSpecification()')
                            }
                        }
                    }
                    else if (typeof dropdownItem === 'object') {
                        if (shouldPush(dropdownItem.condition)) {
                            if (dropdownItem.divider) {
                                dropdownItem = dropdownItem.divider
                            }
                            collection.push(dropdownItem)
                        }
                    }
                })

                if (collection[0] === '-') {
                    collection.shift()
                }
                if (collection[collection.length - 1] === '-') {
                    collection.pop()
                }

                setMultilingualForAllActions(collection)
                return collection
            }

            function setMultilingualForAllActions(collection) {
                _.each(collection, (item) => {
                    allDropdownItems.push(item)

                    if (item.subActions != undefined) {
                        if (item.subActions.constructor === Array && item.subActions.length != 0) setMultilingualForAllActions(item.subActions)
                    }
                    if (typeof item === 'object') item.text = getMultilingual(item.text)
                })
            }

            function buildHtml(item) {
                let
                    subActionsHtml
					
                const liClasses = []
					
                let actionExpression

                if (item === '-') return '<li class=\'divider\'></li>'

                if (item.subActions) {
                    liClasses.push('dropdown-submenu')
                    subActionsHtml = ''
                    _.each(item.subActions, (subAction) => {
                        subActionsHtml += buildHtml(subAction)
                    })
                    subActionsHtml = '<ul class="dropdown-menu">' + subActionsHtml + '</ul>'
                }

                if (item.cssClass) liClasses.push(item.cssClass)

                if (typeof item.action === 'string') actionExpression = item.action
                else actionExpression = 'dropdownVm.invokeAction(' + item.id + ')'

                if (!interpolateFunc) interpolateFunc = $interpolate('<li{{liClass}}{{dataCy}}><a wf-click="{{actionExpression}}"{{hrefAttr}}><i ng-class="\'{{icon}}\'"></i><span ng-bind="::\'{{text}}\'"></span><span wf-if-first="{{showSubText}}" ng-bind="::\'{{subText}}\'" class="subText"></span></a>{{subMenuUl}}</li>')

                return interpolateFunc(_.assign({
                    showSubText: !!item.subText,
                    liClass: liClasses.length ? ' class="' + liClasses.join(' ') + '"' : '',
                    hrefAttr: item.href ? (' href="' + item.href(vm) + '"' + (item.hrefTarget ? ' target="' + item.hrefTarget + '"' : '')) : '',
                    subMenuUl: item.subActions ? subActionsHtml : '',
                    actionExpression,
                }, item))
            }

            function notifyItemUpdated() {
                if (wfActionCompilerUpdate) {
                    setTimeout(() => {
                        wfActionCompilerUpdate.compile()
                    }, 200)
                }
                // else if (wfDataNegotiator && wfDataNegotiator.instance) {
                // 	wfDataNegotiator.instance
                // }
                else if (vm.element) {
                    vm.element.height(vm.element.height())
                    vm.element.css({ opacity: 0 })
					
                    $compile(vm.element.contents())(scope)
					
                    setTimeout(() => {
                        vm.element.css('height', '')
                        vm.element.animate({ opacity: 1 }, 300)
                    }, 300)
                }
				
                scope.$emit('dropdownActionExecuted', 'update', vm.itemContent, vm.dataRelation)
                if (wfItemsCollection) {
                    wfItemsCollection.update(vm.itemContent)
                }
            }

            function edit(itemContent, dataRelation) {
                if (dataRelation != undefined) {
                    if (vm.itemContent.type === enums.objectType.measureAnswer) {
                        if (vm.getCustomFormSpecificationForEditing) {
                            vm.getCustomFormSpecificationForEditing().then((formSpec) => {
                                modal.edit({ ...itemContent, ...formSpec.model }, { thirdParty: vm.thirdParty, customFormSpecification: { form: formSpec.form } }).then((res) => {
                                    notifyItemUpdated()
                                })
                            })
                        }
                    }
                    else {
                        modal.edit(itemContent, { thirdParty: vm.thirdParty }).then((res) => {
                            notifyItemUpdated()
                        })
                    }
                }
                else console.error('Item data relation not defined for item: ', itemContent)
            }

            function editVisibility(itemContent) {
                modal.editVisibility(itemContent).closed(() => {
                    scope.$emit('dropdownActionExecuted', 'visibility', itemContent)
                })
            }

            function deleteContent(itemContent, dataRelation) {
                if (dataRelation != undefined) {
                    const emitAndRemoveFromItemsCollection = function () {
                        scope.$emit('dropdownActionExecuted', 'delete', itemContent, dataRelation)
                        if (wfItemsCollection) {
                            wfItemsCollection.remove(vm.itemComposite ? vm.itemComposite : dataRelation)
                        }
                    }

                    modal.confirmDelete(itemContent, { thirdParty: vm.thirdParty }).then(() => {
                        animateRemoval().then(() => {
                            emitAndRemoveFromItemsCollection()
                        })
                    })
                }
                else console.error('Cannot delete the item! DataRelation not defined for item: ', itemContent)
            }

            function deleteRelation(dataRelation) {
                if (dataRelation != undefined) {
                    const emitAndRemoveFromItemsCollection = function () {
                        scope.$emit('dropdownActionExecuted', 'delete', dataRelation)
                        if (wfItemsCollection) {
                            wfItemsCollection.remove(vm.itemComposite ? vm.itemComposite : dataRelation)
                        }
                    }

                    modal.confirmDelete(dataRelation, { thirdParty: vm.thirdParty }).then(() => {
                        animateRemoval().then(() => {
                            emitAndRemoveFromItemsCollection()
                        })
                    })
                }
                else console.error('Cannot remove the item! DataRelation not defined for item: ', dataRelation)
            }

            function animateRemoval() {
                let elementToRemove
                return $q((resolve, reject) => {
                    if (vm.element) elementToRemove = vm.element

                    if (wfActionCompiler) elementToRemove = wfActionCompiler.element

                    if (elementToRemove) {
                        elementToRemove.animate({ opacity: 0 }, 200, () => {
                            elementToRemove.animate({ height: 0, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0, borderTopWidth: 0, borderBottomWidth: 0 }, 200, () => {
                                elementToRemove.remove()
                                resolve()
                            })
                        })
                    }
                    else resolve()
                })
            }

            function addTo(item) {
                modal.addTo(item).closed(() => {
                    scope.$emit('dropdownActionExecuted', 'addTo')

                    if (scope.mdVm) scope.mdVm.updateNumbers()
                    else if (scope.itemVm != undefined) {
                        if (scope.itemVm.metadataMethods) scope.itemVm.metadataMethods.updateNumbers()
                    }
                })
            }

            function sendTo(item) {
                modal.sendTo(item).closed(() => {
                    scope.$emit('dropdownActionExecuted', 'sendTo')

                    if (scope.mdVm) scope.mdVm.updateNumbers()
                    else if (scope.itemVm != undefined) {
                        if (scope.itemVm.metadataMethods) scope.itemVm.metadataMethods.updateNumbers()
                    }
                })
            }

            function openObjectViewer(itemContent) {
                const url = $state.href('objectViewer_encoded', { encodedData: itemContent.getEncodedWfid() })
                window.open(url, '_blank')
            }

            function attach(typeString, itemContent) {
                let type

                switch (typeString) {
                    case 'activity':
                        type = enums.objectType.orgActivity
                        break
                    case 'document':
                        type = enums.objectType.orgDocument
                        break
                    case 'statement':
                        type = enums.objectType.statement
                        break
                    case 'link':
                        type = enums.objectType.link
                        break
                    case 'embed':
                        type = enums.objectType.embed
                        break
                }

                if (type != undefined) {
                    const pickerOptions = {
                        objectTypes: [type],
                        relationTarget: { item: itemContent, kind: enums.subItemsKind.relatedContentByUser },
                        intersection: intersectionSettings,
                    }
                    modal.openCreatorAndPicker(pickerOptions).closed(() => {
                        scope.$emit('dropdownActionExecuted', 'attach', itemContent)
                        if (wfItemsCollection) {
                            wfItemsCollection.callback_attach(itemContent)
                        }
						
                        if (scope.mdVm) scope.mdVm.updateNumbers()
                        else if (scope.itemVm != undefined) {
                            if (scope.itemVm.metadataMethods) scope.itemVm.metadataMethods.updateNumbers()
                        }
                        else if (scope.boxVm != undefined) {
                            scope.boxVm.objViewerVm.populateBoxItems(scope.boxVm.box)
                        }
                    })
                }
            }

            function editPermissions(item) {
                console.log('Editing permissions', item)
            }

            function sendInviteMail(item) {
                console.log('Sending invite e-mail', item)
            }

            function removeFromOrganization(item) {
                console.log('Removing item from organization', item)
            }

            function invokeAction(actionId) {
                const
                    dropdownItem = _.find(allDropdownItems, { id: actionId })
					
                let func
					
                let notifyAs

                if (dropdownItem) {
                    if (typeof dropdownItem.action === 'object') {
                        func = dropdownItem.action.func
                        notifyAs = dropdownItem.action.notifyAs
                    }
                    else if (typeof dropdownItem.action === 'function') {
                        func = dropdownItem.action
                    }

                    if (typeof func === 'function') {
                        func.call(dropdownItem, vm.itemContent, vm.dataRelation, {
                            action: dropdownItem,
                            notifyItemUpdated,
                            notifyItemRemoved() {
                                animateRemoval().then(() => {
                                    scope.$emit('dropdownActionExecuted', 'delete', vm.itemContent, vm.dataRelation)
                                    if (wfItemsCollection) {
                                        wfItemsCollection.remove(vm.itemComposite ? vm.itemComposite : vm.dataRelation)
                                    }
                                })
                            },
                        })
                    }
                }
            }
        }
    }
})()
