import React from 'react'
import { react2angular } from 'react2angular'
import ProductOrganizationExplorer from '@worldfavor/portal/components/Product/ProductOrganizationExplorer'
import { WrapProvider } from './providers'

const props = [
    'open',
    'organizationId',
    'organizationName',
    'networkId',
    'onClosed',
]

class ProductOrganizationExplorerWrapper extends React.Component {
  prepareProps = (props) => {
      return {
          ...props,
      }
  }

  render() {
      const { wfAuth, ...rest } = this.props
      return (
          <WrapProvider wfAuth={wfAuth}>
              <ProductOrganizationExplorer {...this.prepareProps(rest)} />
          </WrapProvider>
      )
  }
}

export default react2angular(ProductOrganizationExplorerWrapper, props, ['wfAuth'])
