import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfListItem', {
            templateUrl: 'scripts/wf/list/wfListItem_wrapper.component.html',
            controller: wfListItemController,
            controllerAs: 'vm',
            bindings: {
                item: '<',
                listVm: '<',
                configFromAttribute: '=config',
            },
        })

    wfListItemController.$inject = ['$scope', '$rootScope', 'dropdownUtility']
    function wfListItemController($scope, $rootScope, dropdownUtility) {
        const
            vm = this
			
        let contentWatcher

        _.assign(vm, {
            $rootScope,
            config: {
                isExpandableItem: false, //vm.item.type === enums.objectType.structure,
                useItemAsTrigger: true,
                asTableRow: false,
            },
            showDropdown: true,
            showMetadata: true,
            enums,
            useDefaultItemTemplate: vm.item.isPlaceholder ? true : (vm.item.type !== enums.objectType.notification && vm.item.type !== enums.objectType.logEvent),
            showAggregatedTotals: false,
            paddingLeftFromDepth: vm.item.depth ? { paddingLeft: vm.item.depth * 30 } : undefined,
            hasItemRichMedia: vm.item.type === enums.objectType.embed && vm.item.content.html,
            metadataConfig: {
                // limitRelationKinds: [
                // 	//enums.subItemsKind.children,
                // 	// enums.subItemsKind.parents,
                // 	enums.subItemsKind.verifications,
                // 	enums.subItemsKind.relatedContent,
                // 	enums.subItemsKind.relatedContentByUser,
                // 	enums.subItemsKind.verifies,
                // 	//enums.subItemsKind.childrenByUser,
                // 	// enums.subItemsKind.parentsByUser,
                // 	enums.subItemsKind.usersOnOrg,
                // 	enums.subItemsKind.visible,
                // 	enums.subItemsKind.anscestor,
                // 	enums.subItemsKind.relatedParents,
                // 	enums.subItemsKind.relatedParentsByUser,
                // 	enums.subItemsKind.contextChildren,
                // 	enums.subItemsKind.contextParents
                // ]
            },

            // Functions
            getItemCss,
        })

        ////////////////

        vm.$onInit = function() {
            if (vm.listVm) {
                if (vm.listVm.config.itemSettings.showDropdown === false) vm.showDropdown = false
				
                if (vm.showDropdown) {

                    if (vm.listVm.config.itemSettings.dropdownActions instanceof Array) vm.dropdownActions = vm.listVm.config.itemSettings.dropdownActions
                    else vm.dropdownActions = dropdownUtility.determineActions(vm.item, vm.listVm.negotiator)
                }

                // Sometimes structures are the user data data and should not have aggregated stats. Below code will make it always show for structure and have to be modified.
                // The type is structure it a custom list have been used when reporting. Check WF Dev Account for an example.
                // vm.showAggregatedTotals = vm.listVm.isConsolidation && ((vm.item.type === enums.objectType.structure) || !!~[ enums.objectType.question, enums.objectType.measure ].indexOf(vm.item.type));
                vm.showAggregatedTotals = vm.listVm.config.showAggregatedTotals
				
                if (vm.listVm.config.itemSettings.showMetadata === false) vm.showMetadata = false
					
                vm.metadataConfig = _.get(vm.listVm.config, 'itemSettings.metadataConfig')
            }

            if (!vm.item.content) {
                contentWatcher = $scope.$watch('vm.item.content', () => {
                    if (vm.item.content) {
                        // vm.showAttachedInformation = _.get(vm.item.content, "metadata.countByRelationKind." + enums.subItemsKind.relatedContentByUser) > 0
                        contentWatcher()
                    }
                })
            }

            _.assign(vm.config, vm.configFromAttribute)
        }
        vm.$onChanges = function(changesObj) { }
        vm.$onDestroy = function() { }

        function getItemCss() {
            if (vm.listVm && vm.listVm.showItemIndentation) return vm.paddingLeftFromDepth
        }
    }
})()
