import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {

    },
    button: {

    },
})

const FilterButton = (props) => {
    const { label } = props

    const classes = useStyles(props)

    return (
        <Button
            className={classes.button}
            variant={'outlined'}
        >
            { label }
        </Button>
    )
}

export default FilterButton
