(function () {
    'use strict'

    angular
        .module('wf.data')
        .service('dataOperationsCache', dataOperationsCache)

    dataOperationsCache.$inject = []

    function dataOperationsCache() {
        let
            requestsAlreadyMade = {}
			
        let negotiatorInstancesByWfid = {}
			
        const service = {
            clearCachedRequests,
            eject,
            hasRequestAlreadyBeenMade,
            rememberRequest,
            rememberNegotiator,
            getNegotiator,
            clearNegotiators,
        }

        return service
		
        function clearCachedRequests() {
            requestsAlreadyMade = {}
            negotiatorInstancesByWfid = {}
        }

        function eject(wfid) {
            delete requestsAlreadyMade[wfid]
        }
		
        function hasRequestAlreadyBeenMade(wfid, apiParams) {
            const oldParamsList = requestsAlreadyMade[wfid]
            if (oldParamsList)
            {
                for (var i = 0, len = oldParamsList.length, oldParams; i < len; i++) {
                    oldParams = oldParamsList[i]

                    // Uncomment below to debug
                    // if (_.get(apiParams, "ticket.organizationId") === 122012) {
                    // 	console.log({
                    // 		ifConditions: _.chain({
                    // 			"oldParams.includeChildrensRelatedContent === apiParams.includeChildrensRelatedContent": oldParams.includeChildrensRelatedContent === apiParams.includeChildrensRelatedContent,
                    // 			"oldParams.includeChildrensChildren === apiParams.includeChildrensChildren": oldParams.includeChildrensChildren === apiParams.includeChildrensChildren,
                    // 			"oldParams.includeChildrensParents === apiParams.includeChildrensParents": oldParams.includeChildrensParents === apiParams.includeChildrensParents,
                    // 			"oldParams.includeMultilingual === apiParams.includeMultilingual": oldParams.includeMultilingual === apiParams.includeMultilingual,
                    // 			"oldParams.wrapInRelations === apiParams.wrapInRelations": oldParams.wrapInRelations === apiParams.wrapInRelations,
                    // 			"oldParams.objectType === apiParams.objectType": oldParams.objectType === apiParams.objectType,
                    // 			"oldParams.organizationId === apiParams.organizationId": oldParams.organizationId === apiParams.organizationId,
                    // 			"oldParams.kind === apiParams.kind": oldParams.kind === apiParams.kind,
                    // 			"oldParams.useDeletedDataRelations === apiParams.useDeletedDataRelations": oldParams.useDeletedDataRelations === apiParams.useDeletedDataRelations,
                    // 			"oldParams.includeDataRelationsMetadataCount === apiParams.includeDataRelationsMetadataCount": oldParams.includeDataRelationsMetadataCount === apiParams.includeDataRelationsMetadataCount,
                    // 			"oldParams.useOrganizationMatchModeFromKind === apiParams.useOrganizationMatchModeFromKind": oldParams.useOrganizationMatchModeFromKind === apiParams.useOrganizationMatchModeFromKind,
                    // 			"oldParams.aggregateObjectType === apiParams.aggregateObjectType": oldParams.aggregateObjectType === apiParams.aggregateObjectType,
                    // 			"oldParams.skipChildContentOnDepth === apiParams.skipChildContentOnDepth": oldParams.skipChildContentOnDepth === apiParams.skipChildContentOnDepth,
                    // 			"oldParams.onlyLoadRelations === apiParams.onlyLoadRelations": oldParams.onlyLoadRelations === apiParams.onlyLoadRelations,
                    // 			"oldParams.ignoreAdditionalLoadDepthLimits === apiParams.ignoreAdditionalLoadDepthLimits": oldParams.ignoreAdditionalLoadDepthLimits === apiParams.ignoreAdditionalLoadDepthLimits,
                    // 			"(oldParams.aggregate === apiParams.aggregate && oldParams.networkId === apiParams.networkId)": (oldParams.aggregate === apiParams.aggregate && oldParams.networkId === apiParams.networkId),
                    // 			"_.isEqual(oldParams.getterConditions, apiParams.getterConditions)": _.isEqual(oldParams.getterConditions, apiParams.getterConditions),
                    // 			"_.isEqual(oldParams.ticket, apiParams.ticket)": _.isEqual(oldParams.ticket, apiParams.ticket)
                    // 		}).toPairs().groupBy("[1]").value(),
                    // 		oldParams: _.cloneDeep(oldParams),
                    // 		apiParams: _.cloneDeep(apiParams)
                    // 	});
                    // }

                    if (apiParams.requestSignature_noResultNeeded) {
                        if (apiParams.getterConditions && apiParams.getterConditions.organizationIds) {
                            apiParams = _.cloneDeep(apiParams)
                            oldParams = _.cloneDeep(oldParams)

                            delete apiParams.getterConditions.organizationIds

                            if (oldParams.getterConditions && oldParams.getterConditions.organizationIds) {
                                delete oldParams.getterConditions.organizationIds
                            }

                            // console.log("Ignoring apiParams.getterConditions.organizationIds", apiParams);
                        }
                    }
					
                    // console.log(wfid, '-------------------------------------------');
                    // console.log(typeof oldParams.childrenLoadDepth, '===', typeof apiParams.childrenLoadDepth, typeof oldParams.childrenLoadDepth === typeof apiParams.childrenLoadDepth)
                    // console.log(oldParams.childrenLoadDepth, '>=', apiParams.childrenLoadDepth, (typeof oldParams.childrenLoadDepth === typeof apiParams.childrenLoadDepth
                    // 	&& (!oldParams.childrenLoadDepth || oldParams.childrenLoadDepth >= apiParams.childrenLoadDepth)))
                    // console.log(oldParams.includeChildrensRelatedContent, '===', apiParams.includeChildrensRelatedContent, oldParams.includeChildrensRelatedContent === apiParams.includeChildrensRelatedContent)
                    if (!apiParams.onlyStatistics && (!apiParams.childrenLoadDepth || (typeof oldParams.childrenLoadDepth === typeof apiParams.childrenLoadDepth
						&& (!oldParams.childrenLoadDepth || oldParams.childrenLoadDepth >= apiParams.childrenLoadDepth)))
						&& oldParams.includeChildrensRelatedContent === apiParams.includeChildrensRelatedContent
						&& oldParams.includeChildrensChildren === apiParams.includeChildrensChildren
						&& oldParams.includeChildrensParents === apiParams.includeChildrensParents
						&& oldParams.includeMultilingual === apiParams.includeMultilingual
						&& oldParams.wrapInRelations === apiParams.wrapInRelations
						&& oldParams.objectType === apiParams.objectType
						&& oldParams.organizationId === apiParams.organizationId
						&& oldParams.kind === apiParams.kind
						&& oldParams.useDeletedDataRelations === apiParams.useDeletedDataRelations
						&& oldParams.includeDataRelationsMetadataCount === apiParams.includeDataRelationsMetadataCount
						&& oldParams.useOrganizationMatchModeFromKind === apiParams.useOrganizationMatchModeFromKind
						&& oldParams.aggregateObjectType === apiParams.aggregateObjectType
						&& oldParams.skipChildContentOnDepth === apiParams.skipChildContentOnDepth
						&& oldParams.onlyLoadRelations === apiParams.onlyLoadRelations
						&& oldParams.ignoreAdditionalLoadDepthLimits === apiParams.ignoreAdditionalLoadDepthLimits
						&& (oldParams.aggregate === apiParams.aggregate && oldParams.networkId === apiParams.networkId)
						&& _.isEqual(oldParams.getterConditions, apiParams.getterConditions)
						&& _.isEqual(oldParams.ticket, apiParams.ticket)
                    )
                    {
						
                        // console.log('same request', wfid, oldParams, apiParams);
                        // console.log('-------------------------------------------');
                        return true
                    }
                }
            }
            else
            {
                rememberRequest(wfid, apiParams)
			
                // console.log('new request', wfid);
                // console.log('-------------------------------------------');
                return false
            }
        }
		
        function rememberRequest(wfid, apiParams) {
            if (!requestsAlreadyMade[wfid]) {
                requestsAlreadyMade[wfid] = []
            }
            requestsAlreadyMade[wfid].push(apiParams)
        }

        function getNegotiator(negotiatorOptions) {
            const
                fromItem = negotiatorOptions.fromItem
				
            let fromItemWfid
				
            let negotiatorsForItem

            negotiatorOptions = _.clone(negotiatorOptions)
            delete negotiatorOptions.$scope // Remove $scope so that it is not included when comparing options with _.isEqual
            delete negotiatorOptions.fromItem // Remove fromItem so that it is not included when comparing options with _.isEqual

            if (fromItem.type && fromItem.id) {
                fromItemWfid = fromItem.type + '-' + fromItem.id
            }
            else if (fromItem.wfid) {
                fromItemWfid = fromItem.wfid
            }

            // Attempt to find an existing instance of DataNegotiator that matches the options
            if (fromItemWfid in negotiatorInstancesByWfid) {
                negotiatorsForItem = negotiatorInstancesByWfid[fromItemWfid]
                for (let i = 0, len = negotiatorsForItem.length; i < len; i++) {
                    if (_.isEqual(negotiatorOptions, negotiatorsForItem[i].options)) {
                        // if (fromItemWfid === "21-3599")
                        // console.log("existing negotiator found", negotiatorOptions, negotiatorsForItem[i].options)
                        return negotiatorsForItem[i].instance
                    }
                }
            }
        }

        function rememberNegotiator(negotiatorInstance, negotiatorOptions) {
            const
                fromItem = negotiatorOptions.fromItem
				
            let fromItemWfid
				
            let negotiatorsForItem

            negotiatorOptions = _.chain(negotiatorOptions).clone().omit('$scope').cloneDeep().value()
            delete negotiatorOptions.fromItem // Remove fromItem so that it is not included when comparing options with _.isEqual

            if (fromItem.type && fromItem.id) {
                fromItemWfid = fromItem.type + '-' + fromItem.id
            }
            else if (fromItem.wfid) {
                fromItemWfid = fromItem.wfid
            }

            if (fromItemWfid in negotiatorInstancesByWfid) {
                negotiatorsForItem = negotiatorInstancesByWfid[fromItemWfid]
            }

            if (negotiatorsForItem) negotiatorsForItem.push({ options: negotiatorOptions, instance: negotiatorInstance })
            else negotiatorInstancesByWfid[fromItemWfid] = [{ options: negotiatorOptions, instance: negotiatorInstance }]

            // console.log(negotiatorInstancesByWfid);
        }

        function clearNegotiators() {
            _.each(negotiatorInstancesByWfid, (cacheEntries) => {
                _.each(cacheEntries, (cacheEntry) => {
                    cacheEntry.instance.abortOngoingXhrRequests()
                })
            })

            negotiatorInstancesByWfid = {}
        }
    }
})()
