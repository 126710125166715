import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'
	
    angular
        .module('wf.common')
        .component('wfAnalyzeResultDetails', {
            templateUrl: 'scripts/wf/analyze/result/wfAnalyzeResultDetails.component.html',
            controller: wfAnalyzeResultDetailsController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
                result: '<',
            },
        })

    wfAnalyzeResultDetailsController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService']
    function wfAnalyzeResultDetailsController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService) {
        const
            vm = this
			
        const mainItem = vm.item
		
        _.assign(vm, {
            item: undefined,
            loaded: false,
            availableRequirementOptions: undefined,
            maxItemsCount: 15,
            itemsLimit: 15,
            calculatedAt: null,
        })

        this.$onInit = $onInit

        function $onInit() {
            let requirements
            vm.requirementsLoader = new analyzeService.requirementsLoader(mainItem, vm.ticket, $scope)

            requirements = _.map(vm.result.details, 'requirement')

            vm.details = _.clone(vm.result.details)

            vm.requirementsLoader.loadFormattedRequirementsOnItem({ preloadedRequirements: requirements, networkId: _.get(vm.ticket, 'networkId') }).then((formattedRequirements) => {
                vm.items = formattedRequirements
                _.each(vm.details, (detailsItem) => {
                    const actualPoints = detailsItem.points

                    // Result details are merged with the AnalyzeRequirement for convenience of access
                    _.assign(detailsItem, _.find(vm.items, { requirementId: detailsItem.requirement.id }))

                    // The points on the result details must be used because they might have been dynamically set
                    detailsItem.points = actualPoints

                    if (detailsItem.useObjectDataAsPoints && detailsItem.requirement.derivedType === enums.objectType.measure) {
                        detailsItem.pointsTooltip = $translate.instant('modules.analyze.results.valueFromObjectData_tooltip_measure', {
                            value_and_unit: detailsItem.points + ' ' + _.get(detailsItem, 'sourceDataObject.childContent.name'),
                        })
                    }
                })

                vm.score = vm.result.score
                if (vm.result.score >= 0) vm.score = '+' + vm.score

                vm.calculatedAt_fromNow = moment(vm.result.createdAt).fromNow()
                vm.calculatedAt = moment(vm.result.createdAt).format('ddd YYYY-MM-DD HH:mm')

                vm.loaded = true

                $timeout()
            })
        }
    }
})()
