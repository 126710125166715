/* eslint-disable */
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
//import ctColor from './plugins/ctColor'
//import ctPoint from './plugins/ctPoint'
//import ctTooltip from './plugins/ctTooltip'
//import ctDynamicBarWidth from './plugins/ctDynamicBarWidth'
import type { ChartistData, ChartistOptions } from '@worldfavor/types/Chartist'

const defaultSeriesColor = '#7f8c8d'

// TODO refactor all of that

export const getChartistConfigObject = (options) => {
  let chartConfig = {
    data: {
      labels: undefined,
      series: [ /* Object inside series array { name: undefined, color: undefined, data: { value: undefined, meta: undefined } }*/ ],
    },
    options: undefined,
    drawEvent: undefined,

    // prorperties for the pie chart
    verticalSeries: undefined,
    // selectedVerticalSeries: undefined
  }

  if (options.asEmptyState) {
    chartConfig.options = getEmptyStateChartOptions(options)
    chartConfig.data = randomizeData(options)
  }
  else {
    chartConfig.data = prepareChartData(options)
    options.originalData = chartConfig.data // not needed as coming from the props

    if (chartConfig.data && chartConfig.data.series) {
      let zeroValueDatasets = []
      _.each(chartConfig.data.series, function(series, index) {
        zeroValueDatasets[index] = _.every(series.data, function(data) { return data.value === 0 })
      })
      if (zeroValueDatasets.length > 0 && _.every(zeroValueDatasets, Boolean)) {
        options.messageToDisplay = options.messages.allValuesAreZeros
        options.allowToDismissMessage = true,
        options.showInfoMessage = true
        options.highestValue = 1
      }
    }

    if (options.chartType === 'line' || options.type === 'line') {
      //chartConfig.options = getLineChartOptions(options)
      //chartConfig.responsiveOptions = getResponsiveOptions(options)
    }
    else if (options.chartType === 'bar' || options.type === 'bar') {
      //options.enableDynamicBars = true
      //chartConfig.options = getBarChartOptions(options)
      //chartConfig.responsiveOptions = getResponsiveOptions(options)
    }
    else if (options.chartType === 'pie' || options.type === 'pie') {
      options.verticalSeries = chartConfig.data.labels
      options.selectedVerticalSeries = _.last(options.verticalSeries)

      chartConfig.data = getVerticalSeriesAndLabels(chartConfig.data, options.selectedVerticalSeries)
      //chartConfig.options = getPieChartOptions(options)
    }
    chartConfig.options.originalLabels = chartConfig.data.labels
  }

  return chartConfig
}

export const randomizeData = (options) => {
  let color = '#f5f5f5'
  let amountOfSeriesToGenerate = options.chartType === 'pie' ? 10 : 5
  let randomizedData = {
    labels: [],
    series: [ [], [] ],
  }

  for (let i = 2000; i < 2000 + amountOfSeriesToGenerate; i++) {
    if (options.chartType === 'pie')
      randomizedData.labels.push({ color: '#f5f5f5', value: i })
    else
      randomizedData.labels.push(i)
  }

  _.each(randomizedData.series, function (single, index) {
    if (options.chartType === 'pie') {
      randomizedData.series.length = 0
      _.each(randomizedData.labels, function (label, index) {
        options.chartType === 'pie' && index % 2 == 0 ? color = '#e8e8e8' : color = '#f5f5f5'
        randomizedData.series.push({ value: randomScalingFactor(), color: color })
      })
    }
    else {
      _.each(randomizedData.labels, function () {
        single.push({ value: randomScalingFactor() })
      })
      randomizedData.series[index] = { color: options.colors[index], data: getSeriesData({ dataset: single, valuePropertyKey: 'value', labels: randomizedData.labels, accumulateLineChart: true }) }
    }
  })

  return randomizedData

  function randomScalingFactor() {
    return (Math.random() > 0.5 ? 1.0 : 1.0) * Math.round(Math.random() * 2000)
  }
}

export const prepareChartData = (options) => {

  let { datasets, unit, colors = [], valueProperties = 'value', aggregateMode, stackBars } = options

  let highestValue = undefined
  let lowestValue = undefined
  let dataHasMonths, minYear, minMonth, maxYear, maxMonth
  let outputData = { labels: undefined, series: [] }
  let currentNumberOfColors = colors.length
  let numberOfMissingColors
  let d3Colors

  // TODO Rewrite - temp solution
  // Separating valueProperties = ["sum", "average"] into two arrays in
  // data = [ 0: [{value: sum}], 1: [{value: average} ]
  let finalDatasets = []
  if (typeof valueProperties === 'object') {
    if (datasets.length === 1) {
      _.each(valueProperties, function(value) {
        if (unit === '%' && value === 'sum') return

        const splitDataset = {
          data: datasets[0].data
            .filter((data) => value in data)
            .map((data) => ({
              ...data,
              value: data[value],
            })),
          legendLabel: getLegendLabelFromDataSetId(value),
          id: datasets[0].id,
        }

        finalDatasets.push(_.cloneDeep(splitDataset))
      })
      datasets = finalDatasets
    }
    valueProperties = 'value'
  }

  if (datasets.length > 0) {
    if (aggregateMode === 'separate' && options.ticket && options.ticket.networkId) {
      options.colors = undefined
      currentNumberOfColors = 0
    }

    //Check if there are enough colors defined for series
    if (!options.colors || (options.colors.length < datasets.length)) {
      numberOfMissingColors = datasets.length - currentNumberOfColors
      if (!options.colors)
        options.colors = []

      if (numberOfMissingColors)
        d3Colors = () => {
          return '#444444'
        } // TODO category20c doesnt't exist anymore, replace it d3.scale.category20c()

      if (datasets.length > 1 && numberOfMissingColors) {
        for (let i = currentNumberOfColors, len = datasets.length; i < len; i++) {
          options.colors.push(d3Colors(datasets[i].name || datasets[i].id))
        }
      }
      else {
        for (let i = currentNumberOfColors, len = datasets.length; i < len; i++) {
          options.colors.push(defaultSeriesColor)
        }
      }
      // console.warn("Not enough colors defined for chart series.");
    }

    // Find out min and max year and month across all datasets
    _.each(datasets, function (dataSet) {
      let firstDataEntry = dataSet.data[0]
      let lastDataEntry = dataSet.data[dataSet.data.length - 1]

      if (dataSet.name)
        dataSet.legendLabel = dataSet.name

      if (!dataSet.legendLabel)
        dataSet.legendLabel = getLegendLabelFromDataSetId(dataSet.id) || undefined

      if (!dataSet.legendLabel)
        options.showLegend = false

      //check if measure and add a unit to the dataset
      //_.get(item, "unit.symbol")

      dataHasMonths = firstDataEntry ? firstDataEntry.month : null
      // Find out min and max year and month across all datasets
      if (firstDataEntry) {
        if (!minYear || minYear >= firstDataEntry.year) {
          minYear = firstDataEntry.year

          if (dataHasMonths && !minMonth || minMonth >= firstDataEntry.month) {
            minMonth = firstDataEntry.month
          }
        }
      }
      if (lastDataEntry) {
        if (!maxYear || maxYear <= lastDataEntry.year) {
          maxYear = lastDataEntry.year

          if (dataHasMonths && !maxMonth || maxMonth <= lastDataEntry.month) {
            maxMonth = lastDataEntry.month
          }
        }
      }
    })

    if (dataHasMonths && options.startFromZero) {
      // Offset the min month by -1 so that the line chart will always start with a zero value
      if (minMonth === 1) {
        minMonth = 12
        minYear--
      }
      else {
        minMonth--
      }
    }

    // Loop through all datasets
    _.each(datasets, function(dataset, index) {
      let preparedDataSetEntries = dataset.data,
        legendLabel = dataset.legendLabel,
        dataSetWithoutGaps = [],
        dataByKey = _.keyBy(dataset.data, function (item) {
          if (item.year)
            return item.year + '-' + item.month
          else
            return item.label
        }),
        seriesOptions,
        datasetWithHighestValue

      if (options.fillTimeGapsOnXAxes) {
        // Fill in gaps in the data with zeros so that every month has data
        for (var year = minYear, monthCap; year <= maxYear; year++) {
          // Set correct month cap for this year if it is the last year
          monthCap = year === maxYear ? maxMonth : 12
          // The loop starts on minMonth if this is the first year, otherwise 1
          for (var month = year === minYear ? minMonth : 1, dataEntry; month <= monthCap; month++) {
            dataEntry = dataByKey[year + '-' + month]

            if (dataEntry) // Check if a data entry on this timestamp exists
              dataSetWithoutGaps.push(dataEntry)
            else {
              // If not then fill in the gap with a zero
              dataSetWithoutGaps.push({
                year: year,
                month: month,
                value: 0,
              })
            }
          }
        }
        preparedDataSetEntries = dataSetWithoutGaps
      }
      else if (options.datasetLabels) {
        preparedDataSetEntries = []
        for (let i = 0, len = options.datasetLabels.length; i < len; i++) {
          if (options.datasetLabels[i] in dataByKey)
            preparedDataSetEntries.push(dataByKey[options.datasetLabels[i]])
          else
            preparedDataSetEntries.push({
              label: options.datasetLabels[i],
              year: null,
              month: null,
              value: null,
            })
        }
      }

      if (outputData.labels === undefined)
        outputData.labels = getLabels(preparedDataSetEntries, dataHasMonths)

      seriesOptions = {
        dataset: preparedDataSetEntries,
        datasetName: dataset.name, // The organization name when splitUpOrganizationStatistics is used
        valuePropertyKey: valueProperties,
        labels: outputData.labels,
        accumulateLineChart: options.accumulateLineChart || dataset.accumulate,
        unit: options.unit,
        legendLabel: legendLabel,
      }

      outputData.series.push({ name: legendLabel, color: options.colors[index], data: getSeriesData(seriesOptions) })
    })

    //Find highest value from the dataset
    if (stackBars) {
      let stackedData = []
      _.each(outputData.series, function (singleSeries, index) {
        for (let i = 0, len = singleSeries.data.length; i < len; i++) {
          if (index === 0)
            stackedData.push(_.get(singleSeries.data[i], 'value') || 0)
          else
            stackedData[i] += _.get(singleSeries.data[i], 'value') || 0

        }
      })

      options.highestValue = highestValue = _.max(stackedData)
      options.lowestValue = lowestValue = _.min(stackedData)
    }
    else {
      _.each(outputData.series, function(singleSeries) {
        let datasetWithHighestValue = _.maxBy(singleSeries.data, 'value')
        let datasetWithLowestValue = _.minBy(singleSeries.data, 'value')

        if (!highestValue)
          options.highestValue = highestValue = datasetWithHighestValue.value

        if (!lowestValue)
          options.lowestValue = lowestValue = datasetWithLowestValue.value

        if (datasetWithHighestValue && datasetWithHighestValue.value > highestValue )
          options.highestValue = highestValue = datasetWithHighestValue.value

        if (datasetWithLowestValue && datasetWithLowestValue.value < lowestValue )
          options.lowestValue = lowestValue = datasetWithLowestValue.value
      })
    }

  }
  return outputData
}

export const getLegendLabelFromDataSetId = (value) => {
  let label = '', questionAnswerTypeId, answerText

  if (value && value.indexOf('questionAnswerType-') === 0) {
    questionAnswerTypeId =  value.split('-')[1]

    if (questionAnswerTypeId !== 'unanswered') {
      questionAnswerTypeId = parseInt(questionAnswerTypeId)
    }

    answerText = wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId)
    label = answerText
  }
  else {
    switch (value) {
      case 'sum':
        label = 'Sum' //$translate.instant('Sum')
        break
      case 'average':
        label = 'Average'//$translate.instant('Average')
        break
      default:
        // label = $translate.instant("Count");
        break
    }
  }
  return label
}

export const getLabels = (dataset, dataHasMonths) => {
  let monthNames = moment.monthsShort()
  return _.map(dataset, function(dataEntry) {
    if (dataEntry.label)
      return dataEntry.label
    else if (dataHasMonths)
      return monthNames[dataEntry.month - 1] + '\n' + dataEntry.year
    else
      return dataEntry.year
  })
}

export const getSeriesData = (options) => {
  const { dataset, legendLabel, labels = [], datasetName, valuePropertyKey, accumulateLineChart, unit } = options
  let accumulatedValue = 0
  let tooltipMeta = {
    xAxisLabel: {
      value: undefined,
      label: '',
    },
    unit: {
      value: undefined,
      label: 'unit',
    },
    legendLabel: {
      value: legendLabel,
      label: '',
    },
    organizationCount: {
      value: undefined,
      // TODO
      label: /*$translate.instant*/('Organizations'),
    },
    xAxisLabelDetails: {
      value: undefined,
      label: undefined,
    },
    datasetName: {
      value: datasetName, // The organization name when splitUpOrganizationStatistics is used
      label: undefined,
    },
  }

  return dataset.map((dataEntry, index) => {
    tooltipMeta.xAxisLabel.value = dataEntry.label/*labels[index]*/
    tooltipMeta.organizationCount.value = dataEntry.organizationCount
    tooltipMeta.xAxisLabelDetails.value = dataEntry.detailedLabel
    tooltipMeta.unit.value = dataEntry.unit && dataEntry.unit.symbol || unit

    // Use the sum of all previous values if accumulateLineChart is true
    if (accumulateLineChart) {
      accumulatedValue += dataEntry[valuePropertyKey]
      return {
        value: accumulatedValue,
        meta: stringifyJSON(tooltipMeta),
        unit: dataEntry.unit && dataEntry.unit.symbol || unit,
      }
    }
    else {// Otherwise simply use the value as is
      return {
        value: dataEntry[ valuePropertyKey ],
        meta: stringifyJSON(tooltipMeta),
        unit: dataEntry.unit && dataEntry.unit.symbol || unit,
      }
    }
  })
}

/*export const getLineChartOptions = (options) => {
  return {
    type: options.type,
    showPoint: options.showPoint,
    lineSmooth: options.lineSmooth,
    height: options.chartHeight,
    fullWidth: !options.useFullWidth && !options.showXLabels ? true : options.useFullWidth,
    showArea: options.showArea,
    chartPadding: options.chartPadding,
    axisX: {
      showGrid: options.showXGrid,
      showLabel: options.showXLabels,
      position: 'end',
      offset: options.showXLabels ? 40 : 5,
      labelOffset: { y: 10 },
    },
    axisY: {
      showGrid: options.showYGrid,
      showLabel: options.showYLabels,
      offset: options.showYLabels ? 35 : 0,
      labelOffset: { x: -10, y: 15 },
      position: 'start',
      high: options.highestValue === options.lowestValue ? options.highestValue + 2 : options.highestValue,
      low: options.lowestValue,
      labelInterpolationFnc: function (value) { return formatValue(value, true, true, true) },
    },
    plugins: initializeChartistPlugins(options),
  }
}*/

/*
export const getBarChartOptions = (options) => {
  return {
    type: options.type,
    height: options.chartHeight,
    chartPadding: options.chartPadding,
    seriesBarDistance: 20,
    stackBars: options.stackBars,
    fullWidth: options.showXLabels ? false : true,
    axisX: {
      showLabel: options.showXLabels,
      showGrid: options.showXGrid,
      offset: options.showXLabels ? 40 : 5,
      labelOffset: { y: 10 },
    },
    axisY: {
      showLabel: options.showYLabels,
      showGrid: options.showYGrid,
      offset: options.showYLabels ? 35 : 0,
      labelOffset: { x: -10, y: 15 },
      labelInterpolationFnc: function (value) { return formatValue(value, true, true, true) },
      position: 'start',
      high: options.highestValue === options.lowestValue ? options.highestValue + 2 : options.highestValue,
      low: options.lowestValue,
    },
    plugins: initializeChartistPlugins(options),
  }
}
*/

/*export const getPieChartOptions = (options) => {
  return {
    type: options.type,
    showTooltips: options.showTooltips,
    showLegend: options.showLegend,
    chartPadding: { top: 20, left: 15, bottom: 15, right: 15 },
    classNames: {
      chartPie: 'ct-chart-pie',
      chartDonut: 'ct-chart-donut',
      series: 'ct-series',
      slicePie: 'ct-slice-pie',
      sliceDonut: 'ct-slice-donut',
    },
    donut: options.donut,
    donutSolid: false, //if set to true tooltips will not work
    donutWidth: options.donutWidth,
    ignoreEmptyValues: false,
    labelPosition: 'outside',
    labelDirection: 'neutral',
    labelInterpolationFnc : function (options) {
      let label, space = '', unit = options.unit
      unit ? space = options.unit === '%' ? '' : ' ' : unit = ''

      if (options.sum)
        label = _.round(options.value / options.sum * 100, 1) + '%'
      else
        label = formatValue(options.value, true, true, true) + space + unit

      return label
    },
    labelOffset: options.labelOffset ? options.labelOffset : options.showLabelPieChart ? 10 : 0,
    reverseData: false,
    showLabel: options.showLabelPieChart,
    startAngle: 0,
    total: undefined,
    width: undefined,
    height: undefined,
    plugins: initializeChartistPlugins(options),
  }
}*/

/**
 * Get vertical series and labels from a ChartistData object
 * based on the provided index
 * @param data
 * @param selectedIndex
 * @returns {{labels: *, series: *}}
 */
export const getVerticalSeriesAndLabels = (data: ChartistData, selectedIndex: number) => {
  let { verticalSeries, verticalLabels, sumOfAllSeries } = data.series
    .reduce((acc, { data, color, name }) => {
      if (data[selectedIndex].value) {
        if (color) {
          data[selectedIndex].color = color
        }

        return {
          ...acc,
          verticalSeries: [ ...acc.verticalSeries, data[selectedIndex] ],
          verticalLabels: [
            ...acc.verticalLabels,
            {
              color: color,
              name: name,
              value: data[selectedIndex].value,
              unit: data[selectedIndex].unit,
            },
          ],
          sumOfAllSeries: acc.sumOfAllSeries + data[selectedIndex].value,
        }

      }
      return acc
    }, { verticalSeries: [], verticalLabels: [], sumOfAllSeries: 0 })

  if (sumOfAllSeries) {
    verticalLabels = verticalLabels.map(label => ({ ...label, sum: sumOfAllSeries }))
  }

  return {
    labels: verticalLabels,
    series: verticalSeries,
  }
}

export const formatValue = (value, useNumeralJs, roundNumber, abbreviateLargeNumbers) => {
  let decimals = 10
  const absValue = Math.abs(value)

  if (roundNumber) {
   if (absValue < 1) {
      const leadingZerosCount = countLeadingZerosAfterDecimal(value)
      decimals = leadingZerosCount + 2
    }
    else if (absValue < 10) {
      decimals = 2
    }
     else if (absValue < 100) {
       decimals = 1
     }
    else {
      decimals = 0
    }
  }


  if (roundNumber && value % 1 !== 0) { // Check if the value has decimals
    value = _.round(value, decimals)
  }

  if (useNumeralJs) {
    if (abbreviateLargeNumbers && absValue >= 10000) {
      return numeral(value).format('0.0a')
    }
    else {
      const stringOfDecimalPlaces = '0'.repeat(decimals)
      return numeral(value).format(`0,0.[${stringOfDecimalPlaces}]`)
    }
  }
  else {
    return value
  }
}

function countLeadingZerosAfterDecimal(number) {
  // Convert the number to a string to manipulate its characters
  const numStr = number.toString();

  // Check if the number has a decimal point
  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1) {
    // If there is no decimal point, there are no leading zeros after it
    return 0;
  }

  // Count the number of leading zeros after the decimal point
  let count = 0;
  for (let i = decimalIndex + 1; i < numStr.length; i++) {
    if (numStr[i] === '0') {
      count++;
    } else {
      // Break the loop when a non-zero digit is encountered
      break;
    }
  }

  return count;
}

export const getEmptyStateChartOptions = (options) => {
  if (options.cardLayout) {
    _.assign(options, {
      asEmptyState: true,
      height: 225,
      messageToDisplay: options.messages.emptyStateMessage,
      allowToDismissMessage: false,
      showInfoMessage: true,
      xAxisOffset: 0,
      showXLabels: false,
      showYLabels: false,
      showXGrid: false,
      showYGrid: false,
      dashedLine: true,
      colors: [ '#ffffff', '#ffffff', '#ffffff' ],
      chartBackgroundColor: '#f5f5f5',
    })
  }
  else {
    _.assign(options, {
      accumulateLineChart: true,
      showPoint: true,
      lineSmooth: true,
      showXLabels: false,
      showYLabels: false,
      showXGrid: true,
      showYGrid: true,
      showArea: true,
      xAxisOffset: 40,
      showTooltips: false,
      showLegend: false,
      allowToDismissMessage: false,
      showInfoMessage: true,
      messageToDisplay: options.messages.emptyStateMessage,
      colors: [ '#f5f5f5', '#f5f5f5', '#f5f5f5' ],
    })
  }

  return {
    donut: options.actualType && options.actualType === 'donut' ? true : false,
    donutSolid: options.actualType && options.actualType === 'donut' ? true : false,
    donutWidth: options.actualType && options.actualType === 'donut' ? options.donutWidth : undefined,
    accumulateLineChart: options.accumulateLineChart,
    disablePointerEvents: true,
    height: options.chartHeight,
    chartPadding: options.chartType === 'pie' ? { top: 30, left: 5, right: 5, bottom: 5 } : options.chartPadding,
    showPoint: options.showPoint,
    lineSmooth: options.lineSmooth,
    fullWidth: options.showXLabels ? false : true,
    showArea: options.showArea,
    showLegend: options.showLegend,
    showLabel: options.chartType === 'pie' ? false : undefined,
    dashedLine: options.dashedLine,
    axisX: {
      showGrid: options.showXGrid,
      showLabel: options.showXLabels,
      position: 'end',
      offset: options.xAxisOffset,
      labelOffset: { y: 10 },
    },
    axisY: {
      showGrid: options.showYGrid,
      showLabel: options.showYLabels,
      offset: options.showYLabels ? 25 : 0,
      labelOffset: { x: -5, y: 15 },
      position: 'start',
    },
    plugins: initializeChartistPlugins(options),
  }
}

/*export const getResponsiveOptions = () => {
  return [
    [ 'screen and (min-width: 641px) and (max-width: 1024px)', {} ],
  ]
}*/

// TODO remove not used anymore
export const initializeChartistPlugins = (options) => {
  let useNumeralJs = true
  let allowRoundNumber = false
  let abbreviateLargeNumbers = false
  let colors = {}

  if (options.colors && options.colors.length >= 1)
    colors = { colors: options.colors }

  //If the data is aggregated then round the numbers
  if (options.valueProperties) {
    allowRoundNumber = _.some(options.valueProperties, function(property) {
      return property === 'average' || property === 'sum'
    })
  }

  let plugins = []
    /*tooltipPlugin = Chartist.plugins.tooltip({
      appendToBody: options.appendTooltipToBody,
      pointClass: 'ct-custom-point',
      /!*tooltipFnc: function(meta, value) {
        var
          legendLabel,
          datasetName,
          xAxisLabel,
          xAxisLabelDetails,
          extraHtml = ""
        ;

        meta = parseStringToJSON(meta);
        value = formatValue(value, useNumeralJs, allowRoundNumber, abbreviateLargeNumbers);

        value = getValueWithUnit(value, meta.unit);
        delete meta["unit"];

        if (!_.isEmpty(meta)) {
          legendLabel = _.get(meta, "legendLabel.value");
          datasetName = _.get(meta, "datasetName.value");
          xAxisLabel = _.get(meta, "xAxisLabel.value");
          xAxisLabelDetails = _.get(meta, "xAxisLabelDetails.value");

          if (options.ticket && options.ticket.organizationIds && meta.organizationCount && meta.organizationCount.value && parseInt(meta.organizationCount.value) >= 0) {
            if (options.aggregateMode === "separate")
              extraHtml =  "<div class='meta'><div class='org-name'>" + $sanitize(datasetName) + "</div></div>"
            else
              extraHtml =  "<div class='meta'><span>" + $sanitize(meta.organizationCount.label) + ":</span> <span class='text-bold'>" + $sanitize(meta.organizationCount.value) + "</span></div>"
          }
        }

        var tooltipHtml = "<div class='custom-tooltip-template pull-left'>" +
          (xAxisLabel ? (
            "<div class='tooltip-header'>" +
            "<span class='value'>" + $sanitize(xAxisLabel) + "</span>" +
            (xAxisLabelDetails && xAxisLabelDetails != xAxisLabel ? "<div class='value-detailed'>" + $sanitize(xAxisLabelDetails) + "</div>" : '') +
            "</div>"
          ) : '') +
          "<div class='tooltip-body'>" +
          (legendLabel && datasetName != legendLabel ? legendLabel + ": " : '') + "<span class='text-bold data-value'>" + $sanitize(value) + "</span>" +
          extraHtml +
          "</div>" +
          "</div>";

        return tooltipHtml;
      }*!/
    }),
    colorPlugin = Chartist.plugins.color(colors),
    legendPlugin = Chartist.plugins.legend({ position: 'bottom', clickable: true }),
    pointPlugin = Chartist.plugins.point({ circleShapeOptions: { radius: 6, enableAnimations: options.enableAnimations, fillColor: options.chartBackgroundColor } }),
    positionPlugin = Chartist.plugins.positionChartCenter({ gridColor: ColorLuminance(options.chartBackgroundColor, 0.1) }),
    infoMessagePlugin = Chartist.plugins.infoMessage({ message: options.messageToDisplay, allowToDismissMessage: options.allowToDismissMessage }),
    responsiveLabelsPlugin = Chartist.plugins.responsiveLabels(),
    dynamicBarWidthPlugin = Chartist.plugins.dynamicBarWidth(),
    linearGradientPlugin = Chartist.plugins.linearGradient({ linearGradientId: _.uniqueId('chart-area-gradient_'), fromColor: ColorLuminance(options.chartBackgroundColor, options.asEmptyState ? -0.2 : -0.5), toColor: options.chartBackgroundColor }),
    seriesSelectionPlugin = Chartist.plugins.seriesSelection({ data: options.originalData, verticalSeries: options.verticalSeries, selectedVerticalSeries: options.selectedVerticalSeries, getVerticalSeriesAndLabels: getVerticalSeriesAndLabels, translatePlugin: $translate }),
    animatePlugin = Chartist.plugins.animate()*/

  /*let tooltipPlugin = ctTooltip({
    appendToBody: false,
    pointClass: 'ct-custom-point',
  })
  let colorPlugin = ctColor()
  let legendPlugin = null
  let pointPlugin = ctPoint()
  let positionPlugin = null
  let infoMessagePlugin = null
  let responsiveLabelsPlugin = null
  let dynamicBarWidthPlugin = ctDynamicBarWidth()
  let linearGradientPlugin = null
  let seriesSelectionPlugin = null
  let animatePlugin = null

  plugins.push(colorPlugin)

  if (!options.type === 'pie')
    plugins.push(responsiveLabelsPlugin)

  if (options.enableAnimations)
    plugins.push(animatePlugin)

  if (options.showTooltips)
    plugins.push(tooltipPlugin)

  if (options.showLegend)
    plugins.push(legendPlugin)

  if (options.showPoint)
    plugins.push(pointPlugin)

  if (options.showInfoMessage)
    plugins.push(infoMessagePlugin)

  if (options.type === 'line' && !options.useFullWidth)
    plugins.push(positionPlugin)

  if (options.type === 'bar')
    plugins.push(dynamicBarWidthPlugin)

  if (options.addLinearGradientElement)
    plugins.push(linearGradientPlugin)

  if (options.type === 'pie')
    plugins.push(seriesSelectionPlugin)

  // only keep defined plugins
  return plugins.filter(plugin => plugin)

  function getValueWithUnit(value, unit) {
    let newValue = ''
    if (unit && unit.value !== undefined) {
      if (unit.value !== '%')
        unit.value = ' ' + unit.value

      newValue = value + unit.value
    }
    else {
      newValue = value
    }
    return newValue
  }*/
}

export const getValueWithUnit = (value, unit) => {
  if (unit && unit.value) {
    return `${value}${unit.value !== '%' ? ' ' : ''}${unit.value}`
  }
  else {
    return value
  }
}

// TODO test
export const _getChartWithDynamicBars = (chart, options: ChartistOptions, data: ChartistData): ChartistOptions => {
  if (!chart) {
    return options
  }

  let
    chartComponentWidth = chart.getBoundingClientRect().width,
    chartPaddingLeft = _.get(options, 'chartPadding.left', 0),
    chartPaddingRight = _.get(options, 'chartPadding.right', 0),
    axisYOffset = _.get(options, 'axisY.offset', 0),
    //showInfoMessage = false,
    //messageToDisplay = options.messages.barOverlapingMessage,
    chartWidth,
    labelWidth,
    labelPadding = 20,
    labelWidthWithPadding,
    numberOfSeries,
    barDistance,
    barWidth,
    maxBarWidth = 120,
    seriesBarDistance = options.seriesBarDistance || 1,
    stackBars = options.stackBars/*,
      gap*/

  chartWidth = (chartComponentWidth - (chartPaddingLeft + chartPaddingRight)) - axisYOffset
  labelWidth = chartWidth / data.labels.length
  labelWidthWithPadding = labelWidth - labelPadding
  numberOfSeries = stackBars ? 1 : data.series.length

  barDistance = labelWidth / numberOfSeries

  if (barDistance > labelPadding + 5)
    barDistance = labelWidthWithPadding / numberOfSeries

  barWidth = barDistance - seriesBarDistance

  if (barWidth < 1) {
    barWidth = 1
    //gap = seriesBarDistance >= 3 ? seriesBarDistance - 3 : 3
    /*if (barDistance < gap) {
      showInfoMessage = true
    }*/
  }

  if (barWidth > maxBarWidth) {
    barWidth = maxBarWidth
  }

  return { ...options, seriesBarDistance: barDistance, barWidth }

  // TODO take care of correctly initializing options
  /*if (chartOriginalOptions.showInfoMessage) {
    messageToDisplay = chartOriginalOptions.messageToDisplay
    showInfoMessage = true
  }*/

  /*_.assign(chartOriginalOptions, {
    showInfoMessage: showInfoMessage,
    messageToDisplay: messageToDisplay,
  })

  _.assign(options, {
    seriesBarDistance: barDistance,
    barWidth: barWidth,
    plugins: initializeChartistPlugins(chartOriginalOptions),
  })*/
}
// TODO test and compare with _getChartWithDynamicBars
export const getChartWithDynamicBars = (chart, chartOriginalOptions) => {
  let
    chartComponentWidth = chart.getBoundingClientRect().width,
    chartPadding = chart.options.chartPadding,
    axisYOffset = chart.options.axisY.offset,
    showInfoMessage = false,
    messageToDisplay = chartOriginalOptions.messages.barOverlapingMessage,
    chartWidth,
    labelWidth,
    labelPadding = 20,
    labelWidthWithPadding,
    numberOfSeries,
    barDistance,
    barWidth,
    maxBarWidth = 120,
    seriesBarDistance = chartOriginalOptions.seriesBarDistance || 1,
    stackBars = chartOriginalOptions.stackBars,
    gap

  chartWidth = (chartComponentWidth - (chartPadding.left + chartPadding.right)) - axisYOffset
  labelWidth = chartWidth / chart.data.labels.length
  labelWidthWithPadding = labelWidth - labelPadding
  numberOfSeries = stackBars ? 1 : chart.data.series.length

  barDistance = labelWidth / numberOfSeries

  if (barDistance > labelPadding + 5)
    barDistance = labelWidthWithPadding / numberOfSeries

  barWidth = barDistance - seriesBarDistance

  if (barWidth < 1) {
    barWidth = 1
    gap = seriesBarDistance >= 3 ? seriesBarDistance - 3 : 3
    if (barDistance < gap)
      showInfoMessage = true
  }

  if (barWidth > maxBarWidth)
    barWidth = maxBarWidth

  return { ...chartOriginalOptions, seriesBarDistance: barDistance, barWidth }

  // TODO bring back that
  /*if (chartOriginalOptions.showInfoMessage) {
    messageToDisplay = chartOriginalOptions.messageToDisplay,
    showInfoMessage = true
  }

  _.assign(chartOriginalOptions, {
    showInfoMessage: showInfoMessage,
    messageToDisplay: messageToDisplay,
  })

  _.assign(chart.options, {
    seriesBarDistance: barDistance,
    barWidth: barWidth,
    plugins: initializeChartistPlugins(chartOriginalOptions),
  })

  return new Chartist.Bar($(chart.container)[0], chart.data, chart.options, chart.responsiveOptions)*/
}

export const mockRandomData = (options) => {
  let numOfYears = options.numberOfYears || 3
  let numOfDatasets = options.numberOfDatasets || 3
  let datasets = []
  let singleDataset
  let months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ]
  let years = 2000 + numOfYears

  for ( let j = 1; j <= numOfDatasets; j++ ) {
    singleDataset = { data: [], id: 'count' }
    for (let i = 2000; i < years; i++) {
      singleDataset.data.push( { year: i, month: _.sample(months), value: randomScalingFactor(), sum: null, average: null, organizationCount: null } )
    }
    datasets.push(singleDataset)
  }

  console.warn('Chartist data is randomized! Please set mockRandomData to false when finised testing!')
  return datasets

  function randomScalingFactor() {
    return (Math.random() > 0.5 ? 1.0 : 1.0) * Math.round(Math.random() * 2000)
  }
}

export const stringifyJSON = (data) => {
  if (!data) {
    console.error('No data to stringify')
    return
  }

  if (typeof data === 'object') {
    data = JSON.stringify(data, undefined, 4)
  }
  else {
    console.error('Cannot stringify data. Data is not an object')
  }

  return data
}

export const ColorLuminance = (hex, lum) => {

  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  let rgb = '#', c, i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}
