// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { Link, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import Divider from '@worldfavor/components/Divider'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: 300,
        marginTop: 14,
        marginBottom: 2,
    },
    listContainer: {
        borderRadius: 4,
        border: '1px solid #eee',
        padding: '0 16px 5px',
        marginTop: 16,
    },
    cellLink: {
        display: 'block',
        '&, &:hover': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },
    },
    listItem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: 0,
    },
    name: {
        fontSize: 14,
        fontWeight: 500,
        paddingRight: 50,
    },
    itemDetails: {
        color: Colors.grayDark,
        fontSize: 11,
        fontWeight: 400,
        paddingRight: 50,

        '& > span:not(:first-child):before': {
            content: '"\\00b7"',
            display: 'inline-block',
            width: 12,
            textAlign: 'center',
            fontWeight: 700,
        },
    },
    actions: {
        float: 'right',
    },
    linkMenuItem: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        outline: 'none',
        '&:focus': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            outline: 'none',
        },
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
}))

type Props = {
  data: Array<Object>,
  onOpenItem: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onLinkHrefAssign: (Object) => void
}

const ProductOrganizationExplorerContent = (props: Props) => {
    const { data, onOpenItem, onLinkHrefAssign } = props
    const classes = useStyles(props)
    const { products, upstream, downstream } = data

    function renderItemDetails(item) {
        if (item.type === 101) {
            return (
                <div className={classes.itemDetails}>
                    { item.registrationNumber && (<span>{item.registrationNumber}</span>) }
                    { item.vatNumber && (<span>{item.vatNumber}</span>) }
                    { item.gln && (<span>{item.gln}</span>) }
                </div>
            )
        }
        else if (item.type === 120) {
            return (
                <div className={classes.itemDetails}>
                    { item.creatorOrganization && (<Link color="secondary" onClick={() => onOpenItem(item.creatorOrganization)}>{ item.creatorOrganization.name && (<span>{item.creatorOrganization.name}</span>) }</Link>)}
                    { item.gtin && (<span>{item.gtin}</span>) }
                </div>
            )
        }
    }

    function renderList(keyPrefix, items, useLinkButton) {
        if (!items.length) {
            return (
                <ListItem classes={{ root: classes.listItem }}>
                    <ListItemText secondary={<FormattedMessage id={'supplyChain.productOrganizationExplorer.emptyState'} />} />
                </ListItem>
            )
        }
        return (
            <List component="nav">
                {
                    items.map((item, index) => (
                        <div key={`${keyPrefix}-${item.id}`}>
                            <ListItem classes={{ root: classes.listItem }}>
                                <div className={classes.name}>
                                    {item.name}
                                </div>
                                {renderItemDetails(item)}
                                <ListItemSecondaryAction>
                                    {useLinkButton
                                        ? (
                                            <Button
                                                size="small"
                                                color="secondary"
                                                component={Link}
                                                target="_blank"
                                                href={onLinkHrefAssign(item)}
                                            >
                                                <FormattedMessage id={'general.open'} />
                                            </Button>
                                        )
                                        : (
                                            <Button size="small" color="secondary" onClick={() => onOpenItem(item)}>
                                                <FormattedMessage id={'general.explore'} />
                                            </Button>
                                        )
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                            {
                                index < items.length - 1 && (
                                    <div>
                                        <Divider height={1} margin={'0'} width={'100%'} color={'rgba(221, 221, 221, 0.5)'} />
                                    </div>
                                )
                            }
                        </div>
                    ))
                }
            </List>
        )
    }

    return (
        <>
            <div className={classes.listContainer}>
                <h3 className={classes.header}>
                    <FormattedMessage id={'supplyChain.productOrganizationExplorer.products'} />
                </h3>
                {renderList('product', products, true)}
            </div>
            <div className={classes.listContainer}>
                <h3 className={classes.header}>
                    <FormattedMessage id={'supplyChain.productOrganizationExplorer.upstream'} />
                </h3>
                {renderList('upstream', upstream)}
            </div>
            <div className={classes.listContainer}>
                <h3 className={classes.header}>
                    <FormattedMessage id={'supplyChain.productOrganizationExplorer.downstream'} />
                </h3>
                {renderList('downstream', downstream)}
            </div>
        </>
    )
}

export default ProductOrganizationExplorerContent
