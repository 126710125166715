import * as enums from '@worldfavor/constants/enums'
import { ConstVars } from '@worldfavor/constants'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfWorkItem', {
            templateUrl: 'scripts/wf/workItem/wfWorkItem.component.html',
            controller: wfWorkItemController,
            controllerAs: 'vm',
            bindings: {
                itemComposite: '<',
                ticket: '<',
                influence: '<',
                uiModeFromAttr: '<uiMode', // accepts string "view", "work", "admin" or int 1, 2, 3
            },
        })

    wfWorkItemController.$inject = ['wfAnsweringService', 'dataQuery', '$element', 'wfTranslate', '$q', '$translate']
    function wfWorkItemController(wfAnsweringService, dataQuery, $element, wfTranslate, $q, $translate) {
        const
            vm = this

        let answerModules = undefined

        _.assign(vm, {
            uiMode: enums.uiMode.view, // default mode
            showAnsweringFeed: true,
            pickerSettings: {},
            primaryAnswerModules: [], // answerModules that are added on the main item
            secondaryAnswerModules: [], // answerModules that are added on the attached information to the main item (i.e. when the requirements are to attach a document to the answer item)
            canAssignToColleague: !(vm.ticket && vm.ticket.thirdParty),
            scrollToTopOfFeed,
            assignToColleague,
        })

        vm.$onInit = function() {
            // console.log("wfWorkItemComponent", vm.item);
            setUiMode()

            if (vm.itemComposite && vm.ticket && (vm.uiMode === enums.uiMode.admin || vm.uiMode === enums.uiMode.work)) {
                vm.pickerSettings = dataQuery.getPickerSettings(vm.itemComposite, vm.ticket, vm.pickerSettingsFromAttr)

                vm.showAnsweringFeed = !vm.pickerSettings || !vm.pickerSettings.hideAnsweringFeed

                answerModules = wfAnsweringService.defineAnswerModules(vm.itemComposite, vm.ticket, vm.pickerSettings)

                setMultilingualLabels(answerModules).then(() => {
                    vm.primaryAnswerModules = answerModules.primaryAnswerModules
                    vm.secondaryAnswerModules = answerModules.secondaryAnswerModules
                })
            }

            if (_.get(vm.itemComposite.content.conditions, 'uiSettings.useCurrentOrganizationEditor')) {
                vm.showAnsweringFeed = false
                vm.useCurrentOrganizationEditor = true
            }

            if (vm.itemComposite.content.relatedContent.length) {
                vm.itemRelatedContent = _.orderBy(vm.itemComposite.content.relatedContent, [x => x.childContent.title.toLowerCase()])
            }

            vm.showRequirement = !_.get(vm.influence.childContent, 'conditions.requirementPackageSettings.hideRequirementTexts')

            vm.isReadonly = vm.influence.isReadonly
            vm.isReadonlyBeforeDate = vm.influence.isReadonlyBeforeDate
        }

        function setMultilingualLabels(answerModules) {
            const promises = []
            const whatever = answerModules.primaryAnswerModules.concat(answerModules.secondaryAnswerModules)

            whatever.forEach((answerModule) => {
                promises.push(wfTranslate.tryInstant('modules.picker.objectTypes.' + answerModule.type + '.createButtonCaption', (text) => {
                    answerModule.uiSettings.submitButtonLabel = $translate.instant('Save')
                    answerModule.uiSettings.createNewButtonLabel = answerModule.uiSettings.submitButtonLabel + ' ' + $translate.instant('New').toLowerCase()
                    answerModule.uiSettings.chooseExistingButtonLabel = $translate.instant('ChooseExisting')
                }))
            })

            return $q.all(promises)
        }

        function setUiMode() {
            let uiModeToSet = enums.uiMode.view

            if (typeof vm.uiModeFromAttr === 'string') {
                switch (vm.uiModeFromAttr) {
                    case 'admin':
                        uiModeToSet = enums.uiMode.admin
                        break
                    case 'work':
                        uiModeToSet = enums.uiMode.work
                        break
                    default:
                        uiModeToSet = enums.uiMode.view
                        break
                }
            }
            else {
                if (vm.uiModeFromAttr === enums.uiMode.view || vm.uiModeFromAttr === enums.uiMode.work || vm.uiModeFromAttr === enums.uiMode.admin) uiModeToSet = vm.uiModeFromAttr
            }

            vm.uiMode = uiModeToSet
        }

        function scrollToTopOfFeed() {
            const gap = 60
            $('html, body').animate({ scrollTop: $element.offset().top - gap }, 500, null)
        }

        function assignToColleague() {
            vm.itemComposite.categorizeToUser(vm.influence)
        }
    }
})()
