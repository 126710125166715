import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfAnalyzeRequirements', {
            templateUrl: 'scripts/wf/analyze/requirement/wfAnalyzeRequirements.component.html',
            controller: wfAnalyzeRequirementsController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
                uiModeAttr: '=uiMode',
            },
        })

    wfAnalyzeRequirementsController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService']
    function wfAnalyzeRequirementsController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService) {
        const
            vm = this
			
        const mainItem = vm.item
			
        const dataRelation = mainItem
			
        let relationsWithMeasurePeriodSettings

        _.assign(vm, {
            uiMode: enums.uiMode.admin,
            item: undefined,
            loading: true,
            availableRequirementOptions: undefined,
            maxItemsCount: 5,
            itemsLimit: 5,

            editRequirements,
        })

        this.$onInit = $onInit

        function $onInit() {
            const initialPromises = []
            vm.requirementsLoader = new analyzeService.requirementsLoader(mainItem, vm.ticket, $scope)

            if (vm.uiModeAttr) {
                vm.uiMode = vm.uiModeAttr
            }
            vm.isAdminMode = vm.uiMode === enums.uiMode.admin

            if (mainItem.childType === enums.objectType.measure) {
                dataOps.getSubItems(mainItem.childContent, enums.subItemsKind.parents, {
                    onlyLoadRelations: true,
                }).then((res) => {
                    relationsWithMeasurePeriodSettings = _.filter(res, (dataRelation) => {
                        return dataRelation.settings && dataRelation.settings.measurePeriodSettings
                    })
                    loadFormattedRequirementsOnItem(relationsWithMeasurePeriodSettings.length ? { additionalItemsWithMeasurePeriodSettings: relationsWithMeasurePeriodSettings } : null)
                })
            }
            else {
                loadFormattedRequirementsOnItem()
            }

            function loadFormattedRequirementsOnItem(options) {
                vm.requirementsLoader.loadFormattedRequirementsOnItem(_.assign({ networkId: vm.ticket.networkId }, options)).then((formattedRequirements) => {
                    vm.items = formattedRequirements
                    vm.loading = false
                    $timeout()
                })
            }
        }

        function editRequirements() {
            analyzeService.editRequirements(mainItem, vm.ticket, vm.uiMode).then((result) => {
                if (result.requirementsWereChanged) {
                    vm.loading = true
                    $timeout()

                    vm.requirementsLoader.loadFormattedRequirementsOnItem({
                        fromServer: true,
                        additionalItemsWithMeasurePeriodSettings: relationsWithMeasurePeriodSettings,
                    }).then((formattedRequirements) => {
                        vm.items = formattedRequirements
                        vm.loading = false
                        $timeout()
                    })
                }
            })
        }
    }
})()
