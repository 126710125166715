import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'
    angular
        .module('theme.core.navigation_controller', ['theme.core.services', 'wf.data', 'wf.common'])
        .controller('NavigationController', NavigationController)

    NavigationController.$inject = ['$rootScope', '$scope', '$timeout', 'dataOperationsService', '$state', 'valueChainService', '$translate', 'wfAuth', 'wfPropertyExtractor', '$uibModal', '$location']
    function NavigationController($rootScope, $scope, $timeout, dataOps, $state, valueChainService, $translate, wfAuth, wfPropertyExtractor, $uibModal, $location) {
        const
            vm = this
			
        let contextualValueChainNetworkIds
			
        const networkDependentMenuItems = []
			
        let topMenuItemsByUrlPath = {}
			
        let topMenuItemsByStateName = {}
			
        let currentTopSubRootUrl = ''

        _.assign(vm, {
            //Variables
            menu: [],
            notifications: [],
            activeItems: [], // Will contain an array with all active item ids. A fix for active items not being highlighted using item.active in html
            loading: true,
            flatMenu: [],
            solutions: [],
            solutionSwitcherModal: undefined,
            solutionSwitcherActive: false,
            homeIconUrl: getHomeUrl(),
            showCloseButtonInSolutionSwitcher: false,
            networkStructureByRootId: undefined,

            //Functions
            openSolutionSwitcher,
            switchAndCloseSolutionSwitcher,
            getHomeUrl,
	  onAdminModeChange,
	  onReactButtonClicked,
            copyToClipboard,
        })

        //Listener for the active pages in the navigational bar
        $rootScope.$on('$stateChangeSuccess', setActivePage)

        var setParent = function(children, parent) {
            const
                newChildren = []
				
            let newChild
				
            let url
				
            let useCustomUrl
				
            let networkIds
			
            _.each(children, (dataRelation, i) => {
                let childContent = null; let contentConditions; let relationSettings
                let urlFromCondition = false
                useCustomUrl = false

                if (dataRelation.childType === 71 // Structure
					|| dataRelation.childType === 52 // Network
                )
                {
                    if (dataRelation.childType === 52) // Network
                    {
                        url = '/net/' + dataRelation.childId
                        useCustomUrl = true
                    }
                    else if (parent && parent.conditions && parent.conditions.contextVariable1 === 'STANDARDS_v2') {
                        url = '/hier/' + dataRelation.wfcid
                        useCustomUrl = true
                    }
                    else if (parent && parent.conditions && parent.conditions.contextVariable1 === 'OWN_STRUCTURES') {
                        if (dataRelation.settings && dataRelation.settings.templateId) {
                            url = '/rel/' + dataRelation.id
                        }
                        else {
                            url = '/hier/' + dataRelation.wfcid
                            useCustomUrl = true
                        }
                    }
                    else if (parent && parent.conditions && parent.conditions.contextVariable1 === 'ISO_STANDARDS') {
                        url = '/hier/' + dataRelation.wfcid
                        useCustomUrl = true
                    }
                    else
                    {
                        url = (parent && !parent.useCustomUrl ? parent.url : '/rel') +  '/' + (dataRelation.id || dataRelation.wfid)
                    }

                    childContent = dataRelation.childContent
                    if (childContent) {
                        contentConditions = childContent.conditions
                        relationSettings = dataRelation.settings

                        if (contentConditions && (contentConditions.contextVariable1 === 'DataCollector' || contentConditions.contextVariable1 === 'ValueChain')) {
                            newChild = buildValueChainMenu(childContent, parent, contentConditions.secondaryObjectId, dataRelation)
                            networkIds = contextualValueChainNetworkIds.map(x => x)
                            newChildren.push(newChild)
                            vm.flatMenu.push(newChild)
                            handleNetworkDependentMenuItems()
                            return
                        }

                        if (contentConditions && (contentConditions.contextVariable1 === 'DataCollector_Automation')) {
                            newChild = buildValueChainAutomationMenu(childContent, parent, contentConditions.secondaryObjectId, dataRelation)
                            newChildren.push(newChild)
                            vm.flatMenu.push(newChild)
                            return
                        }

                        if (contentConditions && contentConditions.urlPath) {
                            url = contentConditions.urlPath
                            useCustomUrl = true
                            urlFromCondition = true
                        }

                        if (relationSettings && relationSettings.urlPath) {
                            url = relationSettings.urlPath
                            useCustomUrl = true
                            urlFromCondition = true
                        }

                        if (vm.combineSubRootUrls) { // Undefined means top level
                            if (!parent) {
                                currentTopSubRootUrl = urlFromCondition ? url : ''
                            }
                            else if (urlFromCondition && currentTopSubRootUrl && url.indexOf(currentTopSubRootUrl) === -1) {
                                url = currentTopSubRootUrl + url
                                wfPropertyExtractor.registerItemNavigationUrl({
                                    relationWfid: dataRelation.wfid,
                                    contentWfid: childContent.wfid,
                                    url,
                                })
                            }
                        }

                        newChild = {
                            relationWfid: dataRelation.wfid,
                            contentWfid: childContent.wfid,
                            contentId: childContent.id,
                            children: childContent ? _.clone(childContent.childs) : [],
                            title: childContent.title,
                            conditions: contentConditions,
                            useCustomUrl,
                            url,
                            parent,
                            id: dataRelation.id,
                            active: false,
                            hasImage: childContent.hasImage,
                            imageUrl: childContent.imageUrl,
                            imageUrlLarge: childContent.imageUrlLarge,
                        }

                        newChildren.push(newChild)
                        vm.flatMenu.push(newChild)

                        if (contentConditions && useCustomUrl && url.indexOf('{networkId}') !== -1) {
                            networkDependentMenuItems.push(newChild)
                        }

                        if (contentConditions && contentConditions.hideChildrenInMenu || (parent && parent.conditions && parent.conditions.contextVariable1 === 'OWN_STRUCTURES')) {
                            newChild.children = undefined
                            return
                        }

                        if (newChild.children !== undefined && newChild.children.length != 0) {
                            networkIds = setParent(newChild.children, newChild)
                        }

                        if (networkIds) {
                            newChild.networkIds = networkIds
                        }
                    }
                }
            })

            children.length = 0
            Array.prototype.push.apply(children, newChildren)
            return networkIds
        }

        $scope.openItems = []
        $scope.selectedItems = []
        $scope.selectedFromNavMenu = false

        // $rootScope.$on('auth0.loginSuccess', onAuthenticated);
        wfAuth.onAuthenticated(onAuthenticated)
        $rootScope.$on('auth0.logout', onLogout)

        //From the desired destination, get the id, find the root parent
        //and set the it as active page (setting all other pages as inactive)
        function setActivePage(event, toState, toParams, fromState, fromParams) {
            const toId = toParams.objectId
            let foundTopMenu
            let solutionFromLocalStorageId = undefined

            if (vm.solutionSwitcherActive) switchAndCloseSolutionSwitcher()

            if (vm.useRootSubMenuSelector) {
                // if the toState has a parent and the parent is not the same has the vm.selectedTopMenu
                // then set the vm.selectedTopMenu to match the parent of the toState

                if (toState && toState.name === 'root') openSolutionSwitcher()
                else if (toState.current && toState.current.name === 'root') openSolutionSwitcher()

                if (toState.url || toState.current) {

                    // If no top menu is selected or the selected top menu url doesn't match the parent or url start of toState then change to correct selectedTopMenu
                    if (!vm.selectedTopMenu || (toState.url && toState.url.indexOf(vm.selectedTopMenu.url) === -1)) {
                        if (toState.url) {
                            // Lookup which root sub menu the state belongs to, both by name ("sust-sourcing.dashboard") and url ("/surt-sourcing/dashboard")
                            foundTopMenu = topMenuItemsByUrlPath['/' + toState.url.split('/')[1]] || topMenuItemsByStateName[toState.name.split('.')[0]]
                        }

                        // If a match was found then set it
                        if (foundTopMenu) {
                            vm.selectedTopMenu = foundTopMenu
                        }
                    }

                    solutionFromLocalStorageId = getCurrentSolutionFromLocalStorage()
                    if (solutionFromLocalStorageId && !vm.selectedTopMenu) vm.selectedTopMenu = _.find(vm.menu, { id: parseInt(solutionFromLocalStorageId) })

                    // If no selectedTopMenu could be matched then default to the first one in the list
                    if (!vm.selectedTopMenu) {
                        vm.selectedTopMenu = vm.menu[0]
                    }
                }
            }

            if (vm.selectedTopMenu && _.get(vm.selectedTopMenu, 'conditions.contextVariable1') === 'SolutionPackage')
            {
                wfAuth.setSolution(vm.selectedTopMenu.title)
                vm.currentSolution = vm.selectedTopMenu.title
            }

            //Check each id from the path and set the active child to true
            _(vm.flatMenu).forEach((item) => {
                item.active = false
            })

            setActiveHierarchy(toId)

            //Check if Home link is active
            if (vm.active) {
                vm.active = false
            }

            //If the page is reloaded in the home route, check toState.current
            //else if it is just clicked by user, check toState.name and set the link to active
            if (toState.current != null && toState.current.name == 'root') {
                vm.active = true
            }
            else if (toState.name == 'root') {
                vm.active = true
            }

            _(vm.flatMenu).forEach((item) => {
                if (item.active) {
                    vm.activeItems.push(item.id)
                }
            })

            getHomeUrl()
        }

        function setActiveHierarchy(id) {
            let item = _.find(vm.flatMenu, { id })

            while (item != null) {
                // console.log(item);
                item.active = true
                item = item.parent
            }
        }

        function onAuthenticated(event, authContext, reauthenticated, userInfo, token) {
            vm.menu = []
            vm.selectedTopMenu = undefined
            vm.useRootSubMenuSelector = false
            vm.combineSubRootUrls = false
            topMenuItemsByUrlPath = {}
            topMenuItemsByStateName = {}
            wfPropertyExtractor.clearRegisteredItemNavigationLookup()

            vm.user = wfAuth.getUser()
            // console.log(vm.user)
            vm.organization = undefined
            vm.organizationCreatedAt = undefined
            vm.organizationId = wfAuth.getOrganizationId()

            // dataOps.getObject({
            // 	objectType: 100,
            // 	objectId: vm.organizationId,
            // 	skipExtras: true,
            // }).then(function (org) {
            // 	// vm.organization = org
            // });

            if (vm.organizationId)
            {
                vm.isOrganization = true
                dataOps.getObject({
                    objectType: 101,
                    objectId: vm.organizationId,
                    skipExtras: true,
                }).then((org) => {
                    vm.organization = org
                    vm.organizationCreatedAt = moment(org.createdAt).format('YYYY-MM-DD HH:mm')
                })
                vm.loading = true
                dataOps.getObject('71-10010', {
                    objectId: 10010,
                    objectType: 71,
                    childrenLoadDepth: 3,
                    skipExtras: true,
                }).then((res) => {
                    vm.useRootSubMenuSelector = _.get(res.childContent, 'conditions.uiSettings.useRootSubMenuSelector')
                    vm.combineSubRootUrls = _.get(res.childContent, 'conditions.uiSettings.combineSubRootUrls')
                    vm.showHomeMenuItem = !vm.useRootSubMenuSelector

                    if (res.childContent.conditions && res.childContent.conditions.hideChildrenInMenu) {
                        return
                    }

                    vm.menu = _.clone(res.childContent.childs)

                    _.remove(vm.menu, (relation) => {
                        return _.get(relation.childContent, 'conditions.hideInMenu')
                    })

                    setParent(vm.menu)

                    if ((networkDependentMenuItems && networkDependentMenuItems.length) && (!contextualValueChainNetworkIds || !contextualValueChainNetworkIds.length)) {
                        contextualValueChainNetworkIds = ['']
                        handleNetworkDependentMenuItems()
                    }

                    // Remove the menu item 'Account menu' so that is doesn't show up in the main navigation.
                    // It will instead be placed on the right
                    const accountMenuItem = _.remove(vm.menu, (item) => {
                        return item.conditions && item.conditions.contextVariable1 === 'ACCOUNT_MENU'
                    })[0]

                    // ------------- Mocking (same in rootStarter.controller.js) --------------
                    // vm.menu = [ vm.menu[0] ];
                    // -----------------------------------------------------------------

                    if (vm.useRootSubMenuSelector) {
                        _.each(_.chain(vm.menu).map('children').flatten().value(), (item) => {
                            item.parent = null // Set to null so that nav_renderer_horizontal.html will show correct icon
                        })

                        _.reject(res.childContent.childs, { 'childContent.conditions.contextVariable1': 'ACCOUNT_MENU' })

                        if (_.reject(res.childContent.childs, { 'childContent.conditions.contextVariable1': 'ACCOUNT_MENU' }).length > 1) {

                            // Create the lookup object topMenuItemsByUrlPath that will be used by the setActivePage function
                            topMenuItemsByUrlPath = _.keyBy(vm.menu, 'url')

                            // Create the lookup object topMenuItemsByStateName that will be used by the setActivePage function
                            _.each(vm.menu, (item) => {
                                const foundState = $state.get().filter((state) => {
                                    return state.abstract && state.url === item.url
                                })
                                if (foundState.length) topMenuItemsByStateName[foundState[0].name] = item
                            })
                        }
                        else {

                        }

                        if (vm.menu.length === 1) {
                            const firstChild = vm.menu[0]
                            if (_.get(firstChild, 'conditions.contextVariable1') === 'SolutionPackage')
                            {
                                wfAuth.setSolution(firstChild.title)
                                vm.currentSolution = firstChild.title
                            }

                            vm.menu = _.clone(vm.menu[0].children)
                            vm.useRootSubMenuSelector = false
                        }
                    }

                    if (accountMenuItem) {
                        vm.accountSideMenu = accountMenuItem.children

                        _.remove(accountMenuItem.children, (item) => {
                            switch (_.get(item, 'conditions.contextVariable1')) {
                                case 'ACCOUNT_OrgUsers':
                                case 'ACCOUNT_OrgSettings':
                                    return !vm.isOrganization
                                default:
                                    return false
                            }
                        })
                    }

                    // When a page is reloaded it finds the current id and sets the appropriate page
                    // in the navigational bar to active.
                    // Function declared on the top
                    setActivePage(null, $state, $state.params, null, null)

                    if (wfAuth.getRootStructureInfo().name !== 'insights') {
                        dataOps.getObject({
                            objectType: 71,
                            objectId: 10052,
                            skipExtras: true,
                        }).then((orgsStructure) => {
                            const orgCount = _.get(orgsStructure, 'childs.length')

                            vm.loading = false

                            wfAuth.assignAuthUserValues({
                                rootStructureInfo: wfAuth.getRootStructureInfo(),
                                userHasOrganizations: orgCount > 0,
                            })
                            $timeout()
                        })
                    }
                    else {
                        wfAuth.assignAuthUserValues({
                            rootStructureInfo: wfAuth.getRootStructureInfo(),
                        })
                        vm.loading = false
                        $timeout()
                    }
                })
            }
            else {
                vm.loading = true
                dataOps.getObject('71-10052').then((orgsStructure) => {
                    const orgCount = _.get(orgsStructure, 'childs.length')

                    vm.loading = false

                    wfAuth.assignAuthUserValues({
                        userHasOrganizations: orgCount > 0,
                    })
                    $timeout()
                })
            }
        }

        function onLogout() {
            _.assign(vm, {
                isOrganization: false,
                loadedWithRootSubMenus: false,
                menu: [],
                selectedTopMenu: undefined,
                useRootSubMenuSelector: false,
                homeIconUrl: '',
                showCloseButtonInSolutionSwitcher: false,
            })

            if (getCurrentSolutionFromLocalStorage()) localStorage.removeItem('currentSolutionId')
            topMenuItemsByUrlPath = {}
            topMenuItemsByStateName = {}
            wfPropertyExtractor.clearRegisteredItemNavigationLookup()
        }

        function buildValueChainMenu(childContent, parent, networkStructureId, dataRelation) {
            const
                childs = []
				
            const valueChainRootUrlPath = childContent.conditions.urlPath
				
            const valueChainNetworkIds = contextualValueChainNetworkIds = _.map(wfObject.filter({ where: {
                type: enums.objectType.dataRelation,
                wffid: '71-' + (networkStructureId || valueChainService.ids.networksStructure),
            } }), 'childId')
				
            const valueChainNetworks = wfObject.filter({ where: {
                wfid: { in: _.map(valueChainNetworkIds, (networkId) => { return '52-' + networkId }) },
            } })
				
            const valueChainSubPages = _.chain(wfObject.filter({ where: {
                type: enums.objectType.dataRelation,
                wffid: childContent.wfid,
            } })).sortBy('order').map((relation) => {
                return { relation, content: relation.childContent }
            }).value()
				
            const buildNetworkSubMenu = function (network) {
                const menuOutput = _.map(valueChainSubPages, (subPage) => {
                    return {
                        relationWfid: subPage.relation.wfid,
                        contentWfid: subPage.content.wfid,
                        id: parseInt(subPage.content.id),
                        title: subPage.content.title,
                        url: currentTopSubRootUrl + subPage.content.conditions.urlPath.replace('valuechain', 'valuechain/' + network.id),
                    }
                })

                // Some requirement packages causes the "Requirement Packages" menu item to disappear (server does not return it???)
                // This is a quick fix to make it work for know. Need to debug backend to find the real problem.
                // Update 2018-01-11: Had to remove the quick fic because "Requirement Packages" can be hidden via AccessTags and should then not appear
                // if (!_.some(output, { id: 12225 }))
                // {
                // 	output.splice(2, 0, { id: 12225, title: $translate.instant("RequirementPackages"), url: 'valuechain/' + network.id + "/packages" });
                // }
                if (!networkStructureId) {
                    menuOutput.push({
                        id: 'valueChainEmailHistory-' + network.id,
                        title: $translate.instant('modules.mailHistory.pageHeader'),
                        url: currentTopSubRootUrl + 'valuechain/' + network.id + '/email/history',
                    })
                }

                _.each(menuOutput, (menuItem) => {
                    wfPropertyExtractor.registerItemNavigationUrl({
                        // contentWfid not included since they the same structures are reused - only relations are unique
                        relationWfid: menuItem.relationWfid,
                        url: menuItem.url,
                    })
                })

                return menuOutput

            }

            const output = {
                title: childContent.title,
                url: currentTopSubRootUrl + (valueChainNetworks.length === 1
                    ? valueChainRootUrlPath.replace('valuechain', 'valuechain/' + valueChainNetworks[0].id)
                    : valueChainRootUrlPath),
                parent,
                children: valueChainNetworks.length === 1
                    ? buildNetworkSubMenu(valueChainNetworks[0])
                    : _.chain(valueChainNetworks).map((network) => {
                        return {
                            title: network.title,
                            url: currentTopSubRootUrl + valueChainRootUrlPath.replace('valuechain', 'valuechain/' + network.id),
                            parent: childContent,
                            children: buildNetworkSubMenu(network),
                        }
                    }).sortBy('title').value(),
            }

            wfPropertyExtractor.registerItemNavigationUrl({
                relationWfid: dataRelation.wfid,
                contentWfid: childContent.wfid,
                url: output.url,
            })

            return output
        }

        function buildValueChainAutomationMenu(childContent, parent, networkStructureId, dataRelation) {
            const
                childs = []
				
            const valueChainRootUrlPath = childContent.conditions.urlPath

            // valueChainNetworkIds = _.map(wfObject.filter({ where: {
            // 	type: enums.objectType.dataRelation,
            // 	wffid: "71-" + (networkStructureId || valueChainService.ids.networksStructure)
            // } }), "childId"),
				
            const valueChainNetworks = wfObject.filter({ where: {
                wfid: { in: _.map(contextualValueChainNetworkIds, (networkId) => { return '52-' + networkId }) },
            } })
				
            const valueChainSubPages = _.chain(wfObject.filter({ where: {
                type: enums.objectType.dataRelation,
                wffid: childContent.wfid,
            } })).sortBy('order').map((relation) => {
                return { relation, content: relation.childContent }
            }).value()
				
            const buildNetworkSubMenu = function (network) {
                const menuOutput = _.map(valueChainSubPages, (subPage) => {
                    return {
                        relationWfid: subPage.relation.wfid,
                        contentWfid: subPage.content.wfid,
                        id: parseInt(subPage.content.id),
                        title: subPage.content.title,
                        url: currentTopSubRootUrl + (subPage.content.conditions.urlPath + '').replace('valuechain', 'valuechain/' + network.id),

                        // Used in wfPropertyExtractor to generate correct url for some Data Collector structures that are reused between solutions.
                        urlPattern: '{solution}' + (subPage.content.conditions.urlPath + '').replace('valuechain', 'valuechain/' + '{networkId}'),
                    }
                })

                _.each(menuOutput, (menuItem) => {
                    wfPropertyExtractor.registerItemNavigationUrl({
                        // contentWfid not included since they the same structures are reused - only relations are unique
                        relationWfid: menuItem.relationWfid,
                        networkId: network.id,
                        urlPattern: menuItem.urlPattern,
                    })
                })

                return menuOutput
            }

            contextualValueChainNetworkIds = undefined

            const output = {
                title: childContent.title,
                url: currentTopSubRootUrl + (valueChainNetworks.length === 1
                    ? valueChainRootUrlPath.replace('valuechain', 'valuechain/' + valueChainNetworks[0].id)
                    : valueChainRootUrlPath),
                parent,
                children: valueChainNetworks.length === 1
                    ? buildNetworkSubMenu(valueChainNetworks[0])
                    : _.chain(valueChainNetworks).map((network) => {
                        return {
                            title: network.title,
                            //url: valueChainRootUrlPath.replace('valuechain', 'valuechain/' + network.id),
                            parent: childContent,
                            children: buildNetworkSubMenu(network),
                        }
                    }).sortBy('title').value(),
            }

            wfPropertyExtractor.registerItemNavigationUrl({
                relationWfid: dataRelation.wfid,
                url: output.url,
            })

            return output
        }

        function handleNetworkDependentMenuItems() {
            if (networkDependentMenuItems.length && contextualValueChainNetworkIds.length) {
                _.each(networkDependentMenuItems, (menuItem) => {
                    menuItem.url = menuItem.url.replace('{networkId}', contextualValueChainNetworkIds[0])

                    wfPropertyExtractor.registerItemNavigationUrl({
                        relationWfid: menuItem.relationWfid,
                        contentWfid: menuItem.contentWfid,
                        url: menuItem.url,
                    })
                })

                networkDependentMenuItems.length = 0
            }
        }

        function openSolutionSwitcher() {
            let
                activeSolution
				
            const networksStructureBySolution = {
                sustManagement: { solutionId: valueChainService.ids.networksStructureBySolution.sustManagement, rootItemId: enums.ids.root.sustManagement, networkName: '' },
                sustSourcing: { solutionId: valueChainService.ids.networksStructureBySolution.sustSourcing, rootItemId: enums.ids.root.sustSourcing, networkName: '' },
                sustSourcing2: { solutionId: valueChainService.ids.networksStructureBySolution.sustSourcing2, rootItemId: enums.ids.root.sustSourcing2, networkName: '' },
                sustSourcing3: { solutionId: valueChainService.ids.networksStructureBySolution.sustSourcing3, rootItemId: enums.ids.root.sustSourcing3, networkName: '' },
                sustSourcing4: { solutionId: valueChainService.ids.networksStructureBySolution.sustSourcing4, rootItemId: enums.ids.root.sustSourcing4, networkName: '' },
                sustSourcing5: { solutionId: valueChainService.ids.networksStructureBySolution.sustSourcing5, rootItemId: enums.ids.root.sustSourcing5, networkName: '' },
                sustLending: { solutionId: valueChainService.ids.networksStructureBySolution.sustLending, rootItemId: enums.ids.root.sustLending, networkName: '' },
                sustInvestments: { solutionId: valueChainService.ids.networksStructureBySolution.sustInvestments, rootItemId: enums.ids.root.sustInvestments, networkName: '' },
            }

            getNetworkNames(networksStructureBySolution)
            vm.networkStructureByRootId = _.keyBy(networksStructureBySolution, 'rootItemId')

            if (vm.menu && vm.menu.length > 1) {
                vm.solutionSwitcherActive = true

                if (vm.selectedTopMenu) {
                    vm.showCloseButtonInSolutionSwitcher = true
                    activeSolution = _.find(vm.menu, { id: vm.selectedTopMenu.id })

                    if (activeSolution) activeSolution.active = true
                }

                vm.solutionSwitcherModal = $uibModal.open({
                    animation: true,
                    size: 'width-1200',
                    windowClass: 'solution-switcher',
                    backdrop: 'static',
                    scope: $scope,
                    templateUrl: 'scripts/wf/common/solutionSwitcher.template.html',
                })
            }
        }

        function getNetworkNames(networksStructureBySolution) {
            _.each(networksStructureBySolution, (solution) => {
                const network = wfObject.get('71-' + solution.solutionId)
                if (network && network.childs && network.childs.length) solution.networkName = network.childs[0].childContent.title
            })
        }

        function getCurrentSolutionFromLocalStorage() {
            return localStorage.getItem('currentSolutionId')
        }

        function saveCurrentSolutionInLocalStorage(solution) {
            if (solution && solution.id) localStorage.setItem('currentSolutionId', solution.id)
        }

        function switchAndCloseSolutionSwitcher(selectedSolution) {
            if ($state.current.name === 'root') $location.path(vm.selectedTopMenu.url)

            if (selectedSolution) saveCurrentSolutionInLocalStorage(selectedSolution)

            vm.solutionSwitcherActive = false
            vm.solutionSwitcherModal.close()
            vm.showCloseButtonInSolutionSwitcher = false
        }

        function getHomeUrl() {
            if (vm.selectedTopMenu) vm.homeIconUrl = vm.selectedTopMenu.url
            else vm.homeIconUrl = !$rootScope.isLoggedIn && ($rootScope.usePublicLayout || $rootScope.useWhiteModePublicLayout || $rootScope.useDarkModePublicLayout) ? 'http://www.worldfavor.com' : (_.get($rootScope, 'authUser.isOrganization') || !_.get($rootScope, 'authUser.userHasOrganizations') ? '/' : '/account/organizations')

            return vm.homeIconUrl
        }

        function onAdminModeChange() {
            if ($rootScope.adminMode) {
                $(document.body).addClass('adminMode')
            }
            else {
                $(document.body).removeClass('adminMode')
            }
        }

        function onReactButtonClicked() {
            console.log('2. Click event handled in Angular')
        }

        function copyToClipboard(value) {
            if (!value) {
                return
            }

            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(value)
            }
            else {
                const dummy = document.createElement('input')
                document.body.appendChild(dummy)
                dummy.setAttribute('id', 'dummy_id')
                dummy.value = value
                dummy.select()
                document.execCommand('copy')
                document.body.removeChild(dummy)
            }
        }
    }
})()
