import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import { loadItem, loadSubItemsPage } from '@worldfavor/portal/actions/dataThunk'
import { ObjectType } from '@worldfavor/constants/enums'
import { saveProductActivation } from '@worldfavor/portal/actions/productThunk'
import { StaticIds, FilterTypes } from '@worldfavor/constants'
import { useDebouncedCallback } from 'use-debounce'
import { useDialogState } from '@worldfavor/hooks'
import Loading from '@worldfavor/portal/scenes/Loading'
import StyledPageHeader from '@worldfavor/components/Header/StyledPageHeader'
import RequestingProductTable from '../components/Product/RequestingProductTable'
import ProductActivationDialog from '../components/Product/ProductActivationDialog'
import withPage from '../hoc/withPage'
import SearchBar from '@worldfavor/components/SearchBar'
import EmptyState from '@worldfavor/components/EmptyState'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDataFetcher } from '@worldfavor/hooks/dataFetcher'

const useStyles = makeStyles(theme => ({
    searchBar: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginBottom: 50,
    },
    onFocusSearchBarBorder: {},
    roundedSearchBar: {
        borderRadius: 50 + '!important',
    },
    resultCount: {
        fontWeight: theme.typography.fontWeights.medium,
        fontSize: theme.typography.fontSizes.medium,
    },
    preTableInfo: {
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}))

const WrappedTable = withPage(({ items, totalElements, ...other }) => (
    <Paper data-cy="products-table">
        { items && items.length ? (
            <RequestingProductTable data={items} count={totalElements} {...other} />
        ) : (
            <EmptyState
                style={{ padding: 100 }}
                iconClass={'fas fa-inbox'}
                title={<FormattedMessage id={'supplyChain.emptyStates.noProducts'} />}
            />
        )}
    </Paper>
))

const ProductsScene = (props) => {
    const { intl } = props
    const classes = useStyles()
    const { networkId } = props.match.params
    const dispatch = useDispatch()
    const [pageHeader, setPageHeader] = useState({})
    const [searchText, setSearchText] = useState('')
    const dataLoading = useSelector(state => state.apiCallsInProgress > 0)
    const [debouncedSearchText, setDebouncedSearchText] = useState('')
    const [setSearchTextDebounced] = useDebouncedCallback((value) => {
        setDebouncedSearchText(value)
    }, 500)
    const [open, openDialog, closeDialog] = useDialogState(false)
    const [filters, setFilters] = useState(null)

    const [activationDialogData, setActivationDialogData] = useState({ titleId: undefined, descriptionId: undefined, supplier: undefined, product: undefined })
    const [savingActivation, setSavingActivation] = useState(false)

    useEffect(() => {
        async function fetchData() {
            const [type, id] = StaticIds.ProductsInOwnedNetworks.split('-')
            let pageHeader = {}
            try {
                pageHeader = await dispatch(loadItem(id, type, { childrenLoadDepth: -1 }))
            } catch (e) {
                // TODO handle error
            } finally {
                setPageHeader(pageHeader)
            }
        }

        fetchData()
    }, [])

    const fetchData = (source, pageNumber, pageSize) => dispatch(
        loadSubItemsPage(
            StaticIds.ProductsInOwnedNetworks, pageNumber, pageSize, StaticIds.ProductsInOwnedNetworks,
            source, { ticket: { networkId } },
            [
                {
                    filterType: FilterTypes.TextSearch,
                    stringValues: [searchText],
                },
                ...(filters || []),
            ],
        ),
    )

    const {
        resultCount,
        pageNumber,
        pageSize,
        pageId,
        requestId,
        setPageNumber,
        setPageSize,
    } = useDataFetcher(fetchData, [debouncedSearchText, filters])

    function onFilterChange(filters) {
        setFilters(filters)
    }

    function onPageChange(event, pageNumber) {
        setPageNumber(pageNumber)
    }

    function onChangeRowsPerPage(event) {
        setPageSize(event.target.value)
    }

    function onSearchChange(event) {
        setSearchText(event.target.value)
        setSearchTextDebounced(event.target.value)
    }

    function onClear() {
        setSearchText('')
    }

    function onOpenActivationDialog(product, supplier) {
        setActivationDialogData({
            product,
            supplier,
        })
        openDialog()
    }

    async function onSaveActivation() {
        setSavingActivation(true)
        await dispatch(saveProductActivation(activationDialogData.product.wfid, !activationDialogData.product.isActive, `${ObjectType.network}-${networkId}`))
        closeDialog()
        setSavingActivation(false)
    }

    return (
        <Grid container justify={'center'}>
            <MaxWidthGrid item xs={12} sm={12}>
                { pageHeader && (
                    <div data-cy="products-title">
                        <StyledPageHeader title={pageHeader.title} description={pageHeader.description} />
                    </div>
                )}
                <SearchBar
                    inputProps={{
                        value: searchText,
                        placeholder: intl.formatMessage({ id: 'general.search' }) + ` ${pageHeader.title}`,
                        onChange: onSearchChange,
                    }}
                    onClear={onClear}
                    rounded
                    classes={{
                        root: classes.searchBar,
                        rounded: classes.roundedSearchBar,
                        onFocusBorder: classes.onFocusSearchBarBorder,
                    }}
                />
                {
                    (dataLoading || !requestId || !pageId) ? <Loading /> : (
                        <>
                            <div className={classes.preTableInfo}>
                                <span className={classes.resultCount}><FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} /></span>
                            </div>
                            <WrappedTable
                                requestId={requestId}
                                pageId={pageId}
                                networkId={networkId}
                                rowsPerPage={pageSize}
                                onOpenActivationDialog={onOpenActivationDialog}
                                page={pageNumber}
                                onPageChange={onPageChange}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                controlledPagination
                                enableSparsePage
                                enablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                onFilterChange={onFilterChange}
                            />
                        </>
                    )
                }
        
                {
                    open && (
                        <ProductActivationDialog
                            open={open}
                            productName={activationDialogData.product.name}
                            supplierName={activationDialogData.supplier.name}
                            activate={!activationDialogData.product.isActive}
                            onCancel={closeDialog}
                            onSave={onSaveActivation}
                            saving={savingActivation}
                        />
                    )
                }
            </MaxWidthGrid>
        </Grid>
    )
}

export default withRouter(injectIntl(ProductsScene))
