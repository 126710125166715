(function () {
    'use strict'

    angular
        .module('wf.data')
        .service('dataModeller', dataModeller)

    dataModeller.$inject = ['$translate', '$q', 'apiProxy', 'wfObject']

    function dataModeller($translate, $q, apiProxy, wfObject) {
        const valuesByIdentifier = {
        }

        const modellersByType = {}
        const service = {
            fromObjectToArray,
            fromArrayToObject,
            objectSettings: {
                getCollectionModelFromItem(item) {
                    return makeObjectSettingsCollectionModel(item, 'settings')
                },
            },
            objectConditions: {
                getCollectionModelFromItem(item) {
                    return makeObjectSettingsCollectionModel(item, 'conditions')
                },
            },
            getArrayFromFilter(property, filter) {
                const output = _.map(wfObject.filter(filter), property)
                //console.log(output);
                return output
            },
            setFilterValue(identifier, value) {
                valuesByIdentifier[identifier] = value
            },
            getFilterValue(identifier, key) {
                const x = valuesByIdentifier[identifier]
                if (x && key) return x[key]
                else return x
            },
        }

        activate()

        return service

        function fromObjectToArray(type, data) {
            if (modellersByType[type] && modellersByType[type].fromObjectToArray) return modellersByType[type].fromObjectToArray(data)
        }

        function fromArrayToObject(type, data) {
            if (modellersByType[type] && modellersByType[type].fromArrayToObject) return modellersByType[type].fromArrayToObject(data)
        }

        function makeObjectSettingsCollectionModel(item, propertyName) {
            return {
                id: null,
                type: 79,
                objectId: item.id,
                objectType: item.type,
                items: modellersByType[79].fromObjectToArray(item[propertyName]),
            }
        }

        function activate()
        {
            modellersByType[79] = {
                fromObjectToArray(data) {
                    const output = []

                    if (!data) return output

                    if (data.settingsBundle)
                    {
                        return [{ settingKind: 'settingsBundle', value: data.settingsBundle }]
                    }

                    angular.forEach(data, (value, key) => {
                        let formattedValue

                        if (key == 'dataRelation') value = ''
							
                        if (key == 'childrenSettings') return

                        if (typeof value === 'object') formattedValue = JSON.stringify(value)
                        else if (typeof value === 'undefined' || value === null) formattedValue = ''
                        else formattedValue = value.toString()

                        output.push({ settingKind: key, value: formattedValue })
                    })

                    return output
                },
                fromArrayToObject(data) {
                    const output = {}

                    for (let i = 0, len = data.length; i < data; i++) {
                        output[data[i].settingKind] = data[i].value
                    }

                    return output
                },
            }
        }
    }
})()
