// @flow
import * as React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import OrganizationTooltip from './OrganizationTooltip'

type Props = {
  organization: {
    name: string,
    actorTypes: Array<{ title: string }>,
  },
  compact?: boolean,
  showBadge?: boolean,
  actorTypes?: Array<{ title: string }>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 58,
        height: 58,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hover: {
        '& $actorTypeBadge': {
            boxShadow: theme.shadows[2],
            transform: 'scale(1.1,1.1)',
        },

        '& $markerContainer': {
            boxShadow: theme.shadows[2],
            transform: 'scale(1.1,1.1)',
        },
    },
    markerContainer: {
        width: 58,
        height: 58,

        position: 'relative',

        borderRadius: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.55)',
        borderStyle: 'solid',
        borderWidth: 2.5,
        borderColor: 'rgba(0, 0, 0, 0.3)',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    compact: {
        width: 36,
        height: 36,

        '& $markerContainer': {
            width: 36,
            height: 36,
        },

        '& $logoContainer': {
            width: 26,
            height: 26,
            '& i': {
                fontSize: 14,
            },
        },

        '& $actorTypeBadge': {
            height: 22,
            width: 22,

            bottom: -6,
            right: -6,

            '& i': {
                fontSize: 10,
            },
        },
    },
    logoContainer: {
        height: 52,
        width: 52,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        overflow: 'hidden',

        '& i': {
            fontSize: 18,
        },
    },
    logo: {
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    actorTypeBadge: {
        height: 28,
        width: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',

        borderRadius: '100%',
        position: 'absolute',
        bottom: -8,
        right: -8,

        boxShadow: theme.shadows[1],

        '& i': {
            fontSize: 13,
        },
    },
}))

const OrganizationMarker = (props: Props) => {
    const { organization, compact, showBadge, actorTypes, ...rest } = props
    const classes = useStyles(props)

    const { imageUrl } = organization
    const actorTypeIcon = 'fas fa-box-open'

    const [hover, setHover] = useState(false)

    function onMouseOver() {
        setHover(true)
    }

    function onMouseLeave() {
        setHover(false)
    }

    return (
        <div
            className={classNames(classes.root, { [classes.compact]: compact, [classes.hover]: hover })}
            {...rest}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <div className={classes.markerContainer}>
                {
                    imageUrl ? (
                        <div className={classes.logoContainer}>
                            <div className={classes.logo} style={{ backgroundImage: `url('${imageUrl}')` }} />
                        </div>
                    ) : (
                        <div
                            className={classes.logoContainer}
                            style={{ backgroundColor: 'white' }}
                        >
                            <i className={actorTypeIcon} />
                        </div>
                    )
                }
            </div>

            {
                imageUrl && showBadge && (
                    <div className={classes.actorTypeBadge}>
                        <i className={actorTypeIcon} />
                    </div>
                )
            }

            <OrganizationTooltip
                open={!compact && hover}
                organization={organization}
                actorTypes={actorTypes}
            />
        </div>
    )
}

OrganizationMarker.defaultProps = {
    showBadge: true,
}

export default OrganizationMarker
