import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'
    let objSettingsRowRenderer

    angular
        .module('wf.common')
        .directive('wfObjectSettings', wfObjectSettings)

    wfObjectSettings.$inject = ['$interpolate', '$sanitize']
    function wfObjectSettings($interpolate, $sanitize) {
        objSettingsRowRenderer = initObjSettingsRowRenderer($interpolate, $sanitize)

        const directive = {
            controller: wfObjectSettingsController,
            controllerAs: 'objSettingsVm',
            templateUrl: 'scripts/wf/objectSettings/wfObjectSettings.directive.html',
            restrict: 'E',
        }

        return directive
    }

    function initObjSettingsRowRenderer($interpolate, $sanitize) {
        const html = '<li><strong>{{label}}:</strong><span>{{optionName}}</span><li>'
        const interpolationFunc = $interpolate(html)

        return {
            render(settingDef, currentItemSettings) {
                let
                    currentSettingDefOption
					
                let params
					
                let paramsAdditionalItem
					
                let output
					
                const hasCurrentSettingValue = currentItemSettings.hasOwnProperty(settingDef.settingKind)
					
                let currentSettingValue = currentItemSettings[settingDef.settingKind]

                if (settingDef.condition) {
                    if (!_.isMatch(currentItemSettings[settingDef.condition.key], settingDef.condition.value)) {
                        return
                    }
                }

                if (settingDef.customControl) {
                    return
                }

                if (hasCurrentSettingValue) {
                    if (_.isArrayLike(currentSettingValue)) {
                        currentSettingValue = currentSettingValue.join(',')
                    }

                    currentSettingDefOption = _.find(settingDef.options, (option) => {
                        return typeof option.checkValue === 'function'
                            ? option.checkValue(currentSettingValue)
                            : option.value === currentSettingValue
                    })

                    if (!currentSettingDefOption || currentSettingDefOption.hideInPreview) {
                        return
                    }

                    params = {
                        label: $sanitize(settingDef.label),
                        optionName: $sanitize(currentSettingDefOption.name),
                    }

                    if (currentSettingDefOption.additionalWhenSelected && currentSettingDefOption.additionalWhenSelected.display) {
                        const displayValue = currentSettingDefOption.additionalWhenSelected.display.value(currentSettingValue)
                        if (displayValue) {
                            paramsAdditionalItem = {
                                label: $sanitize(currentSettingDefOption.additionalWhenSelected.display.label()),
                                optionName: $sanitize(displayValue),
                            }
                        }
                    }

                }
                else {
                    currentSettingDefOption = _.find(settingDef.options, { whenNotSet: true })
                    if (!currentSettingDefOption || currentSettingDefOption.hideInPreview) {
                        return
                    }
                    params = {
                        label: $sanitize(settingDef.label),
                        optionName: $sanitize(currentSettingDefOption.name),
                    }
                }

                output = interpolationFunc(params)

                if (paramsAdditionalItem) {
                    output += interpolationFunc(paramsAdditionalItem)
                }

                // console.log(params, output);

                return output
            },
        }
    }

    wfObjectSettingsController.$inject = ['$scope', '$element', 'dataOperationsService', '$timeout', 'wfAuth', 'modalService', '$parse', '$attrs', '$translate']
    function wfObjectSettingsController($scope, $element, dataOps, $timeout, wfAuth, modal, $parse, attrs, $translate) {
        const
            definition = $parse(attrs.settings)($scope)
			
        let item
			
        let onChange
			
        let objectSettings
			
        let forOrganization
			
        let uiMode
			
        const vm = this
			
        const requirementSpec = {}
			
        let defaultRequirement
			
        let selectorOptions
			
        let currentItemSettings

        if (definition) {
            item = $parse(attrs.item)($scope) || definition.item
            onChange = $parse(attrs.onChange)($scope)

            if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === 'string') {
                item = item.originalRelation
            }

            objectSettings = definition.settings
            forOrganization = definition.forOrganization
            uiMode = definition.uiMode || enums.uiMode.view
            currentItemSettings = (item.type === enums.objectType.dataRelation ? item.settings : item.conditions) || {}
            vm.editButtonCaption = definition.editButtonCaption || $translate.instant('Edit')

            if ($parse(attrs.compactLayout)($scope)) {
                $element.addClass('compact-layout')
            }
        }
        else {
            $element.remove()
            return
        }

        activate()

        function activate() {
            _.assign(vm, {
                compiler: {},
                uiMode,
                isAdminMode: uiMode === enums.uiMode.admin,
                getDisplayContent,
                editObjectSettings,
                hasObjSettings: !!objectSettings.length,
            })
        }

        function getDisplayContent() {
            const
                output = []

            for (var i = 0, len = objectSettings.length, objectSetting; i < len; i++) {
                objectSetting = objectSettings[i]
                output.push(objSettingsRowRenderer.render(objectSetting, currentItemSettings))
            }

            // for (var key in item.settings) {
            // 	if (item.settings.hasOwnProperty(key)) {
            // 		var value = item.settings[key];

            // 		setting = _.find(objectSettingsDefinition, { settingKind: key })

            // 		if (setting) {
            // 		}

            // 	}
            // }

            return output.join('')
        }

        function editObjectSettings() {
            modal.editFormattedObjectSettings({
                item,
                objectSettings,
                onBeforeSubmit: definition.onBeforeSubmit,
                forOrganization,
            }).then(() => {
                currentItemSettings = (item.type === enums.objectType.dataRelation ? item.settings : item.conditions)
                vm.compiler.compile()
                $timeout()

                if (definition.onUpdated) definition.onUpdated()

                if (typeof onChange === 'function') onChange()
            })
        }

        function getText(setting) {
            // console.log(requirement);
            if (!settingRow) return

            var settingRow = _.find(requirementOptions, { rule: requirement.rule, value: requirement.value })
            if (requirementOption) {
                return requirementOption.name
            }
        }

        function getDefaultRequirementText() {
            const requirement = requirementSpec.standard

            if (!requirement && vm.isAdminMode) {
                return 'Inget krav'
            }

            return getText(requirement)
        }

        function getSpecificRequirementText() {
            const requirement = requirementSpec.specific

            return getText(requirement)
        }

        function editSpecificRequirement() {
            if (!selectorOptions) {
                selectorOptions = _.filter(settings.requirementOptions, (item) => {
                    return item.selectable !== false
                })
            }
            modal.editRequirement({
                item,
                forOrganizationId: settings.forOrganizationId,
                selectorOptions,
            }).then((updatedRequirementSpec) => {
                requirementSpec.specific = updatedRequirementSpec.specific

                if (requirementSpec.specific === null) {
                    requirementSpec.actual = requirementSpec.standard
                }
                else {
                    requirementSpec.actual = requirementSpec.specific
                }

                vm.compiler.compile()
                $timeout()

                if (settings.onUpdated) settings.onUpdated()
            })
        }

        function editDefaultRequirement() {
            if (!selectorOptions) {
                selectorOptions = _.filter(settings.requirementOptions, (item) => {
                    return item.selectable !== false
                })
            }
            modal.editRequirement({
                item,
                selectorOptions,
            }).then((updatedRequirementSpec) => {
                requirementSpec.standard = updatedRequirementSpec.standard

                if (requirementSpec.specific === null) {
                    requirementSpec.actual = requirementSpec.standard
                }
                else {
                    requirementSpec.actual = requirementSpec.specific
                }

                vm.compiler.compile()
                $timeout()

                if (settings.onUpdated) settings.onUpdated()
            })
        }
    }
})()
