import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('statisticsService', statisticsService)

    statisticsService.$inject = ['dataOperationsService', 'apiProxy', '$q', 'wfObject']
    function statisticsService(dataOps, apiProxy, $q, wfObject) {
        const
            forceEmptyState = false
			
        const chartJsPrototypes = getChartJsPrototypes()
			
        const defaultOptions = {
            accumulateLineChart: false,
        }

        const service = {
            loadStatistics,
            getChartJSConfigObject,
        }

        return service

        function loadStatistics(options) {
            const
                stats = _.assign(options, _.cloneDeep(defaultOptions), options)
				
            const promise = init()

            return promise

            function init() {
                return $q((finalResolve, finalReject) => {
                    let promises

                    if (stats.structureIds) { // Get statistics from multiple structures. Don't setup charts
                        promises = []
                        stats.totals = []

                        _.each(stats.structureIds, (structureId) => {
                            const
                                loadStructureStats = function () {
                                    const params = _.assign({
                                        objectType: enums.objectType.structure,
                                        objectId: structureId,
                                        onlyStatistics: true,
                                        bypassCache: true,
                                        cacheResponse: false,
                                        getterConditions: {
                                            loadCreators: false,
                                        },
                                    }, stats.additionalRequestParams)

                                    return dataOps.getObject(params)
                                }
                                
                            const pushStats = function (res) {
                                let structureStats

                                if (res && res.metadata && res.metadata.statistics) {
                                    structureStats = res.metadata.statistics

                                    if (typeof stats.onBeforeSetup === 'function') structureStats = stats.onBeforeSetup(structureStats)

                                    if (stats.chartType == 'line') {
                                        if (stats.combinedData) {
                                            stats.structureStatsToCombine = stats.structureStatsToCombine || []
                                            stats.structureStatsToCombine.push(structureStats)
                                        }
                                        else {
                                            _.assign(stats, structureStats)
                                            setupLineChart(stats)
                                        }

                                    }

                                    if (stats.asEmphasizedTotals) {
                                        stats.totals.push({
                                            id: res.id,
                                            label: stats.labels && stats.labels[res.id] ? stats.labels[res.id] : res.title,
                                            value: forceEmptyState ? 0 : structureStats.count,
                                        })
                                    }
                                }
                            }

                            // Check if access to structure must be checked before loading statistics
                            if (stats.accessStructures && stats.accessStructures[structureId]) {
                                promises.push($q((resolve) => {
                                    const accessStructureWfid = stats.accessStructures[structureId]

                                    dataOps.getObject(accessStructureWfid).then(() => {
                                        if (wfObject.get(accessStructureWfid)) {
                                            loadStructureStats().then((res) => {
                                                pushStats(res)
                                                resolve()
                                            })
                                        }
                                        else resolve()
                                    })
                                }))
                            }
                            else { // Otherwise load statistics directly
                                promises.push(loadStructureStats().then(pushStats))
                            }
                        })

                        $q.all(promises).then(() => {
                            let combinedStructureStats
                            if (stats.combinedData) {
                                combinedStructureStats = combineStructureStats(stats.structureStatsToCombine)
                                _.assign(stats, combinedStructureStats)
                                setupLineChart(stats)
                            }

                            // stats.structures = _.sortBy(stats.structures, function (item) {
                            // 	return stats.structureIds.indexOf(item.id);
                            // });

                            if (!stats.asEmphasizedTotals) {
                                _.each(stats.totals, (total) => {
                                    total.label = stats.labels && stats.labels[total.id] ? stats.labels[total.id] : undefined
                                })
                            }

                            stats.loaded = true
                            finalResolve(stats)
                            // if (stats.chartType === "line") {

                            // }
                            // else
                            // 	stats.emphasizeTotals = true;
                        })
                    }
                    else if (stats.structureId) { // Get statistics from one structure and setup charts
                        const params = _.assign({
                            objectType: enums.objectType.structure,
                            objectId: stats.structureId,
                            onlyStatistics: true,
                            bypassCache: true,
                            cacheResponse: false,
                            getterConditions: {
                                loadCreators: false,
                            },
                        }, stats.additionalRequestParams)

                        dataOps.getObject(params).then((res) => {
                            let structureStats
                            stats.loaded = true

                            if (res && res.metadata && res.metadata.statistics) {
                                structureStats = res.metadata.statistics

                                if (typeof stats.onBeforeSetup === 'function') structureStats = stats.onBeforeSetup(structureStats)

                                _.assign(stats, structureStats)

                                if (stats.chartType == 'donut') {
                                    setupDonutChart(stats)
                                }
                                else if (stats.chartType == 'line') setupLineChart(stats)

                                // if (key === "eventLog") {
                                // 	vm.eventLogStats = stats;
                                // }

                                _.each(stats.totals, (total) => {
                                    total.label = stats.labels && stats.labels[total.id] ? stats.labels[total.id] : undefined
                                })

                                if (typeof stats.onAfterSetup === 'function') {
                                    stats.onAfterSetup(stats)
                                }
                            }
                            else {
                                stats.showMainCount = true
                                stats.count = 0
                            }

                            finalResolve(stats)
                        })
                    }
                })
            }
        }

        function setupDonutChart(stats) {
            const
                dataset = _.cloneDeep(chartJsPrototypes.donut.datasetPrototype)
				
            const fulfilledTotal = _.find(stats.totals, { id: 'fulfilledCount' })

            stats.showTotalsAsFraction = true
            stats.partCount = fulfilledTotal.value
            stats.chartJsOptions = _.cloneDeep(chartJsPrototypes.donut.options)

            stats.chartJsData = {
                labels: [],
                datasets: [dataset],
            }

            dataset.data = []
            if (stats.totals.length > 0) {
                dataset.data.push(fulfilledTotal.value)
                dataset.data.push(stats.count - fulfilledTotal.value)
            }

            if (forceEmptyState) {
                stats.count = 0
                stats.partCount = 0
                _.each(dataset.data, (data, i) => {
                    dataset.data[i] = 0
                })
                _.each(stats.totals, (total) => {
                    total.value = 0
                })
            }
        }

        function setupLineChart(stats) {
            stats.fillTimeGapsOnXAxes = true
            stats.accumulateLineChart = true
            const chartJsConfig = getChartJSConfigObject(stats)

            _.assign(stats, chartJsConfig)

            if (forceEmptyState) {
                stats.count = 0
                stats.chartJsData.labels = []
                stats.chartJsData.datasets = []
                stats.hasDataset = false

                _.each(stats.chartJsData.datasets, (dataset) => {
                    dataset.data = []
                })

                _.each(stats.totals, (total) => {
                    total.value = 0
                })
            }
        }

        function getChartJsPrototypes() {
            const offscreen = document.createElement('canvas') // detached from DOM
            const ctx = offscreen.getContext('2d')
            const bgGradient = ctx.createLinearGradient(0, 0, 0, 110)
            bgGradient.addColorStop(0, 'rgba(0, 0, 0, 0.08)')
            bgGradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

            return {
                line: {
                    datasetPrototype: {
                        label: '',
                        fill: true,
                        lineTension: 0.2,
                        backgroundColor: bgGradient,
                        borderColor: 'rgba(255,255,255,1)',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        borderWidth: 4,
                        pointBorderColor: 'transparent',
                        pointBackgroundColor: 'transparent',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        // pointHoverBackgroundColor: "rgba(75,192,192,1)",
                        // pointHoverBorderColor: "rgba(220,220,220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: undefined,
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                display: false,
                                ticks: {
                                    min: 0,
                                },
                            }],
                            xAxes: [{
                                display: true,
                                gridLines: {
                                    drawTicks: true,
                                    drawOnChartArea: false,
                                    zeroLineColor: 'rgba(0,0,0,0.0)',
                                    tickMarkLength: 8,
                                    color: 'rgba(0,0,0,0.0)',
                                },
                                ticks: {
                                    autoSkip: false,
                                    maxRotation: 0,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    padding: 5,
                                    fontColor: 'rgba(255,255,255,0.6)',
                                },
                            }],
                        },
                        legend: {
                            display: false,
                            labels: {
                                fontColor: 'rgb(255, 99, 132)',
                            },
                        },
                        layout: {
                            padding: {
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            },
                        },
                    },
                },
                donut: {
                    datasetPrototype: {
                        label: '',
                        backgroundColor: ['#fff', 'rgba(0,0,0,0.05)'],
                        borderColor: 'transparent',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        borderWidth: 4,
                        data: undefined,
                    },
                    options: {
                        responsive: false,
                        maintainAspectRatio: false,
                        cutoutPercentage: 94.5,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: false,
                        },
                    },
                },
            }
        }

        function combineStructureStats(structureStatsArray) {
            const output = {
                count: 0,
                datasets: [],
                totals: [],
            }

            _.each(structureStatsArray, (structureStats) => {
                output.count += _.sumBy(structureStats.totals, 'value')

                _.each(structureStats.datasets, (dataset) => {
                    const outputDataset = _.find(output.datasets, { id: dataset.id })
                    let outputDatasetLookup

                    if (outputDataset) {
                        outputDatasetLookup = _.keyBy(outputDataset.data, (dataEntry) => {
                            return dataEntry.year + '-' + dataEntry.month
                        })

                        _.each(dataset.data, (dataEntry) => {
                            const outputDataEntry = outputDatasetLookup[dataEntry.year + '-' + dataEntry.month]

                            if (outputDataEntry) outputDataEntry.value += dataEntry.value
                            else outputDataset.data.push(_.cloneDeep(dataEntry))
                        })
                    }
                    else {
                        output.datasets.push(_.cloneDeep(dataset))
                    }

                })

                _.each(structureStats.totals, (total) => {
                    const outputTotal = _.find(output.totals, { id: total.id })

                    if (outputTotal) {
                        outputTotal.value += total.value
                    }
                    else {
                        output.totals.push(_.cloneDeep(total))
                    }

                })
            })

            _.each(output.datasets, (outputDataset) => {
                outputDataset.data = _.orderBy(outputDataset.data, ['year', 'month'])
            })

            return output
        }

        function getChartJSConfigObject(options) {
            const output = {}
            let minYear; let maxYear; let minMonth; let maxMonth; let dataHasMonths
            const valueProperty = options.valueProperty || 'value'

            output.showMainCount = true
            output.chartJsData = {
                labels: [],
                datasets: [],
            }

            output.chartJsOptions = _.cloneDeep(chartJsPrototypes.line.options)

            //SUPPORT FOR CHARTIST
            // Find out min and max year and month across all datasets
            _.each(options.datasets, (dataSet) => {
                const
                    firstDataEntry = dataSet.data[0]
					
                const lastDataEntry = dataSet.data[dataSet.data.length - 1]
					
                dataHasMonths = firstDataEntry ? firstDataEntry.month : null

                if (firstDataEntry) {
                    if (!minYear || minYear >= firstDataEntry.year) {
                        minYear = firstDataEntry.year

                        if (dataHasMonths && !minMonth || minMonth >= firstDataEntry.month) {
                            minMonth = firstDataEntry.month
                        }
                    }
                }
                if (lastDataEntry) {
                    if (!maxYear || maxYear <= lastDataEntry.year) {
                        maxYear = lastDataEntry.year

                        if (dataHasMonths && !maxMonth || maxMonth <= lastDataEntry.month) {
                            maxMonth = lastDataEntry.month
                        }
                    }
                }
            })

            if (dataHasMonths) {
                // Offset the min month by -1 so that the line chart will always start with a zero value
                if (minMonth === 1) {
                    minMonth = 12
                    minYear--
                }
                else {
                    minMonth--
                }
            }

            // Loop through all datasets
            _.each(options.datasets, (dataSet, index) => {
                let
                    accumulatedValue = 0
					
                const dataSetWithoutGaps = []
					
                let preparedDataSetEntries = dataSet.data
					
                let maxValue
					
                let newMaxValue
					
                let countPerPixel

                // Create keyBy lookup that will be used when filling in gaps in the data with zeros
					
                const dataByKey = _.keyBy(dataSet.data, (item) => {
                    return item.year + '-' + item.month
                })

                //SUPPORT FOR CHARTIST
                if (options.fillTimeGapsOnXAxes) {
                    // Fill in gaps in the data with zeros so that every month has data
                    for (var year = minYear, monthCap; year <= maxYear; year++) {

                        // Set correct month cap for this year if it is the last year
                        monthCap = year === maxYear ? maxMonth : 12

                        // The loop starts on minMonth if this is the first year, otherwise 1
                        for (var month = year === minYear ? minMonth : 1, dataEntry; month <= monthCap; month++) {
                            dataEntry = dataByKey[year + '-' + month]

                            if (dataEntry) // Check if a data entry on this timestamp exists
                                dataSetWithoutGaps.push(dataEntry)
                            else {
                                // If not then fill in the gap with a zero
                                dataSetWithoutGaps.push({
                                    year,
                                    month,
                                    value: 0,
                                })
                            }
                        }
                    }
                    preparedDataSetEntries = dataSetWithoutGaps
                }

                // dataSetWithoutGaps = _.takeRight(dataSetWithoutGaps, 1);

                if (preparedDataSetEntries.length > 1) {
                    output.hasDataset = true
                }

                if (index === 0) {
                    const preparedDataSetCount = preparedDataSetEntries.length
                    let labelDistance
                    const labelsAtIndexes = []
                    let tickCount = 0

                    switch (preparedDataSetCount) {
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            labelDistance = 1
                            tickCount = preparedDataSetCount
                            break
                        case 6:
                        case 7:
                            labelDistance = 2
                            tickCount = 4
                            break
                        case 8:
                        case 9:
                            labelDistance = 2
                            tickCount = 5
                            break
                        case 10:
                            labelDistance = 3
                            tickCount = 5
                            break
                        case 11:
                            labelDistance = 4
                            tickCount = 5
                            break
                        default:
                            labelDistance = preparedDataSetCount * 0.25
                            tickCount = 5
                            break
                    }

                    for (let i = 0; i < tickCount; i++) {
                        if (i == tickCount - 1) labelsAtIndexes.push(preparedDataSetCount - 1)
                        else labelsAtIndexes.push(_.floor(i * labelDistance))
                    }
                    var monthNames = moment.monthsShort()
                    // console.log(stats.contextVariable1, "-------------------------------------")
                    // console.log("dataSetWithoutGapsCount", dataSetWithoutGapsCount);
                    // console.log("labelDistance", labelDistance);
                    // console.log("tickCount", tickCount);
                    // console.log("labelsAtIndexes", labelsAtIndexes);

                    output.chartJsOptions.scales.xAxes[0].afterBuildTicks = function (chart) {
                        let label
                        chart.ticks = []
                        _.each(preparedDataSetEntries, (dataEntry, dataEntryIndex) => {

                            if (~labelsAtIndexes.indexOf(dataEntryIndex)) {
                                label = [monthNames[dataEntry.month - 1], dataEntry.year]
                            }
                            else label = ''

                            chart.ticks.push(label)
                        })
                    }
                }

                const parsedDataEntries = _.map(preparedDataSetEntries, (dataEntry, dataEntryIndex) => {
                    let label
                    if (index === 0) { // Add labels once
                        // if (!!~labelsAtIndexes.indexOf(dataEntryIndex)) {
                        if (dataHasMonths) label = monthNames[dataEntry.month - 1] + '\n' + dataEntry.year
                        else label = dataEntry.year
                        // 	console.log(dataEntryIndex, label)
                        // }
                        // else
                        // 	label = "";

                        output.chartJsData.labels.push(label)
                    }

                    // Use the sum of all previous values if accumulateLineChart is true
                    if (options.accumulateLineChart) {
                        return accumulatedValue += dataEntry[valueProperty]
                    }
                    else // Otherwise simply use the value as is
                        return dataEntry[valueProperty]
                })

                let chartJsDataset

                maxValue = _.max(parsedDataEntries)

                if (!output.maxValue || output.maxValue < maxValue) output.maxValue = maxValue

                chartJsDataset = _.assign(_.cloneDeep(chartJsPrototypes.line.datasetPrototype), options.datasetPrototype, {
                    data: parsedDataEntries,
                    borderDash: index === 0 ? [] : [7, 8], // The first datset will be a straight line and all others will be dashed 
                })

                _.each(chartJsDataset, (value, key) => {
                    if (typeof value === 'function') chartJsDataset[key] = value()
                })

                // Push the prepared dataset to the chartJs dataset property
                output.chartJsData.datasets.push(chartJsDataset)
            })

            const countPerPixel = output.maxValue / 110.0
            const newMaxValue = output.maxValue + (countPerPixel * 10)

            _.assign(output.chartJsOptions.scales.yAxes[0].ticks, {
                min: (countPerPixel * 5) * -1,
                max: newMaxValue,
                // max: maxValue + (countPerPixel * 5)
            })

            _.assign(output.chartJsOptions, _.cloneDeep(options.optionsPrototype))

            return output
        }
    }
})()
