/**
 * @ngdoc directive
 * @name wfPermissions
 * @restrict A
 * 
 * @description
 * Use to set up a container in which CRUD permissions can be used to show/hide ui elements.
 * The permissions can be based on a permissions object from a structure that contains the booleans canCreate, canRead, canUpdate and canDelete.
 * Use the accompanying directives wf-permission-* (for each type of operation) on any html element that should be shown/hidden based on a permission.
 */

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfPermissions', wfPermissions)

    wfPermissions.$inject = ['wfAuth', '$compile']
    function wfPermissions(wfAuth, $compile) {
        const directive = {
            restrict: 'A',
            controllerAs: 'permissionsVm',
            controller: wfPermissionsController,
        }
        return directive
    }

    wfPermissionsController.$inject = ['$scope', '$attrs', '$parse']
    function wfPermissionsController($scope, $attrs, $parse) {
        const
            vm = this
			
        let options
			
        let fromItem
			
        const permissionsPrototype = {
            canCreate: true,
            canRead: false,
            canUpdate: true,
            canDelete: true,
        }

        vm.permissions = void 0

        activate()

        function activate() {
            options = $parse($attrs.wfPermissions)($scope)

            if (options !== void 0) {
                if (options.fromItem) {
                    vm.permissions = options.fromItem.permissions
                }
            }
			
            if (!vm.permissions) {
                vm.permissions = _.clone(permissionsPrototype)
            }
        }
    }
})()
