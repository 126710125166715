// @flow
import * as React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import ActorTypePicker from '@worldfavor/components/Picker/ActorTypePicker'
import EmptyState from '@worldfavor/components/EmptyState'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 15,
        width: 700,
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 24px',
    },
    dialogTitle: {
        color: theme.palette.common.black,
    },
    dialogContent: {
        paddingTop: 16,
        paddingBottom: 16,
    },
}))

type Props = {
  types: Array<{
    imageUrl: string,
    title: string,
    description: string,
  }>,
  selectedActorTypeWfids?: Array<string>,
  saving?: boolean,
  onCancel: () => void,
  onClose: () => void,
  onSubmit?: () => void,
}

const ActorTypesDialog = (props: Props) => {
    const { types, selectedActorTypeWfids, onCancel, onClose, onSubmit, saving, organization, ...rest } = props
    const classes = useStyles(props)

    const [actorType, setActorType] = useState(null)
    const [isCancelable, setIsCancelable] = useState(false)

    useEffect(() => {
        if (rest.open) {
            if (selectedActorTypeWfids && selectedActorTypeWfids.length) {
                const selectedActorType = types.filter(x => selectedActorTypeWfids.includes(x.wfid)).map(x => x.wfid)[0]
                setActorType(selectedActorType) // Currently only supports a single actor type
                setIsCancelable(true)
            }
            else {
                setActorType(null)
                setIsCancelable(false)
            }
        }
    }, [selectedActorTypeWfids])

    function onActorTypeChange(event, selectedActorType) {
        setActorType(selectedActorType)
        setIsCancelable(selectedActorTypeWfids && selectedActorTypeWfids.length && types.filter(x => selectedActorTypeWfids.includes(x.wfid)).map(x => x.wfid).length)

    }

    function _onClose(event, reason) {
        if (!actorType) {
            return
        }
        if (reason === 'escapeKeyDown') {
            onClose()
        }
    }

    function _onSubmit(event) {
        if (!actorType) {
            return
        }
        onSubmit(event, actorType)
    }

    function _onCancel() {
        if (!isCancelable) {
            return
        }
        onCancel()
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            maxWidth={'md'}
            onClose={_onClose}
            {...rest}
        >
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <FormattedMessage id={'dialog.actorTypesDialog.title'} values={{ organizationName: organization ? organization.name : '' }} />
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                {
                    types && (
                        <ActorTypePicker
                            types={types}
                            selectedType={actorType}
                            onSelect={onActorTypeChange}
                        />
                    )
                }
                {
                    !types && (
                        <EmptyState
                            className={classes.emptyState}
                            title={<FormattedMessage id={'dialog.actorTypesDialog.emptyStateTitle'} />}
                            iconClass={'fas fa-hammer'}
                        />
                    )
                }
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button
                    disabled={!isCancelable}
                    className={classes.cancelButton}
                    onClick={_onCancel}
                >
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <CircularProgressWrapper loading={saving}>
                    <Button
                        disabled={saving || !actorType}
                        className={classes.submitButton}
                        onClick={_onSubmit}
                    >
                        <FormattedMessage id={'general.choose'} />
                    </Button>
                </CircularProgressWrapper>
            </DialogActions>
        </Dialog>
    )
}

export default ActorTypesDialog
