// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import Divider from '@worldfavor/components/Divider'
import Colors from '@worldfavor/constants/colors'
import { useSelect } from '@worldfavor/hooks'

import TreeViewer from './TreeViewer'
import ListViewer from './ListViewer'
import MapViewer from './MapViewer'

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        height: 64,
        display: 'flex',
        alignItems: 'center',

        boxShadow: theme.shadows[1],
        marginBottom: 2,
        paddingLeft: 24,
        paddingRight: 12,
    },
    title: {
        fontSize: 18,
        color: theme.palette.primary.contrastText,
        flex: 1,
    },
    editButton: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
    },
    editButtonIcon: {
        marginRight: theme.spacing(1),
    },
    toolbar: {
        height: 64,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 24,
        paddingRight: 24,
    },
    filterContainer: {
        flex: 1,
    },
    iconButton: {
        padding: 8,
        margin: 3,

        '& i': {
            fontSize: 18,
            width: 24,
            height: 24,
            lineHeight: '24px',

            color: Colors.gray,
        },

        '&:hover': {
            '& i': {
                color: Colors.blackLight,
            },
        },
    },
    activeIconButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',

        '& i': {
            color: Colors.black,
        },
    },
    viewContainer: {
        overflow: 'hidden',
        height: 600,
    },
}))

const ViewSwitcher = (props) => {
    const { style, rootNodeId, organizations, nodes, edges, editable, onEditButtonClick } = props
    const classes = useStyles(props)
    const views = {
        tree: (
            <TreeViewer
                rootNodeId={rootNodeId}
                edges={edges}
                nodes={nodes}
                organizations={organizations}
                onEditButtonClick={onEditButtonClick}
                editable={editable}
                showOrganizationNodeDropdown
            />
        ),
        list: (
            <ListViewer
                organizations={organizations}
                onEditButtonClick={onEditButtonClick}
                editable={editable}
            />
        ),
        map: (
            <MapViewer
                edges={edges}
                nodes={nodes}
                organizations={organizations}
                onEditButtonClick={onEditButtonClick}
                editable={editable}
            />
        ),
    }

    const [view, select, viewKey] = useSelect('tree', views)

    return (
        <Paper className={classes.root} style={style}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <FormattedMessage id={'supplyChain.viewSwitcher.title'} />
                </div>
                {
                    editable && (
                        <Button
                            variant={'contained'}
                            className={classes.editButton}
                            onClick={onEditButtonClick}
                        >
                            <i className={classNames('fas fa-project-diagram', classes.editButtonIcon)} />
                            <FormattedMessage id={'supplyChain.viewSwitcher.edit'} />
                        </Button>
                    )
                }
            </div>

            <div className={classes.toolbar}>
                <div className={classes.filterContainer} />
                <div>
                    <IconButton
                        classes={{
                            root: classNames(classes.iconButton, { [classes.activeIconButton]: viewKey === 'tree' }),
                        }}
                        onClick={select('tree')}
                    >
                        <i className={'fas fa-sitemap'} />
                    </IconButton>
                    <IconButton
                        classes={{
                            root: classNames(classes.iconButton, { [classes.activeIconButton]: viewKey === 'list' }),
                        }}
                        onClick={select('list')}
                    >
                        <i className={'fas fa-list'} />
                    </IconButton>
                    <IconButton
                        classes={{
                            root: classNames(classes.iconButton, { [classes.activeIconButton]: viewKey === 'map' }),
                        }}
                        onClick={select('map')}
                    >
                        <i className={'fas fa-map'} />
                    </IconButton>
                </div>
            </div>

            <Divider height={2} color={Colors.graySemiLight} />

            <div
                className={classes.viewContainer}
                style={viewKey === 'list' ? { overflow: 'auto' } : {}}
            >
                { view }
            </div>
        </Paper>
    )
}

ViewSwitcher.defaultProps = {
    organizations: [],
}

export default ViewSwitcher
