import {
    setAuthUser as _setAuthUser,
    setUserInfo as _setUserInfo,
    setCurrentUser,
    changeOrganization,
    resetState,
} from './appActions'
import { customClaims } from '../selectors/appSelector'
import Api from '../api'

/**
 * Dispatch the new userInfo object to the store
 *
 * If the organization id is not the same between
 * the previous value and the new one, also dispatch
 * changeOrganization event, to reset the different reducers.
 */
export const setUserInfo = (userInfo) => {
    return (dispatch, getState) => {
        const { userInfo: prevUserInfo } = getState().app
        dispatch(_setUserInfo(userInfo))

        if (userInfo && (!prevUserInfo ||
      prevUserInfo[customClaims.organizationId] !== userInfo[customClaims.organizationId])) {
            dispatch(changeOrganization())
        }
    }
}

export const setAuthUser = (authUser) => {
    return async (dispatch, getState) => {
        const { authUser: prevAuthUser } = getState().app
        dispatch(_setAuthUser(authUser))

        if (!prevAuthUser || (prevAuthUser.wfUserId !== authUser.wfUserId)) {
            //TODO fetch user with multi and store in the app reducer
            const result = await Api.fetchItem(authUser.wfUserId, 100, { childrenLoadDepth: -1, loadParents: false })
            dispatch(setCurrentUser(result.data))
        }
    }
}

export const resetAppState = () => {
    return async (dispatch) => {
        dispatch(resetState())
    }
}
