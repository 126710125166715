(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfAccessTagSelector', wfAccessTagSelector)

    wfAccessTagSelector.$inject = []
    function wfAccessTagSelector() {
        const directive = {
            require: '^^wfAccessTag',
            controller: wfAccessTagSelectorController,
            controllerAs: 'accessTagSelectorVm',
            bindToController: true,
            templateUrl: 'scripts/wf/accessTags/wfAccessTagSelectorSmall.directive.html',
            restrict: 'E',
            scope: {
                permission: '@',
                name: '<',
                accessTagVm: '<',
            },
        }

        return directive
    }

    wfAccessTagSelectorController.$inject = ['$scope']
    function wfAccessTagSelectorController($scope) {
        $scope.accessTagVm = this.accessTagVm
    }
})()
