import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import { ObjectType } from '@worldfavor/constants/enums'
import { identity } from '@worldfavor/utils/selectors'

export const customClaims = {
    worldfavorUserId: 'https://worldfavor.com/claim/userId',
    organizationId: 'https://worldfavor.com/claim/orgId',
    name: 'https://worldfavor.com/claim/name',
    culture: 'https://worldfavor.com/claim/culture',
    isAdmin: 'https://worldfavor.com/claim/isAdmin',
    role: 'https://worldfavor.com/claim/role',
}

const _userInfo = state => state.app.userInfo
const _authUser = state => state.app.authUser
const _enforcedCulture = state => state.app.enforcedCulture
const _currentUser = state => state.app.currentUser

export const getCulture = createSelector(
    [_userInfo, _enforcedCulture],
    (userInfo, enforcedCulture) => enforcedCulture || (userInfo && userInfo[customClaims.culture]) || 'sv-SE',
)

export const getCurrentUser = createSelector(
    [_currentUser],
    identity,
)

export const getAuthUserOrganizationWfid = createSelector(
    [_authUser],
    authUser => authUser.orgId ? `${ObjectType.organization}-${authUser.orgId}` : null,
)
