/*
	Possible parameters for html2pdfrocket 

	pdfParameters: {
		MarginLeft: 15,
		MarginRight: 15,
		MarginBottom: 15,
		MarginTop: 15,
		UseGrayscale: false,
		UseLandscape: false,
		EnableForms: false,
		LowQuality: false,
		ImageQuality: 94,
		DisableShrinking: false,
		DisableJavascript: false,
		JavascriptDelay: 0,
		UsePrintStylesheet: false,
		FooterSpacing: 0,
		HeaderSpacing: 0,
		PageSize: A4,
		PageWidth: if you use this, you must also use PageHeight
		PageHeight: if you use this, you must also use PageWidth
		ViewPort: e.g. 800x600 - Set if you have custom scrollbars or css attribute overflow to emulate window size
		Dpi: 96,
		Zoom: 1.00,
		OutputFormat: "PDF",
		FileName:
		Username: For URL conversions, creates a secure basic authentication connection to your server
		Password: For URL conversions, creates a secure basic authentication connection to your server
		Cookie: Supply in format: NAME|||VALUE|||NAME|||VALUE (that's 2 cookies).  Often used for authentication.

		HeaderHtml: To use an html header on each page - a string starting with <!DOCTYPE html>
		FooterHtml: To use an html footer on each page - a string starting with <!DOCTYPE html>
		HeaderUrl: To use an html header on each page - a url starting with http containing the html
		FooterUrl: To use an html footer on each page - a url starting with http containing the html
		HeaderLeft: Top left header text (can use replacement tags below)
		HeaderRight: Top right header text (can use replacement tags below)
		FooterLeft: Bottom left footer (can use replacement tags below)
		FooterRight: Bottom right footer (can use replacement tags below)
		FooterFontName: Arial
		HeaderFontName: Arial
		FooterFontSize: 12
		HeaderFontSize: 12
	}
*/

// ********** IMPORTANT INFORMATION AND LIMITATIONS WHEN EXPORTING HTML TO PDF *********

/*
	cssReferences array has the elements that will get replaced by the grunt task ( string-replace:vendor ) which is run every time the code gets deployed.
	These elements will be changed with the newly generated URLs as the following: 
		var cssReferences = [
			'[hash].vendor.css'
			'[hash].styles.css'
			'[hash].wf-styles.css'
		];

	tempCssReferences array is used only for development purposes, for easier and faster change of the urls. Here we use the latest, deployed URLs.

	Inside getCssCode() function, under output variable, there are some custom css rules defined that will override all the other rules that will be passed to the html2pdfrocket service.

	!!! LIMITATIONS !!!
	All of the PDFs that are generated will have Arial font-family (defined inside getCssCode() function) due to a support for Chinese symbols. Apart from the font-family,
	all of the PDFs will have Ionicons set for icons.
	
	Html2pdfrocket service that is used, does not support font fallbacks, therefore Chinese symbols are not recognized and are shown as squares. That is the reason for using Arial font-family.
	If FontAwesome or Glyphicon icons are used, then Chinese characters are not shown, that is the reason why we use Ionicons.

	If there are other icons that are used, it is suggested to define a set of rules inside wf-print-styles.less to replace those icons with similar ones from a set of Ionicons.

	*Note that we need some rules removed from ionicons.css

	Rules that are removed from @font-face in assets/fonts/ionicons/css/ionicons.css are:
			url("../fonts/ionicons.ttf?v=2.0.1") format("truetype"), 
			url("../fonts/ionicons.woff?v=2.0.1") format("woff")

	Chinese letters work only when we remove those 2 rules and when we use Arial as font-family.
*/

(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('pdfGenerator', pdfGenerator)

    pdfGenerator.$inject = ['$q', '$timeout', 'apiProxy', 'wfObject', 'modalService', '$translate']

    function pdfGenerator($q, $timeout, apiProxy, wfObject, modal, $translate) {
        const service = {
            fromElement,
            fromHtml,
        }

        //See above for explanatio
        const cssReferences = '@@PDF_GENERATOR_CSS_URLS'.split(',')

        const tempCssReferences = [
            'https://beta.worldfavor.com/assets/css/66ad2ed5.vendor.css',
            'https://beta.worldfavor.com/assets/css/6fca5925.styles.css',
            'https://beta.worldfavor.com/assets/css/135f0557.wf-styles.css',
            // "https://drive.google.com/uc?export=view&id=1Estfc2xpfJki7QatMYljsKRY830T3hVw"
        ]

        let origin = location.origin
        if (!origin) origin = location.protocol + '//' + location.hostname + (location.port && location.port.length ? ':' + location.port : '')
		
        return service
		
        ////////////////

        function fromHtml(html, title, networkId, options) {
            const jqDf = $.Deferred()
            getCssCode().then((cssCode) => {
                // var htmlWrapper = '<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head><body> ' + html  + ' </body></html>';

                // http://stackoverflow.com/questions/5653207/remove-html-comments-with-regex-in-javascript
                // var COMMENT_PSEUDO_COMMENT_OR_LT_BANG = new RegExp(
                // 	'<!--[\\s\\S]*?(?:-->)?'
                // 	+ '<!---+>?'  // A comment with no body
                // 	+ '|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?'
                // 	+ '|<[?][^>]*>?',  // A pseudo-comment
                // 	'g');
                html = html.replace(/<!--[\s\S]*?-->/g, '')

                if (!options) options = {}

                const params = {
                    cssReferences: getCssReferences(),
                    scriptReferences: [],
                    cssCode,
                    body: html,
                    title,
                    networkId,
                    pdfParameters: options.pdfParameters,
                }

                if (options && options.onlyDownload) {
                    params.onlyDownload = true
                    generatePdfDownloadBinary(params, jqDf, options.returnOnlyBlob)
                    return jqDf.promise()
                }

                // $.proxies.baseUrl = "http://localhost:4481/";
                // getCssReferences();
                // return;
                apiProxy('utility.generatePdf', params).then((res) => {
                    wfObject.inject(res)
                    jqDf.resolve(wfObject.get(res.wfid))
                }).catch((res) => {
                    jqDf.reject(res)
                })

            })

            return jqDf.promise()
        }

        function fromElement(element, title, networkId, options) {
            return fromHtml(element[0].outerHTML, title, networkId, options)
        }
		
        function getCssReferences() {
            let output = []

            if (origin && (origin.indexOf('localhost') !== -1 || origin.indexOf('local.worldfavor.com') !== -1)) return tempCssReferences
            else output = cssReferences
			
            // $('link[rel=stylesheet]').each(function () {
            // 	var href = $(this).attr('href');
				
            // 	if (!href) return; // Shouldn't happen

            // 	if (href.indexOf("http") === 0 || href.indexOf("//") === 0)
            // 		output.push(href);
            // 	else
            // 		output.push(origin + "/" + href);

            // });
            return output
        }
	
        function getCssCode() {
            const deferred = $q.defer()
            let lessCode = undefined
            let output = [
                '* { font-family: \'Arial\' !important }',
                'body { padding: 0 }',
                '.print-mode .hier-content { border-bottom: none !important; }',
                '.print-mode .fa { display: inline-block !important; }',
                '.print-mode .Hierarchical-Controller .guidance-btn { display: none !important; }',
                '.print-mode wf-hierarchical-item { border: none !important; }',
                '.influence-relatedContentByCreator { display: none }',
                '.HierarchicalController .hier-content { border-bottom: 1px solid #e6e6e6; page-break-inside: avoid; }',
                '.localFulfillment { visibility: hidden; }',
                '.fa { display:none }',
                '@font-face {\n'
				+ '    font-family: "Ionicons";\n'
				+ '    src: url("https://sandbox.worldfavor.com//assets/fonts/ionicons.eot?v=2.0.1");\n'
				+ '    src: url("https://sandbox.worldfavor.com//assets/fonts/ionicons.eot?v=2.0.1#iefix") format("embedded-opentype"), url("https://sandbox.worldfavor.com//assets/fonts/ionicons.svg?v=2.0.1#Ionicons") format("svg");\n'
				+ '    font-weight: normal;\n'
				+ '    font-style: normal;\n'
				+ '}',
            ].join(' ')

            if (origin && (origin.indexOf('localhost') !== -1 || origin.indexOf('local.worldfavor.com') !== -1)) {
                const fileManager = new less.FileManager()
                fileManager.loadFile('wf-print-styles.less', 'http://local.worldfavor.com/assets/less/', {}).then((loadedFile) => {
                    lessCode = loadedFile.contents
                    if (lessCode) {
                        //Converting less to css
                        less.render(lessCode, (e, result) => {
                            if (result && result.css) {
                                output += ' ' + result.css
                                deferred.resolve(output)
                            }
                            else {
                                console.log('Couldn\'t convert less to css', lessCode)
                                deferred.resolve('')
                            }
                        })
                    }
                    else {
                        console.log('Less code not defined')
                        deferred.reject(lessCode)
                    }
                }).catch((err) => {
                    console.log('Couldn\'t load wf-print-styles.less ', err)
                    deferred.reject(err)
                })
            }
            else {
                // executed when not in localhost
                deferred.resolve(output)
            }

            return deferred.promise
        }

        function generatePdfDownloadBinary(params, deferred, returnOnlyBlob) {
            const xhr = $.ajax({
                url: $.proxies.baseUrl + 'api/generatePdf',
                method: 'POST',
                dataType: 'binary',
                contentType: 'application/json',
                processData: false,
                data: JSON.stringify(params),
            })
            let ieInUse = false
            let blob
            let pdfGenerated = false

            const pdfLatePromise = $timeout(() => {
                if (!pdfGenerated) modal.alert($translate.instant('modules.export.pdf.errorMessages.contactSupportMessage'))
            }, 90000)

            xhr.then((res) => {
                let itemToResolve = undefined
                if (xhr.status === 200) {
                    let filename = params.title + '.pdf'
                    const disposition = xhr.getResponseHeader('Content-Disposition')
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        const matches = filenameRegex.exec(disposition)
                        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
                    }
                    const type = xhr.getResponseHeader('Content-Type')
			
                    if (typeof window.navigator.msSaveBlob !== 'undefined') ieInUse = true

                    if (ieInUse) {
                        blob = new Blob([res], { type })
                    }
                    else {
                        if (typeof File === 'function') blob = new File([res], filename, { type })
                        else console.error('Could not create Blob!')
                    }

                    if (returnOnlyBlob) itemToResolve = blob
                    else {
                        if (ieInUse) {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveOrOpenBlob(blob, filename)
                        }
                        else {
                            const URL = window.URL || window.webkitURL
                            const downloadUrl = URL.createObjectURL(blob)
	
                            if (filename) {
                                // use HTML5 a[download] attribute to specify filename
                                const a = document.createElement('a')
                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location = downloadUrl
                                }
                                else {
                                    a.href = downloadUrl
                                    a.download = filename
                                    document.body.appendChild(a)
                                    a.click()
                                }
                            }
                            else {
                                window.location = downloadUrl
                            }
                            setTimeout(() => { URL.revokeObjectURL(downloadUrl) }, 100) // cleanup
                        }
                    }

                    pdfGenerated = true
                    $timeout.cancel(pdfLatePromise)
                    deferred.resolve(itemToResolve)
                }
                else {
                    showErrorMessage()
                    deferred.reject(itemToResolve)
                }
            }, () => {
                showErrorMessage()
                deferred.reject()
            })

            function showErrorMessage() {
                if (pdfLatePromise) $timeout.cancel(pdfLatePromise)
                if (xhr) xhr.abort()

                pdfGenerated = false
                modal.alert($translate.instant('modules.export.pdf.errorMessages.couldNotDownloadFile'))
            }
        }
    }
})()
