import * as enums from '@worldfavor/constants/enums'
import { getSsoSpecificationFromEmail } from '@worldfavor/portal/scripts/wf/authentication/enterpriseLogin'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('wfAuth', wfAuth)

    wfAuth.$inject = ['DS', 'dataOperationsCache', '$rootScope', '$translate', '$q', 'lock', '$state', 'jwtHelper', '$timeout', '$window', '$ngBootbox'] // Removed wfObject because of circular reference
    function wfAuth(DS, dataOpsCache, $rootScope, $translate, $q, lock, $state, jwtHelper, $timeout, $window, $ngBootbox) {
        let
            currentOrganization

        let currentSolution

        let userInfo

        /*  userInfo is the the decoded id_token
					Example:
						at_hash: "20wGnBuJarOVXtjD7G4hvQ"
						aud: "OfxP4kGpndPic3A6veHNy6JYGQQdD1Py"
						email: "armin@astateoftrance.com"
						email_verified: true
						exp: 1521685236
						https://worldfavor.com/claim/culture: "sv-SE"
						https://worldfavor.com/claim/isAdmin: true - If user is admin
						https://worldfavor.com/claim/name: "Armin van Buuren"
						https://worldfavor.com/claim/orgId: -1 - Is > 0 if authenticated as an organization
						https://worldfavor.com/claim/userId: 3 - Worldfavor user ID
						https://worldfavor.com/claim/role: Superuser
						iat: 1521649236
						iss: "https://worldfavor.eu.auth0.com/"
						nonce: "5hSOYnF48gLcE6Fm2NDaap06ZNAy4ILT"
						sub: "auth0|138" - Auth0 user ID. From Auth0 docs: "user_id property is sent as sub in the ID Token" https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims
				*/

        let token

        let authUser

        let userPermissions

        const userPermissionsDefaults = {
            write: false, // Create, update, delete
        }

        let authenticationHeaderValue

        let enforcedCulture
        //

        const onAuthenticatedCallbacks = []

        let currentOrganizationSignInAttempt = null

        const publicAuthActive = false

        let tokenMismatchCheckerTimer

        let sessionCheckerTimer

        const userActivityEvents = ['load', 'mousedown', 'keypress', 'scroll', 'touchstart']

        let userIdleTimer

        let userIdleWarningTimer

        let activeTabTimer

        const userScope = 'openid email name wf_userId wf_orgId wf_orgName isAdmin culture'

        let idleTimeWarningModal

        let activeTabTimestamp = ''

        let tokenExpirationInSeconds = 0
        // Calculated from the token exp value

        const tokenAutoRenewalBeforeExpiration = 1
        // Auto renew token 1 minute before the token expires

        const inactivityWarningBeforeLogoutInMinutes = 5

        const autoLogoutAfterMinutes = 30

        const customClaims = {
            worldfavorUserId: 'https://worldfavor.com/claim/userId',
            organizationId: 'https://worldfavor.com/claim/orgId',
            name: 'https://worldfavor.com/claim/name',
            culture: 'https://worldfavor.com/claim/culture',
            isAdmin: 'https://worldfavor.com/claim/isAdmin',
            role: 'https://worldfavor.com/claim/role',
        }

        const defaultClaims = {
            auth0UserId: 'sub', // From Auth0 docs: "user_id property is sent as sub in the ID Token" https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims
            email: 'email',
        }

        const rootStructureIds = {
            13544: 'dashboard_everything',
            18453: 'insights',
            12756: 'report',
        }

        let xhrPool = []

        _.assign(this, {
            clear,
            init,
            initPublic,
            canUserWrite,
            signOut,
            signIn,
            signUp,
            gotoLoginPage,
            setAuthenticationHeaderValue,
            getAuthenticationHeaderValue,
            clearAuthenticationHeaderValue,
            resetAuthenticationHeaderValue,
            getOrganizationId,
            getWorldfavorUserId,
            getWorldfavorUserWfid,
            getAuth0UserId,
            setOrganization,
            getOrganization,
            setSolution,
            getSolution,
            getWorldfavorUser,
            getCulture,
            getUserRole,
            isAuthenticated,
            isAuthenticatedOrganization,
            forceCulture,
            onAuthenticated,
            getToken,
            setCurrentOrganizationSignInAttempt,
            assignAuthUserValues,
            handleAuthentication,
            triggerAuthEvent,
            getUserEmail,
            getUser,
            getRootStructureInfo,
            resetCulture,
            resetForcedCulture,
            isAdmin,
        })

        activate()

        function onCultureChange() {
            $rootScope.$broadcast('wfAuth.cultureChange', { enforcedCulture })
        }

        function onUserInfoChange() {
            $rootScope.$broadcast('wfAuth.userInfoChange', { userInfo })
        }

        function onTokenChange() {
            $rootScope.$broadcast('wfAuth.tokenChange', { token })
        }

        ////////////////

        function activate() {
            // From http://stackoverflow.com/a/10701856/4871222
            $(document).ajaxSend((e, jqXHR, options) => {
                xhrPool.push(jqXHR)
            })
            $(document).ajaxComplete((e, jqXHR, options) => {
                xhrPool = $.grep(xhrPool, (x) => { return x != jqXHR })
            })

            enforcedCulture = localStorage.getItem('culture')

            onCultureChange()

            userPermissions = _.clone(userPermissionsDefaults)

            $rootScope.$on('auth0.loginSuccess', (event, authContext, reauthenticated, options) => {
                const
                    maxRecursion = 50
                // 5 seconds

                let i = 0

                activeTabTimestamp = localStorage.getItem('activeTabRelay')
                addUserActivityEventHandlers()

                // Auth0 uses Local Storage to store the bearer token and the user profile object.
                // Sometimes values in Local Storage does not become instantly available after
                // being set so a recursive function is used to periodically check for the values
                // until they are there.
                ensureStoreValuesExist()

                function ensureStoreValuesExist() {

                    // Check that profile and token is defined and that currentOrganizationSignInAttempt matches current org ID.
                    if (userInfo && token && (!currentOrganizationSignInAttempt || currentOrganizationSignInAttempt === getOrganizationId())) {
                        // Call all callback functions that were added with the wfAuth.onAuthenticated function
                        // console.log("auth0.loginSuccess");
                        // console.log({ orgId: profile.wf_orgId, userId: profile.wf_userId });
                        _.each(onAuthenticatedCallbacks, (callback) => {
                            callback(event, authContext, reauthenticated, userInfo, token, options)
                        })
                        currentOrganizationSignInAttempt = null
                    }
                    else {
                        i++
                        if (i < maxRecursion) {
                            setTimeout(() => {
                                ensureStoreValuesExist()
                            }, 100)
                        }
                        else {
                            console.error('Auth0 values not found in Local Storage')

                            if (typeof window.Bugsnag !== 'undefined') Bugsnag.notify('Auth0 values not found in Local Storage')
                        }
                    }
                }
            })
        }

        function signOutDueToInactivity() {
            // console.log("Idle for too long. Clearing session and showing message...")
            clear()

            if (idleTimeWarningModal) {
                idleTimeWarningModal.modal('hide')
            }
            $ngBootbox.customDialog({
                title: $translate.instant('modules.auth.idleTimeLogout.title'),
                message: $translate.instant('modules.auth.idleTimeLogout.message'),
                onEscape: false,
                closeButton: false,
                buttons: {
                    primary: {
                        label: $translate.instant('modules.auth.idleTimeLogout.button'),
                        className: 'btn-primary',
                        callback() {
                            signOut()
                        },
                    },
                },
            })
        }

        function showIdleTimeWarning() {
            // console.log("Event listeners removed")
            userActivityEvents.forEach((name) => {
                document.removeEventListener(name, throttledResetInactivityAndActiveTabTimers, true)
            })
            idleTimeWarningModal = window.bootbox.dialog({
                className: 'wf-modal',
                title: $translate.instant('modules.auth.idleTimeWarning.title'),
                message: $translate.instant('modules.auth.idleTimeWarning.message'),
                onEscape: false,
                closeButton: false,
                buttons: {
                    success: {
                        label: $translate.instant('modules.auth.idleTimeWarning.button'),
                        className: 'btn-primary',
                        callback() {
                            addUserActivityEventHandlers()
                            setActiveTabValue()
                        },
                    },
                },
            })
        }

        function hideIdleTimeWarning() {
            if (idleTimeWarningModal) {
                idleTimeWarningModal.modal('hide')
                idleTimeWarningModal = undefined
            }
        }

        function storageEventListener() {
            const value = localStorage.getItem('activeTabRelay')
            if (value != activeTabTimestamp) {
                activeTabTimestamp = value
                // console.log("Active in another tab", localStorage.getItem("activeTabRelay"))
                resetInactivityTimers()

                hideIdleTimeWarning()
            }
        }

        function setActiveTabValue() {
            localStorage.setItem('activeTabRelay', new Date().getTime())
        }

        const throttledResetInactivityAndActiveTabTimers = _.throttle(resetInactivityAndActiveTabTimers, 4000)

        function resetInactivityTimers() {

            clearTimeout(userIdleTimer)
            userIdleTimer = setTimeout(signOutDueToInactivity, autoLogoutAfterMinutes * 60000)

            clearTimeout(userIdleWarningTimer)
            userIdleWarningTimer = setTimeout(showIdleTimeWarning, (autoLogoutAfterMinutes - inactivityWarningBeforeLogoutInMinutes) * 60000)
        }

        function resetInactivityAndActiveTabTimers() {
            resetInactivityTimers()

            clearTimeout(activeTabTimer)
            activeTabTimer = setTimeout(setActiveTabValue, 4000)

            // console.log("Timer cleared and restarted")
        }

        function addUserActivityEventHandlers() {
            removeUserActivityEventHandlers()
            // console.log("Start idle time checker")
            userActivityEvents.forEach((name) => {
                document.addEventListener(name, throttledResetInactivityAndActiveTabTimers, true)
            })

            window.addEventListener('storage', storageEventListener)
        }

        function removeUserActivityEventHandlers() {
            // console.log("Stopped idle time checker")

            userActivityEvents.forEach((name) => {
                document.removeEventListener(name, throttledResetInactivityAndActiveTabTimers, true)
            })
            clearTimeout(userIdleTimer)

            window.removeEventListener('storage', storageEventListener)
        }

        function stopAuthIntervalChecker() {
            if (tokenMismatchCheckerTimer) {
                clearInterval(tokenMismatchCheckerTimer)
            }

            if (sessionCheckerTimer) {
                clearInterval(sessionCheckerTimer)
            }
        }

        function startAuthIntervalChecker() {
            stopAuthIntervalChecker()

            const tokenMismatchCheckerDelay = 5000
            tokenMismatchCheckerTimer = setTimeout(() => {
                checkTokenMismatch()
            }, tokenMismatchCheckerDelay)

            const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
            if (expiresAt) {

                tokenExpirationInSeconds = Math.floor(expiresAt - (new Date().getTime() / 1000))
                // console.log(`Token expires in ${tokenExpirationInSeconds} seconds. It will be renewed in ${tokenExpirationInSeconds - (tokenAutoRenewalBeforeExpiration * 60)} seconds`)
                sessionCheckerTimer = setTimeout(() => {
                    // console.log("Renewing token now!")
                    checkSession()
                }, (tokenExpirationInSeconds - (tokenAutoRenewalBeforeExpiration * 60)) * 1000)
            }

            function checkSession() {
                lock.checkSession({
                    scope: userScope,
                    state: btoa(JSON.stringify({ signInAsOrgId: getOrganizationId() })), // This is decoded and parsed in an Auth0 rule
                }, (error, authResult) => {
                    if (!error && authResult && authResult.accessToken && authResult.idToken) {
                        // console.log("Got new token!")
                        $rootScope.isLoggedIn = true
                        _setSession(authResult)
                    }
                    else {
                        $ngBootbox.customDialog({
                            title: $translate.instant('modules.auth.signInErrorModal.title'),
                            message: $translate.instant('modules.auth.signInErrorModal.message'),
                            onEscape: false,
                            closeButton: false,
                            buttons: {
                                primary: {
                                    label: $translate.instant('modules.auth.signInErrorModal.button'),
                                    className: 'btn-primary',
                                    callback() {
                                        gotoLoginPage()
                                    },
                                },
                            },
                        })
                    }
                })
            }

            function checkTokenMismatch() {
                try {
                    const
                        localStorageToken = localStorage.getItem('id_token')

                    const decodedToken = localStorageToken ? jwtHelper.decodeToken(localStorageToken) : undefined

                    let tokenWfUserId = getWorldfavorUserId(decodedToken)

                    let tokenOrgId = getOrganizationId(decodedToken)

                    let handled = true

                    let gotoPath

                    let message

                    if (decodedToken) {
                        tokenWfUserId = getWorldfavorUserId(decodedToken)
                        tokenOrgId = getOrganizationId(decodedToken)
                    }

                    // User logged out - go to login page
                    if (!decodedToken) {
                        message = 'Token in localStorage changed: No token - going to login page'
                        gotoPath = '/login'
                    }
                    // Same user is authenticated but now no organization - go to 'Change organization' page
                    else if (tokenWfUserId === authUser.wfUserId && !tokenOrgId && authUser.orgId) {
                        message = 'Token in localStorage changed: Same user but now no org - going to \'Change org\' page'
                        gotoPath = '/account/organizations'
                    }
                    // Same user is authenticated but different organization - go to dashboard
                    else if (tokenWfUserId === authUser.wfUserId && tokenOrgId && tokenOrgId !== authUser.orgId) {
                        message = 'Token in localStorage changed: Same user but different org - going to Dashboard'
                        gotoPath = '/'
                    }
                    // Same user is authenticated and same organization - do nothing
                    else if (tokenWfUserId === authUser.wfUserId && tokenOrgId === authUser.orgId) {
                        handled = false
                    }
                    // Another user is authenticated - go to 'Change organization' page
                    else if (tokenWfUserId === authUser.wfUserId && getOrganizationId(decodedToken) !== authUser.orgId) {
                        message = 'Token in localStorage changed: Different user - going to \'Change org\' page'
                        gotoPath = '/account/organizations'
                    }
                    else {
                        handled = false
                    }

                    if (handled && !_.get(window, 'wf.skipAuthCheck')) {
                        if (gotoPath) {
                            console.warn(message)
                            $window.location.href = gotoPath
                        }

                    }
                    else {
                        tokenMismatchCheckerTimer = setTimeout(() => {
                            checkTokenMismatch()
                        }, tokenMismatchCheckerDelay)
                    }
                }
                catch (e) {
                    console.warn('Error during auth interval check', e)
                }
            }
        }

        function setRootScopeUser() {
            localStorage.setItem('user_domain', _.last((getUserEmail() || '').split('@')))
            authUser = {
                isAdmin: isAdmin(),
                orgId: getOrganizationId(),
                isOrganization: getOrganizationId() > 0,
                wfUserId: getWorldfavorUserId(),
                auth0UserId: getAuth0UserId(),
                role: getUserRole(),
            }
            assignAuthUserValues(authUser)
            if (location.pathname.includes('/file')) return;
            $rootScope.$broadcast('wfAuth.setAuthUser', { authUser })
        }

        function handleAuthentication() {
            // if (!jwtHelper.isTokenExpired(token)) {
            // 	$rootScope.isLoggedIn = true;
            // }
            // else {
            // 	$rootScope.isLoggedIn = false;
            // }

            lock.on('authenticated', (authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    _setSession(authResult)
                    // console.log('Authenticated!', authResult);
                    lock.hide()
                    $rootScope.$broadcast('auth0.loginSuccess', null, false)
                }
            })

            lock.on('authorization_error', (err) => {
                if (typeof window.Bugsnag !== 'undefined' && _.get(err, 'code') !== 'invalid_user_password') {
                    Bugsnag.notify('Auth0 Lock authorization_error', _.get(err, 'errorDescription') || '', {
                        Auth0: {
                            error: err,
                            latestLoginAttemptEmail: localStorage.getItem('latestEmailCredentialInfo'),
                        },
                    })
                }
                $rootScope.$broadcast('auth0.loginError', err)
                // alert(
                // 'Error: ' + err.error + '. Check the console for further details.'
                // );
            })

            lock.on('show', (err) => {
                let extraSignupElement; let lockHeaderElement; let maxRecursion = 10

                if ($state.current.name === 'signup') {
                    setTimeout(() => {
                        setEmailInputPlaceholder()
                    }, 1000)
                    extraSignupElement = $('<div class=\'wf-auth0-signup-loginBar\' />').text('Already have an account? ')

                    extraSignupElement.append($('<a />').text('Sign in here').attr('href', '/login').click((e) => {
                        e.preventDefault()
                        location.href = '/login'
                    })).append('.')

                    lockHeaderElement = $('div.auth0-lock-header')
                    if (lockHeaderElement.length) {
                        lockHeaderElement.height(lockHeaderElement.height() + 35)
                        lockHeaderElement.css({ paddingBottom: 35 }).prepend(extraSignupElement)
                    }
                }

                function setEmailInputPlaceholder() {
                    const element = $('input.auth0-lock-input[name=email]')

                    maxRecursion--

                    if (!maxRecursion) return

                    if (element.length) element.attr('placeholder', 'your work email')
                    else {
                        setTimeout(() => {
                            setEmailInputPlaceholder()
                        }, 300)
                    }
                }
            })

            lock.on('signin ready', () => {
                const emailInput = $('input.auth0-lock-input[name=email]')
                setTimeout(() => {
                    localStorage.removeItem('latestEmailCredential')
                    // Wait 300 ms and then check if email is an sso domain.
                    // The waiting time should be enough time to let the browser prefill any saved credentials.
                    // If the prefilled email has an sso domain then the username and password input are emptied
                    // because sso credentials should not prefilled by the browser.

                    const passwordInput = $('input.auth0-lock-input[name=password]')
                    const val = emailInput.val()
                    const ssoInfo = getSsoSpecificationFromEmail(val)

                    const values = [val]
                    emailInput.on('click.prefilled focus.prefilled keyup.prefilled', (event) => {
                        const val = emailInput.val()
                        //console.log(event.type)
                        values.push(val)
                        if (values[0] === values[values.length - 1]) {
                            //console.log("same", values)
                            //e.off("click.prefilled focus.prefilled keyup.prefilled")
                        }
                        if (val !== '' && values[0].length === 0 && val.length > 9 && values.length < 3) {
                            //console.log("not empty", values)

                            const isSso = getSsoSpecificationFromEmail(val)
                            if (isSso && values[0] === '') {
                                //console.log("sso", values)
                                emailInput.off('click.prefilled focus.prefilled keyup.prefilled')
                                emailInput.val('')
                                passwordInput.val('')
                            }
                        }
                    })

                    emailInput.on('change, keyup', _.throttle(() => {
                        const val = emailInput.val()
                        const ssoInfo = getSsoSpecificationFromEmail(val)
                        if (ssoInfo) {
                            let locationUrl = ssoInfo.loginUrl

                            if ($state.current.name === 'login' && $state.params.redirect) {
                                locationUrl += '?redirect=' + $state.params.redirect
                            }
                            document.location = locationUrl
                        }
                    }, 100))
                    emailInput.change()
                }, 1000)

            })

            lock.on('signin submit', () => {
                const emailInput = $('input.auth0-lock-input[name=email]')
                localStorage.setItem('latestEmailCredentialInfo', 'Email input: ' + emailInput.val())
            })

            lock.on('show', () => {
                localStorage.removeItem('latestEmailCredentialInfo')
                setTimeout(() => {
                    const ssoButton = $('div.auth0-lock-social-button-text')
                    localStorage.setItem('latestEmailCredentialInfo', 'SSO button: ' + ssoButton.text())
                }, 1000)
            })
        }

        function _setSession(authResult) {
            token = authResult.idToken
            userInfo = jwtHelper.decodeToken(token)
            onUserInfoChange()
            onCultureChange()
            onTokenChange()
            // Set the time that the id_token will expire
            // userInfo.exp is the correct value.
            // The value at authResult.expiresAt is always 7200 and does not represent the Client JWT Expiration setting in the Auth0 management portal.
            const expiresAt = JSON.stringify(
                userInfo.exp,
            )
            //expiresAt = Math.floor((new Date().getTime() / 1000) + 30)
            // Save tokens and expiration to localStorage
            localStorage.setItem('access_token', authResult.accessToken)
            localStorage.setItem('id_token', authResult.idToken)
            localStorage.setItem('expires_at', expiresAt)

            resetCulture()

            setAuthenticationHeaderValue('Bearer ' + token)
            setRootScopeUser()
            startAuthIntervalChecker()

            initLegacyPermissions()
        }

        function isAuthenticated() {
            let expiresAt
            // Check whether the current time is
            // past the Access Token's expiry time
            if (localStorage.getItem('access_token') && localStorage.getItem('id_token') && (expiresAt = localStorage.getItem('expires_at'))) {
                try {
                    const decodedToken = jwtHelper.decodeToken(localStorage.getItem('id_token'))
                    expiresAt = JSON.parse(decodedToken.exp)
                    return (new Date().getTime() / 1000) < (expiresAt - 60)
                }
                catch {
                    return false
                }
            }
        }

        function triggerAuthEvent() {
            let authWasTriggered = false

            if (isAuthenticated()) {
                if (!token) {
                    authWasTriggered = true
                    $rootScope.isLoggedIn = true

                    token = localStorage.getItem('id_token')
                    userInfo = jwtHelper.decodeToken(token)
                    onUserInfoChange()
                    onCultureChange()
                    onTokenChange()

                    setAuthenticationHeaderValue('Bearer ' + token)

                    setRootScopeUser()
                    initLegacyPermissions()

                    startAuthIntervalChecker()

                    setTimeout((params) => {
                        //setTimeout is used so that listeners for "auth0.loginSuccess" have time to be set.
                        $rootScope.$broadcast('auth0.loginSuccess', null, true)
                    }, 500)
                }
            }
            else {
                $rootScope.isLoggedIn = false
            }

            return authWasTriggered
        }

        function initLegacyPermissions() {
            let viewModeValue
            // TODO: Check if below logic is needed after migration to Lock v11 because profile.orgAccess is not returned by default
            try {
                // 	viewModeValue = auth.profile.orgAccess[wfAuth.getOrganizationId()].viewMode;

                if (typeof viewModeValue === 'undefined') viewModeValue = false
            }
            catch (e) {
                if (!userInfo) viewModeValue = true
                else viewModeValue = false
            }
            // console.log("initing, viewMode ", viewModeValue)

            if (viewModeValue != null) {
                userPermissions.write = viewModeValue != true
            }
        }

        function setCurrentOrganizationSignInAttempt(organizationId) {
            currentOrganizationSignInAttempt = organizationId
        }

        function clear() {
            stopAuthIntervalChecker()
            removeUserActivityEventHandlers()
            resetCulture()
            abortOngoingAjaxRequests()
            clearAuthenticationHeaderValue()
            userInfo = undefined
            token = undefined

            onUserInfoChange()
            onCultureChange()
            onTokenChange()

            userPermissions = _.clone(userPermissionsDefaults)
            currentOrganizationSignInAttempt = null
            $rootScope.authUser = undefined

            // Remove tokens and expiry time from localStorage
            localStorage.removeItem('access_token')
            localStorage.removeItem('id_token')
            localStorage.removeItem('expires_at')
            localStorage.removeItem('user_domain')

            // Clear all data in storage (angular-storage, used by Auth0)
            currentOrganization = undefined

            DS.clear() // Clear all cached data in JSData
            dataOpsCache.clearCachedRequests()
            $rootScope.isLoggedIn = false // Set to false so that UI updates

            currentSolution = undefined
        }

        function init() {
            const viewModeValue = undefined

            clear()
        }

        function initPublic() {
            clear()
            // console.log("init public");
            userPermissions.write = false

            // publicAuthActive = true;
            $rootScope.isLoggedIn = true
        }

        function canUserWrite() {
            // return false;
            return userPermissions && userPermissions.write
        }

        function signIn(options) {
            return $q((resolve, reject) => {
                if (options && options.organizationId) {
                    setCurrentOrganizationSignInAttempt(options.organizationId)

                    $rootScope.loginInProgressForOrgId = options.organizationId
                    $timeout()

                    stopAuthIntervalChecker()

                    lock.checkSession({
                        scope: 'openid email name wf_userId wf_orgId wf_orgName isAdmin culture',
                        state: btoa(JSON.stringify({ signInAsOrgId: options.organizationId })), // This is decoded and parsed in an Auth0 rule
                        // Arbitrary props can not be put here
                    }, (error, authResult) => {
                        setTimeout(() => {
                            if (!error && authResult && authResult.accessToken && authResult.idToken) {
                                clear()
                                $rootScope.isLoggedIn = true
                                _setSession(authResult)
                                // console.log('Authenticated as org!', authResult);
                                lock.hide()
                                $rootScope.$broadcast('auth0.loginSuccess', null, false, options)
                                resolve()
                            }
                            else {
                                $ngBootbox.customDialog({
                                    title: $translate.instant('modules.auth.signInErrorModal.title'),
                                    message: $translate.instant('modules.auth.signInErrorModal.message'),
                                    onEscape: false,
                                    closeButton: false,
                                    buttons: {
                                        primary: {
                                            label: $translate.instant('modules.auth.signInErrorModal.button'),
                                            className: 'btn-primary',
                                            callback() {
                                                gotoLoginPage()
                                            },
                                        },
                                    },
                                })
                                reject()
                            }

                            // To not make the singin button in template 17 flash before redirection occurs
                            setTimeout(() => {
                                $rootScope.loginInProgressForOrgId = undefined
                            }, 500)
                        }, 200)
                    })
                }
                else {
                    clear()
                    lock.show(_.get(options, 'lockOptions'))
                }
            })
        }

        function signUp() {
            clear()
            lock.show({
                allowLogin: false,
                allowSignUp: true,
                allowForgotPassword: false,
            })
        }

        function gotoLoginPage() {
            document.location = $state.href(localStorage.getItem('login_state') || 'login')
        }

        function signOut(redirect) {
            clear()

            if (redirect !== false) gotoLoginPage()
            else {
                $rootScope.$broadcast('auth0.logout')
            }
        }

        function abortOngoingAjaxRequests() {
            $.each(xhrPool, (idx, jqXHR) => {
                jqXHR.abort()
            })
        }

        function setAuthenticationHeaderValue(value) {
            authenticationHeaderValue = value
        }

        function getAuthenticationHeaderValue() {
            return authenticationHeaderValue
        }

        function clearAuthenticationHeaderValue() {
            authenticationHeaderValue = undefined
        }

        function resetAuthenticationHeaderValue() {
            const token = localStorage.getItem('id_token')

            if (token) authenticationHeaderValue = 'Bearer ' + token
            else authenticationHeaderValue = undefined

            return authenticationHeaderValue
        }

        function setOrganization(org) {
            currentOrganization = org
        }

        function getOrganizationId(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj && obj[customClaims.organizationId] > 0) return obj[customClaims.organizationId]
        }

        function getOrganization() {
            return currentOrganization || {}
        }

        function isAuthenticatedOrganization() {
            return typeof getOrganizationId() === 'number'
        }

        function setSolution(solution) {
            currentSolution = solution
        }

        function getSolution() {
            return currentSolution
        }

        function getWorldfavorUserId(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj && obj[customClaims.worldfavorUserId] > 0) return obj[customClaims.worldfavorUserId]
        }

        function getWorldfavorUserWfid() {
            const wfUserId = getWorldfavorUserId()
            if (wfUserId) {
                return '100-' + wfUserId
            }
        }

        function getAuth0UserId(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj) return obj[defaultClaims.auth0UserId]
        }

        function getUserEmail(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj) return obj[defaultClaims.email]
        }

        function isAdmin(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj) return !!obj[customClaims.isAdmin]
        }

        function getUserRole(decodedToken) {
            const obj = decodedToken || userInfo
            if (obj) return obj[customClaims.role]
        }

        function getWorldfavorUser() {
            return wfObject.get('100-' + getWorldfavorUserId())
        }

        function getCulture(decodedToken) {
            const obj = decodedToken || userInfo
            if (enforcedCulture) return enforcedCulture
            else if (obj && obj[customClaims.culture]) return obj[customClaims.culture]
            else return 'sv-SE'
        }

        function forceCulture(culture, useLocalStorage) {
            // When culture is changed in account settings it does not change the token that has the culture claim
            // so the new culture value is stored in localStorage and then that value is used until the user gets reauthenticated.

            switch (culture) {
                case 'sv-SE':
                default:
                    enforcedCulture = 'sv-SE'
                    moment.locale('sv')
                    numeral.locale('sv')
                    break
                case 'en-US':
                    moment.locale('en')
                    numeral.locale('en')
                    break
            }

            enforcedCulture = window.__wf_currentCulture = culture
            onCultureChange()
            $translate.use(culture)
            if (useLocalStorage !== false) localStorage.setItem('culture', culture)
        }

        function resetCulture() {
            localStorage.removeItem('culture')
            enforcedCulture = undefined
            onCultureChange()
            window.__wf_currentCulture = undefined

            const culture = getCulture()

            switch (culture) {
                case 'sv-SE':
                    moment.locale('sv')
                    numeral.locale('sv')
                    break
                case 'en-US':
                    moment.locale('en')
                    numeral.locale('en')
                    break
            }

        }

        function resetForcedCulture() {
            enforcedCulture = undefined
            onCultureChange()
            window.__wf_currentCulture = undefined
            const culture = getCulture()

            switch (culture) {
                case 'sv-SE':
                    moment.locale('sv')
                    numeral.locale('sv')
                    break
                case 'en-US':
                    moment.locale('en')
                    numeral.locale('en')
                    break
            }

            $translate.use(culture)
        }

        function onAuthenticated(callback) {
            onAuthenticatedCallbacks.push(callback)
        }

        function getToken() {
            return token
        }

        function assignAuthUserValues(obj) {
            if (!$rootScope.authUser) {
                $rootScope.authUser = {}
            }
            _.assign($rootScope.authUser, obj)
            if (authUser !== obj) {
                _.assign(authUser, obj)
            }
            $timeout()
        }

        function getUser() {
            return authUser
        }

        function getRootStructureInfo() {
            const rootStructureId = _.get(wfObject.filter({ where: { type: enums.objectType.dataRelation, wffid: '71-10010' } })[0], 'childId')

            return {
                id: rootStructureId,
                name: rootStructureIds[rootStructureId],
                content: wfObject.get('71-' + rootStructureId),
            }
        }
    }
})()
