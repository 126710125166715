import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfManageScopeDashboard', {
            templateUrl: 'scripts/wf/manage/wfManageScopeDashboard.component.html',
            controller: wfManageScopeDashboardController,
            controllerAs: 'vm',
            bindings: {
                hierarchyStructureId: '<',
                categoryStructureId: '<',
                permissionsStructureId: '<',
                sourceNetworkId: '<', // Which networkId to load data from. Must be included in the children of sourcesStructureId
                viewsStructureId: '<', // Structure that contain a list of available views
                sourcesStructureId: '<', // Structure that contain a list of available data source
                configFromAttribute: '<config',
                influenceId: '<',
                influence: '<',
            },
        })

    wfManageScopeDashboardController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'DataNegotiator', '$uibModal', 'dataQuery', 'modalService', 'dropdownUtility', 'wfAuth', '$sanitize', 'TheNewLoaderFactory', 'valueChainService', 'wfTranslate', 'apiProxy']
    function wfManageScopeDashboardController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, DataNegotiator, $uibModal, dataQuery, modal, dropdownUtility, wfAuth, $sanitize, TheNewLoaderFactory, valueChainService, wfTranslate, apiProxy) {
        const
            vm = this

        const authOrgId = wfAuth.getOrganizationId()

        const availableViewsStructureId = vm.viewsStructureId

        const availableDataSourcesStructureId = vm.sourcesStructureId || valueChainService.ids.networksStructure //19645

        // Functions
        initMainLoader, openViewCreator, syncAvailableViews, syncAvailableDataSources

        _.assign(vm, {
            showScopeSelectionAndFilters: true,
            preselectedSource: undefined, // If preselectedSource = "ownData", then the sourceNetworkId will not be used on initial loading but must still be in the list of available sources
            ticket: { organizationId: authOrgId },
            openViewCreator,
            allDataSourceActions: [],
            showDataSourceSelector: true,
            showViewSelector: true,
            showViewAdder: true,
            showScopeAdder: true,
            receivingOrganizationsAndLimitedDataAccessFromInfluenceId: null,
            isDataCollectionDashboardViewAllowed: true, //: authOrgId === 102389 || $rootScope.adminMode,
            openGriDisclaimerDialog,
        })

        vm.$onInit = function() {
            let promise; const promises = []
            // wfAuth.forceCulture("en-US", false);

            // $scope.$on("$destroy", function () {
            // 	wfAuth.resetForcedCulture();
            // });

            if (vm.configFromAttribute) {
                _.assign(vm, vm.configFromAttribute)
            }


            // if (vm.hierarchyStructureId > 0)
            // 	initMainLoader();

            // vm.dropdownSwitchActions = [
            // 	{ text: 'GRI Standards', icon: 'fa fa-circle-o-notch', action: function () {
            // 	  $state.go("manageScopeDashboard", { hierarchyStructureId: 10517 })
            // 	} },
            // 	{ text: 'UN Sustainable Development Goals', icon: 'fa fa-cube', action: function () {
            // 	  $state.go("manageScopeDashboard", { hierarchyStructureId: 387 })
            // 	} }
            //   ]

            // var customFramework = wfObject.get("71-16113");
            // if (customFramework) {
            // 	vm.dropdownSwitchActions.push({ text: customFramework.title, icon: 'fa fa-circle', action: function () {
            // 		$state.go("manageScopeDashboard", { hierarchyStructureId: 16113 })
            // 	} })
            // }

            if (vm.influenceId || vm.influence) {
                vm.showViewSelector = false
                const onInfluenceLoaded = function (influence) {
                    vm.influence = influence
                    vm.primaryItemDepth = 0
                    vm.primaryItemDeterminator = function (itemComposite) {
                        if (itemComposite.type === enums.objectType.structure && !dataQuery.hasItemAnySettingForAttachingData(itemComposite)) {
                            return true
                        }
                    }
                    $rootScope.setPageTitle(influence.title)
                    vm.categoryStructureId = undefined
                    vm.hierarchyStructureId = influence.objectId
                    vm.ticket = {
                        networkId: influence.channelId,
                        organizationId: influence.thirdPartyTargetOrganizationId || influence.organizationId,
                        thirdPartyOrganizationId: influence.thirdPartyTargetOrganizationId ? influence.organizationId : undefined,
                        contextParentWfid: influence.contextParentWfids,
                        contextParents: influence.contextParentWfids && [influence.contextParentWfids],
                        contextParentWfids: influence.contextParentWfids && [influence.contextParentWfids],
                    }
                    if (influence.thirdPartyTargetOrganizationId) {
                        vm.ticket.thirdParty = {
                            influenceId: influence.id,
                        }
                    }
                    vm.showScopeAdder = false
                    vm.showDataSourceSelector = false
                    vm.loadedFromInfluence = true
                    vm.allContentPreloaded = true
                }

                if (vm.influence) {
                    onInfluenceLoaded(vm.influence)
                }
                else {
                    promise = dataOps.getObject({
                        objectType: enums.objectType.influence,
                        objectId: vm.influenceId,
                        getterConditions: {
                            skipContentLoad: true,
                        },
                    }).then(onInfluenceLoaded)

                    promises.push(promise)
                }
            }

            if (vm.showViewSelector) {
                promises.push(syncAvailableViews())
            }

            $q.all(promises).then(() => {
                initMainLoader()
                $timeout()
            })
        }

        vm.$onChanges = function(changesObj) { }
        vm.$onDestroy = function() {
            dataOps.clearPersistentNegotiators()
            valueChainService.clearCachedNetworkCategoriesInfo()
        }

        function initMainLoader() {
            const promises = []

            if (vm.selectedView && vm.selectedView.isDataCollectionDashboardView) {
                $rootScope.setPageTitle('Overview')
                apiProxy.raw('valuechain.getDataCollectorStatistics', {
                    networkId: vm.sourceNetworkId,
                }).then((res) => {
                    vm.loaded = true
                    vm.dataCollectorDashboardData = res
                    $timeout()
                })
                return
            }

            let canModify = true

            if (vm.permissionsStructureId) {
                const permissionsStructure = wfObject.get(`71-${vm.permissionsStructureId}`)
                const permissions = _.get(permissionsStructure, 'permissions')
                if (permissions) {
                    if (!permissions.canUpdate || !permissions.canCreate || !permissions.canDelete) {
                        vm.showScopeAdder = false
                        vm.showViewAdder = false
                    }
                }
            }
            if (!vm.showScopeAdder && !vm.showViewAdder) {
                canModify = false
            }

            vm.loaderConfig = {
                uiMode: vm.uiMode,
                // primaryItemDepth: vm.primaryItemDepth,
                primaryItemDeterminator: vm.primaryItemDeterminator,
                categoryStructureId: vm.categoryStructureId,
                hierarchyStructureId: vm.hierarchyStructureId,
                enableSideActions: canModify,
                ticket: vm.ticket,
                loadUserDataOnInit: true, //vm.sourceNetworkId ? false : true
                allContentPreloaded: vm.allContentPreloaded,
                // showItemNumbering: !!vm.influence,
                includePrimaryItemsInFiltering: !!vm.influence || true,
                treatNestedPrimaryItemsAsSubItems: !vm.influence,
                influence: vm.influence,
            }

            vm.loader = TheNewLoaderFactory.instantiate(vm.loaderConfig)
            vm.loader.onLoaded(() => {
                const
                    settingsToCheck = [
                        'showDataSourceSelector',
                        'showInternalNetworkDataInSourceSelector',
                        'preselectedDataSourceFilter',
                    ]

                vm['showInternalNetworkDataInSourceSelector'] = true

                if (!vm.loadedFromInfluence) {
                    _.each(settingsToCheck, (settingKey) => {
                        const settingValue =  _.get(vm.loader.hierarchyStructure, 'conditions.uiSettings.scopeDashboardConfig.' + settingKey)
                        if (typeof settingValue !== 'undefined') {
                            vm[settingKey] = settingValue
                        }
                    })

                    $rootScope.setPageTitle(vm.loader.hierarchyStructure.title)
                }

                if (vm.loadedFromInfluence) {
                    vm.loader.loadedFromInfluence = true
                }

                if (vm.showDataSourceSelector || vm.sourceNetworkId) promises.push(syncAvailableDataSources())


                $q.all(promises).then(() => {
                    vm.loaded = true
                    syncRelativeMeasureResultsCacheUI()
                })
            })
        }

        function openViewCreator() {
            // modal.createWithRelation({
            // 	simplifyForm: true,
            // 	objectType: enums.objectType.structure,
            // 	dataRelationOptions: { item1: enums.objectType.structure + "-" + availableViewsStructureId, kind: enums.subItemsKind.childrenByUser },
            // 	additionalDataRelations: [{ item1: enums.objectType.structure + "-" + enums.ids.common.customFrameworks, kind: enums.subItemsKind.childrenByUser }],
            // }).then(function (dataRelation, additionalDataRelations) {
            // 	// When modal is closed and not submitted, the dataRelation argument is will be false
            // });
            let
                promise

            const sourceLists = []

            const sourceListIds = []

            if (wfAuth.isAdmin()) {
                const solutionDashboardCategories = {
                    'Sustainability Management': [
                        enums.ids.common.standardDashboardsPicker_Environmental,
                        enums.ids.common.standardDashboardsPicker_Social,
                        enums.ids.common.standardDashboardsPicker_Governance,
                        enums.ids.common.standardDashboardsPicker_Investments,
                        enums.ids.common.standardDashboardsPicker_ESRS,
                    ],
                    'Sustainable Investments': [
                        enums.ids.common.standardDashboardsPicker_Environmental,
                        enums.ids.common.standardDashboardsPicker_Social,
                        enums.ids.common.standardDashboardsPicker_Governance,
                        enums.ids.common.standardDashboardsPicker_Investments,
                    ],
                    'Sustainable Sourcing': [
                        enums.ids.common.standardDashboardsPicker_Sourcing,
                    ],
                }[wfAuth.getSolution()]

                if (solutionDashboardCategories) {
                    Array.prototype.push.apply(sourceListIds, solutionDashboardCategories)
                }
            }

            Array.prototype.push.apply(sourceListIds, [
                enums.ids.common.customFrameworks,
                enums.ids.common.isoStandards,
                enums.ids.common.guidelines,
            ])

            _.each(sourceListIds, (structureId) => {
                sourceLists.push({
                    items() {
                        const self = this
                        return $q((resolve, reject) => {
                            dataOps.getObject({
                                objectType: enums.objectType.structure,
                                objectId: structureId,
                            }).then((res) => {
                                if (res && res.childs.length) {
                                    self.title = res.title
                                    resolve(dataQuery.makeItemComposites(res.childs))
                                }
                                else {
                                    resolve([])
                                }
                            }, () => {
                                resolve([])
                            })
                        })
                    },
                })
            })

            promise = modal.openCreatorAndPicker({
                title: $translate.instant('modules.manageScopeDashboard.views.adderModal.header'),
                descriptionHtml: $translate.instant('modules.manageScopeDashboard.views.adderModal.description'),
                relationTarget: { item: enums.objectType.structure + '-' + availableViewsStructureId, kind: enums.subItemsKind.childrenByUser },
                sourceLists,
            })

            promise.modal.closed.then(() => {
                syncAvailableViews()
            })
        }

        function syncAvailableDataSources() {
            return $q((resolve, reject) => {
                let promise

                promise = dataOps.getSubItems(
                    { type: enums.objectType.structure, id: availableDataSourcesStructureId },
                    enums.subItemsKind.children,
                    { skipExtras: true, getterConditions: { dataRelationOrganizationMatchMode: 2 } },
                )

                promise.then((res) => {
                    let preselectedNetwork; let preselectedDataSourceAction

                    vm.dataSourceDropdownActions = _.map(res, (relation) => {
                        let output; let targetContextParentTypes; let subAction

                        output = {
                            text: relation.childContent.title,
                            networkId: relation.childId,
                            objectId: relation.childId,
                            objectType: relation.childType,
                            isNetwork: relation.childType === enums.objectType.network,
                            image: relation.childContent.imageUrl,
                            action: onDropdownItemClickAction,
                            content: relation.childContent,
                        }
                        vm.allDataSourceActions.push(output)

                        if (!output.subActions) output.subActions = []

                        if (output.isNetwork) {
                            targetContextParentTypes = _.get(relation.childContent, 'settings.valueChainSettings.targetContextParentTypes')

                            if (targetContextParentTypes && targetContextParentTypes.length) {
                                output.subActions = _.map(targetContextParentTypes, (objectType) => {
                                    subAction = {
                                        contextParentType: objectType,
                                        parent: output,
                                        networkId: relation.childId,
                                        isNetworkSubSource: true,
                                        text: wfTranslate.instant('MAP_ObjectType', { type: objectType, plural: true }),
                                        subText: objectType === enums.objectType.organization && [wfTranslate.instant('SubSuppliers'), wfTranslate.instant('Or').toLowerCase(), wfTranslate.instant('PortfolioCompanies')].join(' '),
                                        icon: 'fa fa-sitemap',
                                        action: onDropdownItemClickAction,
                                    }
                                    vm.allDataSourceActions.push(subAction)

                                    return subAction
                                })
                            }

                            if (vm.showInternalNetworkDataInSourceSelector) {
                                subAction = {
                                    contextParentType: enums.objectType.organization,
                                    parent: output,
                                    networkId: relation.childId,
                                    isInternalNetworkData: true,
                                    isNetworkSubSource: true,
                                    text: $translate.instant('InternalData'),
                                    icon: 'fa fa-database',
                                    action: onDropdownItemClickAction,
                                }

                                vm.allDataSourceActions.push(subAction)
                                output.subActions.push(subAction)
                            }

                            let reportedDataSubAction = {
                                isNetwork: true,
                                isReportedNetworkData: true,
                                contextParentType: enums.objectType.organization,
                                parent: output,
                                networkId: vm.sourceNetworkId ? vm.sourceNetworkId : relation.childId,
                                isInternalNetworkData: false,
                                text: $translate.instant('ReportedData'),
                                icon: 'fa fa-database',
                                action: onDropdownItemClickAction,
                            }

                            vm.allDataSourceActions.unshift(reportedDataSubAction)
                            output.subActions.unshift(reportedDataSubAction)
                        }

                        return output
                    })
                    vm.dataSourceDropdownActions.unshift(vm.selectedDataSource = {
                        text: vm.selectedDataSourceText = $translate.instant('modules.manageScopeDashboard.yourOwnData'),
                        icon: '',
                        action: onDropdownItemClickAction,
                    })

                    vm.dataSourceDropdownActions.push('-')
                    vm.dataSourceDropdownActions.push({
                        setCurrentAsDefault: true,
                        text: $translate.instant('modules.manageScopeDashboard.setAsDefaultDataSource'),
                        icon: '',
                        action: onDropdownItemClickAction,
                    })

                    if (vm.preselectedDataSourceFilter) {
                        preselectedDataSourceAction = _.find(vm.allDataSourceActions, vm.preselectedDataSourceFilter)
                        if (preselectedDataSourceAction) {
                            onDropdownItemClickAction.call(preselectedDataSourceAction)
                            if (!(preselectedDataSourceAction.isNetwork || preselectedDataSourceAction.isNetworkSubSource)) {
                                resolve()
                            }
                            return
                        }
                    }

                    if (vm.sourceNetworkId) {
                        preselectedNetwork = _.find(vm.dataSourceDropdownActions, { networkId: vm.sourceNetworkId, isNetwork: true })

                        if (!preselectedNetwork) $state.go('root')
                        else if (vm.preselectedSource != 'ownData') { // If preselectedSource = "ownData", then the sourceNetworkId will not be used on initial loading but must still be in the list of available sources
                            onDropdownItemClickAction.call(preselectedNetwork)
                        }
                        else resolve()
                    }
                    else resolve()

                    function onDropdownItemClickAction() {
                        const self = this

                        if (self.setCurrentAsDefault) {
                            if (_.get(vm.loader.hierarchyStructure, 'conditions.uiSettings.scopeDashboardConfig')) {
                                delete vm.loader.hierarchyStructure.conditions.uiSettings.scopeDashboardConfig.preselectedDataSourceFilter
                            }

                            let preselectedDataSourceFilter = undefined

                            if (vm.selectedDataSource.isInternalNetworkData) {
                                preselectedDataSourceFilter = {
                                    isInternalNetworkData: true,
                                    isNetworkSubSource: true,
                                    networkId: vm.selectedDataSource.networkId,
                                }
                            }
                            else if (vm.selectedDataSource.contextParentType) {
                                preselectedDataSourceFilter = {
                                    isNetworkSubSource: true,
                                    contextParentType: vm.selectedDataSource.contextParentType,
                                    networkId: vm.selectedDataSource.networkId,
                                }
                            }
                            else if (vm.selectedDataSource.networkId) {
                                preselectedDataSourceFilter = {
                                    isNetwork: true,
                                    networkId: vm.selectedDataSource.networkId,
                                }
                            }

                            const newSettings = preselectedDataSourceFilter ? _.defaultsDeep({
                                uiSettings: {
                                    scopeDashboardConfig: {
                                        preselectedDataSourceFilter,
                                    },
                                },
                            }, vm.loader.hierarchyStructure.conditions) : vm.loader.hierarchyStructure.conditions

                            const settingsWithSerializedProps = {}
                            _.each(newSettings, (value, key) => {
                                if (key == 'dataRelation') {
                                    value = ''
                                }
                                else if (key === 'childrenSettings') {
                                    return
                                }
                                else if (value instanceof Array) {
                                    // These settings are stored like joined strings in db but are split into arrays when loaded.
                                    // They must be joined here before being saved.
                                    if (_.includes(['objectTypes', 'limitQuestionAnswerTypes', 'contextParentWfids', 'attachObjectTypes'], key)) value = value.join(',')
                                }

                                if (typeof value === 'object') {
                                    settingsWithSerializedProps[key] = JSON.stringify(value)
                                }
                                else {
                                    settingsWithSerializedProps[key] = value
                                }
                            })

                            dataOps.saveSettings({
                                item: vm.loader.hierarchyStructure,
                                settings: settingsWithSerializedProps,
                                forOrganization: vm.loader.hierarchyStructure.creatorOrganizationId !== authOrgId,
                            }).then(() => {
                                vm.loader.hierarchyStructure.conditions = newSettings
                            })
                            return
                        }

                        vm.selectedDataSource = this

                        vm.selectedDataSourceText = self.text
                        if (self.isNetwork) {
                            vm.selectedDataSourceText = self.text + ' / ' + $translate.instant('ReportedData')
                        }
                        if (self.isNetworkSubSource || self.isInternalNetworkData || self.isReportedNetworkData) {
                            vm.selectedDataSourceText = self.parent.text + ' / ' + vm.selectedDataSourceText
                        }

                        vm.isDataSourceFilteringPopoverOpen = false

                        if (self.isNetwork || self.isNetworkSubSource || self.isInternalNetworkData) {
                            vm.currentNetworkSource = self.isNetworkSubSource ? self.parent : self
                            loadNetworkAndSetTicket.call(self)
                        }
                        // else if (self.isInternalNetworkData) {
                        //     vm.currentNetworkSource = null
                        //     vm.loader.setTicket({
                        //         contextParentType: self.contextParentType,
                        //         // networkId: self.networkId,
                        //         organizationIds: [wfAuth.getOrganizationId()],
                        //     }, true)
                        // }
                        else {
                            vm.currentNetworkSource = null
                            vm.loader.setTicket({
                                organizationId: wfAuth.getOrganizationId(),
                            }, true)
                        }

                        syncRelativeMeasureResultsCacheUI()

                        $timeout()
                    }

                    function loadNetworkAndSetTicket() {
                        let self = this; let subSource

                        if ((self.isNetworkSubSource || self.isInternalNetworkData) && self.contextParentType) {
                            subSource = self
                            self = self.parent
                        }

                        if (!self.inited) {
                            self.loading = true
                            self.inited = true

                            if (vm.loader) {
                                vm.loader.pagingFunctionEnabled = false
                                vm.loader.clearContentAndContainerReferencesOnAllItems()
                            }

                            valueChainService.loadOrganizationsInNetwork(this.networkId, true).then((res) => {
                                self.loading = self.loaded = true
                                // If there are duplicate relations to any organization then those duplicate will have undefined childContent
                                // (since the result is not injected into JSData) so remove them here.

                                self.organizationIds = _.chain(res).map('childId').compact().value()
                                self.organizations = _.chain(res).map('childContent').compact().value()
                                self.ticket = createTicketFromSelectedSource(self, subSource, self.organizationIds)

                                vm.ticket = self.ticket

                                if (vm.loader) {
                                    setTicketFromSelectedSource(self, subSource, self.organizationIds)
                                    resolve()
                                }
                                else {
                                    resolve()
                                }
                                loadNetworkFilters()
                            })
                        }
                        else if (self.loaded) {
                            setTicketFromSelectedSource(self, subSource)
                            loadNetworkFilters()
                        }

                        function loadNetworkFilters(networkDataSourceDropdownItem) {
                            if (!networkDataSourceDropdownItem) networkDataSourceDropdownItem = vm.currentNetworkSource

                            valueChainService.loadCategoriesInNetwork(networkDataSourceDropdownItem.networkId, { formatWithParents: true }).then((res) => {
                                const allCategoryWFIDsInNetwork = _.chain(res).map('wfid').uniq().value()

                                valueChainService.loadCategorizationsInNetwork(networkDataSourceDropdownItem.networkId).then(() => {
                                    const
                                        categoryOrganizationRelations = wfObject.filter({ where: {
                                            type: enums.objectType.dataRelation,
                                            parentType: enums.objectType.structure,
                                            childType: enums.objectType.organization,
                                            childId: { in: networkDataSourceDropdownItem.organizationIds },
                                            wffid: { in: allCategoryWFIDsInNetwork },
                                        } })

                                    const orgIdsByCategoryWfid = _.chain(categoryOrganizationRelations)
                                        .groupBy('wffid')
                                        .mapValues((relations) => {
                                            return _.map(relations, 'childId')
                                        })
                                        .value()

                                    let filterGroups

                                    filterGroups = _.chain(res)
                                        .groupBy('parentWfid')
                                        .mapValues((categoryComposites, parentWfid) => {
                                            const
                                                parentContent = categoryComposites[0].parentContent

                                            return {
                                                header: parentContent ? parentContent.title : $translate.instant('Categories'),
                                                content: parentContent,
                                                id: parentContent ? parentContent.id : -1,
                                                filterOptionsSource: (function () {
                                                    let output

                                                    output = _.chain(categoryComposites)
                                                        .map((categoryComposite) => {
                                                            const orgIds = orgIdsByCategoryWfid[categoryComposite.wfid] || []

                                                            return {
                                                                content: categoryComposite.content,
                                                                title: categoryComposite.title,
                                                                id: categoryComposite.wfid,
                                                                count: orgIds.length,
                                                                items: orgIds,
                                                            }
                                                        })
                                                        .value()

                                                    return output
                                                })(),
                                            }
                                        })
                                        .map()
                                        .value()

                                    const categories = []
                                    const categoryGroups = []

                                    _.each(filterGroups, (filterGroup) => {
                                        const categoryGroupInfo = {
                                            id: 'group' + filterGroup.id,
                                            title: filterGroup.header,
                                            categories: [],
                                        }
                                        categoryGroups.push(categoryGroupInfo)
                                        _.each(filterGroup.filterOptionsSource, (item) => {
                                            categoryGroupInfo.categories.push({
                                                id: item.id,
                                                title: item.title,
                                                orgIds: item.items,
                                            })

                                            categories.push({
                                                id: item.id,
                                                title: filterGroup.header + ' - ' + item.title,
                                                orgIds: item.items,
                                            })
                                        })
                                    })

                                    valueChainService.setCachedNetworkCategoriesInfo({ categories, categoryGroups })

                                    filterGroups.push({
                                        header: $translate.instant('Organization'),
                                        // content: parentContent,
                                        displayAs: 'dropdown',
                                        hideCount: true,
                                        filterOptionsSource: (function () {
                                            let output

                                            output = _.chain(networkDataSourceDropdownItem.organizations)
                                                .map((org) => {
                                                    return {
                                                        title: org.name.toString(),
                                                        id: org.id,
                                                        items: [org.id],
                                                    }
                                                })
                                                .value()

                                            return output
                                        })(),
                                    })

                                    networkDataSourceDropdownItem.filteringConfig = {
                                        enableInvertedFiltering: true,
                                        initialSelectedFilterOptions: _.get(networkDataSourceDropdownItem, 'filteringConfig.initialSelectedFilterOptions'),
                                        onFiltered: _.debounce((filteredItems, selectedOptions) => {
                                            networkDataSourceDropdownItem.filteringConfig.initialSelectedFilterOptions = selectedOptions
                                            networkDataSourceDropdownItem.filteredOrganizationIds = filteredItems

                                            setTicketFromSelectedSource(self, subSource, filteredItems, true)

                                            networkDataSourceDropdownItem.selectedFiltersCount = selectedOptions.length

                                            // if (selectedOptions.length)
                                            // 	vm.filteredOrganizationIds = _.map(filteredItems, "id");
                                            // else
                                            // 	vm.filteredOrganizationIds = organizationIds;

                                            // syncAggregatedDataView();
                                            // $timeout();
                                        }, 500, { leading: true }),
                                        filters: filterGroups,
                                    }

                                    networkDataSourceDropdownItem.filtersLoaded = true
                                    $timeout()
                                })
                            })

                        }
                    }
                })
            })
        }

        function createTicketFromSelectedSource(source, subSource) {
            var output
            if (subSource && subSource.isInternalNetworkData) {
                output = {
                    contextParentWfids: subSource.contextParentType === 101 && source.filteredOrganizationIds && source.filteredOrganizationIds.length !== source.organizationIds.length ? _.map(source.filteredOrganizationIds, function (orgId) { return "101-" + orgId; }) : undefined,
                    contextParentType: subSource.contextParentType,
                    organizationIds: [authOrgId],
                }
            }
            else {
                output = {
                    organizationIds: source.filteredOrganizationIds || source.organizationIds,
                    networkId: source.networkId,
                    contextParentType: subSource && subSource.contextParentType,
                }
            }
            return output
        }

        function setTicketFromSelectedSource(source, subSource, organizationIds, overrideChartsLoading) {
            vm.loader.setTicket(createTicketFromSelectedSource(source, subSource, organizationIds), overrideChartsLoading)
        }

        function syncAvailableViews() {
            if (!vm.viewsDropdownActions) {
                if (availableViewsStructureId) {

                    const promise = dataOps.getSubItems(
                        { type: enums.objectType.structure, id: availableViewsStructureId },
                        enums.subItemsKind.children,
                        { skipExtras: true, getterConditions: { dataRelationOrganizationMatchMode: 2 } },
                    )

                    promise.then((res) => {
                        populate()
                    })

                    return promise
                }
                else {
                    vm.loader.negotiator.onRequest.then(() => {
                        populate()
                    })
                }
            }
            else {
                populate()
            }
            function populate() {
                let relations; let actionMatchingHierarchyStructure; let reloadWithDefaultView

                if (availableViewsStructureId) {
                    relations = dataQuery.getRelations({
                        organizationIds: [null, authOrgId],
                        kind: enums.subItemsKind.childrenByUser,
                        parentWfid: enums.objectType.structure + '-' + availableViewsStructureId,
                    })
                }
                else
                {
                    if (vm.loader.hierarchyStructure) {
                        relations = [{ createdAt: null, organizationId: 0, childType: 71, childId: vm.hierarchyStructureId, childContent: vm.loader.hierarchyStructure }]
                    }
                }

                vm.viewsDropdownActions = _.chain(relations).map((relation) => {
                    let viewItem

                    viewItem = {
                        wfid: relation.wfcid,
                        organizationId: relation.organizationId ? relation.organizationId : null,
                        createdAt: relation.createdAt,
                        text: relation.childContent.title,
                        objectId: relation.childId,
                        objectType: relation.childType,
                        content: relation.childContent,
                        image: relation.childContent.imageUrl,
                        url: $state.href($state.current.name, _.assign({}, $state.current.params, { view: relation.childId })),
                        action() {
                            // $state.go("manageScopeDashboard", { hierarchyStructureId: 16113 })
                        },
                    }
                    return viewItem
                }).sortBy('organizationId', 'createdAt').value()

                vm.viewsDropdownActions.unshift({
                    text: 'Overview',
                    isDataCollectionDashboardView: true,
                    url: $state.href($state.current.name, _.assign({}, $state.current.params, { view: 'overview' })),
                })

                actionMatchingHierarchyStructure = _.find(vm.viewsDropdownActions, { objectId: vm.hierarchyStructureId })

                // If the currrently active view was removed then reload the UI with the default view
                if (vm.selectedView && !_.find(vm.viewsDropdownActions, { objectId: vm.selectedView.objectId })) {
                    // if (vm.viewsDropdownActions.length === 0)
                    reloadWithDefaultView = true
                    // If the removed view was also the
                    // if (vm.selectedView.objectId === vm.hierarchyStructurId)

                    // vm.selectedView = vm.viewsDropdownActions[0];
                }
                else if ($state.params.view === 'overview' && vm.isDataCollectionDashboardViewAllowed) {
                    vm.selectedView = vm.viewsDropdownActions[0]
                }
                // If loaded with no preselected view but there are views available then auto select the first view
                else if (!(vm.hierarchyStructureId > 0) && !actionMatchingHierarchyStructure) {
                    if (vm.viewsDropdownActions.length) {
                        vm.selectedView = vm.viewsDropdownActions[vm.isDataCollectionDashboardViewAllowed ? vm.viewsDropdownActions.length > 1 ? 1 : 0 : 1]
                        vm.hierarchyStructureId = vm.selectedView.objectId
                    }

                    if (vm.loader) {
                        reloadWithDefaultView = true
                    }
                }
                // If loaded with a preselected view that is in the list of available views then auto select it
                else if (vm.hierarchyStructureId > 0 && actionMatchingHierarchyStructure) {
                    if (vm.selectedView) {
                        if (vm.selectedView.objectId === actionMatchingHierarchyStructure.objectId) {

                        }
                        else {
                            if (vm.viewsDropdownActions.length === 1) reloadWithDefaultView = true
                        }
                    }

                    vm.selectedView = actionMatchingHierarchyStructure
                }
                else {
                    vm.selectedView = vm.viewsDropdownActions[vm.isDataCollectionDashboardViewAllowed ? vm.viewsDropdownActions.length > 1 ? 1 : 0 : 1]
                }

                if (vm.hierarchyStructureId === 100016) {
                    vm.showGriUiElements = true
                }

                if (reloadWithDefaultView) {
                    if (_.get($state, 'params.view')) {
                        $state.go($state.current.name, { view: null })
                    }
                    else {
                        location.reload()
                    }
                }
            }
        }

        function openGriDisclaimerDialog() {
            modal.alert({
                title: "GRI License Disclaimer",
                message: "The GRI Standards are used by Worldfavor under licensed authority from GRI. GRI, as Licensor of the copyright in the GRI Standards, verified and validated the authentic and accurate representation of the GRI Standards in Worldfavor. This verification was limited to ensuring the maintenance of the integrity, authenticity and accuracy of the Licensed Content. GRI therefore makes no implied or actual representations or warranties as to the correctness, compliance, trustworthiness, fitness of purpose or quality of Worldfavor or any products resulting therefrom; or of Licensee’s use of the GRI copyrighted content; and expressly disclaims any implied or express representations that any report produced by Licensee meets the standards of an approved GRI Standards Report.",
                type: 'info',
                buttons: {
                    primary: {
                        label: $translate.instant('Close'),
                    },
                },
            })
        }

        function syncRelativeMeasureResultsCacheUI() {
            vm.dashboardIncludeRelativeMeasureResultsCache = false
            if (!(_.get(vm.selectedDataSource, 'isReportedNetworkData'))) return

            const relativeMeasures = vm.loader.items.filter(x => x.type === 118)
            if (!relativeMeasures.length) return

            const network = wfObject.get(`52-${vm.selectedDataSource.networkId}`)
            if (!_.get(network, 'settings.valueChainSettings.useRelativeMeasureResultsCache')) return

            vm.dashboardIncludeRelativeMeasureResultsCache = true

            vm.clearCacheOnAllRelativeMeasures = () => {
                const relativeMeasureIds = relativeMeasures.map(x => x.id)

                const progressModalScope = $rootScope.$new()
                progressModalScope.progressWidth = 0
                progressModalScope.loading = true
                progressModalScope.reload = () => location.reload()

                modal.open({
                    templateUrl: 'scripts/wf/manage/wfRelativeMeasureClearCacheProgressModal.template.html',
                    scope: progressModalScope,
                    windowClass: 'modal-width-500',
                    backdrop: 'static',
                    keyboard: false,
                })

                let minTimePassed = false
                let apiCallCompleted = false

                // This setTimeout makes sure that the clearing message is shown for at least 2 seconds, even if the API finished faster
                $timeout(() => {
                    minTimePassed = true

                    if (apiCallCompleted) {
                        progressModalScope.loading = false
                    }
                }, 2000)

                dataOps.clearRelativeMeasureResultsCache({
                    relativeMeasureIds,
                    networkId: vm.sourceNetworkId
                }).then(() => {
                    apiCallCompleted = true

                    if (minTimePassed) {
                        progressModalScope.loading = false
                    }
                }).catch((e) => {
                    progressModalScope.loading = false
                    progressModalScope.error = true
                })
            }
        }
    }
})()
