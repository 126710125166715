// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import ProductDetail from '../ProductDetail'
import { getNodeFromNodeId } from '../../../selectors/dataSelector'

type Props = {
  productWfid: string,
}

const ProductNode = (props: Props) => {
    const product = useSelector(state => getNodeFromNodeId(state, props.productWfid))
    return (
        <ProductDetail
            size={'small'}
            product={product}
            style={{ textAlign: 'initial' }}
        />
    )
}

export default ProductNode
