(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('dropdownOverflow', dropdownOverflowDirective)

    dropdownOverflowDirective.$inject = ['dropdownUtility', '$parse']

    function dropdownOverflowDirective(dropdownUtility, $parse) {
        const win = $(window)
        const directive = {
            link,
            restrict: 'A',
        }

        return directive

        function link(scope, element, $attrs) {
            const el = $(element)
            const button = el.find('.dropdown-toggle, div[type=\'button\'], .btn, .dropdown-custom-trigger')
            const dropdownMenu = el.children().closest('ul.dropdown-menu')
            let subMenu = false
            const
                settings = {
                    align: 'left',
                    width: 160,
                    responsive: true,
                }
				
            let options

            el.click(() => {
                dropdownUtility.setAsOpen(el, win)

                if (typeof options === 'undefined') {
                    if ($attrs.dropdownOverflow.length) {
                        options = $parse($attrs.dropdownOverflow)(scope)
                        _.assign(settings, options)
                    }
                    else options = null
                }

                const discreetElement = el.hasClass('discreet')
                if (!(dropdownMenu.css('position') === 'fixed')) {
                    dropdownMenu.css({
                        position: 'fixed',
                        width: settings.width,
                        minWidth: settings.width,
                    })
                }

                if (discreetElement) { //Admin Tools
                    dropdownUtility.fixDropDownPosition(el, dropdownMenu, subMenu, settings)
                    const subMenuElement = dropdownMenu.children('li.dropdown-submenu')
                    if (subMenuElement) {
                        subMenu = true
                        const subMenuDropdown = subMenuElement.children('ul.dropdown-menu')
                        subMenuDropdown.css({
                            position: 'fixed',
                            width: settings.width,
                            minWidth: settings.width,
                        })
                        subMenuElement.mouseover(() => {
                            dropdownUtility.fixDropDownPosition(subMenuElement, subMenuDropdown, subMenu, settings)
                        })
                    }
                }
                else {
                    dropdownUtility.fixDropDownPosition(button, dropdownMenu, subMenu, settings)
                }
            })

            win.scroll(() => {
                dropdownUtility.close()
            })
        }
    }

} ())
