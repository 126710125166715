// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import Divider from '@worldfavor/components/Divider'
import Colors from '@worldfavor/constants/colors'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        flex: 1,
        paddingTop: 20,
        paddingLeft: 68,
        paddingRight: 68,
    },
    inlineRow: {
        display: 'flex',

        '&>div': {
            flex: 1,
        },

        '& >:not(:last-child)': {
            marginRight: 16,
        },
    },
    invalid: {
        color: Colors.red,
    },
    helperText: {
        whiteSpace: 'pre-wrap',
        '@global li': {
            padding: '1px 0',
        },
        '@global a': {
            textDecoration: 'none',
            color: Colors.blue,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '@global ul': {
            paddingLeft: 8,
        },
    },
    existingOrganizations: {
        marginTop: 0,
    },
    title: {
        ...theme.typography.h6,
    },
    description: {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
        marginTop: 14,
    },
    organizationContainer: {
        overflowY: 'auto',
        marginTop: 16,
    },
})

const OrganizationRow = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 14,
        paddingBottom: 14,
    },
    avatar: {
        marginRight: 16,
    },
    content: {
        flex: 1,
    },
    name: {
        ...theme.typography.h6,
    },
    chooseButton: {
        marginRight: 8,
    },
    details: {
        display: 'flex',
        '& > div': {
            paddingRight: 14,
        },
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    value: {
        fontSize: 12,
        color: '#333',
    },
    icon: {
        fontSize: 20,
    },
}))(({ classes, organization, onSelect }) => {
    const { wfid, name, imageUrl, gln, vatNumber, registrationNumber } = organization

    function _onSelect(event) {
        onSelect && onSelect(event, wfid)
    }

    return (
        <div className={classes.root}>
            <Avatar src={imageUrl} className={classes.avatar}>
                <i className={classNames('fas fa-box-open', classes.icon)} />
            </Avatar>
            <div className={classes.content}>
                <div className={classes.name}>{name}</div>
                <div className={classes.details}>
                    {gln
            && <div><span className={classes.label}><FormattedMessage id={'supplyChain.organizationForm.step0.fields.glnNumber.label'} />:</span> <span className={classes.value}>{gln}</span></div>}
                    {registrationNumber
            && <div><span className={classes.label}><FormattedMessage id={'supplyChain.organizationForm.step0.fields.registrationNumber.label'} />:</span> <span className={classes.value}>{registrationNumber}</span></div>}
                    {vatNumber
            && <div><span className={classes.label}><FormattedMessage id={'supplyChain.organizationForm.step0.fields.vatNumber.label'} />:</span> <span className={classes.value}>{vatNumber}</span></div>}
                </div>
            </div>
            <Button
                className={classes.chooseButton}
                variant="outlined"
                onClick={_onSelect}
            >
                <FormattedMessage id={'general.choose'} />
            </Button>
        </div>
    )
})

const FindOrganizationStep = ({
    classes,
    fields = {},
    descriptors = {},
    validation = {},
    showValidation,
    onChange,
    existingOrganizations = [],
    onSelectOrganization,
}) => {
    const anyFieldsValid = (!validation['gln'] || !validation['registrationNumber'] || !validation['vatNumber']) && !(!fields['gln'] && !fields['registrationNumber'] && !fields['vatNumber'])
    const handleChange = key => (event) => {
        onChange && onChange(event, key, event.target.value)
    }

    function renderHelperText(helperTextId, helperText) {
        let content

        if (helperText) {
            content = helperText
        }
        else if (helperTextId) {
            content = <FormattedMessage id={helperTextId} />
        }

        if (content) {
            return <div className={classes.helperText}>{content}</div>
        }
    }

    function getTextField(key, props = {}) {
        const { id, labelId, helperTextId, helperText, required, validator, ...rest } = descriptors[key] || {}
        return (
            <TextField
                id={id}
                label={<FormattedMessage id={labelId} />}
                helperText={renderHelperText(helperTextId, helperText)}
                value={fields[key]}
                onChange={handleChange(key)}
                margin="normal"
                variant="filled"
                error={showValidation && validation[key]}
                required={required}
                FormHelperTextProps={{ component: 'div' }}
                {...props}
                {...rest}
            />
        )
    }

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <span className={classNames({ [classes.invalid]: !anyFieldsValid })}><FormattedMessage id={'supplyChain.organizationForm.step0.requirement'} /></span>
            <div className={classes.inlineRow}>
                { getTextField('gln') }
                { getTextField('registrationNumber') }
                { getTextField('vatNumber') }
            </div>

            {
                existingOrganizations.length > 0 && (
                    <div className={classes.existingOrganizations}>
                        <div className={classes.title}>
                            <FormattedMessage id={'supplyChain.organizationForm.step0.content.title'} />
                        </div>
                        <div className={classes.description}>
                            <FormattedMessage id={'supplyChain.organizationForm.step0.content.description'} />
                        </div>

                        <div className={classes.organizationContainer}>
                            {
                                existingOrganizations.map((organization, index) => (
                                    <React.Fragment key={`org-row-${organization.wfid}`}>
                                        <OrganizationRow organization={organization} onSelect={onSelectOrganization} />
                                        {
                                            index < existingOrganizations.length - 1 && (
                                                <div>
                                                    <Divider height={1} margin={'0'} width={'100%'} color={'rgba(221, 221, 221, 0.5)'} />
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </form>
    )
}

export default withStyles(styles)(FindOrganizationStep)
