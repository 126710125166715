// @flow
import type { Item } from '@worldfavor/types'
import * as types from '../actions'

type State = {
  +[string]: Item,
}

const initialState: State = {}

export default (state: State = initialState, action: Object): State => {
    switch (action.type) {
        case types.ADD_ITEM:
            return {
                ...state,
                [action.item.wfid]: action.item,
            }

        case types.ADD_ITEMS: {
            return {
                ...state,
                ...action.items
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.wfid]: item,
                    }), {}),
            }
        }

        case types.REMOVE_ITEM: {
            const { [action.wfid]: removed, ...rest } = state
            return {
                ...rest,
            }
        }

        case types.CHANGE_ORGANIZATION:
        case types.RESET_DATA_STATE:
        case types.LOGOUT:
            return {
                ...initialState,
            }

        default:
            return state
    }
}
