import xor from 'lodash/xor'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import createCachedSelector from 're-reselect'
import { getFromWfidAttribute, getToWfidAttribute, identity } from '@worldfavor/utils/selectors'

export const _graphEntities = (state): { [string]: Object } => state.graph
export const _graphEntityById = (state, id): Object => state.graph[id]

export const getNodes = createSelector(
    [_graphEntities],
    entities => Object.values(entities).filter(({ type }) => type === 'vertex'),
)

export const getEdges = createSelector(
    [_graphEntities],
    entities => Object.values(entities).filter(({ type }) => type === 'edge'),
)

/**
 * Get all sub nodes from an edge wfid.
 *
 * Considering the following hierarchy:
 *  edge (top)
 *    node
 *      edge (edgeChild1)
 *        node (nodeChild1)
 *      edge (edgeChild2)
 *        node (nodeChild2)
 *      ...
 * and the passed edge wfid is top.wfid,
 * the result array will contain:
 * [
 *    { edge: edgeChild1, node: nodeChild1 },
 *    { edge: edgeChild2, node: nodeChild2 },
 *    ...
 * ]
 * @returns {*}
 */
export const getSubEntitiesFromEdgeId = createCachedSelector(
    [_graphEntityById, _graphEntities, getEdges],
    (fromEdge, items, edges) => {
        if (!fromEdge) {
            return []
        }

        return edges.filter(edge => getFromWfidAttribute(edge) === getToWfidAttribute(fromEdge))
        //.sort((a, b) => a.order - b.order)
            .map(edge => ({
                edge,
                node: items[getToWfidAttribute(edge)],
            }))
    },
)((state, id) => id)

export const getSubEntitiesFromNodeId = createCachedSelector(
    [_graphEntityById, _graphEntities, getEdges],
    (fromNode, items, edges) => {
        if (!fromNode) {
            return []
        }

        return edges.filter(edge => getFromWfidAttribute(edge) === fromNode.wfid)
        //.sort((a, b) => a.order - b.order)
            .map(edge => ({
                edge,
                node: items[getToWfidAttribute(edge)],
            }))
    },
)((state, id) => id)

/**
 * Get a { edge, node } from an edge wfid.
 *
 * Considering the following hierarchy
 *  ...
 *    edge
 *      node
 *   ...
 *   and the passed edge wfid is edge.wfid,
 *   the result object will contain:
 *    { edge, node }
 * @returns {*}
 */
export const getEntityFromEdgeId = createCachedSelector(
    [_graphEntityById, _graphEntities],
    (edge, items) => (
        (edge && getToWfidAttribute(edge)) ? {
            edge,
            node: items[getToWfidAttribute(edge)],
        } : null
    ),
)((state, id) => id)

export const getNodeFromNodeId = createCachedSelector(
    [_graphEntityById],
    identity,
)((state, id) => id)

/**
 * Create reselect function for wfObject
 * with its own parameter comparison
 * based on the keys of the objects.
 *
 * Invalidate the memoized cache only if the
 * symmetric difference between the two objects is not empty
 */
export const createWfObjectSelector = createSelectorCreator(
    defaultMemoize,
    (a, b) => xor(Object.keys(a), Object.keys(b)).length === 0,
)
