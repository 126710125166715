// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

type Props = {
  open: boolean,
  organization: {
    name: string,
  },
  actorTypes: Array<{ title: string }>,
}

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: -1,
        top: 44,
        backgroundColor: 'white',
        borderRadius: 4,
        width: 180,
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden',
    },
    content: {
        paddingTop: 24,
        paddingLeft: 14,
        paddingRight: 14,
        paddingBottom: 14,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: 8,
        textAlign: 'center',
        wordBreak: 'break-word',
    },
    actorTypes: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        display: 'flex',

        '& i': {
            marginRight: 6,
            marginTop: 5,
        },
    },
    actorTypesList: {
        '& > div': {
            marginBottom: 4,
        },
    },
}))

const OrganizationTooltip = (props: Props) => {
    const { open, organization, actorTypes } = props
    const classes = useStyles(props)
    const { name } = organization
    const actorTypeIcon = 'fas fa-box-open'

    return (
        <Paper
            elevation={3}
            className={classes.root}
            style={!open ? { display: 'none' } : null}
        >
            <div className={classes.content}>
                <div className={classes.title}>{name}</div>
                {
                    actorTypes && actorTypes.length ? (
                        <div className={classes.actorTypes}>
                            <i className={actorTypeIcon} />
                            <div className={classes.actorTypesList}>
                                {
                                    actorTypes.map((item, index) => (
                                        <div key={`actorType_${item.wfid}_${index}`}>{item.title}</div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </Paper>
    )
}

export default OrganizationTooltip
