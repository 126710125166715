(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfExportCsv', wfExportCsv)

    wfExportCsv.$inject = []
    function wfExportCsv() {
        const directive = {
            templateUrl(element, attrs) {
                if ('skipButton' in attrs) return 'scripts/wf/importExport/csvExportPopover.template.html'
                else return 'scripts/wf/importExport/wfExportCsv.directive.html'
            },
            bindToController: true,
            controller: wfExportCsvController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                items: '<',
                itemsOnlyLatestData: '=',
                mapping: '=',
                filenamePart: '=filename',
            },
        }
        return directive
    }

    wfExportCsvController.$inject = ['$scope', '$element', 'importExportService', '$translate', '$attrs', '$timeout']
    function wfExportCsvController($scope, $element, importExportService, $translate, $attrs, $timeout) {
        const vm = this
        let mappingProperties = []

        _.assign(vm, {
            //Variables
            exportingDetails: {},
            popoverTemplate: 'scripts/wf/importExport/csvExportPopover.template.html',
            numberOfSelectedColumns: 0,
            exporting: false,
            fieldSeparator: ',',
            decimalSeparator: '.',
            textDelimiter: '"',
            dataTimeFormat: 'YYYY-MM-DD HH:mm',
            fieldSeparators: [
                { value: ',', text: $translate.instant('modules.export.csv.popover.comma'), class: 'btn-primary' },
                { value: ';', text: $translate.instant('modules.export.csv.popover.semiColon'), class: 'btn-hollow' },
            ],
            decimalSeparators: [
                { value: '.', text: $translate.instant('modules.export.csv.popover.dot'), class: 'btn-primary' },
                { value: ',', text: $translate.instant('modules.export.csv.popover.comma'), class: 'btn-hollow' },
            ],
            textDelimiters: [
                { value: '"', text: $translate.instant('modules.export.csv.popover.doubleQuotes'), class: 'btn-primary' },
                { value: '\'', text: $translate.instant('modules.export.csv.popover.singleQuotes'), class: 'btn-hollow' },
            ],
            dateTimeFormats: [
                { value: 'YYYY-MM-DD HH:mm', text: $translate.instant('No'), class: 'btn-primary' },
                { value: 'YYYY-MM-DD HH:mm:ss', text: $translate.instant('Yes'), class: 'btn-hollow' },
            ],

            //Functions
            initExportToCsvConfigUI,
            exportDataToCsv,
            countSelectedColumns,
            setFieldSeparator,
            setDecimalSeparator,
            setTextDelimiter,
            setDateTimeFormat,
        })

        if ('skipButton' in $attrs) { // If true the export UI is shown directly in the wf-export-csv element instead of in a popover triggered by a button
            initExportToCsvConfigUI()
			
            const itemsWatcher = $scope.$watchCollection('vm.items', () => {
                vm.actualItems = vm.items
                initExportToCsvConfigUI()
            })
	
            $scope.$on('$destroy', () => {
                itemsWatcher()
            })
        }

        if (vm.itemsOnlyLatestData && vm.itemsOnlyLatestData.length) {
            vm.showOnlyLatestDataCheckbox = true

            vm.onOnlyExportLatestDataChange = function () {
                if (vm.onlyExportLatestData) {
                    vm.actualItems = vm.itemsOnlyLatestData
                }
                else {
                    vm.actualItems = vm.items
                }

                vm.exportingDetails.numberOfItems = vm.actualItems.length
                vm.numberOfRowsText = $translate.instant('modules.export.csv.popover.rows') + ': ' + vm.exportingDetails.numberOfItems
                $timeout()
            }
        }

        function countSelectedColumns() {
            vm.numberOfSelectedColumns = _.filter(vm.exportingDetails.mappingOptions, 'include').length
            vm.numberOfColumnsText = $translate.instant('modules.export.csv.popover.columns') + ': ' + vm.numberOfSelectedColumns
        }

        function initExportToCsvConfigUI() {
            if (vm.onlyExportLatestData) {
                vm.actualItems = vm.itemsOnlyLatestData
            }
            else {
                vm.actualItems = vm.items
            }

            if (_.isEmpty(vm.exportingDetails)) {
                vm.exportingDetails = { numberOfItems: vm.actualItems.length, mappingOptions: vm.mapping }
                countSelectedColumns()
            }
            vm.exportingDetails.numberOfItems = vm.items.length
            vm.numberOfRowsText = $translate.instant('modules.export.csv.popover.rows') + ': ' + vm.exportingDetails.numberOfItems
        }

        function setFieldSeparator(button) {
            vm.fieldSeparator = button.value
            button.class = 'btn-primary'
            _.each(_.without(vm.fieldSeparators, button), (btn) => {
                btn.class = 'btn-hollow'
            })
        }

        function setDecimalSeparator(button) {
            vm.decimalSeparator = button.value
            button.class = 'btn-primary'
            _.each(_.without(vm.decimalSeparators, button), (btn) => {
                btn.class = 'btn-hollow'
            })
        }

        function setTextDelimiter(button) {
            vm.textDelimiter = button.value
            button.class = 'btn-primary'
            _.each(_.without(vm.textDelimiters, button), (btn) => {
                btn.class = 'btn-hollow'
            })
        }

        function setDateTimeFormat(button) {
            vm.dateTimeFormat = button.value
            button.class = 'btn-primary'
            _.each(_.without(vm.dateTimeFormats, button), (btn) => {
                btn.class = 'btn-hollow'
            })
        }

        function exportDataToCsv() {
            if (vm.exporting) return
				
            const filename = 'WF_' + (vm.filenamePart || '') + '_' + moment().format('YYYY-MM-DD') + '.csv'

            vm.exporting = true
            mappingProperties = []

            _.each(vm.exportingDetails.mappingOptions, (option) => {
                if (option.include) mappingProperties.push(option)
            })

            if (!_.isEmpty(vm.exportingDetails)) {
                importExportService.exportToCsv(vm.actualItems, mappingProperties, {
                    filename,
                    fieldSep: vm.fieldSeparator,
                    decimalSep: vm.decimalSeparator,
                    txtDelim: vm.textDelimiter,
                    dateTimeFormat: vm.dateTimeFormat,
                }).then(() => {
                    vm.exporting = false
                })
            }
        }

    }
})()
