import React from 'react'
import { react2angular } from 'react2angular'
import ItemSummary from '@worldfavor/components/Items/ItemSummary'
import { WrapProvider } from './providers'

const props = [
    'item',
    'style',
]

class ItemSummaryWrapper extends React.Component {

    render() {
        const { wfAuth, ...rest } = this.props
        return (
            <WrapProvider wfAuth={wfAuth}>
                <ItemSummary {...rest} />
            </WrapProvider>
        )
    }
}

export default react2angular(ItemSummaryWrapper, props, ['wfAuth'])
