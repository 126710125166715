import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfInlineAnswering', {
            templateUrl: 'scripts/wf/workItem/wfInlineAnswering.component.html',
            controller: wfInlineAnsweringController,
            controllerAs: 'vm',
            require: {
                wfWorkItem: '?^^wfWorkItem',
                wfAnsweringFeedItem: '?^^wfAnsweringFeedItem',
            },
            bindings: {
                itemComposite: '<', // REQUIRED
                ticket: '<', // REQUIRED
                pickerSettingsFromAttr: '<pickerSettings',
                showSideActionsFromAttr: '<showSideActions',
                answerModulesFromAttr: '=answerModules',
            },
        })

    wfInlineAnsweringController.$inject = ['$element', '$scope', 'wfAuth', 'dataQuery', 'modalService', '$timeout']
    function wfInlineAnsweringController($element, $scope, wfAuth, dataQuery, modal, $timeout) {
        const vm = this
			
        _.assign(vm, {
            // Variables
            componentLoaded: false,
            user: wfAuth.getWorldfavorUser(),
            selectedAnswerModule: undefined,
            pickerSettings: undefined,
            showSideActions: vm.showSideActionsFromAttr !== undefined ? vm.showSideActionsFromAttr : true,
            formCompiler: {},
            pickerCompiler: {},
            answerModules: [],

            //Functions
            toggleFormAndPicker,
            hideCurrentForm,
            selectAnswerModule,
        })

        vm.$onInit = function() {
            if (vm.wfWorkItem.uiMode === enums.uiMode.view) {
                $element.remove()
                return
            }

            if (vm.itemComposite && vm.ticket) {
                if (vm.answerModulesFromAttr && vm.answerModulesFromAttr.length) {
                    vm.answerModules = vm.answerModulesFromAttr.map(x => x.clone())
                    vm.answerModules.forEach((x) => {
                        x.wfWorkItem = vm.wfWorkItem
                        x.itemComposite = vm.itemComposite

                        if (x.picker && x.picker.pickerSettings) {
                            if (x.picker.pickerSettings.relationTarget.useRelationItem) {
                                x.picker.pickerSettings.relationTarget.item = vm.itemComposite.dataRelation
                            }
                            else {
                                x.picker.pickerSettings.relationTarget.item = vm.itemComposite.content
                            }
                        }
                    })

                    if (vm.answerModules.length === 1) // if there is only one answer module show it right away
                        selectAnswerModule(vm.answerModules[0])
                }

                if (vm.answerModules.length) {
                    vm.showAnswerModuleToggler = vm.answerModules.length > 1 || (!vm.answerModules[0].isQuestionOrMeasureAnswer && vm.answerModules[0].type !== enums.objectType.structure)
                    vm.offsetUserIcon = vm.itemComposite.type === enums.objectType.measure || vm.answerModules[0].type === enums.objectType.structure
                }
				
                vm.componentLoaded = true

                if (vm.wfAnsweringFeedItem) {
                    vm.wfAnsweringFeedItem.isInlineAnsweringFormEmpty = function () {
                        // console.log(_.get(vm.selectedAnswerModule, "form.formControl"));
                        if (!vm.selectedAnswerModule) return true

                        if (!vm.selectedAnswerModule.form || !vm.selectedAnswerModule.form.formControl) {
                            return true
                        }
                        else {
                            const form = vm.selectedAnswerModule.form.formControl.getForm()
                            return !form || form.$pristine
                        }

                    }
                }
            }
            else {
                console.error('itemComposite or ticket not defined to the wfInlineAnsweringComponent')
                return
            }
        }

        function toggleFormAndPicker() {
            if (vm.selectedAnswerModule.enableForm && vm.selectedAnswerModule.enablePicker) {
                vm.selectedAnswerModule.form.visible = !vm.selectedAnswerModule.form.visible
                vm.selectedAnswerModule.picker.visible = !vm.selectedAnswerModule.form.visible
            }

            $timeout()
        }

        function hideCurrentForm() {
            if (vm.selectedAnswerModule && vm.answerModules.length > 1) {
                vm.selectedAnswerModule.selected = false
                vm.selectedAnswerModule = undefined
            }

            $timeout()
        }

        function selectAnswerModule(answerModule) {
            if (answerModule === vm.selectedAnswerModule) return

            if (vm.selectedAnswerModule) vm.selectedAnswerModule.selected = false

            vm.selectedAnswerModule = vm.itemComposite.currentAnswerModule = answerModule
            vm.selectedAnswerModule.selected = true

            if (!vm.selectedAnswerModule.loaded) {
                answerModule.initialize($element)
            }

            if (vm.ticket && vm.ticket.thirdParty) {
                if (vm.selectedAnswerModule.form) {
                    vm.selectedAnswerModule.enablePicker = false
                    delete vm.selectedAnswerModule.picker
                    delete vm.pickerCompiler
                }
            }

            if (vm.formCompiler && typeof vm.formCompiler.compile === 'function') vm.formCompiler.compile()

            if (vm.pickerCompiler && typeof vm.pickerCompiler.compile === 'function') vm.pickerCompiler.compile()

            $timeout()
        }
    }
})()
