import * as enums from '@worldfavor/constants/enums'
import { StaticIds } from '@worldfavor/constants'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfRelativeMeasureStepsEditor', {
            templateUrl: 'scripts/wf/relativeMeasures/steps/wfRelativeMeasureStepsEditor.component.html',
            controller: wfRelativeMeasureStepsEditorController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
            },
        })

    wfRelativeMeasureStepsEditorController.$inject = ['$scope', '$timeout', '$translate', '$q', 'dataOperationsService', 'wfPropertyExtractor', 'modalService', 'dataQuery', 'wfAuth', '$rootScope', '$uibModal']
    function wfRelativeMeasureStepsEditorController($scope, $timeout, $translate, $q, dataOps, wfPropertyExtractor, modal, dataQuery, wfAuth, $rootScope, $uibModal) {
        const
            vm = this

        let itemContent

        const numbersRegExp = /[^\d-,.]/g

        let changesMadeAndSaved = false

        const itemPrototype = {
            model: {
                type: enums.objectType.relativeMeasureStep,
                sourceObjectType: null,
                sourceObjectId: null,
                mathOperation: 0,
                order: 0,
                relativeMeasureId: 0,
                customValue: null,
                missingSourceValuePolicy: enums.missingSourceValuePolicy.useZeroAndContinue,
                dataSource: enums.dataSource.reportedData,
                sumPeriodAnswers: false,
            },
            deserializedResultTaskValue: null,
            wasAdded: false,
            wasChanged: false,
            wasRemoved: false,
            actualObject: undefined,
            remove() { removeItem(this) },
            onChange(property) { onItemChange(this, property) },

        }

        const emissionFactorMeasureIds = []

        const standardMeasureIds = []

        const standardRelativeMeasureIds = []

        _.assign(vm, {
            authOrgId: wfAuth.getOrganizationId(),
            items: [],
            viewItems: [],
            loading: true,
            saving: false,
            debug: false,
            ticket: vm.ticket || {},
            actualObjectType: undefined,
            enableUseObjectDataAsPoints: false,
            invalidForm: false,
            enums: enums,
            mathOperationOptions: [
                { id: enums.mathOperation.addition, name: $translate.instant('mathOperations.add'), icon: 'fa fa-plus' },
                { id: enums.mathOperation.subtraction, name: $translate.instant('mathOperations.subtract'), icon: 'fa fa-minus' },
                { id: enums.mathOperation.multiplication, name: $translate.instant('mathOperations.multiplyBy'), icon: 'fa fa-times' },
                { id: enums.mathOperation.division, name: $translate.instant('mathOperations.divideBy'), icon: 'fa fa-minus', css: { transform: 'rotate(-60deg)' } },
            ],
            missingSourceValuePolicies:
            [
                { id: enums.missingSourceValuePolicy.invalidatePeriod, name: $translate.instant('missingSourceValuePolicy.invalidatePeriod') },
                { id: enums.missingSourceValuePolicy.useZeroAndContinue, name: $translate.instant('missingSourceValuePolicy.useZeroAndContinue') },
                { id: enums.missingSourceValuePolicy.ignoreStepAndContinue, name: $translate.instant('missingSourceValuePolicy.ignoreStepAndContinue') },
                { id: enums.missingSourceValuePolicy.useCustomValueAndContinue, name: $translate.instant('missingSourceValuePolicy.useCustomValueAndContinue') },
                { id: enums.missingSourceValuePolicy.useNextStep, name: $translate.instant('missingSourceValuePolicy.useNextStep') },
            ],
            dataSources:
            [
                { id: enums.dataSource.reportedData, name: $translate.instant('dataSource.reportedData') },
                { id: enums.dataSource.internalData, name: $translate.instant('dataSource.internalData') },
                { id: enums.dataSource.constantData, name: $translate.instant('dataSource.constantData') },
            ],
            dragControlListeners: {
                itemMoved(event) {},
                orderChanged(event) { reorderItemsAndSyncView() },
            },
            sourceMetadataConfig: {
                organizationIds: [wfAuth.getOrganizationId(), 1],
                limitRelationKinds: [7],
                showDetailedViewLink: false,
                showEmbeddedObjectViewer: false,
                hideNumbers: true,
                popoverItemSettings: {
                    showDropdown: false,
                    metadataConfig: {
                        showCreator: false,
                        limitRelationKinds: [],
                        showDetailedViewLink: false,
                        showEmbeddedObjectViewer: false,
                    },
                },
            },

            //Functions
            cancel: close,
            addItem,
            saveChanges,
            selectMeasureItem,
            onNumberInputBlur,
            openRelativeMeasureEditor,
        })

        this.$onInit = $onInit

        function $onInit() {
            itemContent = vm.item.childType ? vm.item.childContent : vm.item

            vm.actualObjectType = itemContent.type
            vm.itemMainTextual = wfPropertyExtractor.getMainTextual(itemContent)

            loadSteps().then(() => {
                vm.viewItems = _.clone(vm.items)
                reorderItemsAndSyncView()
                isFormValid()
                vm.loading = false
                $timeout()
            })

            function loadSteps() {
                const
                    deferred = $q.defer()

                let sourceObjectWfIdsByType = {}

                const promises = []

                getRelativeMeasureSteps().then((res) => {
                    vm.items = _.chain(res).sortBy('order').map((step) => {
                        return _.defaultsDeep({
                            actualObject: step,
                            model: {
                                sourceObjectType: step.sourceObjectType,
                                sourceObjectId: step.sourceObjectId,
                                mathOperation: step.mathOperation,
                                missingSourceValuePolicy: step.missingSourceValuePolicy,
                                dataSource: step.dataSource,
                                customValue: step.customValue,
                                order: step.order,
                                relativeMeasureId: step.relativeMeasureId,
                                sumPeriodAnswers: step.sumPeriodAnswers,
                                distributeOnYear: step.distributeOnYear,
                            },
                        }, itemPrototype)
                    }).value()

                    if (vm.items && vm.items.length) {
                        //Get all the source objectsIdsByType
                        sourceObjectWfIdsByType = _.chain(res).reject({ sourceObjectType: null, sourceObjectId: null }).groupBy('sourceObjectType').mapValues((value) => {
                            return _.uniq(_.map(value, (val) => {
                                return val.sourceObjectType + '-' + val.sourceObjectId
                            }))
                        }).value()

                        if (sourceObjectWfIdsByType && !_.isEmpty(sourceObjectWfIdsByType)) {
                            _.each(sourceObjectWfIdsByType, (wfids) => {
                                promises.push(getSourceMeasures(wfids).then(() => {
                                    _.each(vm.items, (step) => {
                                        const measures = wfids.map(wfid => {
                                            const item = wfObject.get(wfid)
                                            if (!item) {
                                                console.error('Relative measure step source object not found:', wfid)
                                            }
                                            return item
                                        }).filter(x => x)

                                        _.each(measures, (measure) => {

                                            if (step.model.sourceObjectType === measure.type && step.model.sourceObjectId === measure.id) {
                                                step.selectedMeasure = measure
                                                ensureMeasureMetadataIsPresent(step.selectedMeasure)
                                            }
                                        })
                                    })
                                }))
                            })

                            $q.all(promises).then(() => {
                                deferred.resolve()

                            }, () => {
                                console.error('Could not get source measures')
                                modal.alert({
                                    title: $translate.instant('modules.mailPreview.serverErrorModal.title'),
                                    message: $translate.instant('modules.mailPreview.serverErrorModal.message'),
                                    onEscape: false,
                                    type: 'info',
                                    buttons: {
                                        primary: {
                                            label: $translate.instant('Close'),
                                            className: 'btn-hollow action',
                                            callback() {
                                                $scope.$close()
                                            },
                                        },
                                    },
                                })
                                deferred.reject()
                            })
                        }
                        else {
                            deferred.resolve()
                        }
                    }
                    else {
                        deferred.resolve()
                    }
                }, () => {
                    console.error('Could not get relative measure steps')
                    modal.alert({
                        title: $translate.instant('modules.mailPreview.serverErrorModal.title'),
                        message: $translate.instant('modules.mailPreview.serverErrorModal.message'),
                        onEscape: false,
                        type: 'info',
                        buttons: {
                            primary: {
                                label: $translate.instant('Close'),
                                className: 'btn-hollow action',
                                callback() {
                                    $scope.$close()
                                },
                            },
                        },
                    })
                    deferred.reject()
                })

                function getRelativeMeasureSteps() {
                    return dataOps.getObjects({
                        requestSignature_noResultNeeded: ['relative_measure_steps', itemContent.wfid, vm.ticket.organizationId, vm.ticket.networkId, vm.ticket.contextParentWfid].join('_'),
                        objectType: enums.objectType.relativeMeasureStep,
                        bypassCache: true,
                        wrapInRelations: false,
                        getterConditions: {
                            objectType: itemContent.type,
                            objectId: itemContent.id,
                        },
                    })
                }

                function getSourceMeasures(wfids) {
                    return dataOps.getObjects(wfids)
                }

                return deferred.promise
            }
        }

        function addItem() {
            vm.invalidForm = false
            const newItem = _.defaultsDeep({
                wasAdded: true,
                wasChanged: true,
                model: {
                    sourceObjectType: null,
                    sourceObjectId: null,
                    mathOperation: 0,
                    customValue: null,
                    order: 0,
                    relativeMeasureId: itemContent.id,
                    missingSourceValuePolicy: enums.missingSourceValuePolicy.useZeroAndContinue,
                    dataSource: enums.dataSource.reportedData,
                    sumPeriodAnswers: false,
                    distributeOnYear: false,
                },
            }, itemPrototype)

            vm.items.push(newItem)
            vm.viewItems.push(newItem)

            reorderItemsAndSyncView()
        }

        function removeItem(item) {
            vm.invalidForm = false
            if (item.actualObject) {
                item.wasChanged = true
                item.wasRemoved = true
            }
            else {
                _.remove(vm.items, item)
                _.remove(vm.viewItems, item)
            }

            reorderItemsAndSyncView()
        }

        function onItemChange(item) {
            vm.invalidForm = false
            item.wasChanged = true

            const customValueString = handleStringAsNumber(item.model.customValue)
            if (customValueString) item.model.customValue = customValueString
            else item.model.customValue = null

            if (item.selectedMeasure) {
                item.model.sourceObjectType = item.selectedMeasure.type
                item.model.sourceObjectId = item.selectedMeasure.id

                if (item.model.sourceObjectType === enums.objectType.relativeMeasure) {
                    item.model.dataSource = enums.dataSource.reportedData
                }
            }
            else {
                item.model.sourceObjectType = null
                item.model.sourceObjectId = null
            }

            if (!(item.model.sourceObjectType === enums.objectType.measure
              && item.model.sourceObjectId
              && item.model.dataSource === enums.dataSource.constantData
              && [enums.missingSourceValuePolicy.invalidatePeriod, enums.missingSourceValuePolicy.useNextStep].includes(item.model.missingSourceValuePolicy))
            ) {
                item.model.distributeOnYear = false
            }

            validateItem(item)

            determineSumPeriodAnswersCheckboxVisibility()
        }

        function validateItem(item) {
            item.validation_useNextStepPolicyUsageIsInvalid = item.model.missingSourceValuePolicy === enums.missingSourceValuePolicy.useNextStep && (vm.viewItems.length === 1 || vm.viewItems[vm.viewItems.length - 1] === item || vm.viewItems[0] === item)
            item.validation_requiredCustomValueNotSpecified = item.model.missingSourceValuePolicy === enums.missingSourceValuePolicy.useCustomValueAndContinue && item.model.customValue === null
            item.validation_noSourceMeasureOrCustomValueSet = !(item.selectedMeasure || item.model.customValue)
            item.validation_mathOperationNotSet = _.indexOf(vm.viewItems, item) > 0 && typeof item.model.mathOperation !== 'number'

            item.valid = !(item.validation_useNextStepPolicyUsageIsInvalid
              || item.validation_requiredCustomValueNotSpecified
              || item.validation_noSourceMeasureOrCustomValueSet
              || item.validation_mathOperationNotSet)
        }

        function determineSumPeriodAnswersCheckboxVisibility() {
            const item = vm.viewItems[0]

            if (vm.viewItems.length === 1
				&& item.model.sourceObjectType === enums.objectType.measure
				&& item.model.sourceObjectId
				&& item.model.dataSource === enums.dataSource.internalData
				&& item.model.missingSourceValuePolicy === enums.missingSourceValuePolicy.useZeroAndContinue
            ) {
                vm.showSumPeriodAnswersCheckbox = true
            }
            else {
                vm.showSumPeriodAnswersCheckbox = false
                if (item) {
                    item.model.sumPeriodAnswers = false
                }
            }
        }

        function onNumberInputBlur(obj, prop, item) {
            obj[prop] = handleStringAsNumber(obj[prop], true)
            // onItemChange(item);
        }

        function handleStringAsNumber(value, doNotallowPartial) {
            if (typeof value === 'number' && !isNaN(value)) return value

            if (typeof value !== 'string') value = ''

            if (!doNotallowPartial && (value === '-' || value[value.length - 1] === '.' || value[value.length - 1] === ',')) // Allow user to start typing a dash (for negative numbers). If value is still only a dash on blur then it is replaced with blank string
                return value

            value = value.replace(',', '.')

            value = parseFloat(value.replace(numbersRegExp, '')) // Strip all characters except dash and numbers, then parse to float and then remove decimals
            if (isNaN(value)) {
                value = ''
            }

            return value.toString()
        }

        function reorderItemsAndSyncView() {
            vm.invalidForm = false
            vm.viewItems = _.filter(vm.viewItems, { wasRemoved: false })

            let itemsWithUseNextPolicy = 0

            _.each(vm.viewItems, (item, index) => {
                if (item.model.order != index) {
                    item.model.order = index
                    item.wasChanged = true
                }
                if (index > 0 && typeof item.mathOperation !== 'number') {
                    item.mathOperation = 0
                }

                if (item.missingSourceValuePolicy !== 'number') {
                    item.missingSourceValuePolicy = enums.missingSourceValuePolicy.useZeroAndContinue
                }

                if (item.dataSource !== 'number') {
                    item.dataSource = enums.dataSource.reportedData
                }

                if (item.model.missingSourceValuePolicy === enums.missingSourceValuePolicy.useNextStep) {
                    itemsWithUseNextPolicy++
                }

                validateItem(item)
            })

            if (itemsWithUseNextPolicy > 1) {
                vm.viewItems.filter(item => item.model.missingSourceValuePolicy === enums.missingSourceValuePolicy.useNextStep).forEach(item => {
                    item.validation_useNextStepPolicyUsageIsInvalid = true
                    item.valid = false
                })
            }

            if (vm.debug) console.table(_.map(vm.viewItems, 'model'))

            determineSumPeriodAnswersCheckboxVisibility()
        }

        function close() {
            vm.saving = false
            $scope.$parent.$parent.result.requirementsWereChanged = changesMadeAndSaved

            $scope.$parent.$close({ requirementsWereChanged: changesMadeAndSaved })
            $timeout()
        }

        function selectMeasureItem(item) {
            const
                promises = []

            const sourceLists = []

            return $q((resolve, reject) => {
                [12100, 20361].forEach((id) => {
                    promises.push(dataOps.getObject({
                        objectType: enums.objectType.structure,
                        objectId: id,
                    }))
                })

                $q.all(promises).then(() => {
                    const all = []
                    const measures = dataQuery.makeItemComposites(wfObject.get('71-12100').childs)
                    const relativeMeasures = dataQuery.makeItemComposites(wfObject.get('71-20361').childs)

                    resolve()

                    Array.prototype.push.apply(all, measures)
                    Array.prototype.push.apply(all, relativeMeasures)

                    sourceLists.push({
                        alwaysShowButton: true,
                        title: $translate.instant('All'),
                        items: all,
                    })

                    sourceLists.push({
                        alwaysShowButton: true,
                        title: $translate.instant('Measures'),
                        items: measures,
                    })

                    sourceLists.push({
                        alwaysShowButton: true,
                        title: $translate.instant('RelativeMeasures'),
                        items: relativeMeasures,
                    })

                    sourceLists.push(constructLazyLoadedSourceListSpecification({
                        leftButtonDivider: true,
                        title: $translate.instant('EmissionFactors'),
                    }, {
                        loadedIds: emissionFactorMeasureIds,
                        structureId: 84989,
                    }))

                    sourceLists.push(constructLazyLoadedSourceListSpecification({
                        title: $translate.instant('StandardMeasures'),
                    }, {
                        loadedIds: standardMeasureIds,
                        structureId: 88713,
                    }))

                    sourceLists.push(constructLazyLoadedSourceListSpecification({
                        title: $translate.instant('StandardRelativeMeasures'),
                    }, {
                        loadedIds: standardRelativeMeasureIds,
                        structureId: 88714,
                    }))

                    modal.openCreatorAndPicker({
                        title: $translate.instant('modules.relativeMeasure.editor.measurePicker.title'),
                        displayTopItem: { title: $translate.instant('modules.relativeMeasure.editor.relativeMeasureStep') },
                        singlePick: true,
                        relationBucket: {
                            preSelected: item.selectedMeasure ? [item.selectedMeasure] : [],
                            allSelected: item.selectedMeasure ? [item.selectedMeasure] : [],
                        },
                        // objectTypes: [ enums.objectType.measure, enums.objectType.relativeMeasure ],
                        sourceLists,
                        hideItem: itemContent.wfid,
                    }).closed((relationBucketResult) => {
                        if (relationBucketResult.allSelected[0] && relationBucketResult.allSelected[0].wfid === itemContent.wfid) {
                            modal.alert({
                                title: $translate.instant('modules.relativeMeasure.editor.measurePicker.selfReferenceAlert.title'),
                                message: $translate.instant('modules.relativeMeasure.editor.measurePicker.selfReferenceAlert.message'),
                                type: 'info',
                            })
                            return
                        }

                        item.wasChanged = true
                        item.selectedMeasure = undefined
                        $timeout(() => {
                            item.selectedMeasure = relationBucketResult.allSelected[0]
                            ensureMeasureMetadataIsPresent(item.selectedMeasure)

                            if (item.selectedMeasure && emissionFactorMeasureIds.includes(item.selectedMeasure.id)) {
                                item.model.dataSource = enums.dataSource.constantData
                            }

                            onItemChange(item)
                        })
                    })
                })
            })
        }

        function constructLazyLoadedSourceListSpecification(sourceListSettings, { loadedIds, structureId }) {
            const output = {
                ...sourceListSettings,
                count: loadedIds.length,
                alwaysShowButton: true,
                lazyLoad: true,
                items() {
                    const self = this
                    return $q((resolve, reject) => {
                        dataOps.getObject({
                            objectType: enums.objectType.structure,
                            objectId: structureId,
                            childrenLoadDepth: 0,
                            skipExtras: true,
                        }).then((res) => {
                            if (res && res.childs.length) {
                                if (!loadedIds.length) {
                                    Array.prototype.push.apply(loadedIds, res.childs.map(x => x.childId))
                                }

                                resolve(dataQuery.makeItemComposites(res.childs))
                            }
                            else {
                                resolve([])
                            }
                        }, () => {
                            resolve([])
                        })
                    })
                },
            }

            return output
        }

        function isFormValid() {
            let
                valid = false

            reorderItemsAndSyncView()

            const allItemsValid = _.every(vm.viewItems, (item) => {
                return item.valid
            })

            const allItemsSelectedOperations = _.every(vm.viewItems, (item) => {
                if (item.model.order === 0) return true

                return item.model.mathOperation !== null && item.model.mathOperation >= 0 && item.model.mathOperation < 4
            })

            if (vm.viewItems.length === 0) valid = true
            else valid = allItemsValid && allItemsSelectedOperations

            vm.invalidForm = !valid
            $timeout()

            return valid
        }

        function saveChanges() {
            if (isFormValid()) {
                return $q((resolve, reject) => {
                    if (vm.viewItems && vm.viewItems.length) vm.viewItems[0].model.mathOperation = null

                    const itemsWithChanges = _.filter(vm.items, { wasChanged: true })

                    if (itemsWithChanges.length) {
                        vm.saving = true
                        $timeout()
                        saveNextStep()
                    }
                    else {
                        resolve()
                        close()
                    }

                    function saveNextStep() {
                        const
                            item = itemsWithChanges.shift()
                        // shift() removes the first element from the array and returns that element

                        let model

                        if (item) {
                            changesMadeAndSaved = true

                            if (item.wasRemoved) {
                                dataOps.destroy(item.actualObject).then(() => {
                                    _.remove(vm.items, item)
                                    saveNextStep()
                                })
                            }
                            else {
                                // Prepare model for create/update
                                model = _.clone(item.model)

                                if (item.wasAdded) {
                                    dataOps.create(model).then((res) => {
                                        item.actualObject = res
                                        saveNextStep()
                                        resetItemStates(item)
                                    })
                                }
                                else if (item.actualObject) { // Was updated
                                    model.id = item.actualObject.id
                                    model.wfid = item.actualObject.wfid

                                    dataOps.update(model).then((res) => {
                                        item.actualObject = res
                                        saveNextStep()
                                        resetItemStates(item)
                                    })
                                }

                            }
                        }
                        else {
                            resolve()
                            finish()
                            $timeout()
                        }
                    }

                    function finish() {
                        close()
                    }

                    function resetItemStates(item) {
                        item.wasAdded = false
                        item.wasChanged = false
                        item.wasRemoved = false
                    }
                })
            }
        }

        function openRelativeMeasureEditor(relativeMeasure) {
            if (!relativeMeasure || relativeMeasure.type !== enums.objectType.relativeMeasure) {
                return
            }

            if (relativeMeasure.creatorOrganizationId !== vm.authOrgId) {
                return
            }

            return $q((resolve, reject) => {
                const
                    templateHtml = '<wf-relative-measure-steps-editor item="item" ticket="ticket"></wf-relative-measure-steps-editor>'

                const scope = _.assign($rootScope.$new(), {
                    item: relativeMeasure,
                    ticket: vm.ticket,
                    result: {},
                })

                const modal = $uibModal.open({
                    animation: true,
                    size: 'width-1200',
                    backdrop: 'static',
                    template: templateHtml,
                    scope,
                })

                modal.closed.then(() => {
                    resolve(scope.result)
                })
            })
        }

        function ensureMeasureMetadataIsPresent(measure) {
            if (measure && !measure.metadata) {
                measure.metadata = { countByRelationKind: { 7: 0 } }
            }
        }
    }
})()
