import {
    BEGIN_DATA_API_CALL,
    DATA_API_CALL_SUCCESS,
    API_CALL_ERROR,
} from './'

export const beginDataApiCall = () => {
    return { type: BEGIN_DATA_API_CALL }
}

export const dataApiCallSuccess = () => {
    return { type: DATA_API_CALL_SUCCESS }
}

export const apiCallError = () => {
    return { type: API_CALL_ERROR }
}
