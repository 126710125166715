import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('supplierDemandDocumentController', supplierDemandDocumentController)

    supplierDemandDocumentController.$inject = ['$element', '$uibModal', '$ngBootbox', '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$q', 'pdfGenerator', 'apiProxy', '$compile', 'screenLoaderService', 'wfAuth']
	
    function supplierDemandDocumentController($element, $uibModal, $ngBootbox, $scope, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q, pdfGenerator, apiProxy, $compile, screenLoader, wfAuth) {
        let
            vm
			
        const allQuestionDataRelations = []
			
        const allQuestions = []
			
        let structure
			
        var pdfGenerator
			
        let organizationId

        // influence,
			
        let verifications
			
        let requirementWatcher
			
        let uiMode = enums.uiMode.view
			
        const translate = $translate.instant
		
        // _.assign(this, modalContext);
        _.assign(this, $scope.$parent.vm)
        vm = this
        vm.submit = submit
        // MODES
        vm.isWorkMode = false
        vm.isPrintMode = vm.context.isPrintMode
        vm.isAggregateMode = !!vm.context.aggregateObjectType
        vm.verificationDocuments = []
        vm.listControls = {}
        vm.relatedContentLists = {}
        vm.getRequirementText = getRequirementText
        vm.deleteOrgRequirement = deleteOrgRequirement
        vm.addOrgRequirement = addOrgRequirement
        vm.getDefaultRequirementText = getDefaultRequirementText
        vm.getOrgSpecificRequirementText = getOrgSpecificRequirementText
        vm.translate = translate
		
        if (vm.item.type == 73) vm.mainStructure = vm.item.childContent
        else if (vm.item.type == 71) vm.mainStructure = vm.item
		
        if (vm.context.influence)
        {
            vm.influence = vm.context.influence
            vm.mainStructure = wfObject.get(vm.influence.objectType + '-' + vm.influence.objectId)
            vm.organizationId = vm.context.influence.organizationId
            vm.isFulfilled = vm.context.influence.fulfilled
            vm.useNewLayout = vm.context.influence.creatorOrganizationId !== 18796

            // vm.mainRequirement = vm.mainStructure.requirement;
			
            verifications = vm.mainStructure.filterVerifications({ organizationId: vm.organizationId })
            if (verifications.length > 0) {
                _.forEach(verifications, (verificationDataRelation) => {
                    Array.prototype.push.apply(vm.verificationDocuments, verificationDataRelation.childContent.filterChildren({ childType: 18 }))
                })
                vm.verificationDocuments = _.orderBy(vm.verificationDocuments, ['createdAt'], ['desc'])
            }
			
            if (!vm.isPrintMode && vm.context.influence.organizationId == wfAuth.getOrganizationId()) {
                vm.isWorkMode = true
                uiMode = enums.uiMode.work
            }
            if (!vm.isPrintMode && vm.context.influence.creatorOrganizationId == wfAuth.getOrganizationId()) {
                vm.isAdminMode = true
                uiMode = enums.uiMode.admin
            }

            activate()
        }
        else if (vm.context.verification)
        {
            uiMode = enums.uiMode.view
            vm.organizationId = vm.context.verification.organizationId
            activate()
        }
        else
        {
            uiMode = enums.uiMode.view
            dataOps.getSubItems(vm.mainStructure, 1, { childrenLoadDepth: 10, aggregate: vm.context.aggregateQuestionAnswers, networkId: vm.context.networkId, aggregateObjectType: vm.context.aggregateObjectType  }).then(() => {
                $timeout(() => {
                    activate()
                })
            })
        }

        // console.log(vm);

        ////////////////

        function activate() {
            $scope.modal = modal
            $scope.wfObject = wfObject
            $scope.dataQuery = dataQuery

            // // For some strange reason the requirement is not instantly available, so we watch for it until it becomes available.
            // requirementWatcher = $scope.$watch(function () {
            // 	return vm.mainStructure.requirement;
            // }, function () {
            // 	if (vm.mainStructure.requirement)
            // 		requirementWatcher();
				
            // 	vm.mainRequirement = vm.mainStructure.requirement;
            // 	// console.log(vm.mainStructure.requirement);
            // });
			
            $element.on('dragstart', 'a.draggablePdf', function (e) {
                const dataTransfer = e.originalEvent.dataTransfer

                if (dataTransfer && dataTransfer.setData) dataTransfer.setData('DownloadURL', $(this).data().downloadurl)
            })
			
            vm.childs = vm.mainStructure.childs
            // console.log(vm.mainStructure)
            vm.selectedId = null //vm.context.onlyShowAll || vm.context.canShowAll ? null : vm.childs[0].childId;
            vm.context.canShowAll = true
			
            structure = vm.mainStructure
            vm.canAddDocStatement = !!vm.mainStructure.conditions.loadRelatedContentByUser
            vm.docStatement = null
            vm.docStatementText = null
            vm.questionsWithAnswerStatement = {}
            vm.questionCommentCompilers = {}
            vm.questionRelatedContentCompilers = {}
			
            _.each(vm.childs, (item, i) => {
                const childs = _.get(item, 'childContent.childs')
                if (childs) {
                    Array.prototype.push.apply(allQuestionDataRelations, childs)
                    if (vm.useNewLayout) {
                        item.orderNumber = i + 1
                        _.each(item.childContent.childs, (subItemDataRelation, j) => {
                            subItemDataRelation.orderNumber = item.orderNumber + '.' + (j + 1)
                        })
                    }
                }
            })
            vm.questionsWithCommentRequirement = {}
            vm.questionsWithRelatedContentRequirement = {}
			
            _.each(allQuestionDataRelations, (item, index) => {
                const
                    requirement = item.getRequirement()
					
                let objectTypeSetting
				
                allQuestions.push(item.childContent)
                if (!vm.useNewLayout) {
                    item.orderNumber = index + 1
                }

                if (requirement && requirement.rule == enums.requirementRule.preferredValue) {
                    vm.questionsWithCommentRequirement[item.wfcid] = true
                }
                else if (requirement
					&& (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
					)) {
                    objectTypeSetting = item.settings && item.settings.objectTypes ? item.settings.objectTypes : []
                    vm.questionsWithRelatedContentRequirement[item.wfcid] = {
                        statement: _.includes(objectTypeSetting, enums.objectType.statement),
                        document: _.includes(objectTypeSetting, enums.objectType.orgDocument),
                    }
                }
            })
			
            if (vm.canAddDocStatement && !vm.isAggregateMode) {
                vm.docStatement = vm.mainStructure.filterRelatedContent({ organizationId: vm.organizationId })[0]
				
                if (vm.docStatement) {
                    vm.docStatement = vm.docStatement.childContent
                    vm.docStatementText =  vm.docStatement.text
                }
            }
			
            vm.loaded = true
            $scope.isWorkMode = vm.isWorkMode

            if (vm.influence) {
                $scope.$on('questionAnswerChanged', (event, data) => {
                    vm.haveToSign = true
                    // console.log(data.wfid, vm.questionsWithCommentRequirement);
                    // console.log(data.wfid, vm.questionCommentCompilers);
                    if (vm.questionsWithCommentRequirement[data.wfid] && vm.questionCommentCompilers[data.wfid]) {
                        // console.log("compiling")
                        vm.questionCommentCompilers[data.wfid].compile()
                    }

                    if (vm.questionsWithRelatedContentRequirement[data.wfid] && vm.questionRelatedContentCompilers[data.wfid]) {
                        // console.log("compiling")
                        vm.questionRelatedContentCompilers[data.wfid].compile()
                    }
                })

                vm.mainStructureRequirementSettings = {
                    uiMode,
                    forOrganizationId: vm.influence.organizationId,
                    requirementOptions: [
                        {
                            name: translate('modules.valueChain.requirements.None'), // "Inget",
                            rule: 0,
                            value: null,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AllQuestionsFulfilled'), // "Samtliga frågor måste uppfyllas",
                            rule: 9,
                            value: null,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AllQuestionsAnswered'), // "Alla frågor måste besvaras",
                            rule: 9,
                            value: '0',
                        },
                    ],
                    onUpdated(updatedRequirement) {
                        $timeout()

                        calculateFulfillment()
                    },
                }

                vm.questionRequirementSettings = {
                    uiMode,
                    forOrganizationId: vm.influence.organizationId,
                    requirementOptions: [
                        {
                            name: translate('modules.valueChain.requirements.None'), // "Inget",
                            rule: 0,
                            value: null,
                        },
                        {
                            name: translate('modules.valueChain.requirements.ManualAssessment'), // "Manuell bedömning",
                            rule: enums.requirementRule.manual,
                            value: null,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AnyAnswer'), // "Valfritt svar",
                            rule: enums.requirementRule.anyValue,
                            value: null,
                        },
                        {
                            name: translate('modules.valueChain.requirements.Yes'), // "Ja",
                            rule: enums.requirementRule.specificValues,
                            value: '4',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.No'), // "Nej",
                            rule: enums.requirementRule.specificValues,
                            value: '3',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.YesWithMoreInfo'), // "Ja med mer information",
                            rule: enums.requirementRule.specificValueWithRelatedContent,
                            value: '4',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.YesOrNotRelevant'), // "Ja eller Ej relevant",
                            rule: enums.requirementRule.specificValues,
                            value: '4,2',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.NoOrNotRelevant'), // "Nej eller Ej relevant",
                            rule: enums.requirementRule.specificValues,
                            value: '3,2',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.Yes_OtherwiseCommentIsRequired'), // "Ja (Annars krävs kommentar)",
                            rule: enums.requirementRule.preferredValue,
                            value: '4',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.No_OtherwiseCommentIsRequired'), // "Nej (Annars krävs kommentar)",
                            rule: enums.requirementRule.preferredValue,
                            value: '3',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.YesOrNotRelevant_OtherwiseCommentIsRequired'), // "Ja eller Ej relevant (Annars krävs kommentar)",
                            rule: enums.requirementRule.preferredValue,
                            value: '4,2',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.NoOrNotRelevant_OtherwiseCommentIsRequired'), // "Nej eller Ej relevant (Annars krävs kommentar)",
                            rule: enums.requirementRule.preferredValue,
                            value: '3,2',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AnyAnswer_YesRequiresMoreInfo'), // "Valfritt svar (Vid Ja krävs mer information)",
                            rule: enums.requirementRule.preferredValueWithRelatedContent,
                            value: '4',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AnyAnswer_NoRequiresMoreInfo'), // "Valfritt svar (Vid Nej krävs mer information)",
                            rule: enums.requirementRule.preferredValueWithRelatedContent,
                            value: '3',
                            selectable: false,
                        },
                        {
                            name: translate('modules.valueChain.requirements.AnyAnswerWithMoreInfo'), // "Valfritt svar med mer information",
                            rule: enums.requirementRule.anyValueWithRelatedContent,
                            value: null,
                            selectable: false,
                        },
                    ],
                    onUpdated(updatedRequirement) {
                        $timeout()

                        calculateFulfillment()
                    },
                }
            }
        }
		
        function submit($event) {
            const
                jqDf = $.Deferred()
				
            let message
				
            let questionsLeft
				
            let questionNumbers

            if (!areAllAnswersLikeRequired()) {
                message = [
                    $translate.instant('modules.valueChain.influence.message_questionsLeft'),
                    '',
                    $translate.instant('modules.valueChain.influence.message_haveToFulfillQuestions'),
                ].join('<br />')

                questionsLeft = vm.questionAnsweringManager.getLocalFulfillmentData().unfulfilled
                questionNumbers = _.map(questionsLeft, (qdr) => {
                    return '<b>' + qdr.orderNumber + '</b>'
                })

                if (questionNumbers.length > 1) message += _.take(questionNumbers, questionNumbers.length - 1).join(', ') + ' ' + $translate.instant('And').toLowerCase() + ' ' + _.last(questionNumbers)
                else message += questionNumbers[0]

                message += '.'

                $ngBootbox.customDialog({
                    title: $translate.instant('modules.valueChain.influence.message_header'),
                    message,
                    onEscape: true,
                    buttons: {
                        primary: {
                            label: 'OK',
                            className: 'btn-primary',
                            callback() {

                            },
                        },
                    },
                })
                return
            }

            // dataOps.getObject({
            // 	objectId: 3530,
            // 	objectType: 67
            // }).then(function (verification) {

            // screenLoader

            // Fix
            modal.verifyItem(vm.mainStructure, vm.influence).then((verification) => {
                let
                    verificationScope
					
                let compiledVerificationHtml
					
                let element
					
                let listener

                if (verification)
                {
                    verificationScope = $scope.$new()
                    compiledVerificationHtml = $compile('<wf-item template-id="52"></wf-item>') // Generates a link function
                    verificationScope.item = verification
                    vm.context.verification = verification
                    element = compiledVerificationHtml(verificationScope) // Executes link function using the new scope
                    // 									jqDf.resolve();
                    // return;					
                    listener = $scope.$on('verifiedContentLoaded', (event, verifiedContent) => {
                        // console.log(verifiedContent);
                        delete vm.context.verification
                        verificationScope.$destroy()
                        listener() // Deregisters the listener because a new listener ($scope.$on) is registered every time the document is signed

                        vm.haveToSign = false

                        screenLoader.show($translate.instant('modules.valueChain.influence.message_calculatingFulfillment'))
                        apiProxy('fulfillment.calculate', {
                            item: dataOps.prepareWfObject(vm.influence),
                        }).then((fulfillmentResult) => {
                            vm.influence.fulfilled = vm.isFulfilled = fulfillmentResult.fulfillment.fulfills
                            vm.influence.fulfillmentProgress = fulfillmentResult.fulfillment.fulfillmentProgress
                            vm.influence.fulfillmentProgressTotal = fulfillmentResult.fulfillment.fulfillmentProgressTotal
                            vm.influence.fulfillmentProgressPercentage = fulfillmentResult.fulfillment.fulfillmentProgressPercentage

                            screenLoader.show($translate.instant('modules.valueChain.influence.message_generatingPdf'))
                            pdfGenerator.fromElement(element.find('div.pdfGeneratorElement'), vm.influence.title + ' ' + moment().format('YYYY-MM-DD'), vm.influence.channelId).then((document) => {
                                // PdfGenerator succeeded
                                dataOps.createSubItemRelation(verification, document, 7).then((dr) => {
                                    let verifications
                                    verifications = vm.mainStructure.filterVerifications({ organizationId: vm.organizationId })
                                    if (verifications.length > 0) {
                                        $timeout(() => {
                                            vm.verificationDocuments = []
                                            _.forEach(verifications, (verificationDataRelation) => {
                                                Array.prototype.push.apply(vm.verificationDocuments, verificationDataRelation.childContent.filterChildren({ childType: 18 }))
                                            })
                                            vm.verificationDocuments = _.orderBy(vm.verificationDocuments, ['createdAt'], ['desc'])
                                        })
                                        screenLoader.hide()
                                    }
                                    jqDf.resolve()
                                })
                            }).fail((res) => {
                                // PdfGenerator failed
                                console.info('pdf failed', res)

                                $timeout()
                                screenLoader.hide()
                                jqDf.resolve()
								
                                $ngBootbox.customDialog({
                                    message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $translate.instant('modules.valueChain.influence.message_pdfFailed') + '</div>',
                                    // onEscape: true,
                                    closeButton: false,
                                    className: 'centerWithIcon',
                                    buttons: {
                                        primary: {
                                            label: $translate.instant('OK'),
                                            className: 'btn-primary',
                                        },
                                    },
                                })
                            })
                        })
                    }) // Calling the listener to deregister it
                }
                else
                {
                    screenLoader.hide()
                    jqDf.resolve(false)
                }
            })

            return jqDf.promise()

            // return;

            // var jqDf = $.Deferred();
            // modal.verifyItem(vm.mainStructure).then(function (res) {

            // 	wfObject.inject(res);
            // 	verificationScope.item = wfObject.get(res.wfid);
            // 	jqDf.resolve();
            // 	// modal.openItem({
            // 	// 	item: wfObject.get(res.wfid),
            // 	// 	settings: {
            // 	// 		templateId: 52
            // 	// 	}
            // 	// });

            // 	// 	jqDf.resolve();
            // 	// });
            // });
			
            // return jqDf.promise();
            // var element = $($event.target).closest('.pdfGeneratorElement');
            // if (element.length)
            // 	pdfGenerator.fromElement(element);
            // else
            // 	console.log('No element found to generate PDF from.')
        }
		
        function areAllAnswersLikeRequired() {
            return vm.questionAnsweringManager.fulfillsAllLocally()
        }

        function getRequirementText(item) {
            let
                output
				
            const requirement = item.requirement
				
            let reqValue

            if (requirement) {
                output = $translate.instant('Requirement') + ': '

                // switch (requirement.rule) {
                // 	case enums.requirementRule.specificValues:
                // }

                reqValue = requirement.value

                if (~reqValue.indexOf(',')) {
                    output += _.map(reqValue.split(','), (reqPartValue) => {
                        return $translate.instant('QuestionAnswerTypesById.' + reqPartValue)
                    }).join(' ' + $translate.instant('Or').toLowerCase() + ' ')
                }
                else output += $translate.instant('QuestionAnswerTypesById.' + reqValue)

                if (requirement.organizationId && requirement.innerRequirement) {
                    output += ' (' + $translate.instant('Exception') + ')'
                }
            }

            return output
        }

        function getDefaultRequirementText(item) {
            let
                output
				
            let requirement = item.requirement
				
            let reqValue

            if (requirement) {
                output = $translate.instant('Requirement') + ': '

                // switch (requirement.rule) {
                // 	case enums.requirementRule.specificValues:
                // }

                if (requirement.innerRequirement) requirement = requirement.innerRequirement

                reqValue = requirement.value

                if (~reqValue.indexOf(',')) {
                    output += _.map(reqValue.split(','), (reqPartValue) => {
                        return $translate.instant('QuestionAnswerTypesById.' + reqPartValue)
                    }).join(' ' + $translate.instant('Or').toLowerCase() + ' ')
                }
                else output += $translate.instant('QuestionAnswerTypesById.' + reqValue)
            }

            return output
        }

        function getOrgSpecificRequirementText(item) {
            let
                output
				
            const requirement = item.requirement
				
            let reqValue

            if (requirement) {
                output = $translate.instant('Exception') + ':<br />'

                // switch (requirement.rule) {
                // 	case enums.requirementRule.specificValues:
                // }

                if (!(requirement.organizationId && requirement.innerRequirement)) return

                reqValue = requirement.value

                if (~reqValue.indexOf(',')) {
                    output += _.map(reqValue.split(','), (reqPartValue) => {
                        return $translate.instant('QuestionAnswerTypesById.' + reqPartValue)
                    }).join(' ' + $translate.instant('Or').toLowerCase() + ' ')
                }
                else output += $translate.instant('QuestionAnswerTypesById.' + reqValue)
            }

            return output
        }

        function addOrgRequirement(item) {
            modal.editRequirement({
                item,
                forOrganizationId: vm.influence.organizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'), // "Inget",
                        rule: enums.requirementRule.notDefined,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer'), // "Valfritt svar",
                        rule: enums.requirementRule.anyValue,
                        value: null,
                    },
                    // {
                    // 	name: "Ja",
                    // 	rule: enums.requirementRule.specificValues,
                    // 	value: "4"
                    // },
                    // {
                    // 	name: "Nej",
                    // 	rule: enums.requirementRule.specificValues,
                    // 	value: "3"
                    // },
                    // {
                    // 	name: "Ja eller Ej relevant",
                    // 	rule: enums.requirementRule.specificValues,
                    // 	value: "4,2"
                    // },
                    // {
                    // 	name: "Nej eller Ej relevant",
                    // 	rule: enums.requirementRule.specificValues,
                    // 	value: "3,2"
                    // },
                    // {
                    // 	name: "Ja (Annars kräv kommentar)",
                    // 	rule: enums.requirementRule.preferredValue,
                    // 	value: "4"
                    // },
                    // {
                    // 	name: "Nej (Annars kräv kommentar)",
                    // 	rule: enums.requirementRule.preferredValue,
                    // 	value: "3"
                    // }
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    calculateFulfillment()

                },
            })
        }

        function deleteOrgRequirement(item) {
            let jqDf

            if (item.requirement.organizationId) {
                jqDf = $.Deferred()

                $ngBootbox.customDialog({
                    title: $translate.instant('Delete'),
                    message: $translate.instant('ConfirmDeletionOrganizationSpecificRequirement'),
                    onEscape: true,
                    buttons: {
                        cancel: {
                            label: $translate.instant('Cancel'),
                            className: 'btn-default',
                            callback() {
                                jqDf.resolve()
                            },
                        },
                        primary: {
                            label: $translate.instant('OK'),
                            className: 'btn-primary',
                            callback() {
                                return dataOps.destroy(item.requirement).then(() => {
                                    item.requirement = item.requirement.innerRequirement
									
                                    calculateFulfillment()

                                    jqDf.resolve()
                                })
                            },
                        },
                    },
                })
                return jqDf.promise()
            }
        }

        function calculateFulfillment() {
            vm.calculatingFulfillment = true
            $timeout()

            dataOps.calculateFulfillment(vm.influence).then((res) => {
                vm.calculatingFulfillment = false
                $timeout(() => {
                    vm.mainCompiler.compile()
                    if (res.fulfillment) {
                        vm.isFulfilled = res.fulfills
                        vm.influence.containsSpecialRequirements = res.fulfillment.containsSpecialRequirements
                        vm.influence.fulfilled = res.fulfillment.fulfills
                        vm.influence.fulfillmentHasProgress = res.fulfillment.fulfillmentHasProgress
                        vm.influence.fulfillmentProgress = res.fulfillment.fulfillmentProgress
                        vm.influence.fulfillmentProgressTotal = res.fulfillment.fulfillmentProgressTotal
						
                        if (vm.influence.fulfillmentProgressTotal > 0) vm.influence.fulfillmentProgressPercentage = Math.floor((vm.influence.fulfillmentProgress / vm.influence.fulfillmentProgressTotal * 100.0))
                    }
                })
            })
        }
    }
})()
