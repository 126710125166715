import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { hexToRgbA } from '@worldfavor/constants/colors'
import { DataExplorerTableWithPage } from './DataExplorerTable'
import DataExplorerFilterBar from './DataExplorerFilterBar'
import { loadItem, loadSubItemsPage } from '@worldfavor/portal/actions/dataThunk'
import { useDebouncedCallback } from 'use-debounce'
import { objectType } from '@worldfavor/constants/enums'
import { StaticIds, FilterTypes } from '@worldfavor/constants'
import { useSelector, useDispatch } from 'react-redux'
import { getSubNodesFromNodeId } from '@worldfavor/portal/selectors/dataSelector'
import { injectIntl, FormattedMessage } from 'react-intl'
import Image from '@worldfavor/components/Image'
import Grid from '@material-ui/core/Grid'
import Header from '@worldfavor/components/Header'
import SearchBarWithMenu from '@worldfavor/components/SearchBar/SearchBarWithMenu'
import Loading from '@worldfavor/portal/scenes/Loading'
import classNames from 'classnames'
import { MAX_PAGE_CONTENT_WIDTH } from '@worldfavor/constants'
import useDataFetcher from '@worldfavor/hooks/dataFetcher'
import ErrorDialog from '../Dialog/ErrorDialog'
import { useDialogState } from '@worldfavor/hooks'

const useStyles = makeStyles(theme => ({
    [`@media (min-width: ${MAX_PAGE_CONTENT_WIDTH}px)`]: {
        maxWidthGrid: props => ({
            maxWidth: props.disableMaxWidthGrid ? null : MAX_PAGE_CONTENT_WIDTH,
        }),
    },
    backgroundOverlay: {
        backgroundColor: hexToRgbA(theme.palette.secondary.main, 0.92),
        width: '100%',
        height: '100%',
    },
    headerContainer: {
        marginTop: 50,
        marginBottom: 50,
    },
    headerTitle: {
        color: theme.palette.common.white,
        fontSize: 42,
        fontWeight: theme.typography.fontWeightLight,
    },
    toolsSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    menuButton: {
        background: hexToRgbA(theme.palette.common.white, 0.20),
        '&:hover': {
            background: hexToRgbA(theme.palette.common.white, 0.25),
        },
    },
    menuButtonText: {
        color: theme.palette.common.white,
    },
    menuIcon: {
        color: theme.palette.common.white,
    },
    searchBar: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        border: 'none',
    },
    onFocusSearchBarBorder: {},
    tableSettingsIcon: {
        fontSize: theme.typography.fontSizes.medium,
    },
    filterBarWrapper: {
        position: 'relative',
        minHeight: 60,
        top: -30,
        opacity: 1,
        transition: 'opacity 0.2s ease-in-out',
    },
    filterBar: {},
    hiddenFilterBarStyles: {
        opacity: 0,
    },
}))

const DataExplorer = (props) => {
    const { intl, title, coverImageUrl } = props
    const menuOptions = getMenuOptions()
    const rowsPerPageOptions = [5, 10, 25, 50, 75, 100] // TODO
    const classes = useStyles(props)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const dataLoading = useSelector(state => state.apiCallsInProgress > 0)
    const [dataSource, setDataSource] = useState(menuOptions[0])

    const [debouncedSearchText, setDebouncedSearchText] = useState('')
    const [setSearchTextDebounced] = useDebouncedCallback((value) => { setDebouncedSearchText(value) }, 500)
    const [showFilterBar, setShowFilterBar] = useState(false)
    const [filters, setFilters] = useState(null)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useDialogState(false)

    const networks = useSelector(
        state => getSubNodesFromNodeId(state, StaticIds.OwnedNetworks)).map(network => network.node)

    useEffect(() => {
        if (dataSource.filters) {
            const networkFilterDropdown = getNetworkDropdownFilter(networks)
            setFilters([networkFilterDropdown])
            setShowFilterBar(true)
        }
        else {
            setShowFilterBar(false)
            setFilters(null)
            if (selectedFilters && selectedFilters.length > 0) {
                setSelectedFilters([])
            }
        }
    }, [dataSource])

    const fetchData = (source, pageNumber, pageSize) => dispatch(
        loadSubItemsPage(
            dataSource.id,
            pageNumber,
            pageSize,
            dataSource.id,
            source,
            {},
            [
                {
                    filterType: FilterTypes.TextSearch,
                    stringValues: [searchText],
                },
                ...(dataSource.filters ? selectedFilters : []),
            ],
        ),
    )

    const {
        resultCount,
        pageNumber,
        pageSize,
        pageId,
        requestId,
        error,
        setPageNumber,
        setPageSize,
    } = useDataFetcher(fetchData, [debouncedSearchText, selectedFilters, dataSource], {
        onError: () => !isErrorDialogOpen && openErrorDialog(),
    })

    useEffect(() => {
        async function fetchNetworks() {
            const [type, id] = StaticIds.OwnedNetworks.split('-')
            try {
                await dispatch(loadItem(id, type))
            } catch (e) {
                // TODO handle error
            }
        }

        fetchNetworks()
    }, [StaticIds.OwnedNetworks])

    function onAfterFilterChanged(selectedFilters) {
        if (selectedFilters && selectedFilters.length > 0) {
            const filterParams = [{
                filterType: FilterTypes.NetworkId,
                stringValues: selectedFilters.map(filter => filter.id).filter(id => id !== null),
            }]
            setSelectedFilters(filterParams)
        }
    }

    function getNetworkDropdownFilter(networks) {
        const filterOptions = networks.map(network => ({ id: network.wfid, label: network.title }))
        const sortedFilterOptions = filterOptions.sort((a, b) => a.label.localeCompare(b.label))

        const networkFilterDropdown = {
            renderAs: 'dropdown',
            label: <FormattedMessage id={'general.network'} values={{ count: networks.length }} />,
            filterOptions: sortedFilterOptions,
        }

        return networkFilterDropdown
    }

    function getMenuOptions() {
        const options = [
            { objectType: objectType.orgActivity, icon: 'far fa-calendar-check', id: StaticIds.Activities },
            { objectType: objectType.statement, icon: 'fas fa-quote-right', id: StaticIds.Comments },
            { objectType: objectType.link, icon: 'fas fa-link', id: StaticIds.Links },
            { objectType: objectType.embed, icon: 'fas fa-play-circle', id: StaticIds.Embeds },
            { objectType: objectType.certificate, icon: 'fas fa-certificate', id: StaticIds.Certificates },
            { objectType: objectType.person, icon: 'fas fa-user', id: StaticIds.People },
            { objectType: objectType.dateItem, icon: 'fas fa-calendar', id: StaticIds.DateItems },
            { objectType: objectType.question, icon: 'fas fa-comment', id: StaticIds.Questions },
            { objectType: objectType.orgDocument, icon: 'fas fa-file', id: StaticIds.Documents },
            { objectType: objectType.structure, icon: 'fas fa-file', id: StaticIds.Structures },
            { objectType: objectType.measure, icon: 'fas fa-line-chart', id: StaticIds.Measures },
            { objectType: objectType.relativeMeasure, icon: 'fas fa-calculator', id: StaticIds.RelativeMeasures },
            { objectType: objectType.location, icon: 'fas fa-map-marker', id: StaticIds.Locations },
            { objectType: objectType.productionSite, icon: 'fas fa-industry', id: StaticIds.ProductionSites },
            { objectType: objectType.productService, icon: 'fas fa-cube', id: StaticIds.OwnedProducts },
            { objectType: objectType.individual, label: intl.formatMessage({ id: 'modules.customItemTypes.colleague.p' }), icon: 'fas fa-user', id: StaticIds.Colleagues },
            { objectType: objectType.individual, label: intl.formatMessage({ id: 'modules.customItemTypes.contributor.p' }), icon: 'fas fa-user', id: StaticIds.NetworkContributors, filters: ['networks'] },
        ]

        return options.map((option) => {
            if (!option.label && option.objectType) {
                return Object.assign(option, { label: intl.formatMessage({ id: 'modules.itemTypes.' + option.objectType + '.p' }) })
            }
            return option
        })
    }

    function onSearchChange(event) {
        setSearchTextDebounced(searchText)
        setSearchText(event.target.value)
    }

    function onClear() {
        setSearchTextDebounced('')
        setSearchText('')
    }

    function onPageChange(event, pageNumber) {
        setPageNumber(pageNumber)
    }

    function onChangeRowsPerPage(event) {
        setPageSize(event.target.value)
    }

    function onMenuChange(option) {
        onClear()
        setPageNumber(0)
        setDataSource(option)
    }

    if (error) {
        return <ErrorDialog open={isErrorDialogOpen} closeDialog={closeErrorDialog} showReloadButton showContactSupport />
    }

    return (
        <>
            <Image
                src={coverImageUrl}
                style={{ minHeight: 300, paddingBottom: 60 }}
                overlay={<div className={classes.backgroundOverlay} />}
                size={'cover'}
            >
                <Grid container justify={'center'}>
                    <Grid classes={{ item: classes.maxWidthGrid }} item xs={12}>
                        {
                            title && (
                                <div data-cy="scene-title" className={classes.headerContainer}>
                                    <Header title={(<span className={classes.headerTitle}>{title}</span>)} />
                                </div>
                            )
                        }
                        <SearchBarWithMenu
                            value={searchText}
                            onSearchChange={onSearchChange}
                            onClear={onClear}
                            menuOptions={menuOptions}
                            onMenuChange={onMenuChange}
                            selectedMenuOption={dataSource}
                            isLoading={dataLoading}
                            classes={{
                                searchBar: classes.searchBar,
                                onFocusSearchBarBorder: classes.onFocusSearchBarBorder,
                                menuButton: classes.menuButton,
                                menuButtonText: classes.menuButtonText,
                                menuIcon: classes.menuIcon,
                            }}
                        />
                    </Grid>
                </Grid>
            </Image>

            <div className={classNames(classes.filterBarWrapper, !showFilterBar && classes.hiddenFilterBarStyles)}>
                { showFilterBar && filters && filters.length > 0 && (
                    <DataExplorerFilterBar
                        classes={{ gridItem: classes.maxWidthGrid, appBar: classes.filterBar }}
                        filters={filters}
                        onAfterFilterChanged={onAfterFilterChanged}
                    />
                )}
            </div>

            { (dataLoading || !requestId || !pageId) && <div data-cy={'data-explorer-table-loader'}><Loading /></div> }

            {
                (!dataLoading && requestId && pageId) && (
                <>
                    <Grid container justify={'center'}>
                        <Grid classes={{ item: classes.maxWidthGrid }} item xs={12}>
                            <DataExplorerTableWithPage
                                requestId={requestId}
                                pageId={pageId}
                                rowsPerPage={pageSize}
                                page={pageNumber}
                                onPageChange={onPageChange}
                                onSearchChange={onSearchChange}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                searchText={searchText}
                                dataSource={dataSource}
                                resultCount={resultCount}
                                enableSparsePage
                            />
                        </Grid>
                    </Grid>
                </>
                )
            }
        </>
    )
}

export default injectIntl(DataExplorer)
