// @flow
import * as React from 'react'
import ChartistGraph, { GraphType } from './index'
import { formatValue, getSeriesData, getVerticalSeriesAndLabels } from './helper'
import _ from 'lodash'
import ctColor from './plugins/ctColor'
import ctPoint from './plugins/ctPoint'
import ctTooltip from './plugins/ctTooltip'
import ctDynamicBarWidth from './plugins/ctDynamicBarWidth'
import type { ChartistData, ChartistOptions, ChartTypes } from '@worldfavor/types/Chartist'
import { DEFAULT_CHART_COLORS } from '@worldfavor/constants'

type Props = {
  data: ChartistData,
  options?: ChartistOptions,
  type: ChartTypes,
  colors?: Array<string>,
  style?: { [string]: mixed },

  selectedPeriod?: string | null,
  defaultUnitSymbol?: string,

  valueProperties?: Array<string>,

  enableAnimations?: boolean,
  showPoint?: boolean,
  showLegend?: boolean,
  showTooltips?: boolean,
  showInfoMessage?: boolean,
  useFullWidth?: boolean,
  addLinearGradientElement?: boolean,
  chartHeight?: number,
  showYGrid?: boolean,
  showYLabels?: boolean,
  showXGrid?: boolean,
  showXLabels?: boolean,
  showLabelPieChart?: boolean,
}

class WfGraph extends React.PureComponent<Props> {

  static defaultProps = {
      showYGrid: true,
      showYLabels: true,
      showXGrid: true,
      showXLabels: true,
      showLabelPieChart: true,
      type: GraphType.Line,
  }

  staticPlugins = {
      legendPlugin: null,
      positionPlugin: null,
      infoMessagePlugin: null,
      responsiveLabelsPlugin: null,
      dynamicBarWidthPlugin: ctDynamicBarWidth(),
      linearGradientPlugin: null,
      seriesSelectionPlugin: null,
      animatePlugin: null,
  }

  chart = null

  // TODO define all data configuration here
  _prepareData = () => {
      const { data, type, selectedPeriod, defaultUnitSymbol } = this.props

      let preparedData = {
          ...data,
          series: data.series.map((dataset, index) => {
              return {
                  ...dataset,
                  data: getSeriesData({ dataset: dataset.data, unit: defaultUnitSymbol, valuePropertyKey: 'value', legendLabel: dataset.legendLabel }),
                  color: dataset.color
                      || DEFAULT_CHART_COLORS[index]
                      || DEFAULT_CHART_COLORS[index - DEFAULT_CHART_COLORS.length]
                      || DEFAULT_CHART_COLORS[index - DEFAULT_CHART_COLORS.length * 2],
              } },
          ),
      }

      if (type === GraphType.Pie || type === GraphType.Donut) {
          preparedData = getVerticalSeriesAndLabels(preparedData,
              preparedData.labels.indexOf(selectedPeriod || _.last(preparedData.labels)))
      }

      return preparedData
  }

  _getPlugins = (): Array<*> => {
      const {
          type,
          enableAnimations,
          showPoint,
          showLegend,
          showTooltips,
          showInfoMessage,
          useFullWidth,
          addLinearGradientElement,
          valueProperties,
          colors,
      } = this.props

      const chartBackgroundColor = 'white'

      const colorPlugin = ctColor(colors && colors.length >= 1 ? { colors } : {})
      const tooltipPlugin = ctTooltip({
          appendToBody: false,
          pointClass: 'ct-custom-point',
          //If the data is aggregated then round the numbers
          allowRoundNumber: valueProperties &&
        valueProperties.some(property => property === 'average' || property === 'sum'),
      })
      const pointPlugin = ctPoint({
          circleShapeOptions: { radius: 6, enableAnimations, fillColor: chartBackgroundColor },
      })

      // TODO add support for more plugins
      /* eslint-disable */
    /*
      legendPlugin = Chartist.plugins.legend({ position: 'bottom', clickable: true }),
      positionPlugin = Chartist.plugins.positionChartCenter({ gridColor: ColorLuminance(options.chartBackgroundColor, 0.1) }),
      infoMessagePlugin = Chartist.plugins.infoMessage({ message: options.messageToDisplay, allowToDismissMessage: options.allowToDismissMessage }),
      responsiveLabelsPlugin = Chartist.plugins.responsiveLabels(),
      linearGradientPlugin = Chartist.plugins.linearGradient({ linearGradientId: _.uniqueId('chart-area-gradient_'), fromColor: ColorLuminance(options.chartBackgroundColor, options.asEmptyState ? -0.2 : -0.5), toColor: options.chartBackgroundColor }),
      seriesSelectionPlugin = Chartist.plugins.seriesSelection({ data: options.originalData, verticalSeries: options.verticalSeries, selectedVerticalSeries: options.selectedVerticalSeries, getVerticalSeriesAndLabels: getVerticalSeriesAndLabels, translatePlugin: $translate }),
      animatePlugin = Chartist.plugins.animate()
     */
    /* eslint-enable */

      const plugins = [
          colorPlugin,
          enableAnimations && this.staticPlugins.animatePlugin,
          showTooltips && tooltipPlugin,
          showLegend && this.staticPlugins.legendPlugin,
          showPoint && pointPlugin,
          showInfoMessage && this.staticPlugins.infoMessagePlugin,
          addLinearGradientElement && this.staticPlugins.linearGradientPlugin,
      ]

      if (type !== GraphType.Pie && type !== GraphType.Donut) {
          plugins.push(this.staticPlugins.responsiveLabelsPlugin)
      }
      else {
          plugins.push(this.staticPlugins.seriesSelectionPlugin)
      }

      if (type === GraphType.Line && !useFullWidth) {
          plugins.push(this.staticPlugins.positionPlugin)
      }

      if (type === GraphType.Bar || type === GraphType.StackedBar) {
          plugins.push(this.staticPlugins.dynamicBarWidthPlugin)
      }

      return plugins.filter(plugin => plugin)
  }

  _getLineChartOptions = () => {
      const {
          chartHeight,
          showYGrid,
          showYLabels,
          useFullWidth,
          showXGrid,
          showXLabels,
      //highestValue,
      //lowestValue,
      } = this.props

      return {
          height: chartHeight,
          fullWidth: !useFullWidth && !showXLabels ? true : useFullWidth,
          axisX: {
              showGrid: showXGrid,
              showLabel: showXLabels,
              position: 'end',
              /*offset: showXLabels ? 40 : 5,
        labelOffset: { y: 10 },*/
          },
          axisY: {
              showGrid: showYGrid,
              showLabel: showYLabels,
              //offset: showYLabels ? 35 : 0,
              //labelOffset: { x: -10, y: 15 },
              position: 'start',
              //high: highestValue === lowestValue ? highestValue + 2 : highestValue,
              //low: lowestValue,
              labelInterpolationFnc: (value: number) => formatValue(value, true, true, true),
          },
      }
  }

  _getBarChartOptions = () => {
      const {
          chartHeight,
          showYGrid,
          showYLabels,
          showXGrid,
          showXLabels,
      //highestValue,
      //lowestValue,
      } = this.props

      return {
          height: chartHeight,
          seriesBarDistance: 20,
          fullWidth: !showXLabels,
          axisX: {
              showLabel: showXLabels,
              showGrid: showXGrid,
              /*offset: showXLabels ? 40 : 5,
        labelOffset: { y: 10 },*/
          },
          axisY: {
              showGrid: showYGrid,
              showLabel: showYLabels,
              offset: showYLabels ? 35 : 0,
              //labelOffset: { x: -10, y: 15 },
              position: 'start',
              //high: highestValue === lowestValue ? highestValue + 2 : highestValue,
              //low: lowestValue,
              labelInterpolationFnc: (value: number) => formatValue(value, true, true, true),
          },
      }
  }

  _getPieChartOptions = () => {
      const {
          showLabelPieChart,
          options = {},
      } = this.props
      return {
          chartPadding: { top: 20, left: 20, bottom: 30, right: 20 },
          classNames: {
              chartPie: 'ct-chart-pie',
              chartDonut: 'ct-chart-donut',
              series: 'ct-series',
              slicePie: 'ct-slice-pie',
              sliceDonut: 'ct-slice-donut',
          },
          ignoreEmptyValues: false,
          labelPosition: 'outside',
          labelDirection: 'explode',
          labelInterpolationFnc: (options: { unit: string, sum: number, value: number, name: string }, index) => {
              let label
              let space = ''
              let { unit } = options
              unit ? space = options.unit === '%' ? '' : ' ' : unit = ''

              if (options.sum) {
                  label = _.round(options.value / options.sum * 100, 1) + '%'
              }
              else {
                  label = formatValue(options.value, true, true, true) + space + unit
              }

              return this.props.data.series[index].legendLabel + ' ' + label
          },

          labelOffset: options.labelOffset ? options.labelOffset : showLabelPieChart ? 10 : 0,
          reverseData: false,
          showLabel: showLabelPieChart,
          startAngle: 0,
          total: undefined,
          width: undefined,
          height: undefined,
      }
  }

  // TODO define all options configuration here
  _prepareOptions = () => {
      const { type, options } = this.props

      let typeSpecificOptions = {}
      switch (type) {
          case GraphType.Line:
              typeSpecificOptions = this._getLineChartOptions()
              break
          case GraphType.Bar:
          case GraphType.StackedBar:
              typeSpecificOptions = this._getBarChartOptions()
              break
          case GraphType.Pie:
          case GraphType.Donut:
              typeSpecificOptions = this._getPieChartOptions()
              break
          default:
              break
      }

      return {
          ...typeSpecificOptions,
          ...options,
          showArea: true,
          fullWidth: true,
          plugins: this._getPlugins(),
      }
  }

  _getResponsiveOptions = () => {
      const { type } = this.props
      if (type === GraphType.Line || type === GraphType.Bar
      || type === GraphType.StackedBar) {
          return [
              ['screen and (min-width: 641px) and (max-width: 1024px)', {}],
          ]
      }
      return []
  }

  render() {
      const { type, style } = this.props

      return (
          <ChartistGraph
              ref={ref => this.chart = ref}
              className="ct-octave"
              style={style}
              type={type}
              data={this._prepareData()}
              options={this._prepareOptions()}
              responsiveOptions={this._getResponsiveOptions()}
          />
      )
  }
}

export default WfGraph
