import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fab from '@material-ui/core/Fab'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import red from '@material-ui/core/colors/red'

import SearchCount from '@worldfavor/components/SearchBar/SearchCount'
import EmptyState from '@worldfavor/components/EmptyState'
import SearchBar from '@worldfavor/components/SearchBar'
import DeleteDialog from '@worldfavor/components/Dialog/DeleteDialog'
import { useDialogState } from '@worldfavor/hooks'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import OrganizationList from '../List/OrganizationList'
import RemoveOrganizationWarningDialog from './RemoveOrganizationWarningDialog'

const useStyles = makeStyles(theme => ({
    leftPanel: {
        flex: 0.35,
        minWidth: 300,

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.shadows[1],
        marginBottom: 2,
    },
    searchBox: {
        flex: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    searchBar: {
        border: 'none !important',
        backgroundColor: fade(theme.palette.common.white, 1),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.95),
        },
    },
    panelContainer: {
        flex: 1,
        position: 'relative',
    },
    listContainer: {
        boxShadow: theme.shadows[1],
        overflow: 'auto',
    },
    emptyOrganizationList: {
        height: '100%',
        padding: 32,
    },
    emptyStateOrganizationFab: {
        width: 32,
        height: 32,
        minHeight: 32,
        margin: '0 5px',
    },
    addOrganizationFab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    organizationMenu: {
        minWidth: 180,
    },
    deleteIcon: {
        color: red[600],
    },
}))

const MapperLeftPanel = (props) => {
    const { organizations, onAddOrganization, nodes, search, onSearchChange, onDeleteOrganization } = props
    const classes = useStyles(props)
    const [searchCount, setSearchCount] = useState(Object.values(organizations).length)
    const [organizationMenuAnchor, openOrganizationMenu, closeOrganizationMenu] = useMenuState(null)
    const [menuOrganization, setMenuOrganization] = useState(null)

    const [deleteOrganizationDialogOpen, openDeleteOrganizationDialog, closeDeleteOrganizationDialog] = useDialogState(false)
    const [removeOrgWarningDialogOpen, openRemoveOrgWarningDialog, closeRemoveOrgWarningDialog] = useDialogState(false)
    const [dialogOrganization, setDialogOrganization] = useState(null)
    const [deleteOrganization, setDeleteOrganization] = useState(null)
    const [deleting, setDeleting] = useState(false)

    function _onSearchChange(event) {
        onSearchChange(event.target.value)
    }

    function _onSearchClear() {
        onSearchChange('')
    }

    function renderSearchCount() {
        return (
            <SearchCount
                count={searchCount}
                total={Object.values(organizations).length}
            />
        )
    }

    function onDataFiltered(data) {
        setSearchCount(data.length)
    }

    function onOrganizationMenuClick(event, organization) {
        setMenuOrganization(organization)
        openOrganizationMenu(event)
    }

    function _closeOrganizationMenu() {
        closeOrganizationMenu()
        setMenuOrganization(null)
    }

    function onDeleteOrganizationDialogClose() {
        setDeleting(false)
        setDeleteOrganization(null)
        closeDeleteOrganizationDialog()
    }

    function onRemoveOrgWarningDialogClose() {
        setDialogOrganization(null)
        closeRemoveOrgWarningDialog()
    }

    function _onDeleteOrganization() {
        const organizationNodesInTheTree = Object.values(nodes).filter(node => node.properties.organizationWfid === menuOrganization.wfid)

        if (organizationNodesInTheTree && organizationNodesInTheTree.length) {
            setDialogOrganization(menuOrganization)
            openRemoveOrgWarningDialog()
        } else {
            setDeleteOrganization(menuOrganization)
            openDeleteOrganizationDialog()
        }

        closeOrganizationMenu()
    }

    async function deleteOrganizationFromList() {
        if (!deleteOrganization) {
            return
        }

        setDeleting(true)
        await onDeleteOrganization(deleteOrganization.wfid)
        onDeleteOrganizationDialogClose()
    }

    return (
        <div className={classes.leftPanel}>
            <div className={classes.toolbar}>
                <div className={classes.searchBox}>
                    <SearchBar
                        onClear={_onSearchClear}
                        classes={{ root: classes.searchBar }}
                        renderCount={renderSearchCount}
                        compact
                        rounded
                        inputProps={{
                            value: search,
                            onChange: _onSearchChange,
                        }}
                    />
                </div>
            </div>

            <div className={classNames(classes.panelContainer, classes.listContainer)}>
                {
                    organizations.length > 0 ? (
                        <OrganizationList
                            data={organizations}
                            searchText={search}
                            onDataFiltered={onDataFiltered}
                            style={{ paddingBottom: 76 }}
                            onClickMore={onOrganizationMenuClick}
                            draggable
                            showMore
                        />
                    ) : (
                        <EmptyState
                            className={classes.emptyOrganizationList}
                            size={'large'}
                            iconClass={'fas fa-building'}
                            title={<FormattedMessage id={'supplyChain.mapper.emptyOrganizationList.title'} />}
                            description={(
                                <FormattedMessage
                                    id={'supplyChain.mapper.emptyOrganizationList.description'}
                                    values={
                                        {
                                            button: (
                                                <Fab
                                                    color={'primary'}
                                                    className={classes.emptyStateOrganizationFab}
                                                    onClick={onAddOrganization}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            ),
                                        }
                                    }
                                />
                            )}
                        />
                    )
                }
            </div>

            <Fab
                color={'primary'}
                className={classes.addOrganizationFab}
                onClick={onAddOrganization}
            >
                <AddIcon />
            </Fab>

            <Menu
                id={`contextual-organization-menu`}
                anchorEl={organizationMenuAnchor}
                open={Boolean(organizationMenuAnchor)}
                onClose={_closeOrganizationMenu}
                classes={{ paper: classes.organizationMenu }}
            >
                <MenuItem onClick={_onDeleteOrganization}>
                    <ListItemIcon>
                        <DeleteIcon className={classes.deleteIcon} />
                    </ListItemIcon>
                    <FormattedMessage id={'general.remove'} />
                </MenuItem>
            </Menu>

            <DeleteDialog
                open={deleteOrganizationDialogOpen}
                title={deleteOrganization && (
                    <FormattedMessage
                        id={'supplyChain.mapper.removeNode.one.title'}
                        values={{ companyName: deleteOrganization.name }}
                    />
                )}
                subtitle={<FormattedMessage id={'supplyChain.mapper.removeNode.one.description'} />}
                onCancel={onDeleteOrganizationDialogClose}
                onDelete={deleteOrganizationFromList}
                deleting={deleting}
            />

            {
                removeOrgWarningDialogOpen && (
                    <RemoveOrganizationWarningDialog
                        open={removeOrgWarningDialogOpen}
                        onClose={onRemoveOrgWarningDialogClose}
                        organization={dialogOrganization}
                    />
                )
            }
        </div>
    )
}

export default MapperLeftPanel
