//@flow
import _ from 'lodash'

export function capitalize(string) {
    if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
        throw new Error('capitalize(string) expects a string argument.')
    }

    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Creates a function that can be passed into Array.map()
 * that will perform a search on every object in the array.
 *
 * Arguments:
 * filterKeys - array of strings with the keys representing the props to search in
 * searchText - search string
 * minSearchLength - minimum search length, defaults to 2
 *
 * The function passed into Array.map() returns:
 * - null for any item not matching the search string
 * - an object for any item matching the search string
 *  {
 *     item - the item searched
 *     match: {
 *        search - the search string
 *        keys - array of strings, which keys that matched the search string
 *     }
 *  }
 *
 * If searchText is falsy or too short the return value
 * for the map function will be { item, match: { } } for every item
 */

export const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const regExpSearchMapper = (filterKeys, searchText, minSearchLength = 2) => {
    const escapedText = escapeRegExp(searchText)

    function onArrayMap(item)  {
        const searchRegExp = new RegExp(escapedText, 'i')
        if (!searchText || (minSearchLength && minSearchLength > 0 && searchText.length < minSearchLength)) {
            return { item, match: {} }
        }
        const matchingKeys = filterKeys.filter((key: string | (any) => string) => {
            let value
            if (typeof key === 'function') {
                value = key(item) || ''
            }
            else {
                value = _.get(item, key) || ''
            }
            if (!value) {
                return false
            }
            return (value + '').match(searchRegExp)
        })
        if (matchingKeys.length === 0) {
            return null
        }
        return {
            item,
            match: {
                search: searchText,
                keys: matchingKeys,
            },
        }
    }
    return onArrayMap
}

export const uniq = (array: Array<any>) => {
    const uniqueSet = new Set(array)
    return [...uniqueSet]
}

export const addSpaceBetweenWords = (text) => {
    return `${(text.replace(/([A-Z])/g, ' $1').trim()).replace('_', ' ')}` // adds space before capital letters (in case of camelCase) and replaces _ with a space (in case of snake_case)
}

export const sortAlphabetically = (array, key) => {
    if (!array || !array.length) {
        return []
    }

    if (typeof array[0] === 'object') {
        if (!key) {
            key = 'title'
        }
        return array.sort((a, b) => ((_.get(a, key) || '').toLowerCase() > (_.get(b, key) || '').toLowerCase()) ? 1 : -1)
    }
    else {
        return array.sort((a, b) => (a > b) ? 1 : -1)
    }
}

export const sortNaturally = (array, key) => {
    if (!array || !array.length) {
        return []
    }

    if (!''.localeCompare) {
        return sortAlphabetically(array, key)
    }

    if (typeof array[0] === 'object') {
        if (!key) {
            key = 'title'
        }

        return array.sort((a, b) => {
            return (_.get(a, key) || '').localeCompare((_.get(b, key) || ''), undefined, {
                numeric: true,
                sensitivity: 'base',
            })
        })
    }
    else {
        return array.sort((a, b) => {
            return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base',
            })
        })
    }
}

export const copyTextToClipboard = (text) => {
    if (text) {
        const el = document.createElement('textarea')
        el.value = text
        el.style = { display: 'none' }
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }
}

export const formatDuration = (startDateTime, endDateTime) => {
    const duration = moment.duration(moment(endDateTime).diff(moment(startDateTime)))
    let output = `${duration.minutes()}m ${duration.seconds()}s`
    const hours = duration.hours()
    const days = duration.days()

    if (hours) {
        output = `${hours}h ${output}`
    }

    if (days) {
        output = `${days}d ${output}`
    }

    return output
}
