(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfReadMore', wfReadMore)

    wfReadMore.$inject = ['$compile', '$parse', '$translate']
    function wfReadMore($compile, $parse, $translate) {
        const directive = {
            restrict: 'A',
            priority: 9999,
            compile(element, attrs) {
                return {
                    post(scope, element, attrs) {
                        let
                            text
							
                        let limit
							
                        const nativeElement = element[0]
							
                        const options = $parse(attrs.wfReadMore)(scope)
							
                        const windowWidth = window.outerWidth
							
                        const readMore = $translate.instant('ReadMore')
							
                        const readLess = $translate.instant('Hide')
							
                        let focus = false

                        if (typeof options === 'string') {
                            text = options
                        }
                        else if (typeof options === 'object') {
                            if (options.text) text = options.text

                            if (options.limit) limit = options.limit

                            if (options.focus) focus = true
                        }
                        else if ('ngBind' in attrs) { // does not work
                            text = $parse(attrs.ngBind)(scope)
                            nativeElement.removeAttribute('ng-bind')
                        }
                        else {
                            console.error('wf-read-more directive expects either a string or an object: {text: \'example\', limit: 100}')
                            return
                        }

                        if (text && text.length !== 0) {
                            if (!limit) {
                                if (windowWidth < 768) // xs screen
                                    limit = getLimitMargin(200)
                                else if (windowWidth >= 768 && windowWidth < 992) // sm screen
                                    limit = getLimitMargin(300)
                                else if (windowWidth >= 992 && windowWidth < 1200) // md screen
                                    limit = getLimitMargin(400)
                                else if (windowWidth >= 1200) // lg screen
                                    limit = getLimitMargin(500)
                                else limit = 400
                            }

                            nativeElement.setAttribute('hm-read-more', '')
                            nativeElement.setAttribute('hm-text', text)
                            nativeElement.setAttribute('hm-limit', limit)
                            nativeElement.setAttribute('hm-more-text', readMore)
                            nativeElement.setAttribute('hm-less-text', readLess)
							
                            if (focus) nativeElement.setAttribute('hm-focus', '')

                            nativeElement.removeAttribute('wf-read-more')

                            element.on('click', 'span.toggler', (event) => {
                                event.stopPropagation()
                            })

                            // Compile the element so other directive gets processed
                            $compile(element)(scope)
                        }

                        function getLimitMargin(limit) {
                            const limitMargin = limit + Math.round((limit / 2))
                            if (text.length < limitMargin) return text.length
                            else return limit
                        }
                    },
                }
            },
        }
        return directive

    }

})()
