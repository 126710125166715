import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.signalR', ['SignalR'])
        .service('signalR', signalR)

    signalR.$inject = ['$rootScope', 'Hub', '$timeout', 'wfObject', 'dataOperationsService', '$compile']//,'dataOperationsService'];

    function signalR($rootScope, Hub, $timeout, dataOps, $compile) { //, dataOps) {
        var hub
        let pNotifyContainer
        let pNotifyStack
        let signalRUrl = 'https://dev.api1.worldfavor.com/signalr' // Replaced by Grunt string-replace task
        var hub
        const service = {
            sendMessage,
            reconnect,
            disconnect,
            // setOrganization: setOrganization,
            objectCreated: onObjectCreated,
            objectUpdated: onObjectUpdated,
            objectDeleted: onObjectDeleted,
            getConnectionId() {
                _.get(hub, 'connection.id')
            },
        }

        if (signalRUrl.indexOf('SIGNALR_URL') !== -1 && (document.location.hostname === 'localhost' || document.location.hostname === 'local.worldfavor.com')) signalRUrl = 'http://localhost:4481/signalr'

        activate()

        return service

        function activate() {
            return
            if (window.wf__embeddedWidgetMode) return
			
            pNotifyContainer = $('<div />').addClass('ui-pnotify-context').appendTo('body')
            pNotifyStack = { dir1: 'down', dir2: 'right', spacing1: 16, spacing2: 100, push: 'top', context: pNotifyContainer }
            //declaring the hub connection

            // $("div.ui-pnotify-context").on("mouseover", ".ui-pnotify", function () {
            // 	$(this).stop().animate({ opacity: 0.5 }, 0);
            // }).on("mouseleave", ".ui-pnotify", function () {
            // 	$(this).stop().animate({ opacity: 1 }, 200);
            // })

            if (signalRUrl.indexOf('http') === -1) return

            console.log('Activating SignalR...')

            new Hub('signalRHub', {

                autoConnect: false,
                //client side methods
                listeners: {
                    send(val) {
                        console.log('Retrieved from server: ', val)
                    },
                    objectCreated: onObjectCreated,
                    objectUpdated: onObjectUpdated,
                    objectDeleted: onObjectDeleted,
                    connections(connections) {
                        // console.table(connections);
                    },
                    //'newNotification': onNewNotification
                },

                //server side methods
                methods: ['sendMessage', 'setOrganization'],

                //query params sent on initial connection
                queryParams: {
                },
                withCredentials: false,
                //handle connection error
                errorHandler(error) {
                    console.error(error)
                },

                //specify a non default root
                // rootPath: "http://localhost:4480/signalr",
                rootPath: signalRUrl,

                stateChanged(state) {
                    switch (state.newState) {
                        case $.signalR.connectionState.connecting:
                            console.log('connecting')
                            //your code here
                            break
                        case $.signalR.connectionState.connected:
                            console.log('connected', hub.connection.id)
                            // setTimeout(function () {

                            // dataOps.getObject("71-10015", {
                            // 	cacheResponse: true,
                            // 	bypassCache: true,
                            // 	childrenLoadDepth: 0
                            // }).then(function (res) {
                            // 	showNotificationBlade(res, {
                            // 		eventType: 10,
                            // 		objectType: 15,
                            // 		organizationId: 4536,
                            // 		userWfid: "100-3"
                            // 	});
                            // });
                            // }, 1000)
                            //your code here

                            // for (var i = 0; i < 20; i++) {
                            // 	setTimeout(function (i) {
                            // 		new PNotify({
                            // 			stack: pNotifyStack,
                            // 			delay: 30000,
                            // 			// styling: "bootstrap3",
                            // 			// addClass: "wf",
                            // 			// buttons: {
                            // 			// 	closer: true
                            // 			// },
                            // 			insert_brs: false,
                            // 			text: "nice " + i +"<br />ok"
                            // 		});
                            // 	}, i * 300, i)
                            // }

                            break
                        case $.signalR.connectionState.reconnecting:
                            console.log('reconnecting')
                            //your code here
                            break
                        case $.signalR.connectionState.disconnected:
                            console.log('disconnected')
                            //your code here
                            break
                    }
                },
            })

            // window.hub = hub;
        }

        function reconnect(token) {
            if (!hub) return

            hub.disconnect()
            hub.connect()
        }

        function disconnect() {
            if (!hub) return
            hub.disconnect()
        }

        function sendMessage(text) {
            if (!hub) return

            hub.sendMessage(text) //Calling a server method
        }

        function onObjectCreated(obj) {
            let inChache; let res

            if (obj.objectType == enums.objectType.invoice) return
            if (obj.objectType == enums.objectType.accessTag) return
            if (obj.objectType == enums.objectType.visibilityTag) return
            // if (obj.objectType == enums.objectType.questionAnswer || obj.objectType == enums.objectType.measureAnswer)
            // 	return;
            // if (obj.objectType == enums.objectType.dataRelation)
            // 	return;

            inChache = wfObject.filter({ where: { wfid: obj.wfid } }).length

            if (obj.objectType == enums.objectType.loginSession) {
                res = { type: 88 }
                res.createdAt = moment().format()
                showNotificationBlade(res, obj)
            }
            else {
                // Only show notification blade if item doesn't exist in cache.
                // If it's already in the cache it means that the item was just created in the current session
                // and notification is not needed.
                if (!inChache) {
                    dataOps.getObject(obj.wfid, {
                        childrenLoadDepth: 0,
                    }).then((res) => {
                        res.createdAt = moment().format()
						
                        showNotificationBlade(res, obj)
                    })
                }
            }
        }

        function onObjectUpdated(obj) {
            const inChache = wfObject.filter({ where: { wfid: obj.wfid } }).length
            console.log(obj.fromConnectionId)

            if (obj.objectType == enums.objectType.accessTag) return
            if (obj.objectType == enums.objectType.visibilityTag) return
            if (obj.objectType == enums.objectType.dataRelation) return

            // if (inChache)
            // {
            dataOps.getObject(obj.wfid, {
                cacheResponse: true,
                bypassCache: true,
                childrenLoadDepth: 0,
            }).then((res) => {
                if (!inChache) res.createdAt = moment().format()
                // pinesNotifications.notify({
                // 	title: obj.wfid + ' Reloaded from server',
                // 	type: 'info'
                // });
                showNotificationBlade(res, obj)
            })
            // }
        }

        function onObjectDeleted(obj) {
            const inChache = wfObject.get(obj.wfid)

            if (obj.objectType == enums.objectType.accessTag) return
            if (obj.objectType == enums.objectType.visibilityTag) return
            if (obj.objectType == enums.objectType.dataRelation) return

            if (inChache) {
                showNotificationBlade(wfObject.get(obj.wfid), obj)
            }
            else showNotificationBlade(obj.objectData, obj)
			
        }

        function showNotificationBlade(objectData, signalRPayload) {
            const
                itemScope = $rootScope.$new()
				
            const compiledItemHtml = $compile('<wf-notification></wf-notification>')
            // Generates a link function
				
            let element
				
            let watched1; let watched2

            // signalRPayload is an object that contains:
            // {
            //   organizationId, userWfid, eventType, objectType, objectId, objectData, wfid
            // }
            // wfid is the same as objectData.wfid

            itemScope.signalREvent = _.assign({
                createdAt: moment().format(),
                objectData,
            }, signalRPayload)

            itemScope.emitWhenItemLinked = true
            element = compiledItemHtml(itemScope) // Executes link function using the new scope

            watched1 = $rootScope.$on('itemLinked', (event, item) => {
                event.stopPropagation()
                watched1()
                watched2()
                // element.find("p.wf-bodyText").wrap("<div />").parent().css("maxHeight", 30).dotdotdot({
                // }); //.css("height", 30).addClass("dot-ellipsis dot-height-30");
                // TODO remove dependency towards pNotify
                // TODO if toast need to be created, use react components
                /*new PNotify({
					delay: 10000,
					stack: pNotifyStack,
					styling: {
						wfNotify: {
							container: "wfNotify",
							notice: "wfNotify-notice",
							notice_icon: "wfNotify-icon-notice",
							info: "wfNotify-info",
							info_icon: "wfNotify-icon-info",
							success: "wfNotify-success",
							success_icon: "wfNotify-icon-success",
							error: "wfNotify-error",
							error_icon: "wfNotify-icon-error"
						}
					},
					//addClass: 'wfNotify',
					// buttons: {
					// 	closer: true
					// },
					insert_brs: false,
					text: element[0].outerHTML,
					nonblock: {
						nonblock: true,
						nonblock_opacity: 0.2
					}
				});*/
            })

            watched2 = $rootScope.$on('contentError', (event, item, exception) => {
                event.stopPropagation()
                watched1()
                watched2()
            })
            // PNotify.desktop.permission();
            // (new PNotify({
            // 	title: 'Desktop Notice',
            // 	text: 'If you\'ve given me permission, I\'ll appear as a desktop notification. If you haven\'t, I\'ll still appear as a regular PNotify notice.',
            // 	desktop: {
            // 		desktop: true
            // 	}
            // })).get().click(function(e) {
            // 	if ($('.ui-pnotify-closer, .ui-pnotify-sticker, .ui-pnotify-closer *, .ui-pnotify-sticker *').is(e.target)) return;
            // 	alert('Hey! You clicked the desktop notification!');
            // });

        }
    }
})()
