import { getSsoSpecificationFromEmail } from '@worldfavor/portal/scripts/wf/authentication/enterpriseLogin'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('SetPasswordController', SetPasswordController)

    SetPasswordController.$inject = ['$scope', '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService']
    function SetPasswordController($scope, $q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader) {
        const
            vm = this
			
        const userToken = $stateParams.userToken
			
        const passwordStrengthRexExp = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
		
        console.log(userToken)
        vm.loading = true
        vm.loadingMessage = null
        vm.highlightTermsCheckbox = highlightTermsCheckbox
        vm.header = ''

        vm.schema = {
            type: 'object',
            properties: {
                password: {
                    type: 'string',
                },
                password_confirm: {
                    type: 'string',
                },
            },
        }
        vm.form = [
            {
                key: 'password',
                type: 'password',
                placeholder: 'Password',
                fieldAddonLeft: '<i class="a0-icon-budicon"></i>',
                validationMessage: {
                    strength: 'At least 8 characters long with lower case (a‑z), upper case (A‑Z) and numbers (0‑9)', // The dashes are NON-BREAKING HYPHEN
                },
                $validators: {
                    strength(value) {
                        return value && value.length >= 8 && value.match(passwordStrengthRexExp)
                    },
                },
                notitle: true,
                htmlClass: 'auth0-mimic',
                // ngModelOptions: { updateOn: 'blur' }
            },
            {
                key: 'password_confirm',
                type: 'password',
                placeholder: 'Repeat password',
                notitle: true,
                validationMessage: {
                    confirmPassword: 'Have to match',
                },
                $validators: {
                    confirmPassword(value) {
                        return vm.model.password === value
                    },
                },
                fieldAddonLeft: '<i class="a0-icon-budicon"></i>',
                htmlClass: 'auth0-mimic',
                // ngModelOptions: { updateOn: 'blur' }
            },
        ]
        vm.model = {}

        vm.submit = submit

        activate()

        ////////////////

        function activate() {

            validateUserToken()
        }

        function navigateToLoginPage() {
            document.location = '//' + document.location.host + '/login'
        }

        function submit() {
            $scope.$broadcast('schemaFormValidate')

            if ($scope.passwordForm.$valid) {
                console.log('valid')
                vm.loading = true
                vm.loadingMessage = 'You can now log in...'

                return apiProxy('authentication.setPassword', {
                    userToken,
                    password: vm.model.password,
                }).then((res) => {
                    if (res.succeeded) {
                        // screenLoader.show("Redirecting to login page...");
                        setTimeout(() => {
                            navigateToLoginPage()
                            // screenLoader.hide();
                        }, 2000)
                    }
                    else {
                        // screenLoader.show("Redirecting to login page..." + res.error);
                        setTimeout(() => {
                            navigateToLoginPage()
                            // screenLoader.hide();
                        }, 2000)
                        console.log(res)
                    }
                })
            }
        }

        function validateUserToken() {
            apiProxy('authentication.verifyUserActivationToken', userToken).then((res) => {
                // res.succeeded = true;
                // res.model = { email: "info@worldfavor.com" };
				
                if (res.succeeded) {
                    const email = res.model.email

                    const ssoInfo = getSsoSpecificationFromEmail(email)
                    if (ssoInfo) {
                        vm.loadingMessage = 'You are redirected<br />to the login page...'

                        setTimeout(() => {
                            document.location = ssoInfo.loginUrl
                        }, 2000)
                        return
                    }

                    vm.header = 'Choose password'

                    vm.username = res.model.email
                    vm.loading = false
                }
                else {
                    console.log(res)

                    vm.loading = true
                    vm.loadingMessage = 'You are redirected<br />to the login page...'

                    // screenLoader.show("Redirecting to login page... " + res.error);
                    setTimeout(() => {
                        navigateToLoginPage()
                        // screenLoader.hide();
                    }, 2000)
                }
            })
        }

        function highlightTermsCheckbox() {
            vm.termsCheckboxHighlighted = true
            $timeout(() => {
                vm.termsCheckboxHighlighted = false
            }, 250)
        }
    }
})()
