import * as enums from '@worldfavor/constants/enums'
import html2canvas from 'html2canvas'
import {hexToRgbA} from '@worldfavor/constants/colors'

(function () {
    'use strict'

    const queue = {
        items: [],
        lookup: {},
        current: null,
        timer: null,
        add: (id, func) => {
            const item = { id, func }
            queue.items.push(item)
            if (!queue.items.some(x => x.running)) {
                clearTimeout(queue.timer)
                // console.log('add() -> Execute ', id, ' | Queue items: ', queue.items.length)
                func()
                item.running = true
            }
        },
        remove: (id) => {
            // console.log('remove() -> Remove ', id, ' | Queue items: ', queue.items.length)
            const item = queue.items.filter(x => x.id === id)[0]
            if (item) {
                const index = queue.items.indexOf(item)
                queue.items.splice(index, 1)
            }
            clearTimeout(queue.timer)
            queue.timer = setTimeout(() => {
                if (queue.items.length && !queue.items.some(x => x.running)) {
                    // Items are queued but nothing is running so trigger the first item
                    queue.triggerNext(true)
                }
            }, 1000)
        },
        triggerNext: (skipShift) => {
            clearTimeout(queue.timer)

            if (!skipShift) {
                queue.items.shift()
            }

            const nextItem = queue.items[0]
            if (nextItem) {
                // console.log('triggerNext() -> Execute ', nextItem.id, ' | Queue items: ', queue.items.length)
                nextItem.running = true
                nextItem.func()
            }
        },
        clearAll: () => {
            // console.log('clearAll()', ' | Queue items: ', queue.items.length)
            queue.items.length = 0
        },
    }

    angular
        .module('wf.common')
        .component('wfChartNew', {
            templateUrl: 'scripts/wf/visualization/wfChart.component.html',
            require: {
                wfListInterfaceStatistical: '?^^wfListInterfaceStatistical',
                wfDataNegotiator: '?^^wfDataNegotiator',
                wfExpand: '?^wfExpand',
            },
            controller: wfChartNewController,
            controllerAs: 'vm',
            bindings: {
                fromItem: '=',
                ticket: '=',
                negotiatorFromAttr: '=negotiator',
                configFromAttribute: '=config',
                usePersistentNegotiator: '<',
                preloaded: '<',
                customDataset: '=',
            },
        })

    wfChartNewController.$inject = ['$scope', '$timeout', '$attrs', '$element', '$translate', 'DataNegotiator', 'WfChartistService', 'dataOperationsService', '$q', '$rootScope', '$uibModal', 'modalService', 'wfObject']
    function wfChartNewController($scope, $timeout, $attrs, $element, $translate, DataNegotiator, wfChartistService, dataOps, $q, $rootScope, $uibModal, modal, wfObject) {
        const vm = this

        let chartPrototype

        let stackedBarChartPrototype

        let donutChartPrototype

        let optionsPrototype

        let datasetPrototype

        let datasets

        let datasetLabels

        let datasetPeriodSpans

        let scopeDestroyed

        let ticket

        let skipQueue

        const targetId = 'target'
        const periodId = 'period'

        const itemForSavedSettings = _.get(vm.configFromAttribute, 'itemForSavedSettings') || _.get(vm.fromItem, 'dataRelation')

        let selectedChartSettings = _.assign({
            // Default values are combined with values on the dataRelation settings from db
            chartType: 'line',
            aggregateMode: 'combine',
            splitOption: undefined,
            convertMeasureAnswerUnits: false,
            useSingleValue: false,
            period: undefined,
            showAverage: true,
            showTargets: true,
            excludeYears: undefined,
            includeMeasureTargets: true,
            dataRelationId: itemForSavedSettings ? itemForSavedSettings.id : _.get(vm.fromItem, 'dataRelation.id'),
        }, _.get(itemForSavedSettings, 'settings.chartSettings'))

        let yearChanges = false

        vm.$onInit = function () {
            chartPrototype = {
                showButton: true,
                type: selectedChartSettings.chartType,
                aggregateMode: selectedChartSettings.aggregateMode,
                splitOption: selectedChartSettings.splitOption,
                convertMeasureAnswerUnits: selectedChartSettings.convertMeasureAnswerUnits,
                splitUpOrganizationStatistics: selectedChartSettings.aggregateMode === 'separate' && (!selectedChartSettings.splitOption || selectedChartSettings.splitOption === 'organizations'),
                splitUpRelativeMeasureSourcesStatistics: selectedChartSettings.aggregateMode === 'separate' && selectedChartSettings.splitOption === 'relativeMeasureSourceObjects',
                aggregateYearly: (selectedChartSettings.aggregateMode !== 'separate' && selectedChartSettings.splitOption === 'yearly') || selectedChartSettings.aggregateYearly,
                includeMeasureTargets: true,
                dataRelationId: selectedChartSettings.dataRelationId,
                aggregatePeriodFrequencies: selectedChartSettings.aggregateMode !== 'separate' && selectedChartSettings.aggregatePeriodFrequencies,
                showAverage: selectedChartSettings.showAverage,
                showTargets: selectedChartSettings.showTargets,
                created: false,
                loaded: false,
                element: undefined,
                chartInstance: undefined,
                chartHeight: 200,
                addLinearGradientElement: false,
                chartPadding: { top: 15, right: 15, bottom: 5, left: 10 }, //default options
                chartWidth: undefined,
                showSeriesSelection: true,
                //Layout specific settings
                cardLayout: false,
                showTypeToggler: true,
                saveTypeOnToggle: false,

                //Chart Data
                fillTimeGapsOnXAxes: false,
                accumulateLineChart: false,
                startFromZero: false,
                showLegend: true,
                showTooltips: true,
                mockRandomData: false, //used for testing purposes

                //Chart Options
                showArea: false,
                lineSmooth: false,
                showPoint: true,
                showXGrid: true,
                showYGrid: true,
                showXLabels: true,
                showYLabels: true,
                useFullWidth: false,
                dashedLine: false,
                stackBars: false,
                enableAnimations: false,
                disablePointerEvents: false,
                seriesBarDistance: 5, //series bar distance represents the distance from the x1 of the first bar to the x1 of the second bar
                colors: ['#3b7eb8', '#1abc9c', '#f39c12', '#34495e', '#bdc3c7', '#9b59b6'], //each color represents series,
                chartBackgroundColor: 'white',

                //Pie/Donut chart specific options
                showLabelPieChart: true,
                donut: false,
                donutWidth: undefined,

                //Info messages
                showInfoMessage: false,
                allowToDismissMessage: true,
                messageToDisplay: undefined,
                messages: {
                    emptyStateMessage: $translate.instant('modules.visualization.chartist.emptyState'),
                    notEnoughData: $translate.instant('modules.visualization.chartist.notEnoughData'),
                    barOverlapingMessage: $translate.instant('modules.visualization.chartist.barOverlapingMessage'),
                    allValuesAreZeros: $translate.instant('modules.visualization.chartist.allValuesAreZeros'),
                },

                //Functions
                recreateChart,
                generateRandomData,
                clearData,
                onSeriesClick: showSinglePointView,
            }

            stackedBarChartPrototype = _.assign(_.clone(chartPrototype), { stackBars: true })
            donutChartPrototype = _.assign(_.clone(chartPrototype), {
                donut: true,
                donutWidth: 20,
                labelOffset: 20,
            })
            ticket = vm.ticket

            _.assign(vm, {
                //Variables
                currentType: chartPrototype.type,
                currentAggregateMode: chartPrototype.aggregateMode,
                currentSplitOption: chartPrototype.splitOption,
                convertMeasureAnswerUnits: chartPrototype.convertMeasureAnswerUnits,
                currentUnitConversionMode: chartPrototype.convertMeasureAnswerUnits ? 'converted' : 'asReported',
                aggregateYearly: chartPrototype.aggregateYearly,
                aggregatePeriodFrequencies: chartPrototype.aggregatePeriodFrequencies,
                periodSpan: selectedChartSettings.period,
                enableSingleValueOption: true,
                currentChart: undefined,
                showAverage: selectedChartSettings.showAverage,
                showTargets: selectedChartSettings.showTargets,
                charts: {
                    line: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: 'line', name: $translate.instant('modules.visualization.chartTypes.line'), actualType: 'line' }, chartPrototype),
                    bar: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: 'bar', name: $translate.instant('modules.visualization.chartTypes.bar'), actualType: 'bar' }, chartPrototype),
                    stackedBar: _.defaults({ showSeriesSelection: !vm.customDataset }, { type: 'bar', name: $translate.instant('modules.visualization.chartTypes.stackedBar'), actualType: 'stackedBar' }, stackedBarChartPrototype),
                    pie: _.defaults({ type: 'pie', name: $translate.instant('modules.visualization.chartTypes.pie'), actualType: 'pie' }, chartPrototype),
                    donut: _.defaults({ type: 'pie', name: $translate.instant('modules.visualization.chartTypes.donut'), actualType: 'donut' }, donutChartPrototype),
                },
                excludeYears: selectedChartSettings.excludeYears,
                includeMeasureTargets: selectedChartSettings.includeMeasureTargets,
                dataRelationId: selectedChartSettings.dataRelationId,
                measureTargetsHasChanged: false,
                filteringDropdownYears: undefined,
                yearOptionText: selectedChartSettings.excludeYears && selectedChartSettings.excludeYears.length > 0 ?
                    $translate.instant('modules.visualization.yearFilter.some') : $translate.instant('modules.visualization.yearFilter.all'),
                isRelativeMeasure: false,
                appendTooltipToBody: _.get(vm.configFromAttribute, 'appendTooltipToBody') || false,
                showSaveButton: (_.get(vm.configFromAttribute, 'showSaveButton') && itemForSavedSettings) || false,
                saveButtonTooltipText: $translate.instant('modules.visualization.chartist.saveButtonTooltipMessages.selectedChartType'),
                showDownloadButton: (_.get(vm.configFromAttribute, 'showDownloadButton') && itemForSavedSettings) || false,
                showMeasureTargetsButton: (_.get(vm.configFromAttribute, 'showMeasureTargetsButton') && itemForSavedSettings) || false,
                useQueue: ((_.get(vm.configFromAttribute, 'useQueue') && itemForSavedSettings) || false) && selectedChartSettings.dataRelationId,
                averageToggleTooltipText: $translate.instant('modules.visualization.averageToggle.tooltip'),
                targetsToggleTooltipText: $translate.instant('modules.visualization.targetsToggle.tooltip'),
                itemWfid: _.get(vm.configFromAttribute, 'itemWfid') || _.get(itemForSavedSettings, 'wfcid') || (vm.fromItem ? (vm.fromItem.type + '-' + vm.fromItem.id) : undefined),
                downloadButtonTooltipText: $translate.instant('modules.visualization.chartist.downloadButtonTooltipMessage'),
                wfChartistService,
                periodRequests: {}, // {"20150101|20151231": { loaded: true }}
                allPeriodsLoaded: false,
                showYearsFilter: true,
                showDataDropDown: true,
                showSingleValueOption: true,
                showPeriodDropdown: true,
                hasTargetData: false,
                fiscalYearMonthOffset: ((): number => {
                    const networkId = _.get(ticket, 'networkId')
                    if (networkId) {
                        const network = wfObject.get(`52-${networkId}`)
                        return _.get(network, 'settings.valueChainSettings.fiscalYearMonthOffset')
                    }
                })(),
                loadDataAndInitialize,
                setAggregateMode,
                saveCurrentChartSelections,
                selectChartType,
                setUnitConversionMode,
                showSinglePointView,
                captureChart,
                handleAverageColor,
                hideAverageValue,
                hideAverageValueLabelCall,
                showAverageToggle,
                showTargetsToggle,
                toggleTargets,
                toggleTargetsLabelCall,
                toggleAggregateYearly,
                toggleAggregateYearlyLabelCall,
                onSelectYear,
                getDataOptionSelectedItemText,
                showcombineFrequencyOption,
                openMeasureTargetsEditor,
                clearRelativeMeasureResultsCacheAndRecalculate,
            })

            vm.charts.stackedBar.stackBars = true

            if (vm.wfExpand) {
                if (vm.wfExpand.expanded) {
                    activate()
                }
                else {
                    vm.wfExpand.element.on('shown.bs.collapse', () => {
                        if (!vm.currentChart) activate()
                    })
                }
            }
            else {
                activate()
            }

            function activate() {
                if (vm.currentType === undefined) vm.currentType = 'line'

                if (vm.currentAggregateMode === undefined) vm.currentAggregateMode = 'combine'

                vm.splitUpOrganizationStatistics = vm.currentAggregateMode === 'separate' && (!vm.currentSplitOption || vm.currentSplitOption === 'organizations')
                vm.splitUpRelativeMeasureSourcesStatistics = vm.currentAggregateMode === 'separate' && vm.currentSplitOption === 'relativeMeasureSourceObjects'
                vm.aggregatePeriodFrequencies = vm.currentAggregateMode !== 'separate' && vm.currentSplitOption === 'frequency'

                if (!selectedChartSettings.period) {
                    vm.allPeriodsLoaded = true
                }

                if (vm.useQueue && !skipQueue) {
                    loadWithQueue()
                }
                else {
                    loadDataAndInitialize()
                }
            }

            function loadWithQueue() {
                vm.showErrorMessage = false
                vm.errorMessage = ''
                skipQueue = false
                vm.isCached = false
                vm.forceNewNegotiator = true
                vm.loaderMessage = $translate.instant('modules.visualization.calcQueue.queued')
                vm.isQueued = true

                if (vm.currentChart) {
                    vm.currentChart.loaded = false
                }

                if (vm.negotiator) {
                    vm.negotiator.loaded = false
                    vm.negotiator.failed = false
                }
                $timeout()
                queue.add(vm.dataRelationId, loadDataAndInitialize)
            }

            function loadDataAndInitialize(options) {
                let existingNegotiatorInstance
                let fromItem

                const offscreen = document.createElement('canvas')

                const ctx = offscreen.getContext('2d')

                const bgGradient = ctx.createLinearGradient(0, 0, 0, 110)

                const bgGradient_lineFill = ctx.createLinearGradient(0, 0, 0, 130)

                const deferred = $q.defer()

                if (vm.useQueue && !skipQueue) {
                    vm.isQueued = false
                }

                if (vm.isCached) {
                    vm.loaderMessage = ''
                }
                else {
                    vm.loaderMessage = $translate.instant('modules.visualization.calcQueue.calculating')
                }

                vm.showErrorMessage = false
                vm.errorMessage = ''

                vm.item = vm.preloaded ? wfObject.get(vm.fromItem.type + '-' + vm.fromItem.id) : undefined

                if (vm.customDataset) {
                    vm.negotiator = undefined
                    vm.negotiatorFromAttr = undefined
                    vm.wfDataNegotiator = undefined
                }

                bgGradient.addColorStop(0, 'rgba(36, 138, 206, 1)')
                bgGradient.addColorStop(1, 'rgba(52, 152, 219, 1)')

                bgGradient_lineFill.addColorStop(0, 'rgba(36, 138, 206, .1)')
                bgGradient_lineFill.addColorStop(1, 'rgba(52, 152, 219, 0)')

                if (vm.currentChart) {
                    vm.currentChart.loaded = false
                    vm.currentChart = undefined
                }

                // splitUpOrganizationStatistics = vm.splitUpOrganizationStatistics = vm.currentAggregateMode === "separate";
                if (!_.isEmpty(vm.configFromAttribute)) {
                    vm.useEmptyState = vm.configFromAttribute.useEmptyState

                    _.each(vm.charts, (chart) => {

                        _.assign(chart, vm.configFromAttribute)
                        if (vm.configFromAttribute.colors) {
                            // Needs to be cloned, othwerwise a Non-Assignable Expression error will throw because Chartist is reassigning the array
                            // if the number of colors doesn't match the number of series
                            chart.colors = _.clone(vm.configFromAttribute.colors)
                        }
                    })

                    if (typeof vm.configFromAttribute.onLoaded === 'function') vm.configFromAttribute.onLoaded(vm)
                }

                if (options && options.ticket) {
                    ticket = options.ticket
                }

                if (!vm.preloaded && (vm.negotiatorFromAttr || vm.wfDataNegotiator || vm.fromItem)) {
                    $element.show()
                    if (vm.negotiator) vm.negotiator.abortOngoingXhrRequests()

                    // TODO: The checks done here is getting really messy and should be refactored.
                    // It is confusing which negotiator is used

                    if (!_.isEmpty(vm.negotiatorFromAttr) || !_.isEmpty(vm.wfDataNegotiator) || (vm.fromItem.type && vm.fromItem.id)) {
                        existingNegotiatorInstance = vm.negotiatorFromAttr || vm.wfDataNegotiator
                        fromItem = vm.fromItem

                        if (vm.negotiator) {
                            if ((vm.splitUpOrganizationStatistics !== vm.negotiator.splitUpOrganizationStatistics)
                                || (vm.splitUpRelativeMeasureSourcesStatistics !== vm.negotiator.splitUpRelativeMeasureSourcesStatistics)
                                || (vm.aggregateYearly !== vm.negotiator.aggregateYearly)
                                || (vm.aggregatePeriodFrequencies !== vm.negotiator.aggregatePeriodFrequencies)
                                || (vm.excludeYears !== vm.negotiator.excludeYears)
                                || vm.convertMeasureAnswerUnits
                            ) {
                                ticket = _.get(options, 'ticket') || vm.negotiator.ticket
                                fromItem = vm.negotiator.fromItem
                                existingNegotiatorInstance = undefined
                                vm.usePersistentNegotiator = true
                            }
                        }

                        let forceNewNegotiator = false
                        if (existingNegotiatorInstance && (
                            typeof existingNegotiatorInstance.splitUpOrganizationStatistics === 'boolean'
                            || typeof existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics === 'boolean'
                            || typeof existingNegotiatorInstance.aggregateYearly === 'boolean'
                            || typeof existingNegotiatorInstance.aggregatePeriodFrequencies === 'boolean'
                            || existingNegotiatorInstance.excludeYears
                        )) {
                            if (_.get(options, 'useNewAggregateMode') && existingNegotiatorInstance.splitUpOrganizationStatistics !== vm.splitUpOrganizationStatistics) {
                                forceNewNegotiator = true
                            }
                            else if (_.get(options, 'useNewAggregateMode') && existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics !== vm.splitUpRelativeMeasureSourcesStatistics) {
                                forceNewNegotiator = true
                            }
                            else if (_.get(options, 'useNewAggregateMode') && existingNegotiatorInstance.aggregateYearly !== vm.aggregateYearly) {
                                forceNewNegotiator = true
                            }
                            else if (_.get(options, 'useNewAggregateMode') && existingNegotiatorInstance.aggregatePeriodFrequencies !== vm.aggregatePeriodFrequencies) {
                                forceNewNegotiator = true
                            }
                            else if (existingNegotiatorInstance.excludeYears !== vm.excludeYears) {
                                forceNewNegotiator = true
                            }
                            else {
                                vm.splitUpOrganizationStatistics = existingNegotiatorInstance.splitUpOrganizationStatistics
                                vm.splitUpRelativeMeasureSourcesStatistics = existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics
                                vm.aggregateYearly = existingNegotiatorInstance.aggregateYearly
                                vm.aggregatePeriodFrequencies = existingNegotiatorInstance.aggregatePeriodFrequencies
                                vm.currentAggregateMode = (vm.splitUpOrganizationStatistics || vm.splitUpRelativeMeasureSourcesStatistics) ? 'separate' : 'combine'

                                if (vm.aggregatePeriodFrequencies) {
                                    vm.currentSplitOption = 'frequency'
                                }

                                if (vm.splitUpOrganizationStatistics) {
                                    vm.currentSplitOption = 'organizations'
                                }
                                else if (vm.splitUpRelativeMeasureSourcesStatistics) {
                                    vm.currentSplitOption = 'relativeMeasureSourceObjects'
                                }
                            }
                        }

                        if (vm.forceNewNegotiator) {
                            forceNewNegotiator = true
                            vm.forceNewNegotiator = false
                        }

                        if (vm.negotiator && vm.negotiator.excludeYears !== vm.excludeYears) {
                            forceNewNegotiator = true
                        }

                        if (vm.measureTargetsHasChanged) {
                            forceNewNegotiator = true
                        }

                        if (existingNegotiatorInstance && typeof existingNegotiatorInstance.convertMeasureAnswerUnits === 'boolean') {
                            vm.convertMeasureAnswerUnits = existingNegotiatorInstance.convertMeasureAnswerUnits
                        }

                        if (existingNegotiatorInstance && typeof existingNegotiatorInstance.periodSpan === 'string') {
                            vm.periodSpan = existingNegotiatorInstance.periodSpan
                            vm.selectedPeriod = existingNegotiatorInstance.periodSpan
                        }

                        if (existingNegotiatorInstance && existingNegotiatorInstance.excludeYears instanceof Array) {
                            vm.excludeYears = existingNegotiatorInstance.excludeYears
                            vm.filteringDropdownYears = vm.excludeYears && vm.filteringDropdownYears ? vm.filteringDropdownYears.map(yearObj => (
                                { ...yearObj, selected: !vm.excludeYears.includes(parseInt(yearObj.name)) }
                            )) : undefined
                        }

                        if (existingNegotiatorInstance && typeof existingNegotiatorInstance.includeMeasureTargets === 'boolean') {
                            vm.includeMeasureTargets = existingNegotiatorInstance.includeMeasureTargets
                        }

                        if (existingNegotiatorInstance && typeof existingNegotiatorInstance.aggregatePeriodFrequencies === 'boolean') {
                            vm.aggregatePeriodFrequencies = existingNegotiatorInstance.aggregatePeriodFrequencies
                        }

                        let newNegotiatorInstanceCreated = (Boolean)(existingNegotiatorInstance || !vm.usePersistentNegotiator)

                        // Getting messy!
                        if ((existingNegotiatorInstance || !vm.usePersistentNegotiator) && (
                            !existingNegotiatorInstance
							|| existingNegotiatorInstance.splitUpOrganizationStatistics === vm.splitUpOrganizationStatistics
							|| existingNegotiatorInstance.splitUpRelativeMeasureSourcesStatistics === vm.splitUpRelativeMeasureSourcesStatistics
							|| existingNegotiatorInstance.aggregateYearly === vm.aggregateYearly
							|| existingNegotiatorInstance.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
							|| existingNegotiatorInstance.periodSpan === vm.periodSpan
                            || existingNegotiatorInstance.excludeYears === vm.excludeYears
                            || !vm.measureTargetsHasChanged
							|| existingNegotiatorInstance.includeAvailablePeriods === vm.includeAvailablePeriods
							|| existingNegotiatorInstance.aggregatePeriodFrequencies === vm.aggregatePeriodFrequencies
                        )
                        ) {
                            vm.loading = true
                            vm.negotiator = DataNegotiator.instantiate(existingNegotiatorInstance, {
                                fromItem,
                                ticket,
                                loadParents: false,
                                splitUpOrganizationStatistics: vm.splitUpOrganizationStatistics,
                                splitUpRelativeMeasureSourcesStatistics: vm.splitUpRelativeMeasureSourcesStatistics,
                                aggregateYearly: vm.aggregateYearly,
                                onlyStatistics: true,
                                convertMeasureAnswerUnits: vm.convertMeasureAnswerUnits,
                                periodSpan: vm.periodSpan,
                                excludeYears: vm.excludeYears,
                                includeMeasureTargets: vm.aggregatePeriodFrequencies ? false : vm.includeMeasureTargets,
                                includeAvailablePeriods: true,
                                aggregatePeriodFrequencies: vm.aggregatePeriodFrequencies,
                                dataRelationId: vm.dataRelationId,
                            })
                        }
                        else {
                            newNegotiatorInstanceCreated = true
                            vm.loading = true
                            vm.negotiator = dataOps.persistentDataNegotiator(DataNegotiator, {
                                fromItem: fromItem || _.get(existingNegotiatorInstance, 'fromItem'),
                                ticket: ticket || _.get(existingNegotiatorInstance, 'ticket'),
                                loadParents: false,
                                splitUpOrganizationStatistics: vm.splitUpOrganizationStatistics,
                                splitUpRelativeMeasureSourcesStatistics: vm.splitUpRelativeMeasureSourcesStatistics,
                                aggregateYearly: vm.aggregateYearly,
                                convertMeasureAnswerUnits: vm.convertMeasureAnswerUnits,
                                onlyStatistics: true,
                                periodSpan: vm.periodSpan,
                                excludeYears: vm.excludeYears,
                                includeMeasureTargets: vm.aggregatePeriodFrequencies ? false : vm.includeMeasureTargets,
                                includeAvailablePeriods: true,
                                aggregatePeriodFrequencies: vm.aggregatePeriodFrequencies,
                                dataRelationId: vm.dataRelationId,
                            }, forceNewNegotiator)
                        }

                        if (vm.negotiator.item) { // Indicates that the data has been loaded once before
                            handleNegotiatorData().then(() => {
                                deferred.resolve()
                            })
                        }
                        else {
                            if (!vm.negotiator.xhrRequest) {
                                vm.loading = true
                                vm.negotiator.loadItemsFromServer()
                            }

                            if (newNegotiatorInstanceCreated) {
                                vm.negotiator.onRequest.then((res) => {
                                    $timeout.cancel(vm.timer)
                                    vm.isCached = !!vm.negotiator.item.calculationResults
                                    vm.loaderMessage = ''
                                    handleNegotiatorData().then(() => {
                                        deferred.resolve()
                                    })
                                })
                                vm.negotiator.onRequest.fail((res) => {
                                    if (res.statusText == 'abort') return;

                                    vm.showErrorMessage = true
                                    vm.errorMessage = 'WFID: ' + vm.fromItem.wfid

                                    const exceptionMessage = _.get(res, 'responseJSON.InnerException.ExceptionMessage') || _.get(res, 'responseJSON.ExceptionMessage')
                                    if (exceptionMessage && ~exceptionMessage.indexOf('cyclic relative measures'))
                                    {
                                        vm.errorSourceRelativeMeasureWfid = exceptionMessage.split('|')[1]
                                        vm.errorTitle = $translate.instant('modules.visualization.errorMessage.relativeMeasureSelfReferenceErrorTitle') + vm.errorSourceRelativeMeasureWfid
                                    }
                                    else if (exceptionMessage && ~exceptionMessage.indexOf('empty relative measures'))
                                    {
                                        vm.errorSourceRelativeMeasureWfid = exceptionMessage.split('|')[1]
                                        vm.errorTitle = $translate.instant('modules.visualization.errorMessage.relativeMeasureWithoutStepsErrorTitle') + vm.errorSourceRelativeMeasureWfid
                                    }
                                    else if (exceptionMessage && ~exceptionMessage.indexOf('without Custom Value'))
                                    {
                                        vm.errorSourceRelativeMeasureWfid = exceptionMessage.split('|')[1]
                                        vm.errorTitle = $translate.instant('modules.visualization.errorMessage.relativeMeasureStepWithoutCustomValueErrorTitle') + vm.errorSourceRelativeMeasureWfid
                                    }
                                    else if (exceptionMessage && ~exceptionMessage.indexOf('Organizations missing in cached results'))
                                    {
                                        vm.errorTitle = $translate.instant('modules.visualization.errorMessage.relativeMeasureOrganizationsMissingInCacheErrorTitle')
                                        vm.relativeMeasureCacheError = true
                                    }
                                    else {
                                        vm.errorTitle = $translate.instant('modules.visualization.errorMessage.standardTitle')
                                    }
                                    vm.currentChart = undefined

                                    vm.loading = false
                                    $timeout()

                                    deferred.resolve()
                                    if (vm.useQueue && !skipQueue) {
                                        skipQueue = true
                                        vm.isQueued = false
                                        queue.triggerNext()
                                    }
                                })
                            }
                            // else
                            // {
                            // 	handleNegotiatorData().then(function() {
                            // 		deferred.resolve();
                            // 	});
                            // }
                        }
                    }
                }
                else if (vm.item || (vm.customDataset && !_.isEmpty(vm.customDataset))) {
                    handleNegotiatorData()
                    deferred.resolve()
                }
                else {
                    $element.hide()
                    deferred.resolve()
                }

                function handleNegotiatorData() {
                    const
                        deferred2 = $q.defer()

                    let item

                    let fromItem

                    //vm.negotiator.includeAvailablePeriods = false;

                    if (scopeDestroyed) {
                        // if (vm.useQueue && !skipQueue) {
                        //     queue.triggerNext()
                        // }

                        deferred2.reject()
                        return deferred2.promise
                    }

                    if (vm.useQueue && !skipQueue) {
                        skipQueue = true
                        vm.isQueued = false
                        queue.triggerNext()
                    }

                    vm.loading = false

                    if (vm.customDataset) {
                        datasets = vm.customDataset
                        fromItem = {}
                        item = {}
                    }
                    else {
                        datasets = _.get(vm.negotiator, 'itemMetadata.statistics.datasets') || []
                        vm.hasTargetData = datasets.some(dataset => dataset.id.includes(targetId))
                        setDatasetLabels()
                        datasetPeriodSpans = _.get(vm.negotiator, 'itemMetadata.statistics.datasetPeriodSpans') || []
                        const periodDataset = datasets.find(x => x.id.includes('period'))
                        if (periodDataset) {
                            vm.periodsToSelect = periodDataset.data.map((x, index) => ({ label: x.label, span: datasetPeriodSpans && datasetPeriodSpans[index] }))
                            vm.periodsToSelect.reverse()
                        }

                        if (vm.negotiator && !vm.preloaded) {
                            item = vm.negotiator.item
                            fromItem = vm.negotiator.fromItem
                        }
                        else if (vm.preloaded) {
                            item = wfObject.get(vm.fromItem.type + '-' + vm.fromItem.id)
                            fromItem = vm.fromItem

                            if (item.metadata && item.metadata.statistics && item.metadata.statistics.datasets && item.metadata.statistics.datasetLabels) {
                                datasets = item.metadata.statistics.datasets
                                vm.hasTargetData = datasets.some(dataset => dataset.id.includes(targetId))
                                datasetLabels = vm.hasTargetData && vm.showTargets ? item.metadata.statistics.filteredDatasetLabels : item.metadata.statistics.datasetLabels
                                vm.hasData = true
                            }
                            else console.error('Preloaded item does not have datasets or datasetLabels in metadata.statistics object')
                        }

                        if (typeof vm.configFromAttribute.onDataLoaded === 'function') {
                            vm.configFromAttribute.onDataLoaded(item)
                        }

                        if (item.type === enums.objectType.relativeMeasure) vm.isRelativeMeasure = true

                        const ratioUnits = [
                            '%',
                            'percentage',
                            'MWh/employee',
                            'tCO2e/employee',
                            'tCO2e/EUR',
                            'MWh/EUR',
                            'tCO2e/MEUR',
                            'MWh/MEUR',
                            'tCO2e/m2',
                            'MWh/m2',
                            'accidents/worktime',
                            'tCO2e/SEK',
                            'kgCO2e/produced liter',
                            'energy consumption/produced liter',
                            'tCO2e/MSEK',
                            'tCO2e/FTE',
                            'tCO2e (SFDR)',
                            'water/produced liter',
                            'tCO2e/employee',
                            'tCO2e/omsatt Mkr',
                            'kWh/m2',
                            'kWh/employee',
                            'hours per employee',
                            'EUR per employee',
                        ]

                        vm.hasRatioUnit = ratioUnits.includes(_.get(item, 'unit.name')) || ratioUnits.includes(_.get(item, 'unit.symbol'))

                        //Units are now taken from the measure answer

                        vm.showAggregateYearlyOption = vm.isRelativeMeasure || !(ratioUnits.includes(_.get(item, 'unit.name')) || ratioUnits.includes(_.get(item, 'unit.symbol')))

                        // if (vm.charts.length !== 0 && unit) {
                        // 	_.each(vm.charts, function(chart) {
                        // 		chart.unit = unit;
                        // 	});
                        // }
                    }

                    // Extract the years to be shown in the filter years dropdown
                    let datasetPeriodSpansToSelect = datasetPeriodSpans.map(d => d.slice(0, 4))
                    datasetPeriodSpansToSelect = datasetPeriodSpansToSelect.filter((item, index) => datasetPeriodSpansToSelect.indexOf(item) === index)
                    vm.filteringDropdownYears = datasetPeriodSpansToSelect.map(year => ({ name: year, selected: true }))
                    if (vm.excludeYears && vm.excludeYears.length > 0) {
                        vm.filteringDropdownYears = vm.filteringDropdownYears.map(yearObj => ({ ...yearObj, selected: !vm.excludeYears.includes(parseInt(yearObj.name)) }))
                    }
                    vm.yearOptionText = vm.excludeYears && vm.excludeYears.length > 0
                        ? $translate.instant('modules.visualization.yearFilter.some') : $translate.instant('modules.visualization.yearFilter.all')

                    if (vm.configFromAttribute.mockRandomData) datasets = wfChartistService.mockRandomData({ numberOfDatasets: vm.configFromAttribute.mockRandomData.numberOfDatasets, numberOfYears: vm.configFromAttribute.mockRandomData.numberOfYears })

                    if (!vm.preloaded && datasets && datasets.length && _.some(datasets, (dataset) => {
                        return dataset.data.length > 0
                    })) {
                        if (fromItem.type === enums.objectType.measure || fromItem.type === enums.objectType.relativeMeasure) {
                            if (vm.currentAggregateMode === 'separate') {
                                const idPrefix = vm.currentSplitOption === 'relativeMeasureSourceObjects' ? 'period_source_' : 'period_org_'
                                datasets = _.filter(datasets, (dataset) => {
                                    return dataset.id.indexOf(idPrefix) === 0
                                })
                            }
                            else if (vm.aggregatePeriodFrequencies) {
                                const idPrefix = 'period_year_'
                                datasets = _.filter(datasets, (dataset) => {
                                    return dataset.id.indexOf(idPrefix) === 0
                                })
                            }
                            else {
                                datasets = datasets.filter(dataset => dataset.id.includes(periodId) || dataset.id.includes(targetId))

                                // If there is only one point to the dataset, show the single point view
                                vm.useSinglePointView = datasets.every(dataset => dataset.data.length === 1)
                                vm.useSinglePointData = datasets[0].data[0]
                                vm.isInconsistentData = datasets[0].inconsistent
                            }
                        }

                        if (item.type === enums.objectType.question && datasets.length > 1) datasets = _.filter(datasets, (dataset) => { return dataset.id !== 'count' })

                        if ('brightBg' in $attrs) {
                            optionsPrototype = {
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        display: true,
                                        gridLines: {
                                            zeroLineColor: 'rgba(0,0,0,0.5)',
                                            color: 'rgba(0,0,0,0.1)',
                                            // tickMarkLength: 10
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            padding: 5,
                                            fontColor: 'rgba(0,0,0,0.5)',
                                        },
                                    }],
                                    yAxes: [{
                                        display: true,
                                        gridLines: {
                                            zeroLineColor: 'rgba(0,0,0,0.5)',
                                            color: 'rgba(0,0,0,0.1)',
                                            // tickMarkLength: 0
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            padding: 5,
                                            fontColor: 'rgba(0,0,0,0.5)',
                                        },
                                    }],
                                },
                                layout: {
                                    padding: {
                                        left: -5,
                                        right: 0,
                                        bottom: 0,
                                        top: 0,
                                    },
                                },
                            }

                            // datasetPrototype = {
                            // 	fill: true,
                            // 	borderColor: "rgba(0,0,0,0.5)",
                            // 	borderWidth: 2,
                            // 	pointBorderColor: "rgba(0,0,0,0.5)",
                            // 	pointBackgroundColor: "#eee",
                            // 	pointBorderWidth: 1.5,
                            // 	pointRadius: 4,
                            // 	lineTension: 0,
                            // }

                            datasetPrototype = {
                                fill: true,
                                backgroundColor: bgGradient_lineFill,
                                borderColor: 'rgba(36, 138, 206, 1)',
                                borderWidth: 3,
                                pointBorderColor: 'rgba(36, 138, 206, 1)',
                                pointBackgroundColor: 'rgba(36, 138, 206, 1)',
                                pointBorderWidth: 2,
                                pointRadius: 4,
                                lineTension: 0,
                            }

                            // datasetPrototype_bar = {
                            // 	fill: true,
                            // 	backgroundColor: bgGradient,
                            // 	hoverBackgroundColor: bgGradient,
                            // 	borderColor: "transparent",
                            // 	borderWidth: 0,
                            // }
                        }
                        else {
                            optionsPrototype = {
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        display: true,
                                        gridLines: {
                                            zeroLineColor: 'rgb(255,255,255)',
                                            color: 'rgba(255,255,255,0.1)',
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            padding: 5,
                                            fontColor: 'rgba(255,255,255,0.8)',
                                        },
                                    }],
                                    yAxes: [{
                                        display: true,
                                        gridLines: {
                                            zeroLineColor: 'rgba(255,255,255,0.4)',
                                            color: 'rgba(255,255,255,0.1)',
                                        },
                                        ticks: {
                                            //autoSkip: false,
                                            //maxRotation: 0,
                                            //fontSize: 12,
                                            //fontWeight: 400,
                                            beginAtZero: true,
                                            padding: 20,
                                            fontColor: 'rgba(255,255,255,0.8)',
                                        },
                                    }],
                                },
                                layout: {
                                    padding: {
                                        left: -5,
                                        right: 0,
                                    },
                                },
                            }

                            datasetPrototype = {
                                fill: true,
                                borderColor: '#fff',
                                borderWidth: 2,
                                pointBorderColor: '#fff',
                                pointBackgroundColor: '#5696DA',
                                pointBorderWidth: 1,
                                pointRadius: 4,
                                lineTension: 0,
                            }
                        }

                        if (_.get(vm.negotiator, 'dataNeededAfterChartTypeChange')) {
                            vm.currentType = vm.currentChart ? (vm.currentChart.actualType || vm.currentChart.type) : vm.negotiator.currentChartType
                        }

                        vm.charts[vm.currentType].aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies

                        recreateChart.apply(vm.charts[vm.currentType]).then(() => {
                            const hasSingleReportedPeriod = _.get(vm.negotiator, 'itemMetadata.statistics.datasetPeriodSpans.length') === 1
                            //unfortunatelly the singlepointview has to be enabled here because the data changes before recreateChart.apply()
                            if (!vm.customDataset && (hasSingleReportedPeriod || (!_.get(vm.negotiator, 'dataNeededAfterChartTypeChange') && selectedChartSettings.useSingleValue)) && vm.currentAggregateMode === 'combine') {
                                showSinglePointView(vm.selectedPeriod || selectedChartSettings.period, true)
                            }
                            else {
                                vm.useSinglePointView = false
                                vm.currentChart.chartElement.show()
                                $timeout()
                            }

                            if (vm.negotiator) {
                                vm.negotiator.dataNeededAfterChartTypeChange = false
                            }

                            deferred2.resolve()
                        })
                        $timeout()
                    }
                    else {
                        if (vm.preloaded) {
                            recreateChart.apply(vm.charts[vm.currentType]).then(() => {
                                deferred2.resolve()
                            })
                        }
                        else if (!vm.useEmptyState) {
                            $element.hide()
                            deferred2.resolve()
                        }
                    }

                    return deferred2.promise
                }

                return deferred.promise
            }

            function createChartist(chart) {
                //Check if selected chart type supports aggregateMode
                // if (vm.currentAggregateMode === "combine" && chart.aggregateMode === "separate") {
                // 	if (vm.currentType !== chart.actualType)
                // 		chart = vm.charts.line;
                // }

                let
                    chartistConfig

                let tempBarChart

                let fromItem = vm.negotiator ? vm.negotiator.fromItem : vm.fromItem

                setDatasetLabels()

                const deferred = $q.defer()
                vm.chartOptions = {
                    chartType: chart.type,
                    optionsPrototype,
                    datasetPrototype,
                    datasets,
                    valueProperties: undefined,
                    disablePointerEvents: false,
                    datasetLabels,
                    ticket: vm.negotiator ? vm.negotiator.ticket : undefined,
                    aggregateMode: vm.currentAggregateMode,
                    showAverage: vm.showAverage,
                    showTargets: vm.showTargets,
                    aggregateYearly: vm.aggregateYearly,
                    showPeriodDropdown: vm.showPeriodDropdown,
                    periodLabelsToSelect: vm.periodsToSelect ? vm.periodsToSelect.map(x => x.label) : [],
                }

                if (vm.customDataset) fromItem = {}

                vm.hasData = !_.isEmpty(datasets)
                vm.hasTargetData = datasets.some(dataset => dataset.id.includes(targetId))

                chart.aggregateMode = vm.currentAggregateMode
                vm.currentChart = chart
                vm.chartOptions.measure = fromItem
                _.assign(vm.chartOptions, vm.currentChart)

                if (fromItem.type === enums.objectType.measure || fromItem.type === enums.objectType.relativeMeasure) {
                    if (vm.chartOptions.ticket && (vm.chartOptions.ticket.organizationIds || vm.chartOptions.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId)) { //Aggregated mode
                        if (vm.currentAggregateMode === 'separate') {
                            vm.chartOptions.valueProperties = ['value']
                        }
                        else if (vm.aggregatePeriodFrequencies) {
                            vm.chartOptions.valueProperties = ['sum']
                        }
                        else {
                            vm.chartOptions.valueProperties = ['average', 'sum']
                            const numberOfTargets = datasets.filter(x => x.id.includes(targetId)).length
                            if (vm.chartOptions.colors && vm.chartOptions.colors.length === 1 && vm.showAverage) {
                                let colors = ['#ccc', vm.chartOptions.colors[0]]

                                if (vm.includeMeasureTargets && vm.showTargets) {
                                    colors = addTargetColors(colors, colors[1], numberOfTargets)
                                }

                                vm.chartOptions.colors = colors
                            }
                            else if (vm.includeMeasureTargets && vm.showTargets) {
                                vm.chartOptions.colors = addTargetColors(vm.chartOptions.colors, vm.chartOptions.colors[0], numberOfTargets)
                            }
                        }
                    }
                    else vm.chartOptions.valueProperties = ['value']
                }
                else if (vm.negotiator && vm.negotiator.fromItem.type === enums.objectType.question) {
                    _.each(datasets, (dataset) => {
                        if (dataset.id.indexOf('questionAnswerType-') === 0 && dataset.id !== 'questionAnswerType-unanswered') {
                            dataset.accumulate = true
                        }
                    })
                    handleQuestionVisualization()
                }

                if (!vm.hasData) {
                    vm.chartOptions.asEmptyState = true
                    vm.chartOptions.disablePointerEvents = true
                    vm.showSaveButton = false
                }
                else {
                    if (_.get(vm.configFromAttribute, 'showSaveButton')) vm.showSaveButton = true
                }

                if (datasets.length === 1 && datasets[0].data.length === 1) {
                    // vm.chartOptions.messageToDisplay = vm.chartOptions.messages.notEnoughData;
                    // vm.chartOptions.showInfoMessage = true;
                }

                // Fill holes in line chart if targets are shown
                if (vm.hasTargetData && vm.currentChart.type === 'line'
                    && (vm.currentAggregateMode === 'combine' || vm.aggregateYearly)) {
                    vm.chartOptions.lineSmooth = Chartist.Interpolation.none({ fillHoles: true })
                }

                chartistConfig = wfChartistService.getChartistConfigObject(vm.chartOptions)
                vm.showConversionOptions = vm.showConversionOptions || chartistConfig.showConversionOptions

                //If there is only one series of data, hide pie chart stacked bar and donut chart
                if (vm.chartOptions.originalData && vm.chartOptions.originalData.series && vm.chartOptions.originalData.series.length === 1) {
                    vm.charts.pie.showButton = false
                    vm.charts.stackedBar.showButton = false
                    vm.charts.donut.showButton = false
                }
                else {
                    _.each(vm.charts, (chart) => { chart.showButton = true })
                }

                $timeout(() => {
                    vm.currentChart.chartElement = $element.find('div.chartist.' + vm.currentChart.type)
                    $element.height($element.height())
                    vm.currentChart.chartElement.empty() //Remove all elements from div.chartist to create a new one (plugin elements like tooltip and legend are not removed when the chart gets recreated)
                    vm.currentChart.chartElement.attr({ style: 'background-color: ' + vm.chartOptions.chartBackgroundColor + ';' })

                    if (vm.currentChart.chartWidth) chartistConfig.options.width = vm.currentChart.chartWidth

                    if (vm.chartOptions.disablePointerEvents) vm.currentChart.chartElement.addClass('disable-pointer-events')
                    else if (vm.currentChart.chartElement.hasClass('disable-pointer-events')) vm.currentChart.chartElement.removeClass('disable-pointer-events')

                    // Hide the chart element if the single point view is visible
                    if (vm.useSinglePointView || (vm.currentAggregateMode !== 'separate' && vm.useSinglePointView)) {
                        vm.currentChart.chartElement.hide()
                    }

                    if (vm.currentChart.type === 'line') {
                        vm.currentChart.chartInstance = new Chartist.Line(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions)
                        vm.currentChart.dashedLine ? vm.currentChart.chartInstance.options.classNames.line += ' dashed-line' : ''

                    }
                    else if (vm.currentChart.type === 'bar') {
                        if (vm.chartOptions.actualType === 'stackedBar') {
                            handleAverageColor()
                            chartistConfig = wfChartistService.getChartistConfigObject(vm.chartOptions)
                        }
                        tempBarChart = new Chartist.Bar(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions)
                        vm.currentChart.chartInstance = wfChartistService.getChartWithDynamicBars(tempBarChart, vm.chartOptions)
                        tempBarChart = undefined
                    }
                    else if (vm.currentChart.type === 'pie') {
                        vm.currentChart.chartInstance = new Chartist.Pie(vm.currentChart.chartElement[0], chartistConfig.data, chartistConfig.options, chartistConfig.responsiveOptions)
                        vm.aggregatePeriodFrequencies = false
                    }

                    vm.currentChart.loaded = true

                    deferred.resolve()
                    setTimeout(() => {

                        // Don't animate the graph if the single point view is visible
                        if (vm.useSinglePointView || (vm.currentAggregateMode !== 'separate' && vm.useSinglePointView)) {
                            return
                        }

                        if (vm.currentChart) {
                            vm.currentType = vm.currentChart.actualType || vm.currentChart.type
                            vm.currentChart.chartElement.animate({ opacity: 1 }, 100)
                        }
                        $element.height('auto')
                    }, 100)
                }, 100)

                vm.currentChart.created = true
                checkChartSelections(vm.currentChart)

                return deferred.promise
            }

            function setDatasetLabels() {
                if (vm.negotiator && !vm.preloaded) {
                    if (vm.currentAggregateMode === 'separate' && (vm.excludeYears && vm.excludeYears.length > 0)) {
                        datasetLabels = undefined
                    }
                    else if (vm.currentAggregateMode === 'separate' || vm.aggregatePeriodFrequencies) {
                        datasetLabels = _.get(vm.negotiator, 'itemMetadata.statistics.datasetLabels')
                    }
                    else if (vm.hasTargetData && vm.showTargets) {
                        datasetLabels = _.get(vm.negotiator, 'itemMetadata.statistics.filteredDatasetLabels')
                    }
                    else {
                        datasetLabels = undefined
                    }
                }
            }

            function handleQuestionVisualization() {
                vm.showDataDropDown = false
                vm.showYearsFilter = false
                vm.enableSingleValueOption = false
                vm.convertMeasureAnswerUnits = false
                vm.showConversionOptions = false
                if (vm.currentChart.type !== 'pie' || vm.currentChart.type !== 'donut') {
                    vm.chartOptions.showPeriodDropdown = false
                }
            }

            function showAverageToggle() {
                return vm.currentChart && vm.currentChart.loaded
					&& vm.currentAggregateMode !== 'separate'
					&& !vm.aggregatePeriodFrequencies
					&& !vm.useSinglePointView
					&& Array.isArray(vm.chartOptions.valueProperties)
					&& (vm.chartOptions.valueProperties.includes('average') || vm.chartOptions.valueProperties.includes('sum'))
            }

            function showTargetsToggle() {
                return vm.currentChart && vm.currentChart.loaded
                    && vm.currentAggregateMode !== 'separate'
                    && !vm.aggregatePeriodFrequencies
                    && !vm.useSinglePointView
                    && vm.hasTargetData
            }

            function hideAverageValueLabelCall() {
                vm.showAverage = !vm.showAverage
                hideAverageValue()
            }

            function hideAverageValue() {
                handleAverageColor()
                vm.chartOptions.showAverage = vm.showAverage
                vm.currentChart.recreateChart()
            }

            function handleAverageColor() {
                if (!vm.showAverage && vm.chartOptions.colors.includes('#ccc')) {
                    const index = vm.chartOptions.colors.indexOf('#ccc')
                    vm.chartOptions.colors.splice(index, 1)
                } else if (vm.chartOptions.valueProperties && vm.chartOptions.valueProperties.includes('average') && vm.showAverage && !vm.chartOptions.colors.includes('#ccc')) {
                    vm.chartOptions.colors.unshift('#ccc')
                }
            }

            function toggleTargetsLabelCall() {
                vm.showTargets = !vm.showTargets
                toggleTargets()
            }

            function toggleTargets() {

                vm.chartOptions.showTargets = vm.showTargets
                vm.currentChart.recreateChart()
            }

            function toggleAggregateYearlyLabelCall() {
                vm.aggregateYearly = !vm.aggregateYearly
                toggleAggregateYearly()
            }

            function toggleAggregateYearly() {
                vm.chartOptions.aggregateYearly = vm.aggregateYearly

                loadDataAndInitialize().then(() => {
                    checkChartSelections(vm.currentChart)
                })
            }

            function addTargetColors(colors, originalColor, numberOfTargets) {
                for (let i = 0; i < numberOfTargets; i++) {
                    const targetColor = hexToRgbA(originalColor, 1 - (0.25 * (i + 1)))
                    colors = [...colors, targetColor]
                }

                return colors
            }

            $element.on('hide.bs.dropdown', 'div.filter-years', () => {
                if (yearChanges) {
                    vm.excludeYears = vm.filteringDropdownYears.filter(y => y.selected === false).map(y => parseInt(y.name))
                    loadDataAndInitialize().then(() => {
                        checkChartSelections(vm.currentChart)
                    })
                    yearChanges = false
                    $timeout()
                }
            })

            function onSelectYear(filteringDropdownYear) {
                filteringDropdownYear.selected = !filteringDropdownYear.selected

                // All years can't be unselected
                const allYearsAreUnselected = vm.filteringDropdownYears.every(year => year.selected === false)
                if (allYearsAreUnselected) {
                    filteringDropdownYear.selected = !filteringDropdownYear.selected
                }
                else {
                    yearChanges = true
                }

                const someYearsAreUnselected = vm.filteringDropdownYears.some(year => year.selected === false)
                vm.yearOptionText = someYearsAreUnselected ? $translate.instant('modules.visualization.yearFilter.some')
                    : $translate.instant('modules.visualization.yearFilter.all')

                $timeout()
            }

            function resetYearFilter() {
                vm.excludeYears = undefined
                vm.filteringDropdownYears = vm.filteringDropdownYears ? vm.filteringDropdownYears.map(year => ({ ...year, selected: true })) : undefined
                vm.yearOptionText = $translate.instant('modules.visualization.yearFilter.all')
            }

            function selectChartType(chart) {
                vm.useSinglePointView = false

                chart.aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies
                if (!vm.allPeriodsLoaded || (vm.excludeYears && vm.excludeYears.length > 0 && chart.type === 'pie')) {
                    resetYearFilter()
                    vm.allPeriodsLoaded = true
                    vm.currentChart = chart
                    vm.negotiator.currentChartType = chart.actualType || chart.type
                    vm.negotiator.dataNeededAfterChartTypeChange = true
                    vm.negotiator.periodSpan = undefined
                    vm.negotiator.excludeYears = undefined
                    vm.negotiator.loadItemsFromServer()
                }
                else {
                    return recreateChart.apply(chart)
                }
            }

            function recreateChart() {
                const deferred = $q.defer()
                const chartToRecreate = this
                chartToRecreate.loaded = false
                chartToRecreate.showAverage = vm.showAverage
                chartToRecreate.showTargets = vm.showTargets
                vm.currentChart = undefined

                createChartist(chartToRecreate).then(() => {
                    handleAverageColor()
                    deferred.resolve()
                })

                return deferred.promise
            }

            function clearData() {
                const chart = this
                datasets = []
                chart.recreateChart()
            }

            function generateRandomData() {
                const chart = this
                datasets = wfChartistService.mockRandomData(chart)
                chart.recreateChart()
            }

            function setAggregateMode(mode, splitOption) {
                if (vm.currentAggregateMode === mode && vm.currentSplitOption === splitOption) return

                if (vm.periodSpan && (splitOption === 'yearly' || vm.currentSplitOption === 'yearly') && vm.currentSplitOption !== splitOption) {
                    vm.periodSpan = undefined
                }

                vm.splitUpOrganizationStatistics = mode === 'separate' && splitOption === 'organizations'
                vm.splitUpRelativeMeasureSourcesStatistics = mode === 'separate' && splitOption === 'relativeMeasureSourceObjects'
                vm.currentAggregateMode = mode
                vm.currentSplitOption = splitOption
                vm.aggregatePeriodFrequencies = mode === 'combine' && splitOption === 'frequency'
                mode === 'separate' ? vm.enableSingleValueOption = false : vm.enableSingleValueOption = true

                if (mode === 'separate') {
                    vm.periodSpan = undefined
                }

                if ((vm.aggregateYearly || vm.aggregatePeriodFrequencies) && !vm.convertMeasureAnswerUnits) {
                    vm.convertMeasureAnswerUnits = true
                    if (vm.wfListInterfaceStatistical) {
                        vm.wfListInterfaceStatistical.setMeasureUnitConversionMode(vm.convertMeasureAnswerUnits)
                    }
                }

                if (vm.aggregatePeriodFrequencies) {
                    vm.periodSpan = undefined
                    if (vm.currentChart.type === 'pie' || vm.currentChart.type === 'donut') {
                        selectChartType(vm.charts['line']).then(() => loadData())
                        return
                    }
                }

                loadData()

                function loadData() {
                    loadDataAndInitialize({ useNewAggregateMode: true }).then(() => {
                        checkChartSelections(vm.currentChart)
                    })
                }
            }

            function setUnitConversionMode(mode) {
                vm.convertMeasureAnswerUnits = mode === 'converted'
                if (vm.wfListInterfaceStatistical) {
                    vm.wfListInterfaceStatistical.setMeasureUnitConversionMode(vm.convertMeasureAnswerUnits)
                }

                loadDataAndInitialize().then(() => {
                    checkChartSelections(vm.currentChart)
                })
            }

            function checkChartSelections(chart) {
                let setToSaved = false
                //If currently saved chart is selected, set the 'save icon' to show the appropriate color (set as already saved)
                if (chart) {
                    if (vm.useSinglePointView) {
                        if (selectedChartSettings.useSingleValue
							&& (!vm.selectedPeriod
								|| (selectedChartSettings.period === (vm.selectedPeriod && vm.selectedPeriod.span)))) {
                            setToSaved = true
                        }
                    }
                    else {
                        if (chart.actualType) {
                            if (selectedChartSettings.chartType === chart.actualType
								&& selectedChartSettings.aggregateMode === chart.aggregateMode
                                && selectedChartSettings.showAverage === chart.showAverage
                                && selectedChartSettings.showTargets === chart.showTargets
                                && selectedChartSettings.aggregateYearly === vm.aggregateYearly
								&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
                            ) {
                                setToSaved = true
                            }
                        }
                        else {
                            if (selectedChartSettings.chartType === chart.type
								&& selectedChartSettings.aggregateMode === chart.aggregateMode
                                && selectedChartSettings.showAverage === chart.showAverage
                                && selectedChartSettings.showTargets === chart.showTargets
                                && selectedChartSettings.aggregateYearly === vm.aggregateYearly
								&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
                            ) {
                                setToSaved = true
                            }
                        }
                    }
                }

                if (setToSaved) {
                    setChartSelectionAsSaved()
                }
                else {
                    setChartSelectionsAsUnsaved()
                }
            }

            function saveCurrentChartSelections() {
                if ((selectedChartSettings.useSingleValue === vm.useSinglePointView && selectedChartSettings.period === (vm.selectedPeriod && vm.selectedPeriod.span))
					&& (selectedChartSettings.chartType === vm.currentChart.actualType || selectedChartSettings.chartType === vm.currentType)
					&& selectedChartSettings.aggregateMode === vm.currentAggregateMode
					&& selectedChartSettings.splitOption === vm.currentSplitOption
					&& selectedChartSettings.showAverage === vm.showAverage
					&& selectedChartSettings.showTargets === vm.showTargets
                    && selectedChartSettings.aggregateYearly === vm.aggregateYearly
					&& selectedChartSettings.excludeYears === vm.excludeYears
					&& selectedChartSettings.aggregatePeriodFrequencies === vm.aggregatePeriodFrequencies
					&& !!selectedChartSettings.convertMeasureAnswerUnits === vm.convertMeasureAnswerUnits
                ) return

                if (vm.useSinglePointView && vm.selectedPeriod) {
                    selectedChartSettings = { useSingleValue: true, period: vm.selectedPeriod.span }
                    if (vm.aggregateYearly || vm.aggregatePeriodFrequencies) {
                        selectedChartSettings.splitOption = vm.currentSplitOption
                    }
                }
                else {
                    selectedChartSettings = {
                        chartType: vm.currentChart.actualType || vm.currentType,
                        aggregateMode: vm.currentAggregateMode,
                        splitOption: vm.currentSplitOption,
                        showAverage: vm.showAverage,
                        aggregateYearly: vm.aggregateYearly,
                    }

                }

                if (vm.convertMeasureAnswerUnits) selectedChartSettings.convertMeasureAnswerUnits = true
                else delete selectedChartSettings.convertMeasureAnswerUnits

                if (selectedChartSettings.splitOption === 'yearly') {
                    vm.aggregateYearly = true
                    delete selectedChartSettings.splitOption
                    vm.currentChart.aggregateYearly = true
                    delete vm.currentChart.splitOption
                }

                selectedChartSettings.showAverage = vm.showAverage
                selectedChartSettings.showTargets = vm.showTargets
                selectedChartSettings.excludeYears = vm.excludeYears
                selectedChartSettings.aggregateYearly = vm.aggregateYearly
                selectedChartSettings.aggregatePeriodFrequencies = vm.aggregatePeriodFrequencies

                vm.savingChartSettings = true

                dataOps.saveSettings({
                    forOrganization: true,
                    item: itemForSavedSettings,
                    settings: {
                        chartSettings: JSON.stringify(selectedChartSettings),
                    },
                }).then(() => {
                    setChartSelectionAsSaved(true)
                })
            }

            function setChartSelectionAsSaved(saveButtonClicked) {
                if (!vm.showSaveButton) return

                vm.unsavedChartSettings = false

                if (saveButtonClicked) {
                    vm.savingChartSettings = false
                    vm.savingChartSettingsJustCompleted = true

                    $timeout(() => {
                        vm.savingChartSettingsJustCompleted = false
                    }, 1000)
                }
                else vm.saveButtonTooltipText = $translate.instant('modules.visualization.chartist.saveButtonTooltipMessages.selectedChartType')

                $timeout()
            }

            function setChartSelectionsAsUnsaved() {
                if (!vm.showSaveButton) return

                vm.saveButtonTooltipText = $translate.instant('modules.visualization.chartist.saveButtonTooltipMessages.clickToSaveChartType')
                vm.unsavedChartSettings = true
            }

            function watchChartistWidth() {
                const
                    frequency = 100

                let chartistWidthWatcher

                let debounceEvent

                debounceEvent = _.debounce(() => {
                    vm.currentChart.chartElement.stop(true, true)
                    vm.currentChart.chartElement.animate({ opacity: 0 }, 100, () => {
                        vm.currentChart.recreateChart()
                    })
                }, frequency * 2, { leading: false, trailing: true })

                chartistWidthWatcher = $scope.$watch(() => {
                    return $element.width()
                }, () => {
                    if (vm.currentChart && vm.currentChart.chartInstance) {
                        // vm.currentChart.loaded = false;
                        debounceEvent()
                    }
                })

                $scope.$on('$destroy', () => {
                    chartistWidthWatcher()
                })
            }

            function showSinglePointView(period, wasJustLoaded) {
                if (vm.excludeYears && vm.excludeYears.length > 0) {
                    vm.aggregatePeriodFrequencies = false
                    resetYearFilter()
                    loadDataAndInitialize().then(() => {
                        createSinglePointView(period, wasJustLoaded)
                    })
                }
                else {
                    createSinglePointView(period, wasJustLoaded)
                }
            }

            function createSinglePointView(period, wasJustLoaded) { // period = { label: "2019", span: "20190101|20191231" } or "20190101|20191231"
                vm.periodsToSelect = _.get(vm.negotiator, "itemMetadata.statistics.datasetLabels").map((label, index) => ({ label, span: datasetPeriodSpans && datasetPeriodSpans[index] }))
                if (vm.currentChart && vm.currentChart.loaded) {
                    vm.currentChart.loaded = false
                }

                let datasets = _.get(vm.negotiator, 'itemMetadata.statistics.datasets')
                const datasetsHasSingleValue = datasets.every(dataset => dataset.data.length === 1) && datasetPeriodSpans.length === 1
                datasets = datasets.filter(dataset => dataset.id.includes(periodId) || dataset.id.includes(targetId))
                if (!datasetsHasSingleValue) {
                    vm.useSinglePointData = datasets[0].data[0]
                    vm.isInconsistentData = datasets[0].inconsistent
                    vm.selectedPeriod = vm.periodsToSelect[0]
                }

                if (period) {
                    if (typeof period === 'string') {
                        if (period.indexOf('|') === -1) {
                            period = vm.periodsToSelect.find(x => x.label === period)
                        }
                        else {
                            period = vm.periodsToSelect.find(x => x.span === period)
                        }
                    }

                    vm.selectedPeriod = period
                }
                else {
                    //check if a period is already selected by the user and display it on type switch
                    if (selectedChartSettings.period) {
                        vm.selectedPeriod = vm.periodsToSelect.find(x => x.span === selectedChartSettings.period)
                    }
                    else {
                        //get the latest datasetLabel and display it
                        vm.selectedPeriod = vm.periodsToSelect[vm.periodsToSelect.length - 1]
                    }
                }

                //vm.datasetPeriodSpan = datasetPeriodSpans[vm.labelsToSelect.indexOf(vm.selectedPeriod)]

                if (vm.selectedPeriod) {
                    vm.useSinglePointData = _.find(datasets[0].data, (dataset) => {
                        return dataset.label === vm.selectedPeriod.label
                    })
                    vm.isInconsistentData = datasets[0].inconsistent
                }

                if (!wasJustLoaded && !vm.allPeriodsLoaded) {
                    if (vm.wfListInterfaceStatistical) {
                        vm.wfListInterfaceStatistical.updateNegotiatorConfigAndCompile({
                            periodSpan: vm.selectedPeriod.span,
                        })
                    }
                    else {
                        vm.negotiator.periodSpan = vm.selectedPeriod.span
                        vm.negotiator.loadItemsFromServer()
                    }
                }

                $timeout(() => {
                    if (vm.currentChart && !vm.currentChart.loaded) {
                        vm.currentChart.loaded = true
                        vm.useSinglePointView = true
                        if (!wasJustLoaded) {
                            checkChartSelections(vm.currentChart)
                        }
                    }
                })
            }

            function capture($event) {
                const deferred = $q.defer()

                vm.dashboardPanelBodyElement = $($event.target).closest('div.panel-body')//.find(".currentChart");
                vm.isDashboardPanel = !!vm.dashboardPanelBodyElement.length

                if (vm.isDashboardPanel) {
                    vm.dashboardPanelElement = vm.dashboardPanelBodyElement.parent()
                    vm.dashboardPanelElement.addClass('capturing-visualization')
                    vm.dashboardPanelElement.attr('data-capturing-title', $translate.instant('modules.visualization.download.downloadingMessage'))
                    vm.wfChartComponentElement = vm.dashboardPanelBodyElement.find('div.wfChartComponent')

                    setTimeout(() => {
                        if (!vm.useSinglePointView) {
                            vm.currentChart.showLegend = true
                            vm.currentChart.recreateChart().then(() => {
                                setTimeout(() => {
                                    vm.dashboardPanelElement.height(vm.dashboardPanelElement.height())

                                    vm.dashboardPanelBodyElement.height(vm.wfChartComponentElement.height() + 27)

                                    doCapture()
                                }, 400)
                            })
                        }
                        else {
                            doCapture()
                        }
                    }, 100)
                }
                else {
                    doCapture()
                }

                function doCapture() {
                    vm.dashboardPanelBodyElement.find('span').removeAttr('xmlns')
                    vm.elementsToHideInCapture = vm.dashboardPanelBodyElement.find('div.top-bar, div.container-open, div.chartist-series-selection, div.relative-measure-calculation-info, div.fiscalYearMonthOffset-info')

                    vm.elementsToHideInCapture.css('visibility', 'hidden')

                    const captureElement = vm.dashboardPanelBodyElement.get(0)
                    html2canvas(captureElement, { foreignObjectRendering: false, scale: 2.5 })
                        .then((canvas) => {
                            canvas.style.display = 'none'
                            document.body.appendChild(canvas)
                            deferred.resolve(canvas)
                        })
                }

                return deferred.promise
            }

            function getDataOptionSelectedItemText() {
                if (vm.splitUpOrganizationStatistics) {
                    return 'modules.visualization.aggregateOptions.separateOrganizations_short'
                }

                if (vm.splitUpRelativeMeasureSourcesStatistics) {
                    return 'modules.visualization.aggregateOptions.separateRelativeMeasureSources'
                }

                if (vm.aggregatePeriodFrequencies) {
                    return 'modules.visualization.aggregateOptions.combineFrequency'
                }

                return 'modules.visualization.aggregateOptions.combine_short'
            }

            function showcombineFrequencyOption() {
                return true
                const datasetPeriodSpans = _.get(vm.negotiator, 'itemMetadata.statistics.datasetPeriodSpans')
                const datasetPeriodSpansToSelect = datasetPeriodSpans.map(d => d.slice(0, 4))
                const isYearArray = datasetPeriodSpansToSelect.filter((item, index) => datasetPeriodSpansToSelect.indexOf(item) !== index)
                return isYearArray.some(element => element)
            }

            function captureChart($event) {
                capture($event).then((canvas) => {
                    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                    const a = document.createElement('a')
                    a.setAttribute('download', `WF_Chart_${vm.itemWfid}.png`)
                    a.setAttribute('href', image)
                    a.click()
                    a.remove()
                    canvas.remove()

                    vm.elementsToHideInCapture.css('visibility', '')

                    if (vm.isDashboardPanel) {
                        if (!vm.useSinglePointView) {
                            vm.currentChart.showLegend = false
                        }
                        vm.currentChart.recreateChart().then(() => {
                            setTimeout(() => {
                                vm.dashboardPanelElement.css('height', '')
                                vm.dashboardPanelBodyElement.css('height', '')
                                vm.dashboardPanelElement.removeClass('capturing-visualization')
                                vm.dashboardPanelElement.removeAttr('data-capturing-title')
                            }, 250)
                        })
                    }
                })
            }

            function openMeasureTargetsEditor() {
                return $q((resolve) => {
                    const templateHtml = '<wf-measure-targets-editor item="item"></wf-measure-targets-editor>'

                    const scope = _.assign($rootScope.$new(), {
                        item: vm.fromItem,
                        result: {},
                    })

                    const modal = $uibModal.open({
                        animation: true,
                        size: 'width-800',
                        backdrop: 'static',
                        template: templateHtml,
                        scope,
                    })

                    modal.closed.then(() => {
                        vm.measureTargetsHasChanged = true
                        loadDataAndInitialize().then(() => {
                            checkChartSelections(vm.currentChart)
                            vm.measureTargetsHasChanged = false
                            resolve(scope.result)
                        })
                    })
                })
            }

            function clearRelativeMeasureResultsCacheAndRecalculate() {
                return $q((resolve) => {
                    dataOps.clearRelativeMeasureResultsCache({
                        relativeMeasureIds: [ vm.fromItem.id ],
                        networkId: _.get(ticket, 'networkId'),
                    }).then(() => {
                        loadWithQueue()
                        resolve()
                    }).catch(() => {
                        modal.alert({
                            title: $translate.instant('modules.visualization.cache.errorDuringClearing'),
                            message: `WFID: ${vm.fromItem.wfid}`,
                            type: 'warning',
                        })
                        resolve()
                    })
                })
            }
        }

        vm.$onDestroy = function () {
            scopeDestroyed = true
            $element.off('hide.bs.dropdown', 'div.filter-years')
            if (vm.useQueue && !skipQueue) {
                queue.remove(vm.dataRelationId)
            }
        }
    }
})()
