import * as React from 'react'
import { useState, useEffect } from 'react'
import Tree from '@worldfavor/components/Tree'
import OrganizationNode from './OrganizationNode'
import AddOrganizationNode from './AddOrganizationNode'
import OrganizationSearchMenu from '../OrganizationSearchMenu'
import NoOrganizationNode from './NoOrganizationNode'
import ProductNode from './ProductNode'
import { getSubEntitiesFromNodeId } from '../../../selectors/graphSelector'

const TreeView = (props) => {
    const {
        id,
        organizations,
        nodes,
        edges,
        edit,
        search,
        onNodeHoverChange,
        onNodeClick,
        onAddNode,
        onRemoveNode,
        onSetActorTypeClick,
        showOrganizationNodeDropdown,
        allowEditingActorTypes,
        ...rest
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const [menu, setMenu] = useState({ parentWfid: null })
    const [lastCreatedNode, setLastCreatedNode] = useState(null)

    useEffect(() => {
        if (lastCreatedNode) {
            const subEntity = getSubEntitiesFromNodeId({ graph: { ...nodes, ...edges } }, lastCreatedNode.parentWfid)
                .filter(x => !x.node.temporary && x.node.properties.organizationWfid === lastCreatedNode.createdWfid)[0]

            if (subEntity) {
                const newNode = subEntity.node

                setLastCreatedNode(null)
                const createdOrganization = organizations.find(org => org.wfid === newNode.properties.organizationWfid)
                if (createdOrganization) {
                    onSetActorTypeClick(null, newNode, createdOrganization)
                }
            }
        }
    }, [nodes])

    function onAddOrganizationClick(e, containerEl, parentWfid) {
        setAnchorEl(containerEl)
        setMenu({ parentWfid })
    }

    function handleMenuClose() {
        setAnchorEl(null)
        setMenu({ parentWfid: null })
    }

    function onMenuItemClick(e, wfid) {
        setLastCreatedNode({ parentWfid: menu.parentWfid, createdWfid: wfid })
        onAddNode(menu.parentWfid, wfid)
        handleMenuClose()
    }

    function _onDropped(parentWfid, droppedWfid) {
        setLastCreatedNode({ parentWfid, createdWfid: droppedWfid })
        onAddNode(parentWfid, droppedWfid)
    }

    function renderNode({ node, active, onMouseOver, onMouseOut }) {
        if (node.label === 'product') {
            return (
                <ProductNode productWfid={node.properties.productWfid} />
            )
        }

        return (
            <OrganizationNode
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onHoverChange={onNodeHoverChange}
                organization={organizations.find(({ wfid }) => wfid === node.properties.organizationWfid)}
                node={node}
                onClick={onNodeClick}
                active={active}
                matchText={search}
                canDelete={edit}
                onDelete={onRemoveNode}
                showDropdownMenu={showOrganizationNodeDropdown}
                allowEditingActorTypes={allowEditingActorTypes}
                onSetActorTypeClick={onSetActorTypeClick}
            />
        )
    }

    function renderPlaceholder(id, node) {
        if (node.label === 'product' && organizations.length === 0) {
            return (
                <NoOrganizationNode />
            )
        }

        return (
            <AddOrganizationNode
                parentWfid={id}
                onDrop={_onDropped}
                onClick={onAddOrganizationClick}
            />
        )
    }

    return (
        <React.Fragment>
            <Tree
                id={id}
                nodes={nodes}
                edges={edges}
                renderNode={renderNode}
                renderPlaceholder={edit ? renderPlaceholder : null}
                {...rest}
            />
            <OrganizationSearchMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                organizations={organizations}
                onMenuItemClick={onMenuItemClick}
                style={{ zIndex: 9999 }}
            />
        </React.Fragment>
    )
}

TreeView.defaultProps = {
    organizations: [],
}

export default TreeView
