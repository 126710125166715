import * as React from 'react'
import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Droppable from '@worldfavor/components/DragAndDrop/Droppable'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
        position: 'relative',
    },
    active: {
        '& $container': {
            backgroundColor: Colors.grayLight,
        },
    },
    sidePan: {
        position: 'absolute',
        borderRadius: '4px 0px 0px 4px',
        backgroundColor: Colors.gray,
        width: 4,
        height: '100%',
    },
    container: {
        border: `dashed 2px ${Colors.graySemiLight}`,
        borderRadius: 4,
        padding: 18,

        display: 'flex',
    },
    iconContainer: {
        boxSizing: 'content-box',
        backgroundColor: Colors.grayHover,
        height: 28,
        width: 28,
        marginRight: 18,
        borderRadius: 2,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: 12,
    },
    title: {
        marginBottom: 2,
        width: 120,
    },
    subtitle: {
        width: 65,
    },
    placeholder: {
        backgroundColor: Colors.grayHover,
        height: 13,
    },
})

const AddOrganizationNode = (props) => {
    const { parentWfid, onDrop, onClick } = props
    const classes = useStyles(props)
    const container = useRef(null)

    function _onDrop(droppedWfid) {
        onDrop(parentWfid, droppedWfid)
    }

    function _onClick(e) {
        onClick(e, container.current, parentWfid)
    }

    return (
        <Droppable onDrop={_onDrop} targets={'ORGANIZATION-BOX'}>
            {
                (canDrop, isOver) => (
                    <div
                        ref={container}
                        className={classNames(classes.root, canDrop && isOver && classes.active)}
                        onClick={_onClick}
                    >
                        {/*<div className={classes.sidePan} />*/}
                        <div className={classes.container}>
                            <div className={classes.iconContainer}>
                                <i className={classNames(classes.icon, 'fas fa-plus')} />
                            </div>

                            <div>
                                <div className={classNames(classes.title, classes.placeholder)} />
                                <div className={classNames(classes.subtitle, classes.placeholder)} />
                            </div>
                        </div>
                    </div>
                )
            }
        </Droppable>
    )
}

AddOrganizationNode.defaultProps = {
    onDrop: () => {},
    onClick: () => {},
}

export default AddOrganizationNode
