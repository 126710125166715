// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDialogState } from '@worldfavor/hooks'
import TextField from '@material-ui/core/TextField'
import ImagePicker from '@worldfavor/components/ImagePicker'
import LocationPickerDialog from '@worldfavor/components/Leaflet/LocationPickerDialog'
import { FormattedMessage } from 'react-intl'

const styles = {
    root: {
        flex: 1,
        paddingTop: 28,
        paddingLeft: 68,
        paddingRight: 68,
    },
    inlineRow: {
        display: 'flex',

        '&>div': {
            flex: 1,
        },

        '& >:not(:last-child)': {
            marginRight: 16,
        },
    },
    organizationInfo: {
        marginTop: 16,
        display: 'flex',
    },
    image: {
        width: 112,
        height: 112,
        borderRadius: 4,
        marginRight: 26,
    },
    nameField: {
        marginTop: 0,
    },
}

const CreateOrganizationStep = ({
    classes,
    fields = {},
    descriptors = {},
    validation = {},
    showValidation,
    onChange,
}) => {
    const [open, openDialog, closeDialog] = useDialogState(false)

    function onSelectAddress(event, selectedAddress) {
        onChange && onChange(event, 'location', { formattedAddress: selectedAddress.address.LongLabel, ...selectedAddress })
        closeDialog()
    }

    function openAddressDialog(event) {
        openDialog()
        event.stopPropagation()
        event.preventDefault()
    }

    const handleChange = key => (event) => {
        onChange && onChange(event, key, event.target.value)
    }

    function getTextField(key, props = {}) {
        const { id, labelId, required, ...rest } = descriptors[key] || {}

        const textFieldProps = {
            id,
            label: <FormattedMessage id={labelId} />,
            value: fields[key],
            onChange: handleChange(key),
            margin: 'normal',
            variant: 'filled',
            error: showValidation && validation[key],
            required,
            ...props,
            ...rest,
            ...(
                key === 'location' ? {
                    onClick: openAddressDialog,
                    value: (fields.location || {}).formattedAddress || '',
                    InputProps: {
                        readOnly: true,
                    },
                } : null
            ),
        }

        return (
            <TextField {...textFieldProps} />
        )
    }

    function onFileChange(event, files, url) {
        onChange && onChange(event, 'image', { files, url })
    }

    const imageUrl = (fields.image && fields.image.url) || null

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.organizationInfo}>
                <ImagePicker
                    className={classes.image}
                    src={imageUrl}
                    onFileChange={onFileChange}
                />
                <div style={{ flex: 1 }}>
                    { getTextField('name', { className: classes.nameField, fullWidth: true }) }
                    <div className={classes.inlineRow}>
                        { getTextField('location') }
                        { getTextField('website') }
                    </div>
                </div>
            </div>

            <LocationPickerDialog
                open={open}
                onClose={closeDialog}
                onCancel={closeDialog}
                onSelectAddress={onSelectAddress}
            />
        </form>
    )
}

export default withStyles(styles)(CreateOrganizationStep)
