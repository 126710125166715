// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Image from '@worldfavor/components/Image'
import Title from './Title'
import Description from './Description'
import PreHeader from './PreHeader'

type Props = {
  imageStyles: { [string]: any },
  imageUrl?: string,
  preHeader?: string | React.Node,
  title?: string | React.Node,
  description?: string | React.Node,
  PreHeaderProps?: Object,
  TitleProps?: Object,
  DescriptionProps?: Object,
}

const useStyles = makeStyles({
    header: {
        display: 'flex',
    },
    titleDescriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'start',
        marginRight: 20,
    },
})

const Header = (props: Props) => {
    const {
        imageStyles,
        imageUrl,
        preHeader,
        title,
        description,
        PreHeaderProps,
        TitleProps,
        DescriptionProps,
    } = props
    const classes = useStyles(props)

    return (
        <React.Fragment>
            { preHeader && <PreHeader {...PreHeaderProps}>{preHeader}</PreHeader> }
            <div className={classes.header}>
                {
                    imageUrl && (
                        <div className={classes.imageWrapper}>
                            <Image src={imageUrl} style={imageStyles} size={'cover'} />
                        </div>
                    )
                }
                <div className={classes.titleDescriptionWrapper}>
                    { title && <Title {...TitleProps}>{title}</Title> }
                    { description && <Description {...DescriptionProps}>{description}</Description> }
                </div>
            </div>
        </React.Fragment>
    )
}

Header.defaultProps = {
    imageStyles: { width: 85, height: 85, borderRadius: 3 },
}

export default Header
