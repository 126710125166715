(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('SupplierGroupController2', SupplierGroupController2)

    SupplierGroupController2.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$timeout', '$translate']
    function SupplierGroupController2($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $timeout, $translate) {
        const vm = this
        _.assign(this, $scope.$parent.vm)
		
        $scope.modal = modal
        $scope.wfObject = wfObject
        $scope.dataQuery = dataQuery
        if (vm.context) {
            vm.context.templateId = null
			
            if (vm.context.settings) vm.context.settings.templateId = null
        }
		
        _.assign(vm, {
            categoryTypes: [],
            filteredCategoryTypes: [],
            selectedId: null,
            filterByCategoryType,
            createCategory,
            supplierCategories: null,
            supplierDemands: null,
            addSupplierDemands,
            categoriesEmptyState: {
                header: $translate.instant('modules.valueChain.subCategories.emptyStateHeader'),
                body: $translate.instant('modules.valueChain.subCategories.emptyStateDescription'),
            },
        })

        vm.demandsEmptyState = vm.context && vm.context.emptyState ? vm.context.emptyState : {
            header: $translate.instant('modules.valueChain.demands.emptyStateHeader'),
            body: $translate.instant('modules.valueChain.demands.emptyStateDescription'),
        }
		
        activate()

        ////////////////

        function activate() {
            vm.networkId = vm.item.childContent.conditions.networkId
            vm.context = vm.context || {}
            vm.context.networkId = vm.networkId
            vm.categoriesStructure = vm.item.childContent.childs[0]
            vm.demandsStructure = vm.item.childContent.childs[1]
            // console.log(vm.categoriesStructure.childContent.wfid, vm.demandsStructure.childContent.wfid);

            dataOps.getSubItems(vm.categoriesStructure.childContent, 7, { childrenLoadDepth: 1 }).then((supplierCategories) => {
                vm.supplierCategories = supplierCategories
                // vm.filteredCategoryTypes = res;
                dataOps.getSubItems(vm.demandsStructure.childContent, 7,  { childrenLoadDepth: 1 }).then((supplierDemands) => {
                    vm.supplierDemands = supplierDemands
                    $timeout(() => {})
                })
            })
        }
		
        function filterByCategoryType(categoryType) {
            if (categoryType)
            {
                if (categoryType.childId == vm.selectedId) categoryType = null
            }
			
            vm.selectedId = categoryType ? categoryType.childId : null
            vm.filteredCategoryTypes = _.filter(vm.categoryTypes, vm.selectedId ? { childId: vm.selectedId } : {})
        }

        function createCategory() {
            const
                jqDf = $.Deferred()
			
            modal.createWithRelation({
                objectType: 71,
                simplifyForm: true,
                dataRelationOptions: { kind: 7, item1: vm.categoriesStructure.childContent },
            }).then((categoryDataRelation) => {
                if (!categoryDataRelation)
                {
                    jqDf.resolve(false)
                    return
                }

                dataOps.saveSettings({
                    item: categoryDataRelation.childContent,
                    settings: {
                        dataRelation: true,
                        includeOrganizationsInfluences: true,
                        includeOrganizationsUsers: true,
                        // dataRelationByUser: true
                    },
                }).then(() => {
                    vm.supplierCategories = vm.categoriesStructure.childContent.childs

                    vm.categoriesStructureCompiler.compile()
					
                    jqDf.resolve()
                    // vm.supplierGroups = vm.groupsStructure.childs;
                })
            })

            return jqDf.promise()
        }

        function addSupplierDemands() {
            const modalPromise = modal.openItem({
                size: 'sm',
                itemByPath: {
                    sourceItem: vm.item.childContent.childs[1].childContent,
                    path: 'parentByUser/parent/parent/child:1',
                },
                relationTarget: {
                    item: vm.demandsStructure.childContent,
                    kind: 7,
                },
                settings: {
                    templateId: 4,
                    childrenTemplateId: 15,
                },
            })
			
            modalPromise.modal.closed.then(() => {
                vm.supplierDemands.length = 0
                Array.prototype.push.apply(vm.supplierDemands, vm.demandsStructure.childContent.childs)

                vm.demandsStructureCompiler.compile()

                // $timeout(function() {});
            })
        }
    }
})()
