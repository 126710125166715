import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc controller
 * @name InfluenceGrouperController
 *
 * @description
 * Used for displaying a list of items that a reporting organization(s) have picked inside an influnce.
 *
 * Can be used as a directive with wfInfluenceGrouper.directive.js
 *
 * Supports three scenarios:
 * A. Inside an influence for a reporting organization:
 *    The organization adds items from a picker that gets tagged as childrenByUser to the structure (requirement package).
 *    Influences are automatically created for each picked item, with the influences contextParentWfids property set to the respective item's wfid.
 *    The picked items gets displayed in a list with their influences underneath each item.
 *
 * B. Inside an influence for the creator/sender organization:
 *    Picked items gets displayed but the influences for each item is shown in Data Collector style rendering (with the indicator icons).
 *    A button to add more requirement packages for each items is shown.
 *
 * C. Aggregation mode for the creator/sender organization:
 *    Shows a combined distinct list of items that were picked by all organizations.
 *    The influence underneath each item is grouped by the organization that added the item.
 */
(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('InfluenceGrouperController', InfluenceGrouperController)

    InfluenceGrouperController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$q', '$timeout', 'wfAuth', 'valueChainService', 'NgMap', '$sanitize', '$injector', 'apiProxy', '$state', 'wfTranslate']
    function InfluenceGrouperController($scope, dataOps, modal, wfObject, dataQuery, $translate, $q, $timeout, wfAuth, valueChainService, NgMap, $sanitize, $injector, apiProxy, $state, wfTranslate) {
        const
            vm = this
			
        let mainInfluence
			
        let contextParentWfids
			
        let mainStructure
			
        let mainStructureId
			
        let intersectionSettings
			
        let networkId
			
        let organizationId
			
        let organization
			
        const authOrgId = wfAuth.getOrganizationId()
			
        let culture = wfAuth.getCulture()
			
        let authenticatedOrgIsRecipient = false
			
        let influence
        // The opened influence. Used in scenario A and B (not C / aggregation mode)

        // Scenario A variables
			
        let autoCreatedInfluenceSettings
        // From requirementPackageSettings condition on the requirement package structure
			
        let autoCreatedInfluence_objectId
			
        let autoCreatedInfluence_objectIds
			
        let autoCreatedInfluence_objectType
			
        let form
			
        let targetContextParentWording

        // For cases where influences should be created on every sub item on selected items.
        // For example on a product/service the user can specify which sub-products/sub-services it consists of
        // and each of the those sub-items will receive an influence. This variable will be objectId that those influences will have.
			
        let autoCreatedInfluence_subItemsTargetObjectIds
			
        let shouldAutoCreateInfluencesOnSubItems = false
			
        const reportStructureId = 10489 // Structure that contains Influences to the current company

        _.assign(vm, {
            authOrgId,
            pickButtonCaption: undefined,
            pickedItemsByWfid: undefined,
            influences: undefined,
            reportMode: false,
            influenceMode: false,
            aggregateMode: false,
            mapId: 'influenceGrouper' + $scope.$id,
            openedViaRoute: false,
            listCompiler: {},
            isGoogleJsLoaded: 'google' in window,

            // Arrays
            mainItemsArray: [], // Anything that can be picked (like ProductionSites)
            distinctInfluenceStructures: [],

            // Lookup objects
            itemCompilers: {},
            influencesByContextParentWfid: {}, // Influences by wfid of for example ProductionSite (Influences always sorted by organizationId)
            organizationsById: {}, // Recipient organization(s) of the influence(s)

            // Functions
            openPicker,
            openInfluenceModal,
            openInfluenceCreator,
            deleteInfluence,
            changeCategories,

        })

        activate()

        ////////////////

        function syncFilter() {

        }

        function activate() {
            let showFulfillmentStatusFiltering = false

            if (culture !== 'en-US' && culture !== 'sv-SE') {
                culture = 'en-US'
            }
            // If scenario C:
            // Influences will be loaded in loadInfluencesWithContextParents function if openedViaRoute is true
            // otherwise influence must be passed in the directive.
            vm.openedViaRoute = $injector.has('$stateParams')

            vm.filteringConfig = { items: vm.mainItemsArray, collapsable: false, filters: [
                { bySearch: true },
                { sourceIdPath: 'wfcid', bySubItemsKind: 8, organizationId: vm.organizationIds, load: true, intersectionSourceWfid: '71-14177', headerTranslate: 'RawMaterial', canDeselectAll: true },
                { sourceIdPath: 'wfcid', bySubItemsKind: 8, organizationId: vm.organizationIds, load: true, intersectionSourceWfid: '71-13804', headerTranslate: 'CountryOfOrigin', supContentType: 75, canDeselectAll: true },
                { sourceIdPath: 'wfcid', bySubItemsKind: 8, organizationId: vm.authOrgId, load: true, intersectionSourceWfid: '71-12224', headerTranslate: 'Egna kategorier', groupBy: true, canDeselectAll: true },
            ] }

            if ($scope.openedViaDirective) {
                influence = $scope.influence
                mainStructure = vm.mainStructure = $scope.influenceChildContent
                mainStructureId = mainStructure.id

                if (influence) { // Scenario A and B
                    networkId = influence.channelId
                    organizationId = influence.organizationId
                    organization = wfObject.get('101-' + organizationId)

                    if (influence.organizationId === authOrgId) {
                        // Scenario A
                        authenticatedOrgIsRecipient = true
                        vm.reportMode = true
                        vm.pageTitle = influence.title

                        autoCreatedInfluenceSettings = _.get(mainStructure.conditions, 'requirementPackageSettings.autoCreatedInfluenceSettings')
                        form = _.get(mainStructure.conditions, 'requirementPackageSettings.form'),
                        targetContextParentWording = _.get(mainStructure.conditions, 'requirementPackageSettings.targetContextParentWording')

                        autoCreatedInfluence_objectId = mainStructure.conditions.secondaryObjectId
                        autoCreatedInfluence_objectType = mainStructure.conditions.secondaryObjectType || enums.objectType.structure

                        if (autoCreatedInfluenceSettings) { // Check if settings are in requirementPackageSettings.autoCreatedInfluenceSettings
                            autoCreatedInfluence_objectIds = _.get(autoCreatedInfluenceSettings, 'targetObjectIds') // Array of IDs
                        }

                        if (!autoCreatedInfluence_objectIds && autoCreatedInfluence_objectId) autoCreatedInfluence_objectIds = [autoCreatedInfluence_objectId] // Always use an array of IDs

                        // Array of object IDs for the influences that will be created for the sub items of newly selected items
                        autoCreatedInfluence_subItemsTargetObjectIds = _.get(autoCreatedInfluenceSettings, 'subItemsTargetObjectIds')
                        if (_.get(autoCreatedInfluence_subItemsTargetObjectIds, 'length')) {
                            shouldAutoCreateInfluencesOnSubItems = true
                        }
                    }
                    else {
                        // Scenario B
                        vm.influenceMode = true
                        vm.pagePreTitle = organization.name

                        vm.filteringConfig.filters.push({ filterOptionsSource: influenceStatusFilterOptionsSource, header: 'Status', canDeselectAll: true })
                    }

                    intersectionSettings = {
                        kind: enums.subItemsKind.childrenByUser,
                        organizationId: influence.organizationId,
                        // networkId: networkId
                    }
                }
                else { // Scenario C (aggregate)
                    vm.aggregateMode = true
                    vm.pagePreTitle = $translate.instant('AggregatedView')
                    networkId = $scope.network.id
                }
            }
            else if ($injector.has('$stateParams')) {
                showFulfillmentStatusFiltering = true
                $injector.invoke(['$stateParams', function ($stateParams) {
                    mainStructureId = $stateParams.structureId
                    networkId = $stateParams.networkId
                    vm.aggregateMode = true
                    vm.pagePreTitle = $translate.instant('AggregatedView')
                }])
            }

            if (showFulfillmentStatusFiltering) {
                vm.filteringConfig.filters.push({ filterOptionsSource: influenceStatusFilterOptionsSource, header: 'Status', canDeselectAll: true })
            }

            loadMainContent().then(() => {
                const
                    pickObjectType = vm.pickObjectType = _.get(mainStructure, 'conditions.objectTypes[0]')
					
                let buttonWordingType
				
                // Check if structure has condition templateId is 80 (that has InfluenceGrouper directive).
                // If it doesn't then the mainStructure is not ment to be opened with this UI
                // and user gets redirected to the Data Collector Overview.
                if (_.get(mainStructure, 'conditions.templateId') !== 80) {
                    $state.go('valueChainOrganizations', { networkId })
                }

                buttonWordingType = pickObjectType

                if (pickObjectType === enums.objectType.productionSite) {
                    vm.emptyStateHeader = $translate.instant('modules.report.influenceGrouper.productionSites.emptyStateHeader')
                    vm.emptyStateDescription = $translate.instant('modules.report.influenceGrouper.productionSites.emptyStateDescription')
                }
                else if (pickObjectType === enums.objectType.productService) {
                    vm.showSubItems = true
                    vm.emptyStateHeader = $translate.instant('modules.report.influenceGrouper.productServices.emptyStateHeader')
                    vm.emptyStateDescription = $translate.instant('modules.report.influenceGrouper.productServices.emptyStateDescription')

                    if (targetContextParentWording && targetContextParentWording.plural && targetContextParentWording.singular) {
                        vm.emptyStateHeader = $translate.instant('modules.picker.customObjectType.emptyStateHeader', { information: getTextFromProperty(targetContextParentWording.plural).toLowerCase() })
                        vm.emptyStateDescription = $translate.instant('modules.picker.customObjectType.emptyStateDescription', { information: getTextFromProperty(targetContextParentWording.singular).toLowerCase() })
                    }
                }
                else if (pickObjectType === enums.objectType.organization) {
                    if (_.get(mainStructure, 'conditions.pickerSettings.objectTypes[0]') === enums.objectType.supplier) { // Structure for Suppliers
                        vm.emptyStateHeader = $translate.instant('modules.report.influenceGrouper.suppliers.emptyStateHeader')
                        vm.emptyStateDescription = $translate.instant('modules.report.influenceGrouper.suppliers.emptyStateDescription')
                        buttonWordingType = enums.objectType.supplier
                    }
                    else if (_.get(mainStructure, 'conditions.pickerSettings.objectTypes[0]') === enums.objectType.holding) { // Structure for Portfolio Companies
                        vm.emptyStateHeader = $translate.instant('modules.report.influenceGrouper.holdings.emptyStateHeader')
                        vm.emptyStateDescription = $translate.instant('modules.report.influenceGrouper.holdings.emptyStateDescription')
                        buttonWordingType = enums.objectType.holding
                    }
                    else {
                        // Generic empty state for organizations
                        vm.emptyStateHeader = $translate.instant('modules.report.influenceGrouper.organizations.emptyStateHeader')
                        vm.emptyStateDescription = $translate.instant('modules.report.influenceGrouper.organizations.emptyStateDescription')
                    }
                }

                if (!vm.reportMode) vm.emptyStateDescription = null

                vm.pickButtonCaption = $translate.instant('Add') + ' ' + wfTranslate.instant('MAP_ObjectType', { type: buttonWordingType }).toLowerCase()

                if (targetContextParentWording && targetContextParentWording.addButton) vm.pickButtonCaption = getTextFromProperty(targetContextParentWording.addButton)

                if (vm.reportMode) {
                    const targetObjectsToGetWfid = _.chain(_.concat(autoCreatedInfluence_objectIds, autoCreatedInfluence_subItemsTargetObjectIds))
                        .uniq()
                        .filter((id) => {
                            return !wfObject.get('71-' + id)
                        })
                        .map((id) => {
                            return '71-' + id
                        })
                        .value()

                    if (targetObjectsToGetWfid.length) {
                        dataOps.getObjects(targetObjectsToGetWfid).then((result) => {
                        })
                    }

                    if (pickObjectType === enums.objectType.organization) {
                        vm.dropdownActions = null
                    }
                    else if (pickObjectType === enums.objectType.productService) {
                        vm.subItemDropdownActions = [{
                            text: 'Edit',
                            icon: 'fa fa-pencil',
                            action(itemContent, itemRelation, dropdownControl) {
                                modal.edit(itemContent, {
                                    title: form && form.typeOptionsByProperty ? $translate.instant('Edit') + ' ' + getTextFromProperty(_.get(form.typeOptionsByProperty, 'consistsOf.wording.singular')).toLowerCase() : undefined,
                                    wording: targetContextParentWording,
                                    simplifyForm: form && form.limitFields ? form.limitFields : undefined,
                                    formFieldTitlesByProperty: form && form.fieldTitlesByProperty ? form.fieldTitlesByProperty : undefined,
                                    typeOptionsByProperty: form && form.typeOptionsByProperty ? form.typeOptionsByProperty : undefined,
                                    requiredFields: form && form.requiredFields ? form.requiredFields : undefined,
                                }).then(() => {
                                    dropdownControl.notifyItemUpdated() // Tells the dropdown to trigger update events so the UI syncs
                                })
                            },
                        }]
                        vm.dropdownActions = [{
                            text: 'Edit',
                            icon: 'fa fa-pencil',
                            action(itemContent, itemRelation, dropdownControl) {
                                const
                                    subItemsBeforeEditing = itemContent.childs
									
                                let subItemsAfterEditing
									
                                let newlySelectedSubItems
								
                                modal.edit(itemContent, {
                                    title: targetContextParentWording ? $translate.instant('Edit') + ' ' + getTextFromProperty(targetContextParentWording.singular).toLowerCase() : undefined,
                                    wording: targetContextParentWording,
                                    simplifyForm: form && form.limitFields ? form.limitFields : undefined,
                                    formFieldTitlesByProperty: form && form.fieldTitlesByProperty ? form.fieldTitlesByProperty : undefined,
                                    typeOptionsByProperty: form && form.typeOptionsByProperty ? form.typeOptionsByProperty : undefined,
                                    requiredFields: form && form.requiredFields ? form.requiredFields : undefined,
                                }).then(() => {
                                    subItemsAfterEditing = itemContent.childs
                                    newlySelectedSubItems = _.difference(subItemsAfterEditing, subItemsBeforeEditing) // Get any newly selected sub items
                                    if (newlySelectedSubItems.length) {
                                        createInfluencesForPickedItems(newlySelectedSubItems, autoCreatedInfluence_subItemsTargetObjectIds, true).then(() => {
                                            updateMainArray()
                                            updateLookupObjects()
                                            dropdownControl.notifyItemUpdated() // Tells the dropdown to trigger update events so the UI syncs
                                        })
                                    }
                                    else {
                                        updateMainArray()
                                        updateLookupObjects()
                                        dropdownControl.notifyItemUpdated() // Tells the dropdown to trigger update events so the UI syncs
                                    }
                                })
                            },
                        }]
                    }
                    else {
                        vm.dropdownActions = ['edit']
                    }
                }
                else {
                    vm.dropdownActions = vm.subItemDropdownActions = [
                        {
                            text: 'OpenObjectViewer', icon: 'fa fa-external-link',
                            hrefTarget: '_blank',
                            href(dropdownVm) {
                                return $state.href('objectViewer_encoded', { encodedData: dropdownVm.itemContent.getEncodedWfid(), network: networkId })
                            },
                        },
                    ]
                }

                updateMainArray() // Only applicable in scenario A and B

                maybeLoadValueChainCategorizations().then(() => {
                    loadInfluencesWithContextParents().then(() => {
                        updateLookupObjects()

                        // Checks if selectable objectTypes have location data
                        if (_.intersection(mainStructure.conditions.objectTypes, [enums.objectType.location, enums.objectType.productionSite]).length) initializeLocationData()

                        vm.loaded = true
                        $timeout()
                    })
                })
            })

            $scope.$watchCollection('vm.filteredItems', () => {
                vm.filteredContextParentWfids = _.chain(vm.filteredItems).map('wfcid').value()
                vm.influences = _.orderBy(vm.influences, ['organizationId', 'title'])
                vm.distinctInfluenceStructures = _.chain(_.filter(vm.influences, (influence) => {
                    return influence.contextParentWfids && _.includes(vm.filteredContextParentWfids, influence.contextParentWfids)
                }))
                // .filter({ objectType: enums.objectType.structure })
                    .uniqBy('objectId')
                    .map('childContent')
                    .filter((item) => {
                        return item
                    })
                    .sortBy('title')
                    .value()
            })
        }

        // Only applicable in scenario A and B
        function updateMainArray() {
            if (influence) {
                vm.mainItemsArray.length = 0
                Array.prototype.push.apply(vm.mainItemsArray, _.orderBy(dataQuery.getIntersectedSubItems(mainStructure, intersectionSettings), ['createdAt'], ['desc']))
                vm.organizationsBySubItemId = _.chain(vm.mainItemsArray).groupBy('childId').mapValues((relations) => {
                    return [influence.organizationId]
                }).value()
            }
            contextParentWfids = _.chain(vm.mainItemsArray).map('wfcid').value()
            // vm.pickedItemsByWfid = _.chain(vm.mainItemsArray).map("childContent").keyBy("wfid").value();

            if (vm.showSubItems) {
                if (!vm.aggregateMode) {

                    vm.allSubItemRelations = dataQuery.getRelations({
                        organizationIds: _.chain(vm.mainItemsArray).map('organizationId').uniq().value(),
                        parentType: enums.objectType.productService,
                        parentIds: _.map(vm.mainItemsArray, 'childId'),
                        kind: enums.subItemsKind.childrenByUser,
                    })
                }

                Array.prototype.push.apply(contextParentWfids, _.chain(vm.allSubItemRelations).map('wfcid').value())
                contextParentWfids = _.uniq(contextParentWfids)
            }
        }

        // Used in all scenarios
        function updateLookupObjects() {
            let uniqueOrganizations

            vm.influences = _.orderBy(vm.influences.filter(x => x.channelId === networkId), ['organizationId', 'title'])
            vm.distinctInfluenceStructures = _.chain(vm.influences)
            // .filter({ objectType: enums.objectType.structure })
                .uniqBy('objectId')
                .map('childContent')
                .filter((item) => {
                    return item
                })
                .sortBy('title')
                .value()

            // TODO: Delete influences for production sites that gets unpicked from the structure.
            vm.influencesByContextParentWfid = _.chain(vm.influences).groupBy('contextParentWfids').mapValues((influences) => {
                return _.uniqWith(influences, (inf1, inf2) => {
                    return inf1.objectId === inf2.objectId && inf1.organizationId === inf2.organizationId
                })
            }).value()

            uniqueOrganizations = _.chain(vm.influences).uniqBy('organizationId').map('organization').value()
            vm.organizationsById = _.keyBy(uniqueOrganizations, 'id')
            vm.organizationIds = _.map(uniqueOrganizations, 'id')

            if (vm.showSubItems) {
                vm.subItemsByItemWfid = _.chain(vm.allSubItemRelations).groupBy('wffid').value()
            }
        }

        function influenceStatusFilterOptionsSource(sourceArray) {
            const influenceStatuses = {
                // "influences_withDueDate": $translate.instant("modules.valueChain.dueDate"),
                influences_fulfilled: {
                    title: $translate.instant('Fulfills'),
                    icon: 'fas fa-check fulfilled',
                    iconColor: '#48C72B',
                },
                influences_notFulfilled: {
                    title: $translate.instant('NotFulfilled'),
                    icon: 'fa fa-times',
                    iconColor: '#DF4A37',
                },
                influences_progress: {
                    title: $translate.instant('modules.valueChain.partiallyFulfilled'),
                    icon: 'fa fa-minus-circle hasProgress',
                    iconColor: '#DBAE0A',
                },
                influences_overdue: {
                    title: $translate.instant('modules.valueChain.requireAction'),
                    icon: 'fa fa-warning overdue',
                    iconColor: '#F97732',
                },
                influences_withException: {
                    title: $translate.instant('Exception'),
                    icon: 'fa fa-exclamation specialRequirements',
                    iconColor: '#ADA41A',
                },
                influences_assessmentNeeded: {
                    title: $translate.instant('fulfillmentStates.assessmentNeeded'),
                    icon: 'fa fa-question-circle assessmentNeeded',
                    iconColor: '#707980',
                },
                influences_dataExpired: {
                    title: $translate.instant('fulfillmentStates.expired'),
                    icon: '',
                    iconColor: '',
                },
                influences_certificateExpired: {
                    title: $translate.instant('fulfillmentStates.certificateExpired'),
                    icon: '',
                    iconColor: '',
                },
                influences_signed: {
                    title: $translate.instant('modules.notifications.eventSentences.influenceSigned'),
                    icon: 'fa fa-pencil allSigned',
                    iconColor: '#3498db',
                },
            }

            for (var key in influenceStatuses) {
                influenceStatuses[key] = {
                    ...influenceStatuses[key],
                    items: [],
                    count: 0,
                    id: 'influence-status-' + key,
                    type: 'influence-status',
                }
            }

            vm.influences.forEach((influence) => {
                let
                    progress
					
                let overdue

                if (influence.isAssessmentNeeded) {
                    influenceStatuses['influences_assessmentNeeded'].items.push(influence)
                    return
                }

                if (influence.isReportedDataExpired) {
                    influenceStatuses['influences_dataExpired'].items.push(influence)
                }

                if (influence.isReportedCertificateExpired) {
                    influenceStatuses['influences_certificateExpired'].items.push(influence)
                }

                if (influence.isSigned) {
                    influenceStatuses['influences_signed'].items.push(influence)
                }

                if (influence.containsSpecialRequirements) {
                    influenceStatuses['influences_withException'].items.push(influence)
                }

                if (influence.fulfilled) {
                    influenceStatuses['influences_fulfilled'].items.push(influence)
                }
                else {
                    progress = !influence.fulfilled && influence.fulfillmentProgress < influence.fulfillmentProgressTotal
                    overdue = !influence.fulfilled && influence.isOverdue

                    influenceStatuses['influences_notFulfilled'].items.push(influence)

                    if (progress) {
                        influenceStatuses['influences_progress'].items.push(influence)
                    }
                    if (overdue) {
                        influenceStatuses['influences_overdue'].items.push(influence)
                    }
                }
            })

            for (var key in influenceStatuses) {
                const contextParentWfids = _.chain(influenceStatuses[key].items).uniqBy('contextParentWfids').map('contextParentWfids').value()
                const items = sourceArray.filter(x => contextParentWfids.includes(x.wfcid))

                influenceStatuses[key] = {
                    ...influenceStatuses[key],
                    items,
                    count: items.length,
                    id: 'influence-status-' + key,
                    type: 'influence-status',
                }
            }

            return _.map(influenceStatuses)
        }

        function updateCategorizationLookup() {
            let
                parentDataRelations
				
            let parentContentWfids
				
            let contents

            parentDataRelations = wfObject.filter({ where: {
                type: enums.objectType.dataRelation,
                parentData1: null,
                organizationId: authOrgId,
                wffid: { '!=': '71-13874' }, // Info repo structure for production sites
                wfcid: { in: contextParentWfids },
            } })

            contents = _.chain(parentDataRelations).uniqBy('wffid').map('parentContent').value()

            vm.parentsByItemId = _.chain(parentDataRelations).groupBy('childId').mapValues((dataRelations) => {
                return _.intersectionWith(contents, dataRelations, (content, dataRelation) => {
                    return content.wfid === dataRelation.wffid
                })
            }).value()
        }

        // Used in all scenarios
        function loadMainContent() {
            return $q((resolve) => {
                if (influence) {
                    resolve() // In scenario A and B the items are already loaded trough an influence
                }
                else {
                    // In scenario C the items need to be loaded from server
                    apiProxy('suppliers.getAggregatedSubItems', { // The items returned will not be injected in the JSData cache
                        structureId: mainStructureId,
                        networkId,
                        kind: enums.subItemsKind.childrenByUser,
                        wrapInRelations: true,
                        putChildContentOnEveryRelation: true,
                        // wrapInVirtualRelations: true, // Instead of returning the real dataRelations we get virtualDataRelations
                        // distinct: true // Get unique items (if multiple organizations have added the same items)
                    }).then((dataRelations) => {
                        vm.organizationsBySubItemId = _.chain(dataRelations).groupBy('childId').mapValues((relations) => {
                            return _.map(relations, 'organizationId')
                        }).value()

                        vm.mainItemsArray.length = 0
                        Array.prototype.push.apply(vm.mainItemsArray, _.uniqBy(dataRelations, 'childId'))

                        _.each(vm.mainItemsArray, (virtualDataRelation) => {
                            virtualDataRelation.childContent.getMainTextual = mockGetMainTextual
                            virtualDataRelation.isRelationalType = mockIsRelationalType
                            virtualDataRelation.childContent.isAnswerType = isAnswerType
                            virtualDataRelation.childContent.getEncodedWfid = getEncodedWfid
                        })

                        const parents = _.chain(dataRelations).filter((dr) => {
                            return dr.childContent.parents
                        }).map('childContent.parents').flatten().value()

                        wfObject.inject(parents)

                        if (vm.openedViaRoute) {
                            // If opened via route then mainStructure needs to be loaded
                            dataOps.getObject({
                                objectType: enums.objectType.structure,
                                objectId: mainStructureId,
                            }).then((res) => {
                                vm.mainStructure = mainStructure = res
                                setupSubItems()

                                resolve()
                            })
                        }
                        else {
                            // Otherwise it's opened via directive that requires the mainStructure to be passed
                            resolve()
                        }

                        function mockGetMainTextual() {
                            return this.name
                        }

                        function mockIsRelationalType() {
                            return true
                        }

                        function isAnswerType() {
                            return false
                        }

                        function getEncodedWfid() {
                            return btoa(btoa(this.wfid))
                        }

                        function setupSubItems() {
                            const subItemsByWfid = {}; const subItemsWithMissingChildContent = []
                            vm.showSubItems = _.get(mainStructure, 'conditions.objectTypes[0]') === enums.objectType.productService

                            if (vm.showSubItems) {
                                vm.allSubItemRelations = []

                                _.each(vm.mainItemsArray, (virtualDataRelation) => {
                                    if (vm.showSubItems && virtualDataRelation.childContent.childs) {
                                        _.each(virtualDataRelation.childContent.childs, (dataRelation) => {
                                            if (dataRelation.childContent) subItemsByWfid[dataRelation.wfcid] = dataRelation.childContent
                                        })
                                        Array.prototype.push.apply(vm.allSubItemRelations, virtualDataRelation.childContent.childs)
                                    }
                                })

                                _.each(vm.allSubItemRelations, (dataRelation) => {
                                    dataRelation.childContent = subItemsByWfid[dataRelation.wfcid]
                                    dataRelation.childContent.getMainTextual = mockGetMainTextual
                                    dataRelation.isRelationalType = mockIsRelationalType
                                    dataRelation.childContent.isAnswerType = isAnswerType
                                    dataRelation.childContent.getEncodedWfid = getEncodedWfid
                                })
                            }

                            if (vm.showSubItems && vm.allSubItemRelations.length) {
                                if (!contextParentWfids) contextParentWfids = []
                                Array.prototype.push.apply(contextParentWfids, _.chain(vm.allSubItemRelations).map('wfcid').value())
                                contextParentWfids = _.uniq(contextParentWfids)
                            }
                        }
                    })
                }
            })
        }

        function maybeLoadValueChainCategorizations() {
            return $q((resolve) => {
                if (!authenticatedOrgIsRecipient) {
                    dataOps.getSubItemsOfAll(contextParentWfids, enums.subItemsKind.parentsByUser).then(() => {
                        updateCategorizationLookup()
                        resolve()
                    })
                }
                else {
                    resolve()
                }
            })
        }

        // Used in scenario A.
        function openPicker() {
            let pickerSettings

            pickerSettings = _.defaultsDeep(mainStructure.conditions.pickerSettings, {
                title: targetContextParentWording ? $translate.instant('Add') + ' ' + getTextFromProperty(targetContextParentWording.plural).toLowerCase() + ' ' + $translate.instant('To').toLowerCase() : undefined,
                objectTypes: mainStructure.conditions.objectTypes,
                relationTarget: { item: mainStructure, kind: enums.subItemsKind.childrenByUser },
                intersection: intersectionSettings,
                wording: targetContextParentWording,
                simplifyForms: form && form.limitFields ? form.limitFields : undefined,
                formFieldTitlesByProperty: form && form.fieldTitlesByProperty ? form.fieldTitlesByProperty : undefined,
                typeOptionsByProperty: form && form.typeOptionsByProperty ? form.typeOptionsByProperty : undefined,
                requiredFields: form && form.requiredFields ? form.requiredFields : undefined,
            })

            modal.openCreatorAndPicker(pickerSettings).closed(() => {
                // Find out what items were newly picked and create influences for them:
                const allSelectedItems = dataQuery.getIntersectedSubItems(mainStructure, intersectionSettings)
                const newlySelectedItems = _.differenceBy(allSelectedItems, vm.mainItemsArray, 'wfcid')
                // console.log("mainItemsArray", _.map(vm.mainItemsArray, "wfcid"));
                // console.log("allSelectedItems", _.map(allSelectedItems, "wfcid"));
                // console.log("newlySelectedItems", _.map(newlySelectedItems, "wfcid"));
                influence.fulfillmentProgress = allSelectedItems.length
                apiProxy('fulfillment.updateProgress', dataOps.prepareWfObject(influence)).then(() => {
                    createInfluencesForPickedItems(newlySelectedItems, autoCreatedInfluence_objectIds).then((newInfluences) => {
                        updateMainArray()
                        updateLookupObjects()

                        if (vm.filteringVm) vm.filteringVm.updateButtonBars()

                        vm.listCompiler.compile()
                        $timeout()
                    })
                })
            })
        }

        function getTextFromProperty(property) {
            if (property.multilingual) {
                return property.multilingual[culture]
            }
            else if (property.translate) {
                return $translate.instant(property.translate)
            }
            else {
                console.error('Could not getTextFromProperty. Make sure that the property has either multilingual: { en_US: \'English\', sv_SE: \'Svenska\' }, or translate: \'modules.subModule.text\'')
                return ''
            }
        }

        // Used in scenario A.
        function createInfluencesForPickedItems(newlySelectedItems, targetObjectIds, isSubItemsCall) {
            const
                promises = []
				
            const newInfluences = []

            return $q((resolve) => {
                // For every newly selected item one or more influences will be created depending on the number of autoCreatedInfluence_objectIds
                _.forEach(newlySelectedItems, (relation) => {
                    _.forEach(targetObjectIds, (targetObjectId) => {
                        // console.log("Create influence on", relation.wfcid, relation.childContent.name, isSubItemsCall && "(sub item)" || "", {
                        // 	type: enums.objectType.influence,
                        // 	isInternal: false,
                        // 	objectId: targetObjectId,
                        // 	objectType: autoCreatedInfluence_objectType,
                        // 	organizationInputMethod: 1,
                        // 	fulfillmentDueAt: moment().add(3, 'days').format('YYYY-MM-DD'),
                        // 	useViewModelActivationDate: true,
                        // 	activatedAt: moment().format('YYYY-MM-DD'),
                        // 	contextParentWfids: relation.wfcid,

                        // 	// Values set from the original influence
                        // 	channelId: networkId,
                        // 	referralInfluenceId: influence.id, // Must be sent in the request (as safety)
                        // 	organizationId: influence.organizationId,
                        // 	creatorOrganizationId: influence.creatorOrganizationId
                        // });
                        promises.push(dataOps.create({
                            type: enums.objectType.influence,
                            isInternal: false,
                            objectId: targetObjectId,
                            objectType: autoCreatedInfluence_objectType,
                            organizationInputMethod: 1,
                            fulfillmentDueAt: moment().add(3, 'days').format('YYYY-MM-DD'),
                            useViewModelActivationDate: true,
                            activatedAt: moment().format('YYYY-MM-DD'),
                            contextParentWfids: relation.wfcid,

                            // Values set from the original influence
                            channelId: networkId,
                            referralInfluenceId: influence.id, // Must be sent in the request (as safety)
                            organizationId: influence.organizationId,
                            creatorOrganizationId: influence.creatorOrganizationId,
                        }).then((influence) => {
                            newInfluences.push(influence)
                            addInfluenceToReportStructure(influence)
                        }))
                    })

                    // Only do this if isSubItemsCall != true and shouldAutoCreateInfluencesOnSubItems == true
                    if (!isSubItemsCall && shouldAutoCreateInfluencesOnSubItems) {
                        promises.push(createInfluencesForPickedItems(relation.childContent.childs, autoCreatedInfluence_subItemsTargetObjectIds, true))
                    }
                })

                $q.all(promises).then(() => {
                    Array.prototype.push.apply(vm.influences, newInfluences)
                    resolve(newInfluences)
                })
            })

            function addInfluenceToReportStructure(influence) {
                wfObject.inject({
                    type: enums.objectType.virtualDataRelation,
                    wfid: '81-|' + '71-' + reportStructureId + '|' + influence.wfid,
                    parentType: enums.objectType.structure,
                    parentData1: null,
                    childId: influence.id,
                    childType: influence.type,
                    wffid: '71-' + reportStructureId,
                    wfcid: influence.wfid,
                    order: 0,
                })
            }
        }

        // Used in all scenarios
        function loadInfluencesWithContextParents() {
            let
                allInfluences
				
            const promises = []

            return $q((resolve) => {
                if (influence) { // Scenario A & B
                    if (authenticatedOrgIsRecipient) {
                        // Scenario A
                        promises.push(dataOps.getObject({
                            objectType: enums.objectType.structure,
                            objectId: reportStructureId, // Structure that contains Influences to the current company
                        }).then((structureWithInfluences) => {
                            allInfluences = _.map(structureWithInfluences.childs, 'childContent')
                        }))
                    }
                    else {
                        // Scenario B
                        promises.push(dataOps.getObjects({
                            requestSignature_noResultNeeded: ['influenceGrouper', influence.id, organizationId, 'influences'].join('_'),
                            objectType: enums.objectType.influence,
                            wrapInRelations: false,
                            getterConditions: {
                                organizationIds: [organizationId],
                                includeOrganizations: false,
                            },
                            // bypassCache: true
                        }).then(() => {
                            allInfluences = wfObject.filter({ where: { type: enums.objectType.influence, creatorOrganizationId: authOrgId, organizationId, channelId: networkId } })
                        }))
                    }
                }
                else { // Scenario C (aggregate)
                    if (!vm.openedViaRoute) allInfluences = $scope.influences // Passed from valueChainOrganizations.controller.js openInfluenceGrouper function. Are all the influences in the Data Collector (potentially within a category)
                    else {
                        // The loading here is the same as in valueChainOrganizations.controller.js and should be moved to valueChain.service.js
                        promises.push($q((resolve) => {
                            apiProxy('multi.getObject', {
                                objectType: enums.objectType.network,
                                objectId: networkId,
                                childrenLoadDepth: 1,
                                loadParents: false,
                                getterConditions: {
                                    objectTypes: [enums.objectType.organization],
                                },
                            }).then((obj) => {
                                const organizationIds = _.chain(obj.childs).filter({ childType: enums.objectType.organization }).uniqBy('childId').map('childId').value()
                                dataOps.getObjects({
                                    requestSignature_noResultNeeded: ['valueChain', networkId, 'influences'].join('_'),
                                    objectType: enums.objectType.influence,
                                    wrapInRelations: false,
                                    getterConditions: {
                                        organizationIds,
                                        includeOrganizations: true,
                                    },
                                    // bypassCache: true
                                }).then(() => {
                                    allInfluences = _.filter(wfObject.filter({
                                        where: { type: enums.objectType.influence, creatorOrganizationId: authOrgId, organizationId: { in: organizationIds }, channelId: networkId, contextParentWfids: { '!=': undefined } },
                                    }))
                                    resolve()
                                })
                            })
                        }))
                    }
                }

                $q.all(promises).then(() => {
                    vm.influences = _.chain(allInfluences).filter((influence) => {
                        return influence.contextParentWfids && _.includes(contextParentWfids, influence.contextParentWfids)
                    }).value()

                    // Scenario C (aggregate) opened via directive.
                    // Can only happened when its opened from VC Overview which means that the influences is not injected i JSData.
                    if (!influence && !vm.openedViaRoute) {
                        wfObject.inject(vm.influences)
                    }

                    resolve()
                })
            })
        }

        // Used to open a single influence in admin mode.
        // Used in scenario B and C.
        function openInfluenceModal(influence, item) {
            valueChainService.openInfluence(influence, vm.itemCompilers[item.wfcid])
        }

        // Used in admin mode to open a modal for creating a new influence with the specified item (like ProductionSite) as the contextParent.
        // If multiple organizations have added the same item then first show a picker to choose the organization to create an influence to.
        // Used in scenario B and C.
        function openInfluenceCreator(dataRelation, organization, isSubItem, primaryItem) {
            // var
            // 	uniqueInfluenceOrganizations = _.chain(vm.influences).filter({ contextParentWfids: dataRelation.wfcid }).uniqBy("organizationId").map("organization").value(),
            // 	organization
            // ;

            // if (uniqueInfluenceOrganizations.length == 1) {
            // 	organization = uniqueInfluenceOrganizations[0];
            open()
            // }
            // else {
            // 	var sourceList = [];

            // 	sourceList = _.map(uniqueInfluenceOrganizations, function (org) {
            // 		return {
            // 			data: org,
            // 			wfid: org.wfid
            // 		};
            // 	});

            // 	modal.openCreatorAndPicker({
            // 		title: $translate.instant('modules.valueChain.influence.selectOrgToCreateInfluence.modalTitle'),
            // 		message: $translate.instant('modules.valueChain.influence.selectOrgToCreateInfluence.modalMessage'),
            // 		singlePick: true,
            // 		relationBucket: { preSelected: [], allSelected: [] },
            // 		sourceList: sourceList,
            // 		buttons: [
            // 			{
            // 				label: "OK",
            // 				callback: function ($scope, relationBucketResult) {
            // 					organization = relationBucketResult.allSelected[0];
            // 					open();
            // 					$scope.$close();
            // 				}
            // 			}
            // 		]
            // 	});
            // }

            function open() {
                let orgId

                if (!organization) {
                    orgId = vm.organizationsBySubItemId[dataRelation.childId][0]
                    organization = wfObject.get('101-' + orgId)
                }

                // Execution path:
                // This method > valueChainService.openInfluenceCreator() > modal.openItem() > template57 (via modal.controller.js) > dataOps.createInfluence()
                valueChainService.openInfluenceCreator({
                    organization,
                    networkId,
                    contextParent: dataRelation.childContent,
                    // compilerControl: vm.itemCompilers[org.id],
                }).then((influences) => {
                    vm.influences = vm.influences.concat(influences)
                    updateLookupObjects()
                    if (isSubItem && primaryItem) vm.itemCompilers[primaryItem.wfcid].compile()
                    else vm.itemCompilers[dataRelation.wfcid].compile()
                })
            }
        }

        function deleteInfluence(influence, item, isSubItem, primaryItem) {
            valueChainService.deleteInfluence(influence, vm.organizationsById[influence.organizationId]).then(() => {
                _.remove(vm.influences, influence)
                updateLookupObjects()
                if (isSubItem && primaryItem) vm.itemCompilers[primaryItem.wfcid].compile()
                else vm.itemCompilers[item.wfcid].compile()
            })
        }

        function initializeLocationData() {
            let
                infoWindow
				
            const infoWindowId = 'info-window'

            if (!vm.isGoogleJsLoaded) return

            vm.locations = _.map(vm.mainItemsArray, 'childContent') // TODO: Optimize
            vm.filteredLocations = _.chain(vm.locations).clone().filter((location) => {
                return location.latitude && location.longitude
            }).value()

            $timeout()

            NgMap.getMap({ id: vm.mapId }).then((map) => {
                const clearListeners = google.maps.event.clearListeners
                const startBounds = new google.maps.LatLngBounds()
                const markers = []
                infoWindow =  map.infoWindows[infoWindowId]
                vm.mapCoordinatesTooltip = $translate.instant('LatitudeLongitude')

                vm.onMarkerClick = function (event, location) {
                    // console.log("attachMarkersInfoWindow", map.markers.length);
                    // clearListeners(marker, 'click');
                    // console.log("addListener", marker.infocontent)
                    // marker.addListener('click', function() {
                    // console.log("click", infoWindowOpen, currentOpenMarker === marker)
                    if (vm.selectedLocation === location) {
                        vm.selectedLocation = undefined
                        map.hideInfoWindow(infoWindowId)
                    }
                    else {
                        vm.selectedLocation = location
                        location.formattedCoordinates = _.round(location.latitude, 7) + ', ' + _.round(location.longitude, 7)
                        map.showInfoWindow(infoWindowId, vm.mapId + '|' + location.wfid)
                    }

                    // if (infoWindow) {
                    // 	infoWindow.close();
                    // 	infoWindowOpen = false;
                    // }

                    // if (infoWindowOpen && currentOpenLocation === location) {
                    // }
                    // else {
                    // 	infoWindow = new google.maps.InfoWindow({
                    // 		content: '<div class=\'map-infoWindow\'><b>' + $sanitize(location.name) + (location.gln ? '</b><div class=\'subHeaderText\'>GLN: ' + $sanitize(location.gln) + '<br /><span title="Latitude, Longitude"><i class="ionicons ion-pinpoint ml5"></i>' + $sanitize(_.round(location.latitude, 7) + ", " + _.round(location.longitude, 7)) + '</span></div>' : '') + $sanitize(location.formattedAddress) + '</div>',
                    // 		position: new google.maps.LatLng(location.latitude, location.longitude),
                    // 		pixelOffset: new google.maps.Size(-1, -40),
                    // 	})
                    // 	// infoWindow.setContent();
                    // 	// infoWindow.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
                    // 	infoWindow.open(map);
                    // 	infoWindowOpen = true;
                    // }
                    // vm.currentOpenLocation = location;
                    // });
                }

                google.maps.event.addListener(infoWindow, 'closeclick', () => {
                    vm.selectedLocation = undefined
                })

                _.forEach(map.markers, (marker) => {
                    startBounds.extend(marker.position)
                    markers.push(marker)
                    // attachMarkerInfoWindow(marker);
                    map.fitBounds(startBounds)
                })

                $scope.$watchCollection('vm.filteredItems', (influences) => {
                    let
                        // organizationIds = _.map(vm.searchResultItems, "id"),
                        bounds

                    if (!vm.isGoogleJsLoaded) return

                    bounds = new google.maps.LatLngBounds()

                    // console.log(markers);
                    infoWindow.close()

                    vm.filteredLocations.length = 0
                    _.assign(vm.filteredLocations, _.map(vm.filteredItems, 'childContent')) // TODO: Optimize
                    vm.filteredLocations = _.chain(vm.filteredLocations).filter((location) => {
                        return location.latitude && location.longitude
                    }).value()

                    _.each(vm.filteredLocations, (location) => {
                        bounds.extend(new google.maps.LatLng(location.latitude, location.longitude))
                    })

                    $timeout()

                    setTimeout(() => {
                        map.fitBounds(vm.filteredLocations.length ? bounds : startBounds)
                        map.hideInfoWindow(infoWindowId)
                        vm.selectedLocation = undefined

                        if (map.zoom > 16) {
                            map.setZoom(16)
                        }
                    }, 0)

                    // setTimeout(function () {
                    // 	_.forEach(map.markers, function (marker) {
                    // 		attachMarkerInfoWindow(marker);
                    // 	});
                    // }, 1000);
                    // if (vm.filteredLocations.length === 1) {
                    // }
                    // console.log(map)
                })
            })
        }

        function changeCategories(item) {
            valueChainService.openOrganizationCategoriesPicker(item, networkId).closed(() => {
                updateCategorizationLookup()

                if (vm.filteringVm) {
                    vm.filteringVm.updateButtonBars()
                }
                vm.itemCompilers[item.wfid].compile()
            })
        }
    }
})()
