export default {
    Ok: 'OK',
    Link_Description: '',
    Menu_CollaborationSystems: 'Collaboration Systems',
    MarkAsFulfilled: 'Mark as fulfilled',
    Website: 'Website',
    PhoneNumber: 'Phone number',
    Menu_StandardsFilters: 'Standards',
    Menu_Publish: 'Publish',
    NotActivated: 'Not activated',
    Menu_Home: 'Home',
    Menu_Apps: 'Apps',
    Activated: 'Activated',
    Not_Activated: 'Not activated',
    MenuItems: 'Menu items',
    Fulfillment_Description: '',
    Answer_Verb_Short: 'Answer',
    Organization: 'Organization',
    Menu_Administrate: 'Administration',
    Addons: 'Addons',
    EndpointPermissions: 'Endpoint permissions',
    MailTemplates: 'Email templates',
    CanNotBeVerified: 'Can not be verified',
    NotRelevant: 'Not relevant',
    Delete: 'Delete',
    InternalComment: 'Internal comment',
    Method: 'Method',
    Menu_InternalReportingRoom: 'Internal Reporting Room',
    Cancel: 'Cancel',
    MeasureAnswer: 'Measured data',
    Change: 'Change',
    Create: 'Create',
    CreatedAt: 'Created at',
    WorkTasks: 'Work tasks',
    ContactUs: 'Contact us',
    ContactPerson: 'Contact person',
    ObjectType: 'Object type',
    Invite: 'Invite',
    Images: 'Images',
    Object: 'Object',
    ObjectID: 'Object ID',
    LogOut: 'Log out',
    Verification: 'Verification',
    Update: 'Update',
    Upload: 'Upload',
    Influence_Noun: 'Influence',
    Organizations: 'Organizations',
    Statements: 'Comments',
    Generic: 'Generic',
    OrgNumber: 'Organization number',
    OrgNumber_short: 'Org. number',
    Calculating: 'Calculating',
    Menu_Filters: 'Public reporting',
    GreatWork: 'Great work',
    Verified: 'Verified',
    NotFound: 'Not found',
    InternalComment_Description: '',
    SignOut: 'Sign out',
    Activity_Description: '',
    Select_Ellipsis: 'Select...',
    Measure_Description: '',
    PrinciplesOfISO26k: 'Principles of ISO 26000',
    Remove: 'Remove',
    NotFulfilled: 'Not fulfilled',
    FilterContent_Noun_IA: 'A filter container',
    EndpointPermission: 'Endpoint permission',
    Network: 'Network',
    Networks: 'Networks',
    Network_IA: 'A network',
    Network_DA: 'The network',
    Search: 'Search',
    Select: 'Select',
    Selected_Plural: 'Selected',
    Public: 'Public',
    Menu_DataFilters: 'Data types',
    SignIn: 'Sign in',
    Question_IA: 'A question',
    Question_DA: 'The question',
    ClosedNetwork: 'Closed network',
    Position: 'Position',
    SearchOrganizations: 'Search organizations',
    Menu_PluginSolutions: 'Plugin Solutions',
    IUnderstand: 'I understand',
    New_Neutrum: 'New',
    Conditions: 'Conditions',
    Filter_Noun_IA: 'A filter',
    NextPage: 'Next page',
    PreviousPage: 'Previous page',
    RequestHistory: 'Sent information requests',
    SelectAccessibleOrganization: 'Select organization',
    Menu_Colleagues: 'Colleagues',
    Content: 'Content',
    Comment: 'Comment',
    Company: 'Company',
    Country: 'Country',
    Countries: 'Countries',
    Country_IA: 'A country',
    Country_DA: 'The country',
    NoCountry: 'No country',
    OrganizationCountries: 'Organization countries',
    MailTemplate: 'Email template',
    Menu_Activities: 'Activities',
    Menu_Involve: 'Involve',
    InfluenceVerb: 'Influence',
    Activity: 'Activity',
    Colleagues: 'Colleagues',
    Colleague: 'Colleague',
    SaveChanges: 'Save changes',
    ReportedBy: 'Reported by',
    Document: 'Document',
    Language: 'Language',
    Claimed: 'Claimed',
    Created_PastTense: 'Created',
    MeasureAnswers: 'Measured data',
    Fulfills: 'Fulfills',
    Document_Description: '',
    Menu_Structures: 'Data Structures',
    ChangeFilter: 'Change filter',
    Statement: 'Comment',
    Fulfillments: 'Fulfillments',
    FilterContent_Noun: 'Filter container',
    NotSpecified: 'Not specified',
    Current: 'Current',
    Requirements: 'Requirements',
    Internal: 'Internal',
    By: 'By',
    No: 'No',
    On: 'On',
    Or: 'Or',
    To: 'To',
    Permissions: 'Permissions',
    NotVerified: 'Not verified',
    NotDefined: 'Not defined',
    Comment_Description: '',
    ClosedNetworks: 'Closed networks',
    AccountSettings: 'Account settings',
    MakeVisible: 'Make visible',
    Unpublished: 'Unpublished',
    OrganizationInfo: 'Organization information',
    Objects: 'Objects',
    Fulfilled_Verb: 'Fulfilled',
    Assign: 'Assign',
    Add: 'Add',
    All: 'All',
    AllUsers: 'All users',
    New: 'New',
    Yes: 'Yes',
    InternalComments: 'Internal comments',
    Email: 'Email',
    Mail: 'Post',
    Added: 'Added',
    Addon: 'Addon',
    Aside: 'Aside',
    Close: 'Close',
    Processing: 'Processing',
    Image: 'Image',
    Links: 'Links',
    Other: 'Other',
    LogIn: 'Log in',
    Users: 'Users',
    Untag: 'Untag',
    Value: 'Value',
    Title: 'Title',
    Pages: 'Pages',
    Phone: 'Phone',
    Calculate: 'Calculate',
    Notification: 'Notification',
    Change_Verb: 'Change',
    Username: 'User name',
    Lastname: 'Last name',
    Published: 'Published',
    OrgInputMethod: 'Input method',
    RestrictedAccess: 'Restricted acccess',
    ChangeOrgInputMethod: 'Change input method',
    Influences: 'Influences',
    Influenced: 'Influenced',
    Question: 'Question',
    AnswerHistory: 'Answer history',
    Answers: 'Answers',
    Link_Verb: 'Link',
    Link_Noun: 'Link',
    Menu_Impact: 'Influence',
    Notifications: 'Notifications',
    Fulfillment: 'Fulfillment',
    CreatedBy: 'Created by',
    TagCollections: 'Tag collections',
    Menu_GAP: 'GAP Analysis',
    MenuItem: 'Menu item',
    Menu_Layers: 'Layers',
    OrgName: 'Organization name',
    Menu_Manage: 'Manage',
    Locations: 'Locations',
    Firstname: 'First name',
    Questions: 'Questions',
    Menu_InternalFilters: 'Internal filters',
    Activities: 'Activities',
    Search_Ellipsis: 'Search...',
    Visible: 'Visible',
    LoadMore: 'Load more',
    Menu_InternalStructures: 'Create structures',
    Menu_Addons: 'Add-ons',
    QuestionAnswer: 'Question answer',
    QuestionAnswers: 'Question answers',
    Fulfilled_Plural: 'Fulfilled',
    Menu_Documents: 'Documents',
    Fulfilled: 'Fulfilled',
    Menu_PrivateNetwork: 'Private Data Sharing Network',
    Link_IA: 'A link',
    Link_DA: 'The link',
    Sent_Plural: 'Sent',
    Menu_SupportAgreement: 'Support Agreement',
    Menu_Relations: 'Relations',
    Expand: 'Expand',
    Collapse: 'Collapse',
    Menu_AccountSettings: 'Account settings',
    MenuPermissions: 'Menu permissions',
    Measure: 'Measure',
    Measures: 'Measures',
    Measure_IA: 'A measure',
    Measure_DA: 'The measure',
    Menu_Measures: 'Measures',
    RelativeMeasure: 'Relative measure',
    RelativeMeasures: 'Relative measures',
    RelativeMeasure_IA: 'A relative measure',
    RelativeMeasure_DA: 'The relative measure',
    Question_Description: '',
    Activity_IA: 'An activity',
    Activity_DA: 'The activity',
    Year: 'Year',
    Years: 'Years',
    Unpublished_Plural: 'Unpublished',
    MultipleTypes: 'Multiple types',
    Requirement: 'Requirement',
    Request: 'Request',
    Related: 'Related',
    Document_IA: 'A document',
    Document_DA: 'The document',
    Menu_Status: 'Questions',
    Menu_Plugin: 'Plugin',
    Tag_Noun: 'Tag',
    Tag_Verb: 'Tag',
    Menu_OrgSettings: 'Organization settings',
    Selected: 'Selected',
    Verifications: 'Verifications',
    Description: 'Description',
    Menu_ISO26000Definitions: 'ISO 26000 Definitions',
    Answer_Noun: 'Answer',
    Answer_Verb: 'Answer',
    VisibilityTags: 'Visibility tags',
    InfluenceHistory: 'Influence History',
    Filters: 'Filters',
    ShowAllActiveFilters: 'Show all active filters',
    ShowLessActiveFilters: 'Show less active filters',
    WorldfavorPublic: 'Worldfavor Public',
    FilterContents_Noun: 'Filter containers',
    Menu_Report: 'Report',
    LearnMore: 'Learn more',
    Documents: 'Documents',
    MakeInvisible: 'Make invisible',
    WorkTask: 'Work task',
    VerifiedBy: 'Verified by',
    Permission: 'Permission',
    Location: 'Location',
    MenuPermission: 'Menu permission',
    Filter_Noun: 'Filter',
    Edit: 'Edit',
    File: 'File',
    From: 'From',
    Done: 'Done',
    Misc: 'Miscellaneous',
    Name: 'Name',
    NormalMode: 'Normal Mode',
    ExpandedMode: 'Expanded Mode',
    Next: 'Next',
    Open: 'Open',
    OpenInNewWindow: 'Open in full page',
    Link: 'Link',
    Logo: 'Logo',
    User: 'User',
    Unit: 'Unit',
    Hide: 'Hide',
    Help: 'Help',
    Type: 'Type',
    Text: 'Text',
    Rule: 'Rule',
    Role: 'Role',
    Sent: 'Sent',
    Send: 'Send',
    Save: 'Save',
    SaveAndContinue: 'Save and continue',
    Show: 'Show',
    Page: 'Page',
    Period: 'Period',
    TagCollection: 'Tag collection',
    Subject: 'Subject',
    ViewMode: 'View mode',
    VisibilityTag: 'Visibility tag',
    ConfirmDeleteMessage: 'Do you want to delete this?',
    Report: 'Report',
    CreateAndClose: 'Create and close',
    Unseen: 'Unseen',
    MarkAllSeen: 'Mark all seen',
    Structures: 'Structures',
    Structure: 'Structure',
    Found: 'Found',
    Area: 'Area',
    SubSupplier: 'Sub-supplier',
    SubSuppliers: 'Sub-suppliers',
    Supplier: 'Supplier',
    Suppliers: 'Suppliers',
    Supplier_IA: 'A supplier',
    Supplier_DA: 'The supplier',
    RelatedContent: 'Related content',
    Sender: 'Sender',
    GetContent: 'Get content',
    EnterWebAddress: 'Enter Web Address',
    Address: 'Address',
    EditPermissions: 'Edit permissions',
    Your: 'Your',
    PublicInformation: 'Public Information',
    date: 'Date',
    Date: 'Date',
    Dates: 'Dates',
    Date_IA: 'A date',
    Date_DA: 'The date',
    EndDate: 'End date',
    Time: 'Time',
    Switch_Label_On: 'On',
    Switch_Label_Off: 'Off',
    ChooseExisting: 'Choose existing',
    organizationEmptySearchResult: 'No organizations match the current search',
    noOrganizationEmptyState: {
        header: 'Nothing here yet',
        description: 'It seems like you don\'t have user access to any organizational account on Worldfavor. Contact our support at support@worldfavor.com if you should have received access to an organizational account and suspect something is wrong.',
    },
    buttons: {
        add: 'Add file',
        upload: 'Upload',
    },
    'modules.upload': {
        descriptionSinglefile: 'Select a file to upload',
        field: {
            preview: 'Preview',
            filename: 'File name',
            progress: 'Upload progress',
        },
        previewNotAvailable: 'Not available',
        formLabel: 'File',
        formLabelImage: 'Uploaded image',
        formLabelFile: 'Uploaded file',
        note: 'Note that the current file will be removed if you replace it',
        squareImageInfo: 'Please upload a <strong>square</strong> image for best results',
        discardButton: 'Discard',
        dropBoxes: {
            initial: {
                label: 'Choose file',
                headerText: 'Drag & Drop',
                descriptionText: 'or',
                browseButtonText: 'Browse files',
                changeButtonText: 'Replace file',
            },
            imageUpload: {
                label: 'Choose image',
                headerText: 'Drag & Drop an image',
                descriptionText: 'or',
                browseButtonText: 'Browse',
                changeButtonText: 'Choose image',
            },
            acceptUpload: {
                label: 'Choose file',
                headerText: 'Drop the file here',
                descriptionText: '',
                browseButtonText: '',
            },
            rejectUpload: {
                label: 'Choose file',
                headerText: 'Unsupported file format',
                descriptionText: 'Please use a common file format for documents and images',
                browseButtonText: '',
            },
        },
    },
    AddInformation: 'Add information',
    ExistingInformation: 'Existing information',
    Existing: 'Existing',
    DeleteTag: 'Remove from here',
    'modules.report': {
        invite: {
            inviteHeader: 'Is sustainability and compliance not your area?',
            inviteDescription: 'Invite the <strong>responsible colleague</strong>, it’s free!',
            inviteButtonText: 'Invite now',
        },
        reportPageInfo: 'Requests for your organization to report on',
        publicRequestFrom: {
            label: 'Public Requests From:',
            tooltipText: 'The organization below has requested you and/or your colleagues to report important information to their data collector.',
        },
        requestFrom: {
            label: 'Requests From:',
            tooltipText: 'The organization below has requested you and/or your colleagues to report important information to their data collector.',
        },
        contactPersons: {
            label: 'Contact Persons:',
            tooltipText: 'Contact persons related to the specific organization. Please notice that a colleague in your organization might have asked you to contribute with requested information without adding you as a contact person associated with a specific organization.',
        },
        syncingReportItems: 'Updating view',
        emptyStateHeader: 'You currently have no information requests',
        emptyStateDescription: 'Once somebody sends you a request you will see it here and report the information directly to the receiver.\nYou will be notified when this happens.',
        emptyStateButton: 'Back to Dashboard',
        informationRequestsFrom: 'Information requests from: {{orgname}}',
        addRemoveContactPerson: 'Add or change contact persons',
        showPreviousAnswers: 'Show previous answers',
        hidePreviousAnswers: 'Hide previous answers',
        verificationDocuments: {
            previouslySignedDocumentsHeader: 'Previously signed documents',
            lastSigned: 'Last signed',
            previouslySigned: 'Previously signed',
            emptyStateHeader: 'You have not verified your answers yet',
            emptyStateDescription: 'When you have answered everything to the left, you should sign your answers. Once you have signed, a document will be created and placed here.',
            viewModeEmptyState: {
                header: 'Not signed',
            },
        },
        publicProfileOptions: {
            noUrlTooltipText: 'No address set for your profile',
            publishProfileButtonLabel: 'Publish',
            previewProfileButtonLabel: 'Preview',
            profileSettings: 'Profile settings',
            lastPublishedAt: 'Last published at:',
            noAddressMessage: 'You haven\'t set the address for your public profile.',
            clickToOpenSettingsLabel: 'Click here to set it up.',
            publishConfirmationModal: {
                title: 'Publish changes?',
                description: 'You are about to publish the information to the following address:',
                publishButton: 'Publish',
            },
            successMessageModal: {
                title: 'Profile published successfully',
                description: '',
            },
            profileSettingsModal: {
                profileUrl: 'Profile URL',
                profileUrlValidationMessage: 'Please type the URL for your profile',
                coverImg: 'Cover image',
            },
            errorModal: {
                title: 'Sorry, something went wrong',
                message: 'An unexpected error has occured.\nIf an error persists, please contact our customer support.',
            },
            previewSuccessModal: {
                title: 'Preview profile',
                description: 'The preview was generated and can be opened.',
                openPreview: 'Open preview',
            },
        },
        messageAfterSigning: {
            headerMessage: 'Well done!',
            completedMessage: 'You have completed everything in this request. If additional information is needed here you will be notified via email.',
            unansweredRequests: {
                preSentence: 'You still have ',
                unansweredRequestsSingular: ' {{count}} uncompleted request',
                unansweredRequestsPlural: ' {{count}} uncompleted requests',
            },
            dashboardButtonText: 'Go back to dashboard',
            closeButtonText: 'Close',
            justCloseButtonText: 'or continue to report here',
        },
        influenceGrouper: {
            productionSites: {
                emptyStateHeader: 'No production sites added yet',
                emptyStateDescription: 'Add production sites by clicking the button above.',
            },
            productServices: {
                emptyStateHeader: 'No products or services added yet',
                emptyStateDescription: 'Add products or services by clicking the button above.',
            },
            organizations: {
                emptyStateHeader: 'No organizations added yet',
                emptyStateDescription: 'Add organizations by clicking the button above.',
            },
            suppliers: {
                emptyStateHeader: 'No suppliers added yet',
                emptyStateDescription: 'Add suppliers by clicking the button above.',
            },
            holdings: {
                emptyStateHeader: 'No holdings added yet',
                emptyStateDescription: 'Add holdings by clicking the button above.',
            },
        },
    },
    'modules.internalValueChain': {
        collaborationTool: 'Collaboration tool',
    },
    'modules.valueChain': {
        publicContactPerson: 'Public contact',
        publicContactNoAddContact: 'As this organization has a public contact person you cannot change contact persons',
        publicContactWfAddingInfo: 'Change public contact person (this can only be done by Worldfavor and will protect access to this organization)',
        onHoverTextForAggregatedData: 'Open detailed results',
        onHoverTextForDownloadAggregatedData: 'Export reported data',
        suppliersThatRequiresAction: 'Require action',
        noSuppliersRequiresAction: 'No action required.',
        failedMails: 'Failed outbound emails',
        organizationCount: 'Number of organizations',
        basedOnFiltering: 'Based on filtering',
        SubSupplierTo: 'Sub supplier to:',
        batchObjectChange: {
            buttonLabel: 'Bulk change objects',
            allPackagesButtonLabel: 'Bulk change in all requirement packages',
            buttonCaption: 'Settings and requirements',
            modalHeader: 'Bulk change objects',
            savingSettings: 'Saving settings...',
            savingRequirements: 'Saving requirements...',
            successSaving: 'Changes saved successfully. You need to reload the page to see the changes',
            errorSaving: 'Could not save changes, please reload the page and try again.',
            questionSettings: 'Questions settings',
            measureSettings: 'Measures settings',
            questionRequirements: 'Questions requirements',
            measureRequirements: 'Measures requirements',
            taskStructureRequirements: '\'Add information\' requirements',
            measureFrequencyMismatch: {
                title: 'Different answer frequencies',
                message: 'The answer frequency setting must be the same on all measures before the requirement can be changed on them.',
            },
        },
        batchFulfillmentChange: {
            buttonLabel: 'Bulk set fulfillment',
            modalHeader: 'Bulk set fulfillment for manuel assessment',
            progressModalHeader: 'Bulk set fulfillment',
            savingFulfillments: 'Saving fulfillments...',
            successSaving: 'Fulfillment saved successfully.',
            errorSaving: 'Could not save changes, please reload the page and try again.',
        },
        actions: {
            changeContactPersonOnOrg: 'Change contact person',
            changePublicContactPersonOnOrg: 'Change public contact person',
            changeCategoriesOnOrg: 'Change categories',
            createInfluenceOnOrg: 'Add requirement package',
            createInternalInfluenceOnOrg: 'Add internal requirement package',
            mailHistory: 'Email history',
            sendMail: 'Send email',
            reminderMail: 'Send reminder email',
            createAnalyzeJobOnOrg: 'Add analysis',
        },
        sendMail: {
            noRequirementUserModal: {
                title: 'Something is missing',
                message: 'This organization is missing a requirement package and/or contact person.',
                description: 'You need to add at least one contact person and one requirement package to send the invite',
            },
            noUserSelectedModal: {
                message: 'Please select a user to continue',
            },
            noActiveInfluenceModal: {
                headerText: 'Something is missing',
                title: 'None of the requirement packages are activated yet',
                message: 'You have customized the activation dates to be for a specific date in the future (or have not specified a date).\nSet today\'s date or a past date as the activation date on at least one of the requirement packages if you want to be able to send the email right now.',
            },
            allInfluencesNeedAssessment: {
                title: 'All requirement packages needs assessment',
                message: 'You have to manually assess at least one of the requirement packages if you want to be able to send the email right now.',
            },
        },
        dueDate: 'Due date',
        requireAction: 'Require action',
        partiallyFulfilled: 'Unfulfilled signing',
        packages: 'Package',
        aggregatedView: {
            title: 'Detailed results',
        },
        influenceYears: 'Requirement year',
        influenceDates: 'Requirement dates',
        internalInfluence: {
            sidePanelHeader: 'Internal requirement packages',
        },
        influence: {
            remove: {
                modalTitle: 'Delete request for information',
                modalMessage: '<i class="fa fa-trash-o"></i><div class=\'pt15\'>Do you want to delete the request for information<br /><strong>{{influencename}}</strong> from <strong>{{orgname}}</strong>?</div>',
            },
            emptyStateHeader: 'There are no requests for information',
            message_noStructureSelected: 'You have to select a requirement package',
            changesRequiresSigning: 'You have made changes that needs to be signed',
            signingCertify: 'I hereby certify that the information provided is true',
            modalHeader: 'Add requirement for <br /><strong>{{orgname}}</strong>',
            fulfillmentLabel: 'Requirement status',
            dueDateLabel: 'Due date',
            activatedAtLabel: 'Activates',
            activationDate: 'Activation date',
            creatorUserLabel: 'Owner',
            targetUserLabel: 'Recipient',
            mailSendoutState: 'Mail sendout state',
            mailSendoutStateDescription: 'Select if invitation or reminder emails should be sent',
            noTargetUser: 'No specific recipient user',
            hasTargetUser: 'Has recipient user',
            assignedQuestionsLabel: 'Contains questions assigned to you',
            selectRequirementButtonCaption: 'Select requirement package',
            submitButtonCaption: 'Save requirement',
            requirement: 'Requirement',
            requirementPreview: {
                modalHeader: 'Requirement for {{orgname}}',
            },
            requirementSelection: {
                modalHeader: 'Requirements',
                emptyStateHeader: 'All available requirement packages have already been added',
            },
            noReqPackagesSelected: {
                title: 'No requirement packages selected',
                description: 'Please make sure to select at least one requirement package.',
            },
            hasSpecialRequirements: 'Has exceptions',
            mailSentCount: 'Number of emails sent',
            isOverdue: 'Is overdue',
            message_header: 'Incomplete information',
            message_questionsLeft: 'All questions must be fulfilled before signing.',
            message_haveToFulfillQuestions: 'You must comply with question number ',
            message_generatingPdf: 'Generating PDF...',
            message_calculatingFulfillment: 'Calculating fulfillment...',
            message_verifying: 'Verifying...',
            message_pdfFailed: 'Something went wrong PDF was created. Try again later.',
            addComment: 'Add a comment',
            addActivity: 'Add an activity',
            uploadFile: 'Upload a file',
            addCountry: 'Add country',
            uploadCertificate: 'Upload a certificate',
            addFinding: 'Add new finding',
            addPerson: 'Add person',
            addOrganization: 'Add organization',
            youHaveToAddAComment: 'You have to add a comment',
            haveNotYetAddedComment: 'You have not yet added a comment.',
            haveToAnswerQuestionBeforeAddingInfo: 'You have to answer the question before adding more information',
            calculateFulfillment: 'Calculate fulfillment',
            selectOrgToCreateInfluence: {
                modalTitle: 'Select organization',
                modalMessage: 'Choose which organization you want to create a requirement package for',
            },
            commentToReportingOrganization: 'Comment to the reporting organization',
            commentFrom: 'Comment from',
            attachedInfoForInfluence: 'Attached information to the reporting organization',
            lastSignedAt: 'Last signed',
            notSignedYet: 'Not signed yet',
            requestFromAtInfo: 'requested this at {{activatedAt}}:',
        },
        contactPersons: {
            modalHeader: 'Contact persons for {{orgname}}',
            createFormHeader: 'Add contact person',
            createButtonCaption: 'Add contact person',
            emptyStateHeader: 'No contact person added',
        },
        suppliers: {
            emptyStateHeader: 'You haven\'t added any partners to this category yet',
            emptyStateDescription: 'Add a new partner by clicking <strong>Add new</strong> above',
        },
        categories: {
            header: 'Categories',
            createNew: 'Create new category',
            modalHeader: 'Categories for {{orgname}}',
            emptyStateHeader: 'You haven\'t created any category',
            emptyStateDescription: 'Click on <b>Create new category</b> above to get started',
            removeFromGroup: 'Remove from group',
            addToGroup: 'Add to group',
            newGroup: 'New group',
            addToNewGroup: 'Add to new group',
            editGroup: 'Edit group',
        },
        subCategories: {
            emptyStateHeader: 'You haven\'t created any sub-categories yet',
            emptyStateDescription: 'Add a new sub-category by clicking <strong>Create new</strong> above',
        },
        mainDemands: {
            emptyStateHeader: 'Create requirement packages',
            emptyStateDescription: 'Here you setup what information you want from external partners, you can categorize them into different topics.',
        },
        demands: {
            description: 'All available for {{categoryTitle}}',
            createNew: 'Create new requirement package',
            emptyStateHeader: 'You haven\'t created any requirement packages',
            emptyStateDescription: 'Click on <b>Create new requirement package</b> above to get started',
            packageFriendlyTitle: 'Title for receiver (optional)',
            standardPackages: {
                addPackage: 'Add sustainability topic',
                pickerHeader: 'Select topics',
            },
            standardInternalPackages: {
                addPackage: 'Add emission factors',
                pickerHeader: 'Select emission factors package',
            },
        },
        standardCategories: {
            addCategory: 'Add standard categories',
            pickerHeader: 'Select categories',
        },
        analyze: {
            labelsFilterBarHeader: 'Analysis labels',
            titlesFilterBarHeader: 'Analysis packages',
            scoreFilterBarHeader: 'Analysis score',
            analysingData: 'Analyzing reported data',
        },
        analyzePackages: {
            description: 'All available for {{categoryTitle}}',
            createNew: 'Create new Analysis package',
            emptyStateHeader: 'You haven\'t created any Analysis packages',
            emptyStateDescription: 'Click on <b>Create new Analysis package</b> above to get started',
            packageFriendlyTitle: 'Title for receiver (optional)',
            standardPackages: {
                addPackage: 'Add sustainability topic analysis',
                pickerHeader: 'Select analysis packages',
            },
        },
        analyzeJob: {
            choosePackage: 'Choose Analysis package',
            creationModalHeader: 'Add analysis for: {{orgname}}',
            remove: {
                modalTitle: 'Delete analysis',
                modalMessage: '<i class="fa fa-trash-o"></i><div class=\'pt15\'>Do you want to delete the analysis<br /><strong>{{analyzejobtitle}}</strong> from <strong>{{orgname}}</strong>?</div>',
            },
        },
        organizationCategories: {
            emptyStateHeader: 'Not yet categorized',
        },
        organizations: {
            createNew: 'Add organization',
            remove: {
                modalHeaderText: 'Remove {{orgname}}',
                modalTitle: 'Are you sure you want to remove the organization?',
                modalMessage: 'The organization will be removed from your Data Collector.\nAll information requests you have added on them will be deleted.\n\nIf you still want to remove the organization from your Data Collector click <strong>Remove</strong> below.',
            },
            alreadyExists: {
                modalTitle: 'The organization already exists',
                modalMessage: 'An organization with this registration number already exists:<span style=\'font-size:14px;\'><br /><br /><b>{{orgname}}</b><br />{{orgnumber}}</span><br /><br />Do you want to add that organization to your Data Collector?',
            },
            multipleAlreadyExists: {
                modalTitle: 'The organization already exists',
                modalMessage: 'Several organizations with this registration number already exist. Choose the organization that you wish to add to your Value Chain.',
            },
            multipleAlreadyExists2: {
                modalTitle: 'The organization already exists',
                modalMessage: 'Several organizations with this registration number already exist. Choose the organization that you wish to add.',
            },
            alreadyAdded: {
                modalTitle: 'The organization is already added',
                modalMessage: 'An organization with this registration number is already in your Data Collector:<span style=\'font-size:14px;\'><br /><br /><b>{{orgname}}</b><br />{{orgnumber}}</span><br />',
            },
            emptyStates: {
                noAddedOrganizations_header: 'You have not added any organizations',
                noAddedOrganizations_description: 'Click on the button <b>Add organization</b> above to get started',
                failedMails_header: 'There are no failed outbound emails',
                noSearchOrFilterResult_header: 'No organizations matched your search or filter',
                noFilterResult_mailHistory_header: 'No emails match your filtering',
                noFilterResult_mailHistory_description: 'Try another filtering option',
            },
        },
        notifications: {
            seeAll: 'See all notifications',
            emptyStateHeader: 'There are no events to show',
            emptyStateActiveFilter_header: 'No events match your filtering',
            emptyStateActiveFilter_description: 'Try another filtering option',
        },
        packageEditor: {
            emptyStateHeader: 'This requirement package is empty',
            emptyStateDescription: 'Add categories, questions and measures with the button above',
            specialSettings: {
                emptyStateHeader: 'Preconfigured requirement package',
                emptyStateDescription: 'This package is preconfigured and its content can not be changed here',
            },
            targetContextParent: 'Intended for',
            targetContextParent_valueChainOrg: {
                label: 'Organizations report for',
                notSet: 'Themselves',
            },
            packageSettings: {
                requirementTexts: {
                    label: 'Individual requirements',
                    show: 'Show requirements',
                    hide: 'Hide requirements',
                },
                reportingUi: {
                    label: 'Reporting UI',
                    useNew: 'Use new',
                    useOld: 'Use old',
                },
                tabs: {
                    label: 'Tabs',
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                },
                reportedDataDeletion: {
                    label: 'Deleting reported data',
                    enabled: 'Allow',
                    disabledForMeasureAnswers: 'Do not allow deletion of measure answers',
                    disabledForQuestionAnswers: 'Do not allow deletion of question answers',
                    disabledForBoth: 'Do not allow deletion of measure answers and question answers',
                },
                itemNumbering: {
                    label: 'Header numbering for reporter',
                    show: 'Show',
                    hide: 'Hide',
                },
                csvExport: {
                    label: 'CSV export for reporter',
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                },
            },
        },
        requirements: {
            EmptyRequirement: 'Empty requirement (disregard item from fulfillment)',
            CurrentRequirementInvalid_reporter: 'Requirement is not valid',
            CurrentRequirementInvalid_admin: 'Selected requirement cannot be used. Please select a different requirement.',
            ManualAssessment: 'Manual assessment',
            AllQuestionsFulfilled: 'All questions must be fulfilled',
            AllQuestionsAnswered: 'All questions must be answered',
            None: 'None',
            AnyAnswer: 'Any answer',
            Unanswered: 'Unanswered',
            MinimumOneObjectWithAttachments: 'At least one added object with attached information',
            MinimumOneObject: 'At least one added object',
            Yes: 'Yes',
            No: 'No',
            YesOrNotRelevant: 'Yes or Not Relevant',
            NoOrNotRelevant: 'No or Not Relevant',
            Yes_OtherwiseCommentIsRequired: 'Yes (Otherwise comment is required)',
            No_OtherwiseCommentIsRequired: 'No (Otherwise comment is required)',
            YesOrNotRelevant_OtherwiseCommentIsRequired: 'Yes or Not Relevant (Otherwise comment is required)',
            NoOrNotRelevant_OtherwiseCommentIsRequired: 'No or Not Relevant (Otherwise comment is required)',
            AnyAnswer_YesRequiresMoreInfo: 'Any answer (if Yes, more information is required)',
            AnyAnswer_NoRequiresMoreInfo: 'Any answer (if No, more information is required',
            AnyAnswer_AccreditedRequiresMoreInfo: 'Any answer (if Accredited, more information is required)',
            AnyAnswer_NotCompliantRequiresMoreInfo: 'Any answer (if Not Compliant, more information is required)',
            AnyAnswer_NoOrPartlyRequiresMoreInfo: 'Any answer (if No or Partly, more information is required)',
            AnyAnswer_YesOrPartlyRequiresMoreInfo: 'Any answer (if Yes or Partly, more information is required)',
            AnyAnswerWithMoreInfo: 'Any answer with more information',
            YesWithMoreInfo: 'Yes, with more information',
            NotRelevantWithMoreInfo: 'Not Relevant, with more information',
            AccreditedWithMoreInfo: 'Accredited, with more information',
            AnyAnswerSecondPreviousPeriod: 'Any answer in second previous period',
            AnyAnswerSecondPreviousPeriodWithMoreInformation: 'Any answer in second previous period, with more information',
            AnyAnswerSecondPreviousPeriodWithMoreInformationExceptIfNA: 'Any answer in second previous period, with more information unless answer is N/A',
            AnyAnswerSecondPreviousPeriodWithMoreInformationIfNA: 'Any answer in second previous period, with more information if answer is N/A',
            AnyAnswerPreviousPeriod: 'Any answer in previous period',
            AnyAnswerPreviousPeriodWithMoreInformation: 'Any answer in previous period, with more information',
            AnyAnswerPreviousPeriodWithMoreInformationExceptIfNA: 'Any answer in previous period, with more information unless answer is N/A',
            AnyAnswerPreviousPeriodWithMoreInformationIfNA: 'Any answer in previous period, with more information if answer is N/A',
            AnyAnswerCurrentPeriod: 'Any answer in current period',
            AnyAnswerCurrentPeriodWithMoreInformation: 'Any answer in current period, with more information',
            AnyAnswerCurrentPeriodWithMoreInformationExceptIfNA: 'Any answer in current period, with more information unless answer is N/A',
            AnyAnswerCurrentPeriodWithMoreInformationIfNA: 'Any answer in current period, with more information if answer is N/A',
            AnyAnswerSpecificPeriodWithMoreInformationExceptIfNA: 'Any answer in period, with more information unless answer is N/A',
            Span: 'Span',
            specificValues: '{{answertypes}}',
            specificValueWithRelatedContent: '{{answertypes}}, with more information',
            preferredValue: '{{answertypes}} (otherwise more information is required)',
            preferredValueWithRelatedContent: 'Any answer (if {{answertypes}}, more information is required)',
            TargetOrganizationCountry: 'Organization country',
            TargetOrganizationInNetworkCategoryID: 'Organization category in the network',
        },
        objectSettings: {
            answerOptions: {
                label: 'Answering options',
                YesNoNotRelevantAndProcessing: 'Yes, No, Not Relevant and Processing',
                YesAndNo: 'Yes and No',
                YesNoAndNotRelevant: 'Yes, No and Not Relevant',
            },
            informationThatCanBeAdded: {
                label: 'Information that can be attached',
                label_onAnswer: 'Information that can be attached on the answer',
                label_onQuestion: 'Information that can be attached on the question',
                label_nested: 'Information that can be attached on each alternative',
                NoInformation: 'No information',
                Comment: 'Comment',
                FileUpload: 'File Upload',
                CertificateUpload: 'Certificate Upload',
                CommentAndFileUpload: 'Comment and File Upload',
                CommentAndCertificates: 'Comment and Certificate Upload',
                Finding: 'Finding',
                FindingCommentAndFileUpload: 'Finding, Comment and File Upload',
            },
            showSigning: {
                label: 'Signing',
                show: 'Must be signed',
                hide: 'No signing',
            },
            measurePeriodSettings: {
                label: 'Answer frequency',
                dropdownAction: 'Choose answer frequency',
            },
            measureSettings: {
                unitSelector: {
                    label: 'Unit selector',
                    selectorDisabled: 'Disabled',
                    selectorEnabled: 'Allow reporting in other units with the same quantity',
                },
                notAvailableCheckbox: {
                    label: 'N/A value reporting',
                    disabled: 'Disabled',
                    enabled: 'Allow reporting of N/A values',
                },
                lockPeriod: {
                    label: 'Lock reporting period',
                    none: 'Disabled',
                },
            },
            pickerSettings: {
                label: 'How to attach',
                notSet: '-',
                useDropdownAndOnlyCreate: 'Use dropdown and only create new',
                useCustomFindingCaptionAndOnlyCreate: 'Use custom button caption for findings and only create',
                emphasizeFindingCreationAndThenDropdown: 'Emphasize Finding creation and attach other types with dropdown',
                gics: 'GICS',
                sourceItem: {
                    label: 'Source list to pick items from',
                    optionInObjectTypesSelector: 'Custom alternatives',
                    'value.none': 'Not set',
                    'value.set': 'Is set',
                    pickerHeader: 'Choose source list to pick items from',
                },
                openerButton: {
                    label: 'Button caption',
                    'value.attach': 'Attach',
                    'value.select': 'Select',
                    'value.add': 'Add',
                },
                singlePick: {
                    label: 'Can pick multiple alternatives or just a single one',
                    'value.false': 'Multiple',
                    'value.true': 'Single',
                },
                firstLevelAsFilter: {
                    label: 'Are the alternatives categorized?',
                    'value.false': 'No',
                    'value.true': 'Yes',
                },
                dateItemForm: {
                    label: 'Date values',
                    singleDate: 'Single date',
                    startAndEndDate: 'Start and end date',
                },
            },
            reportingPeriod: {
                customMaxAgeInDays: 'Number of days',
            },
        },
        aggregated: {
            exportEntirePackage: {
                modalHeader: 'Export all reported data',
                loadingData: 'Preparing data...',
                exportAllButtonCaption: 'Export all reported data',
                itemErrorModal: {
                    title: 'Sorry, something went wrong',
                    message: 'An unexpected error occured when preparing data for export.\n\nPlease contact our customer support with the following error details:\n<code>{{errorDetails}}</code>',
                },
                relativeMeasureCache: {
                    clearResultsButton: 'Run calculations again',
                    useSavedResultsButton: 'Use saved calculation results',
                },
            },
        },
        organizationAdder: {
            header: 'Value chain membership',
            guidance: 'Here you can import <strong>{{orgname}}</strong> to your Data Collector along with reported contact persons, if any.<br /><br />'
				+ '<i class=\'fa fa-check\'></i> = Already in that Data Collector<br />'
				+ '<i class=\'fa fa-times\'></i> = Can be added to that Data Collector'
				+ '<br /><br />'
				+ 'Underneath each Data Collector you see the contact persons that you already have for the organization. This is only available if contact persons have been reported on the organization.',
            addToValueChainButtonText: 'Add to Data Collector',
            addUserButtonText: 'Add a contact person',
            memberButNoParentOrg: 'Added to Data Collector but not yet categorized to the reporting organization',
            addReportingOrgAsParentButtonText: 'Confirm categorization',
            valueChainPicker: {
                title: 'Add organization to Data Collector',
                description: 'Click on any Data Collector to add the organization there. You can not remove the organization from Data Collector here.',
                untoggleMessage: 'You cannot remove the organization from here',
            },
            peoplePicker: {
                title: 'Contact persons',
                description: 'Click on a contact person to add them to the Data Collector. You can not remove contact persons from Data Collector here.',
                untoggleMessage: 'You cannot remove the contact person from here',
            },
        },
        export: {
            exportOrganizationsHeader: 'Export organizations',
            includeCategorizations: 'Include categorizations',
        },
        nextEmailSendoutInfo: {
            title: 'Scheduled emails',
            showInfo: 'Show info',
        },
        networkSettings: {
            popoverHeader: "Settings",
            reportingLockStatusLabel: 'Reporting lock status',
            isReportingReadonly: {
                label: "Lock reporting of new data (does not affect internal reporting)",
                disabled: "Reporting is allowed",
                enabled: "Reporting is locked",
                setDisabled: "Allow reporting",
                setEnabled: "Lock reporting",
            },
            reportedDataLockedBeforeDate: {
                label: "Lock data reported before specific date (does not affect internal reporting)",
                placeholder: "Pick a date",
                enabled: "Data reported before {{date}} is locked",
            },
            useRelativeMeasureResultsCache: {
                label: "Relative measure results cache",
                disabled: "Disabled",
                enabled: "Enabled",
            },
            fiscalYearMonthOffset: {
                label: "Fiscal year month offset",
                formLabel: "Fiscal year month offset (in dashboard charts when aggregating on years)",
                disabled: "Disabled",
            },
        },
    },
    'modules.picker': {
        Alternatives: 'Options',
        chooseAlternatives: 'Choose options',
        alternative: 'Option',
        inputFieldPlaceholder: 'Select or search for {{ itemText }}',
        customObjectType: {
            emptyStateHeader: 'You don\'t have any existing {{information}} to choose from yet',
            emptyStateDescription: 'Create a new {{information}}',
            emptyStateSearch: 'No {{information}} matched your keywords',
        },
        objectTypes: {
            0: {
                emptyStateHeader: 'You don\'t have any existing information to choose from yet',
                emptyStateDescription: 'Create something new to add as information',
                emptyStateSearch: 'No items matched your keywords',
            },
            15: {
                emptyStateHeader: 'You don\'t have any existing activities to choose from yet',
                emptyStateDescription: 'Create a new activity to add as information',
                emptyStateSearch: 'No activities matched your keywords',
            },
            18: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing documents to choose from yet',
                emptyStateDescription: 'Add a new document to add as information',
                emptyStateSearch: 'No documents matched your keywords',
            },
            25: {
                createButtonCaption: 'Save',
            },
            44: {
                emptyStateHeader: 'You don\'t have any existing comments to choose from yet',
                emptyStateDescription: 'Create a new comment to add as information',
                emptyStateSearch: 'No comments matched your keywords',
            },
            50: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing links to choose from yet',
                emptyStateDescription: 'Add a new link to add as information',
                emptyStateSearch: 'No links matched your keywords',
            },
            75: {
                emptyStateHeader: 'No countries to choose from',
                emptyStateDescription: '',
                emptyStateSearch: 'No country matched your keywords',
            },
            90: {
                emptyStateHeader: 'You don\'t have any existing media to choose from yet',
                emptyStateDescription: 'Create a new media to add as information',
                emptyStateSearch: 'No media matched your keywords',
            },
            100: {
                emptyStateHeader: 'You haven\'t assigned this item to anybody',
                emptyStateDescription: ' ',
                emptyStateSearch: 'No users matched your keywords',
            },
            101: {
                emptyStateHeader: 'You haven\'t added any organizations',
                emptyStateDescription: 'Click on the button <b>Add organization</b> above',
                emptyStateSearch: 'No organizations matched your keywords.<br />Try adjusting your keywords or add a new organization.',
            },
            '101_searchServer': { // Not used yet
                emptyStateHeader: 'Search for organizations or add new',
                emptyStateDescription: 'Use the search bar to find organizations to pick. You can also create new organizations.',
                emptyStateSearch: 'No organizations matched your keywords.<br />Try adjusting your keywords or add a new organization.',
            },
            106: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing certificates to choose from yet',
                emptyStateDescription: 'Add a new certificate to add as information',
                emptyStateSearch: 'No certificate matched your keywords',
            },
            107: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing production sites to choose from yet',
                emptyStateDescription: 'Add a new production site to add as information',
                emptyStateSearch: 'No production sites matched your keywords',
            },
            113: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing suppliers to choose from yet',
                emptyStateDescription: 'Add a new supplier to add as information',
                emptyStateSearch: 'No suppliers matched your keywords',
            },
            114: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing holdings to choose from yet',
                emptyStateDescription: 'Add a new holding to add as information',
                emptyStateSearch: 'No holdings matched your keywords',
            },
            118: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing relative measures to choose from yet',
                emptyStateDescription: 'Add a new relative measure to add as information',
                emptyStateSearch: 'No relative measures matched your keywords',
            },
            120: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing products/services to choose from yet',
                emptyStateDescription: 'Add a new product/service to add as information',
                emptyStateSearch: 'No products/services matched your keywords',
            },
            125: {
                createButtonCaption: 'Add new',
                createAndCloseButtonCaption: 'Add new and close',
                emptyStateHeader: 'You don\'t have any existing dates to choose from yet',
                emptyStateDescription: 'Add a new date to add as information',
                emptyStateSearch: 'No dates matched your keywords',
            },
        },
        closeForm: 'Close Form',
    },
    'modules.addon': {
        emptyStateHeader: 'Not activated',
        emptyStateDescription: 'This add-on is not yet activated for your account.',
    },
    'modules.influence': {
        requestFromLabel: 'This is an information request from:',
    },
    'modules.itemStatistics': {
        inconsistentData: 'Inconsistent data',
        inconsistentDataTooltipText: 'The data consists of values with different units and can therefore not display correct data.',
        latestAnswerTooltipText: 'The percentages are based on the latest answer given by each organization',
        constructedTooltipText: '{{ percentage }} have answered "{{ answer }}" - {{ organizationsAnswered }} out of {{ organizationsTotal }} organizations',
    },
    'modules.verification': {
        organizationName: 'Company name',
        registrationNumber: 'Registration number',
        contactPerson: 'Contact person',
        city: 'City',
        cityAndDate: 'City and date',
        signature: 'Signature',
        digitallySigned: 'Digitally signed',
        reference: 'Reference',
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Phone number',
    },
    ActivityWhen: 'Start date',
    ActivityUntil: 'Finish date',
    UploadImage: 'Upload image',
    UploadFile: 'Upload file',
    Guidance: 'Guidance',
    GuidanceVideo: 'Watch information video',
    Loading: 'Loading',
    City: 'City',
    Back: 'Back',
    'modules.editor': {
        title: '{{editortype}} {{objecttypeword}}',
        create: 'Create',
        edit: 'Edit',
    },
    'modules.notifications': {
        emptyStateHeader: 'There are no notifications to show',
        emptyStateDescription: 'When there are notifications they will appear here',
        navBar: {
            emptyStateHeader: '<i class="fa fa-bell-o"></i><br />No notifications',
        },
        eventLog: {
            emptyStateHeader: 'There is nothing to show in your event log',
            emptyStateDescription: 'When there is something to show it will appear here',
        },
        emailTypes: {
            email: 'an email',
            colleagueInvitation: 'an invitation',
            valueChainInvitation: 'an invitation',
            valueChainReminder: 'a reminder',
        },
        eventSentences: {
            influenceFulfilled: 'fulfilled a request for information',
            influenceUnfulfilled: 'unfulfilled a request for information',
            influenceSigned: 'Signed',
            questionAnswered_specific: 'answered <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> on the question <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            questionAnswered: 'answered a question',
            measureAnswered: 'added measureable data',
            measureAnswered_specific: 'added measureable data <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> on <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            parameterAnswered: 'answered a parameter',
            categorized: 'categorized <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            uncategorized: 'uncategorized <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> from <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            textPart_sourceToTarget: '<span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            textPart_sourceFromTarget: '<span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> from <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            signedIn: 'signed in',
            moved: 'moved',
            updated: 'updated {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            created: '{{creationTerm}} {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            deleted: 'deleted {{objectTypeWord}} <span class=\'itemText\'>{{objectText}}</span>',
            deletedDataRelation: 'removed a relation',
            createdDataRelation: 'added a relation',
            updatedDataRelation: 'updated a relation',
            deletedDataRelation_specific: 'removed <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> from <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            createdDataRelation_specific: 'added <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            updatedDataRelation_specific: 'moved <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            sentTo: 'sent <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\'>{{targetText_cut}}</span>',
            deletedDataRelation_specific_withObjectTypeWord: 'removed {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> from <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            createdDataRelation_specific_withObjectTypeWord: 'added {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            updatedDataRelation_specific_withObjectTypeWord: 'moved {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> to <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            createdVisibilityTag: 'made {{sourceTypeWord}} <span class=\'targetText itemText\' title="{{sourceText}}">{{sourceText_cut}}</span> visible in <span class=\'targetText itemText\' title="{{targetText}}">{{targetText_cut}}</span>',
            individualUserUpdatedOneself: 'updated their account settings',
            sendAndAttestInvoice: 'activated new services <span class=\'itemText\'>{{orgname}}</span>',
            updatedBillingInformation: 'updated billing information for <span class=\'itemText\'>{{orgname}}</span>',
            createdRequirement: 'added a requirement',
            updatedRequirement: 'updated a requirement',
            deletedRequirement: 'removed a requirement',
            updatedOrganization: 'updated organization settings for <span class=\'itemText\'>{{orgname}}</span>',
            updatedOrganizationCustomId: 'updated <span class=\'text-capitalize\'>Custom ID</span> on an organization to <strong>{{value}}</strong>',
            sentEmailToUserOnOrg: 'sent {{emailNoun}} to <span class=\'targetText itemText\' title=\'{{emailAddress}}\'>{{username}}</span> on <span class=\'targetText itemText\'>{{orgname}}</span>{{endingText}}',
            sentEmailToUser: 'sent {{emailNoun}} to <span class=\'targetText itemText\' title=\'{{emailAddress}}\'>{{username}}</span>{{endingText}}',
            sentEmailToAddress: 'sent {{emailNoun}} to <span class=\'targetText itemText\'>{{emailAddress}}</span>{{endingText}}',
            sentEmail: 'sent {{emailNoun}}{{endingText}}',
            sentencePart_inValueChain: ' in Data Collector',
        },
    },
    EventLog: 'Event Log',
    Swedish: 'Swedish',
    English: 'English',
    'languages.byCode': {
        generic: 'Generic',
        en: 'English',
        sv: 'Swedish',
        cs: 'Czech',
        de: 'German',
        es: 'Spanish',
        pl: 'Polish',
        zh: 'Chinese (simplified)',
        nb: 'Norwegian',
        da: 'Danish',
        fi: 'Finnish',
        fr: 'French',
        pt: 'Portuguese',
        af: 'Afrikaans',
        it: 'Italian',
        hu: 'Hungarian',
        vi: 'Vietnamese',
    },
    'languages.settings': {
        sameInterfaceAndPreferredContentLanguage: '{{language}} as interface language and in received requests (if available)',
        differentInterfaceAndPreferredContentLanguage: '{{language_ui}} as interface language. {{language_content}} in received requests (if available)',
    },
    locales: {
        generic: 'Generic',
        'en-US': 'English',
        'sv-SE': 'Swedish',
        'cs-CZ': 'Czech',
        'de-DE': 'German',
        'es-ES': 'Spanish',
        'pl-PL': 'Polish',
        'zh-Hans': 'Chinese (simplified)',
        'nb-NO': 'Norwegian',
        'da-DK': 'Danish',
        'fi-FI': 'Finnish',
        'fr-FR': 'French',
        'pt-PT': 'Portuguese',
        'af-ZA': 'Afrikaans',
        'it-IT': 'Italian',
        'hu-HU': 'Hungarian',
        'vi-VN': 'Vietnamese',
    },
    ShowLinkage: 'Show linkage',
    HideLinkage: 'Hide linkage',
    Sign_Verb: 'Sign',
    CreateNew: 'Create new',
    AddNew: 'Add new',
    Linkages: 'Linkages',
    LinkagesDescription: 'All areas from other frameworks that are linked to:',
    menu: {
        UpgradeAccount: 'Upgrade Your Account',
        Account: 'Account',
        AccountSettings: 'Account settings',
        OrganizationUsers: 'Users',
        OrganizationSettings: 'Organization Settings',
        ChangeOrganization: 'Change Organization',
        SignOut: 'Sign Out',
    },
    'modules.standards.griG4': {
        measuresCreatedByWf: 'Indicators provided by Worldfavor based on GRI G4 guidance',
        measuresCreatedByWfSingular: 'Indicator provided by Worldfavor based on GRI G4 guidance',
    },
    'modules.dataStorage': {
        11: {
            emptyStateHeader: 'You haven\'t added any questions yet',
            emptyStateDescription: 'All questions that you create will appear here.',
        },
        15: {
            emptyStateHeader: 'You haven\'t added any activities yet',
            emptyStateDescription: 'All activities that you create will appear here.',
        },
        18: {
            emptyStateHeader: 'You haven\'t added any documents yet',
            emptyStateDescription: 'All documents that you add will appear here.',
        },
        21: {
            emptyStateHeader: 'You haven\'t added any measures yet',
            emptyStateDescription: 'All measures that you create will appear here.',
        },
        25: {
            emptyStateHeader: 'You haven\'t added any measurable data yet',
            emptyStateDescription: 'All measurable data that you create will appear here.',
        },
        31: {
            emptyStateHeader: 'You haven\'t added any answers yet',
            emptyStateDescription: 'All answers that you create will appear here.',
        },
        44: {
            emptyStateHeader: 'You haven\'t added any comments yet',
            emptyStateDescription: 'All comments that you create will appear here.',
        },
        50: {
            emptyStateHeader: 'You haven\'t added any links yet',
            emptyStateDescription: 'All links that you add will appear here.',
        },
        90: {
            emptyStateHeader: 'You haven\'t added any media yet',
            emptyStateDescription: 'All media that you add will appear here.',
        },
        91: {
            emptyStateHeader: 'You haven\'t created any Sustainability Room yet',
            emptyStateDescription: 'Click on Create new to get started',
        },
        93: {
            emptyStateHeader: 'You haven\'t created any widgets yet',
            emptyStateDescription: 'Click on Create new to get started',
        },
        106: {
            emptyStateHeader: 'You haven\'t added any certificates yet',
            emptyStateDescription: 'All certificates that you add will appear here.',
        },
        107: {
            emptyStateHeader: 'You haven\'t added any production sites yet',
            emptyStateDescription: 'All production sites that you add will appear here.',
        },
        118: {
            emptyStateHeader: 'You haven\'t added any relative measures yet',
            emptyStateDescription: 'All relative measures that you add will appear here.',
        },
        120: {
            emptyStateHeader: 'You haven\'t added any products/services yet',
            emptyStateDescription: 'All products/services that you add will appear here.',
        },
        125: {
            emptyStateHeader: 'You haven\'t added any dates yet',
            emptyStateDescription: 'All dates that you add will appear here.',
        },
        71: {
            emptyStateHeader: 'You haven\'t created any structures yet',
            emptyStateDescription: '',
        },
        generic: {
            emptyStateHeader: 'You haven\'t added any information yet',
            emptyStateDescription: 'All information that you create will appear here.',
        },
        measureAnswers: {
            emptyStateHeader: 'You haven\'t added any measurable data yet',
            emptyStateDescription: 'All measurable data that you create will appear here.',
        },
    },
    In: 'In',
    To2: 'To',
    DetailedInformation: 'Detailed information',
    Data: 'Data',
    SeeMore: 'See more',
    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted',
    Deleted_v2: 'Deleted',
    Item_IA: 'An item',
    Item_DA: 'The item',
    Items: 'Items',
    Statement_IA: 'A comment',
    Statement_DA: 'The comment',
    Structure_IA: 'A structure',
    Structure_DA: 'The structure',
    User_IA: 'A user',
    User_DA: 'The user',
    QuestionAnswer_IA: 'An answer to a question',
    QuestionAnswer_DA: 'The answer',
    MeasureAnswer_IA: 'Measurable data',
    MeasureAnswer_DA: 'The measurable data',
    Organization_IA: 'A company',
    Organization_DA: 'The company',
    Embed: 'Media',
    Embed_IA: 'Media',
    Embed_DA: 'The media',
    Embeds: 'Medias',
    Influence_Noun_IA: 'A request for information',
    Influence_Noun_DA: 'The request for information',
    VisibilityTag_IA: 'A visibility tag',
    VisibilityTag_DA: 'The visibility tag',
    validationMessages: {
        email: 'A valid email address is required',
        required: 'Required',
        notANumber: 'Must be a number',
        stringTooShort: 'The text is too short',
        newEmailAlreadyExists: 'The specified new email already exists',
        swedishOrganizationNumber: 'A valid swedish organization number is required (XXXXXX-XXXX)',
        vatRegOrGlnNumber: 'You must enter either a registration number, VAT number or GLN number',
        password: 'At least 8 characters long with lower case (a‑z), upper case (A‑Z) and numbers (0‑9)', // The dashes are NON-BREAKING HYPHEN
    },
    'modules.sustRoom': {
        childrenHeader: 'Menu',
        editRoomButton: 'Edit',
        editChildrenButton: 'Add information',
        emptyStateHeader: 'You haven\'t added any information yet',
        emptyStateDescription: 'Click on <strong>Add information</strong> to get started',
    },
    'modules.dashboard': {
        emptyStateHeader: 'You don\'t have access to any services in your organization\'s account',
        emptyStateDescription: 'Contact a user that is administrating you account if you have questions regarding this.',
        internalReport: {
            header: 'You have remaining tasks to complete',
            goto: 'Go to Assigned tasks',
            emptyStateHeader: 'You have completed all your tasks',
            emptyStateDescription: 'If you want to see tasks you have already completed, follow the link below.<br /><a href=\'/report/internal\'>See assigned tasks</a>',
        },
        organization: {
            editButton: 'Edit',
        },
        users: {
            header: 'Users',
            body: 'Worldfavor is better when you work together',
            manageButton: 'Manage users',
        },
        eventLog: {
            header: 'Latest activity',
            seeAllEvents: 'See all events',
            body: '',
        },
        promotion: {
            header: '',
            body: '',
            learnMore: 'Learn more about {{title}}',
        },
        visualize: {
            totalViews: 'Total views',
            emptyStateHeader: 'There are no views to show yet',
        },
        valueChain: {
            sentRequest: 'Requests sent',
            fulfilledRequests: 'Requests answered',
            emptyStateHeader: 'You have not yet sent any requests',
        },
        report: {
            completed: 'Requests completed',
            emptyStateHeader: 'You have no uncompleted information requests',
        },
    },
    'modules.mailHistory': {
        pageHeader: 'Email history',
        mailHistoryFor: 'Email history for {{name}}',
        emptyState: {
            header: 'No email history',
            description: 'No email has been sent to this organization yet',
        },
    },
    'modules.pageHeading': {
        helpUrl: {
            title: 'Need Help?',
            description: 'Learn how to use {{solution}}',
        },
    },
    NowFulfilling: 'Now meets',
    NoLongerFulfilling: 'No longer meets',
    'modules.users': {
        AccessLabel: 'Access',
        Full: 'Full',
        Restricted: 'Restricted',
        ViewModeLabel: 'Auditor mode',
        ViewMode: 'Enable Auditor mode (can only view, not edit)',
        RestrictedAccessStatus: 'Restricted access',
        FullAccessStatus: 'Full access',
        CustomUserRootButton: 'Set restricted access',
        ResetToOrgRootButton: 'Full access',
        SendInviteMail: 'Send invite email',
        RemoveFromOrganization: 'Remove from organization',
        confirmRemoveFromOrganization: {
            header: 'Remove {{username}} from organization',
            message: '<i class="fa fa-user-times"></i><div class="tr"><div class="headerText">Are you sure that you want to remove the user?</div><strong>{{username}}</strong> will be removed and will no longer be able to access your account.\n\nIf you still want to remove the user, click on the button <strong>Remove user</strong> below.',
            button: 'Remove user',
        },
        alreadyInOrganization: {
            modalTitle: 'User already exists',
            modalMessage: 'A user with this email address already exists in the organization: <span style=\'font-size:14px;\'><br /><br /><b>{{name}}</b><br />{{email}}</span><br />',
        },
        existingUserAddedToOrganization: {
            modalTitle: 'User already exists',
            modalMessage: 'A user with this email address already exists in Worldfavor. This user has now been added to the organization. <span style=\'font-size:14px;\'><br /><br /><b>{{name}}</b><br />{{email}}</span><br />',
        },
    },
    'modules.sustainabilityRoom': {
        activityFeed: {
            emptyStateHeader: 'Set activities to <strong class=\'text-italic\'>Public Information</strong> to show them here',
            emptyStateDescription: 'Setting the visibility status to <strong>Public Information</strong> makes them visible in your Sustainability Room.<br />Click on the options button on an activity in Manage and then on <strong>Visible in</strong> to change visibility settings.',
            emptyStateImage: '/assets/img/sustRoom_visibilityEmptyState_en.png',
        },
        pickerDescription: 'Choose what you want to include in your Sustainability Room',
        urlRouteName: 'Customize URL (portal.worldfavor.com/room/\'Customize this part below\')',
        topAdminInfo: '<strong>This Sustainability Room is LIVE</strong> and can only be accessed through the following link: <a href=\'{{linkUrl}}\' target=\'_blank\'>{{linkUrl}}</a>. Customize your link under <strong>Edit</strong>.<br /><br /><strong>Sustainability Room displays your Public Information</strong>. Click on the options button on information you want to show here and set <strong>Visible in</strong> to <strong>Public Information</strong>.',
    },
    'modules.invoice': {
        invoiceTableHeader: {
            invoice: 'Invoice',
            created: 'Created',
            status: 'Status',
            deliveryMethod: 'Delivery Method',
            dueDate: 'Due Date',
            amount: 'Amount',
            vat: 'incl. VAT',
            eMail: 'Email',
            post: 'Post',
        },
        invoiceStages: {
            unpaid: 'Unpaid',
            paid: 'Paid',
            overdue: 'Overdue',
            paymentSent: 'Payment sent',
        },
        expired: 'expired',
        subscriptionValidity: '*Each subscription is valid for exactly 1 year from the purchase date.',
    },
    'modules.export': {
        csv: {
            exportButton: 'Export',
            exportItemData: {
                modalHeader: 'Export data',
            },
            columns: {
                relativeMeasureStepObject: 'Calculation step {{step}}',
                relativeMeasureStepSourceValue: 'Calculation step {{step}} Value',
                relativeMeasureStepUnit: 'Calculation step {{step}} Unit',
                relativeMeasureStepOperation: 'Calculation step {{step}} Operation',
            },
            popover: {
                header: 'Export data to CSV file',
                step1: '1. If you want to limit your export, use the filters in Data Collector',
                step2: '2. Select the columns you want to export',
                step3: '3. The names of the columns can be changed',
                step4: '4. Click download',
                columns: 'Columns',
                rows: 'Rows',
                settings: 'Settings',
                download: 'Download CSV',
                fieldSeparator: 'Field separator',
                decimalSeparator: 'Decimal separator',
                textDelimiter: 'Text delimiter',
                comma: 'Comma',
                semiColon: 'Semicolon',
                dot: 'Dot',
                doubleQuotes: 'Double',
                singleQuotes: 'Single',
                dateTimeFormat: 'Time format',
                includeSeconds: 'Include seconds',
                includeSecondsInfo: 'Should timestamps include seconds?',
                onlyExportLatestData: 'Only export latest data',
                useHorizontal: 'Horizontal export',
                convertMeasurements: 'Convert measurements',
                includeCategorizations: 'Include categorizations',
                groupCategorizations: 'Group categories',

            },
        },
        pdf: {
            exportButton: 'Export to PDF',
            popover: {
                header: 'Export data to PDF file',
                optionsHeader: 'Include:',
                options: {
                    coverPage: 'Cover Page',
                    pageHeading: 'Main page header',
                    userData: 'Attached information',
                    measureDataChart: 'Graph for measured data',
                    tooltip: {
                        included: 'Included',
                        excluded: 'Excluded',
                    },
                },
                stylesHeader: 'Document style',
                styles: {
                    default: 'Default',
                    compact: 'Compact',
                    minimal: 'Simple',
                    elegant: 'Elegant',
                },
                note: 'Note: Selected filters and search results will affect the content incuded in the document',
                isoStandardInfo: 'The content you are about to export contains copyright protected material from ISO-standards. The copyright owner does not allow the copyrighted material to be copied and it will therefore be excluded from the export.',
                preview: {
                    header: 'Preview',
                },
                previewButton: 'Preview',
                downloadButton: 'Download',
            },
            errorMessages: {
                couldNotDownloadFile: 'The file could not be downloaded. Please reload the page and try again.',
                contactSupportMessage: 'Generating PDF takes longer than expected. Please try refreshing the page and try again.\n\nIf this error keeps appearing, please use our chat support and we will help you.',
            },
        },
    },
    'modules.objectViewer': {
        children: 'Content',
        childrenByUser: 'Your answers',
        relatedContent: 'Linkages',
        parents: 'Appears in',
        parentsByUser: 'Added to categories',
        parentsByUser_historic: 'Previously added to categories',
        structureChildrenByUser: 'Content',
        attachedInformation: 'Your attached information',
        requestsForInformation: 'Requests for information',
        answerToAQuestion: 'Answer to a question',
        categorizations: 'Categorizations',
        visibility: 'Visibility',
        showGuidance: 'Show Guidance',
        hideGuidance: 'Hide Guidance',
        forThePeriod: 'For the period',
        whenUntilDate: 'When / Until Date',
        wfInformation: 'Worldfavor Information',
        items: 'items',
        expandAll: 'Expand All',
        emptyStateAttachedInfo1: 'Add information by pressing the',
        emptyStateAttachedInfo2: 'button above',
        emptyStateCategorization1: 'Categorize this area by pressing the',
        emptyStateCategorization2: 'button above',
        readMore: 'Read more',
        readLess: 'Read less',
        emptyStateSearch: 'No items matched your keywords',
    },
    'modules.scorecard': {
        addRemoveButton: 'Add/Remove Measures',
        emptyStateHeader: 'You haven\'t added any measurable data yet',
        emptyStateDescription: 'All measurable data that you add will appear for the public.',
    },
    'modules.publicProfile': {
        emptyStateText: 'There are no result for your search keywords',
        emptyStateDescription: 'Please try another search',
    },
    AddTo: 'Add to category',
    SendTo: 'Send to',
    AssignTask: 'Assign to colleague',
    AssignedToColleague: 'Assigned to colleague',
    AssignedToYou: 'Assigned to you',
    OpenObjectViewer: 'Detailed view',
    Details: 'Detailed view',
    TaggedTo: 'Tagged to',
    UrlRouteName: 'Customize URL',
    BackgroundImage: 'Background image',
    UploadBackgroundImage: 'Upload background image',
    SomethingWentWrongDuringSaving: 'Something went wrong during saving. Please try again.',
    Belonging: 'Belonging',
    AttachedInformation: 'Attached information',
    ChooseAttachedInformation: 'Choose attached information',
    Categorizations: 'Categorizations',
    Attach: 'Attach',
    AttachedTo: 'Attached to',
    AttachTo: 'Attach to',
    AttachInformation: 'Attach information',
    Menu: 'Menu',
    VisibleIn: 'Visible in',
    SetVisibility: 'Set visibility for',
    'modules.sunburstChart': {
        outermostCircleInfo: '<strong>Outermost circle</strong>: Your information',
        innerCirclesInfo: '<strong>Inner circles</strong>: Categories and hierarchies within the framework',
    },
    AvailableSoon: 'Available soon',
    Clear: 'Clear',
    QuestionAnswerTypesById: {
        1: 'Processing',
        2: 'Not relevant',
        3: 'No',
        4: 'Yes',
    },
    SetRequirement: 'Set requirement',
    Exception: 'Exception',
    SetException: 'Set exception',
    AddException: 'Add exception',
    RemoveException: 'Remove exception',
    SetFulfillment: 'Set fulfillment',
    ConfirmDeletionOrganizationSpecificRequirement: 'Do you want to delete the organization specific requirement?',
    SendMail: 'Send email',
    Requirement_IA: 'A requirement',
    Requirement_DA: 'The requirement',
    Category: 'Category',
    RegistrationNumber: 'Registration number',
    Events: 'Events',
    Never: 'Never',
    ReadMore: 'Read more',
    InboxTitle: 'Your Inbox',
    InboxEmptyState: 'Your inbox is empty',
    InboxDescription: 'View and work with information that has been sent to you.',
    InboxWhoSent: 'sent you this item',
    'modules.metadata': {
        consolidatedData: {
            title: 'Aggregated data from Data Collector',
        },
        popover: {
            itemsLimitMessage: 'Showing only the 5 most recent items, click below to see more',
            showMore: 'Show more',
            showLess: 'Show less',
        },
        expandedObjectViewer: {
            showData: 'Show your data',
            hideData: 'Hide your data',
            noData: 'You have no data to display',
        },
        emptyStateMessages: {
            emptyStateAttachedInfo1: 'Add information by pressing the',
            emptyStateAttachedInfo2: 'button above',
            emptyStateCategorization1: 'Add this item by pressing the',
            emptyStateCategorization2: 'button above',
            content: 'There is no content for this item',
            linkages: 'This item is not linked to any other item in Worldfavor',
            answers: 'There are no answers for this item',
        },
    },
    SearchForKeywords: 'Search for keywords',
    NoResults: 'No results',
    Unanswered: 'Unanswered',
    TotalAnswered: 'Total answered',
    Status: 'Status',
    PreviewOfMail: 'Preview of email',
    Reported: 'Reported',
    noInformationEmptyState: {
        header: 'You have not added any information',
        description: 'You can add information by clicking the button above.',
    },
    GoTo: 'Go to',
    Message: 'Message',
    'modules.promotion': {
        demoBookingPrompt: {
            header: 'Get a demo of {{name}}',
            description: 'Hello! Fill in some information below and click Send, and we will get back to you for a demo shortly. As a signed in user we already have your email address. <br /><br />By requesting a demo, you don’t commit to anything.',
            messageInputLabel: 'Optional message',
            submitButtonCaption: 'Send',
            errorMessage: 'Something went wrong during booking. Please try again.',
            confirmationText: '<strong>Thank you!</strong><br /><br />We will contact you for a demo shortly.',
        },
        orderContactPrompt: {
            description: 'Hello! Fill in some information below and click Send, and we will get back to you shortly. As a signed in user we already have your email address.',
            errorMessage: 'Something went wrong. Please try again.',
            confirmationText: '<strong>Thank you!</strong><br /><br />We will contact you shortly.',
        },
        isoStandards: {
            gridCard: {
                title: 'Add more ISO standards to your account',
                description: 'Worldfavor offers digital working tools for several ISO standards.',
                button: 'Add ISO standards',
                footerText: 'ISO standards are provided in collaboration with SIS,',
                sis: 'Swedish Standards Institute',
            },
        },
        wfPromotions: {
            videoCaptions: 'Reasons why you should watch this amazing video',
            banner: {
                text: 'Want to know more about how others are taking the next step towards becoming a sustainable company?',
                buttonText: 'Get in touch',
            },
        },
    },
    'modules.eCommerce': {
        productPicker: {
            stepTitle: 'Select Services',
            buttonLabel: 'Proceed to Checkout',
            errorMessage: 'Please select at least one service',
            alertModal: {
                title: 'Sorry, something went wrong',
                message: 'An unexpected error has occured.\nPlease reload the page and try again.',
            },
        },
        checkout: {
            stepTitle: 'Checkout',
            headerTitle: 'Checkout',
            headerDescription: 'Please fill in your billing information',
            buttonLabel: 'Invoice Preview',
            table: {
                record: 'Service',
                billingPeriod: 'Valid until',
                numberOfYears: 'Years',
                costPerMonth: 'Amount/month',
                costPerYear: 'Amount/year',
                cost: 'Amount',
                info: 'All amounts exclude VAT. All subscriptions are billed annually.',
                total: 'Total: ',
                excludingVat: '(excl. VAT)',
            },
            billingInformationTitle: 'Billing Information',
            acceptTerms: 'I accept the',
            termsAndConditions: 'Service Agreement',
            deliveryByPost: 'Post',
        },
        invoicePreview: {
            stepTitle: 'Invoice Preview',
            headerTitle: 'Confirm Order',
            headerDescription: 'Please make sure all your information is correct before confirming',
            buttonLabel: 'Confirm Order',
            alertModal: {
                title: 'Sorry, an unexpected error occured in the ordering process',
                message: 'The invoice was not issued and no services were activated.\nPlease reload the page and try again.\n\nWe apologize for the inconvenience.',
            },
        },
        finalStep: {
            stepTitle: 'Order Completed',
            headerTitle: 'Thank you!',
            headerDescription: 'You can start using your new services right away.',
            message: 'Keep making the world more sustainable!',
            buttonLabel: 'Close',
            alertModal: {
                title: 'Sorry, an unexpected error occured in the ordering process',
                message: 'The invoice was not issued and no services were activated.\nPlease reload the page and try again.\n\nWe apologize for the inconvenience.',
            },
        },
        exitModal: {
            warning: 'Are you sure?',
            title: 'Are you sure you want to cancel the checkout process?',
            message: 'Your selected services and form field information will be cleared.',
            primaryButton: 'Yes',
            backButton: 'No, take me back to Checkout',
        },
        termsAndConditions: {
            title: 'Service Agreement',
            message: 'Text for terms & conditions goes here',
            errorMessage: 'Please accept the Service Agreement before proceeding',
        },
        moreInfo: {
            title: 'More about this service',
        },
    },
    'modules.mailPreview': {
        editEmailInfoMessage: 'Add a friendly message to the new user with some information to get started',
        cancelEditing: 'Cancel editing',
        editMail: 'Edit email',
        saveChangesMessage: 'Save your changes to update the preview ',
        emailPreview: 'Email Preview',
        confirmationMessage: 'The email has now been sent to',
        preview: 'Preview',
        noContactSelected: 'No contact selected',
        noContactSelectedMessage: 'Please select one contact person to continue',
        chooseContact: 'Choose a contact person to send an email',
        previewOfSentMail: 'Sent email',
        previewOfFailedMail: 'Failed email',
        historicMailPreviewUnavailable: 'The email can not be displayed because it was created in an older version of Worldfavor',
        historicMailPreviewError: 'Something went wrong and the email can not be shown',
        errorModal: {
            message1: 'There are no requirements or contacts set for this organization',
            message2: 'Please add requirements/contacts before you can send an email',
        },
        serverErrorModal: {
            title: 'Sorry, something went wrong',
            message: 'An unexpected error has occured.\nPlease reload the page and try again.',
        },
        exitModal: {
            warning: 'Are you sure?',
            title: 'Are you sure you want to cancel?',
            message: 'Your changes will not be saved.',
            primaryButton: 'Yes',
            backButton: 'No, take me back',
        },
    },
    billingInformation: {
        ourReference: 'Our reference',
        address: 'Postal address',
        address2: 'Second address row',
        contactEmail: 'Email of contact person',
        contactName: 'Full name of contact person',
        countryCode: 'Country code',
        vatNumber: 'VAT number',
        zipCode: 'Zip code',
        deliveryMethod: 'Invoice delivery method',
    },
    'modules.questionAnswering': {
        useLatestAnswer: 'Use latest answer',
        useLatestAnswer_info: '',
    },
    'modules.upgradeAccount': {
        pageTitle: 'Upgrade Your Account',
        userLevels: {
            title: 'User levels',
            readMore: 'READ MORE',
            highlight: 'MAIN FUNCTIONALITY',
            manage: {
                title: 'MANAGE',
                description: 'Digitize CSR Practice, Digital Reporting in Guidelines',
                b1: 'Digitalized Guidelines',
                b2: 'Standard Linkage',
                b3: 'Auditor Access',
                b4: 'Materiality Tool',
                b5: 'Custom Framework',
            },
            visualize: {
                title: 'VISUALIZE',
                description: 'Visualize Efforts and Data to Stakeholders, Digital Reporting',
                b1: 'Sustainability Room/Employer Branding Room/Compliance Room',
                b2: 'Website Widgets',
            },
            valuechain: {
                title: 'VALUE CHAIN',
                description: 'Automate Assessment of Suppliers, and/or Internal Units or Assets',
                b1: 'Automated Assessment',
                b2: 'Digital Codes of Conduct',
                b3: 'Aggregated Compliance',
                b4: 'Supplier/Unit Profiling',
                b5: 'Categorization',
                b6: 'Reporting is free for unit/supplier',
            },
        },
        isoStandards: {
            title: 'Available ISO Standards',
            readMore: 'Read more about ISO Standards',
            iso9001: {
                title: 'ISO 9001 - Quality Management Systems',
                description: 'ISO 9001 helps you implement a quality management system in your organization.',
            },
            iso14001: {
                title: 'ISO 14001 - Environmental Management Systems',
                description: 'ISO 14001 helps you implement an environmental management system in your organization.',
            },
            iso20121: {
                title: 'ISO 20121 - Sustainable Events (Swedish)',
                description: 'ISO 20121 supports companies and organizations in improving sustainability throughout the entire event cycle.',
            },
            iso26000: {
                title: 'ISO 26000 - Social Responsibility',
                description: 'ISO 26000 is an international standard with guidelines for Social Responsibility in organizations.',
            },
            iso27001: {
                title: 'ISO 27001 - Information Security',
                description: 'ISO 27001 is a standard for implementing Information Security management in the organization.',
            },
        },
    },
    'modules.organization': {
        introModal: {
            header: {
                title: 'Welcome to Worldfavor',
                description: 'Just a few details left before you continue. Make sure that your organization\'s information is correct.',
                required: '* Required',
            },
            form: {
                orgName: 'Name of the organization *',
                regNumber: 'Registration number',
                vatNumber: 'VAT number',
                glnNumber: 'GLN number',
                country: 'Country *',
                logo: 'Logo',
                employeeRange: 'Number of employees *',
                industries: {
                    label: 'Industry *',
                    button: 'Add/Remove',
                    pickerTitle: 'Select industry',
                    pickerDescription: 'Select your organization\'s primary industry. You can select more than one industry.',
                },
            },
            footer: {
                text: 'Basic information about your organization helps us give you a better Worldfavor experience.\nYou can change the information at any time.',
                submitButton: 'Save',
            },
        },
    },
    'modules.manage': {
        ownStructures: {
            emptyStateHeader: 'You have not yet created a Custom Framework',
            emptyStateDescription: 'Go to Custom Frameworks to get started',
            editor: {
                emptyStateHeader: 'This custom framework is empty',
                emptyStateDescription: 'Click <b>Add</b> above to start adding content',
            },
        },
        internalValueChain: {
            remainingAssignments: 'Remaining assignments: ',
        },
    },
    'modules.dateRangePicker': {
        ranges: {
            today: 'Today',
            lastWeek: 'Last 7 days',
            lastMonth: 'Last 1 month',
            last2Months: 'Last 2 months',
            last3Months: 'Last 3 months',
            last6Months: 'Last 6 months',
            lastYear: 'Last 1 year',
            last2Years: 'Last 2 years',
            last3Years: 'Last 3 years',
            customRange: 'Custom range',
        },
        applyLabel: 'Set date range',
        cancelLabel: 'Clear',
    },
    'modules.filters': {
        activeFilterMessage: 'You have {{count}} active filter',
        activeFilterMessagePlural: 'You have {{count}} active filters',
        clearAllFilters: 'Clear all filters',
        filterByUser: 'Filter by user',
        filterByOrganization: 'Filter by organization',
        filterByObjectType: 'Filter by object type',
        filterByEventType: 'Filter by event type',
        filterByDate: 'Filter by date',
        filterByMailStatus: 'Filter by status',
        filterByMailPurpose: 'Filter by purpose',
        dateFilterPlaceholder: 'Choose date range',
        mailStatus: {
            sent: 'Sent',
            failedToSend: 'Failed to send',
        },
        mailPurpose: {
            undefined: 'Undefined',
            notificationMail: 'Notification mail',
            valueChainInvitation: 'Value chain invite',
            valueChainReminder: 'Value chain reminder',
            colleagueInvitation: 'Colleague invitation',
        },
    },
    EditAccess: 'Edit access',
    Access: 'Access',
    Access_IA: 'An access',
    Access_DA: 'The access',
    Parameter: 'Parameter',
    Parameters: 'Parameters',
    Parameter_IA: 'A parameter',
    Parameter_DA: 'The parameter',
    ParameterValue: 'Parameter value',
    ParameterValues: 'Parameter values',
    ParameterValue_IA: 'A parameter value',
    ParameterValue_DA: 'The parameter value',
    BillingInformation: 'Billing information',
    BillingInformations: 'Billing informations',
    BillingInformation_IA: 'A billing information',
    BillingInformation_DA: 'The billing information',
    Location_IA: 'A location',
    Location_DA: 'The location',
    Invoice: 'Invoice',
    Invoices: 'Invoices',
    Invoice_IA: 'An invoice',
    Invoice_DA: 'The invoice',
    UniqueReports: 'Unique reports',
    RequirementFulfillment: 'Requirement fulfillment',
    GoalFulfillment: 'Goal fulfillment',
    VATNumber: 'VAT number',
    Somebody: 'Somebody',
    'modules.locations': {
        primaryAddress: 'Primary address',
        setOrgPrimaryLocation: 'Set primary address',
        searchInputPlaceholder: 'Search for a place or address',
        manualInputPlaceholder: 'Write the full address',
        openInGoogleMaps: 'Open in Google Maps',
    },
    ClearFilter: 'Clear filter',
    Certificate: 'Certificate',
    Certificates: 'Certificates',
    Certificate_IA: 'A certificate',
    Certificate_DA: 'The certificate',
    ProductionSite: 'Production site',
    ProductionSites: 'Production sites',
    ProductionSite_IA: 'A production site',
    ProductionSite_DA: 'The production site',
    CustomID: 'Custom ID',
    SetCustomID: 'Set custom ID',
    YearlySpend: 'Yearly spend',
    SetYearlySpend: 'Set yearly spend',
    for: 'for',
    UserPosition: 'Title',
    AggregatedView: 'Aggregated view',
    RawMaterial: 'Raw material',
    CountryOfOrigin: 'Country of origin',
    RequirementPackage: 'Requirement Package',
    RequirementPackages: 'Requirement Packages',
    AnalyzePackage: 'Analysis Package',
    AnalyzePackages: 'Analysis Packages',
    Categorize: 'Categorize',
    Share: 'Share',
    ValidFromDate: 'Valid from',
    ValidUntilDate: 'Valid until',
    EmployeeCountSpan: 'Number of employees',
    Issuer: 'Issuer',
    LatitudeLongitude: 'Latitude, Longitude',
    CustomFramework: 'Custom framework',
    CustomFrameworks: 'Custom frameworks',
    CustomCategory: 'Custom category',
    CustomCategories: 'Custom categories',
    OwnCategorizations: 'Own categorizations',
    SustainabilityRoom: 'Sustainability Room',
    SustainabilityRooms: 'Sustainability Rooms',
    SustainabilityRoom_IA: 'A Sustainability Room',
    SustainabilityRoom_DA: 'The Sustainability Room',
    RequiredFormFieldHeader: 'Make sure that you filled all the required fields',
    RequiredFormFieldDescription: 'Please check if you filled the fields for all the languages',
    'modules.mailSettings': {
        pageHeader: 'Email settings',
        Notification: 'Notification',
        ValueChainInvitation: 'Invitation',
        ValueChainReminder: 'Reminder',
        Active: 'Active',
        NotActive: 'Not active',
        Time: 'Time',
        EveryDay: 'Every day',
        Every: 'Every',
        TimePrefix: 'at',
        Sender: 'Sender',
        FromEmail: 'Sender email',
        FromName: 'Sender name',
        ReplyEmail: 'Reply email',
        HelloText: 'Greeting',
        BodyText: 'Body text',
        CallToActionText: 'Call to action',
        ActionButtonText: 'Text on login button',
        EndingText: 'Ending',
        RegardsText: 'Regards',
        SenderLine1: 'Sender line 1',
        SenderLine2: 'Sender line 2',
        DayOfWeek: 'Day of week (1-7)',
        HourOfDay: 'Time (hour of day, 0-23)',
        DayOfMonth: 'Day of month (1-31)',
        MonthOfYear: 'Month of year (1-12)',
        valueChainReminderMessages: {
            editDropdownMessage: 'Edit reminder messages',
            editFormtitle: 'Edit reminder messages',
            messagesInformation: 'No message will be shown if there is less than a week since the request was activated.',
            messagePreset: {
                timeAgoInfo: 'Message when there is no due date or the due date is more an a week away',
                timeLeftWarning: 'Message when the due date is within a week',
                sameDayWarning: 'Message when the due date is the same day',
                overdueWarning: 'Message when the due date has passed',
            },
            validationMessage: 'Please fill in the {{message}}',
            placeholders: {
                placeholderDescription: 'These parameters can be used in the texts. They will get replaced with real values when an email is sent.',
                weeksAgo: 'Time ago since the request was activated expressed in weeks. If less than a week then "1 day", "2 days" etc otherwise "1 week", "2 weeks" etc',
                daysAgo: 'Time ago since the request was activated expressed in days. Like "1 day", "2 days" etc',
                weeksLeft: 'Time left until the due date expressed in weeks. If less than a week then "0 days", "1 day", "2 days" etc otherwise "1 week", "2 weeks" etc.<br />(Requires a due date for the message to show)',
                daysLeft: 'Time left until the due date expressed in days. Like "0 days", "1 day", "2 days" etc.<br />(Requires a due date for the message to show)',
                dueDate: 'The due date in format "7 Jan 2018".<br />(Requires a due date for the message to show)',
                authorFullName: 'Full name of the sender person in the email',
                authorEmail: 'Email address of the sender person in the email',
                authorPhoneNumber: 'Phone number of the sender person in the email',
                authorOrganizationName: 'Your organization name',
                recipientFullName: 'Full name of the contact person',
                recipientFirstName: 'Contact person first name',
                recipientLastName: 'Contact person last name',
                recipientEmail: 'Contact person email address',
                recipientOrganizationName: 'Recipient organization name',
            },
        },
    },
    Industry: 'Industry',
    Industries: 'Industries',
    Goal: 'Goal',
    SetGoal: 'Set goal',
    EditDetails: 'Edit details',
    EditContent: 'Edit content',
    Event: 'Event',
    TagMultiple: 'Tag multiple:',
    TagMultipleTooltip: 'Mark this to quickly tag multiple objects to this category',
    WithAttachedInformation: 'Attached information exists',
    WithoutAttachedInformation: 'Attached information missing',
    FinishedGoals: 'Goals finished',
    UnfinishedGoals: 'Goals remaining',
    MailSetting: 'Settings for mail sending',
    MailSettings: 'Settings for mail sending',
    MailSetting_DA: 'Settings for mail sending',
    MailSetting_IA: 'Settings for mail sending',
    Uncategorize: 'Uncategorize',
    AttemptedTo: 'Attempted to',
    fulfillmentStates: {
        assessmentNeeded: 'Assessment needed',
        assessmentPending: 'Assessment pending',
        awaitingAssessment: 'Awaiting assessment',
        reportingNeeded: 'Should be reported again',
        toBeReported: 'Should be reported',
        expired: 'Data expired',
        certificateExpired: 'Certificate expired',
        partiallyFulfilled: 'Partially fulfilled',
        answered: 'Answered',
        notAnswered: 'Not answered',
        invitationNotSent: 'Invitation pending',
        invitationSent: 'Invitation sent',
        deadlineSoon: 'Deadline soon',
    },
    Materiality: 'Materiality',
    Deviation: 'Deviation',
    TemporaryPassword: 'Temporary password',
    Sum: 'Sum',
    Average: 'Average',
    Count: 'Count',
    Embed_Verb: 'Embed',
    FulfillmentStatus: 'Fulfillment status',
    SelectedOption: 'Selected option',
    'modules.emptyState': {
        genericHeader: 'There is nothing to show here',
        genericDescription: '',
        genericFilteringHeader: 'Nothing match your filtering',
        genericFilteringDescription: 'Try another filtering option',
    },
    'modules.measure': {
        validationMessage: 'You have to enter numbers',
        extractionSuccessMessage: 'This was found in the entered value:',
        extractionExactMessage: '',
        useExtractedAsValue: 'Use this as value',
        decimalSymbolInput: 'You can use both comma (,) and period (.)\nas decimal mark',
        decimalSymbolOutput: 'Decimal mark in the preview is {{decimalsymbol}}',
        notAvailableInfo: 'Indicate that there is no data available for this period by checking N/A',
        deviationLabel: 'Deviation from previous period:',
    },
    ContactPersons: 'Contact Persons',
    'modules.questionAnswerTypes': {
        accredited: 'Accredited',
        compliant: 'Compliant',
        notCompliant: 'Not compliant',
        registered: 'Registered',
        notRegistered: 'Not registered',
        agree: 'Agree',
        doNotAgree: 'Do not agree',
        partlyCompliant: 'Partly compliant',
        approved: 'Approved',
        notApproved: 'Not approved',
        noTime: 'No time',
        notApplicable: 'Not applicable',
        checked: 'Checked',
        notChecked: 'Not checked',
        partly: 'Partly',
        noInfoAvailable: 'No information available',
        dontKnow: 'Don\'t know',
        certified: 'Certified',
        lowRisk: 'Low risk',
        mediumRisk: 'Medium risk',
        highRisk: 'High risk',
        inProgress: 'In progress',
    },
    and: 'and',
    glnInputLabelDescription: 'GLN number for registered unit (If GLN number, i.e. in accordance with GS1, is missing - please leave the field blank)',
    mathOperations: {
        addition: 'Addition',
        subtraction: 'Subtraction',
        multiplication: 'Multiplication',
        division: 'Division',
        add: 'Add',
        subtract: 'Subtract',
        multiply: 'Multiply',
        divide: 'Divide',
        multiplyBy: 'Multiply by',
        divideBy: 'Divide by',
        replaceWith: 'Replace with',
    },
    missingSourceValuePolicy: {
        invalidatePeriod: 'Invalidate Period',
        useZeroAndContinue: 'Use Zero',
        ignoreStepAndContinue: 'Ignore Step',
        useCustomValueAndContinue: 'Use Custom Value',
        useNextStep: 'Use Next Step',
    },
    dataSource: {
        reportedData: 'Reported Data',
        internalData: 'Internal Data',
        constantData: 'Constant Data',
    },
    calculationGrouping: {
        local: 'Local',
        global: 'Total',
    },
    Person: 'Person',
    People: 'People',
    Person_IA: 'A person',
    Person_DA: 'The person',
    confirmDeleteModal: {
        content: {
            title: 'Delete',
            message: 'Do you want to permanently delete this?',
        },
        dataRelation: {
            title: 'Remove from here',
            message: 'Do you want to remove this from here?',
            messageWithObjectType: 'Do you want to remove the {{objtype}} from here?',
        },
    },
    AnalyzeRequirement: 'Analysis requirement',
    AnalyzeRequirements: 'Analysis requirements',
    AnalyzeRequirement_IA: 'An analysis requirement',
    AnalyzeRequirement_DA: 'The analysis requirement',
    AnalyzeJob: 'Analysis job',
    AnalyzeJobs: 'Analysis jobs',
    AnalyzeJob_IA: 'An analysis job',
    AnalyzeJob_DA: 'The analysis job',
    MinimumValue: 'Lowest value',
    MaximumValue: 'Highest value',
    'modules.relativeMeasure': {
        editor: {
            header: 'Calculation steps for relative measure',
            addButtonCaption: 'Add calculation step',
            changeButtonCaption: 'Change calculation steps',
            emptyStateHeader: 'You haven\'t added any calculation steps',
            emptyStateDescription: 'Click on <b>Add calculation step</b> above',
            dragHandleInfo: 'Drag and drop to reorder',
            forItem: 'For relative measure:',
            verificationMessage: 'Calculation steps not configured correctly',
            relativeMeasureStep: 'calculation step',
            measurePicker: {
                title: 'Select a measure or relative measure for the',
                selfReferenceAlert: {
                    title: 'Self-reference not allowed',
                    message: 'The relative measure currently being edited cannot be selected as a source measure inside itself'
                }
            },
            distributeOnYear: {
                checkbox: 'Match with year',
                info: 'If this measure contains data per year but is still to be calculated with other measures that have data within the same year'
            },
            fallbackStepInfo: {
                title: 'Conditional step:',
                message: 'Will only calculate when a value is not found on the previous step'
            }
        },
        steps: {
            columns: {
                measure: {
                    name: 'Measure',
                    info: 'Choose a measure',
                    selectButtonCaption: 'Choose measure',
                },
                operation: {
                    name: 'Operation',
                    info: 'Choose a mathematical operation',
                },
                policy: {
                    name: 'Policy if value is missing',
                    info: 'The policy applied when data is not found',
                },
                dataSource: {
                    name: 'Data source to use',
                    info: 'A data source filter which is applied to relative measure steps',
                },
                customValue: {
                    name: 'Custom value',
                    info: 'Choose a number to be used in the calculation instead of a measure or as a fallback value',
                },
            },
        },
        preview: {
            operationTooltipText: 'Please choose an operation above',
            measureTooltipText: 'Please choose a measure above',
        },
    },
    'modules.measureTargets': {
        addTargets: 'Add targets',
        includesTargets: 'Includes targets',
        editor: {
            header: 'Associate with targets',
            forItem: 'For measure:',
            addButtonCaption: 'Add target',
            emptyStateHeader: 'You haven\'t associated this measure with any targets',
            emptyStateDescription: 'Click on <b>Add target</b> above to do so',
            verificationMessage: 'Select measure and data source for all targets',
            columns: {
                measure: {
                    name: 'Target',
                    info: 'Choose a measure to be a target of this item',
                    selectButtonCaption: 'Choose measure',
                },
                dataSource: {
                    name: 'Data source',
                    info: 'Choose the data source of the measure to use as a target',
                },
            },
            measureSelector: {
                title: 'Select a target for',
            },
        },
    },
    'modules.analyze': {
        package: {
            emptyStateHeader: 'This Analysis package is empty',
            emptyStateDescription: 'Click <b>Add</b> above to start adding content',
        },
        results: {
            totalScore: 'Total score',
            detailsHeader: 'Calculation steps',
            detailsCalcDateInfo: 'At the time of the latest calculation',
            analysisFor: 'Analysis for:',
            scoreBeforeCalc: 'Score before these calculations',
            scoreAfterCalc: 'Score after these calculations',
            scoreDifference: 'Difference',
            previousResults: 'Previous results',
            valueFromObjectData_tooltip_measure: '<strong>{{value_and_unit}}</strong><br />The value comes from reported data',
        },
        requirements: {
            calculationSteps: 'Calculation steps',
            showMore: 'Show {{count}} additional steps',
            showLess: 'Show less',
            editButton: 'Edit calculation steps',
            overrideItemTicket: {
                label: 'Source',
                options: {
                    default: 'Reported data',
                    internalDataOnTargetOrganization: 'Own internal data on the organization',
                },
            },
            editor: {
                header: 'Calculation steps for analysis',
                addButtonCaption: 'Add calculation step',
                changeButtonCaption: 'Change calculation steps',
                viewButtonCaption: 'View calculation steps',
                emptyStateHeader: 'You haven\'t added any calculation steps',
                emptyStateDescription: 'Click on <b>Add calculation step</b> above',
                dragHandleInfo: 'Drag and drop to reorder',
                forItem: 'For item:',
            },
            columns: {
                matchesRequirement: {
                    name: 'Matches requirement',
                    info: 'The requirement that needs to be matched for a calculation step to occur',
                },
                negateRequirement: {
                    name: 'Opposite',
                    info: 'If the opposite of the requirement needs be matched for a calculation step to occur',
                },
                operation: {
                    name: 'Operation',
                    info: 'Choose any mathematical operation or \'Replace with\' to replace with a new value',
                },
                points: {
                    name: 'Points',
                    info: '',
                },
                pointsOrAssumeValue: {
                    name: 'Points or use reported value',
                    info: '',
                    checkbox_tooltip: 'Check here if the reported value should be used in the calculation instead of points',
                },
                calculationGrouping: {
                    name: 'I/O',
                    info: 'The value that will be used in the calculation step',
                },
                terminal: {
                    name: 'Break',
                    info: 'If the requirement matches and Break is checked it will skip any following calculation steps on this item',
                },
                resultTask: {
                    name: 'Additional task',
                    info: 'Additional task that should be perfomed if the requirement is matched',
                },
            },
        },
        additionalResultTask: {
            notDefined: 'None',
            writeLocalLabel: 'Set label',
            writeGlobalLabel: 'Set label on final result',
            writeLocalAndGlobalLabel: 'Set label on item and final result',
            categorizeTargetOrganization: 'Categorize target organization',
            uncategorizeTargetOrganization: 'Uncategorize target organization',
            labelText: 'Label text',
            createMeasureAnswer: 'Add the result on a measure',
            createInfluences: 'Add requests on organization',
            deleteInfluences: 'Delete requests on organization',
            influences: {
                limitOnCategories: 'If organization is in any of these categories',
                limitOnInvertedCategories: 'If organization is not in any of these categories',
            },
        },
    },
    DateAdded: 'Date added',
    calendarFrequency: {
        yearly: 'Yearly',
        halfYearly: 'Half-yearly',
        quarterly: 'Quarterly',
        monthly: 'Monthly',
        custom: 'Custom period',
        halfYear: 'Half-year',
        quarter: 'Quarter',
        month: 'Month',
    },
    measurePeriod: {
        customIntervals: {
            header: 'Custom date intervals',
            info: 'Add one or more intervals and select their start and end date.\nYear is calculated automatically.',
            addIntervalButton: 'Add interval',
            previewHeader: 'Preview',
            previewInfo: 'The resulting intervals that will be available to choose from when measurable data is added',
            columns: {
                startDate: 'Start date',
                endDate: 'End date',
                name: {
                    header: 'Name',
                    info: 'Optional interval name.<br />Available parameters:'
					+ '<br />&#123;&#123;startDate&#125;&#125; - in format YYYY-MM-DD'
					+ '<br />&#123;&#123;startYear&#125;&#125;'
					+ '<br />&#123;&#123;startMonth&#125;&#125;'
					+ '<br />&#123;&#123;startMonthName&#125;&#125;'
					+ '<br />&#123;&#123;startDay&#125;&#125;'
					+ '<br />&#123;&#123;endDate&#125;&#125; - in format YYYY-MM-DD'
					+ '<br />&#123;&#123;endYear&#125;&#125;'
					+ '<br />&#123;&#123;endMonth&#125;&#125;'
					+ '<br />&#123;&#123;endMonthName&#125;&#125;'
					+ '<br />&#123;&#123;endDay&#125;&#125;',
                },
            },
        },
        intervalsMissingModal: {
            title: '',
            message: 'At least one interval have to be specified.',
        },
    },
    errorMessages: {
        reloadButtonCaption: 'Reload the page',
        serverError: {
            title: 'Whoops, something went wrong on our end',
            message: 'We\'re very sorry about this, please try reloading the page.',
        },
    },
    Group: 'Group',
    Groups: 'Groups',
    PortfolioCompany: 'Portfolio Company',
    PortfolioCompanies: 'Portfolio Companies',
    PortfolioCompany_IA: 'A portfolio company',
    PortfolioCompany_DA: 'The portfolio company',
    Categories: 'Categories',
    'modules.hierarchical': {
        limitedView: 'Limited view',
        fullView: 'Full view',
        dropdownActions: {
            extendWithOwnItems: 'Extend with your own objects',
        },
        createUniqueReport: 'Create unique report',
    },
    Depth: 'Depth',
    MeasurementPeriod: 'Measurement period',
    'modules.materialityAnalysis': {
        Menu_MaterialityAnalysis: 'Materiality Analysis',
        MaterialityAnalysis: 'Relevance analysis',
        MaterialityAnalysis_split: 'Relevance analysis',
        griHeader: 'Companies like yours are reporting on these sustainability areas',
        griHeaderWithOrgName: 'Companies like {{orgname}} are reporting on these sustainability areas',
        sdgHeader: 'Working with the <strong>above sustainability areas</strong> directly contributes to the following <strong class=\'sdg\'>Sustainable Development Goals</strong>',
        sdgDescription: 'Showing the top 5 goals that matches the most reported GRI sustainability areas in your industry. Hover each goal to view specific targets within that goal.',
        guidanceExplanationText: 'Guidance from GRI for reporting on the area',
        moreInsights: 'Want more insights? Get in touch!',
        emptyStateText: 'Sorry, there is not enough data on your industry yet.',
        emptyStateDescription: 'Come back later, or select another industry by editing your profile settings below.',
        columns: {
            disclosure: {
                name: 'Sustainability area',
                info: 'Companies like yours report on the below sustainability areas based on GRI Sustainability Reporting Standards. The areas are distributed between GRI’s three main chapters: Economic, Social and Environmental, visualized in three different colors. Both the name of the sustainability area and its GRI disclosure code are shown below.',
            },
            match: {
                name: 'Match',
                info: 'The percentage tells you how many percent of companies in your industry report on the sustainability area, based on the companies included in our analysis. All companies in the analysis report based on GRI Standards.',
            },
            sdg: {
                name: 'Related goals',
                info: 'The Sustainable Development Goals that match the sustainability area in GRI. By working with the sustainability areas you contribute to these goals. The match is based on the official linkage developed by GRI, UN Global Compact and WBCSD.',
            },
            learnMore: {
                name: 'Learn more',
                info: 'Read up more on GRI’s guidance for reporting on each respective area.',
            },
            addToScope: {
                name: 'Is it in your scope?',
                info: 'What is scope?',
            },
        },
        loaderBar: {
            text: 'Matching sustainability work of companies in your industry',
            textVariant: 'Matching sustainability work of similar companies',
            editProfile: 'Edit your profile',
        },
        scopeButton: {
            addToScope: 'Add to scope',
            removeFromScope: 'Remove from scope',
            addedToScope: 'Added to scope',
        },
    },
    'modules.signup': {
        claimOrg: {
            pageHeader: {
                title: 'You are almost there <span>{{userName}}</span>',
                description1: 'You are about to receive a <stron>free</stron> sustainability analysis based on your organization profile.',
                description2: '<strong>We just need a little more information about your organization.</strong>',
            },
            stepsIndicator: {
                step1: 'Sign up',
                step2: 'Information',
                step3: 'Insights',
            },
            form: {
                title: 'Tell us more about your company',
            },
        },
        landingPage: {
            descriptionSteps: {
                firstStep: {
                    text: 'Sustainability reports are gathered from numerous companies',
                    info: 'Current companies: <span class=\'number-of-companies\'>{{numberOfCompanies}}</span><i class=\'fa fa-info-circle\'></i>',
                    tooltipText: 'The analysis is based on sustainability reports from 300+ companies, covering 30000+ data points. Companies included have more than 250 employees, represent various industries, and use GRI Standards as reporting framework. Both nordic and international companies are represented. New data is frequently added to enrich the analysis.',
                },
                secondStep: {
                    text: 'The Reports are analyzed and mapped to GRI',
                    info: 'What is GRI?',
                },
                thirdStep: {
                    text: 'The results are mapped to the Sustainable Development Goals',
                    info: 'Learn more about the SDG\'s',
                },
                fourthStep: {
                    text: 'The results are shown below and are based on similar companies like yours',
                    info: '',
                },
            },
            pageHeader: {
                title: 'Welcome <span>{{userName}}</span>',
                description: 'We\'ve found areas that are relevant for your organization based on sustainability reports presented by companies in your industry',
            },
            invite: 'Invite colleagues',
            footer: {
                madeWithLove: 'Made with <i class=\'fa fa-heart\'></i> in Stockholm, Sweden',
            },
        },
    },
    ShowMore: 'Show more',
    Finding: 'Finding',
    Findings: 'Findings',
    Finding_IA: 'A finding',
    Finding_DA: 'The finding',
    FindingStepAnswer: 'Finding answer',
    FindingStepAnswers: 'Finding answers',
    FindingStepAnswer_IA: 'A finding answer',
    FindingStepAnswer_DA: 'The finding answer',
    'modules.findings': {
        severity: 'Severity',
        status: 'Status',
        openedAt: 'Issue open date',
        closedAt: 'Closed date',
        deadlineAt: 'Deadline',
        lockedFinding: {
            title: 'Locked',
            description: 'Not visible to reporting organization',
        },
        severities: {
            observation: 'Observation',
            minor: 'Minor',
            major: 'Major',
            critical: 'Critical',
            zeroTolerance: 'Zero Tolerance',
        },
        actions: {
            completed: '-',
            toBeAssessed: 'Needs to be assessed by {{organizationName}}',
            toBeReported: 'Needs to be reported by {{organizationName}}',
            toBeAssessedNoOrg: 'Needs to be assessed',
            toBeReportedNoOrg: 'Needs to be reported',
        },
        ownerAndTarget: {
            owner: 'Owner',
            targetCompletionDate: 'Target completion date',
            notSet: 'Not set',
            editOwnerAndTarget: 'Edit owner and target date',
            addOwnerAndTarget: 'Add owner and target date',
            form: {
                'modalTitle:': 'Select an owner and set the target completion date',
                ownerTitle: 'Owner',
                ownerButtonCaption: 'Choose an owner',
                pickerTitle: 'Colleagues',
                pickerDescription: '',
                targetCompletionDateTitle: 'Target completion date',
            },
        },
        filterLabels: {
            open: 'Open',
            closed: 'Closed',
            locked: 'Locked',
            unlocked: 'Unlocked',
            closedOnTime: 'Closed on time',
            notClosedOnTime: 'Not closed on time',
            openedAt: 'Issue opened at',
            closedAt: 'Closed at',
            deadlineAt: 'Deadline at',
        },
        statuses: {
            open: 'Open',
            closed: 'Closed',
            locked: 'Locked',
        },
        answerMessages: {
            reporter: {
                answerUpdated: 'The {{step}} has been updated and is awaiting approval',
                answerDenied: 'The {{step}} was denied by {{verifier}} and must be revised',
                answerApproved: 'The {{step}} was approved by {{verifier}}',
                answerNeeded: '',
                optionalAnswerUpdated: 'The {{step}} was updated with optional information',
            },
            verifier: {
                answerUpdated: 'The {{step}} has been updated and is awaiting approval by {{verifier}}',
                answerDenied: 'The {{step}} has been denied and is awaiting revision',
                answerApproved: 'The {{step}} was approved',
                answerNeeded: '',
                optionalAnswerUpdated: 'The {{step}} was updated with optional information',
            },
        },
        verification: {
            varifiedLabel: 'Verified as',
            commentLabel: 'Comment',
            approved: 'Approved',
            denied: 'Denied',
            verifierUser: 'Verified by',
            verifiedAt: 'Verification date',
        },
        step: {
            emptyState: {
                nothingAddedYet: 'Nothing added yet',
                cannotBeAdded: 'Cannot be added yet',
                cannotBeAddedVerificationNeeded: '{{organizationName}} cannot add information here. You need to verify active steps before they are able to reply',
                otherStepsNotCompleted: 'Information can be added here when active steps have been approved by {{organizationName}}.',
                ownerAndTargetNotSet: 'Please choose an owner and a target completion date',
            },
        },
    },
    'modules.auth': {
        signInErrorModal: {
            title: 'Something went wrong',
            message: 'We couldn\'t authenticate you right now and you need to sign in again.',
            button: 'Go to login page',
        },
        idleTimeWarning: {
            title: 'Logout warning',
            message: 'Due to inactivity you will be automatically logged out within 5 minutes. Continue using Worldfavor to stay logged in.',
            button: 'OK',
        },
        idleTimeLogout: {
            title: 'You were logged out',
            message: 'Due to 30 minutes of inactivity you were automatically logged out. To continue working you have to log in again.',
            button: 'Goto login page',
        },
    },
    'modules.manageScopeDashboard': {
        columns: {
            linkage: {
                emptyState: 'No related goals',
                gri_sdg: {
                    name: 'Related goals',
                    info: 'The Sustainable Development Goals that match the sustainability area in GRI. By working with the sustainability areas you contribute to these goals. The match is based on the official linkage developed by GRI, UN Global Compact and WBCSD.',
                },
            },
            learnMore: {
                name: 'Learn more',
                info: 'Read up more on guidance for reporting on each respective area.',
            },
        },
        panels: {
            emptyStateNoAddedInfo: 'No added information',
            emptyStateNoQuestions: 'No questions',
        },
        topAreasGuidance: {
            part1: 'The following areas are included in your scope',
            part2: 'Click to filter on a specific area',
        },
        yourOwnData: 'Your own data',
        addSubItemsHeader: 'Add items to ',
        addItemsToScopeHeader: 'Add items to scope',
        setAsDefaultDataSource: 'Always open current view with this source',
        views: {
            adderModal: {
                header: 'Change view',
                description: 'To manage Custom Frameworks go to the <a href=\'/rel/446023\'>Custom Frameworks page</a>.',
            },
        },
        relativeMeasureCache: {
            information: 'This dashboard contains calculations with saved results.\n\nYou can trigger all calculations to run again by clearing saved results from here, or calculate them individually from each chart.',
            clearResultsButton: 'Clear saved results',
            onlyThisDashboardInfo: 'Only affects calculations in dashboard:',
            modalHeader: 'Calculation results cache',
            clearingSavedResults: 'Clearing saved results...',
            success: 'Saved results cleared successfully. Please reload the dashboard.',
            error: 'Could not clear saved results, please try again.',
            reloadButtonCaption: 'Reload dashboard'
        }
    },
    'modules.solutionSwitcher': {
        headerText: 'Select a solution',
        footer: {
            additionalSolutions: 'Explore additional solutions',
        },
    },
    Required: 'Required',
    Optional: 'Optional',
    'modules.visualization': {
        download: {
            downloadingMessage: 'Generating image, please wait...',
        },
        chartTypes: {
            line: 'Line',
            bar: 'Bar',
            pie: 'Pie',
            donut: 'Donut',
            stackedBar: 'Stacked Bar',
        },
        chartist: {
            emptyState: 'No data yet',
            notEnoughData: 'Not enough data to show meaningful graph',
            barOverlapingMessage: 'Too much data or not enough space to render the chart',
            allValuesAreZeros: 'All values are zeros',
            period: 'Period',
            singleValue: 'Single value',
            saveButtonTooltipMessages: {
                selectedChartType: 'Selected chart setting will appear for this item',
                savedChartType: 'Selected chart settings saved for this item',
                clickToSaveChartType: 'Click to save chart settings for this item',
            },
            downloadButtonTooltipMessage: 'Download the visualization as an image',
        },
        emptyStateHeader: 'No reported data',
        errorMessage: {
            standardTitle: 'An unexpected error occured when loading the data',
            relativeMeasureSelfReferenceErrorTitle: 'Calculation not allowed - A relative measure is referencing itself: ',
            relativeMeasureWithoutStepsErrorTitle: 'Calculation not possible - A relative measure has no calculation steps: ',
            relativeMeasureStepWithoutCustomValueErrorTitle: 'Calculation not possible - A calculation step is missing a Custom Value: ',
            relativeMeasureOrganizationsMissingInCacheErrorTitle: 'Recalculation needed - One or more organizations are missing in the last calculated result',
        },
        aggregateOptions: {
            combine: 'Combine organizations',
            combine_short: 'Combine org.',
            separateOrganizations: 'Separate organizations',
            separateOrganizations_short: 'Separate org.',
            aggregateYearly: 'Combine years',
            combineFrequency: 'Combine frequency',
            separateRelativeMeasureSources: 'Calc. sources',
            separateRelativeMeasureSourcesDescription: 'Splits up the data according to the calculation sources that the relative measure contains',
        },
        conversionOptions: {
            asReported: 'Reported',
            converted: 'Converted',
        },
        averageToggle: {
            label: 'Average',
            tooltip: 'Show or hide average values in the chart',
        },
        targetsToggle: {
            label: 'Targets',
            tooltip: 'Show or hide targets in the chart',
        },
        yearFilter: {
            label: 'Years',
            all: 'All',
            some: 'Some',
        },
        calcQueue: {
            calculating: 'Calculating',
            calculatingAndSavingResult: 'Calculating and saving result',
            queued: 'Queued',
        },
        cache: {
            info: {
                lastCalculated: 'Last calculated',
                for: 'for',
                seconds: 'seconds',
            },
            recalculate: 'Calculate now',
            errorDuringClearing: 'The saved calculation result could not be cleared',
        },
        fiscalYear: {
            label: 'Fiscal year offset is active',
            tooltipText: 'Data periods have been offset to aggregate to the fiscal year',
        },
    },
    ProductService: 'Product or service',
    ProductServices: 'Products and services',
    ProductService_IA: 'A product/service',
    ProductService_DA: 'The product/service',
    EAN_withExpansion: 'EAN (International Article Number)',
    UPC_withExpansion: 'UPC (Universal Product Code)',
    ISBN_withExpansion: 'ISBN (International Standard Book Number)',
    ConsistsOf: 'Consists of',
    OwnershipOrganization: 'From organization',
    'modules.productService': {
        consistsOf: {
            pickerModal: {
                title: 'Consists of',
                description: 'Specify what other products/services it consists of',
                customDescription: 'Specify what {{information}} it consists of',
            },
        },
        ownershipOrganization: {
            pickerModal: {
                title: 'From which organization?',
                customTitle: 'From which {{information}}?',
            },
        },
    },
    Locked: 'Locked',
    calculationSchedule: 'Calculation schedule',
    'modules.jobs': {
        state: {
            failed: 'Failed',
            aborted: 'Aborted',
            queued: 'Queued',
        },
        batchCalculateAnalyzeJobs: {
            anlyzeJobsCount: 'Analyzes',
            precalculationMessage: 'Recalculation on analyze package: {{title}}',
            calculationDetails: '<strong>{{count}}</strong> analyses of <strong>{{total}}</strong> calculated.',
        },
        batchCalculateInfluences: {
            precalculationMessage: 'Recalculation of requests for requirement package: {{title}}',
            calculationDetails: '<strong>{{count}}</strong> requests of <strong>{{total}}</strong> calculated.',
            unfulfilledCalculationResultPolicy: {
                doNothing: 'Nothing',
                setDueDateFromNow: 'Set new due date {{days}} days from now (only if existing due date is sooner)',
            },
        },
        batchCalculate: {
            recalcReqPackageButton: 'Recalculate...',
            cancelRecalcReqPackageButton: 'Cancel...',
            cancelRecalcConfirmation: {
                title: 'Cancel calculation',
                message: 'Are you sure that you want to cancel the calculation?',
            },
            calculationPending: 'Recalculation is about to start',
            latestCalc: 'Latest recalculation',
            recalculating: 'Recalculating since',
            calculationQueued: 'Queued since',
            calculationQueuedInfo: 'This recalculation is queued and will start when previous calculations have completed. Requirement packages and analyze packages share the same queue.',
            duration: 'Duration',
            calculationDetailsError: 'There were <strong>{{count}}</strong> errors.',
            form: {
                noOrganizationsTitle: 'No organizations',
                noOrganizationsMessage: 'The selected categories doesn\'t match any organizations.',
                unfulfilledCalculationResultPolicy: {
                    label: 'What should happen when a request changes from fulfilled to unfulfilled?',
                },
                limitByCategories: {
                    label: 'Select categories to limit calculation. If none is selected the calculation will be done on all organizations with this requirement package.',
                    selectedCategories: 'Selected categories:',
                    organizationCount: 'Number of organizations:',
                },
            },
        },
        batchManageInfluences: {
            delete: {
                openModal: 'Delete existing requests',
                modalHeader: 'Delete all existing requests on multiple organizations at once',
                modalHeaderWithTitle: 'Delete existing requests on multiple organizations at once with requirement package: {{title}}',
                postActionMessage: {
                    title: 'Finished deleting requests',
                    titleWithTitle: 'Finished deleting requests with requirement package:\n{{title}}',
                    message: '\nNumber of deleted requests: <b>{{deleted}}</b>',
                },
            },
            openCreatorModal: 'Add to organizations',
            openUpdaterModal: 'Update existing requests',
            creatorModalHeader: 'Add requests on multiple organizations at once with requirement package: {{title}}',
            creatorModalInformation: 'Organizations that already have a request with this requirement package will be ignored.',
            updaterModalHeader: 'Update all existing requests on multiple organizations at once',
            updaterModalHeaderWithTitle: 'Update existing requests on multiple organizations at once with requirement package: {{title}}',
            limitByCategories: {
                label: 'Select categories to limit to specific organizations',
                selectedCategories: 'Selected categories:',
                organizationCount: 'Number of organizations:',
            },
            postCreateMessage: {
                title: 'Finished creating requests with requirement package:\n{{title}}',
                message: '\nNumber of created requests: <b>{{created}}</b>\n\nOrganizations that already had a request: <b>{{existed}}</b>',
            },
            postUpdateMessage: {
                title: 'Finished updating requests',
                titleWithTitle: 'Finished updating requests with requirement package:\n{{title}}',
                message: '\nNumber of updated requests: <b>{{updated}}</b>',
            },
        },
        batchManageAnalyzeJobs: {
            delete: {
                openModal: 'Delete existing analyses',
                modalHeader: 'Delete all existing analyses on multiple organizations at once',
                modalHeaderWithTitle: 'Delete existing analyses on multiple organizations at once with analyze package: {{title}}',
                postActionMessage: {
                    title: 'Finished deleting analyses',
                    titleWithTitle: 'Finished deleting analyses with analyze package:\n{{title}}',
                    message: '\nNumber of deleted analyses: <b>{{deleted}}</b>',
                },
            },
            openCreatorModal: 'Add to organizations',
            creatorModalHeader: 'Add analyses on multiple organizations at once with analyze package: {{title}}',
            creatorModalInformation: 'Organizations that already have a analysis with this analyze package will be ignored.',
            limitByCategories: {
                label: 'Select categories to limit to specific organizations',
                selectedCategories: 'Selected categories:',
                organizationCount: 'Number of organizations:',
            },
            postCreateMessage: {
                title: 'Finished creating analyses with analyze package:\n{{title}}',
                message: '\nNumber of created analyses: <b>{{created}}</b>\n\nOrganizations that already had an analysis: <b>{{existed}}</b>',
            },
        },
    },
    Start: 'Start',
    ReportingPeriod: 'Reporting period',
    Suggested: 'Suggested',
    InternalData: 'Internal data',
    ReportedData: 'Reported data',
    SelectDate: 'Select a date',
    'modules.requirement': {
        requirementDetailsHeader: 'The following information is required:',
        fulfillmentStatusLabel: 'Fulfillment status',
        informationToAttachLabel: 'Information that can be attached',
        requirementsLeftToComplete: 'items left to complete',
        completed: 'completed',
        savingFulfillment: 'Saving fulfillment',
        fulfillmentSaved: 'Fulfillment saved',
    },
    GLNNumber: 'GLN number',
    ShouldAttachInformation: 'You should attach additional information',
    Tab: 'Tab',
    Attachment: 'Attachment',
    Attachments: 'Attachments',
    ReportMode: 'Report mode',
    AssignedTo: 'Assigned to',
    Comments: 'Comments',
    Verify: 'Verify',
    Action: 'Action',
    Actors: 'Actors',
    YearSum: 'Year sum',
    InfoAdder: {
        singular: 'Information attacher',
        plural: 'Information  attacher',
        addButton: 'Create information attacher',
    },
    EmissionFactors: 'Emission factors',
    StandardMeasures: 'Standard measures',
    StandardRelativeMeasures: 'Standard calculations',
}
