import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('HierarchicalEditorController', HierarchicalEditorController)

    HierarchicalEditorController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$injector', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService']
    function HierarchicalEditorController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $injector, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService) {
        let
            vm = this

        let influenceToOrganizationId

        let forSpecificOrganization

        let objectId

        let networkId

        const translate = $translate.instant

        let ticket

        const optionForEmptyRequirement = { // Special option that will override the default with a blank requirement
            name: $translate.instant('modules.valueChain.requirements.EmptyRequirement'),
            rule: 19,
            value: null,
        }

        vm = _.assign(vm, {
            uiMode: enums.uiMode.admin,
            window: $window,
            loadRequirements: false,
            loadAnalyzeRequirements: false,
            loadDepth: 10,
            transcludeItemSide: undefined,
            adderDropdownActions: {
                category: true,
                question: true,
                measure: true,
                relativeMeasure: true,
                taskStructure: true,
                utilityStructure: false, // Only for Analyze feature
            },
            emptyState: {
                header: 'modules.manage.ownStructures.editor.emptyStateHeader',
                description: 'modules.manage.ownStructures.editor.emptyStateDescription',
            },
            dragControlListeners: {
                accept(sourceItemHandleScope, destSortableScope) {
                    return destSortableScope.modelValue.includes(sourceItemHandleScope.itemScope.item)
                },
                orderChanged() {
                    reorder(vm.childs)
                },
            },

            // Functions
            addCategory,
            addQuestion,
            addMeasure,
            addRelativeMeasure,
            addTaskStructure,
            addUtilityStructure,
            reorder,
            pickAttachedInformation,
        })

        activate()

        ////////////////

        function activate() {
            if ($scope.structureIdFromDirective) {
                objectId = $scope.structureIdFromDirective
                vm.transcludeItemSide = $scope.transcludeItemSide
                ticket = $scope.ticket

                _.assign(vm, $scope.configFromAttribute)
                _.assign(vm.adderDropdownActions, $scope.adderDropdownActions)
            }
            else if ($injector.has('$stateParams')) {
                $injector.invoke(['$stateParams', function ($stateParams) {
                    objectId = $stateParams.objectId
                    // networkId = $stateParams.networkId;
                }])
            }

            if ($injector.has('$uibModalInstance')) {
                // $injector.invoke(["$uibModalInstance", function ($uibModalInstance) {
                // 	objectId = $stateParams.objectId
                // 	console.log('$uibModalInstance:', $uibModalInstance);
                // }]);
            }

            switch (vm.uiMode) {
                case enums.uiMode.admin:
                    vm.isAdminMode = true
                    break
                case enums.uiMode.view:
                    vm.isViewMode = true
                    break
            }

            dataOps.getObject({
                objectId,
                objectType: enums.objectType.structure,
                childrenLoadDepth: vm.loadDepth,
                getterConditions: {
                    dataRelationOrganizationMatchMode: 2,
                    loadRequirements: vm.loadRequirements,
                    loadAnalyzeRequirements: vm.loadAnalyzeRequirements,
                },
                ticket,
            }).then((res) => {
                vm.mainStructure = res

                $rootScope.setPageTitle(vm.mainStructure.title)

                vm.header = _.assign({
                    title: vm.mainStructure.title,
                    description: vm.mainStructure.description,
                    guidance: vm.mainStructure.guidance,
                    guidanceVideoUrl: vm.mainStructure.guidanceVideoUrl,
                }, $scope.header)

                // dataOps.getSubItems(vm.mainStructure, enums.subItemsKind.relatedContent, {
                // 	// ticket: {
                // 	// 	networkId: 52
                // 	// }
                // }).then(function (res) {
                // 	var flatItems;
                // 	vm.mainStructureRelatedContent = _.orderBy(res, "childContent.title");

                vm.childs = vm.mainStructure.childs

                // 	flatItems = dataQuery.getHierarchyAsList(vm.mainStructure, [ enums.objectType.structure, enums.objectType.question, enums.objectType.measure]);
                // 	dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedContent, { bypassCache: true }).then(function () {
                // 		$timeout();
                // 		vm.loaded = true;
                // 	});
                // });

                $timeout()
                vm.loaded = true

                // defineRequirementAndConditionSettings();
            })
        }

        function addCategory(category) {
            const jqDf = $.Deferred()

            modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                dataRelationOptions: { item1: category || vm.mainStructure, kind: enums.subItemsKind.children },
            }).then((dataRelation) => {
                if (dataRelation) {
                    dataOps.saveSettings({
                        item: dataRelation.childContent,
                        settings: {
                            dataRelation: null,
                        },
                    }).then(() => {
                        if (category) {
                            jqDf.resolve()
                        }
                        else {
                            vm.childs = vm.mainStructure.childs
                            reorder(vm.childs).then(() => {
                                jqDf.resolve()
                                $timeout()
                            })
                        }
                    })
                }
                else {
                    jqDf.resolve()
                }
            })

            return jqDf.promise()
        }

        function addUtilityStructure(category) {
            const jqDf = $.Deferred()

            modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                dataRelationOptions: { item1: category || vm.mainStructure, kind: enums.subItemsKind.children },
            }).then((dataRelation) => {
                if (dataRelation) {
                    if (category) {
                        jqDf.resolve()
                    }
                    else {
                        vm.childs = vm.mainStructure.childs
                        reorder(vm.childs).then(() => {
                            jqDf.resolve()
                            $timeout()
                        })
                    }
                }
                else {
                    jqDf.resolve()
                }
            })

            return jqDf.promise()
        }

        // Creates a structure but instead of a category it is a task to add more information during reporting
        function addTaskStructure_OLD(category) {
            const jqDf = $.Deferred()

            modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                dataRelationOptions: { item1: category || vm.mainStructure, kind: enums.subItemsKind.children },
            }).then((dataRelation) => {
                if (dataRelation) {
                    dataOps.saveSettings({
                        item: dataRelation.childContent,
                        settings: {
                            objectTypes: '44',
                            dataRelationByUser: true,
                            dataRelation: null,
                        },
                    }).then(() => {
                        if (category) {
                            jqDf.resolve()
                        }
                        else {
                            vm.childs = vm.mainStructure.childs
                            reorder(vm.childs).then(() => {
                                jqDf.resolve()
                                $timeout()
                            })
                        }
                    })
                }
                else {
                    jqDf.resolve()
                }
            })

            return jqDf.promise()
        }

        function addTaskStructure(category) {
            const promise = modal.openCreatorAndPicker({
                relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                objectTypes: [enums.objectType.structure],
                // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
            })

            if (category) {
                return promise
            }
            else {
                promise.modal.closed.then(() => {
                    vm.childs = vm.mainStructure.childs
                    reorder(vm.childs).then(() => {
                        $timeout()
                    })

                })
            }
        }

        function addQuestion(category) {
            const promise = modal.openCreatorAndPicker({
                relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                objectTypes: [enums.objectType.question],
                // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
            })

            if (category) {
                return promise
            }
            else {
                promise.modal.closed.then(() => {
                    vm.childs = vm.mainStructure.childs
                    reorder(vm.childs).then(() => {
                        $timeout()
                    })

                })
            }
        }

        function addMeasure(category) {
            const promise = modal.openCreatorAndPicker({
                relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                objectTypes: [enums.objectType.measure],
                // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
            })

            if (category) {
                return promise
            }
            else {
                promise.modal.closed.then(() => {
                    vm.childs = vm.mainStructure.childs
                    reorder(vm.childs).then(() => {
                        $timeout()
                    })

                })
            }
        }

        function addRelativeMeasure(category) {
            const promise = modal.openCreatorAndPicker({
                relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                objectTypes: [enums.objectType.relativeMeasure],
                // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
            })

            if (category) {
                return promise
            }
            else {
                promise.modal.closed.then(() => {
                    vm.childs = vm.mainStructure.childs
                    reorder(vm.childs).then(() => {
                        $timeout()
                    })

                })
            }
        }

        function pickAttachedInformation(item, array) {
            modal.openCreatorAndPicker({
                title: $translate.instant('AddInformation'),
                objectTypes: [enums.objectType.orgDocument],
                relationTarget: { item, kind: enums.subItemsKind.relatedContent },
            }).closed(() => {
                array.length = 0
                Array.prototype.push.apply(array, _.orderBy(item.relatedContent, 'childContent.title'))
            })
        }

        function reorder(array, item, direction) {
            const
                arrayLength = array.length

            const moveUp = direction === 'up'

            const moveDown = direction === 'down'

            let currentIndex

            let old_index; let new_index; let k

            const jqDf = $.Deferred()

            const promise = jqDf.promise()

            if (!arrayLength) {
                jqDf.resolve()
                return promise
            }

            // Only reorder if a direction and item is specified
            if (item && typeof direction === 'string') {
                currentIndex = _.indexOf(array, item)

                if ((moveUp && currentIndex === 0) || (moveDown && currentIndex === arrayLength - 1)) {
                    jqDf.resolve()
                    return promise
                }

                // Logic for moving an element in an array to a new position
                old_index = currentIndex
                new_index = moveUp ? currentIndex - 1 : currentIndex + 1

                while (old_index < 0) {
                    old_index += arrayLength
                }
                while (new_index < 0) {
                    new_index += arrayLength
                }
                if (new_index >= arrayLength) {
                    k = new_index - arrayLength
                    while ((k--) + 1) {
                        arr.push(undefined)
                    }
                }

                array.splice(new_index, 0, array.splice(old_index, 1)[0])
            }

            dataOps.saveDataRelationsOrder(array).then(() => {
                jqDf.resolve()
            })

            return promise
        }

        function defineRequirementAndConditionSettings() {
            vm.mainStructureRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'), // "Inget",
                        rule: 0,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AllQuestionsFulfilled'), // "Samtliga frågor måste uppfyllas",
                        rule: 9,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AllQuestionsAnswered'), // "Alla frågor måste besvaras",
                        rule: 9,
                        value: '0',
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.questionRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'), // "Inget",
                        rule: 0,
                        value: null,
                    },
                    { ...optionForEmptyRequirement, selectable: !forSpecificOrganization },
                    {
                        name: translate('modules.valueChain.requirements.ManualAssessment'), // "Manuell bedömning",
                        rule: enums.requirementRule.manual,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer'), // "Valfritt svar",
                        rule: enums.requirementRule.anyValue,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.Yes'), // "Ja",
                        rule: enums.requirementRule.specificValues,
                        value: '4',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.No'), // "Nej",
                        rule: enums.requirementRule.specificValues,
                        value: '3',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.YesWithMoreInfo'), // "Ja med mer information",
                        rule: enums.requirementRule.specificValueWithRelatedContent,
                        value: '4',
                        selectable: false,
                    },
                    {
                        name: translate('modules.valueChain.requirements.YesOrNotRelevant'), // "Ja eller Ej relevant",
                        rule: enums.requirementRule.specificValues,
                        value: '4,2',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.NoOrNotRelevant'), // "Nej eller Ej relevant",
                        rule: enums.requirementRule.specificValues,
                        value: '3,2',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.Yes_OtherwiseCommentIsRequired'), // "Ja (Annars krävs kommentar)",
                        rule: enums.requirementRule.preferredValue,
                        value: '4',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.No_OtherwiseCommentIsRequired'), // "Nej (Annars krävs kommentar)",
                        rule: enums.requirementRule.preferredValue,
                        value: '3',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.YesOrNotRelevant_OtherwiseCommentIsRequired'), // "Ja eller Ej relevant (Annars krävs kommentar)",
                        rule: enums.requirementRule.preferredValue,
                        value: '4,2',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.NoOrNotRelevant_OtherwiseCommentIsRequired'), // "Nej eller Ej relevant (Annars krävs kommentar)",
                        rule: enums.requirementRule.preferredValue,
                        value: '3,2',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_YesRequiresMoreInfo'), // "Valfritt svar (Vid Ja krävs mer information)",
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '4',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_NoRequiresMoreInfo'), // "Valfritt svar (Vid Nej krävs mer information)",
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '3',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswerWithMoreInfo'), // "Valfritt svar med mer information",
                        rule: enums.requirementRule.anyValueWithRelatedContent,
                        value: null,
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_AccreditedRequiresMoreInfo'),
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '5',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_NotCompliantRequiresMoreInfo'),
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '7',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_NoOrPartlyRequiresMoreInfo'),
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '3,20',
                        selectable: !forSpecificOrganization,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer_YesOrPartlyRequiresMoreInfo'),
                        rule: enums.requirementRule.preferredValueWithRelatedContent,
                        value: '4,20',
                        selectable: !forSpecificOrganization,
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.questionObjectSettings = {
                uiMode: vm.uiMode,
                settings: [
                    {
                        label: translate('modules.valueChain.objectSettings.answerOptions.label'), // "Svarsalternativ",
                        settingKind: 'limitQuestionAnswerTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.answerOptions.YesNoNotRelevantAndProcessing'), // "Ja, Nej, Ej relevant och Behandlas",
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.answerOptions.YesAndNo'), // "Ja och Nej",
                                value: '4,3',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.answerOptions.YesNoAndNotRelevant'), // "Ja, Nej och Ej relevant",
                                value: '4,3,2',
                            },
                        ],
                    },
                    {
                        label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label'), // "Information som kan bifogas",
                        settingKind: 'objectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                                value: '44',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                                value: '18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                                value: '44,18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                                value: '44,106',
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.measureRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'), // "Inget",
                        rule: 0,
                        value: null,
                    },
                    { ...optionForEmptyRequirement, selectable: !forSpecificOrganization },
                    {
                        name: translate('modules.valueChain.requirements.ManualAssessment'), // "Manuell bedömning",
                        rule: enums.requirementRule.manual,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AnyAnswer'), // "Valfritt svar",
                        rule: enums.requirementRule.anyValue,
                        value: null,
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.taskStructureRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'),
                        rule: 0,
                        value: null,
                    },
                    { ...optionForEmptyRequirement, selectable: !forSpecificOrganization },
                    {
                        name: translate('modules.valueChain.requirements.ManualAssessment'), // "Manuell bedömning",
                        rule: enums.requirementRule.manual,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.MinimumOneObject'),
                        rule: enums.requirementRule.anyValue,
                        value: null,
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.taskStructureObjectSettings = {
                uiMode: vm.uiMode,
                settings: [
                    {
                        label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label'), // "Information som kan bifogas",
                        settingKind: 'objectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                                value: '44',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                                value: '18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                                value: '44,18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                                value: '44,106',
                            },
                            {
                                name: translate('Activities'),
                                value: '15',
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }
        }
    }
})()
