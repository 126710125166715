// @flow
import React, { useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InsertPhoto from '@material-ui/icons/InsertPhoto'
import Clear from '@material-ui/icons/Clear'
import classNames from 'classnames'

export const ImageMIMEType = {
    '*': 'image/*',
    png: 'image/png',
    jpeg: '.jpeg',
    jpg: '.jpg',
    gif: 'image/gif',
    svg: '.svg',
    tiff: '.tiff',
    webp: 'image/webp',
    ico: 'image/x-icon',
    dib: '.dib',
    svgz: '.svgz',
    jfif: '.jfif',
    pjpeg: '.pjpeg',
    pjp: '.pjp',
    bmp: '.bmp',
    xbm: '.xbm',
    tif: '.tif',
    'image/tiff': 'image/tiff',
    'image/jpeg': 'image/jpeg',
    'image/svg+xml': 'image/svg+xml',
}

export const StandardImageTypes = [
    ImageMIMEType.png,
    ImageMIMEType.jpeg,
    ImageMIMEType.jpg,
    ImageMIMEType.gif,
    ImageMIMEType.svg,
    ImageMIMEType.bmp,
]

const styles = theme => ({
    root: {},
    input: {
        width: 1,
        height: 1,
        opacity: 0,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: -1,
    },
    container: {
        position: 'relative',
        overflow: 'hidden',
        width: 200,
        height: 200,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        borderRadius: 8,
        backgroundColor: '#E6E6E6',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',

        '&:hover': {
            opacity: 0.8,

            '& $placeholderContainer': {
                opacity: 1,
            },
        },
    },
    placeholderContainer: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        opacity: 0,

        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    placeholder: {
        width: '25%',
        height: '25%',
    },
    clearIcon: {
        position: 'absolute',
        top: 2,
        right: 2,
        color: theme.palette.common.white,

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
        },
    },
})

// TODO blur background
const ImagePicker = ({ classes, src, supportedTypes, onFileChange, className, style }) => {
    const [imageData, setImageData] = useState(src)
    const inputRef = useRef(null)

    function handleImageChange(event) {
        const { files } = event.target
        if (files && files[0]) {
            const file = files[0]
            const extension = file.name.match(/.*?(\.[a-zA-Z]*)$/)[1]
            if (supportedTypes.includes(files[0].type) || supportedTypes.includes(extension)) {
                const url = URL.createObjectURL(file)
                setImageData(url)
                onFileChange && onFileChange(event, files, url)
            }
        }
    }

    function onImageContainerClick() {
        inputRef.current && inputRef.current.click()
    }

    function clearImage(e) {
        if (inputRef.current) {
            inputRef.current.value = null
            setImageData(null)
            onFileChange && onFileChange(null)
        }
        e.stopPropagation()
    }

    return (
        <React.Fragment>
            <input
                ref={inputRef}
                type="file"
                accept={supportedTypes.join(',')}
                capture="camera"
                className={classes.input}
                onChange={handleImageChange}
            />

            <div
                className={classNames(classes.container, className)}
                onClick={onImageContainerClick}
                style={{
                    backgroundImage: imageData ? `url(${imageData})` : null,
                    ...style,
                }}
            >
                {
                    imageData ? (
                        <React.Fragment>
                            <div className={classes.placeholderContainer}>
                                <InsertPhoto className={classes.placeholder} />
                            </div>

                            <IconButton className={classes.clearIcon} onClick={clearImage}>
                                <Clear fontSize={'small'} />
                            </IconButton>
                        </React.Fragment>
                    ) : (
                        <InsertPhoto className={classes.placeholder} />
                    )
                }
            </div>
        </React.Fragment>
    )
}

ImagePicker.defaultProps = {
    supportedTypes: StandardImageTypes,
}

export default withStyles(styles)(ImagePicker)
