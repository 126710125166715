(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('ngAnimateDisabled', ngAnimateDisabled)

    ngAnimateDisabled.$inject = ['$animate']
	
    function ngAnimateDisabled($animate) {
        const directive = {
            restrict: 'A',
            scope: false,
            link(scope, element) {
                $animate.enabled(false, element)
            },
        }
        return directive
    }
})()
