import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ValueChainAnalyzePackageEditorController', ValueChainAnalyzePackageEditorController)

    ValueChainAnalyzePackageEditorController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$injector', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService', 'requirements', '$state', 'wfPropertyExtractor']
    function ValueChainAnalyzePackageEditorController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $injector, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService, requirements, $state, wfPropertyExtractor) {
        const
            vm = this
			
        const authOrgId = wfAuth.getOrganizationId()
			
        let networkId
			
        let objectId

        activate()

        ////////////////

        function activate() {
            if ($injector.has('$stateParams')) {
                $injector.invoke(['$stateParams', function ($stateParams) {
                    $scope.structureId = objectId = $stateParams.objectId
                    networkId = $stateParams.networkId
                }])
            }

            valueChainService.loadNetworks().then((result) => {
                const
                    network = result.networksById[networkId]
					
                const mainStructure = result.structure

                if (!network) {
                    $state.go('valueChain-root')
                    return
                }

                vm.network = network

                // Passed to wfAnalyzeRequirement component
                vm.ticket = {
                    organizationId: wfAuth.getOrganizationId(),
                    networkId,
                }

                dataOps.getObject({
                    objectId,
                    objectType: enums.objectType.structure,
                    childrenLoadDepth: 0,
                }).then((res) => {
                    vm.mainStructure = res

                    $rootScope.setPageTitle(vm.mainStructure.title)
                    $scope.headerPreTitle = ['Data Collector', vm.network.title, $translate.instant('AnalyzePackages')].join(' / ')

                    vm.isPackageOwned = vm.mainStructure.creatorOrganizationId === authOrgId
                    vm.loaded = true

                    if (vm.isPackageOwned) {
                        vm.editorUiMode = enums.uiMode.admin
                    }
                    else {
                        vm.editorUiMode = enums.uiMode.view
                    }

                    vm.dataSourceSettings = {
                        uiMode: vm.editorUiMode,
                        settings: [
                            {
                                label: $translate.instant('modules.analyze.requirements.overrideItemTicket.label'),
                                settingKind: 'customTicketSettings',
                                options: [
                                    {
                                        whenNotSet: true,
                                        name: $translate.instant('modules.analyze.requirements.overrideItemTicket.options.default'),
                                    },
                                    {
                                        name: $translate.instant('modules.analyze.requirements.overrideItemTicket.options.internalDataOnTargetOrganization'),
                                        checkValue(value) {
                                            return value || value.ticketPreset === 1
                                        },
                                        setValue() {
                                            return { ticketPreset: 1 }
                                        },
                                    },
                                ],
                            },
                        ],
                    }
                })

            })
        }

    }
})()
