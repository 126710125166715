import React from 'react'
import DataManager from '@worldfavor/components/DataManager/DataManager'
import { ConstVars } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { fetchOrganizations, fetchContributors, fetchCategorizations, fetchAnalyzeJobs, fetchAnalyzeResults } from '../api/dataCollectionFetchFunctions'
import Api from '../api'
import { AnalyzeJobsTableElements, OrganizationsTableElements, ContributorsTableElements, CategorizationsTableElements, AnalyzeResultsTableElements } from '@worldfavor/components/DataManager/ContentTypeTableElements'

type Props = {
  contentType: string,
}

const DataManagerScene = (props: Props) => {
    const { contentType } = props
    const { solutionId, networkId } = useParams()

    function alanyzeJobsDialogContent(selectedItem, closeDialog) {
        const { AnalyzeJobsDialogContent } = AnalyzeJobsTableElements
        return (
            <AnalyzeJobsDialogContent
                selectedItem={selectedItem}
                closeDialog={closeDialog}
                solutionId={solutionId}
                networkId={networkId}
            />
        )
    }

    function openAnalyzeResultsInNewTab(event, item) {
        window.open(`/${solutionId}/valuechain/${networkId}/analyzeResults/${item.id}`, '_blank')
    }

    switch (contentType) {
        case ConstVars.organizations: {
            return (
                <DataManager
                    fetch={fetchOrganizations}
                    filtersEndpoint={Api.endpoints.filtersForOrganizations}
                    title={<FormattedMessage id="dataManager.contentTypes.organizations" />}
                    contentType={contentType}
                    networkId={networkId}
                    getTableColumns={OrganizationsTableElements.getTableColumns}
                    renderRowCell={OrganizationsTableElements.renderRowCell}
                    renderColumnCell={OrganizationsTableElements.renderColumnCell}
                />
            )
        }
        case ConstVars.contributors: {
            return (
                <DataManager
                    fetch={fetchContributors}
                    filtersEndpoint={Api.endpoints.filtersForContributors}
                    title={<FormattedMessage id="dataManager.contentTypes.contributors" />}
                    contentType={contentType}
                    networkId={networkId}
                    getTableColumns={ContributorsTableElements.getTableColumns}
                    renderRowCell={ContributorsTableElements.renderRowCell}
                    renderColumnCell={ContributorsTableElements.renderColumnCell}
                />
            )
        }
        case ConstVars.categorizations: {
            return (
                <DataManager
                    fetch={fetchCategorizations}
                    filtersEndpoint={Api.endpoints.filtersForCategorizations}
                    title={<FormattedMessage id="dataManager.contentTypes.categorizations" />}
                    contentType={contentType}
                    networkId={networkId}
                    getTableColumns={CategorizationsTableElements.getTableColumns}
                    renderRowCell={CategorizationsTableElements.renderRowCell}
                    renderColumnCell={CategorizationsTableElements.renderColumnCell}
                />
            )
        }
        case ConstVars.analyzeJobs: {
            return (
                <DataManager
                    fetch={fetchAnalyzeJobs}
                    filtersEndpoint={Api.endpoints.filtersForAnalyzeJobs}
                    title={<FormattedMessage id="dataManager.contentTypes.analyzeJobs" />}
                    contentType={contentType}
                    networkId={networkId}
                    dialogContent={alanyzeJobsDialogContent}
                    getTableColumns={AnalyzeJobsTableElements.getTableColumns}
                    renderRowCell={AnalyzeJobsTableElements.renderRowCell}
                    renderColumnCell={AnalyzeJobsTableElements.renderColumnCell}
                    customRowAction={openAnalyzeResultsInNewTab}
                />
            )
        }
        case ConstVars.analyzeResults: {
            return (
                <DataManager
                    fetch={fetchAnalyzeResults}
                    filtersEndpoint={Api.endpoints.filtersForAnalyzeResults}
                    title={<FormattedMessage id="dataManager.contentTypes.analyzeResults" />}
                    contentType={contentType}
                    networkId={networkId}
                    getTableColumns={AnalyzeResultsTableElements.getTableColumns}
                    renderRowCell={AnalyzeResultsTableElements.renderRowCell}
                    renderColumnCell={AnalyzeResultsTableElements.renderColumnCell}
                />
            )
        }
    }
}

export default DataManagerScene
