// @flow
import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { availableLanguages } from '@worldfavor/i18n'
import { injectIntl } from 'react-intl'

const Languages: Array<{ key: string, translationKey: string }> = (Object.values(availableLanguages): any)

const LanguageField = ({ intl, ...props }) => {
    const languages = Languages.map(x => Object.assign({
        label: intl.formatMessage({ id: x.translationKey }),
    }, x)).sort((a, b) => {
        const textA = a.label.toUpperCase()
        const textB = b.label.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })

    const topLanguages = ['en-US', 'sv-SE']

    topLanguages.reverse().forEach((langKey) => {
        const index = languages.findIndex(x => x.key === langKey)
        if (index >= 0) {
            languages.unshift(languages.splice(index, 1)[0])
        }
    })

    return (
        <TextField
            id={'language-selector'}
            label={intl.formatMessage({ id: 'general.language' })}
            {...props}
            select
        >
            {
                languages.map(({ key, label }) => (
                    <MenuItem key={key} value={key}>
                        {label}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}

export default injectIntl(LanguageField)
