import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfToggle', wfToggle)
        .directive('wfToggleElement', wfToggleElement)

    wfToggleElement.$inject = ['$window', '$compile', '$templateCache', '$http', '$translate']

    function wfToggleElement($window, $compile, $templateCache, $http, $translate) {
        const directive = {
            restrict: 'A',
            controller: ['$scope', '$element', function ($scope, $element) {
                this.togglerElement = $element
            }],
        }

        return directive
    }
	
    wfToggle.$inject = ['$window', '$compile', '$templateCache', '$http', '$translate', '$timeout', '$q', 'wfAuth']
    function wfToggle($window, $compile, $templateCache, $http, $translate, $timeout, $q, wfAuth) {
        const
            templateUrl = 'scripts/wf/common/toggle.directive.html'
			
        let templateContent

        const directive = {
            link,
            restrict: 'E',
            require: ['?^^wfToggleElement'],
            // templateUrl: "scripts/wf/common/toggle.directive.html",
            // template: function (element, attrs) {
            // // 	// console.log(1, element);
            // // 	// if (element.innerHTML)

            // 	return $http.get(templateUrl, { cache: $templateCache });
            // },
            // transclude: true,
            scope: {
                item: '=',
                relationTarget: '=',
                relationBucket: '&',
                translations: '=',
                onToggled: '&',
                onBeforeToggled: '&',
                influence: '=',
                intersectionSettings: '<intersection',
                pickerVm: '<',
            },
            controllerAs: 'vm',
            controller: ['$scope', 'wfObject', 'dataOperationsService', 'wfPropertyExtractor', 'wfAuth', wfToggleController],
            // compile: function(element, attrs) {
            // 	// console.info(element.html());
				
            // 	return {
            // 		pre: function preLink(scope, iElement, attrs, controller, transclude) {
            // 			var isTranscluded = false;
            // 			var customHtml;
            // 			transclude(function(clone){
            // 				isTranscluded = !!clone.length;
            // 				if (isTranscluded)
            // 					customHtml = clone[0].outerHTML;
            // 			});

            // 			if (isTranscluded) {
            // 				iElement.html(customHtml);
            // 				$compile(iElement.contents())(scope);
            // 			}
            // 		},
            // 		post: function preLink(scope, iElement, attrs, controller, transclude) { } 
            // 	}
            // }
        }

        return directive
			
        function link(scope, element, attr, ctrls) {
            const isTranscluded = false
            let customHtml
            const
                wfToggleElementCtrl = ctrls[0]

            // Template is preloaded in app.module.js
            // console.log(templateContent);
            // transclude(function(clone){
            // 	isTranscluded = !!clone.length;
            // 	if (isTranscluded)
            // 		customHtml = clone[0].outerHTML;

            // 	console.log(customHtml, clone)
            // });

            // if (isTranscluded) {
            // 	element.html(customHtml);
            if (!element[0].childNodes.length) {
                if (!templateContent) templateContent = $templateCache.get(templateUrl)
                element.html(templateContent)
            }
			
            if (wfToggleElementCtrl && wfToggleElementCtrl.togglerElement) {
                wfToggleElementCtrl.togglerElement.click((event) => {
                    if (event.originalEvent.dropdown) return

                    scope.toggleState(event)
                    $timeout()
                })
				
            }

            $compile(element.contents())(scope)
            // }
        }

        function wfToggleController($scope, wfObject, dataOps, wfPropertyExtractor, wfAuth) {
            const
                vm = this
				
            const item = $scope.item
				
            let relationTarget = $scope.relationTarget
				
            let setToggledState
				
            let setUntoggledState
				
            let query
				
            const subItemsKind = enums.subItemsKind
				
            const relationBucket = $scope.relationBucket ? $scope.relationBucket() : undefined
				
            const useSimple = !!relationBucket || (!relationTarget && !relationBucket)
				
            let dataRelationObjects
				
            let networkId
				
            const useContextAwareRelations = true
				
            let ticket
				
            let organizationId = wfAuth.getOrganizationId()

            if ($scope.influence && $scope.influence.channelId) {
                networkId = $scope.influence.channelId
            }
            else if ($scope.intersectionSettings) {
                networkId = $scope.intersectionSettings.networkId
            }

            if ($scope.intersectionSettings) {
                if (organizationId !== _.get($scope.intersectionSettings, 'organizationId')) {
                    ticket = {
                        organizationId: _.get($scope.intersectionSettings, 'organizationId'),
                        // networkId and contextParentWfid from the ticket is not used in WFDataSaver in backend, only organizationId
                    }

                    if (ticket.organizationId) organizationId = ticket.organizationId
                }

            }

            $scope.toggleState = toggleState
            $scope.toggleState1 = toggleState1
            $scope.toggleState2 = toggleState2
            $scope.toggler_addWord = $scope.translations && $scope.translations.addWord ? $scope.translations.addWord : $translate.instant('Add')
            $scope.toggler_toWord = $scope.translations && $scope.translations.toWord ? $scope.translations.toWord : $translate.instant('To2')
            vm.saving = false

            $scope.disableUntoggle = false
            if ($scope.pickerVm && $scope.pickerVm.disableUntoggle) {
                $scope.disableUntoggle = $scope.pickerVm.disableUntoggle
                if ($scope.pickerVm.disableUntoggleMessage) $scope.disableUntoggleMessage = $scope.pickerVm.disableUntoggleMessage
            }
			
            if (relationTarget)
            {
                if (!_.isArray(relationTarget)) relationTarget = [relationTarget]

                dataRelationObjects = []

                if (relationTarget.length === 1) {
                    $scope.$on('beforeRelationDestroyed', ($event, relation) => {
                        if (dataRelationObjects.length === 1 && relation.wfid === dataRelationObjects[0].wfid) {
                            vm.saving = true
                            $scope.toggled = false
                        }
                    })
                    $scope.$on('wfObject.destroyed', ($event, wfid, obj) => {
                        if (dataRelationObjects.length === 1 && obj.wfid === dataRelationObjects[0].wfid) {
                            dataRelationObjects.length = 0
                            vm.saving = false
                            $timeout()
                        }
                    })
                }

                _.each(relationTarget, (relationTarget) => {
                    let kind; let dataRelationObject

                    if (typeof relationTarget.item === 'string') // String implies that it is a wfid, otherwise it should be a wfObject
                        relationTarget.item = { type: parseInt(relationTarget.item.split('-')[0]), id: parseInt(relationTarget.item.split('-')[1]) }

                    if (relationTarget.settingsByItemType && relationTarget.settingsByItemType[item.type] && relationTarget.settingsByItemType[item.type].kind) kind = relationTarget.settingsByItemType[item.type].kind
                    else kind = relationTarget.kind
						
                    // Set initial query and texts according to subItemsKind
                    switch (kind) {
                        // case subItemsKind.usersOnOrg:
                        case subItemsKind.children:
                        case subItemsKind.childrenByUser:
                        case subItemsKind.verifications:
                        case subItemsKind.relatedContent:
                        case subItemsKind.relatedContentByUser:
                        case subItemsKind.contextChildren:
                            // $scope.item1Title = getText(item);
                            // $scope.item2Title = getText(relationTarget.item);
							
                            query = {
                                where:
								{
								    type: 73,
								    parentId: relationTarget.item.id,
								    parentType: relationTarget.item.type,
								    childId: item.id,
								    childType: item.type,
								},
                            }
                            break
                        case subItemsKind.parents:
                        case subItemsKind.parentsByUser:
                        case subItemsKind.relatedParents:
                        case subItemsKind.relatedParentsByUser:
                        case subItemsKind.verifies:
                        case subItemsKind.contextParents:
                            // $scope.item2Title = getText(item);
                            // $scope.item1Title = getText(relationTarget.item);
                            query = {
                                where:
								{
								    type: 73,
								    childId: relationTarget.item.id,
								    childType: relationTarget.item.type,
								    parentId: item.id,
								    parentType: item.type,
								},
                            }
                            break
                        case subItemsKind.visible:
                            // $scope.item2Title = getText(item);
                            // $scope.item1Title = getText(relationTarget.item);
                            query = {
                                where:
								{
								    type: 61,
								    objectId: relationTarget.item.id,
								    objectType: relationTarget.item.type,
								    wffid: item.wfid,
								},
                            }
                            break
                        default:
                            throw ('Unknown subItemsKind: ' + kind)
                    }
					
                    // Set organizationId on query according to subItemsKind
                    switch (kind) {
                        case subItemsKind.childrenByUser:
                        case subItemsKind.parentsByUser:
                        case subItemsKind.relatedContentByUser:
                        case subItemsKind.relatedParentsByUser:
                        case subItemsKind.verifications:
                        case subItemsKind.verifies:
                        case subItemsKind.contextChildren:
                        case subItemsKind.contextParents:
                            query.where.organizationId = organizationId
                            break
                        case subItemsKind.children:
                        case subItemsKind.parents:
                        case subItemsKind.relatedContent:
                        case subItemsKind.relatedParents:
                            query.where.organizationId = null
                            break
                    }

                    query.where.parentData1 = wfObject.getRelationParentDataOfKind(kind)

                    if (relationTarget.contextParentWfid) {
                        query.where.wfxpid = relationTarget.contextParentWfid
                    }

                    dataRelationObject = wfObject.filter(query)[0]

                    if (dataRelationObject) // ContextParent directly on the relation
                        dataRelationObjects.push(dataRelationObject)

                    // If relationTarget.contextParentWfid is undefined it means that the contextParent might be defined as a separate relationTarget
                    // in which case the contextParent will be treated as a seperate relation

                    relationTarget.toggled = !!dataRelationObject || false
                })

                $scope.toggled = dataRelationObjects.length === relationTarget.length
				
                setToggledState = function () {
                    const promises = []

                    dataRelationObjects = []

                    if (relationTarget.length === 1 && $scope.pickerVm && $scope.pickerVm.singlePick) {
                        $scope.pickerVm.lastPickedItem = item
                    }

                    _.each(relationTarget, (_relationTarget) => {
                        promises.push(dataOps.createSubItemRelation(_relationTarget.item, item, {
                            kind: _relationTarget.kind,
                            networkId,
                            contextParentWfid: _relationTarget.contextParentWfid,
                            ticket,
                        }).then((dataRelationObject) => {
                            dataRelationObjects.push(dataRelationObject)
                            if (_relationTarget.alsoWrapInVirtual) {
                                if (!dataRelationObject.settings) dataRelationObject.settings = {} // Needed in hierarchical and the settings are not put on the relation when newly created

                                wfObject.inject(_.assign(_.clone(dataRelationObject), {
                                    type: enums.objectType.virtualDataRelation,
                                    id: 0,
                                    wfid: '81-|' + dataRelationObject.wffid + '|' + dataRelationObject.wfcid,
                                    originalRelationWfid: dataRelationObject.wfid,
                                }))
                            }

                            if (relationTarget.length === 1 && $scope.pickerVm && $scope.pickerVm.singlePick && $scope.pickerVm.lastPickedItem.wfid !== item.wfid) {
                                vm.saving = null
				
                                setUntoggledState().then(() => {
                                    // console.log("setUntoggledState then")
                                    vm.saving = false
                                    $scope.toggled = false
                                })
                            }
                        }))
                    })
					
                    return $q.all(promises)
                }

                setUntoggledState = function () {
                    const promises = []

                    _.each(dataRelationObjects, (dataRelationObject) => {
                        promises.push(dataOps.destroy(dataRelationObject, { ticket }))
                        wfObject.ejectAll({ where: { type: enums.objectType.virtualDataRelation, originalRelationWfid: dataRelationObject.wfid } })
                    })
					
                    return $q.all(promises).then(() => {
                        dataRelationObjects = []
                    })
                }
            }
            else if (relationBucket)
            {
                $scope.toggled = _.includes(relationBucket.preSelected, item)
            }
            else if (useSimple) { // If using relationBucket
                $scope.toggled = false
            }
            else {
                $scope.toggled = false
            }
			
            onToggled(true)

            function getText(item) {
                if (!item) return ''

                if (item.type === 25 && item.childContent) return item.year + ': ' + item.value + ' ' + wfPropertyExtractor.getMeasureAnswerUnit(item)
                if (item.type === 31 && item.childContent) return item.childContent.text
				
                return item.title || item.name || item.text || ''
            }
			
            function toggleState(event) {
                let result
                if ($scope.toggled && $scope.disableUntoggle) return
					
                if (event) {
                    if (event.target && event.target.tagName === 'A') return
                    else event.stopPropagation()
                }
                if (vm.saving) return

                vm.saving = true

                if (useSimple) { // If using relationBucket
                    result = _.invoke($scope.pickerVm, 'onToggleCallback', $scope.item)

                    if (result === false) { // Toggling prevented
                        vm.saving = false
                    }
                    else if (result && result.then) { // If promise
                        result.then((res) => {
                            vm.saving = false

                            if (res === false) {
                                // Toggling prevented
                            }
                            else {
                                $scope.toggled = !$scope.toggled
                                onToggled()
                            }
                        })
                    }
                    else {
                        vm.saving = false
                        $scope.toggled = !$scope.toggled
                        onToggled()
                    }
                }
                else {
                    if ($scope.toggled) {
                        onBeforeToggled()
                        setUntoggledState().then(() => {
                            // console.log("setUntoggledState then")
                            vm.saving = false
                            $scope.toggled = false
                            onToggled()
                        })
                    }
                    else {
                        onBeforeToggled()
                        setToggledState().then(() => {
                            // console.log("setToggledState then")
                            if (vm.saving === true) vm.saving = false

                            $scope.toggled = true
                            onToggled()
                        })
                    }

                    _.invoke($scope.pickerVm, 'onToggleCallback', $scope.item)
                }
            }
			
            function onToggled(initial) {
                if ($scope.onToggled) {
                    $scope.onToggled({
                        item,
                        state: $scope.toggled,
                        scope: $scope,
                        dataRelations: dataRelationObjects,
                        initial,
                    })
                }
            }
			
            function onBeforeToggled(initial) {
                if ($scope.onBeforeToggled) {
                    $scope.onBeforeToggled({
                        item,
                        state: $scope.toggled,
                        scope: $scope,
                        dataRelations: dataRelationObjects,
                        initial,
                    })
                }
            }

            function toggleState1() {
                if (!$scope.toggled) {
                    if (vm.saving) return
                    vm.saving = true
				
                    setToggledState().then(() => {
                        // console.log("setToggledState then")
                        vm.saving = false
                        $scope.toggled = true
                    })
                }
            }

            function toggleState2() {
                if ($scope.toggled) {
                    if (vm.saving) return
                    vm.saving = true
				
                    setUntoggledState().then(() => {
                        // console.log("setUntoggledState then")
                        vm.saving = false
                        $scope.toggled = false
                    })
                }
            }
        }
    }
})()
