import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfAnalyzeItemResult', {
            templateUrl: 'scripts/wf/analyze/result/wfAnalyzeItemResult.component.html',
            controller: wfAnalyzeItemResultController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
            },
        })

    wfAnalyzeItemResultController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService']
    function wfAnalyzeItemResultController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService) {
        const
            vm = this
			
        let mainItem = vm.item
			
        let dataRelation
			
        let itemContent
			
        let actualObjectType
			
        let availableRequirementOptions
			
        const requirementUiMode = enums.uiMode.admin
			
        let structurePickerSourceWfid

        this.$onInit = $onInit

        vm.popoverTemplateUrl = 'scripts/wf/analyze/result/wfAnalyzeItemResultPopover.template.html'

        function $onInit() {
            _.assign(vm, {
                item: undefined,
                loading: true,
                ticket: vm.ticket || {},
                latestResult: undefined,
                getLatestResult,
            })

            $scope.$on('updateLatestItemAnalyzeResults', () => {
                getLatestResult()
            })

            if (mainItem.composite) {
                dataRelation = mainItem.dataRelation
                itemContent = mainItem.content
                actualObjectType = mainItem.type

                mainItem = mainItem.dataRelation
            }
            else if (mainItem.isRelationalType()) {
                dataRelation = mainItem
                itemContent = mainItem.childContent
                actualObjectType = mainItem.childType
            }
            else {
                dataRelation = null
                itemContent = mainItem
                actualObjectType = mainItem.type
            }

            vm.mainItem = mainItem

            getLatestResult()
        }

        function getLatestResult() {
            const requirements = _.find(mainItem.analyzeRequirements, (x) => {
                return x.contextParentWfid === vm.ticket.contextParentWfid && (x.networkId === null || x.networkId === vm.ticket.networkId)
            })

            if (requirements) { // Only show latest result if the item have any requirements
                vm.latestResult = _.find(mainItem.analyzeResults, {
                    latest: true,
                    targetOrganizationId: vm.ticket.organizationId,
                    networkId: vm.ticket.networkId,
                    contextParentWfid: vm.ticket.contextParentWfid,
                })
            }
            else vm.latestResult = null

            $element.css('visibility', requirements && vm.latestResult ? '' : 'hidden')

            if (vm.latestResult) {
                if (vm.latestResult.score >= 0) vm.scorePrefix = '+'
                else vm.scorePrefix = ''

                vm.totalScoreDifferenceDisplay = vm.scorePrefix + vm.latestResult.score
                vm.formattedScore = numeral(vm.latestResult.totalScoreAfter).format('0,0.[0]')
            }
            else vm.totalScoreDifferenceDisplay = null
        }
    }
})()
