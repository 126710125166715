(function () {
    'use strict'

    angular.module('wf.animations', ['ngAnimate'])
        .animation('.fold-animation', FoldAnimation)
        .animation('.slide-animation', SlideAnimation)

    SlideAnimation.$inject = ['$animateCss']
    function SlideAnimation($animateCss) {
        return {
            enter(element, doneFn) {
                const
                    innerElement = element.children()
					
                const innerElementHeight = innerElement[0].offsetHeight
					
                const index = element.data().index
					
                const jumping = element.data().jumping

                element.height(innerElementHeight)

                $animateCss(innerElement, {
                    from: { marginTop: (-innerElementHeight) + 'px' },
                    to: { marginTop: '0px' },
                    duration: index == 0 ? 0 : .2,
                    easing: 'ease-out',
                    delay: index > 0 && jumping ? index * .12 : .1,
                }).start().done(() => {
                    element.css({ height: '' })
                })
            },
            leave(element, doneFn) {
                const
                    innerElement = element.children()
					
                const innerElementHeight = innerElement[0].offsetHeight
					
                const index = element.data().index
					
                const jumping = element.data().jumping

                return $animateCss(innerElement, {
                    from: { marginTop: '0px' },
                    to: { marginTop: (-innerElementHeight) + 'px' },
                    duration: index == 0 ? 0 : .2,
                    easing: 'ease-out',
                    delay: index > 0 && jumping ? index * .12 : .1,
                })
            },
        }
    }

    FoldAnimation.$inject = ['$animateCss']
    function FoldAnimation($animateCss) {
        return {
            enter(element, doneFn) {
                const
                    innerElement = element.children()
					
                const innerElementHeight = innerElement[0].offsetHeight
					
                const index = element.data().index
					
                const jumping = element.data().jumping

                element.height(innerElementHeight)

                $animateCss(innerElement, {
                    from: { marginTop: (-innerElementHeight) + 'px' },
                    to: { marginTop: '0px' },
                    duration: index == 0 ? 0 : .2,
                    easing: 'ease-out',
                    delay: index > 0 && jumping ? index * .12 : .1,
                }).start().done(() => {
                    element.css({ height: '' })
                })
            },
            leave(element, doneFn) {
                const
                    innerElement = element.children()
					
                const innerElementHeight = innerElement[0].offsetHeight

                element.height(0)

                return $animateCss(innerElement, {
                    // from: { marginTop: '0px' },
                    // to: { marginTop: (-innerElementHeight) + 'px' },
                    duration: 0,
                    // easing: 'ease-out'
                })
            },
        }
    }
})()
