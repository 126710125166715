import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .factory('Picker', PickerFactory)

    PickerFactory.$inject = ['$q', 'pickerService', 'dataOperationsService', '$timeout', 'dataQuery', '$translate', 'wfTranslate', 'wfPropertyExtractor', 'wfAuth']
    function PickerFactory($q, pickerService, dataOps, $timeout, dataQuery, $translate, wfTranslate, wfPropertyExtractor, wfAuth) {
        _.assign(Picker.prototype, {
            //Variables
            culture: wfAuth.getCulture(),
            relationTarget: undefined,
            ticket: undefined,
            objectTypes: undefined,
            sourceItem: undefined,
            sourceList: undefined,
            sourceItemLoadSettings: undefined,
            createObjectOfType: undefined,
            stateByItemWfid: {}, // { "71-123": { toggled, saving } }
            onToggled: undefined,
            onItemsSynced: undefined,
            itemsOfSelectedSourceList: [],

            items: [],
            isRelationByUser: false,

            //Functions
            loadItems,
            syncItems,
            maybeHideItems,
        })
		
        return Picker

        function Picker(pickerSettings) {
            const self = this

            _.assign(self, pickerSettings)

            self.items = []

            if (!self.relationTarget && !self.relationTarget.item && !self.relationTarget.kind && !(self.objectTypes && self.objectTypes.length) && !self.sourceItem) console.error('relationTarget, objectTypes or sourceItem not defined properly')
        }

        function loadItems() {
            const deferred = $q.defer()
            const self = this
			
            if (!self.sourceItem && self.objectType) {
                _.assign(self, pickerService.availableObjectTypes[self.objectType])
                self.sourceItem = '71-' + pickerService.availableObjectTypes[self.objectType].structureId
            }
			
            if (self.sourceItem) {
                activateWithSourceItem().then(() => {
                    deferred.resolve(self)
                })
            }
            else {
                deferred.reject(false)
            }

            function activateWithSourceItem() {
                let id; let type; const sourceItemDeferred = $q.defer()
	
                switch (typeof self.sourceItem) {
                    case 'string':
                        type = self.sourceItem.split('-')[0]
                        id = self.sourceItem.split('-')[1]
                        break
                    case 'object':
                        type = self.sourceItem.type
                        id = self.sourceItem.id
                    default:
                        break
                }
	
                dataOps.getObject({
                    objectId: id,
                    objectType: type,
                    childrenLoadDepth: _.get(self.sourceItemLoadSettings, 'childrenLoadDepth') || (self.sourceItemFirstLevelAsFilter && 2) || 1,
                }).then((res) => {
                    $timeout(() => {
                        if (res.isRelationalType()) self.item = res.childContent
                        else self.item = res
							
                        if (self.sourceItemFirstLevelAsFilter) {
                            let
                                filterOptionsSourceBase
								
                            const allItems = []

                            self.filterOptionsSourceBase = filterOptionsSourceBase = _.map(self.item.childs, (dataRelation) => {
                                const childs = dataRelation.childContent.childs; let itemComposites
                                self.maybeHideItems(childs)
								
                                itemComposites = dataQuery.makeItemComposites(childs)
                                Array.prototype.push.apply(allItems, itemComposites)
	
                                return {
                                    id: dataRelation.wfcid,
                                    title: dataRelation.childContent.title,
                                    count: itemComposites.length,
                                    items: itemComposites,
                                    isSelected: undefined,
                                }
                            })
	
                            filterOptionsSourceBase.unshift({
                                id: 'all',
                                title: $translate.instant('All'),
                                count: allItems.length,
                                items: allItems,
                                //isSelected: selectedFilterId === "all" || allSelectedItems.length === 0
                            })

                            self.items = allItems.sort(x => x.order)

                            self.syncItems()
                            sourceItemDeferred.resolve()
                        }
                        else {
                            self.syncItems()
                            sourceItemDeferred.resolve()
                        }
                    })
                })

                return sourceItemDeferred.promise
            }
	
            function getTextFromProperty(property) {
                if (property.multilingual) return property.multilingual[self.culture]
                else if (property.translate) return $translate.instant(property.translate)
                else {
                    console.error('Could not getTextFromProperty. Make sure that the property has either multilingual: { en_US: \'English\', sv_SE: \'Svenska\' }, or translate: \'modules.subModule.text\'')
                    return ''
                }
            }
			
            return deferred.promise
        }

        function syncItems() {
            const self = this; let newArray; let sortAlphabetically

            if (!self.sourceItemFirstLevelAsFilter) {
                if (self.objectType === enums.objectType.country) {
                    sortAlphabetically = true
                }

                newArray = _.clone(_.uniqBy(self.item.childs, 'wfcid'))
                self.maybeHideItems(newArray)
                newArray = dataQuery.makeItemComposites(newArray)

                if (!sortAlphabetically && _.every(newArray, x => typeof x.dataRelation.order === 'number')) {
                    newArray = _.sortBy(newArray, 'dataRelation.order')
                }
                else {
                    newArray = _.sortBy(newArray, x => x.searchSource)
                }
				
                self.items.length = 0
                Array.prototype.push.apply(self.items, newArray)
            }

            syngToggleStates(self.relationTarget)

            function syngToggleStates(relationTarget) { // relationTarget
                let
                    relationTargets
					
                let subItemsRelations
					
                let categoryItemsRelations
					
                const itemWfids = _.map(self.items, 'wfid')
					
                const kind = relationTarget.kind
					
                const targetItem = relationTarget.item

                if (self.items.length === 0) {
                    self.emptyState = {
                        header: 'Nothing to add',
                    }
                }

                self.onToggleClick = onToggleClick

                // _.assign(options, {
                // 	onToggleClick: onToggleClick,
                // 	toggledText: $translate.instant("Remove"),
                // 	untoggledText: $translate.instant("Add")
                // });

                return $q((resolve, reject) => {
                    let subItemWfidProp = 'wfcid'
                    // dataOps.getSubItemsOfAll(itemWfids, enums.subItemsKind.childrenByUser, {
                    // 	onlyLoadRelations: true,
                    // 	loadMetadata: false
                    // }).then(function () {
                    if (!(relationTarget instanceof Array)) {
                        relationTargets = [relationTarget]
                    }
                    else {
                        relationTargets = relationTarget
                    }

                    _.each(relationTargets, (relationTarget) => {
                        if (self.contextParent || (_.get(relationTarget.item, 'conditions.pickerSettings.relationTarget.item') === '@currentContextParent' && self.ticket.contextParentWfid)) {
                            relationTarget.item = self.contextParent || (self.contextParent = wfObject.get(self.ticket.contextParentWfid))
                            self.ticket = { organizationId: self.ticket.organizationId }
                            relationTarget.relations = dataQuery.getRelations(_.assign({
                                kind: relationTarget.kind,
                                child: relationTarget.item,
                                parentWfids: itemWfids,
                            }, { organizationId: self.ticket.organizationId }))
                            subItemWfidProp = 'wffid'
                        }
                        else {
                            relationTarget.relations = dataQuery.getIntersectedSubItems(relationTarget.item, _.assign({
                                kind: relationTarget.kind,
                                childWfids: itemWfids,
                            }, self.ticket))
                        }
						
                        relationTarget.relationsByContentWfid = _.keyBy(relationTarget.relations, subItemWfidProp)
						
                        // });
                    })

                    // cache.subItemsByWfid = _.keyBy(subItemsRelations, "wfcid");
                    // cache.itemsInCategoryByWfid = _.keyBy(categoryItemsRelations, "wfcid");
                    // cache.subItemsInCategory = _.intersectionBy(subItemsRelations, categoryItemsRelations, "wfcid");

                    const intersectionByArgs = _.map(relationTargets, 'relations')
                    intersectionByArgs.push(subItemWfidProp)

                    self.intersectedRelations = _.intersectionBy.apply(null, intersectionByArgs)
                    const stateByItemWfid = _.chain(self.intersectedRelations).keyBy(subItemWfidProp).mapValues(relation => ({ toggled: true })).value()

                    self.items.forEach((x) => {
                        x.toggled = x.wfid in stateByItemWfid
                    })

                    if (typeof self.onItemsSynced === 'function') self.onItemsSynced(self.items.filter(x => x.toggled), self.filterOptionsSourceBase)

                    resolve()
                })

                function onToggleClick(itemComposite, temp) {
                    const wfid = itemComposite.wfid
                    let promise; const promises = []; let targetRelation
                    // const execId = _.uniqueId(); // For debugging

                    if (itemComposite.saving) {
                        return
                    }

                    if (itemComposite.toggled) {
                        itemComposite.saving = true

                        if (self.latestToggledItem === itemComposite) {
                            self.latestToggledItem = undefined
                        }

                        _.each(relationTargets, (relationTarget) => {
                            if (relationTarget.relationsByContentWfid[wfid]) {
                                targetRelation = relationTarget.relationsByContentWfid[wfid]
                                // console.log(execId + ":", "Delete " + targetRelation.childContent.title, " - " + temp);
                                promise = dataOps.destroy(relationTarget.relationsByContentWfid[wfid])
                                promises.push(promise)
                                delete relationTarget.relationsByContentWfid[wfid]
                            }
                        })

                        $q.all(promises).then(() => {
                            itemComposite.toggled = false
                            itemComposite.saving = false
                            if (typeof self.onToggled === 'function' && targetRelation) {
                                self.onToggled(targetRelation, false, self.items.filter(x => x.toggled))
                            }

                            $timeout()
                        })
                    }
                    else {
                        self.latestToggledItem = itemComposite
                        itemComposite.saving = true

                        if (self.singlePick) {
                            self.items.filter(x => x.toggled && x.wfid != itemComposite.wfid).forEach(x => onToggleClick(x, 'Deleted from ' + itemComposite.content.title))
                        }

                        _.each(relationTargets, (relationTarget) => {
                            // console.log(execId + ":", "Skipping add on " + itemComposite.content.title, _.clone(relationTarget.relationsByWfcid));
                            if (!(wfid in relationTarget.relationsByContentWfid)) {
                                // console.log(execId + ":", "Add " + itemComposite.content.title);
                                // When kind is an array the first kind is used when creating the relation
                                promise = dataOps.createSubItemRelation(relationTarget.item, itemComposite.content, { kind: relationTarget.kind, ticket: self.ticket }).then((res) => {
                                    targetRelation = relationTarget.relationsByContentWfid[wfid] = res
                                })
                                promises.push(promise)
                            }
                        })

                        $q.all(promises).then(() => {
                            itemComposite.toggled = true
                            itemComposite.saving = false

                            if (self.singlePick) {
                                if (self.latestToggledItem !== itemComposite) {
                                    onToggleClick(itemComposite, 'not latest anymore so delete')
                                    return
                                }
                            }

                            // console.log(execId + ":", "Latest toggled is", self.latestToggledItem.content.title, targetRelation)

                            if (typeof self.onToggled === 'function' && targetRelation) {
                                self.onToggled(targetRelation, true, self.items.filter(x => x.toggled))
                            }
                            $timeout()
                        })
                    }

                    $timeout()
                }
            }
        }
			
        function maybeHideItems(array) {
            const self = this

            if (typeof self.hideItem === 'string') {
                _.remove(array, (dataRelation) => {
                    return dataRelation.wfcid === self.hideItem
                })
            }
        }

        // function activateWithSourceLists() {
        // 	var sourceListDeferred = $q.defer();
        // 	var promises = [];

        // 	_.each(self.sourceLists, function (sourceList) {
        // 		if (typeof sourceList.items === "function") {
        // 			(function () {
        // 				var promiseOrArray = sourceList.items();
        // 				if (promiseOrArray.then) {
        // 					promises.push(promiseOrArray.then(function (res) {
        // 						sourceList.items = res;
        // 					}))
        // 				}
        // 				else {
        // 					promises.push($q(function (resolve) {
        // 						sourceList.items = promiseOrArray;
        // 						resolve();
        // 					}));
        // 				}
        // 			})()
        // 		}
        // 		else if (_.isArray(sourceList.items)) {
        // 			promises.push($q(function (resolve) {
        // 				resolve();
        // 			}));
        // 		}
        // 		self.sourceItem = true;
        // 	});

        // 	$q.all(promises).then(function () {
        // 		var initialSourceLists = _.clone(self.sourceLists);

        // 		// Remove source lists that are empty
        // 		_.remove(self.sourceLists, function (sourceList) {
        // 			return !sourceList.items.length
        // 		});

        // 		// If all source lists were removed than add back the first one
        // 		if (self.sourceLists.length === 0)
        // 			self.sourceLists = [ initialSourceLists[0] ];

        // 		selectSourceList(self.sourceLists[0])

        // 		var averageTitleLength, filterOptionsCount = self.sourceLists.length;
        // 		averageTitleLength = _.chain(self.sourceLists).map("title.length").sum() / filterOptionsCount;
        // 		// If the multiplication of average title length and the number of options exceeds 400
        // 		// then display a dropdown instead of buttons. After some testing, 400 seems to work well.
        // 		if (averageTitleLength * filterOptionsCount > 400) {
        // 			self.showSourceListFilteringAsDropdown = true;
        // 		}
				
        // 		sourceListDeferred.resolve();
        // 	});

        // 	return sourceListDeferred.promise;
        // }

        // function selectSourceList(sourceList) {
        // 	var output = [];

        // 	if (!sourceList)
        // 		sourceList = self.sourceLists[0];

        // 	self.selectedSourceList = sourceList;

        // 	self.canCombineSourceListsFilter = false;

        // 	if (self.canCombineSourceListsFilter) {
        // 		sourceList.selected = !sourceList.selected;
				
        // 		_.each(_.filter(self.sourceLists, { selected: true }), function (sourceList) {
        // 			Array.prototype.push.apply(output, sourceList.items);
        // 		});

        // 		self.itemsOfSelectedSourceList.length = 0;
        // 		output = _.uniqBy(output, "wfid");
        // 		Array.prototype.push.apply(self.itemsOfSelectedSourceList, output); 
        // 	}
        // 	else {
        // 		_.each(self.sourceLists, function (sourceList) {
        // 			sourceList.selected = false;
        // 		});
				
        // 		sourceList.selected = true;
        // 		self.itemsOfSelectedSourceList.length = 0;
        // 		Array.prototype.push.apply(self.itemsOfSelectedSourceList, sourceList.items); 
        // 	}
        // }
    }
})()
