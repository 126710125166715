// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Colors from '@worldfavor/constants/colors'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
    dialogDescription: {
        fontSize: 14,
    },
    titleContainer: {
        paddingRight: 40,
    },
    closeButton: {
        color: Colors.gray,
    },
    checkBox: {
        fontSize: 'medium',
        width: '50px',
        height: '50px',
        margin: '1px',
        border: '3px solid',
        color: '#707980',
        borderColor: Colors.grayLight,
    },
    checkedCheckbox: {
        fontSize: 'medium',
        backgroundColor: Colors.blue,
        width: '50px',
        height: '50px',
        color: '#fff',
        border: '3px solid',
        borderColor: Colors.blueDark,
        margin: '1px',
        '&:hover': {
            backgroundColor: Colors.blueDark,
        },
    },
    checkboxes: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
    },
    saveButton: {
        backgroundColor: Colors.blue,
    },
})

type Props = {
  open: boolean,
  onClose: () => void,
  onSave: (days: {}) => void,
  calculationSchedule: {},
}

const CalculationScheduleDialog = (props: Props) => {
    const { open, onClose, onSave, calculationSchedule } = props
    const classes = useStyles(props)
    const [days, setDays] = useState(calculationSchedule)

    useEffect(() => {
        setDays(calculationSchedule)
    }, [calculationSchedule])

    const onChange = (event) => {
        setDays({ ...days, [event.target.name]: event.target.checked })
    }

    const onSaveClick = () => {
        onSave(days)
    }

    const onCloseClick = () => {
        setDays(calculationSchedule)
        onClose()
    }

    function StyledCheckbox({ day }) {
        const labelId = `calculationScheduleDialog.days.${day}`
        return (
            <Checkbox
                className={days[day] ? classes.checkedCheckbox : classes.checkBox}
                icon={(
                    <FormattedMessage
                        id={labelId}
                    />
                )}
                checkedIcon={(
                    <FormattedMessage
                        id={labelId}
                    />
                )}
                disableRipple
                color="default"
                name={day}
                checked={days[day]}
                onChange={onChange}
            />
        )
    }

    return (
        <>
            <Dialog open={open}>
                <DialogTitle>
                    <div className={classes.titleContainer}>
                        <FormattedMessage
                            id={'calculationScheduleDialog.title'}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.dialogDescription}>
                        <FormattedMessage
                            id={'calculationScheduleDialog.description'}
                        />
                    </div>
                    <div className={classes.checkboxes}>
                        <StyledCheckbox day="monday" />
                        <StyledCheckbox day="tuesday" />
                        <StyledCheckbox day="wednesday" />
                        <StyledCheckbox day="thursday" />
                        <StyledCheckbox day="friday" />
                        <StyledCheckbox day="saturday" />
                        <StyledCheckbox day="sunday" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseClick}>
                        <FormattedMessage id={'general.close'} />
                    </Button>
                    <Button variant="contained" color="primary" onClick={onSaveClick} className={classes.saveButton}>
                        <FormattedMessage id={'general.save'} />
                    </Button>
                </DialogActions>
            </Dialog>
   
        </>
    )
}

export default injectIntl(CalculationScheduleDialog)
