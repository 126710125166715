(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfStickyBreadcrumbs', StickyBreadcrumbs)

    StickyBreadcrumbs.$inject = []

    function StickyBreadcrumbs() {

        const directive = {
            //bindToController: true,
            //controller: ControllerController,
            //controllerAs: 'vm',
            link,
            restrict: 'A',
            scope: {
            },
        }
        return directive
		
        function link(scope, element, attrs) {
            return
            const breadcrumbsContainer = $('<div class=\'stickyBreadcrumbs-container\' />').appendTo($('body'))

            setTimeout(() => {
                headingBreadcrumbs({})
            }, 2000)

            function headingBreadcrumbs(options) {
                const ARTICLE = element
                const CONTAINER = breadcrumbsContainer
                const END_OF_ARTICLE = ARTICLE.offset().top + ARTICLE[0].offsetHeight
                // var FADING_DISTANCE = options.fadingDistance || 100;
                // var OFFSET_END_OF_SCOPE = options.offsetEndOfScope || 100;
                const DEPTH = 6

                init()

                function init() {
                    const headingsPerLevel = getHeadingsPerLevel()
                    makeBreadcrumbs(headingsPerLevel)

                    window.addEventListener('scroll', () => {
                        makeBreadcrumbs(headingsPerLevel)
                    })
                }

                function makeBreadcrumbs(headingsPerLevel) {
					
                    CONTAINER.html(getBreadcrumbs(headingsPerLevel, scrollTop()))
                }

                function scrollTop() {
                    return window.scrollY
                }

                function getHeadingsPerLevel() {
                    const headingsPerLevel = []

                    for (let level = 1; level <= DEPTH; level++) {
                        let headings = ARTICLE.find('.level' + level)
                        //Sorting headings by their offset from the top of the deocument in a decending order. 
						 headings = headings.sort((a, b) => {
							 return $(b).offset().top - $(a).offset().top })
                        headingsPerLevel.push(headings)
                    }

                    return headingsPerLevel
                }

                function getBreadcrumbs(headingsPerLevel, scrollTop) {
                    const breadcrumbs = []
                    const headingsInScope = findHeadingsInScope(headingsPerLevel, scrollTop)

                    headingsInScope.forEach((heading) => {
                        //var opacity = calculateOpacity(heading.beginningOfScope, heading.endOfScope, scrollTop);

                        const html = '<a href="#' + heading.id
							+ '" class="' + heading.tag
							//+ '" style="opacity:' + opacity
							//+ '; pointer-events: ' + (opacity > 0.5 ? 'auto' : 'none')
							+ '">' + heading.text
							+ '</a>'

                        breadcrumbs.push(html)
                    })

                    return breadcrumbs.join('')
                }

                function findHeadingsInScope(headingsPerLevel, scrollTop) {
                    const headingsInScope = []
                    let previousHeadingOffset = 0

                    headingsPerLevel.forEach((headings, level) => {
                        let heading = _.find(headings, (node) => {
                            node = $(node)
                            return (node.offset().top < scrollTop) && (node.offset().top > previousHeadingOffset)
                        })

                        if (heading) {
                            heading = $(heading)
                            const nextHeadingOfSameLevel = headingsPerLevel[level][headingsPerLevel[level].index(heading[0]) - 1]
                            const currentHeadingOfHigherLevel = headingsInScope[headingsInScope.length - 1]
                            const endOfScope = calculateEndOfScope(nextHeadingOfSameLevel, currentHeadingOfHigherLevel)

                            headingsInScope.push({
                                id: heading[0].id,
                                tag: heading[0].tagName.toLowerCase(),
                                text: heading[0].textContent,
                                beginningOfScope: heading.offset().top + heading[0].offsetHeight,
                                endOfScope,
                            })

                            previousHeadingOffset = heading.offset().top
                        }
                        else {
                            previousHeadingOffset = END_OF_ARTICLE
                        }
                    })
                    return headingsInScope
                }

                function calculateEndOfScope(nextHeadingOfSameLevel, currentHeadingOfHigherLevel) {
                    let endOfScope

                    if (currentHeadingOfHigherLevel) {
                        if (nextHeadingOfSameLevel) {
                            endOfScope = Math.min($(nextHeadingOfSameLevel).offset().top, currentHeadingOfHigherLevel.endOfScope)
                        }
                        else {
                            endOfScope = currentHeadingOfHigherLevel.endOfScope
                        }
                    }
                    else {
                        if (nextHeadingOfSameLevel) {
                            endOfScope = $(nextHeadingOfSameLevel).offset().top
                        }
                        else {
                            endOfScope = END_OF_ARTICLE
                        }
                    }

                    return endOfScope
                }

                // function calculateOpacity(top, bottom, scrollTop) {
                // 	var diffTop = scrollTop - top;
                // 	var opacityTop = diffTop > FADING_DISTANCE ? 1 : diffTop / FADING_DISTANCE;

                // 	var diffBottom = bottom - scrollTop - OFFSET_END_OF_SCOPE;
                // 	var opacityBottom = diffBottom > FADING_DISTANCE ? 1 : diffBottom / FADING_DISTANCE;
                // 	return Math.min(opacityTop, opacityBottom);
                // }
            }

            // //Another JS File

            // var anchorForId = function (id) {
            // var anchor = document.createElement('a');
            // anchor.className = 'header-link';
            // anchor.href = '#' + id;
            // anchor.innerHTML = '<i class=\'fa fa-link\'></i>';
            // return anchor;
            // };

            // var linkifyAnchors = function (level, containingElement) {
            // var headers = containingElement.getElementsByTagName('h' + level);
            // for (var h = 0; h < headers.length; h++) {
            // 	var header = headers[h];

            // 	if (typeof header.id !== 'undefined' && header.id !== '') {
            // 	header.appendChild(anchorForId(header.id));
            // 	}
            // }
            // };

            // document.addEventListener('DOMContentLoaded', function () {
            // var post = document.getElementsByClassName('post')[0];

            // if (post) {
            // 	for (var level = 1; level <= 6; level++) {
            // 	linkifyAnchors(level, post);
            // 	}
            // }
            // });
        }
    }
})()
