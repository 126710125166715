import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfAnalyzeRequirementsEditor', {
            templateUrl: 'scripts/wf/analyze/requirement/wfAnalyzeRequirementsEditor.component.html',
            controller: wfAnalyzeRequirementsEditorController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
                uiModeAttr: '=uiMode',
            },
        })

    wfAnalyzeRequirementsEditorController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService', 'wfPropertyExtractor', 'modalService', 'valueChainService']
    function wfAnalyzeRequirementsEditorController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService, wfPropertyExtractor, modal, valueChainService) {
        const
            vm = this
			
        const debug = false
			
        const numbersRegExp = /[^\d-,.]/g
			
        const calculationGrouping = {
            local: 0,
            global: 1,
        }
			
        const itemPrototype = {
            model: {
                type: enums.objectType.analyzeRequirement,
                order: undefined,
                rule: undefined,
                ruleInstruction: undefined,
                value: undefined,
                valueDetails: undefined,
                points: 1.0,
                operation: enums.mathOperation.addition,
                calculationGrouping: calculationGrouping.global,
                terminal: false,
                negate: false,
                useObjectDataAsPoints: false,
                resultTask: 0,
                resultTaskValue: null,
            },
            deserializedResultTaskValue: null,
            wasAdded: false,
            wasChanged: false,
            wasRemoved: false,
            actualObject: undefined,
            remove() { removeRequirement(this) },
            onChange(property) {
                onRequirementChange(this, property)
            },
        }
			
        let mainItem
			
        let dataRelation
			
        let itemContent
			
        let availableRequirementOptions
			
        const requirementUiMode = enums.uiMode.admin
			
        let structurePickerSourceWfid
			
        let changesMadeAndSaved = false

        let isStandard = undefined

        _.assign(vm, {
            uiMode: enums.uiMode.admin,
            items: undefined,
            viewItems: undefined,
            saveChanges,
            addItem,
            loading: true,
            saving: false,
            debug,
            ticket: vm.ticket || {},
            availableRequirementOptions: undefined,
            actualObjectType: undefined,
            enableUseObjectDataAsPoints: false,
            selectAdditionalTask_createMeasureAnswer,

            mathOperationOptions: [
                { id: 0, name: $translate.instant('mathOperations.add'), icon: 'fa fa-plus' },
                { id: 1, name: $translate.instant('mathOperations.subtract'), icon: 'fa fa-minus' },
                { id: 2, name: $translate.instant('mathOperations.multiply'), icon: 'fa fa-times' },
                { id: 3, name: $translate.instant('mathOperations.divide'), icon: 'fa fa-minus', css: { transform: 'rotate(-60deg)' } },
                { id: 4, name: $translate.instant('mathOperations.replaceWith'), icon: 'fa fa-exchange' },
            ],

            calculationGroupOptions: [
                { id: 0, name: $translate.instant('calculationGrouping.local'), icon: '' },
                { id: 1, name: $translate.instant('calculationGrouping.global'), icon: '' },
            ],

            additionalResultTaskOptions: [
                { id: 0, name: $translate.instant('modules.analyze.additionalResultTask.notDefined'), icon: 'fa fa-ban' },
                // TODO: { id: 1, name: $translate.instant("modules.analyze.additionalResultTask.writeLocalLabel"), icon: "fa fa-tag" },
                { id: 2, name: $translate.instant('modules.analyze.additionalResultTask.writeGlobalLabel'), icon: 'fa fa-tag' },
                // TODO: { id: 3, name: $translate.instant("modules.analyze.additionalResultTask.writeLocalAndGlobalLabel"), icon: "fa fa-tags" },
                vm.ticket.networkId && { id: 4, name: $translate.instant('modules.analyze.additionalResultTask.categorizeTargetOrganization'), icon: 'fa fa-star' },
                vm.ticket.networkId && { id: 5, name: $translate.instant('modules.analyze.additionalResultTask.uncategorizeTargetOrganization'), icon: 'fa fa-star-o' },
                vm.ticket.networkId && { id: 7, name: $translate.instant('modules.analyze.additionalResultTask.createInfluences'), icon: 'fa fa-plus-square' },
                vm.ticket.networkId && { id: 8, name: $translate.instant('modules.analyze.additionalResultTask.deleteInfluences'), icon: 'fa fa-minus-square' },
            ].filter(Boolean),

            dragControlListeners: {
                itemMoved(event) {
                    // console.log(arguments)
                },
                orderChanged(event) {
                    reorderItemsAndSyncView()
                },
            },
            cancel: close,
            onNumberInputBlur,
            translate: $translate.instant,
        })

        this.$onInit = $onInit

        function $onInit() {
            let objectTypesCondition
            const initialPromises = []
            let relationsWithMeasurePeriodSettings
            // Mocked data
            // vm.items = _.times(2, function (index) {
            // 	return _.defaultsDeep({ actualObject: (function () { var id = parseInt(_.uniqueId()) + 16; return { type: 109, id: id, wfid: "109-" + id } })(), model: {
            // 		order: index + 1,
            // 		rule: undefined,
            // 		value: undefined,
            // 		points: _.random(1, 5),
            // 		operation: _.random(0, 3),
            // 		calculationGrouping: calculationGrouping.local
            // 	}}, itemPrototype);
            // });

            // mainItem = { id: 2, type: 73, childId: 3, childType: 71, childContent: { type: 71, conditions: {
            // 	pickerSettings: {"sourceItem":"71-13804","openerButtonTranslate":"Select"}
            // } } };

            if (vm.uiModeAttr) {
                vm.uiMode = vm.uiModeAttr
            }
            vm.isAdminMode = vm.uiMode === enums.uiMode.admin

            mainItem = vm.item
            dataRelation = mainItem
            itemContent = dataRelation.childContent

            vm.actualObjectType = dataRelation.childType
            vm.itemMainTextual = wfPropertyExtractor.getMainTextual(itemContent)

            vm.requirementsLoader = new analyzeService.requirementsLoader(mainItem, vm.ticket, $scope)

            if (itemContent.type === enums.objectType.measure) {
                vm.enableUseObjectDataAsPoints = true
                vm.additionalResultTaskOptions.push({ id: 6, name: $translate.instant('modules.analyze.additionalResultTask.createMeasureAnswer'), icon: 'fa fa-plus' })
            }

            if (itemContent.type === enums.objectType.measure) {
                dataOps.getSubItems(mainItem.childContent, enums.subItemsKind.parents, {
                    onlyLoadRelations: true,
                    bypassCache: true,
                    getterConditions: {
                        loadSettings: true,
                    },
                }).then((res) => {
                    relationsWithMeasurePeriodSettings = _.filter(res, (dataRelation) => {
                        return dataRelation.settings && dataRelation.settings.measurePeriodSettings
                    })
                    loadFormattedRequirementsOnItem(relationsWithMeasurePeriodSettings.length ? { additionalItemsWithMeasurePeriodSettings: relationsWithMeasurePeriodSettings } : null)
                })
            }
            else {
                loadFormattedRequirementsOnItem()
            }

            function loadFormattedRequirementsOnItem(options) {
                const
                    additionalOutput = {}
					
                const promise = vm.requirementsLoader.loadFormattedRequirementsOnItem(_.assign({ format: false, networkId: vm.ticket.networkId }, options), additionalOutput)
				
                promise.then((_availableRequirementOptions) => {
                    vm.availableRequirementOptions = availableRequirementOptions = _availableRequirementOptions

                    if (additionalOutput.specificItemOptions) {
                        vm.specificItemOptions = additionalOutput.specificItemOptions
                    }
                })

                initialPromises.push(promise)

                initialPromises.push((function () {
                    const promise = dataOps.getObjects({
                        requestSignature_noResultNeeded: ['analyze_requirements', mainItem.wfid, vm.ticket.organizationId, vm.ticket.networkId, vm.ticket.contextParentWfid].join('_'),
                        objectType: enums.objectType.analyzeRequirement,
                        bypassCache: true,
                        wrapInRelations: false,
                        getterConditions: {
                            objectType: mainItem.type,
                            objectId: mainItem.id,
                            organizationId: vm.ticket.organizationId,
                            networkId: vm.ticket.networkId,
                            contextParentWfid: vm.ticket.contextParentWfid,
                        },
                    })

                    promise.then((res) => {
                        vm.items = _.chain(res).sortBy('order').map((analyzeReq) => {
                            let item
                            // console.log(analyzeReq.resultTask)
                            const analyzeReqForRuleValuePairIdMaker = itemContent.type === enums.objectType.structure ? { ...analyzeReq, value: undefined } : analyzeReq

                            item = _.defaultsDeep({
                                actualObject: analyzeReq,
                                model: {
                                    order: analyzeReq.order,
                                    ruleValuePairId: analyzeService.makeRuleValuePairId(itemContent, analyzeReqForRuleValuePairIdMaker),
                                    rule: analyzeReq.rule,
                                    ruleInstruction: analyzeReq.ruleInstruction,
                                    value: analyzeReq.value,
                                    valueDetails: analyzeReq.valueDetails,
                                    points: analyzeReq.points,
                                    operation: analyzeReq.operation,
                                    calculationGrouping: analyzeReq.calculationGrouping,
                                    terminal: analyzeReq.terminal,
                                    negate: analyzeReq.negate,
                                    useObjectDataAsPoints: analyzeReq.useObjectDataAsPoints,
                                    minValue: analyzeReq.rule === enums.requirementRule.span && analyzeReq.value ? analyzeReq.value.split('|')[0] : undefined,
                                    maxValue: analyzeReq.rule === enums.requirementRule.span && analyzeReq.value ? analyzeReq.value.split('|')[1] : undefined,
                                    resultTask: analyzeReq.resultTask || 0,
                                    resultTaskValue: analyzeReq.resultTaskValue,
                                },
                            }, itemPrototype)

                            if (itemContent.type === enums.objectType.structure && analyzeReq.rule === 7) {
                                const requirementOption = _.find(availableRequirementOptions, { id: item.model.ruleValuePairId })
                                if (requirementOption) {
                                    item.specificItemOptions = requirementOption.specificItemOptions
                                }
                            }

                            if (analyzeReq.resultTask === enums.analyzeResultTask.createObject) {
                                item.jsonResultTaskValue = jsonResultTaskValue_createMeasureAnswer(item, analyzeReq)
                            }
                            else if (analyzeReq.resultTask === enums.analyzeResultTask.categorizeTargetOrganization || analyzeReq.resultTask === enums.analyzeResultTask.uncategorizeTargetOrganization) {
                                item.jsonResultTaskValue = jsonResultTaskValue_targetOrganizationCategorization_init(item, analyzeReq)
                                item.additionalTask_targetOrganizationCategorization_onFiltered = additionalTask_targetOrganizationCategorization_onFiltered
                            }
                            else if (analyzeReq.resultTask === enums.analyzeResultTask.createInfluences) {
                                item.jsonResultTaskValue = additionalTask_createInfluences_init(item, analyzeReq)
                                item.additionalTask_createInfluences_setup = additionalTask_createInfluences_setup
                            }
                            else if (analyzeReq.resultTask === enums.analyzeResultTask.deleteInfluences) {
                                item.jsonResultTaskValue = additionalTask_deleteInfluences_init(item, analyzeReq)
                                item.additionalTask_deleteInfluences_setup = additionalTask_deleteInfluences_setup
                            }
                            else {
                                item.jsonResultTaskValue = null
                            }

                            return item
                        }).value()

                        vm.viewItems = _.clone(vm.items)
                        isStandard = vm.items.some(i => !i.actualObject.networkId)
                        reorderItemsAndSyncView()
                    })

                    return promise
                })())

                $q.all(initialPromises).then(() => {
                    vm.loading = false
                    $timeout()
                })
            }
        }

        function removeRequirement(item) {
            if (item.actualObject) {
                item.wasChanged = true
                item.wasRemoved = true
            }
            else {
                _.remove(vm.items, item)
                _.remove(vm.viewItems, item)
            }

            reorderItemsAndSyncView()
        }

        function onRequirementChange(item, property) {
            const requirementOption = _.find(availableRequirementOptions, { id: item.model.ruleValuePairId })
            let validResultTaskProps

            if (requirementOption) {
                if (item.model.rule != requirementOption.rule || item.model.ruleInstruction != requirementOption.ruleInstruction) {
                    item.model.value = undefined
                }

                item.model.rule = requirementOption.rule
                item.model.ruleInstruction = requirementOption.ruleInstruction
                item.specificItemOptions = requirementOption.specificItemOptions
            }

            item.wasChanged = true

            // if (item.model.operation === enums.mathOperation.multiplication || item.model.operation === enums.mathOperation.division) {
            // 	item.model.calculationGrouping = calculationGrouping.global; // Always use calculationGrouping.global for multiplication and division, for now
            // }
            // else {
            // 	item.model.calculationGrouping = calculationGrouping.local;
            // }

            item.model.points = handleStringAsNumber(item.model.points)

            if (vm.actualObjectType === enums.objectType.structure && item.model.rule === enums.requirementRule.span) {
                item.model.minValue = handleStringAsNumber(item.model.minValue)
                item.model.maxValue = handleStringAsNumber(item.model.maxValue)
                item.model.value = item.model.minValue +  '|' + item.model.maxValue
            }

            if (property === 'useObjectDataAsPoints') {
                if (item.model.useObjectDataAsPoints) {
                    item.model.pointsBeforeCleared = item.model.points || 0
                    item.model.points = 0
                }
                else {
                    item.model.points = item.model.pointsBeforeCleared || 0
                }
            }

            if (property === 'resultTask') {
                if (item.model.resultTask === enums.analyzeResultTask.createObject) {
                    item.jsonResultTaskValue = {
                        type: enums.objectType.measureAnswer,
                        measureId: _.get(item.selectedMeasure, 'id') || 0,
                        useTargetOrganizationAsRelationContextParent: true,
                        createVisibilityTag: true,
                    }
                }
                else if (item.model.resultTask === enums.analyzeResultTask.categorizeTargetOrganization || item.model.resultTask === enums.analyzeResultTask.uncategorizeTargetOrganization) {
                    item.jsonResultTaskValue = {
                        categoryIds: _.map(item.selectedCategories, 'id') || [],
                    }
                    item.additionalTask_targetOrganizationCategorization_onFiltered = additionalTask_targetOrganizationCategorization_onFiltered
                }
                else if (item.model.resultTask === enums.analyzeResultTask.createInfluences || item.model.resultTask === enums.analyzeResultTask.deleteInfluences) {
                }
                else {
                    if (typeof item.jsonResultTaskValue === 'object') {
                        item.model.resultTaskValue = ''
                    }

                    item.jsonResultTaskValue = null
                }
            }

            if (requirementOption && property === 'ruleValuePairId') {
                item.model.valueDetails = undefined

                if (vm.actualObjectType === enums.objectType.measure && requirementOption.periodFrequency) {
                    item.model.valueDetails = {
                        measurePeriod: {
                            frequency: requirementOption.periodFrequency,
                            startDate: requirementOption.periodStartDate,
                            endDate: requirementOption.periodEndDate,
                            periodSettingsObjectWfid: requirementOption.periodSettingsObjectWfid || undefined,
                            intervalNameSpecification: requirementOption.intervalNameSpecification || undefined,
                        },
                    }
                }
            }

            if (requirementOption && item.model.resultTask === enums.analyzeResultTask.createObject) {
                _.assign(item.jsonResultTaskValue, {
                    periodFrequency: requirementOption.periodFrequency,
                    periodStartDate: requirementOption.periodStartDate,
                    periodEndDate: requirementOption.periodEndDate,
                    periodSettingsObjectWfid: requirementOption.periodSettingsObjectWfid || undefined,
                    intervalNameSpecification: requirementOption.intervalNameSpecification || undefined,
                })

                validResultTaskProps = [
                    'type',
                    'measureId',
                    'useTargetOrganizationAsRelationContextParent',
                    'createVisibilityTag',
                    'periodFrequency',
                    'periodStartDate',
                    'periodEndDate',
                    'periodSettingsObjectWfid',
                    'intervalNameSpecification',
                ]

                item.selectedMeasurePeriodName = _.get(_.find(availableRequirementOptions, {
                    periodStartDate: item.jsonResultTaskValue.periodStartDate,
                    periodEndDate: item.jsonResultTaskValue.periodEndDate,
                    periodSettingsObjectWfid: item.jsonResultTaskValue.periodSettingsObjectWfid || undefined,
                    intervalNameSpecification: item.jsonResultTaskValue.intervalNameSpecification || undefined,
                }), 'periodName')
            }
            else if (requirementOption && (item.model.resultTask === enums.analyzeResultTask.categorizeTargetOrganization || item.model.resultTask === enums.analyzeResultTask.uncategorizeTargetOrganization)) {
                validResultTaskProps = [
                    'categoryIds',
                ]
            }
            else if (requirementOption && item.model.resultTask === enums.analyzeResultTask.createInfluences) {
                item.resultTaskLoaded = true
                item.additionalTask_createInfluences_setup = additionalTask_createInfluences_setup
                validResultTaskProps = [
                    'creatorUserId',
                    'activatedAtDaysFromNow',
                    'fulfillmentDueAtDaysFromNow',
                    'categoryIds',
                    'invertedCategoryIds',
                    'objectIds',
                    'networkId',
                ]
            }
            else if (requirementOption && item.model.resultTask === enums.analyzeResultTask.deleteInfluences) {
                item.resultTaskLoaded = true
                item.additionalTask_deleteInfluences_setup = additionalTask_deleteInfluences_setup
                validResultTaskProps = [
                    'categoryIds',
                    'invertedCategoryIds',
                    'objectIds',
                    'networkId',
                ]
            }

            if (item.jsonResultTaskValue && validResultTaskProps) {
                _.each(item.jsonResultTaskValue, (value, key) => {
                    if (!validResultTaskProps.includes(key)) delete item.jsonResultTaskValue[key]
                })
            }
        }

        function handleStringAsNumber(value, doNotallowPartial) {
            if (typeof value === 'number' && !isNaN(value)) return value

            if (typeof value !== 'string') value = ''

            // if (typeof value === "string" && (doNotallowPartial || value.length)) {
            if (!doNotallowPartial && (value === '-' || value[value.length - 1] === '.' || value[value.length - 1] === ',')) // Allow user to start typing a dash (for negative numbers). If value is still only a dash on blur then it is replaced with zero
                return value

            value = value.replace(',', '.')

            value = parseFloat(value.replace(numbersRegExp, '')) // Strip all characters except dash and numbers, then parse to float and then remove decimals
            if (isNaN(value)) {
                if (doNotallowPartial) value = 0
                else value = '' // If value is not a number then set to empty string. If value is still empty on blur then it is replaced with zero
            }
            // }

            return value.toString()
        }

        function addItem() {
            const firstRequirementOption = _.first(availableRequirementOptions)

            const newItem = _.defaultsDeep({ wasAdded: true, wasChanged: true, model: {
                ruleValuePairId: firstRequirementOption.id,
                rule: firstRequirementOption.rule,
                ruleInstruction: firstRequirementOption.ruleInstruction,
                value: firstRequirementOption.value,
                points: 1,
                operation: 0,
                calculationGrouping: calculationGrouping.global,
            } }, itemPrototype)

            vm.items.unshift(newItem)
            vm.viewItems.unshift(newItem)

            reorderItemsAndSyncView()
        }

        function saveChanges() {
            return $q((resolve, reject) => {
                const itemsWithChanges = _.filter(vm.items, { wasChanged: true })

                if (itemsWithChanges.length) {
                    vm.saving = true
                    $timeout()
                    saveNextRequirement()
                }
                else {
                    resolve()
                }

                function saveNextRequirement() {
                    const
                        item = itemsWithChanges.shift()
                    // shift() removes the first element from the array and returns that element
						
                    let model
						
                    let requirementOption // Rule and Value properties

                    if (item) {
                        changesMadeAndSaved = true

                        if (item.wasRemoved) {
                            dataOps.destroy(item.actualObject).then(() => {
                                _.remove(vm.items, item)
                                saveNextRequirement()
                            })
                        }
                        else {
                            // Prepare model for create/update
                            if (dataRelation) {
                                model = _.clone(item.model)

                                model.objectType = dataRelation.type
                                model.objectId = dataRelation.id
                                model.derivedType = dataRelation.childType
                                model.derivedId = dataRelation.childId
                                model.networkId = isStandard ? null : vm.ticket.networkId
                                model.contextParentWfid = vm.ticket.contextParentWfid
                                // Currently, TargetOrganizationID on AnalyzeRequirements is not implemented anywhere
                            }

                            requirementOption = _.find(availableRequirementOptions, { id: model.ruleValuePairId })
                            model.rule = requirementOption.rule
                            model.ruleInstruction = requirementOption.ruleInstruction

                            if (requirementOption.value === null) delete model.value
                            else if ('value' in requirementOption) model.value = requirementOption.value

                            delete model.pointsBeforeCleared

                            if (model.resultTask === enums.analyzeResultTask.createInfluences
								|| model.resultTask === enums.analyzeResultTask.deleteInfluences
                            ) {
                                if (!item.jsonResultTaskValue) item.jsonResultTaskValue = {}

                                item.jsonResultTaskValue.networkId = vm.ticket.networkId
                            }
                            if (model.resultTask === enums.analyzeResultTask.createObject || model.resultTask === enums.analyzeResultTask.categorizeTargetOrganization
								|| model.resultTask === enums.analyzeResultTask.uncategorizeTargetOrganization
								|| model.resultTask === enums.analyzeResultTask.createInfluences
								|| model.resultTask === enums.analyzeResultTask.deleteInfluences
                            ) {
                                model.resultTaskValue = JSON.stringify(item.jsonResultTaskValue)
                            }
                            else if (model.resultTask === 0) {
                                model.resultTaskValue = null
                            }

                            if (!model.resultTaskValue) model.resultTaskValue = null

                            if (item.wasAdded) {
                                dataOps.create(model).then((res) => {
                                    item.actualObject = res
                                    saveNextRequirement()
                                    resetItemStates(item)
                                })
                            }
                            else if (item.actualObject) { // Was updated
                                model.id = item.actualObject.id
                                model.wfid = item.actualObject.wfid

                                dataOps.update(model).then((res) => {
                                    item.actualObject = res
                                    saveNextRequirement()
                                    resetItemStates(item)
                                })
                            }

                        }
                    }
                    else {
                        resolve()
                        finish()
                        $timeout()
                    }
                }

                function finish() {
                    close()
                }

                function resetItemStates(item) {
                    item.wasAdded = false
                    item.wasChanged = false
                    item.wasRemoved = false
                }
            })
        }

        function reorderItemsAndSyncView() {
            vm.viewItems = _.filter(vm.viewItems, { wasRemoved: false })

            _.each(vm.viewItems, (item, index) => {
                if (item.model.order != index + 1) {
                    item.model.order = index + 1
                    item.wasChanged = true
                }
            })

            if (vm.debug) console.table(_.map(vm.viewItems, 'model'))
        }

        function close() {
            vm.saving = false
            $scope.$parent.$parent.result.requirementsWereChanged = changesMadeAndSaved

            $scope.$parent.$close({
                requirementsWereChanged: changesMadeAndSaved,
            })
            $timeout()
        }

        function onNumberInputBlur(obj, prop) {
            obj[prop] = handleStringAsNumber(obj[prop], true)
        }

        function selectAdditionalTask_createMeasureAnswer(item) {

            modal.openCreatorAndPicker({
                singlePick: true,
                relationBucket: { preSelected: item.selectedMeasure ? [item.selectedMeasure] : [], allSelected: item.selectedMeasure ? [item.selectedMeasure] : [] },
                objectTypes: [enums.objectType.measure],
            }).closed((relationBucketResult) => {
                const selectedItem = relationBucketResult.allSelected[0]

                item.wasChanged = true
                item.selectedMeasure = selectedItem

                if (selectedItem) {
                    item.jsonResultTaskValue = {
                        type: enums.objectType.measureAnswer,
                        measureId: _.get(item.selectedMeasure, 'id') || 0,
                        useTargetOrganizationAsRelationContextParent: true,
                        createVisibilityTag: true,
                    }
                }
                else {
                    item.jsonResultTaskValue = {
                        type: enums.objectType.measureAnswer,
                        measureId: 0,
                        useTargetOrganizationAsRelationContextParent: true,
                        createVisibilityTag: true,
                    }
                }

                onRequirementChange(item) // To grab measure period settings from seleceted requirement and put it on item.jsonResultTaskValue;
            })
        }

        function jsonResultTaskValue_createMeasureAnswer(item, analyzeReq) {
            let output
            try {
                output = JSON.parse(analyzeReq.resultTaskValue)
                if (output && output.measureId) {
                    dataOps.getObject('21-' + output.measureId).then((res) => {
                        item.selectedMeasure = res
                        item.selectedMeasurePeriodName = _.get(_.find(availableRequirementOptions, {
                            periodStartDate: item.jsonResultTaskValue.periodStartDate,
                            periodEndDate: item.jsonResultTaskValue.periodEndDate,
                            periodSettingsObjectWfid: item.jsonResultTaskValue.periodSettingsObjectWfid || undefined,
                            intervalNameSpecification: item.jsonResultTaskValue.intervalNameSpecification || undefined,
                        }), 'periodName')
                    })
                }
                return JSON.parse(analyzeReq.resultTaskValue)
            }
            catch (e) {
                return null
            }
        }

        function jsonResultTaskValue_targetOrganizationCategorization_init(item, analyzeReq) {
            let output
            try {
                output = JSON.parse(analyzeReq.resultTaskValue)
                if (output && output.categoryIds) {
                    const categoryWfids = output.categoryIds.map(x => '71-' + x)
                    dataOps.getObjects(categoryWfids, { skipExtras: true }).then((res) => {
                        item.selectedCategories = res
                        item.selectedCategoryIds = item.selectedCategories.map(x => x.id)
                        item.selectedCategoriesText = item.selectedCategories.map(x => x.title).join(', ')

                        item.jsonResultTaskValue = {
                            categoryIds: item.selectedCategories.map(x => x.id),
                        }
                    })
                }
                return JSON.parse(analyzeReq.resultTaskValue)
            }
            catch (e) {
                return null
            }
        }

        function additionalTask_targetOrganizationCategorization_onFiltered(filteredItems, selectedOptions) {
            const item = this

            item.selectedCategories = selectedOptions.map(x => x.content)
            item.selectedCategoryIds = item.selectedCategories.map(x => x.id)
            item.selectedCategoriesText = item.selectedCategories.map(x => x.title).join(', ')

            item.jsonResultTaskValue = {
                categoryIds: item.selectedCategories.map(x => x.id),
            }

            onRequirementChange(item)
        }

        function additionalTask_createInfluences_init(item, analyzeReq) {
            let output
            try {
                output = JSON.parse(analyzeReq.resultTaskValue)
                if (output && output.categoryIds) {
                    let categoryWfids = output.categoryIds.map(x => '71-' + x)
                    Array.prototype.push.apply(categoryWfids, output.invertedCategoryIds.map(x => '71-' + x))
                    categoryWfids = _.uniq(categoryWfids)

                    dataOps.getOrganizationUsers().then((users) => {
                        item.creatorUserName = _.get(users.find(x => x.id === output.creatorUserId), 'name')

                        dataOps.getObjects(categoryWfids, { skipExtras: true }).then((res) => {
                            item.categoryTitles = res.filter(x => output.categoryIds && output.categoryIds.includes(parseInt(x.id))).map(x => x.title).join(', ')
                            item.invertedCategoryTitles = res.filter(x => output.invertedCategoryIds && output.invertedCategoryIds.includes(parseInt(x.id))).map(x => x.title).join(', ')

                            valueChainService.loadPackagesInNetwork(vm.ticket.networkId, false).then((relations) => {
                                item.objectTitles = relations.filter(x => output.objectIds.includes(x.childId)).map(x => x.childContent.title).join(', ')
                                item.resultTaskLoaded = true
                                $timeout()
                            })
                        })
                    })
                }
                return JSON.parse(analyzeReq.resultTaskValue)
            }
            catch (e) {
                return null
            }
        }

        function additionalTask_createInfluences_setup() {
            const item = this
            return valueChainService.openInfluencesMultiEditorConfigurator({ creation: true, networkId: vm.ticket.networkId, initialValues: item.jsonResultTaskValue }).then((res) => {
                if (res) {
                    if (!item.jsonResultTaskValue) item.jsonResultTaskValue = {}

                    _.assign(item.jsonResultTaskValue, {
                        creatorUserId: res.creatorUserId,
                        activatedAtDaysFromNow: res.activatedAtDaysFromNow,
                        fulfillmentDueAtDaysFromNow: res.fulfillmentDueAtDaysFromNow,
                        categoryIds: res.categoryIds,
                        invertedCategoryIds: res.invertedCategoryIds,
                        objectIds: res.objectIds,
                    })

                    _.assign(item, {
                        objectTitles: res.objectTitles.join(', '),
                        categoryTitles: res.categoryTitles && res.categoryTitles.join(', '),
                        invertedCategoryTitles: res.invertedCategoryTitles && res.invertedCategoryTitles.join(', '),
                        creatorUserName: res.creatorUserName,
                    })
                }
            })
        }

        function additionalTask_deleteInfluences_init(item, analyzeReq) {
            let output
            try {
                output = JSON.parse(analyzeReq.resultTaskValue)
                if (output && output.categoryIds) {
                    let categoryWfids = output.categoryIds.map(x => '71-' + x)
                    Array.prototype.push.apply(categoryWfids, output.invertedCategoryIds.map(x => '71-' + x))
                    categoryWfids = _.uniq(categoryWfids)

                    dataOps.getObjects(categoryWfids, { skipExtras: true }).then((res) => {
                        item.categoryTitles = res.filter(x => output.categoryIds && output.categoryIds.includes(parseInt(x.id))).map(x => x.title).join(', ')
                        item.invertedCategoryTitles = res.filter(x => output.invertedCategoryIds && output.invertedCategoryIds.includes(parseInt(x.id))).map(x => x.title).join(', ')

                        valueChainService.loadPackagesInNetwork(vm.ticket.networkId, false).then((relations) => {
                            item.objectTitles = relations.filter(x => output.objectIds.includes(x.childId)).map(x => x.childContent.title).join(', ')
                            item.resultTaskLoaded = true
                            $timeout()
                        })
                    })
                }
                return JSON.parse(analyzeReq.resultTaskValue)
            }
            catch (e) {
                return null
            }
        }

        function additionalTask_deleteInfluences_setup() {
            const item = this
            return valueChainService.openInfluencesMultiEditorConfigurator({ deletion: true, networkId: vm.ticket.networkId, initialValues: item.jsonResultTaskValue }).then((res) => {
                if (res) {
                    if (!item.jsonResultTaskValue) item.jsonResultTaskValue = {}

                    _.assign(item.jsonResultTaskValue, {
                        categoryIds: res.categoryIds,
                        invertedCategoryIds: res.invertedCategoryIds,
                        objectIds: res.objectIds,
                    })

                    _.assign(item, {
                        objectTitles: res.objectTitles.join(', '),
                        categoryTitles: res.categoryTitles && res.categoryTitles.join(', '),
                        invertedCategoryTitles: res.invertedCategoryTitles && res.invertedCategoryTitles.join(', '),
                    })
                }
            })
        }
    }
})()
