import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfFileViewer', {
            templateUrl: 'scripts/wf/file/wfFileViewer.component.html',
            controller: wfFileViewerController,
            controllerAs: 'vm',
            bindings: {
                filename: '<',
            },
        })

    const imageFileTypes = ['jpg', 'png', 'jpeg', 'jfif', 'tif', 'tiff', 'gif', 'bmp', 'svg', 'exif', 'ppm', 'pgm', 'pbm', 'pnm']
    const plainTextFileTypes = ['csv', 'txt']
    const inlineViewableFileTypes = ['pdf', ...plainTextFileTypes, ...imageFileTypes]

    wfFileViewerController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService', 'wfAuth']
    function wfFileViewerController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService, wfAuth) {
        const vm = this

        vm.loading = true
        vm.downloadFile = downloadFile
        vm.signOut = signOut
        vm.goHome = goHome

        let fileNamePart = ''
        let fileType = ''
        let filenameWithoutGuid
        let guid = ''
        let isValidParams
        let _blob

        $('body').css('overflow', 'hidden')

        let blobUrl

        this.$onInit = $onInit

        function $onInit() {
            try {
                const lastIndexOf__ = vm.filename.lastIndexOf('__')
                fileNamePart = vm.filename.substring(0, lastIndexOf__)
                const guidAndFileType = vm.filename.substring(lastIndexOf__ + 2)

                const splitDot = (guidAndFileType || '').split('.')
                guid = splitDot[0] || ''
                fileType = splitDot[1] || ''
                filenameWithoutGuid = `${fileNamePart}.${fileType}`

                isValidParams = fileNamePart && fileType && guid
            }
            // eslint-disable-next-line no-empty
            catch {
                isValidParams = false
            }

            vm.title = filenameWithoutGuid
            document.title = filenameWithoutGuid

            if (!isValidParams) {
                showErrorInformation('Not loaded', 'Invalid parameters')
                return
            }

            dataOps.fetchUploadedFile(vm.filename)
                .then(({ blob }) => {
                    vm.loading = false
                    _blob = blob

                    vm.viewableInline = inlineViewableFileTypes.includes(fileType)
                    if (vm.viewableInline) {
                        showFileInline()
                    }
                    else {
                        downloadFile()
                    }
                })
                .catch(({ httpStatus }) => {
                    showErrorInformation(httpStatus, {
                        204: 'File not found',
                        403: 'Unauthorized file access',
                        401: 'User not authenticated',
                    }[httpStatus] || '')
                })
        }

        function showErrorInformation(httpStatus, additionalInformation) {

            vm.loading = false
            vm.errorInformation = [
                `HTTP: ${httpStatus || 'undefined'}`,
                `User: ${wfAuth.getWorldfavorUserId() || 'undefined'}`,
                `Organization: ${wfAuth.getOrganizationId() || 'undefined'}`,
                `File type: ${fileType || 'undefined'}`,
                `File name: ${fileNamePart || 'undefined'}`,
                `GUID: ${guid || 'undefined'}`,
                `Param: ${vm.filename}`,
                additionalInformation ? `\n${additionalInformation}` : '',
            ].join('\n')
            vm.failed = true
            vm.viewableInline = false
        }

        function showFileInline() {
            try {
                blobUrl = URL.createObjectURL(_blob)

                if (imageFileTypes.includes(fileType)) {
                    vm.isImage = true
                }

                $element.find('iframe').attr('src', blobUrl)
            }
            catch (e) {
                showErrorInformation(200, `Exception in showFileInline\n${e}`)
            }
        }

        function downloadFile() {
            try {
                blobUrl = URL.createObjectURL(_blob)
                // use HTML5 a[download] attribute to specify filename
                const a = document.createElement('a')

                a.href = blobUrl
                if (typeof a.download !== 'undefined') {
                    a.download = vm.filename
                }
                a.click()

                vm.downloaded = true

                setTimeout(() => { URL.revokeObjectURL(blobUrl) }, 100) // Cleanup

                return $q((resolve) => {
                    setTimeout(() => resolve(), 1000)
                })
            }
            catch (e) {
                showErrorInformation(200, `Exception in downloadFile\n${e}`)
            }
        }

        function signOut() {
            return $q(() => {
                wfAuth.signOut(true)
            })
        }

        function goHome() {
            document.location = '/'
        }
    }
})()
