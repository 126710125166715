import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfQuestionAnswering', wfQuestionAnswering)

    wfQuestionAnswering.$inject = ['$parse', 'dataQuery', 'dataOperationsService', 'moment', 'wfAuth', '$compile', '$templateCache', 'modalService', '$timeout', '$ngBootbox', '$sanitize', '$translate', '$q', 'wfObject', 'requirements', '$interpolate', 'wfPropertyExtractor']
    function wfQuestionAnswering($parse, dataQuery, dataOps, moment, wfAuth, $compile, $templateCache, modal, $timeout, $ngBootbox, $sanitize, $translate, $q, wfObject, requirements, $interpolate, wfPropertyExtractor) {
        const
            templateUrl = 'scripts/wf/answering/wfQuestionAnswering.directive.html'
			
        let templateContent
		
        const directive = {
            restrict: 'EA',
            require: '?^^wfQuestionAnsweringManager',
            templateUrl,
            link,
            controllerAs: 'questionAnsweringVm',
            controller: WfQuestionAnsweringController,
        }

        return directive

        function link(scope, element, attrs, wfQuestionAnsweringManagerCtrl) {
            const
                questionAnsweringVm = scope.questionAnsweringVm
				
            const html = templateContent || (templateContent = $templateCache.get(templateUrl))
				
            let question
				
            let requirement
				
            let localFulfillmentElement
				
            let fulfillsLocally
				
            let validValues = []
				
            let settings
				
            let latestAnswerContent
				
            let latestAnswerBtn
				
            let latestAnswerDataRelation
				
            let latestAnswerChildContent
				
            let latestAnswerId
				
            let isAnswerValid
				
            let allButtons
				
            let busy
				
            let organizationId
				
            let networkId
				
            let influence
				
            const questionAnswersTypes = {}
				
            const manager = wfQuestionAnsweringManagerCtrl
				
            let item
				
            let questionDataRelation
				
            let itemComposite
				
            let displayMode = null
				
            let isViewMode = false
				
            let verification
				
            const intersectSource = []
				
            let context
				
            let contextParentWfids
				
            const fulfillmentSpec = {}
				
            const useContextAwareRelations = true
				
            let ticket
				
            let fulfillmentResult
			
            // console.log(scope.$id, "link");//, answeringVm, arguments);
            if (!questionAnsweringVm || !questionAnsweringVm.questionDataRelation || !questionAnsweringVm.question || questionAnsweringVm.question.type != 11) return

            item = questionDataRelation = questionAnsweringVm.questionDataRelation
            // console.log("activating question answering");
            questionAnsweringVm.setSelectedAnswer = setSelectedAnswer

            question = questionAnsweringVm.question
            settings = item.settings

            // If intersected in back-end then the originalRelation is on the questionDataRelation (that is a virtualDataRelation)
            if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === 'string') {
                settings = item.originalRelation.settings
            }

            if (attrs.mode) displayMode = attrs.mode

            if (attrs.uiMode && $parse(attrs.uiMode)(scope) === enums.uiMode.view) displayMode = 'view'

            if (attrs.verification) verification = $parse(attrs.verification)(scope)

            if ('itemComposite' in attrs) itemComposite = $parse(attrs.itemComposite)(scope)

            // If intersected in front-end then the originalRelation is on the itemComposite
            if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings && itemComposite.originalRelation.settings.contextParentWfids) {
                contextParentWfids = itemComposite.originalRelation.settings.contextParentWfids
            }
            else if (settings) {
                // Else, use the settings object that we already have
                contextParentWfids = settings.contextParentWfids
            }

            questionAnsweringVm.context = context = (scope.vm ? scope.vm.context : {}) || {}
            questionAnsweringVm.addRelatedContentStatementOnAnswer = addRelatedContentStatementOnAnswer
            questionAnsweringVm.addRelatedContentOnAnswer = addRelatedContentOnAnswer
            questionAnsweringVm.onRelatedContentRemoved = onRelatedContentRemoved
            questionAnsweringVm.makeNewestAnswerVisible = makeNewestAnswerVisible

            if (!context.aggregateQuestionAnswers)
            {
                if (!wfAuth.canUserWrite()) isViewMode = true

                if (context && context.isViewMode)
                {
                    isViewMode = context.isViewMode
                }

                if (context && context.influence)
                {
                    influence = context.influence
                    organizationId = influence.organizationId
                    networkId = influence.channelId
                    getRequirement()
                    validValues = requirement && requirement.value ? requirement.value.toString().split(',') : []

                    // scope.allAnswers = dataQuery.getAll.intersectedChildrenOf(
                    // 	[ question, { 'childContent.organizationId': organizationId } ],
                    // 	// wfObject.get('52-' + influence.channelId)
                    // );
                }
                else if ('influence' in attrs && (influence = $parse(attrs.influence)(scope))) {
                    organizationId = influence.organizationId
                    networkId = influence.channelId
                    getRequirement()
                    validValues = requirement && requirement.value ? requirement.value.toString().split(',') : []
                }
                else if ('ticket' in attrs) {
                    ticket =  $parse(attrs.ticket)(scope)
                    organizationId = ticket.organizationId
                    networkId = ticket.networkId

                    if (ticket.contextParentWfid) contextParentWfids = [ticket.contextParentWfid]
                }
                else if (context && context.verification)
                {
                    organizationId = context.verification.organizationId
                }
                else
                {
                    organizationId = wfAuth.getOrganizationId() || null
                }

                if (influence && !contextParentWfids && influence.contextParentWfids) contextParentWfids = influence.contextParentWfids.split(',')

                if (!requirement && itemComposite) {
                    getRequirement()
                    validValues = requirement && requirement.value ? requirement.value.toString().split(',') : []
                }

                if (itemComposite) {
                    scope.$on('requirementChanged', (event, updatedRequirement) => {
                        if (updatedRequirement && questionDataRelation && updatedRequirement.wffid_requirement !== questionDataRelation.wfid) return

                        getRequirement()

                        if (requirement) {
                            validValues = requirement && requirement.value ? requirement.value.toString().split(',') : []
                            fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                        }
                        else {
                            fulfillsLocally = null
                            fulfillmentSpec.state = null
                        }

                        scope.$broadcast('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: _.get(fulfillmentResult, 'fulfillmentState') })

                        if (manager)
                        {
                            // console.log('scope.$on("requirementChanged"): manager.update(...)', question.wfid, fulfillsLocally, requirement);
                            manager.update(question.wfid, {
                                fulfillsLocally,
                                requirement,
                            }, false, organizationId, networkId, contextParentWfids)
                        }
                    })
                }

                latestAnswerDataRelation = questionAnsweringVm.latestAnswerDataRelation = getLatestQuestionAnswer()

                if (settings && settings.limitQuestionAnswerTypes)
                {
                    buildAnswerTypeButtonsHtml(settings.limitQuestionAnswerTypes)
                    // element.find('div.btn').each(function () {
                    // 	if (settings.limitQuestionAnswerTypes.indexOf($(this).data().id) == -1)
                    // 		$(this).remove();
                    // });
                }
                else {
                    buildAnswerTypeButtonsHtml()
                }

                element.children('div.btn-group').html(scope.questionAnsweringVm.answerTypeButtonsHtml)

                if (latestAnswerDataRelation)
                {

                    latestAnswerContent = questionAnsweringVm.latestAnswerContent = (latestAnswerDataRelation ? latestAnswerDataRelation.childContent : null)

                    latestAnswerId = latestAnswerContent ? latestAnswerContent.questionAnswerTypeId : null;
                    (latestAnswerBtn = element.find('div.btn._' + latestAnswerId)).addClass('btn-primary selected').removeClass('btn-white')

                }
                updateValidAnswerStatus(validValues)

                // console.log(organizationId);
                if ((wfAuth.getOrganizationId() == organizationId && displayMode !== 'view' && !isViewMode)) {
                    scope.isByAutheticatedOrg = true
                    element.on('click', 'div.btn', function() {
                        const
                            btn = $(this)
							
                        const questionAnswerTypeId = btn.data().id

                        if (!allButtons) allButtons = btn.siblings().andSelf()

                        if (busy || btn.hasClass('selected')) return
                        busy = true

                        // console.log(btn.data().id, 'networkId', context.networkId);
                        allButtons.removeClass('btn-primary selected').addClass('btn-white')
                        btn.addClass('btn-primary selected').removeClass('btn-white')

                        btn.addClass('loading')
                        element.find('div.invisible-answer-available').hide()
                        dataOps.createAnswerOnQuestion({ id: questionAnswerTypeId }, question, {
                            influence,
                            networkId,
                            contextParents: contextParentWfids,
                            useContextAwareRelations,
                        }).then((res) => {
                            busy = false
                            res.createdAt = moment().format()
                            btn.removeClass('loading')
                            latestAnswerId = btn.data().id

                            scope.questionAnsweringVm.allAnswers.unshift(res)

                            setSelectedAnswer(res)

                            // if (requirement && requirement.rule == enums.requirementRule.preferredValue && !_.includes(validValues, res.childContent.questionAnswerTypeId.toString()) && influence) {
                            // 	modal.openCreatorAndPicker({
                            // 		influence: influence,
                            // 		pick: false,
                            // 		objectTypes: [ 44 ],
                            // 		// compilerControl: vm.listControls[vm.mainStructure.wfid],
                            // 		relationTarget: { item: res.childContent, kind: 5},
                            // 		title: 'Lägg till kommentar',
                            // 		noFormHeader: true,
                            // 		submitCaption: 'Lägg till'
                            // 	}).modal.closed.then(function () {
                            // 		fulfillsLocally = answersMatchesRequirement(res, validValues, localFulfillmentElement, requirement)
                            // 		if (manager)
                            // 		{
                            // 			manager.update(question.wfid, {
                            // 				fulfillsLocally: fulfillsLocally,
                            // 				latestAnswerId: latestAnswerId
                            // 			});
                            // 		}
                            // 	});
                            // }

                            // console.log(scope, scope.questionAnsweringHistory)
                            if (scope.questionAnsweringHistory) {
                                scope.questionAnsweringHistory.updateHistory()
                            }
                        })
                    })
                }
                else {
                    scope.isByAutheticatedOrg = false
                    element.addClass('view')
                    isViewMode = true
                }

                questionAnsweringVm.isViewMode = isViewMode

                // console.log(scope, scope.yeah, scope.activateQuestionAnsweringHistory);
                if (scope.activateQuestionAnsweringHistory) {
                    scope.activateQuestionAnsweringHistory()
                }
            }

            if (requirement) {
                localFulfillmentElement = element.find('div.localFulfillment')

                fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { isInitialFulfillmentCheck: true, fulfillmentState: fulfillmentResult.fulfillmentState })
            }

            if (manager) {
                manager.insert(question.wfid, {
                    requirement,
                    fulfillsLocally,
                    latestAnswerId,
                    questionDataRelation,
                    question,
                    itemComposite,
                })
            }

            function getRequirement() {
                return requirement = requirements.getActualRequirement({
                    itemContent: question,
                    itemRelation: questionDataRelation,
                    itemComposite,
                    organizationId,
                })
            }

            function setSelectedAnswer(answer) {
                latestAnswerDataRelation = questionAnsweringVm.latestAnswerDataRelation = answer
                latestAnswerContent = questionAnsweringVm.latestAnswerContent = (latestAnswerDataRelation ? latestAnswerDataRelation.childContent : null)
                latestAnswerId = latestAnswerContent ? latestAnswerContent.questionAnswerTypeId : null
                updateValidAnswerStatus(validValues)

                element.find('div.invisible-answer-available').remove()
                scope.questionAnsweringVm.newerAnswerExists = false
                scope.questionAnsweringVm.newerAnswerDataRelation = undefined

                if (!allButtons) allButtons = element.find('div.btn')

                if (latestAnswerId) {
                    (latestAnswerBtn = allButtons.filter('._' + latestAnswerId)).addClass('btn-primary selected').removeClass('btn-white').siblings().removeClass('btn-primary selected').addClass('btn-white')

                    if (!questionAnswersTypes[latestAnswerId]) {
                        questionAnswersTypes[latestAnswerId] = wfObject.get('83-' + latestAnswerId)
                    }

                }
                else
                {
                    allButtons.removeClass('btn-primary selected').addClass('btn-white')
                }

                // element.html(html);
                // $compile(element.contents())(scope);

                if (context && context.sunburstControl && context.sunburstControl.setQuestionAnswerState) {
                    context.sunburstControl.setQuestionAnswerState(question.wfid, questionAnswersTypes[latestAnswerId])
                }
                if (requirement) {
                    fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                    scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: fulfillmentResult.fulfillmentState })
                }

                updateLatestAnswerRelatedContent()

                if (manager)
                {
                    manager.update(question.wfid, {
                        fulfillsLocally,
                        latestAnswerId,
                        latestAnswerDataRelation: latestAnswerDataRelation || undefined,
                        latestAnswerVisibilityTag: latestAnswerDataRelation ? getVisibilityTag(latestAnswerDataRelation.childId) : undefined, // Used in manager.getNotVisibleLatestQuestionAnswer
                        // latestAnswerContextParentRelations: manager.getContextParents(latestAnswerDataRelation.childId, contextParentWfids),
                        latestQuestionAnswerType: questionAnswersTypes[latestAnswerId],
                    }, false, organizationId, networkId, contextParentWfids)
                }
            }

            function getVisibilityTag(questionAnswerId) {
                if (!networkId) return
                else {
                    return wfObject.filter({ where: {
                        type: enums.objectType.visibilityTag,
                        networkId,
                        objectType: enums.objectType.questionAnswer,
                        objectId: questionAnswerId,
                        organizationId,
                    } })[0]
                }
            }

            function getLatestQuestionAnswer() {
                let managerResult; let answerDataRelationWithoutVisibility; let newerQuestionAnswer
                // console.time("intersect answer on " + question.wfid);

                if (manager)
                {
                    managerResult = manager.getLatestQuestionAnswer(
                        question,
                        organizationId,
                        context && context.verification ? context.verification : null,
                        networkId,
                        contextParentWfids,
                    )
                    scope.questionAnsweringVm.allAnswers = managerResult.allAnswersOnQuestion
                    latestAnswerDataRelation = managerResult.latestAnswerDataRelation

                    if (networkId && !isViewMode) {
                        answerDataRelationWithoutVisibility = manager.getNotVisibleLatestQuestionAnswer(question, organizationId, context && context.verification ? context.verification : null, networkId, contextParentWfids)
                        // Earlier, contextParentWfids was also passed into manager.getNotVisibleLatestQuestionAnswer but we don't want to show if there are newer answers in another contextParent.
                        // Answer are always only ment for the contextParent they were answered in.

                        scope.questionAnsweringVm.newerAnswerExists = !!answerDataRelationWithoutVisibility
                        if (scope.questionAnsweringVm.newerAnswerExists) {
                            newerQuestionAnswer = answerDataRelationWithoutVisibility.childContent
                            scope.questionAnsweringVm.newerAnswerDataRelation = answerDataRelationWithoutVisibility

                            if (!questionAnswersTypes[newerQuestionAnswer.questionAnswerTypeId]) {
                                questionAnswersTypes[newerQuestionAnswer.questionAnswerTypeId] = wfObject.get('83-' + newerQuestionAnswer.questionAnswerTypeId)
                            }
                            scope.questionAnsweringVm.newerAnswerContentText = questionAnswersTypes[newerQuestionAnswer.questionAnswerTypeId].text
                        }
                        // console.log("Newer answer exist:", !!answerDataRelationWithoutVisibility, answerDataRelationWithoutVisibility);
                    }
                }
                else
                {
                    intersectSource.push([question, { 'childContent.organizationId': organizationId }])

                    scope.questionAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource)
                    if (scope.questionAnsweringVm.allAnswers.length) {
                        if (context && context.verification)
                        {
                            scope.questionAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(scope.questionAnsweringVm.allAnswers, context.verification)
                        }

                        scope.questionAnsweringVm.allAnswers = _.orderBy(scope.questionAnsweringVm.allAnswers, ['createdAt'], ['desc'])
                    }
                    latestAnswerDataRelation = scope.questionAnsweringVm.allAnswers[0]
                }

                scope.isQuestion = true

                // if (!scope.allAnswers)
                // {
                // 	console.log("NO", scope);
                // }
                // console.timeEnd("intersect answer on " + question.wfid);

                return latestAnswerDataRelation
            }

            questionAnsweringVm.updateLatestQuestionAnswer = updateLatestQuestionAnswer
            function updateLatestQuestionAnswer() {
                intersectSource.push([question, { 'childContent.organizationId': organizationId }])

                scope.questionAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource)
                if (scope.questionAnsweringVm.allAnswers.length) {
                    if (context && context.verification)
                    {
                        scope.questionAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(scope.questionAnsweringVm.allAnswers, context.verification)
                    }

                    scope.questionAnsweringVm.allAnswers = _.orderBy(scope.questionAnsweringVm.allAnswers, ['createdAt'], ['desc'])
                }
                latestAnswerDataRelation = scope.questionAnsweringVm.allAnswers[0]

                if (requirement) {
                    fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                    scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: fulfillmentResult.fulfillmentState })
                }

                latestAnswerId = latestAnswerDataRelation ? latestAnswerDataRelation.childContent.questionAnswerTypeId : undefined

                if (manager) {
                    manager.update(question.wfid, {
                        fulfillsLocally,
                        latestAnswerDataRelation,
                        latestAnswerId,
                    }, true, organizationId)
                }

                latestAnswerContent = getLatestQuestionAnswer()
                questionAnsweringVm.setSelectedAnswer(latestAnswerContent)
            }

            function updateValidAnswerStatus(validValues) {
                if (latestAnswerContent) {
                    if (!validValues || validValues.length) isAnswerValid = _.includes(validValues, latestAnswerContent.questionAnswerTypeId.toString())
                    else isAnswerValid = true
                }
                else {
                    isAnswerValid = false
                }

                questionAnsweringVm.isAnswerValid = isAnswerValid
            }

            function updateLatestAnswerRelatedContent() {
                if (questionAnsweringVm.latestAnswerContent) questionAnsweringVm.latestAnswerRelatedContent = questionAnsweringVm.latestAnswerContent.filterRelatedContentByUser({ organizationId, wfxpid: _.get(contextParentWfids, '[0]') })
                else questionAnsweringVm.latestAnswerRelatedContent = []
            }

            function addRelatedContentStatementOnAnswer(options) {
                if (!latestAnswerContent) return

                modal.openCreatorAndPicker({
                    influence,
                    pick: false,
                    objectTypes: [44],
                    // compilerControl: vm.listControls[vm.mainStructure.wfid],
                    relationTarget: { item: latestAnswerContent, kind: 5 },
                    title: $translate.instant('modules.valueChain.influence.addComment'),
                    noFormHeader: true,
                    submitCaption: $translate.instant('Add'),
                    intersection: {
                        contextParents: contextParentWfids,
                        organizationId,
                        networkId,
                    },
                }).modal.closed.then(() => {
                    updateLatestAnswerRelatedContent()

                    fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                    scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: fulfillmentResult.fulfillmentState })

                    if (manager)
                    {
                        manager.update(question.wfid, {
                            fulfillsLocally,
                            latestAnswerId,
                        })
                    }

                    if (options && options.compiler) options.compiler.compile()
                })
            }

            function addRelatedContentOnAnswer(options) {
                let
                    message
					
                const hasRequirement = requirement && (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.specificValueWithRelatedContent
                )

                if (!latestAnswerContent || _.get(fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.expired) {
                    message = $translate.instant('modules.valueChain.influence.haveToAnswerQuestionBeforeAddingInfo')

                    $ngBootbox.customDialog({
                        message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $sanitize(message) + '</div>',
                        // onEscape: true,
                        closeButton: false,
                        className: 'centerWithIcon',
                        buttons: {
                            primary: {
                                label: $translate.instant('OK'),
                                className: 'btn-primary',
                            },
                        },
                    })
                    return
                }

                const modalOptions = {
                    influence,
                    pick: options.objectType === enums.objectType.certificate || options.objectType === enums.objectType.country,
                    singlePick: true,
                    objectTypes: [options.objectType],
                    // compilerControl: vm.listControls[vm.mainStructure.wfid],
                    relationTarget: { item: latestAnswerContent, kind: 5 },
                    title: options.modalTitle,
                    noFormHeader: true,
                    submitCaption: $translate.instant('Add'),
                    intersection: {
                        contextParents: contextParentWfids,
                        organizationId,
                        networkId,
                    },
                }

                if (options.objectType === enums.objectType.country) {
                    _.assign(modalOptions, {
                        create: false,
                        closeCaption: $translate.instant('Done'),
                    })
                }

                modal.openCreatorAndPicker(modalOptions).modal.closed.then(() => {
                    if (hasRequirement) {
                        fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                        scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: fulfillmentResult.fulfillmentState })

                        if (manager)
                        {
                            manager.update(question.wfid, {
                                fulfillsLocally,
                                latestAnswerId,
                            })
                        }
                    }

                    if (options && options.compiler) options.compiler.compile()
                })
            }

            function onRelatedContentRemoved() {
                if (!latestAnswerContent.metadata) latestAnswerContent.metadata = { countByRelationKind: {} }

                questionAnsweringVm.latestAnswerRelatedContent = questionAnsweringVm.latestAnswerContent.filterRelatedContentByUser({ organizationId, wfxpid: _.get(contextParentWfids, '[0]') })

                latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] = questionAnsweringVm.latestAnswerRelatedContent.length

                if (requirement) {
                    fulfillsLocally = answersMatchesRequirement(latestAnswerDataRelation, validValues, localFulfillmentElement, requirement, fulfillmentSpec, questionDataRelation)
                    scope.$emit('localFulfillmentChanged', question, fulfillmentSpec.state, { fulfillmentState: fulfillmentResult.fulfillmentState })
                }

                if (manager)
                {
                    manager.update(question.wfid, {
                        fulfillsLocally,
                        latestAnswerId,
                    })
                }
            }

            function makeNewestAnswerVisible() {
                const
                    newerAnswerContent = scope.questionAnsweringVm.newerAnswerDataRelation.childContent
					
                let visibilityTag
					
                let existingContextParentWfids
					
                let createRelationsForContextParentWfids
					
                const promises = []

                busy = true
                return $q((resolve, reject) => {
                    if (networkId) {
                        visibilityTag = _.find(newerAnswerContent.visibility, { networkId })
                        if (!visibilityTag) {
                            // dataOps.getObject({
                            // 	objectType: enums.objectType.network,
                            // 	objectId: networkId
                            // }).then(function (network) {
                            promises.push(dataOps.createSubItemRelation(newerAnswerContent, '52-' + networkId, enums.subItemsKind.visible))
                            // });
                        }
                    }

                    if (contextParentWfids) {
                        existingContextParentWfids = _.chain(newerAnswerContent.contextParents, { wffid: { in: contextParentWfids } }).map('wffid').value()
                        createRelationsForContextParentWfids = _.difference(contextParentWfids, existingContextParentWfids)

                        _.each(createRelationsForContextParentWfids, (contextParentWfid) => {
                            promises.push(dataOps.createSubItemRelation(newerAnswerContent, contextParentWfid, enums.subItemsKind.contextParents))
                        })
                    }

                    if (promises.length) {
                        $q.all(promises).then(() => {
                            setSelectedAnswer(scope.questionAnsweringVm.newerAnswerDataRelation)

                            resolve()
                        })
                    }
                    else resolve()
                })
            }

            function canHaveCertificateAttachments(questionDataRelation) {
                const objectTypes = _.get(questionDataRelation, 'settings.objectTypes')
                return objectTypes && objectTypes.length && objectTypes.indexOf(106) !== -1 // Certificate
            }

            function hasValidCertificate(questionAnswerContent) {
                const validCertificates = _.chain(questionAnswerContent.relatedContentByUser)
                    .filter({ childType: enums.objectType.certificate })
                    .filter((certificateDataRelation) => {
                        const certificate = certificateDataRelation.childContent

                        return moment().isBetween(certificate.validFromDate, certificate.validUntilDate)
                    }).value()

                return validCertificates.length !== 0
            }

            function answersMatchesRequirement(latestAnswerDataRelation, validValues, element, requirement, fulfillmentSpec, questionDataRelation) {
                const
                    latestAnswerContent = latestAnswerDataRelation ? latestAnswerDataRelation.childContent : null
					
                const answerId = latestAnswerDataRelation ? latestAnswerDataRelation.childContent.questionAnswerTypeId : null
					
                let fulfills = false
					
                const matchesRequiredValue = false
					
                const fulfillsException = false
					
                const isRelatedContentByUserCountOverZero = latestAnswerContent && latestAnswerContent.metadata && latestAnswerContent.metadata.countByRelationKind && latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] > 0

                fulfillmentResult = requirements.checkLocalFulfillment(question, questionDataRelation, requirement, {
                    contextParents: contextParentWfids,
                    organizationId,
                    networkId,
                }, { useDetailedResult: true })

                fulfills = fulfillmentResult.fulfills

                if (fulfillmentResult.fulfillmentState === enums.fulfillmentState.expired) {
                    if (latestAnswerBtn) latestAnswerBtn.removeClass('btn-primary selected').addClass('btn-white')
                }

                // if (requirement.rule === enums.requirementRule.manual) {
                // 	return true;
                // 	// if (questionDataRelation.fulfillment && questionDataRelation.fulfillment.fulfills)
                // 	// 	fulfills = true;
                // 	// else
                // 	// 	fulfills = false;
                // }
                // else {
                // 	if (element) {
                // 		element.toggleClass("fulfillsException", fulfillsException === true);// : '<i class="fa fa-times"></i>')
                // 		element.toggleClass("fulfills", fulfills === true);// : '<i class="fa fa-times"></i>')
                // 		element.toggleClass("unfulfills", !fulfills);// : '<i class="fa fa-times"></i>')
                // 	}
                // }

                if (fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded) fulfills = true

                if (fulfillmentResult.fulfillmentState === 'partiallyFulfilled') fulfills = true

                if (fulfillmentSpec) {
                    fulfillmentSpec.state = fulfills
                }

                $timeout()
                return fulfills
            }

            function buildAnswerTypeButtonsHtml(availableAnswerTypeIds) {
                let
                    output = ''
                    
                let interpolateFunc

                if ((wfAuth.getOrganizationId() == organizationId && displayMode !== 'view' && !isViewMode)) {
                    interpolateFunc = $interpolate('<div class="_{{answerTypeId}} btn btn-sm btn-white" data-id="{{answerTypeId}}">{{answerTypeText}}</div>')

                    if (!availableAnswerTypeIds || !availableAnswerTypeIds.length) availableAnswerTypeIds = [4, 3, 2, 1] // Use default (Processing, Not relevant, No, Yes)

                    for (var i = 0, len = availableAnswerTypeIds.length, answerTypeId; i < len; i++) {
                        answerTypeId = availableAnswerTypeIds[i]

                        output += interpolateFunc({
                            answerTypeId,
                            answerTypeText: wfPropertyExtractor.getQuestionAnswerTypeText(answerTypeId),
                        })
                    }
                }
                else
                {
                    if (latestAnswerDataRelation)
                    {
                        interpolateFunc = $interpolate('<div class="_{{answerTypeId}} text-bold" style="width:100%" data-id="{{answerTypeId}}">{{answerTypeText}}</div>')
                        output += interpolateFunc({
                            answerTypeId: latestAnswerDataRelation.childContent.questionAnswerTypeId,
                            answerTypeText: wfPropertyExtractor.getQuestionAnswerTypeText(latestAnswerDataRelation.childContent.questionAnswerTypeId),
                        })
                    }
                    else element.html('').append($('<div />').text('?').addClass('text-bold'))
                }

                scope.questionAnsweringVm.answerTypeButtonsHtml = output
            }
        }
    }

    WfQuestionAnsweringController.$inject = ['$scope', '$element', '$attrs', '$parse']
    function WfQuestionAnsweringController(scope, element, attrs, $parse) {
        const
            questionAnsweringVm = this
			
        let questionDataRelation
			
        let question

        if ('itemRelation' in attrs && 'itemContent' in attrs) {
            question = questionAnsweringVm.question = $parse(attrs.itemContent)(scope)
            questionAnsweringVm.questionDataRelation = $parse(attrs.itemRelation)(scope)
            if (!question || question.type != 11) return
        }
        else {
            if (attrs.item) questionDataRelation = $parse(attrs.item)(scope)
            else questionDataRelation = scope.vm.item

            if (questionDataRelation.childType != 11) return

            questionAnsweringVm.item = questionAnsweringVm.questionDataRelation = questionDataRelation
            question = questionAnsweringVm.question = questionDataRelation.childContent
        }

        if (scope.vm && scope.vm.context && scope.vm.context.aggregateQuestionAnswers) {
            questionAnsweringVm.stats = question.filterChildren({ type: 86, networkId: scope.vm.context.networkId, aggregate: 1, wffid: question.wfid })[0]
        }
    }
})()
