import * as enums from '@worldfavor/constants/enums'

/**
 * @ngdoc directive
 * @name wfObjectMetadata
 *
 * @description
 * Used to display a number of data and relations that belongs to an item and where it belongs itself.
 * Data and relations as: Content/Measures/Questions, Appears in, Attached Information, Added to, Linkages
 * It also has a link that opens the item in object viewer (in a new tab/window).
 * Additionaly, it has a link that expands the item component view and shows all user data (Show your data/Hide your data) using expandable-object-viewer attribute
 *
 * Metadata supports two different views:
 * STANDARD view (with/without creator user icon) & VERTICAL view showing data at a glance (with/without creator user)
 *
 * @param {WfObject=} item-content Accepting an item to be used as a main item.
 *
 * @param {Object} config Attribute that accepts additional configurations. Defined config objects - {Object} addActionByKind & {Object} intersectionChildrenByKind
 * @param {Array} limit-kinds Use this attribute to limit what kind of relations to show (i.e limitKinds="[13, 5]"). Numbers inside the array represent kinds that match subItemsKind in enums.service.js. Required attribute: use-vertical
 * @param {} use-vertical Switching to VERTICAL view
 * @param {} hide-creator Hides the creator icon
 * @param {} expandable-object-viewer Expanding item component view and showing all user data. Supported only in STANDARD view
 * @param {Number} ui-mode (goes optional with expandable-object-viewer), Supported modes are: view, work. View mode shows small wf-item-component without itemTools (dropdown), where Work mode shows itemTools (dropdown) and supports right click on the item
 * @param {} show-empty-kinds Showing all kinds, even though they are empty. Required attributes in order to work: use-vertical
 *
 * @example SIMPLE example - mandatory attributes
 *
 * <wf-object-metadata item-content="vm.itemContent"></wf-object-metadata>
 *
 * @example SHOWING/HIDING examples
 *
 * <wf-object-metadata item-content="vm.itemContent" hide-creator></wf-object-metadata>
 * <wf-object-metadata item-content="vm.itemContent" hide-creator expandable-object-viewer></wf-object-metadata>
 *
 * @example VERTICAL VIEW example - limiting kinds and adding additional configuration objects
 *
 * <wf-object-metadata item-content="vm.itemContent"
 * 	use-vertical
 * 	show-empty-kinds
 * 	config="{ addActionByKind:  vm.context.metadataAddActionsByKind, intersectionChildrenByKind: vm.context.metadataIntersectionChildrenByKind }"
 * ></wf-object-metadata>
 *
 * @example EXPANDABLE OBJECT VIEWER & UI Mode
 * <wf-object-metadata item-content="vm.itemContent" expandable-object-viewer></wf-object-metadata>  //BY DEFAULT - VIEW MODE
 * <wf-object-metadata item-content="vm.itemContent" expandable-object-viewer ui-mode="1"></wf-object-metadata>  //VIEW MODE
 * <wf-object-metadata item-content="vm.itemContent" expandable-object-viewer ui-mode="2"></wf-object-metadata>  //WORK MODE
 *
 */

(function () {
    'use strict'

    let relationsToDefine; let relationsByKind; let defineObjTypes; let objTypeSettings; const openPopovers = []; const soonOpenPopovers = []; let timeout

    angular
        .module('wf.common')
        .directive('wfObjectMetadata', wfObjectMetadata)

    wfObjectMetadata.$inject = ['$parse', '$templateCache', '$compile', '$interpolate', '$sanitize', '$timeout', '$animate', '$http']
    function wfObjectMetadata($parse, $templateCache, $compile, $interpolate, $sanitize, $timeout, $animate, $http) {
        const
            directive = {
                controller: wfObjectMetadataController,
                controllerAs: 'mdVm',
                restrict: 'E',
                link,
            }

        return directive

        function link($scope, $element, $attrs, controller) {
            let
                templateContent
				
            let interpolateFunc
				
            const templateUrl_default = 'scripts/wf/objectMetadata/wfObjectMetadata.directive.html'
				
            const templateUrl_vertical = 'scripts/wf/objectMetadata/wfObjectMetadataList.directive.html'
				
            let countByRelationKind
				
            let metadataKindsElement

            if (!controller.hasMetadata) return

            if (controller.useVertical) {
                templateContent = $templateCache.get(templateUrl_vertical)

                $element.html(templateContent)
                $compile($element.contents())($scope)
            }
            else if (!templateContent) {
                countByRelationKind = _.cloneDeep(_.get(controller.item.metadata, 'countByRelationKind'))
                controller.updateMetadata = updateMetadata
                templateContent = $templateCache.get(templateUrl_default)

                $element.html(templateContent)
                $compile($element.contents())($scope)

                if (!controller.onlyShowObjectViewerToggler) {
                    if (controller.relationKindsToShow && controller.relationKindsToShow.length !== 0) {
                        $timeout(() => {
                            metadataKindsElement = $element.find('div.metadata-kinds')
                            constructMetadataHtml(controller.relationKindsToShow)
                        }, 0)
                    }
                    else controller.metadataLoaded = true
                }
            }

            function updateMetadata() {
                const
                    initialCountByRealtionKind = countByRelationKind
					
                const updatedCountByRealtionKind = controller.item.metadata.countByRelationKind
					
                const kindsToUpdate = {}

                if (!metadataKindsElement) metadataKindsElement = $element.find('div.metadata-kinds')

                if (initialCountByRealtionKind && updatedCountByRealtionKind) {
                    //Checking if metadata is changed/added/removed in existing metadata
                    _.each(initialCountByRealtionKind, (value, key) => {
                        let newValue
                        key = parseInt(key)

                        if (updatedCountByRealtionKind[key] === undefined) return

                        if (updatedCountByRealtionKind[key] || updatedCountByRealtionKind[key] == 0) {
                            if (value != updatedCountByRealtionKind[key]) {
                                newValue = updatedCountByRealtionKind[key]
                                kindsToUpdate[key] = newValue

                                if (initialCountByRealtionKind[key] === 0 && newValue > initialCountByRealtionKind[key]) addMetadata(key)
                                else if (initialCountByRealtionKind[key] > 0 && newValue === 0) removeMetadata(key)
                                else if (initialCountByRealtionKind[key] !== 0 && newValue !== 0) changeNumber(value, newValue, key)
                            }
                        }
                        else console.error('Could not determine which metadata to update - no new updateKinds to update in wfObjectMetadata.diretive.js')
                    })
                    countByRelationKind = _.cloneDeep(updatedCountByRealtionKind)
                }
                else console.error('Either initialCountByRelationKind or updatedCountByRelationKind is not defined. Check updateMetadata() function in wfObjectMetadata.directive.js ')

                function addMetadata(key) {
                    constructMetadataHtml(controller.relationsByKind[key])
                }

                function removeMetadata(kind) {
                    const kindElement = (metadataKindsElement || (metadataKindsElement = $element.find('div.metadata-kinds'))).children().find('div.kind[data-kind=' + kind + ']')
                    const kindWrapperElement = kindElement.parent()
                    const elementWidth = kindWrapperElement[0] ? kindWrapperElement[0].offsetWidth : kindWrapperElement.width()
                    kindWrapperElement.addClass('overflow-hidden')

                    _.remove(controller.currentlyShownKinds, (relation) => {
                        if (relation.popoverActive && relation.kind === kind) controller.destroyPopover(relation)

                        return relation.kind === kind
                    })

                    $animate.leave(kindElement, {
                        addClass: 'leave-animation',
                        from: { marginLeft: '0px', opacity: 1 },
                        to: {  marginLeft: (-elementWidth) + 'px', opacity: 0 },
                    }).then(() => {
                        kindWrapperElement.removeClass('overflow-hidden')
                        kindWrapperElement.remove()
                    })
                }

                function changeNumber(oldValue, newValue, key) {
                    const
                        kindElement = $element.find('div.kind[data-kind=' + key + ']')
						
                    const itemsToSlideElement = kindElement.children('div.change-slider').children('div.items-to-slide')
						
                    let animateClass = ''
						
                    const metadataNumberElement = '<span class="metadata-number">' + $sanitize(newValue) + '</span>'

                    animateChangeNumber()
                    animateWithPulse()

                    function animateChangeNumber() {
                        if (oldValue > newValue) {
                            animateClass = 'slide-up lift-up-element'
                            itemsToSlideElement.prepend(metadataNumberElement)
                            itemsToSlideElement.addClass(animateClass)
                            setTimeout(() => {
                                itemsToSlideElement.find('span').last().remove()
                                itemsToSlideElement.removeClass(animateClass)
                            }, 500)
                        }
                        else {
                            animateClass = 'slide-down'
                            itemsToSlideElement.append(metadataNumberElement)
                            itemsToSlideElement.addClass(animateClass)
                            setTimeout(() => {
                                itemsToSlideElement.removeClass(animateClass)
                                itemsToSlideElement.find('span').first().remove()
                            }, 500)
                        }
                    }

                    function animateWithPulse() {
                        kindElement.addClass('pulse')
                        $timeout(() => { kindElement.removeClass('pulse') }, 700)
                    }
                }
            }

            function constructMetadataHtml(_relationKindsToShow) {
                let
                    tempDiv = $('<div></div>')
					
                let addingSingleMetadata = false
					
                let singleRelationKind
					
                let kindWrapperElement

                if (!interpolateFunc) {
                    interpolateFunc = $interpolate(
                        '<div class="kind-wrapper" data-toggle="popover" wf-click="{{wfClick}}" {{showTooltip}} tooltip-position-top-left>' +
							'<div class="kind" ' +
								'ng-class="{{ngClasses}}" ' +
								'order="{{metadataOrder}}" ' +
								'data-kind="{{metadataKind}}"> ' +
									'<div class="metadataIcon">' +
										'<i class="{{metadataIcon}}"></i> ' +
									'</div>' +
									'{{metadataNumber}} ' +
									'<span class="metadataTitle" wf-if-first="vm.emphasized">{{metadataTitle}}</span> ' +
							'</div> ' +
						'</div>',
                    )
                }

                if (!(_relationKindsToShow instanceof Array)) {
                    addingSingleMetadata = true
                    singleRelationKind = _relationKindsToShow
                    _relationKindsToShow = [_relationKindsToShow]
                }
                else controller.currentlyShownKinds = _relationKindsToShow

                // Sometimes there is an undefined item in _relationKindsToShow for unknown reason
                _relationKindsToShow = _.compact(_relationKindsToShow)
                controller.currentlyShownKinds = _.compact(controller.currentlyShownKinds)

                _.each(_relationKindsToShow, (relation) => {
                    relation.count = controller.getCount(relation.kind)
                    relation.hidePopover = function() {
                        controller.hidePopover(relation)
                    }
                    relation.scopeId = $scope.$id
                    tempDiv = $('<div></div>')

                    const	isCreationMetadata = relation.creationMetadata && controller.showCreationMetadata && !controller.hideCreator
                    const	interpolateOptions = {
                        showTooltip: 'tooltip-title="' + relation.title + '"',
                        ngClasses: '{ \'emphasized\': mdVm.emphasized, \'active\': mdVm.activePopoverByKind[' + relation.kind + '] }',
                        wfClick: 'mdVm.showPopover(' + relation.kind + ')',

                        metadataIcon: $sanitize(controller.getIcon(relation.kind)),
                        metadataNumber: controller.hideNumbers ? '' : ('<div class="change-slider"><div class="items-to-slide"><span class="metadata-number">' + $sanitize(relation.count) + '</span></div></div>'),
                        metadataTitle: $sanitize(relation.title),
                        metadataKind: $sanitize(relation.kind),
                        metadataOrder: $sanitize(relation.order),
                    }

                    // will run when initializing metadata
                    // when adding/removing metadata this code will not execute - relation.creationMetadata = true
                    // in all other cases relation.creationMetadata will be false
                    if (isCreationMetadata) {
                        _.assign(interpolateOptions, {
                            showTooltip: '',
                            wfClick: 'mdVm.showPopover(\'' + relation.kind + '\')',
                            metadataNumber: '',
                            metadataIcon: $sanitize(relation.icon),
                        })
                    }
                    else if (relation.consolidated) {
                        _.assign(interpolateOptions, {
                            showTooltip: 'tooltip-title="' + relation.title + '"',
                            wfClick: 'mdVm.showPopover(\'' + relation.kind + '\')',
                            metadataIcon: $sanitize(relation.icon),
                            metadataNumber: '<div class="change-slider"><div class="items-to-slide"><span class="metadata-number">' + $sanitize(relation.count) + '</span></div></div>',
                            metadataTitle: $sanitize(relation.title),
                            metadataKind: $sanitize(relation.kind),
                            metadataOrder: $sanitize(relation.order),
                        })
                    }

                    tempDiv.prepend(interpolateFunc(interpolateOptions))
                    $compile(tempDiv.contents())($scope)

                    relation.element = tempDiv.children().children('.kind')
                    kindWrapperElement = relation.element.parent()

                    if (addingSingleMetadata) {
                        const kindWithHigherOrder = _.find(controller.currentlyShownKinds, (kindSpec) => {
                            return kindSpec.order > singleRelationKind.order
                        })

                        if (kindWithHigherOrder) {
                            const elementWithHigherOrder = metadataKindsElement.children().find('div.kind[order=' + kindWithHigherOrder.order + ']').parent()
                            const indexOfKindWithHigherOrder = controller.currentlyShownKinds.indexOf(kindWithHigherOrder)

                            elementWithHigherOrder.before(tempDiv.children())
                            controller.currentlyShownKinds.splice(indexOfKindWithHigherOrder, 0, singleRelationKind)
                        }
                        else {
                            metadataKindsElement.append(tempDiv.children())
                            controller.currentlyShownKinds.push(singleRelationKind)
                        }
                        kindWrapperElement.addClass('overflow-hidden')
                    }
                    else metadataKindsElement.append(tempDiv.children())

                    const elementWidth = relation.element[0].offsetWidth
                    $animate.enter(relation.element, null, relation.element.prev(), {
                        addClass: addingSingleMetadata ? 'enter-animation' : '',
                        from: {  marginLeft: (-elementWidth) + 'px', opacity: 0 },
                        to: {  marginLeft: '0px', opacity: 1 },
                    }).then(() => {
                        kindWrapperElement.removeClass('overflow-hidden')
                        relation.element.removeClass('enter-animation')
                    })

                })

                controller.metadataLoaded = true
            }
        }
    }

    wfObjectMetadataController.$inject = ['$scope', '$element', '$timeout', '$parse', '$attrs', 'dataOperationsService', '$translate', 'modalService', '$state', '$compile', 'wfObject', 'dataQuery', '$q', 'wfMeasureService', 'valueChainService', 'wfUtilityService']
    function wfObjectMetadataController($scope, $element, $timeout, $parse, $attrs, dataOps, $translate, modal, $state, $compile, wfObject, dataQuery, $q, wfMeasureService, valueChainService, wfUtilityService) {
        const
            vm = this
			
        let objectType
			
        let countByRelationKind
			
        const loadedPopoversByKind = {}
			
        let relationKindsToShow = []
			
        let passedConfig
			
        let config = {
            addActionByKind: {},
            intersectionChildrenByKind: {},
            onlyShowObjectViewerToggler: false,
            showDetailedViewLink: true,
            showEmbeddedObjectViewer: true,
            limitRelationKinds: undefined, // Should be an array of integers (representing enums.subItemsKind)
            showObjectViewerButtonInPopover: true,
            popoverItemSettings: {
                metadataConfig: {
                    showEmbeddedObjectViewer: false,
                },
            },
            hidePopoverAddAction: false,
            showCreator: true,
        }
			
        let showEmptyRelationKinds = false
			
        let limitRelationKinds
			
        const itemsByRelationKind = {}
			
        let visibilityCount
			
        let intersectionSettings
			
        let currentlyOpenKindObj
			
        const defaultPopoverTemplate = 'scripts/wf/objectMetadata/wfPopoverTemplate.html'
			
        const userPopoverTemplate = 'scripts/wf/objectMetadata/wfUserPopoverTemplate.html'
			
        const consolidatedPopoverTemplate = 'scripts/wf/objectMetadata/wfConsolidatedPopover.html'

        _.assign(vm, {
            //Variables
            useExpandableObjectViewer: false,
            metadataLoaded: false,
            currentlyShownKinds: [],

            //Functions
            expandObjectViewer,
            toggleObjectViewer,
        })

        activate()

        function activate() {
            wfUtilityService.loadTemplateUrlInCache(defaultPopoverTemplate)
            wfUtilityService.loadTemplateUrlInCache(userPopoverTemplate)
            wfUtilityService.loadTemplateUrlInCache(consolidatedPopoverTemplate)

            if ('expandableObjectViewer' in $attrs) {
                if ($attrs.expandableObjectViewer.length) vm.useExpandableObjectViewer = !!$parse($attrs.expandableObjectViewer)($scope)
                else vm.useExpandableObjectViewer = true

                if ('uiMode' in $attrs) {
                    if ($attrs.uiMode.length) {
                        vm.uiMode = $parse($attrs.uiMode)($scope)
                    }
                }
                else vm.uiMode = enums.uiMode.view
            }

            if ('itemContent' in $attrs) {
                vm.item = $parse($attrs.itemContent)($scope)
            }
            else {
                vm.item = $parse($attrs.item)($scope).childContent
            }

            if ('useRelationCreation' in $attrs) {
                vm.useRelationCreation = !!$parse($attrs.useRelationCreation)($scope)
            }

            if (vm.useRelationCreation && 'itemRelation' in $attrs) {
                vm.itemRelation = $parse($attrs.itemRelation)($scope)
            }

            if ('emphasized' in $attrs) {
                vm.emphasized = true
            }

            if ('intersection' in $attrs) {
                intersectionSettings = $parse($attrs.intersection)($scope)
            }

            if ('hideCreator' in $attrs) {
                if ($attrs.hideCreator.length) {
                    vm.hideCreator = !!$parse($attrs.hideCreator)($scope)
                }
                else {
                    vm.hideCreator = true
                }
            }

            if ('showCreator' in $attrs) {
                if ($attrs.showCreator.length) {
                    vm.showCreator = !!$parse($attrs.showCreator)($scope)
                }
                else {
                    vm.showCreator = true
                }
            }

            if (!vm.item || vm.item.type === enums.objectType.individual) {
                $element.remove()
                return
            }

            if (vm.item) {
                vm.showCreationMetadata = (vm.item && (vm.item.isUserDataType && vm.item.isUserDataType() || vm.item.isRelationalType && vm.item.isRelationalType())) || vm.showCreator

                objectType = vm.item.type
                countByRelationKind = vm.item.metadata ? vm.item.metadata.countByRelationKind || {} : {}

                defineRelationsKinds(enums, $translate)
                getObjectTypeSettings(enums)

                _.assign(vm, {
                    //Variables
                    context: {},
                    $element,
                    popoverItems: [],
                    activePopoverByKind: {},
                    activePopoverKind: null,
                    kind: enums.subItemsKind,
                    relationsByKind: _.cloneDeep(relationsByKind),
                    relationKinds: relationsToDefine,
                    relationsCompilerByKind: {},
                    objTypeSettings,
                    hasMetadata: !_.isEmpty(countByRelationKind) || vm.showCreationMetadata,
                    popoverItemsLimit: 5,

                    appendPopoverToBody: !!$parse($attrs.appendPopoverToBody)($scope),
                    showAddButtons: false,
                    showDetailedViewLink: undefined,
                    showEmbeddedObjectViewer: false,
                    useExpandableObjectViewer: true,
                    intersectionSettings,

                    //Functions
                    getTitle,
                    getCount,
                    getIcon,
                    isRestricted,
                    showPopover,
                    updateNumbers,
                    getDropdownActions,
                    openCategorizationModal,
                    openModal_attachInformation,
                    openObjectViewer,
                    openModal_sendTo,
                    openModal_addTo,
                    openPicker,
                    getSubItemsOfKind,
                    addItemToRelationKind,
                    onAttachedToPopoverItem,
                    destroyPopover,
                    hidePopover,
                    hideNestedPopover,
                })

                const exposedMethods = $parse($attrs.exposedMethods)($scope)
                _.assign(exposedMethods, {
                    updateNumbers,
                })
            }

            if ('config' in $attrs) {
                passedConfig = $parse($attrs.config)($scope)
                config = _.assign(config, passedConfig)
                vm.popoverItemSettings = config.popoverItemSettings
                vm.hidePopoverAddAction = config.hidePopoverAddAction
                if (config.appendPopoverToBody) vm.appendPopoverToBody = config.appendPopoverToBody

                if (!_.isEmpty(config.addActionByKind)) vm.showAddButtons = true

                if (config.onlyShowObjectViewerToggler === true) {
                    config.showDetailedViewLink = false
                    vm.onlyShowObjectViewerToggler = true
                    vm.showDetailedViewLink = false
                }

                if (config.limitRelationKinds) {
                    limitRelationKinds = config.limitRelationKinds
                }

                if ('showEmbeddedObjectViewer' in config) {
                    vm.useExpandableObjectViewer = config.showEmbeddedObjectViewer
                }
                vm.hideNumbers = config.hideNumbers
                vm.organizationIds = config.organizationIds

                vm.config = config
                if (vm.config.showCreator === false) vm.showCreationMetadata = false
            }

            vm.showObjectViewerButtonInPopover = config.showObjectViewerButtonInPopover

            if ('consolidatedDataCount' in $attrs) {
                vm.consolidatedDataCount = $parse($attrs.consolidatedDataCount)($scope)
                vm.consolidatedDataCountByNetwork = $parse($attrs.consolidatedDataCountByNetwork)($scope)
                if (vm.consolidatedDataCount >= 0) vm.showConsolidatedData = true
            }

            if (vm.useExpandableObjectViewer) {
                vm.objectViewerLoaded = false
                vm.objectViewerNoData = false
                vm.expandButtonText = $translate.instant('modules.metadata.expandedObjectViewer.showData')

                $scope.$watch('vm.hierVm.isAllObjectViewersExpanded', (value) => {
                    vm.showEmbeddedObjectViewer = value

                    if (!value) {
                        vm.objectViewerLoaded = false
                    }
                })

                vm.onObjectViewerLoaded = function (objViewerVm) {
                    vm.objViewerVm = objViewerVm
                    if (objViewerVm.allLoaded && objViewerVm.boxes.length == 0) {
                        vm.objectViewerNoData = true
                        vm.expandButtonText = $translate.instant('modules.metadata.expandedObjectViewer.noData')
                    }
                    vm.objectViewerLoaded = true
                }
            }

            vm.showDetailedViewLink = config.showDetailedViewLink && vm.item.type !== enums.objectType.individual

            if (limitRelationKinds) {
                vm.relationKinds = _.filter(vm.relationKinds, (value) => {
                    if (_.includes(limitRelationKinds, value.kind)) return true
                    else {
                        delete vm.relationsByKind[value.kind]
                        return false
                    }
                })
            }

            showEmptyRelationKinds = 'showEmptyKinds' in $attrs

            _.each(countByRelationKind, (value, key) => {
                if (value > 0 || showEmptyRelationKinds) {
                    key = parseInt(key)
                    const kindSpec = vm.relationsByKind[key]
                    if (kindSpec !== undefined) relationKindsToShow.push(kindSpec)
                }
            })

            if ('useVertical' in $attrs) {
                vm.useVertical = !$attrs.useVertical.length || !!$parse($attrs.useVertical)($scope)

                if (vm.useVertical) {

                    if (vm.item.isUserDataType() && (visibilityCount = vm.item.visibility.length) > 0) _.assign(countByRelationKind, { 10: visibilityCount })

                    vm.relationsByKind = _.filter(vm.relationsByKind, (value, key) => {
                        if (value.kind === enums.subItemsKind.relatedParentsByUser && !(limitRelationKinds && _.includes(limitRelationKinds, value.kind))) {
                            return
                        }
                        if (value.kind === enums.subItemsKind.relatedParents && !(limitRelationKinds && _.includes(limitRelationKinds, value.kind))) return

                        if (limitRelationKinds && !_.includes(limitRelationKinds, value.kind)) return

                        vm.relationsCompilerByKind[value.kind] = {}

                        if (!showEmptyRelationKinds) {
                            return countByRelationKind[value.kind] > 0
                        }

                        return true
                    })

                    _.each(vm.relationsByKind, (item) => {
                        if (item) // Sometimes undefined. Don't know why
                            item.items = getSubItemsOfKind(item.kind)
                    })
                    if (vm.hideCreator) {
                        if (vm.relationKinds.length === 0) $element.addClass('hidden')
                    }
                    else {
                        vm.creatorUser = vm.item.creatorUser
                        vm.createdAt = vm.item.createdAt ? moment(vm.item.createdAt).format('YYYY-MM-DD, HH:mm') : null
                        vm.createdAtTooltip = $translate.instant('CreatedAt') + ': ' + vm.createdAt
                        vm.createdAgo = vm.item.createdAt ? moment(vm.item.createdAt).from(moment()) : null
                    }

                    relationKindsToShow = _.map(vm.relationsByKind)
                }
            }

            if (vm.showCreationMetadata && !vm.hideCreator && !vm.useVertical) {
                relationKindsToShow.push({
                    icon: 'fa fa-user',
                    order: 0,
                    restriction: false,
                    title: '',
                    kind: 'creationDetails',
                    creationMetadata: true,
                    popoverActive: false,
                    popoverInitiated: false,
                    element: undefined,
                    hidePopover() {
                        hidePopover(this)
                    },
                })
            }

            if (vm.showConsolidatedData) {
                relationKindsToShow.push({
                    icon: 'fas fa-sitemap',
                    order: -1,
                    restriction: false,
                    title: $translate.instant('modules.metadata.consolidatedData.title'),
                    kind: 'consolidated',
                    consolidated: true,
                    popoverActive: false,
                    popoverInitiated: false,
                    element: undefined,
                    hidePopover() {
                        hidePopover(this)
                    },
                })
            }

            vm.relationKindsToShow = relationKindsToShow = _.sortBy(relationKindsToShow, 'order')

            initializeListeners()
        }

        function initializeListeners() {
            $scope.$on('dropdownActionExecuted', ($event, operation, item, dataRelation) => {
                if (operation == 'delete') {
                    _.remove(vm.popoverItems, { content: item })
                    if (item.type == enums.objectType.measureAnswer && $scope.measureAnsweringVm) $scope.measureAnsweringVm.updateLatestAnswer()

                    if (item.type == enums.objectType.questionAnswer && $scope.questionAnsweringVm) $scope.questionAnsweringVm.updateLatestQuestionAnswer()

                    updateNumbers()
                    if (vm.loadedPopoverKind) populatePopoverItems(vm.loadedPopoverKind)
                }
            })

            $scope.$on('measureAnswerChanged', ($event, data) => {
                updateNumbers()
                if (vm.loadedPopoverKind) populatePopoverItems(vm.loadedPopoverKind)
            })

            $scope.$on('questionAnswerChanged', ($event, item) => {
                updateNumbers()
                if (vm.loadedPopoverKind) populatePopoverItems(vm.loadedPopoverKind)
            })

            $scope.$on('dataAnswerChanged', ($event, data) => {
                updateNumbers()
                if (vm.loadedPopoverKind) populatePopoverItems(vm.loadedPopoverKind)
            })

            $scope.$on('wfObject.created', ($event, wfid, obj) => {
                let kind
                if (obj && obj.type === enums.objectType.dataRelation && (obj.wfcid === vm.item.wfid || obj.wffid === vm.item.wfid)) {
                    if (obj.wfcid === vm.item.wfid) kind = obj.getKindForChildContent()
                    else if (obj.wffid === vm.item.wfid) kind = obj.getKindForParentContent()

                    if (kind) populatePopoverItems(kind)

                    updateNumbers()
                }
            })

            $scope.$on('wfObject.destroyed', ($event, wfid, obj) => {
                if (obj && obj.type === enums.objectType.dataRelation && (obj.wfcid === vm.item.wfid || obj.wffid === vm.item.wfid)) {
                    updateNumbers()
                }
            })
        }

        function expandObjectViewer() {
            vm.showEmbeddedObjectViewer = true
            $timeout()
        }

        function toggleObjectViewer() {
            if (vm.objectViewerNoData) return

            vm.showEmbeddedObjectViewer = !vm.showEmbeddedObjectViewer

            if (vm.showEmbeddedObjectViewer) {
                vm.expandButtonText = $translate.instant('modules.metadata.expandedObjectViewer.hideData')
            }
            else {
                vm.objectViewerLoaded = false
                vm.expandButtonText = $translate.instant('modules.metadata.expandedObjectViewer.showData')
            }
        }

        function showPopover(kind) {
            const
                relation = _.find(vm.currentlyShownKinds, { kind })
				
            const kindWrapper = relation.element.parent()
				
            const isCreationMetadata = relation.creationMetadata && vm.showCreationMetadata && !vm.hideCreator
				
            let templateToLoad

            if (isCreationMetadata) {
                templateToLoad = userPopoverTemplate
            }
            else if (relation.consolidated) {
                templateToLoad = consolidatedPopoverTemplate
            }
            else {
                templateToLoad = defaultPopoverTemplate
            }

            if (relation.popoverActive) return

            currentlyOpenKindObj = relation

            if (!relation.popoverInitiated) {
                kindWrapper.popover({
                    delay: 0,
                    animation: true,
                    placement: 'bottom',
                    container: vm.appendPopoverToBody ? 'body' : false,
                    trigger: 'manual',
                    template: '<div class="popover metadata-popover' + (relation.consolidated ? ' consolidatedData' : '') + '"><div class="arrow"></div><div class="popover-content"></div></div>',
                    html: true,
                    selector: false,
                    content() {
                        if (relation.scope) {
                            relation.scope.$destroy()
                        }
                        relation.scope = $scope.$new()
                        const compiledElement = $compile(wfUtilityService.getTemplateFromCache(templateToLoad))(relation.scope)
                        relation.popoverElement = compiledElement
                        return compiledElement
                    },
                })
                relation.popoverInitiated = true
                setTimeout(() => {
                    relation.popoverElement = relation.popoverElement.parent()
                })
            }

            loadPopoverContent(relation.kind)
            relation.popoverActive = true
            kindWrapper.popover('show')

            if (timeout) {
                clearTimeout(timeout)
                Array.prototype.push.apply(openPopovers, soonOpenPopovers)
                soonOpenPopovers.length = 0
            }

            soonOpenPopovers.push(relation)

            timeout = setTimeout(() => {
                Array.prototype.push.apply(openPopovers, soonOpenPopovers)
                soonOpenPopovers.length = 0
            }, 50)

            $timeout()
        }

        function loadPopoverContent(kind) {
            vm.popoverLoaded = false
            vm.loadedPopoverKind = kind
            vm.activePopoverByKind[kind] = true
            vm.activePopoverKind = kind

            if (!loadedPopoversByKind[kind]) loadedPopoversByKind[kind] = {}

            if (vm.item.type == enums.objectType.measure && kind == enums.subItemsKind.childrenByUser) vm.measureAnswer = true

            if (vm.popoverItems.length != 0) vm.popoverItems = []

            vm.itemKind = kind

            if (kind === 'creationDetails') {
                loadedPopoversByKind[kind].loaded = true
                if (vm.useRelationCreation && vm.itemRelation) {
                    vm.creatorUser = vm.itemRelation.creatorUser
                    vm.createdAt = vm.itemRelation.createdAt ? moment(vm.itemRelation.createdAt).format('D MMMM YYYY, HH:mm') : null
                    vm.deletedAt = vm.itemRelation.deletedAt ? moment(vm.itemRelation.deletedAt).format('D MMMM YYYY, HH:mm') : null
                }
                else {
                    vm.creatorUser = vm.item.creatorUser
                    vm.createdAt = vm.item.createdAt ? moment(vm.item.createdAt).format('D MMMM YYYY, HH:mm') : null
                    vm.deletedAt = vm.item.deletedAt ? moment(vm.item.deletedAt).format('D MMMM YYYY, HH:mm') : null
                }
                vm.popoverLoaded = true
            }
            else if (kind === 'consolidated') {
                valueChainService.loadNetworks().then((result) => {
                    const networks = result.networks

                    vm.consolidatedData = {
                        activeNetworkTab: 0,
                    }

                    vm.consolidatedData.networks = _.chain(networks)
                        .filter((network) => {
                            return network.id.toString() in vm.consolidatedDataCountByNetwork
                        })
                        .sortBy('id')
                        .value()

                    vm.consolidatedData.networks = _.map(vm.consolidatedData.networks, (network) => {
                        return {
                            id: network.id,
                            title: network.title + (vm.consolidatedData.networks.length > 1 ? ' (' + vm.consolidatedDataCountByNetwork[network.id.toString()] + ')' : ''),
                            inited: false,
                            loaded: false,
                            init() {
                                const self = this

                                if (this.inited) return

                                this.inited = true,
                                loadOrganizationsOnNetwork(network).then((organizationIds) => {
                                    self.loaded = true
                                    self.organizationIds = organizationIds
                                    $timeout()
                                })
                            },
                        }
                    })

                    loadedPopoversByKind[kind].loaded = true
                    vm.popoverLoaded = true
                    $timeout()

                    function loadOrganizationsOnNetwork(network) {
                        return $q((resolve, reject) => {
                            valueChainService.loadOrganizationsInNetwork(network.id, true).then((res) => {
                                // If there are duplicate relations to any organization then those duplicate will have undefined childContent
                                // (since the result is not injected into JSData) so remove them here.
                                const organizationIds = _.chain(res).map('childId').compact().value()

                                resolve(organizationIds)
                            })
                        })
                    }
                })
            }
            else {
                dataOps.getSubItems(vm.item, kind, {
                    useOrganizationMatchModeFromKind: true,
                    ticket: intersectionSettings,
                }).then(() => {
                    const promises = []

                    if (kind === vm.loadedPopoverKind) {
                        if (promises.length) {
                            $q.all(promises).then(() => {
                                finish()
                            })
                        }
                        else finish()
                    }

                    function finish() {
                        loadedPopoversByKind[kind].loaded = true
                        populatePopoverItems(kind)
                        vm.popoverLoaded = true
                        $timeout()
                    }
                })
            }
        }

        function hideNestedPopover($event) {
            if ($event.originalEvent) {
                if (!$event.originalEvent.closeOnlyWithScopeId && $.contains(currentlyOpenKindObj.popoverElement[0], $event.target)) $event.originalEvent.closeOnlyWithScopeId = currentlyOpenKindObj.scopeId
            }
        }

        function hidePopover(relation) {
            const kindWrapper = relation.element.parent()
            vm.activePopoverByKind[relation.kind] = false
            vm.activePopoverKind = null
            relation.popoverActive = false
            kindWrapper.popover('hide')
            $timeout()
        }

        function destroyPopover(relation) {
            const kindWrapper = relation.element.parent()
            vm.activePopoverByKind[relation.kind] = false
            vm.activePopoverKind = null
            relation.popoverActive = false
            relation.popoverInitiated = false
            _.remove(openPopovers, relation)
            kindWrapper.popover('destroy')
            $timeout()
        }

        // Get subItems of the kind specified whete dataRelation.relationType of each subItem is null.
        // dataRelation.relationType === 1 is Infrastructural and should not be shown.
        function getVisibleDataRelations(kind) {
            return _.filter(vm.item.getSubListOfKind(kind, vm.organizationIds), (item) => {
                if (item.type !== enums.objectType.dataRelation || item.relationType === 1) // item.relationType === 1 is Infrastructural
                    return
                else return item
            })
        }

        function onAttachedToPopoverItem(item) {
            // Reason for this function to exist: Actions in wfDropdown causes the popover to be closed.
            // When that happens the scope chain is lost so when wfDropdown emits with $scope.$emit("dropdownActionExecuted")
            // no listeners gets notified. To overcome that we use the callback functionality of wfItemsCollection directive.

            $scope.$broadcast('dropdownActionExecuted', 'attach', item)
        }

        function populatePopoverItems(kind) {
            let
                dataRelations
				
            let contents

            if (intersectionSettings
				&& _.includes([
				    enums.subItemsKind.childrenByUser,
				    enums.subItemsKind.relatedContentByUser,
				], kind))
            {
                dataRelations = dataQuery.getIntersectedSubItems(vm.item, _.assign({
                    kind,
                    sourceDataRelations: dataRelations,
                }, intersectionSettings))
            }
            else {
                dataRelations = getVisibleDataRelations(kind)
            }

            vm.itemsCount = dataRelations.length
            contents = _.keyBy(wfObject.filter({ where: { wfid: { in: _.map(dataRelations, wfObject.getRelationKeyOfKind(kind)) } } }), 'wfid')

            vm.popoverItems = _.map(_.chain(dataRelations).orderBy(['createdAt'], ['desc']).value(), (dataRelation) => {
                let popoverItem
                const content = contents[dataRelation.getSubContentWfidOfKind(kind)]
                let enableDropdown = true
                const objectTypeSetting = objTypeSettings[content.type] || {}

                if (objectTypeSetting.blockItemTools && objectTypeSetting.blockItemDropdown) {
                    enableDropdown = false
                }
                popoverItem = {
                    type: content.type,
                    enableDropdown,
                    content,
                    dataRelation,
                    isLinkable: content.isLinkableType(),
                    isOfAnswerableType: content.isAnswerType(),
                    mainTextual: content.getMainTextual(),
                    typeHasHeader: content.typeHasHeader(),
                }

                if (_.get(config, 'popoverItemSettings.dropdownActionsByKind')) popoverItem.dropdownActions = config.popoverItemSettings.dropdownActionsByKind[kind] || getDropdownActions(popoverItem)
                else popoverItem.dropdownActions = getDropdownActions(popoverItem)

                return popoverItem
            })
        }

        function getTitle(kind) {
            if (countByRelationKind[kind] != null) {
                let output; let relations
                relations = relationsByKind[kind]

                if (relations.specificType && relations.specificType.length > 0) {
                    const finalRelation = _.find(relations.specificType, { type: objectType })
                    if (finalRelation && finalRelation.title) output = finalRelation.title
                    else output = relations.title
                }
                else {
                    output = relations.title
                }

                return output
            }
        }

        function getIcon(kind) {
            if (countByRelationKind[kind] != null) {
                let output; let relations
                relations = relationsByKind[kind]

                if (relations.specificType && relations.specificType.length > 0) {
                    const finalRelation = _.find(relations.specificType, { type: objectType })
                    if (finalRelation && finalRelation.icon) output = finalRelation.icon
                    else output = relations.icon
                }
                else {
                    output = relations.icon
                }

                return output
            }
        }

        function getCount(kind) {
            if (kind === 'consolidated') {
                return vm.consolidatedDataCount
            }

            if (!vm.item.metadata || !vm.item.metadata.countByRelationKind) {
                return
            }

            if (limitRelationKinds && !_.includes(limitRelationKinds, kind)) return

            if (vm.item.metadata.countByRelationKind[kind] != null) {
                return vm.item.metadata.countByRelationKind[kind]
            }
        }

        function isRestricted(kind) {
            let output
            const relations = relationsByKind[kind]

            if (relations.specificType && relations.specificType.length > 0) {
                const finalRelation = _.find(relations.specificType, { type: objectType })
                if (finalRelation && finalRelation.restriction) output = finalRelation.restriction
                else output = relations.restriction
            }
            else {
                output = relations.restriction
            }
            return output
        }

        function openModal_attachInformation(item, type) {
            const pickerOptions = {
                objectTypes: [type],
                relationTarget: [{
                    item: item.content,
                    kind: enums.subItemsKind.relatedContentByUser,
                }],
                intersection: intersectionSettings,
            }

            // if (intersectionSettings.contextParents) {
            // 	console.log(intersectionSettings)
            // 	Array.prototype.push.apply(pickerOptions.relationTarget, _.map(intersectionSettings.contextParents, function (wfid) {
            // 		return {
            // 			item: wfid,
            // 			kind: enums.subItemsKind.contextChildren
            // 		}
            // 	}));
            // }

            modal.openCreatorAndPicker(pickerOptions).closed(() => {
                populatePopoverItems(vm.loadedPopoverKind)
                $timeout()
            })
        }

        function openCategorizationModal() {
            modal.addTo(vm.item)
        }

        function openModal_sendTo(item) {
            modal.openCreatorAndPicker({
                showTopItemAboveTitle: true,
                hideListHeader: false,
                hideFilters: false,
                translations: {
                    addWord: $translate.instant('Send'),
                    toWord: $translate.instant('To'),
                    filterButton_all: $translate.instant('AllUsers'),
                    filterButton_selected: $translate.instant('Sent'),
                },
                compilerControl: null, //vm.context.itemCompilers[item.wfid],
                title: $translate.instant('Send'),
                create: false,
                objectTypes: [enums.objectType.individual],
                relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser },
            })
        }

        function openModal_addTo(item) {
            modal.openCreatorAndPicker({
                hideListHeader: true,
                compilerControl: null, //vm.context.itemCompilers[item.wfid],
                title: $translate.instant('AddTo'),
                create: false,
                sourceItem: '73-347315',
                relationTarget: { item: item.content, kind: enums.subItemsKind.parentsByUser },
            })
        }

        function openPicker(item, type, kind) {
            modal.openCreatorAndPicker({
                objectTypes: [type],
                relationTarget: { item, kind },
            }).closed(() => {
                if (vm.loadedPopoverKind === kind) {
                    populatePopoverItems(kind)
                    $timeout()
                }
            })
        }

        function openObjectViewer(item) {
            const url = $state.href('objectViewer_encoded', { encodedData: item.content.getEncodedWfid() })
            window.open(url, '_blank')
        }

        function updateNumbers() {
            if (vm.item.metadata && vm.item.metadata.countByRelationKind && !vm.useVertical && vm.updateMetadata) vm.updateMetadata()

            if (vm.objViewerVm) vm.objViewerVm.repopulateBoxes()
        }

        function getDropdownActions(item) {
            let
                dropdownActions
				
            let showDivider = true
				
            let include_addTo = true
				
            let include_sendTo = true
				
            let include_attachInformation = true
				
            const objectTypeSetting = objTypeSettings[item.content.type] || {}

            if ((vm.activePopoverKind === enums.subItemsKind.parentsByUser)) {
                include_sendTo = include_addTo = include_attachInformation = false
                showDivider = false
            }

            if (item.content.type === enums.objectType.questionAnswer || item.content.type === enums.objectType.measureAnswer) {
                dropdownActions = []

                if (item.content.type === enums.objectType.measureAnswer) dropdownActions.push('edit')

                dropdownActions.push('deleteContent')
                dropdownActions.push('objectViewer')
            }

            if (!objectTypeSetting.blockItemTools) {
                if (item.content.isUserDataType && item.content.isUserDataType()) {
                    dropdownActions = 'userData'
                }
                else {
                    dropdownActions = ['objectViewer']

                    if (showDivider && !objectTypeSetting.extraActions) dropdownActions.push('-')

                    if (include_sendTo) dropdownActions.push('sendTo')

                    if (include_addTo) dropdownActions.push('addTo')

                    if (include_attachInformation) dropdownActions.push('attachInformation')
                }
            }

            if (objectTypeSetting.extraActions) {
                Array.prototype.push.apply(dropdownActions, objectTypeSetting.extraActions)
            }
            return dropdownActions
        }

        function addItemToRelationKind(kind) {
            let promise
            if (config.addActionByKind && config.addActionByKind[kind]) {
                promise = config.addActionByKind[kind](vm.item)

                if (promise && promise.then) {
                    promise.then(() => {
                        delete itemsByRelationKind[kind]
                        vm.relationsCompilerByKind[kind].compile()
                    })
                }
            }
            else console.error('addActionByKind not defined in metadata-options (config)')
        }

        function getSubItemsOfKind(kind) {
            let list

            if (!itemsByRelationKind[kind]) {
                list = vm.item.getSubListOfKind(kind)
                itemsByRelationKind[kind] = _.chain(list).filter((item) => {
                    if ((item.type !== enums.objectType.dataRelation && item.type !== enums.objectType.visibilityTag) || item.relationType === 1) // item.relationType === 1 is Infrastructural
                        return
                    else if (item.getSubContentTypeOfKind(kind) === enums.objectType.verification) // Hide verifications for now
                        return
                    else return true
                }).map((dataRelation) => {
                    const content = dataRelation.getSubContentOfKind(kind)
                    if (!content) return {}
                    else {
                        return {
                            wfid: content.wfid,
                            hasContent: !!content,
                            mainTextual: content.getMainTextual(),
                            content,
                            isLinkable: content.isLinkableType(),
                        }
                    }
                }).filter({ hasContent: true }).value()
            }

            if (itemsByRelationKind[kind].length === 0 && !showEmptyRelationKinds) {
                _.remove(vm.relationsByKind, { kind })
            }

            return itemsByRelationKind[kind]
        }
    }

    function defineRelationsKinds(enums, $translate) {
        if (!relationsToDefine) {
            relationsToDefine = [
                {
                    //enum: 5
                    kind: enums.subItemsKind.relatedContentByUser,
                    addActionTitle: $translate.instant('AttachInformation'),
                    restriction: false,
                    title: $translate.instant('AttachedInformation'),
                    icon: 'fa fa-paperclip',
                    specificType: [],
                },
                {
                    //enum: 1
                    kind: enums.subItemsKind.children,
                    restriction: false,
                    title: $translate.instant('modules.objectViewer.children'),
                    icon: 'fa fa-th-list',
                    specificType: [{
                        type: enums.objectType.orgDocument,
                        restriction: false,
                    },
                    {
                        type: enums.objectType.orgActivity,
                        restriction: false,
                    },
                    ],
                },
                {
                    //enum: 7
                    kind: enums.subItemsKind.childrenByUser,
                    restriction: false,
                    title: $translate.instant('modules.objectViewer.structureChildrenByUser'),
                    icon: 'fa fa-list',
                    specificType: [{
                        type: enums.objectType.question,
                        restriction: false,
                        title: $translate.instant('AnswerHistory'),
                        icon: 'fa fa-comments-o',
                    },
                    {
                        type: enums.objectType.measure,
                        restriction: false,
                        title: $translate.instant('Measure'),
                        icon: 'fa fa-area-chart',
                    },
                    ],
                },
                {
                    //enum: 2
                    kind: enums.subItemsKind.parents,
                    restriction: false,
                    title: $translate.instant('modules.objectViewer.parents'),
                    icon: 'fa fa-eye',
                    specificType: [],
                },
                {
                    //enum: 4
                    kind: enums.subItemsKind.relatedContent,
                    restriction: false,
                    title: $translate.instant('modules.objectViewer.relatedContent'),
                    icon: 'fa fa-link',
                    specificType: [],
                },
                {
                    //enum: 13
                    kind: enums.subItemsKind.relatedParentsByUser,
                    addActionTitle: $translate.instant('AttachTo'),
                    restriction: false,
                    title: $translate.instant('AttachedTo'),
                    icon: 'fa fa-paperclip',
                    specificType: [],
                },
                {
                    //enum: 8
                    kind: enums.subItemsKind.parentsByUser,
                    restriction: false,
                    title: $translate.instant('Categorizations'),
                    icon: 'fa fa-star-o',
                    specificType: [],
                },
                {
                    //enum: 30 - visibility
                    kind: enums.subItemsKind.visible,
                    restriction: false,
                    title: $translate.instant('modules.objectViewer.visibility'),
                    icon: 'fa fa-eye',
                    specificType: [],
                },
            ]

            relationsByKind = {}
            _.each(relationsToDefine, (item, index) => {
                //the order is set based on the sequence of the objects in array - relationsToDefine
                item.order = index + 1 //because of itemCreationMetadata (user icon)
                relationsByKind[item.kind] = item
            })
        }
    }

    function getObjectTypeSettings(enums) {
        if (!defineObjTypes) {
            defineObjTypes =
				[{
				    type: enums.objectType.individual,
				    blockItemTools: true,
				    blockItemDropdown: true,
				}, {
				    type: enums.objectType.question,
				    blockItemTools: true,
				}, {
				    type: enums.objectType.measure,
				    blockItemTools: true,
				}, {
				    type: enums.objectType.relativeMeasure,
				    blockItemTools: true,
				}, {
				    type: enums.objectType.structure,
				    blockItemTools: true,
				}, {
				    type: enums.objectType.questionAnswer,
				    // extraActions: [ '-', 'deleteContent' ],
				    extraActions: ['attachInformation'],
				    blockItemTools: true,
				}, {
				    type: enums.objectType.measureAnswer,
				    // extraActions: [ '-', 'deleteContent' ],
				    extraActions: ['attachInformation'],
				    blockItemTools: true,
				}, {
				    type: enums.objectType.link,
				    blockItemTools: false,
				}, {
				    type: enums.objectType.orgDocument,
				    blockItemTools: false,
				}, {
				    type: enums.objectType.statement,
				    blockItemTools: false,
				}, {
				    type: enums.objectType.orgActivity,
				    blockItemTools: false,
				}, {
				    type: enums.objectType.location,
				    blockItemTools: false,
				}, {
				    type: enums.objectType.embed,
				    blockItemTools: false,
				},
				]
            objTypeSettings = _.keyBy(defineObjTypes, 'type')
        }
    }

    $(document).on('click.popover', (event) => {
        let closeOnlyWithScopeId
        const rightClick = event.which !== 1
        if (openPopovers.length > 0 && !rightClick) {
            closeOnlyWithScopeId = event.originalEvent ? event.originalEvent.closeOnlyWithScopeId : undefined
            hideAllActivePopovers(closeOnlyWithScopeId)
        }
    })

    function hideAllActivePopovers(limitScopeId) {
        if (openPopovers.length !== 0) {
            _.remove(openPopovers, (popoverRelation) => {
                if (popoverRelation.popoverActive) {
                    if (limitScopeId && popoverRelation.scopeId <= limitScopeId) return

                    popoverRelation.hidePopover()
                    return true
                }
            })
        }
    }

} ())
