(function () {
    'use strict'

    angular.module('wf.common', [
        'wf.multilingual',
        'wf.data',
        'wf.signalR',
        'wf.animations',
        'schemaForm',
        'ngSchemaFormFile',
        'ngAnimate',
        'ngCookies', // Required by Auth0's angular-storage module as a fallback if localStorage is unavailable
        'ui.bootstrap',
        'RecursionHelper',
        'angularUtils.directives.dirPagination',
        'ngBootbox',
        //'auth0',
        'auth0.lock',
        'angularMoment',
        'sticky',
        'ngMap',
        'hm.readmore',
        'currencyFormat',
        'ngScrollbars',
        'infinite-scroll',
        'tc.chartjs',
        'pdf',
        'ngclipboard',
        '720kb.socialshare',
        'rzModule',
        'daterangepicker',
        'ui.grid',
        'ngNumeraljs',
        'as.sortable',
    ])
        .config(['$uibModalProvider', '$uibTooltipProvider', 'sfErrorMessageProvider', '$injectorProvider', function($uibModalProvider, $uibTooltipProvider, sfErrorMessageProvider, $injectorProvider) {
            let extrasContainer

            $uibTooltipProvider.options({
                popupDelay: 100,
            })

            // numeral.locale("sv");

            // Set options for plugin that append elements to the body to instead append it to a specific container.
            if (window.wf__embeddedWidgetMode) {
                extrasContainer = $('<div class=\'wf-w worldfavor-extras-container\'></div>').appendTo($('body'))

                $uibModalProvider.options.appendTo = extrasContainer
                $uibTooltipProvider.options.appendTo = extrasContainer

                if (_.get($.fn.popover, 'Constructor')) $.fn.popover.Constructor.DEFAULTS.container = extrasContainer
                if (_.get($.fn.tooltip, 'Constructor')) $.fn.tooltip.Constructor.DEFAULTS.container = extrasContainer

                // Disable Offline.js for widgets
                if (window.Offline) window.Offline.destroy()
            }

            window.__wf_sfErrorMessageProvider = sfErrorMessageProvider

            numeral.locales.en.delimiters.thousands = ' ' // Non-break space (Alt+0160)
            numeral.locales.sv.delimiters.thousands = ' ' // Non-break space (Alt+0160)
            numeral.defaultFormat('0,0.[0]')
        }])
})()
