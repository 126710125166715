import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('tableDataExtractor', tableDataExtractor)

    tableDataExtractor.$inject = ['$rootScope', 'apiProxy', 'dataQuery', 'wfPropertyExtractor', '$timeout', 'wfAuth', 'wfObject', 'dataOperationsService', 'wfMeasureService', '$q']
    function tableDataExtractor($rootScope, apiProxy, dataQuery, wfPropertyExtractor, $timeout, wfAuth, wfObject, dataOps, wfMeasureService, $q) {
        let
            cachedColumnsDefinitions

        const service = {
            getColumnDefinitionsFromItemComposites,
            getDataFromItemComposites,
            getRowContentByType,
            getColumnDefinitionsByType,
        }

        return service

        function getColumnDefinitionsFromItemComposites(items, options) {
            let output = []
            const
                objectTypes = _.uniq(_.map(items, 'type'))

            const flatDefinitions = []

            const definitionsByType = {}

            if (objectTypes.length) {
                _.each(objectTypes, (type) => {
                    Array.prototype.push.apply(flatDefinitions, (definitionsByType[type] = getColumnDefinitionsByType(type, options)))
                })

                output = _.uniqBy(flatDefinitions, 'field')
            }

            return output
        }

        function getDataFromItemComposites(items, options) {
            let output

            if (options && options.onlyLatestData) {
                // console.log("------------------------\n" + items.length)
                if (items.length && items[0].type === enums.objectType.measureAnswer) {
                    items = _.chain(items) // subItems are item composites since they come from the negotiator
                        .groupBy(options && options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                        .mapValues((itemComposites, orgId) => {
                            // console.log(itemComposites.length, wfMeasureService.groupAndFormatAnswersByPeriod(_.map(itemComposites, "dataRelation")).length, orgId)
                            return _.intersectionWith(itemComposites, wfMeasureService.groupAndFormatAnswersByPeriod(_.map(itemComposites, 'dataRelation')), (itemComposite, content) => {
                                if (itemComposite.content.wfid === content.wfid) {
                                    itemComposite.periodName = content.periodName
                                    return true
                                }
                            })
                        })
                        .map()
                        .flatten()
                        .value()
                }
                else if (items.length && items[0].type === enums.objectType.questionAnswer) {
                    items = _.chain(items) // subItems are item composites since they come from the negotiator
                        .groupBy(options && options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                        .mapValues((itemComposites, orgId) => {
                            return _.sortBy(itemComposites, 'dataRelation.createdAt')[itemComposites.length - 1]
                        })
                        .map()
                        .flatten()
                        .value()
                }
                // console.log(items.length + "\n------------------------");
            }

            output = _.map(items, (itemComposite) => {
                const content = itemComposite.content

                return itemComposite.tableRowContent = getRowContentByType(content.type, content, options, itemComposite)
            })

            if (options && options.includeAttachedData) {
                const subItemsWithAttachedData = items.filter(item => _.get(item, 'content.metadata.countByRelationKind[5]') > 0)

                if (subItemsWithAttachedData) {

                    return $q((resolve, reject) => {
                        const promise = apiProxy.raw('multi.getSubItemsOfAll', {
                            wfids: _.uniq(subItemsWithAttachedData.map(x => x.wfid)),
                            kind: enums.subItemsKind.relatedContentByUser,
                            ticket: options.ticket,
                            //loadParents: false,
                            // loadMetadata: false,
                            // loadVisibilityTags: false,
                            // getterConditions: {
                            // 	loadCreators: false,
                            // 	includeOrganizations: false
                            // }
                        })

                        let attachedDataByOrgAndItem

                        promise.then((res) => {
                            res.forEach((relation) => {
                                if (!relation.childContent) {
                                    const relationWithChildContent = res.find(x => x.wfcid === relation.wfcid && x.childContent)
                                    if (relationWithChildContent) {
                                        relation.childContent = relationWithChildContent.childContent
                                    }
                                }
                            })
                            attachedDataByOrgAndItem = res.reduce((acc, item) => ({
                                ...acc,
                                [item.creatorOrganizationWfid]: {
                                    ...acc[item.creatorOrganizationWfid],
                                    [item.wffid]: [
                                        ...(acc[item.creatorOrganizationWfid] ? acc[item.creatorOrganizationWfid][item.wffid] || [] : []),
                                        wfPropertyExtractor.getItemSummary(item),
                                    ],
                                },
                            }), {})

                            items.forEach((itemComposite) => {
                                const attachedData = attachedDataByOrgAndItem[`101-${itemComposite.dataRelation.organizationId}`] ? attachedDataByOrgAndItem[`101-${itemComposite.dataRelation.organizationId}`][itemComposite.content.wfid] : undefined
                                if (attachedData) {
                                    itemComposite.tableRowContent.attached = attachedData.join('\n\n\n')
                                }
                            })

                            resolve(output)
                        })
                    })
                }

            }

            return output
        }

        function getRowContentByType(objectType, content, options, itemComposite) {
            let output
            switch (objectType) {
                case enums.objectType.measureAnswer:
                    output = {
                        period: wfMeasureService.getMeasureAnswerPeriodName(content, null, { useNumericMonthlyFormat: true }),
                        value: content.isNumber ? parseFloat(content.value) : content.value,
                        unit: content.childContent ? content.childContent.symbol : '',
                    }
                    break
                case enums.objectType.questionAnswer:
                    output = {
                        answer: _.get(content, 'childContent.text'), // childContent is questionAnswerType
                    }
                    break
                case enums.objectType.orgActivity:
                case enums.objectType.orgDocument:
                case enums.objectType.embed:
                case enums.objectType.link:
                case enums.objectType.certificate:
                    output = {
                        title: content.title,
                        description: content.description,
                    }
                    break
                case enums.objectType.statement:
                case enums.objectType.question:
                case enums.objectType.measure:
                    output = {
                        text: content.text,
                    }
                    break
                case enums.objectType.country:
                    output = {
                        country: content.name,
                    }
                    break
                case enums.objectType.organization:
                    output = {
                        organization: content.name,
                    }
                    break
                case enums.objectType.individual:
                case enums.objectType.person:
                    output = {
                        name: content.name,
                    }
                    break
                case enums.objectType.structure:
                    output = {
                        title: content.title || content.description,
                    }
                    break
                case enums.objectType.location:
                    output = {
                        name: content.name,
                        address: content.formattedAddress,
                    }
                    break
                case enums.objectType.dateItem:
                    output = {
                        reportedDate: output = wfPropertyExtractor.getBodyText(content),
                    }
                    break
                default:
                    output = {}
                    break
            }

            switch (objectType) {
                case enums.objectType.orgActivity:
                    output.atDate = content.whenDate ? moment(content.whenDate).format('YYYY-MM-DD') : ''
                    output.untilDate = content.untilDate ? moment(content.untilDate).format('YYYY-MM-DD') : ''
                    break
            }

            output.dateAdded = moment(itemComposite.createdAt || content.createdAt)//.format("YYYY-MM-DD HH:mm");
            output.wfid = itemComposite.wfid

            if (options) {
                if (options.includeCreatorUser) {
                    output.creatorUser = _.get(itemComposite.creatorUser || content.creatorUser, 'name')
                    output.creatorUserEmail = _.get(itemComposite.creatorUser || content.creatorUser, 'email')
                }

                if (options.includeCreatorOrganization) {
                    // if (itemComposite.dataRelation && itemComposite.dataRelation.contextParentType === enums.objectType.organization) {
                    // 	output.creatorOrganization = _.get(wfObject.get(_.get(itemComposite.dataRelation, "wfxpid")), "name");
                    // }
                    // else {
                    output.creatorOrganization = _.get(itemComposite.creatorOrganization || content.creatorOrganization, 'name')

                    if (itemComposite.dataRelation && itemComposite.dataRelation.wfxpid && itemComposite.dataRelation.contextParentContent) {
                        const { id, type, name } = itemComposite.dataRelation.contextParentContent
                        output.contextParentId = id
                        output.contextParentType = type
                        output.contextParent = name
                    }
                    // }
                }
            }

            output.creatorOrContextOrganizationId = output.contextParentType === 101 ?  output.contextParentId : _.get(itemComposite.creatorOrganization || content.creatorOrganization, 'id')

            return output
        }

        function getColumnDefinitionsByType(objectType, options) {
            const
                output = []

            const columnDefPrototype = {
                field: undefined,
                // width: "*",
                // maxWidth: 300,
                // resizable: true,
                // enableFiltering: true,
                enableColumnResizing: true,
                // minWidth: 100
            }

            switch (objectType) {
                case enums.objectType.measureAnswer:
                    appendColumn('period')
                    appendColumn('value')
                    appendColumn('unit')
                    break
                case enums.objectType.questionAnswer:
                    appendColumn('answer')
                    break
                case enums.objectType.orgActivity:
                case enums.objectType.orgDocument:
                case enums.objectType.embed:
                case enums.objectType.link:
                case enums.objectType.certificate:
                    appendColumn('title')
                    appendColumn('description')
                    break

                case enums.objectType.statement:
                case enums.objectType.question:
                case enums.objectType.measure:
                    appendColumn('text')
                    break
                case enums.objectType.country:
                    appendColumn('country')
                    break
                case enums.objectType.organization:
                    appendColumn('organization')
                    break
                case enums.objectType.structure:
                    appendColumn('title')
                    break
                case enums.objectType.individual:
                case enums.objectType.person:
                    appendColumn('name')
                    break
                case enums.objectType.location:
                    appendColumn('name')
                    appendColumn('address')
                    break
                case enums.objectType.dateItem:
                    appendColumn('reportedDate')
                    break
            }

            switch (objectType) {
                case enums.objectType.orgActivity:
                    appendColumn('atDate')
                    appendColumn('untilDate')
                    break
            }

            appendColumn('dateAdded')

            if (options) {
                if (options.includeCreatorUser) {
                    prependColumn('creatorUserEmail')
                    prependColumn('creatorUser')
                }

                if (options.includeContextParent) {
                    prependColumn('contextParent', { name: options.contextParentHeader })
                }

                if (options.includeCreatorOrganization) {
                    prependColumn('creatorOrganization', {  })
                }

                if (options.includeAttachedData) {
                    appendColumn('attached', {  })
                }
            }

            return output

            function appendColumn(fieldName, defOptions) {
                output.push(_.defaults(defOptions, { field: fieldName }, columnDefPrototype))
            }

            function prependColumn(fieldName, defOptions) {
                output.unshift(_.defaults(defOptions, { field: fieldName }, columnDefPrototype))
            }
        }
    }
})()
