import * as React from 'react'
import { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { usePreviousDifferent } from '@worldfavor/hooks'
import { useWidth } from '@worldfavor/hooks/dimensions'

const CurvedLine = ({ x1, y1, x2, y2, style }) => {
    //const mx = x1 + (x2 - x1) / 2
    const my = y1 + 0 + (y2 - y1) / 2
    return (
        <path
            d={`M ${x1} ${y1} C ${x1} ${my} ${x2} ${my} ${x2} ${y2}`}
            fill="transparent"
            style={style}
        />
    )
}

const useStyles = makeStyles({
    root: {
        verticalAlign: 'top',
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    node: {
        display: 'inline-block',
        marginLeft: 4,
        marginRight: 4,
    },
    childContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    subTreeContainer: {
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
    },
    placeholder: {
        verticalAlign: 'top',
        display: 'inline-block',
        marginLeft: 4,
        marginRight: 4,
    },
})

const initialState = {
    collapsed: false,
    childElements: {},
    arrowCoords: [],
    arrowContainerWidth: null,
    active: false,
}

function reducer(state, action) {
    switch (action.type) {
        case 'COLLAPSE':
            return {
                ...state,
                collapsed: true,
            }
        case 'EXPAND':
            return {
                ...state,
                collapsed: false,
            }
        case 'ADD_CHILD_ELEMENT':
            return {
                ...state,
                childElements: {
                    ...state.childElements,
                    [action.id]: action.element,
                },
            }
        case 'REMOVE_CHILD_ELEMENT': {
            const { [action.id]: ignore, ...rest } = state.childElements
            return {
                ...state,
                childElements: {
                    ...rest,
                },
            }
        }
        case 'SET_ARROW_COORDS':
            return {
                ...state,
                arrowCoords: action.coords,
            }
        case 'SET_ARROW_CONTAINER_WIDTH':
            return {
                ...state,
                arrowContainerWidth: action.width,
            }
        case 'RESET_ARROW_CONTAINER_WIDTH':
            return {
                ...state,
                arrowContainerWidth: null,
            }
        case 'SET_ACTIVE':
            return {
                ...state,
                active: true,
            }
        case 'SET_INACTIVE':
            return {
                ...state,
                active: false,
            }
        default:
            return state
    }
}

const getArrowCoordinates = (
    nodeElem,
    childElements,
    offsetLeft,
    placeholderElem,
    edges,
    id,
    parentId,
    fromKey,
    toKey,
    idKey,
) => {
    const x1 = nodeElem.offsetLeft + nodeElem.offsetWidth / 2 - offsetLeft

    const coords = Object.keys(childElements)
        .map((key) => {
            const edge = edges.find(edge => edge[idKey] === key)
            if (!edge) {
                return
            }

            const elem = childElements[key]
            return {
                key: `arc-from-${edge[fromKey]}-to-${edge[toKey]}-id-${edge[idKey]}`,
                x1,
                x2: elem.offsetLeft + elem.offsetWidth / 2 - offsetLeft,
            }
        })
        .filter(Boolean)

    if (placeholderElem) {
        coords.push({
            key: `placeholder-from-${id}-through-${parentId || 'root'}`,
            x1,
            x2: placeholderElem.offsetLeft + placeholderElem.offsetWidth / 2 - offsetLeft,
            placeholder: true,
        })
    }

    return coords
}

const DEFAULT_ARROW_CONTAINER_HEIGHT = 128

const Tree = (props) => {
    const {
        id,
        edgeId,
        parentId,
        edges = [],
        nodes = {},
        active: parentActive,
        renderPlaceholder,
        renderNode,
        onNodeInit,
        onNodeDestroy,
        onChildLayoutUpdate,
        idKey = 'wfid',
        fromKey = 'fromWfid',
        toKey = 'toWfid',
        onTreeMounted,
    } = props
    const classes = useStyles(props)

    const mounted = useRef(false)
    const nodeInitialized = useRef(false)

    const [state, dispatch] = useReducer(reducer, initialState)
    const { collapsed, childElements, arrowCoords, arrowContainerWidth, active } = state
    const [directChildren, setDirectChildren] = useState([])

    const nodeRef = useRef(null)
    const placeholderContainer = useRef(null)
    const containerRef = useRef()
    const arrowContainer = useRef()
    const [childContainer, width] = useWidth()
    const prevArrowContainerWidth = usePreviousDifferent(arrowContainerWidth)

    // get the current node to display
    const currentNode = nodes[id]

    // get all direct children
    useEffect(() => {
        setDirectChildren(edges
            .filter(edge => edge[fromKey] === id)
            .map(edge => ({
                edge,
                node: nodes[edge[toKey]],
            })))
    }, [edges, nodes, id, fromKey, toKey])

    const childrenIds = directChildren.map(({ edge }) => edge[idKey]).join()

    // calculate arrow height depending on the width of the subtree
    const arrowHeight = ((arrowContainerWidth || prevArrowContainerWidth) * (15 / 110)) || DEFAULT_ARROW_CONTAINER_HEIGHT

    const setArrowCoordinates = () => {
        const coords = getArrowCoordinates(nodeRef.current, childElements, containerRef.current.offsetLeft,
            placeholderContainer.current, edges, id, parentId, fromKey, toKey, idKey)
        dispatch({ type: 'SET_ARROW_COORDS', coords })
    }

    function onMouseOver() {
        !active && dispatch({ type: 'SET_ACTIVE' })
    }

    function onMouseOut() {
        active && dispatch({ type: 'SET_INACTIVE' })
    }

    function _onNodeInit(elem, edgeId) {
        dispatch({
            type: 'ADD_CHILD_ELEMENT',
            id: edgeId,
            element: elem,
        })
    }

    function _onNodeDestroy(edgeId) {
        dispatch({
            type: 'REMOVE_CHILD_ELEMENT',
            id: edgeId,
        })
    }

    function _onChildLayoutUpdate() {
        setArrowCoordinates()
        onChildLayoutUpdate && onChildLayoutUpdate()
    }

    function renderChildren() {
        if (directChildren.length === 0 && !renderPlaceholder) {
            return null
        }

        return (
            <div
                className={classes.childContainer}
                style={{ display: collapsed ? 'none' : 'flex' }}
            >
                {
                    arrowContainerWidth ? (
                        <svg
                            ref={arrowContainer}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{ width: arrowContainerWidth, height: arrowHeight }}
                        >
                            {
                                arrowCoords.map(({ x1, x2, key, placeholder }) => (
                                    <CurvedLine
                                        key={key}
                                        x1={x1}
                                        y1={0}
                                        x2={x2}
                                        y2={arrowHeight}
                                        style={{
                                            stroke: parentActive || active ? 'green' : 'grey',
                                            strokeWidth: 2,
                                            ...(placeholder ? { strokeDasharray: '4, 4' } : {}),
                                        }}
                                    />
                                ))
                            }
                        </svg>
                    ) : <div style={{ height: arrowHeight }} />
                }

                <div className={classes.subTreeContainer}>
                    <div ref={childContainer} style={{ display: 'inline-block', margin: '1px' }}>
                        {
                            directChildren.map(({ edge, node }) => (
                                <Tree
                                    key={edge[idKey]}
                                    edgeId={edge[idKey]}
                                    id={node[idKey]}
                                    parentId={edge[idKey]}
                                    edges={edges}
                                    nodes={nodes}
                                    onNodeInit={_onNodeInit}
                                    onNodeDestroy={_onNodeDestroy}
                                    onChildLayoutUpdate={_onChildLayoutUpdate}
                                    active={parentActive || active}
                                    renderNode={renderNode}
                                    renderPlaceholder={renderPlaceholder}
                                    idKey={idKey}
                                    fromKey={fromKey}
                                    toKey={toKey}
                                />
                            ))
                        }

                        {
                            renderPlaceholder && (
                                <div ref={placeholderContainer} className={classes.placeholder}>
                                    {renderPlaceholder(id, currentNode)}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    // init node and dispatch event to the parent
    useEffect(() => {
        if (nodeRef.current && !nodeInitialized.current) {
            onNodeInit && onNodeInit(nodeRef.current, edgeId)
            nodeInitialized.current = true
        }
    }, [nodeRef.current, edgeId])

    // when the node is removed dispatch an update
    // to the parent to rerender the arrow coordinates
    useEffect(() => {
        return () => {
            onNodeDestroy && onNodeDestroy(edgeId)
        }
    }, [edgeId])

    // whenever the arrow container width changes
    // update the arrow coordinates
    useLayoutEffect(() => {
        setArrowCoordinates()
    }, [arrowContainerWidth])

    // whenever the width of the child container
    // is changing, update the size of the arrow container
    // with the same width
    useLayoutEffect(() => {
        dispatch({ type: 'SET_ARROW_CONTAINER_WIDTH', width })
    }, [width])

    // whenever the list of children is changing
    // propagate the update to the parents
    useLayoutEffect(() => {
        onChildLayoutUpdate && onChildLayoutUpdate()
    }, [childrenIds])

    // TODO need improvement
    //  the mounted state only relies on a timeout
    useEffect(() => {
        setTimeout(() => {
            onTreeMounted && onTreeMounted()
            mounted.current = true
        }, 200)
    }, [])

    if (!currentNode) {
        return null
    }

    return (
        <div ref={containerRef} className={classes.root}>

            <div style={{ textAlign: 'center' }}>
                <div ref={nodeRef} className={classes.node}>
                    { renderNode({ node: currentNode, onMouseOver, onMouseOut, active }) }
                </div>
            </div>

            { renderChildren() }
        </div>
    )
}

export default Tree
