import React from 'react'
import { react2angular } from 'react2angular'
import { WrapProvider, StoreProvider } from './providers'
import ThirdPartyPermissions from '@worldfavor/components/ThirdPartyPermissions/ThirdPartyPermissions'

const props = ['thirdPartyRequestsStructure']

const ThirdPartyPermissionsWrapper = (props) => {
    const { wfAuth } = props
  
    return (
        <WrapProvider wfAuth={wfAuth}>
            <StoreProvider>
                <ThirdPartyPermissions />
            </StoreProvider>
        </WrapProvider>
    )
}

export default react2angular(ThirdPartyPermissionsWrapper, props, ['wfAuth'])
