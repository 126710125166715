import * as React from 'react'
import { isIE } from '@worldfavor/utils'
import { setCurrentSourceType } from './sourceTypeRegistry'

// TODO make this code compatible with mobile
class Draggable extends React.Component {

  state = {
      isDragging: false,
  }

  onDragStart = (ev) => {
      const { data, sourceType } = this.props
      if (isIE()) {
          ev.dataTransfer.setData('Text', data)
          setCurrentSourceType(sourceType)
      }
      else {
          ev.dataTransfer.setData(sourceType, data)
      }
      this.setState({ isDragging: true })
  }

  onDragEnd = () => {
      this.setState({ isDragging: false })
      setCurrentSourceType(null)
  }

  render() {
      const { children } = this.props
      const { isDragging } = this.state
      return (
          <div
              draggable
              onDragStart={this.onDragStart}
              onDragEnd={this.onDragEnd}
              style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          >
              {children(isDragging)}
          </div>
      )
  }
}

export default Draggable
