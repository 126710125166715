(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfExpandContent', wfExpandContent)

    wfExpandContent.$inject = []
    function wfExpandContent() {
        const directive = {
            link,
            restrict: 'A',
            transclude: true,
            require: '^^wfExpand',
        }
        return directive
		
        function link(scope, element, attrs, controller, $transclude) {
            let transcludedContent; let transclusionScope

            if (controller.options.allowExpand) {
                element.addClass('collapse')
            }
            else {
                element.removeAttr('wf-expand-content')
                return false
            }

            controller.setExpandableElement(element)
			
            _.assign(controller, {
                compileElements,
                destroyElements,
            })

            function compileElements() {
                $transclude((clone, scope) => {
				  element.append(clone)
				  transcludedContent = clone
				  transclusionScope = scope
                })
            }

            function destroyElements() {
                transclusionScope.$destroy()
                transcludedContent.remove()
            }
        }
    }
})()
