import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import { _allWfObjects, getEdgeAndOutNodeFromEdge } from './dataSelector'
import { StaticIds } from '@worldfavor/constants'
import { getFromWfidAttribute } from '@worldfavor/utils/selectors'
import sortBy from 'lodash/sortBy'

export const getNetworks = createSelector( // eslint-disable-line import/prefer-default-export
    [_allWfObjects],
    (items) => {
        const networks = Object.values(items)
            .filter(item => getFromWfidAttribute(item) === StaticIds.NetworksReceivedFrom)
            .map(item => getEdgeAndOutNodeFromEdge(items, item))
            .map(({ node }) => ({
                network: node,
                creatorOrganization: items[node.creatorOrganizationWfid] || {},
            }))
        return sortBy(networks, 'creatorOrganization.name')
    },
)
