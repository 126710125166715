(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfEmptyState', wfEmptyState)

    wfEmptyState.$inject = ['$translate', '$sanitize']
    function wfEmptyState($translate, $sanitize) {
        const directive = {
            bindToController: true,
            controller: ControllerController,
            controllerAs: 'vm',
            link,
            transclude: true,
            restrict: 'E',
            templateUrl: 'scripts/wf/common/wfEmptyState.directive.html',
            scope: {
                image: '@',
                icon: '<',
                iconScale: '<',
                header: '=',
                headerTranslate: '=',
                descriptionTranslate: '=',
                description: '=',
                condition: '=',
                imageWidth: '@',
                descriptionWidth: '@',
                click: '&',
            },
        }

        return directive
		
        function link(scope, element, attrs, vm) {
            let headerWatcher; let descriptionWatcher

            if (vm.headerTranslate) {
                vm.actualHeader = $translate.instant(vm.headerTranslate)
            }
            else {
                headerWatcher = scope.$watch('vm.header', () => {
                    vm.actualHeader = vm.header
                })
            }
			
            if (vm.descriptionTranslate) {
                vm.actualDescription = $translate.instant(vm.descriptionTranslate)
            }
            else {
                descriptionWatcher = scope.$watch('vm.description', () => {
                    vm.actualDescription = vm.description
                })
            }

            if (vm.icon) {
                vm.showCircleAroundIcon = 'circled' in attrs
                if (vm.iconScale) {
                    vm.iconStyle = { 'font-size': (vm.iconScale * 70) + 'px' }
                }
            }

            scope.$on('$destroy', () => {
                if (descriptionWatcher) descriptionWatcher()

                if (headerWatcher) headerWatcher()
            })
            // scope.$watch("vm.header", function (text) {
            // 	// console.log(text)
            // })
        }
    }

    /* @ngInject */
    function ControllerController() {
		
    }
})()
