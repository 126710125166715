(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfPermissionWrite', wfPermissionWrite)

    wfPermissionWrite.$inject = ['wfAuth', '$compile']
    function wfPermissionWrite(wfAuth, $compile) {
        const directive = {
            restrict: 'A',

            // When wf-permission-write and wf-permission-create are on the same element, one of them need to execute before the other
            // This directive will execute first followed by wf-permission-create (priority 10001) (defined in wfPermissionDirectives.js)
            priority: 10002, // process this directive first.
            terminal: true, // stop compiling other directives on the
            // element, we'll fix it in `post`
            compile(element, attrs) {
                return {
                    post(scope, element, attrs, ctrl) {
                        if (scope.vm && scope.vm.context && scope.vm.context.influence && scope.vm.context.influence.organizationId !== (wfAuth.getOrganizationId() || null)) {
                            element.remove()
                        }
                        else if (wfAuth.canUserWrite() && !(scope.vm && scope.vm.context && scope.vm.context.isViewMode)) {
                            // prevent recursion
                            element[0].removeAttribute('wf-permission-write')
							
                            // process the directives we added
                            $compile(element)(scope)
                        }
                        else {
                            // console.log("remove element", element)
                            element.remove()
                        }
                    },
                }
            },
        }
        return directive
    }
})()
