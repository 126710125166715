(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfInclude', wfInclude)

    wfInclude.$inject = []
	
    function wfInclude() {
        const directive = {
            restrict: 'AE',
            templateUrl(element, attrs) {
                return attrs.src
            },
        }
        return directive
    }
})()
