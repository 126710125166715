(function() {
    'use strict'
	
    angular
        .module('wf.common')
        .component('wfRelativeMeasureSteps', {
            templateUrl: 'scripts/wf/relativeMeasures/steps/wfRelativeMeasureSteps.component.html',
            controller: wfRelativeMeasureStepsController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
            },
        })

    wfRelativeMeasureStepsController.$inject = ['$scope', '$timeout', 'dataOperationsService', 'analyzeService', '$q', '$uibModal', '$rootScope']
    function wfRelativeMeasureStepsController($scope, $timeout, dataOps, analyzeService, $q, $uibModal, $rootScope) {
        const
            vm = this
			
        const mainItem = vm.item
			
        let relationsWithMeasurePeriodSettings
		
        _.assign(vm, {
            // item: undefined,
            loading: true,
            availableRequirementOptions: undefined,
            maxItemsCount: 5,
            itemsLimit: 5,
			
            editRelativeMeasureCalculations,
        })

        this.$onInit = $onInit

        function $onInit() {
            // vm.requirementsLoader = new analyzeService.requirementsLoader(mainItem, vm.ticket, $scope);

            // if (mainItem.childType === enums.objectType.measure) {
            // 	dataOps.getSubItems(mainItem.childContent, enums.subItemsKind.parents, {
            // 		onlyLoadRelations: true
            // 	}).then(function (res) {
            // 		relationsWithMeasurePeriodSettings = _.filter(res, function (dataRelation) {
            // 			return dataRelation.settings && dataRelation.settings.measurePeriodSettings;
            // 		});
            // 		loadFormattedRequirementsOnItem(relationsWithMeasurePeriodSettings.length ? { additionalItemsWithMeasurePeriodSettings: relationsWithMeasurePeriodSettings } : null);
            // 	});
            // }
            // else {
            // 	loadFormattedRequirementsOnItem();
            // }

            // function loadFormattedRequirementsOnItem(options) {
            // 	vm.requirementsLoader.loadFormattedRequirementsOnItem(options).then(function (formattedRequirements) {
            // 		vm.items = formattedRequirements;
            vm.loading = false
            $timeout()
            // 	});
            // }
        }

        function editRelativeMeasureCalculations() {
            openRelativeMeasureEditor().then(() => {
                vm.loading = false
                $timeout()

                // 		vm.requirementsLoader.loadFormattedRequirementsOnItem({
                // 			fromServer: true,
                // 			additionalItemsWithMeasurePeriodSettings: relationsWithMeasurePeriodSettings
                // 		}).then(function (formattedRequirements) {
                // 			vm.items = formattedRequirements;
                // 			vm.loading = false;
                // 			$timeout();
                // 		})

            })

            function openRelativeMeasureEditor() {
                return $q((resolve, reject) => {
                    const
                        templateHtml = '<wf-relative-measure-steps-editor item="item" ticket="ticket"></wf-relative-measure-steps-editor>'
						
                    const scope = _.assign($rootScope.$new(), {
                        item: vm.item,
                        ticket: vm.ticket,
                        result: {},
                    })
						
                    const modal = $uibModal.open({
                        animation: true,
                        size: 'width-1200',
                        backdrop: 'static',
                        template: templateHtml,
                        scope,
                    })
	
                    modal.closed.then(() => {
                        resolve(scope.result)
                    })
                })
            }
        }
    }
})()
