import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.data')
        .factory('TheNewLoaderFactory', TheNewLoaderFactory)

    TheNewLoaderFactory.$inject = ['$q', '$timeout', 'apiProxy', '$rootScope', '$ngBootbox', '$translate', 'wfAuth', 'dataOperationsCache', 'DataNegotiator', '$uibModal', 'dataOperationsService', 'dataQuery', 'modalService', 'dropdownUtility', 'wfPropertyExtractor', 'wfTranslate', 'TheNewLoaderItemFactory', 'requirements']

    function TheNewLoaderFactory($q, $timeout, apiProxy, $rootScope, $ngBootbox, $translate, wfAuth, dataOpsCache, DataNegotiator, $uibModal, dataOps, dataQuery, modal, dropdownUtility, wfPropertyExtractor, wfTranslate, TheNewLoaderItemFactory, requirementService) {
        _.assign(TheNewLoader.prototype, {

        })

        TheNewLoader.instantiate = instantiate

        return TheNewLoader

        function instantiate(instanceOrOptions) {
            if (instanceOrOptions instanceof TheNewLoader) return instanceOrOptions
            else return new TheNewLoader(instanceOrOptions)
        }

        function TheNewLoader(options) {
            const
                self = this

            let authOrgId = wfAuth.getOrganizationId()

            const authUserId = wfAuth.getWorldfavorUserId()

            let lookups

            let linkageLoader

            let pageSize = 7

            let itemUtility

            // Overview:
            /*
				LOADING PROCESS:

				negotiator.loadItemsFromServer()
				|
				self.negotiator.onRequest.then()
					- Load the hierarchy as a flat list with only the relations
					|
					handleItems()
						- If self.categoryStructureId is present then intersect categoryStructure children with negotiator items,
						otherwise use all negotiator items. Put result in self.items
						- Filter out primary items using primaryItemDeterminator function (items that will appear as categories)
						- Grab the ancestors of all primary items and put them in 'parents' array. They will be used in the top filtering
						|
						self.negotiator.loadContentOnItems(_.concat(parents, self.questions)).then()
							- Load content of the parent relations first so that the filter bar can be shown
							|
							_.each(self.items, function (itemComposite, index)
								- Iterate self.items to specify all utility props needed on each item
							|
							initUserData()
							|
							loadUserData()
									self.negotiator.loadContentOnItems(self.items), not including the user data inside each item
										- Iterate self.topLevelIntersectedItems and add a container item for some topLevel items
											- If the topLevel item have relatedContentByUser
											- If the topLevel item have any questions
										- Iterate self.subLevelIntersectedItems and for every structure item make it into a container item for showing relatedContentByUser
								|.then()
									self.filteredItems = _.clone(self.items);
									self.initialLoadingFinished();
										- Set self.loaded = true
										- Apply filter and infinite scroll

				FILTERING PROCESS:

			*/

            _.assign(self, _.defaultsDeep(options, {
                debug: false,
                ticket: undefined,

                // Arrays
                items: [], // All items in the intersected hierarchy, including all main list items and filter items
                hierarchyItemComposites: [], // Items in the intersected hierarchy that are part of the filtering
                filteredItems: [], // Items after filtering was done
                viewItems: [], // Items actually shown in the UI after filtering and governed by infinite scroll feature
                onLoadedCallbacks: [],
                topLevelIntersectedItems: [], // Main list items that looks like a category
                subLevelIntersectedItems: [], // Main list items that are white boxes
                currentFilter: null,
                negotiator: undefined,
                loaded: false,
                categoryStructureId: undefined,
                categoryStructureConfig: {
                    subItemsKind: enums.subItemsKind.children,
                    loadDepth: 2,
                },
                hierarchyStructureId: undefined,
                primaryItemDepth: undefined,
                primaryItemType: enums.objectType.structure,
                primaryItemDeterminator: undefined,
                showFilteringGuidance: false,
                enableSubItems: true,
                enableLinkage: true,
                enableLearnMore: true,
                enableSideActions: true,
                sideAction: { tool: 'add' },
                excludeFilteringOnDepths: undefined,
                pagingFunctionEnabled: true,
                loadUserDataOnInit: true,
                allContentPreloaded: false, // Indicates that all childContent and user data has already been loaded from server using the provided ticket.
                showItemNumbering: false,
                treatNestedPrimaryItemsAsSubItems: true,
                includePrimaryItemsInFiltering: undefined,
                showLevelFiltering: true,
                showItemLines: false,
                showColumnHeaders: true,

                useGriDisclosureCoverImages: false,
                useItemImages: true,

                uiMode: undefined,
                isViewMode: false,
                isWorkMode: false,
                isAdminMode: false,
                canSetViewMode: false,
                canSetWorkMode: true,

                attachedInformationContainersByWfid: {},

                // A map of items that should be reloaded when other items are reported on or updated
                //   When item with this WFID is reported on
                //   |          Then reload these items
                //   |          |
                // { "21-456": [ <relativeMeasure itemComposite>, <relativeMeasure itemComposite> ] }
                itemDependenciesByWfid: undefined,
                analyzingItemsCount: 0,

                // Functions
                onLoaded,
                openGuidance,
                applyFilter,
                addSubItem,
                addItemsToCategory,
                infiniteScrollPagingFunction,
                initialLoadingFinished,
                setTicket,
                clearContentAndContainerReferencesOnAllItems,
                initUserData,
                setUiMode,
                removeItem,
                syncItemGrouping,
                syncFulfillmentStatistics,
                syncUserCategorizations,
                syncItems,
                initRequirementsAndFulfillments,
            }))

            activate()

            function activate() {
                // return;
                defineLookupObjects()

                if (self.influence) {
                    self.groupQuestions = true
                }

                if (self.ticket && self.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId) {
                    authOrgId = _.get(wfObject.get(`13-${self.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId}`), 'creatorOrganizationId')
                }

                if (!self.ticket) self.ticket = { organizationId: authOrgId }

                pageSize = self.enableSubItems ? 8 : 20

                determineAvailableUiModes()

                // self._callbacks

                if (!_.get(options, 'hierarchyStructureId')) {
                    console.error('New Loader needs at least a hierarchyStructureId to load.')
                    return
                }

                itemUtility = new TheNewLoaderItemFactory({
                    loader: self,
                })

                // load intersected scope + hierarchy
                self.negotiator = dataOps.persistentDataNegotiator(DataNegotiator, {
                    fromItem: { type: enums.objectType.structure, id: options.hierarchyStructureId },
                    useRecursiveRelationsLoading: true,
                    additionalSubItemsKinds: self.influence ? [enums.subItemsKind.relatedContentByUser] : [enums.subItemsKind.linkageChildren],
                    ticket: options.ticket,
                    includeStatistics: false,
                    onlyStatistics: true,
                    onlyLoadRelations: true,
                    loadDepth: 10,
                    loadParents: false,
                    loadMetadata: false,
                    loadVisibilityTags: false,
                    loadCreators: false,
                    preloadedItem: self.negotiatorPreloadedItem,
                    allContentPreloaded: self.allContentPreloaded,
                })

                self.negotiator.onRequest.then((hierStructure) => {
                    if (self.isSyncingFromServer) {
                        const
                            itemsToRemove = _.differenceBy(self.negotiatorItemsBeforeSync, self.negotiator.items, 'relationWfid')

                        let itemsToAdd = _.differenceBy(self.negotiator.items, self.negotiatorItemsBeforeSync, 'relationWfid')

                        itemsToRemove.forEach(x => removeItem(self.items.find(y => y.relationWfid === x.relationWfid)))

                        itemsToAdd = itemsToAdd.filter(x => x.type !== 31 && x.type !== 25)
                        itemsToAdd.forEach(x => x.newlyAddedViaSyncFromServer = true)

                        if (itemsToAdd.length) {
                            self.negotiator.loadContentOnItems(itemsToAdd).then(() => {
                                handleItems(itemsToAdd)
                                self.initRequirementsAndFulfillments()
                                $timeout()
                            })
                        }
                        else {
                            self.syncingItems = false

                            if (itemsToRemove.length) {
                                self.initRequirementsAndFulfillments()
                            }
                        }

                        return
                    }

                    self.hierarchyStructure = hierStructure

                    if (self.influence) {
                        self.showItemNumbering = _.get(self.hierarchyStructure, 'conditions.requirementPackageSettings.showItemNumbering') !== false
                    }

                    if (!self.excludeFilteringOnDepths) self.excludeFilteringOnDepths = _.get(self.hierarchyStructure, 'conditions.uiSettings.scopeDashboardConfig.excludeFilteringOnDepths') || []

                    const structureScopeDashboardConfig = _.get(self.hierarchyStructure, 'conditions.uiSettings.scopeDashboardConfig')

                    if (structureScopeDashboardConfig) {
                        if (typeof structureScopeDashboardConfig.primaryItemDepth === 'number' || structureScopeDashboardConfig.primaryItemType) {
                            self.primaryItemDepth = structureScopeDashboardConfig.primaryItemDepth
                            self.primaryItemType = structureScopeDashboardConfig.primaryItemType
                        }
                    }

                    if (_.isUndefined(self.primaryItemDepth) && _.isUndefined(self.primaryItemType)) {
                        self.primaryItemDepth = 1
                    }

                    self.pageHeaderObject = {
                        title: self.negotiator.item.title,
                        // description: self.negotiator.item.description,
                    }
                    // self.isConsolidation = self.negotiator.ticket && self.negotiator.ticket.organizationIds;
                    // self.config = _.defaultsDeep(self.negotiator.listInterfaceConfig, self.config);

                    // load subitems of scope

                    if (options.categoryStructureId) {
                        dataOps.getObject('71-' + options.categoryStructureId, {
                            childrenLoadDepth: -1,
                            skipExtras: true,
                        }).then((categoryStructure) => {
                            const categorySubItemKind = _.get(self.categoryStructureConfig, 'subItemsKind')
                            dataOps.getSubItems('71-' + options.categoryStructureId, categorySubItemKind, {
                                skipExtras: true,
                                onlyLoadRelations: true,
                                ticket: self.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId ? { receivingOrganizationsAndLimitedDataAccessFromInfluenceId: self.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId } : undefined,
                            }).then(() => {
                                self.categoryStructure = categoryStructure
                                self.pageHeaderObject.preTitle = categoryStructure.title
                                self.categoryRelations = getCategoryStructureRelations()
                                handleItems()
                            })
                        })
                    }
                    else {
                        // If no categoryStructureId is defined then don't use intersection, just use the hierarchy structure and primaryItemDeterminator function
                        self.categoryStructure = self.negotiator.item
                        self.pageHeaderObject.preTitle = self.negotiator.item.title
                        // self.categoryRelations = getCategoryStructureRelations();
                        handleItems()
                    }
                })

                if (self.hierarchyStructureId === 10517) {
                    self.isHierarchy_griStandards = true
                    self.useGriDisclosureCoverImages = true
                    self.useItemImages = false

                    if (self.enableLinkage) {
                        linkageLoader = dataOps.linkageLoader({
                            limit: ['71-387'],
                            loadParents: true,
                            preloadedLinkageRelationalMap: lookups.sdgLinkageByGriWfid,
                            groupBySource: false,
                        })

                        linkageLoader.onLoaded(onLinkageLoaded)

                        $rootScope.$watchCollection(() => {
                            return self.filteredItems
                        }, () => {
                            const items = _.filter(self.filteredItems, { type: enums.objectType.structure })
                            linkageLoader.loadLinkage(items)
                            // if (self.allChunksLoaded) {
                            // 	self.allChunksLoaded = false;
                            // 	self.viewItemsChangingAnimation = true;
                            // 	$timeout(function () {
                            // 		self.allChunksLoaded = true;
                            // 		self.viewItemsChangingAnimation = false;
                            // 	}, 900);
                            // }
                        })
                    }
                }
                else if (self.hierarchyStructureId === 387) {
                    self.isHierarchy_sdg = true
                    self.enableLinkage = false
                }
                else {
                    self.enableLinkage = false
                }
            }

            self.$onChanges = function (changesObj) { }
            self.$onDestroy = function () {
                // THIS WONT RUN
                // THIS WONT RUN
                // THIS WONT RUN
                // THIS WONT RUN
                // THIS WONT RUN
                // THIS WONT RUN
                // THIS WONT RUN
                // NEED TO STILL CLEAR THE NEGOTIATORS
                // NEED TO STILL CLEAR THE NEGOTIATORS
                // NEED TO STILL CLEAR THE NEGOTIATORS
                // NEED TO STILL CLEAR THE NEGOTIATORS
                // NEED TO STILL CLEAR THE NEGOTIATORS
                dataOps.clearPersistentNegotiators()
            }

            function syncItems(options) {
                self.isSyncingFromServer = true
                self.syncingItems = true

                if (self.influenceLoadingXhrRequest) {
                    self.influenceLoadingXhrRequest.abort()
                    self.influenceLoadingXhrRequest = undefined
                }

                self.influenceLoadingXhrRequest = apiProxy.raw('multi.getObject', {
                    objectId: self.influence.id,
                    objectType: self.influence.type,
                    culture: wfAuth.getCulture(),
                })

                self.influenceLoadingXhrRequest.then((res) => {
                    wfObject.inject(res)
                    load()
                })

                const load = () => {
                    self.negotiator.additionalSubItemsKinds = self.influence ? [enums.subItemsKind.relatedContentByUser] : [enums.subItemsKind.linkageChildren]
                    self.negotiatorItemsBeforeSync = _.clone(self.negotiator.items)
                    self.negotiator.loadItemsFromServer()
                }
            }

            function addSubItem(itemComposite) {
                if (itemComposite.container) {
                    if (itemComposite.container.isAttachedInformation) {

                    }
                }
                else if (itemComposite.type === enums.objectType.measure) {
                    modal.openMeasureAnswerCreator(itemComposite.dataRelation, itemComposite.content).then((res) => {
                        if (res) itemComposite.chartVm.loadDataAndInitialize({ forceNewNegotiator: true })
                    })
                }
            }

            function determineAvailableUiModes() {
                const presetUiMode = self.uiMode

                if (_.get(self.ticket, 'networkId')) {
                    if (_.get(self.ticket, 'organizationId') && self.ticket.organizationId === authOrgId) {
                        self.canSetViewMode = true
                        self.canSetWorkMode = true
                        setUiMode(enums.uiMode.work)
                    }
                    if (_.get(self.ticket, 'organizationId') && _.get(self.ticket, 'thirdPartyOrganizationId')
						&& self.ticket.thirdPartyOrganizationId === authOrgId
						&& self.ticket.organizationId !== authOrgId
                    ) {
                        self.canSetViewMode = true
                        self.canSetWorkMode = true
                        setUiMode(enums.uiMode.work)
                    }
                    else if (_.get(self.ticket, 'organizationId') && self.ticket.organizationId !== authOrgId) {
                        self.canSetViewMode = false
                        self.canSetWorkMode = false
                        setUiMode(enums.uiMode.view)
                    }
                    else if (_.get(self.ticket, 'organizationIds')) {
                        self.canSetViewMode = false
                        self.canSetWorkMode = false
                        setUiMode(enums.uiMode.view)
                    }
                }
                else {
                    self.canSetViewMode = true
                    self.canSetWorkMode = true
                    setUiMode(enums.uiMode.view)
                }

                function setUiMode(uiMode) {
                    if (presetUiMode) {
                        if (presetUiMode === enums.uiMode.view && self.canSetViewMode) self.setUiMode(presetUiMode)
                        else if (presetUiMode === enums.uiMode.work && self.canSetWorkMode) self.setUiMode(presetUiMode)
                    }
                    else self.setUiMode(uiMode)
                }
            }

            function onLoaded(callback) {
                self.onLoadedCallbacks.push(callback)

                if (self.loaded) callback()
            }

            // Run when initial loading has finished and items are available
            function initialLoadingFinished() {
                if (_.get(self.sideAction, 'tool') === 'toggle') {
                    initSideAction_toggle(self.sideAction)
                }
                else {
                    if (self.items.length === 0) {
                        self.emptyState = {
                            header: 'Nothing added yet',
                        }
                    }
                }

                if (!self.loaded) {
                    rejectExcludedItemsFromFiltered()
                    infiniteScrollPagingFunction()

                    if (self.influence) {
                        self.showLevelFiltering = false

                        initRequirementsAndFulfillments()
                        syncUserCategorizations().then((x) => {
                            const currentUserHaveQuestionsAssigned = self.influence.taggedUserIds && self.influence.taggedUserIds.includes(authUserId)
                            if (currentUserHaveQuestionsAssigned) {
                                const userFilterOption = self.userCategorizationsStatistics[`100-${authUserId}`]
                                if (userFilterOption) {
                                    self.applyFilter(userFilterOption)
                                }
                            }
                        })
                    }

                    self.loaded = true
                    syncItemGrouping()
                    // applyFilter();
                    _.each(self.onLoadedCallbacks, (callback) => {
                        if (typeof callback === 'function') callback()
                    })
                    $timeout()
                }
                else {
                    // MOVE AWAY FROM HERE. THIS FUNCTION SHOULD CONTAIN LOGIC FOR INITIAL LOADING ONLY

                    // Items synced
                    if (self.filterBeforeListSync && self.filterBeforeListSync.wfid in self.topFilterButtonsByWfid) {
                        self.currentFilter = self.topFilterButtonsByWfid[self.filterBeforeListSync.wfid]
                    }
                    self.pagingFunctionEnabled = true
                    self.viewItems.length = 0
                    self.applyFilter(null, self.viewItemsCountBeforeListSync > pageSize ? self.viewItemsCountBeforeListSync : pageSize) // NOT A GOOD WAY TO DETERMINE PAGESIZE

                    self.filterBeforeListSync = null
                    self.viewItemsCountBeforeListSync = null
                    $timeout()
                }
            }

            function onLinkageLoaded(linkageItems) {
                let
                    preparedItems

                _.each(linkageItems, (linkageItem) => {
                    linkageItem.goalWfid = lookups.categoryWfidBySdgWfid[linkageItem.wfid]
                    linkageItem.goal = lookups.sdgCategoriesByWfid[lookups.categoryWfidBySdgWfid[linkageItem.wfid]]
                })

                preparedItems = _.chain(linkageItems).map((linkageItem) => {
                    return {
                        title: linkageItem.title,
                        description: linkageItem.description,
                        content: linkageItem.content,
                        goal: linkageItem.goal,
                        goalWfid: linkageItem.goalWfid,
                        count: linkageItem.count,
                        wfid: linkageItem.wfid,
                        orderValue: linkageItem.count,
                    }
                }).orderBy('orderValue', 'desc').value()

                self.sdgItems = preparedItems
                self.filteredSdgItems = []

                self.linkageGoals = _.chain(preparedItems).groupBy('goalWfid')
                    .mapValues((linkageItems) => {
                        const goal = _.clone(linkageItems[0].goal)
                        goal.sdgTargets = _.chain(linkageItems).keyBy('wfid').map().value()
                        goal.targetsCount = goal.sdgTargets.length
                        goal.orderValue = _.sum(goal.sdgTargets, 'weightedPercentage')
                        return goal
                    })
                    .map()
                    .orderBy('orderValue', 'desc')
                    .take(5)
                    .value()

                self.linkageItemsByGriWfid = _.groupBy(linkageItems, 'forItem.wfid')
                self.linkageGoalsByGriWfid = _.chain(self.linkageItemsByGriWfid).mapValues((linkageItems) => {
                    const output = _.chain(linkageItems)
                        .groupBy('goalWfid')
                        .mapValues((linkageItems) => {
                            const goal = _.clone(linkageItems[0].goal)
                            goal.sdgTargets = linkageItems
                            return goal
                        })
                        .map()
                        .sort((a, b) => {
                            return natsort()(a.title, b.title)
                        })
                        .value()
                    return output
                })
                    .value()

                $timeout()
            }

            function clearItemCaches() {
                self.itemDependenciesByWfid = {}
                self.itemsByWfid = {}
                self.items = []
                self.filteredItems = []
                self.topLevelIntersectedItems = []
                self.subLevelIntersectedItems = []
                self.intersectedPrimaryItemsByWfid = {}
                self.currentFilter = null
            }

            function handleItems(specificItems) {
                const categoryWfids = _.chain(self.categoryRelations).map('wfcid').uniq().value()
                if (!specificItems) {
                    clearItemCaches()
                }
                else {
                    if (specificItems.length === 0) {
                        return
                    }
                }

                const
                    negotiatorItems = specificItems || self.negotiator.items

                let primaryItemDeterminatorFunc = self.primaryItemDeterminator

                let topLevel = null

                const intersectedPrimaryItemsByWfid = self.intersectedPrimaryItemsByWfid

                const clonedNegotiatorItems = _.map(negotiatorItems, (itemComposite, index) => { // BAD PERFORMANCE: FIX!!!
                    _.assign(itemComposite, itemUtility.itemCompositePrototype)
                    itemComposite.compiler = {}
                    itemComposite.subItemsLoaded = specificItems ? false : self.allContentPreloaded
                    itemComposite.subItemsKind = itemComposite.type === enums.objectType.structure ? enums.subItemsKind.relatedContentByUser : enums.subItemsKind.childrenByUser
                    itemComposite.uniqueId = itemComposite.relationWfid + '|' + itemComposite.parentWfid + '|' + itemComposite.wfid + '|' + index

                    return _.clone(itemComposite)
                })

                let intersectedHierarchy = []

                let intersectedHierItemsByWfid

                let intersectedHierByDepth

                let itemsByParentWfid

                const itemsByWfid = self.itemsByWfid

                if (typeof primaryItemDeterminatorFunc !== 'function') {
                    primaryItemDeterminatorFunc = self.primaryItemDeterminator = function (itemComposite) {
                        if (typeof self.primaryItemDepth === 'number') return itemComposite.depth === self.primaryItemDepth
                        else if (self.primaryItemType) {
                            if (self.primaryItemType === enums.objectType.structure) {
                                return itemComposite.type === self.primaryItemType && !dataQuery.hasItemAnySettingForAttachingData(itemComposite)
                            }
                            else return itemComposite.type === self.primaryItemType
                        }
                        else return true
                    }
                }

                if (self.categoryStructureId) {
                    // Negotiator items need to be cloned (clonedNegotiatorItems) because persistent negotiators are used
                    intersectedHierarchy = clonedNegotiatorItems.filter(x => categoryWfids.includes(x.wfid)).filter((itemComposite) => {
                        const isPrimaryType = primaryItemDeterminatorFunc(itemComposite)

                        // If primaryItemDeterminatorFunc is defined that use that, otherwise use primaryItemType or parental check
                        if (isPrimaryType || !!~categoryWfids.indexOf(itemComposite.parentWfid)) {
                            if (isPrimaryType) {
                                intersectedPrimaryItemsByWfid[itemComposite.wfid] = itemComposite
                            }

                            itemComposite.isPrimaryItem = isPrimaryType

                            if (topLevel === null || itemComposite.depth < topLevel) topLevel = itemComposite.depth

                            return true
                        }

                        return false
                    })
                }
                else {
                    // If no categoryStructureId is defined then don't use intersection, just use the hierarchy structure and primaryItemDeterminator function to get items
                    intersectedHierarchy = _.filter(clonedNegotiatorItems, (itemComposite) => {
                        let
                            output = primaryItemDeterminatorFunc(itemComposite)

                        let parent

                        itemComposite.isPrimaryItem = output || false

                        if (output) {
                            intersectedPrimaryItemsByWfid[itemComposite.wfid] = itemComposite
                        }
                        else if (itemComposite.parentWfid in intersectedPrimaryItemsByWfid) {
                            parent = intersectedPrimaryItemsByWfid[itemComposite.parentWfid]
                            if (parent && parent.isPrimaryItem) {
                                output = true
                            }
                        }
                        // If item is not a primary item and does not a have primary item parent it will still be included
                        // if it is a structure and have the self.hierarchyStructure as parent. It will be shown as a grid item.
                        else if (itemComposite.type === enums.objectType.structure && itemComposite.parentWfid === self.hierarchyStructure.wfid) {
                            output = true
                        }

                        return output
                    })
                }

                intersectedHierItemsByWfid = _.chain(intersectedHierarchy).keyBy('wfid').value()
                intersectedHierByDepth = _.groupBy(intersectedHierarchy, 'depth')
                itemsByParentWfid = _.groupBy(clonedNegotiatorItems, 'parentWfid')

                if (self.treatNestedPrimaryItemsAsSubItems) {
                    // Removes any primary items whos parent is also a primary item.
                    // Those items will be shown underneath their primary item parent.
                    // This is used in cases where the primary item determinator is also picking up their children, for example when they are of the same type
                    _.each(intersectedPrimaryItemsByWfid, (itemComposite) => {
                        if (intersectedHierItemsByWfid[itemComposite.wfid].parentWfid in intersectedPrimaryItemsByWfid) delete intersectedPrimaryItemsByWfid[itemComposite.wfid]

                    })
                }

                for (var i = 0, len = clonedNegotiatorItems.length, itemComposite; i < len; i++) {
                    itemComposite = clonedNegotiatorItems[i]

                    if (itemComposite.targetKind !== enums.subItemsKind.linkageChildren) {
                        itemsByWfid[itemComposite.wfid] = itemComposite
                    }
                }

                const includedParentsByWfid = {}
                const parents = [] // Will be used assigned to self.hierarchyItemComposites and used to construct the top filter buttons
                const contentToLoad = _.clone(intersectedHierarchy)

                // Brake out. Do it smarter elsewhere ------------------------
                //  if (self.uiMode != enums.uiMode.work)
                self.questions = _.filter(intersectedHierarchy, { type: enums.objectType.question, isPrimaryItem: false })
                // -----------------------------------------------------------

                if (self.loaded) {
                    self.filterBeforeListSync = self.currentFilter
                    self.viewItemsCountBeforeListSync = self.viewItems.length + 1
                }

                let includePrimaryItemsInFiltering = false

                //If the primary item depth is not excluded from the top level filter then include the primary item in the parent inclusion logic
                if (!self.primaryItemDeterminator) {
                    self.includePrimaryItemsInFiltering = !_.includes(self.excludeFilteringOnDepths, self.primaryItemDepth)
                }

                if (typeof self.includePrimaryItemsInFiltering === 'boolean') {
                    includePrimaryItemsInFiltering = self.includePrimaryItemsInFiltering
                }

                includePrimaryItemsInFiltering = !!self.includePrimaryItemsInFiltering

                // console.log("top level", topLevel);

                // console.log("intersectedHierarchy", intersectedHierarchy);

                // Set up parents from all items and assign children to top level items
                _.each(intersectedHierarchy, (intersectedItem) => {
                    const
                        parentWfid = intersectedItem.parentWfid

                    let parent = itemsByWfid[parentWfid]

                    const localParents = intersectedItem.parents = []

                    // if (intersectedItem.depth === topLevel) { // If the item is a top level item
                    if (intersectedItem.wfid in intersectedPrimaryItemsByWfid) { // If the item is a top level item
                        intersectedItem.actualDepth = 0
                        intersectedItem.groupWfid = intersectedItem.wfid
                        intersectedItem.children = [] // Set children to empty array
                        self.topLevelIntersectedItems.push(intersectedItem) // Add the item to the array of all top level items

                        if (includePrimaryItemsInFiltering) {
                            parent = intersectedItem
                            if (!intersectedItem.aggregatedItems) intersectedItem.aggregatedItems = []

                            includedParentsByWfid[parent.wfid] = parent
                            parents.push(parent)
                        }
                    }
                    else {
                        self.subLevelIntersectedItems.push(intersectedItem) // Add the item to the array of all sub level items

                        if (!self.enableSubItems) {
                            intersectedItem.remove = true
                            return
                        }

                        intersectedItem.actualDepth = 1
                        intersectedItem.groupWfid = parentWfid
                        if (parentWfid in intersectedHierItemsByWfid) {
                            // If the parent to the item is in the list of intersected items then add the item to the children array of the parent
                            if (!intersectedHierItemsByWfid[parentWfid].children) intersectedHierItemsByWfid[parentWfid].children = []

                            intersectedHierItemsByWfid[parentWfid].children.push(intersectedItem)
                        }
                    }

                    // console.log(intersectedItem.depth, intersectedItem.groupWfid, intersectedItem)

                    if (!includePrimaryItemsInFiltering && !(intersectedItem.wfid in intersectedPrimaryItemsByWfid) && parent && !intersectedItem.realParentWfid && parent.wfid in intersectedHierItemsByWfid) {
                        // Change parent value so that filtering will include grand-children also
                        intersectedItem.realParentWfid = intersectedItem.parentWfid // Store the original parentWfid
                        intersectedItem.parentWfid = parent.parentWfid // Set the parentWfid to be the value of the parent's parentWfid
                        parent = includedParentsByWfid[parent.parentWfid]
                    }

                    // Recursively traverse parents until the top parent is reached.
                    // parent.aggregatedItems will contain all the items combined from the levels below it.
                    var i = 0
                    while (parent) {
                        i++
                        localParents.push(parent)

                        if ((parent.wfid in includedParentsByWfid)) { // || parent.wfid in intersectedHierItemsByWfid)) {
                            if (!parent.aggregatedItems) parent.aggregatedItems = []

                            parent.aggregatedItems.push(intersectedItem)
                        }
                        else {
                            parent.aggregatedItems = [intersectedItem]

                            includedParentsByWfid[parent.wfid] = parent
                            parents.push(parent)
                        }

                        if (!itemsByWfid[parent.parentWfid]) {
                            // If no parent to the parent was found it means that we reached the top parent.
                            // Add the top parent to all parents below it so that the parents can be grouped by topParent later.
                            for (var i = 0, len = localParents.length; i < len; i++) {
                                localParents[i].topParentWfid = parent.wfid
                                localParents[i].topParent = parent
                                intersectedItem.topParent = parent
                            }
                            parent = undefined // Set to undefined to that the while-loop will break
                        }
                        else parent = itemsByWfid[parent.parentWfid]
                    }
                })

                _.each(self.topLevelIntersectedItems, (itemComposite) => {
                    if (itemComposite.children.length) itemComposite.children = _.sortBy(itemComposite.children, 'dataRelation.order')
                }) // Every children property has to be sorted so that the indexes of each item is correct after the intersectedHierarchy is sorted. Could be optimized.

                if (!self.enableSubItems) {
                    _.remove(intersectedHierarchy, { remove: true })
                }

                if (intersectedHierarchy.length === 0) {
                    self.topFilterBoxes = []
                    self.topFilterButtonsByWfid = {}
                    self.initialLoadingFinished()
                    return
                }

                // intersectedHierarchy = _.orderBy(intersectedHierarchy, [ "groupWfid", "actualDepth", "dataRelation.order" ]);

                self.hierarchyItemComposites = parents // Will be used to construct the top filter buttons

                const itemsToLoadContentOn = [...parents]

                if (self.groupQuestions) {
                    Array.prototype.push.apply(itemsToLoadContentOn, self.questions)
                }

                self.negotiator.loadContentOnItems(itemsToLoadContentOn).then(() => {
                    Array.prototype.push.apply(self.items, intersectedHierarchy)

                    if (specificItems) {
                        let arrayToSort = [].concat(self.items)
                        self.items.length = 0
                        arrayToSort = _.orderBy(arrayToSort, ['depth', 'dataRelation.order'])
                        Array.prototype.push.apply(self.items, arrayToSort)

                    }
                    // self.items.sort((a, b) => a.responseOrder - b.responseOrder);

                    _.each(intersectedHierarchy, (itemComposite, index) => {
                        // Determine what style is used to display each item
                        if (itemComposite.wfid in intersectedPrimaryItemsByWfid) {
                            itemComposite.useListStyle = true
                        }
                        else {
                            itemComposite.useGridStyle = true
                        }

                        // If the items is a GRI Disclosure then assign values used to display a pure CSS cover image on each item
                        if (itemComposite.wfid in lookups.categoryWfidByGriWfid) {
                            itemComposite.color = lookups.griColorsByWfid[lookups.categoryWfidByGriWfid[itemComposite.wfid]]
                            itemComposite.griCategoryTitle = lookups.griCategoriesByWfid[lookups.categoryWfidByGriWfid[itemComposite.wfid]]
                            itemComposite.griCode = lookups.griCodeByWfid[lookups.categoryWfidByGriWfid[itemComposite.wfid]]
                        }

                        if (itemComposite.parentWfid in lookups.categoryWfidByGriWfid) {
                            itemComposite.color = lookups.griColorsByWfid[lookups.categoryWfidByGriWfid[itemComposite.parentWfid]]
                        }

                        if (itemComposite.type === enums.objectType.structure) {
                            itemComposite.isStructure = true
                            if (!itemComposite.color) itemComposite.color = _.get(itemComposite.topParent, 'content.conditions.color1') || '#677686'
                        }

                        if (itemComposite.type === enums.objectType.measure || itemComposite.type === enums.objectType.relativeMeasure) {
                            itemComposite.open = function () {
                                itemUtility.openItemCompositeOverlay.call(this, {
                                    splitUpOrganizationStatistics: _.get(itemComposite.chartVm, 'splitUpOrganizationStatistics'),
                                    splitUpRelativeMeasureSourcesStatistics: _.get(itemComposite.chartVm, 'splitUpRelativeMeasureSourcesStatistics'),
                                    aggregateYearly: _.get(itemComposite.chartVm, 'aggregateYearly'),
                                    aggregatePeriodFrequencies: _.get(itemComposite.chartVm, 'aggregatePeriodFrequencies'),
                                    convertMeasureAnswerUnits: _.get(itemComposite.chartVm, 'convertMeasureAnswerUnits'),
                                    //useSingleValue: _.get(itemComposite.chartVm, "useSingleValue"),
                                    periodSpan: _.get(itemComposite.chartVm, 'periodSpan'),
                                    excludeYears: _.get(itemComposite.chartVm, 'excludeYears'),
                                    includeMeasureTargets: _.get(itemComposite.chartVm, 'includeMeasureTargets'),
                                    dataRelationId: _.get(itemComposite.chartVm, 'dataRelationId'),
                                    includeAvailablePeriods: true,
                                })
                            }

                            if (!itemComposite.color) itemComposite.color = _.get(itemComposite.topParent, 'content.conditions.color1') || '#677686'

                            itemComposite.onChartLoaded = function (chartVm) {
                                itemComposite.chartVm = chartVm
                            }

                            if (itemComposite.type === enums.objectType.relativeMeasure) {
                                itemComposite.onChartDataLoaded = function (itemContentWithData) {
                                    if (itemContentWithData.sourceObjectWfids) {
                                        itemContentWithData.sourceObjectWfids.forEach((wfid) => {
                                            if (!self.itemDependenciesByWfid[wfid]) {
                                                self.itemDependenciesByWfid[wfid] = []
                                            }
                                            self.itemDependenciesByWfid[wfid].push(itemComposite)
                                        })
                                    }
                                }
                            }
                        }
                        else {
                            if (!itemComposite.color) {
                                itemComposite.color = _.get(itemComposite.topParent, 'content.conditions.color1') || '#677686'
                            }

                            itemComposite.open = itemUtility.openItemCompositeOverlay
                        }

                        itemComposite.attachInfoActions = dropdownUtility.buildDropdownActions({
                            actions: 'attachInformation',
                            item: itemComposite,
                            callback() {
                                if (itemComposite.attachedInformationContainer) {
                                    itemComposite.attachedInformationContainer.populateContainer()
                                }
                                else {
                                    addItem({ appendToChildrenOf: itemComposite, addToView: true }, itemUtility.createContainer_attachedInformation(itemComposite))
                                }
                            },
                        })
                    })

                    self.viewContentLoaded = true

                    if (self.pendingTicket) {
                        self.negotiator.ticket = self.ticket = self.pendingTicket
                        self.pendingTicket = undefined
                        self.loadUserDataOnInit = true
                    }

                    if (self.loadUserDataOnInit) {
                        initUserData(intersectedHierarchy)
                    }
                })
            }

            function initUserData(specificItems) {
                loadUserData(specificItems).then(() => {
                    checkIfAnyItemHasGuidance()
                    self.filteredItems = _.clone(self.items)

                    syncItemGrouping()
                    self.initialLoadingFinished()

                    if (self.isSyncingFromServer) {
                        self.syncingItems = false
                    }
                })
            }

            function setUiMode(uiMode, skipCheckingItems) {
                self.isViewMode = uiMode === enums.uiMode.view
                self.isWorkMode = uiMode === enums.uiMode.work
                self.isAdminMode = uiMode === enums.uiMode.admin
                self.uiMode = uiMode

                if (!self.loaded) return

                if (!skipCheckingItems) {
                    self.subLevelIntersectedItems.filter(item => !item.lockedUiMode).forEach((itemComposite) => {
                        const shouldRecompile = itemComposite.uiMode !== uiMode

                        itemComposite.uiMode = uiMode
                        itemComposite.isViewMode = uiMode === enums.uiMode.view
                        itemComposite.isWorkMode = uiMode === enums.uiMode.work
                        itemComposite.isAdminMode = uiMode === enums.uiMode.admin

                        // if (shouldRecompile && self.viewItems.includes(itemComposite) && itemComposite.compiler) {
                        // 	itemComposite.compiler.compile();
                        // }
                    })
                }

                syncItemGrouping()
            }

            function syncItemGrouping(itemComposite) {
                if (itemComposite) {
                    f(itemComposite)
                }
                else {
                    self.topLevelIntersectedItems.forEach(f)
                }

                if (!self.isSyncingFromServer) {
                    applyFilter()
                }

                function f(itemComposite) {
                    if (itemComposite.questions) {
                        const questionsInViewMode = itemComposite.questions.filter(question => question.isViewMode)
                        if (questionsInViewMode.length) {
                            questionsInViewMode.forEach(question => question.excludeFromMainList = true)
                            itemComposite.questionsContainerItem.excludeFromMainList = false
                            itemComposite.questionsContainerItem.container.items = questionsInViewMode
                        }
                        else {
                            itemComposite.questions.forEach(question => question.excludeFromMainList = false)

                            itemComposite.questionsContainerItem.excludeFromMainList = true
                            itemComposite.questionsContainerItem.container.items = []
                        }
                    }
                }
            }

            function setTicket(newTicket, overrideChartsLoading) {
                if (_.isEqual(newTicket, self.ticket)) return

                if (newTicket.networkId) {
                    self.uiModeIsView = true
                }
                else {
                    self.uiModeIsView = false
                }

                // console.log("-----------> newTicket", newTicket);

                if (self.loaded) {
                    self.negotiator.ticket = self.ticket = _.cloneDeep(newTicket)
                    self.items.forEach((itemComposite) => {
                        itemComposite.subItemsLoaded = false
                        itemComposite.subItemsPopulated = false
                        itemComposite.subItemsLoading = false
                        delete itemComposite.subItemsLoaderDeferred
                        if (itemComposite.subItems) itemComposite.subItems.length = 0
                    })
                    loadUserData(undefined, overrideChartsLoading)
                }
                else {
                    if (self.viewContentLoaded) {
                        console.log('View content already loaded, setting new ticket and loading user data')
                        self.negotiator.ticket = self.ticket = _.cloneDeep(newTicket)
                        self.items.forEach((itemComposite) => {
                            itemComposite.subItemsLoaded = false
                            itemComposite.subItemsPopulated = false
                            itemComposite.subItemsLoading = false
                            delete itemComposite.subItemsLoaderDeferred
                            if (itemComposite.subItems) itemComposite.subItems.length = 0
                        })
                        if (!self.loadUserDataOnInit) initUserData()
                        else loadUserData(undefined, overrideChartsLoading)
                    }
                    else {
                        self.pendingTicket = _.cloneDeep(newTicket) // Will wait for negotiator to load before setting ticket and loading user data
                        if (!self.loadUserDataOnInit) self.loadUserDataOnInit = true
                    }

                }

                determineAvailableUiModes()
                // .then(function () {
                // 	// self.pagingFunctionEnabled = true;
                // });
            }

            function clearContentAndContainerReferencesOnAllItems(items) {
                _.each(_.clone(items || self.items), (itemComposite) => {
                    if (itemComposite.isContainer) {
                        if (itemComposite.container.selfContained && !itemComposite.container.isQuestions) {
                            return
                        }

                        removeItem(itemComposite)
                    }
                    else {
                        clearContentAndContainerReferences(itemComposite)
                    }
                })
            }

            function clearContentAndContainerReferences(itemComposite) {
                _.assign(itemComposite, {
                    isContentLoaded: false,
                    isLoading: false,
                    content: undefined,
                })

                delete itemComposite.attachedInformationContainer
            }

            function loadUserData(specificItems, overrideChartsLoading) {
                const items = specificItems || self.items

                self.loadingUserData = true
                self.pagingFunctionEnabled = false

                if (!self.allContentPreloaded) {
                    clearContentAndContainerReferencesOnAllItems()
                }

                return $q((resolve, reject) => {
                    const
                        questionsByParentWfid = self.questionsByParentWfid = _.chain(self.questions).groupBy('parentWfid').value()

                    self.negotiator.loadContentOnItems(items).then(() => {
                        _.each(items, (itemComposite) => {
                            itemComposite.ticket = self.ticket
                        })

                        const topLevelIntersectedItems = specificItems ? _.intersection(self.topLevelIntersectedItems, specificItems) : self.topLevelIntersectedItems
                        const subLevelIntersectedItems = specificItems ? _.intersection(self.subLevelIntersectedItems, specificItems) : self.subLevelIntersectedItems

                        if (self.enableSubItems) {
                            // Go through top level items and check which one has relatedContentByUser.
                            // Those items will have an additional container item (panel) to display attached information
                            _.each(topLevelIntersectedItems, (itemComposite) => {
                                const countByRelationKind = _.get(itemComposite, 'content.metadata.countByRelationKind')

                                if (countByRelationKind && countByRelationKind[enums.subItemsKind.relatedContentByUser]) {
                                    addItem({ appendToChildrenOf: itemComposite, addToView: self.filteredItems }, itemUtility.createContainer_attachedInformation(itemComposite))
                                }
                            })

                            // Go through top level items and check which one has questions.
                            // Those items will have an additional container item (panel) to display questions

                            if (self.groupQuestions) {
                                _.each(topLevelIntersectedItems, (itemComposite) => {
                                    const questions = questionsByParentWfid[itemComposite.wfid]

                                    if (questions && questions.length) {
                                        addItem({ prependToChildrenOf: itemComposite, addToView: self.filteredItems }, itemUtility.createContainer_questions(itemComposite, questions))
                                    }
                                })
                            }
                            else {
                                // Go through sub level items and check which one is a structure.
                                // Those items will be displayed as a panel with attached information
                                _.each(items, (itemComposite) => {
                                    if (itemComposite.type === enums.objectType.question) {
                                        itemUtility.createContainer_question(itemComposite, { transformItem: true })
                                    }
                                })
                            }

                            // Go through sub level items and check which one is a structure.
                            // Those items will be displayed as a panel with attached information
                            _.each(subLevelIntersectedItems, (itemComposite) => {
                                if (itemComposite.type === enums.objectType.structure) {
                                    itemUtility.createContainer_attachedInformation(itemComposite, { transformItem: true })
                                }
                            })
                        }

                        _.each(items, (itemComposite) => {
                            if (itemComposite.chartVm && (!itemComposite.chartVm.loading || overrideChartsLoading)) {
                                itemComposite.chartVm.loadDataAndInitialize({ ticket: self.ticket })
                            }

                            if (itemComposite.isContainer && itemComposite.container.selfContained && !itemComposite.container.isQuestions) {
                                itemComposite.container.init(itemComposite)
                            }

                            if (itemComposite.type === enums.objectType.relativeMeasure) {
                                itemComposite.uiMode = enums.uiMode.view
                                itemComposite.isViewMode = true
                                itemComposite.isWorkMode = false
                                itemComposite.isAdminMode = false
                                itemComposite.lockedUiMode = true
                            }

                            // if (itemComposite.container && itemComposite.container.isQuestions && itemComposite.container.loaded) {
                            // 	itemComposite.container.init();
                            // }

                        })

                        self.pagingFunctionEnabled = true
                        self.loadingUserData = false

                        syncTopFilter({
                            excludeFilteringOnDepths: self.excludeFilteringOnDepths,
                        })

                        setAbsoluteOrderAndSort()

                        if (!self.isSyncingFromServer) {
                            applyFilter()
                        }
                        resolve()
                    })
                })
            }

            function setAbsoluteOrderAndSort() {
                const incrementorByParentWfid = {}
                const accOrderByParentWfid = {}
                let absoluteOrderIncrementor = 0

                self.items.forEach((itemComposite) => {
                    if (self.showItemNumbering || self.influence) {
                        let parent; let parentNumbering; let paddedParentNumbering

                        if (itemComposite.dataRelation) {
                            parent = itemComposite.parentWfid && self.itemsByWfid[itemComposite.parentWfid]

                            if (!incrementorByParentWfid[itemComposite.parentWfid]) incrementorByParentWfid[itemComposite.parentWfid] = 1

                            parentNumbering = parent ? parent.numbering + '.' : ''
                            paddedParentNumbering = parent ? parent.paddedNumbering : ''

                            if (self.showItemNumbering) {
                                itemComposite.numbering = parentNumbering + incrementorByParentWfid[itemComposite.parentWfid]

                            }
                            itemComposite.paddedNumbering = paddedParentNumbering + (incrementorByParentWfid[itemComposite.parentWfid].toString()).padStart(3, '0')
                            itemComposite.absoluteOrder = parseInt('1' + itemComposite.paddedNumbering.padEnd(15, '0'))

                            incrementorByParentWfid[itemComposite.parentWfid]++
                        }
                    }
                    else {
                        itemComposite.absoluteOrder = absoluteOrderIncrementor++
                    }
                })

                self.items.sort((a, b) => a.absoluteOrder - b.absoluteOrder)
            }

            function addItem(options, newItem) {
                let addAfterIndex; let parentItem

                if (options.appendToChildrenOf) {
                    parentItem = options.appendToChildrenOf
                    if (!parentItem.children) parentItem.children = []

                    if (parentItem.children.length === 0) addAfterIndex = self.items.indexOf(parentItem)
                    else addAfterIndex = self.items.indexOf(_.last(parentItem.children))

                    if (addAfterIndex > -1) {
                        self.items.splice(addAfterIndex + 1, 0, newItem)

                        if (options.addToView && _.includes(self.filteredItems, parentItem)) {
                            if (self.filteredItems) {
                                // console.log("added to filteredItems", addAfterIndex + 1, newItem);
                                self.filteredItems.splice(addAfterIndex + 1, 0, newItem)
                            }

                            // Add to self.viewItems
                            if (parentItem.children.length === 0) addAfterIndex = self.viewItems.indexOf(parentItem)
                            else addAfterIndex = self.viewItems.indexOf(_.last(parentItem.children))

                            if (addAfterIndex > -1) {
                                // console.log("added to viewItems", addAfterIndex + 1, newItem);
                                self.viewItems.splice(addAfterIndex + 1, 0, newItem)
                            }
                        }
                    }
                }
                else if (options.prependToChildrenOf) {
                    parentItem = options.prependToChildrenOf
                    if (!parentItem.children) {
                        parentItem.children = []
                    }

                    addAfterIndex = self.items.indexOf(parentItem)

                    if (addAfterIndex > -1) {
                        self.items.splice(addAfterIndex + 1, 0, newItem)

                        if (options.addToView && _.includes(self.filteredItems, parentItem)) {
                            if (self.filteredItems) {
                                self.filteredItems.splice(addAfterIndex + 1, 0, newItem)
                            }

                            // Add to self.viewItems
                            addAfterIndex = self.viewItems.indexOf(parentItem)

                            if (addAfterIndex > -1) {
                                self.viewItems.splice(addAfterIndex + 1, 0, newItem)
                            }
                        }
                    }
                }

                if (parentItem) {
                    _.each(parentItem.parents, (parent) => {
                        if (parent.aggregatedItems) parent.aggregatedItems.push(newItem)
                    })
                }
            }

            function removeItem(item) {
                if (item) {
                    _.remove(self.items, item)
                    _.remove(self.filteredItems, item)
                    _.remove(self.viewItems, item)
                    _.remove(self.topLevelIntersectedItems, item)
                    _.remove(self.subLevelIntersectedItems, item)

                    _.each(_.get(item, 'parent.parents'), (parent) => {
                        if (parent.aggregatedItems) _.remove(parent.aggregatedItems, item)
                    })

                    if (self.isPrimaryItem) {
                        delete self.intersectedPrimaryItemsByWfid[self.wfid]
                    }

                    self.items.filter(x => x.parentWfid === item.wfid).forEach((itemComposite) => {
                        removeItem(itemComposite)
                    })

                    $timeout()
                }
            }

            function syncTopFilter() {
                self.topFilterButtonsByWfid = {}
                self.topFilterBoxes = _.chain(self.hierarchyItemComposites)
                    .reject((item) => {
                        return !!~self.excludeFilteringOnDepths.indexOf(item.depth) // If array contains item
                    })
                    .map((item) => {
                        self.topFilterButtonsByWfid[item.wfid] = item
                        return item
                    })
                    .orderBy(['depth', 'dataRelation.order'])
                    .groupBy('topParentWfid')
                    .mapValues((items) => {
                        const
                            arrays = _.chain(items).groupBy('depth').map().value()

                        const topParent = items[0]

                        return {
                            color: _.get(topParent, 'content.conditions.color1'),
                            icon: _.get(topParent, 'content.conditions.iconCssClass'),
                            levels: _.map(arrays, (buttonsAtDepth) => {
                                const buttonWidth = (100.0 / buttonsAtDepth.length)

                                _.each(buttonsAtDepth, (button) => {
                                    const parentButtonWidth = _.get(self.topFilterButtonsByWfid[button.parentWfid], 'buttonWidth') || 100

                                    if (parentButtonWidth < 100) button.buttonWidth = parentButtonWidth
                                    else button.buttonWidth = buttonWidth
                                })

                                return {
                                    buttonGroups: _.chain(buttonsAtDepth).groupBy('parentWfid').mapValues((buttons, parentWfid) => {
                                        const parentButtonWidth = _.get(self.topFilterButtonsByWfid[parentWfid], 'buttonWidth') || 100

                                        return {
                                            width: parentButtonWidth,
                                            buttons,
                                        }
                                    }).map().value(),
                                    buttonWidth,
                                }
                            }),
                        }
                    })
                    .map()
                    .value()

                if (_.get(self.hierarchyStructure, 'conditions.requirementPackageSettings.useTabs')) {
                    self.tabs = self.topFilterBoxes.map(x => _.get(x, 'levels[0].buttonGroups[0].buttons[0]')).filter(Boolean).filter(x => x.content)
                    if (self.tabs.length) {
                        self.tabItems = self.tabs[0].aggregatedItems
                        self.activeTab = self.tabs[0]
                    }
                }
            }

            function openGuidance(item) {
                if (!item) return

                const templateHtml =
					'<div class="guidance-explanation"><i class="fa fa-info-circle"></i><span>' + $translate.instant('Guidance') + '</span></div>' +
					'<div class="modal-header">' +
					'<button type="button" class="bootbox-close-button close" wf-click="$close()" aria-hidden="true">×</button>' +
					'<h3 class="modal-title">' + item.title + '</h3>' +
					'</div>' +
					'<div class="modal-body">' +
					'<div class="message">' + item.guidance + '</div>' +
					'</div>' +
					'<div class="modal-footer">' +
					'<button class="btn wf-btn-link" wf-click="$close()">' + $translate.instant('Close') + '</button>' +
					'</div>'

                $uibModal.open({
                    animation: true,
                    size: 'width-700',
                    windowClass: 'guidance gri-guidance-modal',
                    backdrop: 'static',
                    template: templateHtml,
                })
            }

            function checkIfAnyItemHasGuidance() {
                self.someItemsHaveGuidance = _.some(self.items, (item) => {
                    return item.content && item.content.guidance && item.content.guidance !== ''
                })
            }

            function applyFilter(filter, _pageSize, onlyUpdatePreviewCount) {
                let
                    output = _.clone(self.tabItems || self.items)

                let currentFilter = self.currentFilter

                let currentUserFilter = self.currentUserFilter

                // TODO: Switch to wfFiltering in wfEasyList.component.html instead of using this redundant code

                if (filter) {
                    if (!filter.type || filter.type === enums.objectType.fulfillment || filter.type === enums.objectType.structure) { // Right now both level filters and fulfillment stater filters
                        if (!onlyUpdatePreviewCount && self.currentFilter && self.currentFilter.id === filter.id) {
                            currentFilter = null
                        }
                        else {
                            currentFilter = filter
                        }
                    }

                    if (filter.type === enums.objectType.individual) {
                        if (!onlyUpdatePreviewCount && self.currentUserFilter && self.currentUserFilter.id === filter.id) {
                            currentUserFilter = null
                        }
                        else {
                            currentUserFilter = filter
                        }
                    }
                }

                if (currentFilter) {
                    if (currentFilter.aggregatedItems) output = _.intersection(output, currentFilter.aggregatedItems)
                    else if (currentFilter.items) output = _.intersection(output, currentFilter.items)
                }

                if (currentUserFilter) {
                    if (currentUserFilter.aggregatedItems) output = _.intersection(output, currentUserFilter.aggregatedItems)
                    else if (currentUserFilter.items) output = _.intersection(output, currentUserFilter.items)
                }

                if (onlyUpdatePreviewCount) {
                    filter.actualCount = output.length
                    return
                }
                else {
                    const addedParents = {}

                    self.filteredItems.length = 0

                    if (self.influence) {
                        output.forEach((x) => {
                            let parent = self.itemsByWfid[x.parentWfid]

                            while (parent && parent.wfid !== self.hierarchyStructure.wfid && !addedParents[parent.wfid] && !output.find(y => y.wfid === parent.wfid)) {
                                output.push(parent)
                                addedParents[parent.wfid] = parent
                                parent = self.itemsByWfid[parent.parentWfid]
                            }
                        })
                    }

                    Array.prototype.push.apply(self.filteredItems, _.orderBy(output, x => x.absoluteOrder))

                    // if (currentFilter !== undefined) {
                    self.currentFilter = currentFilter
                    // }
                    // if (currentUserFilter !== undefined) {
                    self.currentUserFilter = currentUserFilter;
                    // }

                    [].concat(_.map(self.fulfillmentStatistics).filter(x => x.showButton), _.map(self.userCategorizationsStatistics)).forEach((x) => {
                        if (typeof x === 'object' && x.count > 0) {
                            applyFilter(x, undefined, true)
                        }
                    })

                    // Uncomment this to debug specific items:
                    // self.filteredItems = self.filteredItems.filter(x => x.wfid === "71-14175");
                }

                rejectExcludedItemsFromFiltered()

                // setAllSelectedFilters(filter);

                self.viewItems.length = 0
                self.pagingFunctionEnabled = true
                self.infiniteScrollPagingFunction(_pageSize)
            }

            function rejectExcludedItemsFromFiltered() {
                _.remove(self.filteredItems, { excludeFromMainList: true })
            }

            function setAllSelectedFilters(clickedFilter) {
                //add in easylist.component.html - ng-class="{ 'selected': filterButton.selected, 'unselected': vm.loader.currentFilter && !filterButton.selected }"

                if (clickedFilter.selected) {
                    if (self.topFilterButtonsByWfid && !_.isEmpty(self.topFilterButtonsByWfid)) _.each(self.topFilterButtonsByWfid, (filterButton) => { filterButton.selected = undefined })

                    self.currentFilter = undefined
                }
                else {
                    if (self.topFilterButtonsByWfid && !_.isEmpty(self.topFilterButtonsByWfid)) _.each(self.topFilterButtonsByWfid, (filterButton) => { filterButton.selected = undefined })

                    clickedFilter.selected = true
                    selectAllParentFilters(clickedFilter)
                }

                function selectAllParentFilters(filter) {
                    let parentFilter = undefined

                    filter.selected = true
                    if (filter.parentWfid) {
                        if (filter.wfid === filter.topParentWfid) return
                        else {
                            parentFilter = self.topFilterButtonsByWfid[filter.parentWfid]
                            selectAllParentFilters(parentFilter)
                        }
                    }
                }

                self.selectedFilters = undefined
            }

            function getCategoryStructureRelations() {
                return dataQuery.getRelations({
                    organizationIds: [null, authOrgId],
                    kind: _.get(self.categoryStructureConfig, 'subItemsKind'),
                    parent: self.categoryStructure,
                })
            }

            function addItemsToCategory(itemComposite) {
                let
                    categoryStructureId

                let hierarchyStructureId

                let flatListOfItemsStructure

                let categoryStructure

                let categoryStructure_subItemsKind

                const primaryItemDeterminatorDepth = 1

                let virtualStructureWfid

                let promise

                const listConfig = {
                    enableFiltering: false,
                    enableSubItems: false,
                    enableLinkage: false,
                    enableLearnMore: false,
                    excludeFilteringOnDepths: [1, 2, 3, 4],
                    showFilteringGuidance: false,
                }

                categoryStructure_subItemsKind = enums.subItemsKind.children

                // If adding to an item
                if (itemComposite) {
                    itemComposite.addButtonLoading = true
                    if (self.isHierarchy_griStandards) {
                        hierarchyStructureId = itemComposite.id
                    }
                    else if (self.isHierarchy_sdg) {
                        hierarchyStructureId = 10517
                        categoryStructure_subItemsKind = enums.subItemsKind.linkageChildren
                    }
                    else {
                        hierarchyStructureId = itemComposite.id
                    }

                    virtualStructureWfid = '71-dashPicker:forItem-' + itemComposite.wfid

                    const rootItem = {
                        type: enums.objectType.structure,
                        id: virtualStructureWfid,
                        uniqueId: virtualStructureWfid,
                        wfid: virtualStructureWfid,
                    }

                    promise = prepareEasyListDataForAddingToItem(rootItem)
                    promise.then((res) => {
                        rootItem.additionalItems = res

                        _.assign(listConfig, {
                            hierarchyStructureId: virtualStructureWfid,
                            // categoryStructureId: itemComposite.id,
                            // categoryStructureConfig: {
                            // 	subItemsKind: categoryStructure_subItemsKind,
                            // 	loadDepth: 1
                            // },
                            primaryItemDeterminator(itemComposite) {
                                return itemComposite.depth === 2
                            },
                            // primaryItemDepth: 2,
                            // primaryItemType: enums.objectType.measure,

                            includePrimaryItemsInFiltering: true,
                            enableFiltering: true,
                            negotiatorPreloadedItem: rootItem,
                            excludeFilteringOnDepths: [2],
                            showItemLines: true,
                            showColumnHeaders: false,
                            sideAction: {
                                tool: 'toggle',
                                categoryRelations: getCategoryStructureRelations(),
                                relationTarget: [
                                    { item: self.categoryStructure, kind: enums.subItemsKind.childrenByUser, isScopeRelation: true },
                                    { item: itemComposite.content, kind: [enums.subItemsKind.childrenByUser, enums.subItemsKind.linkageChildren], dontDelete: true, isHierarchyRelation: true },
                                ],
                            },
                        })
                    })
                }
                // If adding to the main list
                else {
                    if (self.isHierarchy_griStandards) {
                        hierarchyStructureId = 10517
                        flatListOfItemsStructure = 19385
                    }
                    else if (self.isHierarchy_sdg) {
                        hierarchyStructureId = 387
                        flatListOfItemsStructure = 16052
                    }
                    else {
                        hierarchyStructureId = self.hierarchyStructureId
                        flatListOfItemsStructure = undefined // Not flat in this case so use primaryItemDeterminator instead
                        listConfig.primaryItemDeterminator = function (itemComposite) {
                            return itemComposite.depth === (typeof self.primaryItemDepth === 'undefined' ? 1 : self.primaryItemDepth)
                        }
                    }

                    _.assign(listConfig, {
                        hierarchyStructureId,
                        categoryStructureId: flatListOfItemsStructure,
                        excludeFilteringOnDepths: [1, 2, 3, 4],
                        showItemLines: true,
                        showColumnHeaders: false,
                        sideAction: {
                            tool: 'toggle',
                            relationTarget: { item: self.categoryStructure, kind: enums.subItemsKind.childrenByUser, isScopeRelation: true },
                        },
                    })
                }

                $q.all(promise ? [promise] : []).then(() => {
                    if (itemComposite) itemComposite.addButtonLoading = false
                    const modalPromise = modal.openItem({
                        size: 'width-1100',
                        template: '<h2 ng-bind-html="modalHeader"></h2><wf-easy-list config="listConfig" enable-search="true"></wf-new-list>',
                        scope: {
                            modalHeader: itemComposite ? $translate.instant('modules.manageScopeDashboard.addSubItemsHeader') + itemComposite.content.title : $translate.instant('modules.manageScopeDashboard.addItemsToScopeHeader'),
                            listConfig,
                        },
                    })

                    modalPromise.modal.closed.then(() => {
                        syncItemsAfterChange(itemComposite)
                    })
                })

                function prepareEasyListDataForAddingToItem(rootItem) {
                    const
                        additionalItemsResult = []

                    let objectTypeSpecs
                    // Measure, Questions, Information (structures with usage "Add information")

                    let sections
                    // Suggested, Scope, All

                    const promises = []

                    let promise

                    return $q((resolve, reject) => {
                        // Specifies the top object types in the picker filter bar
                        objectTypeSpecs = [
                            {
                                type: enums.objectType.measure,
                                allItemsStructureWfid: '71-12100',
                                color: '#74808F',
                            },
                            {
                                type: enums.objectType.question,
                                allItemsStructureWfid: '71-12099',
                                color: '#67819C',
                            },
                            {
                                type: enums.objectType.structure,
                                title: 'Added information',
                                allItemsStructureWfid: '71-16125',
                                allItemsGetterConditions: { structureGetterCondition: 1 },
                                filter: dataQuery.filterItemsWithAnySettingForAttachingData,
                                color: '#6C8DAE',
                            },
                            {
                                type: enums.objectType.relativeMeasure,
                                allItemsStructureWfid: '71-20361',
                                color: '#638BB8',
                            },
                        ]

                        // Specifies the sections under each object type in the picker filter bar
                        sections = [
                            {
                                title: $translate.instant('Suggested'),
                                items: _.concat(
                                    dataQuery.getRelations({ parent: itemComposite.content, kind: enums.subItemsKind.children }),
                                    dataQuery.getRelations({ parent: itemComposite.content, kind: enums.subItemsKind.linkageChildren }),
                                ),
                            },
                            {
                                scopeCategory: true,
                                title: $translate.instant('Scope'),
                                items: dataQuery.getRelations({ parent: self.categoryStructure, organizationId: authOrgId }),
                            },
                            {
                                all: true,
                                title: $translate.instant('All'),
                                items: [],
                            },
                        ]

                        // For each object type there is a structure in db that contains all items of that type. Those structures are loaded here.
                        _.each(objectTypeSpecs, (objectTypeInfo) => {
                            if (!objectTypeInfo.allItemsStructureWfid) return

                            promise = dataOps.getSubItems(objectTypeInfo.allItemsStructureWfid, enums.subItemsKind.children, {
                                onlyLoadRelations: true,
                                getterConditions: objectTypeInfo.allItemsGetterConditions,
                                skipExtras: true,
                            }).then((res) => {
                                objectTypeInfo.allItems = dataQuery.getRelations({
                                    useVirtual: true,
                                    organizationId: authOrgId,
                                    parentWfid: objectTypeInfo.allItemsStructureWfid,
                                })
                            })

                            promises.push(promise)
                        })

                        // Continue when previous loading is done
                        $q.all(promises).then(() => {
                            // Loop through object types and start adding to the virtual hierarchy that will be used in the picker filter bar
                            _.each(objectTypeSpecs, (objectTypeInfo) => {
                                // Create virtual structure and virtual data relation for the object type
                                const objectTypeVdrItem = itemUtility.createVirtualDataRelation({
                                    forObjectType: objectTypeInfo.type,
                                    withParent: rootItem,
                                    childContentTitle: objectTypeInfo.title || wfTranslate.instant('MAP_ObjectType', { type: objectTypeInfo.type, plural: true }), // Will auto add childContent to the relation
                                    childContentConditions: {
                                        color1: objectTypeInfo.color,
                                    },
                                    depth: 0,
                                })
                                additionalItemsResult.push(objectTypeVdrItem)

                                // Loop through each section and add them for each object type
                                _.each(sections, (sectionInfo) => {
                                    //sectionInfo = {
                                    //	allItems: [] // All dataRelations from either the item or the scope
                                    //	title: "" // Suggested, Scope or All
                                    //}
                                    let
                                        sectionVdrItem

                                    let childrenRelations

                                    // Create virtual structure and virtual data relation for the section
                                    sectionVdrItem = itemUtility.createVirtualDataRelation({
                                        forObjectType: objectTypeInfo.type,
                                        withParent: objectTypeVdrItem.childContent,
                                        childContentTitle: sectionInfo.title,
                                    })
                                    additionalItemsResult.push(sectionVdrItem)

                                    // Gather children for the section and create virtual data relations
                                    childrenRelations = sectionInfo.all ? objectTypeInfo.allItems : sectionInfo.items

                                    if (sectionInfo.scopeCategory && objectTypeInfo.filter) childrenRelations = objectTypeInfo.filter(childrenRelations)
                                    else childrenRelations = _.filter(childrenRelations, { childType: objectTypeInfo.type })

                                    childrenRelations = _.chain(childrenRelations)
                                        .map((dataRelation) => {
                                            const vdr = itemUtility.createVirtualDataRelation({
                                                forObjectType: objectTypeInfo.type,
                                                withParent: sectionVdrItem.childContent,
                                                fromDataRelation: dataRelation,
                                                depth: 2,
                                            })

                                            additionalItemsResult.push(vdr)

                                            return vdr
                                        })
                                        .value()
                                })
                            })

                            resolve(additionalItemsResult)
                        })

                    })

                }
            }

            // Gets called when the items in the hierarchy or category have changed
            function syncItemsAfterChange(itemComposite) { // itemComposite of the subitems of an item have changed
                let categoryStructureRelations; let subItemRelations
                let
                    currentSubItems

                let allSubItemRelations

                let subItemsInScope

                let subItemsInScopeBefore

                let subItemsInScopeAfter

                let newSubItemsInScope

                let newSubItemsAdded = false

                let lastChildOfItem

                // Needs to be put on the added item if it is a new sub item and not just added to the categoryStructure.
                // In handleItem function the entire list is order on the reponseOrder property.

                let itemReponseOrderValue

                if (itemComposite) {
                    currentSubItems = itemComposite.children
                    allSubItemRelations = dataQuery.getRelations({ parent: itemComposite.content, kind: enums.subItemsKind.children, organizationIds: [null, authOrgId] }),
                    categoryStructureRelations = getCategoryStructureRelations()

                    const allSubItemsRelationWfids = allSubItemRelations.map(x => x.wfid)
                    const removedSubItems = currentSubItems.filter(x => !allSubItemsRelationWfids.includes(x.relationWfid))
                    removedSubItems.forEach(item => _.remove(self.negotiator.items, x => x.relationWfid === item.relationWfid))

                    subItemsInScopeBefore = _.intersectionBy(allSubItemRelations, self.categoryRelations, 'wfcid')
                    subItemsInScopeAfter = _.intersectionBy(allSubItemRelations, categoryStructureRelations, 'wfcid')
                    newSubItemsInScope = _.differenceWith(subItemsInScopeAfter, currentSubItems, (relation, itemComposite) => {
                        return relation.wfcid === itemComposite.wfid
                    })

                    if (newSubItemsInScope.length) {
                        newSubItemsAdded = true
                        _.each(newSubItemsInScope, (childRelation) => {
                            childRelation.itemCompositeInstructions = { childContent: childRelation.childContent }
                            if (!_.some(self.negotiator.items, { wfid: childRelation.wfcid, parentWfid: itemComposite.wfid })) {
                                // if (currentSubItems.length) {
                                // 	lastChildOfItem = _.last(currentSubItems);
                                // 	itemReponseOrderValue = lastChildOfItem.responseOrder + 0.001 // Arbitrary small value so the item order will be correct
                                // }
                                // else
                                // 	itemReponseOrderValue = itemComposite.responseOrder + 0.001 // Arbitrary small value so the item order will be correct

                                self.negotiator.addItem(childRelation, {
                                    appendToChildrenOf: itemComposite,
                                    itemPrototype: {
                                        depth: itemComposite.depth + 1,
                                        responseOrder: -1,
                                        // responseOrder: itemReponseOrderValue
                                    },
                                })
                            }
                        })
                    }

                    if (!_.isEqual(self.categoryRelations, categoryStructureRelations) || newSubItemsAdded || removedSubItems.length) {
                        self.categoryRelations = categoryStructureRelations
                        handleItems()
                    }
                }
                else {
                    categoryStructureRelations = getCategoryStructureRelations()
                    if (!_.isEqual(self.categoryRelations, categoryStructureRelations)) {
                        self.categoryRelations = categoryStructureRelations
                        handleItems()
                    }
                }
            }

            function initRequirementsAndFulfillments() {
                const
                    actualRequirementsMap = requirementService.getActualRequirementOnItems(self.subLevelIntersectedItems, self.ticket)

                let
                    fulfillmentStateSpec

                let fulfillmentStateSpecByState

                // TEMP
                _.remove(actualRequirementsMap, (item) => {
                    return item.itemContent.type === enums.objectType.finding && item.dataRelation.type !== enums.objectType.virtualDataRelation
                })

                self.hasRequirements = !!actualRequirementsMap.length

                fulfillmentStateSpec = {
                    [enums.fulfillmentState.fulfilled]: { state: enums.fulfillmentState.fulfilled, color: '#48C72B', icon: 'fa fa-check', items: [], count: 0, title: $translate.instant('Fulfills') },
                    [enums.fulfillmentState.unfulfilled]: { state: enums.fulfillmentState.unfulfilled, color: '#DF4A37', icon: 'fa fa-times', items: [], count: 0, title: $translate.instant('NotFulfilled') },
                    partiallyFulfilled: { state: 'partiallyFulfilled', color: '#DBAE0A', icon: 'fa fa-minus-circle', items: [], count: 0, title: $translate.instant('fulfillmentStates.partiallyFulfilled') },
                    [enums.fulfillmentState.assessmentNeeded]: { state: enums.fulfillmentState.assessmentNeeded, color: '', icon: 'fa fa-question-circle', items: [], count: 0, title: $translate.instant('fulfillmentStates.assessmentNeeded') },
                    [enums.fulfillmentState.reportingNeeded]: { state: enums.fulfillmentState.reportingNeeded, color: '#34495e', icon: 'fa fa-repeat', items: [], count: 0, title: $translate.instant('fulfillmentStates.toBeReported') },
                    [enums.fulfillmentState.expired]: { state: enums.fulfillmentState.expired, color: '', icon: 'fa fa-exclamation-triangle', items: [], count: 0, title: $translate.instant('fulfillmentStates.expired') },
                }

                if (actualRequirementsMap.length) {
                    _.each(actualRequirementsMap, (item) => {
                        // When option useDetailedResult is true, the function will return an object with detailed info instead of a boolean
                        const fulfillmentResult = requirementService.checkLocalFulfillment(item.itemContent, item.dataRelation, item.requirement, item.ticket, { useDetailedResult: true })

                        item.itemComposite.requirement = item.requirement
                        item.itemComposite.fulfillmentResult = fulfillmentResult
                        item.itemComposite.ticket = item.ticket
                        if (item.itemComposite.newlyAddedViaSyncFromServer) {
                            item.itemComposite.syncFulfillment()
                            item.itemComposite.newlyAddedViaSyncFromServer = false
                        }
                    })

                    self.syncFulfillmentStatistics(actualRequirementsMap.map(x => x.itemComposite))
                }
            }

            function infiniteScrollPagingFunction(_pageSize) {
                let newChunk

                if (!self.pagingFunctionEnabled) return

                newChunk = _.chain(self.filteredAndSearchedItems || self.filteredItems).slice(self.viewItems ? self.viewItems.length : 0).take(_pageSize || pageSize).value()

                if (newChunk.length) {
                    Array.prototype.push.apply(self.viewItems, newChunk)
                    // self.negotiator.loadContentOnItems(newChunk);
                }

                self.viewItems = _.orderBy(self.viewItems, x => x.absoluteOrder)
                // _.remove(self.viewItems, { isPlaceholder: true });

                if (self.viewItems.length === (self.filteredAndSearchedItems || self.filteredItems).length) {
                    self.pagingFunctionEnabled = false
                }

                // console.log("self.viewItems", _.filter(self.viewItems, { isContainer: true }));

                // console.log("items", self.items);
                // console.log("filteredItems", self.filteredItems);
                // console.log("viewItems", self.viewItems);
            }

            function initSideAction_toggle(options) {
                let
                    relationTargets

                let subItemsRelations

                let categoryItemsRelations

                const cache = {}

                const itemWfids = _.map(self.items, 'wfid')

                const kind = options.relationTarget.kind

                const targetItem = options.relationTarget.item

                if (self.items.length === 0) {
                    self.emptyState = {
                        header: 'Nothing to add',
                    }
                }

                _.assign(options, {
                    onToggleClick,
                    cache,
                    toggledText: $translate.instant('Remove'),
                    untoggledText: $translate.instant('Add'),
                })

                return $q((resolve, reject) => {
                    // dataOps.getSubItemsOfAll(itemWfids, enums.subItemsKind.childrenByUser, {
                    // 	onlyLoadRelations: true,
                    // 	loadMetadata: false
                    // }).then(function () {
                    if (!(options.relationTarget instanceof Array)) {
                        options.relationTarget = [options.relationTarget]
                    }

                    relationTargets = options.relationTarget

                    cache.structuresWfidsInCategoryStructure = !options.categoryRelations ? [] : [...new Set(options.categoryRelations.filter(x => x.childType === enums.objectType.structure).map(x => x.wfcid))]

                    _.each(relationTargets, (relationTarget) => {
                        if (relationTarget.kind instanceof Array) {
                            relationTarget.relations = _.chain(relationTarget.kind).map((kind) => {
                                const relations = dataQuery.getRelations({
                                    organizationId: wfObject.isRelationKindByUser(kind) ? self.ticket.organizationId : null,
                                    kind,
                                    parent: relationTarget.item,
                                    childWfids: itemWfids,
                                })

                                return relations
                            }).flatten().uniqBy('wfcid').value()

                            // subItemsRelations = _.chain(_.concat(dataQuery.getRelations({
                            // 	organizationId: self.ticket.organizationId,
                            // 	kind: kind,
                            // 	parent: options.itemComposite.content,
                            // 	childWfids: itemWfids
                            // }), dataQuery.getRelations({
                            // 	organizationId: null,
                            // 	kind: enums.subItemsKind.linkageChildren,
                            // 	parent: options.itemComposite.content,
                            // 	childWfids: itemWfids
                            // }))).uniqBy("wfcid").value();
                        }
                        else {
                            // Relations for items on scope (category structure)
                            relationTarget.relations = dataQuery.getRelations({
                                organizationId: self.ticket.organizationId,
                                kind: relationTarget.kind,
                                parent: relationTarget.item,
                                childWfids: itemWfids,
                            })
                        }

                        relationTarget.relationsByWfcid = _.keyBy(relationTarget.relations, 'wfcid')

                        // });
                    })

                    // cache.subItemsByWfid = _.keyBy(subItemsRelations, "wfcid");
                    // cache.itemsInCategoryByWfid = _.keyBy(categoryItemsRelations, "wfcid");
                    // cache.subItemsInCategory = _.intersectionBy(subItemsRelations, categoryItemsRelations, "wfcid");

                    const intersectionByArgs = _.map(relationTargets, 'relations')
                    intersectionByArgs.push('wfcid')

                    cache.intersectedRelations = _.intersectionBy.apply(null, intersectionByArgs)
                    cache.toggledItemsByWfid = _.keyBy(cache.intersectedRelations, 'wfcid')

                    resolve()
                })

                function onToggleClick(itemComposite) {
                    let promise; const promises = []
                    return $q((resolve, reject) => {
                        const itemSource = _.get(itemComposite.dataRelation.itemCompositeInstructions, 'itemSource')

                        // if (itemSource === "category") { // Child of categoryStructure
                        // 	// Remove: from targetItem
                        // 	// Add: to targetItem
                        // }
                        // else if (itemSource === "all") { // Child of the structure that contains all items of a specific type created by the authenticated organiztaion
                        // 	// Remove: from targetItem
                        // 	// Add: to targetItem
                        // }
                        // else { // Child of current item (targetItem)
                        // Add: to categoryStructure
                        // Remove: from categoryStructure

                        if (itemComposite.wfid in cache.toggledItemsByWfid) {
                            const parentsInHierarchy = cache.structuresWfidsInCategoryStructure.length === 0 ? [] : dataQuery.getRelations({
                                organizationIds: [null, authOrgId],
                                childWfid: itemComposite.wfid,
                                parentWfids: cache.structuresWfidsInCategoryStructure,
                                kind: enums.subItemsKind.childrenByUser,
                                parentType: enums.objectType.structure,
                            })

                            _.each(relationTargets, (relationTarget) => {
                                const relation = relationTarget.relationsByWfcid[itemComposite.wfid]

                                if (
                                    (relationTarget.isHierarchyRelation && relation.organizationId && relation.organizationId === authOrgId)
									||
									(relationTarget.isScopeRelation && (parentsInHierarchy.length === 1 || !options.categoryRelations))
                                ) {
                                    promise = dataOps.destroy(relation)
                                    promises.push(promise)
                                    promise.then(() => {
                                        delete relationTarget.relationsByWfcid[itemComposite.wfid]
                                    })
                                }
                            })

                            if (promises.length) {
                                $q.all(promises).then(() => {
                                    delete cache.toggledItemsByWfid[itemComposite.wfid]
                                    resolve()
                                })
                            }
                            else {
                                resolve()
                            }
                        }
                        else {
                            _.each(relationTargets, (relationTarget) => {
                                let kind
                                if (!(itemComposite.wfid in relationTarget.relationsByWfcid)) {
                                    if (relationTarget.kind instanceof Array) kind = relationTarget.kind[0]
                                    else kind = relationTarget.kind

                                    // When kind is an array the first kind is used when creating the relation
                                    promise = dataOps.createSubItemRelation(relationTarget.item, itemComposite.content, { kind, ticket: self.ticket }).then((res) => {
                                        relationTarget.relationsByWfcid[itemComposite.wfid] = res
                                    })
                                    promises.push(promise)
                                }
                            })
                            // if (!(itemComposite.wfid in cache.itemsInCategoryByWfid)) {
                            // 	promise = dataOps.createSubItemRelation(targetItem, itemComposite.content, { kind: kind, ticket: self.ticket }).then(function (res) {
                            // 		cache.itemsInCategoryByWfid[itemComposite.wfid] = res;
                            // 	});
                            // 	promises.push(promise)
                            // }
                            // if (!(itemComposite.wfid in cache.subItemsByWfid)) {
                            // 	promise = dataOps.createSubItemRelation(options.itemComposite.content, itemComposite.content, { kind: kind, ticket: self.ticket }).then(function (res) {
                            // 		cache.subItemsByWfid[itemComposite.wfid] = res;
                            // 	});
                            // 	promises.push(promise)
                            // }

                            $q.all(promises).then(() => {
                                cache.toggledItemsByWfid[itemComposite.wfid] = true
                                resolve()
                            })
                        }
                        // }

                        // if (itemComposite.wfid in cache.toggledItemsByWfid) {
                        // 	dataOps.destroy(cache.toggledItemsByWfid[itemComposite.wfid]).then(function () {
                        // 		delete cache.toggledItemsByWfid[itemComposite.wfid];
                        // 		resolve();
                        // 	});
                        // }
                        // else {
                        // 	dataOps.createSubItemRelation(targetItem, itemComposite.content, { kind: kind, ticket: self.ticket }).then(function (res) {
                        // 		cache.toggledItemsByWfid[itemComposite.wfid] = res;
                        // 		resolve();
                        // 	});
                        // }
                    })
                }
            }

            function syncUserCategorizations() {
                const
                    promises = []

                if (!self.userCategorizationsLoaded) {
                    promises.push(dataOps.getSubItemsOfAll(self.subLevelIntersectedItems, enums.subItemsKind.parentsByUser, { objectType: enums.objectType.individual, skipExtras: true }))
                }

                return $q.all(promises).then(() => {
                    const userRelations = dataQuery.getRelations({
                        organizationId: authOrgId,
                        childWfids: self.subLevelIntersectedItems.map(x => x.wfid),
                        kind: enums.objectType.parentsByUser,
                        parentType: enums.objectType.individual,
                    })

                    self.userCategorizations = userRelations

                    self.subLevelIntersectedItems.forEach((itemComposite) => {
                        itemComposite.userCategorizations = userRelations.filter(x => x.wfcid === itemComposite.wfid).map(x => x.parentContent)
                        itemComposite.userCategorizationToAuthUser = !!itemComposite.userCategorizations.find(x => x.id === authUserId)
                    })

                    self.userCategorizationsStatistics = _.chain(self.userCategorizations).groupBy('wffid').mapValues((relations) => {
                        const user = relations[0].parentContent

                        return {
                            id: 'user_' + user.id,
                            userId: user.id,
                            label: user.name,
                            content: user,
                            count: relations.length,
                            type: enums.objectType.individual,
                            items: self.subLevelIntersectedItems.filter(itemComposite => relations.find(x => x.wfcid === itemComposite.wfid)),
                        }
                    }).value()
                })
            }

            function defineLookupObjects() {
                // return $q(function (resolve) {
                const
                    output = {}

                const promises = []

                let promise

                output.includeItemsInCategoryByWfid = {
                    '71-10520': true, // Foundation
                    '71-10521': false, // General Disclosures
                    '71-10522': false, // Management Approach
                    '71-10523': true, // Economic
                    '71-10524': true, // Environmental
                    '71-10525': true, // Social
                }

                output.griCodeByWfid = {
                    '71-10520': '101', // Foundation
                    '71-10521': '102', // General Disclosures
                    '71-10522': '103', // Management Approach
                    '71-10523': '200', // Economic
                    '71-10524': '300', // Environmental
                    '71-10525': '400', // Social
                }

                // Official colors
                output.griColorsByWfid = {
                    '71-10520': '#002C5C', // Foundation
                    '71-10521': '#0B406B', // General Disclosures
                    '71-10522': '#02528A', // Management Approach
                    '71-10523': '#732A82', // Economic
                    '71-10524': '#06966F', // Environmental
                    '71-10525': '#F36B21', // Social
                }

                // Our colors
                // output.griColorsByWfid = {
                // 	"71-10520": "#95A5A6", // Foundation
                // 	"71-10521": "#F9B331", // General Disclosures
                // 	"71-10522": "#c481ca", // Management Approach
                // 	"71-10523": "#3598DB", // Economic
                // 	"71-10524": "#2DCC70", // Environmental
                // 	"71-10525": "#E84C3D" // Social
                // };

                output.griCategoriesByWfid = {
                    '71-10520': 'Foundation',
                    '71-10521': 'General Disclosures',
                    '71-10522': 'Management Approach',
                    '71-10523': 'Economic',
                    '71-10524': 'Environmental',
                    '71-10525': 'Social',
                }

                output.griCategoryCssClassesByWfid = {
                    '71-10520': 'gri-foundation',
                    '71-10521': 'gri-general_disclosures',
                    '71-10522': 'gri-management_approach',
                    '71-10523': 'gri-economic',
                    '71-10524': 'gri-environmental',
                    '71-10525': 'gri-social',
                }

                output.totalCountByCatWfid = {
                    '71-10523': 35,
                    '71-10524': 50,
                    '71-10521': 56,
                    '71-10522': 3,
                    '71-10525': 94,
                }

                output.categoryWfidByGriWfid = {
                    '71-10658': '71-10522', '71-10659': '71-10522', '71-12105': '71-10522', '71-10539': '71-10521', '71-10540': '71-10521', '71-10541': '71-10521', '71-10542': '71-10521', '71-10543': '71-10521', '71-10544': '71-10521', '71-10545': '71-10521', '71-10547': '71-10521', '71-10548': '71-10521', '71-10549': '71-10521', '71-10550': '71-10521', '71-10551': '71-10521',
                    '71-10552': '71-10521', '71-10553': '71-10521', '71-10554': '71-10521', '71-10555': '71-10521', '71-10556': '71-10521', '71-10557': '71-10521', '71-10602': '71-10521', '71-10558': '71-10521', '71-10559': '71-10521', '71-10560': '71-10521', '71-10561': '71-10521', '71-10562': '71-10521', '71-10563': '71-10521', '71-10564': '71-10521', '71-10565': '71-10521', '71-10567': '71-10521',
                    '71-10568': '71-10521', '71-10569': '71-10521', '71-10570': '71-10521', '71-10571': '71-10521', '71-10572': '71-10521', '71-10573': '71-10521', '71-10574': '71-10521', '71-10575': '71-10521', '71-10576': '71-10521', '71-10577': '71-10521', '71-10578': '71-10521', '71-10579': '71-10521', '71-10580': '71-10521', '71-10581': '71-10521', '71-10582': '71-10521', '71-10583': '71-10521',
                    '71-10584': '71-10521', '71-10566': '71-10521', '71-10585': '71-10521', '71-10586': '71-10521', '71-10587': '71-10521', '71-10588': '71-10521', '71-10589': '71-10521', '71-10590': '71-10521', '71-10591': '71-10521', '71-10592': '71-10521', '71-10593': '71-10521', '71-10594': '71-10521', '71-10595': '71-10521', '71-10596': '71-10521', '71-10597': '71-10521', '71-10598': '71-10521',
                    '71-10599': '71-10521', '71-10600': '71-10521', '71-10664': '71-10523', '71-10665': '71-10523', '71-10666': '71-10523', '71-10667': '71-10523', '71-10668': '71-10523', '71-10669': '71-10523', '71-10710': '71-10524', '71-10711': '71-10524', '71-10712': '71-10524', '71-10713': '71-10524', '71-10714': '71-10524', '71-10715': '71-10524', '71-10716': '71-10524', '71-10717': '71-10524',
                    '71-11021': '71-10525', '71-11022': '71-10525', '71-11023': '71-10525', '71-11024': '71-10525', '71-11025': '71-10525', '71-11026': '71-10525', '71-11027': '71-10525', '71-11028': '71-10525', '71-11029': '71-10525', '71-11030': '71-10525', '71-11031': '71-10525', '71-11032': '71-10525', '71-11033': '71-10525', '71-11034': '71-10525', '71-11035': '71-10525', '71-11036': '71-10525',
                    '71-11037': '71-10525', '71-11038': '71-10525', '71-11039': '71-10525', '71-10670': '71-10523', '71-10671': '71-10523', '71-10680': '71-10523', '71-10681': '71-10523', '71-10686': '71-10523', '71-10687': '71-10523', '71-10692': '71-10523', '71-10693': '71-10523', '71-10696': '71-10523', '71-10697': '71-10523', '71-10705': '71-10523', '71-10706': '71-10523', '71-10718': '71-10524',
                    '71-10719': '71-10524', '71-10738': '71-10524', '71-10739': '71-10524', '71-10750': '71-10524', '71-10751': '71-10524', '71-10758': '71-10524', '71-10759': '71-10524', '71-10768': '71-10524', '71-10769': '71-10524', '71-10784': '71-10524', '71-10785': '71-10524', '71-10798': '71-10524', '71-10799': '71-10524', '71-10804': '71-10524', '71-10805': '71-10524', '71-11040': '71-10525',
                    '71-11041': '71-10525', '71-11057': '71-10525', '71-11058': '71-10525', '71-11063': '71-10525', '71-11064': '71-10525', '71-11082': '71-10525', '71-11083': '71-10525', '71-11094': '71-10525', '71-11095': '71-10525', '71-11104': '71-10525', '71-11105': '71-10525', '71-11110': '71-10525', '71-11111': '71-10525', '71-11116': '71-10525', '71-11117': '71-10525', '71-11123': '71-10525',
                    '71-11124': '71-10525', '71-11129': '71-10525', '71-11130': '71-10525', '71-11135': '71-10525', '71-11136': '71-10525', '71-11141': '71-10525', '71-11142': '71-10525', '71-11154': '71-10525', '71-11155': '71-10525', '71-11162': '71-10525', '71-11163': '71-10525', '71-11174': '71-10525', '71-11175': '71-10525', '71-11180': '71-10525', '71-11181': '71-10525', '71-11190': '71-10525',
                    '71-11191': '71-10525', '71-11204': '71-10525', '71-11205': '71-10525', '71-11211': '71-10525', '71-11212': '71-10525', '71-12759': '71-10523', '71-12760': '71-10523', '71-12761': '71-10523', '71-10674': '71-10523', '71-10673': '71-10523', '71-10675': '71-10523', '71-10676': '71-10523', '71-12762': '71-10523', '71-12763': '71-10523', '71-12764': '71-10523', '71-10682': '71-10523',
                    '71-10683': '71-10523', '71-12765': '71-10523', '71-12766': '71-10523', '71-12767': '71-10523', '71-10688': '71-10523', '71-10690': '71-10523', '71-12768': '71-10523', '71-12769': '71-10523', '71-12770': '71-10523', '71-10694': '71-10523', '71-12771': '71-10523', '71-12772': '71-10523', '71-12773': '71-10523', '71-10698': '71-10523', '71-10701': '71-10523', '71-10703': '71-10523',
                    '71-12774': '71-10523', '71-12775': '71-10523', '71-12776': '71-10523', '71-10707': '71-10523', '71-12777': '71-10524', '71-12778': '71-10524', '71-12779': '71-10524', '71-10720': '71-10524', '71-10722': '71-10524', '71-10724': '71-10524', '71-12780': '71-10524', '71-12781': '71-10524', '71-12782': '71-10524', '71-10740': '71-10524', '71-10742': '71-10524', '71-10744': '71-10524',
                    '71-10746': '71-10524', '71-10748': '71-10524', '71-12783': '71-10524', '71-12784': '71-10524', '71-12785': '71-10524', '71-10752': '71-10524', '71-10754': '71-10524', '71-10756': '71-10524', '71-12786': '71-10524', '71-12787': '71-10524', '71-12788': '71-10524', '71-10760': '71-10524', '71-10762': '71-10524', '71-10764': '71-10524', '71-10766': '71-10524', '71-12789': '71-10524',
                    '71-12790': '71-10524', '71-12791': '71-10524', '71-10770': '71-10524', '71-10772': '71-10524', '71-10774': '71-10524', '71-10776': '71-10524', '71-10778': '71-10524', '71-10780': '71-10524', '71-10782': '71-10524', '71-12792': '71-10524', '71-12793': '71-10524', '71-12794': '71-10524', '71-10786': '71-10524', '71-10793': '71-10524', '71-10789': '71-10524', '71-12168': '71-10524',
                    '71-12169': '71-10524', '71-12795': '71-10524', '71-12796': '71-10524', '71-12797': '71-10524', '71-10800': '71-10524', '71-12798': '71-10524', '71-12799': '71-10524', '71-12800': '71-10524', '71-10806': '71-10524', '71-10809': '71-10524', '71-12801': '71-10525', '71-12802': '71-10525', '71-12803': '71-10525', '71-11042': '71-10525', '71-11046': '71-10525', '71-11050': '71-10525',
                    '71-12804': '71-10525', '71-12805': '71-10525', '71-12806': '71-10525', '71-11059': '71-10525', '71-12807': '71-10525', '71-12808': '71-10525', '71-12809': '71-10525', '71-11065': '71-10525', '71-11070': '71-10525', '71-11078': '71-10525', '71-11075': '71-10525', '71-12810': '71-10525', '71-12811': '71-10525', '71-12812': '71-10525', '71-11084': '71-10525', '71-11087': '71-10525',
                    '71-11091': '71-10525', '71-12813': '71-10525', '71-12814': '71-10525', '71-12815': '71-10525', '71-11096': '71-10525', '71-11100': '71-10525', '71-12816': '71-10525', '71-12817': '71-10525', '71-12818': '71-10525', '71-11106': '71-10525', '71-12819': '71-10525', '71-12820': '71-10525', '71-12821': '71-10525', '71-11112': '71-10525', '71-12822': '71-10525', '71-12823': '71-10525',
                    '71-12824': '71-10525', '71-11118': '71-10525', '71-12825': '71-10525', '71-12826': '71-10525', '71-12827': '71-10525', '71-11125': '71-10525', '71-12828': '71-10525', '71-12829': '71-10525', '71-12830': '71-10525', '71-11131': '71-10525', '71-12831': '71-10525', '71-12832': '71-10525', '71-12833': '71-10525', '71-11137': '71-10525', '71-12834': '71-10525', '71-12835': '71-10525',
                    '71-12836': '71-10525', '71-11143': '71-10525', '71-11146': '71-10525', '71-11150': '71-10525', '71-12837': '71-10525', '71-12838': '71-10525', '71-12839': '71-10525', '71-11156': '71-10525', '71-11159': '71-10525', '71-12840': '71-10525', '71-12841': '71-10525', '71-12842': '71-10525', '71-11164': '71-10525', '71-11167': '71-10525', '71-12843': '71-10525', '71-12844': '71-10525',
                    '71-12845': '71-10525', '71-11176': '71-10525', '71-12846': '71-10525', '71-12847': '71-10525', '71-12848': '71-10525', '71-11183': '71-10525', '71-11186': '71-10525', '71-12849': '71-10525', '71-12850': '71-10525', '71-12851': '71-10525', '71-11192': '71-10525', '71-11196': '71-10525', '71-11200': '71-10525', '71-12852': '71-10525', '71-12853': '71-10525', '71-12854': '71-10525',
                    '71-11206': '71-10525', '71-12855': '71-10525', '71-12856': '71-10525', '71-12857': '71-10525', '71-11213': '71-10525', '71-10526': '71-10520', '71-12053': '71-10520', '71-12054': '71-10520', '71-10527': '71-10520', '71-10528': '71-10520', '71-12055': '71-10520', '71-12056': '71-10520', '71-12057': '71-10520', '71-12058': '71-10520', '71-12059': '71-10520', '71-12079': '71-10520',
                    '71-12081': '71-10520', '71-12085': '71-10520', '71-12086': '71-10520', '71-12865': '71-10520', '71-12866': '71-10520', '71-10529': '71-10520', '71-10530': '71-10520', '71-10531': '71-10520', '71-10538': '71-10520', '71-10532': '71-10520', '71-10533': '71-10520', '71-10534': '71-10520', '71-10535': '71-10520', '71-10536': '71-10520', '71-10537': '71-10520', '71-12060': '71-10520',
                    '71-12061': '71-10520', '71-12062': '71-10520', '71-12063': '71-10520', '71-12065': '71-10520', '71-12069': '71-10520', '71-12070': '71-10520', '71-12080': '71-10520', '71-12082': '71-10520', '71-12087': '71-10520', '71-12095': '71-10520', '71-12064': '71-10520', '71-12066': '71-10520', '71-12067': '71-10520', '71-12068': '71-10520', '71-12071': '71-10520', '71-12074': '71-10520',
                    '71-12083': '71-10520', '71-12084': '71-10520', '71-12088': '71-10520', '71-12089': '71-10520', '71-12090': '71-10520', '71-12091': '71-10520', '71-12092': '71-10520', '71-12096': '71-10520', '71-12097': '71-10520', '71-12072': '71-10520', '71-12073': '71-10520', '71-12075': '71-10520', '71-12076': '71-10520', '71-12077': '71-10520', '71-12078': '71-10520', '71-12093': '71-10520',
                    '71-12094': '71-10520',

                    // New structures created by Julia 2021-05-10 and 2021-05-11
                    '71-63175': '71-10523',
                    '71-63178': '71-10523',
                    '71-63179': '71-10523',
                    '71-63180': '71-10523',
                    '71-63181': '71-10523',

                    '71-63164': '71-10524',
                    '71-63165': '71-10524',
                    '71-63166': '71-10524',
                    '71-63167': '71-10524',

                    '71-63155': '71-10525',
                    '71-63156': '71-10525',
                    '71-63157': '71-10525',
                    '71-63163': '71-10525',

                }

                output.sdgLinkageByGriWfid = {
                    '71-12169': ['71-458', '71-523', '71-532', '71-536'], '71-12168': ['71-429', '71-509'], '71-11213': ['71-546'], '71-11206': ['71-546', '71-553', '71-13260'], '71-11200': ['71-546'], '71-11196': ['71-546'], '71-11192': ['71-513'], '71-11186': ['71-546'], '71-11176': ['71-548'], '71-11167': ['71-445', '71-473', '71-544'], '71-11164': ['71-445', '71-473', '71-544'],
                    '71-11159': ['71-409', '71-415'], '71-11137': ['71-415'], '71-11131': ['71-544'], '71-11125': ['71-472'], '71-11118': ['71-472', '71-545'], '71-11112': ['71-473'], '71-11106': ['71-444', '71-473', '71-555'], '71-11100': ['71-444', '71-470', '71-13217'], '71-11096': ['71-444', '71-448', '71-470'], '71-11091': ['71-444', '71-470', '71-13217'], '71-11087': ['71-467', '71-470'],
                    '71-11084': ['71-436', '71-437', '71-438', '71-444', '71-467', '71-470', '71-13217'], '71-11078': ['71-473'], '71-11075': ['71-423', '71-424', '71-429', '71-473'], '71-11070': ['71-423', '71-424', '71-429', '71-473'], '71-11065': ['71-473'], '71-11059': ['71-473'], '71-11050': ['71-444', '71-447', '71-470'], '71-11046': ['71-447', '71-470', '71-13130'],
                    '71-11042': ['71-444', '71-470', '71-471', '71-13217'], '71-10800': ['71-546'], '71-10793': ['71-429', '71-455', '71-509', '71-510'], '71-10789': ['71-429', '71-455', '71-458', '71-509', '71-522', '71-532'], '71-10786': ['71-429', '71-455', '71-456', '71-458', '71-509', '71-522'], '71-10782': ['71-429', '71-509', '71-524', '71-533'],
                    '71-10780': ['71-429', '71-509'], '71-10778': ['71-517', '71-524', '71-533'], '71-10776': ['71-517', '71-524', '71-533'], '71-10774': ['71-429', '71-509', '71-517', '71-524', '71-533'], '71-10772': ['71-429', '71-509', '71-517', '71-524', '71-533'], '71-10770': ['71-429', '71-509', '71-517', '71-524', '71-533'], '71-10766': ['71-458', '71-523', '71-532', '71-535', '71-536'],
                    '71-10764': ['71-458', '71-523', '71-532', '71-535', '71-536'], '71-10762': ['71-458', '71-523', '71-532', '71-535', '71-536'], '71-10760': ['71-458', '71-523', '71-532', '71-535', '71-536'], '71-10756': ['71-455', '71-456', '71-469', '71-507'], '71-10754': ['71-456'], '71-10752': ['71-456'], '71-10748': ['71-463', '71-469', '71-507', '71-517'],
                    '71-10746': ['71-463', '71-469', '71-507', '71-517'], '71-10744': ['71-463', '71-469', '71-507', '71-517'], '71-10742': ['71-462', '71-463', '71-469', '71-507', '71-517'], '71-10740': ['71-462', '71-463', '71-469', '71-507', '71-517'], '71-10724': ['71-469', '71-507', '71-510'], '71-10722': ['71-469', '71-507', '71-510'],
                    '71-10720': ['71-469', '71-507'], '71-10707': ['71-546'], '71-10703': ['71-548'], '71-10701': ['71-548'], '71-10698': ['71-548'], '71-10694': ['71-468'], '71-10690': ['71-407', '71-415', '71-428', '71-467', '71-468', '71-470', '71-494', '71-558'], '71-10688': ['71-418', '71-447', '71-464', '71-465', '71-478', '71-481', '71-483', '71-497'],
                    '71-10683': ['71-470'], '71-10682': ['71-407', '71-444', '71-470'], '71-10674': ['71-418', '71-447', '71-464', '71-465', '71-466', '71-467', '71-478', '71-481', '71-482', '71-483'], '71-10673': ['71-517'], '71-10582': ['71-550'], '71-10574': ['71-550'], '71-10572': ['71-440'], '71-10570': ['71-549'], '71-10569': ['71-448', '71-550'],
                    '71-10568': ['71-549'], '71-10567': ['71-448', '71-550'], '71-10566': ['71-473'], '71-10565': ['71-550'], '71-10561': ['71-546'], '71-10560': ['71-546'], '71-10552': ['71-470', '71-13217'],
                }

                output.sdgCategoriesByWfid = {
                    '71-389': null,
                    '71-391': null,
                    '71-388': null,
                    '71-393': null,
                    '71-395': null,
                    '71-396': null,
                    '71-397': null,
                    '71-398': null,
                    '71-399': null,
                    '71-400': null,
                    '71-401': null,
                    '71-403': null,
                    '71-402': null,
                    '71-394': null,
                    '71-392': null,
                    '71-390': null,
                    '71-404': null,
                }

                output.categoryWfidBySdgWfid = {
                    '71-418': '71-389', '71-415': '71-389', '71-13123': '71-389', '71-10367': '71-389', '71-10368': '71-389', '71-13127': '71-389', '71-10369': '71-389', '71-13330': '71-389', '71-13138': '71-391', '71-13139': '71-391', '71-436': '71-391', '71-437': '71-391', '71-438': '71-391', '71-13140': '71-391', '71-440': '71-391', '71-13195': '71-391', '71-13196': '71-391', '71-13197': '71-391',
                    '71-406': '71-388', '71-409': '71-388', '71-407': '71-388', '71-13119': '71-388', '71-13120': '71-388', '71-13121': '71-388', '71-13122': '71-388', '71-456': '71-393', '71-458': '71-393', '71-455': '71-393', '71-10370': '71-393', '71-13204': '71-393', '71-13205': '71-393', '71-13206': '71-393', '71-10371': '71-393', '71-466': '71-395', '71-13210': '71-395', '71-467': '71-395',
                    '71-468': '71-395', '71-469': '71-395', '71-470': '71-395', '71-471': '71-395', '71-472': '71-395', '71-473': '71-395', '71-13209': '71-395', '71-13211': '71-395', '71-13212': '71-395', '71-478': '71-396', '71-13213': '71-396', '71-13214': '71-396', '71-481': '71-396', '71-482': '71-396', '71-483': '71-396', '71-13215': '71-396', '71-13216': '71-396', '71-486': '71-397',
                    '71-487': '71-397', '71-13217': '71-397', '71-13218': '71-397', '71-13219': '71-397', '71-13220': '71-397', '71-13221': '71-397', '71-13222': '71-397', '71-494': '71-397', '71-13223': '71-397', '71-497': '71-398', '71-13224': '71-398', '71-13225': '71-398', '71-13226': '71-398', '71-13227': '71-398', '71-10372': '71-398', '71-13229': '71-398', '71-13230': '71-398',
                    '71-13231': '71-398', '71-10373': '71-398', '71-10374': '71-399', '71-507': '71-399', '71-10375': '71-399', '71-509': '71-399', '71-510': '71-399', '71-10376': '71-399', '71-512': '71-399', '71-513': '71-399', '71-10387': '71-399', '71-13237': '71-399', '71-13238': '71-399', '71-517': '71-400', '71-981': '71-400', '71-10377': '71-400', '71-10378': '71-400', '71-13241': '71-400',
                    '71-522': '71-401', '71-523': '71-401', '71-524': '71-401', '71-10379': '71-401', '71-13243': '71-401', '71-13244': '71-401', '71-13245': '71-401', '71-13246': '71-401', '71-13247': '71-401', '71-13248': '71-401', '71-544': '71-403', '71-545': '71-403', '71-546': '71-403', '71-548': '71-403', '71-549': '71-403', '71-550': '71-403', '71-553': '71-403', '71-555': '71-403',
                    '71-13257': '71-403', '71-13258': '71-403', '71-13259': '71-403', '71-13260': '71-403', '71-533': '71-402', '71-536': '71-402', '71-535': '71-402', '71-532': '71-402', '71-10380': '71-402', '71-13250': '71-402', '71-10381': '71-402', '71-10382': '71-402', '71-10384': '71-402', '71-10385': '71-402', '71-10383': '71-402', '71-13256': '71-402', '71-13208': '71-394',
                    '71-462': '71-394', '71-463': '71-394', '71-464': '71-394', '71-465': '71-394', '71-444': '71-392', '71-445': '71-392', '71-13198': '71-392', '71-447': '71-392', '71-448': '71-392', '71-13199': '71-392', '71-13200': '71-392', '71-13201': '71-392', '71-13202': '71-392', '71-423': '71-390', '71-429': '71-390', '71-424': '71-390', '71-428': '71-390', '71-13129': '71-390',
                    '71-13130': '71-390', '71-13131': '71-390', '71-13132': '71-390', '71-13134': '71-390', '71-13136': '71-390', '71-13135': '71-390', '71-13133': '71-390', '71-13137': '71-390', '71-13261': '71-404', '71-13262': '71-404', '71-558': '71-404', '71-13263': '71-404', '71-13264': '71-404', '71-13269': '71-404', '71-562': '71-404', '71-13270': '71-404', '71-13271': '71-404',
                    '71-13272': '71-404', '71-13273': '71-404', '71-13274': '71-404', '71-13276': '71-404', '71-13277': '71-404', '71-13278': '71-404', '71-10386': '71-404', '71-13280': '71-404', '71-13281': '71-404', '71-13282': '71-404',
                }

                promise = dataOps.getObjects(_.keys(output.sdgCategoriesByWfid), { cacheResponse: false })
                promise.then((res) => {
                    output.sdgCategoriesByWfid = _.chain(res).keyBy('wfid').mapValues((sdgGoal) => {
                        const title = sdgGoal.title

                        // Extract part "Goal X" from the title
                        // title = title.substring(0, title.indexOf(" - "));

                        return {
                            title,
                            wfid: sdgGoal.wfid,
                            type: sdgGoal.type,
                            hasImage: sdgGoal.hasImage,
                            imageUrl: sdgGoal.imageUrl,
                        }
                    }).value()
                })

                lookups = output
                // 	resolve();
                // });
            }

            function natsort(options) {

                options = options || {}

                const GREATER = options.desc ? -1 : 1
                const SMALLER = -GREATER

                const ore = /^0/
                const sre = /\s+/g
                const tre = /^\s+|\s+$/g
                // unicode
                const ure = /[^\x00-\x80]/
                // hex
                const hre = /^0x[0-9a-f]+$/i
                // numeric
                // var nre = /(^([+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|^0x[\da-fA-F]+$|\d+)/g;
                const nre = /(0x[\da-fA-F]+|(^[\+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|\d+)/g
                // datetime
                const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/

                const normalize = options.insensitive ?
                    function (s) {
                        return lowerCase('' + s).replace(tre, '')
                    } :
                    function (s) {
                        return ('' + s).replace(tre, '')
                    }

                function lowerCase(s) {
                    if (s.toLocaleLowerCase) {
                        return s.toLocaleLowerCase()
                    }
                    return s.toLowerCase()
                }

                function tokenize(s) {
                    return s.replace(nre, '\0$1\0')
                        .replace(/\0$/, '')
                        .replace(/^\0/, '')
                        .split('\0')
                }

                function parse(s, l) {
                    // normalize spaces; find floats not starting with '0',
                    // string or 0 if not defined (Clint Priest)
                    return (!s.match(ore) || l === 1)
						&& parseFloat(s)
						|| s.replace(sre, ' ').replace(tre, '')
						|| 0
                }

                return function (a, b) {

                    // trim pre-post whitespace
                    const x = normalize(a)
                    const y = normalize(b)

                    // return immediately if at least one of the values is empty.
                    // - empty string < any others
                    if (!x && !y) {
                        return 0
                    }

                    if (!x && y) {
                        return SMALLER
                    }

                    if (x && !y) {
                        return GREATER
                    }

                    // tokenize: split numeric strings and default strings
                    const xArr = tokenize(x)
                    const yArr = tokenize(y)

                    // hex or date detection
                    const xD = parseInt(x.match(hre), 16) || (xArr.length !== 1 && Date.parse(x))
                    const yD = parseInt(y.match(hre), 16) || xD && y.match(dre) && Date.parse(y) || null

                    // try and sort Hex codes or Dates
                    if (yD) {
                        if (xD < yD) {
                            return SMALLER
                        } else if (xD > yD) {
                            return GREATER
                        }
                    }

                    //console.log('x: ' + xArr.join('@'));
                    //console.log('y: ' + yArr.join('@'));

                    const xL = xArr.length
                    const yL = yArr.length

                    // handle numeric strings and default strings
                    for (let i = 0, l = Math.max(xL, yL); i < l; i++) {

                        const xF = parse(xArr[i] || '', xL)
                        const yF = parse(yArr[i] || '', yL)

                        //console.log('xF: ' + xF);
                        //console.log('yF: ' + yF);

                        // handle numeric vs string comparison
                        // - numeric < string - (Kyle Adams)
                        if (isNaN(xF) !== isNaN(yF)) {
                            return isNaN(xF) ? GREATER : SMALLER
                        }

                        // if unicode use locale comparison
                        if (ure.test(xF + yF) && xF.localeCompare) {
                            const comp = xF.localeCompare(yF)

                            if (comp > 0) {
                                return GREATER
                            } else if (comp < 0) {
                                return SMALLER
                            } else {
                                if (i === l - 1) {
                                    return 0
                                } else {
                                    continue
                                }
                            }
                        }

                        if (xF < yF) {
                            return SMALLER
                        } else if (xF > yF) {
                            return GREATER
                        }
                    }

                    return 0
                }
            }

            function syncFulfillmentStatistics(itemsWithRequirement) {

                if (!itemsWithRequirement) {
                    itemsWithRequirement = self.items.filter(x => x.requirement && x.requirement.rule !== enums.requirementRule.emptyRequirement)
                }
                // console.log(self.items.length, self.negotiator.items.length)

                // const statesByString = {
                // 	fulfilled:          enums.fulfillmentState.fulfilled,
                // 	unfulfilled:        enums.fulfillmentState.unfulfilled,
                // 	assessmentNeeded:   enums.fulfillmentState.assessmentNeeded,
                // 	reportingNeeded:    enums.fulfillmentState.reportingNeeded,
                // 	expired:            enums.fulfillmentState.expired,
                // 	partiallyFulfilled: "partiallyFulfilled",
                // };

                const states = {
                    fulfilled: { state: enums.fulfillmentState.fulfilled, color: '#48C72B', icon: 'fas fa-check', items: [], count: 0, showButton: true },
                    unfulfilled: { state: enums.fulfillmentState.unfulfilled, color: '#DF4A37', icon: 'fas fa-times', items: [], count: 0, showButton: true },
                    assessmentNeeded: { state: enums.fulfillmentState.assessmentNeeded, color: '', icon: 'fas fa-question-circle', items: [], count: 0, showButton: true },
                    reportingNeeded: { state: enums.fulfillmentState.reportingNeeded, color: '#34495e', icon: 'fas fa-repeat', items: [], count: 0, showButton: true },
                    expired: { state: enums.fulfillmentState.expired, color: '', icon: 'fas fa-exclamation-triangle', items: [], count: 0, showButton: true },
                    certificateExpired: { state: enums.fulfillmentState.certificateExpired, color: '', icon: 'fas fa-exclamation-triangle', items: [], count: 0, showButton: true },
                    partiallyFulfilled: { state: 'partiallyFulfilled', color: '#DBAE0A', icon: 'fas fa-minus-circle', items: [], count: 0, showButton: true },
                    notAnswered: { state: 'notAnswered', color: '', icon: 'fa fa-plus', items: [], count: 0, showButton: true },
                    answered: { state: 'answered', color: '', icon: 'fas fa-comment-alt', items: [], count: 0, showButton: false },
                }

                self.fulfillmentStatistics = _.mapValues({
                    notAnswered: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'isAnswered') === false),
                    fulfilled: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.fulfilled),
                    unfulfilled: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.unfulfilled && _.get(x.fulfillmentResult, 'isAnswered')),
                    assessmentNeeded: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.assessmentNeeded && _.get(x.fulfillmentResult, 'isAnswered')),
                    reportingNeeded: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.reportingNeeded),
                    expired: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.expired),
                    certificateExpired: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.certificateExpired),
                    partiallyFulfilled: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === 'partiallyFulfilled'),
                    answered: itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'isAnswered')),
                }, (x, key) => ({
                    id: 'ffState_' + key,
                    items: x,
                    count: x.length,
                    percentage: x.length / itemsWithRequirement.length * 100,
                    label: requirementService.getFulfillmentStateText(states[key] ? states[key].state : ''),
                    color: states[key] ? states[key].color : '',
                    icon: states[key] ? states[key].icon : '',
                    showButton: states[key] && states[key].showButton,
                    type: enums.objectType.fulfillment,
                }))

                self.fulfillmentStatistics.itemsWithRequirement = {
                    items: itemsWithRequirement,
                    count: itemsWithRequirement.length,
                }

                self.fulfillmentStatistics.unfulfilledItems = itemsWithRequirement.filter(x => _.get(x.fulfillmentResult, 'fulfillmentState') === enums.fulfillmentState.unfulfilled)

                if (typeof self.syncFulfillmentProgressBar === 'function') self.syncFulfillmentProgressBar()
            }
        }
    }
})()
