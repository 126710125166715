/**
 * @ngdoc directive
 * @name wfInfluenceGrouper
 * 
 * @description 
 * Extends the controller InfluenceGrouper so that it can be used as a directive.
 * See description in influence.controller.js
 */
(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfInfluenceGrouper', wfInfluenceGrouper)

    wfInfluenceGrouper.$inject = []
	
    function wfInfluenceGrouper() {
        const directive = {
            restrict: 'E',
            scope: {
                // If defined then it is scenario A and B
                influenceChildContent: '=item',
                influence: '=',

                // If defined then it is scenario C (aggregate)
                influences: '=',
                network: '=',
            },
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                $scope.openedViaDirective = true
                $scope.directiveElement = $element
                $scope.openedInModal = !!$element.closest('div.modal-dialog').length
            }],
            templateUrl: 'scripts/wf/influence/influenceGrouper.controller.html',
        }
        return directive
    }
})()
