// @flow
import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ProductNode from '../Tree/ProductNode'
import EmptyViewerPlaceholder from './EmptyViewerPlaceholder'
import Colors from '@worldfavor/constants/colors'
import PanZoom from 'react-easy-panzoom'
import TreeView from '../Tree/TreeView'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    placeholder: {
        border: `dashed 2px ${Colors.graySemiLight}`,
        borderRadius: 4,
    },
    placeholderLine: {
        width: 2,
        height: 84,
        borderLeft: `dashed 2px ${Colors.graySemiLight}`,
    },
})

const TreeViewer = (props) => {
    const { edges, nodes, organizations, rootNodeId, onEditButtonClick, editable } = props
    const classes = useStyles(props)
    const panZoomRef = useRef(null)

    if (organizations.length === 0) {
        return (
            <div className={classes.root}>
                <ProductNode productWfid={nodes[rootNodeId].properties.productWfid} />
                <div className={classes.placeholderLine} />
                <EmptyViewerPlaceholder
                    classes={{ root: classes.placeholder }}
                    onEditButtonClick={onEditButtonClick}
                    editable={editable}
                />
            </div>
        )
    }

    function onTreeMounted() {
        if (panZoomRef && panZoomRef.current && panZoomRef.current.autoCenter) {
            panZoomRef.current.autoCenter()
        }
    }

    function enableScroll() {
        document.removeEventListener('wheel', preventDefault, false)
    }

    function disableScroll() {
        document.addEventListener('wheel', preventDefault, {
            passive: false,
        })
    }

    function preventDefault(e: any) {
        e = e || window.event
        if (e.preventDefault) {
            e.preventDefault()
        }
        e.returnValue = false
    }

    return (
        <div
            onMouseEnter={disableScroll}
            onMouseLeave={enableScroll}
            style={{ height: '100%' }}
        >
            <PanZoom
                ref={panZoomRef}
                style={{ height: '100%' }}
                maxZoom={1}
                minZoom={0.05}
            >
                <TreeView
                    id={rootNodeId}
                    organizations={organizations}
                    nodes={nodes}
                    edges={edges}
                    onTreeMounted={onTreeMounted}
                />
            </PanZoom>
        </div>
    )
}

export default TreeViewer
