import endpoints from './endpoints'
import { post } from './requestWrappers'

export const fetchItem = async (id, type, options = {}, headers = {}) => {
    const payload = {
        objectId: id,
        objectType: type,
        ...options,
    }
    return await post(endpoints.object, payload, headers)
}

export const fetchItems = async (ids, options = {}, headers = {}) => {
    const payload = {
        wfids: ids,
        ...options,
    }
    return await post(endpoints.objects, payload, headers)
}

export const fetchSubItems = async (id, type, kind, options = {}, headers = {}) => {
    const payload = {
        item: {
            id,
            type,
        },
        kind,
        ...options,
    }
    return await post(endpoints.subItems, payload, headers)
}

export const fetchSubItemsOfAll = async (wfids, kind, options = {}, headers = {}) => {
    const payload = {
        wfids,
        kind,
        ...options,
    }
    return await post(endpoints.subItemsOfAll, payload, headers)
}

export const fetchSubItemsPage = async (id, type, source, options = {}, headers = {}) => {
    const payload = {
        objectId: id,
        objectType: type,
        ...options,
    }
    return await post(endpoints.subItemsPage, payload, headers, source)
}
