// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import OrganizationList from '../List/OrganizationList'
import EmptyViewerPlaceholder from './EmptyViewerPlaceholder'

const useStyles = makeStyles({
    placeholderContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

const ListViewer = (props) => {
    const { organizations, onEditButtonClick, editable } = props
    const classes = useStyles(props)

    if (organizations.length === 0) {
        return (
            <div className={classes.placeholderContainer}>
                <EmptyViewerPlaceholder
                    onEditButtonClick={onEditButtonClick}
                    editable={editable}
                />
            </div>
        )
    }

    return (
        <OrganizationList data={organizations} />
    )
}

export default ListViewer
