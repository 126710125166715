import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ManageController', ManageController)

    ManageController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$state']
    function ManageController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state) {
        const
            vm = this

        $scope.$state = $state

        $scope.$on('$destroy', () => {
            window.wf__managageLoaded = false
        })

        vm.openStandard = function (id) {
            if (vm.useNewHierarchy) $state.go('hier', { objectId: id })
            else $state.go('dataRelation', { idPath: id })
        }

        vm.openDataRelation = function (id) {
            $state.go('dataRelation', { idPath: id })
        }

        activate()
		
        function activate() {
            const routeIsManage = $state.includes('manage')
            // if (!$state.includes("manage^")) {
            // 	$state.go("manage");
            // }

            window.wf__managageLoaded = true

            $rootScope.setPageTitle('Manage')

            vm.urlPath = routeIsManage ? '/manage/' : '/rel/' + $stateParams.objectId + '/data/'

            dataOps.getObject({
                objectId: 10010,
                objectType: enums.objectType.structure,
                childrenLoadDepth: 3,
            }).then((root) => {
                let hasInformationRepository = false
                vm.item = _.find(root.childContent.childs, (item) => {
                    try {
                        return item.childContent.conditions.contextVariable1 == 'MANAGE'
                    }
                    catch (e) {
                        return false
                    }
                })
                // console.info("done", vm.item)
					
                if (!vm.item) {
                    $state.go('promotion', { nameRef: 'Manage' }, { location: false })
                }
                else {
                    _.forEach(vm.item.childContent.childs, (child, i) => {
                        const contextVariable = child.childContent.conditions ? child.childContent.conditions.contextVariable1 : null
                        // console.log(contextVariable, child)
                        // console.log(contextVariable)
                        switch (contextVariable) {
                            case 'USERDATA':
                                hasInformationRepository = true
                                dataOps.getObject({
                                    objectId: child.childId,
                                    objectType: enums.objectType.structure,
                                    childrenLoadDepth: 1,
                                }).then((root) => {
                                    vm.userData = child
                                    if (child.childContent && child.childContent.childs.length && !$state.includes('^.child')) {
                                        if ($state.includes('manage')) {
                                            // console.log(subObjectId)
                                            $state.go('manage.child', { subObjectId: child.childContent.childs[0].id }, { location: 'replace' })
                                        }
                                        else {
                                            $state.go('dataRelation.child', { subObjectId: child.childContent.childs[0].id }, { location: 'replace' })
                                        }
                                    }
									
                                    vm.loaded = true
                                    $timeout()
                                    // $timeout();
                                })
                                break
                            case 'STANDARDS':
                            case 'STANDARDS_v2':
                                if (contextVariable === 'STANDARDS_v2') vm.useNewHierarchy = true
                                // vm.standards = child;
                                // console.log("-----------------------------------");
                                // console.log(vm.standards);
                                // console.log(vm.standards.childContent);
                                // console.log(vm.standards.childContent.childs);
                                var standardsAndGuidelinesWatcher = $scope.$watch(() => {
                                    // console.log(child.childContent.childs.length);
                                    return child.childContent.childs.length
                                }, () => {
                                    if (child.childContent.childs.length > 0) {
                                        dataOps.getSubItemsOfAll(_.map(child.childContent.childs, 'childContent'), enums.subItemsKind.relatedContentByUser, { bypassCache: true, getterConditions: { loadCreators: false } }).then(() => {
                                            vm.standardsAndGuidelines = child
                                            $timeout()
                                        })
                                        standardsAndGuidelinesWatcher()
                                    }
                                })
                                break
                            case 'ISO_STANDARDS':
                                vm.useNewHierarchy = true
							
                                var isoStandardsWatcher = $scope.$watch(() => {
                                    // console.log(child.childContent.childs.length);
                                    return child.childContent.childs.length
                                }, () => {
                                    if (child.childContent.childs.length > 0) {
                                        dataOps.getSubItemsOfAll(_.map(child.childContent.childs, 'childContent'), enums.subItemsKind.relatedContentByUser, { bypassCache: true, getterConditions: { loadCreators: false } }).then(() => {
                                            vm.isoStandards = child
                                            $timeout()
                                        })
                                        isoStandardsWatcher()
                                    }
                                })
                                break
                            case 'TOOLS':
                                var toolsWatcher = $scope.$watch(() => {
                                    // console.log(child.childContent.childs.length);
                                    return child.childContent.childs.length
                                }, () => {
                                    if (child.childContent.childs.length > 0) {
                                        vm.tools = child
                                        toolsWatcher()
                                    }
                                })
                                break
                            case 'OWN_STRUCTURES':
                                vm.useNewHierarchy = true
                                var ownStructuresWatcher = $scope.$watch(() => {
                                    // console.log(child.childContent.childs.length);
                                    return child.childContent.childs.length
                                }, () => {
                                    vm.ownStructures = child
                                    ownStructuresWatcher()
                                })
                                break
                            case 'INTERNAL_VC':
                                var internalValueChainWatcher = $scope.$watch(() => {
                                    // console.log(child.childContent.childs.length);
                                    return child.childContent.childs.length
                                }, () => {
                                    vm.internalValueChain = child

                                    // dataOps.getSubItems(vm.internalValueChain.childContent.childs[0].childContent, 1).then(function (resUsers) {
                                    // 	// console.log(res);
                                    // 	// console.log(res.length);
                                    // 	var internamVCUsers = res;
                                    // });

                                    dataOps.getSubItems(vm.internalValueChain.childContent.childs[0].childContent, 1).then((dataRelations) => {
                                        const userIds = _.map(dataRelations, 'childId')

                                        vm.internalValueChainUsers = vm.internalValueChain.childContent.childs[0].childContent.childs
										
                                        dataOps.getObjects({
                                            requestSignature_noResultNeeded: ['valueChain', 'internal', 'influences'].join('_'),
                                            objectType: enums.objectType.influence,
                                            wrapInRelations: false,
                                            getterConditions: {
                                                userIds,
                                            },
                                        }).then((influences) => {
                                            vm.influenceCountByUserId = _.chain(influences)
                                                .filter((influence) => {
                                                    return influence.fulfilled === false && typeof influence.userId === 'number'
                                                })
                                                .groupBy('userId')
                                                .mapValues((influences) => {
                                                    return influences.length
                                                }).value()

                                            vm.showCollabBox = !_.isEmpty(vm.influenceCountByUserId)

                                            _.remove(vm.internalValueChainUsers, (dataRelation) => {
                                                return !vm.influenceCountByUserId[dataRelation.childId]
                                            })
                                        })

                                    })

                                    internalValueChainWatcher()
                                })
								
                                break
                        }
                    })

                    if (!hasInformationRepository) {
                        vm.loaded = true
                        $timeout()
                    }
                }

            })
        }
    }
})()
