// @flow
import * as React from 'react'
import ItemStatistics from './Questions/ItemStatistics'
import { FormattedMessage } from 'react-intl'
import '@worldfavor/less/components/question-container.less'

type Props = {
    title: string,
    percentageValuesWithTitles: any[],
    valueColor: string,
}

class AttachedInfoPercentages extends React.PureComponent<Props> {

  render() {
    const {
      title,
      percentageValuesWithTitles,
      valueColor,
    } = this.props

    const percentageValuesWithTitlesCopy = [...percentageValuesWithTitles]

    const handleMaxNumberOfValuesShown = (valuesAndTitles) => {
      const maxValuesShown = 8
      if (valuesAndTitles.length > maxValuesShown) {
        const otherValues = valuesAndTitles.splice(7)
        const othersValue = otherValues.reduce((sum, obj) => sum + obj.value, 0)
        valuesAndTitles.push({ title: <FormattedMessage id={'modules.questionAnswerTypes.others'} />, value: othersValue })
      }
    }

    handleMaxNumberOfValuesShown(percentageValuesWithTitlesCopy)
      
    return (
      <>
        <div style={{ whiteSpace: 'pre-wrap', paddingRight: 100, fontWeight: 'bold' }}>{title}</div>
        <ItemStatistics
          values={percentageValuesWithTitlesCopy}
          forceShowPercentage
          valueColor={valueColor}
        />
      </>
    )
    
  }
}

export default AttachedInfoPercentages
