import React, { useState } from 'react'
import { DataManagerTableWithPage } from '../DataManagerTable'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Loading from '@worldfavor/portal/scenes/Loading'
import { useDispatch } from 'react-redux'
import { fetchAnalyzeResults } from '@worldfavor/portal/api/dataCollectionFetchFunctions'
import { loadSubItemsPageForDataCollector } from '@worldfavor/portal/actions/dataThunk'
import { FilterTypes } from '@worldfavor/constants'
import useFilters from '@worldfavor/hooks/filteringHooks'
import Api from '@worldfavor/portal/api'
import { ConstVars } from '@worldfavor/constants'
import useDataFetcher, { useFiltersFetcher } from '@worldfavor/hooks/dataFetcher'
import ErrorDialog from '../../Dialog/ErrorDialog'
import { useDialogState } from '@worldfavor/hooks'
import { AnalyzeResultsTableElements } from '@worldfavor/components/DataManager/ContentTypeTableElements'

const AnalyzeResultsTable = (props: Props) => {
    const {
        title,
        networkId,
        analyzeJobIds,
    } = props
    const [sceneLoading, setSceneLoading] = useState(true)
    const dispatch = useDispatch()
    const useInvertedFiltering = true
    const [searchText] = useState('')
    const contentType = ConstVars.analyzeResults

    const { filtersLoading, fetchedFilters } = useFiltersFetcher(Api.endpoints.filtersForAnalyzeResults(analyzeJobIds))
    const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useDialogState(false)
    const {
        filters,
        onFilterChange,
        activeFilters,
    } = useFilters(
        fetchedFilters,
        { useInvertedFiltering },
    )

    function getFilterConditionsForFilters() {
        if (activeFilters) {
            const activeFiltersArray = _.values(activeFilters)
            return activeFiltersArray.map((filter) => {
                return {
                    filterType: filter.filterType,
                    [filter.filterValueType]: filter.selectedOptions.map(option => option.value),
                }
            })
        }
        return []
    }

    const filterConditions = [
        {
            filterType: FilterTypes.TextSearch,
            stringValues: [searchText],
        },
        {
            filterType: FilterTypes.AnalyzeJobIds,
            numValues: [analyzeJobIds],
        },
        ...getFilterConditionsForFilters(),
    ]

    const fetchData = (source, pageNumber, pageSize) => dispatch(
        loadSubItemsPageForDataCollector(
            fetchAnalyzeResults,
            pageNumber,
            pageSize,
            `${networkId}:${contentType}`,
            source,
            {},
            filterConditions,
        ),
    )

    const {
        resultCount,
        pageNumber,
        pageSize,
        pageId,
        requestId,
        error,
        setPageNumber,
        setPageSize,
    } = useDataFetcher(fetchData, [activeFilters], {
        onSuccess: () => setSceneLoading(false),
        onError: () => !isErrorDialogOpen && openErrorDialog(),
    })

    function onChangeRowsPerPage(event) {
        setPageSize(event.target.value)
    }

    function onPageChange(event, pageNumber) {
        setPageNumber(pageNumber)
    }

    if (error) {
        return <ErrorDialog open={isErrorDialogOpen} closeDialog={closeErrorDialog} showReloadButton showContactSupport />
    }
    return (
        <>
            <Grid container justify={'center'}>
                <Grid item xs={12}>
                    { (sceneLoading || filtersLoading) ? <Loading /> : (
                        <DataManagerTableWithPage
                            requestId={requestId}
                            pageId={pageId}
                            rowsPerPage={pageSize}
                            page={pageNumber}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            onPageChange={onPageChange}
                            title={title}
                            contentType={contentType}
                            resultCount={resultCount}
                            filters={filters}
                            onFilterChange={onFilterChange}
                            getTableColumns={AnalyzeResultsTableElements.getTableColumns}
                            renderRowCell={AnalyzeResultsTableElements.renderRowCell}
                            renderColumnCell={AnalyzeResultsTableElements.renderColumnCell}
                            networkId={networkId}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default injectIntl(AnalyzeResultsTable)
