(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfPromotions', {
            templateUrl: 'scripts/wf/promotion/wfPromotions.component.html',
            controller: wfPromotionsController,
            controllerAs: 'vm',
            bindings: {
                customStructures: '<',
                showBanner: '=',
            },
        })

    wfPromotionsController.$inject = ['$scope', '$element', 'dataOperationsService', 'modalService', '$sce', '$rootScope', '$timeout', 'wfAuth']
    function wfPromotionsController($scope, $element, dataOps, modal, $sce, $rootScope, $timeout, wfAuth) {
        const vm = this
        const landingPageContentStructureWfid = '71-18421'
        let categorySpecificSettings = undefined

        _.assign(vm, {
            //Variables
            loaded: false,
            applyIeStyles: $.browser.msie,
            promotionalCategories: [],

            //Functions
            openGuidance,
            getVideoUrl,
            goToAnchor,
            onPromoItemClick,
        })

        vm.$onInit = function() {
            if (landingPageContentStructureWfid) {
                loadContent().then((contentStructure) => {
                    if (contentStructure && contentStructure.childs.length > 0) {
                        vm.promotionalCategories = _.map(contentStructure.childs, 'childContent')

                        if (vm.customStructures && vm.customStructures.length > 0) {
                            const tempPromotionalCategories = []
                            _.each(vm.customStructures, (customStructure) => {
                                tempPromotionalCategories.push(_.filter(vm.promotionalCategories, { id: customStructure.id })[0])
                            })
                            vm.promotionalCategories = 	tempPromotionalCategories
                        }

                        if (vm.promotionalCategories && vm.promotionalCategories.length > 0) {
                            _.each(vm.promotionalCategories, (category) => {
                                if (category) {
                                    categorySpecificSettings = undefined
                                    categorySpecificSettings = getSpecificSettings(category.id)
			
                                    if (categorySpecificSettings) _.assign(category, categorySpecificSettings)
                                }
                                else console.error('Category is empty', category)
                            })
                        }
                        else {
                            console.error('Promotional categories are empty', vm.promotionalCategories)
                        }

                        vm.loaded = true
                        $timeout()
                    }
                }, (rejectedItems) => {
                    console.error('Could not get promotions. Please check the promotionsStructureWfid. ', rejectedItems)
                })
            }
        }

        function loadContent() {
            return dataOps.getObject(landingPageContentStructureWfid, { childrenLoadDepth: 2, bypassCache: true })
        }

        function openGuidance(box) {
            modal.openGuidance({
                title: box.title,
                message: box.guidance,
            })
        }

        function getSpecificSettings(id) {
            let output
            const settings = [
                {
                    default: true,
                    id: '',
                    additionalClasses: '',
                    verticallyAlignedItems: false,
                    includePromotionHtml: false,
                    includeDescriptionUnderTitle: false,
                    order: 1,
                },
            ]

            if (id) {
                if (vm.customStructures && vm.customStructures.length > 0) output = _.filter(vm.customStructures, { id })[0]
				
                if (!output) output = _.filter(settings, { id })[0]
            }

            if (!output) output = _.filter(settings, { default: true })

            return output
        }

        function getVideoUrl(url) {
            return $sce.trustAsResourceUrl(url)
        }
		
        function goToAnchor(item) {
            const
                gap = 50
				
            let anchorId = undefined
				
            const itemsToAnchorWith = {
                18460: 'working-in-wf-anchor',
                18461: 'working-in-wf-anchor',
                18462: 'working-in-wf-anchor',
            }

            anchorId = itemsToAnchorWith[item.id]
            if (anchorId) {
                $('html, body').animate({
                    scrollTop: $('#' + anchorId).offset().top - gap,
                }, 1000)
            }
        }

        function onPromoItemClick(item, category) {
            if (category && category.useUrlPath) {
                let urlPath = _.get(item, 'childContent.conditions.urlPath')

                if (urlPath) {
                    let culture = wfAuth.getCulture()
                    culture === 'sv-SE' ? culture = 'sv/' : culture = ''

                    if (urlPath.includes('{language}')) urlPath = urlPath.replace('{language}', culture)

                    const win = window.open(urlPath, '_blank')
                    win.focus()
                }
            }
        }
    }
})()
