// @flow
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import ProductOrganizationExplorerContent from './ProductOrganizationExplorerContent'
import Api from '../../api'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles({
    titleContainer: {
        paddingRight: 40,
    },
    closeButtonWrapper: {
        position: 'absolute',
        top: 5,
        right: 10,
    },
    closeButton: {
        color: Colors.gray,
    },
})

type Props = {
  open: boolean,
  organizationId: number,
  organizationName: string,
  networkId: number,
  onClosed: () => void,
}

const ProductOrganizationExplorer = (props: Props) => {
    const { open, organizationId, organizationName, networkId, onClosed } = props
    const classes = useStyles(props)
    const history = useRef([])
    const [dialogOpen, setDialogOpen] = useState(open)
    const [loading, setLoading] = useState(false)
    const [newOrganizationId, setNewOrganizationId] = useState(organizationId)
    const [currentOrganization, setCurrentOrganization] = useState({ id: organizationId, name: organizationName })
    const [currentData, setCurrentData] = useState({
        organization: currentOrganization,
        data: null,
    })

    useEffect(() => {
        setNewOrganizationId(organizationId)
        setCurrentOrganization({ id: organizationId, name: organizationName })
    }, [organizationId, organizationName, open])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                await Promise.all([
                    await fetchProductOrganizationRelations(),
                ])
            } catch (e) {
                // TODO improve error handling
                console.error('Error fetching product data')
            } finally {
                setLoading(false)
            }
        }

        if (newOrganizationId && networkId) {
            setDialogOpen(true)
            fetchData()
        }
    }, [newOrganizationId, networkId])

    async function fetchProductOrganizationRelations() {
        const result = await Api.get(Api.endpoints.getProductOrganizationRelations(`101-${newOrganizationId}`, `52-${networkId}`))
        const data = {
            organization: currentOrganization,
            data: result.data,
        }

        history.current.push(data)
        setCurrentData(data)
    }

    function goBack() {
        history.current.splice(history.current.length - 1)
        const newCurrentData = history.current[history.current.length - 1]
        setCurrentData(newCurrentData)
        setCurrentOrganization(newCurrentData.organization)
        setNewOrganizationId(null)
    }

    function onOpenItem(item) {
        setCurrentOrganization(item)
        setNewOrganizationId(item.id)
    }

    function onLinkHrefAssign(item) {
        return `/valuechain/${networkId}/product/120-${item.id}`
    }

    function onClose() {
        history.current.length = 0
        setCurrentOrganization({ name: null })
        setCurrentData({ organization: { name: null }, data: null })
        setNewOrganizationId(null)
        setDialogOpen(false)
        onClosed()
    }

    return (
        <Dialog open={dialogOpen}>
            <DialogTitle>
                <div className={classes.titleContainer}>
                    {currentData.organization.name}
                </div>
                <div className={classes.closeButtonWrapper}>
                    <IconButton
                        color="primary"
                        className={classes.closeButton}
                        aria-label={<FormattedMessage id={'exportDialog.close'} />}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ minWidth: 600 }}>
                <CircularProgressWrapper loading={loading} blockChildren>
                    <div style={{ minHeight: 100 }}>
                        {currentData.data && <ProductOrganizationExplorerContent data={currentData.data} onOpenItem={onOpenItem} onLinkHrefAssign={onLinkHrefAssign} />}
                    </div>
                </CircularProgressWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={goBack} disabled={loading || history.current.length <= 1}>
                    <FormattedMessage id={'general.back'} />
                </Button>
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={onClose}>
                    <FormattedMessage id={'general.close'} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductOrganizationExplorer
