// @flow
import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MinimumFilterTooltip from './MinimumFilterTooltip'

const useStyles = makeStyles(theme => ({
    root: {},
    menuPaper: {
        minWidth: 160,
    },
    menu: {
        marginTop: theme.spacing(1),
    },
}))

const ActivationStatuses = [
    {
        id: 'active',
        labelId: 'general.active',
    },
    {
        id: 'inactive',
        labelId: 'general.inactive',
    },
]

const StatusHeaderCell = (props) => {
    const { onStatusCheckChange, enableFilter } = props
    const tooltipTimeout = useRef(null)
    const [anchorEl, openMenu, closeMenu] = useMenuState(null)
    const [tooltipAnchorEl, openTooltip, closeTooltip] = useMenuState(null)
    const [statusCheck, setStatusCheck] = useState(ActivationStatuses
        .reduce((acc, { id }) => ({ ...acc, [id]: true }), {}))
    const classes = useStyles(props)

    function onClick(e) {
        openMenu(e)
    }

    function toggleStatus(id) {
        function setStatus(e) {
            const newValue = !statusCheck[id]
            const { [id]: ignore, ...remainingStatuses } = statusCheck

            // check if the user is trying to disable the last option
            if (Object.values(remainingStatuses).every(value => !value)) {
                showTooltip(e)
                return
            }

            const newActivationCheckStatuses = {
                ...statusCheck,
                [id]: newValue,
            }

            setStatusCheck(newActivationCheckStatuses)

            if (Object.values(newActivationCheckStatuses).every(Boolean)) {
                onStatusCheckChange(null)
            }
            else {
                onStatusCheckChange({
                    booleanValues: [
                        ActivationStatuses.reduce((acc, { id }) => {
                            if (newActivationCheckStatuses[id]) {
                                if (acc === undefined) {
                                    return id === 'active'
                                }
                                return null
                            }

                            return acc
                        }, undefined),
                    ],
                })
            }
        }

        return setStatus
    }

    function showTooltip(e, delay = 2000) {
        clearTimeout(tooltipTimeout.current)
        openTooltip(e)
        tooltipTimeout.current = setTimeout(() => {
            closeTooltip()
        }, delay)
    }

    function hasActiveFilter() {
        return !Object.values(statusCheck).every(Boolean)
    }

    return (
        <React.Fragment>
            <TableHeaderCell
                label={<FormattedMessage id={'general.status'} />}
                onClick={enableFilter && onClick}
                hasFilter={enableFilter}
                hasActiveFilter={hasActiveFilter()}
            />
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                classes={{ paper: classes.menuPaper }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                getContentAnchorEl={undefined}
                className={classes.menu}
            >
                {
                    ActivationStatuses.map(({ id, labelId }) => (
                        <MenuItem key={`menu-item-${id}`} onClick={toggleStatus(id)}>
                            <ListItemText id={`${id}-label`}>
                                <FormattedMessage id={labelId} />
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={toggleStatus(id)}
                                    checked={statusCheck[id]}
                                    inputProps={{ 'aria-labelledby': `${id}-label` }}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    ))
                }
            </Menu>

            <MinimumFilterTooltip
                anchorEl={tooltipAnchorEl}
            />
        </React.Fragment>
    )
}

StatusHeaderCell.defaultProps = {
    onStatusCheckChange: () => {},
}

export default StatusHeaderCell
