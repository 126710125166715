import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('schemaForm')
        .config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
            function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
                const location_single = function (name, schema, options) {
                    if (schema.format === 'location_single') {
                        const f  = schemaFormProvider.stdFormObj(name, schema, options)

                        f.key  = options.path
                        f.type = 'location_single'
                        // f.validationMessage = {
                        // 	customRule: ""
                        // };
                        // f.$validators = {
                        // 	customRule: function (value) {
                        // 		return !!value;
                        // 	}
                        // }
                        options.lookup[sfPathProvider.stringify(options.path)] = f
                        return f
                    }
                }

                schemaFormProvider.defaults.integer.unshift(location_single)

                schemaFormDecoratorsProvider.addMapping(
                    'bootstrapDecorator',
                    'location_single',
                    'scripts/wf/form/schema-form-location-single.html',
                )
            },
        ])

    angular
        .module('wf.common')
        .directive('wfSchemaLocationSingle', ['$timeout', '$q', '$translate', 'modalService', 'wfObject', 'apiProxy', 'dataOperationsService', '$parse',
            function ($timeout, $q, $translate, modal, wfObject, apiProxy, dataOps, $parse) {
                return {
                    restrict: 'A',
                    scope: true,
                    require: 'ngModel',
                    link(scope, element, attrs, ngModel) {
                        const
                            vm = scope.vm = {}
					
                        let locationId

                        vm.location = undefined
                        vm.itemComponentCompiler = {}
                        vm.changeLocation = changeLocation
                        vm.removeLocation = removeLocation

                        activate()

                        function activate() {
                            locationId = $parse(attrs.ngModel)(scope)

                            dataOps.getObject({
                                bypassCache: true,
                                objectType: enums.objectType.location,
                                objectId: locationId,
                            }).then((location) => {
                                ngModel.$setViewValue(locationId)
						
                                if (location.id) vm.location = location

                                scope.loaded = true
                                $timeout()
                            })
                        }

                        function changeLocation() {
                            modal[locationId ? 'edit' : 'createWithPromise'](locationId ? vm.location : { type: enums.objectType.location }).then((location) => {
                                locationId = location.id
                                ngModel.$setViewValue(location.id)
                                vm.location = location
						
                                if (vm.itemComponentCompiler.compile) vm.itemComponentCompiler.compile()
                            })
                        }

                        function removeLocation() {
                            vm.location = undefined
                            locationId = undefined
                            ngModel.$setViewValue(undefined)
                        }

                        scope.validateField = function () {

                        }
				
                        scope.$on('schemaFormReset', reset)
                        scope.$on('schemaFormValidate', scope.validateField)
                        scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit)
                        // scope.$on('schemaFormFileUploadSubmit', scope.submit);
				
                        function reset() {
                            ngModel.$setViewValue(undefined)
                        }

                        function schemaFormBeforeSubmit(event, model) {
                        }
                    },
                }
            }])
})()
