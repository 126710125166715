import * as enums from '@worldfavor/constants/enums'
import { StaticIds } from '@worldfavor/constants'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('WfDataExplorerController', WfDataExplorerController)

    WfDataExplorerController.$inject = ['$scope', 'dataOperationsService']
    function WfDataExplorerController($scope, dataOps) {
        const vm = this

        activate()

        function activate() {
            const [type, id] = StaticIds.DataExplorer.split('-')
            dataOps.getObject({
                objectType: type,
                objectId: id,
            }).then((result) => {
                vm.dataExplorerStructure = result
                vm.loaded = true
            })
        }
    }
})()
