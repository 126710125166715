import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ValueChainPackageEditorController', ValueChainPackageEditorController)

    ValueChainPackageEditorController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$injector', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService', 'requirements', '$state', 'wfPropertyExtractor', 'wfMeasureService']
    function ValueChainPackageEditorController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $injector, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService, requirements, $state, wfPropertyExtractor, wfMeasureService) {
        let
            vm = this

        const authOrgId = wfAuth.getOrganizationId()

        let influenceToOrganizationId

        let forSpecificOrganization

        let objectId

        let networkId

        const translate = $translate.instant

        vm = _.assign(vm, {
            uiMode: enums.uiMode.admin,
            window: $window,
            loadedViaIntersection: false,
            authOrg: null,
            hasSpecialSettings: false,
            $scope,
            enabledMainSettings: {
                mainRequirement: true,
                signing: true,
                targetContextParent: true,
                requirementsDisplay: true,
                reportedDataDeletion: true,
                itemNumbering: true,
            },
            useTabs: false,
            nice: [{ wfcid: _.uniqueId(), childContent: { title: _.uniqueId() } }, { wfcid: _.uniqueId(), childContent: { title: _.uniqueId() } }],
            enums,
            isPackageOwned: false,

            // Functions
            addCategory,
            addQuestion,
            addMeasure,
            addRelativeMeasure,
            addTaskStructure,
            reorder,
            pickAttachedInformation,
            setReportingContextCondition,
            setReportingContextCondition_reportFor,
            setRequirementTextsDisplayCondition,
            setReportingUiCondition,
            setTabsCondition,
            setCsvExportCondition,
            getTabDropdownActions,
            setReportedDataDeletionSetting,
            setItemNumberingSetting,
            setSigningSetting,
            bulkChangeSettings,
            bulkChangeRequirements,

            dragControlListeners: {
                accept(sourceItemHandleScope, destSortableScope) {
                    return destSortableScope.modelValue.includes(sourceItemHandleScope.itemScope.item)
                },
                orderChanged() {
                    reorder(vm.hierItems)
                },
            },
        })

        activate()

        ////////////////

        function activate() {
            if ($injector.has('$stateParams')) {
                $injector.invoke(['$stateParams', function ($stateParams) {
                    objectId = $stateParams.objectId
                    networkId = $stateParams.networkId
                }])
            }

            if ($injector.has('$uibModalInstance')) {
                // $injector.invoke(["$uibModalInstance", function ($uibModalInstance) {
                // 	objectId = $stateParams.objectId
                // 	console.log('$uibModalInstance:', $uibModalInstance);
                // }]);
            }

            valueChainService.loadNetworks().then((result) => {
                const
                    network = result.networksById[networkId]

                const mainStructure = result.structure

                if (!network) {
                    $state.go('valueChain-root')
                    return
                }

                vm.network = network

                const applyIntersectionIfPossible = false

                dataOps.getObject({
                    objectId,
                    objectType: enums.objectType.structure,
                    childrenLoadDepth: 10,
                    getterConditions: {
                        loadRequirements: true,
                        applyIntersectionIfPossible,
                        dataRelationOrganizationMatchMode: 2,
                    },
                }).then((res) => {
                    vm.mainStructure = res

                    vm.loadedViaIntersection = !!(applyIntersectionIfPossible && res.conditions && res.conditions.intersectionSourceWfid)

                    if (vm.loadedViaIntersection) vm.relationIntersectionTargetWfid = res.wfid

                    $rootScope.setPageTitle(vm.mainStructure.title)

                    vm.isPackageOwned = vm.mainStructure.creatorOrganizationId === authOrgId

                    if (!vm.isPackageOwned) {
                        const enabledSettingKeys = [ 'mainRequirement', 'signing', 'reportedDataDeletion' ]
                        Object.keys(vm.enabledMainSettings).forEach(key => vm.enabledMainSettings[key] = enabledSettingKeys.includes(key))
                    }

                    dataOps.getSubItems(vm.mainStructure, enums.subItemsKind.relatedContent, {
                        // ticket: {
                        // 	networkId: 52
                        // }
                    }).then((res) => {
                        let flatItems
                        vm.mainStructureRelatedContent = _.orderBy(res, 'childContent.title')

                        vm.childs = vm.mainStructure.childs

                        if (vm.loadedViaIntersection) vm.childs = _.filter(vm.childs, { type: enums.objectType.virtualDataRelation })

                        flatItems = dataQuery.getHierarchyAsList(vm.mainStructure, [enums.objectType.structure, enums.objectType.question, enums.objectType.measure, enums.objectType.relativeMeasure])
                        dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedContent, { bypassCache: true }).then(() => {
                            vm.authOrg = wfAuth.getOrganization()
                            vm.hasSpecialSettings = vm.mainStructure && vm.mainStructure.conditions && !(vm.mainStructure.conditions.templateId === 75 && vm.mainStructure.conditions.dataRelation)

                            if (_.get(vm.mainStructure, 'conditions.templateId') === 42) {
                                useFolksamSupplierEduMode()
                            }

                            vm.useTabs = _.get(vm.mainStructure, 'conditions.requirementPackageSettings.useTabs')

                            if (vm.useTabs && vm.childs.length) {
                                vm.activeTab = vm.childs[0].childContent
                                vm.hierItemsNumbering = 1
                                vm.hierItems = vm.activeTab.childs
                            }
                            else
                            {
                                vm.hierItems = vm.childs
                            }

                            vm.loaded = true
                            $timeout()
                        })
                    })

                    defineRequirementAndConditionSettings()
                })
            })

            $scope.$on('itemToolsActionExecuted', ($event, operation, item, dataRelation) => {
                if (operation === 'delete' && vm.useTabs) {
                    if (_.remove(vm.childs, { wfid: item.wfid }).length || (dataRelation && _.remove(vm.childs, { wfid: dataRelation.wfid }).length)) {
                        $timeout()

                        reorderItemsAndSyncView()
                    }
                }
            })
        }

        function reorderItemsAndSyncView() {
            if (!vm.useTabs) {
                return
            }

            dataOps.saveDataRelationsOrder(vm.childs).then(() => {
                let activeTab = vm.activeTab
                vm.activeTab = undefined
                vm.hierItems = undefined
                vm.hierItemsNumbering = undefined
                $timeout(() => {
                    let activeTabRelation = vm.childs.find(x => x.wfcid === activeTab.wfid)
                    if (!activeTabRelation) {
                        activeTabRelation = vm.childs[0]
                    }

                    if (activeTabRelation) {
                        activeTab = activeTabRelation.childContent
                        vm.activeTab = activeTab
                        vm.hierItems = vm.activeTab.childs
                        vm.hierItemsNumbering = vm.childs.indexOf(vm.childs.find(x => x.wfcid === activeTab.wfid)) + 1
                    }
                }, 0)
            })
        }

        function useFolksamSupplierEduMode() {
            vm.isFolksamSupplierEdu = true

            vm.enabledMainSettings = _.mapValues(vm.enabledMainSettings, () => { return false })
            vm.enabledMainSettings.mainRequirement = true

            vm.mainStructureRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: 'Inget',
                        rule: 0,
                        value: null,
                    },
                    {
                        name: 'Minst 1 har genomfört utbildningen inkl. kontaktperson. Alla inbjudna personer har genomfört utbildningen.',
                        rule: 9,
                        value: '1',
                    },
                    {
                        name: 'Minst 2 har genomfört utbildningen inkl. kontaktperson. Alla inbjudna personer har genomfört utbildningen.',
                        rule: 9,
                        value: '2',
                    },
                    {
                        name: 'Minst 1 har bjudits in och genomfört utbildningen.',
                        rule: 4,
                        value: '1',
                    },
                    {
                        name: 'Minst 2 har bjudits in och genomfört utbildningen.',
                        rule: 4,
                        value: '2',
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()
                },
            }
        }

        function addCategory(category) {
            const jqDf = $.Deferred()

            modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                dataRelationOptions: { item1: category || vm.mainStructure, kind: enums.subItemsKind.children },
            }).then((dataRelation) => {
                if (dataRelation) {
                    dataOps.saveSettings({
                        item: dataRelation.childContent,
                        settings: {
                            dataRelation: null,
                        },
                    }).then(() => {
                        if (category) {
                            if (vm.useTabs && category === vm.activeTab) {
                                vm.hierItems = vm.activeTab.childs
                                vm.reorder(vm.hierItems)
                            }

                            jqDf.resolve()
                        }
                        else {
                            vm.childs = vm.mainStructure.childs
                            reorder(vm.childs).then(() => {
                                if (vm.useTabs) {
                                    vm.activeTab = vm.childs[0].childContent
                                    vm.hierItemsNumbering = 1
                                    vm.hierItems = vm.activeTab.childs
                                }
                                else {
                                    vm.hierItems = vm.childs
                                }
                                jqDf.resolve()
                                $timeout()
                            })
                        }
                    })
                }
                else {
                    jqDf.resolve()
                }
            })

            return jqDf.promise()
        }

        // Creates a structure but instead of a category it is a task to add more information during reporting
        function addTaskStructure(category) {
            return $q((resolve) => {
                const pickerPromise = modal.openCreatorAndPicker({
                    createObjectOfType: enums.objectType.structure,
                    relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                    objectTypes: [enums.objectType.structure],
                    simplifyForms: true,
                    customSettingsForObjectTypes: [
                        {
                            type: 71,
                            form: {
                                wording: {
                                    singular: $translate.instant('InfoAdder.singular'),
                                    plural: $translate.instant('InfoAdder.plural'),
                                    addButton: $translate.instant('InfoAdder.addButton'),
                                },
                                onAfterSubmit(event) {
                                    const obj = event.getObject()

                                    dataOps.saveSettings({
                                        item: obj,
                                        settings: {
                                            objectTypes: '44',
                                            dataRelationByUser: true,
                                            dataRelation: null,
                                        },
                                    }).then(() => {
                                        event.resolve()
                                    })
                                },
                            },
                        },
                    ],
                    // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
                })

                pickerPromise.modal.closed.then(() => {
                    resolve()

                    if (!category) {
                        vm.hierItems = vm.mainStructure.childs
                        reorder(vm.hierItems).then(() => {
                            $timeout()
                        })
                    }

                    if (vm.useTabs && category && category === vm.activeTab) {
                        vm.hierItems = vm.activeTab.childs
                        vm.reorder(vm.hierItems)
                    }
                })

            })

            const jqDf = $.Deferred()

            modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                dataRelationOptions: { item1: category || vm.mainStructure, kind: enums.subItemsKind.children },
            }).then((dataRelation) => {
                if (dataRelation) {
                    dataOps.saveSettings({
                        item: dataRelation.childContent,
                        settings: {
                            objectTypes: '44',
                            dataRelationByUser: true,
                            dataRelation: null,
                        },
                    }).then(() => {
                        if (category) {
                            if (vm.useTabs && category === vm.activeTab) {
                                vm.hierItems = vm.activeTab.childs
                                vm.reorder(vm.hierItems)
                            }

                            jqDf.resolve()
                        }
                        else {
                            vm.hierItems = vm.mainStructure.childs
                            reorder(vm.hierItems).then(() => {
                                jqDf.resolve()
                                $timeout()
                            })
                        }
                    })
                }
                else {
                    jqDf.resolve()
                }
            })

            return jqDf.promise()
        }

        function addQuestion(category) {
            // var promise = modal.openCreatorAndPicker({
            // 	relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
            // 	objectTypes: [ enums.objectType.question ]
            // 	// title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
            // });

            return $q((resolve) => {
                loadPickerSourceLists(enums.objectType.question).then((sourceLists) => {
                    const pickerPromise = modal.openCreatorAndPicker({
                        createObjectOfType: enums.objectType.question,
                        relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                        sourceLists,
                        simplifyForms: true,
                        // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
                    })

                    pickerPromise.modal.closed.then(() => {
                        resolve()

                        if (!category) {
                            vm.hierItems = vm.mainStructure.childs
                            reorder(vm.hierItems).then(() => {
                                $timeout()
                            })
                        }

                        if (vm.useTabs && category && category === vm.activeTab) {
                            vm.hierItems = vm.activeTab.childs
                            vm.reorder(vm.hierItems)
                        }
                    })
                })
            })

            // if (category) {
            // 	return promise;
            // }
            // else {

            // 	return promise;
            // }
        }

        function addMeasure(category) {
            return $q((resolve) => {
                loadPickerSourceLists(enums.objectType.measure).then((sourceLists) => {
                    const pickerPromise = modal.openCreatorAndPicker({
                        createObjectOfType: enums.objectType.measure,
                        relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                        sourceLists,
                        simplifyForms: true,
                        // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
                    })

                    pickerPromise.modal.closed.then(() => {
                        resolve()

                        if (!category) {
                            vm.hierItems = vm.mainStructure.childs
                            reorder(vm.hierItems).then(() => {
                                $timeout()
                            })
                        }

                        if (vm.useTabs && category && category === vm.activeTab) {
                            vm.hierItems = vm.activeTab.childs
                            vm.reorder(vm.hierItems)
                        }
                    })
                })
            })
        }

        function addRelativeMeasure(category) {
            return $q((resolve) => {
                const pickerPromise = modal.openCreatorAndPicker({
                    relationTarget: { item: category || vm.mainStructure, kind: enums.subItemsKind.children },
                    objectTypes: [enums.objectType.relativeMeasure],
                    create: false,
                    intersection: {
                        networkId: vm.network.id,
                    },
                    // title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name })
                })

                pickerPromise.modal.closed.then(() => {
                    resolve()

                    if (!category) {
                        vm.hierItems = vm.mainStructure.childs
                        reorder(vm.hierItems).then(() => {
                            $timeout()
                        })
                    }

                    if (vm.useTabs && category && category === vm.activeTab) {
                        vm.hierItems = vm.activeTab.childs
                        vm.reorder(vm.hierItems)
                    }
                })
            })
        }

        function pickAttachedInformation(item, array) {
            modal.openCreatorAndPicker({
                title: $translate.instant('AddInformation'),
                objectTypes: [enums.objectType.orgDocument],
                relationTarget: { item, kind: enums.subItemsKind.relatedContent },
                intersection: { networkId: 1 },
            }).closed(() => {
                array.length = 0
                Array.prototype.push.apply(array, _.orderBy(item.relatedContent, 'childContent.title'))
            })
        }

        function reorder(array, item, direction) {
            const
                arrayLength = array.length

            const moveUp = direction === 'up'

            const moveDown = direction === 'down'

            let currentIndex

            let old_index; let new_index; let k

            const jqDf = $.Deferred()

            const promise = jqDf.promise()

            if (!arrayLength) {
                jqDf.resolve()
                return promise
            }

            // Only reorder if a direction and item is specified
            if (item && typeof direction === 'string') {
                currentIndex = _.indexOf(array, item)

                if ((moveUp && currentIndex === 0) || (moveDown && currentIndex === arrayLength - 1)) {
                    jqDf.resolve()
                    return promise
                }

                // Logic for moving an element in an array to a new position
                old_index = currentIndex
                new_index = moveUp ? currentIndex - 1 : currentIndex + 1

                while (old_index < 0) {
                    old_index += arrayLength
                }
                while (new_index < 0) {
                    new_index += arrayLength
                }
                if (new_index >= arrayLength) {
                    k = new_index - arrayLength
                    while ((k--) + 1) {
                        arr.push(undefined)
                    }
                }

                array.splice(new_index, 0, array.splice(old_index, 1)[0])
            }

            dataOps.saveDataRelationsOrder(array).then(() => {
                jqDf.resolve()
            })

            return promise
        }

        function defineRequirementAndConditionSettings() {
            vm.mainStructureRequirementSettings = {
                uiMode: vm.uiMode,
                forOrganizationId: influenceToOrganizationId,
                requirementOptions: [
                    {
                        name: translate('modules.valueChain.requirements.None'), // "Inget",
                        rule: 0,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AllQuestionsFulfilled'), // "Samtliga frågor måste uppfyllas",
                        rule: 9,
                        value: null,
                    },
                    {
                        name: translate('modules.valueChain.requirements.AllQuestionsAnswered'), // "Alla frågor måste besvaras",
                        rule: 9,
                        value: '0',
                    },
                ],
                onUpdated(updatedRequirement) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.mainStructureSigningSetting = {
                uiMode: vm.uiMode,
                editButtonCaption: translate('Change'),
                settings: [
                    {
                        label: translate('modules.valueChain.objectSettings.showSigning.label'),
                        settingKind: 'showSigning',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.showSigning.show'),
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.showSigning.hide'),
                                value: false,
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.questionRequirementSettings = requirements.getQuestionRequirementSettings(influenceToOrganizationId, forSpecificOrganization)

            vm.questionObjectSettings = valueChainService.getQuestionObjectSettings(vm.isPackageOwned)

            // Determine if editor is opened for an internal requirement package. If it is then show more settings on questions.
            if ($state.current.name === 'valueChainInternalPackageEditor') {
                vm.questionObjectSettings.settings.push({
                    label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label_onQuestion'), // "Information som kan bifogas på frågan",
                    settingKind: 'attachObjectTypes',
                    options: [
                        {
                            whenNotSet: true,
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                            value: '44',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                            value: '18',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CertificateUpload'), // "Uppladdning av certifikat",
                            value: '106',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                            value: '44,18',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                            value: '44,106',
                        },
                        {
                            name: translate('Country'), // "Country",
                            value: '75',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.Finding'), // Finding
                            value: '116',
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FindingCommentAndFileUpload'), // Finding, Comment and File Upload
                            value: '116,44,18',
                        },
                    ],
                })

                vm.questionObjectSettings.settings.push({
                    label: translate('modules.valueChain.objectSettings.pickerSettings.label'), // "Information som kan bifogas på frågan",
                    settingKind: 'pickerSettings',
                    options: [
                        {
                            whenNotSet: true,
                            name: translate('modules.valueChain.objectSettings.pickerSettings.notSet'),
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.pickerSettings.useDropdownAndOnlyCreate'),
                            value: { pick: false, useOpenerButtonDropdown: true },
                            checkValue(value) {
                                return _.isMatch(value, this.value)
                            },
                            setValue() {
                                return this.value
                            },
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.pickerSettings.emphasizeFindingCreationAndThenDropdown'),
                            value: {
                                openerButtons: [
                                    {
                                        pick: false,
                                        objectTypes: [116],
                                        openerButtonMultilingual: { 'sv-SE': 'Lägg till fynd', 'en-US': 'Add finding' },
                                        openerButtonStyleClass: 'btn-danger',
                                    },
                                    {
                                        pick: false,
                                        objectTypes: [44, 18],
                                        openerButtonTranslate: 'Attach',
                                        openerButtonStyleClass: 'btn-default',
                                        useOpenerButtonDropdown: true,
                                    },
                                ],
                            },
                            checkValue(value) {
                                return _.isMatch(value, this.value)
                            },
                            setValue() {
                                return this.value
                            },
                        },
                    ],
                })
            }

            vm.measureObjectSettings = valueChainService.getMeasureObjectSettings(vm.isPackageOwned)

            vm.measureRequirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, vm.uiMode, $scope, { frequency: enums.calendarFrequency.yearly })

            vm.taskStructureRequirementSettings = requirements.getTaskStructureRequirementSettings(influenceToOrganizationId)

            const specialTypeNames = {
                [enums.objectType.certificate]: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CertificateUpload'),
                [enums.objectType.orgDocument]: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'),
                [enums.objectType.structure]: translate('modules.valueChain.objectSettings.pickerSettings.sourceItem.optionInObjectTypesSelector'),
                gics: translate('modules.valueChain.objectSettings.pickerSettings.gics'),
            }

            vm.taskStructureObjectSettings = {
                uiMode: vm.uiMode,
                forOrganization: !vm.isPackageOwned,
                onBeforeSubmit: (model) => {
                    if (_.some(model.items, (item) => {
                        return item.settingKind === 'objectTypes' && item.value === 'gics'
                    })) {
                        model.items = [
                            { settingKind: 'objectTypes', value: '71' },
                            { settingKind: 'dataRelation', value: '' },
                            { settingKind: 'dataRelationByUser', value: false },
                            { settingKind: 'pickerSettings', value: '{"sourceItem":"71-23805","sourceItemFirstLevelAsFilter":true,"openerButtonTranslate":"Select"}' },
                        ]
                    }
                },
                settings: [
                    {
                        label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label'), // "Information som kan bifogas",
                        settingKind: 'objectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'),
                            },
                            enums.objectType.statement,
                            enums.objectType.orgDocument,
                            enums.objectType.certificate,
                            [enums.objectType.statement, enums.objectType.orgDocument],
                            enums.objectType.link,
                            enums.objectType.orgActivity,
                            enums.objectType.country,
                            enums.objectType.organization,
                            enums.objectType.person,
                            enums.objectType.dateItem,
                            enums.objectType.location,
                            enums.objectType.structure,
                            'gics',
                        ].map((x) => {
                            if (typeof x === 'object' && !(x instanceof Array)) {
                                return x
                            }
                            const types = x instanceof Array ? x : [x]

                            return {
                                name: types.map(t => specialTypeNames[t] || wfTranslate.instant('MAP_ObjectType', { type: t, plural: true })).join(` ${$translate.instant('and').toLowerCase()} `),
                                value: types.join(','),
                            }
                        }),
                    },
                    {
                        condition: {
                            key: 'objectTypes',
                            value: [enums.objectType.dateItem],
                            index: 10,
                        },
                        label: translate('modules.valueChain.objectSettings.pickerSettings.dateItemForm.label'), // "DateItem endDate field visibility",
                        settingKind: 'pickerSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.pickerSettings.dateItemForm.singleDate'),
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.pickerSettings.dateItemForm.startAndEndDate'),
                                value: {
                                    customSettingsForObjectTypes: [
                                        {
                                            type: 125,
                                            form: {
                                                requiredFields: [
                                                    'date',
                                                    'endDate',
                                                ],
                                                customSpecification: {
                                                    form: [
                                                        'date',
                                                        'endDate',
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                                checkValue(value) {
                                    return _.isMatch(value, this.value)
                                },
                                setValue() {
                                    return this.value
                                },
                            },
                        ],
                    },
                    {
                        // pickerSettings: source item
                        condition: {
                            key: 'objectTypes',
                            value: [enums.objectType.structure],
                            index: 12,
                        },
                        label: translate('modules.valueChain.objectSettings.pickerSettings.sourceItem.label'),
                        settingKind: 'pickerSettings',
                        customControl: {
                            onInit(form) {
                                if (typeof _.get(form.value, 'sourceItem') === 'string') {
                                    form.loading = true
                                    dataOps.getObject(form.value.sourceItem).then((res) => {
                                        form.selectedStructure = res
                                        form.loading = false
                                        $timeout()
                                    })
                                }

                                form.openPicker = function () {
                                    return $q((resolve, reject) => {
                                        modal.openCreatorAndPicker({
                                            title: $translate.instant('modules.valueChain.objectSettings.pickerSettings.sourceItem.pickerHeader'),
                                            singlePick: true,
                                            relationBucket: {
                                                preSelected: form.selectedStructure ? [form.selectedStructure] : [],
                                                allSelected: form.selectedStructure ? [form.selectedStructure] : [],
                                            },
                                            sourceItem: '71-203',
                                        }).closed((relationBucketResult) => {
                                            if (relationBucketResult.allSelected.length) {
                                                form.selectedStructure = relationBucketResult.allSelected[0]
                                                form.value.sourceItem = form.selectedStructure.wfid
                                                form.onChanged(form.value)
                                            }
                                        })
                                        resolve()
                                    })
                                }
                            },
                            template: '<div ng-init="form.onInit(form)" class="well well-sm well-hollow clearfix mb20" ng-class="{ \'loader-small\': form.loading }" style="min-height:62px;padding-bottom:3px"><div ng-show="form.selectedStructure" ng-bind="form.selectedStructure.title" class="mr10 mb10 pull-left" style="height:34px; cursor: default; border-left: 3px solid #85c1e9; border-radius:3px; padding:9px 16px 10px 13px; max-width: 100%; white-space: normal;box-shadow:inset 0 0px 0px 1px rgba(0,0,0,0.08);font-weight:500"></div><div wf-click="form.openPicker()" class="btn btn-default pull-left mb10" ng-show="!form.loading"><i class="fa fa-cog pr10"></i><span ng-bind="(form.selectedStructure ? \'Change\' : \'Choose\') | translate"></span></div></div>',
                        },
                    },
                    {
                        // pickerSettings: single/multi pick
                        condition: {
                            key: 'objectTypes',
                            value: [enums.objectType.structure],
                            index: 12,
                        },
                        label: translate('modules.valueChain.objectSettings.pickerSettings.singlePick.label'),
                        settingKind: 'pickerSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.pickerSettings.singlePick.value.false'),
                                value: 'singlePick',
                                checkValue(value) {
                                    return !value || !(this.value in value)
                                },
                                setValue(value) {
                                    if (value) delete value[this.value]
                                    return value
                                },
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.pickerSettings.singlePick.value.true'),
                                value: { singlePick: true },
                                checkValue(value) {
                                    return _.isMatch(value, this.value)
                                },
                                setValue(value) {
                                    return _.assign(value, this.value)
                                },
                            },
                        ],
                    },
                    {
                        // pickerSettings: sourceItemFirstLevelAsFilter
                        condition: {
                            key: 'objectTypes',
                            value: [enums.objectType.structure],
                            index: 12,
                        },
                        label: translate('modules.valueChain.objectSettings.pickerSettings.firstLevelAsFilter.label'),
                        settingKind: 'pickerSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.pickerSettings.firstLevelAsFilter.value.false'),
                                value: 'sourceItemFirstLevelAsFilter',
                                hideInPreview: true,
                                checkValue(value) {
                                    return !value || !(this.value in value)
                                },
                                setValue(value) {
                                    if (value) delete value[this.value]
                                    return value
                                },
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.pickerSettings.firstLevelAsFilter.value.true'),
                                value: { sourceItemFirstLevelAsFilter: true },
                                checkValue(value) {
                                    return _.isMatch(value, this.value)
                                },
                                setValue(value) {
                                    return _.assign(value, this.value)
                                },
                            },
                        ],
                    },
                    {
                        condition: {
                            key: 'objectTypes',
                            value: [enums.objectType.structure],
                            index: 12,
                        },
                        label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label_nested'),
                        settingKind: 'subItemsSettings',
                        options: [
                            {
                                // whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'),
                                hideInPreview: true,
                                checkValue(value) {
                                    return !value || !_.get(value, 'pickerSettings.objectTypes')
                                },
                                setValue(value) {
                                    if (value) {
                                        delete value.pickerSettings
                                    }
                                    return value
                                },
                            },
                            enums.objectType.statement,
                            enums.objectType.orgDocument,
                            enums.objectType.certificate,
                            [enums.objectType.statement, enums.objectType.orgDocument],
                            enums.objectType.link,
                            enums.objectType.orgActivity,
                            enums.objectType.country,
                            // enums.objectType.organization,
                            enums.objectType.person,
                            enums.objectType.dateItem,
                            enums.objectType.location,
                            // enums.objectType.structure,
                        ].map((x) => {
                            if (typeof x === 'object' && !(x instanceof Array)) {
                                return x
                            }
                            const types = x instanceof Array ? x : [x]

                            return {
                                name: types.map(t => specialTypeNames[t] || wfTranslate.instant('MAP_ObjectType', { type: t, plural: true })).join(` ${$translate.instant('and').toLowerCase()} `),
                                checkValue(value) {
                                    return _.isMatch(types, _.get(value, 'pickerSettings.objectTypes'))
                                },
                                setValue(value) {
                                    return  _.defaultsDeep({}, { pickerSettings: { objectTypes: types } }, { pickerSettings: { objectTypes: null } }, value)

                                },
                            }
                        }),
                    },
                    // {
                    // 	condition: {
                    // 		key: "objectTypes",
                    // 		value: [ enums.objectType.organization ],
                    // 		index: 7
                    // 	},
                    // 	label: translate("modules.valueChain.objectSettings.informationThatCanBeAdded.label_nested"),
                    // 	settingKind: "subItemsSettings",
                    // 	options: [
                    // 		{
                    // 			name: translate("modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation"),
                    // 			hideInPreview: true,
                    // 			checkValue: function (value) {
                    // 				return !value || !_.get(value, "pickerSettings.objectTypes")
                    // 			},
                    // 			setValue: function (value) {
                    // 				if (value) {
                    // 					delete value.pickerSettings;
                    // 				}
                    // 				return value;
                    // 			}
                    // 		},
                    // 		// enums.objectType.statement,
                    // 		// enums.objectType.orgDocument,
                    // 		// enums.objectType.certificate,
                    // 		// [ enums.objectType.statement, enums.objectType.orgDocument ],
                    // 		// enums.objectType.orgActivity,
                    // 		// enums.objectType.country,
                    // 		// enums.objectType.organization,
                    // 		enums.objectType.person,
                    // 		// enums.objectType.dateItem,
                    // 		// enums.objectType.location,
                    // 		// enums.objectType.structure,
                    // 	].map(x => {
                    // 		if (typeof x === "object" && !(x instanceof Array)) {
                    // 			return x;
                    // 		}
                    // 		const types = x instanceof Array ? x : [ x ]

                    // 		return {
                    // 			name: types.map(t => specialTypeNames[t] || wfTranslate.instant('MAP_ObjectType', { type: t, plural: true })).join(` ${$translate.instant("and").toLowerCase()} `),
                    // 			checkValue: function (value) {
                    // 				return _.isMatch(_.get(value, "pickerSettings.objectTypes"), types);
                    // 			},
                    // 			setValue: function (value) {
                    // 				return  _.defaultsDeep({}, { "pickerSettings": { "objectTypes": types } }, { "pickerSettings": { "objectTypes": null } }, value);

                    // 			}
                    // 		};
                    // 	})
                    // },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            // Normally these settings are stored on the structure.
            // When the opened hierarchy is loaded via server intersection these settings must instead be stored on the originalRelation on the virtualDataRelation.
            vm.structureObjectSettings_forDataRelation = {
                uiMode: vm.uiMode,
                settings: [
                    {
                        label: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.label'), // "Information som kan bifogas",
                        settingKind: 'attachObjectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                                value: '44',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                                value: '18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                                value: '44,18',
                            },
                            {
                                name: translate('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                                value: '44,106',
                            },
                            {
                                name: translate('Link'),
                                value: '50',
                            },
                            {
                                name: translate('Activities'),
                                value: '15',
                            },
                            {
                                name: translate('Country'),
                                value: '75',
                            },
                            {
                                name: translate('Organizations'),
                                value: '101',
                            },
                            {
                                name: translate('People'),
                                value: '112',
                            },
                            {
                                name: translate('Date'),
                                value: '125',
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            vm.parameterRequirementSettings = requirements.getParameterSettings(influenceToOrganizationId, vm.uiMode, $scope)
        }

        // Controls the requirementPackageSettings.targetContextParentType condition on the mainStructure
        // Needs manual setup to work - the requirement package where organizations add their production sites.
        // After that, this condition control which requirement packages should target organizations (normal mode) or production sites.
        // When an influence is created for a production site, the contextParentWfids prop on the influence will be set accordingly.
        // Support two options:
        // - Intended for organizations (normal mode when no option is selected - contextParentWfids prop on influence will not be set)
        // - Intended for production sites (type depends on targetContextParentType but only production sites are implemented - contextParentWfids prop on influence will be set to the production site's wfid)
        function setReportingContextCondition() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.targetContextParent'),
                    settingKind: 'requirementPackageSettings',
                    options: (function () {
                        let output = [
                            {
                                name: wfTranslate.instant('MAP_ObjectType', { type: enums.objectType.organization, plural: true }),
                                checkValue(value) {
                                    return !value || !value.targetContextParentType
                                },
                                setValue(value) {
                                    if (value) {
                                        delete value.targetContextParentType
                                        delete value.targetContextParentWordingType
                                    }
                                    return value
                                },
                            },
                        ]

                        output = _.concat(output, _.map(vm.network.settings.valueChainSettings.targetContextParentTypes, (objectType) => {
                            let
                                spec

                            let wordingType

                            if (objectType === enums.objectType.organization) {
                                spec = {
                                    checkValue(value) {
                                        return value && value.targetContextParentType === objectType
                                    },
                                    setValue(value) {
                                        if (!value) value = {}

                                        value.targetContextParentType = objectType
                                        value.targetContextParentWordingType = enums.objectType.holding
                                        delete value.targetContextParentWfid
                                        return value
                                    },
                                    // TODO: implement -> replace: true
                                }
                                wordingType = enums.objectType.holding
                            }
                            else {
                                spec = {
                                    checkValue(value) {
                                        return value && value.targetContextParentType === objectType
                                    },
                                    setValue(value) {
                                        if (!value) value = {}

                                        value.targetContextParentType = objectType
                                        delete value.targetContextParentWordingType
                                        delete value.targetContextParentWfid
                                        return value
                                    },
                                }
                                wordingType = objectType
                            }

                            spec.name = wfTranslate.instant('MAP_ObjectType', { type: wordingType, plural: true })

                            return spec
                        }))

                        return output
                    })(),
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        // Controls the requirementPackageSettings.targetContextParentWfid condition on the mainStructure.
        // When an influence is created, the contextParentWfids prop on the influence will be set according to this condition.
        // Supports two options:
        // - Report for themselves (normal mode when no option is selected - contextParentWfids prop on influence will not be set)
        // - Report for <current VC owner> (contextParentWfids prop on influence will be set to the value of targetContextParentWfid)
        function setReportingContextCondition_reportFor() {
            const
                orgWfid = vm.authOrg.wfid

            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.targetContextParent_valueChainOrg.label'),
                    settingKind: 'requirementPackageSettings',
                    options: (function () {
                        let output = [
                            {
                                name: translate('modules.valueChain.packageEditor.targetContextParent_valueChainOrg.notSet'),
                                checkValue(value) {
                                    return !value || !value.targetContextParentWfid
                                },
                                setValue(value) {
                                    if (value) delete value.targetContextParentWfid

                                    return value
                                },
                            },
                        ]

                        output = _.concat(output, _.map(vm.network.settings.valueChainSettings.targetContextParentWfids, (objectType) => {
                            return {
                                name: vm.authOrg.name,
                                checkValue(value) {
                                    return value && value.targetContextParentWfid === orgWfid
                                },
                                setValue(value) {
                                    if (!value) value = {}

                                    value.targetContextParentWfid = orgWfid
                                    delete value.targetContextParentType
                                    delete value.targetContextParentWordingType

                                    return value
                                },
                            }
                        }))

                        return output
                    })(),
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        function setRequirementTextsDisplayCondition() {
            const
                orgWfid = vm.authOrg.wfid

            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.requirementTexts.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.requirementTexts.show'),
                            checkValue(value) {
                                return !value || !value.hideRequirementTexts
                            },
                            setValue(value) {
                                if (value) delete value.hideRequirementTexts

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.requirementTexts.hide'),
                            checkValue(value) {
                                return value && value.hideRequirementTexts
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.hideRequirementTexts = true
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        function setCsvExportCondition() {
            const
                orgWfid = vm.authOrg.wfid

            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.csvExport.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.csvExport.disabled'),
                            checkValue(value) {
                                return !value || !value.showCsvExportForReporter
                            },
                            setValue(value) {
                                if (value) delete value.showCsvExportForReporter

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.csvExport.enabled'),
                            checkValue(value) {
                                return value && value.showCsvExportForReporter
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.showCsvExportForReporter = true
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        function setReportingUiCondition() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.reportingUi.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportingUi.useOld'),
                            checkValue(value) {
                                return !value || !value.useNewReportingUi
                            },
                            setValue(value) {
                                if (value) delete value.useNewReportingUi

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportingUi.useNew'),
                            checkValue(value) {
                                return value && value.useNewReportingUi
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.useNewReportingUi = true
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        function setReportedDataDeletionSetting() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.reportedDataDeletion.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportedDataDeletion.enabled'),
                            checkValue(value) {
                                return !value || (!value.disableMeasureAnswersDeletion && !value.disableQuestionAnswersDeletion)
                            },
                            setValue(value) {
                                if (value) {
                                    delete value.disableMeasureAnswersDeletion
                                    delete value.disableQuestionAnswersDeletion
                                }

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportedDataDeletion.disabledForMeasureAnswers'),
                            checkValue(value) {
                                return value && value.disableMeasureAnswersDeletion && !value.disableQuestionAnswersDeletion
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.disableMeasureAnswersDeletion = true
                                delete value.disableQuestionAnswersDeletion
                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportedDataDeletion.disabledForQuestionAnswers'),
                            checkValue(value) {
                                return value && !value.disableMeasureAnswersDeletion && value.disableQuestionAnswersDeletion
                            },
                            setValue(value) {
                                if (!value) value = {}

                                delete value.disableMeasureAnswersDeletion
                                value.disableQuestionAnswersDeletion = true
                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.reportedDataDeletion.disabledForBoth'),
                            checkValue(value) {
                                return value && value.disableMeasureAnswersDeletion && value.disableQuestionAnswersDeletion
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.disableMeasureAnswersDeletion = true
                                value.disableQuestionAnswersDeletion = true
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
                forOrganization: !vm.isPackageOwned,
            })
        }

        function setItemNumberingSetting() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.itemNumbering.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.itemNumbering.show'),
                            checkValue(value) {
                                return !value || value.showItemNumbering !== false
                            },
                            setValue(value) {
                                if (value) delete value.showItemNumbering

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.itemNumbering.hide'),
                            checkValue(value) {
                                return value && value.showItemNumbering === false
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.showItemNumbering = false
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            })
        }

        function setSigningSetting() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.objectSettings.showSigning.label'),
                    settingKind: 'showSigning',
                    options: [
                        {
                            name: translate('modules.valueChain.objectSettings.showSigning.show'),
                            checkValue(value) {
                                return value == null || value
                            },
                            setValue(value) {
                                return true
                            },
                        },
                        {
                            name: translate('modules.valueChain.objectSettings.showSigning.hide'),
                            value: false,
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
                forOrganization: !vm.isPackageOwned,
            })
        }

        function setTabsCondition() {
            const objectSettings = [
                {
                    label: translate('modules.valueChain.packageEditor.packageSettings.tabs.label'),
                    settingKind: 'requirementPackageSettings',
                    options: [
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.tabs.disabled'),
                            checkValue(value) {
                                return !value || !value.useTabs
                            },
                            setValue(value) {
                                if (value) delete value.useTabs

                                return value
                            },
                        },
                        {
                            name: translate('modules.valueChain.packageEditor.packageSettings.tabs.enabled'),
                            checkValue(value) {
                                return value && value.useTabs
                            },
                            setValue(value) {
                                if (!value) value = {}

                                value.useTabs = true
                                return value
                            },
                        },
                    ],
                },
            ]

            modal.editFormattedObjectSettings({
                item: vm.mainStructure,
                objectSettings,
            }).then(() => {
                vm.useTabs = _.get(vm.mainStructure, 'conditions.requirementPackageSettings.useTabs')
                vm.loaded = false
                vm.activeTab = undefined
                vm.hierItemsNumbering = undefined
                vm.hierItems = undefined

                $timeout(() => {
                    vm.childs = vm.mainStructure.childs
                    if (vm.useTabs) {
                        if (vm.childs.length) {
                            vm.activeTab = vm.childs[0].childContent
                            vm.hierItemsNumbering = 1
                            vm.hierItems = vm.activeTab.childs
                        }
                    }
                    else {
                        vm.hierItems = vm.childs
                    }
                    vm.loaded = true
                })
            })
        }

        function loadPickerSourceLists(objectType) {
            let
                packageIds

            let itemComposites

            const structureIdsByObjectType = _.fromPairs([
                [enums.objectType.question, 12099],
                [enums.objectType.measure, 12100],
            ])

            let sourceLists = []

            return $q((resolve) => {
                valueChainService.loadPackagesInNetwork(networkId).then((packageRelations) => {
                    packageIds = _.map(packageRelations, 'childId')

                    dataOps.getSubItems({
                        type: enums.objectType.structure,
                        id: structureIdsByObjectType[objectType],
                    }, enums.subItemsKind.childrenByUser).then(() => {
                        const items = wfObject.filter({ where: { type: 81, parentType: 71, parentId: structureIdsByObjectType[objectType], parentData1: null } })
                        itemComposites = dataQuery.makeItemComposites(items)

                        groupItemsByPackage(itemComposites).then((parentBranchObjects) => {
                            sourceLists = _.chain(parentBranchObjects).map((branchObj) => {
                                return {
                                    title: branchObj.parentTitle,
                                    items: branchObj.leafComposites,
                                    putNewItemsHere: branchObj.currentParent.wfid === vm.mainStructure.wfid,
                                }
                            }).sortBy('title').value()

                            sourceLists.unshift({
                                id: 'all',
                                title: $translate.instant('All'),
                                items: itemComposites,
                                putNewItemsHere: true,
                            })

                            resolve(sourceLists)
                        })

                    })
                })
            })

            function groupItemsByPackage(itemComposite) {
                const
                    maxIterationDepth = 5

                let parentBranchObjects = []
                // Gets reset recursively and finaly passed when resolving

                let parentBranchObjectsByParentWfid // Gets reset recursively

                parentBranchObjects = _.map(itemComposites, (itemComposite) => {
                    return {
                        parentTitle: itemComposite.content.getMainTextual(),
                        leafComposites: [itemComposite],
                        currentParent: itemComposite.content,
                        wffid: itemComposite.content.wfid,
                        level: 0,
                        isPackage: false,
                    }
                })

                return $q((resolve) => {

                    loadParentsRecursively(0)

                    function loadParentsRecursively(level) {
                        let
                            newParents

                        const newParentBranchObjects = []

                        const parentBranchObjectsToLoadParentsOn = _.filter(parentBranchObjects, { isPackage: false })

                        parentBranchObjectsByParentWfid = _.keyBy(parentBranchObjects, 'wffid')

                        level++

                        dataOps.getSubItemsOfAll(_.map(parentBranchObjectsToLoadParentsOn, 'currentParent'), enums.subItemsKind.parents, { useOrganizationMatchModeFromKind: true }).then(() => {
                            // Concatenate all parents arrays and filter/reject, then get the unique parentContent items.
                            newParents = _.chain(_.concat.apply(null, _.map(parentBranchObjects, 'currentParent.parents')))
                                .compact() // First item is always undefined
                                .filter({ parentType: enums.objectType.structure, organizationId: null })
                                .reject({ parentId: valueChainService.ids.packagesStructure })
                                .uniqBy('wffid')
                                .map('parentContent')
                            // .filter({ ancestorId: undefined })
                                .value()

                            // For easier debugging
                            // var formattedParents = _.chain(newParents).map(function (item) {
                            // 	return {
                            // 		title: item.getMainTextual(),
                            // 		wfid: item.wfid,
                            // 		content: item,
                            // 		childs: _.map(item.childs, "childContent")
                            // 	}
                            // }).sortBy("title").value();

                            _.each(newParents, (newParent) => {
                                let newLeafComposites = []; const oldLeafCompositeArrays = []

                                // The newly loaded parents might also have other children that we don't care about, so intersect with the items that we loaded the parents on.
                                const validChildrenRelations = _.intersectionWith(newParent.childs, parentBranchObjects, (childRelation, branchObject) => {
                                    return childRelation.wfcid === branchObject.wffid
                                })

                                // Put all the leafComposite items from the previous iteration into oldLeafCompositeArrays.
                                // Basically grouping them by the newly loaded parent instead of their old parents.
                                _.each(validChildrenRelations, (validChildRelation) => {
                                    oldLeafCompositeArrays.push(parentBranchObjectsByParentWfid[validChildRelation.wfcid].leafComposites)
                                })

                                // Combine the old arrays into a single new one
                                newLeafComposites = _.concat.apply(null, oldLeafCompositeArrays)

                                // If this parent were loaded in a previous iteration that update that parentBranchObject instead of adding a new one
                                if (parentBranchObjectsByParentWfid[newParent.wfid]) { // If the newly loaded parent is already a parent from a previous iteration
                                    _.assign(parentBranchObjectsByParentWfid[newParent.wfid], {
                                        leafComposites: _.chain(parentBranchObjectsByParentWfid[newParent.wfid].leafComposites).concat(newLeafComposites).uniq().value(),
                                        level,
                                    })
                                }
                                else {
                                    // Add a new parentBranchObject
                                    newParentBranchObjects.push({
                                        parentTitle: newParent.getMainTextual(),
                                        leafComposites: _.uniq(newLeafComposites),
                                        currentParent: newParent,
                                        wffid: newParent.wfid,
                                        level,
                                        isPackage: _.includes(packageIds, parseInt(newParent.id)), // Set to true if the parent is a requirement package structure
                                    })
                                }
                            })

                            // Remove previously loaded parents that is not a package
                            _.remove(parentBranchObjects, { isPackage: false })

                            // Combine previously loaded parents with the newly loaded ones
                            parentBranchObjects = _.concat(parentBranchObjects, newParentBranchObjects)

                            if (level === maxIterationDepth) { // If the level is to high (max iteration depth)
                                // Remove parents that might be left to load and resolve
                                _.remove(parentBranchObjects, { isPackage: false })
                                resolve(parentBranchObjects)
                            }
                            else if (_.every(parentBranchObjects, { isPackage: true })) // If all items have been grouped by package
                                resolve(parentBranchObjects)
                            else loadParentsRecursively(level)
                        })
                    }
                })
            }
        }

        function getTabDropdownActions(item) {
            if (item.childContent.creatorOrganizationId === authOrgId) {
                vm.dropdownActions = 'update,deleteTag'
            }
            else {
                vm.dropdownActions = 'deleteTag'
            }
        }

        function bulkChangeSettings(objectType) {
            let initialSettings = undefined

            const definition = {
                [enums.objectType.question]: vm.questionObjectSettings,
                [enums.objectType.measure]: vm.measureObjectSettings,
            }[objectType]

            const objectTypeItems = dataQuery.getHierarchyAsList(vm.mainStructure, null, { filter(item) {
                if (objectType === enums.objectType.structure) {
                    return item.type === objectType && (_.get(item.content, 'conditions.pickerSettings') || _.get(item.content, 'conditions.objectTypes'))
                }
                else {
                    return item.type === objectType
                }
            } })

            if (objectType === enums.objectType.measure && objectTypeItems.length) {
                const firstMeasurePeriodSettings = objectTypeItems[0].dataRelation.settings && objectTypeItems[0].dataRelation.settings.measurePeriodSettings || { frequency: 1 }
                initialSettings = { measurePeriodSettings: { frequency: firstMeasurePeriodSettings.frequency, range: firstMeasurePeriodSettings.range } }
            }

            modal.editFormattedObjectSettings({
                showSettingToggles: true,
                item: { type: objectType === enums.objectType.structure ? objectType : enums.objectType.dataRelation, id: null, settings: initialSettings },
                objectSettings: definition.settings,
                onBeforeSubmit: definition.onBeforeSubmit,
                action(model) {
                    return $q((resolve, reject) => {
                        if (!objectTypeItems.length) {
                            resolve()
                            return
                        }

                        if (!Object.values(model.settingsToggledState).some(val => val)) {
                            resolve()
                            return
                        }

                        const settingValuesToJoin = ['objectTypes', 'limitQuestionAnswerTypes', 'contextParentWfids', 'attachObjectTypes']
                        const newSettingsArray = model.items
                        const newSettingsKeys = []
                        const newSettings = {}
                        newSettingsArray.forEach((x) => {
                            newSettings[x.settingKind] = x.value
                            newSettingsKeys.push(x.settingKind)
                        })

                        const settingsAccessor = objectType === enums.objectType.structure
                            ? x => x.content.conditions
                            : x => x.dataRelation.settings

                        const resolveSettingValueForSaving = (key, val) => {
                            if (val instanceof Array && settingValuesToJoin.includes(key)) {
                                return val.join(',')
                            }
                            else if (typeof val === 'object') {
                                return JSON.stringify(val)
                            }

                            return val
                        }

                        const settingsToSave = []

                        objectTypeItems.forEach((itemComposite) => {
                            const currentSettings = settingsAccessor(itemComposite)
                            const currentSettingsKeys = currentSettings ? Object.keys(currentSettings) : []
                            const currentSettingsArray = currentSettingsKeys.map(key => ({ settingKind: key, value: resolveSettingValueForSaving(key, currentSettings[key]) }))
                            const updatedSettingsArrayToSave = []

                            currentSettingsKeys.forEach((key) => {
                                let newSettingValue

                                if (newSettings[key]) {
                                    if (typeof currentSettings[key] === 'object' && !(currentSettings[key] instanceof Array)) {
                                        const newSettingValueObject = JSON.parse(newSettings[key])
                                        const mergedSettingValueObject = { ...currentSettings[key], ...newSettingValueObject }
                                        newSettingValue = JSON.stringify(mergedSettingValueObject)
                                    }
                                    else {
                                        newSettingValue = newSettings[key]
                                    }
                                }
                                else {
                                    // If the checkbox was toggled for this setting but the setting is not in the newSettings object it means that the setting
                                    // should be excluded from the updatedSettingsArrayToSave and removed in db
                                    if (!model.settingsToggledState[key]) {
                                        newSettingValue = resolveSettingValueForSaving(key, currentSettings[key])
                                    }
                                }

                                if (newSettingValue) {
                                    updatedSettingsArrayToSave.push({ settingKind: key, value: newSettingValue })
                                }
                            })

                            const difference = newSettingsKeys.filter(x => !currentSettingsKeys.includes(x))
                            if (difference.length) {
                                difference.forEach(addedSettingKey => updatedSettingsArrayToSave.push({ settingKind: addedSettingKey, value: newSettings[addedSettingKey] }))
                            }

                            const settingsModalToSave = {}
                            settingsModalToSave.type = settingsModalToSave.objectType = (objectType === enums.objectType.structure ? objectType : enums.objectType.dataRelation)
                            settingsModalToSave.id = settingsModalToSave.objectId = (objectType === enums.objectType.structure ? itemComposite.id : itemComposite.dataRelation.id)
                            settingsModalToSave.items = updatedSettingsArrayToSave
                            settingsModalToSave.forOrganization = !vm.isPackageOwned

                            settingsToSave.push(settingsModalToSave)
                        })

                        const progressModalScope = $rootScope.$new()
                        progressModalScope.progressWidth = 0
                        progressModalScope.loading = true
                        progressModalScope.reload = () => location.reload()
                        progressModalScope.savingSettings = true

                        const openedModal = modal.open({
                            templateUrl: 'scripts/wf/valueChain/popovers/batchObjectChangeProgress.template.html',
                            scope: progressModalScope,
                            windowClass: 'modal-width-500',
                            backdrop: 'static',
                            keyboard: false,
                            onLoaded($scope, $element) {

                            },
                        })

                        const saveObjectSettings = (settingsObject) => {
							 apiProxy('multi.savesettings', dataOps.prepareWfObject(settingsObject)).then((res) => {
                                progressModalScope.progressWidth = ((totalCount - settingsToSave.length) / totalCount) * 100
                                $timeout()

                                if (settingsToSave.length) {
                                    saveObjectSettings(settingsToSave.shift())
                                }
                                else {
                                    setTimeout(() => {
                                        progressModalScope.loading = false
                                        $timeout()
                                    }, 300)
                                }
							 }).catch((e) => {
							 	progressModalScope.loading = false
							 	progressModalScope.error = true
							 })
                        }

                        const totalCount = settingsToSave.length

                        saveObjectSettings(settingsToSave.shift())

                        resolve()
                    })
                },
            }).then(() => {

            })
        }

        function bulkChangeRequirements(objectType) {
            let requirementSettings = {
                [enums.objectType.question]: vm.questionRequirementSettings.requirementOptions,
                [enums.objectType.measure]: vm.measureRequirementSettings.requirementOptions,
                [enums.objectType.structure]: vm.taskStructureRequirementSettings.requirementOptions,
            }[objectType]

            const objectTypeItems = dataQuery.getHierarchyAsList(vm.mainStructure, null, { filter(item) {
                if (objectType === enums.objectType.structure) {
                    return item.type === objectType && (_.get(item.content, 'conditions.pickerSettings') || _.get(item.content, 'conditions.objectTypes'))
                }
                else {
                    return item.type === objectType
                }
            } })

            if (objectType === enums.objectType.measure && objectTypeItems.length) {
                const firstMeasurePeriodSettings = objectTypeItems[0].dataRelation.settings && objectTypeItems[0].dataRelation.settings.measurePeriodSettings || { frequency: 1 }
                if (!objectTypeItems.every(x => (x.dataRelation.settings && x.dataRelation.settings.measurePeriodSettings || { frequency: 1 }).frequency === firstMeasurePeriodSettings.frequency)) {
                    modal.alert({
                        title: $translate.instant('modules.valueChain.batchObjectChange.measureFrequencyMismatch.title'),
                        message: $translate.instant('modules.valueChain.batchObjectChange.measureFrequencyMismatch.message'),
                    })
                    return
                }

                requirementSettings = requirements.getMeasureSettingsFromPeriodSettings(null, null, null, firstMeasurePeriodSettings).requirementOptions
            }

            const selectorOptions = _.filter(requirementSettings, (item) => {
                return item.selectable !== false
            })

            modal.editRequirement({
                showSettingToggles: true,
                item: { type: enums.objectType.dataRelation, childType: objectType, id: null  },
                selectorOptions,
                action(model) {
                    return $q((resolve, reject) => {
                        if (!objectTypeItems.length) {
                            resolve()
                            return
                        }

                        if (!Object.values(model.settingsToggledState).some(val => val)) {
                            resolve()
                            return
                        }

                        const requirementsToSave = []

                        objectTypeItems.forEach((itemComposite) => {
                            const existingRequirement = itemComposite.dataRelation.getRequirementSpecification().standard
                            let requirementToSave

                            if (existingRequirement) {
                                if (model.settingsToggledState.optionIndex) {
                                    existingRequirement.rule = model.rule
                                    existingRequirement.value = model.value
                                }
                                if (model.settingsToggledState.comment) {
                                    existingRequirement.comment = model.comment
                                }
                                if (model.settingsToggledState.presetMaxAge || model.settingsToggledState.customMaxAgeInDays) {
                                    existingRequirement.maxAgeInDays = model.maxAgeInDays
                                }

                                requirementsToSave.push(existingRequirement)
                            }
                            else {
                                const newRequirement = { ...model }
                                delete newRequirement.settingsToggledState

                                newRequirement.objectType = enums.objectType.dataRelation
                                newRequirement.objectId = itemComposite.dataRelation.id
                                newRequirement.derivedType = itemComposite.dataRelation.childType
                                newRequirement.derivedId = itemComposite.dataRelation.childId

                                requirementsToSave.push(newRequirement)
                            }
                        })

                        const progressModalScope = $rootScope.$new()
                        progressModalScope.progressWidth = 0
                        progressModalScope.loading = true
                        progressModalScope.reload = () => location.reload()
                        progressModalScope.savingRequirements = true

                        const openedModal = modal.open({
                            templateUrl: 'scripts/wf/valueChain/popovers/batchObjectChangeProgress.template.html',
                            scope: progressModalScope,
                            windowClass: 'modal-width-500',
                            backdrop: 'static',
                            keyboard: false,
                            onLoaded($scope, $element) {

                            },
                        })

                        const saveRequirement = (requirementObject) => {
                            apiProxy('multi.saveRequirements', dataOps.prepareWfObject(requirementObject)).then((res) => {
                                progressModalScope.progressWidth = ((totalCount - requirementsToSave.length) / totalCount) * 100
                                $timeout()

                                if (requirementsToSave.length) {
                                    saveRequirement(requirementsToSave.shift())
                                }
                                else {
                                    setTimeout(() => {
                                        progressModalScope.loading = false
                                        $timeout()
                                    }, 300)
                                }
                            }).catch((e) => {
							 	progressModalScope.loading = false
							 	progressModalScope.error = true
                            })
                        }

                        const totalCount = requirementsToSave.length

                        saveRequirement(requirementsToSave.shift())

                        // resolve()
                    })
                },
            }).then(() => {

            })
        }

    }
})()
