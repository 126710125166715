import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export function useWidth() {
    const theme = useTheme()
    const width =
    [...theme.breakpoints.keys].reverse().reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.only(key))

        return !output && matches ? key : output
    }, null) || 'xs'
    return width
}

export function useMenuState(initialState) {
    const [anchorEl, setAnchorEl] = useState(initialState)

    function open(e) {
        setAnchorEl(e.currentTarget)
    }

    function close() {
        setAnchorEl(null)
    }

    return [anchorEl, open, close]
}
