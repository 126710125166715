import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('TransferController', TransferController)

    TransferController.$inject = ['$scope', 'dataOperationsService', 'wfObject', '$q', 'WfFile', 'importExportService', 'modalService', '$uibModal', 'RelationMapper', 'wfAuth', '$interpolate', '$sanitize', '$timeout']
    function TransferController($scope, dataOps, wfObject, $q, WfFile, importExportService, modalService, $uibModal, RelationMapper, wfAuth, $interpolate, $sanitize, $timeout) {
        const vm = this

        _.assign(vm, {
            //Variables
            exportConfig: {
                exporting: false,
                readyForExport: false,
                informationRepository: {
                    include: true,
                    objectTypes: getObjectTypes(true, true),
                    toggleSwitch,
                },
                otherRelations: {
                    include: false,
                    relationItems: undefined,
                },
                checkInputTypeToggle,
                refreshCounts,
                loadRelationsForAllObjectTypes,
                resetConfig() {
                    this.exporting = false
                    this.refreshCounts()
                },
            },
            importConfig: {
                wfFilePreview: undefined,
                loadingPreview: false,
                importing: false,
                importSucceded: undefined,

                informationRepository: {
                    include: true,
                    objectTypes: getObjectTypes(false),
                    toggleSwitch,
                },
                otherRelations: {
                    include: false,
                    relationItems: undefined,
                },
                resetConfig() {
                    this.informationRepository.objectTypes = getObjectTypes(false, false)
                    _.assign(this, {
                        wfFilePreview: undefined,
                        loadingPreview: false,
                        importing: false,
                        importSucceded: undefined,
                    })
                },
            },
            deleteConfig: {
                //Variables
                organization: {},
                orgNameVarification: undefined,
                anyTypeSelected: false,
                countsLoaded: false,
                confirmed: false,
                allowToDelete: false,
                deleting: false,

                informationRepository: {
                    include: false,
                    objectTypes: getObjectTypes(false, false),
                    toggleSwitch,
                },

                //Functions
                checkInputTypeToggle,
                checkOrgNameInput,
                refreshCounts,
                loadRelationsForAllObjectTypes,
                deleteData,
                resetConfig() {
                    const config = this
                    _.assign(this, {
                        organization: wfAuth.getOrganization(),
                        orgNameVarification: undefined,
                        anyTypeSelected: false,
                        countsLoaded: false,
                        confirmed: false,
                        allowToDelete: false,
                        deleting: false,
                    })

                    _.each(config.informationRepository.objectTypes, (objectType) => {
                        objectType.status = undefined
                    })
					
                    config.informationRepository.include = false
                    config.informationRepository.toggleSwitch()

                    config.refreshCounts().then(() => {
                        config.countsLoaded = true
                    })

                    $timeout()
                },
            },

            //Functions
            exportData,
            importData,
            previewWfFile,
            isObject,
            openDeleteModal,
        })

        //------------ EXPORT FUNCTIONS ---------------
		
        function exportData() {
            vm.exportConfig.checkInputTypeToggle()
            if (vm.exportConfig.readyForExport && vm.exportConfig.anyTypeSelected) {
                const objectTypes = vm.exportConfig.informationRepository.objectTypes
                const	wfFile = new WfFile()
                vm.exportConfig.exporting = true
	
                _.each(objectTypes, (objectType) => {
                    let items = []; let relations = []

                    if (objectType.includeRelations) relations = objectType.relations
                    if (objectType.includeItems) items = objectType.items
					
                    wfFile.saveToInformationRepository(objectType.key, items, relations)
                })
				
                //Other Relations
                if (vm.exportConfig.otherRelations.include) {
                    wfFile.saveRelations(vm.exportConfig.otherRelations.relationItems)
                }

                wfFile.download()
                vm.exportConfig.exporting = false
            }
            else {
                modalService.alert('Nothing to export, please select an item or other relations to export')
            }
        }

        function removeInvalidRelations(relations) {
            const reduced = _.reject(relations, (rel) => {
                if (rel.parentId === 10010) return true
            })
            return reduced
        }

        //------------ IMPORT FUNCTIONS ---------------

        function previewWfFile(file) {
            let infoRepository
            let verified
            vm.importConfig.resetConfig()
            vm.loadingPreview = true

            importExportService.extractJSONFromFile(file).then((data) => {
                if (typeof data === 'object') {
                    vm.wfFilePreview = new WfFile(data)
                    verified = vm.wfFilePreview.verify()
                    if (verified === true) {
                        infoRepository = vm.wfFilePreview.data.informationRepository
                        _.each(vm.importConfig.informationRepository.objectTypes, (objectType) => {
                            if (infoRepository[objectType.key] && infoRepository[objectType.key].items.length >= 1) {
                                objectType.includeItems = objectType.exists = true
                                objectType.items = vm.wfFilePreview.data.informationRepository[objectType.key].items
                            }
                            else {
                                objectType.exists = objectType.includeItems = false
                                objectType.items = []
                            }

                            if (infoRepository[objectType.key] && infoRepository[objectType.key].relations.length >= 1) {
                                objectType.includeRelations = true
                                objectType.relations = vm.wfFilePreview.data.informationRepository[objectType.key].relations
                            }
                            else {
                                objectType.includeRelations = false
                                objectType.relations = []
                            }
                        })

                        if (vm.wfFilePreview.data.relations) {
                            vm.importConfig.otherRelations.include = true
                            vm.importConfig.otherRelations.relationItems = vm.wfFilePreview.data.relations
                        }
                        else vm.importConfig.otherRelations.include = false
                    }
                    else {
                        vm.wfFilePreview = undefined
                        modalService.alert({
                            title: 'Information missing',
                            message: '\n' + verified,
                            type: 'warning',
                        })
                    }
                }
                vm.loadingPreview = false
            }, () => {
                vm.loadingPreview = false
                vm.wfFilePreview = undefined
                modalService.alert({
                    title: 'Unsupported file',
                    message: 'Could not load the file due to the following reasons:\n\n1. The file is not of type .json\n2. The file seems to be missing some information or it might be corrupted.\n\nPlease select the right file.',
                    type: 'warning',
                })
            })
        }

        function importData() {
            const
                informationRepositoryPreview = vm.wfFilePreview.data.informationRepository
				
            let objectTypesToInclude = []
				
            let dataByType = []
				
            let oldItem = undefined
				
            let preparedItem = undefined
				
            const relationMapper = new RelationMapper()

            if (vm.importConfig) {
                //Information Repository - creating items (HAS TO EXECUTE BEFORE RELATIONS)
                if (isObject(vm.importConfig.informationRepository.objectTypes)) {
                    objectTypesToInclude = _.filter(vm.importConfig.informationRepository.objectTypes, 'includeItems')
					
                    if (objectTypesToInclude.length >= 1) {
                        vm.importConfig.importing = true
                        dataByType = _.pick(informationRepositoryPreview, _.map(objectTypesToInclude, 'key'))

                        //DOUBLE RECURSION
                        var dataTypeQueue = _.clone(dataByType) // [ "objectType": { items: [], relations: [] } ]
                        var dataTypeKeys = Object.keys(dataTypeQueue)

                        //------------------------------------------------------------
                        //This is only to update the export counts after importing data (can be ignored for now)
                        const exportObjectTypesToUpdate = _.pickBy(vm.exportConfig.informationRepository.objectTypes, (type) => {
                            if (_.includes(Object.keys(dataByType), type.key)) return type
                        })
                        //------------------------------------------------------------
						
                        importDataRecursively().then((key) => {
                            if (vm.importConfig.otherRelations.include) {
                                createOtherRelations(relationMapper).then(() => {
                                    vm.importConfig.importing = false
                                    vm.importConfig.importSucceded = true
                                }, () => {
                                    vm.importConfig.importing = false
                                    vm.importConfig.importSucceded = false
                                })
                            }
                            else {
                                vm.importConfig.importing = false
                                vm.importConfig.importSucceded = true
                            }
                        }, () => {
                            vm.importConfig.importing = false
                            vm.importConfig.importSucceded = false
                        })
                    }
                }
                else console.error('Object types are not defined for information repository.')
				
                if ((!vm.importConfig.otherRelations.include || !isObject(vm.importConfig.otherRelations.relationItems)) && !isObject(objectTypesToInclude)) {
                    modalService.alert({
                        title: 'Nothing to import',
                        message: 'No information selected for importing',
                        type: 'info',
                    })
                }
            }
            else {
                vm.importConfig.importSucceded = false
                console.error('Import config not defined or empty')
            }

            function importDataRecursively() {
                const df = $q.defer()
                const singleRelationsPromises = []

                traverseItems(dataTypeKeys.shift(), 0)
				
                //Recursive function
                function traverseItems(key, dataTypeIndex) {
                    const queue = _.clone(dataTypeQueue[key].items)
                    const currentObjectType = _.find(objectTypesToInclude, { key })
                    currentObjectType.importing = true

                    if (dataTypeQueue[key].items.length >= 1) saveItemIds(queue.shift(), 0)
					
                    //Recursive function
                    function saveItemIds(item, index) {
                        preparedItem = prepareItemForImport(item)
                        importExportService.importSingleItem(preparedItem).then((newItem) => {
                            oldItem = item
                            relationMapper.saveOldAndNewId(oldItem, newItem)
                            if (dataTypeQueue[key].relations.length >= 1) {
                                _.each(dataTypeQueue[key].relations, (relation) => {
                                    if (relation.childId === oldItem.id && relation.childType === oldItem.type) singleRelationsPromises.push(createSingleRelation(relationMapper, relation))
                                })
                            }

                            if (queue.length > 0) saveItemIds(queue.shift(), index++)
                            else if (dataTypeKeys.length > 0) {
                                currentObjectType.importing = false
                                currentObjectType.status = '✔ Imported'
                                traverseItems(dataTypeKeys.shift(), dataTypeIndex++)
                            }
                            else {
                                $q.all(singleRelationsPromises).then(() => {
                                    currentObjectType.importing = false
                                    currentObjectType.status = '✔ Imported'
                                    vm.exportConfig.refreshCounts()
                                    df.resolve(key)
                                }, () => {
                                    df.reject('Could not create single relation')
                                    vm.exportConfig.refreshCounts()
                                })
                            }
							
                        }, (res) => {
                            currentObjectType.importing = false
                            currentObjectType.status = '✘ Failed to import'
                            df.reject('Couldn\'t import item' + res)
                        })
                    }
                }
                return df.promise
            }

            function prepareItemForImport(item) {
                if (typeof item === 'object') {
                    if (item.id || item.wfid || item.metadata || item.createdAt || item.creatorUserWfid || item.userId || item.organizationId) return _.omit(item, ['id', 'wfid', 'metadata', 'createdAt', 'creatorUserWfid', 'userId', 'organizationId'])
                }
            }
			
            function importDataRecursively() {
                const df = $q.defer()
                const singleRelationsPromises = []

                traverseItems(dataTypeKeys.shift(), 0)
				
                //Recursive function
                function traverseItems(key, dataTypeIndex) {
                    const queue = _.clone(dataTypeQueue[key].items)
                    const currentObjectType = _.find(objectTypesToInclude, { key })
                    currentObjectType.importing = true

                    if (dataTypeQueue[key].items.length >= 1) saveItemIds(queue.shift(), 0)
					
                    //Recursive function
                    function saveItemIds(item, index) {
                        preparedItem = prepareItemForImport(item)
                        importExportService.importSingleItem(preparedItem).then((newItem) => {
                            oldItem = item
                            relationMapper.saveOldAndNewId(oldItem, newItem)
                            if (dataTypeQueue[key].relations.length >= 1) {
                                _.each(dataTypeQueue[key].relations, (relation) => {
                                    if (relation.childId === oldItem.id && relation.childType === oldItem.type) singleRelationsPromises.push(createSingleRelation(relationMapper, relation))
                                })
                            }

                            if (queue.length > 0) saveItemIds(queue.shift(), index++)
                            else if (dataTypeKeys.length > 0) {
                                currentObjectType.importing = false
                                currentObjectType.status = '✔ Imported'
                                traverseItems(dataTypeKeys.shift(), dataTypeIndex++)
                            }
                            else {
                                $q.all(singleRelationsPromises).then(() => {
                                    currentObjectType.importing = false
                                    currentObjectType.status = '✔ Imported'
                                    vm.exportConfig.refreshCounts()
                                    df.resolve(key)
                                }, () => {
                                    df.reject('Could not create single relation')
                                    vm.exportConfig.refreshCounts()
                                })
                            }
							
                        }, (res) => {
                            currentObjectType.importing = false
                            currentObjectType.status = '✘ Failed to import'
                            df.reject('Couldn\'t import item' + res)
                        })
                    }
                }
                return df.promise
            }
        }
		
        function createSingleRelation(relationMapper, relation) {
            const deferred = $q.defer()
            const relationsResult = { createdRelations: [], deniedRelations: [] }
			
            console.log('Creating relation - ', relation)
            const relationToImport = prepareRelationForImport(relation)
            let item1 = {}
            let item2 = {}

            relationMapper.getParentAndChildId(relationToImport).then((result) => {
                item1 = {
                    type: relationToImport.parentType,
                    id: result.parentId,
                }
                item2 = {
                    type: relationToImport.childType,
                    id: result.childId,
                }

                if (item1.id !== undefined || item2.id !== undefined) {
                    dataOps.createSubItemRelation(item1, item2, getKindForParentContent.apply(relationToImport)).then((result) => {
                        console.log('Successfully added relation - ', result)
                        relationsResult.createdRelations.push(relationToImport)
                        deferred.resolve(relationsResult)
                    }, (result) => {
                        console.error('Couldn\'t create relation - ', result)
                        relationsResult.deniedRelations.push(relationToImport)
                        deferred.reject(result)
                    })
                }
                else {
                    console.error('One of the items are not defined', item1, item2)
                    relationsResult.deniedRelations.push(relationToImport)
                    deferred.reject(item1, item2)
                }
            }, () => {
                relationsResult.deniedRelations.push(relationToImport)
            })

            return deferred.promise
        }

        function createOtherRelations(relationMapper) {
            const deferred = $q.defer()
            const relationsResult = { createdRelations: [], deniedRelations: [] }
			
            if (vm.importConfig.otherRelations.include) {
                if (isObject(vm.importConfig.otherRelations.relationItems)) {
                    _.each(vm.importConfig.otherRelations.relationItems, (relation) => {
                        console.log('Creating relation - ', relation)
                        const relationToImport = prepareRelationForImport(relation)
                        let item1 = {}
                        let item2 = {}

                        relationMapper.getParentAndChildId(relationToImport).then((result) => {
                            item1 = {
                                type: relationToImport.parentType,
                                id: result.parentId,
                            }
                            item2 = {
                                type: relationToImport.childType,
                                id: result.childId,
                            }

                            if (item1.id !== undefined || item2.id !== undefined) {
                                dataOps.createSubItemRelation(item1, item2, getKindForParentContent.apply(relationToImport)).then((result) => {
                                    console.log('Successfully added relation - ', result)
                                    relationsResult.createdRelations.push(relationToImport)
                                    deferred.resolve(relationsResult)
                                }, (result) => {
                                    console.error('Couldn\'t create relation - ', result)
                                    relationsResult.deniedRelations.push(relationToImport)
                                    deferred.reject(result)
                                })
                            }
                            else {
                                console.error('One of the items are not defined', item1, item2)
                                relationsResult.deniedRelations.push(relationToImport)
                                deferred.reject(item1, item2)
                            }
                        }, () => {
                            relationsResult.deniedRelations.push(relationToImport)
                        })
                    })
                }
            }
			
            return deferred.promise
        }
		
        function prepareRelationForImport(item) {
            if (typeof item === 'object') {
                if (item.id || item.wfid || item.metadata || item.createdAt || item.creatorUserWfid || item.userId) return _.omit(item, ['id', 'wfid', 'createdAt', 'userId'])
            }
        }

        //----------- DELETE FUNCTIONS ----------------

        function openDeleteModal() {
            vm.deleteConfig.resetConfig()

            const modalInstance = $uibModal.open({
                animation: true,
                size: 'width-700',
                windowClass: 'delete-content-modal',
                backdrop: 'static',
                templateUrl: 'scripts/wf/transferManager/deleteContentModal.template.html',
                scope: $scope,
            })

            modalInstance.closed.then(() => {
                vm.exportConfig.refreshCounts()
            })
        }

        function deleteData() {
            let dataTypesToDelete
            let queue

            if (this.allowToDelete && this.anyTypeSelected && this.orgNameVarification === this.organization.name) {
                dataTypesToDelete = _.filter(this.informationRepository.objectTypes, 'includeItems')
                queue = _.clone(dataTypesToDelete)
                getItemsToDelete(queue.shift(), 0)
            }
            else modalService.alert('It seems like you don\'t have anything selected for deleting, or organization name is not defined')

            //Recursive function
            function getItemsToDelete(objectType, index) {
                let itemsToDelete
                vm.deleteConfig.deleting = true
                if (objectType && objectType.includeItems && objectType.structureId) {
                    objectType.deleted = false
                    objectType.deleting = true
                    dataOps.getSubItems({ id: objectType.structureId, type: 71 }, enums.subItemsKind.childrenByUser, { bypassCache: true }).then((res) => {
                        itemsToDelete = _.map(res, 'childContent')
                        if (itemsToDelete.length >= 1) {
                            deleteItems(itemsToDelete).then(() => {
                                objectType.deleted = true
                                objectType.status = '✔ Success'
                                objectType.includeItems = false
                                objectType.exists = false

                                if (queue.length > 0) getItemsToDelete(queue.shift(), index++)
                                else vm.deleteConfig.refreshCounts(dataTypesToDelete)
									
                            }, (result) => {
                                objectType.deleted = false
                                objectType.status = '✘ Fail'
                                console.error('Something went wrong, could not delete item - ', result)
                            }).finally(() => {
                                objectType.deleting = false
                                vm.deleteConfig.deleting = false
                            })
                        }
                    })
                }
                else {
                    modalService.alert('No items found to delete.')
                    vm.deleteConfig.deleting = false
                }
            }

            function deleteItems(items) {
                const deferred = $q.defer()
                const promises = []
                _.each(items, (item) => {
                    promises.push(dataOps.destroy(item))
                })

                $q.all(promises).then((result) => {
                    deferred.resolve(result)
                }, (result) => {
                    deferred.reject('Could not destroy item - ', result)
                })

                return deferred.promise
            }
        }

        function checkOrgNameInput() {
            if (this.orgNameVarification === this.organization.name) this.allowToDelete = true
            else this.allowToDelete = false
        }

        function checkInputTypeToggle() {
            const config = this
            let checked = _.some(config.informationRepository.objectTypes, (type) => {
                if (type.includeItems === true && type.items.length >= 1) return true
            })

            if (config.otherRelations && config.otherRelations.relationItems && config.otherRelations.include && config.otherRelations.relationItems.length >= 1) checked = true

            if (checked) {
                config.anyTypeSelected = true
                if (config.organization && (config.orgNameVarification === config.organization.name)) config.allowToDelte ? config.allowToDelete = true : null
            }
            else {
                config.anyTypeSelected = false
                config.allowToDelte ? config.allowToDelete = false : null
                if (config.confirmed) config.confirmed = false
            }
        }

        //----------- HELPER FUNCTIONS ----------------

        function refreshCounts(typesToRefresh) {
            const deferred = $q.defer()
            const config = this

            if (config.hasOwnProperty('readyForExport')) config.readyForExport = false

            config.loadRelationsForAllObjectTypes().then((result) => {
                let objectTypes = result.objectTypes
                const otherRelations = result.otherRelations
                if (typesToRefresh) objectTypes = typesToRefresh

                const queue = _.clone(objectTypes)
				
                if (otherRelations.length >= 1) {
                    vm.exportConfig.otherRelations.relationItems = otherRelations
                    vm.exportConfig.otherRelations.include = true
                }

                getItems(queue.shift(), 0)

                function getItems(objectType, index) {
                    objectType.countLoaded = false
                    $timeout()
                    dataOps.getSubItems({ id: objectType.structureId, type: 71 }, enums.subItemsKind.childrenByUser, { bypassCache: true }).then((res) => {
                        const items = _.map(res, 'childContent')
                        const relations = objectType.relations
                        let matchedRelationsWithItems = []
                        let matchedRelation

                        _.each(items, (item) => {
                            matchedRelation = _.filter(relations, ['childId', item.id])
                            if (matchedRelation) matchedRelationsWithItems = _.concat(matchedRelation, matchedRelationsWithItems)
                        })

                        //Setting toggles to correct state
                        if (items.length >= 1) {
                            objectType.exists = true
                            if (config.informationRepository.include) objectType.includeItems = true
                        }
                        else objectType.exists = objectType.includeItems = false

                        if (matchedRelationsWithItems.length >= 1) {
                            if (config.informationRepository.include) objectType.includeRelations = true
                        }
                        else objectType.includeRelations = false

                        objectType.items = items
                        objectType.relations = matchedRelationsWithItems
                        objectType.countLoaded = true
                        $timeout()

                        if (queue.length > 0) getItems(queue.shift(), index++)
                        else {
                            config.readyForExport = true
                            deferred.resolve(res)
                        }

                    }, (items) => {
                        deferred.reject(items)
                        console.error('Something went wrong in getItems(objectType)')
                    })
                }

                console.log(vm)
            })

            return deferred.promise
        }

        function loadRelationsForAllObjectTypes() {
            const deferred = $q.defer()
            const objectTypes = this.informationRepository.objectTypes

            getAllRelations().then((result) => {
                const otherRelations = _.clone(result)
                let foundRelations
                _.each(objectTypes, (objectType) => {
                    foundRelations = _.filter(result, ['childType', objectType.type])
                    objectType.relations = foundRelations
                    _.pullAllBy(otherRelations, foundRelations)
                })
                deferred.resolve({ objectTypes, otherRelations })
            })

            return deferred.promise
        }

        function getAllRelations() {
            const df = $q.defer()
            dataOps.getObject({ objectType: 71, objectId: 15691, bypassCache: true }).then((result) => {
                const additionalItems = removeInvalidRelations(result.additionalItems)
                df.resolve(additionalItems)
            })
            return df.promise
        }

        function getObjectTypes(includeItems, includeRelations) {
            return [
                { key: 'activities', type: 15, structureId: 10015, icon: 'fa fa-calendar-check-o', includeItems, includeRelations, exists: true, countLoaded: false }, // Activities
                { key: 'documents', type: 18, structureId: 10016, icon: 'fa fa-file', includeItems, includeRelations, exists: true, countLoaded: false }, // Documents
                { key: 'statements', type: 44, structureId: 10018, icon: 'fa fa-quote-right', includeItems, includeRelations, exists: true, countLoaded: false }, // Statements
                { key: 'links', type: 50, structureId: 10017, icon: 'fa fa-link', includeItems, includeRelations, exists: true, countLoaded: false }, // Links
                { key: 'embeds', type: 90, structureId: 11843, icon: 'fa fa-play-circle', includeItems, includeRelations, exists: true, countLoaded: false },  // Embeds
                { key: 'locations', type: 58, structureId: 11970, icon: 'fa fa-map-marker', includeItems, includeRelations, exists: true, countLoaded: false },  // Locations
                { key: 'questionAnswers', type: 31, structureId: 10019, icon: 'fa fa-comments-o', includeItems, includeRelations, exists: true, countLoaded: false }, // Question answers
                { key: 'measureAnswers', type: 25, structureId: 10020, icon: 'fa fa-area-chart', includeItems, includeRelations, exists: true, countLoaded: false }, // Measure answers
            ]
        }

        //I had to remove organizationId from prepareRelationForImport() in order to get relatedContentByUser
        function getKindForParentContent() {
            if (!this.organizationId && this.parentData1 === null) return enums.subItemsKind.children
            if (this.organizationId && this.parentData1 === null) return enums.subItemsKind.childrenByUser
            if (this.organizationId && this.parentData1 === 2) return enums.subItemsKind.verifications
            if (!this.organizationId && this.parentData1 === 1) return enums.subItemsKind.relatedContent
            if (this.organizationId && this.parentData1 === 1) return enums.subItemsKind.relatedContentByUser
            if (this.organizationId && this.parentData1 === 3) return enums.subItemsKind.contextChildren
        }

        function toggleSwitch() {
            const currentToggleState = this.include
            const objectTypes = this.objectTypes
            if (objectTypes) {
                _.each(objectTypes, (objectType) => {
                    if ((objectType.items && objectType.items.length >= 1) && objectType.exists) objectType.includeItems = currentToggleState
                    else if ((objectType.items && objectType.items.length === 0) && objectType.exists) objectType.includeItems = currentToggleState
                    else if (objectType.items === undefined && objectType.exists) objectType.includeItems = currentToggleState

                    if (objectType.relations && objectType.relations.length >= 1) objectType.includeRelations = currentToggleState
                })
            }
        }

        function isObject(value) {
            return typeof value === 'object' && !_.isEmpty(value)
        }
    }
})()
