import * as enums from '@worldfavor/constants/enums'

let sr;
(function () {
    'use strict'

    angular
        .module('wf.common')
        .controller('PageController', PageController)

    PageController.$inject = ['dataOperationsService', '$stateParams', 'wfObject', '$scope', '$timeout', '$rootScope', '$state', 'modalService', 'signalR']

    function PageController(dataOps, $stateParams, wfObject, $scope, $timeout, $rootScope, $state, modal, signalR) {
        /* jshint validthis:true */
        const vm = this
        let itemTimer

        $scope.item = null
        _.assign(vm, {
            $state,
            items: [],
            window,
            templateId: $stateParams.templateId,
            templateIdFallback: $stateParams.templateIdFallback,
            hideBackButton: $stateParams.hideBackButton,
            context: {
                ticket: $stateParams.ticket,
                isViewMode: $stateParams.isViewMode,
            },
        })

        window['$state'] = $state
        window.sr = signalR

        // This makes sure that the loader gif only shows when data is fetched from the server.
        // Otherwise it flashes by for 1 ms when the data is already in the jsdata cache.
        itemTimer = $timeout(() => {
            $scope.item = null
        }, 0)

        if ($stateParams.get)
        {
            $stateParams.get(dataOps).then((item) => {
                $timeout.cancel(itemTimer)
                $scope.item = item
            })
        }
        else if ($stateParams.hierarchy) {
            dataOps.getObject({
                objectId: $stateParams.objectId,
                objectType: $stateParams.objectType,
                childrenLoadDepth: -1,
            }).then((dataRelation) => {
                $timeout.cancel(itemTimer)

                if ($stateParams.direction === 'up') {
                    $scope.item = {
                        childContent: dataRelation.parentContent,
                        childId: dataRelation.parentContent.id,
                        childType: dataRelation.parentContent.type,
                    }
                }
                else $scope.item = dataRelation

                $rootScope.setPageTitle($scope.item.childContent.title)
            })
        }
        else
        {
            // console.log("page", $stateParams.objectId, $stateParams.subObjectId);
            // console.info($state, $stateParams.isSubObject)
            dataOps.getObject({
                objectId: window.wf__managageLoaded ? $stateParams.subObjectId : $stateParams.objectId || _.get($state.params, 'objectId'),
                objectType: $stateParams.objectType || _.get($state.params, 'objectType') || 73,
                childrenLoadDepth: $stateParams.childrenLoadDepth,
                ticket: $stateParams.ticket,
                skipExtras: $stateParams.skipExtras,
                // bypassCache: $stateParams.bypassCache,
                // cacheResponse: $stateParams.cacheResponse
            }).then((item) => {
                let itemContent = item.childContent; let redirecting

                if (!itemContent) {
                    itemContent = item
                }
                //console.log(item);
                $timeout.cancel(itemTimer)
                // modal.openCreatorAndPicker({ relationTarget: { item: item.childContent, kind: 1 }, objectTypes: [ 15,71,11,21 ] })

                if (itemContent) {
                    $rootScope.setPageTitle(itemContent.getMainTextual())

                    if (itemContent.type === enums.objectType.structure) {
                        if (itemContent.conditions && itemContent.conditions.intersectionSourceWfid) {
                            redirecting = true
                            $state.go('hier', { objectId: itemContent.conditions.intersectionSourceWfid, intersect: itemContent.wfid }, { location: 'replace' })
                        }
                    }
                }
                else $rootScope.setPageTitle()

                if (!redirecting) $scope.item = item

                //console.info("page", vm.item);
                //console.log(item);
                //console.log('wfObject', DS.lastModified(item.children))
                //wfObject.bindAll({ wfid: item.wfid }, vm, 'children');
                //console.log(wfObject.lastModified());

                //$scope.$watch(function () {
                //	return wfObject.lastModified();
                //}, function () {
                //	vm.items = wfObject.filter({ wffid: item.wfid });
                //});
            })
        }

    }
})()
