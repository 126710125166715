import React from 'react'
import { react2angular } from 'react2angular'
import DataCollectorDashboard from '@worldfavor/portal/components/Dashboard/DataCollectorDashboard'
import { WrapProvider } from './providers'

const props = [
    'data',
]

class DataCollectorDashboardWrapper extends React.Component {
    render() {
        const { wfAuth, ...rest } = this.props
        return (
            <WrapProvider wfAuth={wfAuth}>
                <DataCollectorDashboard {...rest} />
            </WrapProvider>
        )
    }
}

export default react2angular(DataCollectorDashboardWrapper, props, ['wfAuth'])
