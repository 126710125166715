import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfListInterface', () => {
            return {
                controller: wfListInterfaceController,
                // templateUrl is loaded dynamically because this directive uses special transclusion logic
                controllerAs: 'vm',
                bindToController: true,
                scope: {
                    fromItem: '=',
                    fromArray: '=',
                    ticket: '<',
                    transclusionOuterVm: '<',
                    configFromAttribute: '<config',
                    depth: '<',
                    listVm: '<',
                    negotiatorFromAttr: '=negotiator',
                },
                require: {
                    wfDataNegotiator: '?^^wfDataNegotiator',
                },

                // Terminal is needed to prevent Angular from touching any html that might be inside <wf-list-interface></wf-list-interface>
                // that will be used for transclusion. The html is manually cloned and passed to every instance of wfListItem in ngRepeat.
                terminal: true,

                // Transclusion slots:
                // transcludeItemRightSide (handled manually)

                link(scope, element, attrs, controllers, transclude) {
                    scope.vm.wfDataNegotiator = controllers.wfDataNegotiator
                    scope.vm.activate()
                },
            }
        })

    const templateUrl = 'scripts/wf/list/wfListInterface.directive.html'
    let templateRequest
    let templateLoaded = false

    wfListInterfaceController.$inject = ['$scope', '$attrs', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$compile', '$translate', 'apiProxy', 'dataQuery', 'wfPropertyExtractor', 'DataNegotiator', '$http', '$templateCache', 'modalService', '$parse', '$q', 'valueChainService']
    function wfListInterfaceController($scope, $attrs, $timeout, $element, $rootScope, $state, requirements, $compile, $translate, apiProxy, dataQuery, wfPropertyExtractor, DataNegotiator, $http, $templateCache, modal, $parse, $q, valueChainService) {
        const
            vm = this

        let xhrRequest

        _.assign(vm, {
            $rootScope,
            scopeId: $scope.$id,
            items: [],
            loaded: false,
            negotiator: undefined,
            showColumns: false,
            itemStyle: 'list_indented',
            creationDropdownActions: undefined,
            showConfigBar: true,
            showItemIndentation: true,
            itemWidth: '100%',
            config: {
                // The wfChartNew component needs a width to render correctly.
                // If the listInterface or any of its parents are hidden when everything has loaded and the html is rendered the chart will have zero width.
                // By default the width of the wfListInterface component element is used but if a wrapping component controls the visibility the width
                // must be specified by that wrapping component like this: <wf-list-interface config="{ width: 600 }"></wf-list-interface>.
                // The width value will be passed along to the wfChartNew component.
                width: $element.width(),

                showLoadedItem: false,
                showCreationButton: false,
                showFiltering: false,
                showVisualization: true,
                showActions: false,
                showModificationActions: false,
                showTotals: false,
                showLayoutOptions: true,
                showConsolidationStatistics: true,
                creationAction: undefined,
                includeActions: {
                    embed: true,
                    export: true,
                    view: true,
                    create: true,
                    sort: true,
                    mark: true,
                    listView: true,
                    gridView: true,
                    tableView: true,
                },
                filteringConfig: {
                    filters: [
                        [
                            { bySearch: true },
                        ],
                        [
                            {
                                headerTranslate: 'Categories',
                                sourceIdPath: 'wfid', bySubItemsKind: enums.subItemsKind.parentsByUser,
                            },
                        ],
                    ],
                },
                itemSettings: {
                    showDropdown: false,
                    dropdownActions: undefined,
                },
                chartConfig: { showLegend: true, chartWidth: $element.width() },
                useReactList: vm.configFromAttribute && vm.configFromAttribute.useReactList,
            },
            creationButtonIconClass: 'fa fa-plus-circle',
            dropdownCreateActions: undefined,
            creationButtonAction: undefined,
            activate,
            noop: _.noop,
            uiCompiler: {}, // Used to compile the head and the list after changes have been made to the config
            listCompiler: {}, // Used to compile the list
            tableData: {
                initExportPanel,
            },

            onUiSettingChanged,
            setListLayout,
            openExportDialog,
        })

        _.each(vm, (value, key) => {
            if (key in $attrs && typeof value === 'boolean') {
                if ($attrs[key] && $attrs[key].length) vm[key] = $parse($attrs[key])($scope)
                else vm[key] = true
            }
        })

        if ($element[0].childNodes.length) {
            vm.transcludeItemRightSide = {
                elementClone: $element.children('transclude-item-right-side')[0],
                outerVm: vm.transclusionOuterVm,
            }
            $element.html('')
        }

        loadTemplate()

        function loadTemplate() {
            if (templateLoaded) {
                applyTemplate()
                return
            }
            else if ($templateCache.get(templateUrl)) {
                templateLoaded = true
                applyTemplate()
                return
            }

            if (!templateRequest) {
                templateRequest = $http.get(templateUrl)
            }

            templateRequest.then((res) => {
                templateLoaded = true
                $templateCache.put(templateUrl, res.data)
                applyTemplate()
            })

        }

        function applyTemplate() {
            const templateContent = $templateCache.get(templateUrl)

            $element.html(templateContent)
            $compile($element.contents())($scope)
        }

        function activate() {
            vm.negotiator = DataNegotiator.instantiate(vm.negotiatorFromAttr || vm.wfDataNegotiator, { fromItem: vm.fromItem, ticket: vm.ticket, loadDepth: vm.depth })
            vm.isQuestion = vm.negotiator.fromItem.type === enums.objectType.question

            vm.negotiator.onRequest.then((res) => {
                vm.ticket = vm.negotiator.ticket
                vm.isConsolidation = vm.negotiator.ticket && vm.negotiator.ticket.organizationIds
                vm.config = _.defaultsDeep(vm.negotiator.listInterfaceConfig, vm.config)

                setupCreationActions()
            })

            _.assign(vm.config, vm.configFromAttribute)

            if (vm.listVm) {
                vm.nestedListDepth = (vm.listVm.nestedListDepth || 0) + 1
            }

            vm.sortingActions = [
                {
                    text: 'Title',
                    action() {},
                },
                {
                    text: 'Created',
                    action() {},
                },
            ]
            // console.log($element.html());

            // var $html = $('<div />',{ html:htmlContent }); // for much easier manipulation (so you can use DOM functions - you can also manipulate directly on htmlContent string)

            // $transclude(function(clone, scope) {
            // 	console.log(clone);
            // 	// vm.transcludeItemRightSide = {
            // 	// 	elementClone: clone[0],
            // 	// 	outerVm: vm.transclusionOuterVm
            // 	// }
            // });//, null, "transcludeItemRightSide");
        }

        function setupCreationActions() {
            const
                mainItem = _.get(vm, 'negotiator.item')

            const objectType = _.get(vm, 'negotiator.item.conditions.objectTypes[0]')

            const dataRelationCondition = _.get(vm, 'negotiator.item.conditions.dataRelation')

            const options = {
                dataRelationOptions: { kind: enums.subItemsKind.childrenByUser, item1: mainItem },
            }

            if (!mainItem) return

            if (typeof vm.config.creationAction === 'function') {
                vm.creationButtonAction = function () {
                    vm.config.creationAction(vm.negotiator)
                }
            }

            vm.creationButtonIcon = 'fa fa-plus'
            vm.creationButtonCaption = $translate.instant('Create')

            vm.creationButtonAction = function () {
                if (mainItem.type === enums.objectType.structure) {
                    if (!dataRelationCondition) {
                        options.dataRelationOptions.virtual = true
                    }

                    if (objectType) {
                        options.objectType = objectType
                    }
                }
                else if (mainItem.type === enums.objectType.measure) {
                    options.objectType = enums.objectType.measureAnswer
                }
                else if (mainItem.type === enums.objectType.question) {
                    options.objectType = enums.objectType.questionAnswer
                }

                const promise = modal.createWithRelation(options)

                promise.then((res) => {
                    if (res) {
                        vm.negotiator.addItem(res)
                    }
                })
            }
        }

        function onUiSettingChanged() {
            vm.uiCompiler.compile()
        }

        function setListLayout(view) {
            const oldView = vm.listLayout
            const listLayoutWatcher = $scope.$watch('vm.listLayout', () => {
                $scope.$broadcast('updateChartist')
            })

            vm.listLayout = view
            vm.showItemIndentation = false

            if (view === 'grid') {
                vm.itemWidth = '33.333%'
            }
            else if (view === 'list' || view === 'list_indented') {
                vm.itemWidth = '100%'

                if (view === 'list_indented') {
                    vm.showItemIndentation = true
                }
            }
            else if (view === 'table') {
                vm.itemWidth = '100%'
                initTableData()
            }

            if (oldView === 'table' || view === 'table') {
                vm.listCompiler.compile()
            }

            $scope.$on('$destroy', () => {
                listLayoutWatcher()
            })
        }

        function initTableData() {
            const promises = []

            if (vm.tableData.inited) {
                return $q((resolve, reject) => {
                    resolve(vm.tableData)
                })
            }

            vm.tableData.inited = true

            if (vm.negotiator.fromItem.type === 11 || vm.negotiator.fromItem.type === 21) {
                const promise = vm.negotiator.getTableData_onlyLatestAnswers()
                promise.then((res) => {
                    vm.tableData.items = res
                })
                promises.push(promise)

            }
            else {
                const promise = vm.negotiator.getTableData()
                promise.then((res) => {
                    vm.tableData.items = res
                })
                promises.push(promise)

            }

            vm.tableData.exportMapping = vm.negotiator.getTableExportMapping()

            return $q((resolve, reject) => {
                $q.all(promises).then(() => {
                    vm.tableData.loaded = true
                    resolve()
                })
            })
        }

        function initExportPanel() {
            const initResult = initTableData()

            if (initResult.then) {
                initResult.then(() => {
                    const exportItems = [...vm.tableData.items]

                    const exportMappingBase = { ...vm.tableData.exportMapping }

                    const { categories, categoryGroups } = valueChainService.getCachedNetworkCategoriesInfo()

                    vm.export = {
                        showCategorizationOptions: categories && categoryGroups,
                        items: exportItems,
                        exportMapping: undefined,
                        includeCategorizations: { value: false },
                        groupCategorizations: { value: false },
                        onIncludeCategorizationsChange() {
                            vm.export.syncExportMappingAndItems()
                        },
                        onGroupCategorizationsChange() {
                            vm.export.syncExportMappingAndItems()
                        },
                        syncExportMappingAndItems: () => {
                            const { includeCategorizations, groupCategorizations } = vm.export
                            const exportMapping = { ...exportMappingBase }

                            vm.export.hideExportUI = true
                            $timeout()

                            vm.export.exportMapping = exportMapping

                            // Setting up category columns
                            if (includeCategorizations.value) {
                                if (groupCategorizations.value) {
                                    _.each(categoryGroups, (item) => {
                                        exportMapping[item.id] = {
                                            include: true,
                                            header: item.title,
                                            source: item.id,
                                        }
                                    })
                                }
                                else {
                                    _.each(categories, (item) => {
                                        exportMapping[item.id] = {
                                            include: true,
                                            header: item.title,
                                            source: item.id,
                                        }
                                    })
                                }
                            }

                            // Generating category values in rows
                            if (includeCategorizations.value) {
                                exportItems.forEach((row) => {
                                    const { creatorOrContextOrganizationId } = row

                                    if (groupCategorizations.value) {
                                        _.each(categoryGroups, (item) => {
                                            const categories = item.categories.filter(x => x.orgIds.includes(creatorOrContextOrganizationId))
                                            row[item.id] = categories.map(x => x.title).join(', ')
                                        })
                                    }
                                    else {
                                        _.each(categories, (item) => {
                                            row[item.id] = ~item.orgIds.indexOf(creatorOrContextOrganizationId) // If value is included in array
                                                ? '1' : '0'
                                        })
                                    }
                                })
                            }


                            $timeout(() => { vm.export.hideExportUI = false })
                        },
                    }

                    vm.export.syncExportMappingAndItems()
                })
            }
        }

        function openExportDialog() {
            const scope = $rootScope.$new()

            _.assign(scope, {
                contentMainTextual: wfPropertyExtractor.getMainTextual(vm.negotiator.itemComposite.content, { html: false }),
                outerVm: vm,
                close() {
                    scope.closed = true
                    openedModal.modal.close()
                },
            })

            vm.tableData.initExportPanel()

            const openedModal = modal.open({
                templateUrl: 'scripts/wf/valueChain/popovers/aggregatedItemExport.template.html',
                scope,
                windowClass: 'modal-width-700',
                onLoaded($scope, $element) {
                },
            })
        }
        // [
        // 	{
        // 		colWidth: 6,
        // 		header: "",
        // 		source: "content.creatorOrganization.name"
        // 	},
        // 	{
        // 		colWidth: 2,
        // 		header: "",
        // 		source: "content.mainTextual"
        // 	},
        // 	{
        // 		colWidth: 4,
        // 		header: "",
        // 		source: "item.content.createdAt",
        // 		format: function (value) {
        // 			return moment(value).format("D MMM YYYY, HH:mm");
        // 		}
        // 	}
        // ]
    }

})()
