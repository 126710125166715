import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('WidgetsController', WidgetsController)

    WidgetsController.$inject = ['$scope', '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService', 'dataOperationsService', '$translate', 'wfTranslate', 'wfAuth', 'modalService']
    function WidgetsController($scope, $q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader, dataOps, $translate, wfTranslate, wfAuth, modal) {
        const
            vm = this
			
        let formSpecification

        _.assign(vm, {
            preview,
            create,
            edit,
            getEmbedCode,
            destroy,
            widgetStatsOptions: {
                height: '280px',
                structureId: 11971,
                bgColor: '#34485E',
                emptyStateHeader: $translate.instant('modules.dashboard.visualize.emptyStateHeader'),
                chartType: 'line',
                additionalRequestParams: {
                    includeReadStatistics: true,
                },
                labels: {
                    reads: $translate.instant('modules.dashboard.visualize.totalViews'),
                },
                datasetPrototype: {
                    backgroundColor() {
                        const offscreen = document.createElement('canvas') // detached from DOM
                        const ctx = offscreen.getContext('2d')
                        const bgGradient = ctx.createLinearGradient(0, 0, 0, 110)
                        bgGradient.addColorStop(0, 'rgba(0, 0, 0, 0.15)')
                        bgGradient.addColorStop(1, 'rgba(0, 0, 0, 0)')
                        return bgGradient
                    },
                },
                onBeforeSetup(structureStats) { // Called for each structure specified in structureIds when they have finished loading but before any other logic has executed
                    // Modify the statistics object of each structure so that only "reads" data is included
                    const modifiedStats = _.cloneDeep(structureStats)
                    modifiedStats.datasets = _.filter(modifiedStats.datasets, { id: 'reads' })
                    modifiedStats.totals = _.filter(modifiedStats.totals, { id: 'reads' })

                    return modifiedStats
                },
            },
        })

        activate()

        function activate() {
            dataOps.getObject({
                objectId: 11971, // Contains widget created by organization
                objectType: enums.objectType.structure,
                childrenLoadDepth: 1,
            }).then((res) => {
                vm.mainStructure = res
                vm.loaded = true
                vm.items = res.childs

                $timeout()
            })

            vm.dropdownActions = [
                { text: 'Preview', icon: 'fa fa-eye', action: '$parent.vm.preview(item.childContent)' },
                { text: 'Edit', icon: 'fa fa-pencil', action: { func: edit } },
                { text: 'Get embed code', icon: 'fa fa-code', action: '$parent.vm.getEmbedCode(item.childContent)' },
                'deleteContent',
            ]
        }

        function preview(widget) {
            const isLocal = document.location.hostname === 'local.worldfavor.com'

            modal.open({
                title: $translate.instant('Preview') + ': ' + widget.name,
                description: 'Preview of how the widget will look.',
                template: '<iframe src="/views/widget_preview' + (isLocal ? '_local' : '') + '.html?id=' + widget.id + '" scrolling="no" frameborder="0" width="100%" height="100%" />',
                scope: {},
                windowClass: 'modal-width-900 modal-fit-window modal-widget-preview',
            })
        }

        function create() {
            modal.createWithRelation({
                objectType: enums.objectType.widget,
                dataRelationOptions: { kind: enums.subItemsKind.childrenByUser, virtual: true, item1: dataOps.prepareWfObject(vm.mainStructure) },
                customFormSpecification: getFormSpecification(),
            }).then((res) => {
                if (res) {
                    vm.items.unshift(res)
                    $timeout()
                }
            })
        }

        function edit(widgetContent, itemRelation, dropdownControl) {
            modal.edit(widgetContent, {
                customFormSpecification: getFormSpecification(widgetContent),
            }).then((res) => {
                dropdownControl.notifyItemUpdated()
            })
            // editor(widget);
        }

        function getFormSpecification(widgetContent) {
            if (formSpecification) {
                formSpecification.form[2].typeOptions.selectedItem = {
                    id: widgetContent ? widgetContent.objectId : null,
                    type: widgetContent ? widgetContent.objectType : null,
                }
                return formSpecification
            }
            else {
                return formSpecification = {
                    schema: {
                        type: 'object',
                        properties: {
                            name: {
                                title: $translate.instant('Name'),
                                type: 'string',
                            },
                            allowedDomains: {
                                title: $translate.instant('AllowedDomains'),
                                type: 'string',
                            },
                            source: {
                                title: $translate.instant('Source'),
                                type: 'integer',
                            },
                        },
                        required: ['name', 'allowedDomains'],
                    },
                    form: [
                        'name',
                        'allowedDomains',
                        {
                            key: 'source',
                            type: 'picker_multiple',
                            typeOptions: {
                                selectedItem: {
                                    id: widgetContent ? widgetContent.objectId : null,
                                    type: widgetContent ? widgetContent.objectType : null,
                                },
                                singlePick: true,
                                validateAction(event, model, relationBucket) {
                                    return !!relationBucket.allSelected.length
                                },
                                submitAction(event, model, relationBucket) {
                                    const selectedItem = relationBucket.allSelected[0]

                                    if (selectedItem) {
                                        model.objectId = selectedItem.id
                                        model.objectType = selectedItem.type
                                    }
                                },
                                picker: {
                                    sourceItem: '71-203', // List of Custom frameworks
                                    title: $translate.instant('modules.widget.form.sourcePickerHeader'),
                                    description: $translate.instant('modules.widget.form.sourcePickerDescription'),
                                },
                            },
                        },
                    ],
                }
            }
        }

        function getEmbedCode(widget) {
            const
                widgetId = widget.id
				
            const formSpec = {
                schema: {},
                form: [],
            }
				
            const jsSnippet = '<script type="text/javascript">;(function() {var s=document.createElement(\'script\'),t=1*new Date();s.type=\'text/javascript\';s.id=\'wfWidgetScript\';s.async=true;s.src="'
						+ 'https://portal.worldfavor.com/scripts/embed/wf-embed.js?_="+t;document.getElementsByTagName(\'head\')[0].appendChild(s);})();</script>'
				
            const htmlSnippet = '<div class="wf-widget" data-id="' + widgetId + '"></div>'
				
            const initClipboard = function ($element, stringToCopy) {
                let
                    clipboard
						
                const iconElement = $element.children('span.fa')
						
                const textElement = $element.children('span[ng-bind]')
						
                const originalIcon = iconElement.attr('class')
						
                const originalText = textElement.text()
						
                const copiedIcon = ''
						
                const copiedText = 'Copied!'
						
                const fadeOutDuration = 100
						
                const fadeInDuration = 200
						
                const copiedMessageDuration = 1000
						
                let animating = false

                $element.width($element.outerWidth())
                $element.height($element.outerHeight())

                // Unbind original event
                $element.off()

                // Initialize clipboard.js
                clipboard = new Clipboard($element[0], {
                    text() {
                        return stringToCopy
                    },
                })

                setTimeout(() => {
                    // Trigger the new click event
                    $element.click()
                })

                clipboard.on('success', (e) => {
                    if (animating) return

                    animating = true
                    iconElement.fadeOut(fadeOutDuration)
                    textElement.fadeOut(fadeOutDuration)

                    setTimeout(() => {
                        iconElement.attr('class', copiedIcon)
                        textElement.text(copiedText)

                        iconElement.fadeIn(fadeInDuration)
                        textElement.fadeIn(fadeInDuration)

                        setTimeout(() => {
                            iconElement.fadeOut(fadeOutDuration)
                            textElement.fadeOut(fadeOutDuration)

                            setTimeout(() => {
                                iconElement.attr('class', originalIcon)
                                textElement.text(originalText)

                                iconElement.fadeIn(fadeInDuration)
                                textElement.fadeIn(fadeInDuration, () => {
                                    animating = false
                                })
                            }, fadeOutDuration)
                        }, fadeInDuration + copiedMessageDuration)
                    }, fadeOutDuration)
                })

                clipboard.on('error', (e) => {
                    console.error('Error when copying')
                })
            }

            formSpec.schema = {
                type: 'object',
                properties: {
                    jsSnippet: {
                        title: $translate.instant('Widget Javascript code'),
                        type: 'string',
                    },
                    htmlSnippet: {
                        title: $translate.instant('Widget HTML code'),
                        type: 'string',
                    },
                },
            }

            formSpec.form = [
                {
                    key: 'jsSnippet',
                    type: 'textarea',
                    readonly: true,
                    htmlClass: 'mb5',
                    fieldHtmlClass: 'text-code height-120px',
                },
                {
                    type: 'button',
                    icon: 'fa fa-files-o',
                    style: 'btn-hollow',
                    title: 'Copy to clipboard',
                    onClick(event) {
                        initClipboard($(event.currentTarget), jsSnippet)
                    },
                },
                {
                    key: 'htmlSnippet',
                    type: 'textarea',
                    readonly: true,
                    htmlClass: 'mb5 mt30',
                    fieldHtmlClass: 'text-code height-40px',
                },
                {
                    type: 'button',
                    icon: 'fa fa-files-o',
                    style: 'btn-hollow',
                    title: 'Copy to clipboard',
                    onClick(event) {
                        initClipboard($(event.currentTarget), htmlSnippet)
                    },
                },
            ]

            modal.editor(
                {
                    jsSnippet,
                    htmlSnippet,
                },
                {
                    title: 'Get widget code',
                    customFormSpecification: formSpec,
                    showSubmitButton: false,
                },
            )
        }

        function destroy() {
            alert('destroy')
			
        }
    }
})()
