// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { questionAnswerTypeTranslateMap } from '@worldfavor/utils/propertyExtractors'
import '@worldfavor/less/components/question-container.less'
import numeral from 'numeral'

type Props = {
  value: number,
  title?: string,
  subTitle?: string,
  small?: boolean,
  type: string,
  forceShowPercentage?: boolean,
  style: { [string]: any },
}

const AnswerValue = (props: Props) => {
    const { value, title, subTitle, small, type, forceShowPercentage, style } = props
    const titleContent = title || (questionAnswerTypeTranslateMap[type]
      && <FormattedMessage id={questionAnswerTypeTranslateMap[type]} />)
    const tooltipTitle = typeof title === 'string' ? title : undefined

    if (value === 0) {
        return null
    }

    return (
        <div styleName={`wf-item-statistics equalWidths ${small ? 'smallSize' : ''}`} style={style} title={tooltipTitle}>
            <div styleName="total-item">
                <div styleName="total-value">
                    {
                        (value && value < 100 && forceShowPercentage !== false) || forceShowPercentage
                            ? numeral(value).format('0,0.[]') + '%'
                            :  numeral(value).format('0,0.[]')
                    }
                </div>
                {
                    <div styleName="total-title">{ titleContent }</div>
                }
                {
                    subTitle && <div styleName="total-subTitle">{ subTitle }</div>
                }
            </div>
        </div>
    )
}

AnswerValue.defaultProps = {
    value: 0,
    style: {},
}

export default AnswerValue
