// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import Api from '../../api'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Colors from '@worldfavor/constants/colors'
import FilePicker from '@worldfavor/components/FilePicker'
import dateFns from 'date-fns'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import classNames from 'classnames'

const useStyles = makeStyles({
    dialogDescription: {
        fontSize: 14,
    },
    header: {
        fontWeight: 400,
        marginTop: 14,
        marginBottom: 10,
    },
    stepContainer: {
        borderRadius: 4,
        border: '1px solid #eaeaea',
        padding: '0 16px 16px',
        marginTop: 22,
    },
    titleContainer: {
        paddingRight: 40,
    },
    closeButtonWrapper: {
        position: 'absolute',
        top: 5,
        right: 10,
    },
    closeButton: {
        color: Colors.gray,
    },
    filePickerWrapper: {
        marginTop: 12,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    uploadButtonWrapper: {
        marginTop: 8,
        display: 'flex',
    },
    alertDialogContent: {
        display: 'flex',
        alignItems: 'center',
    },
    alertDialogIcon: {
        fontSize: 56,
        marginRight: 20,

        '&.info': {
            color: Colors.blue,
        },
        '&.success': {
            color: Colors.green,
        },
        '&.error': {
            color: Colors.red,
        },
    },
})

type Props = {
  open: boolean,
  organizationName: string,
  solutionType: string,
  networkId: number,
  onClosed: () => void,
  intl: any
}

const DataCollectorImporter = (props: Props) => {
    const { open, organizationName, solutionType, networkId, onClosed, intl } = props
    const classes = useStyles(props)
    const [dialogOpen, setDialogOpen] = useState(open)
    const [loading, setLoading] = useState(false)
    const [fileUploadSuccess, setFileUploadSuccess] = useState(false)
    const [fileData1, setFileData1] = useState()
    const [fileData2, setFileData2] = useState()
    const [fileData, setFileData] = useState()
    const templateUrl = 'https://wfhq.sharepoint.com/:x:/s/worldfavorteam/EUUA2TCGXlhKn2cKz0vNwLEBHqdsPEBuz5jU08im7Cd-Rg?e=fcM7ec'

    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
    const [alertDialogTitle, setAlertDialogTitle] = useState()
    const [alertDialogContent, setAlertDialogContent] = useState()
    const [alertDialogType, setAlertDialogType] = useState()
    const [AlertDialogIconComponent, setAlertDialogIconComponent] = useState()

    useEffect(() => {
        setDialogOpen(open)
    }, [organizationName, solutionType, networkId, open])

    function onClose() {
        if (loading) {
            showBusyUploadingDialog()
            return
        }

        onClosed && onClosed()
        setDialogOpen(false)
        setIsAlertDialogOpen(false)

        setTimeout(() => {
            setLoading(null)
            setFileUploadSuccess(false)
            setFileData1(null)
            setFileData2(null)
            setFileData(null)
            setAlertDialogTitle(null)
            setAlertDialogContent(null)
            setAlertDialogType(null)
            setAlertDialogIconComponent(null)
        }, 250)
    }

    function onFileChangeStep1(file) {
        setFileUploadSuccess(false)
        setFileData1(file)
        setFileData2(null)
        setFileData(file)
    }

    function onFileChangeStep2(file) {
        setFileUploadSuccess(false)
        setFileData2(file)
        setFileData1(null)
        setFileData(file)
    }

    function onFileUploadClick() {
        if (fileUploadSuccess) {
            return
        }

        setLoading(true)
        uploadFile()
    }

    async function uploadFile() {
        try {
            const formData = new FormData()
            formData.append('file', fileData)
            formData.append('networkId', networkId)

            const uploadResult = await Api.post(fileData1
                ? Api.endpoints.importDataCollectorItemsStep1
                : Api.endpoints.importDataCollectorItemsStep2,
            formData, {
                'Content-Type': 'multipart/form-data',
            }, null, { overrideData: true, responseType: 'blob' })

            const now = dateFns.format(new Date(), 'YYMMDD_HHmmss')
            const downloadedFileName = `Network ${networkId} DC Import Review File ${now} CONFIDENTIAL.xlsx`

            setTimeout(() => {
                setFileUploadSuccess(true)
                showSuccessUploadDialog(fileData.name, downloadedFileName)
            }, 250)

            const url = URL.createObjectURL(uploadResult.data)
            const link = document.createElement('a')
            link.href = url
            link.download = downloadedFileName
            link.style = { display: 'none' }
            document.body.append(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)

        } catch {
            setTimeout(() => showErrorDialog(), 250)
        } finally {
            setIsAlertDialogOpen(false)
            setTimeout(() => setLoading(false), 400)
        }
    }

    function showSuccessUploadDialog(uploadedFileName, downloadedFileName) {
        setAlertDialogTitle(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.successUploadTitle' }))
        setAlertDialogContent(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.successUploadDescription' }, {
            uploadedFileName: <strong>{uploadedFileName}</strong>,
            downloadedFileName: <strong>{downloadedFileName}</strong>,
            br: <br />,
        }))
        setAlertDialogType('success')
        setAlertDialogIconComponent(CheckCircleIcon)
        setIsAlertDialogOpen(true)
    }

    function showBusyUploadingDialog() {
        setAlertDialogTitle(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.busyUploadingTitle' }))
        setAlertDialogContent(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.busyUploadingDescription' }))
        setAlertDialogType('info')
        setAlertDialogIconComponent(InfoIcon)
        setIsAlertDialogOpen(true)
    }

    function showErrorDialog() {
        setAlertDialogTitle(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.errorTitle' }))
        setAlertDialogContent(intl.formatMessage({ id: 'dataCollectorImporter.infoDialog.errorDescription' }))
        setAlertDialogType('error')
        setAlertDialogIconComponent(ErrorIcon)
        setIsAlertDialogOpen(true)
    }

    function closeAlertDialog() {
        setTimeout(() => {
            setAlertDialogTitle(null)
            setAlertDialogContent(null)
            setAlertDialogType(null)
            setAlertDialogIconComponent(null)
        }, 500)

        setIsAlertDialogOpen(false)
    }

    function renderFileManager(fileData, disabled, onFileChange, fileSelectorLabel) {
        return (
            <>
                <div className={classes.filePickerWrapper}>
                    <FilePicker
                        disabled={disabled || loading}
                        selectorLabel={fileSelectorLabel}
                        onFileChange={onFileChange}
                        file={fileData}
                    />
                    {fileData && (
                        <div className={classes.uploadButtonWrapper}>
                            <CircularProgressWrapper
                                loading={loading}
                            >
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    style={fileUploadSuccess ? { backgroundColor: Colors.green } : null}
                                    color="primary"
                                    onClick={onFileUploadClick}
                                >
                                    {fileUploadSuccess ? <CheckCircleIcon /> : <FormattedMessage id={'components.filePicker.uploadFile'} />}
                                </Button>
                            </CircularProgressWrapper>
                        </div>
                    )}
                </div>
            </>
        )
    }

    return (
        <>
            <Dialog open={dialogOpen}>
                <DialogTitle>
                    <div className={classes.titleContainer}>
                        <FormattedMessage
                            id={'dataCollectorImporter.dialogTitle'}
                        />
                    </div>
                    <div className={classes.closeButtonWrapper}>
                        <IconButton
                            color="primary"
                            className={classes.closeButton}
                            aria-label={<FormattedMessage id={'exportDialog.close'} />}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <div className={classes.dialogDescription}>
                        <FormattedMessage
                            id={'dataCollectorImporter.dialogDescription'}
                            values={{
                                organizationName: <strong>{organizationName}</strong>,
                                solutionType: <strong>{solutionType}</strong>,
                                networkId: <strong>{networkId}</strong>,
                                br: <br />,
                            }}
                        />
                    </div>

                    <div className={classes.stepContainer}>
                        <h3 className={classes.header}>
                            <FormattedMessage id={'dataCollectorImporter.step1Header'} />
                        </h3>
                        <div>
                            <FormattedMessage
                                id={'dataCollectorImporter.step1Info'}
                                values={{
                                    a: (...chunks) => <a href={templateUrl} target="_blank" rel="noopener noreferrer">{chunks}</a>,
                                    br: <br />,
                                }}
                            />
                        </div>
                        {renderFileManager(fileData1, !!fileData2, onFileChangeStep1, intl.formatMessage({ id: 'dataCollectorImporter.step1FileSelectorButton' }))}
                        <div><FormattedMessage id={'dataCollectorImporter.step1AfterUploadInfo'} /></div>
                    </div>

                    <div className={classes.stepContainer}>
                        <h3 className={classes.header}>
                            <FormattedMessage id={'dataCollectorImporter.step2Header'} />
                        </h3>
                        <div><FormattedMessage id={'dataCollectorImporter.step2Info'} /></div>
                        {renderFileManager(fileData2, !!fileData1, onFileChangeStep2, intl.formatMessage({ id: 'dataCollectorImporter.step2FileSelectorButton' }))}
                        <div><FormattedMessage id={'dataCollectorImporter.step2AfterUploadInfo'} /></div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        <FormattedMessage id={'general.close'} />
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isAlertDialogOpen}>
                <DialogTitle>
                    {alertDialogTitle}
                </DialogTitle>
                <DialogContent>
                    <div className={classes.alertDialogContent}>
                        {AlertDialogIconComponent && <AlertDialogIconComponent className={classNames(classes.alertDialogIcon, alertDialogType)} />}
                        <div>
                            {alertDialogContent}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlertDialog} color="secondary">
                        <FormattedMessage id={'general.ok'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default injectIntl(DataCollectorImporter)
