import { resolve } from 'upath'
import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('wfTranslate', wfTranslate)

    wfTranslate.$inject = ['$translate', '$parse', '$q']

    function wfTranslate($translate, $parse, $q) {
        const service = {
            instant,
            tryInstant,
            concat,
        }
        return service
		
        function tryInstant(translationId, callback) {
            const deferred = $q.defer()
            // A missing translation rejects with the translationId by default.
            // Here we instead fire the callback and pass undefined.
            $translate(translationId)
                .then((text) => {
                    callback(text)
                    deferred.resolve(text)
                })
                .catch(() => {
                    callback()
                    deferred.resolve()
                })

            return deferred.promise
        }
		
        function instant(key, translateParams) {
            let
                translationId

            if (key instanceof Array) {
                return concat(key)
            }
            else {
                if (key == 'MAP_ObjectType') {
                    translationId = getObjectTypeKey(translateParams.type, { plural: translateParams.plural, IA: translateParams.IA, DA: translateParams.DA })
                }
                else if (key == 'MAP_ObjectType_New') {
                    // TODO: Return New or New_Neutrum
                }
                else if (key == 'MAP_EventType') {
                    translationId = getEventTypeKey(translateParams.type, translateParams.pastTense)
                }
                else translationId = key
				
                return $translate.instant(translationId)
            }
        }

        function concat(keys) {
            return _.map(keys, (translateOptionsOrKey) => {
                let output

                if (typeof translateOptionsOrKey === 'string') output = $translate.instant(translateOptionsOrKey)
                else {
                    output = $translate.instant(translateOptionsOrKey.key)
                    if (output && translateOptionsOrKey.textTransform === 'lowercase') output = output.toLowerCase()
                }

                return output
            }).join(' ')
        }

        function getEventTypeKey(id, pastTense) {
            let
                key
			
            if (!id) return

            switch (id) {
                case enums.eventType.create:
                    key = pastTense ? 'Created' : 'Create'
                    break
                    //case wf.eventType.read:
                    //	break;
                case enums.eventType.update:
                    key = pastTense ? 'Updated' : 'Update'
                    break
                case enums.eventType.delete:
                    key = pastTense ? 'Deleted' : 'Delete'
                    break
                case enums.eventType.fulfill:
                    key = 'NowFulfilling'
                    break
                case enums.eventType.unfulfill:
                    key = 'NoLongerFulfilling'
                    break
                case enums.eventType.sign:
                    key = 'modules.notifications.eventSentences.influenceSigned'
                    break
                default:
                    key = 'NotDefined'
                    break
            }
            return key
        }
	
        function getObjectTypeKey(id) {
            const
                settings = typeof arguments[1] === 'object' ? arguments[1] : {}
				
            const usePlural = arguments[1] === true || settings.plural
				
            const useIndefiniteArticle = settings.IA
            // Use indefinite articles (grammatical term). Means that "a" or "an" will be added in front of the word (or the equivalents in other languages)
				
            const useDefiniteArticle = settings.DA
            // Use definite articles (grammatical term). Means that "the" will be added in front of the word (or the equivalents in other languages)
				
            const useDescription = settings.descr
				
            let key
			
            if (!id) return

            switch (id) {
                case enums.objectType.orgActivity:
                    key = usePlural ? 'Activities' : 'Activity'
                    break
                case enums.objectType.orgDocument:
                    key = usePlural ? 'Documents' : 'Document'
                    break
                case enums.objectType.measure:
                    key = usePlural ? 'Measures' : 'Measure'
                    break
                case enums.objectType.relativeMeasure:
                    key = usePlural ? 'RelativeMeasures' : 'RelativeMeasure'
                    break
                case enums.objectType.question:
                    key = usePlural ? 'Questions' : 'Question'
                    break
                case enums.objectType.link:
                    key = usePlural ? 'Links' : 'Link'
                    break
                case enums.objectType.statement:
                    key = usePlural ? 'Statements' : 'Statement'
                    break
                case enums.objectType.measureAnswer:
                    key = usePlural ? 'MeasureAnswers' : 'MeasureAnswer'
                    break
                case enums.objectType.questionAnswer:
                    key = usePlural ? 'QuestionAnswers' : 'QuestionAnswer'
                    break
                case enums.objectType.principlesOfSR:
                    key = usePlural ? 'PrinciplesOfISO26k' : 'PrinciplesOfISO26k'
                    break
                case enums.objectType.layer:
                    key = usePlural ? 'Filters' : 'Filter_Noun'
                    break
                case enums.objectType.layerItem:
                    key = usePlural ? 'FilterContents_Noun' : 'FilterContent_Noun'
                    break
                case enums.objectType.userInfo:
                    key = usePlural ? 'OrganizationInfo' : 'OrganizationInfo'
                    break
                case enums.objectType.multipleContent:
                    key = usePlural ? 'MultipleTypes' : 'MultipleTypes'
                    break
                case enums.objectType.page:
                    key = usePlural ? 'Pages' : 'Page'
                    break
                case enums.objectType.network:
                    key = usePlural ? 'Networks' : 'Network'
                    break
                case enums.objectType.organization:
                    key = usePlural ? 'Organizations' : 'Organization'
                    break
                case enums.objectType.image:
                    key = usePlural ? 'Images' : 'Image'
                    break
                case enums.objectType.fulfillment:
                    key = usePlural ? 'Fulfillments' : 'Fulfillment'
                    break
                case enums.objectType.influence:
                    key = usePlural ? 'Influences' : 'Influence_Noun'
                    break
                case enums.objectType.condition:
                    key = usePlural ? 'Conditions' : 'Condition'
                    break
                case enums.objectType.tagCollection:
                    key = usePlural ? 'TagCollections' : 'TagCollection'
                    break
                case enums.objectType.menuItem:
                    key = usePlural ? 'MenuItems' : 'MenuItem'
                    break
                case enums.objectType.addon:
                    key = usePlural ? 'Addons' : 'Addon'
                    break
                case enums.objectType.workTask:
                    key = usePlural ? 'WorkTasks' : 'WorkTask'
                    break
                case enums.objectType.location:
                    key = usePlural ? 'Locations' : 'Location'
                    break
                case enums.objectType.notification:
                    key = usePlural ? 'Notifications' : 'Notification'
                    break
                case enums.objectType.requirement:
                    key = usePlural ? 'Requirements' : 'Requirement'
                    break
                case enums.objectType.visibilityTag:
                    key = usePlural ? 'VisibilityTags' : 'VisibilityTag'
                    break
                case enums.objectType.endpointPermission:
                    key = usePlural ? 'EndpointPermissions' : 'EndpointPermission'
                    break
                case enums.objectType.menuPermission:
                    key = usePlural ? 'MenuPermissions' : 'MenuPermission'
                    break
                case enums.objectType.individual:
                case 1000:
                    key = usePlural ? 'Users' : 'User'
                    break
                case enums.objectType.orgInputMethod:
                    key = usePlural ? 'OrgInputMethod' : 'OrgInputMethod'
                    break
                case enums.objectType.internalComment:
                    key = usePlural ? 'InternalComments' : 'InternalComment'
                    break
                case enums.objectType.verification:
                    key = usePlural ? 'Verifications' : 'Verification'
                    break
                case enums.objectType.structure:
                    key = usePlural ? 'Structures' : 'Structure'
                    break
                case enums.objectType.embed:
                    key = usePlural ? 'Embeds' : 'Embed'
                    break
                case enums.objectType.accessTag:
                    key = usePlural ? 'Access' : 'Access'
                    break
                case enums.objectType.parameter:
                    key = usePlural ? 'Parameters' : 'Parameter'
                    break
                case enums.objectType.parameterValue:
                    key = usePlural ? 'ParameterValues' : 'ParameterValue'
                    break
                case enums.objectType.billingInformation:
                    key = usePlural ? 'BillingInformations' : 'BillingInformation'
                    break
                case enums.objectType.invoice:
                    key = usePlural ? 'Invoices' : 'Invoice'
                    break
                case enums.objectType.certificate:
                    key = usePlural ? 'Certificates' : 'Certificate'
                    break
                case enums.objectType.productionSite:
                    key = usePlural ? 'ProductionSites' : 'ProductionSite'
                    break
                case enums.objectType.sustainabilityRoom:
                    key = usePlural ? 'SustainabilityRooms' : 'SustainabilityRoom'
                    break
                case enums.objectType.mailSettings:
                    key = usePlural ? 'MailSettings' : 'MailSetting'
                    break
                case enums.objectType.person:
                    key = usePlural ? 'People' : 'Person'
                    break
                case enums.objectType.analyzeRequirement:
                    key = usePlural ? 'AnalyzeRequirements' : 'AnalyzeRequirement'
                    break
                case enums.objectType.analyzeJob:
                    key = usePlural ? 'AnalyzeJobs' : 'AnalyzeJob'
                    break
                case enums.objectType.supplier:
                    key = usePlural ? 'Suppliers' : 'Supplier'
                    break
                case enums.objectType.holding:
                    key = usePlural ? 'PortfolioCompany' : 'PortfolioCompanies'
                    break
                case enums.objectType.finding:
                    key = usePlural ? 'Findings' : 'Finding'
                    break
                case enums.objectType.findingStepAnswer:
                    key = usePlural ? 'FindingStepAnswers' : 'FindingStepAnswer'
                    break
                case enums.objectType.country:
                    key = usePlural ? 'Countries' : 'Country'
                    break
                case enums.objectType.productService:
                    key = usePlural ? 'ProductServices' : 'ProductService'
                    break
                case enums.objectType.dateItem:
                    key = usePlural ? 'Dates' : 'Date'
                    break
                default:
                    return ''

					//return wfml.Other + " (" + (function (id) {
					//	for (var i in enums.objectType) {
					//		if (enums.objectType[i] == id)
					//			return i;
					//	}

					//	return id + ", unknown";
					//})(id) + ")";
            }

            if (useIndefiniteArticle) key += '_IA'
            else if (useDefiniteArticle) key += '_DA'
            else if (useDescription) key += '_Description'

            return key
        }
    }
})()
