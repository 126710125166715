import type { Item } from '@worldfavor/types'
import xor from 'lodash/xor'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import createCachedSelector from 're-reselect'
// import { createSelectorWithDependencies as createSelector } from 'reselect-tools'
import {
    getFromWfidAttribute,
    getToWfidAttribute,
    identity,
    _arg1,
} from '@worldfavor/utils/selectors'

export const _allWfObjects = (state): { [string]: Item } => state.data
export const _wfObjectById = (state, id): Item => state.data[id]

export const getEdgeAndOutNodeFromEdge = (items, edge) => {
    if (edge) {
        const toWfidAttribute = getToWfidAttribute(edge)
        return {
            edge,
            node: (items && toWfidAttribute) ? items[toWfidAttribute] : null,
        }
    }
    return null
}
export const _getNodesFromEdgeIds = (items, edgeIds) => (
    edgeIds.map(edgeId => getEdgeAndOutNodeFromEdge(items, items[edgeId]))
)

/**
 * Get all sub nodes from an edge wfid.
 *
 * Considering the following hierarchy:
 *  edge (top)
 *    node
 *      edge (edgeChild1)
 *        node (nodeChild1)
 *      edge (edgeChild2)
 *        node (nodeChild2)
 *      ...
 * and the passed edge wfid is top.wfid,
 * the result array will contain:
 * [
 *    { edge: edgeChild1, node: nodeChild1 },
 *    { edge: edgeChild2, node: nodeChild2 },
 *    ...
 * ]
 * @returns {*}
 */
export const getSubNodesFromEdgeId = createCachedSelector(
    [_wfObjectById, _allWfObjects],
    (fromEdge, items) => {
        if (!fromEdge) {
            return []
        }

        return Object.values(items)
            .filter(item => getFromWfidAttribute(item) === getToWfidAttribute(fromEdge))
            .sort((a, b) => a.order - b.order)
            .map(item => getEdgeAndOutNodeFromEdge(items, item))
    },
)((state, id) => id)

export const getSubNodesFromNodeId = createCachedSelector(
    [_wfObjectById, _allWfObjects],
    (fromNode, items) => {
        if (!fromNode) {
            return []
        }

        return Object.values(items)
            .filter(item => getFromWfidAttribute(item) === fromNode.wfid)
            .sort((a, b) => a.order - b.order)
            .map(item => getEdgeAndOutNodeFromEdge(items, item))
    },
)((state, id) => id)

/**
 * Get a { edge, node } from an edge wfid.
 *
 * Considering the following hierarchy
 *  ...
 *    edge
 *      node
 *   ...
 *   and the passed edge wfid is edge.wfid,
 *   the result object will contain:
 *    { edge, node }
 * @returns {*}
 */
export const getNodeFromEdgeId = createCachedSelector(
    [_wfObjectById, _allWfObjects],
    (item, items) => (
        item && getToWfidAttribute(item) ? {
            edge: item,
            node: items[getToWfidAttribute(item)],
        } : null
    ),
)((state, id) => id)

export const getNodeFromNodeId = createCachedSelector(
    [_wfObjectById],
    identity,
)((state, id) => id)

export const getNodesFromEdgeIds = createCachedSelector(
    [_allWfObjects, _arg1],
    _getNodesFromEdgeIds,
)((state, ids) => ids)

/**
 * Create reselect function for wfObject
 * with its own parameter comparison
 * based on the keys of the objects.
 *
 * Invalidate the memoized cache only if the
 * symmetric difference between the two objects is not empty
 */
export const createWfObjectSelector = createSelectorCreator(
    defaultMemoize,
    (a, b) => xor(Object.keys(a), Object.keys(b)).length === 0,
)

export const getEdgesFromToNodeId = createCachedSelector(
    [_allWfObjects, _arg1],
    (items, toWfid) => Object.values(items).filter(item => getToWfidAttribute(item) && getToWfidAttribute(item) === toWfid),
)((state, toWfid) => toWfid)

// export const getOrganizationFromAnalyzeJob = createCachedSelector(
//   [getNodeFromNodeId],
//   (state, item) => {
//     if (item) {
//       console.log('job', item, '101-' + item.targetOrganizationId, getNodeFromNodeId('101-' + item.targetOrganizationId))
//       return ('101-' + item.targetOrganizationId)
//     }
//   },
// )((state, analyzeJobId) => analyzeJobId)
