// @flow
import React from 'react'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

type Props = {
  open: boolean,
  onCancel: () => void,
  onContinue?: () => void,
}

const CoMapperInfoDialog = (props: Props) => {
    const { open, onCancel, onContinue, ...rest } = props

    function _onContinue() {
        onCancel()
        setTimeout(() => {
            onContinue && onContinue()
        }, 100)
    }
  
    return (
        <Dialog open={open} onClose={onCancel} {...rest}>
            <DialogTitle>
                <FormattedMessage id={'supplyChain.mapper.coMapperOrganization.infoDialog.title'} />
            </DialogTitle>
            <DialogContent>
                <span>
                    <FormattedMessage id={'supplyChain.mapper.coMapperOrganization.infoDialog.description'} />
                </span>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button onClick={_onContinue} color="secondary">
                    <FormattedMessage id={'general.continue'} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CoMapperInfoDialog
