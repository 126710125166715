import React from 'react'
import { react2angular } from 'react2angular'
import AttachedInfoPercentages from '@worldfavor/components/Items/AttachedInfoPercentages'
import { WrapProvider } from './providers'

const props = [
  'title',
  'percentageValuesWithTitles',
  'valueColor',
]

class AttachedInfoPercentagesWrapper extends React.Component {

  prepareProps = (props) => {
    return {
      ...props,
    }
  }

  render() {
    const { wfAuth, ...rest } = this.props
    return (
      <WrapProvider wfAuth={wfAuth}>
        <AttachedInfoPercentages {...this.prepareProps(rest)} />
      </WrapProvider>
    )
  }
}

export default react2angular(AttachedInfoPercentagesWrapper, props, ['wfAuth'])
