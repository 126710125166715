import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import Divider from '@worldfavor/components/Divider'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { capitalize } from '@worldfavor/utils/helpers'
import MappingStatus from './MappingStatus'
import Image from '@worldfavor/components/Image'
import CoMapperStatus from './CoMapperStatus'
import { ConstVars } from '@worldfavor/constants'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    sizeSmall: {
        '& $pictureContainer': {
            width: 68,
        },
        '& $content': {
            maxWidth: 260,
            minWidth: 200,
            boxSizing: 'border-box',

            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 16,
            paddingRight: 20,
        },
        '& $name': {
            fontSize: 16,
            marginBottom: 6,
            fontWeight: theme.typography.fontWeightMedium,

            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    sizeLarge: {
        '& $pictureContainer': {
            width: 310,
        },
        '& $detailContainer': {
            flexDirection: 'column',

            '& >:not(:last-child)': {
                marginBottom: 26,
            },
        },
    },
    pictureContainer: {
        width: 164,
        margin: 8,
        flexShrink: 0,
    },
    content: {
        flex: 1,
        backgroundColor: hexToRgbA(Colors.black, 0.1),
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 50,
        paddingRight: 50,
    },
    name: {
        fontSize: 32,
        color: theme.palette.primary.main,
        marginBottom: 32,
        maxWidth: 800,
    },
    subtitle: {
        fontSize: 12,
        color: hexToRgbA(Colors.black, 0.87),
    },
    detailContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 860,
    },
    iconContainer: {
        width: 25,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
        flexShrink: 0,
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: '100%',
        backgroundColor: Colors.gray,
    },
    activeDot: {
        backgroundColor: Colors.green,
    },
    iconFlag: {
        width: 25,
        height: 25 / (4 / 3),
        borderRadius: 2,
    },
    supplierPicture: {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    flag: {
        fontSize: 13,
        color: 'A8A8A8',
    },
    flagPartiallyMapped: {
        color: Colors.orange,
    },
    flagFullyMapped: {
        color: Colors.green,
    },
    coMapperStatus: {
        justifyContent: 'space-between',
    },
}))

const useTableStyles = makeStyles(theme => ({
    table: {
        display: 'table',
        minWidth: 400,
        maxWidth: 450,
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: theme.shadows[1],
    },
    sizeLarge: {
        minWidth: 'initial',
        maxWidth: 'initial',
    },
    row: {
        display: 'flex',
        height: 45,
    },
    cell: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: 45,
        paddingLeft: 16,
        paddingRight: 16,
        overflow: 'hidden',
    },
}))

const Table = (props) => {
    const { data, renderLabel, renderValue, size } = props
    const classes = useTableStyles(props)
    return (
        <div
            className={classNames(classes.table, {
                [classes[`size${capitalize(size)}`]]: size !== 'medium',
            })}
        >
            {
                data.map((item, index) => (
                    <React.Fragment
                        key={`row-${index}`}
                    >
                        <div className={classes.row}>
                            <div className={classes.cell}>
                                { renderLabel({ item, index }) }
                            </div>

                            <Divider width={2} color={'#E0E0E0'} />

                            <div className={classes.cell}>
                                { renderValue({ item, index }) }
                            </div>
                        </div>

                        {
                            index < data.length - 1 && <Divider height={2} color={'#E0E0E0'} />
                        }
                    </React.Fragment>
                ))
            }
        </div>
    )
}

const renderTableLabel = ({ item }) => {
    return <FormattedMessage id={`supplyChain.productDetails.${item.label}`} />
}

const TextOrNA = (props) => {
    return (
        <div
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            { props.text ? props.text : 'N/A' }
        </div>
    )
}

const ProductDetail = (props) => {
    const { product, supplier, coMapperOptions, size, style } = props
    const { networkWfid } = coMapperOptions || {}
    const classes = useStyles(props)
    const { imageSource: imageUrl, name, isActive, originCountry } = product

    function renderTableValue({ item }) {
        switch (item.label) {
            case 'status':
                return (
                    <React.Fragment>
                        <div className={classes.iconContainer}>
                            <div className={classNames(classes.dot, isActive && classes.activeDot)} />
                        </div>
                        <FormattedMessage id={`general.${isActive ? 'active' : 'inactive'}`} />
                    </React.Fragment>
                )

            case 'origin': {
                return (
                    <React.Fragment>
                        {
                            originCountry && originCountry.iso3166_alpha2 && (
                                <div className={classes.iconContainer}>
                                    <div
                                        className={classNames(classes.iconFlag,
                                            'flag-4-3 flag-icon-background',
                                            `flag-icon-${originCountry.iso3166_alpha2}`)}
                                    />
                                </div>
                            )
                        }
                        {(originCountry && originCountry.name) || 'N/A'}
                    </React.Fragment>
                )
            }

            case 'supplier':
                return supplier && (
                    <React.Fragment>
                        <div className={classes.iconContainer}>
                            <Image
                                className={classes.supplierPicture}
                                src={supplier.imageUrl}
                                noPlaceholder
                            />
                        </div>
                        {supplier.name}
                    </React.Fragment>
                )

            case 'coMapper': {
                if (!coMapperOptions) {
                    return null
                }

                const {
                    influenceWfid,
                    productCoMapper,
                    uniqueProductSuppliers,
                } = coMapperOptions

                return (
                    <CoMapperStatus
                        allowAdding={networkWfid !== `52-${ConstVars.SystembolagetNetworkId}`}
                        organizationImage={productCoMapper && productCoMapper.imageUrl}
                        organizationName={productCoMapper && productCoMapper.name}
                        uniqueOrganizations={uniqueProductSuppliers}
                        mappingEntityWfid={product.wfid}
                        influenceWfid={influenceWfid}
                        networkWfid={networkWfid}
                        classes={{ container: classes.coMapperStatus }}
                        compact
                        compactButton
                    />
                )
            }

            case 'mappingStatus':
                return (
                    <MappingStatus
                        level={product.transparencyLevel}
                        mappingEntity={product}
                        compact
                        compactButton
                        enableEditing={product && product.canDoMapping && networkWfid !== `52-${ConstVars.SystembolagetNetworkId}`}
                    />
                )

            case 'gtin':
                return <TextOrNA text={product.gtin} />

            case 'productSupplierNumber':
                return <TextOrNA text={product.productSupplierNumber} />

            case 'productNumber':
                return <TextOrNA text={product.productNumber} />

            default:
                break
        }

        return 'N/A'
    }

    return (
        <Paper
            classes={{ root: classes.root }}
            className={classNames({
                [classes[`size${capitalize(size)}`]]: size !== 'medium',
            })}
            style={style}
        >
            <Image
                src={imageUrl}
                className={classes.pictureContainer}
            />

            <div className={classes.content}>
                <div className={classes.name}>{name}</div>

                {
                    (size === 'large' || size === 'medium') ? (
                        <div className={classes.detailContainer}>
                            <Table
                                data={[
                                    { label: 'gtin' },
                                    { label: 'productSupplierNumber' },
                                    { label: 'productNumber' },
                                    { label: 'status' },
                                ]}
                                renderLabel={renderTableLabel}
                                renderValue={renderTableValue}
                                size={size}
                            />

                            <Table
                                data={[
                                    { label: 'origin' },
                                    { label: 'supplier' },
                                    { label: 'coMapper' },
                                    { label: 'mappingStatus' },
                                ]}
                                renderLabel={renderTableLabel}
                                renderValue={renderTableValue}
                                size={size}
                            />
                        </div>
                    ) : (
                        <div className={classes.subtitle}>
                            {product.gtin}
                        </div>
                    )
                }
            </div>
        </Paper>
    )
}

ProductDetail.defaultProps = {
    size: 'medium',
    supplier: {},
}

export default ProductDetail
