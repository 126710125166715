(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfExpandTrigger', wfExpandTrigger)

    wfExpandTrigger.$inject = ['$parse']
    function wfExpandTrigger($parse) {
        const directive = {
            link,
            restrict: 'A',
            require: '^^wfExpand',
        }
        return directive
		
        function link(scope, element, attrs, controller) {
            const options =
				_.assign({
				    rightSideArrow: false,
				    leftSideArrow: false,
				    enabled: true,
				}, $parse(attrs.wfExpandTrigger)(scope))

            if (controller.options.allowExpand && options.enabled) {
                if (options.rightSideArrow) element.addClass('right-side-arrow')
                else if (options.leftSideArrow) element.addClass('left-side-arrow')
				
                element.on('click.wf-expand', _.throttle(() => {
                    controller.toggleExpand()
                }, 500, { trailing: false }))
				
                scope.$on('$destroy', () => { element.off('click.wf-expand') })
            }
            else {
                element.removeAttr('wf-expand-trigger')
                return false
            }

        }
    }

})()
