import * as React from 'react'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import OrganizationSelector from '@worldfavor/components/Organization/Settings/OrganizationSelector'
import Loading from './Loading'
import Colors from '@worldfavor/constants/colors'

import { loadItem } from '../actions/dataThunk'
import { getNodeFromEdgeId, getSubNodesFromEdgeId } from '../selectors/dataSelector'
import { getService } from '../AngularServices'

const styles = theme => ({
    root: {
        flexDirection: 'column',
    },
    backSection: {
        alignSelf: 'flex-start',
        marginTop: 30,
        marginBottom: 14,

        color: Colors.blue,

        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    header: {
        alignSelf: 'flex-start',
        paddingTop: 5,
        paddingBottom: 32,
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '34px',
        color: Colors.blackDark,
        marginBottom: 20,
    },
    subTitle: {
        fontSize: 13,
        marginBottom: 8,
    },
    organizationSelectorContainer: {
        padding: theme.spacing(3),
    },
})

const ChangeOrganization = ({ classes, history, authUser, loadItem, structure, organizations }) => {

    const [loading, setLoading] = useState(true)

    function loginAsOrg(wfid) {
        const wfAuth = getService('wfAuth')
        wfAuth && wfAuth.signIn({ organizationId: parseInt(wfid.match(/\d*-(\d*)/)[1]) })
    }

    async function fetchData() {
        const structureItem = await loadItem(43051, 73, {
            loadParents: false,
            loadMetadata: false,
            loadVisibilityTags: false,
            getterConditions: {
                loadCreators: false,
                includeOrganizations: false,
            },
        })
        document.title = `${structureItem.childContent.title} - Worldfavor`
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <Grid container className={classes.root}>
            <div className={classes.backSection} onClick={history.goBack}>
                <i className="fas fa-arrow-left" style={{ marginRight: 3 }} />
                <FormattedMessage id={'general.back'} />
            </div>
            <div className={classes.header}>
                <h1 className={classes.title}>{structure.node.title}</h1>
                <div className={classes.subTitle}>{structure.node.description}</div>
            </div>
            <Grid item xs={12} md={8}>
                <Paper elevation={1} className={classes.organizationSelectorContainer}>
                    <OrganizationSelector
                        selectedOrganizationId={`101-${authUser.orgId}`}
                        organizations={organizations.map(({ node }) => node)}
                        onSignIn={loginAsOrg}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        structure: getNodeFromEdgeId(state, '73-43051'),
        organizations: getSubNodesFromEdgeId(state, '73-43051'),
        authUser: state.app.authUser,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadItem,
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangeOrganization)))
