// @flow
import * as React from 'react'
import debounce from 'lodash/debounce'

type Props = {
  data: Array<any>,
  keyExtractor: (any, number) => string,
  renderItem: ({ item: any, index: number }) => React.Node,
  onEndReached: () => any,
  ItemSeparatorComponent?: React.ComponentType<any>,
  getScrollParent?: () => HTMLElement,
}

class FlatList extends React.PureComponent<Props> {

  static defaultProps = {
      onEndReached: () => {},
  }

  debouncedOnScroll: ?(*) => any = null
  scrollElement: ?Node = null
  bottomElem: ?HTMLElement = null

  componentDidMount() {
      const { getScrollParent } = this.props
      if (getScrollParent) {
          this.scrollElement = getScrollParent()
      }
      else if (this.bottomElem) {
          this.scrollElement = this.bottomElem.parentElement
      }
      else {
          return
      }

      this.debouncedOnScroll = debounce(this._onScroll, 50)
      this.scrollElement && this.scrollElement.addEventListener('scroll', this.debouncedOnScroll)
      this.debouncedOnScroll()
  }

  componentDidUpdate(prevProps: Props) {
      if (this.props.data !== prevProps.data && this.debouncedOnScroll) {
          this.debouncedOnScroll()
      }
  }

  componentWillUnmount() {
      if (this.scrollElement && this.debouncedOnScroll) {
          this.scrollElement.removeEventListener('scroll', this.debouncedOnScroll)
      }
  }

  _onScroll = () => {
      const { onEndReached } = this.props
      if (this._isInViewport()) {
          onEndReached()
      }
  }

  _extractKey = (item: any, index: number) => {
      const { keyExtractor } = this.props
      return keyExtractor ? keyExtractor(item, index) : item.key
  }

  _isInViewport(offset: number = 0) {
      if (!this.bottomElem) return false
      const { top } = this.bottomElem.getBoundingClientRect()
      return (top + offset) >= 0 && (top - offset) <= window.innerHeight
  }

  render() {
      const { data, renderItem, ItemSeparatorComponent } = this.props
      return (
          <React.Fragment>
              {
                  data.map((item, index) => (
                      <React.Fragment
                          key={this._extractKey(item, index)}
                      >
                          { index > 0 && ItemSeparatorComponent && <ItemSeparatorComponent />}
                          { renderItem({ item, index }) }
                      </React.Fragment>
                  ))
              }
              <div ref={el => this.bottomElem = el} />
          </React.Fragment>
      )
  }
}

export default FlatList
