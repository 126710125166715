//@flow
import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 24,
    },
    title: {
        fontSize: 24,
        paddingBottom: 12,
        color: Colors.blackDark,
        textAlign: 'center',
    },
    description: {
        fontSize: 14,
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
    inviteButton: {
        marginTop: 20,
        backgroundColor: Colors.vividPurple,
        color: 'white',
        textAlign: 'center',
        '&:hover': {
            color: 'white',
            backgroundColor: hexToRgbA(Colors.vividPurple, 0.8),
        },
    },
})

const ColleagueInviteCard = ({ classes, className }) => {
    return (
        <Paper className={classNames(classes.root, className)} elevation={2}>
            <div className={classes.title}>
                <FormattedMessage id={'networkExchanges.inviteColleague.title'} />
            </div>
            <div className={classes.description}>
                <FormattedMessage id={'networkExchanges.inviteColleague.description'} />
            </div>
            <Button className={classes.inviteButton} component={Link} to={`/account/organization/users`}>
                <FormattedMessage id={'networkExchanges.inviteColleague.buttonLabel'} />
            </Button>
        </Paper>
    )
}

export default withStyles(styles)(ColleagueInviteCard)
