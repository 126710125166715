import * as enums from '@worldfavor/constants/enums'
import urls from '@worldfavor/constants/urls'
import { ConstVars } from '@worldfavor/constants'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfFulfillmentPageBar', {
            templateUrl: 'scripts/wf/requirements/wfFulfillmentPageBar.component.html',
            controller: wfFulfillmentPageBarController,
            controllerAs: 'vm',
            bindings: {
                itemComposites: '<',
                loader: '<',
            },
        })

    wfFulfillmentPageBarController.$inject = ['$scope', 'wfAuth', 'dataQuery', '$q', '$element', 'dataOperationsService', '$translate', '$timeout', 'requirements', '$uibModal', '$ngBootbox', '$sanitize', 'valueChainService', 'formSchemaService', 'modalService', 'apiProxy']
    function wfFulfillmentPageBarController($scope, wfAuth, dataQuery, $q, $element, dataOps, $translate, $timeout, requirements, $uibModal, $ngBootbox, $sanitize, valueChainService, formSchemaService, modalService, apiProxy) {
        const vm = this
        let publishConfirmationModalInstance = undefined

        _.assign(vm, {
            barLoading: true,
            previewLoading: false,
            publishLoading: false,

            useZero: true,
            verifications: undefined,
            previousRequirementsLeftCount: 0,
            documentsButtonSubCaption: undefined,
            documentRelations: [],
            enableSigning: false,

            lastPublishedAt: undefined,
            previewProfileUrl: undefined,
            publishedProfileUrl: undefined,
            publishedProfile: undefined,
            enableProfileOptions: false,
            viewProfileTooltipText: $translate.instant('modules.report.publicProfileOptions.noUrlTooltipText'),
            profileSettingsForm: {
                model: {},
                formControl: {},
                formSpec: {},
            },

            openPublishedProfile,
            previewProfile,
            publishProfile,
            openPublishConfirmationModal,
            openSigningModal,
            saveProfileSettings,
            openProfileSettings,
            openPreviouslySignedDocumentsModal,
            influenceFulfillmentCalculator: vm.loader.influence && requirements.influenceFulfillmentCalculator(vm.loader.influence),
        })

        const calculateInfluenceFulfillment_throttled = _.throttle(() => {
            vm.influenceFulfillmentCalculator.calculate(false, (influence, fulfillment) => {
                if (vm.requirementsLeftCount === 0 && vm.previousRequirementsLeftCount > 0 && !vm.enableSigning) {
                    valueChainService.openInfluenceCompletionSuccessModal()
                }

                vm.previousRequirementsLeftCount = vm.requirementsLeftCount
            })
        }, 2000)

        vm.$onInit = function() {
            $timeout(() => vm.useZero = false, 2000)

            vm.loader.syncFulfillmentProgressBar = syncProgress
            if (vm.loader.influence) {
                vm.enableSigning = _.get(vm.loader.influence, 'childContent.conditions.showSigning') != false && !(vm.loader.ticket && vm.loader.ticket.thirdParty)
                vm.isReadonly = vm.loader.influence.isReadonly

                initPublicProfileBar(vm.profileSettings).then(() => {
                    vm.barLoading = false
                })

                if (vm.enableSigning) {
                    getInfluenceVerificationDocuments()
                }
            }
            else {
                vm.barLoading = false
            }

            syncProgress()
        }

        function openPreviouslySignedDocumentsModal() {
            if (vm.documentRelations.length) {
                $uibModal.open({
                    animation: true,
                    size: 'width-900',
                    backdrop: 'static',
                    windowClass: 'wf-signed-documents-modal',
                    templateUrl: 'scripts/wf/requirements/wfSignedDocumentsModal.template.html',
                    scope: $scope,
                })
            }
        }

        function getInfluenceVerificationDocuments() {
            vm.documentRelations = valueChainService.getInfluenceVerificationDocuments(vm.loader.influence)
            // vm.documentRelations.length = 0;
            if (vm.documentRelations.length) {
                vm.documentsButtonSubCaption = $translate.instant('modules.valueChain.influence.lastSignedAt') + ' ' + moment(vm.documentRelations[0].createdAt).format('D MMM YYYY HH:mm')
            }
            else {
                vm.documentsButtonSubCaption = $translate.instant('modules.valueChain.influence.notSignedYet')
            }

            return vm.documentRelations
        }

        function syncProgress() {
            if ($scope.$$destroyed) return

            vm.stats = vm.loader.fulfillmentStatistics

            if (vm.stats) {
                vm.progressBarPercentages = {
                    fulfilled: vm.stats.fulfilled.percentage ? vm.stats.fulfilled.percentage : 0,
                    partiallyFulfilled: vm.stats.partiallyFulfilled.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage : 0,
                    unfulfilled: vm.stats.unfulfilled.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage + vm.stats.unfulfilled.percentage : 0,
                    expired: vm.stats.expired.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage + vm.stats.unfulfilled.percentage + vm.stats.expired.percentage : 0,
                    certificateExpired: vm.stats.certificateExpired.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage + vm.stats.unfulfilled.percentage + vm.stats.expired.percentage + vm.stats.certificateExpired.percentage  : 0,
                    reportingNeeded: vm.stats.reportingNeeded.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage + vm.stats.unfulfilled.percentage + vm.stats.expired.percentage + vm.stats.reportingNeeded.percentage : 0,
                    assessmentNeeded: vm.stats.assessmentNeeded.percentage ? vm.stats.fulfilled.percentage + vm.stats.partiallyFulfilled.percentage + vm.stats.unfulfilled.percentage + vm.stats.expired.percentage + vm.stats.reportingNeeded.percentage + vm.stats.assessmentNeeded.percentage : 0,
                }

                const requirementCompleted = vm.stats.fulfilled.count + vm.stats.partiallyFulfilled.count + vm.stats.assessmentNeeded.count
                vm.requirementsLeftCount = vm.stats.itemsWithRequirement.count - requirementCompleted
                vm.requirementsCompletedPercentage = _.round(requirementCompleted / vm.stats.itemsWithRequirement.count * 100, 0)

                if (vm.previousRequirementsLeftCount > 0 && vm.requirementsLeftCount === 0) {
                    vm.pulsateSignButton = true
                }

                setTimeout(() => {
                    if (vm.loader.analyzingItemsCount) {
                        return
                    }
                    if (vm.loader.syncingItems) {
                        return
                    }
                    if (vm.isReadonly) {
                        return
                    }

                    calculateInfluenceFulfillment_throttled()
                }, 1000)

                if (!vm.firstSyncDone) {
                    vm.previousRequirementsLeftCount = vm.requirementsLeftCount
                }
            }

            vm.firstSyncDone = true
        }

        function openSigningModal() {
            let itemsLeftString

            if (vm.isReadonly) {
                return
            }

            if (vm.stats.unfulfilledItems.length) {
                const numbers = vm.stats.unfulfilledItems.map(x => '<b>' + $sanitize(x.numbering) + '</b>')
                let message = [
                    $translate.instant('modules.valueChain.influence.message_questionsLeft'),
                    '',
                    $translate.instant('modules.valueChain.influence.message_haveToFulfillQuestions'),
                ].join('<br />')

                if (numbers.length > 1) {
                    // &nbsp; is for controoling where line breaks will occur.
                    itemsLeftString = numbers.slice(0, -1).join(', &nbsp;') + ' &nbsp;' + $translate.instant('And').toLowerCase() + ' &nbsp;' + numbers.slice(-1)
                }
                else {
                    itemsLeftString = numbers[0]
                }

                message += itemsLeftString + '.'

                $ngBootbox.customDialog({
                    title: $translate.instant('modules.valueChain.influence.message_header'),
                    message,
                    onEscape: true,
                    buttons: {
                        primary: {
                            label: 'OK',
                            className: 'btn-primary',
                            callback() {

                            },
                        },
                    },
                })
            }
            else {
                valueChainService.openInfluenceSigningModal(vm.loader.influence, $scope, { }).then(() => {
                    getInfluenceVerificationDocuments()
                    $timeout()
                })
            }
        }

        function initPublicProfileBar() {
            const deferred = $q.defer()
            const profileSettings = _.get(vm.loader.influence, 'childContent.conditions.requirementPackageSettings.publicProfileSettings')

            if (profileSettings) {
                vm.enableProfileOptions = true

                //Check if public profile already exists or not
                dataOps.getObject({
                    objectType: enums.objectType.publicProfile,
                    objectId: -1,
                    getterConditions: {
                        objectId: profileSettings.viewStructureId,
                        secondaryObjectId: profileSettings.categoryStructureId,
                        networkId: profileSettings.networkId,
                    },
                }).then((res) => {
                    if (res && res.id > 0) { // it exists
                        vm.publishedProfile = res
                        vm.profiledPublishedDate = vm.publishedProfile.publishedAt ? moment(vm.publishedProfile.publishedAt).format('YYYY-MM-DD') : null

                        if (vm.publishedProfile && vm.publishedProfile.urlRouteName) {
                            vm.viewProfileTooltipText = undefined
                            vm.publishedProfileUrl = urls.wfPublicProfileBaseUrl + vm.publishedProfile.urlRouteName
                        }
                    }

                    deferred.resolve()
                })
            }
            else {
                deferred.resolve()
            }

            return deferred.promise
        }

        function openProfileSettings(validateOnFormLoad) {
            const modalOptions = {
                templateUrl: 'scripts/wf/requirements/wfProfileSettingsModal.template.html',
                scope: $scope,
                size: 'width-600',
                windowClass: 'profile-settings-modal',
            }

            formSchemaService.getFromType(enums.objectType.publicProfile).then((res) => {
                res.form = loadCustomFormSpec()
                vm.profileSettingsForm.formSpec = res
                if (vm.publishedProfile) {
                    vm.profileSettingsForm.model = vm.publishedProfile
                }

                vm.profileSettingsModalInstance = $uibModal.open(modalOptions)

                vm.profileSettingsModalInstance.rendered.then(() => {
                    if (validateOnFormLoad) vm.profileSettingsForm.formControl.isValid()
                })
            })

            function loadCustomFormSpec() {
                return [
                    {
                        type: 'section',
                        htmlClass: 'inline-upload-field',
                        items: [
                            {
                                type: 'section',
                                htmlClass: 'wf-base-profile-url',
                                items: [{
                                    type: 'text',
                                    title: $translate.instant('modules.report.publicProfileOptions.profileSettingsModal.profileUrl'),
                                    disableSuccessState: true,
                                    disableErrorState: true,
                                }],
                            },
                            {
                                key: 'urlRouteName',
                                type: 'text',
                                title: 'Hidden label',
                                htmlClass: 'profile-input-field',
                                placeholder: 'url',
                                validationMessage: {
                                    302: $translate.instant('modules.report.publicProfileOptions.profileSettingsModal.profileUrlValidationMessage'),
                                },
                            },
                        ],
                    },
                    {
                        key: 'imageUpload',
                        title: $translate.instant('modules.report.publicProfileOptions.profileSettingsModal.coverImg'),
                        htmlClass: 'profile-cover-image',
                        'x-schema-form': {
                            title: $translate.instant('modules.report.publicProfileOptions.profileSettingsModal.coverImg'),
                        },
                    },
                ]
            }

        }

        function saveProfileSettings() {
            const isValid = vm.profileSettingsForm.formControl.isValid()
            let objToSave = undefined
            const publicProfileSettings = _.get(vm.loader.influence, 'childContent.conditions.requirementPackageSettings.publicProfileSettings')

            if (!publicProfileSettings) {
                modalService.alert({
                    title: $translate.instant('modules.report.publicProfileOptions.errorModal.title'),
                    message: $translate.instant('modules.report.publicProfileOptions.errorModal.message'),
                    type: 'warning',
                })
                return
            }

            if (isValid) {
                return $q((resolve, reject) => {
                    vm.profileSettingsForm.formControl.submit((model, deferred) => {
                        model.type = enums.objectType.publicProfile

                        if (vm.publishedProfile) {
                            dataOps.update(model).then((res) => {
                                deferred.resolve(res)
                            })
                        }
                        else {
                            objToSave = _.assign(model, publicProfileSettings)
                            dataOps.create(objToSave).then((res) => {
                                deferred.resolve(res)
                            })
                        }
                    }).then((res) => {
                        if (res) {
                            vm.publishedProfile = res
                        }

                        vm.publishedProfileUrl = urls.wfPublicProfileBaseUrl + vm.publishedProfile.urlRouteName

                        vm.profileSettingsModalInstance.close()
                        resolve()
                    })
                })
            }
        }

        function openPublishedProfile() {
            if (!vm.publishedProfile || !vm.publishedProfileUrl) {
                openProfileSettings(true)
            }
            else {
                window.open(vm.publishedProfileUrl, '_blank')
            }

            $timeout()
        }

        function previewProfile() {
            if (vm.previewLoading || !vm.publishedProfile) {
                return
            }

            vm.previewLoading = true
            apiProxy.raw('profile.cacheProfile', {
                profileId: vm.publishedProfile.id,
                isPreview: true,
            }).then((result) => {
                vm.previewProfileUrl = result.url
                vm.previewLoading = false

                const previewSuccessModalInstance = $uibModal.open({
                    animation: true,
                    size: 'width-600',
                    backdrop: 'static',
                    // windowClass: 'wf-publish-confirmation-modal',
                    templateUrl: 'scripts/wf/requirements/wfPreviewSuccessModal.template.html',
                    scope: $scope,
                })
            })

            $timeout()
        }

        function openPublishConfirmationModal() {
            if (!vm.publishedProfile) {
                return
            }

            publishConfirmationModalInstance = $uibModal.open({
                animation: true,
                size: 'width-600',
                backdrop: 'static',
                windowClass: 'wf-publish-confirmation-modal',
                templateUrl: 'scripts/wf/requirements/wfPublishConfirmationModal.template.html',
                scope: $scope,
            })

            publishConfirmationModalInstance.result.then((result) => {
                if (result) {
                    showSuccessMessage()
                }
            }, (rejectedResult) => {
                showErrorMessage()
            }).finally(() => {
                publishConfirmationModalInstance = undefined
            })

            return publishConfirmationModalInstance

            function showSuccessMessage() {
                modalService.alert({
                    title: $translate.instant('modules.report.publicProfileOptions.successMessageModal.title'),
                    message: $translate.instant('modules.report.publicProfileOptions.successMessageModal.description'),
                    type: 'success',
                })
            }

            function showErrorMessage() {
                modalService.alert({
                    title: $translate.instant('modules.report.publicProfileOptions.errorModal.title'),
                    message: $translate.instant('modules.report.publicProfileOptions.errorModal.description'),
                    type: 'warning',
                })
            }
        }

        function publishProfile() {
            const result = true

            if (vm.publishLoading) {
                return
            }

            vm.publishLoading = true

            apiProxy.raw('profile.cacheProfile', {
                profileId: vm.publishedProfile.id,
            }).then((result) => {
                vm.publishLoading = false
                vm.profiledPublishedDate = moment(new Date()).format('YYYY-MM-DD')

                publishConfirmationModalInstance.close({ $value: result })
                $timeout()
            })

            $timeout()
        }
    }
})()
