import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// This file is a copy paste of the CssBaseline from Material-ui
// However the one provided by MUI is defining global styling that
// we don't want to apply.
// This version disables all the not needed styling and keep the one we want

const useStyles = makeStyles(
    theme => ({
        '@global': {
            html: {
                WebkitFontSmoothing: 'antialiased', // Antialiasing.
                MozOsxFontSmoothing: 'grayscale', // Antialiasing.
                // Change from `box-sizing: content-box` so that `width`
                // is not affected by `padding` or `border`.
                boxSizing: 'border-box',
            },
            '*, *::before, *::after': {
                boxSizing: 'inherit',
            },
            /*'strong, b': {
        fontWeight: theme.typography.fontWeightMedium,
      },*/
            body: {
                margin: 0, // Remove the margin in all browsers.
                //color: theme.palette.text.primary,
                //...theme.typography.body2,
                backgroundColor: theme.palette.background.default,
                '@media print': {
                    // Save printer ink.
                    backgroundColor: theme.palette.common.white,
                },
            },
        },
    }),
    { name: 'MuiCssBaseline' },
)

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */
function CssBaseline(props) {
    const { children = null } = props
    useStyles()
    return <React.Fragment>{children}</React.Fragment>
}

export default CssBaseline
