import * as enums from '@worldfavor/constants/enums'
import { group, timeout } from 'd3';

(function () {
    'use strict'

    angular
        .module('wf.common')
        .controller('ValueChainCategoriesController', ValueChainCategoriesController)

    ValueChainCategoriesController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$window', 'valueChainService', '$state']
    function ValueChainCategoriesController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, wfItemToolsService, $window, valueChainService, $state) {
        const
            vm = this
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const authSolutionType = wfAuth.getSolution()
			
        let permissions

        _.assign(vm, {
            window: $window,
            loaded: false,
            network: null,
            mainStructure: null,
            showCreateButton: true,
            showStandardCategoriesButton: ['Sustainable Sourcing'].includes(authSolutionType) && wfAuth.isAdmin(),
            showGroupManagementButton: true,

            showDropdown: true,
            dropdownActions: 'update,delete', // This are the default actions but will be based on permissions on mainStructure
            customFormSpecification: {
                form: [
                    'title',
                    'description',
                    'imageUpload',
                ],
            },
            filterBarControl: {},
            groupsDropdownCompiler: {},

            // Arrays
            items: [], // Intersected relations from mainStructure and network
            categoryDataRelations: [], // All relations. Categories always belongs to both mainStructure and network
            distinctParents: [],
            groupsDropdownActions: [],

            // Lookup objects
            dataRelationsByCategoryIds: {}, // All relations by childId. Categories always belongs to both mainStructure and network
            itemSideActionCompilers: {},
            parentsByCategoryId: {},

            dragControlListeners: {
                itemMoved(event) {
                },
                orderChanged(event) {
                    reorderItemsAndSyncView()
                    updateFilterBar()
                },
            },

            // Functions
            createCategory,
            addToGroup,
            addToNewGroup,
            removeFromGroup,
            editGroup,
            getItemUrl,
            openStandardCategoriesPicker,
            isItemOwned,
            reorderItemsAndSyncView,
            setDropdownActionsForEditingGroups,
            refreshGroups,
        })

        activate()

        ////////////////

        function activate() {
            $scope.$on('itemToolsActionExecuted', ($event, operation, item, dataRelation) => {
                let relationToDestroy
                if (operation === 'delete') {
                    if (_.remove(vm.items, { wfid: item.wfid }).length || _.remove(vm.items, { wfid: dataRelation.wfid }).length) {

                        // The first delete operation only deletes the structure, so the relations are deleted now (both from JSData and the relations array).
                        relationToDestroy = _.remove(vm.categoryDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })[0]
                        if (relationToDestroy) dataOps.destroy(relationToDestroy)

                        relationToDestroy = _.remove(vm.categoryDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })[0]
                        if (relationToDestroy) dataOps.destroy(relationToDestroy)
                        $timeout()
                    }
                }
            })

            valueChainService.loadNetworks().then((result) => {
                const
                    network = result.networksById[$stateParams.networkId]
					
                const mainStructure = result.structure

                if (!network) {
                    $state.go('valueChain-root')
                    return
                }

                vm.network = network

                getCategoryGroups()

                dataOps.getObject({
                    objectType: network.type,
                    objectId: network.id,
                    childrenLoadDepth: 1,
                    getterConditions: {
                        objectTypes: [enums.objectType.structure],
                    },
                }).then((obj) => {
                    const
                        relationsInNetwork = _.filter(obj.childs, { childType: enums.objectType.structure })
						
                    const relationsInNetworkByChildId = _.keyBy(relationsInNetwork, 'childId')

                    dataOps.getObject({
                        objectId: valueChainService.ids.categoriesStructure, // Contains the current organization's data collector categories
                        objectType: enums.objectType.structure,
                        childrenLoadDepth: 1,
                    }).then((res) => {
                        let dropdownActionsFromPermissions

                        vm.mainStructure = res
                        $rootScope.setPageTitle(vm.mainStructure.title)

                        // Filter mainStructure childs to only get the children that are also in the network.
                        vm.items = _.chain(vm.mainStructure.childs).filter((dataRelation) => {
                            const networkDataRelation = relationsInNetworkByChildId[dataRelation.childId]
                            if (networkDataRelation) {
                                // Add to utility arrays
                                vm.categoryDataRelations.push(networkDataRelation)
                                vm.categoryDataRelations.push(dataRelation)

                                return true
                            }
                        }).value()

                        // Order by parent title then by category title
                        getParentRelations()

                        vm.items = _.chain(vm.items).map((dataRelation) => {
                            const
                                childContent = dataRelation.childContent
								
                            const parentDataRelation = _.find(vm.parentDataRelations, { wfcid: dataRelation.wfcid })
								
                            const parentContent = _.get(parentDataRelation, 'parentContent')

                            const groupCategoryParentRelation = parentContent ? parentContent.parents.filter(x => x.parentId === valueChainService.ids.categoryGroupsStructure)[0] : {}
                            const order = _.get(groupCategoryParentRelation, 'order')

                            return {
                                wfid: childContent.wfid,
                                content: childContent,
                                title: childContent.title,
                                dataRelation,
                                order: dataRelation.order,
                                parentOrder: order,
                                parentContent,
                                parentTitle: parentContent ? parentContent.getMainTextual() : undefined,
                            }
                        }).orderBy(['parentOrder', 'order', 'parentTitle', 'title'], ['asc', 'asc', 'asc', 'asc']).map('dataRelation').value()

                        syncParentsCache()

                        // Check permissions
                        permissions = vm.mainStructure.permissions
                        if (permissions) {
                            vm.showCreateButton = permissions.canCreate
                            vm.showGroupManagementButton = permissions.canCreate

                            dropdownActionsFromPermissions = []

                            if (!permissions.canUpdate && !permissions.canDelete) {
                                vm.showDropdown = false
                            }
                            else {
                                if (permissions.canUpdate) dropdownActionsFromPermissions.push('update')
                                if (permissions.canDelete) dropdownActionsFromPermissions.push('delete')

                                vm.dropdownActions = dropdownActionsFromPermissions.join(',')
                            }
                        }

                        // Create utility object for easy lookup of relations by structure id
                        vm.dataRelationsByCategoryIds = _.groupBy(vm.categoryDataRelations, 'childId')
                        vm.loaded = true

                        vm.filteringConfig = {
                            items: vm.items,
                            control: vm.filterBarControl,
                            filters: [
                                { bySearch: true },
                                {
                                    header: $translate.instant('Groups'),
                                    headerWidth: '30px',
                                    sourceIdPath: 'wfcid',
                                    bySubItemsKind: 8,
                                    excludeTargetWfid: '71-12224',
                                },
                            ],
                        }
                    })
                })
            })
        }

        function getCategoryGroups() {
            return valueChainService.loadCategoryGroupsInNetwork(vm.network).then((result) => {
                vm.distinctParents = result
            })

        }

        function createCategory() {
            return modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                objectTypeWord: $translate.instant('Category').toLowerCase(),
                customFormSpecification: vm.customFormSpecification,
                dataRelationOptions: { item1: vm.mainStructure, kind: enums.subItemsKind.childrenByUser },
                additionalDataRelations: [
                    { item1: vm.network, kind: enums.subItemsKind.childrenByUser },
                ],
                submitCaption: $translate.instant('Create'),
            }).then((dataRelation, additionalDataRelations) => {
                if (dataRelation) {
                    vm.dataRelationsByCategoryIds[dataRelation.childId] = _.concat([dataRelation], additionalDataRelations)
                    vm.categoryDataRelations.push(dataRelation)
                    vm.categoryDataRelations.push(additionalDataRelations[0])

                    vm.items.unshift(dataRelation)
                    $timeout()
                }
            })
        }

        function reorderItemsAndSyncView() {
            _.each(vm.distinctParents, (item, index) => {
                if (item.order != index + 1) {
                    item.order = index + 1
                    item.wasChanged = true
                }
            })
            dataOps.saveDataRelationsOrder(vm.distinctParents)
        }

        function getParentRelations() {
            const categoryWfids = _.map(vm.items, 'wfcid')
            vm.parentDataRelations = wfObject.filter({
                where: {
                    type: enums.objectType.dataRelation,
                    parentType: enums.objectType.structure,
                    childType: enums.objectType.structure,
                    parentData1: null,
                    wfcid: { in: categoryWfids },
                    parentId: { '!=': valueChainService.ids.categoriesStructure },
                },
            })
            vm.parentsById = _.keyBy(vm.distinctParents, 'id')
        }

        function syncParentsCache(category) {
            getParentRelations()
            _.each(category ? [{ wfcid: category.wfid }] : vm.items, (categoryRelation) => {
                let
                    isIn
					
                let notIn

                isIn = _.filter(vm.parentDataRelations, { wfcid: categoryRelation.wfcid })
                vm.parentsByCategoryId[categoryRelation.wfcid] = {
                    isIn,
                }
            })

            if (category) {
                updateFilterBar()
                $timeout()
            }
        }

        function updateFilterBar() {
            vm.filterBarControl.updateButtonBars()
        }

        function addToGroup(group, category) {
            const groupId = group.childContent || group
            const promise = dataOps.createSubItemRelation(groupId, category, enums.subItemsKind.childrenByUser)
            promise.then(() => {
                syncParentsCache(category)
                vm.itemSideActionCompilers[category.wfid].compile()
            })
            return promise
        }

        function addToNewGroup(category) {
            return modal.createWithRelation({
                simplifyForm: true,
                objectType: 71,
                title: $translate.instant('modules.valueChain.categories.addToNewGroup'),
                customFormSpecification: {
                    ignoreUpload: true,
                    form: [
                        'title',
                    ],
                },
                dataRelationOptions: { item1: `71-${valueChainService.ids.categoryGroupsStructure}`, kind: enums.subItemsKind.childrenByUser },
                additionalDataRelations: [
                    { item1: vm.network, kind: enums.subItemsKind.childrenByUser },
                ],
                submitCaption: $translate.instant('Create'),
            }).then((dataRelation) => {
                if (dataRelation) {
                    addToGroup(`71-${dataRelation.childId}`, category)
                    getCategoryGroups().then(() => reorderItemsAndSyncView())
                }
            })
        }

        function editGroup(categoryWfid) {
            const category = vm.distinctParents.find(x => x.childContent.wfid === categoryWfid)
            const index = vm.distinctParents.findIndex(x => x.childContent.wfid === categoryWfid)
			
            if (category && category.childContent) {
                vm.distinctParents.splice(index, 1)
                modal.edit(category.childContent, {
                    simplifyForm: true,
                    title: $translate.instant('modules.valueChain.categories.editGroup'),
                    customFormSpecification: {
                        ignoreUpload: true,
                        form: ['title'],
                    },
                }).then(() => {
                    updateFilterBar()
                    setDropdownActionsForEditingGroups(category, index)
                    _.invokeMap(vm.itemSideActionCompilers, 'compile')
                }).cancelled(() => {
                    setDropdownActionsForEditingGroups(category, index)
                })
            }
        }

        function removeFromGroup(parentRelation) {
            const category = parentRelation.childContent
            const promise = dataOps.destroy(parentRelation)

            promise.then(() => {
                syncParentsCache(category)
                vm.itemSideActionCompilers[category.wfid].compile()
            })

            return promise
        }

        function setDropdownActionsForEditingGroups(category, index) {
            if (vm.distinctParents && vm.distinctParents.length > 0) {
                vm.distinctParents.splice(index, 0, category)
            }
            $timeout()
        }

        function refreshGroups() {
            return $scope.groupOptions
        }

        function getItemUrl(categoryId) {
            if (_.get($state, '$current.parent.self.url')) {
                return $state.href($state.$current.parent.self.name + 'valueChainOrganizations', { networkId: vm.network.id, parentId: categoryId })
            }
            else {
                return $state.href('valueChainOrganizations', { networkId: vm.network.id, parentId: categoryId })
            }
        }

        function openStandardCategoriesPicker() {
            let sourceItemId

            sourceItemId = {
                'Sustainable Sourcing': enums.ids.common.standardCategoriesPicker_Sourcing,
            }[authSolutionType]

            const pickerPromise = modal.openCreatorAndPicker({
                sourceItem: `71-${sourceItemId}`,
                sourceItemFirstLevelAsFilter: false,
                openerButtonTranslate: 'Select',
                create: false,
                title: $translate.instant('modules.valueChain.standardCategories.pickerHeader'),
                relationTarget: [
                    {
                        item: `71-${valueChainService.ids.categoriesStructure}`,
                        kind: enums.subItemsKind.childrenByUser,
                    },
                    {
                        item: vm.network,
                        kind: enums.subItemsKind.childrenByUser,
                    },
                ],
            })

            pickerPromise.modal.closed.then(() => {
                const currentItems = vm.items
                const withNewItems = _.intersectionBy(vm.mainStructure.childs, vm.network.childs, 'wfcid')
                const onlyNewItems = _.differenceBy(withNewItems, currentItems, 'wfcid')
                const onlyNewItemsNetworkRelations = _.intersectionBy(vm.network.childs, onlyNewItems, 'wfcid')
                const onlyRemovedItems = _.differenceBy(currentItems, withNewItems, 'wfcid')

                onlyNewItems.forEach((dataRelation) => {
                    const networkRelations = onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid)

                    vm.dataRelationsByCategoryIds[dataRelation.childId] = _.concat([dataRelation], onlyNewItemsNetworkRelations.filter(x => x.wfcid = dataRelation.wfcid))
                    vm.categoryDataRelations.push(dataRelation)
                    vm.categoryDataRelations.push(networkRelations[0])

                    vm.items.unshift(dataRelation)
                })

                onlyRemovedItems.forEach((dataRelation) => {
                    _.remove(vm.items, { wfid: dataRelation.wfid })
                    _.remove(vm.categoryDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.structure })
                    _.remove(vm.categoryDataRelations, { wfcid: dataRelation.wfcid, parentType: enums.objectType.network })
                })

                $timeout()
            })
        }

        function isItemOwned(item) {
            return item.creatorOrganizationId === authOrgId
        }
    }
})()
