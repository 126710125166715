(function () {
    'use strict'

    angular
        .module ('wf.common')
        .directive ('wfDropdownRightclick', wfDropdownRightclick)

    wfDropdownRightclick.$inject = ['$parse', '$timeout']
    function wfDropdownRightclick($parse, $timeout) {

        const directive = {
            link,
            restrict: 'A',
        }
        return directive

        function link(scope, element, attrs) {
            const dropdownDirective = element.find('wf-dropdown')

            if (dropdownDirective.length) {
                element.bind('contextmenu', (event) => {
                    clickWfDropdown(event)
                    $timeout()
                })
            }

            function clickWfDropdown(event) {
                const fn = $parse(attrs.ngRightClick)
                const cursorPositionX = event.clientX
                const cursorPositionY = event.clientY

                event.preventDefault()
                event.stopPropagation()
                fn(scope, { $event: event })
                dropdownDirective.click()
                dropdownDirective.children('.dropdown').children('button').click()

                dropdownDirective.children('.dropdown').children('ul.dropdown-menu').css('top', cursorPositionY)
                dropdownDirective.children('.dropdown').children('ul.dropdown-menu').css('left', cursorPositionX)
            }
        }
    }

} ())
