// @flow
import * as React from 'react'
import type { Item } from '@worldfavor/types'
import '@worldfavor/less/components/embedded-player.less'

type Props = {
  item: Item,
  style: { [string]: any }
}

type State = {
  playerLoaded: boolean,
}

class EmbeddedPlayer extends React.PureComponent<Props, State> {

  static defaultProps = {
      style: {},
  }

  state = {
      playerLoaded: false,
  }

  playerParent: ?HTMLElement = null

  componentDidUpdate(prevProps: Props, prevState: State) {
      if (this.state.playerLoaded && !prevState.playerLoaded) {
          this.autoPlay()
      }
  }

  loadPlayer = () => {
      this.setState({ playerLoaded: true })
  }

  autoPlay = () => {
      const iframe = this.playerParent && this.playerParent.getElementsByTagName('iframe')[0]
      if (iframe) {
          iframe.src = `${iframe.src}&autoplay=1`
      }
  }

  render() {
      const { item: { thumbnailUrl, html: playerHtml, aspectRatio }, style } = this.props
      const { playerLoaded } = this.state
      return (
          <div styleName="container" style={style}>
              {
                  playerLoaded ? (
                      <div
                          ref={ref => this.playerParent = ref}
                          styleName="wrapper"
                          style={{ paddingTop: `${100 / aspectRatio}%` }}
                          // ignore dangerouslySetInnerHTML as there is no other way to inject the player's code
              dangerouslySetInnerHTML={{ __html: playerHtml }} // eslint-disable-line
                      />
                  ) : (
                      <div styleName="wrapper" style={{ paddingTop: `${100 / aspectRatio}%` }}>
                          <div
                              onClick={this.loadPlayer}
                              styleName="thumbnail"
                              style={{ backgroundImage: `url(${thumbnailUrl})` }}
                          />
                      </div>
                  )
              }
          </div>
      )
  }
}

export default EmbeddedPlayer
