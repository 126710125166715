import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfForm', wfForm)

    function wfForm() {

        const directive = {
            scope: {
                objectType: '=',
                simplify: '=',
                model: '=',
                control: '=',
                topItem: '=',
                schema: '=',
                formSpecification: '=',
                isCreate: '=',
                hideRelations: '=',
                showAttachInformation: '<',
                intersection: '<',
                config: '<',
                replaceFormSpecification: '<',
                wording: '<',
                typeOptionsByProperty: '<',
                formFieldTitlesByProperty: '<',
                requiredFields: '<',
                limitLanguages: '<',
                requireAllLanguages: '=',
                onSubmit: '<',
                submitThisArg: '<',
                // onBeforeRender: "&",
                // onBeforeSubmit: "&"
            },
            controller: wfFormController,
            controllerAs: 'vm',
            restrict: 'E',
            templateUrl: 'scripts/wf/form/wfForm.directive.html',
        }

        return directive
    }

    wfFormController.$inject = ['$scope', '$translate', 'formSchemaService', 'dataOperationsService', 'apiProxy', '$timeout', 'wfObject', 'modalService', '$ngBootbox', '$sanitize', 'wfAuth', '$attrs', 'wfTranslate', '$q', '$element', 'wfPropertyExtractor']
    function wfFormController($scope, $translate, formSchemaService, dataOps, apiProxy, $timeout, wfObject, modal, $ngBootbox, $sanitize, wfAuth, $attrs, wfTranslate, $q, $element, wfPropertyExtractor) {
        /* jshint validthis:true */
        const
            vm = this
			
        let busy = false
			
        let submitButton
			
        let userLanguage
			
        let userProfile
			
        let multilingualLanguages = ['sv', 'en', 'de', 'es', 'cs', 'pl', 'zh', 'nb', 'da', 'fi', 'fr', 'pt', 'af', 'it', 'hu', 'vi', 'generic']
			
        let multilingualProperties
			
        const objectType = $scope.objectType
			
        let prototypeModel
			
        let formsByLanguage
			
        let relationBucketResult
			
        let originalRelations
			
        const customFormSpecification = $scope.formSpecification
			
        let onBeforeRender
			
        let onBeforeSubmit
			
        let intersectionSettings
			
        let currentModel
        // The model instance of the currently selected language, if form is multilingual
			
        const culture = wfAuth.getCulture()
			
        const shiftCtrltKeyHolder = false

        _.assign(vm, {
            config: _.assign({ focus: true }, $scope.config),
            formName: 'myForm' + $scope.$id,
            submit,
            schema: {},
            form: [],
            model: $scope.model ? angular.copy($scope.model) : { type: objectType },
            submitButtonClick,
            isMultilingualForm: false,
            multilingualForms: [],
            setSelectedLanguage,
            openPicker,
            showRelations: false,
            topItem: $scope.topItem,
            relations: [],
            wrappedRelations: [],
            ignoreUpload: customFormSpecification ? customFormSpecification.ignoreUpload || false : false, // If true, any upload field will be ignored when submitting
            simplify: $scope.simplify,
            wording: $scope.wording,
            typeOptionsByProperty: $scope.typeOptionsByProperty,
            formFieldTitlesByProperty: $scope.formFieldTitlesByProperty,
            requiredFields: $scope.requiredFields,
            requireAllLanguages: $scope.requireAllLanguages,
            limitLanguages: $scope.limitLanguages,
        })

        _.assign($scope.control, {
            getModel,
            setModel,
            submit,
            reset,
            isValid,
            resetUploaders,
            getForm: () => $scope[vm.formName],
            $scope,
        })

        intersectionSettings = $scope.intersection

        activate()

        function activate() {
            vm.loading = true

            if ($scope.onSubmit && $scope.submitThisArg) {
                $element.keydown((event) => {
                    if (event.keyCode === 13) {
                        const tagName = event.target.tagName
                        if (tagName === 'TEXTAREA') {
                            if (event.ctrlKey || event.shiftKey) {
                                event.preventDefault()
                                onSubmitWithThisArg($scope.submitThisArg)
                            }
                        }
                        else if (tagName === 'INPUT') {
                            onSubmitWithThisArg($scope.submitThisArg)
                        }
                    }
                })
            }

            if ($scope.model && $scope.model.preSelectedFile) vm.model.preSelectedFile = $scope.model.preSelectedFile

            // if (objectType === enums.objectType.mailSettings) {
            // 	multilingualLanguages = [ "sv", "en", "generic" ]
            // }

            if ($scope.limitLanguages && $scope.limitLanguages.length) multilingualLanguages = $scope.limitLanguages

            vm.showRelations = (objectType == enums.objectType.orgActivity && !$scope.hideRelations) || $scope.showAttachInformation

            if (vm.showRelations) {
                originalRelations = vm.model.relatedContentByUser || []
                // relationBucketResult = { allSelected: [] };
                // var relations = [wfObject.get("15-5054"), wfObject.get("15-5035")];

                _.forEach(originalRelations, (dataRelation) => {
                    vm.wrappedRelations.push({
                        childContent: dataRelation.childContent,
                        childType: dataRelation.childType,
                    })
                    vm.relations.push(dataRelation.childContent)
                })
            }

            const culture = wfAuth.getCulture()
            if (culture && (culture == 'en-US' || culture == 'sv-SE')) {
                userLanguage = culture.split('-')[0]
            }

            if (($scope.replaceFormSpecification || !objectType) && customFormSpecification) {
                // If objectType is undefined, then initialize with customFormSpecification
                handleFormSpecification(customFormSpecification)
            }
            else if (objectType) {
                // If objectType is defined then load the schema from server
                formSchemaService.getFromType(objectType, { simplify: $scope.simplify, isCreate: $scope.isCreate, model: $scope.model }).then((res) => {
                    // If customFormSpecification is defined then extend res with customFormSpecification (so that form/schema property can be overriden)
                    if (customFormSpecification) {
                        res = _.assign(_.cloneDeep(res), customFormSpecification)
                    }
                    handleFormSpecification(res)
                })
            }

            $scope.$on('sf-render-finished', (event, element) => {
                if (vm.config.focus) {
                    setTimeout(() => {
                        element.find('input, textarea').not('[pick-a-date]').first().focus()
                    }, 100)
                }
            })

            function handleFormSpecification(res) {
                res = _.cloneDeep(res)
                vm.isMultilingualForm = res.isMultilingual

                onBeforeRender = res.onBeforeRender
                onBeforeSubmit = res.onBeforeSubmit

                _.each(res.form, (value) => {
                    if (value.onChangeExtended) {
                        const onChangeExtendedFunc = value.onChangeExtended
                        value.onChange = function (modelValue, form) {
                            onChangeExtendedFunc(modelValue, form, res.isMultilingual ? currentModel : vm.model)
                        }
                        delete value.onChangeExtended
                    }
                })

                _.each(res.schema.properties, (value, key) => {
                    const onChangeExtendedFunc = _.get(value['x-schema-form'], 'onChangeExtended')

                    if (onChangeExtendedFunc) {
                        value['x-schema-form'].onChange = function (modelValue, form) {
                            onChangeExtendedFunc(modelValue, form, res.isMultilingual ? currentModel : vm.model)
                        }
                    }
                })

                if (res.isMultilingual) {
                    multilingualProperties = res.multilingualProperties
                    if (vm.model.wfid) {
                        if (objectType == enums.objectType.finding) {
                            delete res.schema.properties.closed

                            if (vm.model.closed) delete res.schema.properties.closedAt['x-schema-form'].condition
                            else delete res.schema.properties.closedAt
                        }

                        apiProxy('multi.getObject', {
                            objectId: vm.model.id,
                            objectType: vm.model.type,
                            includeMultilingual: true,
                        }).then((mlModel) => {
                            initMultilingualForms(mlModel, res)
                        })
                    }
                    else {
                        if (objectType == enums.objectType.finding) {
                            _.assign(vm.model, { openedAt: moment().format() })
                        }

                        initMultilingualForms(vm.model, res)
                    }
                    // $element.closet("div.modal-dialog").width("1000px");
                }
                else
                {
                    if (objectType == 25 && vm.model.unitId) { // Measure answer
                        dataOps.getObject({
                            objectType: 84,
                            objectId: vm.model.unitId,
                        }).then((unit) => {
                            vm.loading = false
                            vm.schema = res.schema
                            vm.form = res.form
                            vm.schema.properties.value['x-schema-form'] = {
                                fieldAddonRight: unit['symbol'],
                                unitId: unit['id'],
                                unitSymbol: unit['symbol'],
                                unitName: unit['name'],
                                baseUnitFactor: unit['baseUnitFactor'],
                                measureId: vm.model.measureId,
                                measureAnswerFormId: vm.model.measureAnswerFormId,
                                showNotAvailableCheckbox: vm.model.showNotAvailableCheckbox,
                                latestValueInPreviousPeriod: vm.model.latestValueInPreviousPeriod,
                                latestValueUnitIdInPreviousPeriod: vm.model.latestValueUnitIdInPreviousPeriod,
                                latestBaseUnitFactorInPreviousPeriod: vm.model.latestBaseUnitFactorInPreviousPeriod,
                                latestValueUnitInPreviousPeriod: vm.model.latestValueUnitInPreviousPeriod,
                            }

                        })
                    }
                    else if (objectType == 89) { // User organization access settings
                        apiProxy('multi.getObject', {
                            objectType,
                            organizationId: vm.model.organizationId,
                            auth0UserId: vm.model.auth0UserId,
                        }).then((model) => {
                            vm.model = model
                            vm.loading = false
                            vm.schema = res.schema
                            vm.form = res.form
                        })
                    }
                    else if (objectType == enums.objectType.requirement) {
                        apiProxy('multi.getObject', {
                            objectType,
                            objectId: -1,
                            getterConditions: {
                                objectId: vm.model.objectId,
                                objectType: vm.model.objectType,
                                organizationId: vm.model.organizationId,
                            },
                        }).then((model) => {
                            if (!model.id) {
                                vm.model = {
                                    objectId: vm.model.objectId,
                                    objectType: vm.model.objectType,
                                    derivedType: vm.model.derivedType,
                                    organizationId: vm.model.organizationId,
                                    comment: null,
                                    creatorOrganizationId: wfAuth.getOrganizationId(),
                                    rule: null,
                                    value: null,
                                }
                            }
                            else vm.model = model

                            if (onBeforeRender) vm.model = onBeforeRender(vm.model)

                            vm.loading = false
                            vm.schema = res.schema
                            vm.form = res.form
                        })
                    }
                    else if (objectType == enums.objectType.individual && $scope.isCreate) {
                        vm.loading = false
                        vm.schema = res.schema
                        vm.ignoreUpload = true
                        vm.form = [
                            'email',
                            'given_name',
                            'family_name',
                            'phone_number',
                            'position',
                            'culture',
                        ]
                    }
                    else if (objectType == enums.objectType.productService) {
                        vm.loading = false
                        vm.schema = res.schema
                        vm.form = res.form

                        vm.form = [
                            'name',
                            'identifierCode',
                            'ean',
                            'upc',
                            'isbn',
                            {
                                key: 'consistsOf',
                                title: $translate.instant('ConsistsOf'),
                                type: 'picker_multiple',
                                typeOptions: {
                                    ticket: undefined, // ticket,
                                    addButtonCaption: $translate.instant('Add'),
                                    targetWfid: $scope.isCreate ? undefined : _.get(vm.model, 'wfid'),
                                    picker: {
                                        create: true,
                                        objectTypes: [enums.objectType.productService],
                                        title: _.get(vm.formFieldTitlesByProperty, 'consistsOf') ? getTextFromProperty(vm.formFieldTitlesByProperty.consistsOf) : $translate.instant('modules.productService.consistsOf.pickerModal.title'),
                                        description: _.get(vm.formFieldTitlesByProperty, 'consistsOf') ? $translate.instant('modules.productService.consistsOf.pickerModal.customDescription', { information: getTextFromProperty(vm.formFieldTitlesByProperty.consistsOf).toLowerCase() }) : $translate.instant('modules.productService.consistsOf.pickerModal.description'),
                                        wording: _.get(vm.typeOptionsByProperty, 'consistsOf.wording'),
                                        formFieldTitlesByProperty: vm.formFieldTitlesByProperty,
                                        typeOptionsByProperty: vm.typeOptionsByProperty ? vm.typeOptionsByProperty : undefined,
                                        simplifyForms: undefined,
                                    },
                                    required: false,
                                },
                            },
                            {
                                key: 'ownershipOrganizationId',
                                title: $translate.instant('OwnershipOrganization'),
                                type: 'picker_multiple',
                                typeOptions: {
                                    selectedItem: {
                                        id: _.get(vm.model, 'ownershipOrganizationId') || null,
                                        type: _.get(vm.model, 'ownershipOrganizationId') ? enums.objectType.organization : null,
                                    },
                                    addButtonCaption: $translate.instant('Add'),
                                    singlePick: true,
                                    picker: {
                                        create: true,
                                        objectTypes: [enums.objectType.organization],
                                        title: _.get(vm.formFieldTitlesByProperty, 'ownershipOrganizationId') ? $translate.instant('modules.productService.ownershipOrganization.pickerModal.customTitle', { information: getTextFromProperty(vm.formFieldTitlesByProperty.ownershipOrganizationId).toLowerCase() }) : $translate.instant('modules.productService.ownershipOrganization.pickerModal.title'),
                                        formFieldTitlesByProperty: vm.formFieldTitlesByProperty,
                                        wording: _.get(vm.typeOptionsByProperty, 'ownershipOrganizationId.wording'),
                                        typeOptionsByProperty: vm.typeOptionsByProperty ? vm.typeOptionsByProperty : undefined,
                                    },
                                    required: false,
                                    submitAction(event, model, relationBucket) {
                                        const selectedItem = relationBucket.allSelected[0]

                                        if (selectedItem) {
                                            model.ownershipOrganizationId = selectedItem.id
                                        }
                                    },
                                },
                            },
                        ]

                        if (vm.simplify) {
                            //Remove all the properties that are NOT in the simplify array
                            _.each(vm.form, (property, index) => {
                                if (typeof property === 'object' && !_.includes(vm.simplify, property.key)) delete vm.form[index]
                                else if (typeof property !== 'object' && !_.includes(vm.simplify, property)) delete vm.form[index]
                            })

                            // prepare the simplify array for the second level picker
                            const consistsOfProperty = _.find(vm.form, { key: 'consistsOf' })
                            if (consistsOfProperty) consistsOfProperty.typeOptions.picker.simplifyForms = excludeFieldsAndSimplifyForm('consistsOf')
                        }

                        if (vm.formFieldTitlesByProperty) applyFormFieldTitlesByProperty(vm.schema)

                        if (onBeforeRender) vm.model = onBeforeRender(vm.model)

                        prepareUploaderInSchema(vm.schema)
                    }
                    else if (objectType == enums.objectType.dateItem) {
                        // DateItem can have endDate field hidden/shown based on pickerSettings conditions on a structure that dateItems should be attached to.
                        // By default endDate is hidden so it has to be shown by deleting x-schema-form.condition property.
                        if ((_.includes(res.form, 'endDate') && _.get(res.schema.properties.endDate, 'x-schema-form.condition') === 'false') || vm.model.endDate) {
                            res.schema.required = ['date', 'endDate']
                            res.schema.properties.endDate.format = 'date'
                            res.schema.properties.endDate.type = 'string'
                            delete res.schema.properties.endDate['x-schema-form'].condition
                        }

                        // Does not work
                        if (!res.schema.properties.date['x-schema-form']) res.schema.properties.date['x-schema-form'] = {}

                        res.schema.properties.date['x-schema-form'].placeholder = $translate.instant('SelectDate')

                        // Does not work
                        if (!res.schema.properties.endDate['x-schema-form']) res.schema.properties.endDate['x-schema-form'] = {}

                        res.schema.properties.endDate['x-schema-form'].placeholder = $translate.instant('SelectDate')

                        // Needed because it is initialy "Field does not validate" if form is rendered in wfInlineAnswering component
                        res.schema.properties.endDate['x-schema-form'].validationMessage = $translate.instant('validationMessages.required')

                        vm.loading = false
                        vm.schema = res.schema
                        vm.form = res.form
                    }
                    else {
                        vm.loading = false
                        vm.schema = res.schema
                        vm.form = res.form

                        if (onBeforeRender) vm.model = onBeforeRender(vm.model)

                        prepareUploaderInSchema(vm.schema)
                    }

                    if (vm.requiredFields && vm.requiredFields.length) {
                        vm.schema.required = vm.requiredFields

                        _.each(vm.requiredFields, (requiredField) => {
                            const fieldAsObject = _.find(vm.form, { key: requiredField })
                            if (fieldAsObject && fieldAsObject.typeOptions) fieldAsObject.typeOptions.required = true
                        })

                    }

                    prototypeModel = angular.copy(vm.model)
                    $timeout()
                }
            }

            function excludeFieldsAndSimplifyForm(propertiesToExclude) {
                let form = _.clone(vm.simplify)

                if (form instanceof Array) form = _.pullAllBy(form, [propertiesToExclude])

                return form
            }

            function applyFormFieldTitlesByProperty(schema) {
                const
                    propertiesByKeys = []
					
                let formProperty = undefined
					
                let formFieldTitleByProperty = undefined
					
                let text = ''

                if (!(_.isEmpty(vm.formFieldTitlesByProperty) && _.isEmpty(schema.properties))) {
                    _.each(vm.form, (property) => {
                        if (typeof property === 'string') propertiesByKeys.push(property)
                        else if (typeof property === 'object' && property.key) propertiesByKeys.push(property.key)
                    })

                    _.each(propertiesByKeys, (key) => {
                        formFieldTitleByProperty = vm.formFieldTitlesByProperty[key]
                        if (formFieldTitleByProperty) {
                            text = getTextFromProperty(formFieldTitleByProperty)
                            formProperty = _.find(vm.form, { key })

                            if (key in schema.properties && typeof formProperty !== 'object') {
                                schema.properties[key].title = text
                                schema.properties[key]['x-schema-form'].title = text
                            }
                            else if (formProperty && formProperty.title) formProperty.title = text
                        }
                    })
                }
            }
        }

        //Use this function only if you want to pass thisArguments or just arguments to the onSubmit function
        function onSubmitWithThisArg(thisArg, args) {
            if ($scope.onSubmit && thisArg && $scope.control.isValid()) {
                $scope.onSubmit.apply(thisArg, args)
            }
        }

        function getTextFromProperty(property) {
            return wfPropertyExtractor.getTextFromProperty(property, culture)
        }

        function prepareUploaderInSchema(schema) {
            if (schema.properties && schema.properties.imageUpload) {
                schema.properties.imageUpload['x-schema-form'].currentImageUrl = vm.model.imageUrl
                schema.properties.imageUpload['x-schema-form'].authHeaderValue = wfAuth.getAuthenticationHeaderValue()
            }

            if (schema.properties && schema.properties.fileUpload) {
                schema.properties.fileUpload['x-schema-form'].currentFileUrl = vm.model.url
                schema.properties.fileUpload['x-schema-form'].model = vm.model
                schema.properties.fileUpload['x-schema-form'].isFileUpload = true
                schema.properties.fileUpload['x-schema-form'].isValid = false
                schema.properties.fileUpload['x-schema-form'].authHeaderValue = wfAuth.getAuthenticationHeaderValue()
            }
        }

        function setSelectedLanguage(language) {
            _.forEach(formsByLanguage, (o) => {
                o.selected = false
            })
            formsByLanguage[language].selected = true
            currentModel = formsByLanguage[language].model
        }

        function moveLanguage(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length
            }
            while (new_index < 0) {
                new_index += arr.length
            }
            if (new_index >= arr.length) {
                let k = new_index - arr.length
                while ((k--) + 1) {
                    arr.push(undefined)
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
            return arr
        }

        function initMultilingualForms(mlModel, schemaSpec) {
            let
                multilingualOnlyForm
				
            let formItem
				
            const multilingualPropertiesAsKeys = _.keyBy(multilingualProperties)
				
            let i; let len
			
            // console.log(mlModel);

            prepareUploaderInSchema(schemaSpec.schema)

            if (userLanguage) {
                // multilingualLanguages = _.sortBy(multilingualLanguages, function (o) {
                // 	console.log(o, userLanguage, o === userLanguage)
                // 	return o === userLanguage;
                // });
                multilingualLanguages = moveLanguage(multilingualLanguages, multilingualLanguages.indexOf(userLanguage), 0)
            }

            multilingualOnlyForm = []

            if (schemaSpec.form.length === 1 && schemaSpec.form[0] === '*') {
                multilingualOnlyForm = _.clone(multilingualProperties)
            }
            else {

                for (i = 0, len = schemaSpec.form.length; i < len; i++) {
                    formItem = schemaSpec.form[i]

                    if (typeof formItem === 'object') {
                        if (formItem.key in multilingualPropertiesAsKeys || formItem.type === 'template') multilingualOnlyForm.push(_.cloneDeep(formItem))
                    }
                    else if (typeof formItem === 'string' && formItem in multilingualPropertiesAsKeys) multilingualOnlyForm.push(_.cloneDeep(formItem))
                }
            }

            _.forEach(multilingualLanguages, (language, index) => {
                let
                    schema
					
                let model

                model = {}
                for (var i = 0, len = multilingualProperties.length, key; i < len; i++) {
                    key = multilingualProperties[i]

                    if (!mlModel[key]) mlModel[key] = {}
                    if (!mlModel[key][language]) mlModel[key][language] = null

                    // console.info("--", language, key)
                    model[key] = mlModel[key][language]
                }

                if (index === 0) {
                    schema = schemaSpec.schema
                    vm.schema = schema

                    // At this point, model only contains multilingual properties
                    // so the rest of the properties is added now.
                    // Properties must be added here otherwise the beforeSave hook in formSchema js will not get the correct model
                    for (const modelProp in mlModel) {
                        if (mlModel.hasOwnProperty(modelProp) && !model.hasOwnProperty(modelProp)) model[modelProp] = mlModel[modelProp]
                    }
                    currentModel = model
                }
                else {
                    schema = schemaSpec.multilingualSchema
                }

                // console.table([model]);

                vm.multilingualForms.push({
                    languageName: $translate.instant('languages.byCode.' + language),
                    language,
                    prototypeModel: angular.copy(model),
                    model,
                    form: index !== 0 ? multilingualOnlyForm : schemaSpec.form,
                    schema,
                    onlyMultilingual: index !== 0,
                })
            })

            formsByLanguage = _.keyBy(vm.multilingualForms, 'language')

            vm.selectedLanguage = multilingualLanguages[0]
            vm.multilingualForms[0].selected = true
            // console.log(vm.multilingualForms);
            vm.loading = false
            $timeout()
            // $timeout(function () {
            // 	$scope[vm.formName].$setPristine();
            // }, 1000)
        }

        function submit(submitCallback) {
            let
                deferredUpload
				
            let uploadPromise
				
            const deferred = $.Deferred()
				
            const promise = deferred.promise()
				
            const innerDeferred = $.Deferred()
				
            const innerPromise = innerDeferred.promise()

            if (busy) {
                deferred.resolve(false)
            }
            else {
                if (isValid()) {
                    busy = true
                    // submitButton.addClass("loading");
                    formSchemaService.beforeSave(vm.isMultilingualForm ? _.get(vm.multilingualForms, '[0].model') : vm.model, objectType)

                    if (!vm.ignoreUpload && (vm.schema.properties.fileUpload || vm.schema.properties.imageUpload)) {
                        deferredUpload = $.Deferred()
                        uploadPromise = deferredUpload.promise()

                        $scope.$broadcast('schemaFormFileUploadSubmit', deferredUpload)

                        uploadPromise.then((res) => {
                            if (res && res.data)
                            {
                                if (res.data.createdAt) delete res.data.createdAt
                                if (res.data.savedAt) delete res.data.savedAt

								 // The properties vm.model.imageUpload or vm.model.fileUpload is set schema-form-file.js.
								 // It might happen that they are not set because the reference to the original model object was changed.
								 // This is rare but probably happens when the form is resetted and the uploader still uses the reference to the old form model.
								 // An extra check and assignment is done here to fix that issue (2018-09-13).
                                if (vm.schema.properties.imageUpload) vm.model.imageUpload = res.data
                                if (vm.schema.properties.fileUpload) vm.model.fileUpload = res.data
                            }

                            continueSubmit(submitCallback, innerDeferred)
                        }).fail((res) => {
                            // console.log(res);
                            busy = false
                            deferred.reject(res)
                            // submitButton.removeClass("loading");
                        })
                    }
                    else {
                        continueSubmit(submitCallback, innerDeferred)
                    }
                }
                else {
                    if ($scope.vm.multilingualForms.length != 0 && vm.requireAllLanguages) {
                        let invalidForm; let requiredFields = undefined
                        _.each(formsByLanguage, (form) => {
                            if (form) {
                                requiredFields = form.schema.required

                                _.each(requiredFields, (field) => {
                                    if (form.model[field] === undefined || form.model[field] === '') invalidForm = form
                                })
                            }
                        })

                        if (invalidForm) showRequiredMessageDialog(invalidForm)
                    }
                    deferred.resolve(false)
                }
            }

            innerPromise.then((savedItem) => {
                let operationsCount; const i = 0; const promises = []

                if (vm.showRelations && relationBucketResult && relationBucketResult.newlySelected && relationBucketResult.newlyRemoved) {
                    // console.log(relationBucketResult);
                    operationsCount = relationBucketResult.newlySelected.length + relationBucketResult.newlyRemoved.length

                    // if (intersectionSettings) {
                    // 	operationsCount
                    // }

                    // if (operationsCount > 0) {
                    _.forEach(relationBucketResult.newlySelected, (item) => {
                        // console.log("createSubItemRelation", savedItem, item, enums.subItemsKind.relatedContentByUser);
                        // if (savedItem.wfid == item.wfid) {
                        // 	operationComplete();
                        // }
                        // else {
                        if (savedItem.wfid !== item.wfid) {
                            promises.push(dataOps.createSubItemRelation(savedItem, item, {
                                kind: enums.subItemsKind.relatedContentByUser,
                                networkId: _.get(intersectionSettings, 'networkId'),
                                contextParentWfid: intersectionSettings && intersectionSettings.contextParents ? intersectionSettings.contextParents[0] : undefined,
                            }))

                            // if (intersectionSettings && intersectionSettings.contextParents) {
                            // 	_.forEach(intersectionSettings.contextParents, function (wfid) {
                            // 		promises.push(dataOps.createSubItemRelation(wfid, item, enums.subItemsKind.contextChildren));
                            // 	});
                            // }
                        }
                    })
                    _.forEach(relationBucketResult.newlyRemoved, (item) => {
                        const dataRelation = _.find(originalRelations, { wfcid: item.wfid })
                        // console.log(dataRelation);
                        if (dataRelation) {
                            // console.log("destroy", dataRelation);
                            promises.push(dataOps.destroy(dataRelation))
                        }
                        // else
                        // 	operationComplete();
                    })

                    // }
                    // else
                    // 	deferred.resolve(savedItem);
                }
                // else {
                // 	deferred.resolve(savedItem);
                // }

                $scope.$broadcast('schemaFormSubmitDeferred', promises, savedItem)

                // If a finding was updated then remove any virtualDataRelations in JSData where the finding is childContent.
                // VirtualDataRelations with findings are found in influence with a requirement package that constructs a virtual tree with findings for the supplier.
                // if (objectType === enums.objectType.finding && !$scope.isCreate && savedItem.locked && savedItem.wfid) {
                // 	wfObject.ejectAll({ where: {
                // 		type: enums.objectType.virtualDataRelation,
                // 		childType: enums.objectType.finding,
                // 		wfcid: savedItem.wfid
                // 	} });
                // }

                if (promises.length) {
                    $q.all(promises).then(() => {
                        deferred.resolve(savedItem)
                    })
                }
                else deferred.resolve(savedItem)

                // function operationComplete() {
                // 	i++
                // 	// console.log(i, operationsCount);
                // 	if (i == operationsCount) {
                // 		// console.log("Resolving for real")
                // 		deferred.resolve(savedItem);
                // 	}
                // }
            }).fail((errorResult) => {
                let message
                // console.log(errorResult)
                if (errorResult) {
                    if (errorResult.multilingualMessagePath) {
                        message = $translate.instant(errorResult.multilingualMessagePath)
                    }
                    else {
                        message = $translate.instant('SomethingWentWrongDuringSaving')
                    }

                    $ngBootbox.customDialog({
                        message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $sanitize(message) + '</div>',
                        // onEscape: true,
                        closeButton: false,
                        className: 'centerWithIcon',
                        buttons: {
                            primary: {
                                label: $translate.instant('OK'),
                                className: 'btn-primary',
                            },
                        },
                    })
                    // $ngBootbox.alert(errorResult.multilingualPath);
                    // $ngBootbox.alert($translate.instant(errorResult.multilingualPath))
                }
                deferred.resolve(false)
            })

            promise.then(() => {
                busy = false
            })

            return promise
        }

        function showRequiredMessageDialog(invalidForm) {
            modal.alert({
                title: $translate.instant('RequiredFormFieldHeader'),
                message: $translate.instant('RequiredFormFieldDescription'),
                type: 'warning',
                buttons: {
                    primary: {
                        label: 'OK',
                        className: 'btn-hollow action',
                        callback() {
                            if (invalidForm) {
                                setSelectedLanguage(invalidForm.language)
                                vm.selectedLanguage = invalidForm.language
                                $timeout()
                            }
                        },
                    },
                },
            })
        }

        function submitButtonClick(e) {
            submitButton = $(e.currentTarget)
        }

        function continueSubmit(submitCallback, innerDeferred) {
            let
                model
				
            let mlProperty

            if (vm.isMultilingualForm) {
                // Loop through each language multilingual spec object. (The first one contains all form fields, the rest only fields for each language)
                _.forEach(vm.multilingualForms, (multilingual, index) => {

                    if (!multilingual.onlyMultilingual) {
                        // Set up the model that will be populated with multilingual properties.
                        model = angular.copy(multilingual.model)
                        for (var i = 0, len = multilingualProperties.length, key; i < len; i++) {
                            // console.log(multilingualProperties[i]);
                            model[multilingualProperties[i]] = {}
                        }
                    }

                    // Loop through multilingual property names and set value for each language
                    for (var i = 0, len = multilingualProperties.length, key; i < len; i++) {
                        key = multilingualProperties[i]
                        model[key][multilingual.language] = multilingual.model[key]
                    }
                })

                model = _.merge(angular.copy(vm.model), angular.copy(model))
            }
            else {
                $scope.$broadcast('schemaFormBeforeSubmit', vm.model)
                model = vm.model
            }

            if (onBeforeSubmit) {
                model = onBeforeSubmit(model)
                if (model === false) {
                    innerDeferred.reject(false)
                    return
                }
            }

            // return;

            submitCallback(model, innerDeferred)
        }

        function reset() {
            $scope.$broadcast('schemaFormReset')
            $scope[vm.formName].$setPristine()

            if (vm.isMultilingualForm) {
                _.forEach(vm.multilingualForms, (multilingual, index) => {
                    multilingual.model = angular.copy(multilingual.prototypeModel)
                    if (multilingual.selected) currentModel = multilingual.model
                })
            }
            else vm.model = angular.copy(prototypeModel)
        }

        function resetUploaders(newImageUrl) {
            $scope.$broadcast('schemaFormResetUploaders', newImageUrl)
        }

        function isValid() {
            const validator = { isValid: true }
            $scope.$broadcast('schemaFormValidate', undefined, validator)

            if (!validator.isValid) return false

            if (vm.schema.properties.fileUpload && $scope[vm.formName].uploadForm && $scope[vm.formName].uploadForm.file && $scope[vm.formName].uploadForm.file.$valid === false) return false

            if ($scope.showAttachInformation && (!relationBucketResult || relationBucketResult.allSelected.length == 0)) {
                $ngBootbox.alert($translate.instant('ShouldAttachInformation'))
                return false
            }

            return $scope[vm.formName].$valid
        }

        function openPicker(pickObjectType) {
            const
                jqDf = $.Deferred()
				
            let allSelected
			
            if (relationBucketResult) allSelected = relationBucketResult.allSelected
            else allSelected = vm.relations

            modal.openCreatorAndPicker({
                objectTypes: [pickObjectType],
                displayTopItem: vm.model.wfid ? vm.model : { type: objectType, _headerText_: $translate.instant('New') + ' ' + wfTranslate.instant('MAP_ObjectType', { type: objectType }).toLowerCase() },
                relationBucket: { preSelected: vm.relations, allSelected },
                title: $translate.instant('AttachInformation'),
            }).closed((_relationBucketResult) => {
                jqDf.resolve()

                relationBucketResult = _relationBucketResult

                vm.wrappedRelations.length = 0
                _.forEach(relationBucketResult.allSelected, (item) => {
                    vm.wrappedRelations.push({
                        childContent: item,
                        childType: item.type,
                    })
                })

                $timeout(() => {})
            })

            jqDf.promise()
        }

        function getModel() {
            return vm.model
        }

        function setModel(newModel) {
            return vm.model = newModel
        }
    }
})()
