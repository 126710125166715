angular
    .module('theme.core.services', [])
    .factory('progressLoader', () => {
        'use strict'
        return {
            start() {
                angular.element.skylo('start')
            },
            set(position) {
                angular.element.skylo('set', position)
            },
            end() {
                angular.element.skylo('end')
            },
            get() {
                return angular.element.skylo('get')
            },
            inch(amount) {
                angular.element.skylo('show', () => {
                    angular.element(document).skylo('inch', amount)
                })
            },
        }
    })
    .factory('EnquireService', ['$window', function($window) {
        'use strict'
        return $window.enquire || {}
    }])
    .factory('$bootbox', ['$modal', '$window', function($modal, $window) {
        'use strict'
        // NOTE: this is a workaround to make BootboxJS somewhat compatible with
        // Angular UI Bootstrap in the absence of regular bootstrap.js
        if (angular.element.fn.modal === undefined) {
            angular.element.fn.modal = function(directive) {
                const that = this
                if (directive === 'hide') {
                    if (this.data('bs.modal')) {
                        this.data('bs.modal').close()
                        angular.element(that).remove()
                    }
                    return
                } else if (directive === 'show') {
                    return
                }

                const modalInstance = $modal.open({
                    template: angular.element(this).find('.modal-content').html(),
                })
                this.data('bs.modal', modalInstance)
                setTimeout(() => {
                    angular.element('.modal.ng-isolate-scope').remove()
                    angular.element(that).css({
                        opacity: 1,
                        display: 'block',
                    }).addClass('in')
                }, 100)
            }
        }

        return $window.bootbox
    }])
    .service('lazyLoad', ['$q', '$timeout', function($q, $t) {
        'use strict'
        const deferred = $q.defer()
        let promise = deferred.promise
        this.load = function(files) {
            angular.forEach(files, (file) => {
                if (file.indexOf('.js') > -1) { // script
                    (function(d, script) {
                        const fDeferred = $q.defer()
                        script = d.createElement('script')
                        script.type = 'text/javascript'
                        script.async = true
                        script.onload = function() {
                            $t(() => {
                                fDeferred.resolve()
                            })
                        }
                        script.onerror = function() {
                            $t(() => {
                                fDeferred.reject()
                            })
                        }

                        promise = promise.then(() => {
                            script.src = file
                            d.getElementsByTagName('head')[0].appendChild(script)
                            return fDeferred.promise
                        })
                    }(document))
                }
            })

            deferred.resolve()

            return promise
        }
    }])
    .filter('safe_html', ['$sce', function($sce) {
        'use strict'
        return function(val) {
            return $sce.trustAsHtml(val)
        }
    }])
