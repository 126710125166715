//@flow
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import ButtonBase from '@material-ui/core/ButtonBase'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import classnames from 'classnames'

type Props = {
  guidance: string,
  
  icon?: string | React.Node,
  dialogTitle?: string,
  buttonLabel?: string | React.Node,
  buttonFontColor?: string,

  classes: { +[string]: string },
  style?: { [string]: any },
}

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    guidanceButton: {
        fontSize: 18,
        color: Colors.black,
        display: 'flex',
        alignItems: 'center',

        '& i': {
            fontSize: 18,
        },
    },
    buttonLabel: {
        marginLeft: 10,
    },
    dialogContentContainer: {
        display: 'flex',
        padding: '36px !important',
        color: Colors.blackDark,
    },
    dialogTitleContainer: {
        marginBottom: 35,
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogTitle: {
        fontSize: 32,
        fontWeight: 300,
    },
    guidance: {
        fontSize: 18,
        fontWeight: 300,
    },
    closeButton: {
        width: 40,
        height: 40,
        padding: 8,
    },
    closeIcon: {
        fontSize: 18,
    },
}

class GuidanceButton extends React.PureComponent<Props, { dialogOpen: boolean }> {

  static defaultProps = {
      icon: 'fas fa-info-circle',
  }

  state = {
      dialogOpen: false,
  }
  
  _onGuidanceClick = () => this.setState({ dialogOpen: true })
  
  _handleClose = () => this.setState({ dialogOpen: false })

  render() {
      const { classes, style, icon, buttonLabel, buttonFontColor, dialogTitle, guidance } = this.props

      return (
          <div className={classes.root}>
              <ButtonBase
                  classes={{ root: classes.guidanceButton }}
                  style={{ color: buttonFontColor, ...style }}
                  disableRipple
                  type="button"
                  onClick={this._onGuidanceClick}
              >
                  <i className={icon} />
                  {
                      buttonLabel && (<span className={classes.buttonLabel}> { buttonLabel }</span>)
                  }
              </ButtonBase>
              <Dialog
                  open={this.state.dialogOpen}
                  onClose={this._handleClose}
                  aria-labelledby="dialog-impact-title"
                  PaperProps={{ style: { minWidth: 700 } }}
              >
                  <DialogContent classes={{ root: classes.dialogContentContainer }}>
                      <div style={{ flex: 1 }}>
                          <div className={classes.dialogTitleContainer}>
                              <div className={classes.dialogTitle}>{ dialogTitle || <FormattedMessage id="general.guidance" /> }</div>
                              <IconButton aria-label="Close" className={classes.closeButton} onClick={this._handleClose}>
                                  <Icon className={classnames(classes.closeIcon, 'fas fa-times')} />
                              </IconButton>
                          </div>
                          <div className={classes.guidance}>{ guidance }</div>
                      </div>
                  </DialogContent>
              </Dialog>
          </div>
      )
  }
}

export default withStyles(styles)(GuidanceButton)
