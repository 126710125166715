// @flow
import * as React from 'react'
import * as Plugins from './plugins'
import _ from 'lodash'
import type { ChartistData, ChartistOptions, ChartTypes } from '@worldfavor/types/Chartist'
import { _getChartWithDynamicBars } from './helper'

export const GraphType = {
    Line: 'Line',
    Bar: 'Bar',
    Pie: 'Pie',
    Donut: 'Donut',
    StackedBar: 'Stacked Bar',
    DistributedSeries: 'Distributed Series',
}

type Props = {
  type: ChartTypes,
  data: ChartistData,

  className?: string,
  style?: {},
  children?: React.Node,
  options?: ChartistOptions,
  responsiveOptions?: Array<any>,
  listener?: { [string]: (any) => void },
}

const isBarChart = type => type === GraphType.Bar || type === GraphType.StackedBar
  || type === GraphType.DistributedSeries
const isPieChart = type => type === GraphType.Pie || type === GraphType.Donut

class ChartistGraph extends React.Component<Props> {

  chartist = null

  container: HTMLElement | null = null
  type = null

  displayName: 'ChartistGraph'

  componentDidMount() {
      this.updateChart(this.props)
  }

  componentDidUpdate(prevProps: Props): void {
      if (this.props !== prevProps) {
          this.updateChart(this.props)
      }
  }

  componentWillUnmount() {
      if (this.chartist) {
          try {
              this.chartist.detach()
          } catch (err) {
              // $FlowFixMe ignore the warning as they are not relevant
              throw new Error('Internal chartist error', err)
          }
      }
  }

  getContainer = () => this.container

  _getChartistCompatibleType = (type: string): string => {
      switch (type) {
          case GraphType.Donut:
              return GraphType.Pie
          case GraphType.StackedBar:
          case GraphType.DistributedSeries:
              return GraphType.Bar
          default:
              return type
      }
  }

  _getOptions = (config: Props) => {
      const { type, data } = config
      let options = config.options || {}

      if (type === GraphType.Donut) {
          options = {
              donut: true,
              donutWidth: 30,
              donutSolid: true,
              startAngle: 0,
              showLabel: true,
              ...options,
          }
      } else if (type === GraphType.StackedBar) {
          options = {
              stackBars: true,
              ...options,
          }
      } else if (type === GraphType.DistributedSeries) {
          options = {
              distributeSeries: true,
              ...options,
          }
      }

      if (isPieChart(type)) {
          options = {
              ...options,
              verticalSeries: data.labels,
              selectedVerticalSeries: _.last(data.labels),
          }
      }

      if (isBarChart(type)) {
          if (options.plugins
        && options.plugins.map(f => f.name).includes(Plugins.ctDynamicBarWidth().name)) {
              options = _getChartWithDynamicBars(this.container, options, data)
          }
      }

      return options
  }

  updateChart(config: Props) {
      const Chartist = require('chartist')

      const { data, type, responsiveOptions = [] } = config

      const options = this._getOptions(config)

      if (this.chartist && this.type === type) {
          this.chartist.update(data, options, responsiveOptions)
      } else {
      // set new instance type
          this.type = type

          const refinedType = this._getChartistCompatibleType(type)

          this.chartist = new Chartist[refinedType](this.container, data, options, responsiveOptions)

          if (config.listener) {
              for (const event in config.listener) {
                  if (config.listener.hasOwnProperty(event)) {

                      // $FlowFixMe ignore the warning as they are not relevant
                      this.chartist.on(event, config.listener[event])
                  }
              }
          }
      }

      return this.chartist
  }

  render() {
      const { className, style, children, data, type } = this.props
      const childrenWithProps = children && React.Children.map(children, child => (
          React.cloneElement(child, {
              type,
              data,
          })
      ))
      return (
          <div
              ref={ref => this.container = ref}
              className={`ct-chart ${className || ''}`}
              style={style}
          >
              {childrenWithProps}
          </div>
      )
  }
}

export default ChartistGraph
