import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'
	
    angular
        .module('wf.common')
        .component('wfFinding', {
            templateUrl: 'scripts/wf/findings/wfFinding.component.html',
            controller: wfFindingController,
            controllerAs: 'vm',
            require: {
                wfInfluenceViewerVm: '?^^wfInfluenceViewer',
            },
            bindings: {
                ticket: '<',
                item: '<',
                uiMode: '<',
                excludeFindingText: '<',
                printMode: '<',
            },
        })

    wfFindingController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'findingService', 'wfAuth', 'modalService', '$templateCache']
    function wfFindingController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, findingService, wfAuth, modalService, $templateCache) {
        const
            vm = this
			
        let finding
			
        let stepsInfo
			
        let itemComposite
			
        const authOrgId = wfAuth.getOrganizationId()
		
        _.assign(vm, {
            formModel: {},
            ownerTargetCompiler: {},
            attachedInfoCompiler: {},
            progressBarCompiler: {},
            formControl: {},
            loading: false,
            ownerObject: undefined,
            ownerAndTargetSet: undefined,
            ticket: undefined,
            totalAnswersCounted: 0,
            allStepsAreOptional: false, // If all steps are optional the finding is instantly fulfilled and no added answers affects the fulfillment state
            progress: {
                approved: {
                    label: 'Approved', //add multilingual
                    count: 0,
                    percentage: 0,
                },
                needed: {
                    label: 'Needed', //add multilingual
                    count: 0,
                    percentage: 0,
                },
                denied: {
                    label: 'Denied', //add multilingual
                    count: 0,
                    percentage: 0,
                },
                updated: {
                    label: 'Updated', //add multilingual
                    count: 0,
                    percentage: 0,
                },
            },
            neededActionText: undefined,
            neededAction: true,

            //Functions
            openOwnerAndTargetForm,
            attachInformation,
            syncSteps,
            onAnswerStateChanged,
            determineNeededAction,
            emitFindingLoaded,
        })

        this.$onInit = $onInit

        function $onInit() {
            const
                initialPromises = []
				
            let promise

            if (vm.item.composite) {
                itemComposite = vm.item
                vm.finding = finding = vm.item.content
            }
            else vm.finding = finding = vm.item

            determineTicket()
            determineUiMode()

            vm.answers = _.map(finding.childs, 'childContent')
            vm.answersByStepId = _.groupBy(vm.answers, 'findingStepId')

            formatFindingProperties()

            promise = findingService.getFindingSteps().then((_stepsInfo) => {
                stepsInfo = _stepsInfo
                buildStepsHierarchy()
                syncOwnerObject()

                checkIfAllStepsAreOptional()
            })

            initialPromises.push(promise)

            $q.all(initialPromises).then(() => {
                if (vm.printMode) {
                    $timeout(() => {
                        emitFindingLoaded(vm.finding)
                    })
                }
            })
        }

        function formatFindingProperties() {
            vm.severity = findingService.severityById[finding.severity]
            vm.severityName = _.get(vm.severity, 'name')
            vm.severityColor = _.get(vm.severity, 'color')
            vm.statusText = $translate.instant(vm.finding.closed ? 'modules.findings.statuses.closed' : 'modules.findings.statuses.open')
        }

        function buildStepsHierarchy() {
            const output = []; let stepOutput; let optionalWhenSeverity

            for (var i = 0, len = stepsInfo.steps.length, step; i < len; i++) {
                step = stepsInfo.steps[i]
                optionalWhenSeverity = _.get(step.findingStepSettings, 'optionalWhenSeverity')

                stepOutput = {
                    //Variables
                    id: step.id,
                    wfid: step.wfid,
                    title: step.title,
                    answers: vm.answersByStepId[step.id],
                    isCompleted: false,
                    findingStepSettings: step.findingStepSettings,
                    emptyStateMessage: undefined,
                    emptyStateIcon: '',

                    isOptional: false,
					
                    //Functions 
                    checkIfAllAnswersAreApproved: stepPrototype_checkIfAllAnswersAreApproved,
                    checkIfStepIsUnlocked: stepPrototype_checkIfStepIsUnlocked,
                    syncAnswers: stepPrototype_syncAnswers,
                    getEmptyStateMessage: stepPrototype_getEmptyStateMessage,
                }

                if (finding.historic || (optionalWhenSeverity instanceof Array && !!~optionalWhenSeverity.indexOf(finding.severity))) {
                    stepOutput.isOptional = true
                }

                stepOutput.checkIfAllAnswersAreApproved()

                output.push(stepOutput)
            }

            vm.findingSteps = output
            vm.findingStepsByWfid = _.keyBy(output, 'wfid')

            vm.requiredFindingSteps = _.filter(vm.findingSteps, { isOptional: false })

            function stepPrototype_syncAnswers() {
                const step = this
                vm.answers = _.map(finding.childs, 'childContent')
                vm.answersByStepId = _.groupBy(vm.answers, 'findingStepId')
                step.checkIfStepIsUnlocked()
                step.answers = vm.answersByStepId[step.id]
                step.checkIfAllAnswersAreApproved()
            }

            function stepPrototype_checkIfAllAnswersAreApproved() {
                if (this.isOptional && (!this.answers || !this.answers.length)) this.isCompleted = true
                else if (this.answers && this.answers.length > 0) this.isCompleted = _.every(this.answers, { state: enums.findingStepAnswerState.answerApproved })
                else this.isCompleted = false
            }
			
            function stepPrototype_checkIfStepIsUnlocked() {
                const
                    step = this
					
                let stepToCheck
					
                const findingStepSettings = step.findingStepSettings
					
                let stepWfids

                step.isLocked = false

                if (!vm.ownerAndTargetSet && (!step.answers || step.answers.length === 0)) step.isLocked = true
                else {
                    if (findingStepSettings && findingStepSettings.unlockWhenCompleted && findingStepSettings.unlockWhenCompleted.length > 0) {
                        stepWfids = findingStepSettings.unlockWhenCompleted
	
                        if (stepWfids && stepWfids.length > 0) {
                            step.isLocked = _.some(stepWfids, (stepWfid) => {
                                stepToCheck = vm.findingStepsByWfid[stepWfid]
                                return stepToCheck && !stepToCheck.isCompleted && !stepToCheck.isOptional
                            })
                        }
                    }
                }

                step.getEmptyStateMessage()
            }

            function stepPrototype_getEmptyStateMessage() {
                const step = this
                let emptyStateMessage = ''
                let waitingForAnAnswer = false
                step.emptyStateIcon = 'fa fa-lock'
	
                if (!step.isLocked && (!step.answers || step.answers.length === 0)) {
                    if (vm.isAdminMode) {
                        emptyStateMessage = $translate.instant('modules.findings.step.emptyState.nothingAddedYet')
                        step.isLocked = true
                        waitingForAnAnswer = true
                        step.emptyStateIcon = 'fa fa-info-circle'
                    }
                }
				
                if (step.isLocked && !waitingForAnAnswer) {
                    if (vm.ownerAndTargetSet) {
                        if (vm.isAdminMode) emptyStateMessage = $translate.instant('modules.findings.step.emptyState.cannotBeAddedVerificationNeeded', { organizationName: wfObject.get('101-' + finding.targetOrganizationId).name })
		
                        if (vm.isWorkMode) emptyStateMessage = $translate.instant('modules.findings.step.emptyState.otherStepsNotCompleted', { organizationName: wfObject.get('101-' + finding.creatorOrganizationId).name })
                    }
                    else if (!vm.ownerAndTargetSet) {
                        if (vm.isAdminMode) {
                            emptyStateMessage = $translate.instant('modules.findings.step.emptyState.nothingAddedYet')
                            step.emptyStateIcon = 'fa fa-info-circle'
                        }
	
                        if (vm.isWorkMode) emptyStateMessage = $translate.instant('modules.findings.step.emptyState.ownerAndTargetNotSet')
                    }
                }

                step.emptyStateMessage = emptyStateMessage
				
                return emptyStateMessage
            }
        }

        function determineUiMode() {
            if (vm.uiMode) {
                if (vm.uiMode ===  enums.uiMode.admin && finding.creatorOrganizationId === authOrgId) {
                    vm.isAdminMode = true
                }
                else if (vm.uiMode ===  enums.uiMode.work && finding.targetOrganizationId === authOrgId) {
                    vm.isWorkMode = true
                }
            }
            else {
                if (finding.creatorOrganizationId === authOrgId) {
                    vm.isAdminMode = true
                }
                else if (finding.targetOrganizationId === authOrgId) {
                    vm.isWorkMode = true
                }
            }
        }

        function determineTicket() {
            const influence = _.get(vm.wfInfluenceViewerVm, 'influence')

            if (influence) {
                vm.ticket = {
                    organizationId: influence.organizationId,
                    networkId: influence.channelId,
                    contextParentWfid: influence.contextParentWfids,
                }
            }
            else {
                vm.ticket = {
                    organizationId: wfAuth.getOrganizationId(),
                }
            }
        }

        function updateProgressCounts(initial) {
            let
                answersOfRequiredSteps
				
            let requiredStepsAndOptionalStepsWithAnswers
				
            let allAnswers

            if (!initial) clearCounts()

            if (vm.ownerAndTargetSet) {
                vm.progress.approved.count++
                vm.totalAnswersCounted++
            }
            else {
                vm.progress.needed.count++
                vm.totalAnswersCounted++
            }

            answersOfRequiredSteps = _.chain(vm.requiredFindingSteps).map('answers').flatten().value()
            requiredStepsAndOptionalStepsWithAnswers = getRequiredStepsAndOptionalStepsWithAnswers()
            allAnswers = _.chain(requiredStepsAndOptionalStepsWithAnswers).map('answers').flatten().value()

            _.each(requiredStepsAndOptionalStepsWithAnswers, (step) => {
                if (step.answers && step.answers.length > 0) {
                    _.each(step.answers, (answer) => {
                        switch (answer.state) {
                            case enums.findingStepAnswerState.answerApproved:
                                vm.progress.approved.count++
                                break
                            case enums.findingStepAnswerState.answerNeeded:
                                vm.progress.needed.count++
                                break
                            case enums.findingStepAnswerState.answerDenied:
                                vm.progress.denied.count++
                                break
                            case enums.findingStepAnswerState.answerUpdated:
                                vm.progress.updated.count++
                                break
                            default:
                                break
                        }
                        vm.totalAnswersCounted++
                    })
                }
                else {
                    vm.progress.needed.count++
                    vm.totalAnswersCounted++
                }
            })

            _.each(vm.progress, (progress) => { progress.percentage = (100 / vm.totalAnswersCounted) * progress.count })

            function clearCounts() {
                vm.totalAnswersCounted = 0
                _.each(vm.progress, (progress) => {
                    progress.count = 0
                })
            }
        }

        function determineNeededAction() {
            const state = determineFulfillmentState()
            if (state) {
                vm.neededAction = true
                if (state === enums.fulfillmentState.fulfilled) {
                    vm.neededActionText = $translate.instant('modules.findings.actions.completed')
                    vm.neededAction = false
                }
                else if (state === enums.fulfillmentState.assessmentNeeded) {
                    if (wfObject.get('101-' + finding.creatorOrganizationId)) vm.neededActionText = $translate.instant('modules.findings.actions.toBeAssessed', { organizationName: wfObject.get('101-' + finding.creatorOrganizationId).name })
                    else vm.neededActionText = $translate.instant('modules.findings.actions.toBeAssessedNoOrg')
                }
                else if (state === enums.fulfillmentState.reportingNeeded) {
                    if (wfObject.get('101-' + finding.targetOrganizationId)) vm.neededActionText = $translate.instant('modules.findings.actions.toBeReported', { organizationName: wfObject.get('101-' + finding.targetOrganizationId).name })
                    else vm.neededActionText = $translate.instant('modules.findings.actions.toBeReportedNoOrg')
                }
            }
            else console.error('Couldn\'t determine the fulfillment state')
			
        }

        function determineFulfillmentState() {
            let
                fulfillmentState
				
            let unlockedFindingSteps
				
            const answersOfRequiredSteps = _.chain(vm.requiredFindingSteps).map('answers').flatten().value()
				
            const requiredStepsAndOptionalStepsWithAnswers = getRequiredStepsAndOptionalStepsWithAnswers()
				
            const allAnswers = _.chain(requiredStepsAndOptionalStepsWithAnswers).map('answers').flatten().value()

            vm.showNeededAction = requiredStepsAndOptionalStepsWithAnswers.length > 0

            if (vm.ownerAndTargetSet || allAnswers.length) {
                unlockedFindingSteps = _.filter(vm.requiredFindingSteps, { isLocked: false })
				
                // if there are no answers
                // or some answers have denied state
                // or some unlocked finding steps don't have an answer
                // then set fulfillment to report again
                if ((vm.requiredFindingSteps.length && answersOfRequiredSteps.length === 0)
					|| _.some(allAnswers, { state: enums.findingStepAnswerState.answerDenied })
					|| _.some(unlockedFindingSteps, (step) => { return !step.isOptional && (!step.answers || !step.answers.length) })
                ) {
                    fulfillmentState = enums.fulfillmentState.reportingNeeded
                }
                else { // if state changed to updated - set fulfillment to assessment needed
                    fulfillmentState = enums.fulfillmentState.assessmentNeeded

                    // if all steps are completed - set fulfillment to fulfilled
                    if (_.every(requiredStepsAndOptionalStepsWithAnswers, { isCompleted: true })) fulfillmentState = enums.fulfillmentState.fulfilled
                    else if (_.some(allAnswers, { state: enums.findingStepAnswerState.answerUpdated })) // if some answers still have state updated - set fulfillment to assessment needed 
                        fulfillmentState = enums.fulfillmentState.assessmentNeeded
                    else // otherwise, some answers are denied or new steps have been unlocked - set fulfillment to report again
                        fulfillmentState = enums.fulfillmentState.reportingNeeded
                }
            }
            else if (vm.allStepsAreOptional) {
                return enums.fulfillmentState.fulfilled
            }

            if ((!finding.ownerObjectId || !finding.targetCompletionAt) && !allAnswers.length) fulfillmentState = enums.fulfillmentState.reportingNeeded

            return fulfillmentState
        }

        function getRequiredStepsAndOptionalStepsWithAnswers() {
            return _.filter(vm.findingSteps, (step) => {
                return !step.isOptional || (step.answers && step.answers.length)
            })
        }

        function openOwnerAndTargetForm() {
            if (vm.finding) {
                vm.finding.updateByTargetOrganization = true
                modalService.edit(vm.finding, {
                    translateTitle: 'modules.findings.ownerAndTarget.form.modalTitle',
                    replaceFormSpecification: true,
                    customFormSpecification: getFormSpecification(),
                }).then(() => {
                    delete vm.finding.updateByTargetOrganization
                    syncOwnerObject()
                })
            }
            else {
                console.error('Could not open the form, vm.finding not defined')
            }

            function getFormSpecification() {
                return {
                    schema: {
                        type: 'object',
                        required: ['targetCompletionAt'],
                        properties: {
                            ownerObjectId: {
                                type: 'integer',
                            },
                            ownerObjectType: {
                                type: 'integer',
                            },
                            targetCompletionAt: {
                                type: 'string',
                                format: 'date',
                            },
                        },
                    },
                    form: [
                        {
                            key: 'owner',
                            title: $translate.instant('modules.findings.ownerAndTarget.form.ownerTitle'),
                            type: 'picker_multiple',
                            typeOptions: {
                                selectedItem: {
                                    id: vm.finding.ownerObjectId ? vm.finding.ownerObjectId : null,
                                    type: vm.finding.ownerObjectType ? vm.finding.ownerObjectType : null,
                                },
                                addButtonCaption: $translate.instant('modules.findings.ownerAndTarget.form.ownerButtonCaption'),
                                singlePick: true,
                                picker: {
                                    sourceItem: '73-322825', // List of available colleagues
                                    title: $translate.instant('modules.findings.ownerAndTarget.form.pickerTitle'),
                                    description: $translate.instant('modules.findings.ownerAndTarget.form.pickerDescription'),
                                },
                                validateAction(event, model, relationBucket) { return !!relationBucket.allSelected.length },
                                submitAction(event, model, relationBucket) {
                                    const selectedItem = relationBucket.allSelected[0]
	
                                    if (selectedItem) {
                                        model.ownerObjectId = selectedItem.id
                                        model.ownerObjectType = selectedItem.type
                                    }
                                },
                            },
                        },
                        {
                            key: 'targetCompletionAt',
                            format: 'yyyy-mm-dd',
                            title: $translate.instant('modules.findings.ownerAndTarget.form.targetCompletionDateTitle'),
                            // validationMessage: {
                            // 	'validateDateInTheFuture': ''
                            // },
                            // $validators: {
                            // 	validateDateInTheFuture: function(value) {
                            // 		return value;
                            // 	}
                            // }
                        },
                    ],
                }
            }
        }

        function syncOwnerObject() {
            if (finding && finding.ownerObjectId) {
                vm.ownerAndTargetSet = !!finding.ownerObjectId && !!finding.ownerObjectType
                vm.ownerObject = wfObject.get('100-' + finding.ownerObjectId)
                syncSteps()
            }
            determineNeededAction()

            if (typeof vm.ownerTargetCompiler.compile === 'function') vm.ownerTargetCompiler.compile()
        }

        function attachInformation() {
            const pickerOptions = {
                objectTypes: [enums.objectType.orgDocument],
                relationTarget: {
                    item: vm.finding,
                    kind: enums.subItemsKind.relatedContentByUser,
                },
                intersection: {
                    organizationId: vm.finding.creatorOrganizationId,
                    networkId: vm.finding.networkId,
                },
            }

            modalService.openCreatorAndPicker(pickerOptions).closed(() => {
                if (typeof vm.attachedInfoCompiler.compile === 'function') vm.attachedInfoCompiler.compile()
            })
        }

        function syncSteps() {
            _.invokeMap(vm.findingSteps, 'checkIfStepIsUnlocked')
            updateProgressCounts()
        }

        function onAnswerStateChanged(answer) {
            let
                fulfillmentState
				
            let unlockedFindingSteps
				
            const answersOfRequiredSteps = _.chain(vm.requiredFindingSteps).map('answers').flatten().value()
				
            const requiredStepsAndOptionalStepsWithAnswers = getRequiredStepsAndOptionalStepsWithAnswers()
				
            const allAnswers = _.chain(requiredStepsAndOptionalStepsWithAnswers).map('answers').flatten().value()

            if (finding.locked) return

            switch (answer.state) {
                case enums.findingStepAnswerState.answerUpdated:
                    unlockedFindingSteps = _.filter(vm.requiredFindingSteps, { isLocked: false })

                    // if there are no answers
                    // or some answers have denied state
                    // or some unlocked finding steps don't have an answer
                    // then set fulfillment to report again
                    if ((vm.requiredFindingSteps.length && answersOfRequiredSteps.length === 0)
						|| _.some(allAnswers, { state: enums.findingStepAnswerState.answerDenied })
						|| _.some(unlockedFindingSteps, (step) => { return !step.isOptional && (!step.answers || !step.answers.length) })
                    ) {
                        fulfillmentState = enums.fulfillmentState.reportingNeeded
                    }
                    else // if state changed to updated - set fulfillment to assessment needed
                        fulfillmentState = enums.fulfillmentState.assessmentNeeded
                    break
                case enums.findingStepAnswerState.answerApproved:
                    // if all steps are completed - set fulfillment to fulfilled
                    if (_.every(requiredStepsAndOptionalStepsWithAnswers, { isCompleted: true })) fulfillmentState = enums.fulfillmentState.fulfilled
                    else if (_.some(allAnswers, { state: enums.findingStepAnswerState.answerUpdated })) // if some answers still have state updated - set fulfillment to assessment needed 
                        fulfillmentState = enums.fulfillmentState.assessmentNeeded
                    else // otherwise, some answers are denied or new steps have been unlocked - set fulfillment to report again
                        fulfillmentState = enums.fulfillmentState.reportingNeeded
                    break
                case enums.findingStepAnswerState.answerDenied:
                    // if some answers still have state updated - set fulfillment to assessment needed 
                    if (_.some(allAnswers, { state: enums.findingStepAnswerState.answerUpdated })) fulfillmentState = enums.fulfillmentState.assessmentNeeded
                    else // otherwise - set fulfillment to report again
                        fulfillmentState = enums.fulfillmentState.reportingNeeded
                    break
            }

            if ((!finding.ownerObjectId || !finding.targetCompletionAt) && !allAnswers.length) fulfillmentState = enums.fulfillmentState.reportingNeeded

            if (itemComposite && itemComposite.updateFulfillment) {
                itemComposite.updateFulfillment()
            }

            if (fulfillmentState === enums.fulfillmentState.fulfilled && !finding.closed
				|| fulfillmentState !== enums.fulfillmentState.fulfilled && finding.closed
            ) {
                finding.closed = !finding.closed
                dataOps.update(finding, { ignoreMultilingualProperties: true }).then(() => {
                    vm.statusText = $translate.instant(vm.finding.closed ? 'modules.findings.statuses.closed' : 'modules.findings.statuses.open')
                    $timeout()
                }, (rejectedItem) => {
                    console.error('Could not update finding!')
                })
            }

            $scope.$emit('dataAnswerChanged', {
                itemContent: finding,
                requirement: finding.requirements[0],
                forceFulfillmentState: fulfillmentState,
                shouldCalculateInfluenceFulfillment: vm.isAdminMode,
            }) // Fetched by hierarchical directive that broadcasts it down to wfInfluenceSigning directive

            $scope.$emit('checkLocalFulfillment', finding, { fulfillmentState }) // Fetched by wfRequirement directive
        }

        function checkIfAllStepsAreOptional() {
            let
                fulfillmentState
				
            const existingFulfillment = finding.fulfillment
				
            let shouldUpdateFulfillmentState = false
				
            let shouldUpdateFinding = false

            if (finding.locked) return

            if (_.every(vm.findingSteps, { isOptional: true })) {
                vm.allStepsAreOptional = true
            }
			
            fulfillmentState = determineFulfillmentState()

            // if there is an existing fulfillment whos state doesnt match calculated state
            //  - set the new state and set Closed accordingly
            // if there is no existing fulfillment and the calculated state is ReportAgain
            //  - do nothing

            // Also, if the calculated state doesn't match the corresponding Closed state
            //  - set new Closed state

            if (existingFulfillment) {
                if (existingFulfillment.fulfillmentState !== fulfillmentState) {
                    shouldUpdateFulfillmentState = true
                }
				
            }
            else if (fulfillmentState === enums.fulfillmentState.reportingNeeded) {
                // Do nothing
            }

            if (fulfillmentState === enums.fulfillmentState.fulfilled && !finding.closed
				|| fulfillmentState !== enums.fulfillmentState.fulfilled && finding.closed
            ) {
                finding.closed = !finding.closed

                if (finding.closed) finding.closedAt = moment().format()
					
                shouldUpdateFinding = true
            }

            if (shouldUpdateFinding) {
                dataOps.update(finding, { ignoreMultilingualProperties: true }).then(() => {
                    vm.statusText = $translate.instant(vm.finding.closed ? 'modules.findings.statuses.closed' : 'modules.findings.statuses.open')
                    $timeout()
                }, (rejectedItem) => {
                    console.error('Could not update finding!')
                })
            }

            if (shouldUpdateFulfillmentState) {
                $scope.$emit('dataAnswerChanged', {
                    itemContent: finding,
                    requirement: finding.requirements[0],
                    forceFulfillmentState: fulfillmentState,
                    shouldCalculateInfluenceFulfillment: vm.isAdminMode,
                }) // Fetched by hierarchical directive that broadcasts it down to wfInfluenceSigning directive
	
                $scope.$emit('checkLocalFulfillment', finding, { fulfillmentState }) // Fetched by wfRequirement directive
            }
        }

        function emitFindingLoaded(finding) {
            $scope.$emit('findingLoaded', finding)
        }
    }
})()

/*

When a finding is created, if the outcome of the severity level is that all steps are optional, the findings status should be Closed instantly

Optional steps are exluded from requirement/fulfillment/action flow. Answers are only informative.
Any answers belonging to optional steps should be excluded from requirement/fulfillment/action flow.

If all steps are optional then no action or progress bar should be displayed.

*/
