(function ($) {
    'use strict'

    const isProductionSite = (location.hostname || '').indexOf('portal') !== -1

    angular
        .module('wf.data')
        .provider('apiProxy', apiProxy)

    apiProxy.$inject = ['$windowProvider']

    function apiProxy($windowProvider) {
        $.support.cors = true
        $.support.xhrFileUpload = !!($windowProvider.$get().ProgressEvent && $windowProvider.$get().FileReader)
        $.ajaxSetup({
            cache: false,
            xhrFields: {
                withCredentials: false, // This setting passes cookies along the request, including our authentication cookie.
            },
        })
		
        this.$get = ajaxUsingProxy
    }

    ajaxUsingProxy.$inject = ['$rootScope', '$timeout', '$q']

    function ajaxUsingProxy($rootScope, $timeout, $q) {
        const ajax = jQuery.ajax
        const func = function(route, data) {
            const
                splitRoute = route.split('.')
				
            const apiController = splitRoute[0]
				
            const apiAction = splitRoute[1]
				
            const actionFunction = $.proxies[apiController][apiAction]
				
            let formattedData
				
            let apiPromise
				
            const dfd = $q.defer()
            // jqDf = $.Deferred()

            if (!actionFunction)
            {
                throw 'ApiProxyError: ' + apiController + '.' + apiAction + ' is undefined.'
            }

            if (window.serverFail) {
                console.log('SERVER FAIL: TRUE')
                setTimeout(function () {
                    dfd.reject(...arguments)
                }, 100)
                return dfd.promise
            }

            formattedData = typeof data === 'string' ? data : JSON.stringify(data)

            apiPromise = $.proxies[apiController][apiAction](formattedData, isProductionSite || !data ? undefined : { fields: [JSON.stringify(data).replace(/\"/g, '').substring(0, 1000)] })

            apiPromise.then((res) => {
                // console.log(route, "resolve", res);
                dfd.resolve(res)
            })

            apiPromise.error(function() {
                const failArgs = arguments
                // console.log(route, "error", arguments);
                dfd.reject(...failArgs)
            })

            return dfd.promise
        }

        func.raw = function (route, data) {
            const
                splitRoute = route.split('.')
				
            const apiController = splitRoute[0]
				
            const apiAction = splitRoute[1]
				
            const actionFunction = $.proxies[apiController][apiAction]
				
            let formattedData

            if (!actionFunction)
            {
                throw 'ApiProxyError: ' + apiController + '.' + apiAction + ' is undefined.'
            }

            formattedData = typeof data === 'string' ? data : JSON.stringify(data)

            return $.proxies[apiController][apiAction](formattedData)
        }

        return func
    }
})(jQuery)
