import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { getMessages, getLocale } from '@worldfavor/i18n'
import { ThemeProvider } from '@material-ui/styles'
import { Provider } from 'react-redux'
import { getStore } from '../store/storeRegistry'
import theme from '@worldfavor/constants/theme'

export class WrapProvider extends React.Component { // eslint-disable-line import/prefer-default-export
    render() {
        const { wfAuth, children } = this.props
        let locale = getLocale(wfAuth.getCulture())
        if (locale === 'en-US') {
            locale = 'en-GB'
        }
        return (
            <IntlProvider
                key={locale}
                locale={locale}
                messages={getMessages(locale)}
            >
                <ThemeProvider theme={theme}>
                    {children}
                </ThemeProvider>
            </IntlProvider>
        )
    }
}

export class StoreProvider extends React.Component {
    render() {
        const { children } = this.props
        const store = getStore()
        return (
            <Provider store={store}>
                {children}
            </Provider>
        )
    }
}
