angular
    .module('theme.core.directives')
    .directive('disableAnimation', ['$animate', function($animate) {
        'use strict'
        return {
            restrict: 'A',
            link($scope, $element, $attrs) {
                $attrs.$observe('disableAnimation', (value) => {
                    $animate.enabled(!value, $element)
                })
            },
        }
    }])
    .directive('slideOut', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                show: '=slideOut',
            },
            link(scope, element) {
                element.hide()
                scope.$watch('show', (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                        element.slideToggle({
                            complete() {
                                scope.$apply()
                            },
                        })
                    }
                })
            },
        }
    })
    .directive('slideOutNav', ['$timeout', function($t) {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                show: '=slideOutNav',
            },
            link(scope, element) {
                scope.$watch('show', (newVal) => {
                    if (angular.element('body').hasClass('sidebar-collapsed')) {
                        if (newVal === true) {
                            element.css('display', 'block')
                        } else {
                            element.css('display', 'none')
                        }
                        return
                    }
                    if (newVal === true) {
                        element.slideDown({
                            complete() {
                                $t(() => {
                                    scope.$apply()
                                })
                            },
                            duration: 100,
                        })
                    } else if (newVal === false) {
                        element.slideUp({
                            complete() {
                                $t(() => {
                                    scope.$apply()
                                })
                            },
                            duration: 100,
                        })
                    }
                })
            },
        }
    }])
    .directive('fauxOffcanvas', ['EnquireService', '$window', function(EnquireService, $window) {
        'use strict'
        return {
            restrict: 'AE',
            link() {
                EnquireService.register('screen and (max-width: 767px)', {
                    match() {  //smallscreen
                        // angular.element('body').addClass('sidebar-collapsed');

                        setWidthtoContent()
                        angular.element(window).on('resize', setWidthtoContent)
                    },
                    unmatch() {  //bigscreen
                        // angular.element('body').removeClass('sidebar-collapsed');

                        angular.element('.static-content').css('width', '')
                        angular.element($window).off('resize', setWidthtoContent)
                    },
                })
            
                function setWidthtoContent() {
                    const w = angular.element('#wrapper').innerWidth()
                    angular.element('.static-content').css('width', (w) + 'px')
                }
            },
        }
    }])
    .directive('pulsate', () => {
        'use strict'
        return {
            scope: {
                pulsate: '=',
            },
            link(scope, element) {
                // stupid hack to prevent FF from throwing error
                if (element.css('background-color') === 'transparent') {
                    element.css('background-color', 'rgba(0,0,0,0.01)')
                }
                angular.element(element).pulsate(scope.pulsate)
            },
        }
    })
    .directive('prettyprint', ['$window', function($window) {
        'use strict'
        return {
            restrict: 'C',
            link: function postLink(scope, element) {
                element.html($window.prettyPrintOne(element.html(), '', true))
            },
        }
    }])
    .directive('animatePageContent', ['$rootScope', '$timeout', function($rootScope, $timeout) {
        'use strict'
        return {
            restrict: 'A',
            link: function postLink() {
                $rootScope.$on('$stateChangeSuccess', () => {
                    $timeout(() => {
                        angular.element('.container-fluid .animated-content')
                            .css('visibility', 'visible')
                            .velocity('transition.slideUpIn', { stagger: 150 })
                    }, 10)
                })
            },
        }
    }])
    .directive('passwordVerify', () => {
        'use strict'
        return {
            require: 'ngModel',
            scope: {
                passwordVerify: '=',
            },
            link(scope, element, attrs, ctrl) {
                scope.$watch(() => {
                    let combined

                    if (scope.passwordVerify || ctrl.$viewValue) {
                        combined = scope.passwordVerify + '_' + ctrl.$viewValue
                    }
                    return combined
                }, (value) => {
                    if (value) {
                        ctrl.$parsers.unshift((viewValue) => {
                            const origin = scope.passwordVerify
                            if (origin !== viewValue) {
                                ctrl.$setValidity('passwordVerify', false)
                                return undefined
                            } else {
                                ctrl.$setValidity('passwordVerify', true)
                                return viewValue
                            }
                        })
                    }
                })
            },
        }
    })
    .directive('backgroundSwitcher', () => {
        'use strict'
        return {
            restrict: 'EA',
            link(scope, element) {
                angular.element(element).click(() => {
                    angular.element('body').css('background', angular.element(element).css('background'))
                })
            },
        }
    })
/*
.directive('icheck', ['$timeout', '$parse', function($timeout, $parse) {
    'use strict';
    return {
      require: '?ngModel',
      link: function($scope, element, $attrs, ngModel) {
        var onChangedCallback = $parse($attrs.onChange)($scope);
        var setIndetermined = false, allowIndeterminate = true;
        
        return $timeout(function() {
          var parentLabel = element.parent('label');
          if (parentLabel.length) {
            parentLabel.addClass('icheck-label');
          }
          var value;
          value = $attrs.value;

        var onChangedCallback = ($parse($attrs.onChange)($scope) || {}).func;
          // console.log($parse($attrs.value)($scope), ngModel.$viewValue);

          $scope.$watch($attrs.ngModel, function() {
            // angular.element(element).iCheck('update');
            console.log("watcher", $parse($attrs.value)($scope));

            if ($parse($attrs.value)($scope) === null) {
              setTimeout(function () {
                angular.element(element).iCheck('indeterminate');
              })
            }
          });

          var cssClass = 'icheckbox_wf';

          if ($attrs.checkboxClass)
            cssClass = $attrs.checkboxClass;
          
          return angular.element(element).iCheck({
            checkboxClass: cssClass,
            radioClass: 'iradio_wf',
            // checkboxClass: 'icheckbox_minimal-blue',
            // radioClass: 'iradio_minimal-blue'
          }).on('ifChecked', onDetermined)
            .on('ifUnchecked', onDetermined)
          .on('ifIndeterminate', onIndetermined);

          function onDetermined(event) {
            if (angular.element(element).attr('type') === 'checkbox' && $attrs.ngModel) {
  
              if (allowIndeterminate && event.target.checked) {
                if (setIndetermined) {
                  angular.element(element).iCheck('indeterminate');
                  setIndetermined = false;
                  return;
                }
                else
                  setIndetermined = true;
              }

              console.log("set", event.target.checked)
              
              // $scope.$apply(function() {
                $timeout(function () {
                  if (typeof onChangedCallback === "function") {
                    onChangedCallback(event.target.checked)
                  }
                  ngModel.$setViewValue(event.target.checked);
                });
              // });
            }
            if (angular.element(element).attr('type') === 'radio' && $attrs.ngModel) {
              return $scope.$apply(function() {
                return ngModel.$setViewValue(value);
              });
            }
          }

          function onIndetermined(event) {
            if (angular.element(element).attr('type') === 'checkbox' && $attrs.ngModel) {

              console.log("set undetermined")
              
              // $scope.$apply(function() {
                $timeout(function () {
                  if (typeof onChangedCallback === "function") {
                    onChangedCallback(null)
                  }
                  ngModel.$setViewValue(null);
                    // setTimeout(function () {
                    // });
                });
                // });
            }
            if (angular.element(element).attr('type') === 'radio' && $attrs.ngModel) {
              return $scope.$apply(function() {
                return ngModel.$setViewValue(null);
              });
            }
          }
        });
      }
    };
  }])

*/
    .directive('icheck', ['$timeout', '$parse', function($timeout, $parse) {
        'use strict'
        return {
            require: '?ngModel',
            link($scope, element, $attrs, ngModel) {
                const onChangedCallback = $parse($attrs.onChange)($scope)
                let setIndetermined = false; const allowIndeterminate = false
        
                return $timeout(() => {
                    const parentLabel = element.parent('label')
                    if (parentLabel.length) {
                        parentLabel.addClass('icheck-label')
                    }
                    let value
                    value = $attrs.value

                    const onChangedCallback = $parse($attrs.onChange)($scope)
                    $scope.$watch($attrs.ngModel, () => {
                        angular.element(element).iCheck('update')
                    })

                    let cssClass = 'icheckbox_wf'

                    if ($attrs.checkboxClass) cssClass = $attrs.checkboxClass
          
                    return angular.element(element).iCheck({
                        checkboxClass: cssClass,
                        radioClass: 'iradio_wf',
                        // checkboxClass: 'icheckbox_minimal-blue',
                        // radioClass: 'iradio_minimal-blue'
                    }).on('ifChecked', onDetermined)
                        .on('ifUnchecked', onDetermined)
                        .on('ifIndeterminate', onIndetermined)

                    function onDetermined(event) {
                        if (angular.element(element).attr('type') === 'checkbox' && $attrs.ngModel) {
  
                            if (allowIndeterminate && event.target.checked) {
                                if (setIndetermined) {
                                    angular.element(element).iCheck('indeterminate')
                                    setIndetermined = false
                                    return
                                }
                                else setIndetermined = true
                            }
  
                            if (typeof onChangedCallback === 'function') {
                                setTimeout(() => {
                                    onChangedCallback()
                                })
                            }
                            $scope.$apply(() => {
                                return ngModel.$setViewValue(event.target.checked)
                            })
                        }
                        if (angular.element(element).attr('type') === 'radio' && $attrs.ngModel) {
                            return $scope.$apply(() => {
                                return ngModel.$setViewValue(value)
                            })
                        }
                    }

                    function onIndetermined(event) {
                        if (angular.element(element).attr('type') === 'checkbox' && $attrs.ngModel) {
  
                            if (typeof onChangedCallback === 'function') {
                                setTimeout(() => {
                                    onChangedCallback()
                                })
                            }
                            $scope.$apply(() => {
                                return ngModel.$setViewValue(undefined)
                            })
                        }
                        if (angular.element(element).attr('type') === 'radio' && $attrs.ngModel) {
                            return $scope.$apply(() => {
                                return ngModel.$setViewValue(undefined)
                            })
                        }
                    }
                })
            },
        }
    }])
    .directive('knob', () => {
        'use strict'
        return {
            restrict: 'EA',
            template: '<input class="dial" type="text"/>',
            scope: {
                options: '=',
            },
            replace: true,
            link(scope, element) {
                angular.element(element).knob(scope.options)
            },
        }
    })
    .directive('uiBsSlider', ['$timeout', function($timeout) {
        'use strict'
        return {
            link(scope, element) {
                // $timeout is needed because certain wrapper directives don't
                // allow for a correct calculation of width
                $timeout(() => {
                    element.slider()
                })
            },
        }
    }])
    .directive('tileLarge', () => {
        'use strict'
        return {
            restrict: 'E',
            scope: {
                item: '=data',
            },
            templateUrl: 'templates/tile-large.html',
            replace: true,
            transclude: true,
        }
    })
    .directive('tileSimple', () => {
        'use strict'
        return {
            restrict: 'E',
            scope: {
                item: '=data',
            },
            templateUrl: 'templates/tile-simple.html',
            replace: true,
            transclude: true,
        }
    })
    .directive('tileShortcut', () => {
        'use strict'
        return {
            restrict: 'E',
            scope: {
                item: '=data',
            },
            replace: true,
            templateUrl: 'templates/tile-shortcut.html',
        }
    })
    .directive('tile', () => {
        'use strict'
        return {
            restrict: 'E',
            scope: {
                heading: '@',
                type: '@',
            },
            transclude: true,
            templateUrl: 'templates/tile-generic.html',
            link(scope, element) {
                const heading = element.find('tile-heading')
                if (heading.length) {
                    heading.appendTo(element.find('.tiles-heading'))
                }
            },
            replace: true,
        }
    })
    .directive('datepaginator', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                options: '=datepaginator',
            },
            link(scope, element) {
                setTimeout(() => {
                    element.datepaginator(scope.options)
                }, 10)
            },
        }
    })
    .directive('stickyScroll', () => {
        'use strict'
        return {
            restrict: 'A',
            link(scope, element, attr) {
                function stickyTop() {
                    let topMax = parseInt(attr.stickyScroll)
                    const headerHeight = angular.element('header').height()
                    if (headerHeight > topMax) {
                        topMax = headerHeight
                    }
                    if (angular.element('body').hasClass('static-header') === false) {
                        return element.css('top', topMax + 'px')
                    }
                    const windowTop = angular.element(window).scrollTop()
                    if (windowTop < topMax) {
                        element.css('top', (topMax - windowTop) + 'px')
                    } else {
                        element.css('top', 0 + 'px')
                    }
                }

                angular.element(() => {
                    angular.element(window).scroll(stickyTop)
                    stickyTop()
                })
            },
        }
    })
    .directive('rightbarRightPosition', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                isFixedLayout: '=rightbarRightPosition',
            },
            link(scope) {
                scope.$watch('isFixedLayout', (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                        setTimeout(() => {
                            const $pc = angular.element('#wrapper')
                            let endingRight = (angular.element(window).width() - ($pc.offset().left + $pc.outerWidth()))
                            if (endingRight < 0) {
                                endingRight = 0
                            }
                            angular.element('.infobar').css('right', endingRight)
                        }, 100)
                    }
                })
            },
        }
    })
// .directive('fitHeight', ['$window', '$timeout', '$location', function ($window, $timeout, $location) {
// 'use strict';
//   return {
//     restrict: 'A',
//     scope: true,
//     link: function (scope, element, attr) {
//       function resetHeight () {
//         var horizontalNavHeight = angular.element('nav.navbar').height();
//         var viewPortHeight = angular.element(window).height()-angular.element('header').height()-horizontalNavHeight;
//         var contentHeight = angular.element('#page-content').height();
//         if (viewPortHeight>contentHeight)
//           angular.element('#page-content').css('min-height', viewPortHeight+'px');
//       }
//       setInterval(resetHeight, 1000);
//       angular.element(window).on('resize', resetHeight);
//     }
//   };
// }])
    .directive('backToTop', () => {
        'use strict'
        return {
            restrict: 'AE',
            link(scope, element) {
                element.click(() => {
                    angular.element('body').scrollTop(0)
                })
            },
        }
    })
    .directive('toTopOnLoad', ['$rootScope', function($rootScope) {
        'use strict'
        return {
            restrict: 'AE',
            link() {
                $rootScope.$on('$stateChangeSuccess', () => {
                    angular.element('body').scrollTop(0)
                })
            },
        }
    }])
    .directive('scrollToBottom', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                model: '=scrollToBottom',
            },
            link(scope, element) {
                scope.$watch('model', (n, o) => {
                    if (n !== o) {
                        element[0].scrollTop = element[0].scrollHeight
                    }
                })
            },
        }
    })
    .directive('positionChatBox', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                chatter: '=positionChatBox',
            },
            link(scope, element) {
                let nanoScrollOld = 0
                angular.element('.infobar .nano-content').on('scroll', () => {
                    const top = angular.element('.infobar .nano-content').scrollTop()
                    const scrolledAmmount = top - nanoScrollOld
                    element.css({
                        top: parseInt(element.css('top').replace('px', '')) - scrolledAmmount + 'px',
                    })
                    fixWindowOverflow()
                    nanoScrollOld = top
                })
                angular.element('.infobar').on('click', 'li[data-stats]', function(event) {
                    angular.element(this).siblings().removeClass('active')
                    angular.element(this).addClass('active')
                    event.stopPropagation()
                    element.css({
                        top: 0,
                        left: 0,
                    })
                    const clickOffset = angular.element(event.target).closest('li[data-stats]').offset()
                    element.css({
                        top: clickOffset.top - angular.element(window).scrollTop() + 'px',
                        left: clickOffset.left - 420 + 'px',
                    })
                    fixWindowOverflow()
                })

                angular.element('body').click(() => {
                    angular.element('li[data-stats]').removeClass('active')
                })

                function fixWindowOverflow() {
                    if (angular.element(window).height() < parseInt(element.css('top').replace('px', '')) + element.height()) {
                        const offset = angular.element(window).height() - (parseInt(element.css('top').replace('px', '')) + element.height())
                        element.css({
                            top: parseInt(element.css('top').replace('px', '')) + offset + 'px',
                        })
                    } else if (parseInt(element.css('top').replace('px', '')) < 50) {
                        element.css({
                            top: '50px',
                        })
                    }
                }
            },
        }
    })
