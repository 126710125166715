import React from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import { ConstVars } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'
import _ from 'lodash'

export function getTableColumns(options) {
    const { filters, onFilterChange, clearFilter, expandCells } = options

    return [
        {
            Header: <FormattedMessage id="dataManager.contributor" />,
            accessor: ConstVars.ContributorAccessors.Name,
            width: !expandCells ? 250 : 400,
        },
        {
            Header: <FormattedMessage id="dataManager.position" />,
            accessor: ConstVars.ContributorAccessors.Position,
            width: !expandCells ? 150 : 250,
        },
        {
            Header: <FormattedMessage id="dataManager.phone" />,
            accessor: ConstVars.ContributorAccessors.Phone,
            width: !expandCells ? 150 : 200,
        },
        {
            Header: <FormattedMessage id="dataManager.email" />,
            accessor: ConstVars.ContributorAccessors.Email,
            width: !expandCells ? 230 : 350,
        },
        {
            Header: <FormattedMessage id="dataManager.organization" />,
            accessor: ConstVars.ContributorAccessors.Organization,
            options: {
                filter: filters && filters.find(filter => filter.id === ConstVars.ContributorAccessors.OrganizationId),
                onFilterChange,
                clearFilter,
            },
            width: !expandCells ? 280 : 400,
        },
        {
            Header: <FormattedMessage id="dataManager.language" />,
            accessor: ConstVars.ContributorAccessors.Language,
            width: !expandCells ? 150 : 250,
        },
    ]
}

export function renderRowCell(value, row, column, cell) {
    const classes = TableElementsStyles()

    const imageUrl = _.get(row, 'original.contributorUser.imageUrl')
    if (column && column.id === ConstVars.ContributorAccessors.Name) {
        return (
            <div className={classes.cell}>
                <Image
                    className={classes.image}
                    size={'cover'}
                    src={imageUrl}
                />
                <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
            </div>
        )
    }
    if (column && column.id === ConstVars.ContributorAccessors.Language && cell.value.length > 0) {
        const prefix = cell.value.split('-')
        return <TooltipWithCopyToClipboard text={<FormattedMessage id={`lang.${prefix[0]}`} />} />
    }
    return <div className={classes.cell}><TooltipWithCopyToClipboard text={value} /></div>
}

export function renderColumnCell(Header, id, column) {
    if (column.options) {
        const { filter, onFilterChange, clearFilter } = column.options

        if (filter && onFilterChange) {
            return (
                <NewFilterDropdown
                    filter={filter}
                    style={{ width: column.width }}
                    onChange={onFilterChange}
                    onClear={clearFilter}
                />
            )
        }
    }

    return column.Header
}
