import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { FormattedMessage, FormattedDate } from 'react-intl'
import Colors from '@worldfavor/constants/colors'
import Header from '@worldfavor/components/Header'
import Image from '@worldfavor/components/Image'

const useStyles = makeStyles(theme => ({
    senderInfo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    senderImage: {
        width: 18,
        height: 18,
        marginRight: 10,
    },
    senderText: {
        color: Colors.grayDark,
        fontSize: 14,
    },
    senderName: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.main,
    },
}))

const PreHeader = (props) => {
    const { classes, imageUrl, senderName, senderDate } = props

    return (
        <div className={classes.senderInfo}>
            { imageUrl && <Image className={classes.senderImage} src={imageUrl} style={{ width: 18, height: 18, borderRadius: 3 }} size={'cover'} /> }
            <div className={classes.senderText}>
                <FormattedMessage
                    id={`requests.senderInfo`}
                    values={{
                        name: <span className={classes.senderName}>{senderName}</span>,
                        dateTime: (
                            <FormattedDate
                                year={'numeric'}
                                day={'numeric'}
                                month={'long'}
                                hour={'2-digit'}
                                minute={'2-digit'}
                                value={senderDate}
                            />
                        ),
                    }}
                />
            </div>
        </div>
    )
}

const InfluenceHeader = (props) => {
    const { senderImageUrl, senderName, senderDate, imageUrl, title, description } = props
    const classes = useStyles(props)

    return (
        <Header
            title={title}
            description={description}
            classes={{ imageWrapper: classNames(classes.image, classes.backgroundSetup) }}
            imageUrl={imageUrl}
            imageStyles={{
                width: 120,
                height: 120,
                backgroundColor: Colors.grayLight,
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: 3,
            }}
            preHeader={<PreHeader imageUrl={senderImageUrl} senderName={senderName} senderDate={senderDate} classes={classes} />}
        />
    )
}

export default InfluenceHeader
