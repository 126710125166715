import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@worldfavor/components/Divider'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import Header from '@worldfavor/components/Header'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginTop: 55,
        marginBottom: 50,
    },
    headerMainContent: {
        flex: '0.7',
        flexDirection: 'column',
    },
    descriptionContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        marginTop: 45,
        zIndex: 100,
    },
    children: {
        flex: '0.3',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },
    preHeaderText: {
        color: hexToRgbA(theme.palette.common.white, 0.87),
    },
    headerText: {
        justifyContent: 'center',
    },
}))

const PageHeader = (props) => {
    const classes = useStyles(props)
    const {
        imageUrl,
        title,
        description,
        preHeader,
        verticalLineColor,
        children,
    } = props

    return (
        <div className={classes.root}>
            <div className={classes.headerMainContent}>
                <Header
                    PreHeaderProps={{
                        classes: { preHeaderText: classes.preHeaderText, titleDescriptionWrapper: classes.headerText },
                    }}
                    preHeader={preHeader}
                    title={title}
                    imageUrl={imageUrl}
                />
                {
                    description && (
                        <div className={classes.descriptionContainer}>
                            <Divider
                                width={3}
                                height={'initial'}
                                color={verticalLineColor}
                                style={{ borderRadius: 3, minWidth: 3, marginRight: 15 }}
                            />
                            { description }
                        </div>
                    )
                }
            </div>
            { children && <div className={classes.children}>{ children }</div> }
        </div>
    )
}

PageHeader.defaultProps = {
    verticalLineColor: Colors.vividPurple,
}

export default React.memo(PageHeader)
