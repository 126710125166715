import {
    ADD_PAGE,
    RESET_PAGINATION_STATE,
} from './'

export const addPage = (requestId, pageId, elementIds, totalElements, pageNumber, pageSize) => { // eslint-disable-line import/prefer-default-export
    return {
        type: ADD_PAGE,
        requestId,
        pageId,
        ids: elementIds,
        totalElements,
        pageNumber,
        pageSize,
    }
}

export const resetState = () => {
    return {
        type: RESET_PAGINATION_STATE,
    }
}
