// @flow
import React, { useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Colors from '@worldfavor/constants/colors'

export const MimeTypeFontAwesomeIconClasses = {
    image: 'fa-file-image-o',
    audio: 'fa-file-audio-o',
    video: 'fa-file-video-o',
    // Documents
    'application/pdf': 'fa-file-pdf-o',
    'application/msword': 'fa-file-word-o',
    'application/vnd.ms-word': 'fa-file-word-o',
    'application/vnd.oasis.opendocument.text': 'fa-file-word-o',
    'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fa-file-word-o',
    'application/vnd.ms-excel': 'fa-file-excel-o',
    'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fa-file-excel-o',
    'application/vnd.oasis.opendocument.spreadsheet': 'fa-file-excel-o',
    'application/vnd.ms-powerpoint': 'fa-file-powerpoint-o',
    'application/vnd.openxmlformats-officedocument.presentationml': 'fa-file-powerpoint-o',
    'application/vnd.oasis.opendocument.presentation': 'fa-file-powerpoint-o',
    'text/plain': 'fa-file-text-o',
    'text/html': 'fa-file-code-o',
    'application/json': 'fa-file-code-o',
    // Archives
    'application/gzip': 'fa-file-archive-o',
    'application/zip': 'fa-file-archive-o',
}

export const FontAwesomeFileIconClassColors = {
    'fa-file-pdf-o': '#f40f02',
    'fa-file-word-o': '#185abd',
    'fa-file-excel-o': '#107c41',
    'fa-file-powerpoint-o': '#c43e1c',
}

export const DocumentMIMEType = {
    xlsx: '.xlsx',
}

export const StandardFileTypes = [
    DocumentMIMEType.xlsx,
]

const styles = () => ({
    root: {},
    input: {
        width: 1,
        height: 1,
        opacity: 0,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: -1,
    },
    browseButton: {
    },
    fileButton: {
        textTransform: 'none',
        lineHeight: '26px',
        maxWidth: '100%',
    },
    fileName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: 12,
    },
    browseIcon: {
        marginRight: 9,
        fontSize: 16,
        position: 'relative',
        top: -1,
        width: 20,
    },
    fileIcon: {
        marginRight: 3,
        fontSize: 16,
        position: 'relative',
        top: -1,
        left: -3,
    },
    clearIcon: {
        marginLeft: 20,
        fontSize: 16,
        color: Colors.gray,
        '&:hover': {
            color: Colors.grayDark,
        },
    },
})

const FilePicker = ({ classes, file: filePropValue, supportedTypes, onFileChange, disabled, selectorLabel }) => {
    const [file, setFile] = useState(filePropValue)
    const inputRef = useRef(null)
    const [fileIconClass, setFileIconClass] = useState()
    const [fileIconColor, setFileIconColor] = useState()

    function handleFileChange(event) {
        const { files } = event.target
        if (files && files[0]) {
            const file = files[0]
            const extension = file.name.match(/.*?(\.[a-zA-Z]*)$/)[1]
            if (supportedTypes.includes(files[0].type) || supportedTypes.includes(extension)) {
                setFile(file)
                const _fileIconClass = getFontAwesomeIconFromMIME(file.type)
                setFileIconClass(_fileIconClass)
                setFileIconColor(FontAwesomeFileIconClassColors[_fileIconClass] || '#444')
                onFileChange && onFileChange(file)
            }
        }
    }

    function onFileContainerClick() {
        inputRef.current && inputRef.current.click()
    }

    function clearFile(e) {
        if (inputRef.current) {
            inputRef.current.value = null
            setFile(null)
            onFileChange && onFileChange(null)
        }
        e.stopPropagation()
    }

    function getFontAwesomeIconFromMIME(mimeType) {
        for (const key in MimeTypeFontAwesomeIconClasses) {
            if (MimeTypeFontAwesomeIconClasses.hasOwnProperty(key)) {
                if (mimeType.search(key) === 0) {
                    // Found it
                    return MimeTypeFontAwesomeIconClasses[key]
                }
            } else {
                return 'fa-file-o'
            }
        }
    }

    return (
        <React.Fragment>
            <input
                ref={inputRef}
                type="file"
                accept={supportedTypes.join(',')}
                capture="camera"
                className={classes.input}
                onChange={handleFileChange}
            />

            {
                file
                    ? (
                        <Button className={classes.fileButton} variant="outlined" onClick={onFileContainerClick} disabled={disabled}>
                            <Icon className={classnames(classes.fileIcon, `fas ${fileIconClass}`)} style={{ color: fileIconColor }} />
                            <span className={classes.fileName} title={file.name}>{file.name}</span>
                            <Icon className={classnames(classes.clearIcon, `fas fa-trash-alt`)} onClick={clearFile} style={{ opacity: disabled && 0.3 }} />
                        </Button>
                    ) : (
                        <Button className={classes.browseButton} variant="contained" color="primary" onClick={onFileContainerClick} disabled={disabled}>
                            <Icon className={classnames(classes.browseIcon, `fas fa-folder-open`)} />
                            {selectorLabel || <FormattedMessage id={'components.filePicker.selectFile'} />}
                        </Button>
                    )}

        </React.Fragment>
    )
}

FilePicker.defaultProps = {
    supportedTypes: StandardFileTypes,
}

export default withStyles(styles)(FilePicker)
