import endpoints from './endpoints'
import {
    get,
    deleteRequest,
    head,
    options,
    post,
    put,
    patch,
    registerBaseUrl,
    getBaseUrl,
} from './requestWrappers'
import {
    fetchItem,
    fetchItems,
    fetchSubItems,
    fetchSubItemsOfAll,
    fetchSubItemsPage,
} from './fetchFunctions'

export default {
    endpoints,

    get,
    delete: deleteRequest,
    head,
    options,
    post,
    put,
    patch,
    registerBaseUrl,
    getBaseUrl,

    fetchItem, fetchItems, fetchSubItems, fetchSubItemsOfAll, fetchSubItemsPage,
}
