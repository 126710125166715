import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import classnames from 'classnames'
import HighlightedText from '../../Text/HighlightedText'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2),
        borderRadius: 4,

        '&:hover': {
            backgroundColor: Colors.grayHover,
        },
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: theme.spacing(2),
        borderRadius: 2,
    },
    infoContainer: {
        flex: 1,
    },
    title: {
        color: Colors.blackDarker,
        padding: '2px 0',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '20px',
    },
    subTitle: {
        color: Colors.grayDark,
        fontSize: 11,
        fontWeight: 500,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        minWidth: 120,
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
        fontSize: 14,
    },
})

const OrganizationSelectorRow = withStyles(styles)(({
    classes,
    organization: { wfid, name, imageUrl, registrationNumber, vatNumber },
    selected,
    match = {},
    onClick,
}) => {

    function _onClick() {
        return onClick && onClick(wfid)
    }

    return (
        <div className={classes.root}>
            <img
                alt={`Logo for ${name}`}
                className={classes.logo}
                src={imageUrl}
            />
            <div className={classes.infoContainer}>
                <div className={classes.title}>
                    <HighlightedText text={name} match={match.search} />
                </div>
                <div className={classes.subTitle}>
                    { registrationNumber && <HighlightedText text={registrationNumber} match={match.search} /> }
                    { registrationNumber && vatNumber && ' • ' }
                    { vatNumber && <HighlightedText text={vatNumber} match={match.search} /> }
                </div>
            </div>
            <div className={classes.buttonContainer}>
                {
                    selected ? (
                        <Button disabled className={classes.button}>
                            <Icon className={classnames(classes.buttonIcon, 'fas fa-check')} />
                            <FormattedMessage id={'general.current'} />
                        </Button>
                    ) : (
                        <Button className={classes.button} onClick={_onClick}>
                            <Icon className={classnames(classes.buttonIcon, 'fas fa-sign-in-alt')} />
                            <FormattedMessage id={'general.signIn'} />
                        </Button>
                    )
                }
            </div>
        </div>
    )
})

export default OrganizationSelectorRow
