(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfQuestionAnsweringHistory', wfQuestionAnsweringHistory)
	
    wfQuestionAnsweringHistory.$inject = ['$parse', 'dataQuery', 'dataOperationsService', '$compile']

    function wfQuestionAnsweringHistory($parse, dataQuery, dataOps, $compile) {
        const
            templateUrl = 'scripts/wf/answering/wfQuestionAnsweringHistory.directive.html'
			
        let templateContent
		
        const directive = {
            restrict: 'EA',
            require: '?^^wfQuestionAnsweringManager',
            templateUrl,
            link,
            controllerAs: 'questionAnsweringHistory',
            controller: ['$scope', '$element', '$templateCache', '$timeout', '$attrs', wfQuestionAnsweringHistoryController],
			
        }

        function wfQuestionAnsweringHistoryController($scope, $element, $templateCache, $timeout, $attrs) {
            const
                vm = this
					
            const html = templateContent || (templateContent = $templateCache.get(templateUrl))
					
            let minAnswersView = 0

            // console.log($scope.$id, "controller");//, answeringVm, arguments);
            // if (item.childContent.type != 21) return;
            // if (!$scope.isQuestion)
            // 	return;

            // console.log($scope.allAnswers)

            vm.hideRelatedContent = $attrs.hasOwnProperty('hideRelatedcontent')
            vm.noRelationTags = $attrs.hasOwnProperty('noRelationTags')
            vm.noTools = $attrs.hasOwnProperty('noTools')
            // console.log(vm.hideRelatedContent);

            if ($scope.questionAnsweringVm && $scope.questionAnsweringVm.allAnswers) {
                activate()
            }
            else {
                $scope.activateQuestionAnsweringHistory = function () {
                    // console.log("Activated later", activate);
                    activate()
                    $element.html(html)
                    $compile($element.contents())($scope)
                    // $timeout(function () {
                    // }, 1000)
                }
                // console.log("$scope.questionAnsweringHistory.activate set")
            }

            // console.log("activating question history");

            vm.updateHistory = updateHistory
            vm.toggleAnswers = toggleAnswers
				
            function activate() {
                vm.onAnswerRemoved = onAnswerRemoved
                getValues()
                // if (!vm.context)
                // 	vm.context = {};
						
                // vm.context.isByAutheticatedOrg = $scope.isByAutheticatedOrg;
                // console.log(vm.isByAutheticatedOrg);
					
                function getValues() {
                    _.assign(vm, {
                        isViewMode: $scope.questionAnsweringVm.isViewMode,
                        allAnswers: $scope.questionAnsweringVm.allAnswers, // From wfQuestionAnswering directive
                        answersCount: $scope.questionAnsweringVm.allAnswers.length,
                        isByAutheticatedOrg: $scope.isByAutheticatedOrg,
                        toggleAnswers,
                        filteredAnswers: [],
                        toggled: false,
                        answerWfidMap: _.keyBy($scope.questionAnsweringVm.allAnswers, 'wfcid'),
                    })
                }

                function onAnswerRemoved(action, item) {
                    let updateAnswer
                    if (action === 'delete' && vm.answerWfidMap[item.wfid]) {
                        if (!vm.allAnswers.length || vm.allAnswers[0].wfcid === item.wfid) {
                            // Needs to update answer
                            updateAnswer = true
                        }
                        dataOps.destroy(_.find(vm.allAnswers, { wfcid: item.wfid }))
							
                        _.remove(vm.allAnswers, { wfcid: item.wfid })
                        vm.answersCount = vm.allAnswers.length

                        if (updateAnswer) {
                            $scope.questionAnsweringVm.setSelectedAnswer(vm.allAnswers[0])
                        }
                    }

                    console.log('onAnswerRemoved', vm.allAnswers[0])

                }
            }
				
            function updateHistory() {
                // console.log("compiling");
                minAnswersView = 1
                vm.answersCount = vm.allAnswers.length

                vm.answerWfidMap = _.keyBy(vm.allAnswers, 'wfcid')
                // console.log(vm.answersCount);
                if (!vm.toggled)
                {
                    vm.filteredAnswers.length = 0
                    vm.filteredAnswers.push(_.take(vm.allAnswers, minAnswersView)[0])
                    // console.log(vm.filteredAnswers);
                }
					
                $element.html(html)
                $compile($element.contents())($scope)
                // console.log(html)
            }
				
            function toggleAnswers() {
                if (vm.toggled) {
                    vm.filteredAnswers = _.take(vm.allAnswers, minAnswersView)
                }
                else {
                    vm.filteredAnswers = vm.allAnswers
                }
                vm.toggled = !vm.toggled
            }
        }

        function link(scope, element, attrs, wfQuestionAnsweringManagerCtrl) {
            // console.log(element.html())
            element.addClass('wfQuestionAnsweringHistory')
            if (wfQuestionAnsweringManagerCtrl) {
                scope.questionAnsweringManager = wfQuestionAnsweringManagerCtrl
            }

            // console.log(ctrl);
            // console.log(ctrl.itemToolsListener);
            // ctrl.itemToolsListener.onActionExecuted(function (action, item) {
            // 	console.log(action, item);
            // })
        }
		
        return directive
    }
})()
