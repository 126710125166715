import React, { useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Colors from '@worldfavor/constants/colors'
import { FormattedDate, FormattedMessage } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Popper from '@material-ui/core/Popper'
import GuidanceDialog from '@worldfavor/components/Dialog/GuidanceDialog'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'

const CommentDetails = withStyles({
    root: {
        display: 'flex',
        width: 400,
    },
    commentAvatar: {
        width: 36,
        height: 36,
        marginRight: 16,
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 20,
    },
    text: {
        flex: 1,
        whiteSpace: 'pre-wrap',
        maxHeight: 400,
        overflowY: 'auto',
        paddingTop: 20,
        paddingRight: 20,
        paddingBottom: 20,
    },
})(({ classes, authorName, authorImageUrl, text }) => {
    return (
        <div className={classes.root}>
            <Avatar alt={authorName} src={authorImageUrl} className={classes.commentAvatar} />
            <span className={classes.text}>
                {text}
            </span>
        </div>
    )
})

const AttachmentsDetails = withStyles(theme => ({
    root: {
        display: 'flex',
        width: 400,
        paddingTop: 8,
        paddingBottom: 8,
    },
    row: {
        display: 'flex',
        width: '100%',
        padding: '6px 20px',
        alignItems: 'center',
        minHeight: 26,
        color: Colors.blue,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover $text': {
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
    },
    icon: {
        fontSize: 14,
        width: 18,
        textAlign: 'center',
        marginRight: 8,
    },
    text: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1,
    },
}))(({ classes, attachments }) => {
    return (
        <div className={classes.root}>
            {
                attachments.map(attachment => (
                    <a key={`attachment-${attachment.wfid}`} className={classes.row} href={attachment.fileUrl} target="_blank" rel="noopener noreferrer">
                        <i className={classNames('fas fa-file', classes.icon)} />
                        <span className={classes.text}>
                            {attachment.title}
                        </span>
                        <i className={classNames('fas fa-external-link-alt', classes.icon)} />
                    </a>
                ))
            }
        </div>
    )
})

const styles = theme => ({
    root: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    row: {
        display: 'flex',
        padding: '6px 24px',
        fontWeight: theme.typography.fontWeightMedium,
        alignItems: 'center',
        minHeight: 26,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
    },
    icon: {
        fontSize: 12,
        width: 18,
        textAlign: 'center',
        marginRight: 8,
    },
    dueAt: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    dueAtLabel: {
        fontWeight: 400,
        marginRight: 4,
    },
    guidance: {
        color: Colors.greenDark,
    },
    commentAvatar: {
        width: 26,
        height: 26,
    },
    text: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1,
    },
    attachmentsBadge: {
        fontSize: 14,
        backgroundColor: Colors.blue,
        borderRadius: 5,
        padding: '3px 8px',
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
    },
    popperContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    popperCloseButton: {
        margin: 8,
    },
})

const InfluenceInfoBox = ({ classes, style, guidance, comment, attachments, deadline }) => {
    const [guidanceOpen, setGuidanceOpen] = useState(false)
    const [commentOpen, setCommentOpen] = useState(false)
    const commentTriggerRef = useRef(null)
    const [attachmentsOpen, setAttachmentsOpen] = useState(false)
    const attachmentsTriggerRef = useRef(null)

    function openGuidance() {
        setGuidanceOpen(true)
    }

    function closeGuidance() {
        setGuidanceOpen(false)
    }

    function toggleComment() {
        setCommentOpen(!commentOpen)
    }

    function handleCommentClose(event) {
        if (commentTriggerRef.current && commentTriggerRef.current.contains(event.target)) {
            return
        }

        setCommentOpen(false)
    }

    function toggleAttatchments() {
        setAttachmentsOpen(!attachmentsOpen)
    }

    function handleAttachmentsClose(event) {
        if (attachmentsTriggerRef.current && attachmentsTriggerRef.current.contains(event.target)) {
            return
        }

        setAttachmentsOpen(false)
    }

    if (!guidance && !comment && !attachments && !deadline) {
        return null
    }

    return (
        <Paper className={classes.root} style={style}>
            {
                deadline && (
                    <React.Fragment>
                        <div className={classNames(classes.row, classes.dueAt)} onClick={openGuidance}>
                            <i className={classNames('fas fa-clock', classes.icon)} />
                            <div className={classes.text}>
                                <span className={classes.dueAtLabel}><FormattedMessage id={'general.deadline'} />:</span>
                                <FormattedDate
                                    year={'numeric'}
                                    day={'2-digit'}
                                    month={'2-digit'}
                                    value={deadline}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }

            {
                guidance && (
                    <React.Fragment>
                        <div className={classNames(classes.row, classes.guidance)} onClick={openGuidance}>
                            <i className={classNames('fas fa-book-open', classes.icon)} />
                            <div className={classes.text}>
                                <FormattedMessage id={'general.guidance'} />
                            </div>
                        </div>
                        <GuidanceDialog open={guidanceOpen} onClose={closeGuidance}>
                            {guidance}
                        </GuidanceDialog>
                    </React.Fragment>
                )
            }

            {
                comment && (
                    <React.Fragment>
                        <div className={classes.row} onClick={toggleComment} ref={commentTriggerRef}>
                            <i className={classNames('fas fa-quote-right', classes.icon)} />
                            <div className={classes.text}>
                                <FormattedMessage id={'general.comment'} />
                            </div>
                            <Avatar alt={comment.authorName} src={comment.authorImageUrl} className={classes.commentAvatar} />
                        </div>
                        <Popper open={commentOpen} anchorEl={commentTriggerRef.current} transition>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: 'top center' }}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleCommentClose}>
                                            <div className={classes.popperContent}>
                                                <CommentDetails {...comment} />
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </React.Fragment>
                )
            }

            {
                attachments && (
                    <React.Fragment>
                        <div className={classes.row} onClick={toggleAttatchments} ref={attachmentsTriggerRef}>
                            <i className={classNames('fas fa-paperclip', classes.icon)} />
                            <div className={classes.text}>
                                <FormattedMessage id={'general.attachments'} />
                            </div>
                            <div className={classes.attachmentsBadge}>{attachments.length}</div>
                        </div>
                        <Popper open={attachmentsOpen} anchorEl={attachmentsTriggerRef.current} transition>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: 'top center' }}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleAttachmentsClose}>
                                            <div className={classes.popperContent}>
                                                <AttachmentsDetails attachments={attachments} />
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </React.Fragment>
                )
            }
        </Paper>
    )
}

export default withStyles(styles)(InfluenceInfoBox)
