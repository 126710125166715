// @flow
import * as React from 'react'
import { InfoWindow, Marker } from 'react-google-maps'
import '@worldfavor/less/components/map-marker.less'
import LocationLink from './LocationLink'

class MarkerInfoWindow extends React.PureComponent<*> {

    render() {
        const {
            title,
            description,
            subHeader,
            showPositionLink,
            position: {
                lat,
                lng,
            },
        } = this.props
        return (
            <div styleName="info-window">
                <b>{ title }</b>
                { subHeader && <div styleName="sub-header">{ subHeader }</div> }
                {
                    showPositionLink && (
                        <LocationLink
                            lat={lat}
                            lng={lng}
                            style={{ marginTop: 4, marginBottom: 6 }}
                        />
                    )
                }
                { description && <div>{ description }</div> }
            </div>
        )
    }
}

type Props = {
  position: {
    lat: number,
    lng: number,
  },
  title: string,
  description?: string,
  subHeader?: string | null,
  showPositionLink?: boolean,
  id?: string,
  markerProps?: *,
  infoWindowProps?: *,

  onInfoOpened: (string) => any,
}

class MarkerWithInfo extends React.PureComponent<Props, { open: boolean }> {

  static defaultProps = {
      onInfoOpened: () => {},
  }

  state = {
      open: false,
  }

  isInfoWindowOpen = () => {
      return this.state.open
  }

  closeInfo = () => {
      this.setState({ open: false })
  }

  _onToggleOpen = () => {
      const { onInfoOpened, id } = this.props
      this.setState({ open: !this.state.open })

      // We only call the action callback if id is defined
      if (id) {
          onInfoOpened(id)
      }
  }

  render() {
      const {
          position,
          markerProps = {},
          infoWindowProps = {},
          title,
          description,
          subHeader,
          showPositionLink,
      } = this.props
      const { open } = this.state
      return (
          <Marker
              position={position}
              onClick={this._onToggleOpen}
              title={title}
              {...markerProps}
          >
              {
                  open && (
                      <InfoWindow
                          onCloseClick={this._onToggleOpen}
                          {...infoWindowProps}
                      >
                          <MarkerInfoWindow
                              title={title}
                              description={description}
                              subHeader={subHeader}
                              showPositionLink={showPositionLink}
                              position={position}
                          />
                      </InfoWindow>
                  )
              }
          </Marker>
      )
  }
}

export default MarkerWithInfo
