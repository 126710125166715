import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfRelativeMeasureCalculationPreview', {
            templateUrl: 'scripts/wf/relativeMeasures/steps/wfRelativeMeasureCalculationPreview.component.html',
            controller: wfRelativeMeasureCalculationPreviewController,
            controllerAs: 'vm',
            bindings: {
                relativeMeasureItem: '<',
                steps: '<',
                showOnlyValues: '<',
                layout: '@',
            },
        })

    wfRelativeMeasureCalculationPreviewController.$inject = []
    function wfRelativeMeasureCalculationPreviewController() {
        const vm = this

        vm.$onInit = function() {
            _.assign(vm, {
                itemContent: undefined,
                itemRealtion: undefined,
                orderedStepsByModel: undefined,
                layout: vm.layout ? vm.layout : 'default-md-layout',
                mathOperationsByType: undefined,
                mathOperations: {
                    addition: {
                        operationEnum: enums.mathOperation.addition,
                        iconClass: 'fa fa-plus',
                    },
                    subtraction: {
                        operationEnum: enums.mathOperation.subtraction,
                        iconClass: 'fa fa-minus',
                    },
                    multiplication: {
                        operationEnum: enums.mathOperation.multiplication,
                        iconClass: 'fa fa-asterisk',
                    },
                    division: {
                        operationEnum: enums.mathOperation.division,
                        iconClass: 'fa fa-minus rotate-45',
                    },
                    replace: {
                        operationEnum: enums.mathOperation.replace,
                        iconClass: 'fa fa-exchange',
                    },
                },
            })

            if (vm.relativeMeasureItem) {
                if (vm.relativeMeasureItem.childContent) {
                    vm.itemContent = vm.relativeMeasureItem.childContent
                    vm.itemRelation = vm.relativeMeasureItem
                }
                else {
                    vm.itemContent = vm.relativeMeasureItem
                }
            }
            else console.error('No relative measure passsed to the wfRelativeMeasureCalculationPreview component')

            if (vm.steps) {
                vm.steps = _.orderBy(vm.steps, 'order', 'asc')
                vm.mathOperationsByType = _.mapKeys(vm.mathOperations, (value) => { return value.operationEnum })
            }
            else console.error('No steps are passsed to the wfRelativeMeasureCalculationPreview component')
        }
    }
})()
