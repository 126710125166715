(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfPublicRequest', wfPublicRequest)

    wfPublicRequest.$inject = []
    function wfPublicRequest() {

        const directive = {
            bindToController: true,
            controller: wfPublicRequestController,
            controllerAs: 'vmRequest',
            restrict: 'E',
            templateUrl: 'scripts/wf/influence/wfPublicRequest.directive.html',
            scope: {
                influence: '=',
            },
        }
        return directive

    }

    wfPublicRequestController.$inject = ['$scope', '$uibModal', 'wfObject', '$translate', '$attrs', '$parse', 'dataOperationsService', '$timeout', 'informationRequestService', 'wfAuth', '$q']
    function wfPublicRequestController($scope, $uibModal, wfObject, $translate, $attrs, $parse, dataOps, $timeout, informationRequestService, wfAuth, $q) {
        const
            vm = this
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const recipientOrganizationId = vm.influence ? vm.influence.organizationId : authOrgId
			
        const userId = wfAuth.isAuthenticated() ? wfAuth.getWorldfavorUserId() : 1

        _.assign(vm, {
            //Variables
            responsiveUi: 'responsiveUi' in $attrs,

            //Functions
            openRequestModal,
            createInfluence,
            clearLocalStorage,
			
            buttonDisabled: authOrgId === recipientOrganizationId,
        })

        function openRequestModal() {
            vm.modalLoading = true
            // vm.items = dataOps.getObject({
            // 	objectType: influence.objectType,
            // 	objectId: influence.objectId,
            // 	bypassCache: true,
            // 	getterConditions: { hierarchyFilter: 2 } // Without user data
            // }).then(function (res) {
            // 	vm.items = res.childs;

            // });

            // console.log(wfAuth, wfAuth.isAuthenticated());

            // return $q(function (resolve) {
            informationRequestService.loadItems(vm.influence).then((flatItemComposites) => {
                vm.items = flatItemComposites

                const existingRequests = getExistingRequestsFromLocalStorage()

                if (existingRequests.length !== 0) {
                    _.each(vm.items, (item) => {
                        const localStorageId = [userId, recipientOrganizationId, item.wfid].join('|')

                        if (_.includes(existingRequests, localStorageId)) {
                            item.buttonDisabled = true
                            informationRequestService.setButtonStage(item, informationRequestService.requestButtonStages.requestSent)
                        }
                    })
                }

                const modalOptions = {
                    templateUrl: 'scripts/wf/influence/wfPublicRequestModal.html',
                    scope: $scope, // not able to access scope in wfPublicRequestModal.html
                    size: 'width-700',
                    windowClass: 'request-info-modal',
                }

                $uibModal.open(modalOptions)
                vm.modalLoading = false
					
                // setTimeout(function () {
                // 	resolve();
                // }, 1000);

            })
            // });
        }

        function createInfluence(item) {
            // return dataOps.createInfluence();
            if (!item.buttonDisabled) {
                informationRequestService.setButtonStage(item, informationRequestService.requestButtonStages.sendingRequest)
				
                const influence = vm.influence.organizationId

                // $timeout(function() {
                dataOps.createInfluence({
                    channelId: 1,
                    influenceModel: {
                        activatedAt: moment().format('YYYY-MM-DD'),
                    },
                    organization: { id: vm.influence.organizationId },
                    isInternal: false,
                    item: item.content,
                }).then((newInfluence) => {
                    saveInLocalStorage(item.wfid)

                    // console.log(localStorage);
					 	informationRequestService.setButtonStage(item, informationRequestService.requestButtonStages.requestSent)

                })
                // }, 2000);
            }
        }

        function saveInLocalStorage(objectWfid) {
            const request = {}
            let existingRequests = JSON.parse(localStorage.getItem('wfPublicRequests'))

            const localStorageId = [userId, recipientOrganizationId, objectWfid].join('|')

            if (existingRequests == null) {
                existingRequests = []
            }

            existingRequests.push(localStorageId)
				
            localStorage.setItem('wfPublicRequests', JSON.stringify(existingRequests))
        }

        function getExistingRequestsFromLocalStorage() {
            const output = []
            const existingRequests = JSON.parse(localStorage.getItem('wfPublicRequests')) || []

            return existingRequests
        }

        function clearLocalStorage() {
            // console.log("Before clearing: ", localStorage);
            localStorage.clear()
            // console.log("After clearing: ", localStorage);
        }
    }
})()
