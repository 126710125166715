import * as enums from '@worldfavor/constants/enums'
import { formatValue as formatValueHelper } from '@worldfavor/components/Chartist/helper'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('WfChartistService', WfChartistService)

    WfChartistService.$inject = ['$filter', '$sanitize', '$translate', 'wfPropertyExtractor', 'wfTranslate']
    function WfChartistService($filter, $sanitize, $translate, wfPropertyExtractor, wfTranslate) {
        const vm = this
        const defaultSeriesColor = '#7f8c8d'
        const maxNumberOfPointLabels = 14

        // When the React component WfGraph is included elsewhere it will override window.Chartist and cause issues here.
        // The original values of window.Chartist is backed up here and then used later in function initializeChartistPlugins
        if (!vm.ChartistBackup && Chartist) {
            vm.ChartistBackup = {}
            for (const key in Chartist) {
                if (Chartist.hasOwnProperty(key)) {
                    vm.ChartistBackup[key] = Chartist[key]
                }
            }
        }

        _.assign(vm, {
            getChartistConfigObject,
            randomizeData,
            prepareChartData,
            getLineChartOptions,
            getBarChartOptions,
            getPieChartOptions,
            getEmptyStateChartOptions,
            getResponsiveOptions,
            initializeChartistPlugins,
            getChartWithDynamicBars,
            mockRandomData,
        })

        function getChartistConfigObject(options) {
            let numberOfDataPoints = 0

            let periodDataset; const chartConfig = {
                data: {
                    labels: undefined,
                    series: [],
                },
                options: undefined,
                drawEvent: undefined,

                // prorperties for the pie chart
                verticalSeries: undefined,
                // selectedVerticalSeries: undefined
            }

            if (options.asEmptyState) {
                chartConfig.options = getEmptyStateChartOptions(options)
                chartConfig.data = randomizeData(options)
                numberOfDataPoints = chartConfig.data.labels.length
            }
            else {
                chartConfig.data = prepareChartData(options)
                numberOfDataPoints = chartConfig.data.labels.length
                options.originalData = chartConfig.data
                periodDataset = _.find(options.datasets, { id: 'period' })

                if (periodDataset && periodDataset.inconsistent) {
                    options.messageToDisplay = $translate.instant('modules.itemStatistics.inconsistentData')
                    options.allowToDismissMessage = false
                    options.showInfoMessage = true
                }

                if (!options.measure || _.get(options.measure.content || options.measure, 'unit.quantityType')) chartConfig.showConversionOptions = periodDataset && periodDataset.inconsistent

                if (chartConfig.data && chartConfig.data.series) {
                    const zeroValueDatasets = []
                    _.each(chartConfig.data.series, (series, index) => {
                        zeroValueDatasets[index] = _.every(series.data, (data) => { return data.value === 0 })
                    })
                    if (zeroValueDatasets.length > 0 && _.every(zeroValueDatasets, Boolean)) {
                        options.messageToDisplay = options.messages.allValuesAreZeros
                        options.allowToDismissMessage = true,
                        options.showInfoMessage = true
                        options.highestValue = 1
                    }

                    if (chartConfig.data.labels.length <= maxNumberOfPointLabels) {
                        options.showPointLabels = true
                    }
                    else {
                        options.showPointLabels = false
                    }
                }

                if (options.chartType === 'line' || options.type === 'line') {
                    chartConfig.options = getLineChartOptions(options)
                    chartConfig.responsiveOptions = getResponsiveOptions(options)

                    if (chartConfig.data.series.length > 1) {
                        chartConfig.options.plugins = chartConfig.options.plugins.filter(element => element.name !== 'ctPointLabels')
                    }
                }
                else if (options.chartType === 'bar' || options.type === 'bar') {
                    options.enableDynamicBars = true
                    chartConfig.options = getBarChartOptions(options)
                    chartConfig.responsiveOptions = getResponsiveOptions(options)
                }
                else if (options.chartType === 'pie' || options.type === 'pie') {
                    options.verticalSeries = options.periodLabelsToSelect
                    options.selectedVerticalSeries = _.last(options.periodLabelsToSelect)

                    chartConfig.data = getVerticalSeriesAndLabels(chartConfig.data, options.selectedVerticalSeries)
                    chartConfig.options = getPieChartOptions(options)
                }
                chartConfig.options.originalLabels = chartConfig.data.labels
            }

            if (chartConfig.options.axisX) {
                chartConfig.options.axisX['labelInterpolationFnc'] =
					function(value, index) {
					    // Max 16 labels on x-axis
					    const a = Math.floor(numberOfDataPoints / 16) + 1
					    return index % a === 0 ? value : null
					}
            }

            return chartConfig
        }

        function randomizeData(options) {
            let color = '#f5f5f5'
            const amountOfSeriesToGenerate = options.chartType === 'pie' ? 10 : 5
            const randomizedData = {
                labels: [],
                series: [[], []],
            }

            for (let i = 2000; i < 2000 + amountOfSeriesToGenerate; i++) {
                if (options.chartType === 'pie') randomizedData.labels.push({ color: '#f5f5f5', value: i })
                else randomizedData.labels.push(i)
            }

            _.each(randomizedData.series, (single, index) => {
                if (options.chartType === 'pie') {
                    randomizedData.series.length = 0
                    _.each(randomizedData.labels, (label, index) => {
                        options.chartType === 'pie' && index % 2 == 0 ? color = '#e8e8e8' : color = '#f5f5f5'
                        randomizedData.series.push({ value: randomScalingFactor(), color })
                    })
                }
                else {
                    _.each(randomizedData.labels, () => {
                        single.push({ value: randomScalingFactor() })
                    })
                    randomizedData.series[index] = { color: options.colors[index], data: getSeries({ dataset: single, valueProperty: 'value', labels: randomizedData.labels, accumulateLineChart: true }) }
                }
            })

            return randomizedData

            function randomScalingFactor() {
                return (Math.random() > 0.5 ? 1.0 : 1.0) * Math.round(Math.random() * 2000)
            }
        }

        function prepareChartData(options) {
            let { datasets } = options

            let highestValue = undefined

            let lowestValue = undefined

            let dataHasMonths; let minYear; let minMonth; let maxYear; let maxMonth

            let valueProperties = options.valueProperties || 'value'

            let currentNumberOfColors = options.colors ? options.colors.length : 0

            let numberOfMissingColors

            let d3Colors

            //Rewrite - temp solution
            //Separating valueProperties = ["sum", "average"] into two arrays in data = [ 0: [{value: sum}], 1: [{value: average} ]
            const finalDatasets = []; let splitDataset = undefined
            if (Array.isArray(valueProperties) && !options.showAverage) {
                valueProperties = valueProperties.filter(x => x !== 'average')
            }

            if (!options.showTargets) {
                datasets = datasets.filter(x => !x.id.includes('target'))
            }

            const outputData = { labels: options.datasetLabels, series: [] }

            if (typeof valueProperties === 'object') {
                const periodDatasetExists = datasets.some(x => x.id === 'period')
                if (periodDatasetExists && !options.aggregatePeriodFrequencies) {
                    _.each(valueProperties, (value) => {
                        splitDataset = { data: [], legendLabel: '', id: undefined }
                        splitDataset.id = datasets[0].id
                        splitDataset.legendLabel = getLegendLabelFromDataSetId(value)
                        _.each(datasets[0].data, (res) => {
                            if (value in res) {
                                res.value = res[value]
                                splitDataset.data.push(res)
                            }
                        })
                        finalDatasets.push(_.cloneDeep(splitDataset))
                    })

                }

                const targetDatasets = datasets.filter(x => x.id.includes('target'))
                if (targetDatasets.length > 0 && (options.aggregateMode !== 'separate' || !options.aggregatePeriodFrequencies)) {
                    finalDatasets.push(...targetDatasets)
                }

                datasets = finalDatasets.length > 0 ? finalDatasets : datasets

                valueProperties = valueProperties.length == 1 && valueProperties[0] === 'sum' ? 'sum' : 'value'
            }

            if (datasets.length !== 0) {
                if (options.aggregateMode === 'separate' && options.ticket && options.ticket.networkId) {
                    options.colors = undefined
                    currentNumberOfColors = 0
                }

                //Check if there are enough colors defined for series
                if (!options.colors || (options.colors.length < datasets.length)) {
                    numberOfMissingColors = datasets.length - currentNumberOfColors
                    if (!options.colors) options.colors = []

                    if (numberOfMissingColors) d3Colors = d3.scale.category20c()

                    if (datasets.length > 1 && numberOfMissingColors) {
                        for (var i = currentNumberOfColors, len = datasets.length; i < len; i++) {
                            options.colors.push(d3Colors(datasets[i].name || datasets[i].id))
                        }
                    }
                    else {
                        for (var i = currentNumberOfColors, len = datasets.length; i < len; i++) {
                            options.colors.push(defaultSeriesColor)
                        }
                    }
                    // console.warn("Not enough colors defined for chart series.");
                }

                // Find out min and max year and month across all datasets
                _.each(datasets, (dataSet) => {
                    const firstDataEntry = dataSet.data[0]
                    const lastDataEntry = dataSet.data[dataSet.data.length - 1]

                    if (dataSet.name) dataSet.legendLabel = dataSet.name

                    if (!dataSet.legendLabel) dataSet.legendLabel = getLegendLabelFromDataSetId(dataSet.id) || undefined

                    if (!dataSet.legendLabel) options.showLegend = false

                    dataHasMonths = firstDataEntry ? firstDataEntry.month : null
                    // Find out min and max year and month across all datasets
                    if (firstDataEntry) {
                        if (!minYear || minYear >= firstDataEntry.year) {
                            minYear = firstDataEntry.year

                            if (dataHasMonths && !minMonth || minMonth >= firstDataEntry.month) {
                                minMonth = firstDataEntry.month
                            }
                        }
                    }
                    if (lastDataEntry) {
                        if (!maxYear || maxYear <= lastDataEntry.year) {
                            maxYear = lastDataEntry.year

                            if (dataHasMonths && !maxMonth || maxMonth <= lastDataEntry.month) {
                                maxMonth = lastDataEntry.month
                            }
                        }
                    }
                })

                if (dataHasMonths && options.startFromZero) {
                    // Offset the min month by -1 so that the line chart will always start with a zero value
                    if (minMonth === 1) {
                        minMonth = 12
                        minYear--
                    }
                    else {
                        minMonth--
                    }
                }

                // Loop through all datasets
                _.each(datasets, (dataset, index) => {
                    let preparedDataSetEntries = dataset.data

                    const legendLabel = dataset.legendLabel

                    const dataSetWithoutGaps = []

                    const dataByKey = _.keyBy(dataset.data, (item) => {
                        if (item.year) return item.year + '-' + item.month
                        else return item.label
                    })

                    let seriesOptions

                    let datasetWithHighestValue

                    if (options.fillTimeGapsOnXAxes) {
                        // Fill in gaps in the data with zeros so that every month has data
                        for (var year = minYear, monthCap; year <= maxYear; year++) {
                            // Set correct month cap for this year if it is the last year
                            monthCap = year === maxYear ? maxMonth : 12
                            // The loop starts on minMonth if this is the first year, otherwise 1
                            for (var month = year === minYear ? minMonth : 1, dataEntry; month <= monthCap; month++) {
                                dataEntry = dataByKey[year + '-' + month]

                                if (dataEntry) // Check if a data entry on this timestamp exists
                                    dataSetWithoutGaps.push(dataEntry)
                                else {
                                    // If not then fill in the gap with a zero
                                    dataSetWithoutGaps.push({
                                        year,
                                        month,
                                        value: 0,
                                    })
                                }
                            }
                        }
                        preparedDataSetEntries = dataSetWithoutGaps
                    }
                    else if (options.datasetLabels && !options.aggregatePeriodFrequencies) {
                        preparedDataSetEntries = []
                        for (let i = 0, len = options.datasetLabels.length; i < len; i++) {
                            if (options.datasetLabels[i] in dataByKey) preparedDataSetEntries.push(dataByKey[options.datasetLabels[i]])
                            else preparedDataSetEntries.push({
                                label: options.datasetLabels[i],
                                year: null,
                                month: null,
                                value: null,
                            })
                        }
                    }

                    if (outputData.labels === undefined) outputData.labels = getLabels(preparedDataSetEntries, dataHasMonths)

                    seriesOptions = {
                        dataset: preparedDataSetEntries,
                        datasetName: dataset.name, // The organization name when splitUpOrganizationStatistics is used
                        valueProperty: valueProperties,
                        labels: outputData.labels,
                        accumulateLineChart: options.accumulateLineChart || dataset.accumulate,
                        legendLabel,
                        ticket: options.ticket,
                        aggregatePeriodFrequencies: options.aggregatePeriodFrequencies,
                    }

                    outputData.series.push({ id: dataset.id, name: legendLabel, color: options.colors[index], data: getSeries(seriesOptions) })
                })

                //Find highest value from the dataset
                if (options.stackBars) {
                    const stackedData = []
                    _.each(outputData.series, (singleSeries, index) => {
                        for (let i = 0, len = singleSeries.data.length; i < len; i++) {
                            if (index === 0) {
                                stackedData.push(_.get(singleSeries.data[i], 'value') || 0)
                            }
                            else {
                                stackedData[i] += _.get(singleSeries.data[i], 'value') || 0
                            }
                        }
                    })

                    options.highestValue = highestValue = _.max(stackedData)
                    options.lowestValue = lowestValue = _.min(stackedData)
                }
                else {
                    _.each(outputData.series, (singleSeries) => {
                        const datasetWithHighestValue = _.maxBy(singleSeries.data, 'value')
                        const datasetWithLowestValue = _.minBy(singleSeries.data, 'value')

                        if (typeof datasetWithHighestValue === 'undefined' || typeof datasetWithLowestValue === 'undefined') {
                            return
                        }

                        if (typeof highestValue === 'undefined') options.highestValue = highestValue = datasetWithHighestValue.value

                        if (typeof lowestValue === 'undefined') options.lowestValue = lowestValue = datasetWithLowestValue.value

                        if (datasetWithHighestValue && datasetWithHighestValue.value > highestValue) options.highestValue = highestValue = datasetWithHighestValue.value

                        if (datasetWithLowestValue && datasetWithLowestValue.value < lowestValue) options.lowestValue = lowestValue = datasetWithLowestValue.value
                    })
                }

            }
            return outputData
        }

        function getLegendLabelFromDataSetId(value) {
            let label = ''; let questionAnswerTypeId; let answerText

            if (value && value.indexOf('questionAnswerType-') === 0) {
                questionAnswerTypeId = value.split('-')[1]

                answerText = wfPropertyExtractor.getQuestionAnswerTypeText(questionAnswerTypeId)
                label = answerText
            }
            else {
                switch (value) {
                    case 'sum':
                        label = $translate.instant('Sum')
                        break
                    case 'average':
                        label = $translate.instant('Average')
                        break
                    default:
                        // label = $translate.instant("Count");
                        break
                }
            }
            return label
        }

        function getLabels(dataset, dataHasMonths) {
            const monthNames = moment.monthsShort()
            return _.map(dataset, (dataEntry) => {
                if (dataEntry.label) return dataEntry.label
                else if (dataHasMonths) return monthNames[dataEntry.month - 1] + '\n' + dataEntry.year
                else return dataEntry.year
            })
        }

        function getSeries(options) {
            let
                accumulatedValue = 0

            const contextParentType = _.get(options.ticket, 'contextParentType')

            const groupName = contextParentType ? wfTranslate.instant('MAP_ObjectType', { type: contextParentType, plural: true }) : $translate.instant('Organizations')

            const dataset = options.dataset

            const valueProperty = options.valueProperty

            const accumulateLineChart = options.accumulateLineChart

            const tooltipMeta = {
                xAxisLabel: {
                    value: undefined,
                    label: '',
                },
                legendLabel: {
                    value: options.legendLabel,
                    label: '',
                },
                organizationCount: {
                    value: undefined,
                    label: groupName,
                },
                xAxisLabelDetails: {
                    value: undefined,
                    label: undefined,
                },
                datasetName: {
                    value: options.datasetName, // The organization name when splitUpOrganizationStatistics is used
                    label: undefined,
                },
                yearSum: {
                    value: undefined,
                    label: $translate.instant('YearSum'),
                },
            }

            if (options.aggregatePeriodFrequencies) {
                const datasetByLabel = _.keyBy(dataset, 'label')
                return _.map(options.labels, (label) => {
                    const dataEntry = datasetByLabel[label]

                    if (dataEntry) {
                        tooltipMeta.xAxisLabel.value = label
                        tooltipMeta.organizationCount.value = dataEntry.organizationCount
                        tooltipMeta.xAxisLabelDetails.value = dataEntry.detailedLabel
                        tooltipMeta.unit = dataEntry.unit

                        return { value: dataEntry[valueProperty], meta: stringifyJSON(tooltipMeta), unit: dataEntry.unit ? dataEntry.unit.symbol : undefined }
                    }

                    return { value: null }
                })
            }

            return _.map(dataset, (dataEntry, index) => {

                tooltipMeta.xAxisLabel.value = options.labels[index]
                tooltipMeta.organizationCount.value = dataEntry.organizationCount
                tooltipMeta.xAxisLabelDetails.value = dataEntry.detailedLabel
                tooltipMeta.unit = dataEntry.unit

                if (dataEntry.detailedLabel) {
                    const year = dataEntry.detailedLabel.substr(0, 4)
                    const sameYearEntries = dataset.filter(x => x.detailedLabel && x.detailedLabel.substr(0, 4) === year)
                    if (sameYearEntries.length) {
                        tooltipMeta.yearSum.value = sameYearEntries.reduce((total, x) => total + x[valueProperty], 0)
                    }
                }

                // Use the sum of all previous values if accumulateLineChart is true
                if (accumulateLineChart) {
                    accumulatedValue += dataEntry[valueProperty]
                    return { value: accumulatedValue, meta: stringifyJSON(tooltipMeta), unit: dataEntry.unit ? dataEntry.unit.symbol : undefined }
                }
                else // Otherwise simply use the value as is
                    return { value: dataEntry[valueProperty], meta: stringifyJSON(tooltipMeta), unit: dataEntry.unit ? dataEntry.unit.symbol : undefined }
            })
        }

        function getLineChartOptions(options) {
            return {
                type: options.type,
                showPoint: options.showPoint,
                lineSmooth: options.lineSmooth,
                height: options.chartHeight,
                fullWidth: !options.useFullWidth && !options.showXLabels ? true : options.useFullWidth,
                showArea: options.showArea,
                chartPadding: options.chartPadding,
                axisX: {
                    showGrid: options.showXGrid,
                    showLabel: options.showXLabels,
                    position: 'end',
                    offset: options.showXLabels ? 40 : 5,
                    labelOffset: { y: 10 },
                },
                axisY: {
                    showGrid: options.showYGrid,
                    showLabel: options.showYLabels,
                    offset: options.showYLabels ? 47 : 0,
                    labelOffset: { x: -10, y: 15 },
                    position: 'start',
                    high: options.highestValue === options.lowestValue ? options.highestValue + 2 : options.highestValue,
                    low: options.lowestValue,
                    labelInterpolationFnc(value) { return formatValue(value, true, true, true) },
                },
                plugins: initializeChartistPlugins(options),
            }
        }

        function getBarChartOptions(options) {
            return {
                type: options.type,
                height: options.chartHeight,
                chartPadding: options.chartPadding,
                seriesBarDistance: 20,
                stackBars: options.stackBars,
                fullWidth: !options.showXLabels,
                axisX: {
                    showLabel: options.showXLabels,
                    showGrid: options.showXGrid,
                    offset: options.showXLabels ? 40 : 5,
                    labelOffset: { y: 10 },
                },
                axisY: {
                    showLabel: options.showYLabels,
                    showGrid: options.showYGrid,
                    offset: options.showYLabels ? 47 : 0,
                    labelOffset: { x: -10, y: 15 },
                    labelInterpolationFnc(value) { return formatValue(value, true, true, true) },
                    position: 'start',
                    high: options.highestValue === options.lowestValue ? options.highestValue + 2 : options.highestValue,
                    low: options.lowestValue,
                },
                plugins: initializeChartistPlugins(options),
            }
        }

        function getPieChartOptions(options) {
            return {
                type: options.type,
                showTooltips: options.showTooltips,
                showLegend: options.showLegend,
                chartPadding: { top: 20, left: 15, bottom: 15, right: 15 },
                classNames: {
                    chartPie: 'ct-chart-pie',
                    chartDonut: 'ct-chart-donut',
                    series: 'ct-series',
                    slicePie: 'ct-slice-pie',
                    sliceDonut: 'ct-slice-donut',
                },
                donut: options.donut,
                donutSolid: false, //if set to true tooltips will not work
                donutWidth: options.donutWidth,
                ignoreEmptyValues: false,
                labelPosition: 'outside',
                labelDirection: 'neutral',
                labelInterpolationFnc(options) {
                    let label; let space = ''; let unit = options.unit
                    unit ? space = options.unit === '%' ? '' : ' ' : unit = ''

                    if (options.sum) label = _.round(options.value / options.sum * 100, 1) + '%'
                    else label = formatValue(options.value, true, true, true) + space + unit

                    return label
                },
                labelOffset: options.labelOffset ? options.labelOffset : options.showLabelPieChart ? 10 : 0,
                reverseData: false,
                showLabel: options.showLabelPieChart,
                startAngle: 0,
                total: undefined,
                width: undefined,
                height: undefined,
                plugins: initializeChartistPlugins(options),
            }
        }

        function getVerticalSeriesAndLabels(data, selectedVerticalSeries) {
            let
                verticalSeries = []

            let verticalLabels = []

            const index = selectedVerticalSeries ? data.labels.indexOf(selectedVerticalSeries) : 0

            let sumOfAllSeries = 0

            _.each(data.series, (series) => {
                if (series.data[index].value) {
                    if (series.color) series.data[index].color = series.color

                    sumOfAllSeries = sumOfAllSeries + series.data[index].value

                    verticalSeries = _.concat(verticalSeries, series.data[index])
                    verticalLabels = _.concat(verticalLabels, [{ color: series.color, name: series.name, value: series.data[index].value, unit: series.data[index].unit }])
                }
            })

            if (sumOfAllSeries) {
                _.each(verticalLabels, (label) => {
                    label.sum = sumOfAllSeries
                })
            }

            return {
                labels: verticalLabels,
                series: verticalSeries,
            }
        }

        function formatValue(value, useNumeralJs, roundNumber, abbreviateLargeNumbers) {
            return formatValueHelper(value, useNumeralJs, roundNumber, abbreviateLargeNumbers)
        }

        function getEmptyStateChartOptions(options) {
            if (options.cardLayout) {
                _.assign(options, {
                    asEmptyState: true,
                    height: 225,
                    messageToDisplay: options.messages.emptyStateMessage,
                    allowToDismissMessage: false,
                    showInfoMessage: true,
                    xAxisOffset: 0,
                    showXLabels: false,
                    showYLabels: false,
                    showXGrid: false,
                    showYGrid: false,
                    dashedLine: true,
                    colors: ['#ffffff', '#ffffff', '#ffffff'],
                    chartBackgroundColor: '#f5f5f5',
                })
            }
            else {
                _.assign(options, {
                    accumulateLineChart: true,
                    showPoint: true,
                    lineSmooth: true,
                    showXLabels: false,
                    showYLabels: false,
                    showXGrid: true,
                    showYGrid: true,
                    showArea: true,
                    xAxisOffset: 40,
                    showTooltips: false,
                    showLegend: false,
                    allowToDismissMessage: false,
                    showInfoMessage: true,
                    messageToDisplay: options.messages.emptyStateMessage,
                    colors: ['#f5f5f5', '#f5f5f5', '#f5f5f5'],
                })
            }

            return {
                donut: !!(options.actualType && options.actualType === 'donut'),
                donutSolid: !!(options.actualType && options.actualType === 'donut'),
                donutWidth: options.actualType && options.actualType === 'donut' ? options.donutWidth : undefined,
                accumulateLineChart: options.accumulateLineChart,
                disablePointerEvents: true,
                height: options.chartHeight,
                chartPadding: options.chartType === 'pie' ? { top: 30, left: 5, right: 5, bottom: 5 } : options.chartPadding,
                showPoint: options.showPoint,
                lineSmooth: options.lineSmooth,
                fullWidth: !options.showXLabels,
                showArea: options.showArea,
                showLegend: options.showLegend,
                showLabel: options.chartType === 'pie' ? false : undefined,
                dashedLine: options.dashedLine,
                axisX: {
                    showGrid: options.showXGrid,
                    showLabel: options.showXLabels,
                    position: 'end',
                    offset: options.xAxisOffset,
                    labelOffset: { y: 10 },
                },
                axisY: {
                    showGrid: options.showYGrid,
                    showLabel: options.showYLabels,
                    offset: options.showYLabels ? 25 : 0,
                    labelOffset: { x: -5, y: 15 },
                    position: 'start',
                },
                plugins: initializeChartistPlugins(options),
            }
        }

        function getResponsiveOptions() {
            return [
                ['screen and (min-width: 641px) and (max-width: 1024px)', {}],
            ]
        }

        function initializeChartistPlugins(options) {
            const useNumeralJs = true
            const allowRoundNumber = true
            const abbreviateLargeNumbers = false
            let colors = {}

            if (vm.ChartistBackup) {
                window.Chartist = vm.ChartistBackup
            }

            if (options.colors && options.colors.length >= 1) colors = { colors: options.colors }

            const plugins = []

            const tooltipPlugin = Chartist.plugins.tooltip({
                appendToBody: options.appendTooltipToBody,
                pointClass: 'ct-custom-point',
                tooltipFnc(meta, value) {
                    let
                        legendLabel

                    let datasetName

                    let xAxisLabel

                    let xAxisLabelDetails

                    let extraHtml = ''

                    meta = parseStringToJSON(meta)
                    value = formatValue(parseFloat(value), useNumeralJs, allowRoundNumber, abbreviateLargeNumbers)

                    value = getValueWithUnit(value, meta.unit)

                    if (!_.isEmpty(meta)) {
                        legendLabel = _.get(meta, 'legendLabel.value')
                        datasetName = _.get(meta, 'datasetName.value')
                        xAxisLabel = _.get(meta, 'xAxisLabel.value')
                        xAxisLabelDetails = _.get(meta, 'xAxisLabelDetails.value')

                        if (options.ticket && (options.ticket.organizationIds || options.ticket.receivingOrganizationsAndLimitedDataAccessFromInfluenceId) && meta.organizationCount && meta.organizationCount.value && parseInt(meta.organizationCount.value) >= 0) {
                            if (options.aggregateMode === 'separate') {
                                extraHtml = '<div class=\'meta\'><div class=\'org-name\'>'
                                    + $sanitize(datasetName)
                                    + '</div>'
                                    + (options.splitOption !== 'relativeMeasureSourceObjects' ? '' : ('<div class=\'pt5\'><span>' + $sanitize(meta.organizationCount.label) + ':</span> <span class=\'text-bold\'>' + $sanitize(meta.organizationCount.value) + '</span></div>'))
                                    + '</div>'
                            }
                            else {
                                extraHtml = '<div class=\'meta\'><span>' + $sanitize(meta.organizationCount.label) + ':</span> <span class=\'text-bold\'>' + $sanitize(meta.organizationCount.value) + '</span></div>'
                            }
                        }

                        if (datasetName) {
                            extraHtml = '<div class=\'meta\'><div class=\'org-name\'>'
                                    + $sanitize(datasetName)
                                    + '</div>'
                        }

                        if (meta.yearSum && !options.aggregatePeriodFrequencies) {
                            extraHtml += `<div class='meta' style='padding-top:6px'><span>${$sanitize(meta.yearSum.label)}:</span>`
                                    + ` <span class='text-bold'>${$sanitize(getValueWithUnit(formatValue(parseFloat(meta.yearSum.value), useNumeralJs, allowRoundNumber, abbreviateLargeNumbers), meta.unit))}`
                                    + `</span></div>`
                        }
                    }

                    const tooltipHtml = '<div class=\'custom-tooltip-template pull-left\'>' +
							(options.aggregatePeriodFrequencies ? (
							    '<div class=\'tooltip-header\'>' +
								'<span class=\'value\'>' + $sanitize(xAxisLabelDetails + ' ' + datasetName) + '</span>' +
								'</div>'
							) :
							    (xAxisLabel ? (
							        '<div class=\'tooltip-header\'>' +
									'<span class=\'value\'>' + $sanitize(xAxisLabel) + '</span>' +
									(xAxisLabelDetails && xAxisLabelDetails != xAxisLabel ? '<div class=\'value-detailed\'>' + $sanitize(xAxisLabelDetails) + '</div>' : '') +
									'</div>'
							    ) : '') +
								'<div class=\'tooltip-body\'>' +
								(legendLabel && datasetName != legendLabel ? legendLabel + ': ' : '')) + '<span class=\'text-bold data-value\'>' + $sanitize(value) + '</span>' +
							extraHtml +
							'</div>' +
							'</div>'

                    return tooltipHtml
                },
            })

            const colorPlugin = Chartist.plugins.color(colors)

            const legendPlugin = Chartist.plugins.legend({ position: 'bottom', clickable: true })

            const pointPlugin = Chartist.plugins.point({ circleShapeOptions: { radius: 6, enableAnimations: options.enableAnimations, fillColor: options.chartBackgroundColor } })

            const positionPlugin = Chartist.plugins.positionChartCenter({ gridColor: ColorLuminance(options.chartBackgroundColor, 0.1) })

            const infoMessagePlugin = Chartist.plugins.infoMessage({ message: options.messageToDisplay, allowToDismissMessage: options.allowToDismissMessage })

            const responsiveLabelsPlugin = Chartist.plugins.responsiveLabels()

            const dynamicBarWidthPlugin = Chartist.plugins.dynamicBarWidth()

            const linearGradientPlugin = Chartist.plugins.linearGradient({ linearGradientId: _.uniqueId('chart-area-gradient_'), fromColor: ColorLuminance(options.chartBackgroundColor, options.asEmptyState ? -0.2 : -0.5), toColor: options.chartBackgroundColor })

            const lineSeriesSelectionPlugin = Chartist.plugins.seriesSelection({ periodLabelsToSelect: options.periodLabelsToSelect, data: options.originalData, onSeriesClick: options.onSeriesClick, translatePlugin: $translate })

            const pieSeriesSelectionPlugin = Chartist.plugins.seriesSelection({ periodLabelsToSelect: options.periodLabelsToSelect, data: options.originalData, verticalSeries: options.verticalSeries, selectedVerticalSeries: options.selectedVerticalSeries, getVerticalSeriesAndLabels, translatePlugin: $translate })

            const animatePlugin = Chartist.plugins.animate()

            const ctPointLabels = Chartist.plugins.ctPointLabels({ labelInterpolationFnc(value) { return formatValue(value, true, true, true) } })

            plugins.push(colorPlugin)

            if (!options.type === 'pie') plugins.push(responsiveLabelsPlugin)

            if (options.showSeriesSelection && options.aggregateMode === 'combine' && (options.type === 'line' || options.type === 'bar') && options.showPeriodDropdown) {
                plugins.push(lineSeriesSelectionPlugin)
            }

            if (options.enableAnimations) plugins.push(animatePlugin)

            if (options.showTooltips) plugins.push(tooltipPlugin)

            if (options.showLegend) plugins.push(legendPlugin)

            if (options.showPoint) plugins.push(pointPlugin)

            if (options.showInfoMessage) plugins.push(infoMessagePlugin)

            if (options.showPointLabels && (options.type === 'line' || options.actualType === 'bar')) {
                plugins.push(ctPointLabels)
            }

            if (options.type === 'line' && !options.useFullWidth) plugins.push(positionPlugin)

            if (options.type === 'bar') plugins.push(dynamicBarWidthPlugin)

            if (options.addLinearGradientElement) plugins.push(linearGradientPlugin)

            if (options.type === 'pie') {
                plugins.push(pieSeriesSelectionPlugin)
            }

            return plugins

            function getValueWithUnit(value, unit) {
                let newValue = ''
                if (unit && unit.symbol !== undefined) {
                    if (unit.symbol !== '%') newValue = value + ' ' + unit.symbol // Non-break space (Alt+0160)
                    else newValue = value + unit.symbol
                }
                else {
                    newValue = value
                }
                return newValue
            }
        }

        function getChartWithDynamicBars(chart, chartOriginalOptions) {
            const
                chartComponentWidth = $(chart.container)[0].getBoundingClientRect().width

            const chartPadding = chart.options.chartPadding

            const axisYOffset = chart.options.axisY.offset

            let showInfoMessage = false

            let messageToDisplay = chartOriginalOptions.messages.barOverlapingMessage

            let chartWidth

            let labelWidth

            const labelPadding = 20

            let labelWidthWithPadding

            let chartLength

            let numberOfSeries

            let barDistance

            let barWidth

            const maxBarWidth = 120

            const seriesBarDistance = chartOriginalOptions.seriesBarDistance || 1

            const stackBars = chartOriginalOptions.stackBars

            let gap

            chartWidth = (chartComponentWidth - (chartPadding.left + chartPadding.right)) - axisYOffset
            labelWidth = chartWidth / chart.data.labels.length
            labelWidthWithPadding = labelWidth - labelPadding
            chartLength = chart.data.series.length
            numberOfSeries = stackBars ? 1 : chartLength
            barDistance = labelWidth / numberOfSeries

            if (barDistance > labelPadding + 5) barDistance = labelWidthWithPadding / numberOfSeries

            barWidth = barDistance - seriesBarDistance

            if (barWidth < 1) {
                barWidth = 1
                gap = seriesBarDistance >= 3 ? seriesBarDistance - 3 : 3
                if (barDistance < gap) showInfoMessage = true
            }

            chartOriginalOptions.showPointLabels = (chart.data.labels.length * chartLength) <= 13

            if (barWidth > maxBarWidth) barWidth = maxBarWidth

            if (chartOriginalOptions.showInfoMessage) {
                messageToDisplay = chartOriginalOptions.messageToDisplay,
                showInfoMessage = true
            }

            _.assign(chartOriginalOptions, {
                showInfoMessage,
                messageToDisplay,
            })

            _.assign(chart.options, {
                seriesBarDistance: barDistance,
                barWidth,
                plugins: initializeChartistPlugins(chartOriginalOptions),
            })

            chart.options.showAverage = chartOriginalOptions.showAverage

            return new Chartist.Bar($(chart.container)[0], chart.data, chart.options, chart.responsiveOptions)
        }

        function mockRandomData(options) {
            const numOfYears = options.numberOfYears || 3
            const numOfDatasets = options.numberOfDatasets || 3
            const datasets = []
            let singleDataset
            const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            const years = 2000 + numOfYears

            for (let j = 1; j <= numOfDatasets; j++) {
                singleDataset = { data: [], id: 'count' }
                for (let i = 2000; i < years; i++) {
                    singleDataset.data.push({ year: i, month: _.sample(months), value: randomScalingFactor(), sum: null, average: null, organizationCount: null })
                }
                datasets.push(singleDataset)
            }

            console.warn('Chartist data is randomized! Please set mockRandomData to false when finised testing!')
            return datasets

            function randomScalingFactor() {
                return (Math.random() > 0.5 ? 1.0 : 1.0) * Math.round(Math.random() * 2000)
            }
        }

        function parseStringToJSON(string) {
            //Define a function to fix special characters in the String
            if (!String.prototype.escapeSpecialChars) {
                String.prototype.escapeSpecialChars = function () {
                    return this.replace(/\\n/g, '\\n')
                        .replace(/\\'/g, '\\\'')
                        .replace(/\\"/g, '\\"')
                        .replace(/\\&/g, '\\&')
                        .replace(/\\r/g, '\\r')
                        .replace(/\\t/g, '\\t')
                        .replace(/\\b/g, '\\b')
                        .replace(/\\f/g, '\\f')
                }
            }

            if (string.indexOf('&quot;') > -1) string = string.replace(/&quot;/g, '"')
            string = string.escapeSpecialChars()

            if (string !== null && isJSONString(string)) return JSON.parse(string)
            else return false

            function isJSONString(string) {
                try {
                    JSON.parse(string)
                }
                catch (e) {
                    console.warn('Could not parse String into JSON')
                    return false
                }
                return true
            }
        }

        function stringifyJSON(data) {
            if (!data) {
                console.error('No data to stringify')
                return
            }

            if (typeof data === 'object') data = JSON.stringify(data, undefined, 4)
            else console.error('Cannot stringify data. Data is not an object')

            return data
        }

        function ColorLuminance(hex, lum) {

            // validate hex string
            hex = String(hex).replace(/[^0-9a-f]/gi, '')
            if (hex.length < 6) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
            }
            lum = lum || 0

            // convert to decimal and change luminosity
            let rgb = '#'; let c; let i
            for (i = 0; i < 3; i++) {
                c = parseInt(hex.substr(i * 2, 2), 16)
                c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
                rgb += ('00' + c).substr(c.length)
            }

            return rgb
        }
    }
}())
