import * as _AnalyzeJobsTableElements from './AnalyzeJobsTableElements'
import * as _AnalyzeResultsTableElements from './AnalyzeResultsTableElements'
import * as _OrganizationsTableElements from './OrganizationsTableElements'
import * as _CategorizationsTableElements from './CategorizationsTableElements'
import * as _ContributorsTableElements from './ContributorsTableElements'
import * as _ProductsTableElements from './ProductsTableElements'

export const AnalyzeJobsTableElements = _AnalyzeJobsTableElements
export const AnalyzeResultsTableElements = _AnalyzeResultsTableElements
export const OrganizationsTableElements = _OrganizationsTableElements
export const CategorizationsTableElements = _CategorizationsTableElements
export const ContributorsTableElements = _ContributorsTableElements
export const ProductsTableElements = _ProductsTableElements

export default {
    AnalyzeJobsTableElements,
    AnalyzeResultsTableElements,
    OrganizationsTableElements,
    CategorizationsTableElements,
    ContributorsTableElements,
    ProductsTableElements,
}
