function ctColor(options) {
    const Chartist = require('chartist')

    function setStrokeColor(color, element) {
        element.attr({ style: `stroke: ${color};` })
    }

    function setFillColor(color, element) {
        element.attr({ style: `fill: ${color};` })
    }

    return function ctColor(chart) {
        const defaultOptions = {}

        options = Chartist.extend({}, defaultOptions, options)

        if (chart instanceof Chartist.Line) {
            chart.on('draw', (data) => {
                if (data.type !== 'grid' && data.type !== 'label') {
                    setStrokeColor(data.series.color, data.element)
                }
                if (data.type === 'area') {
                    setFillColor(data.series.color, data.element)
                }
            })
        }

        if (chart instanceof Chartist.Bar) {
            chart.on('draw', (data) => {
                if (data.type !== 'grid' && data.type !== 'label') {
                    setStrokeColor(data.series.color, data.element)
                }
            })
        }

        if (chart instanceof Chartist.Pie) {
            chart.on('draw', (data) => {
                if (data.type === 'slice') {
                    if (chart.options.donut) {
                        data.element._node.style.stroke = data.series.color
                        if (chart.options.donutSolid) {
                            setFillColor(data.series.color, data.element)
                        }
                    }
                    else {
                        setStrokeColor(data.series.color, data.element)
                        setFillColor(data.series.color, data.element)
                    }
                }
            })
        }
    }
}

export default ctColor
