export const ConstVars = {
    betaPath: '/r/beta',
    exchanges: 'exchanges',
    products: 'products',
    thirdParty: 'third-party-exchanges',
    organizations: 'organizations',
    contributors: 'contributors',
    categorizations: 'categorizations',
    analyzeJobs: 'analyzeJobs',
    analyzeResults: 'analyzeResults/:analyzeJobId',
    action: 'action',
    dataManagerPath: '/:solutionId/valuechain/:networkId/',
    OrganizationAccessors: {
        Name: 'name',
        Organization: 'organization',
        PrimaryAddress: 'location.formattedAddress',
        VatNumber: 'vatNumber',
        GlnNumber: 'glnNumber',
        RegistrationNumber: 'registrationNumber',
        Country: 'country',
        CountryId: 'countryId',
    },
    ContributorAccessors: {
        Name: 'contributorUser.name',
        Organization: 'contributorOrganization.name',
        OrganizationId: 'organizationId',
        Email: 'contributorUser.email',
        Position: 'contributorUser.position',
        Phone: 'contributorUser.phone_number',
        Language: 'contributorUser.culture',
        Culture: 'culture',
    },
    CategorizationsAccessors: {
        Organization: 'categorizedOrganization.name',
        Category: 'category.title',
        Date: 'createdAt',
        CategoryId: 'categoryId',
    },
    AnalyzeJobsAccessors: {
        Title: 'title',
        Organization: 'targetOrganization.name',
        TargetOrganizationId: 'targetOrganizationId',
        PackageId: 'packageId',
        Score: 'score',
        ScoreCalculatedAt: 'scoreCalculatedAt',
        CreatedAt: 'createdAt',
        ScoreLabel: 'scoreLabel',
    },
    AnalyzeResultsAccessors: {
        Name: 'item',
        Organization: 'organization',
        CategoryOne: 'category1_title',
        CategoryOneDescription: 'category1_description',
        CategoryTwo: 'category2_title',
        CategoryTwoDescription: 'category2_description',
        CategoryThree: 'category3_title',
        CategoryThreeDescription: 'category3_description',
        Order: 'order',
        CalcStepOrder: 'stepOrder',
        Matched: 'matched',
        Requirement: 'requirement',
        Operation: 'operation',
        StepScore: 'points',
        RequiresOppositeAnswer: 'opposite',
        Break: 'break',
        AdditionalTasks: 'additionalTask',
        CategorizationsAssigned: 'categorizationsAssigned',
        TotalDataPointScore: 'localScoreAfterCalcStep',
        TotalAnalyzeJobScore: 'totalScoreAfterCalcStep',
    },
    PackageTypes: {
        RequirementPackage: 'RequirementPackage',
        AnalyzePackage: 'AnalyzePackage',
    },
    SystembolagetNetworkId: 186,
}

export const MIN_FILTER_OPTIONS_TO_SHOW_SEARCH_BAR = 10

export const DEFAULT_LANGUAGE = 'en-US'

export const DEFAULT_CHART_COLORS = ['#F89278', '#6773C7', '#FFBF00', '#02975C', '#990099', '#109618', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#3366cc', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411']

export const GOOGLE_MAPS_API_KEY = 'AIzaSyChfzQUypvsAJdjIdCtQIzAUP_P0GHIbiM'

export const MAX_PAGE_CONTENT_WIDTH = 1300

export type FILTER_OPTIONS_TYPE_PROPS = Array<{ label: string, id: string | number, count?: number, active: boolean }>

export const FilterTypes = {
    TextSearch: 'TextSearch',
    PageSize: 'PageSize',
    PageNumber: 'PageNumber',
    NetworkId: 'NetworkId',
    NetworkIds: 'NetworkIds',
    AnalyzeJobIds: 'AnalyzeJobIds',
    TargetOrganizationIds: 'TargetOrganizationIds',
    ObjectWfids: 'ObjectWfids',
}

export const StaticIds = {
    SentInfluences: '71-30152',
    ReceivedInfluences: '71-10489',
    ProductsInOwnedNetworks: '71-30150',
    DataExplorer: '71-36359',
    ThirdPartyPermissions: '71-37813',
    SentThirdPartyInfluences: '71-38258',

    NetworksReceivedFrom: '71-31339',
    OwnedNetworks: '71-12226',
    NetworksMemberOf: '71-11009',

    Activities: '71-10015',
    Documents: '71-10016',
    Comments: '71-10018', //objectType is still Statement
    Links: '71-10017',
    Structures: '71-16125',
    Questions: '71-12099',
    Measures: '71-12100',
    RelativeMeasures: '71-20361',
    Embeds: '71-11843',
    Locations: '71-11970',
    Certificates: '71-13802',
    Countries: '71-13804',
    ProductionSites: '71-13874',
    OwnedProducts: '71-20243',
    Colleagues: '71-10051',
    NetworkContributors: '71-35982',
    People: '71-16091',
    DateItems: '71-23762',
}

export const DefaultTablePageSize = 10

export const SupportEmail = 'help@worldfavor.com'
