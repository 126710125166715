import { subItemsKind } from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfChartLine', wfChartLine)

    wfChartLine.$inject = ['$timeout', 'wfMeasureService', '$filter', 'dataQuery', 'wfAuth']
    function wfChartLine($timeout, wfMeasureService, $filter, dataQuery, wfAuth) {
        const directive = {
            link,
            scope: {
                measure: '=?',
                height: '=',
                chartOptions: '<',
                chartDataPrototype: '<',
                chartAsImage: '<',
                ticket: '<',
            },
            restrict: 'E',
            require: '?^^wfHierarchical',
            templateUrl: 'scripts/wf/visualization/wfChartLine.directive.html',
        }

        return directive

        function link($scope, $element, $attrs, wfHierarchicalController) {
            const vm = this

            _.assign($scope, {
                defaultCanvasHeight: 150,
                data: undefined,
                options: undefined,
                showChart: true,
                chartLoaded: false,
            })

            _.assign(vm, {
                data: undefined,
                options: undefined,
                groupedSortedAnswers: undefined,
                minifiedBlue: 'minifiedBlue' in $attrs || false,
                minifiedGreen: 'minifiedGreen' in $attrs || false,
                responsive: 'responsive' in $attrs || false,
                defaultScale: {
                    height: $scope.defaultCanvasHeight,
                    radius: 6,
                    grid: true,
                    xAxes: {
                        display: true,
                        ticks: {
                            fontStyle: 'normal',
                            fontColor: '#34495e',
                            fontSize: 11,
                            display: true,
                            ticksLimit: undefined,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    yAxes: {
                        display: true,
                        ticks: {
                            fontStyle: 'normal',
                            fontColor: '#34495e',
                            fontSize: 11,
                            display: false,
                            ticksLimit: undefined,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    maxTicksLimit: 11,
                    pointBorderWidth: 3,
                    hoverBorderWidth: 3,
                    hoverRadius: 10,
                    tooltips: {
                        xPadding: 8,
                        yPadding: 8,
                        caretSize: 8,
                        cornerRadius: 6,
                        titleFontSize: 10,
                        footerFontSize: 16,
                        callbacks: {
                            title(tooltipItems) {
                                return tooltipItems[0].xLabel
                            },
                            footer(tooltipItems, data) {
                                const index = tooltipItems[0].index
                                let unit = data.datasets[0].unit[index]
                                let value = tooltipItems[0].yLabel

                                if (unit && unit !== '%') unit = ' ' + unit // Non-break space (Alt+0160)

                                if (typeof value === 'number') value = numeral(value).format('0,0.[0000]')

                                return value + (unit || '')
                            },
                            label() {
                                return ''
                            },
                            labelColor() {
                                return ''
                            },
                        },
                    },
                    pointBackgroundColor: '#2980B9',
                    pointBorderColor: '#FFFFFF',
                    backgroundColor: '',
                    lineBorderColor: '#2980b9',
                    tooltipHoverBackground: 'rgba(52,73,94, 0.8)',
                },
                minifiedScaleBlue: {
                    radius: 4,
                    grid: false,
                    xAxes: {
                        display: true,
                        ticks: {
                            fontStyle: 'normal',
                            fontColor: '#34495e',
                            fontSize: 9,
                            display: true,
                            ticksLimit: undefined,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    yAxes: {
                        display: true,
                        ticks: {
                            fontStyle: 'normal',
                            fontColor: '#34495e',
                            fontSize: 9,
                            display: false,
                            ticksLimit: undefined,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    maxTicksLimit: 2,
                    pointBorderWidth: 3,
                    hoverBorderWidth: 2,
                    hoverRadius: 6,
                    tooltips: {
                        xPadding: 5,
                        yPadding: 5,
                        caretSize: 5,
                        cornerRadius: 4,
                        titleFontSize: 9,
                        footerFontSize: 13,
                        callbacks: {
                            title(tooltipItems) {
                                return tooltipItems[0].xLabel
                            },
                            footer(tooltipItems, data) {
                                const index = tooltipItems[0].index
                                return tooltipItems[0].yLabel + ' ' + data.datasets[0].unit[index] // Non-break space (Alt+0160)
                            },
                            label() {
                                return ''
                            },
                            labelColor() {
                                return ''
                            },
                        },
                    },
                    pointBackgroundColor: '#2980B9',
                    pointBorderColor: '#FFFFFF',
                    backgroundColor: '',
                    lineBorderColor: '#2980b9',
                    tooltipHoverBackground: 'rgba(52,73,94, 0.8)',
                },
                minifiedScaleGreen: {
                    radius: 4,
                    grid: false,
                    xAxes: {
                        display: true,
                        ticks: {
                            fontStyle: '400',
                            fontColor: '#34495e',
                            fontSize: 9,
                            display: true,
                            ticksLimit: 2,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    yAxes: {
                        display: false,
                        ticks: {
                            fontStyle: 'normal',
                            fontColor: '#34495e',
                            fontSize: 9,
                            display: false,
                            ticksLimit: undefined,
                        },
                        minValue: undefined,
                        maxValue: undefined,
                    },
                    pointBorderWidth: 1.5,
                    lineBorderWidth: 3,
                    hoverBorderWidth: 2,
                    hoverRadius: 6,
                    tooltips: {
                        xPadding: 5,
                        yPadding: 5,
                        caretSize: 5,
                        cornerRadius: 4,
                        titleFontSize: 9,
                        footerFontSize: 13,
                        callbacks: {
                            title(tooltipItems, data) {
                                const index = tooltipItems[0].index
                                return tooltipItems[0].xLabel + ' - ' + tooltipItems[0].yLabel + ' ' + data.datasets[0].unit[index]
                            },
                            footer() {
                                return ''
                            },
                            label() {
                                return ''
                            },
                            labelColor() {
                                return ''
                            },
                        },
                    },
                    pointBackgroundColor: '#00CE71',
                    pointBorderColor: '#FFFFFF',
                    backgroundColor: '#B0F1D5',
                    lineBorderColor: '#00CE71',
                    tooltipHoverBackground: '#00CE71',
                },
            })

            $scope.$on('measureChangedInObjectViewer', ($event, measure) => {
                activate()
            })

            const defaultFontFamily = '\'Roboto\', \'Segoe UI\', \'Droid Sans\', Tahoma, Arial, sans-serif'
            let chartOption = vm.defaultScale

            if (vm.minifiedBlue) chartOption = vm.minifiedScaleBlue
            if (vm.minifiedGreen) chartOption = vm.minifiedScaleGreen

            setCanvasHeight()
            $timeout(() => {
                activate()
                $scope.chartLoaded = true
            }, 100)

            function activate() {
                const measureChilds = $scope.measure && dataQuery.getIntersectedSubItems($scope.measure, _.assign({
                    kind: subItemsKind.childrenByUser,
                }, $scope.ticket || { organizationId: wfAuth.getOrganizationId() }))

                if (measureChilds && measureChilds.length < 2) {
                    $scope.showChart = false
                    if (wfHierarchicalController) wfHierarchicalController.chartLoaded($scope.measure.wfid)
                }
                else
                {
                    $scope.data = chartData(chartOption)
                    if ($scope.data.length < 2) {
                        $scope.showChart = false
                        if (wfHierarchicalController) wfHierarchicalController.chartLoaded($scope.measure.wfid)
                    }
                    else if ($scope.chartOptions) {
                        $scope.options = $scope.chartOptions
                    }
                    else {
                        $scope.options = chartOptions(chartOption)
                    }
                }
            }

            function setCanvasHeight() {
                if (!(typeof $scope.height === 'undefined')) {
                    $scope.defaultCanvasHeight = $scope.height
                }
            }

            function getColumnsFromMeasure() {
                const measureChilds = dataQuery.getIntersectedSubItems($scope.measure, _.assign({
                    kind: subItemsKind.childrenByUser,
                }, $scope.ticket || { organizationId: wfAuth.getOrganizationId() }))

                vm.groupedSortedAnswers = wfMeasureService.groupAndFormatAnswersByPeriod(measureChilds, { useShortDates: true })

                return {
                    labels: _.chain(vm.groupedSortedAnswers).map((o) => {
                        return o.periodName
                    }).value(),
                    data: _.chain(vm.groupedSortedAnswers).map((o) => {
                        return parseFloat(o.value)
                    }).value(),
                    unit: _.chain(vm.groupedSortedAnswers).map((o) => {
                        return o.childContent.symbol || ''
                    }).value(),
                }
            }

            function chartData(scale) {
                const
                    measure = getColumnsFromMeasure()
					
                let dataMinValue
					
                let dataMaxValue

                if (measure.unit[0] === '%') {
                    scale.yAxes.display = true,
                    scale.yAxes.minValue = 0

                    dataMinValue = _.min(measure.data)
                    dataMaxValue = _.max(measure.data)

                    scale.yAxes.minValue = dataMinValue < 0 ? dataMinValue : 0
                    scale.yAxes.maxValue = dataMaxValue > 100 ? dataMaxValue : 100
                }

                return {
                    labels: measure.labels,
                    datasets: [_.assign({
                        data: measure.data,
                        unit: measure.unit,
                        pointHoverBorderColor: '#FFFFFF',
                        showLine: true,
                        backgroundColor: scale.backgroundColor,
                        pointBackgroundColor: scale.pointBackgroundColor,
                    }, $scope.chartDataPrototype)],
                }
            }

            function chartOptions(scale) {
                return {
                    responsive: vm.responsive,
                    maintainAspectRatio: false,
                    fontFamily: defaultFontFamily,
                    legend: { display: false },
                    scales: {
                        xAxes: [{
                            display: scale.xAxes.display,
                            gridLines: {
                                display: scale.grid,
                                borderDash: [1],
                            },
                            ticks: {
                                fontFamily: defaultFontFamily,
                                fontStyle: scale.xAxes.ticks.fontStyle,
                                fontColor: scale.xAxes.ticks.fontColor,
                                fontSize: scale.xAxes.ticks.fontSize,
                                autoSkip: true,
                                maxTicksLimit: scale.xAxes.ticks.ticksLimit,
                                min: scale.yAxes.minValue,
                                max: scale.yAxes.maxValue,
                            },
                        }],
                        yAxes: [{
                            display: scale.yAxes.display,
                            gridLines: {
                                display: scale.grid,
                                borderDash: [1],
                            },
                            ticks: {
                                display: scale.yAxes.ticks.display,
                                fontColor: scale.yAxes.ticks.fontColor,
                                fontSize: scale.yAxes.ticks.fontSize,
                                min: scale.yAxes.minValue,
                                max: scale.yAxes.maxValue,
                            },
                        }],
                    },
                    elements: {
                        line: {
                            tension: 0,
                            borderWidth: scale.lineBorderWidth,
                            borderColor: scale.lineBorderColor,
                        },
                        point: {
                            borderWidth: scale.pointBorderWidth,
                            borderColor: scale.pointBorderColor,
                            hoverBorderWidth: scale.hoverBorderWidth,
                            hoverRadius: scale.hoverRadius,
                            radius: scale.radius,
                        },
                        arc: {
                            backgroundColor: 'rgba(0,0,0,0.9)',
                        },
                    },
                    tooltips: {
                        callbacks: scale.tooltips.callbacks,
                        xPadding: scale.tooltips.xPadding,
                        yPadding: scale.tooltips.yPadding,
                        caretSize: scale.tooltips.caretSize,
                        cornerRadius: scale.tooltips.cornerRadius,
                        titleFontSize: scale.tooltips.titleFontSize,
                        footerFontSize: scale.tooltips.footerFontSize,
                        footerFontStyle: 'bold',
                        displayColors: true,
                        position: 'nearest',
                        backgroundColor: scale.tooltipHoverBackground,
                        multiKeyBackground: '#ffffff',
                        borderColor: '#34495e',
                        enabled: true,
                        intersect: false,
                    },
                    layout: {
                        padding: {
                            top: 5,
                            left: 5,
                            right: 0,
                            bottom: 0,
                        },
                    },
                    animation: {
                        duration: $scope.chartAsImage ? 0 : 1, // general animation time
                        onComplete(chartElement) {
                            if ($scope.chartAsImage) {
                                const base64Image = chartElement.chart.toBase64Image()
                                if (base64Image && base64Image.length > 6) {
                                    const canvasWrapperElement = $element.children('div.canvas-wrapper')
                                    const img = $('<img id=\'chart-as-png\'>')
                                    canvasWrapperElement.find('canvas').remove()
                                    img.attr('src', base64Image)
                                    canvasWrapperElement.append(img)
                                    if (wfHierarchicalController) wfHierarchicalController.chartLoaded($scope.measure.wfid)
                                }
                            }
                        },
                    },
                    hover: {
                        animationDuration: 0, // duration of animations when hovering an item
                    },
                    responsiveAnimationDuration: 0, // animation duration after a resize,
                }
            }
        }

    }
} ())
