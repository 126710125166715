import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfParameterAnswering', wfParameterAnswering)
	
    wfParameterAnswering.$inject = ['$parse', 'dataQuery', 'dataOperationsService', 'moment', 'modalService', 'wfAuth', '$compile', '$translate', '$q', 'apiProxy', '$rootScope']

    function wfParameterAnswering($parse, dataQuery, dataOps, moment, modal, wfAuth, $compile, $translate, $q, apiProxy, $rootScope) {
        const directive = {
            restrict: 'EA',
            templateUrl: 'scripts/wf/answering/wfParameterAnswering.directive.html',
            controllerAs: 'parameterAnsweringVm',
            controller: ['$scope', '$element', '$attrs', 'wfObject', wfParameterAnsweringController],
        }

        let countriesDropdownItems
		
        return directive

        function wfParameterAnsweringController($scope, $element, $attrs, wfObject) {
            let
                vm = this
				
            let organizationId
				
            let influence
				
            let networkId
				
            let parameter
				
            let parameterValue
				
            let uiMode
				
            let ticket

            uiMode = $parse($attrs.uiMode)($scope)
            parameter = $parse($attrs.itemContent)($scope)
            influence = $parse($attrs.influence)($scope)

            if (influence) {
                organizationId = influence.organizationId
                networkId = influence.channelId
            }
            else if ('ticket' in $attrs) {
                ticket =  $parse($attrs.ticket)($scope)
                organizationId = ticket.organizationId
                networkId = ticket.networkId
                // contextParentWfids = [ ticket.contextParentWfid ];
            }

            vm = _.assign(vm, {
                parameterValue,
                isWorkMode: uiMode === enums.uiMode.work,
                editValue,
                readMoreLimit: 600,
                text: {
                    readMore: $translate.instant('ReadMore'),
                    readLess: $translate.instant('Hide'),
                },
                valueCompiler: {},
            })

            getParameterValue()

            function editValue() {
                let promise
                if (parameter.dataFormat === enums.dataFormat.image) {
                    promise = editValueAsImage()
                }
                else if (parameter.dataFormat === enums.dataFormat.location) {
                    promise = editValueAsLocation()
                }
                else if (parameter.dataFormat === enums.dataFormat.country) {
                    promise = editValueAsCountry()
                }
                else {
                    promise = editValueAsText()
                }

                if (promise) {
                    return promise.then(() => {
                        doPostProcessingOfSavedValue()
                    })
                }
            }

            function editValueAsText() {
                if (parameterValue) {
                    modal.edit(parameterValue, {
                        title: parameter.text,
                        customFormSpecification: {
                            form: [
                                {
                                    key: 'value',
                                    title: ' ', // Non-break space (Alt+0160)
                                    type: parameter.dataFormat == 1 ? 'textarea' : 'text', // Multiline or not
                                },
                            ],
                        },
                    }).then(() => {
                        doPostProcessingOfSavedValue()
                        getParameterValueAndCompile()
                    })
                }
                else {
                    modal.createWithPromise({
                        type: enums.objectType.parameterValue,
                        parameterId: parameter.id,
                    }, {
                        title: parameter.text,
                        influence,
                        networkId,
                        customFormSpecification: {
                            form: [
                                {
                                    key: 'value',
                                    title: ' ', // Non-break space (Alt+0160)
                                    type: parameter.dataFormat == 1 ? 'textarea' : 'text', // Multiline or not
                                },
                            ],
                        },
                    }).then(() => {
                        doPostProcessingOfSavedValue()
                        getParameterValueAndCompile()
                    })
                }
            }
			
            function editValueAsCountry() {
                return $q((resolve) => {
                    const promises = []
                    if (!countriesDropdownItems) {
                        promises.push(apiProxy('utility.getCountriesDropdownDefinition').then((countries) => {
                            countriesDropdownItems = countries
                        }))
                    }

                    $q.all(promises).then(() => {
                        modal.edit({
                            countryId: parameterValue && parameterValue.value ? parameterValue.value : '',
                        },
                        {
                            title: parameter.text,
                            action(model) {
                                let dataOpsPromise

                                if (parameterValue) parameterValue.value = model.countryId

                                dataOpsPromise = dataOps[parameterValue ? 'update' : 'create'](parameterValue || {
                                    type: enums.objectType.parameterValue,
                                    parameterId: parameter.id,
                                    value: model.countryId,
                                })

                                dataOpsPromise.then(() => {
                                    getParameterValueAndCompile()
                                    resolve()
                                })

                                return dataOpsPromise
                            },
                            customFormSpecification: {
                                form: ['*'],
                                schema: {
                                    type: 'object',
                                    properties: {
                                        countryId: {
                                            title: ' ', // Non-break space (Alt+0160)
                                            type: 'string',
                                            'x-schema-form': {
                                                type: 'select',
                                                titleMap: countriesDropdownItems,
                                            },
                                        },
                                    },
                                    required: ['countryId'],
                                },
                            },
                        }).cancelled(() => {
                            resolve()
                        })

                    })
                })
            }
			
            function editValueAsImage() {
                // Right now only Organization logo is supported
                const org = wfObject.filter({ where: { type: enums.objectType.organization, id: organizationId } })[0]

                return $q((resolve) => {
                    modal.edit(org, {
                        title: parameter.text,
                        customFormSpecification: {
                            form: [{
                                key: 'imageUpload',
                                title: '',
                            }],
                        },
                    }).then((org) => {
                        console.log(parameterValue.value)
                        parameterValue.value = org.imageUrl
                        console.log(parameterValue.value)

                        getParameterValueAndCompile()
                        resolve()
                    }).cancelled(() => {
                        resolve()
                    })
                })
            }

            function editValueAsLocation() {
                return $q((resolve) => {
                    if (parameterValue && vm.location) {
                        modal.edit(vm.location, {
                            title: parameter.text,
                        }).then((location) => {
                            saveParameterValue(location)
                        }).cancelled(() => {
                            resolve()
                        })
                    }
                    else {
                        // Create location
                        modal.createWithPromise({ type: enums.objectType.location }, {
                            title: parameter.text,
                        }).then((location) => {
                            saveParameterValue(location)
                        }).cancelled(() => {
                            resolve()
                        })
                    }

                    function saveParameterValue(location) {
                        if (parameterValue) {
                            parameterValue.value = location.id

                            // Update parameterValue (if the parameter has a LogicLinkAddress then the value will be set where is should in backend)
                            dataOps.update(parameterValue).then(() => {
                                getParameterValueAndCompile()
                                resolve()
                            })
                        }
                        else {
                            // Create parameterValue (if the parameter has a LogicLinkAddress then the value will be set where is should in backend)
                            dataOps.create({
                                type: enums.objectType.parameterValue,
                                parameterId: parameter.id,
                                value: location.id,
                            }).then(() => {
                                getParameterValueAndCompile()
                                resolve()
                            })
                        }
                    }
                })
            }

            function getParameterValue() {
                const promises = []

                vm.parameterValue = parameterValue = _.find(wfObject.filter({ where: { type: enums.objectType.parameterValue, wffid: parameter.wfid } }), { organizationId })
                $scope.$broadcast('checkLocalFulfillment', parameter, { latestAnswerContent: parameterValue })
                $scope.$emit('checkLocalFulfillment', parameter, { latestAnswerContent: parameterValue })

                if (parameter.dataFormat === enums.dataFormat.image) {
                    vm.isImageFormat = true

                    if (parameterValue) {
                        vm.imageUrl = parameterValue.value
                        if (vm.valueCompiler && vm.valueCompiler.compile) vm.valueCompiler.compile()
                    }
                }
                else if (parameter.dataFormat === enums.dataFormat.location) {
                    vm.isLocationFormat = true

                    if (parameterValue) {
                        vm.locationId = parseInt(parameterValue.value)

                        vm.location = wfObject.get(enums.objectType.location + '-' + vm.locationId)

                        if (!vm.location) {
                            if (parameterValue.valueObjectData) {
                                vm.location = wfObject.inject(parameterValue.valueObjectData)
                            }
                            else {
                                // Does not support loading in an influence if authenticated user is not the location creator
                                dataOps.getObject({
                                    objectType: enums.objectType.location,
                                    objectId: vm.locationId,
                                }).then((location) => {
                                    vm.location = location && location.id ? location : undefined
									
                                    if (vm.valueCompiler && vm.valueCompiler.compile) vm.valueCompiler.compile()
                                })
                            }
                        }

                        if (vm.location) {
                            if (vm.valueCompiler && vm.valueCompiler.compile) vm.valueCompiler.compile()
                        }
                    }
                }
                else if (parameter.dataFormat === enums.dataFormat.country) {
                    vm.isCountryFormat = true

                    if (!countriesDropdownItems) {
                        promises.push(apiProxy('utility.getCountriesDropdownDefinition').then((countries) => {
                            countriesDropdownItems = countries
                        }))
                    }

                    $q.all(promises).then(() => {
                        let country

                        if (parameterValue && parameterValue.value) {
                            country = _.find(countriesDropdownItems, { value: parameterValue.value })

                            if (country) {
                                vm.countryName = country.name
                                if (vm.valueCompiler && vm.valueCompiler.compile) vm.valueCompiler.compile()
                            }
                        }
                    })
                }
                else {
                    vm.isTextFormat = true
                }
            }

            function getParameterValueAndCompile() {
                getParameterValue()

                if (vm.valueCompiler && vm.valueCompiler.compile) vm.valueCompiler.compile()
                // if (vm.parameterValue) {
                // 	$element.find("div.parameter-value").text(vm.parameterValue.value)
                // }
            }

            function doPostProcessingOfSavedValue() {
                let
                    organization
					
                let orgSetting
					
                let value

                if (parameter.logicLinkAddress) {

                    if (parameter.logicLinkAddress.indexOf('Organization.') === 0) {
                        orgSetting = parameter.logicLinkAddress.split('.')[1]
                        organization = wfObject.filter({ where: { type: enums.objectType.organization, id: organizationId } })[0]

                        value = parameterValue ? parameterValue.value : undefined

                        switch (orgSetting)
                        {
                            case 'Name':
                                organization.name = value
                                wfAuth.assignAuthUserValues({
                                    organizationName: value,
                                })
                                break
                            case 'RegistrationNumber':
                                organization.registrationNumber = value
                                break
                            case 'VatNumber':
                                organization.vatNumber = value
                                break
                            case 'CountryID':
                                organization.countryId = value ? parseInt(value) : undefined
                                break
                            case 'PrimaryLocationID':
                                organization.primaryLocationId = value ? parseInt(value) : undefined
                                break
                            case 'Image':
                                // Image on organization is updated using the normal create/edit form
                                break
                        }
                    }
                }
            }
        }

    }
})()
