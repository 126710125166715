import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfInfluenceViewer', wfInfluenceViewer)

    wfInfluenceViewer.$inject = []
    function wfInfluenceViewer() {
        const directive = {
            bindToController: true,
            controller: wfInfluenceViewerController,
            templateUrl(element, attrs) {
                const isPublicLayout = attrs.hasOwnProperty('publicLayout')
                if (isPublicLayout) return 'scripts/wf/influence/wfPublicInfluenceViewer.directive.html'
                else return 'scripts/wf/influence/wfInfluenceViewer.directive.html'
            },
            controllerAs: 'vm',
            link,
            restrict: 'EA',
            scope: {
                influenceId: '=',
                lookupObjectWfid: '<',
                context: '=',
                uiMode: '<',
                uiComponents: '<',
            },
        }
        return directive

        function link(scope, element, attrs) {
        }
    }
    /* @ngInject */

    wfInfluenceViewerController.$inject = ['$scope', '$element', 'dataOperationsService', '$timeout', 'wfAuth', 'modalService', 'wfObject', 'dataQuery', '$q', '$translate', '$attrs', '$location', '$stateParams', 'valueChainService']

    function wfInfluenceViewerController($scope, $element, dataOps, $timeout, wfAuth, modal, wfObject, dataQuery, $q, $translate, $attrs, $location, $stateParams, valueChainService) {
        const
            vm = this
			
        const influenceId = $scope.influenceId
			
        const isPublicLayout = $attrs.hasOwnProperty('publicLayout')
			
        let getterConditions
			
        let methodName = 'getObject'
			
        const originalTranslateCulture = $translate.use()
			
        const originalMomentLanguage = moment.locale()

        _.assign(vm, {
            //Variables
            showInfluenceHeader: vm.context && vm.context.showInfluenceHeader === false ? vm.context.showInfluenceHeader : true,
            editInfluence,
            templateId: 75,
            absUrl: $location.absUrl(),
            pickAttachedInformation,
            influenceRelatedContent: undefined,
            exportToCsv,
            exportOptions: undefined,
            getMailSendoutState,
        })

        activate()

        function activate() {
            const authUserId = wfAuth.getWorldfavorUserId()
            vm.currentAuthOrgId = wfAuth.getOrganizationId()
            clearInfluenceObjectFromCache()

            if (vm.lookupObjectWfid) {
                getterConditions = {
                    objectType: parseInt(vm.lookupObjectWfid.split('-')[0]),
                    objectId: parseInt(vm.lookupObjectWfid.split('-')[1]),
                }
                methodName = 'getObjects'
            }
            else if (isPublicLayout) {
                wfAuth.forceCulture('en-US')
                getterConditions = { hierarchyFilter: 1 } // What does 1 mean?

                $scope.$on('$destroy', () => {
                    wfAuth.resetCulture()
                })
            }

            dataOps[methodName]({
                objectType: enums.objectType.influence,
                objectId: vm.influenceId || -1,
                wrapInRelations: false,
                bypassCache: true,
                readFromCache: isPublicLayout,
                ignoreAdditionalLoadDepthLimits: isPublicLayout ? true : undefined,
                getterConditions,
            }).then((res) => {
                let
                    flatItems
					
                let conditions
					
                const innerPromises = []
					
                let influence

                if (methodName === 'getObjects') influence = res[0]
                else influence = res

                vm.influence = influence
                // influence.objectId = parseInt(res.id);
                vm.influenceChildContent = wfObject.get(influence.objectType + '-' + influence.objectId)
                vm.metadataAddActionsByKind = {}
                vm.metadataIntersectionChildrenByKind = {}

                conditions = vm.influenceChildContent.conditions

                vm.influenceRelatedContent = influence.relatedContent

                // vm.influenceChildContent.conditions.templateId = 75;

                if (influence.contextParentWfids) {
                    _.each(influence.contextParentWfids.split(','), (wfid) => {
                        innerPromises.push(dataOps.getObject(wfid))
                    })
                }

                if (conditions) { // Hierarchical UI

                    if (conditions.templateId) vm.templateId = conditions.templateId

                    // If hierarchical layout (template 75) and condition intersectionSourceWfid is not defined
                    if (vm.templateId === 75 && !conditions.intersectionSourceWfid) {
                        if (!flatItems) flatItems = dataQuery.getHierarchyAsList(vm.influenceChildContent)

                        innerPromises.push(dataOps.getSubItemsOfAll(_.filter(flatItems, (item) => {
                            return _.includes([enums.objectType.structure, enums.objectType.question, enums.objectType.measure], item.type)
                        }), enums.subItemsKind.relatedContent, { bypassCache: true }))
                    }

                    if (conditions.attachToChildrenOfSourceWfid) {
                        if (!flatItems) flatItems = dataQuery.getHierarchyAsList(vm.influenceChildContent)

                        vm.attachToChildrenOfSourceWfid = conditions.attachToChildrenOfSourceWfid

                        innerPromises.push(dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedParentsByUser, { bypassCache: true }))
                        innerPromises.push(dataOps.getSubItems(vm.attachToChildrenOfSourceWfid, enums.subItemsKind.children).then((childrenDataRelations) => {
                            vm.intersectionChildrenSourceByKind = _.map(childrenDataRelations, 'wfcid')

                            vm.metadataAddActionsByKind[enums.subItemsKind.relatedParentsByUser] = function (item) {
                                return modal.openCreatorAndPicker({
                                    showTopItemAboveTitle: true,
                                    hideListHeader: true,
                                    title: $translate.instant('AttachTo'),
                                    templateId: 65,
                                    create: false,
                                    sourceItem: vm.attachToChildrenOfSourceWfid,
                                    relationTarget: { item, kind: enums.subItemsKind.relatedParentsByUser },
                                }).modal.closed
                            }
                        }))
                    }

                    if (conditions.attachObjectTypes) {
                        if (!flatItems) flatItems = dataQuery.getHierarchyAsList(vm.influenceChildContent)

                        vm.metadataIntersectionChildrenByKind = {}
                        vm.metadataIntersectionChildrenByKind[enums.subItemsKind.relatedContentByUser] = conditions.attachObjectTypes

                        innerPromises.push(dataOps.getSubItemsOfAll(flatItems, enums.subItemsKind.relatedContentByUser, { bypassCache: true }))
                        vm.metadataAddActionsByKind[enums.subItemsKind.relatedContentByUser] = function (item) {
                            return modal.openCreatorAndPicker({
                                showTopItemAboveTitle: true,
                                title: $translate.instant('AttachInformation'),
                                objectTypes: conditions.attachObjectTypes,
                                relationTarget: { item, kind: enums.subItemsKind.relatedContentByUser },
                            }).modal.closed
                        }
                    }
                }

                if (isPublicLayout && vm.influence.organization.primaryLocationId) getPrimaryLocation()

                $q.all(innerPromises).then(() => {
                    if (!_.get(vm.context, 'disableNewInfluenceUI')) {
                        if (_.get(conditions, 'requirementPackageSettings.useNewReportingUi')) {
                            vm.useNewReportingUi = true
                            vm.newReportingUiConfig = {}
                        }
                    }
                    if (_.get(conditions, 'requirementPackageSettings.isTool')) {
                        vm.isTool = true

                        const dashboardSettings = _.get(conditions, 'requirementPackageSettings.dashboard')
                        if (dashboardSettings) {
                            vm.dashboard = {
                                hierarchyStructureId: dashboardSettings.hierarchyStructureId,
                                categoryStructureId: dashboardSettings.categoryStructureId,
                                config: {
                                    ticket: { receivingOrganizationsAndLimitedDataAccessFromInfluenceId: vm.influence.id },
                                    showDataSourceSelector: false,
                                    showViewSelector: false,
                                    showViewAdder: false,
                                    showScopeAdder: false,
                                },
                            }
                        }
                    }

                    if ($stateParams.ui === 'new') {
                        vm.useNewReportingUi = true
                        vm.newReportingUiConfig = {}
                    }

                    if ($stateParams.ui === 'old') {
                        vm.useNewReportingUi = false
                    }

                    if (vm.influence.creatorOrganizationId === vm.currentAuthOrgId || (vm.influence.networkCreatorOrganizationId && vm.influence.networkCreatorOrganizationId === vm.currentAuthOrgId)) {
                        vm.useNewReportingUi = false
                    }

                    if (!vm.useNewReportingUi && !vm.isTool) {
                        dataOps.getObject({ objectType: 52, objectId: influence.channelId, childrenLoadDepth: -1 }).then((network) => {
                            vm.isAdminMode = vm.uiMode ? vm.uiMode === enums.uiMode.admin : vm.influence.creatorOrganizationId === vm.currentAuthOrgId && network.organizationId === vm.currentAuthOrgId && (!influence.isInternal || (authUserId !== influence.userId))
                            finalize()
                        })
                    }
                    else {
                        vm.isAdminMode = false
                        finalize()
                    }

                    function finalize() {
                        if (_.get(conditions, 'requirementPackageSettings.showCsvExportForReporter') && !vm.isAdminMode) {
                            vm.showCsvExport = true
                        }

                        if (!vm.influence.isTool) {
                            if (vm.isAdminMode) {
                                vm.relatedContentHeader = $translate.instant('modules.valueChain.influence.attachedInfoForInfluence')
                            }
                            else {
                                vm.relatedContentHeader = $translate.instant('AttachedInformation') + ' ' + $translate.instant('From').toLowerCase() + ' ' + vm.influence.creatorOrganization.name
                            }
                        }

                        vm.loaded = true
                        $timeout()

                        vm.exportOptions = {
                            structure: vm.influence.childContent,
                            networkId: vm.influence.channelId,
                            allReportedDataForExport: undefined,
                            rawSubItemsAndParents: undefined,
                            itemComposites_noUserData: undefined,
                            influencesByOrgId: { [vm.influence.organizationId]: [vm.influences] },
                            organizationsById: { [vm.influence.organizationId]: vm.influence.organization },
                            categoriesEtc: [],
                            reqPackage: vm.influence.childContent,
                            isInternalPackage: vm.influence.isInternal,
                            hideShowHorizontalOption: true,
                            hideCustomIdOption: true,
                        }

                        if (isPublicLayout) {
                            $timeout(() => {
                                vm.showHierarchical = true
                                vm.publicLayoutLoaded = true
                            }, 700)
                        }
                    }
                })
            })
        }

        function editInfluence() {
            const options = {
                customFormSpecification: {
                    form: [
                        { key: 'fulfillmentDueAt' },
                        { key: 'activatedAt' },
                        { key: 'useViewModelActivationDate' },
                        { key: 'creatorUserId' },
                        { key: 'userId' },
                        {
                            key: 'mailSentCount',
                            description: `<div class="info"><div class="tooltip">
								<i class="fa fa-info-circle"></i><span class="tooltiptext">
								${$translate.instant('modules.valueChain.influence.mailSendoutStateDescription')}
								</span></div></div>`,
                        },
                        { key: 'comment' },
                    ],
                },
            }

            modal.edit(vm.influence, options).then((influence) => {
                // if (vm.room.urlRouteName === currentRouteName)
                vm.influenceHeaderCompiler.compile()
                if (vm.context && typeof vm.context.onUpdated === 'function') vm.context.onUpdated(influence)

                // else
				 	// document.location.replace(currentLocation.replace("/room/" + currentRouteName, "/room/" + vm.room.urlRouteName));

            })
        }

        function exportToCsv() {
            vm.exportOptions.allReportedDataForExport = undefined
            vm.exportOptions.rawSubItemsAndParents = undefined
            vm.exportOptions.itemComposites_noUserData = undefined

            valueChainService.exportAll(vm.exportOptions)
        }

        function clearInfluenceObjectFromCache() {
            const
                influence = wfObject.get('13-' + vm.influenceId)
				
            let influencedWithObject
				
            let relations
				
            let relationWfids
				
            let childWfids

            if (influence) {
                influencedWithObject = wfObject.get(influence.wfcid)

                if (influencedWithObject) {
                    // Ejects all nested children of the object from JSData's cache and from dataOps request cache

                    dataOps.eject(influencedWithObject.wfid)
                    childWfids = [influencedWithObject.wfid]

                    for (let i = 0; i < 10; i++) {
                        relations = wfObject.filter({ where: { type: 73, wffid: { in: childWfids } } }) // parentData1 omitted from query so that all relations will be removed
                        if (!relations.length) break

                        relationWfids = _.map(relations, 'wfid')
                        childWfids = _.map(relations, 'wfcid')
                        wfObject.ejectAll({ where: { type: 60, wfid: { in: relationWfids } } }) // Requirements
                        wfObject.ejectAll({ where: { type: 73, wfid: { in: relationWfids } } }) // Relations
                        wfObject.ejectAll({ where: { wfid: { in: childWfids } } }) // Other

                    }
                }
            }
        }

        function getPrimaryLocation() {
            vm.organizationPrimaryLocation = wfObject.get(enums.objectType.location + '-' + vm.influence.organization.primaryLocationId)
        }

        function pickAttachedInformation() {
            modal.openCreatorAndPicker({
                title: $translate.instant('AddInformation'),
                objectTypes: [enums.objectType.orgDocument],
                relationTarget: { item: vm.influence, kind: enums.subItemsKind.relatedContent },
            }).closed(() => {
                vm.influenceRelatedContent.length = 0
                Array.prototype.push.apply(vm.influenceRelatedContent, _.orderBy(vm.influence.relatedContent, 'childContent.title'))
            })
        }

        function getMailSendoutState(mailSentCount) {
            return mailSentCount === 0  ? $translate.instant('modules.mailSettings.ValueChainInvitation') : $translate.instant('modules.mailSettings.ValueChainReminder')
        }
    }
})()
