import * as types from '../actions'

const initialState = {
    apiCallsInProgress: 0,
}

function actionTypeEndsInSuccess(type) {
    return type.substring(type.length - 16) === 'API_CALL_SUCCESS'
}

export default (state = initialState.apiCallsInProgress, action): State => {
    if (action.type === types.BEGIN_DATA_API_CALL) {
        return state + 1
    } else if (actionTypeEndsInSuccess(action.type) || action.type === types.API_CALL_ERROR) {
        return state - 1
    }

    return state
}
