import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfRequirementFulfillmentManager', wfRequirementFulfillmentManager)
	
    wfRequirementFulfillmentManager.$inject = ['$parse', 'dataQuery', 'dataOperationsService', '$compile', 'requirements']

    function wfRequirementFulfillmentManager($parse, dataQuery, dataOps, $compile, requirements) {
        const directive = {
            restrict: 'A',
            controllerAs: 'requirementFulfillmentManager',
            controller: ['$scope', '$attrs', '$element', '$templateCache', '$timeout', wfRequirementFulfillmentManagerController],
			
        }
		
        return directive

        function wfRequirementFulfillmentManagerController($scope, $attrs, $element, $templateCache, $timeout) {
            const
                vm = this
				
            const itemsByWfid = {}
				
            const items = []

            /* {
					requirement: <object>,
					fulfillsLocally: <bool>,
					latestAnswerId: <number>,
					measureDataRelation: <object>,
					measure: <object>
				}
					*/
				
            let fulfillsAllLocally = false
				
            let initialCalcTimer
				
            let allAnswers
				
            let networkId

            const
                itemPrototype = {
                    requirement: undefined,
                    fulfillsLocally: undefined,
                    dataRelation: undefined,
                    content: undefined,
                    itemComposite: undefined,
                    checkLocalFulfillment,
                }
			
            vm.insert = setObjectInfo
            vm.update = updateObjectInfo
            vm.fulfillsAllLocally = checkLocalFulfillmentOnAll
            vm.getLocalFulfillmentData = getLocalFulfillmentData
            // vm.getLatestMeasureAnswer = getLatestMeasureAnswer;
            vm.addCompositeItems = addCompositeItems

            if ($attrs.wfRequirementFulfillmentManager !== '') {
                const x = $parse($attrs.wfRequirementFulfillmentManager)($scope)
                _.assign(x, vm)
                if (typeof x.onLoaded === 'function') {
                    x.onLoaded(vm)
                }
            }

            function updateObjectInfo(wfid, data, intersectionSettings) {
                if (!itemsByWfid[wfid]) return // If item is not cached then it doesn't have a requirement

                // console.log(wfid, data);
                setObjectInfo(wfid, data, intersectionSettings)
                // if (allAnswers)
                // 	allAnswers.push(data.latestAnswerDataRelation);
                // console.log(itemsByWfid[wfid]);
                $scope.$emit('requirementFulfillmentChanged', itemsByWfid[wfid])
                $scope.$broadcast('requirementFulfillmentChanged', itemsByWfid[wfid])
            }
			
            function setObjectInfo(wfid, data, intersectionSettings) {
                $timeout.cancel(initialCalcTimer)
				
                if (!itemsByWfid[wfid]) {
                    itemsByWfid[wfid] = {}
                    data.wfid = wfid
                    items.push(data)
                }

                if (data) _.assign(itemsByWfid[wfid], data)

                itemsByWfid[wfid].checkLocalFulfillment(intersectionSettings)
                // When items are being set up and setObjectInfo are executed several times in a row,
                // by using a $timeout that cancels itself the checkLocalFulfillmentOnAll method is only executed when
                // all items have been set up.
                initialCalcTimer = $timeout(() => {
                    $scope.fulfillsAllLocally = checkLocalFulfillmentOnAll()
                }, 0)
            }
			
            function checkLocalFulfillmentOnAll() {
                var item
                fulfillsAllLocally = true
				
                for (const key in itemsByWfid) {
                    if (itemsByWfid.hasOwnProperty(key)) {
                        var item = itemsByWfid[key]
                        if (item.fulfillsLocally === false) {
                            return fulfillsAllLocally = false
                        }
                    }
                }
                return fulfillsAllLocally
            }

            function getLocalFulfillmentData() {
                const
                    unfulfilled = []
					
                const fulfilled = []
					
                const unfulfilledCompositeItems = []

                for (const key in itemsByWfid) {
                    if (itemsByWfid.hasOwnProperty(key)) {
                        const item = itemsByWfid[key]
                        if (item.fulfillsLocally === true) fulfilled.push(item.dataRelation)
                        else if (item.fulfillsLocally === false) {
                            unfulfilled.push(item.dataRelation)
                            unfulfilledCompositeItems.push(item.itemComposite)
                        }
                    }
                }

                return {
                    fulfilled,
                    unfulfilled,
                    unfulfilledCompositeItems,
                }
            }

            // function getLatestMeasureAnswer(measure, organizationId, verification, networkId) {
            // 	var
            // 		allAnswersOnMeasure,
            // 		latestAnswerDataRelation
            // 	;

            // 	// TODO: Implement network visibility

            // 	if (!allAnswers) {
            // 		allAnswers = wfObject.filter({ where: {
            // 			type: 73,
            // 			parentType: enums.objectType.measure,
            // 			childType: enums.objectType.measureAnswer,
            // 			organizationId: organizationId
            // 		}});
            // 	}

            // 	allAnswersOnMeasure = _.chain(allAnswers).filter({ wffid: measure.wfid }).orderBy([ 'createdAt' ], [ 'desc' ]).value();
            // 	latestAnswerDataRelation = allAnswersOnMeasure[0];

            // 	return {
            // 		allAnswersOnMeasure: allAnswersOnMeasure,
            // 		latestAnswerDataRelation: latestAnswerDataRelation
            // 	}
            // }
			
            function addCompositeItems(items, organizationId, intersectionSettings) {
                // var
                // 	measureWfids = _.map(items, "wfid"),
                // 	latestMeasureAnswerDataRelationsByMeasureWfid = _.chain(wfObject.filter({ where: {
                // 		type: 73,
                // 		parentData1: null,
                // 		wffid: { "in": measureWfids },
                // 		parentType: enums.objectType.measure,
                // 		childType: enums.objectType.measureAnswer,
                // 		organizationId: organizationId
                // 	}})).sortBy(["wffid", "createdAt"]).groupBy("wffid").mapValues(function (dataRelations) {
                // 		return _.last(dataRelations);
                // 	}).map().keyBy("wffid").value()
                // 	// latestAnswers = _.keyBy(wfObject.filter({ where: {
                // 	// 	type: enums.objectType.measureAnswer,
                // 	// 	wfid: { "in": _.map(latestMeasureAnswerDataRelations, "wfcid") } }
                // 	// });
                // ;

                _.each(items, (itemComposite) => {
                    const
                        requirement = itemComposite.content.getRequirement(organizationId) || itemComposite.dataRelation.getRequirement(organizationId)
						
                    let newItem

                    newItem = _.assign(_.clone(itemPrototype), {
                        requirement,
                        // latestAnswerId: latestMeasureAnswerDataRelationsByMeasureWfid[itemComposite.wfid].id,
                        dataRelation: itemComposite.dataRelation,
                        content: itemComposite.content,
                        itemComposite,
                    })

                    setObjectInfo(itemComposite.wfid, newItem, intersectionSettings)
                })
            }

            function checkLocalFulfillment(intersectionSettings)
            {
                const
                    requirement = this.requirement
					
                let fulfillmentResult

                if (requirement) {
                    fulfillmentResult = requirements.checkLocalFulfillment(this.content, this.dataRelation, this.requirement, intersectionSettings, { useDetailedResult: true })
                    this.fulfillsLocally = (fulfillmentResult.fulfillmentState == enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills)
                }
                else this.fulfillsLocally = undefined

                return this.fulfillsLocally
            }
        }
    }
})()
