// @flow
import * as types from '../actions'

type State = {
  [string]: {
    pages: {|
      ids: Array<string>,
      totalElements: number,
      pageNumber: number,
      pageSize: number,
    |},
  },
}

const initialState = {}

export default (state: State = initialState, action: Object): State => {
    switch (action.type) {
        case types.ADD_PAGE: {
            const { requestId, pageId, ids, totalElements, pageNumber, pageSize } = action
            return {
                ...state,
                [requestId]: {
                    pages: {
                        ...(state[requestId] ? state[requestId].pages : {}),
                        [pageId]: {
                            ids,
                            totalElements,
                            pageNumber,
                            pageSize,
                        },
                    },
                },
            }
        }

        case types.RESET_PAGINATION_STATE:
        case types.CHANGE_ORGANIZATION:
        case types.LOGOUT:
            return {
                ...initialState,
            }

        default:
            return state
    }
}
