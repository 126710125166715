(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfScrollable', wfScrollable)

    wfScrollable.$inject = ['$compile', 'dropdownUtility', '$parse']
	
    function wfScrollable($compile, dropdownUtility, $parse) {
        const directive = {
            restrict: 'A',
            link,
            priority: 9000,
            terminal: true,
        }
        return directive

        function link(scope, element, attrs) {
            const configs = {}
            let initialScrollTopPos
            let popoverElement
            let elementTop; let elementOuterHeight
            let initialPopoverTop; let newPopoverTop
            _.assign(configs, {
                defaultConfig: {
                    autoHideScrollbar: false,
                    theme: 'dark-3',
                    advanced: {},
                    setHeight: true,
                    axis: 'y',
                    scrollInertia: 150,
                    // contentTouchScroll: 5,
                    documentTouchScroll: true,
                    scrollEasing: 'easeOut',
                    keyboard: {
                        enable: true,
                        scrollAmount: 3,
                        scrollType: 'stepless',
                    },
                    scrollButtons: {
                        enable: true,
                        scrollAmount: 'auto',
                        scrollType: 'stepped',
                    },
                    mouseWheel: {
                        scrollAmount: 150,
                    },
                    callbacks: {
                    },
                },
                objectViewerConfig: {
                    advanced: {
                        updateOnContentResize: true, // caused the dropdown to close when a question is answered (onScrollStart callback called all the time)
                    },
                    setLeft: '5px',
                    callbacks: {
                        // 1. Make the popover follow the triggered element (metadata element) when scrolled
                        // 2. Close the dropdown when scroll started
                        whileScrolling() {
                            const delta = initialScrollTopPos - this.mcs.top
                            if (popoverElement && popoverElement.length) {
                                newPopoverTop = initialPopoverTop - (initialScrollTopPos - this.mcs.top)
                                popoverElement.css('top', newPopoverTop)

                                //Show/Hide Popover after scrolling
                                if (newPopoverTop < elementTop) popoverElement.popover('hide')
                                else if (newPopoverTop > elementTop + elementOuterHeight) popoverElement.popover('hide')
                            }
                        },
                        onScrollStart() {
                            popoverElement = $('div.popover')
                            elementTop = element.offset().top
                            elementOuterHeight = element.outerHeight(true)
                            if (popoverElement && popoverElement.length) {
                                initialPopoverTop = parseInt(popoverElement.css('top'))
                            }

                            initialScrollTopPos = this.mcs.top
                            dropdownUtility.close()
                        },
                    },
                },
            })

            scope.wfScrollConfig = getCorrectConfig()
            setAttributes()
			
            function setAttributes() {
                // prevent recursion
                element[0].removeAttribute('wf-scrollable')
                element[0].setAttribute('ng-scrollbars', null)
                element[0].setAttribute('ng-scrollbars-config', 'wfScrollConfig')
				
                // process the directives we added
                $compile(element)(scope)
            }

            function getCorrectConfig() {
                const configFromAttrs = $parse(attrs.wfScrollable)(scope)
                if (attrs.wfScrollable == 'objectViewerConfig') {
                    return _.merge(configs.defaultConfig, configs.objectViewerConfig)
                }
                else if (typeof configFromAttrs === 'object') {
                    return _.merge(configs.defaultConfig, configFromAttrs)
                }
                else {
                    return configs.defaultConfig
                }
            }
        }
    }
})()
