import * as app from './appSelector'
import * as data from './dataSelector'
import * as pagination from './paginationSelector'
import * as graph from './graphSelector'
import * as product from './productSelector'

export default {
    ...app,
    ...data,
    ...pagination,
    ...graph,
    ...product,
}
