import * as enums from '@worldfavor/constants/enums'

angular
    .module('theme.core.notifications_controller', ['wf.data'])
    .controller('NotificationsController', ['$rootScope', '$scope', '$filter', 'dataOperationsService', '$timeout', '$translate', 'wfTranslate', '$state', 'wfAuth',
        function($rootScope, $scope, $filter, dataOps, $timeout, $translate, wfTranslate, $state, wfAuth) {
            'use strict'
            const
                vm = this

            vm.notifications = []
            vm.loadingNotifications = true

            vm.eventSentence = function (item) {
                let
                    output = ''
                    
                const objType = item.objectType
                    
                const eventType = item.eventType

                if (objType === enums.objectType.influence) {
                    if (eventType === enums.eventType.fulfill) {
                        output = $translate.instant('modules.notifications.eventSentences.influenceFulfilled') + ' ' + item.childContent.organization.name
                    }
                    else if (eventType === enums.eventType.unfulfill) {
                        output = $translate.instant('modules.notifications.eventSentences.influenceUnfulfilled') + ' ' + item.childContent.organization.name
                    }
                }

                return output

                // return wfTranslate.instant('MAP_EventType', { type: item.eventType }) + ' ' + wfTranslate.instant('MAP_ObjectType', { type: item.objectType });
            }

            vm.setSeen = function(item, $event) {
                $event.preventDefault()
                $event.stopPropagation()
                item.seen = true
                dataOps.setNotificationToSeen(item)
            }

            vm.setUnseen = function(item, $event) {
                $event.preventDefault()
                $event.stopPropagation()
                item.seen = false
            }

            vm.navigateToItem = function (item) {
                switch (item.childContent.type)
                {
                    case 73: // DataRelation
                        $state.go('dataRelation', { idPath: item.childContent.id })
                        break
                    case 71: // Structure
                        $state.go('structure', { idPath: item.childContent.id })
                        break
                    case 52: // Network
                        $state.go('network', { idPath: item.childContent.id })
                        break
                    case 13: // Influence
                        $state.go('influence', { idPath: item.childContent.id })
                        break
                }
                return false
            }

            vm.contentLoaded = null
            vm.setSeenAll = function($event) {
                if (vm.contentLoaded != null) return

                // vm.contentLoaded = false;
                // $event.preventDefault();
                // $event.stopPropagation();
                // if (!_.every(vm.notifications, { seen: true }))
                // {
                // 	angular.forEach(vm.notifications, function(item) {
                // 		item.seen = true;
                // 	});
                // }

                vm.loadContent()
            }

            vm.loadContent = function () {
                let
                    wfids
			
                // console.log("load content");
                if (!vm.notifications.length) {
                    vm.contentLoaded = true
                    return
                }
			
                wfids = _.chain(vm.notifications).map('wfcid').uniq().value()
								
                // Load content of each notification
                dataOps.getObjects(wfids).then(() => {
                    $timeout(() => {
                        vm.notifications = _.filter(vm.notifications, (item) => {
                            return item.childContent && item.childContent.childContent
                        })

                        vm.contentLoaded = true
                        dataOps.setAllNotificationsToSeen()
                        vm.unseenCount = 0
                    })
                })
                // _.forEach(vm.notifications, function (item) {
                // 	if (!item.childContent) {
                // 		// console.log("load it");
                // 		dataOps.getObject({
                // 			objectId: item.objectId,
                // 			objectType: item.objectType
                // 		}).then(function (res) {
                // 			i++;
                // 			if (res.id > 0)
                // 				item.loaded = true;

                // 			if (i === count) {
                // 				finishContentLoading();
                // 			}
                // 		});
                // 	}
                // 	else
                // 	{
                // 		i++;
                // 		item.loaded = true;

                // 		if (i === count) {
                // 			finishContentLoading();
                // 		}
                // 	}
                // })
            }

            // $scope.$watch('vm.notifications', function(notifications) {
            // 	vm.unseenCount = $filter('filter')(notifications, {
            // 		seen: false
            // 	}).length;
            // }, true);

            // $rootScope.$on('auth0.loginSuccess', onAuthenticated);
            wfAuth.onAuthenticated(onAuthenticated)
		
            $rootScope.$on('auth0.logout', onLogout)
            // if ($rootScope.isLoggedIn)
            // {
            // 	onAuthenticated();
            // }

            function onAuthenticated(event, authContext, reauthenticated, userInfo, token) {
                // Sometimes, values in localStorage is not set instantly so to be sure
                // that the bearer token is set we wait a little before doing anything else

                vm.loadingNotifications = true
                vm.notifications = []
                vm.contentLoaded = null

                // console.info("on authenticated")
                // setTimeout(function () {
                dataOps.getObject({
                    objectId: 43050,
                    objectType: 73,
                }).then((res) => {
                    $timeout((params) => {
                        // console.info("map children", res.childContent.childs.length);
                        const notifications = _.map(res.childContent.childs, 'childContent')
                        // console.log(notifications);
                        vm.notifications = notifications
                        // console.info(vm.notifications);
                        vm.loadingNotifications = false

                        vm.unseenCount = $filter('filter')(vm.notifications, {
                            seen: false,
                        }).length
                    })
                })
                // }, 500);
            }

            function onLogout() {
                vm.notifications = []
            }
        },
    ])
