(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfChart', wfChart)

    wfChart.$inject = []
	
    function wfChart() {
        const directive = {
            restrict: 'E',
            scope: {
                percentage: '=',
                color: '=',
                colorBase: '=',
                header: '=',
                influencesQuery: '=',
                influencesItem: '=',
                influencesQueryOrganizations: '=',
                influencesOrganizationsIds: '=',
                influencesContextparentsWfids: '=',
                influences: '=',
            },
            //template: '<div><span ng-show="vm.percentage > -1" style="color:{{item.childContent.conditions.color1}}">{{vm.fulfilledPercentage}}%</span><canvas width="150" height="150" canvas-chart="vm.chartData" options="{percentageInnerCutout: 73, animateRotate: false, showTooltips: false}" data-type="\'Doughnut\'"></canvas></div><p>{{vm.header}}</p>',
            templateUrl: 'scripts/wf/visualization/wfChart.directive.html',
            controller: ['$scope', '$element', '$interval', 'dataModeller', '$compile', 'd3Utility', 'wfObject', '$timeout', wfChartController],
            controllerAs: 'vm',
        }
        return directive
    }

    function wfChartController($scope, $element, $interval, dataModeller, $compile, d3Utility, wfObject, $timeout) {
        const vm = this
        const setValues = function () {
            vm.colorBase  = vm.colorBase  || $scope.colorBase
            vm.color      = vm.color  || $scope.color
            vm.percentage = typeof vm.percentage !== 'undefined' ? vm.percentage : $scope.percentage
            vm.header     = vm.header     || $scope.header
            //console.log(vm.percentage);
            if (vm.colorBase)
            {
                vm.color = d3Utility.color20(vm.colorBase)
            }
            else vm.color = vm.color || '#99999'
				
            vm.color = vm.color.replace(/\'/g, '')
            if (vm.chartObj) {
                if (vm.percentage > 0) {
                    vm.chartObj.data.datasets[0].data[0] = parseInt(vm.percentage)
                    vm.chartObj.data.datasets[0].data[1] = 100 - parseInt(vm.percentage)
                }
                else {
                    vm.chartObj.data.datasets[0].data[0] = 0
                    vm.chartObj.data.datasets[0].data[1] = 100
                }
                // $scope.chart.update();
            }
            else {
                vm.chartData = {
                    datasets: [
                        {
                            data: vm.percentage > 0 ? [parseInt(vm.percentage), 100 - parseInt(vm.percentage)] : [0, 100],
                            backgroundColor: [vm.color, '#F3F3F3'],
                            borderWidth: [0, 0],
                            hoverBackgroundColor: [vm.color, '#F3F3F3'],
                            hoverBorderWidth: [0, 0],
                        },
                    ],
                }
            }
			
            vm.chartOptions = vm.chartOptions || {
                cutoutPercentage: 77,
                tooltips: {
                    enabled: false,
                },
                animation: {
                    animateRotate: true,
                },
                responsive: false,
            }
			
            // datasets: [
            // 	{
            // 		data: [ parseInt(vm.percentage), 100 - parseInt(vm.percentage) ],
            // 		backgroundColor: [ vm.color, '#F3F3F3' ]
            // 	}
            // ]
			
            // [ { color: vm.color, value: parseInt(vm.percentage) }, { color: '#F3F3F3', value: 100 - parseInt(vm.percentage) } ];
            // vm.chartData = [ { color: vm.color, value: parseInt(vm.percentage) }, { color: '#F3F3F3', value: 100 - parseInt(vm.percentage) } ];

            if (vm.chartObj) {
                vm.chartObj.update()
            }
            else $compile($element.contents())($scope)
        }

        if ($scope.influencesOrganizationsIds && $scope.influencesItem)
        {
            $scope.$watchCollection('influencesOrganizationsIds', (val) => {
                update()
            })
        }

        if ($scope.influencesContextparentsWfids && $scope.influencesItem)
        {
            $scope.$watchCollection('influencesContextparentsWfids', (val) => {
                update()
            })
        }

        setValues()

        function update() {
            let
                query
				
            const orgIds = $scope.influencesOrganizationsIds
				
            let fulfilledCount = 0
				
            let unfulfilledCount = 0
				
            const fulfilledOrgMap = {}
				
            const unfulfilledOrgMap = {}
				
            let orgId
				
            let useContextParents = false

            if ($scope.influences) {
                vm.influences = _.chain($scope.influences).each((influence) => {
                    if (influence.objectId === $scope.influencesItem.id && !!~orgIds.indexOf(influence.organizationId)) {
                        orgId = influence.organizationId
                        if (influence.fulfilled) {
                            if (!(orgId in fulfilledOrgMap)) {
                                fulfilledOrgMap[orgId] = null
                                fulfilledCount++
                            }
                        }
                        else {
                            if (!(orgId in unfulfilledOrgMap)) {
                                unfulfilledOrgMap[orgId] = null
                                unfulfilledCount++
                            }
                        }
                    }
                }).value()

                vm.total = fulfilledCount + unfulfilledCount
                vm.fulfilled = fulfilledCount
                vm.notFulfilled = unfulfilledCount
            }
            else {
                query = {
                    where:
					{
					    type: 13,
					    objectId: $scope.influencesItem.id,
					    objectType: 71,
					    organizationId:
						{
						    in: $scope.influencesOrganizationsIds,
						},
					},
                }

                if ($scope.influencesContextparentsWfids instanceof Array && $scope.influencesContextparentsWfids.length) {
                    query.where.contextParentWfids = { in: $scope.influencesContextparentsWfids }
                    useContextParents = true
                }
				
                vm.influences = wfObject.filter(query)
                if (useContextParents) {
                    vm.fulfilled = _.chain(vm.influences).filter({ fulfilled: true }).uniqBy((influence) => {
                        return influence.organizationId + '|' + influence.contextParentWfids
                    }).value().length
					
                    vm.notFulfilled = _.chain(vm.influences).filter({ fulfilled: false }).uniqBy((influence) => {
                        return influence.organizationId + '|' + influence.contextParentWfids
                    }).value().length
                }
                else {
                    vm.fulfilled = _.chain(vm.influences).filter({ fulfilled: true }).uniqBy('organizationId').value().length
                    vm.notFulfilled = _.chain(vm.influences).filter({ fulfilled: false }).uniqBy('organizationId').value().length
                }

                vm.total = vm.influences.length
            }

            vm.percentageFulfilled = vm.fulfilled / (vm.total * 1.0) * 100
            vm.part = vm.fulfilled

            if (!$scope.color) vm.colorBase  = $scope.influencesItem.wfid + $scope.influencesItem.title

            vm.percentage = Math.round(vm.percentageFulfilled) || 0
            vm.header     = $scope.influencesItem.title

            setValues()
        }
        //var selectedParentStructureId;
        //var compileDirective = function () {
        //	var influences = DS.filter('wfObject', {where:{type: 13, objectId: $scope.item.childContent.conditions.objectId, objectType: 71, organizationId:{ 'in': dataModeller.getArrayFromFilter('childId', { where: { type: 73, childType: 101, parentType: 71, parentId: selectedParentStructureId } }) } } })
        //	var totalCount = influences.length;
        //	var fulfilledCount = _.filter(influences, { fulfilled: true }).length, notFulfilledCount = 0, overdueCount = 0;

        //	if (totalCount != vm.oldTotalCount || fulfilledCount != vm.oldFulfilledCount)
        //	{
        //		fulfilledCount = _.filter(influences, { fulfilled: true }).length;
        //		notFulfilledCount = _.filter(influences, { fulfilled: false }).length;
        //		overdueCount = _.filter(influences, { fulfilled: false }).length;
        //		vm.fulfilledPercentage = Math.floor(fulfilledCount / (totalCount * 1.0) * 100);

        //		vm.chartData = [{color:$scope.item.childContent.conditions.color1,value: fulfilledCount },{color:'#F3F3F3',value:notFulfilledCount}];
        //		$scope.collection.showItem($scope.item)

        //		$compile($element.contents())($scope);
        //	}

        //	if (!(vm.fulfilledPercentage > -1))
        //	{
        //		$scope.collection.hideItem($scope.item);
        //	}
        //}
        //vm.DS = DS;
        //vm.fulfilledPercentage = null;
        //vm.oldTotalCount = -1;
        //vm.oldFulfilledCount = -1;
		
        //vm.chartData = [{color:'',value: 0 },{color:'#F3F3F3',value:1}];

        ////console.log($scope.item.childContent.wfid, $scope.item.childContent.conditions.comHubKey1, $scope.item.childContent.conditions.comHubProperty1);

        //comHub.onValueChange($scope, $scope.item.childContent.conditions.comHubKey1, $scope.item.childContent.conditions.comHubProperty1, function (value) {
        //	//console.log(value);
        //	selectedParentStructureId = value
        //	compileDirective();
        //})

        //$scope.$on("wfObjectModified", function () {
        //	var structures
        //	structures = vm.DS.filter('wfObject', {type: 71, id: $scope.item.childContent.conditions.objectId });

        //	if (structures.length)
        //		vm.header = structures[0].title;

        //	compileDirective();
        //});
    }

})()
