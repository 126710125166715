import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { FormattedMessage } from 'react-intl'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import classNames from 'classnames'
import GroupedAvatars from './GroupedAvatars'
import { useDialogState } from '@worldfavor/hooks'
import ContributorPickerDialog from '@worldfavor/components/ContributorPickerDialog'

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    avatars: {
        display: 'flex',
    },
    label: {
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: hexToRgbA(theme.palette.common.white, 0.87),
        marginBottom: 10,
        letterSpacing: '0.15em',
    },
    addButton: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 10,
    },
    tooltip: {
        margin: 0,
        fontSize: 12,
        backgroundColor: Colors.blackDark,
        '& .tooltip-arrow:before': {
            borderColor: Colors.blackDark + ' transparent transparent transparent !important',
        },
    },
})

const Contributors = ({
    classes,
    avatarSize,
    fontColor,
    align,
    maxAvatar,
    onSaveContributors,
    contributors,
    colleagues,
}) => {
    const [open, openDialog, closeDialog] = useDialogState(false)
    const [saving, setSaving] = useState(false)

    async function _onSave(event, contributorsToAdd, contributorsToRemove) {
        try {
            setSaving(true)
            await onSaveContributors(contributorsToAdd, contributorsToRemove)
            closeDialog()
        } catch (e) {
            // TODO handle error
        } finally {
            setSaving(false)
        }
    }

    return (
        <div className={classes.root}>
            <span className={classes.label} style={{ color: fontColor }}>
                <FormattedMessage id={'networkExchanges.contributors'} />
            </span>
            <div className={classes.avatars}>
                <GroupedAvatars
                    avatars={contributors}
                    maxAvatar={maxAvatar}
                    align={'right'}
                    overlapAvatars
                />
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={<FormattedMessage id={'networkExchanges.addContributors'} />}
                    placement="top"
                >
                    <IconButton
                        onClick={openDialog}
                        className={classNames(classes.addButton, classes[`${align}AvatarAlignment`])}
                        style={{ width: avatarSize, height: avatarSize, fontSize: 14, color: fontColor }}
                    >
                        <i className={'fas fa-users-cog'} />
                    </IconButton>
                </Tooltip>
            </div>
            { open && (
                <ContributorPickerDialog
                    open={open}
                    onCancel={closeDialog}
                    onSave={_onSave}
                    contributors={contributors}
                    colleagues={colleagues}
                    saving={saving}
                />
            )}
        </div>
    )
}

Contributors.defaultProps = {
    avatarSize: 40,
    align: 'left',
    onSaveContributors: () => {},
}

export default withStyles(styles)(Contributors)
