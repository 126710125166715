// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import type { Item } from '@worldfavor/types'

import '@worldfavor/less/components/item-summary-stats-container.less'

class StatsHeader extends React.PureComponent<*> {
    render() {
        const { value = 0, title, translationId, style } = this.props
        return (
            <div styleName="wf-item-statistics" style={style}>
                <div styleName="total-item">
                    <div styleName="total-value">{ value }</div>
                    <div styleName="total-title">
                        {
                            title || <FormattedMessage id={translationId} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

type Props = {
  subItems: Array<Item>,
}

class ItemSummaryStats extends React.PureComponent<Props> {

  static defaultProps = {
      subItems: [],
  }

  _getStats = (): Array<{ value: number, type: string }> => {
      const { subItems } = this.props
      const groupedStats = subItems.reduce((acc, item) => ({
          ...acc,
          [item.type]: (acc[item.type] || 0) + 1,
      }), {})
      return Object.keys(groupedStats)
          .map(key => ({
              value: groupedStats[key],
              type: key,
          }))
  }

  render() {
      return (
          <div styleName="container">
              {
                  this._getStats().map((stats, index) => (
                      <StatsHeader
                          key={`stats-${index}`}
                          value={stats.value}
                          translationId={`modules.itemTypes.${stats.type}.${stats.value > 1 ? 'p' : 's'}`}
                      />
                  ))
              }
          </div>
      )
  }
}

export default ItemSummaryStats
