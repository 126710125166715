import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'
import Routes from './reactRoutes'
import { setEnforcedCulture, setToken, logout } from './actions/appActions'
import { setUserInfo, setAuthUser } from './actions/appThunk'
import { registerService } from './AngularServices'

let componentInstantiated = false

/**
 * This component holds the Angular compatibility layer.
 *
 * The component renders a div that is used to bootstrap angular in,
 * and renders the remaining children in it (thus the 'wrapper').
 * In order to communicate between Angular and React to support
 * authentication and routing, the Angular configuration is enhanced
 * with a compatibility layer for the routing and a communication layer
 * for the authentication.
 */
const AngularWrapper = ({ children, onAuthenticated, history, location, setEnforcedCulture, setUserInfo, setToken, setAuthUser, logout }) => {
    const [authenticated, setAuthenticated] = useState(false)
    const [authenticationError, setAuthenticationError] = useState(undefined)
    const angularContainer = useRef(null)

    useEffect(() => {

        if (componentInstantiated) {
            throw new Error(`AngularWrapper can only be used once. Only keep one instance of this component, probably the shallowest in the DOM.`)
        }

        componentInstantiated = true

        angular.module('wf')
            .config(['$stateProvider', '$urlMatcherFactoryProvider', ($stateProvider, $urlMatcherFactoryProvider) => {

                // Routing compatibility layer (1)
                // React route definition in angular ui-router
                const valToString = val => val ? val.toString() : val
                $urlMatcherFactoryProvider.type('nonURIEncoded', {
                    encode: valToString,
                    decode: valToString,
                    is: () => true,
                })

                Routes.forEach(({ name, path, exact }) => {
                    const url = `${path}${exact ? '' : '{path:nonURIEncoded}'}`
                    $stateProvider.state(name, { url })
                })

            }])
            .run(['$rootScope', '$location', 'wfAuth', ($rootScope, $location, wfAuth) => {

                // Routing compatibility layer (2)
                // hijack on $stateChangeSuccess to update react-router history
                // on location change
                $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState) => {
                    const realUrl = $location.url()
                    const pathName = history.location.pathname + history.location.search
                    if (pathName !== realUrl) {
                        if (fromState.parent || toParams.pushToHistory) {
                            history.push(realUrl)
                        }
                        else {
                            history.replace(realUrl)
                        }
                    }
                })

                $rootScope.$on('wfAuth.cultureChange', (event, { enforcedCulture }) => {
                    setEnforcedCulture(enforcedCulture)
                })

                $rootScope.$on('wfAuth.userInfoChange', (event, { userInfo }) => {
                    setUserInfo(userInfo)
                })

                $rootScope.$on('wfAuth.tokenChange', (event, { token }) => {
                    setToken(token)
                })

                $rootScope.$on('wfAuth.setAuthUser', (event, { authUser }) => {
                    setAuthUser(authUser)
                })

                $rootScope.$on('auth0.logout', logout)

                // Authentication communication layer
                $rootScope.onAuthenticated = () => {
                    setAuthenticationError(undefined)
                    setAuthenticated(true)
                    onAuthenticated && onAuthenticated()
                }

                $rootScope.$on('auth0.loginError', error => setAuthenticationError(error))

                registerService('wfAuth', wfAuth)
            }])
        angular.bootstrap(angularContainer.current, ['wf'])
    }, [])

    const isReactPage = Routes.find(route => matchPath(location.pathname, route))
    const isSetPasswordPage = location.pathname.includes('/account/activate/')
    const isDownloadFilePage = location.pathname.includes('/file')

    if (isDownloadFilePage) {
        return (
          <div id="angular-root" ref={angularContainer} ng-controller="WfMainController">
              <ui-view id="wrap" />
          </div>
        )
    }

    return (
      <div id="angular-root" ref={angularContainer} ng-controller="WfMainController">
            <ng-include src="'views/layout/header.html'" />

            <div id="wrapper" style={isReactPage && authenticated ? { display: 'none' } : {}}>
                <div id="layout-static">
                    <div className="static-content-wrapper" style={(isReactPage ? { paddingBottom: 0 } : {})}>
                        <div className={`static-content ${authenticated || isSetPasswordPage || authenticationError ? '' : 'loader-wf height-500'}`}>
                            <ui-view id="wrap" className="mainview-animation animated" />
                        </div>
                    </div>
                </div>
            </div>

            { children }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setEnforcedCulture,
        setUserInfo,
        setToken,
        setAuthUser,
        logout,
    }, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(AngularWrapper))
