(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfCollection', wfCollection)

    wfCollection.$inject = ['$translate', '$compile']
	
    function wfCollection($translate, $compile) {
        const directive = {
            scope: {
                items: '=', // Directly fed array that the directive should display
                layout: '=',
                kind: '@', // The kind of items that is displayed in the directive. Determines how CRUD is handled. Used together with the 'parent' parameter below
                parent: '=', // The parent item, if any, of the array. Used together with the 'kind' property above
                settings: '=', // Settings for the collection
                conditions: '=',
                extraSettings: '=',
                context: '=',
                filter: '=',
                exclude: '=', // Used to define exclution options, what items to exclude from the list. Example: { values: [ '71-123' ], lookup: 'wfcid' }
                query: '=', // Optional query to use when querying JSData
                activateIf: '=',
                queryUnwatched: '=',
                queryGetter: '=',
                queryGetterArgs: '=',
                emptyState: '=',
            },
            restrict: 'E',
            // templateUrl: "scripts/wf/collection/collection.directive.html",
            // template: '<div ng-include="\'scripts/wf/collection/collection.directive.html\'" class="clearfix inner"></div>',
            // template: "<div>HEJ</div>",
            controllerAs: 'vm',
            controller: ['$scope', '$element', 'dataOperationsService', 'apiProxy', 'wfObject', '$attrs', 'dataModeller', 'modalService', wfCollectionController],
            link,
            // transclude: true
            // compile: compile
        }
        return directive
        // function compile() {
			
        // }

        function wfCollectionController($scope, $element, dataOperations, apiProxy, wfObject, $attrs, dataModeller, modalService) {
            const
                vm = this
				
            let loadChildContent = false
				
            const watches = {
                parentItem: null,
                itemArray: null,
                items: null,
                activateIf: null,
            }
				
            const subListKind = parseInt($scope.kind)
				
            let isotopeContainer
				
            const hiddenItemsByWfid = {}
				
            let settings
				
            let conditions
				
            let parent
				
            const extraSettings = $scope.extraSettings || {}
				
            const triggerLoadAttr = $element.attr('trigger-load')
				
            const shouldTriggerLoad = typeof triggerLoadAttr !== 'undefined' && (triggerLoadAttr === '' || triggerLoadAttr)
				
            let isotopeRedrawTimeout
				
            let shouldQuery = true
				
            let excludeOptions
				
            let emptyStateOptions
			
            // console.log("controller" + $scope.$id);
            vm.watches = watches
            //console.log($rootScope);
            //$scope._            = $rootScope._;
            //$scope.dataModeller = $rootScope.dataModeller;
            //$scope.dataOps      = $rootScope.dataOps;
            //$scope.root         = $rootScope.$rootScope;

            //if ($scope.items)
            //{
            //	vm.items = $scope.items;
            //}
            //else
            //{
            //	if ($scope.parent && $scope.kind)
            //	{
            //		if ($scope.kind == 1 && !$scope.parent.childs)
            //		{
            //			vm.items = $scope.parent.childs = [];
            //		}
            //	}
            //	else
            //	{
            //		vm.items = [];
            //	}
            //}
			
            vm.activate = activate
            // activate();
			
            function activate() {
                // console.log("ACTIVATE");
				
                vm.items = null
                excludeOptions = $scope.exclude
                $scope.collection = vm.collection = {
                    //openPicker: openPicker,
                    create, // Triggers the creation modal overlay
                    update, // Updates an item in the array
                    remove, // Removes an item from the array
                    add,    // Adds an item to the array
                    load: maybeLoadItems, // Requires scope parameters 'kind' and 'parent'
                    items: vm.items,
                    settings: {},
                    conditions: conditions = $scope.conditions,
                    kind: subListKind,
                    parent: parent = $scope.parent,
                    redrawGrid,

                    showItem,
                    hideItem,
                    toggleItem,
					
                    //reloadItemChildren: reloadItemChildren
                    //reloadItemChildContent: reloadItemChildContent
                }
						
                //vm.openPicker = openPicker;
                vm.create = create
                vm.itemWidth = itemWidth
                vm.itemPaddingTop = itemPaddingTop
                vm.itemCssClass = itemCssClass
                vm.isItemHidden = function (item) { redrawGrid(); return hiddenItemsByWfid[item.wfid] }
                vm.layout = angular.extend({
                    grid: true,
                    list: false,
                    gridColumns: undefined,
                    gridRows: undefined,
                    itemsPerPage: 10,
                }, $scope.layout)
                vm.emptyState = emptyStateOptions = $scope.emptyState
					
                //vm.setFilteredId = function (id) {
                //	//vm.selectedParentId = id;
                //	//console.log(id);
                //}

                //vm.filterItems = function (item) {
                //	//return true;
                //	var i;

                //	if (item.childContent && (i = item.childContent.parents.length))
                //	{
                //		while (i--)
                //		{
                //			if (item.childContent.parents[i].wffid == vm.selectedParentId)
                //				return true;
                //		}
                //	}
                //}
				
                angular.forEach(vm.items, (item) => {
                    item._pos = {}
                })

                // Option 1: Directive is assigned a Parent item and Kind
                //  -> If parent is null then watch it until it loads then start watching the items array with specified kind. If parent has truthy value then start watching the array immediately.

                if ($scope.parent == null)
                {
                    // Wait for parent to load
                    // console.log("Wait for parent to load");

                    watches.parentItem = $scope.$watch('parent', () => {
                        if ($scope.parent)
                        {
                            watches.parentItem() // Destroy watch

                            watchCollection() // Start watching itemArray
                        }
                    })
                }
                else if ($scope.parent)
                {
                    //console.info($scope.parent);
                    //console.log("Parent already exists");

                    //setTimeout(function () {
                    watchCollection()
                    //}, 1000);
                }
				
                // console.log("no parent")

                // Option 2: 
                $scope.$on('$destroy', () => {
                    for (const key in watches) {
                        if (watches[key]) watches[key]()
                    }
                })

                $scope.$on('itemToolsActionExecuted', (event, action, item, dataRelation) => {
                    if (dataRelation) item = dataRelation

                    if (action === 'delete' && remove(item)) {
                        event.stopPropagation()
                    }
                })
            }

            function watchCollection()
            {
                // console.log("watchCollection");
                let collectionQuery
                let filterButtonsQuery

                vm.collection.settings   = settings = ($scope.settings || $scope.parent.settings || {})
                vm.collection.conditions = conditions = ($scope.conditions || $scope.parent.conditions || {})
                vm.collection.parent     = parent = $scope.parent
				
                if (!$scope.layout && settings.childrenLayoutMode)
                {
                    if (settings.childrenLayoutMode === 1)
                    {
                        vm.layout.list = false
                        vm.layout.grid = true
                    }
                    else if (settings.childrenLayoutMode === 2)
                    {
                        vm.layout.grid = false
                        vm.layout.list = true
                    }
                }
				
                if (!vm.layout.grid && !vm.layout.list) vm.layout.list = true

                vm.filterWfid = parent.wfid // Fix

                // console.log($scope.query);
                if ($scope.query)
                {
                    // console.log("Watch query");
                    watches.query = $scope.$watch('query', () => {
                        shouldQuery = true
                        // console.log("Query changed");
                        collectionQuery = $scope.query
                    })
                }
                else if ($scope.queryGetter)
                {
                    // console.log($scope.queryGetter, $scope.queryGetterArgs);
                    collectionQuery = $scope.queryGetter(...$scope.queryGetterArgs)
                    // console.log($scope.queryGetterArgs);
                    // if (typeof $scope.queryUnwatched === 'string')
                    // {
                    // 	collectionQuery = $scope.queryUnwatched;
                    // }
                    // else
                    // 	collectionQuery = $scope.queryUnwatched;
					
                }
                else if ($scope.items)
                {
                    watches.items = $scope.$watch('items', (items) => {
                        vm.getItems()
                    })
                }
                else
                {
                    if (subListKind === 1) // Children
                    {
                        if (conditions.dataRelation)
                        {
                            collectionQuery = {
                                where: {
                                    wffid: $scope.parent.wfid,
                                    parentData1: null,
                                },
                                orderBy: ['type', 'order', 'id'],
                            }
                        }
                        else
                        {
                            collectionQuery = {
                                where: {
                                    wffid: $scope.parent.wfid,
                                    parentData1: null,
                                },
                                orderBy: ['type', 'order', 'id'],
                            }
                        }
                    }
                    else if (subListKind === 2) // Parents
                    {
                        collectionQuery = {
                            wfcid: $scope.parent.wfid,
                            parentData1: null,
                        }
                    }
                    else if (subListKind === 4) // Related content
                    {
                        collectionQuery = {
                            where: {
                                wffid: $scope.parent.wfid,
                                parentData1: 1,
                            },
                            orderBy: ['type', 'order', 'id'],
                        }
                    }
                }

                // if (settings.filterButtonsSourceType === 1) // Use some sub list of the children as source
                // {
                // 	if (parseInt(settings.filterButtonsSourceValue) === 1) // Children, this block might not work yet
                // 	{
                // 		filterButtonsQuery = function (item) {
                // 			var x, y, tempX, tempY;

                // 			if (item.type === settings.filterButtonsObjectType && item.parents && (x = item.parents.length) > 0)
                // 			{
                // 				while (x--)
                // 				{
                // 					tempX = item.parents[x];
                // 					if (conditions.objectTypes.indexOf(tempX.parentType) !== -1 && tempX.parentContent && (y = tempX.parentContent.childs.length) > 0)
                // 					{
                // 						while (y--)
                // 						{
                // 							tempY = tempX.parentContent.childs[y];
                // 							if (tempY.wfcid === parent.wfid)
                // 								return true;
                // 						}
                // 					}
                // 				}
                // 			}
                // 		};
                // 	}
                // 	else if (parseInt(settings.filterButtonsSourceValue) === 2) // Parents
                // 	{
                // 		filterButtonsQuery = function (item) {
                // 			var x, y, tempX, tempY;

                // 			if (item.type === settings.filterButtonsObjectType && item.childs && (x = item.childs.length) > 0)
                // 			{
                // 				while (x--)
                // 				{
                // 					tempX = item.childs[x];
                // 					if (conditions.objectTypes.indexOf(tempX.childType) !== -1 && tempX.childContent && (y = tempX.childContent.parents.length) > 0)
                // 					{
                // 						while (y--)
                // 						{
                // 							tempY = tempX.childContent.parents[y];
                // 							if (tempY.wffid === parent.wfid)
                // 								return true;
                // 						}
                // 					}
                // 				}
                // 			}
                // 		};
                // 	}
                // }

                vm.getItems = function () {
                    let regexp; let res; let words; let output
                    // console.info("GET ITEMS");
					
                    if ($scope.items)
                    {
                        // console.log($scope.items)
                        vm.items = $scope.items
                    }
						
                    if ($scope.query && shouldQuery) vm.applyListFilter()

                    if ($scope.filter)
                    {
                        if (typeof $scope.filter === 'string' && $scope.filter !== '')
                        {
                            words = _.uniq($scope.filter.trim().replace(/\s+/g, ' ').split(' '))
                            regexp = new RegExp('(' + words.join(')|(') + ')', 'gi')
                            output = _.filter(vm.items, (x) => {
                                if (x.childContent && x.childContent.title)
                                {
                                    res = x.childContent.title.match(regexp)
                                    return res && _.uniq(res).length === words.length
                                }
                                else if (x.childContent && x.childContent.name)
                                {
                                    res = x.childContent.name.match(regexp)
                                    return res && _.uniq(res).length === words.length
                                }
                                else if (x.childContent && x.childContent.text)
                                {
                                    res = x.childContent.text.match(regexp)
                                    return res && _.uniq(res).length === words.length
                                }
                                else if (x.childContent && x.childContent.description)
                                {
                                    res = x.childContent.description.match(regexp)
                                    return res && _.uniq(res).length === words.length
                                }
                                else return false
                            })
                        }
                    }
                    else output = vm.items
					
                    if (excludeOptions) {
                        output = _.filter(output, (item) => {
                            return excludeOptions.values.indexOf(item[excludeOptions.lookup]) === -1
                        })
                    }

                    if (emptyStateOptions && output) {
                        if (output.length == 0) vm.isEmpty = true
                        else vm.isEmpty = false
                    }
					
                    return output
                }

                //vm.refilterList = function () {
                //	vm.selectedParent = comHub.getValue(parent.wfid);
                //	vm.applyListFilter();
                //}

                // comHub.onValueChange($scope, parent.wfid, 'wfid', function (value) {
                // 	vm.filterWfid = value
                // 	vm.applyListFilter();
                // })

                vm.applyListFilter = function () {
                    if (collectionQuery)
                    {
                        // var finalCollectionQuery = collectionQuery;
                        //console.log("Applying filter");
                        if (settings.filterButtonsSourceType && $scope.parent.conditions.objectTypes && $scope.parent.conditions.objectTypes[0] === 101)
                        {
                            //mainItemsFilterParams.where.childType = 101;
                            collectionQuery.where['childContent.parents_wfid'] = { contains: vm.filterWfid }
                            //mainItemsFilterParams.offset = 0;
                            //mainItemsFilterParams.limit = 20;
                        }
						
                        // console.log(collectionQuery);
                        // console.info("FILTERING");
                        vm.items = wfObject.filter(collectionQuery)
                        shouldQuery = false
                    }
                    // console.log(vm.items, finalCollectionQuery);
                }

                if (!$scope.items) maybeLoadItems()

                isotopeContainer = $element.children('div.list')
				
                if (!$scope.items && collectionQuery)
                {
                    vm.applyListFilter()

                    // if (filterButtonsQuery)
                    // {
                    // 	vm.filterBarItems = DS.utils.filter(wfObject.filter({ type: 71 }), filterButtonsQuery);
                    // }
                    // console.log("WATCHING");
                    $scope.$on('wfObjectModified', () => {
                        //var oldCount = vm.items ? vm.items.length : 0;
                        vm.applyListFilter()
                        // if (filterButtonsQuery)
                        // {
                        // 	vm.filterBarItems = DS.utils.filter(wfObject.filter({ type: 71 }), filterButtonsQuery);
                        // }

                        $element.removeClass('loading')

                        redrawGrid()
                    })
                }
            }

            function reloadItemChildren(item) {
                $scope.$broadcast('reloadItemSubList', item, 1)
            }

            function redrawGrid() {
                // isotopeContainer = isotopeContainer || $element.children('div.list');
                // clearTimeout(isotopeRedrawTimeout);
                // isotopeRedrawTimeout = setTimeout(function () {
                // 	console.log("redrawing");
                // 	isotopeContainer.isotope("what");
                // }, 1000);
            }

            function create() {
                let
                    initialValues = {}
					
                let objectType = 0
					
                let shouldCreateDataRelation = false
					
                let shouldCreateVirtualDataRelation = false
						
                //if ($scope.settings.dataRelation)
                //{
                //	_.assign(initialValues, {
                //		type: 15
                //		//type: 73, // DataRelation
                //		//parentId: $scope.settings.dataRelation.parentId,
                //		//parentType: $scope.settings.dataRelation.parentType,
                //		//parentData1: $scope.settings.dataRelation.parentData
                //	});
                //}

                if (extraSettings.createWithPickerOptions)
                {
                    modalService.openItem(extraSettings.createWithPickerOptions)
                    return
                }

                // Determine what type to create
                if ($scope.parent && $scope.kind)
                {
                    if ($scope.kind === 3)
                    {
                        // Verification
                        objectType = 97
                        initialValues = { objectId: $scope.parent.id, objectType: $scope.parent.type } // Specifies the item to verify
                    }
                    else
                    {
                        if ($scope.kind === 1) // If kind children
                        {
                            if ($scope.parent.conditions.dataRelation) shouldCreateDataRelation = true
                            else shouldCreateVirtualDataRelation = true

                        }

                        if ($scope.parent.conditions.objectTypes) objectType = $scope.parent.conditions.objectTypes[0] // First objectType in the list for now
                        else objectType = 71 // Structure for now
                    }
                }

                _.assign(initialValues, {
                    type: objectType,
                })

                modalService.createWithPromise(initialValues).then((newItem) => {
                    if (shouldCreateDataRelation)
                    {
                        dataOperations.createSubItemRelation($scope.parent, newItem, $scope.kind)
                    }
                    else if (shouldCreateVirtualDataRelation)
                    {
                        dataOperations.createVirtualSubItemRelation($scope.parent, newItem, $scope.kind)
                    }
                })
            }

            function maybeLoadItems() {
                // var triggerLoadAttr = $element.attr('trigger-load');
                //console.log("maybeLoadItems");
                if (shouldTriggerLoad)
                {
                    // console.log($scope.parent.conditions);
                    if ($scope.parent.conditions && subListKind == 1)// && $scope.parent.getSubList(subListKind).length == 0)
                    {
                        //console.log("conditions match");
                        //console.log("getSubItemsKind");
                        // $element.addClass('loading');
                        //console.log("get");
                        dataOperations.getSubItems($scope.parent, subListKind).then(() => {
                            vm.applyListFilter()
                            $element.removeClass('loading')
                        })
                    }
                }
                else {
                }
            }

            function add(newItem) {
                newItem._pos = {}
                handleNewOrUpdatedItem(newItem, null, () => {
                    vm.items.unshift(newItem)
                })
            }

            function update(existingItem, updatedItem) {
                handleNewOrUpdatedItem(updatedItem, existingItem, () => {
                    _.assign(existingItem, updatedItem)

                })
            }

            function remove(item) {
                let itemsDeleted
                itemsDeleted = _.remove(vm.items, (x) => { return item === x })
                // console.log(itemsDeleted);
                // console.log("removed", vm.items.length)

                if ($scope.items && itemsDeleted.length > 0) {
                    _.remove($scope.items, (x) => { return item === x })
                }

                redrawGrid()

                return itemsDeleted.length > 0
            }

            function handleNewOrUpdatedItem(item, oldItem, applyMethod)
            {
                //preItemApplied(item, oldItem);
                applyMethod()
                //postItemApplied(item);
                //redrawGrid();
            }

            function preItemApplied(item, oldItem)
            {
                loadChildContent = false

                if (item.childId || item.childType)
                {
                    if (oldItem && oldItem.childContent
						&& angular.equals(item.childId, oldItem.childId)
						&& angular.equals(item.childType, oldItem.childType))
                    {
                        item.childContent = oldItem.childContent
                    }
                    else if (!oldItem && item.childContent)
                    {

                    }
                    else
                    {
                        loadChildContent = true
                    }
                }
            }

            function postItemApplied(item) {
                if (loadChildContent)
                {
                    loadChildContent = false
                    apiProxy('multi.getObject', {
                        objectId: item.childId,
                        objectType: item.childType,
                    }).then((res) => {
                        item.childContent = res
                        reloadItemChildContent(item)
                    })
                }
            }

            //function reloadItemChildContent(item)
            //{
            //	$scope.$broadcast("reloadChildContent", item);
            //}
			
            function getItemSettings(item)
            {
                if (settings && settings.childrenSettings) return settings.childrenSettings
                else if (parent.settings && parent.settings.childrenSettings) return parent.settings.childrenSettings
                else if (item.childContent && item.settings) return item.settings
                else return {}
            }

            function itemWidth(item) {
                const s = getItemSettings(item)
					
                const cols = vm.layout.gridColumns || s.gridColumns
				
                if (cols)
                {
                    return (cols * (100 / 12.0)) + '%'
                }
                else return vm.layout.list ? 'auto' : (100 / 4.0) + '%'
            }

            function itemPaddingTop(item) {
                const s = getItemSettings(item)
					
                const cols = vm.layout.gridColumns || s.gridColumns
					
                const rows = vm.layout.gridRows || s.gridRows
				
                if (rows)
                {
                    return ((rows / ((cols || 4) * 1.0)) * 100) + '%'
                }
                else return '0'
            }

            function itemCssClass(item) {
                const s = getItemSettings(item)
					
                const rows = vm.layout.gridRows || s.gridRows
				
                if (rows) return 'fixedHeight'
                else return 'autoHeight'
            }

            function showItem(item) {
                let shouldRedraw

                if (hiddenItemsByWfid[item.wfid]) shouldRedraw = true

                hiddenItemsByWfid[item.wfid] = undefined

                if (shouldRedraw) redrawGrid()
            }

            function hideItem(item) {
                let shouldRedraw

                if (!hiddenItemsByWfid[item.wfid]) shouldRedraw = true

                hiddenItemsByWfid[item.wfid] = true

                if (shouldRedraw) redrawGrid()
            }

            function toggleItem(item, condition) {
                if (condition) showItem(item)
                else hideItem(item)
            }
        }

        function link(scope, element, attributes, vm) {
            let transcludedContent
            vm.compileAndActivate = function () {
                if (element.children().length) {
                    transcludedContent = element.children()
                    $compile(transcludedContent)(scope)
                }

                element.html('<div ng-include="\'scripts/wf/collection/collection.directive.html\'" class="inner clearfix"></div>')

                $compile(element.contents())(scope)
                if (transcludedContent) {
                    const x = element.find('div.transcluded')
                    x.append(transcludedContent)

                }
				
                vm.activate()
				
                if (vm.layout && vm.layout.grid)
                {
                    element.addClass('grid')
                }
            }

            if (attributes.activateIf)
            {
                vm.watches.activateIf = scope.$watch('activateIf', (val) => {
                    if (val)
                    {
                        vm.watches.activateIf()
                        vm.compileAndActivate()
                    }
                })
            }
            else
            {
                vm.compileAndActivate()
            }
        }
    }
	
})()
