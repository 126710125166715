// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
    root: {
        flex: 1,
        paddingLeft: 120,
        paddingRight: 120,
        textAlign: 'center',
    },
    title: {
        ...theme.typography.h6,
    },
    description: {
        ...theme.typography.body1,
        marginTop: 18,
    },
})

const FinishStep = ({ classes }) => {
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <FormattedMessage id={'supplyChain.organizationForm.step3.content.title'} />
            </div>
            <div className={classes.description}>
                <FormattedMessage id={'supplyChain.organizationForm.step3.content.description'} />
            </div>
        </div>
    )
}

export default withStyles(styles)(FinishStep)
