(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('wfUtilityService', wfUtilityService)

    wfUtilityService.$inject = ['$templateCache', '$http']
    function wfUtilityService($templateCache, $http) {
        const
            templateRequests = {}
			
        const templates = {}

        const service = {
            loadTemplateUrlInCache,
            getTemplateFromCache,
        }
		
        return service
	
        function loadTemplateUrlInCache(url) {
            const html = $templateCache.get(url)
	
            if (html) {
                templates[url] = html
                $templateCache.put(url, html)
                return
            }
	
            if (!templateRequests[url]) {
                templateRequests[url] = $http.get(url)
	
                templateRequests[url].then((res) => {
                    templates[url] = res.data
                })
            }
	
            templateRequests[url].then((res) => {
                $templateCache.put(url, res.data)
            })
        }

        function getTemplateFromCache(templateToLoad) {
            return $templateCache.get(templateToLoad)
        }
    }
})()
