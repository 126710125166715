import './reactBootstrap'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { initStore } from './store'
import { JssProvider } from 'react-jss'
import { create } from 'jss'
import { createGenerateClassName, jssPreset, ThemeProvider } from '@material-ui/styles'
import ScrollRestoration from '@worldfavor/components/ScrollRestoration'
import theme from '@worldfavor/constants/theme'
import App from '@worldfavor/components/App'
import LegacyApp from './LegacyApp'

//const LegacyApp = React.lazy(() => import('./LegacyApp'))
const store = initStore()

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: false,
})

const jss = create({
    ...jssPreset(),
})

class Root extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <ScrollRestoration />
                    <JssProvider jss={jss} generateClassName={generateClassName}>
                        <ThemeProvider theme={theme}>
                            <Switch>
                                <Route path={'/beta'}>
                                    <App />
                                </Route>
                                <Route>
                                    {/*<Suspense fallback={<div>loading</div>}>*/}
                                    <LegacyApp />
                                    {/*</Suspense>*/}
                                </Route>
                            </Switch>
                        </ThemeProvider>
                    </JssProvider>
                </Router>
            </Provider>
        )
    }
}

export default Root
