import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('SustainabilityDiagnosisController', SustainabilityDiagnosisController)

    SustainabilityDiagnosisController.$inject = ['$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', '$injector',
        'wfTranslate', '$timeout', '$rootScope', 'apiProxy', 'wfAuth', 'wfItemToolsService', '$state', '$compile', '$window', 'requirements', '$sce']
    function SustainabilityDiagnosisController($scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, $injector,
        wfTranslate, $timeout, $rootScope, apiProxy, wfAuth, wfItemToolsService, $state, $compile, $window, requirementService, $sce) {
        const
            vm = this
			
        const debug = false
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const riskAssessmentStuctureId = 13910
        // Risk assessment structure
			
        const mainStructureId = 13917
        // Structure for the page header
			
        const standardStuctureId = 21
        // ISO 26000
			
        const prioCategoryStructureId = 11969
        // Contains items categorized as prioritized
			
        let allPrioritizedItemRelationsByChildWfid
        // All items in the entire standard that are prioritized (including parents and children)
			
        let prioritizedItemsByParentWfid // Items that are visualy prioritizable grouped by their parent (parent = top category in ISO 26000)

        _.assign(vm, {
            mainStructure: null,
            standardStructure: null,
            prioCategoryStructure: null,
            prioritizedItemsCount: 0,
            prioCategoryStructureWfid: '71-' + prioCategoryStructureId, // Passed into wfHierarchical directive
            prioDonutChartValue: 0,

            activeView: 'mainBoxes',

            // Arrays
            allItemsInStandard: null, // Contains the entire hierarchy as a flat list of itemComposites
            prioritizableItems: null, // Items that can have prioritization toggled in the UI. In ISO 26000 it is the sub categories
            prioritizedItems: null,

            prioritizableItemsByWfid: {},
            childItemsByPrioritizableItemWfid: {},
            prioritizedItemsByWfid: {},

            // Functions
            togglePrioritization,
            gotoMainBoxes() {
                vm.activeView = 'mainBoxes'
                $timeout(() => {
                    updateArraysAndLookupObjects()
                    updateChartData()
                }, 300)
            },
        })

        activate()

        function activate() {
            // Loading main structure
            dataOps.getObject({
                objectType: enums.objectType.structure,
                objectId: mainStructureId,
            }).then((res) => {
                vm.mainStructure = res
                vm.mainGuidanceVideoUrl = $sce.trustAsResourceUrl(vm.mainStructure.guidanceVideoUrl)

                // Loading standard
                dataOps.getObject({
                    objectType: enums.objectType.structure,
                    objectId: standardStuctureId,
                    getterConditions: {
                        contextVariable1: 'onlyLatestAnswers',
                        applyIntersectionIfPossible: true,
                        loadRequirements: true,
                    },
                    childrenLoadDepth: 10,
                }).then((standardStructure) => {
                    vm.standardStructure = standardStructure
                    vm.allItemsInStandard = dataQuery.getHierarchyAsList(standardStructure, [enums.objectType.structure, enums.objectType.question, enums.objectType.measure], {
                        asItemComposites: true,
                    })

                    handleISO26000()

                    // Loading Risk Assessment structure
                    dataOps.getObject({
                        objectType: enums.objectType.structure,
                        objectId: riskAssessmentStuctureId,
                        getterConditions: {
                            contextVariable1: 'onlyLatestAnswers',
                            applyIntersectionIfPossible: true,
                            loadRequirements: true,
                        },
                        childrenLoadDepth: 10,
                    }).then((riskAssessmentStructure) => {
                        vm.riskAssessmentStructure = riskAssessmentStructure

                        // Loading prio category
                        dataOps.getObject({
                            objectType: enums.objectType.structure,
                            objectId: prioCategoryStructureId,
                        }).then((prioCategoryStructure) => {
                            let prioDonutChartValue; let riskDonutChartValue; const prioAnswerStatCounts = []
                            vm.prioCategoryStructure = prioCategoryStructure

                            updateArraysAndLookupObjects()
                            updateChartData()
                            prioDonutChartValue = vm.prioDonutChartValue
                            riskDonutChartValue = vm.riskAssessmentAnswersPercentage_No
                            vm.prioDonutChartValue = 0
                            vm.riskAssessmentAnswersPercentage_No = 0
                            _.each(vm.prioAnswerStatistics.statsArray, (stats) => {
                                prioAnswerStatCounts.push(stats.percentage)
                                stats.percentage = 0
                            })

                            vm.loaded = true
                            $timeout()

                            $timeout(() => {
                                vm.prioDonutChartValue = prioDonutChartValue
                                vm.riskAssessmentAnswersPercentage_No = riskDonutChartValue
                                _.each(vm.prioAnswerStatistics.statsArray, (stats, index) => {
                                    stats.percentage = prioAnswerStatCounts[index]
                                })
                            }, 400)

                        })
                    })
                })
            })

        }

        function handleISO26000() {
            let
                itemsExceptPrioritizable
				
            let groupedTopCategories
				
            let groupedQuestions

            vm.prioritizableItems = _.filter(vm.allItemsInStandard, { depth: 1 }) // Sub categories
            vm.prioritizableItemsByWfid = _.keyBy(vm.prioritizableItems, 'wfid')
            itemsExceptPrioritizable = _.difference(vm.allItemsInStandard, vm.prioritizableItems) // Top categories and questions
            vm.questions = _.chain(itemsExceptPrioritizable).filter({ depth: 2 }).value()

            // _.chain(vm.prioritizableItems).each() // Top categories? Will need logic for determining what items that needs to be tagged/untagged

            _.assign(vm.childItemsByPrioritizableItemWfid,
                // groupedTopCategories = _.chain(itemsExceptPrioritizable).filter({ depth: 0 }).groupBy("dataRelation.wffid").value(),
                groupedQuestions = _.chain(itemsExceptPrioritizable).filter({ depth: 2 }).groupBy('dataRelation.wffid').value(),
            )
        }

        function togglePrioritization(item) {
            const
                relationsToDelete = []
				
            const relationsToCreate = []
				
            let childrenRelationsToDelete
				
            const promises = []

            return $q((resolve) => {

                // Already selected? Then delete relations
                if (vm.prioritizedItemsByWfid[item.wfid]) {
                    // Check which relations to delete:
                    // The item itself
                    relationsToDelete.push(allPrioritizedItemRelationsByChildWfid[item.wfid])

                    // console.log(prioritizedItemsByParentWfid[item.parentWfid].length);
                    // Top category? Only if this is the last selected item in that top category
                    if (prioritizedItemsByParentWfid[item.parentWfid].length === 1) {
                        relationsToDelete.push(allPrioritizedItemRelationsByChildWfid[item.parentWfid])

                        // Instantly remove the item from this object beacuse if several items are untoggled quickly
                        // within the same top category this object needs to reflect the changes instantly instead of waiting
                        // to update with updateArraysAndLookupObjects() until the promises resolves.
                        // Otherwise the top category relation will not get deleted.

                        delete allPrioritizedItemRelationsByChildWfid[item.parentWfid] // Needs to be removed instantly from lookup object
                    }
					
                    _.remove(prioritizedItemsByParentWfid[item.parentWfid], { wfid: item.wfid }) // Needs to be removed instantly from lookup object
					
                    // Questions? All with a relation to prio category
                    childrenRelationsToDelete = _.chain(vm.childItemsByPrioritizableItemWfid[item.wfid]).map((childItem) => {
                        return allPrioritizedItemRelationsByChildWfid[childItem.wfid]
                    }).filter((childItem) => {
                        return childItem
                    }).value()
                    Array.prototype.push.apply(relationsToDelete, childrenRelationsToDelete)

                    // Check so that nothing is undefined. Needed?
                    _.remove(relationsToDelete, (dataRelation) => {
                        return !dataRelation
                    })

                    _.each(relationsToDelete, (dataRelation) => {
                        let item
                        promises.push(dataOps.destroy(dataRelation))

                        if (debug) {
                            item = _.find(vm.allItemsInStandard, { wfid: dataRelation.wfcid })
                            console.log('Destroy relation ' + dataRelation.wfid + ' (depth ' + item.depth + ') \'' + item.content.getMainTextual() + '\'')
                        }
                    })

                    $q.all(promises).then(() => {
                        updateArraysAndLookupObjects()
                        resolve()
                    })
                }
                else { //Else, not selected? Then create relations

                    // Check which relations to create:

                    // For the item itself
                    relationsToCreate.push(item.wfid)

                    // Top category? Only if it doesn't already have a relation
                    if (!(item.parentWfid in allPrioritizedItemRelationsByChildWfid)) {
                        relationsToCreate.push(item.parentWfid)

                        // Instantly add the item to this object beacuse if several items are toggled quickly
                        // within the same top category this object needs to reflect the changes instantly instead of waiting
                        // to update with updateArraysAndLookupObjects() until the promises resolves.
                        // Otherwise the top category relation will not get created.

                        allPrioritizedItemRelationsByChildWfid[item.parentWfid] = {}
                    }

                    if (!(item.parentWfid in prioritizedItemsByParentWfid)) prioritizedItemsByParentWfid[item.parentWfid] = []

                    prioritizedItemsByParentWfid[item.parentWfid].push(vm.prioritizableItemsByWfid[item.wfid]) // Needs to be added instantly to lookup object

                    // Questions? All that doesn't have a relation already
                    _.each(vm.childItemsByPrioritizableItemWfid[item.wfid], (childItem) => {
                        if (!(childItem.wfid in allPrioritizedItemRelationsByChildWfid)) relationsToCreate.push(childItem.wfid)
                    })

                    _.each(relationsToCreate, (wfid) => {
                        let item
                        promises.push(dataOps.createSubItemRelation(vm.prioCategoryStructureWfid, wfid, enums.subItemsKind.childrenByUser))

                        if (debug) {
                            item = _.find(vm.allItemsInStandard, { wfid })
                            console.log('Create relation for ' + wfid + ' (depth ' + item.depth + ') \'' + item.content.getMainTextual() + '\'')
                        }
                    })

                    $q.all(promises).then(() => {
                        updateArraysAndLookupObjects()
                        resolve()
                    })

                }

                if (debug) console.log(' ')
            })

        }

        function updateArraysAndLookupObjects() {
            vm.prioritizedItemsCount = _.intersection(_.map(vm.prioritizableItems, 'wfid'), _.map(vm.prioCategoryStructure.childs, 'wfcid')).length

            vm.prioritizedItems = _.intersectionBy(vm.prioritizableItems, _.map(vm.prioCategoryStructure.childs, 'childContent'), 'wfid')
            vm.prioritizedItemsByWfid = _.keyBy(vm.prioritizedItems, 'wfid')

            const allPrioritizedItemRelations = _.intersectionWith(vm.prioCategoryStructure.childs, vm.allItemsInStandard, (dataRelation, itemComposite) => {
                // Comparator function
                return dataRelation.wfcid === itemComposite.wfid
            })
            allPrioritizedItemRelationsByChildWfid = _.keyBy(allPrioritizedItemRelations, 'wfcid')
            prioritizedItemsByParentWfid = _.groupBy(vm.prioritizedItems, 'parentWfid')

            if (debug) console.log(vm)
			
        }

        function updateChartData() {
            const questionsInStandard = _.filter(vm.allItemsInStandard, { type: enums.objectType.question })
            const prioritizedQuestions = _.filter(questionsInStandard, (question) => {
                return question.wfid in allPrioritizedItemRelationsByChildWfid
            })
            vm.prioAnswerStatistics = dataQuery.getAnswerStatistics(prioritizedQuestions, authOrgId)
            vm.prioDonutChartValue = vm.prioritizableItems.length > 0 ? (vm.prioritizedItemsCount / vm.prioritizableItems.length * 100.0) : 0

            vm.allItemsInRiskAssessment = dataQuery.getHierarchyAsList(vm.riskAssessmentStructure, [enums.objectType.structure, enums.objectType.question, enums.objectType.measure], {
                asItemComposites: true,
            })
            const questionsInRiskAssessment = _.filter(vm.allItemsInRiskAssessment, { type: enums.objectType.question })
            const riskAssessmentAnswerStatistics = dataQuery.getAnswerStatistics(questionsInRiskAssessment, authOrgId)
            vm.riskAssessmentQuestionsCount = questionsInRiskAssessment.length
            vm.riskAssessmentAnswersCount_Yes = (riskAssessmentAnswerStatistics.byAnswer['83-4'] || { }).count || 0
            vm.riskAssessmentAnswersPercentage_Yes = (riskAssessmentAnswerStatistics.byAnswer['83-4'] || { }).percentage || 0
        }
    }
})()
