import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfMeasureTargetsEditor', {
            templateUrl: 'scripts/wf/measureTargets/wfMeasureTargetsEditor.component.html',
            controller: wfMeasureTargetsEditorController,
            controllerAs: 'vm',
            bindings: {
                item: '<',
            },
        })

    wfMeasureTargetsEditorController.$inject = ['$scope', '$timeout', '$translate', '$q', 'dataOperationsService', 'wfPropertyExtractor', 'modalService', 'dataQuery', 'wfAuth']
    function wfMeasureTargetsEditorController($scope, $timeout, $translate, $q, dataOps, wfPropertyExtractor, modal, dataQuery, wfAuth) {
        const vm = this
			
        const maxTargetCount = 3
        let dataRelation
        let itemContent
        let hasChanges = false
			
        const itemPrototype = {
            model: {
                measureId: null,
                dataSource: null,
            },
            selectedMeasure: null,
            valid: false,
        }

        _.assign(vm, {
            originalItems: [],
            viewItems: [],
            loading: true,
            saving: false,
            debug: false,
            invalidForm: false,
            dataSources:
                [
                    { id: enums.dataSource.reportedData, name: $translate.instant('dataSource.reportedData') },
                    { id: enums.dataSource.internalData, name: $translate.instant('dataSource.internalData') },
                    { id: enums.dataSource.constantData, name: $translate.instant('dataSource.constantData') },
                ],
            sourceMetadataConfig: {
                organizationIds: [wfAuth.getOrganizationId(), 1],
                limitRelationKinds: [7],
                showDetailedViewLink: false,
                showEmbeddedObjectViewer: false,
                hideNumbers: true,
                popoverItemSettings: {
                    showDropdown: false,
                    metadataConfig: {
                        showCreator: false,
                        limitRelationKinds: [],
                        showDetailedViewLink: false,
                        showEmbeddedObjectViewer: false,
                    },
                },
            },

            //Functions
            cancel: close,
            addItem,
            saveChanges,
            selectMeasureItem,
            changeItem,
            removeItem,
            disableAddTargetButton,
        })

        this.$onInit = $onInit

        function $onInit() {
            if (vm.item.type === enums.objectType.dataRelation) {
                dataRelation = vm.item
                itemContent = dataRelation.childContent
            }
            else {
                dataRelation = vm.item.dataRelation
                itemContent = vm.item.content
            }

            vm.itemMainTextual = itemContent.text
            
            loadTargets().then(() => {
                vm.viewItems = _.cloneDeep(vm.originalItems)
                vm.loading = false
                $timeout()
            })

            function loadTargets() {
                vm.loading = true
                const deferred = $q.defer()
                const promises = []

                getMeasureTargets().then((measureTargets) => {
                    vm.originalItems = _.chain(measureTargets).map((target) => {
                        return _.defaultsDeep({
                            model: target,
                            valid: true,
                        }, itemPrototype)
                    }).value()

                    if (vm.originalItems && vm.originalItems.length) {
                        const measureWfIds = measureTargets.map(target => (`${enums.objectType.measure}-${target.measureId}`))
                  
                        if (measureWfIds && measureWfIds.length > 0) {
                         
                            promises.push(getSourceMeasures(measureWfIds).then((measures) => {
                                _.each(vm.originalItems, (target) => {
                                    _.each(measures, (measure) => {
                                        if (target.model.measureId === measure.id) target.selectedMeasure = measure
                                    })
                                })
                            }))

                            $q.all(promises).then(() => {
                                deferred.resolve()

                            }, () => {
                                console.error('Could not get source measures')
                                modal.alert({
                                    title: $translate.instant('modules.mailPreview.serverErrorModal.title'),
                                    message: $translate.instant('modules.mailPreview.serverErrorModal.message'),
                                    onEscape: false,
                                    type: 'info',
                                    buttons: {
                                        primary: {
                                            label: $translate.instant('Close'),
                                            className: 'btn-hollow action',
                                            callback() {
                                                $scope.$close()
                                            },
                                        },
                                    },
                                })
                                deferred.reject()
                            })
                        }
                        else {
                            deferred.resolve()
                        }
                    }
                    else {
                        deferred.resolve()
                    }
                }, () => {
                    console.error('Could not get target measures')
                    modal.alert({
                        title: $translate.instant('modules.mailPreview.serverErrorModal.title'),
                        message: $translate.instant('modules.mailPreview.serverErrorModal.message'),
                        onEscape: false,
                        type: 'info',
                        buttons: {
                            primary: {
                                label: $translate.instant('Close'),
                                className: 'btn-hollow action',
                                callback() {
                                    $scope.$close()
                                },
                            },
                        },
                    })
                    deferred.reject()
                })

                function getMeasureTargets() {
                    return $q((resolve) => {
                        if (dataRelation.measureTargets) {
                            resolve(dataRelation.measureTargets)
                        }
                        else {
                            dataOps.getMeasureTargets(dataRelation.id).then((data) => {
                                resolve(data)
                            })
                        }
                    })
                }

                function getSourceMeasures(wfids) {
                    return dataOps.getObjects(wfids, true)
                }

                return deferred.promise
            }
        }

        function addItem() {
            hasChanges = true
            const newItem = _.cloneDeep(itemPrototype)
            vm.viewItems.push(newItem)
        }

        function removeItem(item) {
            hasChanges = true
            _.remove(vm.viewItems, item)

            if (vm.viewItems.length === 0) {
                isFormValid()
            }
        }

        function changeItem(item) {
            hasChanges = true
            if (item.selectedMeasure) {
                item.model.measureId = item.selectedMeasure.id
            }
            else {
                item.model.measureId = null
                item.model.dataSource = null
            }

            item.valid = !!item.selectedMeasure && (item.model.dataSource != null)
            
            if (vm.invalidForm) {
                isFormValid()
            }
        }

        function close() {
            vm.saving = false
            $scope.$parent.$close()
            $timeout()
        }

        function selectMeasureItem(item) {
            return $q((resolve) => {
                resolve()

                modal.openCreatorAndPicker({
                    title: $translate.instant('modules.measureTargets.editor.measureSelector.title'),
                    displayTopItem: { title: vm.itemMainTextual },
                    singlePick: true,
                    relationBucket: {
                        preSelected: item.selectedMeasure ? [item.selectedMeasure] : [],
                        allSelected: item.selectedMeasure ? [item.selectedMeasure] : [],
                    },
                    objectTypes: [enums.objectType.measure],
                    hideItem: vm.item.wfcid,
                }).closed((relationBucketResult) => {
                    item.selectedMeasure = undefined

                    $timeout(() => {
                        item.selectedMeasure = relationBucketResult.allSelected[0]
                        changeItem(item)
                    })
                })
            })
        }

        function isFormValid() {
            let valid = false

            const allItemsValid = _.every(vm.viewItems, item => item.valid)
            
            valid = vm.viewItems.length === 0 ? true : allItemsValid
            
            vm.invalidForm = !valid

            $timeout()

            return valid
        }

        function saveChanges() {
            if (isFormValid()) {
                return $q((resolve) => {
                    vm.saving = true
                    if (!hasChanges) {
                        // No changes were done
                        resolve()
                        close()
                    }
                    else {
                        const targets = vm.viewItems.map(item => item.model)
                        dataRelation.measureTargets = targets
    
                        const payload = {
                            dataRelationId: dataRelation.id,
                            targets,
                        }
                        dataOps.saveMeasureTargets(payload).then(() => {
                            resolve()
                            close()
                        })
                    }
                })
            
            }
        }

        function disableAddTargetButton() {
            return vm.viewItems && vm.viewItems.length >= maxTargetCount
        }
    }
})()
