(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfIf', wfIf)
        .directive('wfIfFirst', wfIfFirst)

    wfIf.$inject = []
    function wfIf() {
        const directive = {
            restrict: 'A',
            // terminal: true,
            // priority: 1000,
            link,
        }
        return directive

        function link(scope, element, attrs) {
            // console.log(scope.$id, attrs.wfIf, scope.$eval(attrs.wfIf));
            // if (scope.vm && scope.vm.item)
            // 	console.log(scope.vm.item.wfid, "if - link", attrs.wfIf)
            if (!scope.$eval(attrs.wfIf)) {
                element.remove()
            }
        }
    }

    wfIfFirst.$inject = ['$compile']
    function wfIfFirst($compile) {
        const directive = {
            restrict: 'A',
            // terminal: true,
            // priority: 1000,
            // controller: wfIfFirstController,
            priority: 10000, // process this directive first
            terminal: true, // stop compiling other directives on the
            // element, we'll fix it in `post`
            compile(element, attrs) {
                return {
                    post(scope, element, attrs, ctrl) {
                        // console.log(attrs.wfIfFirst, scope);
                        // if (scope.vm && scope.vm.item)
                        // 	console.log(scope.vm.item.wfid, "if - compile", attrs.wfIfFirst)

                        if (!scope.$eval(attrs.wfIfFirst)) {
                            element.remove()
                        }
                        else {
                            // Prevent recursion
                            element[0].removeAttribute('wf-if-first')
							
                            // Compile the element so other directive gets processed
                            $compile(element)(scope)
                        }
                    },
                }
            },
        }
        return directive

    }

    // wfIfFirstController.$inject = ["$scope", "$element", "$attrs"]
    // function wfIfFirstController(scope, element, attrs) {
    // 	// if (scope.vm && scope.vm.item)
    // 	// 	console.log(scope.vm.item.wfid, "if - controller")
    // 	if (scope.vm && scope.vm.item)
    // 		console.log(scope.vm.item.wfid, "if - controller", attrs.wfIfFirst)
    // 	if (!scope.$eval(attrs.wfIfFirst)) {
    // 		element.remove();
    // 	}
    // }

})()
