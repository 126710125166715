import createCachedSelector from 're-reselect'
import { _getNodesFromEdgeIds, _allWfObjects } from './dataSelector'
import { _arg2 } from '@worldfavor/utils/selectors'

const _allRequests = state => state.pagination // eslint-disable-line no-unused-vars
const _requestByRequestId = (state, requestId) => state.pagination[requestId]

export const getPageFromRequestIdAndPageId = createCachedSelector(
    [_requestByRequestId, _arg2],
    (request, pageId) => {
        if (request) {
            return request.pages[pageId]
        }

        return null
    },
)((state, requestId, pageId) => `${requestId}:${pageId}`)

export const getItemIdsFromRequestIdAndPageId = createCachedSelector(
    [getPageFromRequestIdAndPageId],
    (page) => {
        if (page) {
            return page.ids
        }

        return []
    },
)((state, requestId, pageId) => `${requestId}:${pageId}`)

export const getNodesFromRequestIdAndPageId = createCachedSelector(
    _allWfObjects,
    getItemIdsFromRequestIdAndPageId,
    (items, ids) => _getNodesFromEdgeIds(items, ids),
)((state, requestId, pageId) => `${requestId}:${pageId}`)
