(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfValueChainCategoryFiltering', {
            templateUrl: 'scripts/wf/valueChain/wfValueChainCategoryFiltering.component.html',
            controller: valueChainCategoryFilteringController,
            controllerAs: 'vm',
            bindings: {
                networkId: '<',
                onFiltered: '&',
                filterOutput: '<',
                preselectedCategoryIds: '<',
                preselectedInvertedCategoryIds: '<',
                enableInvertedFiltering: '<',
            },
        })

    valueChainCategoryFilteringController.$inject = ['valueChainService', '$timeout']
    function valueChainCategoryFilteringController(valueChainService, $timeout) {
        const
            vm = this

        this.$onInit = function () {
            const initialSelectedFilterOptions = vm.preselectedCategoryIds && vm.preselectedCategoryIds.map(x => ({ id: '71-' + x, isSelected: true }))

            if (vm.preselectedInvertedCategoryIds) {
                Array.prototype.push.apply(initialSelectedFilterOptions, vm.preselectedInvertedCategoryIds.map(x => ({ id: '71-' + x, isSelected: true, isInverted: true })))
            }

            vm.filterConfigInfo = {
                filteringConfig: {
                    initialSelectedFilterOptions,
                    enableInvertedFiltering: vm.enableInvertedFiltering,
                },
                onFiltered(filteredItems, selectedOptions) {
                    if (vm.filterOutput) {
                        vm.filterOutput.filteredItems = filteredItems
                        vm.filterOutput.selectedOptions = selectedOptions
                    }

                    if (vm.onFiltered) {
                        vm.onFiltered({
                            filteredItems,
                            selectedOptions,
                        })
                    }

                    $timeout()
                },
            }
            vm.filterConfigInfo.selectedFiltersCount = vm.filterConfigInfo.filteringConfig.initialSelectedFilterOptions && vm.filterConfigInfo.filteringConfig.initialSelectedFilterOptions.length
            valueChainService.loadCategoriesInNetworkAsFilterConfigInfo(vm.networkId, null, vm.filterConfigInfo).then((filterConfigInfo) => {
                if (vm.filterOutput) vm.filterOutput.unfilteredItems = filterConfigInfo.unfilteredItems
            })
        }
    }
})()
