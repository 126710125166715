export default {
    // to be filled with all the paths for the app routes
}

export const exchangePath = (id) => {
    return `/exchange/${id}`
}

export const productPath = (exchangeId, productId) => {
    return `/exchange/${exchangeId}/product/${productId}`
}

export const influencePath = (id) => {
    return `/inf/${id}`
}
