import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfQuestionAnsweringManager', wfQuestionAnsweringManager)
	
    wfQuestionAnsweringManager.$inject = ['$parse', 'dataQuery', 'dataOperationsService', '$compile']

    function wfQuestionAnsweringManager($parse, dataQuery, dataOps, $compile) {
        const directive = {
            restrict: 'A',
            controllerAs: 'questionAnsweringManager',
            controller: ['$scope', '$attrs', '$element', '$templateCache', '$timeout', 'wfObject', wfQuestionAnsweringManagerController],

        }

        return directive

        function wfQuestionAnsweringManagerController($scope, $attrs, $element, $templateCache, $timeout, wfObject) {
            const
                vm = this
				
            const itemsByWfid = {}
				
            const items = []

            /* {
					requirement: <object>,
					fulfillsLocally: <bool>,
					latestAnswerId: <number>,
					questionDataRelation: <object>,
					question: <object>
				}
					*/
				
            const fulfillsAllLocally = false
				
            let initialCalcTimer
				
            let questions
            // Questions that might have been added to this instance of wfQuestionAnsweringManager. If not defined then all questions in JSData will be used

            // Arrays
				
            let allAnswerRelations
            // All answers (according to intersectionSettings)
				
            let allLatestAnswerRelations
            // All latest answers per question (according to intersectionSettings)
				
            let allInvisibleAnswerRelations
            // All invisible answers (without visibility but with context parents)
				
            let allInvisibleLatestAnswerRelations
            // All latest invisible answers (without visibility but with context parents)

            // Lookup objects (key = questionWfid, value = array of dataRelations)
				
            let allAnswerRelationsByQuestionWfid
            // All answers
				
            let allInvisibleAnswerRelationsByQuestionWfid
            // All invisible answers

            // Lookup objects (key = questionWfid, value = single dataRelation)
				
            let allLatestAnswerRelationsByQuestionWfid
            // Latest answers
				
            let allInvisibleLatestAnswerRelationsByQuestionWfid
            // Latest invisible answers
				
            let allAnswerInNetwork
				
            let allAnswersByContextParents
				
            let allAnswersContextParentsArrays = []
				
            let contextParentsIntersectionArguments
				
            let networkId
				
            let allQuestionAnswers
				
            const useContextAwareRelations = true

            vm.insert = setQuestionAnswerInfo
            vm.update = updateQuestionAnswerInfo
            vm.fulfillsAllLocally = checkLocalFulfillmentOnAll
            vm.getLocalFulfillmentData = getLocalFulfillmentData
            vm.getLatestQuestionAnswer = getLatestQuestionAnswer
            vm.getNotVisibleLatestQuestionAnswer = getNotVisibleLatestQuestionAnswer
            vm.addCompositeItems = addCompositeItems
            vm.getContextParents = getContextParents

            if ($attrs.wfQuestionAnsweringManager !== '') {
                const x = $parse($attrs.wfQuestionAnsweringManager)($scope)
                _.assign(x, vm)
                if (typeof x.onLoaded === 'function') {
                    x.onLoaded(vm)
                }
            }

            /* Used in
			wfQuestionAnswering.directive.js
			 */
            function updateQuestionAnswerInfo(wfid, data, updateAnswersCache, organizationId, networkId, contextParentWfids) {
                let cacheWasUpdated
                setQuestionAnswerInfo(wfid, data)
                // if (allAnswerRelations)
                if (updateAnswersCache) {
                    recacheAllAnswers({
                        organizationId,
                        networkId,
                        contextParents: contextParentWfids,
                    })
                }
                else {
                    cacheWasUpdated = ensureAnswerCache({
                        organizationId,
                        networkId,
                        contextParents: contextParentWfids,
                    })

                    if (!cacheWasUpdated) {

                        if (data.latestAnswerDataRelation) {

                            // Update arrays
                            allAnswerRelations.push(data.latestAnswerDataRelation)

                            _.remove(allLatestAnswerRelations, { wffid: wfid })
                            allLatestAnswerRelations.push(data.latestAnswerDataRelation)

                            // Update lookup objects
                            allLatestAnswerRelationsByQuestionWfid[wfid] = data.latestAnswerDataRelation

                            if (!allAnswerRelationsByQuestionWfid[wfid]) allAnswerRelationsByQuestionWfid[wfid] = []

                            allAnswerRelationsByQuestionWfid[wfid].push(data.latestAnswerDataRelation)
                        }
                        // if (networkId && data.latestAnswerVisibilityTag) {
                        // 	allAnswerInNetwork.push(data.latestAnswerVisibilityTag)
                        // }
                        // if (contextParentWfids && data.latestAnswerContextParentRelations) {
                        // 	if (!allAnswersByContextParents)
                        // 		allAnswersByContextParents = {};

                        // 	for (var i = 0, len = contextParentWfids.length; i < len; i++) {
                        // 		if (!allAnswersByContextParents[contextParentWfids[i]])
                        // 			allAnswersByContextParents[contextParentWfids[i]] = [];

                        // 		allAnswersByContextParents[contextParentWfids[i]].push(_.find(data.latestAnswerContextParentRelations, { wffid: contextParentWfids[i] }))
                        // 	}
                        // }
                    }
                }
                // console.log(itemsByWfid[wfid]);
                // console.log('$scope.$emit("questionAnswerChanged", itemsByWfid[wfid])', '$scope.$broadcast("questionAnswerChanged", itemsByWfid[wfid])', wfid, itemsByWfid[wfid]);
                $scope.$emit('questionAnswerChanged', itemsByWfid[wfid])
                $scope.$broadcast('questionAnswerChanged', itemsByWfid[wfid])
            }

            /* Used in
			wfQuestionAnswering.directive.js
			 */
            function updateQuestionAnswerInfo_OLD(wfid, data, updateAnswersCache, organizationId, networkId, contextParentWfids) {
                setQuestionAnswerInfo(wfid, data)
                // if (allAnswerRelations)
                if (updateAnswersCache) {
                    allAnswerRelations = wfObject.filter({ where: {
                        type: 73,
                        parentType: enums.objectType.question,
                        childType: enums.objectType.questionAnswer,
                        organizationId,
                    } })
                    if (networkId) {
                        allAnswerInNetwork = wfObject.filter({ where: {
                            type: enums.objectType.visibilityTag,
                            networkId,
                            objectType: enums.objectType.questionAnswer,
                            organizationId,
                        } })
                    }
                    if (contextParentWfids && contextParentWfids.length) {
                        for (var i = 0, len = contextParentWfids.length; i < len; i++) {
                            allAnswersByContextParents = {}
                            allAnswersByContextParents[contextParentWfids[i]] = wfObject.filter({ where: {
                                type: enums.objectType.dataRelation,
                                parentData1: 3,
                                wffid: contextParentWfids[i],
                                childType: enums.objectType.questionAnswer,
                                organizationId,
                            } })
                            allAnswersContextParentsArrays = _.map(allAnswersByContextParents)
                            contextParentsIntersectionArguments = _.clone(allAnswersContextParentsArrays)
                            contextParentsIntersectionArguments.push('wfcid')
                        }
                    }
                }
                else if (allAnswerRelations) {
                    if (data.latestAnswerDataRelation) allAnswerRelations.push(data.latestAnswerDataRelation)
                    if (networkId && data.latestAnswerVisibilityTag) {
                        allAnswerInNetwork.push(data.latestAnswerVisibilityTag)
                    }
                    if (contextParentWfids && data.latestAnswerContextParentRelations) {
                        if (!allAnswersByContextParents) allAnswersByContextParents = {}

                        for (var i = 0, len = contextParentWfids.length; i < len; i++) {
                            if (!allAnswersByContextParents[contextParentWfids[i]]) allAnswersByContextParents[contextParentWfids[i]] = []

                            allAnswersByContextParents[contextParentWfids[i]].push(_.find(data.latestAnswerContextParentRelations, { wffid: contextParentWfids[i] }))
                        }
                    }
                }
                // console.log(itemsByWfid[wfid]);
                $scope.$emit('questionAnswerChanged', itemsByWfid[wfid])
                $scope.$broadcast('questionAnswerChanged', itemsByWfid[wfid])
            }

            /* Used in
			wfQuestionAnswering.directive.js
			 */
            function setQuestionAnswerInfo(wfid, data) {
                $timeout.cancel(initialCalcTimer)

                if (!itemsByWfid[wfid]) {
                    itemsByWfid[wfid] = {}
                    data.wfid = wfid
                    items.push(data)
                }

                _.assign(itemsByWfid[wfid], data)
                // When questions are being set up and setQuestionAnswerInfo are executed several times in a row,
                // by using a $timeout that cancels itself the checkLocalFulfillmentOnAll method is only executed when
                // all questions have been set up.
                initialCalcTimer = $timeout(() => {
                    $scope.fulfillsAllAnswersLocally = checkLocalFulfillmentOnAll()
                }, 0)
            }

            /* Used in
			wfInfluenceSigning.directive.js
			 */
            function checkLocalFulfillmentOnAll() {
                return getLocalFulfillmentData().unfulfilled.length === 0
            }

            /* Used in
			wfInfluenceSigning.directive.js
			 */
            function getLocalFulfillmentData() {
                const
                    unfulfilled = []
					
                const fulfilled = []
					
                const unfulfilledCompositeItems = []

                for (const key in itemsByWfid) {
                    if (itemsByWfid.hasOwnProperty(key)) {
                        const item = itemsByWfid[key]
                        if (item.fulfillsLocally === true) {
                            // If requirement rule is manual it is still required that there must be an answer before signing
                            if (item.requirement && item.requirement.rule === enums.requirementRule.manual) {
                                if (item.latestAnswerId) fulfilled.push(item.questionDataRelation)
                                else {
                                    unfulfilled.push(item.questionDataRelation)
                                    unfulfilledCompositeItems.push(item.itemComposite)
                                }
                            }
                            else fulfilled.push(item.questionDataRelation)
                        }
                        else if (item.fulfillsLocally === false) {
                            unfulfilled.push(item.questionDataRelation)
                            unfulfilledCompositeItems.push(item.itemComposite)
                        }
                    }
                }

                return {
                    fulfilled,
                    unfulfilled,
                    unfulfilledCompositeItems,
                }
            }

            /* Used in
			wfQuestionAnswering.directive.js
			wfQuestionAnsweringManager.directive.js
			 */
            function getNotVisibleLatestQuestionAnswer(question, organizationId, verification, networkId, contextParentWfids) {
                let
                    answerWithVisibility
					
                let answerWithoutVisibilityCheck

                if (!networkId) return

                answerWithVisibility = getLatestQuestionAnswer(question, organizationId, verification, networkId, contextParentWfids).latestAnswerDataRelation
                answerWithoutVisibilityCheck = getLatestQuestionAnswer(question, organizationId, verification, false, contextParentWfids).latestAnswerDataRelation

                if (answerWithVisibility === answerWithoutVisibilityCheck) return undefined
                else if (answerWithVisibility && answerWithoutVisibilityCheck && moment(answerWithoutVisibilityCheck.createdAt).isAfter(answerWithVisibility.createdAt)) return answerWithoutVisibilityCheck
                else if (!answerWithVisibility && answerWithoutVisibilityCheck) return answerWithoutVisibilityCheck
                else return undefined
            }

            /*
			Update the cache arrays/objects allAnswerRelations, allAnswerRelationsByQuestionWfid,
			allLatestAnswerRelations and allLatestAnswerRelationsByQuestionWfid
			 */
            function recacheAllAnswers(intersectionSettings) {
                let
                    array_all
					
                let lookupObject_all
					
                let array_latest
					
                let lookupObject_latest

                if (!questions) // If questions not defined the use all questions in JSData
                    questions = wfObject.filter({ where: { type: enums.objectType.question } })

                // Assign the correct arrays/lookupObjects and instantiate them if they are undefined
                if (typeof intersectionSettings.networkId === 'number' || typeof intersectionSettings.networkId === 'undefined' || intersectionSettings.networkId === null) {
                    array_all           = allAnswerRelations || (allAnswerRelations = [])
                    lookupObject_all    = allAnswerRelationsByQuestionWfid || (allAnswerRelationsByQuestionWfid = {})
                    array_latest        = allLatestAnswerRelations || (allLatestAnswerRelations = [])
                    lookupObject_latest = allLatestAnswerRelationsByQuestionWfid || (allLatestAnswerRelationsByQuestionWfid = {})
                }
                else if (intersectionSettings.networkId === false) { // If invisible answers show be fetched
                    array_all           = allInvisibleAnswerRelations || (allInvisibleAnswerRelations = [])
                    lookupObject_all    = allInvisibleAnswerRelationsByQuestionWfid || (allInvisibleAnswerRelationsByQuestionWfid = {})
                    array_latest        = allInvisibleLatestAnswerRelations || (allInvisibleLatestAnswerRelations = [])
                    lookupObject_latest = allInvisibleLatestAnswerRelationsByQuestionWfid || (allInvisibleLatestAnswerRelationsByQuestionWfid = {})

                    intersectionSettings = _.clone(intersectionSettings) // Clone before chaning networkId so that the original stays untouched
                    intersectionSettings.networkId = undefined // The actual networkId must be undefined to get invisible answers
                }

                // Empty arrays/lookupObjects
                array_all.length = 0
                for (var key in lookupObject_all) {
                    delete lookupObject_all[key]
                }
                array_latest.length = 0
                for (var key in lookupObject_latest) {
                    delete lookupObject_latest[key]
                }

                Array.prototype.push.apply(array_all, dataQuery.getAll.answersOnQuestions(questions, intersectionSettings))
                _.assign(lookupObject_all, _.groupBy(array_all, 'wffid'))

                // Same grouping logic as in dataQuery.getAll.latestAnswersOnQuestions
                Array.prototype.push.apply(array_latest, _.chain(array_all).sortBy(['wffid', 'createdAt']).groupBy('wffid').mapValues((dataRelations) => {
                    return dataRelations[dataRelations.length - 1]
                }).map().value())

                _.assign(lookupObject_latest, _.keyBy(array_latest, 'wffid'))
            }

            function ensureAnswerCache(intersectionSettings) {
                if (typeof intersectionSettings.networkId === 'number' || typeof intersectionSettings.networkId === 'undefined' || intersectionSettings.networkId === null) {
                    if (!allAnswerRelations) {
                        recacheAllAnswers(intersectionSettings)
                        return true
                    }
                    else return false
                }
                else if (intersectionSettings.networkId === false) {
                    if (!allInvisibleAnswerRelations) {
                        recacheAllAnswers(intersectionSettings)
                        return true
                    }
                    else return false
                }
                else return false

            }

            /* Used in
			wfQuestionAnswering.directive.js
			wfQuestionAnsweringManager.directive.js
			 */
            // Returns an object containing the latest questionAnswerDataRelation and an array of all questionAnswerDataRelations for the specified question
            function getLatestQuestionAnswer(question, organizationId, verification, networkId, contextParentWfids) {
                ensureAnswerCache({
                    organizationId,
                    networkId,
                    contextParents: contextParentWfids,
                })

                if (typeof networkId === 'number' || typeof networkId === 'undefined' || networkId === null) {
                    return {
                        allAnswersOnQuestion: allAnswerRelationsByQuestionWfid[question.wfid] || [],
                        latestAnswerDataRelation: allLatestAnswerRelationsByQuestionWfid[question.wfid],
                    }
                }
                else if (networkId === false) {
                    return {
                        allAnswersOnQuestion: allInvisibleAnswerRelationsByQuestionWfid[question.wfid] || [],
                        latestAnswerDataRelation: allInvisibleLatestAnswerRelationsByQuestionWfid[question.wfid],
                    }
                }
            }

            /* Used in
			wfQuestionAnswering.directive.js
			wfQuestionAnsweringManager.directive.js
			 */
            function getLatestQuestionAnswer_OLD(question, organizationId, verification, networkId, contextParentWfids) {
                let
                    allAnswersOnQuestion
					
                let latestAnswerDataRelation
					
                let lodashWrap

                // TODO: Implement network visibility

                if (!allAnswerRelations) {
                    allAnswerRelations = wfObject.filter({ where: {
                        type: enums.objectType.dataRelation,
                        parentType: enums.objectType.question,
                        childType: enums.objectType.questionAnswer,
                        organizationId,
                    } })
                }

                if (networkId) {
                    if (!allAnswerInNetwork) {
                        allAnswerInNetwork = wfObject.filter({ where: {
                            type: enums.objectType.visibilityTag,
                            networkId,
                            objectType: enums.objectType.questionAnswer,
                            organizationId,
                        } })
                    }

                    allAnswersOnQuestion = _.chain(allAnswerRelations).filter({ wffid: question.wfid }).intersectionBy(allAnswerInNetwork, 'wfcid').orderBy(['createdAt'], ['desc']).value()

                    // console.log(allAnswerRelations);
                    // console.log(allAnswerInNetwork);
                    // console.log(allAnswersOnQuestion);
                }

                if (contextParentWfids) {
                    if (!allAnswersByContextParents) {
                        allAnswersByContextParents = {}
                        for (let i = 0, len = contextParentWfids.length; i < len; i++) {
                            allAnswersByContextParents[contextParentWfids[i]] = wfObject.filter({ where: {
                                type: enums.objectType.dataRelation,
                                parentData1: 3,
                                wffid: contextParentWfids[i],
                                childType: enums.objectType.questionAnswer,
                                organizationId,
                            } })
                        }
                        allAnswersContextParentsArrays = _.map(allAnswersByContextParents)
                    }

                    contextParentsIntersectionArguments = _.clone(allAnswersContextParentsArrays)
                    contextParentsIntersectionArguments.push('wfcid')

                    if (allAnswersOnQuestion) { // If networkId was also defined
                        contextParentsIntersectionArguments.unshift(allAnswersOnQuestion)
                    }

                    lodashWrap = _.chain(allAnswerRelations).filter({ wffid: question.wfid })
                    allAnswersOnQuestion = lodashWrap.intersectionBy(...contextParentsIntersectionArguments).orderBy(['createdAt'], ['desc']).value()
                }

                if (!allAnswersOnQuestion) {
                    allAnswersOnQuestion = _.chain(allAnswerRelations).filter({ wffid: question.wfid }).orderBy(['createdAt'], ['desc']).value()
                }

                latestAnswerDataRelation = allAnswersOnQuestion[0]

                return {
                    allAnswersOnQuestion,
                    latestAnswerDataRelation,
                }
            }

            /* Used in
			hierarchical.controller.js
			 */
            function addCompositeItems(items, intersectionSettings) {
                // var
                // 	// questionWfids = _.map(items, "wfid"),
                // 	// latestQuestionAnswerDataRelationsByQuestionWfid = _.chain(wfObject.filter({ where: {
                // 	// 	type: 73,
                // 	// 	parentData1: null,
                // 	// 	wffid: { "in": questionWfids },
                // 	// 	parentType: enums.objectType.question,
                // 	// 	childType: enums.objectType.questionAnswer,
                // 	// 	organizationId: organizationId
                // 	// } })).sortBy([ "wffid", "createdAt" ]).groupBy("wffid").mapValues(function (dataRelations) {
                // 	// 	return _.last(dataRelations);
                // 	// }).map().keyBy("wffid").value()
                // 	// latestAnswers = _.keyBy(wfObject.filter({ where: {
                // 	// 	type: enums.objectType.questionAnswer,
                // 	// 	wfid: { "in": _.map(latestQuestionAnswerDataRelations, "wfcid") } }
                // 	// });
                // ;

                questions = items

                recacheAllAnswers(intersectionSettings)

                _.each(items, (itemComposite) => {
                    const
                        requirement = itemComposite.content.getRequirement(intersectionSettings.organizationId) || itemComposite.dataRelation.getRequirement(intersectionSettings.organizationId)
						
                    const validValues = requirement && requirement.value ? requirement.value.toString().split(',') : []
						
                    const latestAnswerDataRelation = allLatestAnswerRelationsByQuestionWfid[itemComposite.wfid]
					
                    setQuestionAnswerInfo(itemComposite.wfid, {
                        requirement,
                        fulfillsLocally: requirement ? answersMatchesRequirement(allLatestAnswerRelationsByQuestionWfid[itemComposite.wfid], validValues, requirement, itemComposite.dataRelation) : undefined,
                        latestAnswerId: latestAnswerDataRelation ? latestAnswerDataRelation.id : undefined,
                        questionDataRelation: itemComposite.dataRelation,
                        question: itemComposite.content,
                        itemComposite,
                    })
                })
            }

            function canHaveCertificateAttachments(questionDataRelation) {
                const objectTypes = _.get(questionDataRelation, 'settings.objectTypes')
                return objectTypes && objectTypes.length && objectTypes.indexOf(106) !== -1 // Certificate
            }

            function hasValidCertificate(questionAnswerContent) {
                const validCertificates = _.chain(questionAnswerContent.relatedContentByUser)
                    .filter({ childType: enums.objectType.certificate })
                    .filter((certificateDataRelation) => {
                        const certificate = certificateDataRelation.childContent

                        return moment().isBetween(certificate.validFromDate, certificate.validUntilDate)
                    }).value()

                return validCertificates.length !== 0
            }

            function answersMatchesRequirement(latestAnswerDataRelation, validValues, requirement, questionDataRelation)
            {
                const
                    latestAnswerContent = latestAnswerDataRelation ? latestAnswerDataRelation.childContent : null
					
                const answerId = latestAnswerDataRelation ? latestAnswerDataRelation.childContent.questionAnswerTypeId : null
					
                let fulfills = false
					
                let matchesRequiredValue = false
					
                let fulfillsException = false
					
                const isRelatedContentByUserCountOverZero = latestAnswerContent && latestAnswerContent.metadata && latestAnswerContent.metadata.countByRelationKind && latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] > 0

                if (requirement.rule === enums.requirementRule.manual) {
                    return true
                    // if (questionDataRelation.fulfillment && questionDataRelation.fulfillment.fulfills)
                    // 	return true;
                    // else
                    // 	return false;
                }

                if (answerId) matchesRequiredValue = validValues.indexOf(answerId.toString()) !== -1

                if (requirement.rule == enums.requirementRule.specificValues) {
                    fulfills = matchesRequiredValue
                }
                else if (requirement.rule == enums.requirementRule.anyValue) {
                    if (latestAnswerContent) fulfills = true
                }
                else if (requirement.rule == enums.requirementRule.preferredValue) {
                    if (matchesRequiredValue) fulfills = true
                    else {
                        if ((isRelatedContentByUserCountOverZero) || (latestAnswerContent && latestAnswerContent.relatedContentByUser.length)) {
                            fulfillsException = true
                        }
                    }
                }
                else if (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent) {
                    if (matchesRequiredValue) {
                        if ((isRelatedContentByUserCountOverZero) || (latestAnswerContent && latestAnswerContent.relatedContentByUser.length)) {
                            fulfills = true
                        }
                    }
                    else if (latestAnswerContent) {
                        fulfills = true
                    }
                }
                else if (requirement.rule == enums.requirementRule.anyValueWithRelatedContent) {
                    if ((isRelatedContentByUserCountOverZero) || (latestAnswerContent && latestAnswerContent.relatedContentByUser.length)) {
                        fulfills = true
                    }
                }
                else if (requirement.rule == enums.requirementRule.specificValueWithRelatedContent) {
                    if (matchesRequiredValue) {
                        if ((isRelatedContentByUserCountOverZero) || (latestAnswerContent && latestAnswerContent.relatedContentByUser.length)) {
                            fulfills = true
                        }
                    }
                }

                return fulfills || fulfillsException
            }

            /* Used in
			wfQuestionAnswering.directive.js
			 */
            function getContextParents(questionAnswerId, contextParentWfids) {
                if (!useContextAwareRelations) return
                else if (!contextParentWfids) return
                else {
                    return wfObject.filter({ where: {
                        type: enums.objectType.dataRelation,
                        parentData1: 3,
                        wffid: { in: contextParentWfids },
                        childType: enums.objectType.questionAnswer,
                        childId: questionAnswerId,
                        organizationId,
                    } })
                }
            }
        }
    }
})()
