import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('EventLogController', EventLogController)

    EventLogController.$inject = ['$scope', '$q', '$timeout', 'apiProxy', 'wfObject', '$rootScope', '$stateParams', '$state', 'screenLoaderService', 'dataOperationsService', '$translate', 'wfTranslate', 'wfAuth', 'valueChainService']
    function EventLogController($scope, $q, $timeout, apiProxy, wfObject, $rootScope, $stateParams, $state, screenLoader, dataOps, $translate, wfTranslate, wfAuth, valueChainService) {
        const
            vm = this
			
        let configurations
			
        let eventTypes
			
        let objectTypes
			
        const networkId = $stateParams.networkId

        _.assign(this, $scope.$parent.vm)

        _.assign(vm, {
            //Variables
            notifications: [],
            activityLogHeaderText: $translate.instant('User') + ' / ' + $translate.instant('Event'),
            dateTimeHeaderText: $translate.instant('Date') + ' / ' + $translate.instant('Time'),
            filtersLoaded: false,
            firstLog: undefined,
            heading: {
                preTitle: '',
                title: '',
                description: '',
            },
            isNotifications: $stateParams.isNotifications,
            enums,

            //Functions
            selectedUser: undefined,
            selectedEventType: undefined,
            selectedObjectType: undefined,
            eventSentence,
            allowObjectTypeToExpand,
        })

        vm.eventTypes = [
            { title: 'Create', id: enums.eventType.create },
            { title: 'Update', id: enums.eventType.update },
            { title: 'Delete', id: enums.eventType.delete },
            // { title: "Fulfill", id: enums.eventType.fulfill },
            // { title: "Unfulfill", id: enums.eventType.unfulfill },
            // { title: "Sign", id: enums.eventType.sign }
        ]

        vm.objectTypes = [
            { title: 'Activity', id: enums.objectType.orgActivity, icon: 'fa fa-calendar-check-o' },
            { title: 'Statement', id: enums.objectType.statement, icon: 'fa fa-quote-right' },
            { title: 'Document', id: enums.objectType.orgDocument, icon: 'fa fa-file' },
            { title: 'Link', id: enums.objectType.link, icon: 'fa fa-link' },
            { title: 'Media', id: enums.objectType.embed, icon: 'fa fa-play-circle' },
        ]
		
        if (networkId) {
            valueChainService.loadNetworks().then((result) => {
                const network = result.networksById[networkId]
                const pageHeaderItem = {
                    title: $translate.instant('Notifications'),
                    description: '',
                }
				
                if (network) vm.network = network
                else {
                    $state.go('valueChain-root')
                    return
                }

                vm.heading.preTitle = 'Data Collector'
                vm.heading.title = network.title + ' / ' + pageHeaderItem.title
				
                $rootScope.setPageTitle(pageHeaderItem.title)
				
                valueChainService.loadOrganizationsInNetwork(networkId, true).then((res) => {
                    vm.organizations = _.map(res, 'childContent')

                    // If there are duplicate relations to any organization then those duplicate will have undefined childContent
                    // (since the result is not injected into JSData) so remove them here.
                    _.remove(vm.organizations, (item) => {
                        return typeof item === 'undefined'
                    })

                    vm.filterConfig = getFilterConfig('notificationsConfig')
                    vm.pageLoaded = true
                    vm.filtersLoaded = true
                })
            })
        }
        else {
            dataOps.getOrganizationUsers().then((users) => {
                vm.isLogEvents = true
                vm.organizationUsers = users
                loadEventLogValues()
            })
        }
		
        function loadEventLogValues() {
            dataOps.getObject({
                objectId: $stateParams.objectId, // EventLog table: 11723
                objectType: 71,
                childrenLoadDepth: 0,
                onlyStatistics: true,
            }).then((res) => {
                vm.item = res
                if (_.get(res, 'metadata.statistics.values')) vm.firstLogEventDate = (_.find(res.metadata.statistics.values, { id: 'minCreatedAt' }) || {}).value

                vm.heading.title = vm.item.title
                vm.heading.description = vm.item.description
                vm.heading.guidance = vm.item.guidance
                vm.heading.guidanceVideoUrl = vm.item.guidanceVideoUrl
                vm.pageLoaded = true
				
                vm.usersFilterLoaded = true
                vm.filterConfig = getFilterConfig('eventLogConfig')
                vm.filtersLoaded = true
                $timeout()
            })
        }

        function eventSentence(item) {
            let
                output = ''
                
            const objType = item.objectType
                
            const eventType = item.eventType
			
            // if (!item.childContent.organization) return;

            if (objType === enums.objectType.influence) {
                if (eventType === enums.eventType.fulfill) {
                    output = $translate.instant('modules.notifications.eventSentences.influenceFulfilled') + ' ' + item.childContent.organization.name
                }
                else if (eventType === enums.eventType.unfulfill) {
                    output = $translate.instant('modules.notifications.eventSentences.influenceUnfulfilled') + ' ' + item.childContent.organization.name
                }
            }
            else {
                output = [
                    wfTranslate.instant('MAP_ObjectType', { type: item.objectType }),
                    wfTranslate.instant('MAP_EventType', { type: item.eventType, pastTense: true }).toLowerCase(),
                    $translate.instant('By').toLowerCase(),
                    // item.childContent.organization.name
                ].join(' ')
            }

            return output

            // return wfTranslate.instant('MAP_EventType', { type: item.eventType }) + ' ' + wfTranslate.instant('MAP_ObjectType', { type: item.objectType });
        }

        function navigateToItem(item) {
            switch (item.childContent.type)
            {
                case 73: // DataRelation
                    $state.go('dataRelation', { idPath: item.childContent.id })
                    break
                case 71: // Structure
                    $state.go('structure', { idPath: item.childContent.id })
                    break
                case 52: // Network
                    $state.go('network', { idPath: item.childContent.id })
                    break
                case 13: // Influence
                    $state.go('influence', { idPath: item.childContent.id })
                    break
            }
            return false
        }

        function getFilterConfig(config) {
            if (configurations) return configurations[config]

            if (config == 'eventLogConfig') {
                eventTypes = [
                    { title: 'Create', id: enums.eventType.create },
                    { title: 'Update', id: enums.eventType.update },
                    { title: 'Delete', id: enums.eventType.delete },
                ]
            }
            else if (config === 'notificationsConfig') {
                eventTypes = [
                    { title: 'Fulfill', id: enums.eventType.fulfill },
                    { title: 'Unfulfill', id: enums.eventType.unfulfill },
                    { title: 'Sign', id: enums.eventType.sign },
                ]
            }

            objectTypes = [
                { title: 'Activity', id: enums.objectType.orgActivity, icon: 'fa fa-calendar-check-o' },
                { title: 'Statement', id: enums.objectType.statement, icon: 'fa fa-quote-right' },
                { title: 'Document', id: enums.objectType.orgDocument, icon: 'fa fa-file' },
                { title: 'Link', id: enums.objectType.link, icon: 'fa fa-link' },
                { title: 'Media', id: enums.objectType.embed, icon: 'fa fa-play-circle' },
            ]

            _.each(objectTypes, (item) => {
                item.title = wfTranslate.instant('MAP_ObjectType', { type: item.id })
            })
	
            _.each(eventTypes, (item) => {
                item.title = wfTranslate.instant('MAP_EventType', { type: item.id })
            })

            configurations = {
                eventLogConfig: {
                    controllerAs: 'logVm',
                    infiniteScroll: true,
                    useServer: {
                        method: 'getObject',
                        baseParams: {
                            objectId: 11723,
                            objectType: 71,
                            bypassCache: true,
                        },
                        handleResponse(items) {
                            return _.filter(items, (dataRelation) => {
                                return _.get(dataRelation, 'childContent.objectData')
                            })
                        },
                    },
                    filters: [
                        [
                            {
                                placeholder: $translate.instant('Search'),
                                label: $translate.instant('modules.filters.filterByUser'),
                                filterOptionsSource: vm.organizationUsers,
                                getterParamName: 'userIds',
                                displayAs: 'dropdown',
                            },
                            {
                                placeholder: $translate.instant('Search'),
                                label: $translate.instant('modules.filters.filterByObjectType'),
                                filterOptionsSource: objectTypes,
                                getterParamName: 'objectTypes',
                                displayAs: 'dropdown',
                            },
                            {
                                placeholder: $translate.instant('Search'),
                                label: $translate.instant('modules.filters.filterByEventType'),
                                filterOptionsSource: eventTypes,
                                getterParamName: 'eventTypes',
                                displayAs: 'dropdown',
                            },
                        ],
                        {
                            label: $translate.instant('modules.filters.filterByDate'),
                            displayAs: 'dateRangePicker',
                            includeSlider: true,
                            minValue: vm.firstLogEventDate,
                        },
                    ],
                },
                notificationsConfig: {
                    controllerAs: 'logVm',
                    infiniteScroll: true,
                    useServer: {
                        method: 'getObjects',
                        baseParams: {
                            objectType: enums.objectType.notification,
                            getterConditions: {
                                networkId,
                            },
                            wrapInRelations: false,
                        },
                        handleResponse(items) {
                            return _.filter(items, (notification) => {
                                return _.get(notification, 'objectData')
                            })
                        },
                    },
                    filters: [
                        [
                            {
                                placeholder: $translate.instant('Search'),
                                label: $translate.instant('modules.filters.filterByOrganization'),
                                filterOptionsSource: vm.organizations,
                                getterParamName: 'organizationIds',
                                displayAs: 'dropdown',
                            },
                            {
                                placeholder: $translate.instant('Search'),
                                label: $translate.instant('modules.filters.filterByEventType'),
                                filterOptionsSource: eventTypes,
                                getterParamName: 'eventTypes',
                                displayAs: 'dropdown',
                            },
                            {
                                label: $translate.instant('modules.filters.filterByDate'),
                                displayAs: 'dateRangePicker',
                                includeSlider: false,
                                minValue: vm.firstLogEventDate,
                            },
                        ],
                    ],
                },
            }
			
            return configurations[config]
        }

        function allowObjectTypeToExpand(objectType) {
            switch (objectType) {
                case enums.objectType.dataRelation:
                case enums.objectType.statement:
                case enums.objectType.organization:
                case enums.objectType.orgDocument:
                case enums.objectType.measureAnswer:
                case enums.objectType.measure:
                case enums.objectType.orgActivity:
                case enums.objectType.link:
                case enums.objectType.parameterValue:
                case enums.objectType.influence:
                    return true
                default:
                    return false
            }
			
        }
    }
})()
