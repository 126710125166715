import * as enums from '@worldfavor/constants/enums'
/*
 * DATA OPERATIONS SERVICE
 *
 * Used for performing operations on data
 *
 */
(function () {
    'use strict'

    angular
        .module('wf.data')
        .service('dataOperationsService', dataOperations)

    dataOperations.$inject = ['$q', '$timeout', 'apiProxy', '$rootScope', '$ngBootbox', '$translate', 'wfAuth', 'dataOperationsCache'] // Removed wfObject because of circular reference

    function dataOperations($q, $timeout, apiProxy, $rootScope, $ngBootbox, $translate, wfAuth, dataOpsCache) {
        const service = {
            calculateFulfillment,
            clearCachedRequests,
            create,
            createAnswerOnQuestion,
            createInfluence,
            createSubItemRelation,
            createVirtualSubItemRelation,
            destroy,
            eject,
            getObject,
            getObjects,
            getObjectByPath,
            getSubItems,
            getSubItemsCount,
            getSubItemsOfAll,
            getIndustriesFromOrganization,
            prepareWfObject,
            saveSettings,
            setAllNotificationsToSeen,
            update,
            verifyItem,
            saveDataRelationsOrder,
            reorder,
            getOrganizationUsers,
            linkageLoader,
            setVerification,
            persistentDataNegotiator,
            clearPersistentNegotiators,
            getMeasureTargets,
            saveMeasureTargets,
            clearRelativeMeasureResultsCache,
            getRelativeMeasureResultsCacheInfo,
            fetchUploadedFile,
        }

        const relationPropertyNameByKind = {
            1: 'childs',
            2: 'parents',
            3: 'verifications',
            4: 'relatedContent',
            5: 'relatedContentByUser',
        }

        window.getObjects = getObjects

        // Note about culture:
        // The user culture (sv-SE, en-US etc) must be included as a parameter in every request to the Worldfavor API that will return multilingual data.
        // Because: If the user goes to the Account Settings page and changes language, it is not possible to change the culture claim encoded inside the bearer token
        //          without reauthenticating the user with the Auth0 login box.

        return service

        function clearCachedRequests() {
            dataOpsCache.clearCachedRequests()
        }

        function eject(wfid) {
            dataOpsCache.eject(wfid)
        }

        function hasRequestAlreadyBeenMade(wfid, apiParams) {
            return dataOpsCache.hasRequestAlreadyBeenMade(wfid, apiParams)
        }

        function rememberRequest(wfid, apiParams) {
            dataOpsCache.rememberRequest(wfid, apiParams)
        }

        function prepareWfObject(attrs, onlyPrimitiveTypes) {
            const ownAttrs = {}

            if (!attrs) return undefined

            for (const key in attrs) {
                // The api proxy uses jQuery's AJAX logic internally which, when parameters are serialized, erroneously tries to invoke prototype functions
                // that are defined by JSData on the WfObject instance. To prevent this behaviour the properties are looped through and only the properties
                // that exists directly on the object will be sent in the request.

                //console.log(key, attrs.hasOwnProperty(key), Object.getOwnPropertyDescriptor(attrs, key))

                if (attrs.hasOwnProperty(key) && typeof attrs[key] !== 'function' && key !== '_cachedChildContent' && key !== '_cachedParentContent') {
                    if (!onlyPrimitiveTypes || (typeof attrs[key] === 'string' || typeof attrs[key] === 'number' || typeof attrs[key] === 'boolean')) ownAttrs[key] = attrs[key]
                }
            }

            return ownAttrs
        }

        function create(item, options) {
            // signalR.objectCreated(item.wfid);
            const networkId = _.get(options, 'ticket.networkId') || _.get(options, 'networkId')
            const contextParents = _.get(options, 'ticket.contextParents') || _.get(options, 'contextParents')
            const thirdParty = _.get(options, 'ticket.thirdParty') || _.get(options, 'thirdParty')

            return wfObject.create({
                item: prepareWfObject(item),
                influence: options && options.influence ? prepareWfObject(options.influence) : undefined,
                networkId,
                contextParents,
                byUser: options && options.byUser ? options.byUser : undefined,
                culture: wfAuth.getCulture(),
                thirdParty,
            })
        }

        function createInfluence(options) {
            let
                dataRelationId

            let item

            const jqDf = $.Deferred()

            let contextParentWfid

            options = angular.extend({
                organization: {},
                userId: null,
                item: undefined,
                itemBucket: undefined,
                organizationInputMethod: 1,
                channelId: 1,
                context: undefined,
                isInternal: false,
                allowDataRelations: false,
                contextParentWfids: undefined,
                onCreated: undefined,
                comment: undefined,
            }, options)

            if (options.itemBucket)
            {
                if (options.itemBucket.item) options.item = options.itemBucket.item
            }

            if (!options.item) {
                $ngBootbox.customDialog({
                    message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $translate.instant('modules.valueChain.influence.message_noStructureSelected') + '</div>',
                    // onEscape: true,
                    closeButton: false,
                    className: 'centerWithIcon',
                    buttons: {
                        primary: {
                            label: $translate.instant('OK'),
                            className: 'btn-primary',
                        },
                    },
                })
                return
            }

            if (!options.allowDataRelations && options.item.type === 73)
            {
                dataRelationId = options.item.id
                item = options.item.childContent
            }
            else
            {
                item = options.item
            }

            if (!options.contextParentWfids && (contextParentWfid = _.get(item, 'conditions.requirementPackageSettings.targetContextParentWfid'))) {
                options.contextParentWfids = contextParentWfid
            }

            create({
                type: 13,
                organizationId: options.organization.id,
                userId: options.userId,
                isInternal: options.isInternal,
                objectId: item.id,
                objectType: item.type,
                organizationInputMethod: options.organizationInputMethod,
                dataRelationId,
                channelId: options.channelId,
                fulfillmentDueAt: options.influenceModel.fulfillmentDueAt,
                useViewModelActivationDate: options.influenceModel.hasOwnProperty('activatedAt'),
                activatedAt: options.influenceModel.hasOwnProperty('activatedAt') ? options.influenceModel.activatedAt : undefined,
                contextParentWfids: options.contextParentWfids,
                comment: options.influenceModel.comment,
            }).then((influence) => { // JSData doesn't properly chain promises so after the first ".then" the argument is lost, therefore another promise is resolved
                jqDf.resolve(influence)

                if (options.context && options.context.modalInstance) options.context.modalInstance.close()

                if (typeof options.onCreated === 'function') {
                    options.onCreated(influence) // Defined in valueChainService.openInfluenceCreator and then passed from template 57
                }
            })

            return jqDf.promise()
        }

        function update(existingOrUpdatedItem, updatedItemOrOptions, options) {
            let updatedItem; let existingItem

            if (arguments.length >= 2)
            {
                if (updatedItemOrOptions.type && updatedItemOrOptions.id) {
                    existingItem = existingOrUpdatedItem
                    updatedItem = updatedItemOrOptions
                    return wfObject.update(existingItem.wfid,
                        {
                            item: prepareWfObject(updatedItem),
                            culture: wfAuth.getCulture(),
                            thirdParty: _.get(options, 'thirdParty'),
                        })
                }
                else {
                    updatedItem = existingOrUpdatedItem
                    options = updatedItemOrOptions
                    return wfObject.update(updatedItem.wfid,
                        {
                            item: prepareWfObject(updatedItem),
                            culture: wfAuth.getCulture(),
                            ignoreMultilingualProperties: options.ignoreMultilingualProperties,
                            thirdParty: _.get(options, 'thirdParty'),
                        })
                }
            }
            else if (arguments.length == 1)
            {
                updatedItem = existingOrUpdatedItem
                return wfObject.update(updatedItem.wfid,
                    {
                        item: prepareWfObject(updatedItem),
                        culture: wfAuth.getCulture(),
                        thirdParty: _.get(options, 'thirdParty'),
                    })
            }
        }

        function destroy(item, options) {
            const df = $q.defer(); let wfid

            if (item) {
                wfid = item.wfid
                item = prepareWfObject(item)

                if (_.get(options, 'ticket')) {
                    item.ticket = options.ticket
                }

                if (item.type === enums.objectType.measureAnswer && item.notAvailable) {
                    item.value = ''
                }

                // signalR.objectDeleted(item.wfid);
                wfObject.destroy(wfid, { item, thirdParty: _.get(options, 'thirdParty') }).then((res) => {
                    df.resolve(res)
                }) // The wfid is needed for JSData and the second argument is the params that are sent to the API
            }
            else {
                df.resolve({})
            }

            return df.promise
        }

        // Saves the order on DataRelations according to their position in the array.
        function saveDataRelationsOrder(dataRelations) {
            const
                jqDf = $.Deferred()

            let dataRelationIds

            dataRelationIds = _.map(dataRelations, 'id')

            // Update the order value on each dataRelation, the position of each item in the array will be used as the order value (index + 1)
            apiProxy('utility.reorderDatarelations', {
                dataRelationIds,
            }).then(() => {
                // In front-end, the order property on each dataRelation item are simply set to the new value
                for (let i = 0, len = dataRelations.length; i < len; i++) {
                    dataRelations[i].order = i
                }

                jqDf.resolve()
            })

            return jqDf.promise()
        }

        // Saves the order on DataRelations according to their position in the array.
        // If an item and direction is provided the item will be moved "up" or "down" one position within the array.
        // The provided item must exist within the array.
        function reorder(array, item, direction) {
            const
                arrayLength = array.length

            const moveUp = direction === 'up'

            const moveDown = direction === 'down'

            let currentIndex

            let old_index; let new_index; let k

            const jqDf = $.Deferred()

            const promise = jqDf.promise()

            if (!arrayLength) {
                jqDf.resolve()
                return promise
            }

            // Only reorder if a direction and item is specified
            if (item && typeof direction === 'string') {
                currentIndex = _.indexOf(array, item)

                if ((moveUp && currentIndex === 0) || (moveDown && currentIndex === arrayLength - 1)) {
                    jqDf.resolve()
                    return promise
                }

                // Logic for moving an element in an array to a new position
                old_index = currentIndex
                new_index = moveUp ? currentIndex - 1 : currentIndex + 1

                while (old_index < 0) {
                    old_index += arrayLength
                }
                while (new_index < 0) {
                    new_index += arrayLength
                }
                if (new_index >= arrayLength) {
                    k = new_index - arrayLength
                    while ((k--) + 1) {
                        arr.push(undefined)
                    }
                }

                array.splice(new_index, 0, array.splice(old_index, 1)[0])
            }

            saveDataRelationsOrder(array).then(() => {
                jqDf.resolve()
            })

            return promise
        }

        // Arguments style 1: item1, item2, kind, virtualItem, order
        // Arguments style 2: item1, item2, { kind, virtualItem, order, networkId }
        function createSubItemRelation(item1, item2) {
            const df = $q.defer()
            let options; let kind; let virtualItem; let order; let networkId; let contextParentWfid; let ticket; let thirdParty

            function prepareItem(itemOrWfid) {
                if (typeof itemOrWfid === 'string') // String implies that it is a wfid
                    return { type: itemOrWfid.split('-')[0], id: itemOrWfid.split('-')[1] }
                else return prepareWfObject(itemOrWfid, true) // Otherwise it should be a wfObject that needs to be prepared (have getter properties excluded)
            }

            if (typeof arguments[2] === 'object') {
                options = arguments[2]

                kind        = options.kind
                virtualItem = options.virtualItem
                order       = options.order
                networkId   = options.networkId
                contextParentWfid = options.contextParentWfid || _.get(options.ticket, 'contextParentWfid')
                ticket      = options.ticket
                thirdParty = options.thirdParty || _.get(options.ticket, 'thirdParty')
            }
            else {
                kind        = arguments[2]
                virtualItem = arguments[3]
                order       = arguments[4]
            }

            // item1 = { type: item1.type, id: item1.id };
            // item2 = { type: item2.type, id: item2.id };

            wfObject.create({
                item1: prepareItem(item1, true),
                item2: prepareItem(item2, true),
                virtualItem1: prepareWfObject(virtualItem),
                order,
                kind, // Kind can be Children, Related content, Verifications etc
                networkId,
                contextParentWfid,
                culture: wfAuth.getCulture(),
                ticket,
                thirdParty,
            }, {
                action: 'multi.createSubItemRelation',
            }).then((res) => {
                df.resolve(res)
            }, (res) => {
                df.reject(res)
            })

            return df.promise
        }

        function createVirtualSubItemRelation(item1, item2, kind, virtualItem1) {
            return wfObject.create({
                item1: prepareWfObject(item1, true),
                item2: prepareWfObject(item2, true),
                virtualItem1: prepareWfObject(virtualItem1),
                kind, // Kind can be Children, Related content, Verifications etc
                virtual: true,
                culture: wfAuth.getCulture(),
            }, {
                action: 'multi.createSubItemRelation',
            })
        }

        function getSubItemsOfAll(items, kindOfItemsToGet, options) {
            const
                count = items.length

            const i = 0

            const jqDf = $.Deferred()

            let apiParams

            const itemsApiParams = []

            let ticket

            if (count === 0) jqDf.resolve()

            if (options && options.ticket) {
                ticket = options.ticket
            }

            _.each(items, (itemOrWfid) => {
                let item
                if (typeof itemOrWfid === 'string') { // Implies that the item is a wfid
                    item = {
                        wfid: itemOrWfid,
                        type: itemOrWfid.split('-')[0],
                        id: itemOrWfid.split('-')[1],
                    }
                }
                else item = itemOrWfid

                apiParams = {
                    item: {
                        id: item.id,
                        type: item.type,
                    },
                    kind: kindOfItemsToGet,
                }
                apiParams = _.assign(apiParams, options)

                if ((options && options.bypassCache) || !hasRequestAlreadyBeenMade(item.wfid, apiParams))
                {
                    itemsApiParams.push(_.clone(apiParams))
                }

                delete apiParams.ticket // Ticket is needed for hasRequestAlreadyBeenMade() but should not be in here in request
            })

            if (itemsApiParams.length == 0) {
                jqDf.resolve()
            }
            else {
                const dummyWfid = 'dummy_subItemsOfall_' + kindOfItemsToGet + '_' + moment().format('x') + '_' + _.uniqueId()
                wfObject.findAll({
                    wfid: dummyWfid, // Needs unique query
                }, {
                    bypassCache: true,
                    apiParams: {
                        action: 'getSubItemsOfAll',
                        culture: wfAuth.getCulture(),
                        items: itemsApiParams,
                        ticket,
                    },
                }).then(() => {
                    jqDf.resolve()
                })
            }

            // _.each(items, function(item) {
            // 	getSubItems(item, kindOfItemsToGet, options).then(function () {
            // 		i++;
            // 		if (i === count)
            // 		{
            // 			jqDf.resolve();
            // 		}
            // 	});
            // });

            return jqDf.promise()
        }

        function getSubItemsCount(item, kindOfItemsToGet) {
            const
                propertyName = relationPropertyNameByKind[kindOfItemsToGet]

            const jqDf = $.Deferred()

            if (item.count && typeof item.count[propertyName] === 'number')
            {
                // console.log("in cache"),
                setTimeout(() => {
                    jqDf.resolve(item.count[propertyName])

                }, 100)
            }
            else
            {
                apiProxy('multi.getSubItems', {
                    item: prepareWfObject(item),
                    kind: kindOfItemsToGet,
                    onlyStatistics: true,
                }).then((res) => {
                    // console.log("not in cache"),
                    item.count = item.count || {}
                    item.count[propertyName] = res.count
                    jqDf.resolve(res.count)
                })
            }

            return jqDf.promise()
        }

        function getSubItems(itemOrWfid, kindOfItemsToGet, options) {
            const
                query = {}

            const jsdataOptions = {}

            const skipExtras = _.get(options, 'skipExtras')

            let apiParams

            let ejectQuery

            let item = {}

            let relationType
            // jqDf = $.Deferred()

            options = options || {}

            if (skipExtras) delete options.skipExtras

            if (!itemOrWfid.wfid && itemOrWfid.type && itemOrWfid.id)
            {
                item.wfid = itemOrWfid.type + '-'  + itemOrWfid.id
                item.type = itemOrWfid.type
                item.id = itemOrWfid.id
            }
            else if (itemOrWfid.wfid && !itemOrWfid.type && !itemOrWfid.id)
            {
                item.wfid = itemOrWfid.wfid
                item.type = parseInt(itemOrWfid.wfid.split('-')[0])
                item.id = parseInt(itemOrWfid.wfid.split('-')[1])
            }
            else if (typeof itemOrWfid === 'string') {
                item = {
                    wfid: itemOrWfid,
                    type: parseInt(itemOrWfid.split('-')[0]),
                    id: parseInt(itemOrWfid.split('-')[1]),
                }
            }
            else {
                item = itemOrWfid
            }

            //action: "getSubItems",
            //	//item: prepareWfObject(item),
            //	item: {
            //		id: item.id,
            //		type: item.type
            //	},
            //	kind: kindOfItemsToGet // Kind can be Children, Related content, Verifications etc
            switch (kindOfItemsToGet) {
                case 1: // Children
                    query.where = {
                        type: 73,
                        wffid: item.wfid,
                        parentData1: null,
                    }
                    break
                case 2: // Parents
                    query.where = {
                        type: 73,
                        wfcid: item.wfid,
                        parentData1: null,
                    }
                    break
                    // case 3: // Verifications
                    // 	query.where = {
                    // 		type: 73,
                    // 		wfcid: item.wfid,
                    // 		parentData1: null
                    // 	};
                    // 	break;
                case 4: // Related content by creator
                    query.where = {
                        type: 73,
                        wffid: item.wfid,
                        parentData1: 1,
                        organizationId: null,
                    }
                    break
                case 5: // Related content by user
                    query.where = {
                        type: 73,
                        wffid: item.wfid,
                        parentData1: 1,
                        organizationId: wfAuth.getOrganizationId() || 0,
                    }
                    break
                case 7: // Children by user
                    query.where = {
                        type: 73,
                        wffid: item.wfid,
                        parentData1: null,
                        organizationId: wfAuth.getOrganizationId() || 0,
                    }
                    break
                case 8: // Parents by user
                    query.where = {
                        type: 73,
                        wfcid: item.wfid,
                        parentData1: null,
                        organizationId: wfAuth.getOrganizationId() || 0,
                    }
                    break

				// TODO: Other queries needs to be added here!
            }

            let ticket = options.ticket
            if (ticket) {
                ticket = _.cloneDeep(ticket)
                if (ticket.contextParents) {
                    ticket.contextParentWfids = ticket.contextParents
                    delete ticket.contextParents
                }
            }

            apiParams = {
                action: 'getSubItems',
                item: {
                    id: item.id,
                    type: item.type,
                },
                childrenLoadDepth: options.childrenLoadDepth,
                skipChildContentOnDepth: options.skipChildContentOnDepth,
                aggregateObjectType: options.aggregateObjectType,
                aggregate: options.aggregate,
                networkId: options.networkId,
                onlyStatistics: options.onlyStatistics,
                ticket,
                kind: kindOfItemsToGet, // Kind can be Children, Related content, Verifications etc
                useOrganizationMatchModeFromKind: options.useOrganizationMatchModeFromKind,
                getterConditions: options.getterConditions,
                useDeletedDataRelations: options.useDeletedDataRelations,
                includeDataRelationsMetadataCount: options.includeDataRelationsMetadataCount,
                loadMetadata: options.loadMetadata,
                loadParents: options.loadParents,
                loadVisibilityTags: options.loadVisibilityTags,
                onlyLoadRelations: options.onlyLoadRelations,
                limit: options.limit,
                loadCreators: true,
            }

            if (options.searchString) {
                jsdataOptions.bypassCache = true
                jsdataOptions.cacheResponse = true
                apiParams.searchString = options.searchString

                ejectQuery = { where: { parentData1: null, type: 82 } }
                switch (kindOfItemsToGet) {
                    case 1: // Children
                        ejectQuery.where.wffid = item.wfid; break
                    case 2: // Parents
                        ejectQuery.where.wfcid = item.wfid; break
                    case 4: // Related content
                        ejectQuery.where.wffid = item.wfid
                        ejectQuery.where.parentData1 = 1; break
                }
                wfObject.ejectAll(ejectQuery)
            }
            else
            {
                if (skipExtras) {
                    apiParams = _.defaultsDeep({
                        loadParents: false,
                        loadMetadata: false,
                        loadVisibilityTags: false,
                        getterConditions: {
                            loadCreators: !!_.get(options, 'loadCreators'),
                            includeOrganizations: false,
                        },
                    }, apiParams)
                }

                // bypassCache is an option built into JSData
                if (hasRequestAlreadyBeenMade(item.wfid, apiParams))
                {
                    // If the request has been made before but bypassCache is true then still go to server
                    if (options.bypassCache) jsdataOptions.bypassCache = true
                }
                else
                {
                    // If the request has not been made before then remember the request and set bypassCache to true
                    jsdataOptions.bypassCache = true
                    rememberRequest(item.wfid, apiParams)
                }

                // if (hasRequestAlreadyBeenMade(item.wfid, apiParams))
                // {
                // 	jsdataOptions.bypassCache = false;
                // }
                // else
                // {
                // 	jsdataOptions.bypassCache = true;
                // 	rememberRequest(item.wfid, apiParams);
                // }
            }

            if (apiParams.useDeletedDataRelations) {
                query.where.type = enums.objectType.historicDataRelation
            }

            jsdataOptions.apiParams = apiParams
            jsdataOptions.apiParams.culture = wfAuth.getCulture()

            // jsdataOptions.bypassCache = false;
            // jqDf.resolve(wfObject.filter(query));

            // return jqDf.promise();
            return wfObject.findAll(query, jsdataOptions)
        }

        function getObject() {
            let
                wfid

            let options

            let skipExtras

            const df = $q.defer()

            let objId; let objType

            let a

            if (skipExtras) delete options.skipExtras

            if (typeof arguments[0] === 'string')
            {
                wfid = arguments[0]
                options = arguments[1] || { childrenLoadDepth: 0 }

                skipExtras = _.get(options, 'skipExtras')
                if (skipExtras) delete options.skipExtras

                a = wfid.split('-')
                objType = parseInt(a[0])
                objId = parseInt(a[1])
                options.apiParams = {
                    objectType: objType,
                    objectId: objId,
                    childrenLoadDepth: options.childrenLoadDepth,
                    skipChildContentOnDepth: options.skipChildContentOnDepth,
                    getterConditions: options.getterConditions,
                    ignoreAdditionalLoadDepthLimits: options.ignoreAdditionalLoadDepthLimits,
                    includeChildrensRelatedContent: options.includeChildrensRelatedContent,
                    includeChildrensChildren: options.includeChildrensChildren,
                    includeChildrensParents: options.includeChildrensParents,
                    includeMultilingual: options.includeMultilingual,
                    useDeletedDataRelations: options.useDeletedDataRelations,
                    ticket: options.ticket,
                    readFromCache: options.readFromCache,
                    onlyStatistics: options.onlyStatistics,
                }

                delete options.childrenLoadDepth
                delete options.getterConditions
                delete options.includeChildrensRelatedContent
                delete options.includeChildrensChildren
                delete options.includeChildrensParents
                delete options.includeMultilingual
                delete options.useDeletedDataRelations
                delete options.readFromCache
                delete options.onlyStatistics
            }
            else
            {
                options = arguments[0]
                skipExtras = _.get(options, 'skipExtras')
                if (skipExtras) delete options.skipExtras

                if (!options.objectType && options.type) {
                    options.objectType = options.type
                    delete options.type
                }
                if (!options.objectId && options.id) {
                    options.objectId = options.id
                    delete options.id
                }

                delete options.wfid
                wfid = options.objectType + '-' + options.objectId
                options = {
                    apiParams: options,
                    cacheResponse: 'cacheResponse' in options ? !!options.cacheResponse : true,
                    bypassCache: options.bypassCache || false,
                }
                // delete options.cacheResponse;
                // delete options.bypassCache;
            }

            if (skipExtras) {
                options.apiParams = _.defaultsDeep({
                    loadParents: false,
                    loadMetadata: false,
                    loadVisibilityTags: false,
                    getterConditions: {
                        loadCreators: !!_.get(options, 'loadCreators'),
                        includeOrganizations: false,
                    },
                }, options.apiParams)
            }

            // console.log(wfid, options);
            // options.bypassCache = false;
            if (!options.bypassCache) {
                if (hasRequestAlreadyBeenMade(wfid, options.apiParams))
                {
                    options.bypassCache = false
                }
                else
                {
                    options.bypassCache = true
                    rememberRequest(wfid, options.apiParams)
                }
            }

            if (options.suppressErrorMessage) {
                options.apiParams.suppressErrorMessage = true
            }

            options.apiParams.culture = wfAuth.getCulture()

            if (options.apiParams.objectType && !(options.apiParams.objectId || options.apiParams.auth0UserId)) {
                df.resolve({ wfid: -1 })
            }
            else {
                wfObject.find(wfid, options).then((res) => {
                    if (res.conditions && res.conditions.firstSubItemAsRoot) res = res.childs[0]

                    df.resolve(res)
                }).catch(function(e) {
                    console.error('Could not getObject(): ', e)
                    df.reject(...arguments)
                })
            }

            return df.promise
        }

        function getObjects() {
            let

                jqDf

            let query

            let alreadyInCache

            let arrayOfWfid

            let apiParams

            let bypassCache = true

            if (_.isArray(arguments[0])) {
                arrayOfWfid = arguments[0]
                const ignoreCache = arguments[1]
                query = {
                    where: {
                        wfid: { in: arrayOfWfid },
                    },
                }

                if (ignoreCache !== true) {
                    // Get items that are already the cache
                    alreadyInCache = wfObject.filter(query)
                    // Exclude already existing items
                    arrayOfWfid = _.difference(arrayOfWfid, _.map(alreadyInCache, 'wfid'))
                }

                if (arrayOfWfid.length == 0) { // If 0 then all items are already in the cache
                    jqDf = $.Deferred()

                    jqDf.resolve(alreadyInCache)
                    return jqDf.promise()
                }
                else {
                    // Get items that are on not in the cache
                    return wfObject.findAll(query, {
                        apiParams: {
                            wfids: arrayOfWfid,
                            culture: wfAuth.getCulture(),
                        },
                    })
                }
            }
            else if (typeof arguments[0] === 'object') {
                apiParams = arguments[0]
                query = { wfid: 'dummy_' + moment().format('x') }
                // // bypassCache =
                if (apiParams.requestSignature_noResultNeeded && !apiParams.bypassCache) {
                    query.wfid = apiParams.requestSignature_noResultNeeded
                    if (hasRequestAlreadyBeenMade(apiParams.requestSignature_noResultNeeded, apiParams))
                    {
                        bypassCache = false
                    }
                    else
                    {
                        bypassCache = true
                        rememberRequest(apiParams.requestSignature_noResultNeeded, apiParams)
                    }
                }
                // else {
                // 	console.info("Missing property requestSignature_noResultNeeded when invoking getObjects with object argument.", apiParams);
                // }

                apiParams.culture = wfAuth.getCulture()

                return wfObject.findAll(query, {
                    bypassCache,
                    apiParams,
                })
            }

        }

        function getObjectByPath(sourceItem, path) {
            const
                jqDf = $.Deferred()

            const parts = path.split('/')

            const length = parts.length

            let i = 0

            //parts = "parent/parent/parent:1/child:1"
            // console.log("------------------ start", parts)

            iterate(sourceItem)

            // console.log(parts);

            return jqDf.promise()

            function iterate(item) {
                const
                    a = parts[i].split(':')

                const kind = a[0]

                const kinds = {
                    child: 1,
                    parent: 2,
                    childByUser: 7,
                    parentByUser: 8,
                }

                const contentProperty = {
                    child: 'childContent',
                    parent: 'parentContent',
                    childByUser: 'childContent',
                    parentByUser: 'parentContent',
                }

                const index = a[1] ? parseInt(a[1]) : 0

                // console.info('getting', parts[i], { id: item.id, type: item.type, kind: kinds[kind] });
                i++

                getSubItems(item, kinds[kind]).then((res) => {
                    if (kinds[kind] === kinds.parent || kinds[kind] === kinds.parentByUser) {
                        res = _.filter(res, { parentType: 71 }) // Only parents that are structures
                    }
                    // console.info(kind, index, "[ " + res[index][contentProperty[kind]].getHeaderText() + " ]", res[index][contentProperty[kind]]);
                    if (i < length)
                    {
                        // console.log("continuing");
                        // console.log(res, index);
                        iterate(res[index][contentProperty[kind]])
                    }
                    else
                    {
                        // console.log("done!");
                        jqDf.resolve(res[index])
                        // console.log("------------------ end")
                    }
                })

            }
        }

        function createAnswerOnQuestion(answerType, question, options) {
            const jqDf = $.Deferred()

            create({
                type: enums.objectType.questionAnswer,
                questionAnswerTypeId: answerType.id,
            }, {
                ticket: options ? options.ticket : undefined,
                influence: options ? options.influence : undefined,
                networkId: options ? options.networkId : undefined,
                contextParents: options && !options.useContextAwareRelations ? options.contextParents : undefined, // ContextParent as separate relation
            }).then((questionAnswer) => {
                createSubItemRelation(question, questionAnswer, {
                    kind: enums.subItemsKind.childrenByUser,
                    contextParentWfid: options && options.contextParents && options.useContextAwareRelations ? options.contextParents[0] : _.get(options, 'ticket.contextParentWfid'), // ContextParent directly on relation
                    thirdParty: _.get(options, 'ticket.thirdParty'),
                }).then((res) => {
                    jqDf.resolve(res)
                })
            })

            return jqDf.promise()
        }

        function setAllNotificationsToSeen() {
            return apiProxy('utility.setNotificationsToseen')
        }

        // Needs back-end work on the endpoint
        // function setNotificationToSeen(notification) {
        // 	return apiProxy("utility.setNotificationsToseen", { ids: [ notification.id ] });
        // }

        function createVirtualRelation() {

        }

        function verifyItem(item) {
            return apiProxy('utility.verifyItem', prepareWfObject(item))
        }

        function calculateFulfillment(item) {
            const promise = apiProxy('fulfillment.calculate', { item: prepareWfObject(item) })

            promise.then((res) => {
                if (item.type == 13) {
                    item.fulfilled = res.fulfills
                }
            })

            return promise
        }

        function saveSettings(params) {
            const
                apiParams = {
                    id: null,
                    type: enums.objectType.objectSetting,
                    objectId: params.item.id,
                    objectType: params.item.type,
                    items: [],
                }

            for (const key in params.settings) {
                if (params.settings.hasOwnProperty(key)) {
                    apiParams.items.push({
                        settingKind: key,
                        value: params.settings[key],
                    })
                }
            }

            if (params.forOrganization) {
                apiParams.forOrganization = true
            }

            return apiProxy('multi.savesettings', apiParams).then((result) => {
                const actual = result.actual || result
                const isDataRelation = params.item.type === enums.objectType.dataRelation

                if (result.bag) {
                    params.item[isDataRelation ? 'settingsBag' : 'conditionsBag'] = result.bag
                }

                if (isDataRelation) params.item.settings = actual
                else params.item.conditions = actual
            })

        }

        function getOrganizationUsers() {
            return $q((resolve, reject) => {
                getObject({
                    objectId: 10051, // Contains the current organization's users
                    objectType: enums.objectType.structure,
                    suppressErrorMessage: true,
                }).then((colleaguesStructure) => {
                    const users = _.map(colleaguesStructure.childs, 'childContent')
                    resolve(users)
                }, () => {
                    resolve([])
                })
            })
        }

        function getIndustriesFromOrganization() {
            return getSubItems('71-14409', enums.subItemsKind.childrenByUser)
        }

        function linkageLoader(options) {
            let
                vm

            var factory = vm = _.defaultsDeep(options, {
                limit: undefined, // IDs of standards and guidelines to include in the linkage result
                onLoadedCallback: undefined,
                loadLinkage,
                preloadedLinkageRelationalMap: undefined,
                loadedContents: [],
                loadedContentWfids: [],
                groupBySource: true,
                onLoaded(callback) {
                    factory.onLoadedCallback = callback
                },
            })

            return factory

            function loadLinkage(itemComposites) {
                const
                    culture = wfAuth.getCulture()

                const apiParams = { items: null, culture }

                const kind = enums.subItemsKind.relatedContent

                const itemsByWfid = _.keyBy(itemComposites, 'wfid')

                vm.linkagesLoading = false
                vm.linkages = []

                if (vm.linkageXhrRequest && vm.linkageXhrRequest.abort) {
                    vm.linkageXhrRequest.abort()
                    vm.linkageXhrRequest = undefined
                }

                if (itemComposites.length === 0) {
                    return
                }

                if (vm.preloadedLinkageRelationalMap) {
                    vm.linkageXhrRequest = $q((resolve) => {
                        const mockedLinkageRelations = _.chain(vm.preloadedLinkageRelationalMap)
                            .pick(_.map(itemComposites, 'wfid'))
                            .map((linkageWfids, sourceWfid) => {
                                return _.map(linkageWfids, (linkageWfid) => {
                                    return {
                                        wffid: sourceWfid,
                                        wfcid: linkageWfid,
                                    }
                                })
                            })
                            .flatten()
                            .value()

                        resolve(mockedLinkageRelations)
                    })
                }
                else {
                    apiParams.items = _.map(itemComposites, (item) => {
                        return {
                            item: {
                                id: item.content.id,
                                type: item.type,
                            },
                            onlyLoadRelations: true,
                            kind,
                        }
                    })

                    vm.linkagesLoading = true

                    vm.linkageXhrRequest = apiProxy.raw('multi.getSubItemsOfAll', apiParams)
                }

                vm.linkageXhrRequest.then((relations) => {
                    const
                        distinctChildWfids = _.chain(relations).map('wfcid').uniq().value()

                    let childWfidsToLoad

                    let promise

                    if (distinctChildWfids.length === 0) {
                        vm.linkages = []
                        vm.linkagesLoading = false
                        if (_.isFunction(factory.onLoadedCallback)) factory.onLoadedCallback([])
                        $timeout()
                        return
                    }

                    childWfidsToLoad = _.difference(distinctChildWfids, vm.loadedContentWfids)

                    promise = $q((resolve) => {
                        if (childWfidsToLoad.length) {
                            vm.linkageXhrRequest = apiProxy.raw('multi.getObjects', {
                                wfids: childWfidsToLoad,
                                loadMetadata: false,
                                culture,
                            })

                            vm.linkageXhrRequest.then((contents) => {
                                Array.prototype.push.apply(vm.loadedContents, contents)
                                Array.prototype.push.apply(vm.loadedContentWfids, _.map(contents, 'wfid'))

                                resolve(_.intersectionWith(vm.loadedContents, distinctChildWfids, (content, wfid) => { return content.wfid === wfid }))
                            })
                        }
                        else {
                            resolve(_.intersectionWith(vm.loadedContents, distinctChildWfids, (content, wfid) => { return content.wfid === wfid }))
                        }
                    })

                    promise.then((contents) => {
                        const
                            relationsByWfcid = _.groupBy(relations, 'wfcid')

                        let ancestorWfids = _.chain(contents).map('ancestorWfid').compact().uniq().value()

                        let itemOutput

                        let ancestorWfidsToLoad

                        let output = []

                        // Excluding linkage from these ancestors:
                        // 71-12386 = European directive on non-financial and diversity disclosure
                        // 71-12387 = Hållbarhetsrapport enligt årsredovisningslagen
                        ancestorWfids = _.difference(ancestorWfids, ['71-12386', '71-12387'])

                        if (options && options.limit) {
                            ancestorWfids = _.intersection(options.limit, ancestorWfids)
                        }

                        if (ancestorWfids.length === 0) {
                            vm.linkages = []
                            vm.linkagesLoading = false
                            if (_.isFunction(factory.onLoadedCallback)) factory.onLoadedCallback([])
                            $timeout()
                            return
                        }

                        ancestorWfidsToLoad = _.difference(ancestorWfids, vm.loadedContentWfids)

                        promise = $q((resolve) => {
                            if (ancestorWfidsToLoad.length) {
                                vm.linkageXhrRequest = apiProxy.raw('multi.getObjects', {
                                    wfids: ancestorWfidsToLoad,
                                    loadMetadata: false,
                                    culture,
                                })

                                vm.linkageXhrRequest.then((contents) => {
                                    Array.prototype.push.apply(vm.loadedContents, contents)
                                    Array.prototype.push.apply(vm.loadedContentWfids, _.map(contents, 'wfid'))

                                    resolve(_.intersectionWith(vm.loadedContents, ancestorWfids, (content, wfid) => { return content.wfid === wfid }))
                                })
                            }
                            else {
                                resolve(_.intersectionWith(vm.loadedContents, ancestorWfids, (content, wfid) => { return content.wfid === wfid }))
                            }
                        })

                        promise.then((ancestors) => {
                            const
                                ancestorsByWfid = _.keyBy(ancestors, 'wfid')

                            let itemOutputPrototype

                            // dropdownActions = getDropdownActions(),

                            let sourceItems

                            for (var i = 0, len_i = contents.length, content; i < len_i; i++) {
                                content = contents[i]

                                if (content.creatorOrganizationId === 1 || content.creatorOrganizationId === 4536) {
                                    relations = relationsByWfcid[content.wfid]

                                    if (relations) {
                                        if (!ancestorsByWfid[content.ancestorWfid]) continue

                                        sourceItems = getSourceItems(relations)

                                        itemOutputPrototype = {
                                            content,
                                            wfid: content.wfid,
                                            title: content.title,
                                            description: content.description,
                                            ancestorId: content.ancestorId,
                                            ancestor: ancestorsByWfid[content.ancestorWfid],
                                        }

                                        if (itemOutputPrototype.ancestor) itemOutputPrototype.ancestorTitle = itemOutputPrototype.ancestor.title

                                        if (vm.groupBySource) {
                                            itemOutput = _.clone(itemOutputPrototype)
                                            itemOutput.count = relations.length
                                            itemOutput.forItems = sourceItems

                                            output.push(itemOutput)
                                        }
                                        else {
                                            for (let j = 0, len_j = sourceItems.length; j < len_j; j++) {
                                                itemOutput = _.clone(itemOutputPrototype)
                                                itemOutput.count = 1
                                                itemOutput.forItem = sourceItems[j]

                                                output.push(itemOutput)
                                            }
                                        }
                                    }
                                }
                            }

                            output = _.orderBy(output, ['ancestorTitle', 'title'])

                            vm.linkages = output
                            vm.linkagesLoading = false

                            if (_.isFunction(factory.onLoadedCallback)) factory.onLoadedCallback(output)
                        })
                    })
                })

                function getSourceItems(relations) {
                    const
                        wfids = _.chain(relations).map('wffid').uniq().value()

                    const itemGroups = _.pick(itemsByWfid, wfids)

                    const items = _.chain(itemGroups).map().flatten().uniqBy('wfid').value()

                    return items
                }
            }
        }

        function setVerification(item, options) {
            let model

            model = {
                objectType: item.type,
                objectId: item.id,
                isVerified: options.isVerified,
                verifiedAt: options.verifiedAt,
                verifierUserId: options.verifierUserId,
                city: options.city,
                verifierPhoneNumber: options.verifierPhoneNumber,
                organizationId: options.targetOrganizationId,
                comment: options.comment,
                objectData: prepareWfObject(item),
            }

            const promise = apiProxy('verification.setVerification', model)

            promise.then((verificationResult) => {
                verificationResult.verifiedObject.createdAt = moment().format('YYYY-MM-DDTHH:mm:ss') //Temporary solution !!!!
                // Set old verifications in cache to latest false
                _.each(wfObject.filter({ where: {
                    type: enums.objectType.verification,
                    verifierOrganizationId: wfAuth.getOrganizationId(),
                    organizationId: options.targetOrganizationId,
                    objectType: item.type,
                    objectId: item.id,
                    latest: true,
                } }), (verification) => {
                    verification.latest = false
                })

                // Inject to cache
                wfObject.inject(verificationResult.dataRelation)
                wfObject.inject(verificationResult.verification)
                wfObject.inject(verificationResult.verifiedObject)
            })

            return promise
        }

        function persistentDataNegotiator(DataNegotiator, options, forceNewNegotiator) {
            let
                existingInstance

            let newInstance

            existingInstance = dataOpsCache.getNegotiator(options)

            // If an existing instance was found then return it
            if (existingInstance && !forceNewNegotiator) {
                // if (existingInstance && existingInstance.fromItem.wfid === "21-3599" && existingInstance.itemMetadata) {
                // 	console.log("persistentDataNegotiator", existingInstance.itemMetadata.count);
                // }

                return existingInstance
            }
            else { // Otherwise create a new instance, cache it and return it
                newInstance = DataNegotiator.instantiate(null, options)
                dataOpsCache.rememberNegotiator(newInstance, options)

                return newInstance
            }
        }

        function clearPersistentNegotiators() {
            dataOpsCache.clearNegotiators()
        }

        function getMeasureTargets(relationId) {
            return apiProxy('valuechainapi.getMeasureTargets', relationId)
        }

        function saveMeasureTargets(payload) {
            return apiProxy('valuechainapi.saveMeasureTargets', prepareWfObject(payload))
        }

        function clearRelativeMeasureResultsCache({ relativeMeasureIds, networkId  }) {
            return apiProxy('valuechainapi.clearRelativeMeasureResultscache', { relativeMeasureIds, networkId })
        }

        function getRelativeMeasureResultsCacheInfo({ relativeMeasureIds, networkId, organizationIds }) {
            return apiProxy('valuechainapi.getRelativeMeasureResultsCacheInfo', { relativeMeasureIds, networkId, organizationIds })
        }

        function fetchUploadedFile(filename) {
            let apiBaseUrl = $.proxies.baseUrl

            const xhr = $.ajax({
                url: `${apiBaseUrl}sfile?f=${encodeURIComponent(filename)}`,
                method: 'GET',
                dataType: 'binary',
                contentType: 'application/json',
                processData: false,
                xhrFields: {
                    responseType: 'blob',
                },
            })

            return $q((resolve, reject) => {
                xhr.then((res) => {
                    if (xhr.status !== 200) {
                        reject({ httpStatus: xhr.status })
                        return
                    }

                    let downloadFilename
                    const disposition = xhr.getResponseHeader('Content-Disposition')
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        const matches = filenameRegex.exec(disposition)
                        if (matches != null && matches[1]) downloadFilename = matches[1].replace(/['"]/g, '')
                    }
                    const type = xhr.getResponseHeader('Content-Type')

                    const blob = new Blob([res], { type })
                    const fileType = filename.split('.').slice(-1)[0]
                    resolve({
                        blob,
                        downloadFilename,
                        fileType,
                    })

                }).fail(() => {
                    reject({ httpStatus: xhr.status })
                })
            })

        }
    }

})()
