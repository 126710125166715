import { useEffect, useRef, useState } from 'react'

export * from './validationHooks'

export const useHistory = (maxSize, inputs) => {
    const history = useRef([])

    const reset = () => {
        history.current = []
    }

    const add = (item) => {
        if (history.current.length >= maxSize) {
            history.current.shift()
        }
        history.current.push(item)
    }

    const get = callback => history.current.find(callback)

    useEffect(() => {
        return () => reset()
    }, inputs)

    return [get, add, reset]
}

export const useDialogState = (initialState) => {
    const [open, setOpen] = useState(initialState)

    function openDialog() {
        setOpen(true)
    }

    function closeDialog() {
        setOpen(false)
    }

    return [open, openDialog, closeDialog]
}

export const useSelect = (initialKey, values: { [string]: * } = {}) => {
    const [key, setKey] = useState(values[initialKey] ? initialKey : null)

    function select(key) {
        if (!values[key]) {
            return
        }
        return function _setValue() {
            setKey(key)
        }
    }

    return [values[key], select, key]
}

export function useBeforeUnload(when, message) {
    useEffect(() => {
        function beforeUnload(event) {
            event.preventDefault()
            event.returnValue = message || 'Please make sure that your changes are saved before leaving the page.'
        }

        if (when) {
            window.addEventListener('beforeunload', beforeUnload)
        }
        else {
            window.removeEventListener('beforeunload', beforeUnload)
        }

        return () => {
            window.removeEventListener('beforeunload', beforeUnload)
        }
    }, [when, message])
}

export function usePrevious(value, input) {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, input)

    return ref.current
}

export function usePreviousDifferent(value) {
    return usePrevious(value, [value])
}

export function useDataInChunks(data, limit) {
    const [dataChunk, setDataChunk] = useState(data.slice(0, limit))

    function getNextChunk() {
        const nextChunk = dataChunk.length + limit

        if (nextChunk < data.length) {
            setDataChunk(data.slice(0, nextChunk))
        }
        else {
            setDataChunk(data)
        }
    }

    function resetChunk() {
        setDataChunk(data.slice(0, limit))
    }

    return [dataChunk, getNextChunk, resetChunk]
}
