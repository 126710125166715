import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfMeasureAnswering', wfMeasureAnswering)
        .service('wfMeasureService', wfMeasureService)

    wfMeasureAnswering.$inject = ['$parse', 'dataQuery', 'dataOperationsService', 'moment', 'modalService', 'wfAuth', 'requirements', 'wfMeasureService', '$translate', '$ngBootbox', '$sanitize']

    function wfMeasureAnswering($parse, dataQuery, dataOps, moment, modal, wfAuth, requirementService, wfMeasureService, $translate, $ngBootbox, $sanitize) {
        const directive = {
            restrict: 'EA',
            require: '?^^wfMeasureAnsweringManager',
            templateUrl: 'scripts/wf/answering/wfMeasureAnswering.directive.html',
            link,
            controllerAs: 'measureAnsweringVm',
            controller: ['$scope', '$element', '$attrs', wfMeasureAnsweringController],
        }

        return directive

        function wfMeasureAnsweringController(scope, element, attrs) {
            const
                measureAnsweringVm = this

            let measureDataRelation

            let measure

            if ('itemRelation' in attrs && 'itemContent' in attrs) {
                measure = measureAnsweringVm.measure = $parse(attrs.itemContent)(scope)
                measureAnsweringVm.measureDataRelation = $parse(attrs.itemRelation)(scope)
                if (!measure || (measure.type !== enums.objectType.measure && measure.type !== enums.objectType.relativeMeasure)) return
            }
            else {
                if (attrs.item) measureDataRelation = $parse(attrs.item)(scope)
                else measureDataRelation = scope.vm.item

                if (measureDataRelation.childType !== enums.objectType.measure && measureDataRelation.childType !== enums.objectType.relativeMeasure) return

                measureAnsweringVm.item = measureAnsweringVm.measureDataRelation = measureDataRelation
                measure = measureAnsweringVm.measure = measureDataRelation.childContent
            }

            if (scope.vm && scope.vm.context && scope.vm.context.aggregateMeasureAnswers) {
                measureAnsweringVm.stats = measure.filterChildren({ type: 86, networkId: scope.vm.context.networkId, aggregate: 1, wffid: measure.wfid })[0]
            }

            //Show/Hide Add data button
            measureAnsweringVm.showAddButton = measure.type !== enums.objectType.relativeMeasure
        }

        function link(scope, element, attrs, wfMeasureAnsweringManagerCtrl) {
            const
                measureAnsweringVm = scope.measureAnsweringVm

            let measure

            let measureDataRelation

            let itemComposite

            let requirement

            let localFulfillmentElement

            let fulfillsLocally

            let validValues

            let settings

            let latestAnswerId

            let busy

            let organizationId

            let networkId

            let influence

            const manager = wfMeasureAnsweringManagerCtrl

            let item

            let displayMode = null

            let isViewMode = false

            let verification

            const measureAnswerElement = element.children('div.measure-answer')

            const displayedAnswerElement = measureAnswerElement.children('div.value')

            const answerTrendElement = measureAnswerElement.children('div.trend')

            let context

            let contextParentWfids

            let latestAnswerContent

            let intersectionSettings

            let ticket

            const fulfillmentSpec = {}

            if (!measureAnsweringVm || !measureAnsweringVm.measureDataRelation || !measureAnsweringVm.measure || (measureAnsweringVm.measure.type !== enums.objectType.measure && measureAnsweringVm.measure.type !== enums.objectType.relativeMeasure)) return

            item = measureDataRelation = measureAnsweringVm.measureDataRelation
            measureAnsweringVm.setSelectedAnswer = setSelectedAnswer

            measure = measureAnsweringVm.measure
            settings = item.settings

            // If intersected in back-end then the originalRelation is on the questionDataRelation (that is a virtualDataRelation)
            if (item.type == enums.objectType.virtualDataRelation && typeof item.originalRelationWfid === 'string') {
                settings = item.originalRelation.settings
            }

            if (attrs.mode) displayMode = attrs.mode

            if (attrs.uiMode && $parse(attrs.uiMode)(scope) === enums.uiMode.view) displayMode = 'view'

            if (attrs.verification) verification = $parse(attrs.verification)(scope)

            if ('itemComposite' in attrs) itemComposite = $parse(attrs.itemComposite)(scope)

            // If intersected in front-end then the originalRelation is on the itemComposite
            if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings && itemComposite.originalRelation.settings.contextParentWfids) {
                contextParentWfids = itemComposite.originalRelation.settings.contextParentWfids
            }
            else if (settings) {
                // Else, use the settings object that we already have
                contextParentWfids = settings.contextParentWfids
            }

            measureAnsweringVm.context = context = (scope.vm ? scope.vm.context : {}) || {}
            measureAnsweringVm.addRelatedContentStatementOnAnswer = addRelatedContentStatementOnAnswer
            measureAnsweringVm.addRelatedContentOnAnswer = addRelatedContentOnAnswer
            measureAnsweringVm.onRelatedContentRemoved = onRelatedContentRemoved
            measureAnsweringVm.updateLatestAnswer = updateLatestAnswer

            if (!context.aggregateMeasureAnswers) {
                if (!wfAuth.canUserWrite()) isViewMode = true

                if (context && context.isViewMode)
                {
                    isViewMode = context.isViewMode
                }

                if (context && context.influence)
                {
                    influence = measureAnsweringVm.influence = context.influence
                    organizationId = influence.organizationId
                    networkId = influence.channelId
                    getRequirement()

                    // scope.allAnswers = dataQuery.getAll.intersectedChildrenOf(
                    // 	[ meausre, { 'childContent.organizationId': organizationId } ],
                    // 	// wfObject.get('52-' + influence.channelId)
                    // );
                }
                else if ('influence' in attrs && (influence = $parse(attrs.influence)(scope))) {
                    measureAnsweringVm.influence = influence
                    organizationId = influence.organizationId
                    networkId = influence.channelId
                    getRequirement()
                }
                else if ('ticket' in attrs) {
                    ticket =  $parse(attrs.ticket)(scope)
                    organizationId = ticket.organizationId
                    networkId = ticket.networkId

                    if (ticket.contextParentWfid) contextParentWfids = [ticket.contextParentWfid]
                }
                else if (context && context.verification)
                {
                    organizationId = context.verification.organizationId
                }
                else
                {
                    organizationId = wfAuth.getOrganizationId() || 0
                }

                if (!requirement && itemComposite) {
                    getRequirement()
                }

                if (itemComposite) {
                    scope.$on('requirementChanged', (event, updatedRequirement) => {
                        if (updatedRequirement && measureDataRelation && updatedRequirement.wffid_requirement !== measureDataRelation.wfid) return

                        getRequirement()

                        if (requirement) fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement)
                        else fulfillsLocally = null

                        // console.log(requirement, fulfillsLocally);
                        scope.$broadcast('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, onChecked(fulfillmentResult) {
                            fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                        } })

                        if (manager)
                        {
                            manager.update(measure.wfid, {
                                fulfillsLocally,
                                requirement,
                            })
                        }
                    })
                }

                if (influence) {
                    answerTrendElement.remove()
                }

                if (measureDataRelation && measureDataRelation.settings && measureDataRelation.settings.contextParentWfids) {
                    contextParentWfids = measureDataRelation.settings.contextParentWfids
                }

                if (influence && !contextParentWfids && influence.contextParentWfids) contextParentWfids = influence.contextParentWfids.split(',')

                intersectionSettings = {
                    organizationId,
                    contextParents: contextParentWfids,
                    networkId,
                }

                // intersectSource.push([ measure, { 'childContent.organizationId': organizationId } ]);

                // measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource);
                scope.isMeasure = true

                updateLatestAnswer(true)

                // if (measureAnsweringVm.allAnswers.length) {
                // 	if (context && context.verification)
                // 	{
                // 		measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(measureAnsweringVm.allAnswers, context.verification);
                // 	}

                // 	// measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ 'createdAt' ], [ 'desc' ]);
                // 	measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ function (a) {
                // 		return a.childContent.year;
                // 	}, 'createdAt' ], [ 'desc', 'desc' ]);

                // 	latestAnswerId = measureAnsweringVm.allAnswers[0];

                // 	displayLatestAnswer({
                // 		scope: scope,
                // 		measure: measure,
                // 		organizationId: organizationId,
                // 		displayedAnswerElement: displayedAnswerElement,
                // 		answerTrendElement: answerTrendElement,
                // 		updateChart: false,

                // 		networkId: networkId,
                // 		contextParents: contextParentWfids,
                // 		dataQuery: dataQuery
                // 	});
                // 	// displayLatestAnswer(scope, measure, organizationId, displayedAnswerElement, answerTrendElement, false, dataQuery);
                // 	var x = dataQuery.get.latestAnswerOnMeasure(measure, {
                // 		networkId: networkId,
                // 		organizationId: organizationId,
                // 		getTop: 2,
                // 		contextParents: measureDataRelation && measureDataRelation.settings ? measureDataRelation.settings.contextParentWfids : undefined
                // 	});
                // 	console.log((x ? (x.year + ": " + x.value + " " + x.childContent.symbol, x) : null), x);
                // }

                if (wfAuth.getOrganizationId() == organizationId && displayMode !== 'view' && !isViewMode)
                {
                    scope.isByAutheticatedOrg = true
                    element.on('click', 'button.btn', function() {
                        const
                            btn = $(this)

                        const initialValues = {}

                        if (busy) return
                        busy = true

                        btn.addClass('loading')
                        setTimeout((params) => {
                            btn.removeClass('loading')
                            busy = false
                        }, 1000)

                        if (requirement) {
                            if (requirement.value) initialValues.year = requirement.value
                        }

                        const fulfillmentResult = requirementService.checkLocalFulfillment(measure, measureDataRelation, requirement, intersectionSettings, { useDetailedResult: true })

                        const measureCreatorOptions = {
                            additionalPropertiesForInitialValues: { year: initialValues.year },
                            influence,
                            networkId,
                            contextParents: contextParentWfids,
                            intersectionSettings,
                            showAttachInformation: requirement && (requirement.rule === enums.requirementRule.anyValueWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContent || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.anyValueWithRelatedContentExceptIfNA || requirement.rule === enums.requirementRule.inPeriodWithRelatedContentIfNA),
                            fulfillmentResult,
                        }

                        modal.openMeasureAnswerCreator(measureDataRelation, measure, measureCreatorOptions).then((res) => {
                            if (typeof res === 'object') {
                                latestAnswerId = res.childId

                                if (requirement) {
                                    fulfillsLocally = answersMatchesRequirement(res.childContent, validValues, localFulfillmentElement, requirement)
                                    scope.$emit('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, onChecked(fulfillmentResult) {
                                        fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                                        // manager.update(measure.wfid, {
                                        // 	fulfillsLocally: fulfillsLocally,
                                        // 	latestAnswerId: latestAnswerId
                                        // });
                                    } })
                                }

                                if (manager) {
                                    manager.update(measure.wfid, {
                                        fulfillsLocally,
                                        latestAnswerId,
                                    })
                                }
                                else scope.$emit('measureAnswerChanged', measure)

                                scope.$emit('newAnswerCreated', measure)
                                updateLatestAnswer()
                            }
                        })
                    })
                }
                else
                {
                    element.find('button.btn').remove()
                }

                measureAnsweringVm.isViewMode = isViewMode
                // else
                // {
                // 	scope.isByAutheticatedOrg = false;
                // 	element.addClass('view');
                // 	if (latestAnswerContent)
                // 	{
                // 		latestAnswerBtn.removeClass('btn btn-primary btn-white btn-sm').addClass('text-bold').siblings().remove();
                // 		latestAnswerBtn.width('100%');
                // 	}
                // 	else
                // 		element.html('').append($('<div />').text('?').addClass('text-bold'));
                // }
                if (scope.activateMeasureAnsweringHistory) {
                    scope.activateMeasureAnsweringHistory()
                }
            }

            if (requirement) {
                // localFulfillmentElement = element.find('div.localFulfillment');
                // validValues = requirement.value.toString().split(',');

                // fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement)
                // scope.$emit("checkLocalFulfillment", measure, { latestAnswerContent: latestAnswerContent });
            }

            if (manager) {
                manager.insert(measure.wfid, {
                    requirement,
                    fulfillsLocally,
                    latestAnswerId,
                    measureDataRelation,
                    measure,
                    itemComposite,
                })
            }

            function getRequirement() {
                return requirement = measureAnsweringVm.actualRequirement = requirementService.getActualRequirement({
                    itemContent: measure,
                    itemRelation: measureDataRelation,
                    itemComposite,
                    organizationId,
                })
            }

            function setSelectedAnswer(answerDataRelation, answerContent, orderedLatestAnswers, initial) {
                displayLatestAnswer({
                    orderedLatestAnswers,
                    scope,
                    measure,
                    organizationId,
                    displayedAnswerElement,
                    answerTrendElement,
                    updateChart: true,

                    networkId,
                    contextParents: contextParentWfids,
                    dataQuery,
                })

                if (answerContent) latestAnswerId = answerContent.id
                else if (answerDataRelation) latestAnswerId = answerDataRelation.childId
                else latestAnswerId = undefined

                if (requirement) {
                    fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement)
                    scope.$emit('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, initial: false, onChecked(fulfillmentResult) {
                        fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                    } })
                }

                if (manager)
                {
                    // When initial is true, "measureAnswerChanged" will not be emitted/broadcasted
                    manager.update(measure.wfid, {
                        fulfillsLocally,
                        latestAnswerId,
                    }, initial)
                }
            }

            function updateLatestAnswer(initial) {
                let orderedLatestAnswers
                // var latestAnswerDataRelation;
                // intersectSource.push([ measure, { 'childContent.organizationId': organizationId } ]);

                // measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedChildrenOf.apply(this, intersectSource);
                scope.isMeasure = true

                // if (measureAnsweringVm.allAnswers.length) {
                // 	if (context && context.verification)
                // 	{
                // 		measureAnsweringVm.allAnswers = dataQuery.getAll.intersectedVerifiedBy(measureAnsweringVm.allAnswers, context.verification);
                // 	}

                // 	measureAnsweringVm.allAnswers = _.orderBy(measureAnsweringVm.allAnswers, [ function (a) {
                // 		return a.childContent.year;
                // 	}, 'createdAt' ], [ 'desc', 'desc' ]);

                // 	latestAnswerDataRelation = measureAnsweringVm.allAnswers[0];

                orderedLatestAnswers = dataQuery.get.latestAnswerOnMeasure(measure, {
                    networkId,
                    organizationId,
                    take: 2,
                    contextParents: contextParentWfids,
                })

                latestAnswerContent = measureAnsweringVm.latestAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 1]

                setSelectedAnswer(null, latestAnswerContent, orderedLatestAnswers, initial)
                // }
            }

            function updateLatestAnswerRelatedContent() {
                if (latestAnswerContent) measureAnsweringVm.latestAnswerRelatedContent = latestAnswerContent.filterRelatedContentByUser({ organizationId, wfxpid: _.get(contextParentWfids, '[0]') })
                else measureAnsweringVm.latestAnswerRelatedContent = []
            }

            function addRelatedContentStatementOnAnswer(options) {
                if (!latestAnswerContent) return

                modal.openCreatorAndPicker({
                    influence,
                    pick: false,
                    objectTypes: [44],
                    // compilerControl: vm.listControls[vm.mainStructure.wfid],
                    relationTarget: { item: latestAnswerContent, kind: 5 },
                    title: $translate.instant('modules.valueChain.influence.addComment'),
                    noFormHeader: true,
                    submitCaption: $translate.instant('Add'),
                    intersection: {
                        contextParents: contextParentWfids,
                        organizationId,
                        networkId,
                    },
                }).modal.closed.then(() => {
                    updateLatestAnswerRelatedContent()

                    fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation)
                    // scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
                    scope.$emit('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, onChecked(fulfillmentResult) {
                        fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                    } })

                    if (manager)
                    {
                        manager.update(measure.wfid, {
                            fulfillsLocally,
                            latestAnswerId,
                        })
                    }

                    if (options && options.compiler) options.compiler.compile()
                })
            }

            function addRelatedContentOnAnswer(options) {
                let
                    message

                const hasRequirement = requirement && (requirement.rule == enums.requirementRule.preferredValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.specificValueWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContent
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.anyValueWithRelatedContentExceptIfNA
						|| requirement.rule == enums.requirementRule.inPeriodWithRelatedContentIfNA
                )

                if (!latestAnswerContent) {
                    message = $translate.instant('modules.valueChain.influence.haveToAnswerQuestionBeforeAddingInfo')

                    $ngBootbox.customDialog({
                        message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $sanitize(message) + '</div>',
                        // onEscape: true,
                        closeButton: false,
                        className: 'centerWithIcon',
                        buttons: {
                            primary: {
                                label: $translate.instant('OK'),
                                className: 'btn-primary',
                            },
                        },
                    })
                    return
                }

                modal.openCreatorAndPicker({
                    influence,
                    pick: options.objectType === enums.objectType.certificate,
                    singlePick: true,
                    objectTypes: [options.objectType],
                    // compilerControl: vm.listControls[vm.mainStructure.wfid],
                    relationTarget: { item: latestAnswerContent, kind: 5 },
                    title: options.modalTitle,
                    noFormHeader: true,
                    submitCaption: $translate.instant('Add'),
                    intersection: {
                        contextParents: contextParentWfids,
                        organizationId,
                        networkId,
                    },
                }).modal.closed.then(() => {
                    if (hasRequirement) {
                        fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation)
                        // scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
                        scope.$emit('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, onChecked(fulfillmentResult) {
                            fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                        } })

                        if (manager)
                        {
                            manager.update(measure.wfid, {
                                fulfillsLocally,
                                latestAnswerId,
                            })
                        }
                    }

                    if (options && options.compiler) options.compiler.compile()
                })
            }

            function onRelatedContentRemoved() {
                if (!latestAnswerContent.metadata) latestAnswerContent.metadata = { countByRelationKind: {} }

                measureAnsweringVm.latestAnswerRelatedContent = latestAnswerContent.filterRelatedContentByUser({ organizationId, wfxpid: _.get(contextParentWfids, '[0]') })

                latestAnswerContent.metadata.countByRelationKind[enums.subItemsKind.relatedContentByUser] = measureAnsweringVm.latestAnswerRelatedContent.length

                if (requirement) {
                    fulfillsLocally = answersMatchesRequirement(latestAnswerContent, validValues, localFulfillmentElement, requirement, fulfillmentSpec, measureDataRelation)
                    // scope.$emit("localFulfillmentChanged", measure, fulfillmentSpec.state);
                    scope.$emit('checkLocalFulfillment', measure, { latestAnswerContent, intersectionSettings, onChecked(fulfillmentResult) {
                        fulfillsLocally = fulfillmentResult.fulfillmentState === enums.fulfillmentState.assessmentNeeded ? true : fulfillmentResult.actuallyFulfills
                    } })
                }

                if (manager)
                {
                    manager.update(measure.wfid, {
                        fulfillsLocally,
                        latestAnswerId,
                    })
                }
            }
        }

        function answersMatchesRequirement(latestAnswerContent, validValues, element, requirement, fulfillmentSpec, measureDataRelation) {
            let fulfills = !!latestAnswerContent// && validValues.indexOf(answer.toString()) !== -1;
            const fulfillsException = false

            if (requirement && requirement.rule == enums.requirementRule.manual) fulfills = true

            if (element) {
                // element.toggleClass("fulfillsException", fulfillsException === true);// : '<i class="fa fa-times"></i>')
                // element.toggleClass("fulfills", fulfills === true);// : '<i class="fa fa-times"></i>')
                // element.toggleClass("unfulfills", !fulfills);// : '<i class="fa fa-times"></i>')
            }

            if (fulfillmentSpec) {
                fulfillmentSpec.state = fulfillsException ? 'exception' : fulfills === true
            }

            return fulfills
        }

        function displayLatestAnswer(options) {
            const
                orderedLatestAnswers = options.orderedLatestAnswers

            const intersectSource = []

            const latestAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 1]

            // latestAnswerContent,

            let lastPeriod

            const previousPeriodAnswerContent = orderedLatestAnswers[orderedLatestAnswers.length - 2]

            let lastValue

            let previousValue

            let sortedAnswers

            const jqDf = $.Deferred()

            const
                scope = options.scope

            const measure = options.measure

            let organizationId

            const displayedAnswerElement = options.displayedAnswerElement

            const answerTrendElement = options.answerTrendElement

            const updateChart = options.updateChart

            let dataQuery

            let networkId

            let contextParents

            // if (!scope.allAnswers)
            // {
            // 	console.log("NO", scope);
            // }
            // sortedAnswers = _.orderBy(scope.measureAnsweringVm.allAnswers, [ function (a) {
            // 	return a.childContent.year;
            // }, 'createdAt' ], [ 'desc', 'desc' ]);

            // latestAnswerContent = sortedAnswers[0];

            answerTrendElement.removeClass('up down same')
            if (latestAnswerContent)
            {
                // latestAnswerChildContent = latestAnswerContent.childContent;

                // if (orderedLatestAnswers.length > 1) {
                // lastPeriod = latestAnswerChildContent.year;
                // previousPeriodAnswer = _.find(sortedAnswers, function (o) {
                // 	return o.childContent.year < lastPeriod;
                // });

                if (previousPeriodAnswerContent) {
                    lastValue = parseInt(latestAnswerContent.value)
                    previousValue = parseInt(previousPeriodAnswerContent.childContent.value)
                    // console.log(lastPeriod, previousPeriodAnswer, lastValue, previousValue, lastValue > previousValue)
                    if (lastValue > previousValue) answerTrendElement.addClass('up')
                    else if (lastValue < previousValue) answerTrendElement.addClass('down')
                    else if (lastValue === previousValue) answerTrendElement.addClass('same')
                }
                // }

                if (!latestAnswerContent.childContent)
                {
                    dataOps.getObject({
                        objectType: latestAnswerContent.wfcid.split('-')[0],
                        objectId: latestAnswerContent.wfcid.split('-')[1],
                    }).then((res) => {
                        // displayedAnswerElement.text(latestAnswerChildContent.value + " " + res["symbol"])
                        // displayedAnswerElement.prepend("<span>" + latestAnswerChildContent.year + ":</span> ")
                        displayedAnswerElement.html(latestAnswerContent.getHeaderText())
                        jqDf.resolve(latestAnswerContent)
                    })
                }
                else
                {
                    // displayedAnswerElement.text(latestAnswerChildContent.value + " " + latestAnswerChildContent.childContent["symbol"])
                    displayedAnswerElement.html(latestAnswerContent.getHeaderText())//("<span>" + latestAnswerChildContent.year + ":</span> ")
                    jqDf.resolve(latestAnswerContent)
                }
            }
            else
            {
                displayedAnswerElement.text('')
                jqDf.resolve()
            }

            if (updateChart && scope.vm && scope.vm.context && scope.vm.context.sunburstControl && scope.vm.context.sunburstControl.setMeasureAnswerState) {
                scope.vm.context.sunburstControl.setMeasureAnswerState(measure.wfid, latestAnswerContent)
            }

            return jqDf.promise()
        }
    }

    wfMeasureService.$inject = ['$translate', '$q', 'dataOperationsService', '$rootScope']
    function wfMeasureService($translate, $q, dataOps, $rootScope) {
        const
            monthNames = moment.months()

        const service = {
            getMeasureAnswerFormSpecification,
            getFullMeasureAnswerFormSpecification,
            generatePeriods,
            getDaysInMonth,
            formatPeriodNameFromAnswer,
            groupAndFormatAnswersByPeriod,
            getMeasureAnswerPeriodName,
            formatPeriodNameFromPeriodItem,
            formatPeriodNameFromRequirement,
            getMeasurePeriodSettings,
        }

        return service

        function getMeasurePeriodSettings(item) {
            let
                dataRelation

            let measurePeriodSettings

            let periodSettingsObjectWfid

            if (item.isComposite) {
                dataRelation = item.dataRelation
            }
            else if (item.type === enums.objectType.dataRelation || item.type === enums.objectType.virtualDataRelation) {
                dataRelation = item
            }

            if (dataRelation) {
                measurePeriodSettings = _.get(dataRelation.originalDataRelation, 'settings.measurePeriodSettings')
                if (measurePeriodSettings) periodSettingsObjectWfid = dataRelation.originalDataRelation.wfid
                else {
                    measurePeriodSettings = _.get(dataRelation, 'settings.measurePeriodSettings')
                    if (measurePeriodSettings) periodSettingsObjectWfid = dataRelation.wfid
                }
            }

            if (!measurePeriodSettings) {
                measurePeriodSettings = {
                    frequency: enums.calendarFrequency.yearly,
                }
            }

            return {
                measurePeriodSettings,
                periodSettingsObjectWfid,
            }
        }

        function getFullMeasureAnswerFormSpecification(itemContent, itemRelation, form, options) {
            const
                periodRangesByIndex = {}

            const periodSettingsResult = getMeasurePeriodSettings(itemRelation)

            const { measurePeriodSettings, periodSettingsObjectWfid } = periodSettingsResult

            const measureAnswerFormId = 'measureAnswerForm_' + _.uniqueId()

            const initialValues = {
                unitId: itemContent.unitId,
                frequency: measurePeriodSettings.frequency,
                periodSettingsObjectWfid: periodSettingsObjectWfid || null,
                measureId: itemContent.id,
                measureAnswerFormId,
                showNotAvailableCheckbox: measurePeriodSettings.showNotAvailableCheckbox,
                // getLatestValueInPeriod
            }

            // if (options && options.additionalPropertiesForInitialValues)
            // 	_.assign(initialValues, options.additionalPropertiesForInitialValues);

            if (form) {
                form.model = initialValues
                form.periodRangesByIndex = periodRangesByIndex
            }

            return getMeasureAnswerFormSpecification({
                measure: itemContent,
                measureAnswerFormId,
                periodSettings: measurePeriodSettings,
                periodRangesByIndex,
                getLatestValueInPeriod: options && options.getLatestValueInPeriod,
            })
        }

        function getMeasureAnswerFormSpecification(options) {
            const
                deferred = $q.defer()

            let output

            const periodRangesByIndex = options.periodRangesByIndex || null

            let generatedPeriods

            let periodDropdownItems

            const measure = options.measure

            generatedPeriods = generatePeriods(options.periodSettings)

            periodDropdownItems = _.map(generatedPeriods, (period, index) => {
                period.value = index.toString()

                return period
            })

            if (periodRangesByIndex) {
                _.assign(periodRangesByIndex, _.chain(periodDropdownItems).keyBy('value').mapValues((period) => {
                    return {
                        period: period.startDate,
                        periodEnd: period.endDate,
                        nameSpecification: period.nameSpecification,
                    }
                }).value())
            }

            if (options.periodSettings.showUnitSelector) {
                getAllUnits().then((dataRelationUnits) => {
                    let
                        units = _.chain(dataRelationUnits).map('childContent').orderBy(['quantityType', 'name']).value()

                    const titleMap = []

                    const unitObjectFromMeasure = _.find(units, { id: measure.unit.id })

                    let quantityType = undefined

                    if (unitObjectFromMeasure) {
                        quantityType = unitObjectFromMeasure.quantityType
                        units = _.filter(units, { quantityType })

                        if (units && units.length) {
                            _.forEach(units, (unit) => {
                                titleMap.push({ value: unit.id, name: unit.name + (unit['symbol'] && unit['symbol'].length ? ' (' + unit['symbol'] + ')' : '') })
                            })
                        }
                    }

                    output = {
                        form: [
                            {
                                key: 'value',
                            },
                            {
                                key: 'unitId',
                                type: 'select',
                                name: 'Units',
                                titleMap,
                                onChange(modelValue, form) {
                                    const unit = _.find(units, { id: modelValue })
                                    if (unit) $rootScope.$emit('measureUnitChanged_' + options.measureAnswerFormId, unit)
                                },
                            },
                            {
                                key: 'periodSelection',
                                type: 'select',
                                readonly: options.periodSettings && options.periodSettings.lockPeriod,
                                titleMap: periodDropdownItems,
                                onChangeExtended(value, form, model) {
                                    getLatestValueInPeriod(value, form, model)
                                },
                            },
                        ],
                    }

                    deferred.resolve(output)
                })
            }
            else {
                output = {
                    form: [
                        {
                            key: 'value',
                        },
                        {
                            key: 'periodSelection',
                            type: 'select',
                            readonly: options.periodSettings && options.periodSettings.lockPeriod,
                            titleMap: periodDropdownItems,
                            onChangeExtended(value, form, model) {
                                getLatestValueInPeriod(value, form, model)
                            },
                        },
                    ],
                }

                deferred.resolve(output)
            }

            function getLatestValueInPeriod(periodIndexString, form, model) {
                if (options.getLatestValueInPeriod) {
                    options.getLatestValueInPeriod(periodIndexString).then(({ value, baseUnitFactor, unit }) => {
                        $rootScope.$emit('periodChanged_' + options.measureAnswerFormId, value, baseUnitFactor, unit)
                    })
                }
            }

            return deferred.promise
        }

        function getAllUnits() {
            return dataOps.getSubItems({ id: 12102, type: enums.objectType.structure }, enums.subItemsKind.children)
        }

        function generatePeriods(measurePeriodSettings, options) {
            let
                output

            let periodSettings

            let periodDropdownItems = []

            const minYear = _.get(options, 'minYear') || 1980

            let maxYear = new Date().getFullYear() + 1

            let monthNames

            const halfYearWord = $translate.instant('calendarFrequency.halfYear')

            periodSettings = measurePeriodSettings

            if (periodSettings.frequency === enums.calendarFrequency.yearly) {
                // Yearly
                maxYear = maxYear + 6
                for (var year = minYear; year <= maxYear; year++) {
                    periodDropdownItems.push({
                        frequency: periodSettings.frequency,
                        name: year,
                        year,
                        startDate: concatDateString(year, 1),
                        endDate: concatDateString(year, 12, getDaysInMonth(12, year)),
                    })
                }
            }
            else if (periodSettings.frequency === enums.calendarFrequency.halfYearly) {
                // Half yearly
                for (var year = minYear; year <= maxYear; year++) {
                    for (let j = 1; j <= 2; j++) {
                        periodDropdownItems.push({
                            frequency: periodSettings.frequency,
                            name: halfYearWord + ' ' + j + ' ' + year,
                            startDate: concatDateString(year, (6 * j) - 5),
                            endDate: concatDateString(year, (6 * j), getDaysInMonth((6 * j), year)),
                        })
                    }
                }
            }
            else if (periodSettings.frequency === enums.calendarFrequency.quarterly) {
                // Quarterly
                for (var year = minYear; year <= maxYear; year++) {
                    for (let quarter = 1; quarter <= 4; quarter++) {
                        periodDropdownItems.push({
                            frequency: periodSettings.frequency,
                            name: 'Q' + quarter + ' ' + year,
                            startDate: concatDateString(year, (3 * quarter) - 2),
                            endDate: concatDateString(year, (3 * quarter), getDaysInMonth((3 * quarter), year)),
                        })
                    }
                }
            }
            else if (periodSettings.frequency === enums.calendarFrequency.monthly) {
                // Monthly
                monthNames = moment.months()

                for (var year = minYear; year <= maxYear; year++) {
                    for (let month = 1; month <= 12; month++) {
                        periodDropdownItems.push({
                            frequency: periodSettings.frequency,
                            name: monthNames[month - 1] + ' ' + year,
                            startDate: concatDateString(year, month),
                            endDate: concatDateString(year, month, getDaysInMonth(month, year)),
                        })
                    }
                }
            }
            else if (periodSettings.frequency === enums.calendarFrequency.custom) {
                // Custom ranges
                monthNames = moment.months();

                (function () {
                    let
                        currentYear = minYear

                    let yearBeforeRangeIterations

                    const maxYear = new Date().getFullYear() + 1

                    let itemOutput

                    while (currentYear < maxYear) {
                        yearBeforeRangeIterations = currentYear

                        for (var i = 0, startYear, startMonth, startDay, endYear, endMonth, endDay, name, len = periodSettings.ranges.length; i < len; i++) {
                            startYear = currentYear
                            startMonth = periodSettings.ranges[i].startMonth
                            startDay = periodSettings.ranges[i].startDay

                            endMonth = periodSettings.ranges[i].endMonth
                            endDay = periodSettings.ranges[i].endDay
                            name = periodSettings.ranges[i].name

                            if (endMonth < startMonth || (endMonth === startMonth && endDay <= startDay)) endYear = startYear + 1
                            else endYear = startYear

                            currentYear = endYear

                            itemOutput = {
                                frequency: periodSettings.frequency,
                                name: null,
                                startYear,
                                startMonth,
                                startDay,
                                endYear,
                                endMonth,
                                endDay,
                                startDate: concatDateString(startYear, startMonth, startDay),
                                endDate: concatDateString(endYear, endMonth, endDay),
                                nameSpecification: name,
                            }

                            if (name && name.length) name = formatPeriodName(itemOutput, name)
                            else name = moment({ year: startYear, month: startMonth - 1, day: startDay }).format('YYYY-MM-DD') + ' - ' + moment({ year: endYear, month: endMonth - 1, day: endDay }).format('YYYY-MM-DD')

                            itemOutput.name = name

                            periodDropdownItems.push(itemOutput)
                        }

                        if (yearBeforeRangeIterations === currentYear) currentYear++
                    }
                })()
            }

            periodDropdownItems = _.reverse(periodDropdownItems)

            return periodDropdownItems

            function concatDateString(year, month, day) {
                return (year < 10 ? '0' : '') + year + '-' + (month < 10 ? '0' : '') + month + '-' + ((day || 1) < 10 ? '0' : '') + (day || 1)
            }
        }

        // Gets the no. of days in a month. Assumes m starts with 1 for January
        // Credit: https://stackoverflow.com/a/27947860 / https://stackoverflow.com/a/27946635
        function getDaysInMonth(m, y) {
            return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1)
        }

        function getMeasureAnswerPeriodName(measureAnswer, useShortDates, options) {
            let
                startMoment

            let endMoment

            if (measureAnswer.periodName) return measureAnswer.periodName

            if (!measureAnswer.frequency) return measureAnswer.year

            if (measureAnswer.frequency !== enums.calendarFrequency.custom) {
                startMoment = moment(measureAnswer.period)
            }

            switch (measureAnswer.frequency) {
                case enums.calendarFrequency.yearly:
                    return startMoment.year()
                case enums.calendarFrequency.halfYearly:
                    return 'H' + measureAnswer.halfYear + ' ' +  startMoment.year()
                case enums.calendarFrequency.quarterly:
                    return 'Q' + measureAnswer.quarter + ' ' +  startMoment.year()
                case enums.calendarFrequency.monthly:
                    if (options && options.useNumericMonthlyFormat) {
                        return startMoment.format('YYYY-MM')
                    }
                    else {
                        return startMoment.format('MMM YYYY')
                    }
                case enums.calendarFrequency.custom:
                    return formatPeriodNameFromAnswer(measureAnswer, measureAnswer.intervalNameSpecification, useShortDates)
            }
        }

        function formatPeriodNameFromPeriodItem(spec, useShortDates) {
            let
                startMoment

            let endMoment

            if (spec.periodName) return spec.periodName

            if (!spec.frequency) return spec.year

            if (spec.frequency !== enums.calendarFrequency.custom) {
                startMoment = moment(spec.startDate)
            }

            switch (spec.frequency) {
                case enums.calendarFrequency.yearly:
                    return startMoment.year()
                case enums.calendarFrequency.halfYearly:
                    return 'H' + getHalfYearNumber(spec.startDate) + ' ' +  startMoment.year()
                case enums.calendarFrequency.quarterly:
                    return 'Q' + getQuarterNumber(spec.startDate) + ' ' +  startMoment.year()
                case enums.calendarFrequency.monthly:
                    return startMoment.format('MMM YYYY')
                case enums.calendarFrequency.custom:
                    return formatPeriodNameFromAnswer(spec, spec.intervalNameSpecification, useShortDates)
            }

            function getHalfYearNumber(startDateString) {
                if (_.endsWith(startDateString, '-01-01')) return 1
                else if (_.endsWith(startDateString, '-07-01')) return 2
            }

            function getQuarterNumber(startDateString) {
                if (_.endsWith(startDateString, '-01-01')) return 1
                else if (_.endsWith(startDateString, '-04-01')) return 2
                else if (_.endsWith(startDateString, '-07-01')) return 3
                else if (_.endsWith(startDateString, '-10-01')) return 4
            }
        }

        function formatPeriodNameFromAnswer(measureAnswerOrPeriodItem, nameSpec, useShortDates) {
            let periodItem; const dateString = useShortDates ? 'YYMMDD' : 'YYYY‑MM‑DD'
            const
                startDate = measureAnswerOrPeriodItem.startDate || measureAnswerOrPeriodItem.period

            const endDate = measureAnswerOrPeriodItem.endDate || measureAnswerOrPeriodItem.periodEnd

            if (!nameSpec || !nameSpec.length) {
                // The hyphens in the dates are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)
                return moment(startDate).format(dateString) + ' - ' + moment(endDate).format(dateString)
            }
            else if (nameSpec && nameSpec.indexOf('{{') !== -1 && nameSpec.indexOf('}}') !== -1) {
                periodItem = {
                    startMoment: moment(startDate),
                    endMoment: moment(endDate),
                    replaceParams: true,
                }
                periodItem.startYear = periodItem.startMoment.year()
                periodItem.startMonth = periodItem.startMoment.month() + 1
                periodItem.startDay = periodItem.startMoment.day()
                periodItem.endYear = periodItem.endMoment.year()
                periodItem.endMonth = periodItem.endMoment.month() + 1
                periodItem.endDay = periodItem.endMoment.day()

                return formatPeriodName(periodItem, nameSpec, useShortDates)
            }
            else return nameSpec
        }

        function formatPeriodNameFromRequirement(requirement, measurePeriodSettings) {
            if (measurePeriodSettings.frequency === enums.calendarFrequency.yearly) {
                return requirement.value
            }
            else {
                const
                    dates = requirement.value.split('|')

                const startDate = dates[0]

                const endDate = dates[1]

                return formatPeriodNameFromPeriodItem({
                    frequency: measurePeriodSettings.frequency,
                    intervalNameSpecification: measurePeriodSettings.name,
                    startDate, endDate,
                })
            }

        }

        function formatPeriodName(periodItem, nameSpec, useShortDates) {
            let
                output = nameSpec

            let startDate

            let endDate

            const dateString = useShortDates ? 'YYMMDD' : 'YYYY‑MM‑DD'

            if ((output && periodItem.replaceParams) || (output && output.indexOf('{{') !== -1 && output.indexOf('}}') !== -1)) {
                startDate = periodItem.startMoment || moment({ year: periodItem.startYear, month: periodItem.startMonth - 1, day: periodItem.startDay })
                endDate = periodItem.endMoment || moment({ year: periodItem.endYear, month: periodItem.endMonth - 1, day: periodItem.endDay })

                output = output.replace(/{{startDate}}/g, startDate.format(dateString)) // The hyphens are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)
                output = output.replace(/{{endDate}}/g, endDate.format(dateString)) // The hyphens are the unicode Character 'NON-BREAKING HYPHEN' (U+2011)

                output = output.replace(/{{startYear}}/g, periodItem.startYear)
                output = output.replace(/{{startMonth}}/g, periodItem.startMonth)
                output = output.replace(/{{startMonthName}}/g, monthNames[periodItem.startMonth - 1])
                output = output.replace(/{{startDay}}/g, periodItem.startDay)

                output = output.replace(/{{endYear}}/g, periodItem.endYear)
                output = output.replace(/{{endMonth}}/g, periodItem.endMonth)
                output = output.replace(/{{endMonthName}}/g, monthNames[periodItem.endMonth - 1])
                output = output.replace(/{{endDay}}/g, periodItem.endDay)
            }

            return output
        }

        function groupAndFormatAnswersByPeriod(answerRelations, options) {
            let
                output

            let preparedContents
            // unitContent

            preparedContents = _.map(answerRelations, (relation) => {
                const
                    measureAnswer = relation.childContent

                const clonedContent = _.assign({}, measureAnswer)

                clonedContent.relationCreatedAt = relation.createdAt

                // if (!unitContent)
                // 	unitContent = measureAnswer.childContent;

                // Concatenate periods to get for example "2016-04-01|2016-06-31"
                // If no periodEnd is specified the answer uses the old format which was always a full year so just use year + "-12-31" then
                clonedContent.periodConcat = clonedContent.period + '|' + (clonedContent.periodEnd || (clonedContent.year + '-12-31'))
                clonedContent.frequency = clonedContent.frequency || enums.calendarFrequency.yearly // If no frequency specified it is yearly

                clonedContent.realContent = measureAnswer

                return clonedContent
            })

            output = _.chain(preparedContents)
                .groupBy('periodConcat')
                .map((group) => {
                    if (group.length === 1) return group[0]
                    else return _.orderBy(group, 'relationCreatedAt')[group.length - 1]
                })
                .orderBy('periodConcat')
                .value()

            if (options && options.take) {
                output = _.takeRight(output, options.take)
            }

            _.each(output, (clonedContent) => {
                clonedContent.periodName = getMeasureAnswerPeriodName(clonedContent, options && options.useShortDates)
                clonedContent.childContent = clonedContent.realContent && clonedContent.realContent.childContent
            })

            if (options && options.useRealContent) {
                output = _.map(output, 'realContent')
            }

            return output
        }
    }

})()
