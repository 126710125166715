// @flow
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { regExpSearchMapper } from '@worldfavor/utils/helpers'
import EmptyState from '@worldfavor/components/EmptyState'
import ReportingProductTable from './ReportingProductTable'
import SearchBar from '@worldfavor/components/SearchBar'
import { injectIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    searchBarContainer: {
        paddingBottom: 30,
    },
    searchBar: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginBottom: 50,
    },
    onFocusSearchBarBorder: {},
    roundedSearchBar: {
        borderRadius: 50 + '!important',
    },
    emptyState: {
        padding: '50px 0',
    },
    preTableInfo: {
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    resultCount: {
        fontWeight: theme.typography.fontWeights.medium,
        fontSize: theme.typography.fontSizes.medium,
    },
}))

const ReportingProductSearchTable = (props) => {
    const { influenceWfid, data, intl } = props
    const classes = useStyles(props)
    const [searchText, setSearchText] = useState('')
    const [resultCount, setResultCount] = useState(data.length)
    const searchMapper = regExpSearchMapper(['node.name', 'node.gtin'/*, 'node.productNumber', 'node.productSupplierNumber'*/], searchText)
    const [filteredData, setFilteredData] = useState(data.map(searchMapper))

    useEffect(() => {
        const filterResult = data.map(searchMapper).filter(x => Boolean(x))
        onDataFiltered(filterResult)
    }, [data, searchText])

    function onDataFiltered(data) {
        setFilteredData(data)
        if (resultCount !== data.length) {
            setResultCount(data.length)
        }
    }

    function onSearchChange(event) {
        setSearchText(event.target.value)
    }

    function onClear() {
        setSearchText('')
    }

    return (
        <>
            <div className={classes.searchBarContainer}>
                <SearchBar
                    inputProps={{
                        value: searchText,
                        placeholder: intl.formatMessage({ id: 'search.keywordPlaceholder' }),
                        onChange: onSearchChange,
                    }}
                    onClear={onClear}
                    rounded
                    classes={{
                        root: classes.searchBar,
                        rounded: classes.roundedSearchBar,
                        onFocusBorder: classes.onFocusSearchBarBorder,
                    }}
                />
            </div>
            {resultCount ?
                (
                    <div className={classes.preTableInfo}>
                        <span className={classes.resultCount}>
                            <FormattedMessage id="search.resultsCount" values={{ count: `${resultCount}` }} />
                        </span>
                    </div>
                ) : null
            }
            <Paper>
                {
                    data.length && !filteredData.length
                        ? (
                            <EmptyState
                                className={classes.emptyState}
                                title={<FormattedMessage id={'search.noProductMatch'} />}
                                iconClass={'fas fa-search'}
                            />
                        )
                        : (
                            <ReportingProductTable
                                data={filteredData}
                                influenceWfid={influenceWfid}
                                rowsPerPage={10}
                                enablePagination={data.length && data.length > 10}
                            />
                        )
                }
            </Paper>
        </>
    )
}

export default injectIntl(ReportingProductSearchTable)
