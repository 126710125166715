import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfDataAnswering', wfDataAnswering)
	
    wfDataAnswering.$inject = ['$parse', 'dataQuery', 'dataOperationsService', 'moment', 'wfAuth', '$compile', '$templateCache', 'modalService', '$timeout', '$ngBootbox', '$sanitize', '$translate', '$q', 'wfObject', 'requirements', 'wfTranslate', '$interpolate']

    function wfDataAnswering($parse, dataQuery, dataOps, moment, wfAuth, $compile, $templateCache, modal, $timeout, $ngBootbox, $sanitize, $translate, $q, wfObject, requirements, wfTranslate, $interpolate) {
        const
            templateUrl = 'scripts/wf/answering/wfDataAnswering.directive.html'

        const directive = {
            restrict: 'EA',
            templateUrl,
            controllerAs: 'dataAnsweringVm',
            controller: ['$scope', '$element', '$attrs',  function ($scope, $element, $attrs) {
                const
                    vm = this
					
                let requirement
					
                let item
					
                let itemContent
					
                let itemDataRelation
					
                let itemComposite
					
                let organizationId
					
                let fulfillsLocally
					
                let displayMode
					
                let settings
					
                let contextParentWfids
					
                let objectTypesToAdd = undefined
					
                let subItemsKind = enums.subItemsKind.relatedContentByUser
					
                let intersectionSettings
					
                let dataRelationWithSettings
					
                let pickerSettingsFromAttr
					
                let pickerOptions
					
                let influence
					
                const buttonInterpolate = $interpolate('<button wf-if-first="!dataAnsweringVm.button{{btnId}}.useDropdown" type="button" class="btn btn-sm {{buttonStyleClass}}" ng-bind="::dataAnsweringVm.button{{btnId}}.buttonCaption" wf-click="dataAnsweringVm.button{{btnId}}.onClick()" wf-permission-create></button>')
					
                const dropdownInterpolate = $interpolate(
                    '<wf-dropdown wf-if-first="dataAnsweringVm.button{{btnId}}.useDropdown" actions="dataAnsweringVm.button{{btnId}}.dropdownActions" wf-permission-create'
						+ ' btn-class="btn btn-sm {{buttonStyleClass}}"'
						+ ' btn-caption="::dataAnsweringVm.button{{btnId}}.buttonCaption"'
						+ ' btn-icon="fa fa-chevron-down"'
						+ ' btn-icon-placement="right"'
						+ '></wf-dropdown>',
                )
					
                let html = ''
					
                let buttonIdIncrementor = 0

                vm.addInformation = addInformation

                activate()
				
                function activate() {
                    // If intersected in back-end then the originalRelation is on the questionDataRelation (that is a virtualDataRelation)
					
                    if ($attrs.mode) displayMode = $attrs.mode

                    if ($attrs.uiMode && $parse($attrs.uiMode)($scope) === enums.uiMode.view) displayMode = 'view'

                    if ('itemComposite' in $attrs) {
                        itemComposite = $parse($attrs.itemComposite)($scope)
                        itemContent = itemComposite.content
                    }

                    if ('itemContent' in $attrs) itemContent = $parse($attrs.itemContent)($scope)

                    if ('itemRelation' in $attrs) itemDataRelation = $parse($attrs.itemRelation)($scope)

                    if ('pickerSettings' in $attrs) pickerSettingsFromAttr = $parse($attrs.pickerSettings)($scope)

                    if ('influence' in $attrs) influence = $parse($attrs.influence)($scope)

                    settings = itemDataRelation.settings

                    if (itemDataRelation.type == enums.objectType.virtualDataRelation && typeof itemDataRelation.originalRelationWfid === 'string') {
                        settings = _.get(itemDataRelation.originalRelation, 'settings')
                    }

                    // If intersected in front-end then the originalRelation is on the itemComposite
                    if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings && itemComposite.originalRelation.settings.contextParentWfids) {
                        contextParentWfids = itemComposite.originalRelation.settings.contextParentWfids
                    }
                    else if (settings) {
                        // Else, use the settings object that we already have
                        contextParentWfids = settings.contextParentWfids
                    }

                    if (itemComposite && itemComposite.originalRelation && itemComposite.originalRelation.settings) {
                        settings = itemComposite.originalRelation.settings
                    }

                    if ('intersection' in $attrs) {
                        intersectionSettings = $parse($attrs.intersection)($scope)
                    }

                    if (intersectionSettings) organizationId = intersectionSettings.organizationId
                    else organizationId = wfAuth.getOrganizationId()

                    if (itemContent && itemContent.conditions && itemContent.conditions.objectTypes) objectTypesToAdd = itemContent.conditions.objectTypes
					
                    if (settings) {
                        if (settings.objectTypes) objectTypesToAdd = settings.objectTypes

                        subItemsKind = settings.pickerRelationTargetKind || enums.subItemsKind.relatedContentByUser
                    }

                    if ('objectTypes' in $attrs) objectTypesToAdd = $parse($attrs.objectTypes)($scope)

                    if ('kind' in $attrs) subItemsKind = $parse($attrs.kind)($scope)

                    if (itemComposite) {
                        $scope.$on('requirementChanged', () => {
                            // getRequirement();
							
                            // if (requirement) {
                            // 	fulfillsLocally = true; // Check local fulfillment
                            // }
                            // else
                            // 	fulfillsLocally = null;

                            // $scope.$broadcast("checkLocalFulfillment", itemContent, fulfillsLocally);
                        })
                    }

                    $scope.$on('dropdownActionExecuted', ($event, operation, item, dataRelation) => {
                        if (operation == 'delete' && itemContent) {
                            if ((dataRelation && dataRelation.wffid === itemContent.wfid) || item.wffid === itemContent.wfid) {
                                $scope.$emit('dataAnswerChanged',  {
                                    itemContent,
                                    itemRelation: itemDataRelation,
                                    itemComposite,
                                    requirement: getRequirement(),
                                })
                                $scope.$emit('checkLocalFulfillment', itemContent, { intersectionSettings })
                            }

                        }
                    })

                    determinePickerOptions()

                    if (pickerOptions.openerButtonTranslate) {
                        vm.buttonCaption = $translate.instant(pickerOptions.openerButtonTranslate)
                    }
                    else if (pickerOptions.openerButtonMultilingual) {
                        vm.buttonCaption = pickerOptions.openerButtonMultilingual[wfAuth.getCulture()]
                    }
                    else {
                        vm.buttonCaption = $translate.instant('Attach')
                    }

                    if (pickerOptions.useOpenerButtonDropdown && pickerOptions.objectTypes && pickerOptions.objectTypes.length) {
                        vm.useDropdown = true
                        vm.dropdownActions = _.map(pickerOptions.objectTypes, (objectType) => {
                            return {
                                text: wfTranslate.instant('MAP_ObjectType', { type: objectType }),
                                icon: 'fa fa-plus',
                                action() {
                                    pickerOptions.objectTypes = [objectType]
                                    addInformation()
                                },
                            }
                        })
                    }

                    if (pickerOptions.openerButtons) {
                        _.each(pickerOptions.openerButtons, (buttonPickerOptions) => {
                            buildButton(buttonPickerOptions)
                        })
                    }
                    else {
                        buildButton(pickerOptions)
                    }

                    $element.append($compile(html)($scope))
					
                    // if (requirement) {
                    // 	// fulfillsLocally = 
                    // 	$scope.$emit("checkLocalFulfillment", itemContent, fulfillsLocally);
                    // }
                }

                function determinePickerOptions() {
                    let
                        pickerSettingsFromItemConditions
						
                    let pickerSettingsFromRelationSettings

                    pickerOptions = {
                        intersection: intersectionSettings,
                        objectTypes: objectTypesToAdd,
                        relationTarget: { item: itemContent, kind: subItemsKind },
                        influence,
                    }

                    if (pickerSettingsFromAttr) {
                        pickerOptions = _.defaultsDeep(_.cloneDeep(pickerSettingsFromAttr), pickerOptions)
                    }

                    pickerSettingsFromItemConditions = itemContent.conditions ? itemContent.conditions.pickerSettings : undefined
                    pickerSettingsFromRelationSettings = settings ? settings.pickerSettings : undefined

                    if (pickerSettingsFromItemConditions) {
                        pickerOptions = _.defaultsDeep(_.cloneDeep(pickerSettingsFromItemConditions), pickerOptions)
                    }

                    if (pickerSettingsFromRelationSettings) {
                        pickerOptions = _.defaultsDeep(_.cloneDeep(pickerSettingsFromRelationSettings), pickerOptions)
                    }

                    if (pickerOptions.relationTarget.item === '@currentContextParent') {
                        // In this scenario show the original itemContent at the top in the picker instead of @currentContextParent item
                        pickerOptions.displayTopItem = itemContent
                    }
                }

                function getRequirement(_itemComposite) {
                    if (_itemComposite) return requirements.getActualRequirement({
                        itemContent: _itemComposite.content,
                        itemRelation: _itemComposite.dataRelation,
                        itemComposite: _itemComposite,
                        organizationId,
                    })
                    else return requirement = requirements.getActualRequirement({
                        itemContent,
                        itemRelation: itemDataRelation,
                        itemComposite,
                        organizationId,
                    })
                }

                function addInformation() {
                    modal.openCreatorAndPicker(pickerOptions).modal.closed.then(() => {
                        let requirement = getRequirement()

                        if (itemComposite) itemComposite.updateChildren()

                        $scope.$emit('dataAnswerChanged', {
                            itemContent,
                            itemRelation: itemDataRelation,
                            itemComposite,
                            requirement,
                        })

                        $scope.$emit('checkLocalFulfillment', itemContent, { intersectionSettings })

                        // If parent itemComposite has subItemsSettings condition also do the same thing with the parent item
                        if (!requirement && itemComposite.parent && _.get(itemComposite, 'parent.content.conditions.subItemsSettings.pickerSettings') && itemComposite.parent.requirementVm) {
                            requirement = getRequirement(itemComposite.parent)
							
                            itemComposite.parent.updateChildren()

                            $scope.$emit('dataAnswerChanged', {
                                itemContent: itemComposite.parent.content,
                                itemRelation: itemComposite.parent.dataRelation,
                                itemComposite: itemComposite.parent,
                                requirement,
                            })

                            itemComposite.parent.requirementVm.checkLocalFulfillment(itemComposite.parent.content, { intersectionSettings })
                        }

                    })
                }

                function buildButton(buttonPickerOptions) {
                    const
                        bpo = buttonPickerOptions
						
                    const button = {}
						
                    const buttonId = ++buttonIdIncrementor
						
                    let interpolateFunc

                    vm['button' + buttonId] = button

                    if (bpo.openerButtonTranslate) {
                        button.buttonCaption = $translate.instant(bpo.openerButtonTranslate)
                    }
                    else if (bpo.openerButtonMultilingual) {
                        button.buttonCaption = bpo.openerButtonMultilingual[wfAuth.getCulture()]
                    }
                    else {
                        if (_.includes(buttonPickerOptions.objectTypes, enums.objectType.organization)) button.buttonCaption = $translate.instant('modules.valueChain.influence.addOrganization')
                        else if (_.includes(buttonPickerOptions.objectTypes, enums.objectType.country)) button.buttonCaption = $translate.instant('modules.valueChain.influence.addCountry')
                        else button.buttonCaption = $translate.instant('Attach')
                    }

                    if (bpo.useOpenerButtonDropdown && bpo.objectTypes && bpo.objectTypes.length) {
                        button.useDropdown = true
                        button.dropdownActions = _.map(bpo.objectTypes, (objectType) => {
                            return {
                                text: wfTranslate.instant('MAP_ObjectType', { type: objectType }),
                                icon: 'fa fa-plus',
                                action() {
                                    _.assign(pickerOptions, bpo)
                                    pickerOptions.objectTypes = [objectType]
                                    addInformation()
                                },
                            }
                        })
                        interpolateFunc = dropdownInterpolate
                    }
                    else {
                        interpolateFunc = buttonInterpolate

                        button.onClick = function () {
                            _.assign(pickerOptions, bpo)
                            vm.addInformation()
                        }
                    }

                    html += '<div>' + interpolateFunc({
                        btnId: buttonId,
                        buttonStyleClass: bpo.openerButtonStyleClass ? $sanitize(bpo.openerButtonStyleClass) : 'btn-primary',
                    }) + '</div>'
                }
            }],
        }

        return directive
    }
})()
