// @flow
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { MAX_PAGE_CONTENT_WIDTH } from '@worldfavor/constants'

const MaxWidthGrid = withStyles({
    // TODO in Material-ui v4 rename that class to 'root'
    [`@media (min-width: ${MAX_PAGE_CONTENT_WIDTH}px)`]: {
        item: {
            maxWidth: MAX_PAGE_CONTENT_WIDTH,
        },
    },
})(Grid)

export default MaxWidthGrid
