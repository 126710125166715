import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('SupplierCategoryController', SupplierCategoryController)

    SupplierCategoryController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$q', 'screenLoaderService', 'apiProxy', '$ngBootbox']
    function SupplierCategoryController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $q, screenLoader, apiProxy, $ngBootbox) {
        const
            vm = this
			
        let mainCategory
		
        _.assign(this, $scope.$parent.vm)
		
        $scope.modal = modal
        $scope.wfObject = wfObject
        $scope.dataQuery = dataQuery

        _.assign(vm, {
            loaded: false,
            filterBarList: {},
            filterByCategory,
            selectedCategory: null,
            openFilteredUsersModal,
            collectionQuery: { type: null },
            allSuppliers: [],
            pickedCategorySuppliers: [],
            filteredSuppliers: [],
            filterByFailedMails,
            sendMailToOrganization,
            showRequiresAction,
            requiresActionCount: 0,
            createSupplier() {
                const df = $q.defer()
                modal.createWithRelation({ objectType: 101, dataRelationOptions: { kind: 7, item1: mainCategory } }).then(() => {
                    setTimeout(() => {
                        if (vm.selectedParentId == mainCategory.id) filterByCategory(vm.selectedCategory)
							
                        df.resolve()
                    }, 1000)
                })
                return df.promise
            },
        })
		
        mainCategory = vm.item.childContent

        activate()

        ////////////////

        function sendMailToOrganization(organization, orgDataRelation) {
            const formSpec = {
                schema: {},
                form: ['*'],
                // onBeforeRender: function (model) {
                // },
                // onBeforeSubmit: function (model) {
                // }
            }

            formSpec.schema = {
                type: 'object',
                properties: {
                    subject: {
                        title: $translate.instant('Subject'),
                        type: 'string',
                        'x-schema-form': {
                        },
                    },
                    bodyText: {
                        title: 'Ingress', //$translate.instant("Body"),
                        type: 'string',
                        'x-schema-form': {
                            type: 'textarea',
                        },
                    },
                },
                required: ['subject', 'bodyText'],
            }

            modal.edit({
                organizationId: organization.id,
                networkId: vm.networkId,
                subject: 'Uppgifter att utföra av dig som leverantör',
                bodyText: 'Du har fått detta mail för att du har kontakt med våra kunder eller utför skadereparationer. Nedan ser du vilka uppgifter du har tilldelats.',
            },
            {
                title: $translate.instant('modules.valueChain.actions.sendMail'),
                action: 'suppliers.sendInviteMail',
                customFormSpecification: formSpec,
                bypassAdapter: true,
                submitCaption: $translate.instant('Send'),
            },
            ).then((updatedRequirement) => {

                // $ngBootbox.confirm("Skicka mail till: " + organization.name)
                // 	.then(function(result) {
                // 		apiProxy("suppliers.sendInviteMail", {
                // 			organizationId: organization.id,
                // 			networkId: vm.networkId,
                // 			creatorOrganizationId: orgDataRelation.organizationId
                // 		})
                // 	}, function() {
                // 	});
            })
        }

        function filterByFailedMails() {
            vm.filteredByFailedMails = !vm.filteredByFailedMails
            vm.filterByCategory()
        }

        function activate() {
            $scope.$watch('vm.search', () => {
                applySearchFilter()
				
                if (vm.listControl) vm.listControl.compile()
            })
			
            loadStructureDependencies().then(() => {
                vm.allSuppliers = vm.filteredSuppliers = vm.pickedCategorySuppliers = _.orderBy(mainCategory.childs, ['createdAt'], ['desc'])
                // console.log(vm.filteredSuppliers);
				
                vm.selectedCategory =  mainCategory
                vm.selectedParentId = mainCategory.id
                vm.collectionQuery = {
                    where:
					{
					    type: { in: [73, 81] },
					    parentType: 71,
					    parentId: mainCategory.id,
					    childType: 101,
					    childId: { in: dataQuery.mapProperty('childId',
					        {
					            where:
								{
								    type: { in: [73, 81] },
								    childType: 101,
								    parentType: 71,
								    parentId: vm.selectedParentId,
								},
					        }),
					    },
					},
                }
                vm.organizationIds = dataQuery.mapProperty('childId', vm.collectionQuery)

                apiProxy('multi.getObjects', {
                    objectType: enums.objectType.mailOutbound,
                    wrapInRelations: false,
                    organizationIds: vm.organizationIds,
                }).then((mailOutboundResult) => {
                    vm.failedMailsByOrg = _.keyBy(mailOutboundResult, 'toOrganizationId')
					
                    vm.chartSource = wfObject.filter({
                        where:
						{
						    type: 71,
						    id:
							{
							    in: dataQuery.mapProperty('objectId',
							        {
							            where:
									{
									    type: 13,
									    objectType: 71,
									    organizationId:
										{
										    in: vm.organizationIds,
										},
									},
							        }),
							},
						},
                    })
					
                    vm.categoryFilterButtons = wfObject.filter({
                        where:
						{
						    type: 71,
						    id:
							{
							    // Get all categories on the organizations, except the current category
							    '!==': mainCategory.id,
							    in: dataQuery.mapProperty('parentId',
							        {
							            where:
									{
									    type: { in: [73, 81] },
									    parentType: 71,
									    childType: 101,
									    childId:
										{
										    // Get all organizations in the current category
										    in: dataQuery.mapProperty('childId',
										        {
										            where:
												{
												    type: { in: [73, 81] },
												    childType: 101,
												    parentType: 71,
												    parentId: mainCategory.id,
												},
										        }),
										},
									},
							        }),
							},
						},
                    })
					
                    vm.categoryFilterButtons.unshift({ title: $translate.instant('All'), id: mainCategory.id })
                    loadNotifications()

                    vm.requiresAction = wfObject.filter({ where: { type: 13, fulfilled: false, objectType: 71, organizationId: { in: vm.organizationIds } } })
                    vm.requiresAction = _.filter(vm.requiresAction, (influence) => {
                        return influence.fulfillmentDueAt && moment().isAfter(influence.fulfillmentDueAt, 'days')
                    })
                    vm.requiresActionCount = vm.requiresAction.length
					
                    $timeout(() => {
                        vm.loaded = true
                        vm.listControl.compile()
                    }, 100)
                })
            })
        }
		
        function loadStructureDependencies() {
            const jqDf = $.Deferred()
			
            // Load parent/parent/parent (Bygg > Kategorier > Huvudkategorier/Län/Inköpare > Stockholm)
            //                                 |                                                 |
            //             	Gets the dataRelation object of Bygg/Motor                      mainCategory
            dataOps.getObjectByPath(mainCategory, 'parent/parent/parent').then((supplierGroupDataRelation) => {
                // console.log(supplierGroupDataRelation);
                vm.networkId = supplierGroupDataRelation.parentContent.conditions.networkId // parentContent is Bygg/Motor
                // TODO: Make sure that the below code only loads what is necessary
				
                // Load children of Bygg/Motor with depth 3 (will load Kategorier and Krav structures)
                dataOps.getSubItems(supplierGroupDataRelation.parentContent, 1, { childrenLoadDepth: 3 }).then((res) => {
					
                    dataOps.getObjectByPath(supplierGroupDataRelation.parentContent, 'parent/child:1/child').then(() => {

                        jqDf.resolve()
						
                    })
                })
            })
			
            return jqDf.promise()
        }
		
        const
            escapeCharacters = /[-\/\\^$*+?.()|[\]{}]/g
			
        const whiteSpaces = /\s+/g
		
        function applySearchFilter() {
            let
                regexp; let res; let words
				
            const searchString = vm.search
			
            if (searchString && searchString !== '')
            {
                words = _.uniq(searchString.trim().replace(whiteSpaces, ' ').replace(escapeCharacters, '').split(' '))
                regexp = new RegExp('(' + words.join(')|(') + ')', 'gi')
                vm.filteredSuppliers = _.filter(vm.pickedCategorySuppliers, (x) => {
                    if (x.childContent && x.childContent.title)
                    {
                        res = x.childContent.title.match(regexp)
                        return res && _.uniq(res).length === words.length
                    }
                    else if (x.childContent && x.childContent.name)
                    {
                        res = x.childContent.name.match(regexp)
                        return res && _.uniq(res).length === words.length
                    }
                    else if (x.childContent && x.childContent.text)
                    {
                        res = x.childContent.text.match(regexp)
                        return res && _.uniq(res).length === words.length
                    }
                    else if (x.childContent && x.childContent.description)
                    {
                        res = x.childContent.description.match(regexp)
                        return res && _.uniq(res).length === words.length
                    }
                    else return false
                })
            }
            else vm.filteredSuppliers = vm.pickedCategorySuppliers
        }
		
        function filterByCategory(category) {
            // return;
            screenLoader.show()
            $timeout(() => {
                if (category) {
                    vm.selectedCategory = category
                    vm.selectedParentId = (vm.selectedParentId == category.id ? mainCategory.id : category.id)
                }
                vm.collectionQuery = {
                    where:
					{
					    type: { in: [73, 81] },
					    parentType: 71,
					    parentId: mainCategory.id,
					    childType: 101,
					    childId: { in: dataQuery.mapProperty('childId',
					        {
					            where:
								{
								    type: { in: [73, 81] },
								    childType: 101,
								    parentType: 71,
								    parentId: vm.selectedParentId,
								},
					        }),
					    },
					},
                }
                vm.organizationIds = dataQuery.mapProperty('childId', vm.collectionQuery)
                vm.chartSource = wfObject.filter({
                    where:
					{
					    type: 71,
					    id:
						{
						    in: dataQuery.mapProperty('objectId',
						        {
						            where:
								{
								    type: 13,
								    objectType: 71,
								    organizationId:
									{
									    in: vm.organizationIds,
									},
								},
						        }),
						},
					},
                })
				
                // console.log(vm.filteredByFailedMails, vm.failedMailsByOrg)
                vm.filteredSuppliers = vm.pickedCategorySuppliers
					= _.chain(wfObject.filter(vm.collectionQuery)).filter((item) => {
                        if (!vm.filteredByFailedMails) {
                            return true
                        }
                        else if (vm.failedMailsByOrg[item.childContent.id]) {
                            return true
                        }
                    }).orderBy(wfObject.filter(vm.collectionQuery), ['createdAt'], ['desc']).value()

                applySearchFilter()
				
                loadNotifications()
                vm.listControl.compile()

                vm.requiresAction = wfObject.filter({ where: { type: 13, fulfilled: false, objectType: 71, organizationId: { in: vm.organizationIds } } })
                vm.requiresAction = _.filter(vm.requiresAction, (influence) => {
                    return influence.fulfillmentDueAt && moment().isAfter(influence.fulfillmentDueAt, 'days')
                })
                vm.requiresActionCount = vm.requiresAction.length

                screenLoader.hide()
            }, 50)
        }
		
        function openFilteredUsersModal(structureItem) {
            modal.openItem({
                meta: {
                    title: structureItem.title,
                    settings: {
                        templateId: 21,
                        childrenTemplateId: 7,
                    },
                },
                viewMode: true,
                highlightInfluence: true,
                onlyFulfilled: true,
                structureId: structureItem.id,
                query:
				{
				    where:
					{
					    type: { in: [73, 81] },
					    childType: 101,
					    parentType: 71,
					    parentId: vm.selectedParentId,
					    childId:
						{
						    in: dataQuery.mapProperty('organizationId', {
						        where:
								{
								    type: 13,
								    objectType: 71,
								    objectId: structureItem.id,
								    organizationId:
									{
									    in: vm.organizationIds,
									},
								},
						    }),
						},
					},
				},
            })
        }

        function loadNotifications() {
            dataOps.getObjects({
                objectType: enums.objectType.notification,
                organizationIds: vm.organizationIds,
            }).then((notificationsResult) => {
                // var
                // 	wfids = _.map(eventLogResult.childs, 'childContent.wfcid') // eventLogResult > childs(dataRelations) > childContent(logEvent) > childContent(items to get)
                // ;

                // wfids = _.uniq(wfids);
                // console.info(eventLogResult);
                // dataOps.getObjects(wfids).then(function () {
                $timeout(() => {
                    const filteredNotifications = []

                    _.forEach(notificationsResult, (item) => {
                        // if (item.childContent || item.objectData) {
                        filteredNotifications.push(item.childContent)
                        // }
                    })

                    vm.notifications = filteredNotifications
                })

                // });
            })
        }

        function showRequiresAction() {
            _.forEach(wfObject.filter({ where: { type: 13, fulfilled: false, objectType: 71, organizationId: { in: vm.organizationIds } } }), (influence) => {
                if (influence.fulfillmentDueAt && moment().isAfter(influence.fulfillmentDueAt, 'days')) influence.isOverDue_calc = true
                else influence.isOverDue_calc = false
            })

            modal.openItem({
                meta: {
                    title: $translate.instant('modules.valueChain.suppliersThatRequiresAction'),
                    settings: {
                        templateId: 21,
                        childrenTemplateId: 7,
                    },
                },
                viewMode: true,
                highlightInfluence: true,
                onlyOverdue: true,
                query:
				{
				    where:
					{
					    type: 73,
					    childType: 101,
					    parentType: 71,
					    parentId: vm.item.childContent.id,
					    childId:
						{
						    in: dataQuery.mapProperty('organizationId', {
						        where:
								{
								    type: 13,
								    objectType: 71,
								    fulfilled: false,
								    isOverDue_calc: true,
								    organizationId:
									{
									    in: vm.organizationIds,
									},
								},
						    }),
						},
					},
				},
            })
        }
		
    }
})()
