// @flow
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'

import FindOrganizationStep from './FindOrganizationStep'
import CreateOrganizationStep from './CreateOrganizationStep'
import ContactPersonStep from './ContactPersonStep'
import FinishStep from './FinishStep'
import { FormattedMessage } from 'react-intl'
import { useFormValidation } from '@worldfavor/hooks'
import { loadExistingOrganizations } from '../../../actions/dataThunk'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'
import LeavePrompt from '@worldfavor/components/LeavePrompt'

type Props = {
  onCancel: () => void,
  onFinish: (formData: any) => void,
}

const useStyles = makeStyles({
    paper: {
        width: 880,
    },
    step: {
        display: 'flex',
        minHeight: 440,
        maxHeight: 440,
    },
    '@global a': {
        textDecoration: 'none',
    },
    cancelButton: {
        marginRight: 'auto',
    },
    dialogActions: {
    // This css style below is for IE11 and IE10 compatibility
    // According to StackOverflow:
    //
    // According to the flexbox specification:
    //
    // 8.1. Aligning with auto margins
    // Prior to alignment via justify-content and align-self, any positive free space is distributed to auto margins in that dimension.
    // Note: If free space is distributed to auto margins, the alignment properties will have no effect in that dimension because the margins will have stolen all the free space left over after flexing.
    //
    // In other words, auto margins take precedence over justify-content.
    // If an element has auto margins applied, then keyword alignment properties such as justify-content and align-self have no effect (because the auto margins have taken all the space).
    // Chrome and Firefox are in compliance with the spec.
    // IE10 and IE11 appear to not be in compliance. They are not applying the auto margin as defined in the spec.
    //
    // see https://stackoverflow.com/questions/37534254/flex-auto-margin-not-working-in-ie10-11
        justifyContent: 'flex-start',
    },
    titleStepperContainer: {
        display: 'flex',
    },
    stepper: {
        flex: 1,
        padding: '6px 0 0 20px',
        marginRight: -15,
    },
    stepIcon: {
        transform: 'scale(0.9)',
    },
    completedStepIcon: {
    },
    activeStepIcon: {
        transform: 'scale(1.1)',
        color: '#2ecc71 !important',
    },
    completedStepLabel: {
        fontWeight: '400 !important',
    },
})

const regNumberMinLength = 5
const vatNumberMinLength = 5
const regExps = {
    swedishOrgNumber: new RegExp(/^\d{6}[-]\d{4}$/),
    regNumber: new RegExp('^([/ .\\-\\da-zA-Z]{' + regNumberMinLength + ',})?$'),
    vatNumber: new RegExp('^([.\\-\\da-zA-Z]{' + vatNumberMinLength + ',})?$'),
    glnNumber: new RegExp(/^(\d{13})?$/),
    leiNumber: new RegExp(/^[\d]{6}[/ \da-zA-Z]{12}[\d]{2}$/),
    gstNumber: new RegExp(/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/),
    cinNumber: new RegExp(/^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/),
    invalidPatterns: new RegExp(/([ \-/]{2,})|([a-zA-z]{5,})|(www[.]?)|((https?):\/\/)(www.)?[-_a-z0-9]+\.[/a-z]*/),
}

const validRegNumber = (value, vatNumber) => {
    if (vatNumber && vatNumber.toUpperCase().startsWith('SE')) {
        return value && regExps.swedishOrgNumber.test(value)
    }
    else {
        const match = regExps.regNumber.test(value)
        if (match && (!regExps.invalidPatterns.test(value) || regExps.leiNumber.test(value) || regExps.gstNumber.test(value) || regExps.cinNumber.test(value))) {
            return true
        }
    }
}
const validVatNumber = value => regExps.vatNumber.test(value) && !regExps.invalidPatterns.test(value)
const validGlnNumber = value => regExps.glnNumber.test(value)

const findOrganizationDescriptors = {
    gln: {
        id: 'gln-number',
        labelId: 'supplyChain.organizationForm.step0.fields.glnNumber.label',
        helperText: (
            <ul>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.glnNumberFormat'} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.leaveEmpty'} /></li>
                <li><FormattedMessage
                    id={'supplyChain.organizationForm.step0.fieldHelperTexts.glnNumberLink.info'}
                    values={{
                        link: (
                            <a href="https://www.gs1.se/en/Support/Number-information-service" target="_blank" rel="noopener noreferrer" tabIndex="-1">
                                <FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.glnNumberLink.linkCaption'} /><i className="fas fa-external-link-alt" style={{ marginLeft: '0.4em', marginRight: '0.1em' }} />
                            </a>
                        ),
                    }}
                />
                </li>
            </ul>
        ),
        // validator: (value, formData) => validRegNumber(formData.registrationNumber, formData.vatNumber) || validVatNumber(formData.vatNumber) || validGlnNumber(value),
        validator: value => validGlnNumber(value),

    },
    registrationNumber: {
        id: 'registration-number',
        labelId: 'supplyChain.organizationForm.step0.fields.registrationNumber.label',
        helperText: (
            <ul>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.minimumLength'} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.leaveEmpty'} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.registrationNumberFormat'} values={{ b: (...chunks) => <strong>{chunks}</strong> }} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.registration_onlyCertainCharacters'} /></li>
                {/*<li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.onlyWriteProperIdentifiers'} values={{ b: (...chunks) => <strong>{chunks}</strong> }} /></li>*/}
            </ul>
        ),
        // validator: (value, formData) => validRegNumber(value, formData.vatNumber) || validVatNumber(formData.vatNumber) || validGlnNumber(formData.gln),
        validator: value => validRegNumber(value),
    },
    vatNumber: {
        id: 'vat-number',
        labelId: 'supplyChain.organizationForm.step0.fields.vatNumber.label',
        helperText: (
            <ul>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.minimumLength'} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.leaveEmpty'} /></li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.vatNumberFormat'} values={{ b: (...chunks) => <strong>{chunks}</strong> }} /></li>
                <li><FormattedMessage
                    id={'supplyChain.organizationForm.step0.fieldHelperTexts.vatNumberLink.info'}
                    values={{
                        link: (
                            <a href="https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11" target="_blank" rel="noopener noreferrer" tabIndex="-1">
                                <FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.vatNumberLink.linkCaption'} /><i className="fas fa-external-link-alt" style={{ marginLeft: '0.4em', marginRight: '0.1em' }} />
                            </a>
                        ),
                    }}
                />
                </li>
                <li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.vat_onlyCertainCharacters'} /></li>
                {/*<li><FormattedMessage id={'supplyChain.organizationForm.step0.fieldHelperTexts.onlyWriteProperIdentifiers'} values={{ b: (...chunks) => <strong>{chunks}</strong> }} /></li>*/}
            </ul>
        ),
        // validator: (value, formData) => {
        //   formData.vatNumber = formData.vatNumber.toUpperCase()
        //   return validRegNumber(formData.registrationNumber, formData.vatNumber) || validVatNumber(value) || validGlnNumber(formData.gln)
        // },
        validator: value => validVatNumber(value),
    },
}

const organizationDescriptors = {
    image: {},
    name: {
        id: 'organization-name',
        labelId: 'supplyChain.organizationForm.step1.fields.organizationName',
        required: true,
    },
    location: {
        id: 'location',
        labelId: 'supplyChain.organizationForm.step1.fields.address',
        required: true,
    },
    website: {
        id: 'website',
        labelId: 'supplyChain.organizationForm.step1.fields.website',
        required: false,
        type: 'url',
    },
}

const contactDescriptors = {
    firstName: {
        id: 'first-name',
        labelId: 'supplyChain.organizationForm.step2.fields.firstName',
        required: true,
    },
    lastName: {
        id: 'last-name',
        labelId: 'supplyChain.organizationForm.step2.fields.lastName',
        required: true,
    },
    title: {
        id: 'title',
        labelId: 'supplyChain.organizationForm.step2.fields.title',
    },
    email: {
        id: 'email',
        labelId: 'supplyChain.organizationForm.step2.fields.email',
        required: true,
        type: 'email',
    },
    phoneNumber: {
        id: 'phone-number',
        labelId: 'supplyChain.organizationForm.step2.fields.phoneNumber',
    },
    language: {
        id: 'language',
        labelId: 'supplyChain.organizationForm.step2.fields.language',
    },
}

const steps = [
    'supplyChain.organizationForm.step0.label',
    'supplyChain.organizationForm.step1.label',
    'supplyChain.organizationForm.step2.label',
    'supplyChain.organizationForm.step3.label',
]

const initialFindOrganizationForm = {
    registrationNumber: '',
    vatNumber: '',
    gln: '',
}

const initialOrganizationForm = {
    image: null,
    name: '',
    location: null,
    website: '',
}

const initialContactForm = {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    language: '',
}

const OrganizationFormDialog = (props: Props) => {
    const { onCancel, onFinish, ...rest } = props
    const classes = useStyles(props)
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0)
    const [
        findOrganizationForm,
        setFindOrganizationFormField,
        findOrganizationValidation,
        validateFindOrganizationForm,
        setFindOrganizationForm,
    ] = useFormValidation(initialFindOrganizationForm, findOrganizationDescriptors)

    const [
        organizationForm,
        setOrganizationFormField,
        organizationValidation,
        validateOrganizationForm,
        setOrganizationForm,
    ] = useFormValidation(initialOrganizationForm, organizationDescriptors)

    const [
        contactForm,
        setContactFormField,
        contactValidation,
        validateContactForm,
        setContactForm,
    ] = useFormValidation(initialContactForm, contactDescriptors)

    const [showFindOrganizationValidation, setShowFindOrganizationValidation] = useState(false)
    const [showOrganizationValidation, setShowOrganizationValidation] = useState(false)
    const [showContactValidation, setShowContactValidation] = useState(false)
    const [existingOrganizations, setExistingOrganizations] = useState([])
    const [loadingOrganization, setLoadingOrganization] = useState(false)
    const [useExistingOrganization, setUseExistingOrganization] = useState(null)
    const [saving, setSaving] = useState(false)

    const [fetchExistingOrganizations] = useDebouncedCallback(async (registrationNumber, vatNumber, gln) => {
        try {
            setLoadingOrganization(true)
            const organizations = await dispatch(loadExistingOrganizations(registrationNumber, vatNumber, gln))
            setExistingOrganizations(organizations)
        } catch (e) {
            // TODO handle error
            console.warn(e)
        } finally {
            setLoadingOrganization(false)
        }
    }, 200)

    function onSelectExistingOrganization(event, wfid) {
    // TODO go to last screen and use existing org
        const organization = existingOrganizations.find(organization => organization.wfid === wfid)
        setUseExistingOrganization(organization)
        setActiveStep(3)
    }

    async function onFindOrganizationFieldChange(event, key, value) {
    //setShowFindOrganizationValidation(true)
        if (key === 'registrationNumber' || key === 'vatNumber' || key === 'gln') {
            const registrationNumber = key === 'registrationNumber' ? value : findOrganizationForm.registrationNumber
            const vatNumber = key === 'vatNumber' ? value : findOrganizationForm.vatNumber
            const gln = key === 'gln' ? value : findOrganizationForm.gln

            const params = [
                registrationNumber.length >= regNumberMinLength && registrationNumber,
                vatNumber.length >= vatNumberMinLength && vatNumber,
                validGlnNumber(gln) && gln,
            ]

            if (params.some(Boolean)) {
                fetchExistingOrganizations(...params)
            }
            else {
                setExistingOrganizations([])
            }
        }

        setFindOrganizationFormField(key)(value)
    }

    function onOrganizationFieldChange(event, key, value) {
        setOrganizationFormField(key)(value)
    }

    function onContactFieldChange(event, key, value) {
        setContactFormField(key)(value)
    }

    function getStepContent(stepIndex) {
        const steps = {
            step0: (
                <FindOrganizationStep
                    fields={findOrganizationForm}
                    validation={findOrganizationValidation}
                    descriptors={findOrganizationDescriptors}
                    onChange={onFindOrganizationFieldChange}
                    showValidation={showFindOrganizationValidation}
                    onSelectOrganization={onSelectExistingOrganization}
                    existingOrganizations={existingOrganizations}
                />
            ),
            step1: (
                <CreateOrganizationStep
                    fields={organizationForm}
                    validation={organizationValidation}
                    descriptors={organizationDescriptors}
                    onChange={onOrganizationFieldChange}
                    showValidation={showOrganizationValidation}
                />
            ),
            step2: (
                <ContactPersonStep
                    fields={contactForm}
                    validation={contactValidation}
                    descriptors={contactDescriptors}
                    onChange={onContactFieldChange}
                    showValidation={showContactValidation}
                />
            ),
            step3: <FinishStep />,
        }
        return steps[`step${stepIndex}`] || null
    }

    async function handleFinish() {
        const useExisting = Boolean(useExistingOrganization)
        const formData = {
            content: (
                useExisting ?
                    useExistingOrganization
                    : {
                        organization: {
                            ...organizationForm,
                            ...findOrganizationForm,
                        },
                        contact: contactForm,
                    }
            ),
            useExisting,
        }

        setSaving(true)
        const shouldReset = await onFinish(formData)

        setSaving(false)

        if (shouldReset) {
            setTimeout(() => {
                resetForm()
            }, 100)
        }
    }

    function handleNext() {
        const isFormValid = validateFindOrganizationForm()
        const hasAnyFieldValue = (Boolean)(findOrganizationForm['gln'] || findOrganizationForm['registrationNumber'] || findOrganizationForm['vatNumber'])

        if (activeStep === 0 && (!hasAnyFieldValue || !isFormValid)) {
            setShowFindOrganizationValidation(true)
            return
        }
        else if (activeStep === 1 && !validateOrganizationForm()) {
            setShowOrganizationValidation(true)
            return
        }
        else if (activeStep === 2 && !validateContactForm()) {
            setShowContactValidation(true)
            return
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    function handleCancel() {
        onCancel()
        setTimeout(() => {
            resetForm()
        }, 100)
    }

    function resetForm() {
        setActiveStep(0)
        setFindOrganizationForm(initialFindOrganizationForm)
        setOrganizationForm(initialOrganizationForm)
        setContactForm(initialContactForm)

        setShowFindOrganizationValidation(false)
        setShowOrganizationValidation(false)
        setShowContactValidation(false)

        setExistingOrganizations([])
        setUseExistingOrganization(null)
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            maxWidth={false}
            {...rest}
        >
            <LeavePrompt when />
            <DialogTitle>
                <div className={classes.titleStepperContainer}>
                    <FormattedMessage id={'supplyChain.organizationPickerDialog.title'} />
                    <Stepper activeStep={activeStep} alternativeLabel classes={{ root: classes.stepper }}>
                        {
                            steps.map((label, index) => {
                                const stepProps = {}
                                const labelProps = {}
                                return (
                                    <Step key={`organization-step-${label}`} {...stepProps}>
                                        <StepLabel classes={{ completed: classes.completedStepLabel }} StepIconProps={{ classes: { root: classes.stepIcon, active: classes.activeStepIcon, completed: classes.completedStepIcon } }} {...labelProps}>
                                            <FormattedMessage id={`supplyChain.organizationForm.step${index}.label`} />
                                        </StepLabel>
                                    </Step>
                                )
                            })
                        }
                    </Stepper>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.step}>
                    { getStepContent(activeStep) }
                </div>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button
                    disabled={saving}
                    className={classes.cancelButton}
                    onClick={handleCancel}
                >
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button
                    disabled={saving || activeStep === 0 || Boolean(useExistingOrganization)}
                    onClick={handleBack}
                >
                    <FormattedMessage id={'general.back'} />
                </Button>

                {
                    activeStep === steps.length - 1 ? (
                        <CircularProgressWrapper loading={saving}>
                            <Button disabled={saving} onClick={handleFinish}>Finish</Button>
                        </CircularProgressWrapper>
                    ) : (
                        <Button
                            disabled={(activeStep === 0 && (existingOrganizations.length > 0 || loadingOrganization))}
                            onClick={handleNext}
                        >
                            <FormattedMessage id={'general.next'} />
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}

OrganizationFormDialog.defaultProps = {
    onFinish: () => true,
    onCancel: () => {},
}

export default OrganizationFormDialog
