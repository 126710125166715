(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfCompile', wfCompile)
        .directive('wfCompileClone', wfCompileClone)
        .directive('wfCompileSpec', wfCompileSpec)
        .directive('wfConsoleLog', wfConsoleLog)

    wfCompile.$inject = ['$parse', '$compile', '$timeout']

    function wfCompile($parse, $compile, $timeout) {
        const directive = {
            restrict: 'A',
            transclude: true,
            priority: 200,
            link,
        }
        return directive

        function link(scope, element, attrs, ctrl, transclude) {
            let
                control

            if (attrs.wfCompile) {
                control = $parse(attrs.wfCompile)(scope)
				
                if (control) control.compile = compile
                // console.info(control); 

                element[0].removeAttribute('wf-compile')
                compile(true)
            }
			
            function compile(dontSetHeight) {
                let oldHeight
				
                // console.log(element.height())
                if (!dontSetHeight && element.height() !== 0) {
                    // oldHeight = element.css("height");
                    // element.height(element.height());
                }

                // element.hide();
                transclude(scope, (clone) => {
                    element.html('')
                    element.append(clone)
                    // if (!dontSetHeight)
                    // 	element.css("height", oldHeight ? oldHeight : "");
                    // element.show();
                })
                $timeout()
            }
			
        }
    }

    wfCompileClone.$inject = []
    function wfCompileClone() {
        const directive = {
            controller: wfCompileCloneController,
            restrict: 'A',
            terminal: true,
            // compile: function(element, attrs) {
            // 	return {
            // 		post: function(scope, element, attrs, ctrl) {
            // 			console.log("compile", element.html());
            // 		}
            // 	};
            // }
        }
        return directive
    }

    wfCompileCloneController.$inject = ['$scope', '$element', '$attrs', '$parse', '$compile', '$timeout']
    function wfCompileCloneController($scope, $element, $attrs, $parse, $compile, $timeout) {
        let
            control
			
        let clonedScope
			
        const elementClone = $element[0].cloneNode(true)

        // ##############################################################
        // This directive does not work when ui-view directive is used
        // anywhere inside the element that is being compiled.
        // ##############################################################

        if ($attrs.wfCompileClone) {
            control = $parse($attrs.wfCompileClone)($scope)
            if (control) control.compile = compile

            $element[0].removeAttribute('wf-compile')
            clonedScope = $scope.$new()
            $compile($element.contents())(clonedScope)
            $timeout()
        }
			
        function compile() {
            let newElementClone; let compiledElement
            let oldHeight; let newHeight; let oldClonedScope

            if ($element) {
                oldHeight = $element.height()
                $element.height($element.height())
				
                // $element.animate({ opacity: 0 }, 50, function () {
                clonedScope.$destroy()
                clonedScope = null

                newElementClone = elementClone.cloneNode(true)
                clonedScope = $scope.$new()
			
                $compile(newElementClone.childNodes)(clonedScope)
                $timeout()

                setTimeout(() => {
                    $element.children().remove()
                    $element.html('')

                    $element.append(newElementClone.childNodes)
                    setTimeout(() => {
                        $element.css('height', '')
                    }, 0)
                }, 0)
					
                // 	setTimeout(function () {
                // 		$element.css("height", "");
                // 		newHeight = $element.height();
                // 		$element.height(oldHeight);
                // 		$element.animate({ opacity: 1, height: newHeight }, 250, function () {
                // 			$element.css("height", "");
                // 		});
                // 	}, 50)
                // });
            }
        }
        // $compile($element.contents())($scope);
    }

    wfCompileSpec.$inject = []
    function wfCompileSpec() {
        const directive = {
            controller: wfCompileSpecController,
            restrict: 'A',
            terminal: true,
            // compile: function(element, attrs) {
            // 	return {
            // 		post: function(scope, element, attrs, ctrl) {
            // 			console.log("compile", element.html());
            // 		}
            // 	};
            // }
        }
        return directive
    }

    wfCompileSpecController.$inject = ['$scope', '$element', '$attrs', '$parse', '$compile', '$timeout', '$rootScope']
    function wfCompileSpecController($scope, $element, $attrs, $parse, $compile, $timeout, $rootScope) {
        const
            config = $parse($attrs.wfCompileSpec)($scope)
			
        let control
			
        let clonedScope

        compile()

        // ##############################################################
        // This directive does not work when ui-view directive is used
        // anywhere inside the element that is being compiled.
        // ##############################################################

        // if ($attrs.wfCompileClone) {
        // 	control = $parse($attrs.wfCompileClone)($scope);
			
        // 	if (control)
        // 		control.compile = compile;

        // 	$element[0].removeAttribute('wf-compile');
        // 	clonedScope = $scope.$new();
        // 	$compile($element.contents())(clonedScope);
        // 	$timeout();
        // }
			
        function compile() {
            $scope.outerVm = config.outerVm
            $scope.$rootScope = $rootScope

            const elementClone = config.elementClone.cloneNode(true)
            const compiledElement = $compile(elementClone)($scope)
			
            $element.append(compiledElement.contents())
            // console.info("compile", $scope.$id, $scope.item);
            $timeout()
        }
        // $compile($element.contents())($scope);
    }

    wfConsoleLog.$inject = []
    function wfConsoleLog() {
        const directive = {
            controller: wfConsoleLogController,
            restrict: 'A',
        }
        return directive
    }

    wfConsoleLogController.$inject = ['$scope', '$parse', '$attrs']
    function wfConsoleLogController($scope, $parse, $attrs) {
        console.log($scope.$id, $scope, $parse($attrs.wfConsoleLog)($scope))
    }
})()
