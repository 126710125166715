import * as enums from '@worldfavor/constants/enums'
import { ConstVars } from '@worldfavor/constants'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .service('valueChainService', valueChainService)

    valueChainService.$inject = ['dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$q', 'wfTranslate', '$timeout', '$stateParams', '$rootScope', 'apiProxy', 'wfAuth', '$state', '$sanitize', '$ngBootbox', '$window', 'DataNegotiator', 'wfPropertyExtractor', 'wfMeasureService', '$compile', 'screenLoaderService', 'pdfGenerator', '$uibModal']
    function valueChainService(dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $q, wfTranslate, $timeout, $stateParams, $rootScope, apiProxy, wfAuth, $state, $sanitize, $ngBootbox, $window, DataNegotiator, wfPropertyExtractor, wfMeasureService, $compile, screenLoader, pdfGenerator, $uibModal) {
        const
            ids = {
                // External Data Collector
                networksStructure: 12226, // All networks are childrenByUser to this structure
                categoriesStructure: 12224, // All categories are childrenByUser to this structure
                categoryGroupsStructure: 97685, // All category groups are childrenByUser to this structure
                packagesStructure: 12225, // All requirement packages are childrenByUser to this structure
                internalPackagesStructure: 18721, // All internal requirement packages are childrenByUser to this structure
                mailSettingsStructure: 2365,  // The mail settings structure. It doesn't contain the actual MailSettings, they are fetched by the controller
                analyzePackagesStructure: 16075, // All analyze packages are childrenByUser to this structure
                automationStructure: 19890, // Structure that contains 'Email settings' and 'Analyze Packages'
                productsStructure: 30150, // All ProductService objects reported to a network --- How do decide? By visibility only?
                influencesStructure: 30152, // All influences created by authenticated organization are childrenByUser to this structure
                networksStructureBySolution: {
                    sustManagement: 19898,
                    sustSourcing: 19896,
                    sustSourcing2: 22092,
                    sustSourcing3: 22095,
                    sustSourcing4: 22098,
                    sustSourcing5: 25904,
                    sustLending: 19897,
                    sustInvestments: 25873,

                },

                // Internal Data Collector
                usersStructure: 13594, // Contains the current organization's users
                internalVCMailSettingsStructure: 13595, // The mail settings structure for Internal VC. It doesn't contain the actual MailSettings, they are fetched by the controller
                internalValueChainRootStructure: 13593,
            }

        _.assign(this, {
            //Variables
            ids,
            availableNetworks: undefined,

            //Functions
            loadNetworks,
            loadCategoriesInNetwork,
            loadPackagesInNetwork,
            openOrganizationUsersManager,
            openOrganizationCategoriesPicker,
            openInfluenceCreator,
            openInfluence,
            loadInternalValueChainUsers,
            loadOrganizationsInNetwork,
            loadCategorizationsInNetwork,
            deleteInfluence,
            createUser,
            createOrganization,
            setOrganizationYearlySpend,

            getCategoriesInNetwork,
            loadCategoryGroupsInNetwork,
            openConsolidatedPackageView,
            admin_openStandardCategorizer,
            getNetworkOrgRelations,
            loadCategoriesInNetworkAsFilterConfigInfo,

            getRequirementPackagesStatistics,
            recalculateInfluences,

            getAnalyzePackagesStatistics,
            recalculateAnalyzeJobs,
            abortJobInvocation,
            openInfluencesCreator,
            getInfluenceVerificationDocuments,
            openInfluenceSigningModal,
            openInfluencesUpdater,
            openInfluencesDeleter,
            openInfluencesMultiEditorConfigurator,
            openInfluenceCompletionSuccessModal,
            openAnalyzeJobsCreator,
            openAnalyzeJobsDeleter,
            exportAll,

            getCalculationSchedule,
            setCalculationSchedule,

            buildAnswerTypesOptionObject,
            getQuestionObjectSettings,
            getMeasureObjectSettings,

            getCachedNetworkCategoriesInfo: () => ({ ...this.networkCategoriesCache  }),
            setCachedNetworkCategoriesInfo: ({ categories, categoryGroups }) => {
                this.networkCategoriesCache = { categories, categoryGroups }
            },
            clearCachedNetworkCategoriesInfo: () => { this.networkCategoriesCache = { } },
        })

        return this

        function setPageTitle() {
            $rootScope.setPageTitle('Data Collector')
        }

        function loadInternalValueChainUsers(options) {
            if (!options || options && !(options.setPageTitle === false)) setPageTitle()

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.structure,
                    objectId: ids.usersStructure, // This will contain a network for each organization
                    childrenLoadDepth: 1,
                }).then((obj) => {
                    const output = {}

                    output.structure = obj
                    output.users = obj.childs
                    resolve(output)
                })
            })
        }

        function loadNetworks(options) {
            const serviceVm = this
            if (!options || options && !(options.setPageTitle === false)) setPageTitle()

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.structure,
                    objectId: ids.networksStructure, // This will contain a network for each organization
                    childrenLoadDepth: 1,
                    loadParents: false,
                }).then((obj) => {
                    const output = {}

                    output.structure = obj
                    output.networks = _.map(obj.childs, 'childContent')
                    output.networkIds = _.map(output.networks, 'id')
                    output.networksById = _.keyBy(output.networks, 'id')

                    serviceVm.availableNetworks = output
                    resolve(output)
                })
            })
        }

        function loadCategoriesInNetwork(networkOrId, options) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.network,
                    objectId: networkId,
                    childrenLoadDepth: 1,
                    skipExtras: true,
                    getterConditions: {
                        objectTypes: [enums.objectType.structure],
                    },
                }).then((network) => {
                    dataOps.getObject({
                        objectId: ids.categoriesStructure, // Contains the current organization's data collector categories
                        objectType: enums.objectType.structure,
                        childrenLoadDepth: 1,
                        loadMetadata: false,
                        loadVisibilityTags: false,
                        getterConditions: {
                            loadCreators: false,
                            includeOrganizations: false,
                        },
                    }).then((structure) => {
                        loadCategoryGroupsInNetwork(networkOrId).then((items) => {
                            const categoryDataRelations = getCategoriesInNetwork(network, options)

                            resolve(categoryDataRelations)
                        })
                    })
                })
            })
        }

        function loadOrganizationsInNetwork(networkOrId, skipInject) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                apiProxy('multi.getObject', {
                    objectType: enums.objectType.network,
                    objectId: networkId,
                    childrenLoadDepth: 1,
                    loadParents: false,
                    getterConditions: {
                        objectTypes: [enums.objectType.organization],
                    },
                }).then((obj) => {
                    if (!skipInject) wfObject.inject(obj)

                    resolve(_.filter(obj.childs, 'organizationId'))
                })
            })
        }

        function getCategoriesInNetwork(networkOrId, options) {
            const
                network = typeof networkOrId === 'number' ? wfObject.get('52-' + networkOrId) : networkOrId
            const
                structureWithCategories = wfObject.get('71-' + ids.categoriesStructure) || {}

            const relationsInNetwork = _.filter(network.childs, { childType: enums.objectType.structure })

            const relationsInNetworkByChildId = _.keyBy(relationsInNetwork, 'childId')

            // Filter mainStructure childs to only get the children that are also in the network.
            let items = _.filter(structureWithCategories.childs, (dataRelation) => {
                const networkDataRelation = relationsInNetworkByChildId[dataRelation.childId]
                if (networkDataRelation) {
                    return true
                }
            })

            // Order by parent dataRelation order, then by parent title, then by category title
            const categoryWfids = _.map(items, 'wfcid')
            const parentDataRelations = wfObject.filter({
                where: {
                    type: enums.objectType.dataRelation,
                    parentType: enums.objectType.structure,
                    childType: enums.objectType.structure,
                    parentData1: null,
                    wfcid: { in: categoryWfids },
                    parentId: { '!=': ids.categoriesStructure },
                },
            })

            items = _.chain(items).map((dataRelation) => {
                const
                    childContent = dataRelation.childContent

                const parentDataRelation = _.find(parentDataRelations, { wfcid: dataRelation.wfcid })

                const parentContent = _.get(parentDataRelation, 'parentContent')
                const groupCategoryParentRelation = parentContent ? parentContent.parents.filter(x => x.parentId === ids.categoryGroupsStructure)[0] : {}
                const order = _.get(groupCategoryParentRelation, 'order') || -1

                return {
                    wfid: childContent.wfid,
                    content: childContent,
                    title: childContent.title,
                    dataRelation,
                    order: dataRelation.order,
                    parentWfid: parentContent ? parentContent.wfid : undefined,
                    parentOrder: order,
                    parentContent,
                    parentTitle: parentContent ? parentContent.getMainTextual() : undefined,
                }
            }).orderBy(['parentOrder', 'order', 'parentTitle', 'title'], ['asc', 'asc', 'asc', 'asc']).value()

            if (_.get(options, 'formatWithParents')) return items
            else return _.map(items, 'dataRelation')
        }

        function loadCategorizationsInNetwork(networkOrId, skipInject) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                apiProxy('valuechainapi.getCategorizationRelations', {
                    networkId,
                }).then((relations) => {
                    if (!skipInject) wfObject.inject(relations)

                    resolve(relations)
                })
            })
        }

        function loadCategoriesInNetworkAsFilterConfigInfo(networkOrId, organizationIds, filterConfigInfo) {
            const
                networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            if (!filterConfigInfo) filterConfigInfo = {}

            return $q((resolve, reject) => {
                loadOrganizationsInNetwork(networkId, true).then((res) => {
                    organizationIds = _.chain(res).map('childId').compact().value()

                    loadCategoriesInNetwork(networkId, { formatWithParents: true }).then((res) => {
                        const allCategoryWFIDsInNetwork = _.chain(res).map('wfid').uniq().value()

                        loadCategorizationsInNetwork(networkId).then(() => {
                            const
                                categoryOrganizationRelations = wfObject.filter({
                                    where: {
                                        type: enums.objectType.dataRelation,
                                        parentType: enums.objectType.structure,
                                        childType: enums.objectType.organization,
                                        childId: { in: organizationIds },
                                        wffid: { in: allCategoryWFIDsInNetwork },
                                    },
                                })

                            const orgIdsByCategoryWfid = _.chain(categoryOrganizationRelations)
                                .groupBy('wffid')
                                .mapValues((relations) => {
                                    return _.map(relations, 'childId')
                                })
                                .value()

                            let filterGroups

                            filterGroups = _.chain(res)
                                .groupBy('parentWfid')
                                .mapValues((categoryComposites, parentWfid) => {
                                    const
                                        parentContent = categoryComposites[0].parentContent

                                    return {
                                        header: parentContent ? parentContent.title : $translate.instant('Categories'),
                                        content: parentContent,
                                        filterOptionsSource: (function () {
                                            let output

                                            output = _.chain(categoryComposites)
                                                .map((categoryComposite) => {
                                                    const orgIds = orgIdsByCategoryWfid[categoryComposite.wfid] || []

                                                    return {
                                                        content: categoryComposite.content,
                                                        title: categoryComposite.title,
                                                        id: categoryComposite.wfid,
                                                        count: orgIds.length,
                                                        items: orgIds,
                                                    }
                                                })
                                                .value()

                                            return output
                                        })(),
                                    }
                                })
                                .map()
                                .value()

                            filterConfigInfo.unfilteredItems = organizationIds
                            filterConfigInfo.filteringConfig = {
                                items: organizationIds,
                                enableInvertedFiltering: _.get(filterConfigInfo, 'filteringConfig.enableInvertedFiltering') !== false,
                                initialSelectedFilterOptions: _.get(filterConfigInfo, 'filteringConfig.initialSelectedFilterOptions'),
                                onFiltered: _.debounce((filteredItems, selectedOptions) => {
                                    filterConfigInfo.filteringConfig.initialSelectedFilterOptions = selectedOptions
                                    filterConfigInfo.filteredOrganizationIds = filteredItems
                                    filterConfigInfo.selectedFiltersCount = selectedOptions.length
                                    if (filterConfigInfo.onFiltered) filterConfigInfo.onFiltered(filteredItems, selectedOptions)
                                }, 500, { leading: true }),
                                filters: filterGroups,
                            }

                            filterConfigInfo.filtersLoaded = true
                            resolve(filterConfigInfo)
                        })
                    })
                })
            })
        }

        function loadCategoryGroupsInNetwork(networkOrId) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.network,
                    objectId: networkId,
                    childrenLoadDepth: 1,
                    skipExtras: true,
                    getterConditions: {
                        objectTypes: [enums.objectType.structure],
                    },
                }).then((network) => {
                    const relationsInNetwork = _.filter(network.childs, { childType: enums.objectType.structure })
                    const relationsInNetworkByChildId = _.keyBy(relationsInNetwork, 'childId')
                    dataOps.getObject({
                        objectId: ids.categoryGroupsStructure, // Contains the current category's groups
                        objectType: enums.objectType.structure,
                        childrenLoadDepth: 1,
                        skipExtras: true,
                    }).then((structure) => {
                        // Filter mainStructure childs to only get the children that are also in the network.
                        const items = _.filter(structure.childs, (dataRelation) => {
                            const networkDataRelation = relationsInNetworkByChildId[dataRelation.childId]
                            if (networkDataRelation) {
                                return true
                            }
                        })
                        resolve(items)
                    })
                })
            })
        }

        function loadPackagesInNetwork(networkOrId, useInternalPackages) {
            const networkId = typeof networkOrId === 'number' ? networkOrId : networkOrId.id

            return $q((resolve, reject) => {
                dataOps.getObject({
                    objectType: enums.objectType.network,
                    objectId: networkId,
                    childrenLoadDepth: 1,
                    skipExtras: true,
                    getterConditions: {
                        objectTypes: [enums.objectType.structure],
                    },
                }).then((network) => {
                    const
                        relationsInNetwork = _.filter(network.childs, { childType: enums.objectType.structure })

                    const relationsInNetworkByChildId = _.keyBy(relationsInNetwork, 'childId')
                    dataOps.getObject({
                        objectId: useInternalPackages ? ids.internalPackagesStructure : ids.packagesStructure, // Contains the current organization's data collector packages
                        objectType: enums.objectType.structure,
                        childrenLoadDepth: 1,
                        skipExtras: true,
                    }).then((structure) => {
                        // Filter mainStructure childs to only get the children that are also in the network.
                        const items = _.filter(structure.childs, (dataRelation) => {
                            const networkDataRelation = relationsInNetworkByChildId[dataRelation.childId]
                            if (networkDataRelation) {
                                return true
                            }
                        })

                        resolve(items)
                    })
                })
            })
        }

        function openOrganizationUsersManager(org, network, compilerControl) {
            if (typeof network === 'number') network = { type: enums.objectType.network, id: network, wfid: '52-' + network }

            return modal.open({
                size: 'sm',
                controller: 'SupplierUsersController',
                templateUrl: 'scripts/wf/supplierPlatform/supplierUsers.controller.html',
                network,
                supplierOrg: org,
                compilerControl,
            })
        }

        function openOrganizationCategoriesPicker(org, networkId) {
            return modal.openCreatorAndPicker({
                relationTarget: { item: org, kind: 8 },
                emptyState: {
                    header: 'Ni har inte skapat någon kategori',
                    body: 'Gå till <strong>Data Collector &gt; Kategorier</strong> för att skapa kategorier',
                    imageWidth: 100,
                    image: '/assets/img/icons/folder-add.png',
                },
                create: false,
                canAttachInformationToRelation: true,
                sourceList() {
                    return $q((resolve, reject) => {
                        loadCategoriesInNetwork(networkId).then((dataRelations) => {
                            resolve(_.map(dataRelations, (dataRelation) => {
                                return {
                                    data: dataRelation.childContent,
                                    toggle: dataRelation.childContent,
                                    wfid: dataRelation.wfcid,
                                }
                            }))
                        })
                    })
                },
                templateId: 65,
                title: $translate.instant('modules.valueChain.categories.modalHeader', { orgname: org.name }),
            })
        }

        function createOrganization(network, model) {
            const
                swedishOrgNumberRegExp = /^(\d{1})(\d{5})\-(\d{4})$/

            const jqDf = $.Deferred()

            const mapOrgsByRegNumber = function (organizations) {
                return _.chain(organizations).filter((org) => {
                    return org.registrationNumber && org.registrationNumber.length > 0 // Ignore empty reg numbers
                }).map((org) => {
                    let orgNumber = org.registrationNumber

                    if (org.countryId === 190) { // Sweden
                        if (swedishOrgNumberRegExp.test(orgNumber)) orgNumber = orgNumber // If valid swedish reg number, use that
                        else if (orgNumber.length === 10 && !isNaN(orgNumber)) // If correct length and only numbers (like 1234567890)
                            orgNumber = orgNumber.substr(0, 6) + '-' + orgNumber.substr(6) // Add a dash (like 123456-7890)
                    }

                    return [orgNumber, org]
                }).fromPairs().value()
            }

            const formSpec = {
                schema: {},
                form: ['*'],
            }

            let isVatOrRegNumberValid = false
            let instantVatOrRegNumberValidation = false
            let regNumberValid = false
            let vatNumberValid = false

            formSpec.schema = {
                type: 'object',
                properties: {
                    name: {
                        title: $translate.instant('Name'),
                        type: 'string',
                        'x-schema-form': {
                        },
                    },
                    registrationNumber: {
                        title: $translate.instant('RegistrationNumber'),
                        type: 'string',
                        'x-schema-form': {
                            validationMessage: {
                                vatOrRegNumber: $translate.instant('validationMessages.vatRegOrGlnNumber'),
                            },
                            $validators: {
                                vatOrRegNumber(value) {
                                    if (!instantVatOrRegNumberValidation) return true

                                    const model = modalPromise.formControl.getModel()
                                    const result = !!(vatNumberValid || value)
                                    regNumberValid = !!value

                                    if (isVatOrRegNumberValid !== result) {
                                        isVatOrRegNumberValid = result
                                        modalPromise.formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', result)
                                    }

                                    return result
                                },
                            },
                        },
                    },
                    vatNumber: {
                        title: $translate.instant('VATNumber'),
                        type: 'string',
                        'x-schema-form': {
                            validationMessage: {
                                vatOrRegNumber: $translate.instant('validationMessages.vatRegOrGlnNumber'),
                            },
                            $validators: {
                                vatOrRegNumber(value) {
                                    if (!instantVatOrRegNumberValidation) return true

                                    const model = modalPromise.formControl.getModel()
                                    const result = !!(regNumberValid || value)
                                    vatNumberValid = !!value

                                    if (isVatOrRegNumberValid !== result) {
                                        isVatOrRegNumberValid = result
                                        modalPromise.formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', result)
                                    }

                                    return result
                                },
                            },
                        },
                    },
                    gln: {
                        title: $translate.instant('GLNNumber'),
                        type: 'string',
                    },
                },
                required: ['name'],
            }

            var modalPromise = modal.createWithPromise(_.assign({
                type: 101,
            }, model),
            {
                title: $translate.instant('modules.valueChain.organizations.createNew'),
                submitCaption: $translate.instant('Add'),
                customFormSpecification: formSpec,
                bypassAdapter: true,
                onBeforeSubmitTriggered(event) {
                    const
                        orgModel = event.getModel()

                    const formControl = modalPromise.formControl

                    orgModel.type == 101

                    event.setModel(orgModel)

                    if (!orgModel.registrationNumber && !orgModel.vatNumber) {
                        instantVatOrRegNumberValidation = true
                        formControl.$scope.$broadcast('schemaForm.error.registrationNumber', 'vatOrRegNumber', false)
                        formControl.$scope.$broadcast('schemaForm.error.vatNumber', 'vatOrRegNumber', false)
                        event.cancelSubmit()
                        return
                    }
                    // If the organization is not already in the Data Collector then
                    // check with server if it already exists in the database (using registration number)
                    apiProxy('utility.getOrganizationByCondition', {
                        registrationNumber: orgModel.registrationNumber,
                        vatNumber: orgModel.vatNumber,
                        gln: orgModel.gln,
                    }).then((orgs) => {
                        let org

                        if (!orgs.length) event.continueSubmit()
                        else {
                            if (orgs.length === 0) {
                                org = orgs[0]
                                $ngBootbox.customDialog({
                                    title: $translate.instant('modules.valueChain.organizations.alreadyExists.modalTitle'),
                                    message: $translate.instant('modules.valueChain.organizations.alreadyExists.modalMessage', {
                                        orgname: org.name,
                                        orgnumber: org.registrationNumber,
                                    }),
                                    onEscape: true,
                                    className: 'valueChain-modal-orgAlreadyExists',
                                    buttons: {
                                        cancel: {
                                            label: $translate.instant('No'),
                                            className: 'btn-default',
                                            callback() {
                                                event.cancelSubmit()
                                            },
                                        },
                                        primary: {
                                            label: $translate.instant('Yes'),
                                            className: 'btn-primary',
                                            callback() {
                                                wfObject.inject(org)
                                                org = wfObject.get(org.wfid)
                                                event.setResultAndCloseModal(org)
                                            },
                                        },
                                    },
                                })
                            }
                            else {
                                let sourceList = []

                                sourceList = _.map(orgs, (org) => {
                                    return {
                                        data: org,
                                        wfid: org.wfid,
                                    }
                                })

                                modal.openCreatorAndPicker({
                                    title: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalTitle'),
                                    description: $translate.instant('modules.valueChain.organizations.multipleAlreadyExists.modalMessage'),
                                    singlePick: true,
                                    relationBucket: { preSelected: [], allSelected: [] },
                                    sourceList,
                                    buttons: [
                                        {
                                            label: 'OK',
                                            callback($scope, relationBucketResult) {
                                                org = relationBucketResult.allSelected[0]
                                                if (org) {
                                                    wfObject.inject(org)
                                                    org = wfObject.get(org.wfid)
                                                    event.setResultAndCloseModal(org)
                                                }
                                                $scope.$close()
                                            },
                                        },
                                    ],
                                }).closed((relationBucketResult) => {
                                    event.cancelSubmit()
                                })
                            }
                        }
                    })
                },
            },
            )

            let orgCreated = false
            modalPromise.cancelled(() => {
                if (!orgCreated) jqDf.resolve()
            })

            modalPromise.then((organization) => {
                const
                    finish = function () {
                        dataOps.getObject({
                            objectType: enums.objectType.organization,
                            objectId: organization.id,
                            getterConditions: {
                                includeOrganizationsUsers: true,
                            },
                            // bypassCache: true
                        }).then(() => {
                            jqDf.resolve(organization)
                            $timeout()
                        })
                    }

                organization = wfObject.inject(organization)
                orgCreated = true
                dataOps.createSubItemRelation(network, organization, enums.subItemsKind.childrenByUser).then((res) => {
                    finish()
                })
            })

            return jqDf.promise()
        }

        function setOrganizationYearlySpend(organization, value) {
            if (typeof value === 'number') value = value.toString()

            return $q((resolve, reject) => {
                const promise = apiProxy.raw('multi.getObjects', {
                    objectType: enums.objectType.parameterValue,
                    wrapInRelations: false,
                    getterConditions: {
                        parameterId: 35,
                        objectType: enums.objectType.organization,
                        objectIds: [organization.id],
                    },
                })

                promise.then((res) => {
                    const existingParameterValue = res[0]

                    if (existingParameterValue) existingParameterValue.value = value

                    dataOps[existingParameterValue ? 'update' : 'create'](existingParameterValue || {
                        type: enums.objectType.parameterValue,
                        parameterId: 35,
                        objectType: enums.objectType.organization,
                        objectId: organization.id,
                        value,
                    }).then((parameterValue) => {
                        resolve(parameterValue)
                    })
                })
            })
        }

        // Users functions
        // ################################################################################

        function createUser(networkOrNetworks, organization, model) {
            const df = $q.defer()
            let networks

            if (networkOrNetworks instanceof Array && networkOrNetworks.length > 0) {
                networks = _.map(networkOrNetworks, (network) => {
                    return { item1: network, kind: enums.subItemsKind.children, contextParentWfid: organization.wfid }
                })
            }
            else if (typeof networkOrNetworks === 'object') {
                networks = [{ item1: networkOrNetworks, kind: enums.subItemsKind.children, contextParentWfid: organization.wfid }]
            }

            modal.createWithRelation({
                objectType: 100,
                initialValues: model,
                dataRelationOptions: { kind: 9, item1: organization },
                additionalDataRelations: networks,
                title: $translate.instant('modules.valueChain.contactPersons.createFormHeader'),
                submitCaption: $translate.instant('Add'),
            }).then((res) => {
                df.resolve(res)
            })

            return df.promise
        }

        // Influence functions
        // ################################################################################

        // Opens a modal for creating an influence.
        // Returns a promise that resolves when the influence has been created.
        // Additionally, promise.modal.closed will resolve when the modal has closed.
        function openInfluenceCreator(options) {
            const
                jqDf = $.Deferred()

            const promise = jqDf.promise()

            let openedItemPromise

            let selectedItems = []

            // Execution path from this method:
            // This method > modal.openItem() > template57 (via modal.controller.js) > dataOps.createInfluence()

            options = _.assign({
                useInternalPackages: false,
                organization: undefined,
                networkId: undefined,
                contextParent: undefined,
                contextParentWfid: undefined,
                compilerControl: undefined,
            }, options)

            const modalOptions = {
                size: '',
                meta: {
                    title: $translate.instant('modules.valueChain.influence.modalHeader', { orgname: options.organization.name + (options.contextParent ? ' - ' + options.contextParent.getMainTextual() : '') }),
                    settings: { templateId: 57 },
                },
                resolve() {
                    return dataOps.getObject({
                        objectType: enums.objectType.structure,
                        objectId: options.useInternalPackages ? ids.internalPackagesStructure : ids.packagesStructure,
                        childrenLoadDepth: 1,
                    })
                },
                contextParentWfids: options.contextParent ? options.contextParent.wfid : options.contextParentWfid,
                networkId: options.networkId,
                isInternal: options.useInternalPackages,
                influenceSourceObject: undefined,
                packagesStructureAlreadyReduced: true, // To make template 57 support legazy Data Collector that doesn't use this function
                packagesStructure() {
                    return $q((resolve, reject) => {
                        loadPackagesInNetwork(options.networkId, options.useInternalPackages).then((dataRelations) => {
                            const
                                influencesToOrg = wfObject.filter({ where: { type: enums.objectType.influence, organizationId: options.organization.id, channelId: options.networkId } })

                            let influencesToOrgWithoutContextParent

                            let influencesToOrgWithContextParent

                            let contextParentType

                            if (options.useInternalPackages) {
                                const internalInfluencesToOrgWithContextParent = _.filter(influencesToOrg, { contextParentWfids: options.contextParentWfid })

                                // Reduce the dataRelations array to only include structures that haven't been sent to the organization/contextParent yet.
                                dataRelations = _.chain(dataRelations).differenceWith(internalInfluencesToOrgWithContextParent, (dataRelation, influence) => {
                                    return dataRelation.wfcid === influence.wfcid
                                }).value()
                            }
                            else if (options.contextParent) { // Single context parent
                                contextParentType = options.contextParent.type
                                influencesToOrgWithContextParent = _.filter(influencesToOrg, { contextParentWfids: options.contextParent.wfid })

                                // Reduce the dataRelations array to only include structures that haven't been sent to the organization/contextParent yet.
                                dataRelations = _.chain(dataRelations).differenceWith(influencesToOrgWithContextParent, (dataRelation, influence) => {
                                    return dataRelation.wfcid === influence.wfcid
                                }).filter((dataRelation) => {
                                    let
                                        requirementPackageSettings

                                    const childContent = dataRelation.childContent

                                    // Each requirement package (structure) that is ment for influencing with contextParent
                                    // must have a condition that defines what contextParent type it is ment for using
                                    // requirementPackageSettings.targetContextParentType condition.
                                    requirementPackageSettings = childContent.conditions ? childContent.conditions.requirementPackageSettings : undefined
                                    return requirementPackageSettings && requirementPackageSettings.targetContextParentType === contextParentType
                                }).value()
                            }
                            else {
                                influencesToOrgWithoutContextParent = _.filter(influencesToOrg, (influence) => { return !influence.contextParentWfids })

                                // Reduce the dataRelations array to only include structures that haven't been sent to the organization yet.
                                dataRelations = _.chain(dataRelations).differenceWith(influencesToOrgWithoutContextParent, (dataRelation, influence) => {
                                    return dataRelation.wfcid === influence.wfcid
                                }).filter((dataRelation) => {
                                    let
                                        requirementPackageSettings

                                    const childContent = dataRelation.childContent

                                    // Each requirement package (structure) that is ment for influencing with contextParent
                                    // must have a condition that defines what contextParent type. Those structures
                                    // should not show up when options.contextParent is undefined.
                                    requirementPackageSettings = childContent.conditions ? childContent.conditions.requirementPackageSettings : undefined
                                    return !requirementPackageSettings || !requirementPackageSettings.targetContextParentType
                                }).value()
                            }

                            resolve({
                                wfid: '71-temp_packagesStructure',
                                hasPredefinedItems: true,
                                title: $translate.instant('modules.valueChain.influence.selectRequirementButtonCaption'),
                                childs: dataRelations,
                            })
                        })
                    })
                },
                organization: options.organization,
                compilerControl: options.compilerControl,
                initializeReqPackageForm,
                createInfluences,

                formSpecLoaded: false,
                formSpec: undefined,
                formControl: {},
            }

            openedItemPromise = modal.openItem(modalOptions)
            promise.modal = openedItemPromise.modal

            return promise

            function initializeReqPackageForm(context) {
                let itemsToExclude = undefined
                let listOfRequirementPackages = []

                if (!context.packagesStructureAlreadyReduced) itemsToExclude = ('71-' + dataQuery.mapProperty('objectId', { type: 13, organizationId: context.organization.id }).join(',71-')).split(',')

                context.packagesStructure().then((requirementPackages) => {
                    listOfRequirementPackages = requirementPackages.childs

                    if (itemsToExclude && !_.isEmpty(itemsToExclude)) {
                        listOfRequirementPackages = _.filter(listOfRequirementPackages, (reqPackage) => {
                            return !_.includes(itemsToExclude, reqPackage.wfcid)
                        })
                    }

                    context.formSpec = {
                        schema: {
                            type: 'object',
                            properties: {
                                sourceObject: { title: $translate.instant('RequirementPackages'), type: 'integer' },
                                userId: {
                                    title: $translate.instant('modules.valueChain.influence.targetUserLabel'),
                                    type: ['null', 'integer'],
                                    'x-schema-form': formSchemaService.getNetworkMemberOrganizationUsersSchema({
                                        organizationId: options.organization.id,
                                        networkId: options.networkId,
                                    }),
                                },
                            },
                        },
                        form: [
                            {
                                key: 'sourceObject',
                                type: 'picker_multiple',
                                typeOptions: {
                                    selectedItem: { id: null, type: null },
                                    addButtonCaption: $translate.instant('modules.valueChain.influence.selectRequirementButtonCaption'),
                                    singlePick: false,
                                    validateAction(event, model, relationBucket) { //Not called anywhere!!!
                                        return !!relationBucket.allSelected.length
                                    },
                                    onPickerClosed(relationBucketResults) {
                                        selectedItems.length = []
                                        selectedItems = relationBucketResults.allSelected
                                    },
                                    picker: {
                                        hideFilters: true,
                                        sourceList: _.map(listOfRequirementPackages, (item) => {
                                            return { data: item.childContent, wfid: item.wfid }
                                        }),
                                        create: false,
                                        pick: true,
                                        title: $translate.instant('modules.valueChain.influence.selectRequirementButtonCaption'),
                                        emptyState: { header: $translate.instant('modules.valueChain.influence.requirementSelection.emptyStateHeader') },
                                        closeCaption: $translate.instant('Done'),
                                    },
                                },
                            },
                            'userId',
                        ],
                    }

                    context.formSpecLoaded = true
                })
            }

            function createInfluences(params) {
                const deferred = $q.defer()
                const promises = []
                const formControl = params.context.formControl

                if (formControl.isValid() && selectedItems && !_.isEmpty(selectedItems)) {
                    _.each(selectedItems, (item) => {
                        promises.push(dataOps.createInfluence({
                            organization: params.context.organization,
                            item,
                            contextParentWfids: params.context.contextParentWfids,
                            channelId: params.context.networkId,
                            context: params.context,
                            influenceModel: params.sfModel,
                            isInternal: params.context.isInternal,
                            userId: formControl.getModel().userId,
                        }))
                    })

                    $q.all(promises).then((res) => {
                        jqDf.resolve(res)
                        deferred.resolve()
                    }, () => {
                        deferred.reject()
                        modal.alert({
                            title: $translate.instant('errorMessages.serverError.title'),
                            message: $translate.instant('errorMessages.serverError.message'),
                            onEscape: false,
                            type: 'info',
                            buttons: {
                                reload: {
                                    label: $translate.instant('errorMessages.reloadButtonCaption'),
                                    className: 'btn-hollow action',
                                    callback() {
                                        location.reload()
                                    },
                                },
                            },
                        })
                    })
                }
                else {
                    deferred.reject()
                }

                return deferred.promise
            }
        }

        function openInfluence(influence, compilerControl, options) {
            const organization = wfObject.get(enums.objectType.organization + '-' + influence.organizationId)

            return modal.openItem({
                meta: {
                    // TemplateId 23 uses ng-bind which automatically santizes the title, so "null, null, null" is passed into $translate.instant
                    // to prevent the translate service from sanitizing the text (the last null is the sanitizeStrategy).
                    // Otherwise org names like "Byggcenter Bygg &amp; Service AB" will appear in the UI because the text gets sanitized twice.
                    // If ng-bind-html is used in a template then, as usual, we most always take great care in santizizing user input.
                    title: $translate.instant('modules.valueChain.influence.requirementPreview.modalHeader', { orgname: organization.name }, null, null, null),
                    settings: {
                        templateId: 23,
                    },
                },
                influence,
                influenceSourceObject: wfObject.get(influence.objectType + '-' + influence.objectId),
                organization,
                compilerControl,
                showInfluenceHeader: _.get(options, 'showInfluenceHeader'),
                uiMode: _.get(options, 'uiMode'),
                uiComponents: _.get(options, 'uiComponents'),
                onUpdated: _.get(options, 'onUpdated'),
                onClosed: _.get(options, 'onClosed'),
                disableNewInfluenceUI: true,
            })
        }

        function openConsolidatedPackageView(options) {
            let
                allReportedDataForExport

            let rawSubItemsAndParents

            let itemComposites_noUserData

            let scope

            const structure = options.structure

            const networkId = options.networkId

            const organizationIds = options.organizationIds

            const selectedFilterOptions = options.selectedFilterOptions

            const influencesByOrgId = options.influencesByOrgId

            const organizationsById = options.organizationsById

            const categories = options.categories

            const categoryGroups = options.categoryGroups

            const statusesEtc = options.statusesEtc

            const contextParentWfids = options.contextParentWfids

            const reqPackage = structure

            const isInternalPackage = options.isInternalPackage

            const exportAllOptions = {
                structure,
                networkId,
                allReportedDataForExport,
                rawSubItemsAndParents,
                itemComposites_noUserData,
                contextParentWfids,
                influencesByOrgId,
                organizationsById,
                organizationIds,
                categories,
                categoryGroups,
                statusesEtc,
                reqPackage,
                isInternalPackage,
            }

            modal.open({
                template: '<wf-hierarchical item="structure" consolidate="consolidationSettings" override="overrideObject" vm-prototype="hierVm" hide-filter-bars></wf-hierarchical>',
                scope: scope = {
                    structure,
                    hierVm: {
                        showStatsOnTopOfHierarchical: _.get(structure, 'conditions.objectTypes[0]') === enums.objectType.finding,
                        mainPanelFullWidth: true,
                        showFilterBars: false,
                    },
                    loaded: false,
                    consolidationSettings: {
                        networkId,
                        organizationIds,
                        contextParentWfids,
                        onlyStatistics: true,
                    },
                    overrideObject: {
                        headerOptions: {
                            title: $translate.instant('modules.valueChain.aggregatedView.title'),
                            description: structure.title + '\n\n'
								+ $translate.instant('modules.valueChain.organizationCount') + ': ' + organizationIds.length
								+ (selectedFilterOptions && selectedFilterOptions.length ? ('\n' + $translate.instant('modules.valueChain.basedOnFiltering') + ':\n• ' + selectedFilterOptions.join('\n• ')) : ''),
                        },
                    },
                },
                windowClass: 'modal-width-1000',
                onLoaded($scope, $element) {
                },
            })
        }

        function exportAll(options) {
            const cancelablePromises = []
            const scope = $rootScope.$new()
            const countryById = options.countryById || {}
            let negotiator

            if (!options.allReportedDataForExport) {
                negotiator = new DataNegotiator({
                    inject: false,
                    fromItem: { type: options.structure.type, id: options.structure.id },
                    loadDepth: 10,
                    loadParents: false,
                    loadMetadata: false,
                    loadVisibilityTags: false,
                    includeStatistics: false,
                })
            }

            _.assign(scope, {
                exportAll,
                loading: !options.allReportedDataForExport,
                progressWidth: 0,
                close() {
                    scope.closed = true
                    openedModal.modal.close()
                },
                preparedSubItems: options.allReportedDataForExport,
                onlyExportLatestData: { value: false },
                showHorizontalOption: (!options.contextParentWfids || !options.contextParentWfids.length) && !options.hideShowHorizontalOption,
                useHorizontal: { value: false },
                includeCategorizations: { value: false },
                groupCategorizations: { value: false },
                convertMeasurements: { value: false },
                packageTitle: _.get(options.reqPackage, 'title'),
                onOnlyExportLatestDataChange() {
                    handleSubItems()
                    $timeout()
                },
                hideExportUI: false,
                onUseHorizontalChange() {
                    scope.hideExportUI = true
                    $timeout()
                    handleSubItems(() => $timeout(() => scope.hideExportUI = false))
                },
                onConvertMeasurementsChange() {
                    handleSubItems()
                    $timeout()
                },
                onIncludeCategorizationsChange() {
                    scope.hideExportUI = true
                    $timeout()
                    handleSubItems(() => $timeout(() => scope.hideExportUI = false))
                },
                onGroupCategorizationsChange() {
                    scope.hideExportUI = true
                    $timeout()
                    handleSubItems(() => $timeout(() => scope.hideExportUI = false))
                },
                mappingOptionsForCsvExport: null,
            })

            scope.mappingOptionsForCsvExport = getMappingOptionsForCsvExport()

            if (!scope.mappingOptionsForCsvExport.reportingOrganization) {
                delete scope.mappingOptionsForCsvExport.reportingOrganization
            }

            if (options.hideCustomIdOption) {
                delete scope.mappingOptionsForCsvExport.customId
            }

            var openedModal = modal.open({
                templateUrl: 'scripts/wf/valueChain/popovers/consolidatedExport.template.html',
                scope,
                windowClass: 'modal-width-700',
                onLoaded($scope, $element) {

                },
            })

            let relMeasureStepColumnsCount = 0

            let callCounter = 0

            if (!options.allReportedDataForExport) {
                negotiator.onRequest.then(() => {
                    const allSubItems = []

                    // Hack to get negotiator.items, the same request is ran twice
                    if (callCounter === 0) {
                        callCounter++
                        negotiator.loadItemsFromServer()
                        return
                    }

                    options.rawSubItemsAndParents = []

                    const items = _.filter(negotiator.items, (item) => {
                        if (item.type === enums.objectType.question || item.type === enums.objectType.measure || item.type === enums.objectType.relativeMeasure) return true
                        else if (_.get(item.content, 'conditions.pickerSettings') || _.get(item.content, 'conditions.objectTypes') || _.get(item.dataRelation, 'settings.attachObjectTypes') || _.get(item.dataRelation, 'originalRelation.settings.attachObjectTypes')) return true
                    })

                    const
                        totalLoadCount = items.length

                    let loadedCount = 0

                    options.itemComposites_noUserData = _.clone(items)

                    let allRelativeMeasureSourceObjectWfids = []

                    if (items.length) {
                        const relativeMeasureIds = items.filter(x => x.type === 118).map(x => x.id)
                        const relativeMeasuresCount = relativeMeasureIds.length
                        const network = relativeMeasuresCount && wfObject.get(`52-${options.networkId}`)
                        const useRelativeMeasureResultsCache = network && _.get(network, 'settings.valueChainSettings.useRelativeMeasureResultsCache')
                        const startPreparingData = () => {
                            scope.showRelativeMeasureResultsCacheInfo = false
                            scope.showProgressBar = true
                            $timeout()
                            loadAggregatedDataOnNextItem(items.pop())
                        }

                        if (useRelativeMeasureResultsCache) {
                            scope.showRelativeMeasureResultsCacheInfo = true
                            scope.getting = true

                            dataOps.getRelativeMeasureResultsCacheInfo({ relativeMeasureIds, networkId: options.networkId, organizationIds: options.organizationIds })
                                .then((res) => {
                                    const { oldestResultCalculatedAt, missingResultsCount, resultsWithMissingOrganizationsCount, averageCalculationDuration } = res
                                    scope.getting = false

                                    if (!oldestResultCalculatedAt) {
                                        startPreparingData()
                                    }
                                    else {
                                        scope.showTextAndActionButtons = true
                                        const oldestResultTimeAgo = moment(oldestResultCalculatedAt).fromNow()
                                        scope.oldestRelativeMeasureResultTimeAgo = oldestResultTimeAgo
                                        scope.relativeMeasureResultsWithMissingOrganizationsCount = resultsWithMissingOrganizationsCount
                                        scope.averageRelativeMeasureCalculationDuration = 16

                                        scope.useSavedRelativeMeasureResults = () => {
                                            startPreparingData()
                                        }
                                        scope.clearRelativeMeasureResults = () => {
                                            scope.showTextAndActionButtons = false
                                            scope.clearing = true
                                            $timeout()

                                            dataOps.clearRelativeMeasureResultsCache({ relativeMeasureIds, networkId: options.networkId })
                                                .then(() => {
                                                    startPreparingData()
                                                })
                                                .catch(() => {
                                                    scope.clearing = false
                                                    scope.errorDuringClearing = true
                                                })
                                        }
                                    }
                                })
                                .catch(() => {
                                    scope.getting = false
                                    scope.errorDuringGetting = true
                                })
                        }
                        else {
                            scope.showProgressBar = true
                            loadAggregatedDataOnNextItem(items.pop())
                        }
                    }
                    else {
                        handleSubItems()
                    }

                    function loadAggregatedDataOnNextItem(item) {
                        if (!item || scope.closed) return

                        const negotiator = new DataNegotiator({
                            fromItem: { type: item.type, id: item.id },
                            ticket: { organizationIds: options.organizationIds, networkId: options.networkId, contextParentWfids: options.contextParentWfids },
                            loadDepth: 0,
                            loadParents: false,
                            loadMetadata: true,
                            loadVisibilityTags: false,
                            includeStatistics: false,
                            convertMeasureAnswerUnits: true,
                        })
                        negotiator.onRequest.then(() => {
                            if (scope.closed) return

                            const subItems = _.filter(negotiator.items, (subItem) => {
                                return subItem.wfid !== item.wfid
                            })

                            if (item.type === enums.objectType.relativeMeasure) {
                                item.content.sourceObjectWfids = negotiator.item.sourceObjectWfids

                                const stepCalculationResults = _.get(subItems, '[0].content.stepCalculationResults')
                                if (stepCalculationResults) {
                                    stepCalculationResults.forEach((x) => {
                                        if (x.stepObjectWfid) allRelativeMeasureSourceObjectWfids.push(x.stepObjectWfid)
                                    })
                                }
                            }

                            Array.prototype.push.apply(allSubItems, subItems)
                            options.rawSubItemsAndParents.push({ parentWfid: item.wfid, parent: item, subItems })

                            loadedCount++

                            scope.progressWidth = (loadedCount / totalLoadCount) * 100
                            $timeout()

                            if (items.length) {
                                loadAggregatedDataOnNextItem(items.pop())
                            }
                            else {
                                options.rawSubItemsAndParents = _.reverse(options.rawSubItemsAndParents)

                                allRelativeMeasureSourceObjectWfids = _.uniq(allRelativeMeasureSourceObjectWfids)
                                if (allRelativeMeasureSourceObjectWfids && allRelativeMeasureSourceObjectWfids.length) {
                                    const promise = apiProxy.raw('multi.getObjects', {
                                        wfids: allRelativeMeasureSourceObjectWfids,
                                        loadParents: false,
                                        loadMetadata: false,
                                        loadVisibilityTags: false,
                                        getterConditions: {
                                            loadCreators: false,
                                            includeOrganizations: false,
                                        },
                                    })

                                    promise.then((res) => {
                                        options.relativeMeasureSourceObjectsByWfid = _.keyBy(res, 'wfid')
                                        handleSubItems()
                                    })

                                    cancelablePromises.push(promise)
                                } else {
                                    handleSubItems()
                                }
                            }
                        })

                        negotiator.onRequest.fail(() => {
                            if (scope.closed) return

                            const organizationId = wfAuth.getOrganizationId()

                            modal.alert({
                                title: $translate.instant('modules.valueChain.aggregated.exportEntirePackage.itemErrorModal.title'),
                                message: $translate.instant('modules.valueChain.aggregated.exportEntirePackage.itemErrorModal.message', {
                                    errorDetails: `WFID: ${item.type}-${item.id}\nStructureId: ${options.reqPackage.id}\nOrganizationId: ${organizationId}\nNetworkId: ${options.networkId}\nProgress: ${scope.progressWidth}%`,
                                }),
                                type: 'warning',
                                modalCssClass: 'exportAll_error',
                                buttons: {
                                    primary: {
                                        label: 'OK',
                                        callback() {
                                            openedModal.modal.close()
                                        },
                                    },
                                },
                            })
                        })
                    }
                })
            }
            else {
                handleSubItems()
            }

            let relatedContentByItemWfid

            function handleSubItems(onDone) {
                if (scope.useHorizontal.value) {
                    handleSubItemsHorizontally(() => onDone && onDone())
                }
                else handleSubItemsVertically(() => onDone && onDone())
            }

            function handleSubItemsVertically(onDone) {
                let output = []; const i = 0
                const promises = []
                const relativeMeasureSourceObjectsByWfid = options.relativeMeasureSourceObjectsByWfid

                cancelablePromises.forEach((promise) => {
                    if (promise) {
                        promise.abort()
                    }
                })
                cancelablePromises.length = 0

                const stepWords = {
                    [enums.mathOperation.addition]: $translate.instant('mathOperations.add'),
                    [enums.mathOperation.subtraction]: $translate.instant('mathOperations.subtract'),
                    [enums.mathOperation.multiplication]: $translate.instant('mathOperations.multiply'),
                    [enums.mathOperation.division]: $translate.instant('mathOperations.divide'),
                    customValue: `(${$translate.instant('modules.relativeMeasure.steps.columns.customValue.name')})`,
                }

                relMeasureStepColumnsCount = 0

                const mapRelativeMeasureColumnValues = (stepCalculationResultItem, rowData, index) => {
                    const stepNumber = index + 1
                    const sourceObject = stepCalculationResultItem.stepObjectWfid ? relativeMeasureSourceObjectsByWfid[stepCalculationResultItem.stepObjectWfid] : undefined

                    rowData[`relMeasure_step${stepNumber}ObjectName`] = sourceObject ? sourceObject.text : (stepCalculationResultItem.stepObjectWfid ? undefined : stepWords.customValue)
                    rowData[`relMeasure_step${stepNumber}SourceValue`] = stepCalculationResultItem.sourceValue
                    rowData[`relMeasure_step${stepNumber}Unit`] = sourceObject ? wfPropertyExtractor.measureUnitExtractor.getUnitSymbolFromMeasure(sourceObject) : undefined
                    if (index > 0) {
                        rowData[`relMeasure_step${stepNumber}Operation`] = stepWords[stepCalculationResultItem.operation]
                    }

                    if (stepNumber > relMeasureStepColumnsCount) {
                        relMeasureStepColumnsCount = stepNumber
                    }
                }

                _.each(options.rawSubItemsAndParents, (group, index) => {
                    const
                        parent = group.parent

                    const isParentRelativeMeasure = group.parent.type === enums.objectType.relativeMeasure

                    const customIdParamsByOrgId = _.chain(wfObject.filter({
                        where: {
                            type: enums.objectType.parameterValue,
                            organizationId: wfAuth.getOrganizationId(),
                            parameterId: 33, // customId
                            objectType: enums.objectType.organization,
                        },
                    })).keyBy('objectId').mapValues((paramValue) => {
                        return paramValue.value
                    }).value()

                    let subItems

                    subItems = group.subItems

                    if (scope.onlyExportLatestData.value) {
                        if (group.parent.type === enums.objectType.measure || group.parent.type === enums.objectType.relativeMeasure) {
                            subItems = _.chain(subItems) // subItems are item composites since they come from the negotiator
                                .groupBy(options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                                .mapValues((itemComposites, orgId) => {
                                    return _.intersectionWith(itemComposites, wfMeasureService.groupAndFormatAnswersByPeriod(_.map(itemComposites, 'dataRelation')), (itemComposite, content) => {
                                        if (itemComposite.content.wfid === content.wfid) {
                                            itemComposite.periodName = content.periodName
                                            return true
                                        }
                                    })
                                })
                                .map()
                                .flatten()
                                .value()
                        }
                        else if (group.parent.type === enums.objectType.question) {
                            subItems = _.chain(subItems) // subItems are item composites since they come from the negotiator
                                .groupBy(options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                                .mapValues((itemComposites, orgId) => {
                                    return _.sortBy(itemComposites, 'dataRelation.createdAt')[itemComposites.length - 1]
                                })
                                .map()
                                .flatten()
                                .value()
                        }
                    }

                    const subItemsWithAttachedData = subItems.filter(subItem => _.get(subItem, 'content.metadata.countByRelationKind[5]') > 0)
                    const promisesInIteration = []
                    let attachedDataByOrgAndItem = {}

                    if (subItemsWithAttachedData.length) {
                        const promise = apiProxy.raw('multi.getSubItemsOfAll', {
                            wfids: _.uniq(subItemsWithAttachedData.map(x => x.wfid)),
                            kind: enums.subItemsKind.relatedContentByUser,
                            ticket: { organizationIds: options.organizationIds, networkId: options.networkId, contextParentWfids: options.contextParentWfids },
                        })

                        promise.then((res) => {
                            res.forEach((relation) => {
                                if (!relation.childContent) {
                                    const relationWithChildContent = res.find(x => x.wfcid === relation.wfcid && x.childContent)
                                    if (relationWithChildContent) {
                                        relation.childContent = relationWithChildContent.childContent
                                    }
                                }
                            })
                            attachedDataByOrgAndItem = res.reduce((acc, item) => {
                                const orgWfid = item.contextParentType === enums.objectType.organization ? item.wfxpid : item.creatorOrganizationWfid
                                return ({
                                    ...acc,
                                    [orgWfid]: {
                                        ...acc[orgWfid],
                                        [item.wffid]: [
                                            ...(acc[orgWfid] ? acc[orgWfid][item.wffid] || [] : []),
                                            wfPropertyExtractor.getItemSummary(item),
                                        ],
                                    },
                                });
                            }, {})
                        })

                        promisesInIteration.push(promise)
                        promises.push(promise)
                        cancelablePromises.push(promise)
                    }

                    $q.all(promisesInIteration).then(() => {
                        _.each(subItems, (subItem) => {
                            let
                                orgId = _.get(subItem.creatorOrganization, 'id')

                            let customId = orgId ? customIdParamsByOrgId[orgId.toString()] : undefined

                            let reportedValue

                            let measurementPeriod

                            let measurementUnit

                            if (subItem.content.type === enums.objectType.measureAnswer) {
                                reportedValue = wfPropertyExtractor.getFormattedMeasureAnswerValue(subItem.content, false, undefined, !scope.convertMeasurements.value, true)
                                if (subItem.content.isNumber) {
                                    reportedValue = parseFloat(reportedValue)
                                }
                                measurementUnit = wfPropertyExtractor.measureUnitExtractor.getUnitSymbolFromMeasure(subItem.content, !scope.convertMeasurements.value) || undefined

                                if (scope.onlyExportLatestData.value) {
                                    measurementPeriod = wfPropertyExtractor.getMeasureAnswerPeriod(subItem.content, { useNumericMonthlyFormat: true })
                                }
                                else {
                                    measurementPeriod = wfPropertyExtractor.getMeasureAnswerPeriod(subItem.content, { useNumericMonthlyFormat: true })
                                }
                            }
                            else if (subItem.content.type === enums.objectType.questionAnswer) {
                                reportedValue = wfPropertyExtractor.getMainTextual(subItem.content, { alwaysIncludeBody: true, html: false, dontSanitize: true })
                            }
                            else {
                                reportedValue = wfPropertyExtractor.getItemSummary(subItem.content)
                            }

                            let reportingOrganization
                            let creatorOrganization
                            if (options.isInternalPackage) {
                                creatorOrganization = subItem.dataRelation.contextParentType === enums.objectType.organization ? subItem.dataRelation.contextParentContent : subItem.creatorOrganization
                                if (subItem.dataRelation.contextParentType === enums.objectType.organization) {
                                    reportingOrganization = subItem.creatorOrganization
                                    creatorOrganization = subItem.dataRelation.contextParentContent

                                    orgId = _.get(creatorOrganization, 'id')
                                    customId = orgId ? customIdParamsByOrgId[orgId.toString()] : undefined
                                }
                                else {
                                    creatorOrganization = subItem.creatorOrganization
                                }
                            }
                            else {
                                creatorOrganization = subItem.creatorOrganization
                            }

                            const row = {
                                parentIndex: index,
                                customId: customId || '',
                                orgNumber: _.get(creatorOrganization, 'registrationNumber'),
                                vatNumber: _.get(creatorOrganization, 'vatNumber'),
                                country: countryById[_.get(creatorOrganization, 'countryId')] || '',
                                gln: _.get(creatorOrganization, 'gln'),
                                reportingOrganization: _.get(reportingOrganization, 'name'),
                                creatorOrganization: _.get(creatorOrganization, 'name'),
                                creatorOrganizationWfid: _.get(creatorOrganization, 'wfid'),
                                creatorUser: _.get(subItem.creatorUser, 'name'),
                                creatorUserEmail: _.get(subItem.creatorUser, 'email'),
                                itemWfid: parent.content.wfid,
                                item: wfPropertyExtractor.getMainTextual(parent.content, { alwaysIncludeBody: true, html: false, dontSanitize: true }),
                                reported: reportedValue,
                                measurementPeriod,
                                measurementUnit,
                                dateAdded: moment(subItem.createdAt),
                            }

                            if (scope.includeCategorizations.value) {
                                if (scope.groupCategorizations.value) {
                                    _.each(options.categoryGroups, (item) => {
                                        const categories = item.categories.filter(x => x.orgIds.includes(orgId))
                                        row[item.id] = categories.map(x => x.title).join(', ')
                                    })
                                }
                                else {
                                    _.each(options.categories, (item) => {
                                        row[item.id] = ~item.orgIds.indexOf(orgId) // If value is included in array
                                            ? '1' : '0'
                                    })
                                }
                            }

                            const attachedData = attachedDataByOrgAndItem[`101-${orgId}`] ? attachedDataByOrgAndItem[`101-${orgId}`][subItem.content.wfid] : undefined
                            if (attachedData) {
                                row.attached = attachedData.join('\n\n\n')
                            }

                            if (isParentRelativeMeasure) {
                                subItem.content.stepCalculationResults.forEach((x, index) => mapRelativeMeasureColumnValues(x, row, index))
                            }

                            output.push(row)
                        })
                    })
                })

                if (scope.preparedSubItems) scope.preparedSubItems.length = 0
                else scope.preparedSubItems = []

                $q.all(promises).then(() => {

                    output = _.orderBy(output, ['parentIndex', 'creatorOrganization', 'measurementPeriod'])
                    scope.preparedSubItems = output
                    options.allReportedDataForExport = scope.preparedSubItems
                    scope.mappingOptionsForCsvExport = getMappingOptionsForCsvExport()

                    $timeout(() => {
                        onDone && onDone()
                        scope.loading = false
                    }, 1000)
                })
            }

            function handleSubItemsHorizontally(onDone) {
                let output = []; const i = 0
                const customIdParamsByOrgId = _.chain(wfObject.filter({
                    where: {
                        type: enums.objectType.parameterValue,
                        organizationId: wfAuth.getOrganizationId(),
                        parameterId: 33, // customId
                        objectType: enums.objectType.organization,
                    },
                })).keyBy('objectId').mapValues((paramValue) => {
                    return paramValue.value
                }).value()
                const yearlySpendParamsByOrgId = _.chain(wfObject.filter({
                    where: {
                        type: enums.objectType.parameterValue,
                        organizationId: wfAuth.getOrganizationId(),
                        parameterId: 35, // yearlySpend
                        objectType: enums.objectType.organization,
                    },
                })).keyBy('objectId').mapValues((paramValue) => {
                    return paramValue.value
                }).value()

                _.each(options.organizationIds, (orgId) => {
                    const row = {}
                    const influences = options.influencesByOrgId[orgId]
                    const influence = influences ? influences[0] : null
                    const org = options.organizationsById[orgId]

                    if (!influence) return

                    row.organizationWfid = _.get(org, 'wfid')
                    row.organization = _.get(org, 'name')
                    row.orgNumber = _.get(org, 'registrationNumber')
                    row.vatNumber = _.get(org, 'vatNumber')
                    row.country = countryById[_.get(org, 'countryId')] || '',
                    row.gln = _.get(org, 'gln')
                    row.customId = customIdParamsByOrgId[orgId]
                    row.yearlySpend = yearlySpendParamsByOrgId[orgId]

                    row['reqPackage_fulfilled'] = influence.fulfilled && !influence.isAssessmentNeeded ? '1' : '0'
                    row['reqPackage_requireAction'] = influence.isOverdue ? '1' : '0'
                    row['reqPackage_exception'] = influence.containsSpecialRequirements ? '1' : '0'
                    row['reqPackage_assessmentNeeded'] = influence.isAssessmentNeeded ? '1' : '0'

                    _.each(options.statusesEtc, (item) => {
                        row[item.id] = ~item.orgIds.indexOf(orgId) // If value is included in array
                            ? '1' : '0'
                    })

                    if (scope.includeCategorizations.value) {
                        if (scope.groupCategorizations.value) {
                            _.each(options.categoryGroups, (item) => {
                                const categories = item.categories.filter(x => x.orgIds.includes(orgId))
                                row[item.id] = categories.map(x => x.title).join(', ')
                            })
                        }
                        else {
                            _.each(options.categories, (item) => {
                                row[item.id] = ~item.orgIds.indexOf(orgId) // If value is included in array
                                    ? '1' : '0'
                            })
                        }
                    }

                    _.each(options.rawSubItemsAndParents, (group) => {
                        const
                            subItems = _.filter(group.subItems, (subItem) => {
                                return subItem.creatorOrganization && subItem.creatorOrganization.id === orgId
                            })

                        if (!subItems.length) {
                            row[group.parentWfid] = ''
                        }
                        else {
                            row[group.parentWfid] = getReportedValueOnItem(subItems, group, orgId)
                        }
                    })

                    output.push(row)
                })

                if (scope.preparedSubItems) scope.preparedSubItems.length = 0
                else scope.preparedSubItems = []

                output = _.orderBy(output, ['organization'])
                scope.preparedSubItems = output
                options.allReportedDataForExport = scope.preparedSubItems
                scope.mappingOptionsForCsvExport = getMappingOptionsForCsvExport()
                $timeout(() => {
                    onDone && onDone()
                    scope.loading = false
                }, 1000)

                function getReportedValueOnItem(subItems, group) {
                    if (group.parent.type === enums.objectType.measure) {
                        subItems = [_.chain(subItems) // subItems are item composites since they come from the negotiator
                            .groupBy(options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                            .mapValues((itemComposites, orgId) => {
                                return _.intersectionWith(itemComposites, wfMeasureService.groupAndFormatAnswersByPeriod(_.map(itemComposites, 'dataRelation')), (itemComposite, content) => {
                                    if (itemComposite.content.wfid === content.wfid) {
                                        itemComposite.periodName = content.periodName
                                        return true
                                    }
                                })
                            })
                            .map()
                            .flatten()
                            .sortBy('content.period_epoch')
                            .last()
                            .value()]
                    }
                    else if (group.parent.type === enums.objectType.question) {
                        subItems = _.chain(subItems) // subItems are item composites since they come from the negotiator
                            .groupBy(options.isInternalPackage ? 'dataRelation.contextParentId' : 'dataRelation.organizationId')
                            .mapValues((itemComposites, orgId) => {
                                return _.sortBy(itemComposites, 'dataRelation.createdAt')[itemComposites.length - 1]
                            })
                            .map()
                            .flatten()
                            .value()
                    }

                    const combinedReportedValue = _.map(subItems, (subItem) => {
                        let
                            reportedValue

                        let measurementPeriod

                        if (subItem.content.type === enums.objectType.measureAnswer) {
                            reportedValue = wfPropertyExtractor.getFormattedMeasureAnswerValue(subItem.content, true)
                            measurementPeriod = subItem.periodName
                        }
                        else if (subItem.content.type === enums.objectType.questionAnswer) {
                            reportedValue = wfPropertyExtractor.getMainTextual(subItem.content, { alwaysIncludeBody: true, html: false, dontSanitize: true })
                        }
                        else {
                            reportedValue = wfPropertyExtractor.getItemSummary(subItem.content)
                        }

                        if (measurementPeriod) {
                            reportedValue = reportedValue + ' (' + measurementPeriod + ')'
                        }

                        return reportedValue
                    }).join('\n\n')

                    return combinedReportedValue
                }
            }

            function getMappingOptionsForCsvExport() {
                let
                    output = {}

                const itemWfidsThatHaveAttachedInfo = []

                const orgIdsThatAttachedInfo = []

                const orgIdByItemWfid = {}

                if (scope.useHorizontal.value) {
                    output['organizationWfid'] = {
                        include: true,
                        header: $translate.instant('Organization') + 'ID',
                        source: 'organizationWfid',
                    }
                    output['organization'] = {
                        include: true,
                        header: $translate.instant('Organization'),
                        source: 'organization',
                    }
                    output['orgNumber'] = {
                        include: true,
                        header: $translate.instant('RegistrationNumber'),
                        source: 'orgNumber',
                    }
                    output['vatNumber'] = {
                        include: true,
                        header: $translate.instant('VATNumber'),
                        source: 'vatNumber',
                    }
                    output['gln'] = {
                        include: true,
                        header: 'GLN',
                        source: 'gln',
                    }
                    output['customId'] = {
                        include: true,
                        header: $translate.instant('CustomID'),
                        source: 'customId',
                    }
                    output['yearlySpend'] = {
                        include: true,
                        header: $translate.instant('YearlySpend'),
                        source: 'yearlySpend',
                    }
                    output['country'] = {
                        include: true,
                        header: $translate.instant('Country'),
                        source: 'country',
                    }
                    output['reqPackage_fulfilled'] = { include: true, header: options.reqPackage.title + ' - Fulfilled', source: 'reqPackage' + '_fulfilled' }
                    output['reqPackage_requireAction'] = { include: true, header: options.reqPackage.title + ' - Requires action', source: 'reqPackage' + '_requireAction' }
                    output['reqPackage_exception'] = { include: true, header: options.reqPackage.title + ' - Exception', source: 'reqPackage' + '_exception' }
                    output['reqPackage_assessmentNeeded'] = { include: true, header: options.reqPackage.title + ' - Assessment needed', source: 'reqPackage' + '_assessmentNeeded' }

                    _.each(options.statusesEtc, (item) => {
                        output[item.id] = {
                            include: true,
                            header: item.title,
                            source: item.id,
                        }
                    })

                    if (scope.includeCategorizations.value) {
                        if (scope.groupCategorizations.value) {
                            _.each(options.categoryGroups, (item) => {
                                output[item.id] = {
                                    include: true,
                                    header: item.title,
                                    source: item.id,
                                }
                            })
                        }
                        else {
                            _.each(options.categories, (item) => {
                                output[item.id] = {
                                    include: true,
                                    header: item.title,
                                    source: item.id,
                                }
                            })
                        }
                    }

                    _.each(options.itemComposites_noUserData, (item) => {
                        output[item.wfid] = {
                            include: true,
                            header: wfPropertyExtractor.getMainTextual(item.content, { alwaysIncludeBody: true, html: false, dontSanitize: true }),
                            source: item.wfid,
                        }
                    })
                    return output
                }
                else {
                    output = {
                        itemWfid: {
                            include: true,
                            header: $translate.instant('ObjectID'),
                            source: 'itemWfid',
                        },
                        item: {
                            include: true,
                            header: $translate.instant('Object'),
                            source: 'item',
                        },
                        reported: {
                            include: true,
                            header: $translate.instant('Reported'),
                            source: 'reported',
                        },
                        attached: {
                            include: true,
                            header: $translate.instant('Attached'),
                            source: 'attached',
                        },
                        measurementUnit: {
                            include: true,
                            header: $translate.instant('Unit'),
                            source: 'measurementUnit',
                        },
                        measurementPeriod: {
                            include: true,
                            header: $translate.instant('MeasurementPeriod'),
                            source: 'measurementPeriod',
                        },
                        reportingOrganization: options.isInternalPackage && {
                            include: true,
                            header: $translate.instant('Creator Organization'),
                            source: 'reportingOrganization',
                        },
                        creatorOrganizationWfid: {
                            include: true,
                            header: $translate.instant('Organization') + 'ID',
                            source: 'creatorOrganizationWfid',
                        },
                        creatorOrganization: {
                            include: true,
                            header: $translate.instant('Organization'),
                            source: 'creatorOrganization',
                        },
                        orgNumber: {
                            include: true,
                            header: $translate.instant('RegistrationNumber'),
                            source: 'orgNumber',
                        },
                        vatNumber: {
                            include: true,
                            header: $translate.instant('VATNumber'),
                            source: 'vatNumber',
                        },
                        gln: {
                            include: true,
                            header: 'GLN',
                            source: 'gln',
                        },
                        customId: {
                            include: true,
                            header: $translate.instant('CustomID'),
                            source: 'customId',
                        },
                        country: {
                            include: true,
                            header: $translate.instant('Country'),
                            source: 'country',
                        },
                        creatorUser: {
                            include: true,
                            header: $translate.instant('User'),
                            source: 'creatorUser',
                        },
                        creatorUserEmail: {
                            include: true,
                            header: $translate.instant('Email'),
                            source: 'creatorUserEmail',
                        },
                        dateAdded: {
                            include: true,
                            header: $translate.instant('DateAdded'),
                            source: 'dateAdded',
                        },
                    }

                    if (!output.reportingOrganization) {
                        delete output.reportingOrganization
                    }

                    if (options.hideCustomIdOption) {
                        delete output.customId
                    }

                    if (relMeasureStepColumnsCount) {
                        for (let stepNumber = 1; stepNumber <= relMeasureStepColumnsCount; stepNumber++) {

                            let header

                            if (stepNumber > 1) {
                                header = `relMeasure_step${stepNumber}Operation`
                                output[header] = {
                                    include: true,
                                    header: $translate.instant('modules.export.csv.columns.relativeMeasureStepOperation', { step: stepNumber }),
                                    source: header,
                                }
                            }

                            header = `relMeasure_step${stepNumber}ObjectName`
                            output[header] = {
                                include: true,
                                header: $translate.instant('modules.export.csv.columns.relativeMeasureStepObject', { step: stepNumber }),
                                source: header,
                            }

                            header = `relMeasure_step${stepNumber}SourceValue`
                            output[header] = {
                                include: true,
                                header: $translate.instant('modules.export.csv.columns.relativeMeasureStepSourceValue', { step: stepNumber }),
                                source: header,
                            }

                            header = `relMeasure_step${stepNumber}Unit`
                            output[header] = {
                                include: true,
                                header: $translate.instant('modules.export.csv.columns.relativeMeasureStepUnit', { step: stepNumber }),
                                source: header,
                            }
                        }
                    }

                    if (scope.includeCategorizations.value) {
                        if (scope.groupCategorizations.value) {
                            _.each(options.categoryGroups, (item) => {
                                output[item.id] = {
                                    include: true,
                                    header: item.title,
                                    source: item.id,
                                }
                            })
                        }
                        else {
                            _.each(options.categories, (item) => {
                                output[item.id] = {
                                    include: true,
                                    header: item.title,
                                    source: item.id,
                                }
                            })
                        }
                    }

                    return output
                }
            }
        }

        function deleteInfluence(influence, organization) {
            return $q((resolve, reject) => {
                $ngBootbox.customDialog({
                    title: $translate.instant('modules.valueChain.influence.remove.modalTitle'),
                    message: $translate.instant('modules.valueChain.influence.remove.modalMessage', { influencename: influence.title, orgname: organization.name }),
                    onEscape: true,
                    className: 'valueChain-modal-removeOrganization',
                    buttons: {
                        cancel: {
                            label: $translate.instant('No'),
                            className: 'btn-default',
                            callback() {
                            },
                        },
                        primary: {
                            label: $translate.instant('Delete'),
                            className: 'btn-danger',
                            callback() {
                                dataOps.destroy(influence).then(() => {
                                    resolve(influence, organization)
                                })
                            },
                        },
                    },
                })
            })
        }

        function admin_openStandardCategorizer(org, networkId) {
            const categoryId = 17645

            modal.openItem({
                size: 'width-900',
                template: '<h2 ng-bind-html="modalHeader"></h2><wf-hierarchical item="{ type: 71, id: 10517, wfid: \'71-10517\' }" transclusion-outer-vm="vm" vm-prototype="vmPrototype" include="{ backButton: false }" override="{ headerOptions: pageHeader }" hooks="hooks">'
					+ '<transclude-item-side><div>'
					+ '<wf-toggle item="hierItemVm.item.content" relation-target="outerVm.relationTarget" intersection="outerVm.ticket"></wf-toggle>'
					+ '</div></transclude-item-side></wf-hierarchical>',
                scope: {
                    modalHeader: 'Pick materiality disclosures for <b>' + $sanitize(org.name) + '</b>',
                    vm: {
                        ticket: { networkId, organizationId: org.id },
                        relationTarget: { item: { type: 71, id: categoryId, wfid: '71-' + categoryId }, kind: enums.subItemsKind.childrenByUser },
                    },
                    hooks: {
                        onLoaded(vm) {
                            return $q((resolve, reject) => {
                                dataOps.getSubItems('71-' + categoryId, enums.subItemsKind.childrenByUser, {
                                    ticket: { networkId, organizationId: org.id },
                                }).then(() => {
                                    resolve()
                                })
                            })
                        },
                    },
                    vmPrototype: {
                        mainPanelFullWidth: true,
                        showPageHeading: false,
                        showTypeSpecificTools: false,
                        showFilterBars: false,
                        showLevelsFilterBars: true,
                        itemSettings: {
                            showDropdown: false,
                            showMetadata: false,
                        },
                    },
                },
            })
        }

        function getNetworkOrgRelations(organization) {
            return $q((resolve, reject) => {
                dataOps.getSubItems(organization, enums.subItemsKind.parentsByUser, { bypassCache: true }).then((res) => {
                    resolve(res)
                }, () => {
                    reject()
                    console.error('Could not get networkOrgRelations')
                })
            })
        }

        function getRequirementPackagesStatistics(options) {
            return apiProxy.raw('valuechain.getRequirementPackagesStatistics', {
                networkId: options.networkId,
                objectType: options.objectType,
                objectIds: options.objectIds,
                objectId: options.objectId,
                targetOrganizationIds: options.targetOrganizationIds,
                contextParentWfids: options.contextParentWfids,
                isInternal: options.isInternal,
            })
        }

        function getCalculationSchedule(options) {
            return apiProxy.raw('valuechain.getCalculationSchedule', options)
        }

        function setCalculationSchedule(options) {
            return apiProxy.raw('valuechain.setCalculationschedule', options)
        }

        function recalculateInfluences(options) {
            return $q((resolve, reject) => {
                let formField; let filteredItems; let filteredTargetOrganizationIds = null

                const unfulfilledCalculationResultPolicies = [
                    { value: 0, name: $translate.instant('modules.jobs.batchCalculateInfluences.unfulfilledCalculationResultPolicy.doNothing') },
                    { value: 7, name: $translate.instant('modules.jobs.batchCalculateInfluences.unfulfilledCalculationResultPolicy.setDueDateFromNow', { days: 7 }) },
                    { value: 14, name: $translate.instant('modules.jobs.batchCalculateInfluences.unfulfilledCalculationResultPolicy.setDueDateFromNow', { days: 14 }) },
                    { value: 30, name: $translate.instant('modules.jobs.batchCalculateInfluences.unfulfilledCalculationResultPolicy.setDueDateFromNow', { days: 30 }) },
                ]

                const formSpec = {
                    schema: {
                        type: 'object',
                        properties: {
                            unfulfilledCalculationResultPolicy: {
                                title: $translate.instant('modules.jobs.batchCalculate.form.unfulfilledCalculationResultPolicy.label'),
                                type: 'integer',
                            },
                            categoryIds: {
                                title: $translate.instant('modules.jobs.batchCalculate.form.limitByCategories.label'),
                                type: 'object',
                            },
                        },
                    },
                    form: [
                        {
                            key: 'unfulfilledCalculationResultPolicy',
                            type: 'select',
                            titleMap: unfulfilledCalculationResultPolicies,
                        },
                        formField = {
                            key: 'categoryIds',
                            type: 'template',
                            template: '<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>'
								+ '<div class="pull-left mr20"><wf-value-chain-category-filtering network-id="' + options.networkId + '" on-filtered="form.onFiltered(filteredItems, selectedOptions)" filter-output="form.filterOutput"></wf-value-chain-category-filtering></div>'
								+ '<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">'
								+ '<span translate="modules.jobs.batchCalculate.form.limitByCategories.organizationCount"></span> '
								+ '<strong ng-bind="form.filterOutput.selectedOptions.length ? form.filterOutput.filteredItems.length : form.filterOutput.unfilteredItems.length"></strong>'
								+ '</div></div>',
                            filterOutput: {},
                            onFiltered(filteredItems, selectedOptions) {
                                if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                else filteredTargetOrganizationIds = null

                                $timeout()
                            },
                        },
                    ],
                }

                const modalPromise = modal.editor({
                    unfulfilledCalculationResultPolicy: 0,
                },
                {
                    title: $translate.instant('modules.jobs.batchCalculateInfluences.precalculationMessage', { title: options.title }),
                    action(model) {
                        if (!model.unfulfilledCalculationResultPolicy) model.unfulfilledCalculationResultPolicy = null

                        if (filteredTargetOrganizationIds !== null && filteredTargetOrganizationIds.length === 0) {
                            modal.alert({
                                title: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsTitle'),
                                message: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsMessage'),
                                type: 'info',
                            })
                            return false
                        }

                        return apiProxy.raw('valuechain.recalculateInfluences', {
                            networkId: options.networkId,
                            objectType: options.objectType,
                            objectId: options.objectId,
                            targetOrganizationIds: options.targetOrganizationIds || filteredTargetOrganizationIds || [],
                            contextParentWfids: options.contextParentWfids,
                            unfulfilledCalculationResultPolicy: model.unfulfilledCalculationResultPolicy ? { setNewDueDateDaysFromNow: model.unfulfilledCalculationResultPolicy } : null,
                        })
                    },
                    customFormSpecification: formSpec,
                    submitCaption: $translate.instant('Start'),
                })

                modalPromise.then((res) => {
                    resolve(res)
                })

                modalPromise.cancelled(() => {
                    resolve()
                })
            })
        }

        function getAnalyzePackagesStatistics(options) {
            return apiProxy.raw('valuechain.getAnalyzePackagesStatistics', {
                networkId: options.networkId,
                objectType: options.objectType,
                objectIds: options.objectIds,
                objectId: options.objectId,
                targetOrganizationIds: options.targetOrganizationIds,
                contextParentWfids: options.contextParentWfids,
            })
        }

        function recalculateAnalyzeJobs(options) {
            return $q((resolve, reject) => {
                let formField; let filteredItems; let filteredTargetOrganizationIds = null

                const formSpec = {
                    schema: {
                        type: 'object',
                        properties: {
                            categoryIds: {
                                title: $translate.instant('modules.jobs.batchCalculate.form.limitByCategories.label'),
                                type: 'object',
                            },
                        },
                    },
                    form: [
                        formField = {
                            key: 'categoryIds',
                            type: 'template',
                            template: '<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>'
								+ '<div class="pull-left mr20"><wf-value-chain-category-filtering network-id="' + options.networkId + '" on-filtered="form.onFiltered(filteredItems, selectedOptions)" filter-output="form.filterOutput"></wf-value-chain-category-filtering></div>'
								+ '<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">'
								+ '<span translate="modules.jobs.batchCalculate.form.limitByCategories.organizationCount"></span> '
								+ '<strong ng-bind="form.filterOutput.selectedOptions.length ? form.filterOutput.filteredItems.length : form.filterOutput.unfilteredItems.length"></strong>'
								+ '</div></div>',
                            filterOutput: {},
                            onFiltered(filteredItems, selectedOptions) {
                                if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                else filteredTargetOrganizationIds = null

                                $timeout()
                            },
                        },
                    ],
                }

                const modalPromise = modal.editor({
                },
                {
                    title: $translate.instant('modules.jobs.batchCalculateAnalyzeJobs.precalculationMessage', { title: options.title }),
                    action(model) {
                        if (filteredTargetOrganizationIds !== null && filteredTargetOrganizationIds.length === 0) {
                            modal.alert({
                                title: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsTitle'),
                                message: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsMessage'),
                                type: 'info',
                            })
                            return false
                        }

                        return apiProxy.raw('valuechain.recalculateAnalyzeJobs', {
                            networkId: options.networkId,
                            objectType: options.objectType,
                            objectId: options.objectId,
                            targetOrganizationIds: options.targetOrganizationIds || filteredTargetOrganizationIds || [],
                            contextParentWfids: options.contextParentWfids,
                        })
                    },
                    customFormSpecification: formSpec,
                    submitCaption: $translate.instant('Start'),
                })

                modalPromise.then((res) => {
                    resolve(res)
                })

                modalPromise.cancelled(() => {
                    resolve()
                })
            })
        }

        function abortJobInvocation(options) {
            return $q((resolve, reject) => {
                modal.alert({
                    title: $translate.instant('modules.jobs.batchCalculate.cancelRecalcConfirmation.title'),
                    message: $translate.instant('modules.jobs.batchCalculate.cancelRecalcConfirmation.message'),
                    onEscape: false,
                    type: 'warning',
                    buttons: {
                        cancel: {
                            label: $translate.instant('No'),
                            className: 'btn btn-hollow',
                            callback: () => reject(),
                        },
                        primary: {
                            label: $translate.instant('Yes'),
                            className: 'btn-hollow action',
                            callback() {
                                apiProxy.raw('valuechain.abortJobInvocation', {
                                    id: options.jobInvocationId,
                                }).then(() => resolve())
                            },
                        },
                    },
                })
            })
        }

        function openBatchCreator(options) {
            const { useInternal, packageType } = options

            return $q((resolve, reject) => {
                let formField; let filteredTargetOrganizationIds = null

                dataOps.getOrganizationUsers().then((users) => {
                    let translationGroupKey

                    const usersTitleMap = _.map(users, (user) => {
                        return { value: user.id, name: user.name + ' <' + user.email + '>' }
                    })

                    if (packageType === ConstVars.PackageTypes.RequirementPackage) {
                        translationGroupKey = 'batchManageInfluences'
                    }
                    else if (packageType === ConstVars.PackageTypes.AnalyzePackage) {
                        translationGroupKey = 'batchManageAnalyzeJobs'
                    }

                    const formSpec = {
                        schema: {
                            type: 'object',
                            properties: {
                                fulfillmentDueAt: packageType === ConstVars.PackageTypes.RequirementPackage && {
                                    title: $translate.instant('modules.valueChain.influence.dueDateLabel'),
                                    type: 'string',
                                    format: 'date',
                                },
                                activatedAt: packageType === ConstVars.PackageTypes.RequirementPackage && {
                                    title: $translate.instant('modules.valueChain.influence.activationDate'),
                                    type: 'string',
                                    format: 'date',
                                },
                                creatorUserId: packageType === ConstVars.PackageTypes.RequirementPackage && {
                                    title: $translate.instant('modules.valueChain.influence.creatorUserLabel'),
                                    type: 'integer',
                                },
                                categoryIds: {
                                    title: $translate.instant(`modules.jobs.${translationGroupKey}.limitByCategories.label`),
                                    type: 'object',
                                },
                            },
                            required: ['creatorUserId'],
                        },
                        form: [
                            packageType === ConstVars.PackageTypes.RequirementPackage && {
                                key: 'fulfillmentDueAt',
                            },
                            packageType === ConstVars.PackageTypes.RequirementPackage && {
                                key: 'activatedAt',
                            },
                            packageType === ConstVars.PackageTypes.RequirementPackage && {
                                key: 'creatorUserId',
                                type: 'select',
                                titleMap: usersTitleMap,
                            },
                            formField = {
                                key: 'categoryIds',
                                type: 'template',
                                template: '<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>'
									+ '<div class="pull-left mr20"><wf-value-chain-category-filtering network-id="' + options.networkId + '" on-filtered="form.onFiltered(filteredItems, selectedOptions)" filter-output="form.filterOutput"></wf-value-chain-category-filtering></div>'
									+ '<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">'
									+ `<span translate="modules.jobs.${translationGroupKey}.limitByCategories.organizationCount"></span> `
									+ '<strong ng-bind="form.filterOutput.selectedOptions.length ? form.filterOutput.filteredItems.length : form.filterOutput.unfilteredItems.length"></strong>'
									+ '</div></div>',
                                filterOutput: {},
                                onFiltered(filteredItems, selectedOptions) {
                                    if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                    else filteredTargetOrganizationIds = null

                                    $timeout()
                                },
                            },
                        ],
                    }

                    const modalPromise = modal.editor({
                    },
                    {
                        title: $translate.instant(`modules.jobs.${translationGroupKey}.creatorModalHeader`, { title: options.title }),
                        description: $translate.instant(`modules.jobs.${translationGroupKey}.creatorModalInformation`),
                        action(model) {
                            if (filteredTargetOrganizationIds !== null && filteredTargetOrganizationIds.length === 0) {
                                modal.alert({
                                    title: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsTitle'),
                                    message: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsMessage'),
                                    type: 'info',
                                })
                                return false
                            }

                            return $q((resolve, reject) => {
                                const orgIds = options.targetOrganizationIds || filteredTargetOrganizationIds

                                const targetOrganizationIds = useInternal ? undefined : orgIds
                                const contextParentIds = useInternal ? orgIds : undefined
                                const contextParentType = useInternal ? enums.ObjectType.organization : undefined

                                const payload = {
                                    networkId: options.networkId,
                                    objectType: options.objectType,
                                    objectId: options.objectId,
                                    targetOrganizationIds,
                                    contextParentIds,
                                    contextParentType,
                                }

                                if (packageType === ConstVars.PackageTypes.RequirementPackage) {
                                    payload.activatedAt = model.activatedAt
                                    payload.fulfillmentDueAt = model.fulfillmentDueAt
                                    payload.creatorUserId = model.creatorUserId
                                }

                                apiProxy.raw(packageType === ConstVars.PackageTypes.RequirementPackage ? 'valuechain.createInfluences' : 'valuechain.createAnalyzeJobs', payload).then((res) => {
                                    modal.alert({
                                        title: $translate.instant(`modules.jobs.${translationGroupKey}.postCreateMessage.title`, { title: options.title }),
                                        message: $translate.instant(`modules.jobs.${translationGroupKey}.postCreateMessage.message`, { created: res.createdCount, existed: res.existedCount }),
                                        type: 'info',
                                        onClose() { resolve() },
                                    })
                                })
                            })
                        },
                        customFormSpecification: formSpec,
                        submitCaption: $translate.instant('Create'),
                    })

                    modalPromise.then((res) => {
                        resolve(res)
                    })

                    modalPromise.cancelled(() => {
                        resolve()
                    })
                })
            })
        }

        function openAnalyzeJobsCreator(options) {
            return openBatchCreator({ ...options, packageType: ConstVars.PackageTypes.AnalyzePackage })
        }

        function openInfluencesCreator(options) {
            return openBatchCreator({ ...options, packageType: ConstVars.PackageTypes.RequirementPackage })
        }

        function openInfluencesMultiEditorConfigurator(options) {
            return $q((resolve, reject) => {
                const isCreation = options.creation; const isDeletion = options.deletion
                let formField; let filteredTargetOrganizationIds = null
                const outputObj = _.defaultsDeep(_.cloneDeep(options.initialValues), {
                    creatorUserId: null,
                    categoryIds: [],
                    invertedCategoryIds: [],
                    networkId: options.networkId,
                    activatedAtDaysFromNow: null,
                    fulfillmentDueAtDaysFromNow: null,
                    objectType: 71,
                    objectIds: [],
                })
                let usersTitleMap; let users; let listOfRequirementPackages

                const promises = []; let promise

                if (outputObj.activatedAtDaysFromNow === undefined || outputObj.activatedAtDaysFromNow === null) outputObj.activatedAtDaysFromNow = -1

                if (outputObj.fulfillmentDueAtDaysFromNow === undefined || outputObj.fulfillmentDueAtDaysFromNow === null) outputObj.fulfillmentDueAtDaysFromNow = -1

                promise = $q((resolve, reject) => {
                    loadCategoriesInNetwork(options.networkId).then((relations) => {
                        outputObj.invertedCategoryTitles = relations.filter(x => outputObj.invertedCategoryIds.includes(x.childId)).map(x => x.childContent.title)
                        outputObj.categoryTitles = relations.filter(x => outputObj.categoryIds.includes(x.childId)).map(x => x.childContent.title)

                        loadPackagesInNetwork(options.networkId, false).then((relations) => {
                            listOfRequirementPackages = relations

                            if (outputObj.objectIds) outputObj.objectTitles = relations.filter(x => outputObj.objectIds.includes(x.childId)).map(x => x.childContent.title)

                            resolve()
                        })
                    })
                })

                promises.push(promise)

                if (isCreation) {
                    promise = dataOps.getOrganizationUsers()
                    promise.then((_users) => {
                        users = _users
                        usersTitleMap = _.map(_users, (user) => {
                            return { value: user.id, name: user.name + ' <' + user.email + '>' }
                        })
                    })
                    promises.push(promise)
                }

                $q.all(promises).then(() => {
                    const formSpec = {
                        schema: {
                            type: 'object',
                            properties: _.omitBy({
                                fulfillmentDueAtDaysFromNow: isCreation && {
                                    title: $translate.instant('modules.valueChain.influence.dueDateLabel'),
                                    type: 'integer',
                                },
                                activatedAtDaysFromNow: isCreation && {
                                    title: $translate.instant('modules.valueChain.influence.activationDate'),
                                    type: 'integer',
                                },
                                creatorUserId: isCreation && {
                                    title: $translate.instant('modules.valueChain.influence.creatorUserLabel'),
                                    type: 'integer',
                                },
                                sourceObject: { title: $translate.instant('RequirementPackages'), type: 'integer' },
                                limitCategoryIds: {
                                    title: $translate.instant('Select categories the organization has to be in or not be in. If not selected, the requests will always be created.'),
                                    type: 'object',
                                },
                            }, x => !x),
                            required: isCreation && ['creatorUserId'],
                        },
                        form: _.compact([
                            isCreation && {
                                key: 'fulfillmentDueAtDaysFromNow',
                                type: 'select',
                                titleMap: [
                                    { value: -1, name: 'No due date' },
                                    { value: 10, name: 'Due in 10 days' },
                                    { value: 14, name: 'Due in 14 days' },
                                    { value: 30, name: 'Due in 30 days' },
                                    { value: 60, name: 'Due in 60 days' },
                                    { value: 90, name: 'Due in 90 days' },
                                ],
                            },
                            isCreation && {
                                key: 'activatedAtDaysFromNow',
                                type: 'select',
                                titleMap: [
                                    { value: -1, name: 'No activation date' },
                                    { value: 0, name: 'Activate same day' },
                                    { value: 7, name: 'Activate in 7 days' },
                                    { value: 14, name: 'Activate in 14 days' },
                                    { value: 30, name: 'Activate in 30 days' },
                                    { value: 60, name: 'Activate in 60 days' },
                                    { value: 90, name: 'Activate in 90 days' },
                                ],
                            },
                            isCreation && {
                                key: 'creatorUserId',
                                type: 'select',
                                titleMap: usersTitleMap,
                            },
                            {
                                key: 'sourceObject',
                                type: 'picker_multiple',
                                typeOptions: {
                                    selectedItem: { id: null, type: null },
                                    selectedItems: wfObject.filter({ where: { wfid: { in: outputObj.objectIds.map(x => '71-' + x) } } }),
                                    addButtonCaption: $translate.instant('modules.valueChain.influence.selectRequirementButtonCaption'),
                                    singlePick: false,
                                    submitAction: () => { },
                                    validateAction(event, model, relationBucket) { // Not called anywhere?
                                        return !!relationBucket.allSelected.length
                                    },
                                    onPickerClosed(relationBucketResults) {
                                        outputObj.objectIds = relationBucketResults.allSelected.map(x => parseInt(x.id))
                                        outputObj.objectTitles = relationBucketResults.allSelected.map(x => x.title)
                                    },
                                    picker: {
                                        hideFilters: true,
                                        sourceList: (() => {
                                            return _.map(listOfRequirementPackages, (item) => {
                                                return { data: item.childContent, wfid: item.wfid }
                                            })
                                        })(),
                                        create: false,
                                        pick: true,
                                        title: $translate.instant('modules.valueChain.influence.selectRequirementButtonCaption'),
                                        emptyState: { header: $translate.instant('modules.valueChain.influence.requirementSelection.emptyStateHeader') },
                                        closeCaption: $translate.instant('Done'),
                                    },
                                },
                            },
                            formField = {
                                key: 'limitCategoryIds',
                                type: 'template',
                                template: `<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>
										<div class="pull-left mr20">
										<wf-value-chain-category-filtering
											network-id="form.networkId"
											on-filtered="form.onFiltered(filteredItems, selectedOptions)"
											filter-output=""
											preselected-category-ids="form.categoryIds"
											preselected-inverted-category-ids="form.invertedCategoryIds"
										></wf-value-chain-category-filtering>
										</div>
										<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">
										</div></div>`,
                                categoryIds: _.get(options.initialValues, 'categoryIds'),
                                invertedCategoryIds: _.get(options.initialValues, 'invertedCategoryIds'),
                                filterOutput: {},
                                networkId: options.networkId,
                                onFiltered(filteredItems, selectedOptions) {
                                    outputObj.invertedCategoryIds = selectedOptions.filter(x => x.isInverted).map(x => parseInt(x.content.id))
                                    outputObj.categoryIds = selectedOptions.filter(x => !x.isInverted).map(x => parseInt(x.content.id))

                                    outputObj.invertedCategoryTitles = selectedOptions.filter(x => x.isInverted).map(x => x.content.title)
                                    outputObj.categoryTitles = selectedOptions.filter(x => !x.isInverted).map(x => x.content.title)

                                    if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                    else filteredTargetOrganizationIds = null

                                    $timeout()
                                },
                            },
                        ]),
                    }

                    const modalPromise = modal.editor(outputObj,
                        {
                            title: $translate.instant(isCreation ? 'Setup request creation' : 'Setup request deletion', { title: options.title }),
                            action(model) {
                                return $q((resolveAction, rejectAction) => {
                                    if (isCreation) {
                                        _.assign(outputObj, {
                                            activatedAtDaysFromNow: model.activatedAtDaysFromNow === undefined || model.activatedAtDaysFromNow === -1 ? null : model.activatedAtDaysFromNow,
                                            fulfillmentDueAtDaysFromNow: model.fulfillmentDueAtDaysFromNow === undefined || model.fulfillmentDueAtDaysFromNow === -1 ? null : model.fulfillmentDueAtDaysFromNow,
                                            creatorUserId: model.creatorUserId,
                                            creatorUserName: users.find(x => x.id === model.creatorUserId).name,
                                        })
                                    }

                                    resolveAction()
                                })
                            },
                            customFormSpecification: formSpec,
                            submitCaption: $translate.instant('OK'),
                        })

                    modalPromise.then((res) => {
                        resolve(outputObj)
                    })

                    modalPromise.cancelled(() => {
                        resolve()
                    })
                })
            })
        }

        function openInfluencesUpdater(options) {
            const { useInternal } = options

            return $q((resolve, reject) => {
                let formField; let filteredTargetOrganizationIds = null

                dataOps.getOrganizationUsers().then((users) => {
                    const usersTitleMap = _.map(users, (user) => {
                        return { value: user.id, name: user.name + ' <' + user.email + '>' }
                    })

                    const mailSendoutStates = [
                        { name: $translate.instant('modules.mailSettings.ValueChainInvitation'), value: enums.mailPurpose.valueChainInvitation },
                        { name: $translate.instant('modules.mailSettings.ValueChainReminder'), value: enums.mailPurpose.valueChainReminder },
                    ]

                    const formSpec = {
                        schema: {
                            type: 'object',
                            properties: {
                                setFulfillmentDueAt: {
                                    title: $translate.instant('Change') + ' ' + $translate.instant('modules.valueChain.influence.dueDateLabel'),
                                    type: 'boolean',
                                },
                                setActivatedAt: {
                                    title: $translate.instant('Change') + ' ' + $translate.instant('modules.valueChain.influence.activationDate'),
                                    type: 'boolean',
                                },
                                setCreatorUserId: {
                                    title: $translate.instant('Change') + ' ' + $translate.instant('modules.valueChain.influence.creatorUserLabel'),
                                    type: 'boolean',
                                },
                                setMailSendoutState: {
                                    title: $translate.instant('Change') + ' ' + $translate.instant('modules.valueChain.influence.mailSendoutState'),
                                    type: 'boolean',
                                },
                                fulfillmentDueAt: {
                                    title: $translate.instant('modules.valueChain.influence.dueDateLabel'),
                                    type: 'string',
                                    format: 'date',
                                },
                                activatedAt: {
                                    title: $translate.instant('modules.valueChain.influence.activationDate'),
                                    type: 'string',
                                    format: 'date',
                                },
                                creatorUserId: {
                                    title: $translate.instant('modules.valueChain.influence.creatorUserLabel'),
                                    type: 'integer',
                                },
                                mailSendoutState: {
                                    title: $translate.instant('modules.valueChain.influence.mailSendoutState'),
                                    type: 'integer',
                                },
                                categoryIds: {
                                    title: $translate.instant('modules.jobs.batchManageInfluences.limitByCategories.label'),
                                    type: 'object',
                                },
                            },
                            required: ['creatorUserId', 'mailSendoutState'],
                        },
                        form: [
                            {
                                key: 'setFulfillmentDueAt',
                            },
                            {
                                key: 'fulfillmentDueAt',
                                condition: 'vm.model.setFulfillmentDueAt',
                            },
                            {
                                key: 'setActivatedAt',
                            },
                            {
                                key: 'activatedAt',
                                condition: 'vm.model.setActivatedAt',
                            },
                            {
                                key: 'setCreatorUserId',
                            },
                            {
                                key: 'creatorUserId',
                                type: 'select',
                                titleMap: usersTitleMap,
                                condition: 'vm.model.setCreatorUserId',
                            },
                            {
                                key: 'setMailSendoutState',
                                description: `<div class="info"><div class="tooltip">
									<i class="fa fa-info-circle"></i><span class="tooltiptext">
									${$translate.instant('modules.valueChain.influence.mailSendoutStateDescription')}
									</span></div></div>`,
                            },
                            {
                                key: 'mailSendoutState',
                                type: 'select',
                                titleMap: mailSendoutStates,
                                condition: 'vm.model.setMailSendoutState',
                            },
                            formField = {
                                key: 'categoryIds',
                                type: 'template',
                                template: '<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>'
									+ '<div class="pull-left mr20"><wf-value-chain-category-filtering network-id="' + options.networkId + '" on-filtered="form.onFiltered(filteredItems, selectedOptions)" filter-output="form.filterOutput"></wf-value-chain-category-filtering></div>'
									+ '<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">'
									+ '<span translate="modules.jobs.batchManageInfluences.limitByCategories.organizationCount"></span> '
									+ '<strong ng-bind="form.filterOutput.selectedOptions.length ? form.filterOutput.filteredItems.length : form.filterOutput.unfilteredItems.length"></strong>'
									+ '</div></div>',
                                filterOutput: {},
                                onFiltered(filteredItems, selectedOptions) {
                                    if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                    else filteredTargetOrganizationIds = null

                                    $timeout()
                                },
                            },
                        ],
                    }

                    const modalPromise = modal.editor({
                    },
                    {
                        title: options.title ? $translate.instant('modules.jobs.batchManageInfluences.updaterModalHeaderWithTitle', { title: options.title }) : $translate.instant('modules.jobs.batchManageInfluences.updaterModalHeader'),
                        action(model) {
                            if (filteredTargetOrganizationIds !== null && filteredTargetOrganizationIds.length === 0) {
                                modal.alert({
                                    title: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsTitle'),
                                    message: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsMessage'),
                                    type: 'info',
                                })
                                return false
                            }

                            return $q((resolve, reject) => {
                                const orgIds = options.targetOrganizationIds || filteredTargetOrganizationIds

                                const targetOrganizationIds = useInternal ? undefined : orgIds
                                const contextParentIds = useInternal ? orgIds : undefined
                                const contextParentType = useInternal ? enums.ObjectType.organization : undefined

                                apiProxy.raw('valuechain.updateInfluences', {
                                    networkId: options.networkId,
                                    objectType: options.objectType,
                                    objectId: options.objectId,
                                    setActivatedAt: model.setActivatedAt,
                                    setFulfillmentDueAt: model.setFulfillmentDueAt,
                                    setCreatorUserId: model.setCreatorUserId,
                                    activatedAt: model.activatedAt,
                                    fulfillmentDueAt: model.fulfillmentDueAt,
                                    creatorUserId: model.creatorUserId,
                                    mailSendoutState: model.mailSendoutState,
                                    targetOrganizationIds,
                                    contextParentIds,
                                    contextParentType,
                                }).then((res) => {
                                    modal.alert({
                                        title: options.title ? $translate.instant('modules.jobs.batchManageInfluences.postUpdateMessage.titleWithTitle', { title: options.title }) : $translate.instant('modules.jobs.batchManageInfluences.postUpdateMessage.title'),
                                        message: $translate.instant('modules.jobs.batchManageInfluences.postUpdateMessage.message', { updated: res.updatedCount }),
                                        type: 'info',
                                        onClose() { resolve() },
                                    })
                                })
                            })
                        },
                        customFormSpecification: formSpec,
                        submitCaption: $translate.instant('Update'),
                    })

                    modalPromise.then((res) => {
                        resolve(res)
                    })

                    modalPromise.cancelled(() => {
                        resolve()
                    })
                })
            })
        }

        function openAnalyzeJobsDeleter(options) {
            return openBatchDeleter({ ...options, packageType: ConstVars.PackageTypes.AnalyzePackage })
        }

        function openInfluencesDeleter(options) {
            return openBatchDeleter({ ...options, packageType: ConstVars.PackageTypes.RequirementPackage })
        }

        function openBatchDeleter(options) {
            const { useInternal, packageType } = options
            let translationGroupKey

            if (packageType === ConstVars.PackageTypes.RequirementPackage) {
                translationGroupKey = 'batchManageInfluences'
            }
            else if (packageType === ConstVars.PackageTypes.AnalyzePackage) {
                translationGroupKey = 'batchManageAnalyzeJobs'
            }

            return $q((resolve, reject) => {
                let formField; let filteredTargetOrganizationIds = null

                const formSpec = {
                    schema: {
                        type: 'object',
                        properties: {
                            categoryIds: {
                                title: $translate.instant(`modules.jobs.${translationGroupKey}.limitByCategories.label`),
                                type: 'object',
                            },
                        },
                    },
                    form: [
                        formField = {
                            key: 'categoryIds',
                            type: 'template',
                            template: '<div class="pt10 pb10"><label class="control-label" ng-bind="form.title"></label>'
								+ '<div class="pull-left mr20"><wf-value-chain-category-filtering network-id="' + options.networkId + '" on-filtered="form.onFiltered(filteredItems, selectedOptions)" filter-output="form.filterOutput"></wf-value-chain-category-filtering></div>'
								+ '<div class="pull-left pt10" ng-if="form.filterOutput.unfilteredItems">'
								+ `<span translate="modules.jobs.${translationGroupKey}.limitByCategories.organizationCount"></span> `
								+ '<strong ng-bind="form.filterOutput.selectedOptions.length ? form.filterOutput.filteredItems.length : form.filterOutput.unfilteredItems.length"></strong>'
								+ '</div></div>',
                            filterOutput: {},
                            onFiltered(filteredItems, selectedOptions) {
                                if (selectedOptions.length) filteredTargetOrganizationIds = filteredItems
                                else filteredTargetOrganizationIds = null

                                $timeout()
                            },
                        },
                    ],
                }

                const modalPromise = modal.editor({
                },
                {
                    title: '⚠ ' + (options.title ? $translate.instant(`modules.jobs.${translationGroupKey}.delete.modalHeaderWithTitle`, { title: options.title }) : $translate.instant(`modules.jobs.${translationGroupKey}.delete.modalHeader`)),
                    action(model) {
                        if (filteredTargetOrganizationIds !== null && filteredTargetOrganizationIds.length === 0) {
                            modal.alert({
                                title: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsTitle'),
                                message: $translate.instant('modules.jobs.batchCalculate.form.noOrganizationsMessage'),
                                type: 'info',
                            })
                            return false
                        }

                        return $q((resolve, reject) => {
                            const orgIds = options.targetOrganizationIds || filteredTargetOrganizationIds
                            const targetOrganizationIds = useInternal ? undefined : orgIds
                            const contextParentIds = useInternal ? orgIds : undefined
                            const contextParentType = useInternal ? enums.ObjectType.organization : undefined

                            apiProxy.raw(packageType === ConstVars.PackageTypes.RequirementPackage ? 'valuechain.deleteInfluences' : 'valuechain.deleteAnalyzeJobs', {
                                networkId: options.networkId,
                                objectType: options.objectType,
                                objectId: options.objectId,
                                targetOrganizationIds,
                                contextParentIds,
                                contextParentType,
                            }).then((res) => {
                                modal.alert({
                                    title: options.title ? $translate.instant(`modules.jobs.${translationGroupKey}.delete.postActionMessage.titleWithTitle`, { title: options.title }) : $translate.instant(`modules.jobs.${translationGroupKey}.delete.postActionMessage.title`),
                                    message: $translate.instant(`modules.jobs.${translationGroupKey}.delete.postActionMessage.message`, { deleted: res.deletedCount }),
                                    type: 'info',
                                    onClose() { resolve() },
                                })
                            })
                        })
                    },
                    customFormSpecification: formSpec,
                    submitCaption: $translate.instant('Delete'),
                    submitButtonCssClass: 'btn-danger',
                })

                modalPromise.then((res) => {
                    resolve(res)
                })

                modalPromise.cancelled(() => {
                    resolve()
                })
            })
        }

        function getInfluenceVerificationDocuments(influence) {
            const verifications = influence.childContent.filterVerifications({ organizationId: influence.organizationId })
            let documentRelations = []

            if (verifications.length > 0) {
                _.forEach(verifications, (verificationDataRelation) => {
                    Array.prototype.push.apply(documentRelations, verificationDataRelation.childContent.filterChildren({ childType: 18, wfxpid: influence.contextParentWfids }))
                })
                documentRelations = _.orderBy(documentRelations, ['createdAt'], ['desc'])
            }

            return documentRelations
        }

        function openInfluenceSigningModal(influence, $scope, options) {
            const
                jqDf = $.Deferred()

            let fulfillmentCalcXhrRequest

            const showPreviewInHtml = _.get(options, 'showPreviewInHtml')

            modal.verifyItem(influence.childContent, influence).then((verification) => {
                let
                    verificationScope

                let compiledVerificationHtml

                let element

                if (verification) {
                    verificationScope = $scope.$new()
                    compiledVerificationHtml = $compile('<wf-item template-id="52"></wf-item>') // Generates a link function
                    verificationScope.item = verification
                    const context = {
                        templateId: 75,
                        influence,
                        isPrinting: true,
                    }
                    verificationScope.context = context
                    context.verification = verification
                    if (!showPreviewInHtml) {
                        element = compiledVerificationHtml(verificationScope) // Executes link function using the new scope
                        $scope.$on('verifiedContentLoaded', (event, verifiedContent) => {
                            delete context.verification
                            screenLoader.show($translate.instant('modules.valueChain.influence.message_calculatingFulfillment'))

                            calculateFulfillment(true).then((fulfillmentResult) => {
                                screenLoader.show($translate.instant('modules.valueChain.influence.message_generatingPdf'))

                                pdfGenerator.fromElement(element, influence.title + ' ' + moment().format('YYYY-MM-DD'), influence.channelId).then((document) => {
                                    // PdfGenerator succeeded
                                    dataOps.createSubItemRelation(verification, document, {
                                        kind: enums.subItemsKind.childrenByUser,
                                        contextParentWfid: influence.contextParentWfids,
                                    }).then((dr) => {

                                        $timeout()
                                        openInfluenceCompletionSuccessModal()
                                        screenLoader.hide()
                                        jqDf.resolve()
                                    })
                                }).fail((res) => {
                                    // PdfGenerator failed
                                    console.info('pdf failed', res)

                                    $timeout()
                                    screenLoader.hide()
                                    jqDf.resolve()
                                    $ngBootbox.customDialog({
                                        message: '<i class="fa fa-exclamation-circle bootbox-icon"></i><div class="bootbox-text">' + $translate.instant('modules.valueChain.influence.message_pdfFailed') + '</div>',
                                        closeButton: false,
                                        className: 'centerWithIcon',
                                        buttons: {
                                            primary: {
                                                label: $translate.instant('OK'),
                                                className: 'btn-primary',
                                            },
                                        },
                                    })
                                })
                            })
                        })
                    }
                    else {
                        delete context.verification
                        screenLoader.show($translate.instant('modules.valueChain.influence.message_calculatingFulfillment'))
                        calculateFulfillment(true).then((fulfillmentResult) => {
                            if (showPreviewInHtml) {
                                showPreviewHtml(verificationScope).opened.then(() => {
                                    $timeout()
                                    screenLoader.hide()
                                    jqDf.resolve()
                                })
                            }
                        })
                    }
                }
                else {
                    screenLoader.hide()
                    jqDf.resolve(false)
                }
            })

            return jqDf.promise()

            function showPreviewHtml(scope) {
                let modalInstance = $uibModal.open({
                    size: 'width-1200',
                    windowClass: 'wf-export-pdf-modal',
                    animation: true,
                    backdrop: 'static',
                    template: '<div><div class="header" style="display: inline-block;"><button type="button" class="close-modal-button close" wf-click="$close()" aria-hidden="true">Close</button></div><div class="pdf-preview-html">'
						+ '<wf-item template-id="52"></wf-item>'
						+ '</div></div>',
                    scope,
                })

                modalInstance.closed.then((result) => {
                    scope.$destroy()
                    modalInstance = undefined
                }, (rejectedResult) => {
                    scope.$destroy()
                    modalInstance = undefined
                    console.error('Rejected result modal - ', rejectedResult)
                })

                return modalInstance
            }

            function calculateFulfillment(hasSigned) {
                $timeout()

                if (fulfillmentCalcXhrRequest) {
                    fulfillmentCalcXhrRequest.abort()
                    fulfillmentCalcXhrRequest = undefined
                }

                fulfillmentCalcXhrRequest = apiProxy.raw('fulfillment.calculate', {
                    item: dataOps.prepareWfObject(influence),
                    hasSigned,
                })

                fulfillmentCalcXhrRequest.then((fulfillmentResult) => {
                    fulfillmentCalcXhrRequest = undefined
                    influence.fulfilled = fulfillmentResult.fulfillment.fulfills
                    influence.fulfillmentProgress = fulfillmentResult.fulfillment.fulfillmentProgress
                    influence.fulfillmentProgressTotal = fulfillmentResult.fulfillment.fulfillmentProgressTotal
                    influence.fulfillmentProgressPercentage = fulfillmentResult.fulfillment.fulfillmentProgressPercentage
                    influence.containsSpecialRequirements = fulfillmentResult.fulfillment.containsSpecialRequirements
                    influence.fulfillmentState = fulfillmentResult.fulfillment.fulfillmentState
                    influence.isAssessmentNeeded = fulfillmentResult.fulfillment.isAssessmentNeeded
                    influence.isReportingNeeded = fulfillmentResult.fulfillment.isReportingNeeded
                    influence.fulfillmentStatistics = fulfillmentResult.influence.fulfillmentStatistics
                })

                return fulfillmentCalcXhrRequest
            }
        }

        function openInfluenceCompletionSuccessModal() {
            let
                templateHtml

            let buttonHtml = '<button class="btn btn-primary btn-bg" wf-click="$close()">' + $translate.instant('modules.report.messageAfterSigning.closeButtonText') + '</button>'

            let unansweredRequests

            let unansweredRequestText

            let unansweredRequestHtml = ''

            dataOps.getObject({
                objectId: 10489, // Structure containing external reporting influences.
                objectType: 71,
            }).then((reportingStructure) => {
                unansweredRequests = _.filter(reportingStructure.childs, (relation) => {
                    const influence = relation.childContent

                    if (!influence.fulfilled) {
                        // Check for influences where sub-entities are added (Production sites etc using InfluenceGrouper).
                        // Those influence does not ever have fulfilled true but can be checked using the below properties.
                        // If something has been added in the InfluenceGrouper UI, the fulfillmentProgress will be >= 1. The fulfillmentProgressTotal is not applicable for those kind of influences and will be null.
                        if (influence.fulfillmentProgress && !influence.fulfillmentProgressTotal) // At least one item was added with InfluenceGrouper and the influence is considered fulfilled for this scenario.
                        {
                            return false
                        }
                        else if (influence.fulfillmentState === enums.fulfillmentState.assessmentNeeded) {
                            return false
                        }

                        return true
                    }
                })

                if (unansweredRequests && unansweredRequests.length > 0) {
                    buttonHtml = '<button class="btn btn-primary btn-bg" wf-click="goToDashboard()">' + $translate.instant('modules.report.messageAfterSigning.dashboardButtonText') + '</button><div class="pt10"><span class="btn btn-link" wf-click="$close()" translate="modules.report.messageAfterSigning.justCloseButtonText"></span></div>'
                    unansweredRequestText = unansweredRequests.length === 1 ? $translate.instant('modules.report.messageAfterSigning.unansweredRequests.unansweredRequestsSingular', { count: unansweredRequests.length }) : $translate.instant('modules.report.messageAfterSigning.unansweredRequests.unansweredRequestsPlural', { count: unansweredRequests.length })
                    unansweredRequestHtml = '<div class="unanswered-requests-wrapper"><span>' + $translate.instant('modules.report.messageAfterSigning.unansweredRequests.preSentence') + '</span><span class="unanswered-requests">' + unansweredRequestText + '</span><div>'
                }

                templateHtml =
					'<div class="modal-header">' +
					'<img src="assets/img/confetti.png" alt="">' +
					'</div>' +
					'<div class="modal-body">' +
					'<div class="message">' +
					'<h3 class="modal-title">' + $translate.instant('modules.report.messageAfterSigning.headerMessage') + '</h3>' +
					'<p class="message-text">' + $translate.instant('modules.report.messageAfterSigning.completedMessage') + '</p>' +
					unansweredRequestHtml +
					'</div>' +
					'</div>' +
					'<div class="modal-footer">' + buttonHtml + '</div>'

                const scope = $rootScope.$new()
                scope.goToDashboard = goToDashboard

                const uibModalInstance = $uibModal.open({
                    onEscape: false,
                    keyboard: false,
                    animation: true,
                    size: 'width-500',
                    windowClass: 'request-completed',
                    backdrop: 'static',
                    scope,
                    template: templateHtml,
                })

                function goToDashboard() {
                    uibModalInstance.close()
                    uibModalInstance.closed.then(() => { $state.go('root') })
                }
            })
        }

        function buildAnswerTypesOptionObject(questionAnswerTypeIds, options) {
            if (options && options.preferredValueWithRelatedContent) {
                return {
                    rule: enums.requirementRule.preferredValueWithRelatedContent,
                    name: (function () {
                        const texts = _.chain(questionAnswerTypeIds).map((id) => {
                            return wfPropertyExtractor.getQuestionAnswerTypeText(id)
                        }).value()
                        let joinedAnswerTypesText

                        if (texts.length == 1) joinedAnswerTypesText = texts[0]
                        else joinedAnswerTypesText = _.take(texts, texts.length - 1).join(', ') + ' ' + $translate.instant('or') + ' ' + texts[texts.length - 1]

                        return $translate.instant('modules.valueChain.requirements.preferredValueWithRelatedContent', { answertypes: joinedAnswerTypesText })

                    })(),
                    value: questionAnswerTypeIds.join(','),
                }
            }
            else {
                return {
                    rule: enums.requirementRule.specificValues,
                    name: (function () {
                        const texts = _.chain(questionAnswerTypeIds).map((id) => {
                            return wfPropertyExtractor.getQuestionAnswerTypeText(id)
                        }).value()

                        if (texts.length == 1) return texts[0]
                        else return _.take(texts, texts.length - 1).join(', ') + ' ' + $translate.instant('and') + ' ' + texts[texts.length - 1]
                    })(),
                    value: questionAnswerTypeIds.join(','),
                }
            }
        }

        function getQuestionObjectSettings(isPackageOwned) {
            const questionObjectSettings = {
                uiMode: enums.uiMode.admin,
                forOrganization: isPackageOwned ? !isPackageOwned : true,
                settings: [
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.answerOptions.label'), // "Svarsalternativ",
                        settingKind: 'limitQuestionAnswerTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('modules.valueChain.objectSettings.answerOptions.YesNoNotRelevantAndProcessing'), // "Ja, Nej, Ej relevant och Behandlas",
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.answerOptions.YesAndNo'), // "Ja och Nej",
                                value: '4,3',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.answerOptions.YesNoAndNotRelevant'), // "Ja, Nej och Ej relevant",
                                value: '4,3,2',
                            },
                            buildAnswerTypesOptionObject([5, 6, 7]), // Accredited/Compliant/Not Compliant,
                            buildAnswerTypesOptionObject([8, 9, 2]), // Registered/Not Registered/Not Relevant
                            buildAnswerTypesOptionObject([6, 7, 2]), // Compliant/Not Compliant/Not Relevant
                            buildAnswerTypesOptionObject([10, 11]), // Agree / Do not agree

                            buildAnswerTypesOptionObject([5, 6, 13, 7]), // Accredited / Compliant / Partly compliant / Not compliant
                            buildAnswerTypesOptionObject([6, 7]), // Compliant / Not compliant
                            buildAnswerTypesOptionObject([14, 15]), // Approved / Not approved
                            buildAnswerTypesOptionObject([5, 15, 2]), // Accredited / Not approved
                            buildAnswerTypesOptionObject([2, 16, 7, 6]), // Not relevant, no time, not compliant, compliant
                            buildAnswerTypesOptionObject([4, 20, 3]), // Yes, Partly, No
                            buildAnswerTypesOptionObject([4, 3, 21]), // Yes, No, No information available
                            buildAnswerTypesOptionObject([4, 3, 22]), // Yes, No, Don't know
                            buildAnswerTypesOptionObject([4, 20, 3, 2]), // Yes, Partly, No, Not relevant
                            buildAnswerTypesOptionObject([23, 6, 7]), // Certified/Compliant/Not Compliant
                            buildAnswerTypesOptionObject([24, 25, 26]), // Low risk, medium risk, high risk
                            buildAnswerTypesOptionObject([6, 13, 7]), // Compliant, Partly compliant, Not compliant
                            buildAnswerTypesOptionObject([6, 13, 7, 2]), // Compliant, Partly compliant, Not compliant, Not relevant
                            buildAnswerTypesOptionObject([4, 3, 2, 22]), // Yes, No, Not relevant, Don't know
                            buildAnswerTypesOptionObject([4, 27, 3]), // Yes, In Progress, No
                            buildAnswerTypesOptionObject([4, 27, 3, 2]), // Yes, In Progress, No, Not Relevant
                            buildAnswerTypesOptionObject([4, 20, 3, 2, 22]), // Yes, Partly, No, Not relevant, Don't know
                        ],
                    },
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.label_onAnswer'), // "Information som kan bifogas på svaret",
                        settingKind: 'objectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                            },
                            {
                                name: $translate.instant('Activities'),
                                value: '15',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                                value: '44',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                                value: '18',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CertificateUpload'), // "Uppladdning av certifikat",
                                value: '106',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                                value: '44,18',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                                value: '44,106',
                            },
                            {
                                name: $translate.instant('Link'),
                                value: '50',
                            },
                            {
                                name: $translate.instant('Country'), // "Country",
                                value: '75',
                            },
                            {
                                name: $translate.instant('Organizations'),
                                value: '101',
                            },
                            {
                                name: $translate.instant('People'),
                                value: '112',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.pickerSettings.sourceItem.optionInObjectTypesSelector'),
                                value: '71',
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()

                    // calculateFulfillment();
                },
            }

            Array.prototype.push.apply(questionObjectSettings.settings, getObjectSettingForPickerListAttachmentOnAnswer(11))

            return questionObjectSettings
        }

        function getMeasureObjectSettings(isPackageOwned) {
            const measureLockSettingDisplay = {
                label: () => $translate.instant('modules.valueChain.objectSettings.measureSettings.lockPeriod.label'),
                value: (value) => {
                    if (!value || !value.lockPeriod) return

                    const dates = value.lockPeriod.split('|')
                    const periodName = wfMeasureService.formatPeriodNameFromPeriodItem({
                        frequency: value.frequency,
                        //year: !dates[1] ? value.lockPeriod : undefined,
                        startDate: dates[0],
                        endDate: dates[1],
                        intervalNameSpecification: value.intervalNameSpecification,
                    })
                    return periodName
                },
            }

            const measureObjectSettings = {
                uiMode: enums.uiMode.admin,
                forOrganization: isPackageOwned ? !isPackageOwned : true,
                settings: [
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.measurePeriodSettings.label'),
                        settingKind: 'measurePeriodSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('calendarFrequency.yearly'),
                                checkValue(value) {
                                    return !value || !value.frequency || value.frequency === enums.calendarFrequency.yearly
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.ranges = null
                                    delete value.lockPeriod
                                    value.frequency = enums.calendarFrequency.yearly
                                    return value
                                },
                                additionalWhenSelected: {
                                    template: `<wf-measure-period-lock-setting form='form' value='form.value' frequency='${enums.calendarFrequency.yearly}'></wf-measure-period-lock-setting>`,
                                    display: measureLockSettingDisplay,
                                },
                            },
                            {
                                name: $translate.instant('calendarFrequency.halfYearly'),
                                checkValue(value) {
                                    return value && value.frequency === enums.calendarFrequency.halfYearly
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.ranges = null
                                    delete value.lockPeriod
                                    value.frequency = enums.calendarFrequency.halfYearly
                                    return value
                                },
                                additionalWhenSelected: {
                                    template: `<wf-measure-period-lock-setting form='form' value='form.value' frequency='${enums.calendarFrequency.halfYearly}'></wf-measure-period-lock-setting>`,
                                    display: measureLockSettingDisplay,
                                },
                            },
                            {
                                name: $translate.instant('calendarFrequency.quarterly'),
                                checkValue(value) {
                                    return value && value.frequency === enums.calendarFrequency.quarterly
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.ranges = null
                                    delete value.lockPeriod
                                    value.frequency = enums.calendarFrequency.quarterly
                                    return value
                                },
                                additionalWhenSelected: {
                                    template: `<wf-measure-period-lock-setting form='form' value='form.value' frequency='${enums.calendarFrequency.quarterly}'></wf-measure-period-lock-setting>`,
                                    display: measureLockSettingDisplay,
                                },
                            },
                            {
                                name: $translate.instant('calendarFrequency.monthly'),
                                checkValue(value) {
                                    return value && value.frequency === enums.calendarFrequency.monthly
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.ranges = null
                                    delete value.lockPeriod
                                    value.frequency = enums.calendarFrequency.monthly
                                    return value
                                },
                                additionalWhenSelected: {
                                    template: `<wf-measure-period-lock-setting form='form' value='form.value' frequency='${enums.calendarFrequency.monthly}'></wf-measure-period-lock-setting>`,
                                    display: measureLockSettingDisplay,
                                },
                            },
                            {
                                name: $translate.instant('calendarFrequency.custom'),
                                checkValue(value) {
                                    return value && value.frequency === enums.calendarFrequency.custom
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    delete value.lockPeriod
                                    value.frequency = enums.calendarFrequency.custom
                                    return value
                                },
                                validate(value) {
                                    if (!value.ranges || !value.ranges.length) {
                                        modal.alert({
                                            // title: translate("measurePeriod.intervalsMissingModal.title"),
                                            message: translate('measurePeriod.intervalsMissingModal.message'),
                                            type: 'info',
                                        })
                                        return false
                                    }
                                    else return true
                                },
                                additionalWhenSelected: {
                                    template: '<wf-measure-period-settings-editor form=\'form\' value=\'form.value\'></wf-measure-period-settings-editor>',
                                    display: measureLockSettingDisplay,
                                },
                            },
                        ],
                    },
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.measureSettings.unitSelector.label'),
                        settingKind: 'measurePeriodSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('modules.valueChain.objectSettings.measureSettings.unitSelector.selectorDisabled'),
                                checkValue(value) {
                                    return !value || !value.showUnitSelector
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.showUnitSelector = false
                                    return value
                                },
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.measureSettings.unitSelector.selectorEnabled'),
                                checkValue(value) {
                                    return value && value.showUnitSelector
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.showUnitSelector = true
                                    return value

                                },
                            },
                        ],
                    },
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.measureSettings.notAvailableCheckbox.label'),
                        settingKind: 'measurePeriodSettings',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('modules.valueChain.objectSettings.measureSettings.notAvailableCheckbox.disabled'),
                                checkValue(value) {
                                    return !value || !value.showNotAvailableCheckbox
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.showNotAvailableCheckbox = false
                                    return value
                                },
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.measureSettings.notAvailableCheckbox.enabled'),
                                checkValue(value) {
                                    return value && value.showNotAvailableCheckbox
                                },
                                setValue(value) {
                                    if (!value) value = {}
                                    value.showNotAvailableCheckbox = true
                                    return value

                                },
                            },
                        ],
                    },
                    {
                        label: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.label'), // "Information som kan bifogas",
                        settingKind: 'objectTypes',
                        options: [
                            {
                                whenNotSet: true,
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.NoInformation'), // "Ingen information",
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.Comment'), // "Kommentar",
                                value: '44',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.FileUpload'), // "Uppladdning av fil",
                                value: '18',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CertificateUpload'), // "Uppladdning av certifikat",
                                value: '106',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndFileUpload'), // "Kommentar och Uppladdning av fil",
                                value: '44,18',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.informationThatCanBeAdded.CommentAndCertificates'), // "Kommentar och Uppladdning av certifikat",
                                value: '44,106',
                            },
                            {
                                name: $translate.instant('Link'),
                                value: '50',
                            },
                            {
                                name: $translate.instant('Country'), // "Country",
                                value: '75',
                            },
                            {
                                name: $translate.instant('Organizations'),
                                value: '101',
                            },
                            {
                                name: $translate.instant('People'),
                                value: '112',
                            },
                            {
                                name: $translate.instant('modules.valueChain.objectSettings.pickerSettings.sourceItem.optionInObjectTypesSelector'),
                                value: '71',
                            },
                        ],
                    },
                ],
                onUpdated(updatedSettings) {
                    $timeout()
                },
            }

            Array.prototype.push.apply(measureObjectSettings.settings, getObjectSettingForPickerListAttachmentOnAnswer(10))

            return measureObjectSettings
        }

        function getObjectSettingForPickerListAttachmentOnAnswer(conditionIndex) {
            return [
                {
                    // pickerSettings: source item
                    condition: {
                        key: 'objectTypes',
                        value: [enums.objectType.structure],
                        index: conditionIndex,
                    },
                    label: $translate.instant('modules.valueChain.objectSettings.pickerSettings.sourceItem.label'),
                    settingKind: 'pickerSettings',
                    customControl: {
                        onInit(form) {
                            if (typeof _.get(form.value, 'sourceItem') === 'string') {
                                form.loading = true
                                dataOps.getObject(form.value.sourceItem).then((res) => {
                                    form.selectedStructure = res
                                    form.loading = false
                                    $timeout()
                                })
                            }

                            form.openPicker = function () {
                                return $q((resolve, reject) => {
                                    modal.openCreatorAndPicker({
                                        title: $translate.instant('modules.valueChain.objectSettings.pickerSettings.sourceItem.pickerHeader'),
                                        singlePick: true,
                                        relationBucket: {
                                            preSelected: form.selectedStructure ? [form.selectedStructure] : [],
                                            allSelected: form.selectedStructure ? [form.selectedStructure] : [],
                                        },
                                        sourceItem: '71-203',
                                    }).closed((relationBucketResult) => {
                                        if (relationBucketResult.allSelected.length) {
                                            form.selectedStructure = relationBucketResult.allSelected[0]
                                            form.value.sourceItem = form.selectedStructure.wfid
                                            form.onChanged(form.value)
                                        }
                                    })
                                    resolve()
                                })
                            }
                        },
                        template: '<div ng-init="form.onInit(form)" class="well well-sm well-hollow clearfix mb20" ng-class="{ \'loader-small\': form.loading }" style="min-height:62px;padding-bottom:3px"><div ng-show="form.selectedStructure" ng-bind="form.selectedStructure.title" class="mr10 mb10 pull-left" style="height:34px; cursor: default; border-left: 3px solid #85c1e9; border-radius:3px; padding:9px 16px 10px 13px; max-width: 100%; white-space: normal;box-shadow:inset 0 0px 0px 1px rgba(0,0,0,0.08);font-weight:500"></div><div wf-click="form.openPicker()" class="btn btn-default pull-left mb10" ng-show="!form.loading"><i class="fa fa-cog pr10"></i><span ng-bind="(form.selectedStructure ? \'Change\' : \'Choose\') | translate"></span></div></div>',
                    },
                },
                {
                    // pickerSettings: single/multi pick
                    condition: {
                        key: 'objectTypes',
                        value: [enums.objectType.structure],
                        index: conditionIndex,
                    },
                    label: $translate.instant('modules.valueChain.objectSettings.pickerSettings.singlePick.label'),
                    settingKind: 'pickerSettings',
                    options: [
                        {
                            whenNotSet: true,
                            name: $translate.instant('modules.valueChain.objectSettings.pickerSettings.singlePick.value.false'),
                            value: 'singlePick',
                            checkValue(value) {
                                return !value || !(this.value in value)
                            },
                            setValue(value) {
                                if (value) delete value[this.value]
                                return value
                            },
                        },
                        {
                            name: $translate.instant('modules.valueChain.objectSettings.pickerSettings.singlePick.value.true'),
                            value: { singlePick: true },
                            checkValue(value) {
                                return _.isMatch(value, this.value)
                            },
                            setValue(value) {
                                return _.assign(value, this.value)
                            },
                        },
                    ],
                },
            ]
        }
    }
})()
