import * as React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import { GOOGLE_MAPS_API_KEY } from '@worldfavor/constants'

class Map extends React.PureComponent {

  map = null

  componentDidMount() {
      const { onMapLoaded } = this.props
      onMapLoaded && onMapLoaded(this.map)
  }

  render() {
      return <GoogleMap {...this.props} ref={ref => this.map = ref} />
  }
}

const WrappedGoogleMap = withScriptjs(withGoogleMap(Map))

class GoogleMaps extends React.PureComponent {

    render() {
        const { children, containerStyle, ...rest } = this.props
        return (
            <WrappedGoogleMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={containerStyle || { height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                {...rest}
            >
                { children }
            </WrappedGoogleMap>
        )
    }
}

export default GoogleMaps
