(function() {
    'use strict'

    angular
        .module('wf.common')
        .component('wfMeasureDisplayVariant', {
            templateUrl: 'scripts/wf/visualization/wfMeasureDisplayVariant.component.html',
            controller: wfMeasureDisplayVariantController,
            controllerAs: 'vm',
            bindings: {
                measure: '<',
                measureRelation: '<',
                ticket: '<',
            },
        })

    wfMeasureDisplayVariantController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'dataQuery', 'wfAuth', '$filter']
    function wfMeasureDisplayVariantController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, dataQuery, wfAuth, $filter) {
        const
            vm = this
			
        let orderedLatestAnswers
			
        let valueElement

        _.assign(vm, {

        })

        activate()

        function activate() {
            orderedLatestAnswers = dataQuery.get.latestAnswerOnMeasure(vm.measure, {
                // networkId: networkId,
                organizationId: wfAuth.getOrganizationId(),
                take: 1,
                // contextParents: contextParentWfids
            })

            vm.latestMeasureAnswer = orderedLatestAnswers[0]

            if (vm.latestMeasureAnswer) {
                vm.value = vm.latestMeasureAnswer.value
                vm.formattedValue = numeral(vm.value).format('0,0.[00000000000000000000]')

                if (Math.abs(vm.value) >= 10000) vm.shortValue = numeral(vm.value).format('0.0a')
                else vm.shortValue = numeral(vm.value).format('0,0.[00000000000000000000]')
            }

            vm.color = _.get(vm.measureRelation, 'settings.color1') || '#333333'//(vm.hsl = [ 360 * Math.random(), (25 + 70 * Math.random()),  (50 + 10 * Math.random()) ]);
            vm.iconClass = _.get(vm.measureRelation, 'settings.iconCssClass')
            vm.imageUrl = _.get(vm.measureRelation, 'settings.imageUrl')
            vm.customText = _.get(vm.measureRelation, 'settings.customText')

            if (typeof vm.customText !== 'undefined') vm.measureText = vm.customText
            else vm.measureText = vm.measure.text

            initChartConfig()

            valueElement = $element.find('div.value')
            valueElement.css({ opacity: 0 })

            if (elementInViewport($element[0])) {
                vm.visible = true
                setTimeout(() => {
                    valueElement.animate({ opacity: 1 }, 1000)
                }, 0)
            }
            else {
                bindScrollHandler()
                $scope.$on('$destroy', () => {
                    unbindScrollHandler()
                })
            }

        }

        let scrollableContainer
        let scrollEventHandlerBound

        function bindScrollHandler() {
            if (!scrollEventHandlerBound) {
                scrollEventHandlerBound = true

                scrollableContainer = $(window)

                scrollableContainer.on('scroll.wfMeasureDisplayVariant_' + $scope.$id, _.throttle(() => {
                    if (elementInViewport($element[0])) {
                        unbindScrollHandler()

                        vm.visible = true
                        $timeout()
                        valueElement.animate({ opacity: 1 }, 1000)
                    }
                }, 300))
            }
        }

        function unbindScrollHandler() {
            scrollEventHandlerBound = false
            if (scrollableContainer) scrollableContainer.off('scroll.wfMeasureDisplayVariant_' + $scope.$id)
        }

        function elementInViewport(el) {
            let top = el.offsetTop
            let left = el.offsetLeft
            const width = el.offsetWidth
            const height = el.offsetHeight

            while (el.offsetParent) {
                el = el.offsetParent
                top += el.offsetTop
                left += el.offsetLeft
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
				left < (window.pageXOffset + window.innerWidth) &&
				(top + height) > window.pageYOffset &&
				(left + width) > window.pageXOffset
            )
        }

        function hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
            hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                return r + r + g + g + b + b
            })

            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            } : null
        }

        // Takes degree, percentage, percentage and returns css hex color:
        // ES6
        // function hslToHex(h, s, l) {
        // 	h /= 360;
        // 	s /= 100;
        // 	l /= 100;
        // 	var r, g, b;
        // 	if (s === 0) {
        // 		r = g = b = l; // achromatic
        // 	}
        // 	else {
        // 		var hue2rgb = function (p, q, t) {
        // 		if (t < 0) t += 1;
        // 		if (t > 1) t -= 1;
        // 		if (t < 1 / 6) return p + (q - p) * 6 * t;
        // 		if (t < 1 / 2) return q;
        // 		if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        // 		return p;
        // 		};
        // 		var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        // 		var p = 2 * l - q;
        // 		r = hue2rgb(p, q, h + 1 / 3);
        // 		g = hue2rgb(p, q, h);
        // 		b = hue2rgb(p, q, h - 1 / 3);
        // 	}
        // 	var toHex = x => {
        // 		var hex = Math.round(x * 255).toString(16);
        // 		return hex.length === 1 ? '0' + hex : hex;
        // 	};
        // 	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
        // }

        function initChartConfig() {
            // console.log("rgba(" + hslToRgb(vm.hsl[0] / 100.0, vm.hsl[1] / 100.0, vm.hsl[2] / 100.0, 0.9).join(",") + ")");
            let hex
            if (vm.color.indexOf('#') === 0) hex = vm.color
            // else
            // 	hex = hslToHex(vm.hsl[0], vm.hsl[1], vm.hsl[2]);

            const rgb = hexToRgb(hex)

            vm.chartDataPrototype = {
                fill: false,
                lineTension: 0.2,
                backgroundColor: 'rgba(' + [rgb.r, rgb.g, rgb.b, 0.12].join(',') + ')',
                borderColor: vm.color,
                borderCapStyle: 'round',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                borderWidth: 4,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointBorderWidth: 0,
                pointHoverRadius: 1,
                pointHoverBackgroundColor: vm.color,
                pointHoverBorderColor: vm.color,
                pointHoverBorderWidth: 0,
                pointRadius: 1,
                pointHitRadius: 10,
            }

            vm.chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 4000,
                },
                scales: {
                    yAxes: [{
                        display: false,
                        ticks: {
                            min: 0,
                        },
                    }],
                    xAxes: [{
                        display: false,
                        gridLines: {
                            drawTicks: true,
                            drawOnChartArea: false,
                            zeroLineColor: 'rgba(0,0,0,0.0)',
                            tickMarkLength: 8,
                            color: 'rgba(0,0,0,0.0)',
                        },
                        ticks: {
                            autoSkip: false,
                            maxRotation: 0,
                            fontSize: 12,
                            fontWeight: 400,
                            padding: 5,
                            fontColor: 'rgba(255,255,255,0.6)',
                        },
                    }],
                },
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)',
                    },
                },
                layout: {
                    padding: {
                        top: 10,
                        left: 10,
                        right: 10,
                        bottom: 10,
                    },
                },
                tooltips: {
                    enabled: false,
                    // custom: function(tooltipModel) {
                    // 	// Tooltip Element
                    // 	var tooltipEl = $element.find(('.chartjs-tooltip'))[0];

                    // 	// Create element on first render
                    // 	if (!tooltipEl) {
                    // 		tooltipEl = document.createElement('div');
                    // 		tooltipEl.id = 'chartjs-tooltip';
                    // 		tooltipEl.innerHTML = "<table></table>";
                    // 		$element.append(tooltipEl);
                    // 	}

                    // 	// Hide if no tooltip
                    // 	if (tooltipModel.opacity === 0) {
                    // 		tooltipEl.style.opacity = 0;
                    // 		return;
                    // 	}

                    // 	// Set caret Position
                    // 	tooltipEl.classList.remove('above', 'below', 'no-transform');
                    // 	if (tooltipModel.yAlign) {
                    // 		tooltipEl.classList.add(tooltipModel.yAlign);
                    // 	} else {
                    // 		tooltipEl.classList.add('no-transform');
                    // 	}

                    // 	function getBody(bodyItem) {
                    // 		return bodyItem.lines;
                    // 	}

                    // 	// Set Text
                    // 	if (tooltipModel.body) {
                    // 		var titleLines = tooltipModel.title || [];
                    // 		var bodyLines = tooltipModel.body.map(getBody);

                    // 		var innerHtml = '<thead>';

                    // 		titleLines.forEach(function(title) {
                    // 			innerHtml += '<tr><th>' + title + '</th></tr>';
                    // 		});
                    // 		innerHtml += '</thead><tbody>';

                    // 		bodyLines.forEach(function(body, i) {
                    // 			var colors = tooltipModel.labelColors[i];
                    // 			var style = 'background:' + colors.backgroundColor;
                    // 			style += '; border-color:' + colors.borderColor;
                    // 			style += '; border-width: 2px';
                    // 			var span = '<span style="' + style + '"></span>';
                    // 			innerHtml += '<tr><td>' + span + body + '</td></tr>';
                    // 		});
                    // 		innerHtml += '</tbody>';

                    // 		var tableRoot = tooltipEl.querySelector('table');
                    // 		tableRoot.innerHTML = innerHtml;
                    // 	}

                    // 	// `this` will be the overall tooltip
                    // 	var position = this._chart.canvas.getBoundingClientRect();

                    // 	// Display, position, and set styles for font
                    // 	tooltipEl.style.opacity = 1;
                    // 	tooltipEl.style.position = 'absolute';
                    // 	tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
                    // 	tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                    // 	tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    // 	tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    // 	tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    // 	tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    // }
                },
            }
        }

        function randomizeIcon() {
            const iconArray = [
                'fa fa-compass',
                'fa fa-expand',
                'fa fa-eur',
                'fa fa-euro',
                'fa fa-gbp',
                'fa fa-usd',
                'fa fa-dollar',
                'fa fa-inr',
                'fa fa-rupee',
                'fa fa-jpy',
                'fa fa-yen',
                'fa fa-cny',
                'fa fa-krw',
                'fa fa-won',
                'fa fa-btc',
                'fa fa-bitcoin',
                'fa fa-file',
                'fa fa-thumbs-up',
                'fa fa-thumbs-down',
                'fa fa-youtube',
                'fa fa-youtube-play',
                'fa fa-dropbox',
                'fa fa-instagram',
                'fa fa-flickr',
                'fa fa-adn',
                'fa fa-bitbucket',
                'fa fa-tumblr',
                'fa fa-apple',
                'fa fa-windows',
                'fa fa-android',
                'fa fa-linux',
                'fa fa-dribbble',
                'fa fa-skype',
                'fa fa-foursquare',
                'fa fa-trello',
                'fa fa-female',
                'fa fa-male',
                'fa fa-archive',
                'fa fa-bug',
                'fa fa-vk',
                'fa fa-weibo',
                'fa fa-renren',
                'fa fa-adjust',
                'fa fa-anchor',
                'fa fa-archive',
                'fa fa-asterisk',
                'fa fa-barcode',
                'fa fa-beer',
                'fa fa-bell',
                'fa fa-bolt',
                'fa fa-book',
                'fa fa-briefcase',
                'fa fa-bug',

            ]

            return iconArray[Math.floor(Math.random() * (iconArray.length))]
        }
    }
})()
