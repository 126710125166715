import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .controller('FormController', FormController)

    FormController.$inject = ['$translate', '$uibModalInstance', 'dataOperationContext', 'wfTranslate']

    function FormController($translate, $uibModalInstance, dataOperationContext, wfTranslate) {

        /* jshint validthis:true */
        const vm = this

        _.assign(vm, {
            submit,
            schema: {},
            form: [],
            dataOperationContext,
            model: angular.copy(dataOperationContext.item),
            submitButtonClick,
            isMultilingualForm: false,
            multilingualForms: [],
            objectType: dataOperationContext.item.type,
            simplifyForm: dataOperationContext.simplifyForm,
            influence: dataOperationContext.influence,
            topItem: dataOperationContext.topItem,
            replaceFormSpecification: dataOperationContext.replaceFormSpecification,
            customFormSpecification: dataOperationContext.customFormSpecification,
            isCreate: dataOperationContext.isCreate,
            intersectionSettings: dataOperationContext.intersectionSettings,
            showAttachInformation: dataOperationContext.showAttachInformation,
            showSubmitButton: dataOperationContext.showSubmitButton !== false,
            submitButtonCssClass: dataOperationContext.submitButtonCssClass || 'btn-primary',
            formControl: {},
            config: { focus: dataOperationContext.focus !== false },
            wording: dataOperationContext.wording,
            typeOptionsByProperty: dataOperationContext.typeOptionsByProperty,
            formFieldTitlesByProperty: dataOperationContext.formFieldTitlesByProperty,
            requiredFields: dataOperationContext.requiredFields,
            limitLanguages: dataOperationContext.limitLanguages,
            requireAllLanguages: dataOperationContext.requireAllLanguages,
            description: dataOperationContext.description,
        })

        activate()

        function activate() {
            vm.loading = true

            dataOperationContext.formControl = vm.formControl

            if (dataOperationContext.item.preSelectedFile) vm.model.preSelectedFile = dataOperationContext.item.preSelectedFile

            let editorType = $translate.instant('modules.editor.edit') //Set initial form title as Edit
            let objectTypeWord = wfTranslate.instant('MAP_ObjectType', { type: dataOperationContext.item.type }) || '' //set object type for form title

            if (objectTypeWord && dataOperationContext.item.type !== enums.objectType.sustainabilityRoom) objectTypeWord = objectTypeWord.toLowerCase()

            if (dataOperationContext.item.type == enums.objectType.structure)  // Temporarily remove object type from title if object is a structure
                objectTypeWord = null

            if (dataOperationContext.objectTypeWord) objectTypeWord = dataOperationContext.objectTypeWord

            if (vm.dataOperationContext.isCreate) { //change form title if the purpose is to create an object
                editorType = $translate.instant('modules.editor.create')
            }

            if (dataOperationContext.titleTranslate) {
                vm.title = wfTranslate.instant(dataOperationContext.titleTranslate)
            }
            else {
                vm.title =  dataOperationContext.title || $translate.instant('modules.editor.title', { objecttypeword: objectTypeWord, editortype: editorType })
            }

            vm.submitCaption = dataOperationContext.submitCaption || (vm.dataOperationContext.isCreate ? $translate.instant('Create') : $translate.instant('Save'))
        }

        function submit(form) {
            const
                jqDf = $.Deferred()
				
            let result
				
            const triggerSubmit = function () {
                vm.formControl.submit((model, deferred) => {

                    // The dataOperationContext.saved function is in modal.service.js > editor()
                    dataOperationContext.saved(model).then((res) => {
                        // If the request returns http status 200 OK but with no result
                        // then res will be undefined. The resolved result needs to be defined
                        // so that the form will close.
                        result = res || {}
                        deferred.resolve(result) // .then is in wfForm.directive.js > submit() > innerPromise.then(function (savedItem) { ...
                    }).fail((errorResult) => {
                        deferred.reject(errorResult)
                    })
                }).then((valid) => {
                    if (valid) {
                        $uibModalInstance.valid = true
                        $uibModalInstance.close()
                        dataOperationContext.modalDeferred.resolve(result)
                    }
                    else jqDf.resolve(false)
                })
            }

            if (dataOperationContext.onBeforeSubmitTriggered) {
                if (vm.formControl.isValid()) {
                    dataOperationContext.onBeforeSubmitTriggered({
                        getModel() {
                            return vm.formControl.getModel()
                        },
                        closeModal() {
                            // jqDf.resolve(false);
                            $uibModalInstance.close()
                        },
                        setResultAndCloseModal(result) {
                            $uibModalInstance.valid = true
                            $uibModalInstance.close()
                            dataOperationContext.modalDeferred.resolve(result)
                        },
                        continueSubmit() {
                            triggerSubmit()
                        },
                        setModel(newModel) {
                            vm.formControl.setModel(newModel)
                        },
                        cancelSubmit() {
                            jqDf.resolve(false)
                        },
                    })
                }
                else jqDf.resolve(false)
            }
            else {
                triggerSubmit()
            }

            return jqDf.promise()
        }

        function submitButtonClick(e) {
            return submit()
        }
    }
})()
