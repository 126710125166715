import React from 'react'
import { react2angular } from 'react2angular'
import QuestionBox from '@worldfavor/components/Items/Questions/QuestionBox'
import { WrapProvider } from './providers'

const props = [
    'questions',
    'statistics',
    'style',
    'forceShowPercentage',
    'percentagePerAnswerTypePerQuestion',
    'totalPercentagePerAnswerType',
    'single',
    'valueColor',
]

class QuestionBoxWrapper extends React.Component {

  prepareProps = (props) => {
      return {
          ...props,
          questions: (props.questions || []),
          statistics: {
              percentagePerAnswerTypePerQuestion: props.percentagePerAnswerTypePerQuestion,
              totalPercentagePerAnswerType: props.totalPercentagePerAnswerType,
          },
          style: {
              ...props.style,
              maxHeight: 'none',
          },
      }
  }

  render() {
      const { wfAuth, ...rest } = this.props
      return (
          <WrapProvider wfAuth={wfAuth}>
              <QuestionBox {...this.prepareProps(rest)} />
          </WrapProvider>
      )
  }
}

export default react2angular(QuestionBoxWrapper, props, ['wfAuth'])
