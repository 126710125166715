//@flow
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Colors, { hexToRgbA } from '@worldfavor/constants/colors'
import CheckCircle from '@material-ui/icons/CheckCircle'

type Props = {
  items: Array<{ id: string, value: any }>,
  onSelect: (event, any | Array<any>) => void,
  ItemSeparatorComponent?: React.ComponentType<any>,
}

const styles = {
    itemContainer: {
        position: 'relative',
        cursor: 'pointer',
    },
    overlay: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: hexToRgbA(Colors.blueDark, 0.25),
        border: `1px solid ${Colors.blueDark}`,
        boxSizing: 'border-box',
    },
    overlayIcon: {
        top: 8,
        right: 8,
        position: 'absolute',
        color: Colors.blueDark,
        fontSize: 20,
    },
}

const PickerRow = ({ index, children, onClick, ...rest }) => {
    function _onClick(event) {
        onClick(event, index)
    }

    return (
        <div onClick={_onClick} {...rest}>
            { children }
        </div>
    )
}

PickerRow.defaultProps = {
    onClick: () => {},
}

const PickerList = ({
    classes,
    items,
    selectedIds,
    renderItem,
    keyExtractor,
    multiselect,
    onSelect,
    ItemSeparatorComponent,
}: Props) => {

    function toggleSelectItem(event, selectedIndex) {
        const selectedId = items[selectedIndex].id

        if (multiselect) {
            let newSelectedIds = [...selectedIds]
            if (newSelectedIds.includes(selectedId)) {
                newSelectedIds = newSelectedIds.filter(id => id !== selectedId)
            }
            else {
                newSelectedIds.push(selectedId)
            }
            onSelect(event, newSelectedIds)
        }
        else {
            onSelect(event, selectedIds.includes(selectedId) ? null : selectedId)
        }
    }

    return (
        <React.Fragment>
            {
                items.map(({ id, value }, index) => {
                    const selected = multiselect ? selectedIds.includes(id) : selectedIds[0] === id
                    return (
                        <React.Fragment key={keyExtractor(value, id, index)}>
                            { index > 0 && ItemSeparatorComponent && <ItemSeparatorComponent />}
                            <PickerRow
                                index={index}
                                onClick={toggleSelectItem}
                                className={classes.itemContainer}
                            >
                                {renderItem({ item: value, index, selected })}
                                {
                                    selected && (
                                        <React.Fragment>
                                            <div className={classes.overlay} />
                                            <CheckCircle className={classes.overlayIcon} size={'small'} />
                                        </React.Fragment>
                                    )
                                }
                            </PickerRow>
                        </React.Fragment>
                    )
                })
        
            }
        </React.Fragment>
    )
}

PickerList.defaultProps = {
    keyExtractor: item => item.key,
    selectedIds: [],
    onSelect: () => {},
}

export default withStyles(styles)(PickerList)
