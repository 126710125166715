//@flow
import React from 'react'
import { MenuItem as MuiMenuItem } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles'
import MuiSwitch from '@material-ui/core/Switch'
import Colors from '@worldfavor/constants/colors'

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: 6,
        padding: 15,
        fontSize: theme.typography.fontSizes.larger,
        '&:focus': {
            outline: 'none',
        },
    },
    optionsSection: {
        padding: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    option: {
        fontSize: theme.typography.fontSizes.medium,
    },
    description: {
        fontSize: theme.typography.fontSizes.default,
        color: Colors.grayText,
    },
    dropdownWindow: {
        position: 'absolute',
        margin: 5,
        minWidth: 340,
    },
}))

const MenuItem = (props) => {
    const { dataCy, label, description, action, checked, disabled } = props.option
    const classes = useStyles(props)

    const Switch = (props) => {
        const { switchCheck } = props

        return (
            <MuiSwitch
                checked={switchCheck}
                value="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        )
    }

    function onMenuClick() {
        action()
    }
    return (
        <MuiMenuItem
            onClick={onMenuClick}
            disabled={disabled}
            data-cy={dataCy}
        >
            <div className={classes.optionsSection}>
                <div>
                    <div className={classes.option}>{label}</div>
                    <div className={classes.description}>{description}</div>
                </div>
                <div>
                    <Switch switchCheck={checked} />
                </div>
            </div>
        </MuiMenuItem>
    )
}

type Props = {
  menuOptions: Array<Object>,
  anchorEl: HTMLElement,
  close: () => void,
  menuTitle?: string,
  anchorOrigin: object,
  transformOrigin: object
}

const SwitchesMenu = (props: Props) => {
    const { menuOptions, anchorEl, close, menuTitle, anchorOrigin, transformOrigin } = props
    const classes = useStyles(props)
    return (
        <Menu
            classes={{ paper: classes.dropdownWindow }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={close}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            getContentAnchorEl={null}
        >
            <div data-cy={'table-settings-window'}>
                { menuTitle && <div className={classes.title}>{menuTitle}</div> }
                {
                    menuOptions && menuOptions.length > 0 && (
                        menuOptions.map((option, index) => {
                            return (
                                <MenuItem
                                    key={`${option.label}-${index}`}
                                    option={option}
                                />
                            )
                        })
                    )
                }
            </div>
        </Menu>
    )
}

export default SwitchesMenu
