import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwitchesMenu from '../SwitchesMenu/SwitchesMenu'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'

const useStyles = makeStyles(theme => ({
    tableSettingsIcon: {
        fontSize: theme.typography.fontSizes.medium,
    },
}))

const DataManagerTableSettings = (props) => {
    const classes = useStyles()
    const { settings, setSettings } = props
    const { expandCells, wordWrap, stickyHeader, columnResizing, enableDenseRows } = settings
    const [anchorEl, openTableSettings, closeTableSettings] = useMenuState(null)

    const switchesMenuOptions = [
        {
            dataCy: 'dense-table',
            label: <FormattedMessage id="dataManager.denseTable" />,
            description: <FormattedMessage id="dataManager.denseTableDescription" />,
            action: toggleDenseRows,
            checked: enableDenseRows,
        },
        {
            dataCy: 'column-resizer',
            label: <FormattedMessage id="dataManager.columnResizer" />,
            description: <FormattedMessage id="dataManager.columnResizerDescription" />,
            action: toggleColumnResizer,
            checked: columnResizing,
        },
        {
            dataCy: 'sticky-header',
            label: <FormattedMessage id="dataManager.stickyHeader" />,
            description: <FormattedMessage id="dataManager.stickyHeaderDescription" />,
            action: toggleStickyHeader,
            checked: stickyHeader,
        },
        {
            dataCy: 'expand-cells',
            label: <FormattedMessage id="dataManager.expandCells" />,
            description: <FormattedMessage id="dataManager.expandCellsDescription" />,
            action: toggleExpandCells,
            checked: expandCells,
        },
        {
            dataCy: 'word-wrap',
            label: <FormattedMessage id="dataManager.wordWrap" />,
            description: <FormattedMessage id="dataManager.wordWrapDescription" />,
            action: toggleWordWrap,
            checked: wordWrap,
        },
    ]

    function toggleColumnResizer() {
        expandCells && setSettings({ ...settings, expandCells: false })
        setSettings({ ...settings, columnResizing: !columnResizing })
    }

    function toggleDenseRows() {
        setSettings({ ...settings, enableDenseRows: !enableDenseRows })
    }

    function toggleStickyHeader() {
        setSettings({ ...settings, stickyHeader: !stickyHeader })
    }

    function toggleExpandCells() {
        columnResizing && setSettings({ ...settings, columnResizing: false })
        setSettings({ ...settings, expandCells: !expandCells })
    }

    function toggleWordWrap() {
        setSettings({ ...settings, wordWrap: !wordWrap })
    }

    return (
        <>
            <IconButton
                data-cy="table-settings-button"
                aria-label="TableSettings"
                onClick={openTableSettings}
            >
                <i className={classNames(classes.tableSettingsIcon, 'fas fa-cog')} />
            </IconButton>
            <SwitchesMenu
                menuOptions={switchesMenuOptions}
                menuTitle={<FormattedMessage id="dataExplorer.tableSettings" />}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                close={closeTableSettings}
            />
        </>
    )
}

export default DataManagerTableSettings
