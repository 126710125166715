// @flow
import * as React from 'react'
import { createPortal } from 'react-dom'
import type { Marker as LeafletMarker } from 'leaflet'
import { DivIcon, marker } from 'leaflet'
import { LeafletProvider, MapLayer, withLeaflet } from 'react-leaflet'
import type { LatLng, MapLayerProps  } from 'react-leaflet/src/types'
import { withSize } from 'react-sizeme'

type LeafletElement = LeafletMarker
type Props = {
  draggable?: boolean,
  opacity?: number,
  position: LatLng,
  zIndexOffset?: number,
} & MapLayerProps

const SizedMarker = withSize({ monitorHeight: true })(({ innerRef, ...rest }) => <div ref={innerRef} {...rest} />)
const SizedMarkerWithRef = React.forwardRef((props, ref) => <SizedMarker innerRef={ref} {...props} />)
const noop = () => {}

class CustomMarker extends MapLayer<LeafletElement, Props> {

  markerRef = null
  firstRun = true

  constructor(props) {
      super(props)
      this.leafletElement = this.createLeafletElement(props)
      this.leafletElement.on('add', () => this.forceUpdate())
      this.markerRef = React.createRef()
  }

  createLeafletElement(props: Props): LeafletElement {
      const { position, ...rest } = props

      // when not providing className, the element's background is a white square
      // when not providing iconSize, the element will be 12x12 pixels
      const icon = new DivIcon({ ...rest, className: '', iconSize: undefined })

      const el = marker(position, { icon, ...rest })
      this.contextValue = { ...props.leaflet, popupContainer: el }
      return el
  }

  updateLeafletElement(fromProps: Props, toProps: Props) {
      if (fromProps.position !== toProps.position) {
          this.leafletElement.setLatLng(toProps.position)
      }
      if (fromProps.zIndexOffset !== toProps.zIndexOffset) {
          this.leafletElement.setZIndexOffset(toProps.zIndexOffset)
      }
      if (fromProps.opacity !== toProps.opacity) {
          this.leafletElement.setOpacity(toProps.opacity)
      }
      if (fromProps.draggable !== toProps.draggable) {
          if (toProps.draggable) {
              this.leafletElement.dragging.enable()
          } else {
              this.leafletElement.dragging.disable()
          }
      }
      if (fromProps.className !== toProps.className) {
          const fromClasses = fromProps.className.split(' ')
          const toClasses = toProps.className.split(' ')
          this.leafletElement._icon.classList.remove(
              ...fromClasses.filter(className => !toClasses.includes(className)),
          )
          this.leafletElement._icon.classList.add(
              ...toClasses.filter(className => !fromClasses.includes(className)),
          )
      }
      if (fromProps.iconAnchor !== toProps.iconAnchor) {
          this.setIconAnchor(toProps.iconAnchor)
      }
      if (fromProps.placement !== toProps.placement) {
          this.setMarkerAnchor()
      }
  }

  setMarkerAnchor = () => {
      if (!this.markerRef || !this.markerRef.current) {
          return
      }

      const markerElem = this.markerRef.current
      const markerWidth = markerElem.offsetWidth
      const markerHeight = markerElem.offsetHeight
      this.setIconAnchor(CustomMarker.getAnchorCoordinates(markerWidth, markerHeight, this.props.placement))
  }

  setIconAnchor = (iconAnchor) => {
      const container = this.leafletElement._icon
      container.style.marginLeft = -iconAnchor[0] + 'px'
      container.style.marginTop = -iconAnchor[1] + 'px'
  }

  static getAnchorCoordinates(width, height, anchorPosition) {
      const placements = {
          top: [width / 2, 0],
          bottom: [width / 2, height],
          left: [0, height / 2],
          right: [width, height / 2],
          'top-start': [0, 0],
          'top-end': [width, 0],
          'bottom-start': [0, height],
          'bottom-end': [width, height],
          'left-start': [0, 0],
          'left-end': [0, height],
          'right-start': [width, 0],
          'right-end': [width, height],
      }
      return placements[anchorPosition] || [0, 0]
  }

  componentDidUpdate(fromProps: Props) {
      this.updateLeafletElement(fromProps, this.props)
  }

  onMarkerSize = ({ width, height }) => {
      const { placement, autoAnchor } = this.props
      if (width === 0 && height === 0) {
          return
      }

      if (autoAnchor || this.firstRun) {
          this.setIconAnchor(CustomMarker.getAnchorCoordinates(width, height, placement))
      }

      if (this.firstRun) {
          this.firstRun = false
      }
  }

  render() {
      const { children, placement } = this.props
      const container = this.leafletElement._icon

      return (!container || !children || !this.contextValue) ? null :
          createPortal((
              <LeafletProvider value={this.contextValue}>
                  <SizedMarkerWithRef
                      ref={this.markerRef}
                      onSize={placement ? this.onMarkerSize : noop}
                  >
                      {children}
                  </SizedMarkerWithRef>
              </LeafletProvider>
          ), container)
  }
}

export default withLeaflet<Props, CustomMarker>(CustomMarker)
