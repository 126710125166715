(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfItemRelationsPane', wfItemRelationsPane)
	
    wfItemRelationsPane.$inject = []

    function wfItemRelationsPane() {
        const directive = {
            restrict: 'E',
            templateUrl: 'scripts/wf/itemRelations/wfItemRelations.pane.directive.html',
            link,
        }
		
        return directive

        function link(scope, element, attrs) {
            element.addClass('wfItemRelationsPane')
        }
    }
})()
