import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'
	
    angular
        .module('wf.common')
        .component('wfAnalyzeTotalResult', {
            templateUrl: 'scripts/wf/analyze/result/wfAnalyzeTotalResult.component.html',
            require: { wfAnalyzeJob: '?^^wfAnalyzeJob' },
            controller: wfAnalyzeTotalResultController,
            controllerAs: 'vm',
            bindings: {
                ticket: '<',
                item: '<',
            },
        })

    wfAnalyzeTotalResultController.$inject = ['$scope', '$timeout', '$element', '$rootScope', '$state', 'requirements', '$transclude', '$compile', '$translate', '$q', 'dataOperationsService', 'wfObject', 'statisticsService', 'analyzeService', 'apiProxy']
    function wfAnalyzeTotalResultController($scope, $timeout, $element, $rootScope, $state, requirementService, $transclude, $compile, $translate, $q, dataOps, wfObject, statisticsService, analyzeService, apiProxy) {
        const
            vm = this
			
        let wfAnalyzeJobCtrl
			
        let mainItem = vm.item
			
        let dataRelation
			
        let itemContent
			
        let actualObjectType
			
        let availableRequirementOptions
			
        const requirementUiMode = enums.uiMode.admin
			
        let structurePickerSourceWfid

        this.$onInit = $onInit

        function $onInit() {
            wfAnalyzeJobCtrl = this.wfAnalyzeJob

            _.assign(vm, {
                item: undefined,
                ticket: vm.ticket || {},
                latestResult: undefined,

                calculateAnalyzeJob,
            })

            if (mainItem.composite) {
                dataRelation = mainItem.dataRelation
                itemContent = mainItem.content
                actualObjectType = mainItem.type

                mainItem = mainItem.dataRelation
            }
            else if (mainItem.isRelationalType()) {
                dataRelation = mainItem
                itemContent = mainItem.childContent
                actualObjectType = mainItem.childType
            }
            else {
                dataRelation = null
                itemContent = mainItem
                actualObjectType = mainItem.type
            }

            loadResults(mainItem, vm.ticket).then(() => {
                handleResults()
                vm.loaded = true
                $timeout()
            })

            if (wfAnalyzeJobCtrl && wfAnalyzeJobCtrl.targetOrganization) {
                vm.targetOrganization = wfAnalyzeJobCtrl.targetOrganization
            }
        }

        function loadResults(item, ticket) {
            const promise = dataOps.getObjects({
                requestSignature_noResultNeeded: ['analyze_requirements', item.wfid, ticket.organizationId, ticket.networkId, ticket.contextParentWfid].join('_'),
                objectType: enums.objectType.analyzeResult,
                bypassCache: true,
                wrapInRelations: false,
                getterConditions: {
                    objectType: item.type,
                    objectId: item.id,
                    organizationId: ticket.organizationId,
                    networkId: ticket.networkId,
                    contextParentWfid: ticket.contextParentWfid,
                },
            })

            return promise
        }

        function handleResults() {
            vm.latestResult = _.find(mainItem.analyzeResults, {
                latest: true,
                targetOrganizationId: vm.ticket.organizationId,
                networkId: vm.ticket.networkId,
                contextParentWfid: vm.ticket.contextParentWfid,
            })

            vm.results = _.chain(mainItem.analyzeResults).filter({
                targetOrganizationId: vm.ticket.organizationId,
                networkId: vm.ticket.networkId,
                contextParentWfid: vm.ticket.contextParentWfid,
            }).sortBy('createdAt').reverse().map((analyzeResult) => {
                return {
                    result: analyzeResult,
                    calculatedAt_onlyDate: moment(analyzeResult.createdAt).format('YYYY-MM-DD'),
                    calculatedAt: moment(analyzeResult.createdAt).format('YYYY-MM-DD HH:mm'),
                }
            }).value()
        }

        function calculateAnalyzeJob() {
            const promise = apiProxy('analyze.calculate', dataOps.prepareWfObject(wfAnalyzeJobCtrl.analyzeJob))

            promise.then((res) => {
                res.analyzeJob.title = wfAnalyzeJobCtrl.analyzeJob.title
                wfObject.inject(res.analyzeJob)
                wfObject.inject(res.newAnalyzeResults)
                wfObject.inject(res.oldAnalyzeResults)

                handleResults()
                $timeout()

                wfAnalyzeJobCtrl.updateAllItemsResults()
            })

            return promise
        }
    }
})()
