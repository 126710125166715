(function () {
    'use strict'

    angular
        .module('wf.common')
        .component('wfFindingStep', {
            templateUrl: 'scripts/wf/findings/wfFindingStep.component.html',
            controller: wfFindingStepController,
            controllerAs: 'vm',
            require: {
                findingVm: '^^wfFinding',
            },
            bindings: {
                ticket: '<',
                step: '<item',
            },
        })

    wfFindingStepController.$inject = ['findingService']
    function wfFindingStepController(findingService) {
        const
            vm = this

        _.assign(vm, {
            loading: false,
            openAnswerCreator,
        })

        this.$onInit = $onInit

        function $onInit() {
            _.assign(vm, {
                isAdminMode: vm.findingVm.isAdminMode,
                isWorkMode: vm.findingVm.isWorkMode,
            })

            _.assign(vm.step, {
                isLocked: false,
            })

            vm.step.checkIfStepIsUnlocked()

            // console.log("wfFindingStep - ", vm);
        }

        function openAnswerCreator() {
            if (!vm.step.isLocked) {
                findingService.openAnswerCreator({
                    finding: vm.findingVm.finding,
                    step: vm.step,
                    ticket: vm.findingVm.ticket,
                }).then((answer) => {
                    vm.step.syncAnswers()
                    vm.findingVm.syncSteps()
                    vm.findingVm.onAnswerStateChanged(answer)
                    vm.findingVm.determineNeededAction()
                })
            }
        }
    }
})()
