// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const useStyles = makeStyles({
    loader: {
        color: 'lime',
    },
})

type Props = {
  open: boolean,
  onCancel: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onSave: (event: SyntheticEvent<HTMLButtonElement>) => void,
  saving: boolean,
  activate: boolean,
  productName: string,
  supplierName: string,
}

const ProductActivationDialog = (props: Props) => {
    const { open, onCancel, onSave, saving, activate, productName, supplierName } = props
    const classes = useStyles(props)
    const titleTranslationId = `supplyChain.activateStatus.confirmation.${activate ? 'activate' : 'deactivate'}.title`
    const descriptionTranslationId = `supplyChain.activateStatus.confirmation.${activate ? 'activate' : 'deactivate'}.description`
    const saveTranslationId = `general.${activate ? 'activate' : 'deactivate'}`

    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage id={titleTranslationId} values={{ supplier: <strong>{supplierName}</strong>, product: <strong>{productName}</strong> }} />
            </DialogTitle>
            <DialogContent>
                <FormattedMessage id={descriptionTranslationId} values={{ supplier: <strong>{supplierName}</strong>, product: <strong>{productName}</strong> }} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    disabled={saving}
                >
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <CircularProgressWrapper
                    loading={saving}
                    classes={{ circle: classes.loader }}
                >
                    <Button
                        onClick={onSave}
                        disabled={saving}
                        color={activate ? 'primary' : 'secondary'}
                    >
                        <FormattedMessage id={saveTranslationId} />
                    </Button>
                </CircularProgressWrapper>
            </DialogActions>
        </Dialog>
    )
}

export default ProductActivationDialog
