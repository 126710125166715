// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

type Props = {
  children: string | React.Node,
}

const useStyles = makeStyles({
    title: {
        lineHeight: 1.2,
    },
})

const Title = (props: Props) => {
    const { children } = props
    const classes = useStyles(props)

    if (!React.isValidElement(children)) {
        return <Typography color="primary" variant="h3" classes={{ h3: classes.title }}>{children}</Typography>
    }
  
    return children
}

export default Title
