(function() {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfHierarchical', wfHierarchical)

    const templates = {
        _default: { url: 'scripts/wf/hierarchical/hierarchical.controller.html', loaded: false, request: undefined },
        publicLayout: { url: 'scripts/wf/hierarchical/hierarchicalPublicLayout.controller.html', loaded: false, request: undefined },
    }

    wfHierarchical.$inject = []
	
    function wfHierarchical() {
        const directive = {
            restrict: 'E',
            scope: {
                itemFromDirective: '=item',
                include: '<',
                override: '<', // Can be used to override hierarchical vm or headerOptions { vm: {}, headerOptions: {} }
                orgAccessAdmin: '=orgAccessAdmin',
                influenceFromDirective: '=influence',
                isPrinting: '=',
                onLoaded: '&',
                limitDepth: '<',
                transclusionOuterVm: '<',
                intersectionWfid: '<',
                showNumbering: '<',
                uiMode: '<',
                uiComponents: '<',
                consolidate: '<',
                vmPrototype: '<',
                ticket: '<',
                hooks: '<',
                hideBackButton: '<',
                // hideFilterBars: "<",
                // hidePageHeading: "<"
            },

            // Terminal is needed to prevent Angular from touching any html that might be inside <wf-list-interface></wf-list-interface>
            // that will be used for transclusion. The html is manually cloned and passed to every instance of wfListItem in ngRepeat.
            terminal: true,

            // Transclusion slots:
            // transcludeItemSide (handled manually)
            // transcludeInterfaceSide (handled manually)

            controller: ['$scope', '$element', '$attrs', '$templateCache', '$http', '$timeout', '$compile', '$parse', function ($scope, $element, $attrs, $templateCache, $http, $timeout, $compile, $parse) {
                let
                    conditions
					
                let templateSpec
					
                let transclusionChild

                // conditions = $scope.itemFromDirective.conditions;

                // if (conditions) {
                // 	if (conditions.intersectionSourceWfid) {
                // 		$scope.objectWfid = conditions.intersectionSourceWfid;
                // 		$scope.intersectionWfid = $scope.itemFromDirective.wfid;
                // 		return;
                // 	}
                // }

                const vm = this
                vm.chartLoaded = chartLoaded
                _.assign(vm, $scope)

                $scope.hierVm = {}
                $scope.openedViaDirective = true
                $scope.directiveElement = $element
                $scope.openedInModal = !!$element.closest('div.modal-dialog').length
                $scope.includeRelatedContentAsChildren = 'relatedContentAsChildren' in $attrs
                // if ("aggregate" in $attrs) {
                $scope.consolidationSettings = $scope.consolidate
                // }

                $scope.hideFilterBars = 'hideFilterBars' in $attrs
                $scope.hidePageHeading = 'hidePageHeading' in $attrs
                $scope.hideSunburstChart = 'hideSunburstChart' in $attrs
                $scope.showPdfExportButton = 'showPdfExportButton' in $attrs

                if ($scope.itemFromDirective) {
                    $scope.objectWfid = $scope.itemFromDirective.wfid
                }
                if ('useItemAsIs' in $attrs) {
                    $scope.useItemInstanceFromDirective = true
                }

                if ($element[0].childNodes.length) {
                    transclusionChild = $element.children('transclude-item-side')
                    if (transclusionChild.length) {
                        $scope.transcludeItemSide = {
                            elementClone: transclusionChild[0],
                            outerVm: $scope.transclusionOuterVm,
                        }
                    }
                    transclusionChild = $element.children('transclude-interface-side')
                    if (transclusionChild.length) {
                        $scope.transcludeInterfaceSide = {
                            elementClone: transclusionChild[0],
                            outerVm: $scope.transclusionOuterVm,
                        }
                    }

                    $element.html('')
                }

                loadTemplate()

                $timeout()

                function chartLoaded(wfid) {
                    if ($scope.hierVm.measuresToLoad.length > 0) {
                        $scope.hierVm.measuresToLoad.splice(_.findIndex($scope.hierVm.measuresToLoad, { wfid }), 1)

                        if ($scope.hierVm.measuresToLoad.length === 0) $scope.hierVm.emitHierarchicalLoaded()
                    }
                }
		
                function loadTemplate() {
                    const isPublicLayout = $attrs.hasOwnProperty('publicLayout')

                    if (isPublicLayout) templateSpec = templates.publicLayout
                    else templateSpec = templates._default

                    if (templateSpec.loaded) {
                        applyTemplate()
                        return
                    }
                    else if ($templateCache.get(templateSpec.url)) {
                        templateSpec.loaded = true
                        applyTemplate()
                        return
                    }
		
                    if (!templateSpec.request) {
                        templateSpec.request = $http.get(templateSpec.url)
                    }
		
                    templateSpec.request.then((res) => {
                        templateSpec.loaded = true
                        $templateCache.put(templateSpec.url, res.data)
                        applyTemplate()
                    })
                }
		
                function applyTemplate() {
                    const templateContent = $templateCache.get(templateSpec.url)
		
                    $element.html(templateContent)
                    $compile($element.contents())($scope)
                }
            }],
            // templateUrl: function(element, attrs) {
            // 	var isPublicLayout = attrs.hasOwnProperty('publicLayout');
            // 	if (isPublicLayout)
            // 		return 'scripts/wf/hierarchical/hierarchicalPublicLayout.controller.html';
            // 	else
            // 		return "scripts/wf/hierarchical/hierarchical.controller.html"
            // }
        }
        return directive
    }
})()
