import { useState, useMemo, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import filteringHook from '../utils/useEffect'

export default function useFilters(incomingFilters, options) {
    const _incomingFilters = useMemo(() => incomingFilters, [incomingFilters])
    const _options = useMemo(() => options, [options])

    const [filters, setFilters] = useState([])
    const [activeFilters, setActiveFilters] = useState({})
    const [canResetFilters, setCanResetFilters] = useState(false)
    const useInvertedFiltering = _options && _options.useInvertedFiltering ? _options.useInvertedFiltering : false

    useEffect(() => {
        filteringHook(_incomingFilters, useInvertedFiltering, setFilters, setActiveFilters)
    }, [incomingFilters, canResetFilters])

    function clearAllFilters() {
        setCanResetFilters(!canResetFilters)
    }

    function clearFilter(filterId) {
        const clonedFilters = cloneDeep(filters)
        const clonedActiveFilters = cloneDeep(activeFilters)
        const filterToClear = clonedFilters.find(filter => filter.id === filterId)

        filterToClear.active = false
        filterToClear.filterOptions.map(option => option.checked = useInvertedFiltering)
        delete clonedActiveFilters[filterToClear.id]

        setFilters(clonedFilters)
        setActiveFilters(clonedActiveFilters)
    }

    function onFilterChange(changedFilterOptions, filterId) {
        const clonedFilters = cloneDeep(filters) // should maybe try to find another way not to use cloneDeep (reason: setFilters will catch the change when we cloneDeep)
        const clonedActiveFilters = cloneDeep(activeFilters)
        const filterToChange = clonedFilters.find(filter => filter.id === filterId)

        if (filterToChange) {
            const { filterOptions } = filterToChange
            let selectedOptions = []

            changedFilterOptions.forEach((newOption) => {
                const optionToChange = filterOptions.find(currentOption => currentOption.value === newOption.value)
                optionToChange.checked = newOption.checked
                selectedOptions = [
                    ...new Set([...selectedOptions, ...filterOptions.filter(option => option.checked === useInvertedFiltering)]),
                ]
            })

            filterToChange.active = isAnyOptionSelected(filterOptions)
            clonedActiveFilters[filterToChange.id] = {
                id: filterToChange.id,
                labelKey: filterToChange.labelKey,
                filterType: filterToChange.filterType,
                filterValueType: filterToChange.filterValueType,
                selectedOptions,
            }

            setFilters(clonedFilters)
            setActiveFilters(clonedActiveFilters)
        }
    }

    function isAnyOptionSelected(filterOptions) {
        return Object.values(filterOptions).some(option => option.checked === !useInvertedFiltering)
    }

    return { filters, activeFilters, onFilterChange, clearFilter, clearAllFilters }
}
