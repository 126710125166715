//@flow
import React, { useState, useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { FormattedMessage, injectIntl } from 'react-intl'
import Colors from '@worldfavor/constants/colors'
import SearchBar from '@worldfavor/components/SearchBar'
import SearchList from '@worldfavor/components/List/SearchList'
import HighlightedText from '@worldfavor/components/Text/HighlightedText'
import FilterOptionsEmptyState from './FilterOptionsEmptyState'
import { MIN_FILTER_OPTIONS_TO_SHOW_SEARCH_BAR } from '@worldfavor/constants'

const useStyles = makeStyles(theme => ({
    menuPaper: {
        marginTop: theme.spacing(1),
        minWidth: 250,
    },
    menuItem: {
        paddingRight: 15,
    },
    menuItemAll: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: Colors.grayLight,
    },
    clearButtonWrapper: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    searchBarContainer: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        paddingRight: 12,
    },
}))

const useMenuItemStyles = makeStyles(theme => ({
    menuItem: {},
    checkboxContainer: {
        minWidth: 40,
        marginLeft: 5,
    },
    checkbox: {
        padding: 5,
    },
    listItemText: {
        fontSize: theme.typography.fontSizes.large,
    },
}))

type Props = {
  menuItemProps?: Object,
  menuProps?: Object,
  options: { label: string, value: string },
  invertCheckboxes: boolean,
  onChange: () => void,
}

const MenuItem = forwardRef((props, ref) => {
    const { label, match, id, checked, onClick } = props
    const classes = useMenuItemStyles(props)

    function onChange(event) {
        onClick(event, id)
    }

    return (
        <MuiMenuItem classes={{ root: classes.menuItem }} disableGutters onClick={onChange} ref={ref} dense>
            <ListItemIcon classes={{ root: classes.checkboxContainer }}>
                <Checkbox
                    disableRipple
                    checked={checked}
                    classes={{ root: classes.checkbox }}
                />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} data-cy={`filter-${label}`}>
                <HighlightedText text={label} match={match && match.search} />
            </ListItemText>
        </MuiMenuItem>
    )
})

const NewFilterMenu = (props: Props) => {
    const classes = useStyles(props)
    const { options, menuProps, menuItemProps, onChange, invertCheckboxes, intl } = props
    const [allOptionsSelected, setAllOptionsSelected] = useState(invertCheckboxes)
    const [search, setSearch] = useState('')

    function keyExtractor(item) {
        return `filter-option-row-${item.value}`
    }

    function onSearchChange(event) {
        setSearch(event.target.value)
    }

    function onSearchClear() {
        setSearch('')
    }

    useEffect(() => {
        const everyOptionChecked = isEveryOptionChecked(options)
        setAllOptionsSelected(everyOptionChecked)
    }, [options])

    function isEveryOptionChecked(options) {
        return Object.values(options).every(option => option.checked)
    }

    function toggleFilter(event, value) {
        const selectedOption = options.find(option => option.value === value)

        selectedOption.checked = !selectedOption.checked

        onChange && onChange([selectedOption])
    }

    function toggleAll() {
        const everyOptionChecked = isEveryOptionChecked(options)

        options.forEach(option => option.checked = !everyOptionChecked)

        onChange && onChange(options)
    }

    function renderItem({ item, match }) {
        const { value, label, checked } = item
        return (
            <MenuItem
                key={`menu-item-${value}`}
                label={label}
                id={value}
                onClick={toggleFilter}
                classes={{ menuItem: classes.menuItem }}
                checked={checked}
                match={match}
                {...menuItemProps}
            />
        )
    }

    return (
        <Menu
            keepMounted
            classes={{ paper: classes.menuPaper }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            getContentAnchorEl={undefined}
            data-cy={'filter-menu-window'}
            {...menuProps}
        >
            {
                (options && options.length >= MIN_FILTER_OPTIONS_TO_SHOW_SEARCH_BAR) && (
                    <div className={classes.searchBarContainer} data-cy={'filter-search-bar'}>
                        <SearchBar
                            onClear={onSearchClear}
                            rounded
                            compact
                            inputProps={{
                                value: search,
                                placeholder: intl.formatMessage({ id: 'general.search' }),
                                onChange: onSearchChange,
                            }}
                        />
                    </div>
                )
            }
            {
                options && options.length > 1 && (
                    <MenuItem
                        label={<FormattedMessage id={'general.all'} />}
                        onClick={toggleAll}
                        classes={{ menuItem: classes.menuItemAll }}
                        checked={allOptionsSelected}
                        {...menuItemProps}
                    />
                )
            }

            <SearchList
                data={options}
                searchText={search}
                filterKeys={['label']}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                ListEmptyComponent={FilterOptionsEmptyState}
                minSearchLength={2}
            />
        </Menu>
    )
}

NewFilterMenu.defaultProps = {
    invertCheckboxes: true,
}

export default injectIntl(NewFilterMenu)
