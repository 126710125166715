import React from 'react'
import Image from '@worldfavor/components/Image'
import TableElementsStyles from './TableElementsStyles'
import NewFilterDropdown from '@worldfavor/components/Filters/NewFilterDropdown'
import { ConstVars } from '@worldfavor/constants'
import { FormattedMessage } from 'react-intl'
import TooltipWithCopyToClipboard from '@worldfavor/components/Text/TooltipWithCopyToClipboard'
import _ from 'lodash'

export function getTableColumns(options) {
    const { filters, onFilterChange, clearFilter, expandCells } = options
    return [
        {
            Header: <FormattedMessage id="dataManager.organization" />,
            accessor: ConstVars.OrganizationAccessors.Name,
            width: !expandCells ? 320 : 500,
        },
        {
            Header: <FormattedMessage id="dataManager.registrationNumber" />,
            accessor: ConstVars.OrganizationAccessors.RegistrationNumber,
            width: !expandCells ? 160 : 200,
        },
        {
            Header: <FormattedMessage id="dataManager.vatNumber" />,
            accessor: ConstVars.OrganizationAccessors.VatNumber,
            width: !expandCells ? 160 : 200,
        },
        {
            Header: <FormattedMessage id="dataManager.glnNumber" />,
            accessor: ConstVars.OrganizationAccessors.GlnNumber,
            width: !expandCells ? 160 : 200,
        },
        {
            Header: <FormattedMessage id="dataManager.country" />,
            accessor: ConstVars.OrganizationAccessors.Country,
            options: {
                filter: filters && filters.find(filter => filter.id === ConstVars.OrganizationAccessors.CountryId),
                onFilterChange,
                clearFilter,
            },
            width: !expandCells ? 160 : 200,
        },
        {
            Header: <FormattedMessage id="dataManager.primaryAddress" />,
            accessor: ConstVars.OrganizationAccessors.PrimaryAddress,
            width: !expandCells ? 250 : 400,
        },
    ]
}

export function renderRowCell(value, row, column, cell) {
    const classes = TableElementsStyles()
    const imageUrl = _.get(row, 'original.imageUrl')

    if (column && column.id === ConstVars.OrganizationAccessors.Name) {
        return (
            <div className={classes.cell}>
                <Image
                    className={classes.image}
                    size={'cover'}
                    src={imageUrl}
                />
                <TooltipWithCopyToClipboard classes={{ root: classes.labelCell }} text={cell.value} />
            </div>
        )
    }
    if (column && column.id === ConstVars.OrganizationAccessors.Country) {
        return (
            <div className={classes.cell}>
                <div
                    className={`flag-icon-${cell.value.iso3166_alpha2}`}
                    style={{ minWidth: 25, minHeight: 25 / (4 / 3), borderRadius: 2, marginRight: 15 }}
                />
                <TooltipWithCopyToClipboard text={cell.value.name} />
            </div>
        )
    }
    if (column && column.id === ConstVars.OrganizationAccessors.PrimaryAddress) {
        return (
            <div className={classes.cell}>
                <a
                    href={`https://maps.google.com/?q=${cell.value}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{  overflow: 'hidden ', textOverflow: 'ellipsis' }}
                >
                    <TooltipWithCopyToClipboard text={cell.value} />
                </a>
            </div>
        )
    }
    return <div className={classes.cell}><TooltipWithCopyToClipboard text={value} /></div>
}

export function renderColumnCell(Header, id, column) {
    if (column.options) {
        const { filter, onFilterChange, clearFilter } = column.options

        if (filter && onFilterChange) {
            return (
                <NewFilterDropdown
                    filter={filter}
                    style={{ width: column.width }}
                    onChange={onFilterChange}
                    onClear={clearFilter}
                />
            )
        }
    }

    return column.Header
}
