(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('SupplierGroupController', SupplierGroupController)

    SupplierGroupController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$timeout']
    function SupplierGroupController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $timeout) {
        const vm = this
        _.assign(this, $scope.$parent.vm)
		
        $scope.modal = modal
        $scope.wfObject = wfObject
        $scope.dataQuery = dataQuery
        if (vm.context) {
            vm.context.templateId = null
			
            if (vm.context.settings) vm.context.settings.templateId = null
        }
		
        _.assign(vm, {
            categoryTypes: [],
            filteredCategoryTypes: [],
            selectedId: null,
            filterByCategoryType,
        })
		
        activate()

        ////////////////

        function activate() {
            vm.networkId = vm.item.childContent.conditions.networkId
            loadDependencies()
			
            dataOps.getSubItems(vm.item.childContent.childs[0].childContent, 1, { childrenLoadDepth: 2 }).then((res) => {
                vm.categoryTypes = res
                vm.filteredCategoryTypes = res
                $timeout(() => {})
            })
            // vm.categoryTypes
			
        }
		
        function filterByCategoryType(categoryType) {
            if (categoryType)
            {
                if (categoryType.childId == vm.selectedId) categoryType = null
            }
			
            vm.selectedId = categoryType ? categoryType.childId : null
            vm.filteredCategoryTypes = _.filter(vm.categoryTypes, vm.selectedId ? { childId: vm.selectedId } : {})
        }

        function loadDependencies() {
            dataOps.getSubItems(vm.item.childContent.childs[0].childContent, 1, { childrenLoadDepth: 2 }).then((res) => {
                vm.categoryTypes = res
                vm.filteredCategoryTypes = res
                $timeout(() => {})
            })
            // dataOps.getObject({
            // 	objectType: enums.objectType.network,
            // 	objectId: vm.networkId
            // }).then(function (network) {
            // 	vm.network = network;

            // });
        }
    }
})()
