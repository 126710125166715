function ctDynamicBarWidth(options) {
    const Chartist = require('chartist')

    return function ctDynamicBarWidth(chart) {
        const defaultOptions = {}

        options = Chartist.extend({}, defaultOptions, options)

        if (chart instanceof Chartist.Bar) {
            chart.on('draw', (data) => {
                if (data.type === 'bar') {
                    data.element._node.style['stroke-width'] = `${chart.options.barWidth}px`
                }
            })
        }
    }
}

export default ctDynamicBarWidth
