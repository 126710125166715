// @flow
import * as React from 'react'
import { getGoogleMapsLink } from '@worldfavor/utils'
import { withStyles } from '@material-ui/core/styles'

type Props = {
  lat: number,
  lng: number,

  classes: { +[string]: string },
  style?: { [string]: any }
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        color: '#bdc3c7',
        fontSize: 11,

        '& a': {
            textDecoration: 'none',
            color: '#bdc3c7',

            '&:hover': {
                color: '#3498db',
                textDecoration: 'underline',
            },
            '& i': {
                color: '#3498db',
            },
        },
    },
}

class LocationLink extends React.PureComponent<Props> {

  _prepareCoordinates = () => {
      const { lat, lng } = this.props
      return {
          lat: Math.round(lat * 10e6) / 10e6,
          lng: Math.round(lng * 10e6) / 10e6,
      }
  }

  render() {
      const { classes, style } = this.props
      const { lat, lng } = this._prepareCoordinates()
      return (
          <div className={classes.container} style={style}>
              <i style={{ marginRight: 6 }} className={'fas fa-map-marker-alt'} />
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getGoogleMapsLink(lat, lng)}
              >
                  {`${lng}, ${lng}`}
                  <i style={{ marginLeft: 6 }} className={'fas fa-external-link-alt'} />
              </a>
          </div>
      )
  }

}

export default withStyles(styles)(LocationLink)
