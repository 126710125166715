import * as React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import SearchBar from '@worldfavor/components/SearchBar'
import SearchList from '@worldfavor/components/List/SearchList'
import Divider from '@worldfavor/components/Divider'
import OrganizationRow from './List/OrganizationRow'
import EmptyOrganizationList from './List/EmptyOrganizationList'
import { injectIntl } from 'react-intl'

const useStyles = makeStyles({
    paper: {
        overflow: 'hidden',
        marginTop: 8,
        width: 300,
    },
    searchBarContainer: {
        padding: 12,
    },
})

const ITEM_HEIGHT = 74

const OrganizationMenuItem = ({ organization, match, onClick }) => {

    function _onClick(e) {
        onClick(e, organization.wfid)
    }

    return (
        <OrganizationRow
            match={match}
            organization={organization}
            onClick={_onClick}
            showGripIcon={false}
            style={{ cursor: 'pointer' }}
        />
    )
}

const ItemSeparator = () => <Divider height={1} color={'rgba(221, 221, 221, 0.5)'} />
const CompactEmptyOrganizationList = props => <EmptyOrganizationList {...props} compact />

const keyExtractor = item => `organization-row-${item.wfid}`

const OrganizationSearchMenu = (props) => {
    const { intl, organizations, onMenuItemClick, onClose, ...rest } = props
    const classes = useStyles(props)
    const [search, setSearch] = useState('')

    function onSearchChange(event) {
        setSearch(event.target.value)
    }

    function onSearchClear() {
        setSearch('')
    }

    function renderItem({ item, match }) {
        return (
            <OrganizationMenuItem
                match={match}
                organization={item}
                onClick={onMenuItemClick}
            />
        )
    }

    return (
        <Popper
            position={'bottom'}
            {...rest}
            transition
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="organization-search-menu-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={onClose}
                        >
                            <div>
                                <div className={classes.searchBarContainer}>
                                    <SearchBar
                                        onClear={onSearchClear}
                                        rounded
                                        compact
                                        inputProps={{
                                            value: search,
                                            placeholder: intl.formatMessage({ id: 'general.search' }),
                                            onChange: onSearchChange,
                                        }}
                                    />
                                </div>

                                <ItemSeparator />

                                <SearchList
                                    data={organizations}
                                    searchText={search}
                                    filterKeys={['name', 'vatNumber', 'registrationNumber']}
                                    keyExtractor={keyExtractor}
                                    renderItem={renderItem}
                                    ItemSeparatorComponent={ItemSeparator}
                                    ListEmptyComponent={CompactEmptyOrganizationList}
                                    minSearchLength={2}
                                    style={{ maxHeight: ITEM_HEIGHT * 4, overflowY: 'auto' }}
                                />
                            </div>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

OrganizationSearchMenu.defaultProps = {
    organizations: [],
    onMenuItemClick: () => {},
}

export default injectIntl(OrganizationSearchMenu)
