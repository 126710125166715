angular
    .module('theme.core.directives', [])
    .directive('autosize', () => {
        'use strict'
        return {
            restrict: 'AC',
            link(scope, element) {
                element.autosize({
                    append: '\n',
                })
            },
        }
    })
    .directive('fullscreen', () => {
        'use strict'
        return {
            restrict: 'AC',
            link(scope, element) {
                element.fseditor({
                    maxHeight: 500,
                })
            },
        }
    })
    .directive('colorpicker', () => {
        'use strict'
        return {
            restrict: 'AC',
            link(scope, element) {
                element.colorpicker()
            },
        }
    })
    .directive('daterangepicker', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                options: '=daterangepicker',
                start: '=dateBegin',
                end: '=dateEnd',
            },
            link(scope, element) {
                element.daterangepicker(scope.options, (start, end) => {
                    if (scope.start) {
                        scope.start = start.format('MMMM D, YYYY')
                    }
                    if (scope.end) {
                        scope.end = end.format('MMMM D, YYYY')
                    }
                    scope.$apply()
                })
            },
        }
    })
    .directive('multiselect', ['$timeout', function($t) {
        'use strict'
        return {
            restrict: 'A',
            link(scope, element) {
                $t(() => {
                    element.multiSelect()
                })
            },
        }
    }])
    .directive('wizard', () => {
        'use strict'
        return {
            restrict: 'A',
            scope: {
                options: '=wizard',
            },
            link(scope, element) {
                if (scope.options) {
                    element.stepy(scope.options)

                    //Make Validation Compability - see docs
                    if (scope.options.validate === true) {
                        element.validate({
                            errorClass: 'help-block',
                            validClass: 'help-block',
                            highlight(element) {
                                angular.element(element).closest('.form-group').addClass('has-error')
                            },
                            unhighlight(element) {
                                angular.element(element).closest('.form-group').removeClass('has-error')
                            },
                        })
                    }
                } else {
                    element.stepy()
                }
                //Add Wizard Compability - see docs
                element.find('.stepy-navigator').wrapInner('<div class="pull-right"></div>')
            },
        }
    })
    .directive('maskinput', () => {
        'use strict'
        return {
            restrict: 'A',
            link(scope, element) {
                element.inputmask()
            },
        }
    })
    .directive('croppable', ['$timeout', function($t) {
        'use strict'
        return {
            restrict: 'A',
            replace: true,
            scope: {
                src: '@',
                imgSelected: '&',
                cropInit: '&',
            },
            link(scope, element) {
                let myImg
                $t(() => {
                    if (scope.src) {
                        myImg = element
                        element.width(element.width()) // stupid width bug
                        angular.element(myImg).Jcrop({
                            trackDocument: true,
                            onSelect(x) {
                                $t(() => {
                                    scope.imgSelected({
                                        coords: x,
                                    })
                                })
                            },
                            // aspectRatio: 1
                        }, function() {
                            // Use the API to get the real image size 
                            scope.bounds = this.getBounds()
                        })
                    }
                })
                scope.$watch('bounds', () => {
                    scope.cropInit({
                        bounds: scope.bounds,
                    })
                })
            },
        }
    }])
