import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('schemaForm')
        .config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
            function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
                const defaultPatternMsg  = 'Wrong file type. Allowed types are '
				
                const defaultMaxSizeMsg1 = 'This file is too large. Maximum size allowed is '
				
                const defaultMaxSizeMsg2 = 'Current file size:'
				
                const defaultMinItemsMsg = 'You have to upload at least one file'
				
                const defaultMaxItemsMsg = 'You can\'t upload more than one file.'

                const embedlyPreview = function (name, schema, options) {
                    if (schema.type === 'object' && schema.format === 'wfEmbedly') {
                        const f  = schemaFormProvider.stdFormObj(name, schema, options)

                        f.key  = options.path
                        f.type = 'wfEmbedly'
                        f.validationMessage = {
                            customRule: '',
                        }
                        f.$validators = {
                            customRule(value) {
                                return !!value
                            },
                        }
                        options.lookup[sfPathProvider.stringify(options.path)] = f
                        return f
                    }
                }

                schemaFormProvider.defaults.object.unshift(embedlyPreview)

                schemaFormDecoratorsProvider.addMapping(
                    'bootstrapDecorator',
                    'wfEmbedly',
                    'scripts/wf/embed/schema-form-embedly.html',
                )
            },
        ])

    angular
        .module('wf.common')
        .directive('wfSchemaEmbedly', ['$timeout', '$q', '$translate', function ($timeout, $q, $translate) {
            return {
                restrict: 'A',
                scope: true,
                require: 'ngModel',
                link(scope, element, attrs, ngModel) {
                    const
                        urlInput = element.find('input.embedUrl')
					
                    const previewElement = element.find('div.selector-wrapper')
					
                    let currentLoadedUrl

                    // scope.selectFile  = function (file) {
                    // 	scope.picFile = file;
                    // };
                    // scope.selectFiles = function (files) {
                    // 	scope.picFiles = files;
                    // };
                    // scope.uploadFile = function (file) {
                    // 	file && doUpload(file);
                    // };
                    // scope.uploadFiles = function (files) {
                    // 	files.length && angular.forEach(files, function (file) {
                    // 		doUpload(file);
                    // 	});
                    // };

                    // console.log(scope);
                    scope.fetchContent = fetchContent
                    scope.inputPlaceholder = $translate.instant('EnterWebAddress') + '...'
                    scope.doIt = function () {
                        ngModel.$setUntouched()
                        ngModel.$setPristine()
                        console.log(ngModel)
                    }

                    activate()

                    function activate() {
                        // Docs: https://github.com/embedly/jquery-preview

                        urlInput.preview({ key: '239e5d0ec76511e0b9a74040d3dc5c07',
                            bind: false,
                            query: {
                                autoplay: 0,
                                maxwidth: 600,
                            },
                        })
                            .on('loading', () => {
                                urlInput.prop('disabled', true)
                                previewElement.toggleClass('loader-small', true)
                            }).on('loaded', (event, embedData) => {
                                urlInput.prop('disabled', false)
                                previewElement.toggleClass('loader-small', false)

                                ngModel.$setViewValue({})
                                currentLoadedUrl = urlInput.val()
                                // ngModel.$commitViewValue();
                            }).on('error', (event, embedData) => {
                                urlInput.prop('disabled', false)
                                previewElement.toggleClass('loader-small', false)
                                currentLoadedUrl = undefined

                                $timeout(() => {
                                    ngModel.$viewValue = undefined
                                    ngModel.$setUntouched()
                                    ngModel.$setPristine()
                                })
                            })
                    }

                    function fetchContent() {
                        if (currentLoadedUrl !== urlInput.val()) {
                            ngModel.$viewValue = undefined
                            ngModel.$setUntouched()
                            ngModel.$setPristine()
						
                            urlInput.trigger('preview')
                        }

                        // $('#id_attach').bind('click', function(e){
                        // 	if ($(this).text() == 'Attach'){
                        // 	} else {
                        // 		var preview = urlInput.data('preview');
                        // 	}
                        // });
                    }

                    // function doUpload(file) {
                    // if (file && !file.$error && scope.url) {
                    // 	file.upload = Upload.upload({
                    // 		url:  scope.url,
                    // 		file: file
                    // 	});

                    // 	file.upload.then(function (response) {
                    // 		$timeout(function () {
                    // 			file.result = response.data;
                    // 		});
                    // 		ngModel.$setViewValue(response.data);
                    // 		ngModel.$commitViewValue();
                    // 	}, function (response) {
                    // 		if (response.status > 0) {
                    // 			scope.errorMsg = response.status + ': ' + response.data;
                    // 		}
                    // 	});

                    // 	file.upload.progress(function (evt) {
                    // 		file.progress = Math.min(100, parseInt(100.0 *
                    // 			evt.loaded / evt.total));
                    // 	});
                    // }
                    // }

                    scope.validateField = function () {
                        const
                            embedlyData = urlInput.data('preview')
					
                    }
                    // scope.submit = function () {
                    // 	if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
                    // 		scope.uploadFile(scope.picFile);
                    // 	} else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
                    // 		scope.uploadFiles(scope.picFiles);
                    // 	}
                    // };
                    scope.$on('schemaFormReset', reset)
                    scope.$on('schemaFormValidate', scope.validateField)
                    scope.$on('schemaFormBeforeSubmit', schemaFormBeforeSubmit)
                    // scope.$on('schemaFormFileUploadSubmit', scope.submit);
				
                    function reset() {
                        ngModel.$setViewValue(undefined)
                        urlInput.val('')
                        urlInput.data('preview', {})
                        urlInput.preview({ key: '239e5d0ec76511e0b9a74040d3dc5c07',
                            bind: false,
                            query: {
                                autoplay: 0,
                                maxwidth: 600,
                            },
                        })
                        previewElement.html('')
                    }

                    function schemaFormBeforeSubmit(event, model) {
                        const
                            // The data variable is the returned result from Embed.ly Extract API
                            // (with thumbnail value set by Embedly jquery-preview plugin)
                            // Docs: http://docs.embed.ly/docs/extract
                            // We need to make it into an Embed.ly oEmbed object before it gets submitted to the server.
                            // Docs: http://docs.embed.ly/docs/oembed
                            data = urlInput.data('preview')
						
                        let output
						
                        const media = data.media
                        // Object
						
                        let author = data.authour // Array
					
                        console.info(data)
                        output = {
                            type: enums.objectType.embed,

                            embedType: data.type,
                            url: data.url,
                            originalUrl: data.original_url,
                            title: data.title,
                            description: data.description,
                            providerName: data.provider_name,
                            providerUrl: data.provider_url,
                            providerDisplay: data.provider_display,
                            faviconUrl: data.favicon_url,
                            safe: data.safe,
                            publishedAt: data.published ? moment(data.published).format() : null,

                            // All properties needs to have an initial null value so that if an item is being updated, the updated item
                            // does not risk retaining some of its old values (since the old and new model is merged before being sent to the server).
                            // If these were only 'undefined' then they wouldn't get updated.
                            mediaType: null,
                            width: null,
                            height: null,
                            html: null,
                            duration: null,

                            authorName: null,
                            authorUrl: null,

                            thumbnailUrl: null,
                            thumbnailWidth: null,
                            thumbnailHeight: null,
                        }

                        if (media && media.type) {
                            _.assign(output, {
                                mediaType: media.type,
                                width: media.width,
                                height: media.height,
                                html: media.html,
                                duration: media.duration,
                            })
                        }

                        if (author && author.length) {
                            author = author[0]
                            _.assign(output, {
                                authorName: author.name,
                                authorUrl: author.url,
                            })
                        }

                        _.assign(output, {
                            thumbnailUrl: data.thumbnail_url,
                            thumbnailWidth: data.thumbnail_width,
                            thumbnailHeight: data.thumbnail_height,
                        })

                        delete model.dummyProperty

                        _.assign(model, output)
                        console.log(model)
                    }
                },
            }
        }])
})()
