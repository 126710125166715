(function() {
    'use strict'

    angular
        .module('wf.common')
        .service('screenLoaderService', screenLoaderService)

    screenLoaderService.$inject = ['$translate']

    function screenLoaderService($translate) {
        const
            defaultMessage = $translate.instant('Loading') + '...'
            
        const element = $('<div />')
            
        let messageElement
            
        let extrasContainer = $('body').children('div.worldfavor-extras-container')

        // if (window.wf__embeddedWidgetMode) {
            
        const service = {
            show(message, fade) {
                messageElement.text(message || ($translate.instant('Loading') + '...'))

                if (fade) {
                    element.fadeIn(typeof fade === 'number' ? fade : 300)
                }
                else {
                    element.show()
                }
            },
            showAndHide() {
                messageElement.text(($translate.instant('Loading') + '...'))
                element.show()
                setTimeout(() => {
                    element.hide()
                }, 100)
            },
            hide(fade) {
                if (fade) {
                    element.fadeOut(typeof fade === 'number' ? fade : 300)
                }
                else {
                    element.hide()
                }
            },
            showWfLoader() {
                messageElement.text('')
                element.addClass('loader')
                element.fadeIn(300)
            },
            hideWfLoader() {
                element.hide()
                element.removeClass('loader')
            },
        }
		
        element.addClass('worldfavor-screenLoader')
        element.append(messageElement = $('<div />').text(defaultMessage))

        if (!extrasContainer.length) {
            extrasContainer = $('<div class=\'wf-w worldfavor-extras-container\'></div>').appendTo($('body'))
        }

        extrasContainer.append(element)

        return service
    }
})()
