// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import Colors  from '@worldfavor/constants/colors'
import { fade } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import WfGraph from '@worldfavor/components/Chartist/WfGraph'
import { GraphType } from '@worldfavor/components/Chartist'
import Map from '@worldfavor/components/Items/Map'
import { sum, max } from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import '@worldfavor/less/global/wf-gmaps.less'
import { DEFAULT_CHART_COLORS } from '@worldfavor/constants'
import { sortNaturally } from '@worldfavor/utils/helpers'

const useStyles = makeStyles((theme) => {
    const paperBaseStyles = {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 255,
        position: 'relative',
        overflow: 'hidden',

        '&.small': {
            height: 150,
        },
    }

    return ({
        root: {
            flexGrow: 1,
            paddingTop: 40,
        },
        paper: paperBaseStyles,
        paperForGaugeChart: { ...paperBaseStyles, height: 150, position: 'relative' },
        sectionHeader: {
            marginTop: 40,
            marginBottom: 14,
            fontSize: 24,
            fontWeight: theme.typography.fontWeightMedium,
            color: Colors.blackDark,
        },
        subSectionHeader: {
            marginTop: 24,
            marginBottom: 12,
            fontSize: 20,
            fontWeight: theme.typography.fontWeightLight,
            color: Colors.blackDark,
        },
        panelHeader: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            margin: 0,
            fontSize: '13px',
            fontWeight: theme.typography.fontWeightMedium,
            color: Colors.grayText,
            paddingBottom: theme.spacing(1),
        },
        panelHeaderCount: {
            fontWeight: theme.typography.fontWeightMedium,
            color: Colors.gray,
            marginLeft: 4,

        },
        panelHeaderVerticalBars: {
            position: 'absolute',
            top: 0,
            left: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: 1,
            backgroundColor: fade('#fff', 0.9),
            paddingTop: theme.spacing(2),
        },
        number: {
            fontSize: '80px',
            lineHeight: '180px',
            color: Colors.grayText,
            fontWeight: theme.typography.fontWeightMedium,
            textAlign: 'center',

            '&.overdue': {
                color: Colors.orange,
            },

            '&.small': {
                fontSize: '50px',
                lineHeight: '80px',
            },
        },
        gaugePercentage: {
            position: 'absolute',
            bottom: 17,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            fontSize: '20px',
            color: Colors.gray,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: 'center',
        },
        mapWrapper: {
            height: 534,
            borderRadius: 4,
            overflow: 'hidden',
            boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
        },
        mapEmptyState: {
            marginTop: '40%',
            textAlign: 'center',
            color: Colors.gray,
        },
        verticalBars: {
            overflowY: 'auto',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            padding: theme.spacing(2),
            paddingTop: 60,
            paddingBottom: 30,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
        },
        verticalBarRow: {
            padding: '4px 0',
            display: 'flex',
            borderRadius: 3,
            height: '100%',
            minHeight: 22,
            maxHeight: 40,
        },
        verticalBarLabel: {
            width: '50%',
            paddingRight: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            textAlign: 'right',
            boxSizing: 'border-box',
            wordBreak: 'break-word',
            position: 'relative',
        },
        verticalBarLabelText: {
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        verticalBarLine: {
            display: 'block',
            width: 1,
            position: 'absolute',
            top: -10,
            bottom: -10,
            right: 0,
            background: Colors.grayText,
        },
        verticalBarValue: {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 50,
            boxSizing: 'border-box',
        },
        verticalBarRectangle: {
            height: '100%',
            backgroundColor: '#aaa',
            marginRight: 10,
            flexShrink: 0,
            minWidth: 1,
        },
        verticalBarPercentage: {
        },
        tooltip: {
            margin: 4,
            fontSize: 12,
            padding: '6px 8px',
            backgroundColor: fade('#000', 0.8),
        },
        tooltipArrow: {
            color: fade('#000', 0.8),
        },
        tooltipLabel: {
            color: fade('#fff', 0.8),
            fontWeight: theme.typography.fontWeightRegular,
            paddingBottom: 5,
        },
        tooltipCount: {
            fontSize: 15,
        },
    })
})

type Props = {
  data: any,
  intl: any
}

const DataCollectorDashboard = (props: Props) => {
    const { data, intl } = props
    const classes = useStyles(props)
    const categoryGroups = data.categoryGroups.filter(x => x.entries.length)
    const requirementPackageFulfillments = data.requirementPackageFulfillments.filter(x => x.entries.length)
    const gridItemSize = 3

    function getColor(entryId) {
        if (entryId === 'fulfilled') return '#5EAC67'
        else if (entryId === 'notFulfilled') return '#F3F3F3'
    }

    function prepareDataset(dataset, options) {
        const labels = ['header']
        const { isFulfillment, colors } = options || {}
        const sortingFunction = isFulfillment
            ? (a, b) => a.id.length - b.id.length
            : (a, b) => b.count - a.count

        const series = dataset.sort(sortingFunction).map((x, index) => ({
            id: x.id || x.label,
            legendLabel: x.label,
            // eslint-disable-next-line no-mixed-operators
            color: colors && colors[index] || getColor(x.id),
            data: [{ value: x.count, name: x.label }],
        }))

        return {
            labels,
            series,
        }
    }

    function renderPieChartPanel(header, dataset, options) {
        const { isFulfillment } = options || {}
        const { labels, series } = prepareDataset(dataset, options)

        let chartOptions = {
            donutWidth: 20,
            showLabel: true,
        }
        let fulfillmentPercentage

        if (isFulfillment) {
            chartOptions = {
                ...chartOptions,
                startAngle: 270,
                showLabel: false,
                total: sum(dataset.map(x => x.count)) * 2,
            }

            fulfillmentPercentage = Math.round((dataset.find(x => x.id === 'fulfilled') || { percentage: 0 }).percentage, 2)
        }

        return (
            <Paper elevation={1} className={isFulfillment ? classes.paperForGaugeChart : classes.paper}>
                <h3 className={classes.panelHeader} title={`${header}`}>
                    {header}
                </h3>
                <WfGraph
                    data={{
                        labels,
                        series,
                    }}
                    type={GraphType.Donut}
                    style={{ height: 200, marginTop: -10, width: '100%' }}
                    showTooltips
                    options={chartOptions}
                />
                {
                    isFulfillment && <div className={classes.gaugePercentage}>{fulfillmentPercentage}%</div>
                }
            </Paper>
        )
    }

    function renderNumberPanel(header, number, options) {
        const { isOverdue, small } = options || {}
        return (
            <Paper elevation={1} className={classNames(classes.paper, small && 'small')}>
                <h3 className={classes.panelHeader} title={header}>{header}</h3>
                <div
                    className={classNames(
                        classes.number,
                        isOverdue && 'overdue',
                        small && 'small',
                    )}
                >{number}
                </div>
            </Paper>
        )
    }

    function renderVerticalBars(header, dataset) {
        const maxCount = max(dataset.map(x => x.count))

        return (
            <Paper elevation={1} className={classes.paper}>
                <h3 className={classNames(classes.panelHeader, classes.panelHeaderVerticalBars)} title={header}>{header} <span className={classes.panelHeaderCount}>({dataset.length})</span></h3>
                <div className={classes.verticalBars}>
                    {dataset.map((x, index) => (
                        <Tooltip
                            key={`bar-${header}-${x.id}`}
                            arrow
                            title={(
                                <>
                                    <div className={classes.tooltipLabel}>{x.label}</div>
                                    <div className={classes.tooltipCount}>{x.count} <FormattedMessage id="general.organization" values={{ count: x.count }} /></div>
                                </>
                            )}
                            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                            placement="top"
                            TransitionComponent={Fade}
                        >
                            <div className={classes.verticalBarRow}>
                                <div className={classes.verticalBarLabel}>
                                    <div className={classes.verticalBarLabelText}>{x.label}</div>
                                    <div className={classes.verticalBarLine} />
                                </div>
                                <div className={classes.verticalBarValue}>
                                    <div
                                        className={classes.verticalBarRectangle}
                                        style={{
                                            width: `${x.count / maxCount * 100}%`,
                                            backgroundColor: DEFAULT_CHART_COLORS[index] || DEFAULT_CHART_COLORS[index - DEFAULT_CHART_COLORS.length] || DEFAULT_CHART_COLORS[index - DEFAULT_CHART_COLORS.length * 2],
                                        }}
                                    />
                                    <div className={classes.verticalBarPercentage}>{x.percentage.toFixed(x.percentage < 1 ? 2 : 1)}%</div>
                                </div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </Paper>
        )
    }

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {renderNumberPanel(intl.formatMessage({ id: 'dataCollectorDashboard.organizations' }), data.organizationsCount)}
                            </Grid>
                            <Grid item xs={6}>
                                {renderVerticalBars(intl.formatMessage({ id: 'dataCollectorDashboard.countries' }), data.countries)}
                            </Grid>
                            <Grid item xs={12}>
                                {renderVerticalBars(intl.formatMessage({ id: 'dataCollectorDashboard.industries' }), data.industries)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.mapWrapper}>
                                    {
                                        data.organizationLocations.length
                                            ? (
                                                <Map
                                                    items={data.organizationLocations}
                                                    clusterize={false}
                                                    closeInfoOnClick
                                                />
                                            )
                                            : (
                                                <div className={classes.mapEmptyState}>
                                                    <FormattedMessage id="dataCollectorDashboard.mapEmptyState" />
                                                </div>
                                            )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (Boolean)(categoryGroups.length) && (
                    <>
                        <h2 className={classes.sectionHeader}><FormattedMessage id="dataCollectorDashboard.categories" /></h2>
                        <Grid container spacing={3}>
                            {categoryGroups.map(group => (
                                <Grid key={`category-group-${group.id}`} item xs={gridItemSize}>
                                    {renderVerticalBars(group.name, group.entries)}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                    )
                }
                <h2 className={classes.sectionHeader}><FormattedMessage id="dataCollectorDashboard.progressReport" /></h2>
                <Grid container spacing={3}>
                    <Grid item xs={gridItemSize}>
                        {renderNumberPanel(intl.formatMessage({ id: 'dataCollectorDashboard.organizationsWithReceivedRequests' }), data.organizationsWithReceivedRequestsCount, { small: true })}
                    </Grid>
                    <Grid item xs={gridItemSize}>
                        {renderNumberPanel(intl.formatMessage({ id: 'dataCollectorDashboard.organizationsWithExpiredRequests' }), data.organizationsWithExpiredRequestsCount, { small: true, isOverdue: true })}
                    </Grid>
                    {
                        (Boolean)(requirementPackageFulfillments.length) && (
                            <Grid item xs={gridItemSize}>
                                {renderPieChartPanel(intl.formatMessage({ id: 'dataCollectorDashboard.organizationsWithEverythingFulfilled' }), data.organizationsWithEverythingFulfilled, { isFulfillment: true, colors: ['#666', null] })}
                            </Grid>
                        )
                    }
                </Grid>
                {
                    (Boolean)(requirementPackageFulfillments.length) && (
                    <>
                        <h3 className={classes.subSectionHeader}><FormattedMessage id="dataCollectorDashboard.requirementPackages" />
                        </h3>
                        <Grid container spacing={3}>
                            {sortNaturally(requirementPackageFulfillments, 'name').map(x => (
                                <Grid key={`package-fulfillment-${x.id}`} item xs={gridItemSize}>
                                    {renderPieChartPanel(x.name, x.entries, { isFulfillment: true })}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                    )
                }
            </div>
        </div>
    )
}

export default injectIntl(DataCollectorDashboard)
