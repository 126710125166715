// @flow
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaxWidthGrid from '@worldfavor/components/MaxWidthGrid'
import { Link } from 'react-router-dom'

import { getNetworks } from '../selectors/networkSelector'
import { getCurrentUser } from '../selectors/appSelector'
import { loadItem } from '../actions/dataThunk'
import { StaticIds } from '@worldfavor/constants'
import ColleagueInviteCard from '@worldfavor/components/ColleagueInviteCard'
import Colors from '@worldfavor/constants/colors'
import EmptyState from '@worldfavor/components/EmptyState'
import LinkedNetworkCard from '../components/Exchange/LinkedNetworkCard'
import PageHeader from '@worldfavor/components/Header/PageHeader'
import Loading from './Loading'

const useStyles = makeStyles(theme => ({
    headerTitle: {
        color: theme.palette.primary.main,
        fontSize: 42,
        fontWeight: theme.typography.fontWeightLight,
    },
    headerDescription: {
        color: theme.palette.primary.main,
        fontSize: 18,
        whiteSpace: 'pre-wrap',
    },
    networksSectionTitle: {
        fontSize: 34,
        marginBottom: 34,
    },
    networksSection: {
        width: '100%',
        backgroundColor: 'white',
        paddingTop: 75,
        paddingBottom: 75,
    },
    inviteCard: {
        minWidth: 240,
        maxWidth: 250,
    },
    networkPlaceholder: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: Colors.gray,
        borderRadius: 3,
        height: '100%',
        padding: 32,
    },
}))

type Props = {}

const NetworksLandingScene = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const currentUser = useSelector(getCurrentUser)
    const networks = useSelector(getNetworks)
    const classes = useStyles(props)

    useEffect(() => {
        async function fetchData() {
            const [type, id] = StaticIds.NetworksReceivedFrom.split('-')
            setLoading(true)
            try {
                await dispatch(loadItem(id, type, { getterConditions: { includeOrganizations: true } }))
            } catch (e) {
                // TODO handle error
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [dispatch])

    return (
        <React.Fragment>
            <Grid container justify={'center'}>
                <MaxWidthGrid item xs={12}>
                    <PageHeader
                        title={(
                            <span className={classes.headerTitle}>
                                <FormattedMessage
                                    id={'networkExchanges.title'}
                                    values={{ name: currentUser && (<strong>{currentUser.given_name}</strong>) }}
                                />
                            </span>
                        )}
                        description={<span className={classes.headerDescription}><FormattedMessage id={'networkExchanges.description'} /></span>}
                        verticalLineColor={Colors.vividPurple}
                    >
                        <ColleagueInviteCard className={classes.inviteCard} />
                    </PageHeader>
                </MaxWidthGrid>
            </Grid>
            <div className={classes.networksSection}>
                <Grid container justify={'center'}>
                    <MaxWidthGrid item xs={12} md={12}>
                        <h1 className={classes.networksSectionTitle} data-cy="networks-section-title">
                            <FormattedMessage id={'general.network'} values={{ count: 2 }} />
                        </h1>
                        {
                            loading || !networks ? (
                                <Loading />
                            ) : (
                                <Grid
                                    container
                                    spacing={2}
                                    justify="flex-start"
                                >
                                    {
                                        networks.map(({ network, creatorOrganization }) => (
                                            <Grid
                                                key={`grid-item-${network.wfid}`}
                                                item
                                                xs={3}
                                            >
                                                <Link to={`/exchanges/${network.wfid}`} style={{ textDecoration: 'none' }}>
                                                    <LinkedNetworkCard
                                                        network={network}
                                                        creatorOrganization={creatorOrganization}
                                                    />
                                                </Link>
                                            </Grid>
                                        ))
                                    }
                                    <Grid item xs={3}>
                                        <EmptyState
                                            className={classes.networkPlaceholder}
                                            iconClass={'fas fa-arrow-circle-down'}
                                            title={<FormattedMessage id={'networkExchanges.networkPlaceholder'} />}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }
                    </MaxWidthGrid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default NetworksLandingScene
