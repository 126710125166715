import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { MenuItem } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles'
import Colors from '@worldfavor/constants/colors'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import CircularProgressWrapper from '@worldfavor/components/CircularProgressWrapper'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        background: Colors.grayLight,
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 200,
        minHeight: 38,
    },
    menuButtonText: {
        textTransform: 'capitalize',
        fontSize: theme.typography.fontSizes.large,
        fontWeight: '400',
        textAlign: 'left',
        overflow: 'hidden',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
    },
    menuIcon: {
        minWidth: 20,
    },
    menuWindow: {
        position: 'absolute',
        margin: 5,
        minWidth: 180,
        maxHeight: 235,
        overflowY: 'scroll',
    },
    menuItem: {
        minHeight: 45,
    },
    optionIcon: {
        textAlign: 'center',
        minWidth: 20,
        marginRight: 10,
        fontSize: theme.typography.fontSizes.medium,
    },
    optionLabel: {
        fontSize: theme.typography.fontSizes.medium,
    },
}))

const DataExplorerSourceMenu = (props) => {
    const { menuOptions, onChange, loading } = props
    const classes = useStyles(props)
    const [anchorEl, open, close] = useMenuState(null)
    const [currentOption, setCurrentOption] = useState(menuOptions[0])

    function onMenuItemClick(option) {
        return () => {
            onChange(option)
            setCurrentOption(option)
            close()
        }
    }

    return (
        <>
            <Button
                data-cy="data-source-menu"
                aria-controls="menu-list-grow"
                aria-haspopup="true"
                onClick={open}
                className={classes.menuButton}
            >
                <div className={classes.menuButtonText}>
                    {currentOption.label ? currentOption.label : currentOption}
                </div>
                <CircularProgressWrapper loading={loading}>
                    <div className={classes.menuIcon}>
                        { !loading && <i className="fas fa-angle-down" /> }
                    </div>
                </CircularProgressWrapper>
            </Button>
            <Menu
                data-cy="data-source-list"
                classes={{ paper: classes.menuWindow }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null}
            >
                {
                    menuOptions && menuOptions.length > 0 && (
                        menuOptions.map((option, index) => {
                            return (
                                <MenuItem
                                    key={`${option.label}-${index}`}
                                    data-cy={`${option.label}-option`}
                                    onClick={onMenuItemClick(option)}
                                    className={classes.menuItem}
                                >
                                    <div className={classes.optionIcon}>
                                        { <i className={option.icon} /> }
                                    </div>
                                    <div className={classes.optionLabel}>
                                        { option.label }
                                    </div>
                                </MenuItem>
                            )
                        })
                    )
                }
            </Menu>
        </>
    )
}

export default DataExplorerSourceMenu
