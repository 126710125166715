import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('ReportController', ReportController)

    ReportController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$state', 'wfAuth', '$rootScope']
    function ReportController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $state, wfAuth, $rootScope) {
        const
            vm = this
			
        const organizationId = wfAuth.getOrganizationId()
			
        const authUser = wfAuth.getWorldfavorUser()
			
        const orgWfid = enums.objectType.organization + '-' + organizationId
			
        const rootStructure = wfAuth.getRootStructureInfo()
			
        const reportingOrgRootStructure = 12756
			
        let isIsoStandardInRootStructure = undefined
			
        _.assign(vm, {
            isoStandardStructure: undefined,
            isoStandardWfids: undefined,
            isReportingUser: rootStructure.id === reportingOrgRootStructure,
            pageTitle: $translate.instant('modules.signup.landingPage.pageHeader.title', { userName: authUser.given_name + '!' }),
            changeContactPersons,
            goToState,
            openContactForm,
        })

        activate()

        function activate() {
            $rootScope.setPageTitle('Report')
            loadHbspScript()
            dataOps.getObject({
                objectId: 10489, // Structure containing external reporting influences.
                objectType: 71,
            }).then((reportingStructure) => {
                vm.reportingStructure = reportingStructure
                if (reportingStructure.childs.length > 0) {

                    dataOps.getObject({
                        objectId: 10051,
                        objectType: 71,
                    }).then((users) => {
                        vm.users = _.map(users.childs, 'childContent')

                        // Load what networks the users are tagged to. Used in function getUsersInNetwork.
                        dataOps.getSubItemsOfAll(_.map(vm.users, 'wfid'), enums.subItemsKind.parents, { ticket: { contextParentWfids: [orgWfid] } }).then(() => {
                            vm.uniqueNetworks = _.chain(reportingStructure.childs)
                                .map('childContent')
                                .orderBy('title')
                                .groupBy('channelId')
                                .mapValues((influences, key) => {
                                    const networkId = influences[0].channelId

                                    return {
                                        orgName: influences[0].creatorOrganization.name,
                                        orgRegNumber: influences[0].creatorOrganization.registrationNumber,
                                        orgImageUrl: influences[0].creatorOrganization.imageUrl,
                                        influences,
                                        network: wfObject.get('52-' + networkId) || { type: 52, id: networkId },
                                        isPublic: networkId === 1,
                                        contactPersons: getUsersInNetwork(networkId),
                                    }
                                }).map().value()

                            loadIstoStandards()
                            vm.loaded = true
                            $timeout()
                        })
                    })
                }
                else
                {
                    loadIstoStandards()
                    vm.loaded = true
                    $timeout()
                }
            })
        }

        function changeContactPersons(network) {
            modal.openCreatorAndPicker({
                title: network.orgName,
                sourceList: _.map(vm.users, (user) => {
                    return {
                        wfid: user.wfid,
                        data: user,
                        searchSource: user.name,
                    }
                }),
                relationTarget: { item: network.network, kind: enums.subItemsKind.children, contextParentWfid: orgWfid },
            }).modal.closed.then(() => {
                network.contactPersons = getUsersInNetwork(network.network.id)
                $timeout()
            })
        }

        function getUsersInNetwork(networkId) {
            const
                relationsToNetwork = wfObject.filter({
                    where: {
                        type: enums.objectType.dataRelation,
                        parentType: enums.objectType.network,
                        parentId: networkId,
                        parentData1: null,
                        childType: enums.objectType.individual,
                        wfxpid: orgWfid,
                    },
                })

            return _.chain(vm.users).intersectionWith(relationsToNetwork, (user, dataRelation) => {
                return user.wfid === dataRelation.wfcid
            }).value()
        }

        function goToState(state) {
            if (state) $state.go(state)
        }

        function loadHbspScript() {
            let scriptElement

            if (!$('#hs-script-loader').length) {
                scriptElement = document.createElement('script')
                scriptElement.id = 'hs-form-loader'
                scriptElement.type = 'text/javascript'
                scriptElement.src = '//js.hsforms.net/forms/v2.js'
                scriptElement.charset = 'utf-8'
                document.head.appendChild(scriptElement)

                scriptElement.onload = scriptElement.onreadystatechange = function() {
					
                    vm.hbspScriptLoaded = true
                    //Handling memory leak in IE
                    scriptElement.onload = scriptElement.onreadystatechange = null
                }
            }
        }

        function openContactForm() {
            vm.contactFormLoading = true
            if (hbspt) {
                modal.open({
                    template: '<div id=\'hubspot-form-container\'></div>',
                    scope: { },
                    windowClass: 'modal-width-600 wf-landing-page-contact-modal',
                    onLoaded($scope, $element) {
                        vm.contactFormLoading = false
                        const
                            hubSpotForm = $('#hubspot-form-container')
							
                        const user = wfAuth.getUser()

                        // Config docs: https://developers.hubspot.com/docs/methods/forms/advanced_form_options
                        hbspt.forms.create({
                            portalId: '2989273',
                            formId: 'fe3e06f0-80d7-4cb0-9cf8-5f4b94741f72',
                            target: '#hubspot-form-container',
                            css: '',
                            onFormReady() {
                                hubSpotForm.find('select[name="i_want_to"]').val('').change()
                                hubSpotForm.find('input[name="email"]').val(user.email).change()
                                hubSpotForm.find('input[name="firstname"]').val(user.given_name).change()
                                hubSpotForm.find('input[name="lastname"]').val(user.family_name).change()
                                hubSpotForm.find('input[name="phone"]').val(user.phone_number).change()
                                hubSpotForm.find('textarea[name="message"]').val('').change()
                                hubSpotForm.find('input[name="subscribe_to_worldfavor_sustainability_blog"]').prop('checked', false).change()
                                hubSpotForm.find('.hs-submit').click(() => {
                                    hubSpotForm.addClass('showValidationErrors')
                                })
                            },
                        })
                    },
                })
            }
            else {
                setTimeout(() => {
                    openContactForm()
                }, 500)
            }
        }

        function loadIstoStandards() {
            isIsoStandardInRootStructure = rootStructure && rootStructure.content && rootStructure.content.childs.length ? _.find(rootStructure.content.childs, { wfcid: '71-13546' }) : undefined
            if (isIsoStandardInRootStructure) getIsoStandardItems()

            function getIsoStandardItems() {
                vm.isoStandardStructure = wfObject.get('71-13546')
	
                if (!vm.isoStandardItems && vm.isoStandardStructure) vm.isoStandardItems = vm.isoStandardStructure.childs
	
                return vm.isoStandardItems
            }
        }
    }
})()
