// @flow
import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import TableHeaderCell from '@worldfavor/components/Table/TableHeaderCell'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useMenuState } from '@worldfavor/hooks/mui-hooks'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MinimumFilterTooltip from './MinimumFilterTooltip'

const useStyles = makeStyles(theme => ({
    root: {},
    menuPaper: {
        minWidth: 200,
    },
    menu: {
        marginTop: theme.spacing(1),
    },
}))

const MappingStatuses = [
    {
        id: 'not-mapped',
        labelId: 'supplyChain.mappingState.notMapped',
        value: 0,
    },
    {
        id: 'partially-mapped',
        labelId: 'supplyChain.mappingState.partiallyMapped',
        value: 50,
    },
    {
        id: 'fully-mapped',
        labelId: 'supplyChain.mappingState.fullyMapped',
        value: 100,
    },
]

const MappingStatusObject = MappingStatuses.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})

const MappingStatusHeaderCell = (props) => {
    const { onMappingCheckChange, enableFilter } = props
    const tooltipTimeout = useRef(null)
    const [anchorEl, openMenu, closeMenu] = useMenuState(null)
    const [tooltipAnchorEl, openTooltip, closeTooltip] = useMenuState(null)
    const [mappingCheck, setMappingCheck] = useState(MappingStatuses
        .reduce((acc, { id }) => ({ ...acc, [id]: true }), {}))
    const classes = useStyles(props)

    function onClick(e) {
        openMenu(e)
    }

    function toggleStatus(id) {
        function setStatus(e) {
            const newValue = !mappingCheck[id]
            const { [id]: ignore, ...remainingStatuses } = mappingCheck

            // check if the user is trying to disable the last option
            if (Object.values(remainingStatuses).every(value => !value)) {
                showTooltip(e)
                return
            }

            const newMappingCheckStatuses = {
                ...mappingCheck,
                [id]: newValue,
            }

            setMappingCheck(newMappingCheckStatuses)

            if (Object.values(newMappingCheckStatuses).every(Boolean)) {
                onMappingCheckChange(null)
            }
            else {
                onMappingCheckChange({
                    numValues: Object.keys(newMappingCheckStatuses).map((key) => {
                        if (newMappingCheckStatuses[key]) {
                            return MappingStatusObject[key].value
                        }
                        return null
                    }).filter(value => value !== null),
                })
            }
        }

        return setStatus
    }

    function hasActiveFilter() {
        return !Object.values(mappingCheck).every(Boolean)
    }

    function showTooltip(e, delay = 2000) {
        clearTimeout(tooltipTimeout.current)
        openTooltip(e)
        tooltipTimeout.current = setTimeout(() => {
            closeTooltip()
        }, delay)
    }

    return (
        <React.Fragment>
            <TableHeaderCell
                label={<FormattedMessage id={'general.mappingStatus'} />}
                onClick={enableFilter && onClick}
                hasFilter={enableFilter}
                hasActiveFilter={hasActiveFilter()}
            />
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                classes={{ paper: classes.menuPaper }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                getContentAnchorEl={undefined}
                className={classes.menu}
            >
                {
                    MappingStatuses.map(({ id, labelId }) => (
                        <MenuItem key={`menu-item-${id}`} onClick={toggleStatus(id)}>
                            <ListItemText id={`${id}-label`}>
                                <FormattedMessage id={labelId} />
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={toggleStatus(id)}
                                    checked={mappingCheck[id]}
                                    inputProps={{ 'aria-labelledby': `${id}-label` }}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    ))
                }
            </Menu>

            <MinimumFilterTooltip
                anchorEl={tooltipAnchorEl}
            />
        </React.Fragment>
    )
}

MappingStatusHeaderCell.defaultProps = {
    onMappingCheckChange: () => {},
}

export default MappingStatusHeaderCell
