// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'

import ProductTable from './ProductTable'
import ProductTableRowCell from './ProductTableRowCell'

const useStyles = makeStyles({
    row: {
        cursor: 'pointer',
    },
})

const rowKeyExtractor = row => `row-${row.node.wfid}`
const rowCellKeyExtractor = ({ row, columnIndex }) => `rowCell-${row.node.wfid}-${columnIndex}`

const RequestingProductTable = (props) => {
    const { data, networkId, onOpenActivationDialog, history, ...rest } = props
    const classes = useStyles(props)

    function renderRowCell(props) {
        return (
            <ProductTableRowCell
                {...props}
                onToggleActivation={onOpenActivationDialog}
                to={`/valuechain/${networkId}/product/${props.row.node.wfid}`}
            />
        )
    }

    function onRowClick(event, row) {
        history.push(`/valuechain/${networkId}/product/${row.node.wfid}`)
    }

    return (
        <ProductTable
            data={data}
            classes={{ row: classes.row }}
            columns={[
                'product',
                'organization',
                'coMapper',
                'gtin',
                { id: 'transparencyLevel', enableFilter: true },
                { id: 'activation', enableFilter: true },
                'actions',
            ]}
            columnWidths={['16%', '16%', '16%', '16%', '17%', '11%', '8%']}
            renderRowCell={renderRowCell}
            rowKeyExtractor={rowKeyExtractor}
            onRowClick={onRowClick}
            rowCellKeyExtractor={rowCellKeyExtractor}
            rowHover
            {...rest}
        />
    )
}

export default withRouter(RequestingProductTable)
