import * as enums from '@worldfavor/constants/enums'

(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('PickerController', PickerController)

    PickerController.$inject = ['$rootScope', '$scope', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', 'formSchemaService', '$uibModalInstance', 'modalContext', '$q', 'wfTranslate', '$timeout', '$window', '$ngBootbox', 'apiProxy', 'wfAuth', '$sanitize', 'pickerService', 'wfPropertyExtractor']

    function PickerController($rootScope, $scope, dataOps, modal, wfObject, dataQuery, $translate, formSchemaService, $uibModalInstance, modalContext, $q, wfTranslate, $timeout, $window, $ngBootbox, apiProxy, wfAuth, $sanitize, pickerService, wfPropertyExtractor) {
        /* jshint validthis:true */
        let
            vm = this
			
        let allItems = []
			
        let modalElement
			
        const authOrgId = wfAuth.getOrganizationId()
			
        const culture = wfAuth.getCulture()
			
        const availableObjectTypes = _.cloneDeep(pickerService.availableObjectTypes)
			
        const isBucketMode = !!modalContext.relationBucket

        // Preselected mode
			
        const bucket = {
            preSelected: [], // Incoming items (pre-selected)
            newlySelected: [], // Newly selected items
            newlyRemoved: [], // Newly removed items
            allSelected: [],
            allSelected_scopes: [],
        }
			
        let pickedItemsOfSelectedObjectType
			
        let showOnlySelectedItems
			
        let isRelationByUser = null
			
        let promises
			
        const useContextAwareRelations = true

        // Puts an extra property on every array in bucket that gets the the wfid properties as an array.
        // Used like this: bucket.alreadySelected.wfids
        for (const key in bucket) {
            (function (obj) {
                Object.defineProperty(obj, 'wfids', {
                    get() {
                        return _.map(obj, 'wfid')
                    },
                })
            })(bucket[key])
        }
        // console.log(modalContext);
        vm = _.assign(vm, {
            context: modalContext,
            formVisible: false,
            toggleForm,
            selectObjectType,
            word_create: $translate.instant('Create'),
            itemsOfSelectedObjectType: [],
            alreadyAddedItems: [],
            relationTarget: modalContext.relationTarget,
            intersectionSettings: modalContext.intersection,
            submitForm,
            submitFormAndClose,
            allItemsOption: { type: 0, filter: {}, title: $translate.instant('All'), itemPlural: $translate.instant('All'), selected: false },
            objectTypes: [],
            singlePick: modalContext.singlePick,
            sourceList: modalContext.sourceList,
            sourceLists: modalContext.sourceLists,
            sourceItem: modalContext.sourceItem,
            sourceItemLoadSettings: modalContext.sourceItemLoadSettings,
            sourceItemFirstLevelAsFilter: modalContext.sourceItemFirstLevelAsFilter,
            templateId: modalContext.templateId || 65,
            canPick: modalContext.pick,
            canCreate: modalContext.create,
            selectedObjectType: null,
            title: modalContext.title,
            description: modalContext.description,
            descriptionHtml: $sanitize(modalContext.descriptionHtml),
            influence: modalContext.influence,
            networkId: modalContext.networkId,
            noFormHeader: modalContext.noFormHeader,
            submitCaption: modalContext.submitCaption || $translate.instant('Create'),
            submitAndCloseCaption: modalContext.submitAndCloseCaption || $translate.instant('CreateAndClose'),
            onToggled,
            onBeforeToggled,
            relationBucket: bucket,
            hideListHeader: modalContext.hideListHeader,
            hideFilters: modalContext.hideFilters,
            listLimit: 3,
            resetListLimit,
            onSearch,
            selectedItemsToggled: false,
            toggleOnlySelectedItems,
            totalCountOfItems: null,
            allItemsFilterSelected: false,
            translations: modalContext.translations,
            footerButtons: modalContext.buttons,
            onFormCloseClick,
            selectSourceList,
            itemsOfSelectedSourceList: [],
            onFooterButtonClick,
            outerVm: typeof modalContext.outerController === 'function' ? modalContext.outerController() : undefined,
            closeCaption: modalContext.closeCaption || $translate.instant('Close'),
            canAttachInformationToRelation: modalContext.canAttachInformationToRelation,
            selectedItemsByWfid: {},
            canCombineSourceListsFilter: modalContext.canCombineSourceListsFilter,
            createObjectOfType: modalContext.createObjectOfType,
            simplifyForms: modalContext.simplifyForms,
            showCreationDropdownButton: true,
            disableUntoggle: modalContext.disableUntoggle,
            disableUntoggleMessage: modalContext.disableUntoggleMessage,
            onToggleCallback: modalContext.onToggleCallback,
            hideItem: modalContext.hideItem,
            customSettingsForObjectTypes: modalContext.customSettingsForObjectTypes,
        })

        activate()

        ////////////////

        function activate() {
            let objectTypes

            $rootScope.$on('$stateChangeSuccess', () => {
                $scope.$close()
            })

            if (isBucketMode) {
                bucket.preSelected = modalContext.relationBucket.preSelected
                bucket.allSelected = _.clone(modalContext.relationBucket.allSelected)

                Object.defineProperty(bucket, 'allSelectedCount', {
                    get() {
                        // if (objectTypes)
                        objectTypes = _.map(vm.objectTypes, 'type')

                        return _.filter(bucket.allSelected, (item) => {
                            return !!~objectTypes.indexOf(item.type)
                        }).length
                    },
                })
                // console.log(bucket.preSelected.length, bucket.allSelected.length);

                _.forEach(bucket.allSelected, (item) => {
                    if (!_.includes(bucket.preSelected, item)) bucket.newlySelected.push(item)
                })
                _.forEach(bucket.preSelected, (item) => {
                    if (!_.includes(bucket.allSelected, item)) bucket.newlyRemoved.push(item)
                })
                modalContext.relationBucketResult = bucket
            }
            else {
                isRelationByUser = _.includes([
                    enums.subItemsKind.relatedContentByUser,
                    enums.subItemsKind.childrenByUser,
                    enums.subItemsKind.parentsByUser,
                    enums.subItemsKind.verifications,
                    enums.subItemsKind.verifies,
                ], modalContext.relationTarget.kind)
            }

            let useCurrentContextParentAsItem

            // Handle cases when relationTarget.item is specified as "@currentContextParent".
            // This will set the relationTarget.item to be the first item in vm.intersectionSettings.contextParents
            // if it is defined.
            // TODO: This should be extended to support multiple contextParents.
            if (vm.relationTarget && !_.isArray(vm.relationTarget) && vm.relationTarget.item === '@currentContextParent') {
                useCurrentContextParentAsItem = true

                if (vm.intersectionSettings.contextParents && vm.intersectionSettings.contextParents.length) {
                    if (!vm.context.displayTopItem) vm.context.displayTopItem = vm.relationTarget.item

                    vm.relationTarget.item = wfObject.get(vm.intersectionSettings.contextParents[0])
                    if (!vm.relationTarget.item) {
                        console.error('Picker: RelationTarget item specifies @currentContextParent but the contextParent is not loaded.')
                    }
                }
                else console.error('Picker: RelationTarget item specifies @currentContextParent but no contextParent is defined.')
            }

            if (vm.relationTarget && !isBucketMode && !useCurrentContextParentAsItem) {

                if (!_.isArray(vm.relationTarget)) {
                    vm.firstRelationTargetItem = vm.relationTarget.item
                    vm.relationTarget = [vm.relationTarget]
                }
                else vm.firstRelationTargetItem = vm.relationTarget[0].item

                if (vm.intersectionSettings && vm.intersectionSettings.contextParents) {
                    if (useContextAwareRelations) { // ContextParent directly on the relation
                        vm.relationTarget[0].contextParentWfid = _.get(vm, 'intersectionSettings.contextParents[0]')
                    }
                    else {
                        Array.prototype.push.apply(vm.relationTarget, _.map(vm.intersectionSettings.contextParents, (wfid) => {
                            return {
                                item: wfid,
                                kind: enums.subItemsKind.contextChildren,
                            }
                        }))
                    }
                }

            }

            if (vm.intersectionSettings && vm.intersectionSettings.networkId) {
                vm.networkId = vm.intersectionSettings.networkId
            }

            if (vm.sourceItem) {
                activateWithSourceItem()
                return
            }

            if (vm.sourceList) {
                vm.filteringConfig = {
                    controllerAs: 'pickerVm', items: vm.sourceList,
                    filters: [
                        { bySearch: true, sourceItemPath: 'data' },
                    ],
                }

                if (!vm.hideFilters) {
                    vm.filteringConfig.filters.push({ sourceIdPath: 'data.wfid', bySubItemsKind: 8, excludeTargetWfid: '71-12224', condition: vm.hideFilters })
                }

                if (typeof vm.sourceList === 'function') {
                    (function () {
                        const promiseOrArray = vm.sourceList()
                        if (promiseOrArray.then) {
                            promiseOrArray.then((res) => {
                                $timeout(() => {
                                    vm.loaded = true
                                    vm.filteringConfig.items = vm.sourceList = res
                                }, 50)
                            })
                        }
                        else {
                            vm.filteringConfig.items = vm.sourceList = promiseOrArray
                            $timeout(() => {
                                vm.loaded = true
                            }, 50)
                        }
                    })()
                }
                else {
                    $timeout(() => {
                        vm.loaded = true
                    }, 50)
                }
                vm.sourceItem = true
                // console.log(vm);
                return

            }

            if (vm.sourceLists) {
                activateWithSourceLists()
                return
            }

            vm.objectTypes = _.clone(availableObjectTypes)
            if (!modalContext.objectTypes || modalContext.objectTypes.length === 0) modalContext.objectTypes = [15, 18, 44, 50, 90]

            vm.context.noTools = true
            vm.context.hideRelatedContent = true
            vm.listHeader = ''
            vm.objectTypes = _.filter(vm.objectTypes, (item) => {
                return _.includes(modalContext.objectTypes, item.type)
            })

            // The items in modalContext.objectTypes can be either numbers (object types) or objects
            // specifing a full set of options just like an item in availableObjectTypes
            _.each(modalContext.objectTypes, (item) => {
                if (typeof item === 'object') {
                    vm.objectTypes.push(item)
                }
            })

            _.each(vm.objectTypes, (spec, index) => {
                let inheritedSpec

                if (spec.inheritSettingsFrom) {
                    if (spec.inheritSettingsFrom) {
                        inheritedSpec = _.cloneDeep(_.find(availableObjectTypes, { type: spec.inheritSettingsFrom }))
                        spec = _.defaultsDeep(spec, inheritedSpec)
                        vm.objectTypes[index] = spec
                    }
                }
            })

            if (!vm.translations) vm.translations = {}

            if (vm.objectTypes.length == 1) {
                vm.singleObjectType = vm.objectTypes[0]

                if (vm.singleObjectType.hideFilters) vm.hideFilters = true
            }

            if (vm.objectTypes.length === 1 && vm.objectTypes[0].canPick === false) vm.canPick = false

            if (vm.canPick && vm.objectTypes.length > 1) vm.objectTypes.unshift(vm.allItemsOption)

            vm.selectedObjectType = vm.objectTypes[0]

            promises = []

            _.each(vm.objectTypes, (objType) => {
                if (objType.type)
                {
                    setupObjectTypeSpec(objType)
                    // objType.buttons = {
                    // 	create: wfTranslate.tryInstant("modules.picker.objectTypes." + objType.type + ".createButtonCaption") || $translate.instant("Create"),
                    // 	createAndClose: wfTranslate.tryInstant("modules.picker.objectTypes." + objType.type + ".createAndCloseButtonCaption") || $translate.instant("CreateAndClose")
                    // };

                    if (vm.canPick) {

                        // Give the modal time to appear before populating items (might freeze the ui for a second if there are a lot of items)
                        promises.push(dataOps.getSubItems({ id: objType.structureId, type: 71 }, objType.kind || enums.subItemsKind.childrenByUser, {
                            getterConditions: objType.getterConditions,
                            contextParent: useContextAwareRelations ? _.get(vm, 'intersectionSettings.contextParents[0]') : undefined,
                        }).then((result) => {
                            // Instead of using the result, we query the cache so that items that were added later are included.
                            objType.items = wfObject.filter({ where: { parentType: 71, parentId: objType.structureId, childType: objType.type }, orderBy: [['childContent.createdAt', 'DESC']] }) // Ordering by newest
                            maybeHideItems(objType.items)

                            if (vm.relationTarget && !_.isArray(vm.relationTarget) && vm.relationTarget.settingsByItemType
								&& vm.relationTarget.settingsByItemType[objType.type]
								&& vm.relationTarget.settingsByItemType[objType.type].prependItem
                            ) {
                                objType.items.unshift(vm.relationTarget.settingsByItemType[objType.type].prependItem())
                            }
                        }))
                    }
                    else {
                        vm.loaded = true
                    }
                }
                else
                {
                    objType.listHeader = $translate.instant('ExistingInformation')
                    objType.emptyState = {
                        header: $translate.instant('modules.picker.objectTypes.0.emptyStateHeader'),
                        body: $translate.instant('modules.picker.objectTypes.0.emptyStateDescription'),
                    }
                }
            })

            if (promises.length) {
                // Give the modal time to appear before populating items (might freeze the ui for a second if there are a lot of items)
                setTimeout(() => {
                    $q.all(promises).then(() => {
                        _.each(vm.objectTypes, (objType) => {
                            if (objType.type) Array.prototype.push.apply(allItems, objType.items)
                            else objType.items = allItems // "All items" filter button
                        })
                        vm.totalCountOfItems = allItems.length
                        allItems = _.orderBy(allItems, [function (item) { // Ordering by newest
                            return item.childContent.createdAt
                        }], ['desc'])
                        vm.itemsOfSelectedObjectType.length = 0

                        if (vm.selectedObjectType.useFirstRelationTargetAsSource) {
                            if (vm.relationTarget) Array.prototype.push.apply(vm.itemsOfSelectedObjectType, getItemsFromRelationTarget(vm.relationTarget[0]))
                        }
                        else {
                            Array.prototype.push.apply(vm.itemsOfSelectedObjectType, _.filter(allItems, vm.selectedObjectType.filter))
                        }

                        promises = []
                        if (!isBucketMode) {
                            // relationTargets support multiple targets and if only one is defined, still make it into an array.
                            // if (!_.isArray(vm.relationTarget)) {
                            // 	vm.relationTarget = [ vm.relationTarget ];
                            // }

                            // if (vm.intersectionSettings && vm.intersectionSettings.contextParents) {
                            // 	if (useContextAwareRelations) { // ContextParent directly on the relation
                            // 		vm.relationTarget[0].contextParentWfid = _.get(vm, "intersectionSettings.contextParents[0]")
                            // 	}
                            // 	else { // ContextParent as a seperate relation
                            // 		Array.prototype.push.apply(vm.relationTarget, _.map(vm.intersectionSettings.contextParents, function (wfid) {
                            // 			return {
                            // 				item: wfid,
                            // 				kind: enums.subItemsKind.contextChildren
                            // 			}
                            // 		}));
                            // 	}
                            // }

                            _.each(vm.relationTarget, (relationTarget) => {

                                // Only children/parents relations are loaded from server by default. Other relation kinds need to be loaded on demand
                                if (!_.includes([
                                    enums.subItemsKind.children,
                                    enums.subItemsKind.childrenByUser,
                                    enums.subItemsKind.parents,
                                    enums.subItemsKind.parentsByUser,
                                ], relationTarget.kind)) {
                                    promises.push(dataOps.getSubItemsOfAll(_.map(allItems, 'wfcid'), wfObject.getOppositeRelationKind(relationTarget.kind)))
                                }
                            })
                        }

                        if (promises.length) {
                            $q.all(promises).then(() => {
                                vm.loaded = true
                            })
                        }
                        else {
                            vm.loaded = true
                        }
                    })
                }, 400)
            }

            vm.createableObjectTypes = _.chain(vm.objectTypes).reject({ id: 0 }).reject({ canCreate: false }).value()

            if (vm.createableObjectTypes.length === 0) {
                vm.showCreationDropdownButton = false
                vm.showFormToggler = false
                vm.canCreate = false
            }

            if (vm.objectTypes && vm.objectTypes.length > 0) selectObjectType(_.first(vm.objectTypes))
            else console.error('objectTypes array is empty')

            if (!vm.canPick && vm.objectTypes.length == 1) vm.formVisible = true

            // if (!vm.canPick) {
            // 	if (!_.isArray(vm.relationTarget)) {
            // 		vm.relationTarget = [ vm.relationTarget ];
            // 	}

            // 	if (vm.intersectionSettings && vm.intersectionSettings.contextParents) {
            // 		if (useContextAwareRelations) { // ContextParent directly on the relation
            // 			vm.relationTarget[0].contextParentWfid = _.get(vm, "intersectionSettings.contextParents[0]")
            // 		}
            // 		else {
            // 			Array.prototype.push.apply(vm.relationTarget, _.map(vm.intersectionSettings.contextParents, function (wfid) {
            // 				return {
            // 					item: wfid,
            // 					kind: enums.subItemsKind.contextChildren
            // 				}
            // 			}));
            // 		}
            // 	}
            // }

            if (!vm.title) {
                if (vm.singleObjectType) vm.title = $translate.instant('Add') + ' ' + vm.singleObjectType.title.toLowerCase() + ' ' + $translate.instant('To').toLowerCase()
                else vm.title = $translate.instant('AddInformation')
            }

            if (vm.singleObjectType) {
                if (!vm.translations.filterButton_all) vm.translations.filterButton_all = $translate.instant('All') + ' ' + vm.singleObjectType.itemPlural.toLowerCase()

                if (!vm.translations.filterButton_selected) vm.translations.filterButton_selected = $translate.instant('Selected_Plural') + ' ' + vm.singleObjectType.itemPlural.toLowerCase()
            }
        }

        function getItemsFromRelationTarget(relationTarget) {
            const items = dataQuery.getIntersectedSubItems(relationTarget.item, { kind: relationTarget.kind })
            return items
        }

        function setViewItemsFromRelationTarget() {
            setViewItemsFromRelationTarget = _.debounce(() => {
                vm.itemsOfSelectedObjectType.length = 0
                if (vm.relationTarget) Array.prototype.push.apply(vm.itemsOfSelectedObjectType, getItemsFromRelationTarget(vm.relationTarget[0]))
            }, 500)

            setViewItemsFromRelationTarget()
        }

        function setupObjectTypeSpec(objType) {
            const wordingType = objType.wordingType || objType.type
            let customWording = undefined
            let customSettingsForObjectType

            _.assign(objType, {
                type: objType.actualType || objType.type,
                title: wfTranslate.instant('MAP_ObjectType', { type: wordingType }),
                iconClass: getObjectTypeIconClass(objType.type),
                filter: { childType: objType.actualType || objType.type },
                prototypeModel: { type: objType.type },
                model: _.clone(objType.prototypeModel),
                formControl: {},
                listHeader: wfTranslate.instant('MAP_ObjectType', { type: wordingType, plural: true }),
                itemPlural: wfTranslate.instant('MAP_ObjectType', { type: wordingType, plural: true }).toLowerCase(),
                emptyState: {},
                buttons: {},
            })

            if (!objType.form) objType.form = {}

            if (typeof objType.form === 'function') {
                objType.form = objType.form(objType.formControl)
            }

            _.assign(objType.form, {
                simplify: vm.simplifyForms ? vm.simplifyForms : undefined,
                wording: modalContext && modalContext.wording ? modalContext.wording : undefined,
                typeOptionsByProperty: modalContext && modalContext.typeOptionsByProperty ? modalContext.typeOptionsByProperty : undefined,
                formFieldTitlesByProperty: modalContext && modalContext.formFieldTitlesByProperty ? modalContext.formFieldTitlesByProperty : undefined,
                requiredFields: modalContext && modalContext.requiredFields ? modalContext.requiredFields : undefined,
            })

            if (typeof objType.form.customSpecification === 'function') {
                objType.form.customSpecification = objType.form.customSpecification(objType.formControl)
            }

            customSettingsForObjectType = _.find(vm.customSettingsForObjectTypes, { type: objType.type })
            if (customSettingsForObjectType) {
                customSettingsForObjectType = _.cloneDeep(customSettingsForObjectType)
                _.assign(objType, _.defaultsDeep(customSettingsForObjectType, objType))
            }

            if (objType.addButtonCaptionTranslate) objType.addButtonCaption = wfTranslate.concat(objType.addButtonCaptionTranslate)

            if (objType.form && objType.form.wording && vm.objectTypes && vm.objectTypes.length === 1) {
                customWording = objType.form.wording

                objType.title = getTextFromProperty(customWording.singular).toLowerCase()
                objType.itemPlural = getTextFromProperty(customWording.plural).toLowerCase()
                objType.listHeader = getTextFromProperty(customWording.plural).toLowerCase()
                objType.addButtonCaption = getTextFromProperty(customWording.addButton)
                objType.emptyState = {
                    header: $translate.instant('modules.picker.customObjectType.emptyStateHeader', { information: getTextFromProperty(customWording.plural).toLowerCase() }),
                    body: $translate.instant('modules.picker.customObjectType.emptyStateDescription', { information: getTextFromProperty(customWording.singular).toLowerCase() }),
                    emptyStateSearch: $translate.instant('modules.picker.customObjectType.emptyStateSearch', { information: getTextFromProperty(customWording.plural).toLowerCase() }),
                }
            }
            else {
                wfTranslate.tryInstant('modules.picker.objectTypes.' + wordingType + '.emptyStateHeader', (text) => {
                    objType.emptyState.header = text || $translate.instant('modules.picker.objectTypes.0.emptyStateHeader')
                })
                wfTranslate.tryInstant('modules.picker.objectTypes.' + wordingType + '.emptyStateDescription', (text) => {
                    objType.emptyState.body = text || $translate.instant('modules.picker.objectTypes.0.emptyStateDescription')
                })
                wfTranslate.tryInstant('modules.picker.objectTypes.' + wordingType + '.emptyStateSearch', (text) => {
                    objType.emptyState.emptyStateSearch = text || $translate.instant('modules.picker.objectTypes.0.emptyStateSearch')
                })
            }

            wfTranslate.tryInstant('modules.picker.objectTypes.' + wordingType + '.createButtonCaption', (text) => {
                objType.buttons.create = text || $translate.instant('Create')
            })
            wfTranslate.tryInstant('modules.picker.objectTypes.' + wordingType + '.createAndCloseButtonCaption', (text) => {
                objType.buttons.createAndClose = text || $translate.instant('CreateAndClose')
            })
        }

        function getTextFromProperty(property) {
            if (typeof property === 'string') {
                return property
            }
            else {
                return wfPropertyExtractor.getTextFromProperty(property, culture)
            }
        }

        function getObjectTypeIconClass(type) {
            switch (type) {
                case enums.objectType.statement: return 'fa fa-quote-right'
                case enums.objectType.question:  return 'fa fa-comment'
                case enums.objectType.measure: return 'fa fa-line-chart'
                case enums.objectType.measureAnswer: return 'fa fa-line-chart'
                case enums.objectType.location: return 'fa fa-map-marker'
                case enums.objectType.link: return 'fa fa-link'
                case enums.objectType.embed: return 'fa fa-play-circle'
                case enums.objectType.orgDocument: return 'fa fa-file'
                case enums.objectType.questionAnswer: return 'fa fa-comments-o'
                case enums.objectType.orgActivity: return 'fa fa-calendar-check-o'
                default:
                    break
            }
        }

        function selectObjectType(objType) {
            const x = arguments
            _.each(vm.objectTypes, (item) => {
                item.selected = false
            })
            // console.log(_.invokeMap(vm.objectTypes, _.assign, { selected: false }));
            // _.map(vm.objectTypes, _.ary(_.assign, { selected: false }));

            if (vm.canCreate) {
                if (objType.type == 0) {
                    vm.showFormToggler = false
                }
                else if (objType.canCreate === false) {
                    vm.showFormToggler = false
                }
                else vm.showFormToggler = true
            }

            objType.selected = true
            vm.selectedObjectType = objType

            if (objType.type && !objType.form)
            {
                objType.form = {}
                objType.model = { type: objType.type }

                // objType.loadingForm = true;
                // formSchemaService.getFromType(objType.type).then(function (res) {
                // 	objType.loadingForm = false;
                // 	objType.schema = res.schema;
                // 	objType.form = res.form;
                // });

            }

            if (!objType.type && vm.formVisible) {
                toggleForm()
            }
            vm.listHeader = objType.listHeader

            // vm.itemsOfSelectedObjectType = _.filter(allItems, objType.filter);
            vm.itemsOfSelectedObjectType.length = 0
            Array.prototype.push.apply(vm.itemsOfSelectedObjectType, _.filter(allItems, objType.filter))

            maybeHideItems(vm.itemsOfSelectedObjectType)
        }

        function submit($event, scope, objType) {
            return objType.formControl.submit((model, deferred) => {
                if (objType.type === enums.objectType.finding) {

                    model.contextParentWfid = _.get(vm, 'intersectionSettings.contextParents[0]')

                    if (vm.influence
						&& vm.influence.contextParentWfids
						&& vm.influence.organizationId === authOrgId
						&& vm.influence.contextParentWfids.indexOf('101-') === 0
                    ) {
                        model.targetOrganizationId = parseInt(vm.influence.contextParentWfids.split('-')[1])
                        model.contextParentWfid = null
                    }
                    else if (vm.intersectionSettings) {
                        model.targetOrganizationId = vm.intersectionSettings.organizationId
                    }
                    else {
                        model.targetOrganizationId = wfAuth.getOrganizationId()
                    }

                    model.networkId = _.get(vm, 'intersectionSettings.networkId')
                }

                if (objType.form && objType.form.onBeforeSubmitTriggered) {
                    objType.form.onBeforeSubmitTriggered({
                        getModel() {
                            return model
                        },
                        closeModal() {
                        },
                        setResultAndFinalize(result) {
                            finializeSubmit(result)
                        },
                        continueSubmit() {
                            createAndFinalizeSubmit(model)
                        },
                        setModel(newModel) {
                            model = newModel
                        },
                        cancelSubmit() {
                            deferred.resolve(false)
                        },
                    })
                }
                else {
                    createAndFinalizeSubmit()
                }

                function createAndFinalizeSubmit() {
                    dataOps.create(model, {
                        byUser: isRelationByUser,
                        influence: vm.influence,
                        networkId: vm.networkId,
                    }).then((newlyCreatedItem) => {
                        if (objType.form && objType.form.onAfterSubmit) {
                            objType.form.onAfterSubmit({
                                getObject() {
                                    return newlyCreatedItem
                                },
                                resolve(item) {
                                    finializeSubmit(item || newlyCreatedItem)
                                },
                            })
                        }
                        else {
                            finializeSubmit(newlyCreatedItem)
                        }
                    })
                }

                function finializeSubmit(newlyCreatedItem) {
                    const promises = []
                    console.log(newlyCreatedItem)

                    pickerService.injectInStore({
                        newlyCreatedItem,
                        typeStructureId: objType.structureId,
                        useItemComposites: objType.useItemComposites,
                        addToStructureAsDataRelation: objType.addToStructureAsDataRelation, // Used for types productionSite, productService, supplier (sub supplier) and holding (portfolio company)
                    }).then((relation) => { // dataRelation can be both a normal dataRelation, virtual dataRelation or itemComposite
                        let added = false

                        if (isBucketMode) {
                            bucket.newlySelected.push(newlyCreatedItem)
                            bucket.allSelected.push(newlyCreatedItem)
                            // console.log("375: allSelected.push(res)");

                            objType.items.unshift(relation)
                            allItems.unshift(relation)

                            if (vm.selectedObjectType.type === objType.type) // If the object type is still selected then add to the displayed list
                                vm.itemsOfSelectedObjectType.unshift(relation)

                            $q.all(promises).then(() => {
                                deferred.resolve(newlyCreatedItem)
                            })

                            vm.totalCountOfItems++
                        }
                        else {
                            if (!_.isArray(vm.relationTarget)) {
                                vm.relationTarget = [vm.relationTarget]
                            }

                            _.each(vm.relationTarget, (relationTarget) => {
                                promises.push(dataOps.createSubItemRelation(relationTarget.item, newlyCreatedItem, {
                                    kind: relationTarget.kind,
                                    networkId: vm.networkId,
                                    contextParentWfid: _.get(vm, 'intersectionSettings.contextParents[0]'),
                                }))
                            })

                            $q.all(promises).then(() => {
                                // If the newly created item actually is an item that already exists (like ProductionSites, that deals with unique GLN numbers)
                                // and/or the newly created DataRelation is also a relation that already existed then it will result in duplicate items
                                // in the UI so before the item is added an any array we always check if it's not already there.

                                if (vm.sourceLists) { // If sourceList mode then the objType spec was setup from activateWithSourceLists()
                                    if (vm.canCombineSourceListsFilter) {

                                    }
                                    else {
                                        _.each(vm.sourceLists, (sourceListSpec) => {
                                            if (sourceListSpec.putNewItemsHere) {
                                                if (!_.includes(sourceListSpec.items, relation)) {
                                                    added = true
                                                    sourceListSpec.items.unshift(relation)
                                                }
                                            }
                                            if (sourceListSpec === vm.selectedSourceList) {
                                                if (!_.includes(vm.itemsOfSelectedSourceList, relation)) {
                                                    added = true
                                                    vm.itemsOfSelectedSourceList.unshift(relation)
                                                }
                                            }
                                        })
                                    }
                                }
                                else {
                                    if (objType.items && !_.includes(objType.items, relation)) {
                                        added = true
                                        objType.items.unshift(relation)
                                    }

                                    if (!_.includes(allItems, relation)) {
                                        added = true
                                        allItems.unshift(relation)
                                    }

                                    if (vm.selectedObjectType.type === objType.type) { // If the object type is still selected then add to the displayed list
                                        if (!_.includes(vm.itemsOfSelectedObjectType, relation)) {
                                            added = true
                                            vm.itemsOfSelectedObjectType.unshift(relation)
                                        }
                                    }
                                }

                                if (added) vm.totalCountOfItems++

                                deferred.resolve(newlyCreatedItem)
                            })
                            // dataOps.createSubItemRelation(modalContext.relationTarget.item, res, modalContext.relationTarget.kind).then(function (res2) {
                            // });
                        }
                    })
                }

            })
        }

        function submitForm($event, scope, objType) {
            return submit($event, scope, objType).then((savedItem) => {
                // Reset form

                if (savedItem) {
                    objType.formControl.reset()
                    if (vm.canPick) {
                        toggleForm()

                        if (vm.singlePick) {
                            //Toggle scopes are destroyed when a form gets opened therefore listener (beforeRelationDestroyed) is not triggered
                            //Timeout is added to wait for the scopes to be triggered, although it is not the best solution.
                            $timeout(() => {
                                onBeforeToggled(savedItem)
                            }, 500)
                        }
                    }
                    else $uibModalInstance.close()
                }
            })
        }

        function submitFormAndClose($event, scope, objType) {
            return submit($event, scope, objType).then((valid) => {
                if (valid) $uibModalInstance.close()
            })
        }

        function injectInStore(objType, item) {
            return $q((resolve, reject) => {
                const parentWfid = '71-' + objType.structureId
                let virtualDataRelation

                if (objType.addToStructureAsDataRelation) { // Used for ProductionSites
                    dataOps.createSubItemRelation(parentWfid, item, {
                        kind: enums.subItemsKind.childrenByUser,
                    }).then((dataRelation) => {
                        resolve(dataRelation)
                    })
                }
                else {
                    // Creates a VirtualDataRelation and injects it into the store
                    virtualDataRelation = wfObject.inject({
                        childData1: null,
                        childId: item.id,
                        childType: item.type,
                        createdAt: null,
                        id: 0,
                        organizationId: item.creatorOrganizationId || item.organizationId,
                        parentData1: null,
                        parentId: objType.structureId,
                        parentType: 71,
                        type: 81,
                        userId: item.userId,
                        wfcid: item.wfid,
                        wffid: parentWfid,
                        wfid: '81-|' + parentWfid + '|' + item.wfid,
                    })

                    if (objType.useItemComposites) {
                        resolve(dataQuery.makeItemComposites([{
                            childContent: item,
                        }])[0])
                    }
                    else {
                        resolve(virtualDataRelation)
                    }

                }
            })
        }

        function toggleForm($event) {
            if ($event && !modalElement) {
                modalElement = $($event.target).closest('div.picker-modal')
            }

            if (modalElement) modalElement.toggleClass('picker-modal', vm.formVisible)

            vm.formVisible = !vm.formVisible

            if (vm.formVisible && vm.selectedObjectType.type == 0) {
                selectObjectType(vm.objectTypes[1])
            }
        }

        function maybeHideItems(array) {
            if (typeof vm.hideItem === 'string') {
                _.remove(array, (dataRelation) => {
                    return dataRelation.wfcid === vm.hideItem
                })
            }
        }

        function activateWithSourceItem() {
            let
                item
				
            let id; let type

            switch (typeof vm.sourceItem) {
                case 'string':
                    type = vm.sourceItem.split('-')[0]
                    id = vm.sourceItem.split('-')[1]
                    break
                case 'object':
                    type = vm.sourceItem.type
                    id = vm.sourceItem.id
                default:
                    break
            }

            dataOps.getObject({
                objectId: id,
                objectType: type,
                childrenLoadDepth: _.get(vm.sourceItemLoadSettings, 'childrenLoadDepth') || (vm.sourceItemFirstLevelAsFilter && 2) || 2,
            }).then((res) => {
                $timeout(() => {
                    let sourceLists; let allItems

                    if (res.isRelationalType()) $scope.item = res.childContent
                    else $scope.item = res

                    if (vm.sourceItemFirstLevelAsFilter) {
                        allItems = []
                        sourceLists = _.map($scope.item.childs, (dataRelation) => {
                            const childs = dataRelation.childContent.childs; let itemComposites
                            maybeHideItems(childs)

                            itemComposites = dataQuery.makeItemComposites(childs)
                            Array.prototype.push.apply(allItems, itemComposites)

                            return {
                                title: dataRelation.childContent.title,
                                items: itemComposites,
                            }
                        })

                        vm.sourceItem = undefined
                        vm.sourceLists = sourceLists

                        sourceLists.unshift({
                            title: $translate.instant('All'),
                            items: allItems,
                        })

                        activateWithSourceLists()
                    }
                    else {
                        if (_.every($scope.item.childs, (dataRelation) => {
                            return typeof dataRelation.order === 'number'
                        })) {
                            vm.sortedSourceItemChildren = _.sortBy($scope.item.childs, (dataRelation) => {
                                return dataRelation.order
                            })
                        }
                        else {
                            vm.sortedSourceItemChildren = _.sortBy($scope.item.childs, (dataRelation) => {
                                const childContent = dataRelation.childContent
                                return childContent && typeof childContent.getMainTextual === 'function' ? childContent.getMainTextual() : undefined
                            })
                        }

                        maybeHideItems(vm.sortedSourceItemChildren)

                        vm.loaded = true
                    }

                })
            })
        }

        function activateWithSourceLists() {
            promises = []
            vm.canCreate = false
            vm.hideFilters = false

            if (vm.createObjectOfType) {
                vm.selectedObjectType = _.find(availableObjectTypes, { type: vm.createObjectOfType })
                if (vm.selectedObjectType) {
                    setupObjectTypeSpec(vm.selectedObjectType)

                    vm.selectedObjectType.useItemComposites = true
                    vm.selectedObjectType.items = vm.sourceLists[0].items
                    vm.canCreate = true
                    vm.showFormToggler = true
                }
            }

            _.each(vm.sourceLists, (sourceList) => {
                if (typeof sourceList.items === 'function') {
                    if (!sourceList.lazyLoad) {
                        (function () {
                            const promiseOrArray = sourceList.items()
                            if (promiseOrArray.then) {
                                promises.push(promiseOrArray.then((res) => {
                                    sourceList.items = res
                                    sourceList.loaded = true
                                }))
                            }
                            else {
                                promises.push($q((resolve) => {
                                    sourceList.items = promiseOrArray
                                    sourceList.loaded = true
                                    resolve()
                                }))
                            }
                        })()
                    }
                }
                else if (_.isArray(sourceList.items)) {
                    promises.push($q((resolve) => {
                        resolve()
                    }))
                }
                vm.sourceItem = true
            })

            $q.all(promises).then(() => {
                const initialSourceLists = _.clone(vm.sourceLists)

                // Remove source lists that are empty
                _.remove(vm.sourceLists, (sourceList) => {
                    return !sourceList.alwaysShowButton && !sourceList.items.length
                })

                // If all source lists were removed than add back the first one
                if (vm.sourceLists.length === 0) vm.sourceLists = [initialSourceLists[0]]

                selectSourceList(vm.sourceLists[0])
                vm.loaded = true

                let averageTitleLength; const filterOptionsCount = vm.sourceLists.length
                averageTitleLength = _.chain(vm.sourceLists).map('title.length').sum() / filterOptionsCount
                // If the multiplication of average title length and the number of options exceeds 400
                // then display a dropdown instead of buttons. After some testing, 400 seems to work well.
                if (averageTitleLength * filterOptionsCount > 400) {
                    vm.showSourceListFilteringAsDropdown = true
                }
            })
        }

        function onBeforeToggled(item, initial) {
            let ticketOrgId
            if (!isBucketMode) {
                if (!initial && item && vm.singlePick && (vm.sourceItem || vm.objectTypes.length > 0) && vm.templateId === 65 && vm.relationTarget.length === 1) {
                    ticketOrgId = _.get(vm.intersectionSettings, 'organizationId')

                    _.each(vm.relationTarget, (relationTarget) => {
                        const
                            kind = relationTarget.kind
							
                        let relations
							
                        const queryWhere = {
                            type: enums.objectType.dataRelation,
                        }
							
                        const foreignKey = wfObject.getForeignKeyOfKind(kind)
							
                        const localKey = wfObject.getRelationKeyOfKind(kind)

                        queryWhere[foreignKey] = relationTarget.item.wfid
                        queryWhere[localKey] = { '!=': item.wfid }

                        switch (kind) {
                            case enums.subItemsKind.childrenByUser:
                            case enums.subItemsKind.parentsByUser:
                            case enums.subItemsKind.relatedContentByUser:
                            case enums.subItemsKind.relatedParentsByUser:
                            case enums.subItemsKind.verifications:
                            case enums.subItemsKind.verifies:
                            case enums.subItemsKind.contextChildren:
                            case enums.subItemsKind.contextParents:
                                queryWhere.organizationId = ticketOrgId || authOrgId
                                break
                            case enums.subItemsKind.children:
                            case enums.subItemsKind.parents:
                            case enums.subItemsKind.relatedContent:
                            case enums.subItemsKind.relatedParents:
                                queryWhere.organizationId = null
                                break
                        }

                        queryWhere.parentData1 = wfObject.getRelationParentDataOfKind(kind)

                        if (relationTarget.contextParentWfid) queryWhere.wfxpid = relationTarget.contextParentWfid

                        relations = wfObject.filter({ where: queryWhere })

                        if (vm.sortedSourceItemChildren) {
                            relations = _.intersectionWith(relations, vm.sortedSourceItemChildren, (relation, availableRelation) => {
                                return relation[localKey] === availableRelation.wfcid
                            })
                        }
                        else {
                            if (vm.objectTypes.length === 1) {
                                relations = _.intersectionWith(relations, vm.itemsOfSelectedObjectType, (relation, availableRelation) => {
                                    return relation[localKey] === availableRelation.wfcid
                                })
                            }
                            else {
                                relations = _.intersectionWith(relations, allItems, (relation, availableRelation) => {
                                    return relation[localKey] === availableRelation.wfcid
                                })
                            }
                        }

                        if (relations) {
                            _.each(relations, (relation) => {
                                $scope.$broadcast('beforeRelationDestroyed', relation)
                                dataOps.destroy(relation)
                            })
                        }
                    })
                }
            }
        }

        function onToggled(item, checked, togglerScope, dataRelations, initial) {
            if (isBucketMode) {
                if (!checked) {
                    _.pull(bucket.newlySelected, item)
                    _.pull(bucket.allSelected, item)
                    _.pull(bucket.allSelected_scopes, togglerScope)

                    if (_.includes(bucket.preSelected, item) && !_.includes(bucket.newlyRemoved, item)) bucket.newlyRemoved.push(item)

                    delete vm.selectedItemsByWfid[item.wfid]
                }
                else {
                    if (vm.singlePick) {
                        _.forEach(bucket.allSelected_scopes, (togglerScope) => {
                            togglerScope.toggleState()
                        })
                        bucket.allSelected.length = 0
                        bucket.newlySelected.length = 0
                        bucket.allSelected_scopes.length = 0
                    }

                    _.pull(bucket.newlyRemoved, item)

                    if (!_.includes(bucket.preSelected, item) && !_.includes(bucket.newlySelected, item)) {
                        bucket.newlySelected.push(item)
                    }

                    if (!_.includes(bucket.allSeletced, item)) {
                        bucket.allSelected.push(item)
                    }

                    if (!_.includes(bucket.allSelected_scopes, togglerScope)) {
                        bucket.allSelected_scopes.push(togglerScope)
                    }

                    // console.log(bucket);
                    vm.selectedItemsByWfid[item.wfid] = {
                        item,
                        dataRelations,
                    }
                }

                // The above logic result in duplicates in the bucket arrays, this needs to be fixed.
                // Quick fixed now by doing _.uniq() on all arrays.
                _.each(bucket, (value, key) => {
                    bucket[key] = _.uniq(value)
                })

                const selectedIds = _.map(bucket.allSelected, 'wfid')
                pickedItemsOfSelectedObjectType = _.filter(vm.itemsOfSelectedObjectType, (item) => {
                    return _.includes(selectedIds, item.wfcid)
                })

                if (vm.selectedObjectType && vm.selectedObjectType.useFirstRelationTargetAsSource) {
                    setViewItemsFromRelationTarget()
                }
            }
        }

        function resetListLimit() {
            vm.listLimit = null
        }

        function onSearch(searchString, items, searchActive) {
            vm.searchActive = searchActive
        }

        function toggleOnlySelectedItems(showOnlySelectedItems) {
            let selectedIds = []
            let relations

            vm.selectedItemsToggled = showOnlySelectedItems
            if (showOnlySelectedItems) {
                if (isBucketMode) {
                    selectedIds = _.map(bucket.allSelected, 'wfid')
                }
                else {
                    if (vm.relationTarget) {
                        _.each(vm.relationTarget, (target) => {
                            if (target.item) {
                                relations = dataQuery.getRelations({
                                    organizationId: wfObject.isRelationKindByUser(target.kind) ? _.get(vm.intersectionSettings, 'organizationId') || authOrgId : null,
                                    kind: target.kind,
                                    parent: target.item,
                                })

                                Array.prototype.push.apply(selectedIds, _.map(relations, 'wfcid'))
                            }
                        })
                    }
                }

                if (selectedIds && selectedIds.length) {
                    pickedItemsOfSelectedObjectType = _.filter(vm.itemsOfSelectedObjectType, (item) => {
                        return _.includes(selectedIds, item.wfcid)
                    })
                }
                else {
                    if (pickedItemsOfSelectedObjectType) pickedItemsOfSelectedObjectType.length = 0
                }

                vm.itemsOfSelectedObjectType.length = 0
                Array.prototype.push.apply(vm.itemsOfSelectedObjectType, pickedItemsOfSelectedObjectType)
            }
            else {
                vm.itemsOfSelectedObjectType.length = 0
                Array.prototype.push.apply(vm.itemsOfSelectedObjectType, _.filter(allItems, vm.selectedObjectType.filter))
            }
        }

        function selectSourceList(sourceList) {
            let output = []

            if (!sourceList) sourceList = vm.sourceLists[0]

            vm.selectedSourceList = sourceList

            vm.canCombineSourceListsFilter = false

            if (sourceList.lazyLoad && !sourceList.loaded) {
                delete sourceList.count

                if (sourceList.loading) {

                }
                else {
                    sourceList.loading = true
                    const promiseOrArray = sourceList.items()
                    let promise
                    if (promiseOrArray.then) {
                        promise = promiseOrArray.then((res) => {
                            sourceList.items = res
                        })
                    }
                    else {
                        promise = $q((resolve) => {
                            sourceList.items = promiseOrArray
                            resolve()
                        })
                    }

                    promise.then((x) => {
                        sourceList.loaded = true
                        sourceList.loading = false
                        if (vm.selectedSourceList === sourceList) {
                            finish()
                        }
                    })
                }
            }
            else {
			 	finish()
            }

            function finish() {
                if (vm.canCombineSourceListsFilter) {
                    sourceList.selected = !sourceList.selected

                    _.each(_.filter(vm.sourceLists, { selected: true }), (sourceList) => {
                        Array.prototype.push.apply(output, sourceList.items)
                    })

                    vm.itemsOfSelectedSourceList.length = 0
                    output = _.uniqBy(output, 'wfid')
                    Array.prototype.push.apply(vm.itemsOfSelectedSourceList, output)
                }
                else {
                    _.each(vm.sourceLists, (sourceList) => {
                        sourceList.selected = false
                    })

                    sourceList.selected = true
                    vm.itemsOfSelectedSourceList.length = 0
                    Array.prototype.push.apply(vm.itemsOfSelectedSourceList, sourceList.items)
                }
            }
        }

        //E-commerce (to be deleted)
        function onFormCloseClick() {
            if (vm.canPick) {
                toggleForm()
            }
            else {
                $scope.$close()
            }
        }

        function onFooterButtonClick($event, button) {
            if (typeof button.callback === 'function') {
                button.callback($scope, bucket)
            }
            else {
                $scope.$close()
            }

            $timeout()
        }
    }
})()
