(function() {
    'use strict'

    angular
        .module('wf.common')
        .controller('InfluenceController', InfluenceController)

    InfluenceController.$inject = ['$scope', '$element', '$attrs', 'dataOperationsService', 'modalService', 'wfObject', 'dataQuery', '$translate', '$timeout', '$stateParams']
	
    function InfluenceController($scope, $element, $attrs, dataOps, modal, wfObject, dataQuery, $translate, $timeout, $stateParams) {
        const ivm = this
        ivm.influenceId = $stateParams.objectId
        ivm.lookupObjectWfid = $stateParams.lookupObjectWfid

        ivm.window = window
        // //console.log($scope.$parent)
        // _.assign(this, $scope.$parent.vm);
        // ivm.$state = $state;
        // // ivm.influence = ivm.item.childContent;
        // // console.log($scope.$parent);
        // ivm.isFulfilled = true;//vm.influence.fulfilled;
		
        // console.info(vm);
    }
})()
