import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#9ca4b0',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
}))

function renderMessage(count, total) {
    if (!total) {
        return <FormattedMessage id={'search.foundCount'} values={{ count }} />
    }

    return <FormattedMessage id={'search.foundCountWithTotal'} values={{ count, total }} />
}

const SearchCount = (props) => {
    const { count, total } = props
    const classes = useStyles(props)

    return (
        <div className={classes.searchCounter}>
            { renderMessage(count, total) }
        </div>
    )
}

export default SearchCount
