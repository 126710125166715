import React from 'react'
import SinglePointChart from '@worldfavor/components/Items/SinglePointChart'
import { WrapProvider } from './providers'
import { react2angular } from 'react2angular'

const props = [
    'item',
    'data',
    'label',
    'detailedLabel',
    'average',
    'organizationCount',
    'value',
    'unit',
    'createdAt',
    'creator',
    'valueColor',
    'isInconsistent',
]

class SinglePointChartWrapper extends React.Component {

  prepareProps = (props) => {
      const { data = {} } = props
      return {
          ...props,
          label: props.label || data.label,
          detailedLabel: props.detailedLabel || data.detailedLabel,
          average: props.average || (data.organizationCount > 1 && data.average),
          organizationCount: props.organizationCount || (data.organizationCount > 1 && data.organizationCount),
          value: props.value || data.value || data.sum,
          unit: props.unit || (data.unit && data.unit.symbol),
          valueColor: props.valueColor || data.valueColor,
          style: {
              ...props.style,
              minHeight: 200,
          },
      }
  }

  render() {
      const { wfAuth, ...rest } = this.props
      return (
          <WrapProvider wfAuth={wfAuth}>
              <SinglePointChart {...this.prepareProps(rest)} />
          </WrapProvider>
      )
  }
}

export default react2angular(SinglePointChartWrapper, props, ['wfAuth'])
