import * as enums from '@worldfavor/constants/enums'

(function () {
    'use strict'

    angular
        .module('wf.common')
        .directive('wfItemRelations', wfItemRelations)
	
    wfItemRelations.$inject = ['$parse', 'dataQuery', 'dataOperationsService', '$compile']

    function wfItemRelations($parse, dataQuery, dataOps, $compile) {
        const
            templateUrl_default = 'scripts/wf/itemRelations/wfItemRelations.directive.html'
			
        const templateUrl_tags = 'scripts/wf/itemRelations/wfItemRelations.tags.directive.html'
			
        let templateContent
		
        const directive = {
            restrict: 'E',
            templateUrl,

            // Note: Since multiple instances of wfItemRelations can exist in the same scope
            // we can't use controllerAs because the 'this' is the same for all instances in the scope
            // the controllerAs value is set "manually" with $scope.relVm or $scope.relTagsVm instead

            // controllerAs: 'relVm',

            controller: ['$scope', '$element', '$templateCache', 'modalService', 'dataOperationsService', '$timeout', '$attrs', '$translate', 'wfAuth', wfItemRelationsController],
            link,
        }
		
        return directive

        function templateUrl(element, attrs) {
            if (attrs.hasOwnProperty('useTags')) return templateUrl_tags
            else return templateUrl_default
        }

        function wfItemRelationsController($scope, $element, $templateCache, modal, dataOps, $timeout, $attrs, $translate, wfAuth) {
            let
                vm
				
            const minItemsView = 0
				
            let busy
				
            let loaded
				
            let kind = 5
            // Related content by user
				
            let label
				
            let labelTranslate
				
            let useCustomLabel
				
            const useTags = $attrs.hasOwnProperty('useTags')
				
            const useDetachedPane = $attrs.hasOwnProperty('useDetachedPane')
				
            let readOnly = $attrs.hasOwnProperty('readOnly') || !wfAuth.canUserWrite()
				
            const useOverlay = $attrs.hasOwnProperty('useOverlay')
				
            const templateId = $attrs.hasOwnProperty('templateId') ? parseInt($attrs.templateId) : 32
				
            const scopeItem = $attrs.hasOwnProperty('item') ? $parse($attrs.item)($scope) : $scope.item
				
            const context = $attrs.hasOwnProperty('context') ? $parse($attrs.context)($scope) : {}
				
            const itemChildContent = scopeItem ? scopeItem.childContent : null
				
            const itemStats = itemChildContent && itemChildContent.metadata ? itemChildContent.metadata.countByRelationKind : null
				
            let tempItems
				
            let tempItem
				
            let ticket
				
            const authenticatedOrgId = wfAuth.getOrganizationId() || null

            if ($attrs.kind) kind = parseInt($attrs.kind)

            if (useTags) {
                initWithTags()
                return
            }

            vm = $scope.relVm = {}
            vm.itemsCount = itemStats ? itemStats[kind] || 0 : 0

            if (itemChildContent && itemChildContent.organizationId != authenticatedOrgId) readOnly = true

            if (!vm.itemsCount && readOnly)
            {
                $element.remove()
                return
            }

            label = $attrs.label
            labelTranslate = $attrs.labelTranslate
            useCustomLabel = !!label || !!labelTranslate

            if (labelTranslate) label = $translate.instant(labelTranslate)
            else if (!label) label = $translate.instant('AttachedInformation')
				
            _.assign(vm, {
                allItems: [], // From wfQuestionIteming directive
                toggleItems,
                filteredItems: [],
                toggled: false,
                addOrToggle,
                addItems,
                readOnly,
                label,
                useCustomLabel,
                useDetachedPane,
            })
            // $scope.questionItemingHistory = {
            // 	updateHistory: updateHistory
            // };
			
            function toggleItems() {
                let tempId; let tempWfid; let tempDr; let newTempItem
                if (useOverlay)
                {
                    $element.addClass('useOverlay')
					
                    if (!tempItems)
                    {
                        tempId = _.uniqueId()
                        tempWfid = '71-temp' + tempId

                        tempItems = []
                        tempItem = newTempItem = {
                            type: 73,
                            wfid: '73-temp' + tempId,
                            wffid: '00-' + tempId,
                            wfcid: tempWfid,
                            childType: 71,
                            settings: {
                                itemsPerPage: 3,
                            },
                        }
                        tempItems.push(newTempItem)

                        newTempItem = {
                            type: 71,
                            wfid: tempWfid,
                            title: $translate.instant('Linkages'),
                            description: $translate.instant('LinkagesDescription'),
                            childs: [],
                        }
                        tempItems.push(newTempItem)
                        wfObject.inject(newTempItem)

                        wfObject.inject(tempItem)

                        modal.openItem(_.assign({
                            outerItem: itemChildContent,
                            hideSideChart: true,
                            resolve() {
                                const jqDf = $.Deferred()
                                // console.log("getSubItemsOfAll")
                                dataOps.getSubItems(itemChildContent, kind, { ticket }).then((res) => {
                                    vm.itemsCount = res.length
                                    vm.allItems = res
                                    vm.filteredItems = vm.allItems
                                    // $timeout();
									
                                    _.forEach(vm.allItems, (item) => {
                                        const childContent = item.childContent
                                        // tempItem.childContent.childs.push(
                                        wfObject.inject(newTempItem = {
                                            type: 81,
                                            wfid: '81-|' + tempWfid + '|' + childContent.wfid,
                                            childId: item.childId,
                                            childType: 71,
                                            parentType: 71,
                                            parentData1: null,
                                            wffid: tempWfid,
                                            wfcid: childContent.wfid,
                                        })
                                    })

                                    tempItems.push(newTempItem)

                                    dataOps.getSubItemsOfAll(_.map(vm.allItems, 'childContent'), 1, { childrenLoadDepth: 10 }).then(() => {
                                        jqDf.resolve()
                                    })
                                })
                                return jqDf.promise()
                            },
                            item: wfObject.get('73-temp' + tempId),
                            settings: {
                                templateId,
                            },
                        }, $parse($attrs.useOverlay)($scope))).modal.closed.then(() => {
                            _.forEach(tempItems, (item) => {
                                wfObject.eject(item.wfid)
                            })
                            tempItems = null
                        })
                    }
                }
                else
                {
                    if (vm.toggled) {
                        vm.filteredItems = []
                    }
                    else {
                        vm.filteredItems = vm.allItems
                    }
                    vm.toggled = !vm.toggled
                }
            }
			
            function addOrToggle() {
                if (busy) return
				
                if (loaded) {
                    toggleItems()
                }
                else {
                    if (vm.itemsCount) {
                        busy = true
                        loaded = true

                        if (context && context.influence) {
                            ticket = {
                                organizationId: context.influence.organizationId,
                                networkId: context.influence.channelId,
                            }
                            vm.noTools = wfAuth.getOrganizationId() !== context.influence.organizationId
                        }

                        if (useOverlay) {
                            toggleItems()
                            busy = false
                        }
                        else {
                            dataOps.getSubItems(itemChildContent, kind, { ticket }).then((res) => {
                                busy = false
                                $timeout(() => {
                                    vm.itemsCount = res.length
                                    vm.allItems = res
                                    vm.filteredItems = vm.allItems
                                    toggleItems()
                                }, 100)
                            })
                        }

                    }
                    else {
                        modal.openCreatorAndPicker({ relationTarget: { item: itemChildContent, kind } }).modal.closed.then(() => {
                            vm.filteredItems = vm.allItems = itemChildContent.filterRelatedContent({ organizationId: wfAuth.getOrganizationId() })
                            vm.itemsCount = vm.allItems.length
                            if (vm.itemsCount) {
                                $timeout(() => {
                                    toggleItems()
                                }, 100)
                            }
                        })
                    }
                }
            }
			
            function addItems() {
                modal.openCreatorAndPicker({ relationTarget: { item: itemChildContent, kind } }).modal.closed.then(() => {
                    $timeout(() => {
                        vm.filteredItems = vm.allItems = itemChildContent.filterRelatedContent({ organizationId: wfAuth.getOrganizationId() })
                        vm.itemsCount = vm.allItems.length
                    }, 100)
                })
            }

            function initWithTags() {
                const vm = $scope.relTagsVm = {}; let query
                $element.addClass('useTags')

                if (!scopeItem || !itemChildContent) return

                if (kind == enums.subItemsKind.parents) {
                    vm.item = itemChildContent
                    vm.items = _.chain(itemChildContent.parents)
                    // .reject({ wffid: $scope.vm.item.wffid })
                        .filter((item) => {
                            const parentContent = item.parentContent
                            return parentContent && (parentContent.title || parentContent.text || parentContent.name)
                        })
                        .map((item) => {
                            return { content: item.parentContent, relation: item }
                        })
                        .value()
                }
                else if (kind == enums.subItemsKind.parentsByUser && wfAuth.getOrganizationId() && itemChildContent.filterParents) {
                    query = { organizationId: wfAuth.getOrganizationId(), type: { in: [enums.objectType.dataRelation, enums.objectType.visibilityTag] } }

                    if (scopeItem.childType == enums.objectType.questionAnswer) query.parentType = { '!=': enums.objectType.question }
                    else if (scopeItem.childType == enums.objectType.measureAnswer) query.parentType = { '!=': enums.objectType.measure }

                    vm.item = itemChildContent
                    vm.items = _.chain(itemChildContent.filterParents(query))
                    // .reject({ wffid: $scope.vm.item.wffid })
                        .filter((item) => {
                            const parentContent = item.parentContent
                            return parentContent && (parentContent.title || parentContent.text || parentContent.name)
                        })
                        .map((item) => {
                            return { content: item.parentContent, relation: item }
                        })
                        .value()
                }
            }
        }

        function link(scope, element, attrs) {
            // console.log(element.html())
            element.addClass('wfItemRelations')
        }
    }
})()
