import React, { useState, useEffect } from 'react'
import Loading from '@worldfavor/portal/scenes/Loading'
import { StaticIds } from '@worldfavor/constants'
import { useSelector, useDispatch } from 'react-redux'
import { loadItem } from '@worldfavor/portal/actions/dataThunk'
import { getNodeFromNodeId } from '@worldfavor/portal/selectors/dataSelector'
import DataExplorer from '@worldfavor/components/DataExplorer/DataExplorer'

const DataExplorerScene = () => {
    const dispatch = useDispatch()
    const [structureLoading, setStructureLoading] = useState(true)
    const dataExplorerStructure = useSelector(state => getNodeFromNodeId(state, StaticIds.DataExplorer))

    useEffect(() => {
        async function fetchStructure() {
            setStructureLoading(true)
            const [type, id] = StaticIds.DataExplorer.split('-')
            try {
                await dispatch(loadItem(id, type))
            } catch (e) {
                // TODO handle error
            } finally {
                setStructureLoading(false)
            }
        }
        fetchStructure()
    }, [])

    return (
        <>
            { structureLoading && <Loading /> }
            { !structureLoading && <DataExplorer coverImageUrl={dataExplorerStructure.imageUrlLarge} title={dataExplorerStructure.title} /> }
        </>
    )
}

export default DataExplorerScene
