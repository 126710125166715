(function () {
    'use strict'

    angular
        .module('wf.common')
        .controller('ModalController', ModalController)

    ModalController.$inject = ['$scope', '$translate', '$uibModalInstance', 'modalContext', '$timeout', '$compile']

    function ModalController($scope, $translate, $uibModalInstance, modalContext, $timeout, $compile) {
        /* jshint validthis:true */
        const
            vm = this
			
        let promise
			
        let templateContainer

        vm.modalContext = modalContext
        $scope.onButtonClick = onButtonClick

        $scope.context = modalContext
        $scope.context.modalInstance = $uibModalInstance

        if (typeof modalContext.resolve === 'function' && (promise = modalContext.resolve()).then) {
            // console.log("WAITING TO LOAD");
            modalContext.loading = true

            promise.then(() => {
                $timeout(() => {
                    modalContext.loading = false
                    $scope.item = modalContext.item
                })
            })
        }
        else if (modalContext.template && modalContext.scope) {
            $timeout(() => {
                templateContainer = $('#wf-modal-templateContainer')
                templateContainer.hide()
                templateContainer.html(modalContext.template)
                $compile(templateContainer.contents())(_.assign($scope.$new(), modalContext.scope))

                if (typeof modalContext.onLoaded === 'function') modalContext.onLoaded($scope, templateContainer)

                // Content shows for a split second without being compiled.
                // Fixed with a timeout.
                setTimeout(() => {
                    templateContainer.show()
                }, 0)

            })
        }
        else {
            $scope.item = modalContext.item
        }

        $scope.context.modalButtons = [
            {
                caption: 'Close',
                cssClass: 'btn btn-cancel wf-btn-link',
                onClick() {
                    $uibModalInstance.close()
                },
            },
        ]

        function onButtonClick($event, button) {
            const fnOutput = button.onClick(button.clickEventParam)

            if (button.busy) return

            if (fnOutput && fnOutput.then) {
                button.busy = true
                fnOutput.then(() => {
                    button.busy = false
                    $uibModalInstance.close()
                })
                fnOutput.catch(() => {
                    button.busy = false
                    $timeout()
                })
            }

            return fnOutput
        }
    }
})()
